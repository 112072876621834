import lessonManager from '../managers/LessonManager';
import responseManager from '../managers/ResponseManager';

import { LessonMode } from '../../Constants';

export default class PlayerService {
  static isReadOnly = (lessonElementId) => {
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
    return (lessonElementState.readOnly || lessonManager.isReadOnly);
  }

  static isStudentPreviewMode = () => {
    const { playerMode } = lessonManager;
    return playerMode === LessonMode.STUDENT_PREVIEW;
  }

  static isPreviewMode = () => {
    const { playerMode } = lessonManager;
    return playerMode === LessonMode.PREVIEW;
  }

  static isManuscriptMode = () => {
    const { playerMode } = lessonManager;
    return playerMode === LessonMode.MANUSCRIPT;
  }

  static isReviewMode = () => {
    const { playerMode } = lessonManager;
    return playerMode === LessonMode.REVIEW;
  }

  static isScoringMode = () => {
    const { playerMode } = lessonManager;
    return playerMode === LessonMode.SCORING;
  }

  static isActivityMode = () => {
    const { playerMode } = lessonManager;
    return playerMode === LessonMode.ACTIVITY_MODE;
  }

  static isPrintPreviewMode = () => {
    const { playerMode } = lessonManager;
    return playerMode === LessonMode.PRINT_PREVIEW;
  }

  static getToolbarWrapperElement = () => {
    return document.querySelector('.toolbar-wrapper');
  }

  static getScoringToolbarWrapperElement = () => {
    return document.querySelector('.scoring-toolbar-wrapper');
  }

  static hasMultiplePanes = () => {
    return document.getElementsByClassName('Pane')?.length > 1;
  }

  static getPaneDomRect = (paneQuerySelector = '.Pane1') => {
    const paneElement = document.querySelector(paneQuerySelector);
    const paneDomRect = paneElement?.getBoundingClientRect?.();
    return paneDomRect;
  }
}
