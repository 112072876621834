// noinspection FallThroughInSwitchStatementJS

import { useEffect } from 'react';

import { LessonMode } from '../Constants';

import lessonManager from '../lessonPlayer/managers/LessonManager';
import studentActivityManager from '../lessonPlayer/managers/StudentActivityManager';
import alignmentService from '../lessonPlayer/services/AlignmentService';
import studentActivityService from '../lessonPlayer/services/StudentActivityService';
import toolbarManager from '../lessonPlayer/managers/ToolbarManager';
import userManager from '../lessonPlayer/managers/UserManager';

import Auth from '../lessonPlayer/services/AuthService';

import itemPreviewService from '../lessonPlayer/services/ItemPreviewService';
import lessonService from '../lessonPlayer/services/LessonService';
import navMenuService from '../lessonPlayer/services/NavMenuService';
import responseService from '../lessonPlayer/services/ResponseService';
import utilsService from '../lessonPlayer/services/UtilsService';

const useLessonInit = (mode) => {
  async function getLesson(isStudent) {
    try {
      lessonManager.setLoaded(1);
      const urlParams = new URLSearchParams(window.location.search);

      const activityAccommodationIds = urlParams.get('actAcmIds');
      const courseContentItemId = urlParams.get('courseId');
      const externalUrl = urlParams.get('appUrl');
      const feedbackModeRequested = urlParams.get('modeId');
      const profileId = urlParams.get('profileId');
      const lessonContentItemId = urlParams.get('contentItemId');
      const previewLessonElementId = urlParams.get('previewLessonElementId');
      const lessonElementIdRequested = urlParams.get('lessonElementId');
      const contentOnly = urlParams.get('contentOnly');
      const studentViewId = urlParams.get('sid');
      const forceStudent = urlParams.get('forceStudent');
      const forceTeacher = urlParams.get('forceTeacher');
      const title = urlParams.get('title');
      const ficon = urlParams.get('ficon');
      const isSat = urlParams.get('sat') === 'true';
      const isOld = urlParams.get('old') === 'true';
      const builderPreviewMode = urlParams.get('previewMode');

      const isForceStudent = (forceStudent === 'true');
      const isForceTeacher = (forceTeacher === 'true');

      lessonManager.setIsSat(isSat);
      lessonManager.setIsOld(isOld);
      lessonManager.setIsForceTeacher(isForceTeacher);

      const activityId = urlParams.get('activityId');
      if (activityId) {
        await userManager.fetchUserAccommodations(activityId);
        toolbarManager.setUserAccommodationsOff(userManager.accommodationIds);
      }
      if (activityAccommodationIds) {
        toolbarManager.setActivityAccommodationsOff(activityAccommodationIds);
      }
      studentActivityManager.setStudentId(studentViewId);
      toolbarManager.setExternalUrl(externalUrl);

      // lesson Content Item Id is stored in lessonManager.lessonContentItem.id;
      lessonService.setCourseContentItemId(courseContentItemId);

      if (mode === LessonMode.PREVIEW && (isStudent || isForceStudent)) {
        mode = LessonMode.STUDENT_PREVIEW;
      }
      if (mode === LessonMode.PREVIEW && userManager.isPublisherUser && (!isForceStudent && !isForceTeacher)) {
        mode = LessonMode.PUBLISHER_PREVIEW;
      }
      if (mode === LessonMode.ITEM_PREVIEW || ( mode === LessonMode.PRINT_PREVIEW &&  builderPreviewMode === 'itemPreview')) {
        await itemPreviewService.fetchItemPreviewData(lessonContentItemId, mode, feedbackModeRequested, profileId);
      } else {
        await lessonService.fetchLessonData(lessonContentItemId, mode, feedbackModeRequested, profileId);
      }

      navMenuService.initFlyoutOpenState();

      if (title) {
        document.title = title;
      }

      if (ficon) {
        const icon = document.getElementById('theficon');
        let image = getComputedStyle(document.documentElement)
          .getPropertyValue('--favicon');
        image = image.replace(/\'/gi, '');
        icon.setAttribute('href', image);
      }

      // eslint-disable-next-line default-case
      switch (mode) {
      case LessonMode.SCORING: {
        lessonManager.setLoaded(+1);

        // is this lti
        const origin = urlParams.get('isLtiLaunched');
        const isLti = ((origin !== null && origin !== '' && origin !== undefined) && (origin === 'true'));
        lessonManager.setIsLti(isLti);

        const activityId = urlParams.get('activityId');
        const isNext = false;
        lessonManager.lessonOptions.navigateWithinActivity = true;

        await responseService.doScoringModeStartUp(activityId, studentViewId, lessonElementIdRequested, isNext);
        await responseService.fetchAllTeacherComments();
        lessonManager.setLoaded(-1);
        break;
      }
      case LessonMode.REVIEW: {
        const studentActivityId = urlParams.get('activityId');
        await studentActivityService.fetchStudentActivity(studentActivityId);
        await responseService.fetchResponses(studentActivityId);
        await responseService.fetchAllTeacherComments();
        break;
      }

      case LessonMode.ACTIVITY_MODE: {
        const studentActivityId = urlParams.get('activityId');
        await studentActivityService.fetchStudentActivity(studentActivityId);
        await responseService.fetchResponses(studentActivityId);
        // break; // intentional fall through
      }
      case LessonMode.ITEM_PREVIEW:
      case LessonMode.STUDENT_PREVIEW:
      case LessonMode.PUBLISHER_PREVIEW:
      case LessonMode.PREVIEW: {
        let lessonElementId = '';
        if (utilsService.isNullOrEmpty(studentActivityService.getLastViewedItem())) {
          lessonElementId = lessonService.getCurrentLessonElementId();
        } else {
          const model = lessonManager.contentMap[studentActivityService.getLastViewedItem()];
          if (model) {
            lessonElementId = model.lessonElementId;
            const index = lessonManager.slideList.indexOf(lessonElementId);
            lessonManager.setCurrentSlideIndex(index);
          }
        }

        // if (previewLessonElementId !== '') {
        if (previewLessonElementId) {
          await navMenuService.navToSlideClickHandler(previewLessonElementId);
        }
        responseService.setupRuntimeDependency();

        // If we have a title page go to next slide to start on item page
        if (mode === LessonMode.ITEM_PREVIEW) {
          const { lessonOptions } = lessonManager;
          if (lessonOptions && !lessonOptions.hideTitlePage) {
            lessonManager.nextSlide();
          }
        }
        break;
      }
      case LessonMode.MANUSCRIPT: {
        if (contentOnly === 'true') {
          lessonManager.setManuscriptShort(true);
        }
        break;
      }
      case LessonMode.PRINT_PREVIEW: {
        const arrayString = Object.keys(lessonManager.contentMap);
        const contentItemIds = [];
        if (arrayString.length > 0) {
          arrayString.forEach((id) => {
            const newId = `'${id}'`;
            contentItemIds.push(newId);
          });
          if (contentItemIds.length > 0) {
            await alignmentService.fetchContentItemsTaggedStandards(contentItemIds);
          }
        }
        break;
      }
      }
      lessonManager.setLoaded(-1);
      await navMenuService.doPreNavigationActions(true);
      await navMenuService.doPostNavigationActions();
    } catch (e) {
      console.error(e);
      lessonManager.setLoaded(-1);
    }
  }

  useEffect(() => {
    async function init() {
      if (window.location.hash) {
        const authKey = window.location.hash.substring(1);
        const user = await Auth.logInWithAuthKey(authKey);
        Auth.setAuthKey(authKey);
        userManager.setUser(user);
        await getLesson(userManager.isStudent);
        lessonManager.addLessonWideListeners();
      }
    }
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useLessonInit;
