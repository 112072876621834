import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/GotoCard.scss';

import flaggedSvg from '../../../images/summaryIcons/flagged.svg';

import { register } from '../../../i18n';

import { ContentType, LessonMode } from '../../../Constants';

import htmlService from '../../services/HtmlService';
import lessonService from '../../services/LessonService';
import navMenuService from '../../services/NavMenuService';
import responseService from '../../services/ResponseService';

import UtilsService from '../../services/UtilsService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import HtmlComponent from '../HtmlComponent';
import ResourceIcon from '../ResourceIcon';

const t = register('NavigationBar');

const GotoCard = observer(({ lessonElementId, isLocked, childState }) => {
  const {
    lessonManager,
    responseManager
  } = useContext(MobXProviderContext);

  const [noClick, setNoClick] = useState(false);

  const cardRef = useRef();
  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const flagged = responseService.isMarkedForReview(lessonElementId);
  const lessonItem = lessonManager.getLessonElement(model.lessonElementId);

  const clickHandler = async (event) => {
    if (noClick) {
      setNoClick(false);
      return;
    }
    htmlService.closeMathTypeModal();
    navMenuService.gotoButtonHandler(event, false);

    if (isLocked) {
      if (childState && childState.isDependencyLocked) {
        await responseService.alertDependencyLock(childState);
      } else if (lessonElementState.isDependencyLocked) {
        await responseService.alertDependencyLock(lessonElementState);
      }
      return;
    }
    await navMenuService.navToSlideClickHandler(lessonElementId, { fromCardHandler: true });
  };

  useAccessibilityClick(cardRef, clickHandler);

  useEffect(() => {
    if (lessonManager.isSelected(lessonElementId)) {
      cardRef.current.focus();
      setNoClick(true);
    }
  }, [lessonManager, lessonElementId]);

  const isActivityWithNoQuestions = (model.isActivity && lessonItem.lessonElementIds.length === 0);
  const isActivityNoItemNumbers = (model.isActivity &&
    lessonItem.lessonElementIds.length > 0 &&
    UtilsService.isNullOrEmpty(model.questionRange));

  const isSmallCard = (lessonManager.lessonOptions && lessonManager.lessonOptions.hasSmallGotoCards);
  const hasNumberedCards = (lessonManager.lessonOptions &&
      lessonManager.lessonOptions.hasNumberedGotoCards &&
      !isSmallCard) ||
    (lessonManager.lessonOptions &&
      !lessonManager.lessonOptions.smallCardQuestionNumber &&
      isSmallCard);

  const showHtmlComponent = (((model.isTestItem ||
        (model.isActivity && !isActivityNoItemNumbers && !isActivityWithNoQuestions) ||
        model.isTitlePage ||
        model.isSummaryPage) &&
    (isSmallCard)) ||
    (!isSmallCard));

  const showContentTypeIcon = (model && !model.isTestItem &&
    (!model.isActivity || isActivityNoItemNumbers || isActivityWithNoQuestions) &&
    !model.isTitlePage &&
    !model.isSummaryPage &&
    isSmallCard);

  const showTeacherInstructionIcon = (model && !isSmallCard && model.type === ContentType.TEACHER_INSTRUCTION.type);

  const showTeacherFeedbackIcon = (lessonManager.playerMode === LessonMode.REVIEW &&
    lessonElementState && lessonElementState.comment &&
    !UtilsService.isNullOrEmpty(lessonElementState.comment)
  );

  let display = '';

  if (model && !UtilsService.isNullOrEmpty(model.slideSummary) && !isSmallCard) {
    display = lessonService.getQuestionLabel(model);
  } else if (model && model.isTitlePage) {
    display = t('titlePageName');
  } else if (model && model.isSummaryPage) {
    display = t('summaryPageName');
  } else if (model && model.type === ContentType.DIFFERENTIATION_REASON.type) {
    display = (!UtilsService.isNullOrEmpty(model.slideSummary)) ? model.slideSummary : t('differentiationReasonName');
  } else if (model && model.isActivity && !isSmallCard) {
    display = lessonService.getQuestionLabel(model);
  } else if (showContentTypeIcon) {
    display = ContentType.getLabel(model.type);
  } else {
    display = lessonService.getQuestionLabel(model);
  }

  const strippedDisplayRolloverText = (!!display && !isSmallCard) ? UtilsService.stripHtmlTagsAdvanced(display) : undefined;

  return (
    <>
      {model && (
        <div ref={cardRef} aria-label={display}
          className={classNames({
            'goto-card': true,
            'selected': lessonManager.isSelected(lessonElementId),
            'is-dependent-locked': isLocked,
            'is-small-card': isSmallCard,
            'is-title-page': model.isTitlePage,
            'is-summary-page': model.isSummaryPage
          })}>
          {(showTeacherFeedbackIcon) && <ResourceIcon altText='Teacher Feedback' className='TeacherFeedback' maskOff={true} />}
          <div aria-hidden className='goto-card-inner' tabIndex='-1' title={strippedDisplayRolloverText}>
            {(flagged) && <img alt='' className='marked-for-review-icon' src={flaggedSvg} />}
            {(showContentTypeIcon) && <ResourceIcon altText={ContentType.getLabel(model.type)} className={model.type} />}
            {(showHtmlComponent) && <HtmlComponent className='goto-card-label' htmlStr={display} />}
            {(hasNumberedCards && isSmallCard) ? <div className={classNames('goto-card-line', { 'hide-me': showContentTypeIcon })} /> : ''}
            {(hasNumberedCards) ? <div className='goto-card-number-label' /> : ''}
            {(showTeacherInstructionIcon) && <ResourceIcon altText={ContentType.getLabel(ContentType.TEACHER_INSTRUCTION.label)} className={`${ContentType.TEACHER_INSTRUCTION.type} big-card`} />}
          </div>
        </div>
      )}
    </>
  );
});

export default GotoCard;
