import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { register } from '../../../i18n';
import HtmlComponent from '../HtmlComponent';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import Guideline from '../tools/Guideline';
import '../../../css/TeacherInstruction.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';

const t = register('GlobalQuestionLabels')

const TeacherInstruction = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const [blurred, setBlurred] = useState(true);
  const blurButtonRef = useRef(null);
  useAccessibilityClick(blurButtonRef, () => {
    setBlurred((blurred) => !blurred);
  });

  useStyleEvents(lessonElementId);

  useEffect(() => {
    // we want to check for false explicitly.  This is a new option might be undefined
    // in old lessons.
    if(lessonManager.lessonOptions.teacherInstructionBlured === false) {
      setBlurred(false)
    }
  }, [lessonManager.lessonOptions.teacherInstructionBlured]);

  const model = lessonManager.getSlideModel(lessonElementId);

  return (
    <div className='teacher-instruction-wrapper'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <button ref={blurButtonRef} className={`toggle-blur-button primary ${blurred ? 'blur-active' : 'blur-inactive'}`}>{blurred ? `${t('showTeacherInstruction')}` : `${t('hideTeacherInstruction')}`}</button>
      <div className={blurred ? 'blurred' : ''}>
        <HtmlComponent htmlStr={model.text} />
      </div>
    </div>
  );
});
export default TeacherInstruction;
