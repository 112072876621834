import React, { useEffect, useRef, useState } from 'react';

import { inRange } from 'lodash';

import Moveable from 'react-moveable';

import '../../../css/Tools.scss';

import utilsService from '../../services/UtilsService';

const CombinedRuler = () => {
  const [target, setTarget] = useState();
  // eslint-disable-next-line no-unused-vars

  const [frame, setFrame] = useState({
    translate: [0, 0],
    rotate: 0,
    transformOrigin: '50% 50%',
  });

  const moveableRef = useRef();

  // TODO we are currently not displaying the 'horizontal' and 'vertical' buttons for CombinedRuler
  // const verticalRef = useRef();
  // const horizontalRef = useRef();
  // useAccessibilityClick(verticalRef, (event) => {
  //   moveableRef.current.moveable.request('rotatable', { rotate: 90 }, true);
  // });
  // useAccessibilityClick(horizontalRef, (event) => {
  //   moveableRef.current.moveable.request('rotatable', { rotate: 0 }, true);
  // });

  // CF-4140: Added to fix bug that continued drag even after mouse click.
  const stopDrag = (_event) => {
    moveableRef.current.moveable.stopDrag();
  };

  useEffect(() => {
    setTarget(document.querySelector('.combinedRulerTarget'));
    document.addEventListener('mouseup', stopDrag);
    return () => {
      document.removeEventListener('mouseup', stopDrag);
    };
  }, []);

  const handleCombinedRulerRotateEnd = (event = {}) => {
    if (typeof event?.lastEvent?.rotate !== 'number' || !event?.moveable?.request) {
      return;
    }
    const lastEventRotation = event?.lastEvent?.rotate;

    // determine if nearby a defined 'snap' angle. if so, snap ruler into that position
    const SNAP_ANGLES = [0, 90, 180, 270, 360];
    // note: for a wider variety of 'snap' angles, we can also use the `COMMON_TRIGONOMETRY_ANGLES` array
    // but for now, we are limiting 'snap' angles to the values within `SNAP_ANGLES`
    if (SNAP_ANGLES.includes(Math.round(Math.abs(frame.rotate)))) {
      // prevent infinite loop if `frame.rotate` is equal to a 'snap' angle
      // no need to determine if nearby a 'snap' angle when already equal to one
      return;
    }

    let adjustedRotationValue = utilsService.subtractFullCircleRotations(lastEventRotation);

    const ANGLE_RANGE_OFFSET = 5;
    let nearbySnapAngle = SNAP_ANGLES.find((snapAngle) => {
      const startRange = snapAngle - ANGLE_RANGE_OFFSET;
      const endRange = snapAngle + ANGLE_RANGE_OFFSET;
      return inRange(Math.abs(adjustedRotationValue), startRange, endRange);
    });

    nearbySnapAngle = nearbySnapAngle && adjustedRotationValue < 0 ? -nearbySnapAngle : nearbySnapAngle;

    adjustedRotationValue = typeof nearbySnapAngle === 'number' ? nearbySnapAngle : adjustedRotationValue;

    // manually change the moveable & state 'rotate' values after setting a small delay
    // i.e. to prevent conflicts, ensure the event-driven 'rotatable' process has ended before changing the rotation manually
    setTimeout(() => {
      const isInstant = true;
      // manually trigger a rerender to apply the adjusted rotation
      const requester = event.moveable.request('rotatable', { rotate: nearbySnapAngle }, isInstant);
      setFrame({
        ...frame,
        rotate: adjustedRotationValue
      });
      requester.requestEnd();
    }, 300);
  };

  return (
    <div className='combinedRulerContainer'>
      <div className='combinedRulerTarget' id='combinedRulerTool'>
        {/* TODO we are currently not displaying the 'horizontal' and 'vertical' buttons for CombinedRuler */}
        {/* <button ref={horizontalRef} id='combinedRulerToolSnapHorizontalButton'></button>
        <button ref={verticalRef} id='combinedRulerToolSnapVerticalButton'></button> */}
        {/* <button ref={rulerPostionChangerRef} style={{ visibility: 'hidden' }} /> */}
        <div id='combinedRulerToolRotator'>
          <div id='combinedRulerToolRotateHandle' />
        </div>
      </div>
      <Moveable
        ref={moveableRef}
        draggable={true}
        onDrag={(event) => {
          frame.translate = event.beforeTranslate;
        }}
        onDragOrigin={(event) => {
          frame.translate = event.drag.beforeTranslate;
          frame.transformOrigin = event.transformOrigin;
        }}
        onDragOriginStart={(event) => {
          event.dragStart && event.dragStart.set(frame.translate);
        }}
        onDragStart={(event) => {
          event.set(frame.translate);
        }}
        onRender={(event) => {
          const { translate, rotate, transformOrigin } = frame;
          event.target.style.transformOrigin = transformOrigin;
          event.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
            + ` rotate(${rotate}deg)`;
        }}
        onRotate={(event) => {
          const adjustedRotationValue = utilsService.subtractFullCircleRotations(event.beforeRotate);
          frame.rotate = adjustedRotationValue;
        }}
        onRotateEnd={(event) => {
          handleCombinedRulerRotateEnd(event);
        }}
        onRotateStart={(_event) => {
          // TODO remove
          // event.set(frame.rotate);
        }}
        origin={false}
        originDraggable={true}
        originRelative={true}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        rotatable={true}
        rotationPosition='left'
        startDragRotate={0}
        target={target}
        throttleDrag={0}
        throttleDragRotate={0}
        throttleRotate={0}
        zoom={1}
      />
    </div>
  );
};

export default CombinedRuler;
