import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import $ from 'jquery';
import Rangy from 'rangy';
import 'rangy/lib/rangy-textrange';
import _ from 'lodash';

import '../../../css/TextHighlight.scss';
import { LessonMode } from '../../../Constants';

import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';

import { fixCharacterSelectionRange } from '../../services/HighlightAnnotationService';

import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const TextHighlight = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const bodyRef = useRef();

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  const htmlElementBodyId = `${model.id}_body`;

  const showResponseRanges = function () {
    $_unwrapRanges();
    if (lessonElementState.currentResponseAnswer.ranges) {
      for (const range of lessonElementState.currentResponseAnswer.ranges) {
        if (typeof range == 'undefined') {
          return;
        }

        if (bodyRef.current) {
          const r = Rangy.createRange();
          r.selectCharacters(bodyRef.current, range.startIndex, range.endIndex);
          fixCharacterSelectionRange(r);
          Rangy.getSelection().setSingleRange(r);

          $_showRangeSpan(range.id, r, () => {

            // alert('error');
            // TODO This was added because if there was an error with showRangeSpan, the currentResponseAnswer.ranges would still include the range with an error. So the user wouldn't be able to see what they highlighted, but it would still show up as incorrect if they submitted there answer. We are just filtering out the range with an error and setCurrerntResponse to the correct format.
            // const filteredRanges = lessonElementState.currentResponseAnswer.ranges.filter(filteredRange => filteredRange.id !== range.id);
            // lessonElementState.setCurrentResponse(utilsService.safeMobxClone({ lessonElementId: model.lessonElementId, ranges: filteredRanges }));
          });
        }
      }
    }
  };

  const showCorrectAnswerRanges = function () {
    $_unwrapRanges();

    if (model.validation?.ranges) {
      for (const range of model.validation.ranges) {
        if (typeof range == 'undefined') {
          return;
        }

        if (bodyRef.current) {
          const r = Rangy.createRange();
          r.selectCharacters(bodyRef.current, range.startIndex, range.endIndex);
          fixCharacterSelectionRange(r);
          Rangy.getSelection().setSingleRange(r);

          $_showRangeSpan(range.id, r, () => {
            // alert('error');
          });
        }
      }
    }
    const $body = $(`#${ htmlElementBodyId}`);
    $body.find('.range').addClass('correct');
  };

  useStyleEvents(lessonElementId);

  useEffect(() => {
    const element = document.getElementById(`${lessonElementId}-standalone-wrapper`);

    const handleMouseUp = function (e) {
      // Redeclaring the lessonElementId because if there are multiple text highlight questions sometimes
      // a previous text highlight questions lessonElementId is being used instead of the current lessonElementId
      const lessonElementId = lessonManager.currentLessonElementId;
      // If the element doesn't have the class 'TextHighlight' we don't want to run this mouseup function.
      // There is a weird issue where this event listener is getting added to every stand alone wrapper.
      // This is hopefully a temporary fix until we figure out why its doing that.
      if (element && element.className.indexOf('TextHighlight') < 0) {
        return;
      }
      if (lessonElementState.isSubmitted) {
        return;
      }
      e = e || window.event;
      const el = e.target;
      // If selection is within an existing $range, then controls will show automatically
      const rangeSpan = el.closest('.range');
      if (rangeSpan && rangeSpan.innerHTML.length > 0) {
        const rangeId = rangeSpan.getAttribute('data-id');
        const $rangeSpan = $(`[data-id="${rangeId}"]`);
        $rangeSpan.contents().unwrap();

        responseService.responseRemoveHandler({ rangeId }, lessonElementId);
        return;
      }

      let selectedText = Rangy.getSelection().toString();
      selectedText = utilsService.stripTagsAndEntities(selectedText);
      selectedText = selectedText.trim();

      // If nothing selected, just return.
      if (selectedText.length <= 1 || !bodyRef || !bodyRef.current) {
        return;
      }

      const selection = Rangy.getSelection();
      if (!bodyRef.current.contains(selection.anchorNode.parentElement)) {
        window.getSelection().removeAllRanges();
        return;
      }

      selection.expand('word');
      selection.trim();
      const r = selection.getRangeAt(0);
      fixCharacterSelectionRange(r);

      const characterRange = r.toCharacterRange(bodyRef.current);
      const startIndex = characterRange.start;
      const endIndex = characterRange.end;
      const text = Rangy.getSelection().toString();
      const id = utilsService.createGuid();

      const newRange = {
        id,
        startIndex,
        endIndex,
        text
      };

      if (text.trim().length > 0) {
        responseService.responseChangeHandler(newRange, lessonElementId);
        // showResponseRanges();
      }
    };

    if (element) {
      element.addEventListener('mouseup', handleMouseUp);
    }

    if (lessonElementState.currentResponseAnswer.ranges) {
      showResponseRanges();

      const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
      const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;

      if (feedbackState && showAnswerFeedback) {
        $_showValidationFeedback();
      }
    }

    return () => {
      const element = document.getElementById(`${lessonElementId}-standalone-wrapper`);
      if (element) {
        element.removeEventListener('mouseup', handleMouseUp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonManager.currentLessonElementId, toolbarManager.isToolbarAnswerFeedbackActive]);

  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
  const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;
  const showAnswers = (feedbackState) ? feedbackState.showAnswers : false;

  if (lessonElementState.currentResponseAnswer.ranges) {
    showResponseRanges();

    if (feedbackState && showAnswerFeedback) {
      $_showValidationFeedback();
    }
  }

  if (feedbackState && showAnswers) {
    showCorrectAnswerRanges();
  }

  return (
    <>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div ref={bodyRef} className='textBody' id={htmlElementBodyId}>
        <HtmlComponent htmlStr={model.body} />
      </div>
    </>
  );

  // To be evaluated or replaced as neccessary.
  // START JQuery functions
  function $_unwrapRanges() {
    const $container = $(`#${ htmlElementBodyId}`);
    $container.find('span').contents().unwrap();
  }

  function $_showRangeSpan(id, r, errorCallback) {
    if (r) {
      const el = document.createElement('span');

      try {
        r.surroundContents(el);
        const $selection = $(el);

        if ($selection.closest('.range').length === 0 && $.trim($selection.text()).length > 0) {
          $selection.attr('data-id', id);
          $selection.data('id', id);
          $selection.addClass('range');
        }
      } catch (error) {
        if ((error instanceof Rangy.DOMException || Object.prototype.toString.call(error) === '[object DOMException]') && error.code === 1) {
          console.error(error);
        } else {
          if (errorCallback) {
            errorCallback();
          }
        }
      }
    }
  }

  function $_showValidationFeedback() {
    const $body = $(`#${ htmlElementBodyId}`);

    const correctRanges = model.validation?.ranges || [];
    const studentRanges = lessonElementState.currentResponseAnswer?.ranges || [];

    $body.find('.range').addClass('incorrect');

    if (studentRanges) {
      $.each(studentRanges, (index, studentRange) => {
        const correctRange = _.find(correctRanges, { startIndex: studentRange.startIndex });
        if (correctRange && correctRange.endIndex === studentRange.endIndex) {
          const $rangeSpan = utilsService.$_getElement(studentRange.id, $body);
          $rangeSpan.removeClass('incorrect').addClass('correct');
        }
      });

      $.each($_getComboStudentRanges(), (index, comboRange) => {
        const correctRange = _.find(correctRanges, { startIndex: comboRange.startIndex });
        if (correctRange && correctRange.endIndex === comboRange.endIndex) {
          $.each(comboRange.ids, (idIndex, id) => {
            const $rangeSpan = utilsService.$_getElement(id, $body);
            $rangeSpan.removeClass('incorrect').addClass('correct');
          });
        }
      });
    }
  }

  function $_getComboStudentRanges() {
    // let responseModel = this.getResponseModel();
    let studentRanges = lessonElementState.currentResponseAnswer.ranges;
    let currentComboRange = null;
    const comboStudentRanges = [];

    if (studentRanges) {
      studentRanges = _.sortBy(studentRanges, 'startIndex');

      $.each(studentRanges, (index, studentRange) => {
        if (currentComboRange) {
          if (studentRange.startIndex === (currentComboRange.endIndex + 1)) {
            currentComboRange.endIndex = studentRange.endIndex;
            currentComboRange.text = `${currentComboRange.text } ${ studentRange.text}`;
            currentComboRange.ids.push(studentRange.id);
            if (index === studentRanges.length - 1) {
              comboStudentRanges.push(currentComboRange);
            }
          } else {
            comboStudentRanges.push(currentComboRange);
            currentComboRange = _.clone(studentRange);
            currentComboRange.ids = [studentRange.id];
          }
        } else {
          currentComboRange = _.clone(studentRange);
          currentComboRange.ids = [studentRange.id];
        }
      });
    }

    // Remove comboStudentRanges that are a single responseRange.
    const filteredComboStudentRanges = [];
    $.each(comboStudentRanges, (index, comboRange) => {
      if (comboRange.ids.length > 1) {
        filteredComboStudentRanges.push(comboRange);
      }
    });

    return filteredComboStudentRanges;
  }
  // END JQuery functions
});
export default TextHighlight;
