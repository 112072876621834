import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import classNames from 'classnames';
import lessonService from '../lessonPlayer/services/LessonService';
import Section from './Section';

import Multichoice from './questions/Multichoice';
import Multiselect from './questions/Multiselect';
import ShortText from './questions/ShortText';
import LongText from './questions/LongText';
import Upload from './questions/Upload';
import Cloze from './questions/Cloze';
import ClozeCombo from './questions/ClozeCombo';
import ClozeDragDrop from './questions/ClozeDragDrop';
import ClozeMultichoice from './questions/ClozeMultichoice';
import ClozeMath from './questions/ClozeMath';
import Categorize from './questions/Categorize';
import ImageLabel from './questions/ImageLabel';
import ImageLabelMultichoice from './questions/ImageLabelMultichoice';
import ImageLabelDragDrop from './questions/ImageLabelDragDrop';
import Draw from './questions/Draw';
import ChoiceMatrix from './questions/ChoiceMatrix';
import ChoiceMatrixMulti from './questions/ChoiceMatrixMulti';
import TextSelector from './questions/TextSelector';
import TextHighlight from './questions/TextHighlight';
import HotText from './questions/HotText';
import OrderList from './questions/OrderList';
import OrderSentences from './questions/OrderSentences';
import ClozeSpelling from './questions/ClozeSpelling';
import ImageTextMatch from './questions/ImageTextMatch';
import ImageTextMatchFlip from './questions/ImageTextMatchFlip';
import ImageHotSpot from './questions/ImageHotSpot';
import NumberLine from './questions/NumberLine';
import DefaultQuestion from './questions/DefaultQuestion';
import Analogies from './graphicorganizers/Analogies';
import CategoryTree from './graphicorganizers/CategoryTree';
import DefaultGraphicOrganizer from './graphicorganizers/DefaultGraphicOrganizer';
import Presentation from './instructions/Presentation';
import StudentInstruction from './instructions/StudentInstruction';
import TeacherInstruction from './instructions/TeacherInstruction';
import WebPage from './instructions/WebPage';
import DefaultInstruction from './instructions/DefaultInstruction';
import Image from './resources/Image';
import DefaultResource from './resources/DefaultResource';
import MultipartActivity from './activities/MultipartActivity';
import VideoQuestion from './activities/VideoQuestion';
import AudioQuestion from './activities/AudioQuestion';
import ArticleQuestion from './activities/ArticleQuestion';
import ImageQuestion from './activities/ImageQuestion';
import BookmarkActivity from './activities/BookmarkActivity';
import DefaultActivity from './activities/DefaultActivity';
import Google from './google/Google';

const LessonElementList = ({ lessonElement }) => {
  const {
    userManager, lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  if (!lessonElement) {
    return null;
  }

  const model = lessonManager.contentMap[lessonElement.entityId];

  let Question;
  if (model && model.isQuestion) {
    switch (model.type) {
    case 'Multichoice':
      Question = Multichoice;
      break;
    case 'Multiselect':
      Question = Multiselect;
      break;
    case 'ShortText':
      Question = ShortText;
      break;
    case 'LongText':
      Question = LongText;
      break;
    case 'Upload':
      Question = Upload;
      break;
    case 'Cloze':
      Question = Cloze;
      break;
    case 'ClozeCombo':
      Question = ClozeCombo;
      break;
    case 'ClozeDragDrop':
      Question = ClozeDragDrop;
      break;
    case 'ClozeMultichoice':
      Question = ClozeMultichoice;
      break;
    case 'ClozeMath':
      Question = ClozeMath;
      break;
    case 'Categorize':
      Question = Categorize;
      break;
    case 'ImageLabel':
      Question = ImageLabel;
      break;
    case 'ImageLabelMultichoice':
      Question = ImageLabelMultichoice;
      break;
    case 'ImageLabelDragDrop':
      Question = ImageLabelDragDrop;
      break;
    case 'Draw':
      Question = Draw;
      break;
    case 'ChoiceMatrix':
      Question = ChoiceMatrix;
      break;
    case 'ChoiceMatrixMulti':
      Question = ChoiceMatrixMulti;
      break;
    case 'TextSelector':
      Question = TextSelector;
      break;
    case 'TextHighlight':
      Question = TextHighlight;
      break;
    case 'HotText':
      Question = HotText;
      break;
    case 'OrderList':
      Question = OrderList;
      break;
    case 'OrderSentences':
      Question = OrderSentences;
      break;
    case 'ClozeSpelling':
      Question = ClozeSpelling;
      break;
    case 'ImageTextMatch':
      Question = ImageTextMatch;
      break;
    case 'ImageTextMatchFlip':
      Question = ImageTextMatchFlip;
      break;
    case 'ImageHotSpot':
      Question = ImageHotSpot;
      break;
    case 'NumberLine':
      Question = NumberLine;
      break;
    default:
      Question = DefaultQuestion;
    }
  }

  let GraphicOrganizer;
  if (model && model.isGraphicOrganizer) {
    switch (model.type) {
    case 'Analogies':
      GraphicOrganizer = Analogies;
      break;
    case 'CategoryTree':
      GraphicOrganizer = CategoryTree;
      break;
    default:
      GraphicOrganizer = DefaultGraphicOrganizer;
    }
  }

  let Instruction;
  if (model && model.isInstruction) {
    switch (model.type) {
    case 'Presentation':
      Instruction = Presentation;
      break;
    case 'StudentInstruction':
      Instruction = StudentInstruction;
      break;
    case 'TeacherInstruction':
      Instruction = TeacherInstruction;
      break;
    case 'WebPage':
      Instruction = WebPage;
      break;
    default:
      Instruction = DefaultInstruction;
    }
  }

  let Resource;
  if (model && model.isResource) {
    switch (model.type) {
    case 'Image':
      Resource = Image;
      break;
    default:
      Resource = DefaultResource;
    }
  }

  let Activity;
  if (model && model.isActivity) {
    switch (model.type) {
    case 'MultipartActivity':
      Activity = MultipartActivity;
      break;
    case 'VideoQuestion':
      Activity = VideoQuestion;
      break;
    case 'AudioQuestion':
      Activity = AudioQuestion;
      break;
    case 'ArticleQuestion':
      Activity = ArticleQuestion;
      break;
    case 'ImageQuestion':
      Activity = ImageQuestion;
      break;
    case 'BookmarkActivity':
      Activity = BookmarkActivity;
      break;
    default:
      Activity = DefaultActivity;
    }
  }

  const sectionContent = JSON.parse(lessonElement.content);
  const isActivityPart = lessonService.isActivityPart(lessonElement.id);

  return (
    <>
      {lessonElement.type === 'Section' && (
        <div className='lesson-element'>
          <hr />
          <Section
            model={sectionContent}
            isManuscriptShort={lessonManager.isManuscriptShort}
          />
        </div>
      )}
      {model && model.isActivity && (
        <div className='lesson-element'>
          <hr />
          <div className='top-label'>Activity:</div>
          <Activity
            model={model}
            isManuscriptShort={lessonManager.isManuscriptShort}
          />
        </div>
      )}
      {model && model.isGraphicOrganizer && (
        <div className={classNames({ 'activity-part': isActivityPart, 'lesson-element': true })}>
          <hr />
          <div className='top-label'>Graphic Organizer:</div>
          <GraphicOrganizer
            model={model}
            isManuscriptShort={lessonManager.isManuscriptShort}
          />
        </div>
      )}
      {model && model.isQuestion && (
        <div className={classNames({ 'activity-part': isActivityPart, 'lesson-element': true })}>
          <hr />
          <div className='top-label'>Question:</div>
          <Question
            model={model}
            isManuscriptShort={lessonManager.isManuscriptShort}
          />
        </div>
      )}
      {model && model.isGoogle && (
        <div className={classNames({ 'activity-part': isActivityPart, 'lesson-element': true })}>
          <hr />
          <Google
            model={model}
          />
        </div>
      )}
      {model && model.isInstruction && (
        <div className={classNames({ 'activity-part': isActivityPart, 'lesson-element': true })}>
          <hr />
          <Instruction
            model={model}
            isManuscriptShort={lessonManager.isManuscriptShort}
          />
        </div>
      )}
      {model && model.isResource && (
        <div className={classNames({ 'activity-part': isActivityPart, 'lesson-element': true })}>
          <hr />
          <Resource
            model={model}
          />
        </div>
      )}
      {lessonElement.lessonElementIds.map((lessonElementId) => {
        const lessonElement = lessonManager.structureMap[lessonElementId];

        return (
          <LessonElementList
            key={lessonElementId}
            lessonElement={lessonElement}
          />
        );
      })}
    </>
  );
};
export default LessonElementList;
