import { toJS } from 'mobx';

import { LessonMode, QuestionFeedbackState } from '../../Constants';

import questionService from '../services/QuestionService';

import { Behavior } from './Behavior';
import UtilsService from '../services/UtilsService';

export class ChartGraphicOrganizerBehavior extends Behavior {
  checkForValidResponse = (responseAnswer, _model) => {
    const columns = toJS(responseAnswer?.columns || []);
    const hasSomeEmptyResponses = columns.some?.(({ text } = {}) => !text);
    return !hasSomeEmptyResponses;
  }

  isAutoScored = (_model) => {
    return false;
  }

  getScore = (_responseAnswer, _model) => {
    // return 1;
    // this content type is not autoscored

  }

  setResponseData = (data, responseAnswer) => {
    const { id, text } = data;
    responseAnswer.columns.find((column) => column.id === id).text = text;
  }

  getAnswerFeedback = (_id, _responseAnswer) => {
    return true;
  }

  getInitializedResponse = (lessonElementId, model, _activityId) => {
    const { columns } = model;
    const emptyAnswers = columns.map(({ id }) => ({ id, text: '' }));
    const responseAnswer = {
      ...questionService.generateInitializedQuestionResponse({
        isScored: false,
        isScoredCorrect: false,
        lessonElementId,
        columns: emptyAnswers
      })
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (_model) => {
    return '';
  }

  getQuestionFeedbackText = (_model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    const textHeader = '';

    // TODO unused; this content type is not autoscored so we do not have this feedback
    // if ( questionIsCorrect === QuestionFeedbackState.CORRECT ) {
    //   textHeader = correctFeedback;
    //   if (correctFeedbackBody) {
    //     textBody = correctFeedbackBody+' '+textBody;
    //   }
    // } else if ( questionIsCorrect ===  QuestionFeedbackState.PARTIAL_CORRECT ) {
    //   textHeader = `${UtilsService.partiallyCorrect()}`;
    //   let custom = '';
    //   if (incorrectFeedbackBody) {
    //     custom = incorrectFeedbackBody + ' ';
    //   }

    //   textBody = UtilsService.partiallyCorrectPrompt() + ' <br/>' + custom + textBody;

    // } else {
    //   textHeader = incorrectFeedback;
    //   if (incorrectFeedbackBody) {
    //     textBody = incorrectFeedbackBody+' '+textBody;
    //   }
    // }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (_answerId, _lessonElementState, _model) => {
    return false;
  }

  isQuestionCorrect = (lessonElementState, _lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }
    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }
    return QuestionFeedbackState.INCORRECT;
  }
}

export default new ChartGraphicOrganizerBehavior();
