import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from 'ckeditor5-custom-build';

import classNames from 'classnames';

import '../../css/WebSpellChecker.scss';

import {
  Accommodations,
  CKEDITOR_DEV_LICENSE_KEY,
  ContentType,
  WPROOFREADER_SOURCE_URL
} from '../../Constants';

import questionService from '../services/QuestionService';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';

import { register } from '../../i18n';

const t = register('Metadata');

export const MATH_ONLY_CKEDITOR_TOOLBAR =
  [
    'wproofreader', 'MathType'
  ];

export const MINIMUM_TOOLBAR =
  [
    'wproofreader', 'bold', 'italic', 'underline', 'bulletedList', 'specialCharacters'
  ];

export const MATH_TOOLBAR =
  [
    'wproofreader', 'bold', 'italic', 'underline', 'bulletedList', 'specialCharacters', 'subscript', 'superscript', 'MathType'
  ];

export const MATH_WITH_TABLE_TOOLBAR =
  [
    'wproofreader', 'bold', 'italic', 'underline', 'bulletedList', 'specialCharacters', 'subscript', 'superscript', 'insertTable', 'MathType'
  ];

export const ADVANCED_MATH_CKEDITOR_TOOLBAR = MATH_TOOLBAR;

export const BASIC_CKEDITOR_TOOLBAR =
  [
    'wproofreader', 'bold', 'italic', 'underline', 'bulletedList'
  ];

export const INFO_CKEDITOR_TOOLBAR =
  [
    'wproofreader',
    'bold',
    'italic',
    'underline',
    'bulletedList',
    'specialCharacters',
    'subscript',
    'superscript',
    'MathType'
  ];

export const ADVANCED_CKEDITOR_TOOLBAR =
  [
    'sourceEditing',
    '|',
    'findAndReplace',
    '|',
    'undo',
    'redo',
    '|',
    'removeFormat',
    '|',
    'outdent',
    'indent',
    'alignment',
    'numberedList',
    'bulletedList',
    '|',
    'textPartLanguage',
    '-',
    'wproofreader',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'subscript',
    'superscript',
    '|',
    'fontBackgroundColor',
    'fontColor',
    'fontFamily',
    'fontSize',
    '-',
    'heading',
    '|',
    'highlight',
    'blockQuote',
    'insertTable',
    'link',
    '|',
    'specialCharacters',
    'MathType',
    'ChemType'
  ];

const defaultStyleSet = [ // eslint-disable-line no-unused-vars
  // Block-level styles
  // { name: '- No Style -', element: 'div', attributes: { 'class': '' } },
  // { name: '- No Table Style -', element: 'table', attributes: { 'class': '' } },

  { name: 'LevelOHead', element: 'div', attributes: { class: 'LevelOHead' } },
  { name: 'LevelAHead', element: 'div', attributes: { class: 'LevelAHead' } },
  { name: 'LevelBHead', element: 'div', attributes: { class: 'LevelBHead' } },
  { name: 'LevelBHead_RI', element: 'span', attributes: { class: 'LevelBHead_RI' } },

  { name: 'FeatBoxVert', element: 'div', attributes: { class: 'FeatBoxVert parentEl' } },
  { name: 'FeatBoxHoriz', element: 'div', attributes: { class: 'FeatBoxHoriz parentEl' } },
  { name: 'FeatBoxHead', element: 'div', attributes: { class: 'FeatBoxHead' } },

  { name: 'PassTitle', element: 'div', attributes: { class: 'PassTitle' } },
  { name: 'PassSubTitle', element: 'div', attributes: { class: 'PassSubTitle' } },

  { name: 'ProseText', element: 'div', attributes: { class: 'ProseText' } },
  { name: 'ProseText Numbered', element: 'div', attributes: { class: 'ProseText Numbered' } },
  { name: 'ProseText Indent', element: 'div', attributes: { class: 'ProseText Indent' } },
  { name: 'ProseText Indent Numbered', element: 'div', attributes: { class: 'ProseText Indent Numbered' } },

  { name: 'HeadAuth', element: 'div', attributes: { class: 'HeadAuth' } },
  { name: 'PoemText', element: 'div', attributes: { class: 'PoemText' } },
  { name: 'PoemText Numbered', element: 'div', attributes: { class: 'PoemText Numbered' } },
  { name: 'PoemText Indent', element: 'div', attributes: { class: 'PoemText Indent' } },
  { name: 'PoemText Indent Numbered', element: 'div', attributes: { class: 'PoemText Indent Numbered' } },
  { name: 'PoemText - Stanza break', element: 'div', attributes: { class: 'StanzaBreak' } },

  { name: 'BoxText100', element: 'div', attributes: { class: 'BoxText100 parentEl' } },
  { name: 'BoxText75', element: 'div', attributes: { class: 'BoxText75 parentEl' } },
  { name: 'BoxText75 BoxRight', element: 'div', attributes: { class: 'BoxText75 BoxRight parentEl' } },
  { name: 'BoxText50', element: 'div', attributes: { class: 'BoxText50 parentEl' } },
  { name: 'BoxText50 BoxRight', element: 'div', attributes: { class: 'BoxText50 BoxRight parentEl' } },
  // { name: 'BoxHeading', element: 'div', attributes: { 'class': 'BoxHeading' }},
  // { name: 'BoxContent', element: 'p', attributes: { 'class': 'BoxContent' }},
  // { name: 'ClearBoth', element: 'p', attributes: { 'class': 'ClearBoth' }},

  { name: 'Callout', element: 'div', attributes: { class: 'Callout' } },
  { name: 'StandBox', element: 'div', attributes: { class: 'StandBox' } },
  { name: 'Caption', element: 'div', attributes: { class: 'Caption' } },
  { name: 'ImageFloat', element: 'div', attributes: { class: 'ImageFloat' } },
  // { name: 'Source', element: 'div', attributes: { 'class': 'Source' }},
  { name: 'PassSyn', element: 'div', attributes: { class: 'PassSyn' } },
  { name: 'Footnote', element: 'div', attributes: { class: 'Footnote' } },

  { name: 'TeachNote', element: 'div', attributes: { class: 'TeachNote' } },
  // { name: 'CloseReadHead', element: 'div', attributes: { 'class': 'CloseReadHead' }},
  { name: 'InstrxHead', element: 'div', attributes: { class: 'InstrxHead' } },
  // { name: 'CloseRead', element: 'p', attributes: { 'class': 'CloseRead' }},
  { name: 'Instrx', element: 'p', attributes: { class: 'Instrx' } },
  { name: 'Hints', element: 'div', attributes: { class: 'Hints' } },

  // { name: 'Math6+', element: 'div', attributes: { 'class': 'Math6Plus' }},
  // { name: 'TextBold6+', element: 'div', attributes: { 'class': 'TextBold6Plus' }},
  // { name: 'TextItal6+', element: 'div', attributes: { 'class': 'TextItal6Plus' }},

  // { name: 'Tbl_math', element: 'table', attributes: { 'class': 'Tbl_math' } },
  // { name: 'Tbl_teal', element: 'table', attributes: { 'class': 'Tbl_teal' } },
  // { name: 'TblHead', element: 'td', attributes: { 'class': 'TblHead' } },

  { name: 'TblTitle', element: 'div', attributes: { class: 'TblTitle' } },
  { name: 'RotateText', element: 'div', attributes: { class: 'RotateText' } },
  { name: 'RotateTextRight', element: 'div', attributes: { class: 'RotateTextRight' } },
  { name: 'LineHeight133', element: 'div', attributes: { class: 'LineHeight133' } },

  // Inline styles
  { name: 'TeachHead', element: 'span', attributes: { class: 'TeachHead' } },
  { name: 'CLRunIn', element: 'span', attributes: { class: 'CLRunIn' } },
  { name: 'HintsRI', element: 'span', attributes: { class: 'HintsRI' } },
  { name: 'TextRI', element: 'span', attributes: { class: 'TextRI' } },
  { name: 'TextRI_caps', element: 'span', attributes: { class: 'TextRI_caps' } },
  { name: 'Highlight', element: 'span', attributes: { class: 'Highlight' } },
  { name: 'LozRI_teal', element: 'span', attributes: { class: 'LozRI_teal' } },
  { name: 'LozRI_blue', element: 'span', attributes: { class: 'LozRI_blue' } },
  { name: 'LozRI_red', element: 'span', attributes: { class: 'LozRI_red' } },
  { name: 'LozRI_green', element: 'span', attributes: { class: 'LozRI_green' } },
  { name: 'LozRI_vio', element: 'span', attributes: { class: 'LozRI_vio' } },
  { name: 'StandardPill', element: 'span', attributes: { class: 'StandardPill' } },
  { name: 'SectionPill', element: 'span', attributes: { class: 'SectionPill' } },
  { name: 'ExSteps', element: 'span', attributes: { class: 'ExSteps' } },
  { name: 'NoWrap', element: 'span', attributes: { class: 'NoWrap' } },
  { name: 'ChapterPill', element: 'span', attributes: { class: 'ChapterPill' } },
  { name: 'ModuleNum', element: 'span', attributes: { class: 'ModuleNum' } }
];

function isSelectAllScope(schema, element) { // eslint-disable-line no-unused-vars
  return schema.isLimit(element) && (schema.checkChild(element, 'mathtype') || schema.checkChild(element, 'paragraph'));
}

const RichTextEditor = observer((props = {}) => {
  const {
    lessonManager, publisherManager, toolbarManager
  } = useContext(MobXProviderContext);

  const [characters, setCharacters] = useState(0);
  const [words, setWords] = useState(0);

  const { maxCharacters, maxWords } = props;

  // NOTE - webSpellCheckerId is a serviceId that is provided by WebSpellChecker (author of WProofReader plugin)
  // We set `webSpellCheckerId` as a superadmin for given publishers (e.g. Sirius) who have purchased access to the plugin
  // See https://evotext.atlassian.net/browse/SIR-798 for more info
  const { hasWebSpellCheckerId, webSpellCheckerId } = publisherManager;

  const themeAndTranslationsLoaded = lessonManager.themeLoaded && lessonManager.translationsLoaded;

  let hasApplicableSpellcheckerAccommodation;
  if (hasWebSpellCheckerId) {
    const hasAssessmentSpellcheckerAccommodation = (
      !toolbarManager.activityAccommodationsOff.includes(Accommodations.ASSESSMENT_SPELLCHECKER) &&
      !toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_SPELLCHECKER)
    );
    const hasLessonSpellcheckerAccommodation = (
      !toolbarManager.activityAccommodationsOff.includes(Accommodations.LESSON_SPELLCHECKER) &&
      !toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_SPELLCHECKER)
    );

    if (lessonManager.isAssessment) {
      hasApplicableSpellcheckerAccommodation = hasAssessmentSpellcheckerAccommodation;
    } else if (lessonManager.isLesson) {
      hasApplicableSpellcheckerAccommodation = hasLessonSpellcheckerAccommodation;
    } else {
      hasApplicableSpellcheckerAccommodation = hasAssessmentSpellcheckerAccommodation || hasLessonSpellcheckerAccommodation;
    }
  }

  const excludeSpellchecker = !themeAndTranslationsLoaded || !hasApplicableSpellcheckerAccommodation || !hasWebSpellCheckerId;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key.startsWith('Arrow')) {
        event.stopImmediatePropagation();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const theEditor = null;
  const launchMathIfApplicable = () => {
    if (theEditor) {
      if (!props.skipLaunchMathImmediately && props.toolbar === MATH_ONLY_CKEDITOR_TOOLBAR) {
        // this is done in a time out with no wait.
        // this is a trick to force this to occur on the next render loop
        // it ensures the editor internal states have been updated
        // and set before select all happens.
        setTimeout(() => {
          theEditor.execute('selectAll');
          theEditor.execute('MathType', { integration: window.WirisPlugin.currentInstance });
        }, 0);
      }
    }
  };
  const clickRef = useRef();
  useAccessibilityClick(clickRef, (_event) => {
    setCurrentLessonElement();
    launchMathIfApplicable();
  });

  const setCurrentLessonElement = async (event) => {
    if (props.lessonElementId) {
      const model = lessonManager.getSlideModel(props.lessonElementId);
      if (model.isActivityPart) {
        const parentModel = lessonManager.getSlideModel(model.parentActivityElementId);
        if ((parentModel && parentModel.showAllChildren)
          || (parentModel && parentModel.type === ContentType.MULTIPART_ACTIVITY.type) ||
          model.inGroup) {
          if (model.isActivityPart) {
            await questionService.questionChildEventListener(event, props.lessonElementId);
          }
        }
      }
    }
  };

  // Yeah. There are 3 configs in here, not a fan... but seemingly neccessary for the time being!
  const config_advanced = {
    wordCount: {
      maxCharacters,
      maxWords,
      onUpdate: (counts) => {
        setWords(counts.words);
        setCharacters(counts.characters);
      }
    },
    mathTypeParameters: {
      editorParameters: { language: 'en' }
    },
    typing: {
      transformations: {
        remove: ['oneHalf', 'oneThird', 'twoThirds', 'oneForth', 'threeQuarters'],
        extra: [
          { from: /\b(1\/2)\b/, to: '½' },
          { from: /\b(1\/3)\b/, to: '⅓' },
          { from: /\b(2\/3)\b/, to: '⅔' },
          { from: /\b(1\/4)\b/, to: '¼' },
          { from: /\b(3\/4)\b/, to: '¾' }

        ]
      }
    },
    placeholder: (props.placeHolder) ? props.placeHolder : ''
  };

  const config_basic = {
    wordCount: {
      maxCharacters,
      maxWords,
      onUpdate: (counts) => {
        setWords(counts.words);
        setCharacters(counts.characters);
      }
    },
    mathTypeParameters: {
      editorParameters: { language: 'en', toolbar: 'Grade3_5' } // MathType config, including language
    },
    typing: {
      transformations: {
        remove: ['oneHalf', 'oneThird', 'twoThirds', 'oneForth', 'threeQuarters'],
        extra: [
          { from: /\b(1\/2)\b/, to: '½' },
          { from: /\b(1\/3)\b/, to: '⅓' },
          { from: /\b(2\/3)\b/, to: '⅔' },
          { from: /\b(1\/4)\b/, to: '¼' },
          { from: /\b(3\/4)\b/, to: '¾' }

        ]
      }
    },
    placeholder: (props.placeHolder) ? props.placeHolder : ''
  };

  const config_full = {
    wordCount: {
      maxCharacters,
      maxWords,
      onUpdate: (counts) => {
        setWords(counts.words);
        setCharacters(counts.characters);
      }
    },
    mathTypeParameters: {
      editorParameters: { language: 'en', toolbar: 'Grade6_8' } // MathType config, including language
    },
    typing: {
      transformations: {
        remove: ['oneHalf', 'oneThird', 'twoThirds', 'oneForth', 'threeQuarters'],
        extra: [
          { from: /\b(1\/2)\b/, to: '½' },
          { from: /\b(1\/3)\b/, to: '⅓' },
          { from: /\b(2\/3)\b/, to: '⅔' },
          { from: /\b(1\/4)\b/, to: '¼' },
          { from: /\b(3\/4)\b/, to: '¾' }

        ]
      }
    },
    placeholder: (props.placeHolder) ? props.placeHolder : ''
  };

  let config = {};

  if (lessonManager && lessonManager.lessonOptions && lessonManager.lessonOptions.studentMathToolbar) {
    if (lessonManager.lessonOptions.studentMathToolbar === 'basic') {
      config = config_basic;
    } else if (lessonManager.lessonOptions.studentMathToolbar === 'full') {
      config = config_full;
    } else {
      config = config_advanced;
    }
  } else {
    config = config_advanced;
  }

  config.removePlugins = [];

  if (!props.markdown) {
    // TODO remove // config.removePlugins = ['Markdown'];
    config.removePlugins.push('Markdown');
  }

  if (props.forceNoToolbar) {
    config.toolbar = undefined;
  } else if (props.toolbar) {
    if (props.toolbar !== ADVANCED_CKEDITOR_TOOLBAR) {
      config.toolbar = props.toolbar;
    }
  } else {
    config.toolbar = MINIMUM_TOOLBAR;
  }
  config.lessonElementId = (props.lessonElementId) ? props.lessonElementId : '';

  config.licenseKey = CKEDITOR_DEV_LICENSE_KEY;

  // disable Spellchecker (WProofreader) plugin if flagged as not allowed, else apply the `wproofreader` config
  if (excludeSpellchecker) {
    if (!config.removePlugins.includes('WProofreader')) {
      // WebSpellChecker is disabled, so ensure 'wproofreader' plugin is removed (and excluded from the toolbar)
      config.removePlugins.push('WProofreader');
    }
    if (Array.isArray(config.toolbar)) {
      const modifiedToolbar = config.toolbar.filter((toolName) => {
        return toolName !== 'wproofreader';
      });
      config.toolbar = modifiedToolbar;
    }
  } else {
    // ensure we are setting the proper language for WebSpellChecker (wproofreader)
    // i.e. English by default, but will set to language defined in theme translation file (if present)
    localStorage.removeItem('wsc_lang');
    let languageCode = t('languageCode', '') || 'en_US';
    if (languageCode === 'es-mx') {
      languageCode = 'es_MX';
    }
    config.wproofreader = {
      // disableOptionsStorage: ['all'],
      disableOptionsStorage: [
        'autocomplete',
        'autocorrect',
        'grammarSuggestions',
        'ignoreAllCapsWords',
        'ignoreWordsWithMixedCases',
        'ignoreWordsWithNumbers',
        'lang',
        'styleGuideSuggestions'
        // 'ignoreDomainNames',
        // 'spellingSuggestions'
      ],
      aiWritingAssistant: false,
      autocomplete: false,
      autocorrect: false,
      grammarSuggestions: false,
      ignoreAllCapsWords: false,
      ignoreDomainNames: true,
      ignoreWordsWithMixedCases: false,
      ignoreWordsWithNumbers: false,
      lang: languageCode,
      removeBranding: true,
      serviceId: webSpellCheckerId || '',
      spellingSuggestions: true,
      srcUrl: WPROOFREADER_SOURCE_URL,
      styleGuideSuggestions: false
    };
  }

  const key = JSON.stringify(config); // Makes sure the editor is remounted if the config changes.
  return (
    <>
      <div ref={clickRef}
        className={classNames('ckeditor', props?.additionalClassNames)}
        style={{
          height: props?.height,
          width: props?.width,
          ...(props.additionalStyles || {})
        }}
        tabIndex={typeof props.tabIndex === 'number' ? props.tabIndex : 0}>
        <CKEditor
          key={key}
          config={config}
          data={props.data}
          disabled={props.disabled || false}
          editor={InlineEditor}
          onBlur={(event, editor) => {
            if (props.onBlur) {
              const data = editor.getData();
              props.onBlur(data, { event, editor });
            }
          }}
          onChange={(event, editor) => {
            if (props.onChange) {
              const data = editor.getData();
              props.onChange(data, {
                event, editor
              });
            }
          }}
          onFocus={(event, editor) => {
            if (props.onFocus) {
              props.onFocus(event, editor);
            }
          }}
          onReady={(editor) => {
            if (props.forceNoToolbar) {
              // remove ckeditor toolbar elements from DOM
              const ckBodyWrapper = document.querySelector('.ck-body-wrapper');
              const ckToolbarElements = Array.from(ckBodyWrapper.querySelectorAll('.ck-body') || []);
              ckToolbarElements.forEach((ckToolbarElement) => ckToolbarElement.remove());
            }
            if (props.onInit) {
              props.onReady(editor);
            }
            if (props.readOnly) {
              // TODO remove // editor.isReadOnly = true;
              editor.enableReadOnlyMode('our-editor-id');
            } else {
              editor.disableReadOnlyMode('our-editor-id');
            }
          }} />

      </div>
      <div className='character-count'>
        {!!maxCharacters && <span> Characters: {characters} / {maxCharacters}</span>}
        {!!maxWords && <span> Words: {words} / {maxWords}</span>}
      </div>
    </>
  );
});

export default RichTextEditor;
