import classNames from 'classnames';
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { ContentType } from '../../Constants';
import HtmlComponent from './HtmlComponent';
import PrintQuestionNumber from './lessonItems/PrintQuestionNumber';
import StandardsFooter from './lessonItems/StandardsFooter';

import StandAlonePrintViewer from './StandAlonePrintViewer';

const PrintQuestionListViewer = observer(({ lessonElementIds, directions }) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  return (
    <>
      <div className='question-list-wrapper' id='printable-question-list'>
        {
          lessonElementIds.map((childElementId, index) => {
            const isFirstChild = index === 0;
            const model = lessonManager.getSlideModel(childElementId);
            const isActivity = model.type === ContentType.MULTIPART_ACTIVITY.type;
            let isLastActivityChild = false;

            if (model.isActivityPart) {
              const parentLessonElement = lessonManager.getLessonElement(model.parentActivityElementId);
              const index = parentLessonElement.lessonElementIds.indexOf(model.lessonElementId);
              isLastActivityChild = (index === (parentLessonElement.lessonElementIds.length - 1));
            }

            return (
              <div key={`${childElementId}-${index}`} className={classNames({ 'printable-multipart': isActivity })}>
                {(isActivity) ?
                  (
                    <div className='multipart-directions' id={`${childElementId}-view-area`}>
                      <PrintQuestionNumber model={model} />
                      <HtmlComponent htmlStr={model.directions} />
                    </div>
                  ) :
                  (
                    <StandAlonePrintViewer
                      isFirstChild={isFirstChild}
                      isLastActivityChild={isLastActivityChild}
                      isMultipart={model.isActivityPart}
                      lessonElementId={childElementId}
                      showQuestionBanner={false} />
                  )}
                {(isLastActivityChild && model.parentActivityElementId) && <StandardsFooter key={`${model.parentActivityElementId}_print_stands`} lessonElementId={model.parentActivityElementId} />}
              </div>
            );
          })
        }
      </div>
    </>
  );
});
export default PrintQuestionListViewer;
