import React, { useContext, useEffect, useMemo, useState } from 'react';

import ReactModal from 'react-modal-resizable-draggable';

import { MobXProviderContext, observer } from 'mobx-react';
import Select, { components } from 'react-select';

import ReactTooltip from 'react-tooltip';

import '../../../css/Draw.scss';

import classNames from 'classnames';
import { register } from '../../../i18n';

import Auth from '../../services/AuthService';
import DrawService from '../../services/DrawService';

import styleService from '../../services/StyleService';
import utilsService from '../../services/UtilsService';

import ColorPicker from './ColorPicker';
import RichTextEditor from '../RichTextEditor';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';

const t = register('ToolNames');
const t2 = register('GlobalQuestionLabels');
const t3 = register('AriaLabels');

const { ValueContainer, Placeholder, SingleValue } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        return child && child.type !== Placeholder && child.type !== SingleValue ? child : null;
      })}
    </ValueContainer>
  );
};

const brushSizeOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const brushSizeCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    cursor: 'pointer',
    padding: '5px 0px',
    margin: 0
  }),
  control: (_provided, _state) => ({
    // ...provided,
    width: 30,
    height: 20,
    padding: 0,
    margin: 0,
    outline: 'none'
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    padding: 0,
    marginTop: '-2px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100
  }),
  input: () => ({
    color: 'transparent',
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    marginTop: '-2px',
    padding: 0
  })
};

const shapeCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    cursor: 'pointer',
    padding: '4px 0px',
    margin: '0px -2px',
  }),
  control: (_provided, _state) => ({
    // ...provided,
    height: 20,
    margin: 0,
    padding: 0
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
  input: () => ({
    color: 'transparent',
  }),
  placeholder: (_provided, _state) => ({
    // ...provided,
    width: 23,
    marginTop: '-2px',
    padding: 0,
  }),
  valueContainer: (provided, _state) => ({
    ...provided,
    width: 33
  })
};

const lineCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    cursor: 'pointer',
    margin: 0,
    padding: '3px 0px',
  }),
  control: (_provided, _state) => ({
    // ...provided,
    height: 20,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    width: 51,
    padding: '0px 3px'
  }),
  input: () => ({
    color: 'transparent',
  }),
  placeholder: (_provided, _state) => ({
    // ...provided,
    padding: 0,
    margin: 0,
    width: 48,
    marginTop: '-2px'
  }),
  valueContainer: (provided, _state) => ({
    ...provided,
    width: 64
  })
};

const pointCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    cursor: 'pointer',
    margin: 0,
    padding: '4px 0px',
  }),
  control: (_provided, _state) => ({
    // ...provided,
    height: 20,
    padding: 0,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
  input: () => ({
    color: 'transparent',
  }),
  placeholder: (_provided, _state) => ({
    // ...provided,
    padding: 0,
    width: 32,
    marginTop: '-2px'
  })
};

const brushSizePlaceholder = <div className='brushSizeToolButton drawToolbarButton' />;
const shapePlaceholder = <div className='shapeToolButton drawToolbarButton' />;
const linePlaceholder = <div className='lineToolButton drawToolbarButton' />;
const pointPlaceholder = <div className='pointToolButton drawToolbarButton' />;

const GraphPaper = observer(() => {
  const {
    graphPaperManager,
    toolbarManager,
  } = useContext(MobXProviderContext);

  // per C2C-5055 we are shrinking the GraphPaper grid for all customers by 'two grid squares'
  const GRID_SQUARE_SIZE = 29.5;
  const TWO_GRID_SQUARES_SIZE = GRID_SQUARE_SIZE * 2;

  const model = {
    altText: '',
    autoScore: false,
    convertOnExport: true,
    graphHeight: 540 - TWO_GRID_SQUARES_SIZE,
    graphInset: 0,
    graphTitle: '',
    graphWidth: 600 - TWO_GRID_SQUARES_SIZE,
    hasAxes: false,
    hasGraph: true,
    hint: '',
    id: 'graphTool',
    imageSource: null,
    imageTitle: '',
    isTestItem: true,
    maxScore: 1,
    scoringType: 'exact',
    snapToGrid: true,
    sourceId: 'graphTool',
    survey: false,
    tolerance: 100,
    type: 'Graph',
    typeLabel: '',
    unscored: false,
    xAxisMaxValue: 18,
    xAxisMinValue: 0,
    xAxisStepValue: 1,
    xAxisTitle: 'X-Axis',
    yAxisMaxValue: 16,
    yAxisMinValue: 0,
    yAxisStepValue: 1,
    yAxisTitle: 'Y-Axis'
  };

  const drawService = useMemo(() => new DrawService(model), ['GraphPaper']); // eslint-disable-line react-hooks/exhaustive-deps

  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    const init = async () => {
      await drawService.initialize();
      await drawService.runtimeObjectReady();
      await drawService.showResponse();

      const initialBrushStrokeWidth = +styleService.getStyleVar('--graph-paper-initial-brush-stroke-width');

      if (initialBrushStrokeWidth > 0) {
        drawService.setStrokeWidth(initialBrushStrokeWidth);
      }
      // possible timing issue with text containers from other Draw slides showing - rerender may fix it
      setRerender(!rerender);
    };
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const strokeColor = drawService.currentStrokeColor;
  const fillColor = drawService.currentFillColor;

  const width = drawService.getWidth() - TWO_GRID_SQUARES_SIZE;
  const height = drawService.getHeight() - TWO_GRID_SQUARES_SIZE;

  const widthPx = `${width }px`;
  const heightPx = `${height }px`;

  const imageTitle = model.imageTitle || '';
  let altText = model.altText || '';

  if (!altText) {
    if (imageTitle) {
      altText = utilsService.stripTagsAndEntities(model.imageTitle) || '';
    }
  }

  const canvasId = `${model.id }_canvas`;
  const drawCanvasId = `${model.id }_drawCanvas`;

  let image = '';
  if (model.croppedImageSource) {
    image = <img alt={altText} className='imageLabelImage' src={Auth.getResourceUrlByFileName(model.croppedImageSource)} style={{ maxHeight: heightPx }} />;
  } else if (model.imageSource) {
    image = <img alt={altText} className='imageLabelImage' src={Auth.getResourceUrlByFileName(model.imageSource)} style={{ maxHeight: heightPx }} />;
  } else {
    // image = <img alt={altText} src=""></img>;
    image = null;
  }

  let toolbar = RichTextEditor.MINIMUM_TOOLBAR;
  if (model.responseFormat === 'html') {
    if (model.toolbarType === 'mathToolbar') {
      toolbar = RichTextEditor.MATH_TOOLBAR;
    } else if (model.toolbarType === 'advancedToolbar') {
      toolbar = RichTextEditor.ADVANCED_CKEDITOR_TOOLBAR;
    } else if (model.toolbarType === 'mathAdvancedToolbar') {
      toolbar = RichTextEditor.ADVANCED_MATH_CKEDITOR_TOOLBAR;
    }
  }
  // richText.createEditor(textId, {onClick:function() {
  //     self.disableTextDragging();
  //     richText.getEditor(textId).focus();
  //   },
  //   onBlur:function(e) {
  //     self.updateState();
  //     self.enableTextDragging();
  //   },
  //   toolbar:toolbar
  // });

  const shapeOptions = [
    { value: drawService.TRIANGLE, label: <span className={`shapeIcon shapeIcon-${drawService.TRIANGLE}`}>&nbsp;</span> },
    { value: drawService.SQUARE, label: <span className={`shapeIcon shapeIcon-${drawService.SQUARE}`}>&nbsp;</span> },
    { value: drawService.CIRCLE, label: <span className={`shapeIcon shapeIcon-${drawService.CIRCLE}`}>&nbsp;</span> },
    { value: drawService.STAR, label: <span className={`shapeIcon shapeIcon-${drawService.STAR}`}>&nbsp;</span> },
  ];

  const pointOptions = [
    { value: 'closed', label: <span className='pointIcon pointIcon-closed'>&nbsp;</span> },
    { value: 'open', label: <span className='pointIcon pointIcon-open'>&nbsp;</span> },
  ];

  const lineOptions = [
    { value: drawService.LINE, label: <span className={`lineIcon lineIcon-${drawService.LINE}`}>&nbsp;</span> },
    { value: `${drawService.LINE}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.LINE}-dashed`}>&nbsp;</span> },
    { value: drawService.RAY, label: <span className={`lineIcon lineIcon-${drawService.RAY}`}>&nbsp;</span> },
    { value: `${drawService.RAY}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.RAY}-dashed`}>&nbsp;</span> },
    { value: drawService.SEGMENT, label: <span className={`lineIcon lineIcon-${drawService.SEGMENT}`}>&nbsp;</span> },
    { value: `${drawService.SEGMENT}-hidden-hidden`, label: <span className={`lineIcon lineIcon-${drawService.SEGMENT}-hidden-hidden`}>&nbsp;</span> },
    { value: `${drawService.SEGMENT}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.SEGMENT}-dashed`}>&nbsp;</span> },
    { value: drawService.VECTOR, label: <span className={`lineIcon lineIcon-${drawService.VECTOR}`}>&nbsp;</span> },
    { value: `${drawService.VECTOR}-open-hidden`, label: <span className={`lineIcon lineIcon-${drawService.VECTOR}-open-hidden`}>&nbsp;</span> },
    { value: `${drawService.VECTOR}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.VECTOR}-dashed`}>&nbsp;</span> },
  ];

  const handleChangeStrokeColor = (color) => {
    drawService.setStrokeColor(color.hex);
  };

  const handleChangeFillColor = (color) => {
    drawService.setFillColor(color.hex);
  };

  const handleChangeBrushSize = (data) => {
    ReactTooltip.hide();
    drawService.setStrokeWidth(data.value);
  };

  const handleChangeShape = (data) => {
    ReactTooltip.hide();
    drawService.addShape(data.value);
  };

  const handleChangeLine = (data) => {
    ReactTooltip.hide();
    drawService.addLine(data.value);
  };

  const handleChangePoint = (data) => {
    ReactTooltip.hide();
    drawService.addPoint(data.value);
  };

  const handleSelect = () => {
    drawService.select();
  };

  const handleCopy = () => {
    drawService.copy();
  };

  const handleUndo = () => {
    drawService.undo();
  };

  const handleRedo = () => {
    drawService.redo();
  };

  const handleDeleteText = (textId) => {
    graphPaperManager.deleteTextContainer(textId);
    drawService.updateState();
    drawService.updateResponseModel();
  };

  const handleDragStart = (e) => {
    const rect = document.getElementById(e.target.id).getBoundingClientRect();
    graphPaperManager.dragStartTextContainer(e.target.id, e.clientX, e.clientY, rect.width, rect.height);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    graphPaperManager.dropTextContainer(e.clientX, e.clientY);
    drawService.updateState();
    drawService.updateResponseModel();
  };

  const handleClose = () => {
    toolbarManager.setIsGraphPaperOpen(false);
    toolbarManager.toggleToolOff('Graph');
  };

  const graphPaperToolSiblingClassName = 'graph-draw';

  const actionButtonHeightPx = styleService.getStyleVar('--theme-primary-button-height');

  useReactModalResizeableDraggable('graphPaperTool');

  return (
    <div className={classNames('graphPaperTool', graphPaperToolSiblingClassName)}>
      <ReactModal
        disableResize={true}
        initHeight={`calc((625px - ${TWO_GRID_SQUARES_SIZE}px) + ${actionButtonHeightPx})`}
        initWidth={630 - TWO_GRID_SQUARES_SIZE}
        isOpen={true}>
        <div className='tool-header'>
          <div className='title-label basic'>
            {t('graphPaperLabel')}
          </div>
          <div aria-label={t3('closedialog')} className='x-close-button' id='modal-close' onClick={handleClose} role='button'
            tabIndex='0' />
        </div>

        <div className='graphContainer'>
          <div className='drawToolbar' role='toolbar' style={{ width: width + TWO_GRID_SQUARES_SIZE }}>

            <ReactTooltip border effect='solid' id='graph-paper' padding='10px'
              place='top' type='light' />

            <div aria-label={t3('drawwithpencil')} className='pencilToolButton drawToolbarButton' data-for='graph-paper' data-tip={t3('pencil')}
              data-tool='pencil'
              onClick={() => drawService.onClickPencilTool()} role='button'
              tabIndex='0'
            />

            <div aria-label={t3('drawwithpaintbrush')} className='paintToolButton drawToolbarButton' data-for='graph-paper' data-tip={t3('brush')}
              data-tool='paint'
              onClick={() => drawService.onClickPaintTool()} role='button'
              tabIndex='0'
            />

            <div aria-label={t3('strokecolor')} className='strokeColorButton drawToolbarButton' data-for='graph-paper' data-tip={t3('strokecolor')}
              role='menu'
              style={{ display: 'inline-block' }} tabIndex='0'>
              <ColorPicker color={strokeColor} onChange={handleChangeStrokeColor} />
            </div>

            <div aria-label={t3('fillcolor')} className='fillColorButton drawToolbarButton' data-for='graph-paper' data-tip={t3('fillcolor')}
              role='menu'
              style={{ display: 'inline-block' }} tabIndex='0'>
              <ColorPicker color={fillColor} onChange={handleChangeFillColor} />
            </div>

            <div className='drawBrushSizeMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t3('brushsize')} className='drawBrushSizeMenu drawToolMenu' data-for='graph-paper' data-tip={t3('brushsize')}
                role='menu'
                tabIndex='-0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  onChange={handleChangeBrushSize}
                  options={brushSizeOptions}
                  placeholder={brushSizePlaceholder}
                  styles={brushSizeCustomStyles}
                />
              </div>
            </div>

            <div className='shapeMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t3('shape')} className='shapeMenu drawToolMenu' data-for='graph-paper' data-tip={t3('shape')}
                role='menu'
                tabIndex='0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ValueContainer: CustomValueContainer }}
                  onChange={handleChangeShape}
                  options={shapeOptions}
                  placeholder={shapePlaceholder}
                  styles={shapeCustomStyles}
                />
              </div>
            </div>

            <div className='lineMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t3('graphline')} className='lineMenu drawToolMenu' data-for='graph-paper' data-tip={t3('graphline')}
                role='menu'
                style={{ width: '60px' }} tabIndex='0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ValueContainer: CustomValueContainer }}
                  onChange={handleChangeLine}
                  options={lineOptions}
                  placeholder={linePlaceholder}
                  styles={lineCustomStyles}
                />
              </div>
            </div>

            <div className='pointMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t3('graphpoint')} className='pointMenu drawToolMenu' data-for='graph-paper' data-tip={t3('graphpoint')}
                role='menu'
                style={{ width: '50px' }} tabIndex='0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ValueContainer: CustomValueContainer }}
                  onChange={handleChangePoint}
                  options={pointOptions}
                  placeholder={pointPlaceholder}
                  styles={pointCustomStyles}
                />
              </div>
            </div>

            <div aria-label={t3('selectitems')} className='selectToolButton drawToolbarButton' data-for='graph-paper' data-tip={t3('selectitems')} onClick={handleSelect}
              role='button'
              tabIndex='0' />

            <div aria-label={t3('addtext')} className='textToolButton drawToolbarButton' data-for='graph-paper' data-tip={t3('addtext')} onClick={drawService.onClickTextTool}
              role='button'
              tabIndex='0' />

            <div className='rightButtonContainer' tabIndex='-1'>
              <div aria-label={t3('copyelement')} className='drawCopyElementButton drawToolbarButton' data-for='graph-paper' data-tip={t3('copyelement')}
                onClick={handleCopy}
                role='button'
                tabIndex='0' />
              <div aria-label={t3('undo')} className='drawUndoButton drawToolbarButton' data-for='graph-paper' data-tip={t3('undo')}
                onClick={handleUndo}
                role='button'
                tabIndex='0' />
              <div aria-label={t3('redo')} className='drawRedoButton drawToolbarButton' data-for='graph-paper' data-tip={t3('redo')}
                onClick={handleRedo}
                role='button'
                tabIndex='0' />
              <div aria-label={t3('deleteelement')} className='drawDeleteElementButton drawToolbarButton' data-for='graph-paper' data-tip={t3('deleteelement')}
                onClick={drawService.deleteFromDraw}
                role='button'
                tabIndex='0' />
            </div>

            <div className='deleteButtonContainer'>
              <button className='deleteElementButton'>{t2('delete')}</button>
              <button className='clearButton'>{t2('clearAll')}</button>
            </div>
          </div>

          <div style={{
            position: 'relative',
            width: widthPx,
            height: heightPx
          }}>
            <div className='drawBody imageBody ' data-allow='1' style={{ width: widthPx, height: heightPx }}>
              {image}
            </div>

            <div className='canvasContainerOuter' id='canvasContainerOuter' onDragEnd={handleDragEnd}
              style={{ height: heightPx, width: widthPx }}>

              {graphPaperManager.textContainers.map((textContainer) => {
                return (
                  <div key={textContainer.textId} className='drawTextContainer deleteButtonParent' draggable id={textContainer.textId}
                    onDragStart={handleDragStart} style={{ top: textContainer.top, left: textContainer.left, opacity: textContainer.opacity }}>
                    <div className='deleteButton deleteTextContainerButton'
                      onClick={() => handleDeleteText(textContainer.textId)} />
                    <div className='drawText oneRowToolbar cke_editable cke_editable_inline cke_contents_ltr cke_show_borders placeholder' id={`${textContainer.textId}_text`}>
                      <RichTextEditor
                        data={textContainer.text}
                        lessonElementId={lessonElementId}
                        onChange={(data) => {
                          graphPaperManager.setTextContainerText(textContainer.textId, data);
                        }}
                        placeHolder='Text'
                        readOnly={false}
                        toolbar={toolbar} />
                    </div>
                  </div>
                );
              })}

              <div className='body canvasOrganizerBody canvasContainer' id='canvasContainer' style={{ height: heightPx, width: widthPx }}>
                <canvas className='canvas' id={canvasId} style={{ height: heightPx, width: widthPx }} />
              </div>

              <div className='body canvasOrganizerBody drawCanvasContainer drawCanvasContainer-graph' id='drawCanvasContainer' style={{ width: widthPx, height: heightPx }}>
                <canvas className='canvas' id={drawCanvasId} style={{ height, width }} />
              </div>
            </div>
          </div>
        </div>
        <div className='modal-buttons'>
          <button className='primary' onClick={handleClose}>{t2('ok')}</button>
        </div>
      </ReactModal>
    </div>
  );
});

export default GraphPaper;
