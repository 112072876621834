import { action, computed, makeObservable, observable } from 'mobx';
import { FlyoutOpenState, FlyoutSide, FlyoutTabs, LessonMode } from '../../Constants';
import lessonManager from './LessonManager';

export class NavMenuManager {
  @observable selectedParentActivityLessonElement = null;

  @observable previousActivityNavigatorPointY = null;

  /**
   * ```
   * Map<string, {
   *   childModel: {};
   *   parentModel: {};
   *   groupList?: [];
   *   index: number;
   *   isFirstInGroup?: boolean;
   * }> activityNavigatorLessonElementMap
   * ```
   */
  @observable activityNavigatorLessonElementMap = new Map();

  @observable selectedActivityNavigatorLessonElementIndex = 0;

  @observable gotoMenuOpen = false;
  @observable summarySectionOpenToggles = new Map();
  @observable flyoutOpenStates = new Map();
  @observable flyupOpenStates = new Map();
  @observable scoringFlyoutSide = FlyoutSide.RIGHT;

  constructor() {
    makeObservable(this);
  }

  @action
  setScoringFlyoutSide = (side) => {
    this.scoringFlyoutSide = side;
  }

  @action setSelectedParentActivityLessonElement = (lessonElement) => {
    this.selectedParentActivityLessonElement = lessonElement;
  }

  @action setPreviousActivityNavigatorPointY = (pointY) => {
    this.previousActivityNavigatorPointY = pointY;
  }

  @action setActivityNavigatorLessonElementMap = (map) => {
    this.activityNavigatorLessonElementMap = map;
  }

  @action setSelectedActivityNavigatorLessonElementIndex = (index) => {
    this.selectedActivityNavigatorLessonElementIndex = index;
  }

  @action
  toggleGotoMenuState = (toggle) => {
    if (typeof toggle != 'undefined') {
      this.gotoMenuOpen = toggle;
    } else {
      this.gotoMenuOpen = !this.gotoMenuOpen;
    }
  }

  @action
  initSummarySectionToggles = (sections) => {
    sections.forEach((lessonElement, lessonElementId, map) => {
      this.summarySectionOpenToggles.set(lessonElementId, false);
    });
  }

  @action
  setSummarySectionOpen = (lessonElementId, toggle) => {
    this.summarySectionOpenToggles.set(lessonElementId, toggle);
  }

  @action
  setFlyoutOpenState = (state, lessonElementId) => {
    this.flyoutOpenStates.set(lessonElementId, state);
    if (lessonManager.playerMode === LessonMode.REVIEW) {
      this.flyoutOpenStates.forEach((_value, key, map) => {
        _value.side = state.side;
        if (state.open === FlyoutOpenState.OPEN_WIDE && _value.tab !== FlyoutTabs.RUBRIC) {
          _value.open = FlyoutOpenState.OPEN;
        } else {
          _value.open = state.open;
        }
        map.set(key, _value);
      });
    } else {
      if (state.open !== FlyoutOpenState.OPEN_WIDE) {
        this.flyoutOpenStates.forEach((_value, key, map) => {
          if (_value && _value.side === state.side) {
            _value.open = state.open;
            _value.tab = state.tab;
            map.set(key, _value);
          }
        });
      }
    }
  }

  getFlyoutOpenState = (lessonElementId) => {
    return this.flyoutOpenStates.get(lessonElementId);
  }

  @action
  setFlyupOpenState = (state, lessonElementId) => {
    this.flyupOpenStates.set(lessonElementId, state);
  }

  getFlyupOpenState = (lessonElementId) => {
    return this.flyupOpenStates.get(lessonElementId);
  }

  isSectionOpen = (lessonElementId) => {
    return this.summarySectionOpenToggles.get(lessonElementId);
  }

  @computed get hasPreviousActivityNavigatorPointY() {
    return typeof this.previousActivityNavigatorPointY === 'number';
  }

  @computed get currentActivityNavigatorLessonElementId() {
    return Array.from(this.activityNavigatorLessonElementMap.keys())[this.selectedActivityNavigatorLessonElementIndex];
  }

  @computed get firstActivityNavigatorTopOffset() {
    const firstChildElementId = this.activityNavigatorLessonElementIds[0];
    const firstElement = document.getElementById(`${firstChildElementId}-standalone-wrapper`);
    const _firstActivityNavigatorTopOffset = firstElement?.offsetTop || 0;
    return _firstActivityNavigatorTopOffset;
  }

  @computed get activityNavigatorLessonElementIds() {
    return Array.from(this.activityNavigatorLessonElementMap.keys());
  }

  /**
   * @return {{
   *   childModel: {};
   *   parentModel: {};
   *   groupList?: [];
   *   index: number;
   *   isFirstInGroup?: boolean;
   * }[]}
   */
  @computed get activityNavigatorLessonElementValues() {
    return Array.from(this.activityNavigatorLessonElementMap.values());
  }
}

export default new NavMenuManager();
