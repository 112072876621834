import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';
import RichTextEditor, { BASIC_CKEDITOR_TOOLBAR } from '../RichTextEditor';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import '../../../css/BookNotes.scss';
import bookDrawService from '../../services/BookDrawService';
import StampNoteEditor from './StampNoteEditor';
import { register } from '../../../i18n';

const t = register('ToolNames');
const t2 = register('GlobalQuestionLabels');
const t3 = register('AriaLabel');

// function BookNotes() {
const BookNotes = observer(() => {
  const {
    bookDrawManager,
  } = useContext(MobXProviderContext);

  const toolbar = BASIC_CKEDITOR_TOOLBAR;
  // TODO unused // const readOnly = false;

  const [enteredText, setEnteredText] = useState('');

  const stamp = bookDrawManager.getStamp(bookDrawManager.selectedStamp.pageId, bookDrawManager.selectedStamp.stampId);

  useEffect(() => {
    const init = async () => {
      // preload the font used by fabric/canvas
      await bookDrawService.fetchDiscussions();
    };
    init();
  }, []); // eslint-di
  function closeNotes() {
    bookDrawManager.setSelectedStamp(null);
  }

  const saveNoteRef = useRef();
  useAccessibilityClick(saveNoteRef, async (e) => {
    await bookDrawService.createOrUpdateDiscussion(enteredText);
    setEnteredText('');
    await bookDrawService.fetchDiscussions();
  });

  const cancelNoteRef = useRef();
  useAccessibilityClick(cancelNoteRef, (e) => {
    setEnteredText('');
  });

  return (
    <div className='bookNotesTool'>
      <ReactModal
        initHeight={300}
        initWidth={400}
        isOpen={true}
        left={5}
        top={150}>
        <div className='notes-body'>
          <div className='tool-header'>
            <div className='title-label basic'>{t('bookNotesLabel')}</div>
            <div aria-label={t3('closedialog')} className='x-close-button' onClick={closeNotes} role='button'
              tabIndex='0' />
          </div>
          <div className='existing-notes'>
            {
              (stamp.discussions.size > 0) ?
                stamp.discussionsArray.map((discussion, index) => <StampNoteEditor key={`${discussion.id}-note`} discussion={discussion} />
                ) : <></>
            }
          </div>
          <div className='new-note'>
            <div className='note-user'>{t2('createNewDiscussion')}</div>
            <div className='cke_topspace'>
              <RichTextEditor data={enteredText}
                onChange={(data) => setEnteredText(data)}
                readOnly={false} toolbar={toolbar} />
            </div>

            <div className='footer-buttons'>
              <button ref={saveNoteRef} className='submit-response primary'>{t2('save')}</button>
              <button ref={cancelNoteRef} className='submit-response primary'>{t2('cancel')}</button>
              {
                /* ( highlightAnnotationManager.currentHighlightNotes && highlightAnnotationManager.currentHighlightNotes.length > 1) ?
                  <button ref={deleteAllNoteRef} className='submit-response primary'>Delete All</button>
                  : <></> */
              }
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
});

export default BookNotes;
