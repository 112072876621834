import { Behavior } from './Behavior';
import { ContentType, LessonMode, PartialScoringType, QuestionFeedbackState } from '../../Constants';
import numberLineService from '../services/NumberLineService';
import UtilsService from '../services/UtilsService';

export class NumberLineBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    if (!responseAnswer || !responseAnswer.answers || responseAnswer.answers.length === 0) {
      return false;
    }
    return true;
  }

  isAutoScored = (model) => {
    const autoScore = ContentType.getAutoscore(model.type) && !model.survey && !model.unscored;
    return autoScore;
  }

  getScore = (responseAnswer, model) => {
    let score = 0;
    let tempScore = 0;
    const responseModel = responseAnswer;
    const promptCount = numberLineService.getPromptCount(model);

    if (responseModel.answers) {
      responseModel.answers.forEach((responseModelElement) => {
        if (!model?.validation?.correctAnswers) {
          return 0;
        }
        if (responseModelElement.elementType === 'point') {
          const correctElements = model.validation.correctAnswers.filter((answer) => answer.elementType === 'point');
          const isMatch = numberLineService.isPointElementMatch(correctElements, responseModelElement);

          if (isMatch) {
            tempScore += 1;
          } else {
            tempScore -= 1;
          }
        } else if (responseModelElement.elementType === 'segment') {
          const correctElements = model.validation.correctAnswers.filter((answer) => answer.elementType === 'segment');
          const isMatch = numberLineService.isSegmentMatch(correctElements, responseModelElement);

          if (isMatch) {
            tempScore += 1;
          } else {
            tempScore -= 1;
          }
        } else if (responseModelElement.elementType === 'ray') {
          // const isMatch = false;
          const correctElements = model.validation.correctAnswers.filter((answer) => answer.elementType === 'ray');
          const isMatch = numberLineService.isRayMatch(correctElements, responseModelElement);

          if (isMatch) {
            tempScore += 1;
          } else {
            tempScore -= 1;
          }
        }
      });

      if (model.scoringType === PartialScoringType.EXACT) {
        score = (tempScore === promptCount) ? model.maxScore : 0;
      } else if (model.scoringType === PartialScoringType.PARTIAL) {
        score = tempScore / promptCount * model.maxScore;
      } else if (model.scoringType === PartialScoringType.PARTIAL_MATCH_RESPONSE) {
        score = tempScore;
      }
    }

    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    responseAnswer = data;
  }

  getInitializedResponse = (lessonElementId) => {
    const responseAnswer = { answers: [] };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    return null;
  }

  setCorrectAnswer = (state, model) => {
    // Do nothing. This is handled directly in the NumberLine component. Changing the user's responses is not the correct way to handle this.
    // state.currentResponseAnswer.answers = model.validation.correctAnswers;
  }

  resetStudentAnswer = (state) => {
    // state.currentResponseAnswer = UtilsService.safeMobxClone(state.cachedResponseAnswer);
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody } `;
      }

      textBody = `${UtilsService.partiallyCorrectPrompt()}. <br/>${ custom }${textBody}`;
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    return lessonElementState.isScored && (lessonElementState.scoreValue === lessonElementState.maxScore);
  }

  isQuestionCorrect = (lessonElementState) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }

    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new NumberLineBehavior();
