import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../../css/FlyoutStudentScorePanel.scss';
import RubricScoreTable from './RubricScoreTable';
import { ContentType, ScoreState } from '../../../Constants';
import HtmlComponent from '../HtmlComponent';
import { register } from '../../../i18n';

const t = register('TeacherScoringPanel');
const t2 = register('AriaLabels');

import highlightAnnotationService from '../../services/HighlightAnnotationService';

const StudentScorePanel = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    responseManager,
    scoringManager,
    highlightAnnotationManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const score = (lessonElementState && lessonElementState.submittedItemScore) ? lessonElementState.submittedItemScore : '0';
  const maxScore = (lessonElementState && lessonElementState.submittedMaxScore) ? lessonElementState.submittedMaxScore : '0';
  const rubricScored = (lessonElementState && (lessonElementState.scoreState === ScoreState.MANUALLY_SCORED ||
      lessonElementState.scoreState === ScoreState.NOT_SCORED) &&
    scoringManager.rubricMap.has(lessonElementId));

  useEffect(() => {
    // run once to initialize the highlights.  Student default highlights mode is 'teacher'
    const highlightMode = highlightAnnotationManager.highlightUserMode || 'teacher';
    highlightAnnotationManager.setHighlightUserMode(highlightMode);
    highlightAnnotationService.switchHighightAndNotesView(highlightAnnotationManager.highlightUserMode);
  }, []);

  const handleHighlightChange = async (data) => {
    highlightAnnotationManager.setHighlightUserMode(data.target.value);
    highlightAnnotationService.switchHighightAndNotesView(data.target.value);
  };

  return (
    <div className='student-score-panel flyout-panel'>
      <div className='panel-header'>{(!model.unscored && model.isTestItem) ?  `${t('headerLabelScore')}`  : `${t('headerLabelUnscored')}`}</div>
      <select aria-label={t2('highlight')} className='highlight-select' name='highlight-select' onChange={handleHighlightChange}
        tabIndex='0' value={highlightAnnotationManager.highlightUserMode}>
        <option value='off'>{t('selectionOne', 'Highlights and Notes: Off')}</option>
        <option value='teacher'>{t('selectionTwo', 'Highlights and Notes: Teacher')}</option>
        <option value='student'>{t('selectionThree', 'Highlights and Notes: Student')}</option>
		  </select>
      {(model && model.isTestItem && lessonElementState) && (
        <div className='student-score-wrapper'>
          <div className='student-score-container'>
            {(rubricScored && !model.unscored) ? <RubricScoreTable lessonElementId={lessonElementId} /> : (!model.unscored) ?
              <div className='student-score'><span>{t('points')}:&nbsp;</span><span>{score}</span><span>/</span><span>{maxScore}</span></div> : <></>}
          </div>
          <div className='teacher-notes-wrapper'>
            <div className='teacher-notes-container'>
              <div className='teacher-notes-textbox-wrapper'>
                <div aria-label={t('teacherComments')} className='teacher-comments-label'>{t('teacherComments')}:</div>
                <HtmlComponent htmlStr={lessonElementState.comment} />
              </div>
            </div>
          </div>
        </div>
      )}
      {(model && (model.type === ContentType.STUDENT_INSTRUCTION.type) && lessonElementState) && (
        <div className='student-score-wrapper'>
          <div className='student-score-container'>
            <div className='teacher-notes-wrapper'>
              <div className='teacher-notes-container'>
                <div className='teacher-notes-textbox-wrapper'>
                  <div aria-label={t('teacherComments')} className='teacher-comments-label'>{t('teacherComments')}:</div>
                  <HtmlComponent htmlStr={lessonElementState.comment} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default StudentScorePanel;
