import React from 'react';

const NumberCircle = ({ number }) => {
  return (
    <svg height='20' style={{ verticalAlign: 'bottom' }} width='20'>
      <circle cx='10' cy='10' fill='#fff' r='9'
        stroke='#666' strokeWidth='2' />
      <text alignmentBaseline='central' fill='#000' style={{ fontSize: '12px', fontWeight: 'bold' }} textAnchor='middle'
        x='10'
        y='10'>{number}
      </text>
    </svg>
  );
};
export default NumberCircle;
