import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import Utils from '../../services/UtilsService';
import HtmlComponent from '../HtmlComponent';
import '../../../css/RubricResource.scss';
import scoringService from '../../services/ScoringService';
import Loader from '../Loader';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import { register } from '../../../i18n';

const t = register('PanelMenu')

const RubricResourceViewer = observer(({ lessonElementId }) => {
  const {
    scoringManager,
    lessonManager,
    userManager
  } = useContext(MobXProviderContext);

  const rubric = scoringManager.rubricMap.get(lessonElementId);
  const model = lessonManager.getSlideModel(lessonElementId);

  const [blurred, setBlurred] = useState(true);
  const blurButtonRef = useRef(null);
  useAccessibilityClick(blurButtonRef, () => {
    setBlurred((blurred) => !blurred);
  });

  const { isTeacher } = userManager;

  useEffect(() => {
    if (!rubric) {
      model.rubricEntityId = model.entityId; // api expects the exact name of rubricEntityId
      scoringService.fetchContentItemRubric(lessonElementId, model, true);
    }
  });
  return (
    <>
      {(rubric) ? (
        <div className='rubric-wrapper'>
          {(isTeacher && model.hideFromStudents) && <button ref={blurButtonRef} className={`toggle-blur-button primary ${blurred ? 'blur-active' : 'blur-inactive'}`}>{blurred ? `${t('showButton')}` : `${t('hideButton')}`}</button>}
          <div className={`rubric-wrapper-inner  ${(blurred && isTeacher && model.hideFromStudents) ? 'blurred' : ''} `}>
            <div className='rubric-summary'><HtmlComponent htmlStr={model.text} /></div>
            <div className='rubric-container'>
              <table className='rubric font-content-container'>
                <thead>
                  <tr>
                    <th className='no-border font-content-container' />
                    {rubric.columns.map((column) => {
                      return (<th key={`rebric_header_${Utils.getKey()}`} className='columnHeader font-content-container'><HtmlComponent htmlStr={column} /></th>);
                    })}
                    <th className='no-border font-content-container'>{t('maxPoints')}</th>
                  </tr>
                </thead>
                <tbody>
                  {rubric.categories.map((category) => {
                    return (
                      <tr key={`rebric_row_${Utils.getKey()}`}>
                        <td className='category-label font-content-container'><HtmlComponent htmlStr={category.name} /></td>
                        {category.columns.map((column, clickIndex) => {
                          return (
                            <td key={`rebric_inner_col_${Utils.getKey()}`}
                              className={classNames({
                                'rubric-cell': true,
                                'font-content-container': true,
                                'selected': scoringManager.isSelectedCell(category.categoryId, clickIndex, lessonElementId)
                              })}>
                              <HtmlComponent htmlStr={column} />
                            </td>
                          );
                        })}
                        <td className='max-points font-content-container'>{category.maxPoints}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) :
        <div className='dialog-loader'><Loader /></div>}
    </>
  );
});

export default RubricResourceViewer;
