import React from 'react';
import RadioButton from '../RadioButton';
import NumberCircle from '../NumberCircle';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import AudioFeedback from '../AudioFeedback';
import Auth from '../../lessonPlayer/services/AuthService';
import Utils from '../../lessonPlayer/services/UtilsService';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const ImageLabelMultichoice = ({ model, isManuscriptShort }) => {
  const imgSrc = `${Auth.ecms}/api/redirectToResource?contentItemId=${model.contentItemId}&authKey=${Auth.authKey}`;
  const promptMap = Utils.mapById(model.validation.prompts);

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Label Image Multiple Choice</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Question:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Image Title:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.imageTitle} /></div>
      </div>
      <div className='maximum-image-size' style={{ position: 'relative' }}>
        {model.prompts.map((prompt, idx) => {
          return (
            <div key={prompt.id} className='image-label' style={{ top: `${prompt.point.topPercent}%`, left: `${prompt.point.leftPercent}%` }}>
              <NumberCircle number={idx + 1} />
            </div>
          );
        })}
        <img alt='' className='maximum-image-size' src={imgSrc} />
      </div>
      {model.validation.prompts.map((prompt, promptIdx) => {
        const validationPrompt = promptMap[prompt.id];
        return (
          <div key={prompt.id}>
            <div className='choices'>
              <div className='has-number-circles'><NumberCircle number={promptIdx + 1} />Choices:</div>
              <div>Correct</div>
              {validationPrompt.answers.map((answer, answerIdx) => {
                const answerLetter = String.fromCharCode(65 + answerIdx);
                const selected = answer.isCorrect;

                return (
                  <React.Fragment key={answerIdx}>
                    <div className='choice-letter'>{`${answerLetter}:`}</div>
                    <div className='ckeditor-text text-area'><HtmlComponent htmlStr={answer.text} /></div>
                    <div className='correct-button'>
                      <RadioButton selected={selected} />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Student Answer Format:</div>
            <div>{model.responseFormat}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
          <div className='label-value-row'>
            <div>Numbered Blanks:</div>
            <div>{(model.numberedBlanks) ? 'ON' : 'OFF'}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <AudioFeedback model={model} />
          <div className='label-value-row'>
            <div>Randomize Answers:</div>
            <div>{(model.isRandomized) ? 'ON' : 'OFF'}</div>
          </div>
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default ImageLabelMultichoice;
