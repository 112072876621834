import { action, computed, makeObservable, observable } from 'mobx';

export class AlignmentManager {
  // question alignment structures for viewing question standard alignments
  @observable alignmentTreeStructure = {};
  @observable alignmentCoveragePaths = new Map();
  @observable lessonCoverageAlignments = [];
  @observable lessonAlignmentTreeStructure = null;
  lessonExpandedItems = [];
  expandedItems = [];
  serverAlignmentData = [];
  notFetched = true;
  taggedStandards = new Map();

  // cmap tree data for looking at where a standard lives in its
  // parent cmap hierarchy
  @observable cmapContentItem = {};
  @observable cmapResource = {};
  @observable detailCmapTreeStructure = {};
  @observable cmapDetailDialogIsOpen = false;
  expandedCmapItems = [];
  serverCmapElements = [];
  @observable cmapLoaded = false;

  constructor() {
    makeObservable(this);
  }

  @action setCmapContentItemName = (name) => {
    this.cmapContentItem.name = name;
  }

  @action setCmapContentItemFriendlyName = (name) => {
    this.cmapContentItem.friendlyName = name;
  }

  @action setCmapContentItemResourceNodeId = (id) => {
    this.cmapContentItem.resourceNodeId = id;
  }

  @action setTreeStructure = (structure) => {
    this.alignmentTreeStructure = structure;
  }

  @action setLessonTreeStructure = (structure) => {
    this.lessonAlignmentTreeStructure = structure;
  }

  @action setLessonCoverageAlignments = (alignments) => {
    this.lessonCoverageAlignments = alignments;
  }

  @action
  setContentAlignmentCoveragePaths = (lessonElementId, alignmentCoveragePaths) => {
    this.alignmentCoveragePaths.set(lessonElementId, alignmentCoveragePaths);
  }

  @action
  setDetailRoot = (detailRoot) => {
    this.detailAlignmentTreeStructure[detailRoot.id] = detailRoot;
  }

  @action
  setCmapContentItem = (cmapContentItem) => {
    this.cmapContentItem = cmapContentItem;
  }

  @action
  setCmapResource = (cmapResource) => {
    this.cmapResource = cmapResource;
  }

  @action
  setCmapLoaded = (flag) => {
    this.cmapLoaded = flag;
  }

  @action
  setCmapDetailDialogIsOpen = (flag) => {
    this.cmapDetailDialogIsOpen = flag;
  }

  @action
  setCmapTreeStructure = (treeData) => {
    this.detailCmapTreeStructure = treeData;
  }

  @computed
  get unitName() {
    return this.cmapResource.unitName;
  }

  alreadyFetched = (lessonElementId) => {
    const path = this.alignmentCoveragePaths.get(lessonElementId);
    if (path) {
      return true;
    }
    return false;
  }

  hasAlignments = (lessonElementId) => {
    const path = this.alignmentCoveragePaths.get(lessonElementId);
    if (path && path.length > 0) {
      return true;
    }
    return false;
  }

  setServerAlignments = (data) => {
    this.serverAlignmentData = data;
  }

  addToExpandedItems = (id) => {
    this.expandedItems.push(id);
  }

  addToLessonExpandedItems = (id) => {
    this.lessonExpandedItems.push(id);
  }

  @action
  setTaggedStandards = (results) => {
    if (results.standards && results.standards.length > 0) {
      results.standards.forEach((standard) => {
        let stds = this.taggedStandards.get(standard.id);
        if (!stds) {
          stds = [standard.name];
        } else {
          stds.push(standard.name);
        }
        this.taggedStandards.set(standard.id, stds);
      });
    }
  }

  getTaggedStandards = (contentItemId) => {
      return this.taggedStandards.get(contentItemId);
  }
}

export default new AlignmentManager();
