import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/QuestionFlyout.scss';

import { ContentType } from '../../../Constants';

import playerService from '../../services/PlayerService';
import videoService from '../../services/VideoService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import HtmlComponent from '../HtmlComponent';
import UtilsService from '../../services/UtilsService';
import { register } from '../../../i18n';

const t = register('PanelMenu');

const DirectionsFlyout = observer(({ lessonElementId, directions, isActivity }) => {
  const {
    lessonManager,
  } = useContext(MobXProviderContext);

  const openButton = useRef();

  const element = lessonManager.getLessonElement(lessonElementId);
  const model = lessonManager.contentMap[element.entityId];

  lessonManager.setIsQuestionListView(false);

  useAccessibilityClick(openButton, () => {
    if (lessonManager.isDirectionsFlyoutOpen) {
      lessonManager.setIsDirectionsFlyoutOpen(false);
    } else {
      lessonManager.setIsDirectionsFlyoutOpen(true);
    }
  });

  const directionsContentClass = (lessonManager.isDirectionsFlyoutOpen) ? 'directions-content content-show' : 'directions-content content-hide';

  const isScoreModeVideoActivityParent = model?.type === ContentType.VIDEO_QUESTION.type && playerService.isScoringMode();

  return ((lessonElementId && !UtilsService.isNullOrEmpty(directions)) ? (
    <div className={classNames('q-flyout-wrapper', 'directions-flyout',
      videoService.getVideoActivityParentClassNamesIfApplicable({
        isScoreModeVideoActivityParent
      }), {
        open: (lessonManager.isDirectionsFlyoutOpen),
        closed: (!lessonManager.isDirectionsFlyoutOpen),
        left: true,
        activity: isActivity
      }, model.type)}>
      <div className={classNames({ 'q-flyout-tab-bar': true })}>
        <div ref={openButton} className='q-flyout-tab open-button-wrapper'>
          <div aria-hidden className='icon-wrapper' tabIndex='-1'>
            <div aria-hidden aria-label={(!lessonManager.isDirectionsFlyoutOpen) ? `${t('openMenu', 'Open flyout menu')}` : `${t('closeMenu', 'Close flyout menu')}`}
              className='open-button'
              tabIndex='-1' />
          </div>
        </div>
      </div>
      <div className='q-flyout-panel-wrapper'>
        <div className='q-flyout-panel'>
          <div className={directionsContentClass}>
            <div className='question-header'>
              <div className='html-input'>{t('directions')}</div>
            </div>
            <div className='directions-text'>
              <HtmlComponent htmlStr={directions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : <></>
  );
});

export default DirectionsFlyout;
