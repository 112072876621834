import { action, computed, makeObservable, observable } from 'mobx';
import UtilsService from '../services/UtilsService';
import toolbarService from '../services/ToolbarService';
import { ActivityStatus } from '../../Constants';

export class StudentActivityManager {
  @observable
  activityId = null;

  @observable
  activityInstanceStatus = ActivityStatus.ACTIVITY_LOCKED;

  @observable
  lastContentItemPresented = null;

  @observable
  lastContentItemViewed = null;

  @observable
  isAssignmentReadOnly = false;

  @observable
  showCorrectAnswers = true;

  @observable
  isSubmitted = false;

  @observable studentName = '';

  @observable studentId = null;

  @observable students = [];

  @observable currentStudentIndex = 0;

  @observable studentInstruction = null;

  // @observable
  activityNoteText = null;

  constructor() {
    makeObservable(this);
  }

  @action setStudentActivity = (studentActivity, activityId) => {
    this.studentActivity = studentActivity;

    this.activityId = activityId;

    this.activityInstanceStatus = studentActivity.activityInstanceStatus;

    this.lastContentItemPresented = studentActivity.lastContentItemPresented;

    this.lastContentItemViewed = studentActivity.lastContentItemViewed;

    this.isAssignmentReadOnly = studentActivity.isAssignmentReadOnly;

    this.showCorrectAnswers = studentActivity.showCorrectAnswers;

    this.isSubmitted = studentActivity.isSubmitted;

    this.studentInstruction = studentActivity.instruction;
    toolbarService.setStudentInstructionVisibility(this.hasInstruction);

    this.activityNoteText = studentActivity.noteText;
  }

  @action
  getNextStudent = () => {
    this.currentStudentIndex += 1;
    if (this.currentStudentIndex >= this.students.length) {
      this.currentStudentIndex = 0;
    }
    return this.students[this.currentStudentIndex];
  }

  @action
  getPreviousStudent = () => {
    this.currentStudentIndex -= 1;
    if (this.currentStudentIndex < 0) {
      this.currentStudentIndex = (this.students.length - 1);
    }
    return this.students[this.currentStudentIndex];
  }

  @action
  setActivityUsers = (users, studentId) => {
    let index = 0;
    if (users && users.length > 0) {
      users.forEach((user, i) => {
        if (user.id === studentId) {
          index = i;
        }
        this.students.push(user);
      });
    }
    this.currentStudentIndex = index;
  }

  @computed
  get currentStudent() {
    return this.students[this.currentStudentIndex];
  }

  @action setStudentActivityId = (activityId) => {
    this.activityId = activityId;
  }

  @action setStudentName = (user) => {
    this.studentName = `${user.firstName} ${user.lastName}`;
  }

  @action setStudentId = (id) => {
    this.studentId = id;
  }

  @action setActivityNoteText = (text) => {
    this.activityNoteText = text;
  }

  @computed get studentActivityId() {
    return this.activityId;
  }

  @computed get hasInstruction() {
    let tempInstruction = this.studentActivity.instruction;

    if (tempInstruction === undefined || tempInstruction === null || tempInstruction === '') {
      return false;
    }

    tempInstruction = UtilsService.stripTagsAndEntities(tempInstruction);
    tempInstruction = tempInstruction.trim();

    if (tempInstruction === undefined || tempInstruction === null || tempInstruction === '') {
      return false;
    }

    return true;
  }
}

export default new StudentActivityManager();
