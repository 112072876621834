import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import itemBankService from '../services/ItemBankService';

const AddItemToAssessmentButton = (props) => {
  const { itemId, refreshCallback } = props;

  const addRef = useRef();

  useAccessibilityClick(addRef, () => {
    const doAdd = async () => {
      await itemBankService.addItemToLesson(itemId);
      if (refreshCallback) {
        await refreshCallback();
      }
    };
    ReactTooltip.hide();
    doAdd();
  });

  return (
    <>
      <ReactTooltip border effect='solid' type='light' />
      <div ref={addRef} aria-label='Place in assessment' className='add-to-assessment-button' data-tip='Place in assessment.'
        data-tool='quick add' />
    </>
  );
};

export default AddItemToAssessmentButton;
