import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import ReactModal from 'react-modal-resizable-draggable';
import { register } from '../../i18n';
import Loader from './Loader';
import responseService from '../services/ResponseService';
import UtilsService from '../services/UtilsService';
import HtmlComponent from './HtmlComponent';
import '../../css/HintDialog.scss';
import useReactModalResizeableDraggable from '../../hooks/useReactModalResizeableDraggable';

const t = register('GlobalQuestionLabels')

const HintDialog = observer((props) => {
  const {
    lessonManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  const { lessonElementId } = props;

  const model = lessonManager.getSlideModel(lessonElementId);
  const title = `${t('hint')}`;
  const id = `${lessonElementId}_hintdialog`;

  useEffect(() => {
    const dialog = document.getElementById(id);
    const flexibleModal = dialog.querySelector('.flexible-modal');
    if (dialog && flexibleModal) {
      const windowWidth = window.innerWidth;
      const styleString = `width: 500px; height: 200px; top: 0px; left: ${windowWidth - 515}px;`;
      flexibleModal.setAttribute('style', styleString);
      dialog.setAttribute('style', '');
    }
  });
  const isOpen = questionFeedbackManager.openHintDialog && (lessonElementId === lessonManager.currentLessonElementId);

  useReactModalResizeableDraggable('hint-dialog');

  return (

    <div className='hint-dialog theme-container' id={id} style={{ display: 'none' }}>

      <ReactModal
        initHeight={200}
        initWidth={500}
        isOpen={isOpen}
        left={0}
        top={0}>
        <div className='hint-dialog-body'>
          {(!UtilsService.isNullOrEmpty(model.hint)) ? (
            <>
              <div className='hint-header'>
                <div className='title-label basic'>{title}</div>
                <div className='x-close-button mask-image' id='modal-close' onClick={() => { responseService.setHintDialogOpen(false); }} />
              </div>
              <div className='scroll-area'>
                <HtmlComponent htmlStr={model.hint} />
              </div>
            </>
          )
            :
            <div className='dialog-loader'><Loader /></div>}
        </div>
      </ReactModal>
    </div>
  );
});

export default HintDialog;
