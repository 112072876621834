import React, { useEffect, useState } from 'react';
import Moveable from 'react-moveable';
import '../../../css/Tools.scss';

function Reveal(props) {
  const [target, setTarget] = useState();
  // eslint-disable-next-line no-unused-vars
  const [frame, setFrame] = useState({
    translate: [0, 0],
  });
  useEffect(() => {
    setTarget(document.querySelector('.revealTool'));
  }, []);

  return (

    <div className='revealContainer'>
      <div className='revealTool' />
      <Moveable
        edge={true}
        keepRatio={false}
        onResize={(e) => {
          const { beforeTranslate } = e.drag;

          frame.translate = beforeTranslate;
          e.target.style.width = `${e.width}px`;
          e.target.style.height = `${e.height}px`;
          e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
        }}
        onResizeStart={(e) => {
          e.setOrigin(['%', '%']);
          e.dragStart && e.dragStart.set(frame.translate);
        }}
        origin={false}
        padding={{ left: 0, top: 20, right: 0, bottom: 0 }}
        renderDirections={['n']}
        resizable={true}
        target={target}
        throttleResize={0}
        zoom={1}
      />
    </div>

  );
}

export default Reveal;
