import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import ReactModal from 'react-modal-resizable-draggable';
import classNames from 'classnames';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import '../../../css/Tools.scss';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';
import { register } from '../../../i18n';

const t = register('ToolNames')

const TextHelpCustom = observer(() => {
// function TextHelpCustom() {

  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const [isSpeaking, setIsSpeaking] = useState(toolbarManager.textHelpApi.speechTools.isSpeaking());
  const [isClickToSpeakOn, setIsClickToSpeakOn] = useState(toolbarManager.textHelpApi.speechTools.getClickToSpeakState());

  const stopRef = useRef();
  const playRef = useRef();
  const pauseRef = useRef();
  const clickToSpeakRef = useRef();
  const startLeft = (window.innerWidth - 275);

  useAccessibilityClick(stopRef, (e) => {
    toolbarManager.textHelpApi.speechTools.stop();
    setSpeechToolStates();
  });

  useAccessibilityClick(playRef, (e) => {
    // if click to speak is on, shut it off before playing.
    const clickToSpeakState = toolbarManager.textHelpApi.speechTools.getClickToSpeakState();
    if (clickToSpeakState) {
      toolbarManager.textHelpApi.speechTools.clickToSpeak();
    }
    toolbarManager.textHelpApi.speechTools.play();
    toolbarManager.addContentToolUsage('TextHelp');
    setSpeechToolStates();
  });

  useAccessibilityClick(pauseRef, (e) => {
    toolbarManager.textHelpApi.speechTools.pause();
  });

  useAccessibilityClick(clickToSpeakRef, (e) => {
    const isSpeaking = toolbarManager.textHelpApi.speechTools.isSpeaking();
    if (isSpeaking) {
      toolbarManager.textHelpApi.speechTools.stop();
    }
    toolbarManager.textHelpApi.speechTools.clickToSpeak();
    setSpeechToolStates();
  });

  function setSpeechToolStates() {
    setIsClickToSpeakOn(toolbarManager.textHelpApi.speechTools.getClickToSpeakState());
    setIsSpeaking(toolbarManager.textHelpApi.speechTools.isSpeaking());
  }

  useReactModalResizeableDraggable('textHelpCustom');

  console.log(isClickToSpeakOn);
  console.log(isSpeaking);

  return (
    <div className='textHelpCustom'>
      <ReactModal
        className='textHelpModal'
        disableResize={true}
        initHeight={62}
        initWidth={155}
        isOpen={true}
        left={startLeft}
        top={100}>
        <div className='selectorBody'>
          <div className='title-label basic'>{t('textHelpTitle')}</div>
          <div className='control-container'>
            <div className='control-section'>
              <button ref={clickToSpeakRef}
                className={classNames({
                  clickToSpeakButton: true,
                  listenIcon: true,
                  isActive: (isClickToSpeakOn)
                })} />
              <button ref={playRef}
                className={classNames({
                  playButton: true,
                  listenIcon: true,
                  isActive: (isSpeaking)
                })} />
              <button ref={pauseRef} className='pauseButton listenIcon' />
              <button ref={stopRef} className='stopButton listenIcon' />
              {/* <button ref={clickToSpeakRef} className='stopButton listenIcon listenControl'></button> */}
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
});

export default TextHelpCustom;
