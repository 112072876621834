import { fabric } from 'fabric';
import CanvasOrganizerService from './CanvasOrganizerService';
import UtilsService from './UtilsService';

export class BubbleWebService extends CanvasOrganizerService {
  RADIUS=72;
  ORBIT_RADIUS=225;
  ELLIPSE_RADIUS_X= 70;
  ELLIPSE_RADIUS_Y= 35;
  ENTRY_BOX_WIDTH= 100;
  ENTRY_BOX_HEIGHT= 25;

  getCanvasWidth = () => {
    return this.CANVAS_WIDTH;
  }

  getCanvasHeight = () => {
    return this.CANVAS_HEIGHT;
  }

  showBoxes = (model, width, height) => {
    // this.clearCanvas();

    // Draw circle and center box
    const radius = this.RADIUS;
    const centralCircleLeft = (width / 2) - radius;
    const centralCircleTop = (height / 2) - radius;
    const centerLineColor = model.centerText ? 'black' : this.STROKE;
    const centralLineStroke = model.centerText ? this.LINE_STROKE_MED : this.LINE_STROKE;
    const centralCircle = this.drawCircle(centralCircleLeft, centralCircleTop, radius, true, null, null, centerLineColor, centralLineStroke);
    const centerX = width / 2;
    const centerY = height / 2;

    const numBoxes = model.circleCount;

    for (let i = 0; i < numBoxes; i++) {
      const x = centerX - this.RADIUS + this.ORBIT_RADIUS * Math.cos(2 * Math.PI * i / numBoxes);
      const y = centerY - this.RADIUS + this.ORBIT_RADIUS * Math.sin(2 * Math.PI * i / numBoxes);
      const lineColor = (!UtilsService.isNullOrEmpty(model.circleText[i])) ? 'black' : this.STROKE;
      const lineStroke = (!UtilsService.isNullOrEmpty(model.circleText[i])) ? this.LINE_STROKE_MED : this.LINE_STROKE;
      const thisCircle = this.drawCircle(x, y, radius, true, null, null, lineColor, lineStroke);
      this.drawArrowBetweenCircles(centralCircle, thisCircle);
    }
  };

  drawCircle = (left, top, radius, hasInput, noFill, fill, stroke, strokeWidth, opacity) => {
    fill = fill || this.FILL;
    stroke = stroke || this.STROKE;
    strokeWidth = strokeWidth || this.LINE_STROKE;
    opacity = opacity || this.OPACITY;

    if (noFill) {
      fill = 'rgba(0,0,0,0)';
    }
    const circle = new fabric.Circle({
      type: 'circle',
      left,
      top,
      strokeWidth,
      radius,
      fill,
      stroke,
      opacity
    });

    circle.hasInput = hasInput;
    circle.hasControls = false;
    circle.hasBorders = false;
    circle.lockMovementX = true;
    circle.lockMovementY = true;
    circle.selectable = false;

    this.canvas.add(circle);

    return circle;
  }

  drawArrowBetweenCircles = (circle1, circle2) => {
    // x = cx + r * cos(a)
    // y = cy + r * sin(a)

    const cx1 = circle1.left + circle1.radius;
    const cy1 = circle1.top + circle1.radius;
    const cx2 = circle2.left + circle2.radius;
    const cy2 = circle2.top + circle2.radius;

    const xVect = cx2 - cx1;
    const yVect = cy2 - cy1;
    const hyp = Math.sqrt(xVect * xVect + yVect * yVect);

    const px1 = cx1 + circle1.radius * xVect / hyp;
    const py1 = cy1 + circle1.radius * yVect / hyp;
    const px2 = cx2 - circle2.radius * xVect / hyp;
    const py2 = cy2 - circle2.radius * yVect / hyp;

    this.drawArrow(px1, py1, px2, py2);
  }

  createCanvas = (model) => {
    const width = this.CANVAS_WIDTH;
    const height = this.CANVAS_HEIGHT;
    const settings = {
      width,
      height,
    };
    if (!fabric.LineArrow) {
      this.createArrowClass();
    }
    this.canvas = new fabric.Canvas(this.getCanvasId(model.lessonElementId), settings);
  }

  getCanvasId = (id) => {
    return `${id}_canvas`;
  };
}

export default new BubbleWebService();
