import { useEffect } from 'react';
import styleService from '../lessonPlayer/services/StyleService';

function useStyleEvents(lessonElementId, containerId = `${lessonElementId}-standalone-wrapper`) {
  useEffect(() => {
    // Create click event listeners for ".RevealText" dom elements.
    styleService.initRevealTextEventListener(lessonElementId, containerId);

    // Remove click event listeners for ".RevealText" dom elements when unmounting the component.
    return () => {
      styleService.removeRevealTextEventListener(containerId);
    };
  }, []);
}

export default useStyleEvents;
