import alignmentManager from '../managers/AlignmentManager';
import Auth from './AuthService';
import lessonService from './LessonService';
import lessonManager from '../managers/LessonManager';
import UtilsService from './UtilsService';

export class AlignmentService {
  async fetchServerLessonAlignmentData(lessonContentItemId, courseContentItemId, activityId) {
    if (alignmentManager.notFetched) {
      try {
        const url = `${Auth.ecms}/api/viewActiveContentAlignments?contentItemId=${lessonContentItemId}&courseId=${courseContentItemId}&activityId=${activityId}&differentiationReasonId=`;

        const response = await Auth.fetch(url, {
          method: 'GET',
        });
        if (response.status === 'SUCCESS') {
          alignmentManager.setServerAlignments(response.lessonAlignments);
          const treeObject = this.convertToTreeDataSource(response.lessonAlignments, this.convertAlignmentToNode, 'lessonAlignments');
          if (treeObject) {
            alignmentManager.setTreeStructure(treeObject);
            Object.entries(treeObject).map((node) => {
              if (node[1].hasChildren) {
                alignmentManager.addToExpandedItems(node[1].index);
              }
              return node;
            });
          }
        } else {
          alignmentManager.notFetched = false;
          console.log(response.status);
          console.log(response.statusMessage);
        }
      } catch (e) {
        alignmentManager.notFetched = false;
        console.log(e);
      }
      alignmentManager.notFetched = false;
    }
  }

  fetchContentItemAlignments = async (lessonElementId, courseId, activityId, contentItemId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    const skipAlignments = (model.isTitlePage || model.isSummaryPage);
    if (!skipAlignments && !alignmentManager.alreadyFetched(lessonElementId)) {
      // putting new lines inside the curly braces so they don't get picked
      // up by the string template. This is more efficient than using "\" or "+"

      const url = `${Auth.ecms}/api/viewContentAlignments?entityId=${
        lessonElementId}&contentItemId=${contentItemId}&activityId=${
        (activityId) || ''}&courseId=${(courseId || '')}`;

      try {
        const response = await Auth.fetch(url, {
          method: 'GET'
        });
        if (response.status === 'SUCCESS') {
          const { contentAlignments } = response;
          contentAlignments.forEach((cmapAlignment) => {
            const cmapElements = cmapAlignment.curriculumMapElements;
            cmapElements.sort((a, b) => {
              let aStr = a.type;
              if (a.subCategoryName) {
                aStr += a.subCategoryName;
              }
              if (a.name) {
                aStr += a.name.toUpperCase();
              }
              let bStr = b.type;
              if (b.subCategoryName) {
                bStr += b.subCategoryName;
              }
              if (b.name) {
                bStr += b.name.toUpperCase();
              }
              return ((aStr < bStr) ? -1 : ((aStr > bStr) ? 1 : 0));
            });
          });
          const alignmentCoveragePaths = [];
          contentAlignments.forEach((contentAlignment) => {
            if (contentAlignment.curriculumMapElements) {
              contentAlignment.curriculumMapElements.forEach((curriculumMapElement) => {
                const alignmentCoveragePath = `${contentAlignment.courseContentItemId}_${contentAlignment.curriculumMapId}_${curriculumMapElement.id}`;
                alignmentCoveragePaths.push(alignmentCoveragePath);
              });
            }
          });

          alignmentManager.setContentAlignmentCoveragePaths(lessonElementId, alignmentCoveragePaths);
        } else {
          console.warn(response.status);
          console.warn(response.statusMessage);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  fetchHistoricalContentAlignments = async (contentItemId, reasonId, courseContentItemId) => {
    const url = `${Auth.ecms}/api/viewHistoricalContentAlignments?contentItemId=${
      contentItemId}&differentiationReasonId=${reasonId}`;

    try {
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        let filteredAlignments = [];
        if (!UtilsService.isNullOrEmpty(courseContentItemId) && response.lessonAlignments) {
          response.lessonAlignments.forEach((alignment, index) => {
            if (alignment.courseContentItemId === courseContentItemId) {
              filteredAlignments.push(alignment);
            }
          });
        } else {
          if (response.lessonAlignments) {
            filteredAlignments = response.lessonAlignments;
          }
        }

        alignmentManager.setLessonCoverageAlignments(filteredAlignments);

        const treeObject = this.convertToTreeDataSource(filteredAlignments, this.convertAlignmentToNode, 'lessonAlignments');
        if (treeObject) {
          alignmentManager.setLessonTreeStructure(treeObject);
          Object.entries(treeObject).map((node) => {
            if (node[1].hasChildren) {
              alignmentManager.addToLessonExpandedItems(node[1].index);
            }
            return node;
          });
        }
      } else {
        console.warn(response.status);
        console.warn(response.statusMessage);
      }
    } catch (e) {
      console.error(e);
    }
  }

  openCmapDialog = (flag) => {
    alignmentManager.setCmapDetailDialogIsOpen(flag);
  }

  async getCmapDetail(cmapContentItemId, elementPath) {
    try {
      alignmentManager.setCmapLoaded(false);
      alignmentManager.setCmapContentItem({});
      alignmentManager.setCmapResource({});
      if (alignmentManager.serverCmapElements.length > 0) {
        alignmentManager.serverCmapElements = [];
      }

      const cmapContentItem = await lessonService.fetchContentItem(cmapContentItemId);
      const cmapResource = await lessonService.fetchResourceItem(cmapContentItemId);

      if (cmapContentItem && cmapResource) {
        alignmentManager.setCmapContentItem(cmapContentItem);
        alignmentManager.setCmapResource(cmapResource);
      } else {
        console.log('Cmap or cmap resource not found');
        return;
      }

      const response = await this.loadCmapElementsByParent(cmapContentItemId, '');

      alignmentManager.serverCmapElements = alignmentManager.serverCmapElements.concat(response.curriculumMapElements);

      await this.loadDetailsProgressive(response, cmapContentItemId, elementPath);
      alignmentManager.setCmapLoaded(true);
    } catch (e) {
      console.log(e);
    }
  }

  async loadCmapElementsByParent(cmapContentItemId, curriculumMapElementId) {
    try {
      const url = `${Auth.ecms}/api/viewCurriculumMapElementsByParent?cmapContentItemId=${cmapContentItemId}&curriculumMapElementId=${curriculumMapElementId}`;
      const response = await Auth.fetch(url, {
        method: 'GET',
      });
      if (response.status === 'SUCCESS') {
        return response;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async loadDetailsProgressive(response, cmapContentItemId, elementPath) {
    // Load children of ROOT
    const rootNodeId = response.curriculumMapElements[0].id;
    const rootNode = response.curriculumMapElements[0];

    const jsonChildData = await this.loadElementChildren(rootNodeId, null);

    const resourceData = jsonChildData.curriculumMapElements[0];
    const unitsData = jsonChildData.curriculumMapElements[1];
    const resourcesNodeId = resourceData.id;
    const unitsNodeId = unitsData.id;

    resourceData.type = 'RESOURCES_BUCKET';
    const { name } = alignmentManager.cmapContentItem;
    const { friendlyName } = alignmentManager.cmapContentItem;

    resourceData.name = name;
    if (friendlyName && friendlyName.length > 0) {
      resourceData.displayName = friendlyName;
    } else {
      resourceData.displayName = name;
    }

    alignmentManager.expandedCmapItems.push(rootNodeId);
    alignmentManager.setCmapContentItemResourceNodeId(resourcesNodeId);

    const jsonData = await this.loadElementChildren(unitsNodeId, rootNode);
    alignmentManager.expandedCmapItems.push(unitsNodeId);
    await this.buildPath(elementPath, jsonData);
    const treeObject = this.convertToTreeDataSource(alignmentManager.serverCmapElements, this.convertCmapElementToNode, 'curriculumMapElements');
    alignmentManager.setCmapTreeStructure(treeObject);
  }

  buildPath = async (elementPath, jsonData) => {
    const nodeId = elementPath.shift();
    let elementObject = null;
    if (jsonData.curriculumMapElements && jsonData.curriculumMapElements.length > 0) {
      jsonData.curriculumMapElements.forEach((element) => {
        if (element.id === nodeId) {
          elementObject = element;
        }
      });
    }

    if (elementObject === null) {
      return;
    }

    const hasChildren = elementObject.curriculumMapElements && elementObject.curriculumMapElements.length > 0;

    if (hasChildren) {
      if (elementPath.length === 0) {
        elementObject.highlight = true;
      } else {
        await this.buildPath(elementPath, elementObject);
      }
    } else {
      const nextJsonData = await this.loadElementChildren(nodeId, elementObject);
      alignmentManager.expandedCmapItems.push(nodeId);

      if (elementPath.length === 0) {
        elementObject.highlight = true;
      } else {
        await this.buildPath(elementPath, nextJsonData);
      }
    }
  }

  async loadElementChildren(nodeId, elementObject) {
    const jsonData = await this.loadCmapElementsByParent(alignmentManager.cmapContentItem.id, nodeId);

    // If there are child records returned, update the datasource element
    if (jsonData.curriculumMapElements.length > 0 && elementObject) {
      if (elementObject.curriculumMapElements && elementObject.curriculumMapElements.length > 0) // (elementObject.hasChildren)
      {
        if (nodeId === alignmentManager.cmapContentItem.resourcesNodeId) {
          this.updateNestedElementProperty(elementObject, 'type', 'SECTION', 'INFO_SECTION');
        }

        elementObject.children = jsonData.curriculumMapElements;
        elementObject.curriculumMapElementCount += jsonData.curriculumMapElements.length;
        elementObject.curriculumMapElements = elementObject.curriculumMapElements.concat(jsonData.curriculumMapElements);
      } else if (elementObject) {
        elementObject.curriculumMapElementCount = jsonData.curriculumMapElements.length;

        elementObject.hasChildren = (elementObject.curriculumMapElementCount > 0);
        elementObject.isFolder = (elementObject.curriculumMapElementCount > 0);

        const CurriculumMapElements = [];
        jsonData.curriculumMapElements.forEach((curriculumMapElement) => {
          CurriculumMapElements.push(curriculumMapElement);
        });
        elementObject.curriculumMapElements = CurriculumMapElements;
      }
    }
    return jsonData;
  }

  updateNestedElementProperty = (jsonObject, property, value1, value2) => {
    const children = jsonObject.curriculumMapElements || jsonObject.children;

    if (children) {
      children.forEach((child) => {
        if (child[property] === value1) {
          child[property] = value2;
        }
        this.updateNestedElementProperty(child, property, value1, value2);
      });
    }
    return jsonObject;
  }

  convertToTreeDataSource = (hierarchyData, conversionFunction, childField) => {
    if (!hierarchyData || hierarchyData.length < 1) {
      return null;
    }

    let treeObject = {};
    let treeRoot = null;

    if (hierarchyData.length === 1) {
      treeRoot = conversionFunction(hierarchyData[0], true);
      treeObject['root'] = treeRoot;
      if (treeRoot.hasChildren) {
        treeObject = this.flattenAlignmentChildren(treeObject, treeRoot, hierarchyData[0][childField], conversionFunction, childField);
      }
    } else {
      treeRoot = {
        index: 'root',
        hasChildren: true,
        isFolder: true,
        children: [],
        data: {
          id: 'root',
          name: '',
          type: 'root',

        }
      };
      treeObject['root'] = treeRoot;
      treeObject = this.flattenAlignmentChildren(treeObject, treeRoot, hierarchyData, conversionFunction, childField);
    }
    return treeObject;
  }

  flattenAlignmentChildren = (treeObject, currentNode, children, conversionFunction, childField) => {
    for (let x = 0; x < children.length; ++x) {
      currentNode.children.push(children[x].id);
      const node = conversionFunction(children[x], false);
      treeObject[node.index] = node;
      if (node.hasChildren && children[x][childField]) {
        this.flattenAlignmentChildren(treeObject, node, children[x][childField], conversionFunction, childField);
      }
    }
    return treeObject;
  }

  convertAlignmentToNode = (alignment, isRoot) => {
    const elementPath = [];

    let foundRoot = false;
    if (alignment.contextPath && alignment.contextPath.length > 0) {
      for (let x = alignment.contextPath.length - 1; x > -1; --x) {
        const item = alignment.contextPath[x];
        if (item.type === 'UNIT') {
          foundRoot = true;
        }
        // add everything after the unit in reverse order
        if (foundRoot) {
          elementPath.push(item.id);
        }
      }
    }

    const node = {
      index: (isRoot) ? 'root' : alignment.id,
      hasChildren: (alignment.lessonAlignments.length > 0),
      isFolder: (alignment.lessonAlignments.length > 0),
      children: ((alignment.lessonAlignments.length > 0) ? [] : null),
      data: {
        id: alignment.id,
        name: alignment.name,
        type: alignment.type,
        curriculumMapElementEntityId: alignment.curriculumMapElementEntityId,
        curriculumMapElementEntityTypeId: alignment.curriculumMapElementEntityTypeId,
        coveragePath: alignment.coveragePath,
        description: alignment.description,
        subCategoryId: alignment.subCategoryId,
        subCategoryName: alignment.subCategoryName,
        curriculumMapId: alignment.curriculumMapId,
        curriculumMapName: alignment.curriculumMapName,
        friendlyName: alignment.curriculumMapFriendlyName,
        cmapContentItemId: alignment.cmapContentItemId,
        courseResourceId: alignment.courseResourceId,
        courseContentItemId: alignment.courseContentItemId,
        curriculumMapElementId: alignment.curriculumMapElementId,
        alignerId: alignment.alignerId,
        alignerDisplayName: alignment.alignerDisplayName,
        covered: alignment.covered,
        contextPath: alignment.contextPath,
        elementPath
      }
    };
    return node;
  }

  convertCmapElementToNode = (cmapElement) => {
    const node = {
      index: (cmapElement.type === 'ROOT') ? 'root' : cmapElement.id,
      hasChildren: (cmapElement.curriculumMapElements && cmapElement.curriculumMapElements.length > 0),
      isFolder: (cmapElement.curriculumMapElements && cmapElement.curriculumMapElements.length > 0),
      children: ((cmapElement.curriculumMapElements && cmapElement.curriculumMapElements.length > 0) ? [] : null),
      data: {
        aligned: cmapElement.aligned,
        alignmentCount: cmapElement.alignmentCount,
        alignmentUsageType: cmapElement.alignmentUsageType,
        categoryTagIds: cmapElement.categoryTagIds,
        color: cmapElement.color,
        contextPath: cmapElement.contextPath,
        crosswalkAction: cmapElement.crosswalkAction,
        crosswalkDate: cmapElement.crosswalkDate,
        crosswalkNote: cmapElement.crosswalkNote,
        crosswalkSeriesId: cmapElement.crosswalkSeriesId,
        crosswalkType: cmapElement.crosswalkType,
        crosswalkUserId: cmapElement.crosswalkUserId,
        crosswalkUserName: cmapElement.crosswalkUserName,
        curriculumMapElementCount: cmapElement.curriculumMapElementCount,
        curriculumMapId: cmapElement.curriculumMapId,
        decodedDescription: cmapElement.decodedDescription,
        descrString: cmapElement.descrString,
        description: cmapElement.description,
        displayName: cmapElement.displayName,
        entityId: cmapElement.entityId,
        entityTypeId: cmapElement.entityTypeId,
        hasAlignments: cmapElement.hasAlignments,
        hasChildren: cmapElement.hasChildren,
        isFolder: cmapElement.hasChildren,
        hasDescendantAlignments: cmapElement.hasDescendantAlignments,
        hasDescendantWithoutAlignments: cmapElement.hasDescendantWithoutAlignments,
        id: cmapElement.id,
        instructionalDays: cmapElement.instructionalDays,
        instructionalDaysHidden: cmapElement.instructionalDaysHidden,
        name: cmapElement.name,
        noteBody: cmapElement.noteBody,
        noteColor: cmapElement.noteColor,
        noteTitle: cmapElement.noteTitle,
        orderNum: cmapElement.orderNum,
        originElementId: cmapElement.originElementId,
        originEntityId: cmapElement.originEntityId,
        originEntityTypeId: cmapElement.originEntityTypeId,
        originStandardFrameworkId: cmapElement.originStandardFrameworkId,
        originState: cmapElement.originState,
        pages: cmapElement.pages,
        parentElementId: cmapElement.parentElementId,
        relativeOrderNum: cmapElement.relativeOrderNum,
        resourceCount: cmapElement.resourceCount,
        resourceStoreJson: cmapElement.resourceStoreJson,
        standardFrameworkId: cmapElement.standardFrameworkId,
        state: cmapElement.state,
        subCategoryId: cmapElement.subCategoryId,
        subCategoryName: cmapElement.subCategoryName,
        subtitle: cmapElement.subtitle,
        type: cmapElement.type,
        unitHidden: cmapElement.unitHidden,
        unitName: cmapElement.unitName,
        usageType: cmapElement.usageType,
        usageTypeId: cmapElement.usageTypeId,
        weight: cmapElement.weight,
      }
    };
    return node;
  }

  async fetchContentItemsTaggedStandards(contentItemIds) {
    try {
      const idString = contentItemIds.toString();
      const url = `${Auth.ecms}/api/viewAllContentStandardsAndTags?contentItemIds=${idString}`;

      const response = await Auth.fetch(url, {
        method: 'GET',
      });
      if (response.status === 'SUCCESS') {
        alignmentManager.setTaggedStandards(response.standards);
      }
    } catch (e) {
      alignmentManager.notFetched = false;
      console.log(e);
    }
    alignmentManager.notFetched = false;
  }
}
export default new AlignmentService();
