import { action, makeObservable, observable } from 'mobx';

export class PublisherManager {
    @observable publisherId = null;
    @observable publisherName = null;
    @observable hasDocReaderId = false;
    @observable hasWebReaderId = false;
    @observable hasWebSpellCheckerId = false;
    @observable hasTextHelpId = false;
    @observable docReaderId = null;
    @observable webReaderId = null;
    @observable textHelpId = null;
    @observable webSpellCheckerId = null;
    @observable rsHref = null;

    constructor() {
      makeObservable(this);
    }

    @action
    setRsRef= (url) => {
      this.rsHref = url;
    }

    @action setPublisherOptionsFromResponse(response) {
      this.setDocReaderId(response.docReaderId);
      this.setHasDocReaderId(response.hasDocReaderId);
      this.setHasTextHelpId(response.hasTextHelpId);
      this.setHasWebReaderId(response.hasWebReaderId);
      this.setHasWebSpellCheckerId(response.hasWebSpellCheckerId);
      this.setPublisherId(response.publisherId);
      this.setPublisherName(response.publisherName);
      this.setTextHelpId(response.textHelpReactId);
      this.setWebReaderId(response.webReaderId);
      this.setWebSpellCheckerId(response.webSpellCheckerId);
    }

    @action setPublisherId(val) {
      this.publisherId = val;
    }

    @action setPublisherName(val) {
      this.publisherName = val;
    }

    @action setHasDocReaderId(val) {
      this.hasDocReaderId = val;
    }

    @action setHasWebReaderId(val) {
      this.hasWebReaderId = val;
    }

    @action setHasWebSpellCheckerId(val) {
      this.hasWebSpellCheckerId = val;
    }

    @action setHasTextHelpId(val) {
      this.hasTextHelpId = val;
    }

    @action setDocReaderId(val) {
      this.docReaderId = val;
    }

    @action setWebReaderId(val) {
      this.webReaderId = val;
    }

    @action setTextHelpId(val) {
      this.textHelpId = val;
    }

    @action setWebSpellCheckerId(val) {
      this.webSpellCheckerId = val;
    }
}
export default new PublisherManager();
