import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import itemBankService from '../services/ItemBankService';

const AddSelectedItemsToAssessmentButton = (props) => {
  const addRef = useRef();

  useAccessibilityClick(addRef, () => {
    const doAdd = async () => {
      await itemBankService.processAddItemsToLesson();
    };
    ReactTooltip.hide();
    doAdd();
  });

  return (
    <>
      <ReactTooltip border effect='solid' type='light' />
      <div ref={addRef} aria-label='Place selected items in assessment'
        className='add-to-assessment-button' data-tip='Place selected items in assessment.'
        data-tool='quick add' />
    </>
  );
};

export default AddSelectedItemsToAssessmentButton;
