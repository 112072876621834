import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { toJS } from 'mobx';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LessonMode } from '../../../Constants';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import FeedbackIconSimple from '../FeedbackIconSimple';
import responseService from '../../services/ResponseService';
import Guideline from '../tools/Guideline';

import '../../../css/OrderList.scss';
import playerService from '../../services/PlayerService';
import useStyleEvents from '../../../hooks/useStyleEvents';
import navMenuService from '../../services/NavMenuService';
import PrintQuestionNumber from './PrintQuestionNumber';

function OrderPrompt({ model, showAnswers, orderPrompt, index }) {
  // let classes = 'orderListPrompt draggable';
  let isCorrect = false;

  if (showAnswers) {
    // classes = 'orderListPrompt correct-answer-symbol';
    const correctAnswerId = model.validation?.prompts[index];
    const submittedAnswerId = orderPrompt.id;
    isCorrect = (correctAnswerId === submittedAnswerId);
  }

  return (
    <Draggable draggableId={orderPrompt.id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef}

          className={classNames({
            'orderListPrompt': true,
            'draggable': true,
            'correct-answer-symbol': (showAnswers && isCorrect),
            'incorrect': (showAnswers && !isCorrect),
            'duringDrag': snapshot.isDragging,
            'duringDragOver': provided.isDraggingOver
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {showAnswers && <FeedbackIconSimple isCorrect={isCorrect} />}
          <HtmlComponent htmlStr={orderPrompt.text} />
        </div>
      )}
    </Draggable>
  );
}

const OrderPromptList = ({ model, showAnswers, orderPrompts }) => {
  return orderPrompts?.map((orderPrompt, index) => {
    return (
      <OrderPrompt key={orderPrompt.id} index={index} model={model} orderPrompt={orderPrompt}
        showAnswers={showAnswers} />
    );
  }) || null;
};

const OrderList = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  // TODO unused // const readOnly = lessonElementState.readOnly || lessonManager.isReadOnly;

  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
  const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;
  const showAnswers = (feedbackState && showAnswerFeedback);

  const [orderListItems, setOrderListItems] = useState(toJS(lessonElementState.currentResponseAnswer.promptObjects));

  useEffect(() => {
    // OrderList items are sometimes correctly ordered when first displayed (due to a Lesson Editor bug that has been fixed).
    // Allow the initial ordering to be used as the student's response.
    responseService.responseChangeHandler(lessonElementState.currentResponseAnswer.promptObjects, lessonElementId);
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (playerService.isReadOnly(lessonElementId)) {
      return;
    }

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderPrompts = reorder(
      lessonElementState.currentResponseAnswer.promptObjects,
      result.source.index,
      result.destination.index
    );
    setOrderListItems(orderPrompts);
    responseService.responseChangeHandler(orderPrompts, lessonElementId);
  }

  useStyleEvents(lessonElementId);

  const navToCurrentLessonElement = async () => {
    if (lessonManager.currentLessonElementId !== lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
    }
  };

  return (
    <>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='promptWrapper' refresh-attr={questionFeedbackManager.getShowAnswers(lessonElementId).toString()}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={navToCurrentLessonElement}>
          <Droppable droppableId={`list_${lessonElementId}`} isDropDisabled={showAnswerFeedback}
            renderClone={(provided, snapshot, rubric) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                className={classNames({
                  orderListPrompt: true,
                  draggable: true,
                  duringDrag: snapshot.isDragging,
                  duringDragOver: provided.isDraggingOver
                })}
              >
                <HtmlComponent htmlStr={orderListItems[rubric.source.index].text} />
              </div>
            )}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <OrderPromptList model={model} orderPrompts={showAnswers ? lessonElementState.currentResponseAnswer.promptObjects : orderListItems} showAnswers={showAnswers} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
});
export default OrderList;
