import { action, makeObservable, observable } from 'mobx';

const FeedbackModeManualIndexes = ['msht', 'msso', 'mssb'];
const FeedbackModeFirstAttemptIndexes = ['a1bht', 'a1cht', 'a1cso', 'a1cca', 'a1iht', 'a1ica'];
const FeedbackModeSecondAttemptIndexes = ['a2bht', 'a2cht', 'a2cso', 'a2cca', 'a2iht', 'a2iso', 'a2ica'];
const FeedbackModeThirdAttemptIndexes = ['a3bht', 'a3bso', 'a3cht', 'a3cso', 'a3cca', 'a3iht', 'a3iso', 'a3ica'];
const FeedbackModeReviewIndexes = ['rmht', 'rmso', 'rmca'];

export const QFMFlagKeys = {
  msht: 'msht',
  msso: 'msso',
  a1bht: 'a1bht',
  a1cht: 'a1cht',
  a1cso: 'a1cso',
  a1cca: 'a1cca',
  a1iht: 'a1iht',
  a1ica: 'a1ica',
  a2bht: 'a2bht',
  a2cht: 'a2cht',
  a2cso: 'a2cso',
  a2cca: 'a2cca',
  a2iht: 'a2iht',
  a2iso: 'a2iso',
  a2ica: 'a2ica',
  a3bht: 'a3bht',
  a3bso: 'a3bso',
  a3cht: 'a3cht',
  a3cso: 'a3cso',
  a3cca: 'a3cca',
  a3iht: 'a3iht',
  a3iso: 'a3iso',
  a3ica: 'a3ica',
  rmht: 'rmht',
  rmso: 'rmso',
  rmca: 'rmca',
  mssb: 'mssb'
};

export class QuestionFeedbackMode {
  @observable
  modeId = false;

  @observable
  modeName = false;

  @observable
  isDefault= false;

  @observable
  testItemFeedback='immediate';

  @observable
  isPractice=false;

  @observable
  isTTSOverride=false;

  @observable
  practiceAttempts=0;

  @observable
  customPracticeMatrix= new Map();

  @observable
  correctFeedback = '';

  @observable
  incorrectFeedback='';

  @action
  setManualModeFlags = (flagArray) => {
    FeedbackModeManualIndexes.forEach((key, index) => {
      // this is backwards compatability protection.
      // In most cases this should do nothing.
      // We added a new flag and this should protect against old
      // lesson options that are missing the flag.
      if (key === 'mssb' && flagArray['mssb'] === undefined) {
        if (flagArray['msso'] === false && flagArray['msht'] === false) {
          flagArray['mssb'] = false;
        } else {
          flagArray['mssb'] = true;
        }
      }
      /// ////

      this.customPracticeMatrix.set(key, flagArray[key]);
    });
  }

  @action
  setFirstAttemptFlags = (flagArray) => {
    FeedbackModeFirstAttemptIndexes.forEach((key, index) => {
      this.customPracticeMatrix.set(key, flagArray[key]);
    });
  }

  @action
  setSecondAttemptFlags = (flagArray) => {
    FeedbackModeSecondAttemptIndexes.forEach((key, index) => {
      this.customPracticeMatrix.set(key, flagArray[key]);
    });
  }

  @action
  setThirdAttemptFlags = (flagArray) => {
    FeedbackModeThirdAttemptIndexes.forEach((key, index) => {
      this.customPracticeMatrix.set(key, flagArray[key]);
    });
  }

  @action
  setReviewModeFlags = (flagArray) => {
    FeedbackModeReviewIndexes.forEach((key, index) => {
      this.customPracticeMatrix.set(key, flagArray[key]);
    });
  }

  @action
  setStateFromOptions = (lessonOptions, modeId) => {
    const that = this;
    let mode = null;
    if (lessonOptions.feedbackModes && lessonOptions.feedbackModes.length > 0) {
      if (modeId) {
        mode = lessonOptions.feedbackModes.find((e) => e.modeId === modeId);
      } else if (lessonOptions.feedbackModeRequested) {
        mode = lessonOptions.feedbackModes.find((e) => e.modeId === lessonOptions.feedbackModeRequested);
      } else {
        mode = lessonOptions.feedbackModes.find((e) => e.isDefault);
      }

      if (mode) {
        that.modeId = (mode.modeId) ? mode.modeId : 'DEFAULT01';
        that.modeName = (mode.modeName) ? mode.modeName : 'DEFAULT_MODE';
        that.testItemFeedback = mode.testItemFeedback;
        that.isPractice = mode.isPractice;
        that.isTTSOverride = mode.isTTSOverride;
        that.practiceAttempts = (mode.practiceAttempts) ? parseInt(mode.practiceAttempts) : 1;
        that.correctFeedback = mode.correctFeedback;
        that.incorrectFeedback = mode.incorrectFeedback;

        const practiceArray = (mode.customPracticeMatrix) ?
          mode.customPracticeMatrix :
          {
            msht: true,
            msso: true,
            a1bht: true,
            a1cht: true,
            a1cso: true,
            a1cca: true,
            a1iht: true,
            a1ica: true,
            a2bht: true,
            a2cht: true,
            a2cso: true,
            a2cca: true,
            a2iht: true,
            a2iso: true,
            a2ica: true,
            a3bht: true,
            a3bso: true,
            a3cht: true,
            a3cso: true,
            a3cca: true,
            a3iht: true,
            a3iso: true,
            a3ica: true,
            rmht: true,
            rmso: true,
            rmca: true,
            mssb: true
          };

        that.setManualModeFlags(practiceArray);

        if (that.isPractice) {
          switch (that.practiceAttempts) {
          case 1: {
            that.setFirstAttemptFlags(practiceArray);
            break;
          }
          case 2: {
            that.setFirstAttemptFlags(practiceArray);
            that.setSecondAttemptFlags(practiceArray);
            break;
          }
          case 3: {
            that.setFirstAttemptFlags(practiceArray);
            that.setSecondAttemptFlags(practiceArray);
            that.setThirdAttemptFlags(practiceArray);
            break;
          }
          default: {
            break;
          }
          }
        }
        that.setReviewModeFlags(practiceArray);
      }
    }
  }

  constructor() {
    makeObservable(this);
  }
}
