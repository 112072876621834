import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import Guideline from '../tools/Guideline';
import GridWidget from './widgets/Grid';
import '../../../css/Presentation.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';

const Presentation = ({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);

  useStyleEvents(lessonElementId);

  return (
    <div className='presentation-wrapper'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question presentation' id={`${lessonElementId}-presentation-view`}>
        <GridWidget model={model.grid} />
      </div>
    </div>
  );
};
export default Presentation;
