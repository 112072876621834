import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import '../../css/StampLetterPicker.scss';
import { MobXProviderContext, observer } from 'mobx-react';
import { TOOL_TYPE } from '../managers/BookDrawManager';

const StampLetterPicker = observer((props) => {
  const {
    bookDrawManager
  } = useContext(MobXProviderContext);

  const AstampRef = useRef();
  const BstampRef = useRef();
  const CstampRef = useRef();
  const DstampRef = useRef();
  const EstampRef = useRef();
  const FstampRef = useRef();
  const GstampRef = useRef();
  const HstampRef = useRef();
  const IstampRef = useRef();
  const JstampRef = useRef();
  const KstampRef = useRef();
  const LstampRef = useRef();
  const MstampRef = useRef();
  const NstampRef = useRef();
  const OstampRef = useRef();
  const PstampRef = useRef();
  const QstampRef = useRef();
  const RstampRef = useRef();
  const SstampRef = useRef();
  const TstampRef = useRef();
  const UstampRef = useRef();
  const VstampRef = useRef();
  const WstampRef = useRef();
  const XstampRef = useRef();
  const YstampRef = useRef();
  const ZstampRef = useRef();
  const t1stampRef = useRef();
  const t2stampRef = useRef();
  const t3stampRef = useRef();
  const t4stampRef = useRef();
  const t5stampRef = useRef();
  const t6stampRef = useRef();
  const t7stampRef = useRef();
  const t8stampRef = useRef();
  const t9stampRef = useRef();
  const t10stampRef = useRef();
  const questionstampRef = useRef();
  const exclamationstampRef = useRef();
  const checkstampRef = useRef();
  const crossstampRef = useRef();
  const plusstampRef = useRef();
  const minusstampRef = useRef();

  const handleClick = (e) => {
    const { iconId } = e.target.dataset;
    bookDrawManager.setSelectedIcon(iconId);
    bookDrawManager.setTool(TOOL_TYPE.STAMPS);
  };

  useAccessibilityClick(AstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(BstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(CstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(DstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(EstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(FstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(GstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(HstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(IstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(JstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(KstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(LstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(MstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(NstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(OstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(PstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(QstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(RstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(SstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(TstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(UstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(VstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(WstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(XstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(YstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(ZstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t1stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t2stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t3stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t4stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t5stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t6stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t7stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t8stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t9stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(t10stampRef, (e) => { handleClick(e); });
  useAccessibilityClick(questionstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(exclamationstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(checkstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(crossstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(plusstampRef, (e) => { handleClick(e); });
  useAccessibilityClick(minusstampRef, (e) => { handleClick(e); });

  return (
    <div className={classNames({ stampLetterPicker: true, menuOpen: props.open })}>
      <div className='stampLetterPickerInner'>
        <div className='stampLetterGroup'>
          <div ref={AstampRef} className={classNames({ stampIcon: true, AStamp: true, selected: (bookDrawManager.selectedIcon === 'AStamp') })} data-icon-id='AStamp' title='AStamp' />
          <div ref={BstampRef} className={classNames({ stampIcon: true, BStamp: true, selected: (bookDrawManager.selectedIcon === 'BStamp') })} data-icon-id='BStamp' title='BStamp' />
          <div ref={CstampRef} className={classNames({ stampIcon: true, CStamp: true, selected: (bookDrawManager.selectedIcon === 'CStamp') })} data-icon-id='CStamp' title='CStamp' />
          <div ref={DstampRef} className={classNames({ stampIcon: true, DStamp: true, selected: (bookDrawManager.selectedIcon === 'DStamp') })} data-icon-id='DStamp' title='DStamp' />
          <div ref={EstampRef} className={classNames({ stampIcon: true, EStamp: true, selected: (bookDrawManager.selectedIcon === 'EStamp') })} data-icon-id='EStamp' title='EStamp' />
          <div ref={FstampRef} className={classNames({ stampIcon: true, FStamp: true, selected: (bookDrawManager.selectedIcon === 'FStamp') })} data-icon-id='FStamp' title='FStamp' />
          <div ref={GstampRef} className={classNames({ stampIcon: true, GStamp: true, selected: (bookDrawManager.selectedIcon === 'GStamp') })} data-icon-id='GStamp' title='GStamp' />
          <div ref={HstampRef} className={classNames({ stampIcon: true, HStamp: true, selected: (bookDrawManager.selectedIcon === 'HStamp') })} data-icon-id='HStamp' title='HStamp' />
          <div ref={IstampRef} className={classNames({ stampIcon: true, IStamp: true, selected: (bookDrawManager.selectedIcon === 'IStamp') })} data-icon-id='IStamp' title='IStamp' />
          <div ref={JstampRef} className={classNames({ stampIcon: true, JStamp: true, selected: (bookDrawManager.selectedIcon === 'JStamp') })} data-icon-id='JStamp' title='JStamp' />
          <div ref={KstampRef} className={classNames({ stampIcon: true, KStamp: true, selected: (bookDrawManager.selectedIcon === 'KStamp') })} data-icon-id='KStamp' title='KStamp' />
          <div ref={LstampRef} className={classNames({ stampIcon: true, LStamp: true, selected: (bookDrawManager.selectedIcon === 'LStamp') })} data-icon-id='LStamp' title='LStamp' />
          <div ref={MstampRef} className={classNames({ stampIcon: true, MStamp: true, selected: (bookDrawManager.selectedIcon === 'MStamp') })} data-icon-id='MStamp' title='MStamp' />
          <div ref={NstampRef} className={classNames({ stampIcon: true, NStamp: true, selected: (bookDrawManager.selectedIcon === 'NStamp') })} data-icon-id='NStamp' title='NStamp' />
          <div ref={OstampRef} className={classNames({ stampIcon: true, OStamp: true, selected: (bookDrawManager.selectedIcon === 'OStamp') })} data-icon-id='OStamp' title='OStamp' />
          <div ref={PstampRef} className={classNames({ stampIcon: true, PStamp: true, selected: (bookDrawManager.selectedIcon === 'PStamp') })} data-icon-id='PStamp' title='PStamp' />
          <div ref={QstampRef} className={classNames({ stampIcon: true, QStamp: true, selected: (bookDrawManager.selectedIcon === 'QStamp') })} data-icon-id='QStamp' title='QStamp' />
          <div ref={RstampRef} className={classNames({ stampIcon: true, RStamp: true, selected: (bookDrawManager.selectedIcon === 'RStamp') })} data-icon-id='RStamp' title='RStamp' />
          <div ref={SstampRef} className={classNames({ stampIcon: true, SStamp: true, selected: (bookDrawManager.selectedIcon === 'SStamp') })} data-icon-id='SStamp' title='SStamp' />
          <div ref={TstampRef} className={classNames({ stampIcon: true, TStamp: true, selected: (bookDrawManager.selectedIcon === 'TStamp') })} data-icon-id='TStamp' title='TStamp' />
          <div ref={UstampRef} className={classNames({ stampIcon: true, UStamp: true, selected: (bookDrawManager.selectedIcon === 'UStamp') })} data-icon-id='UStamp' title='UStamp' />
          <div ref={VstampRef} className={classNames({ stampIcon: true, VStamp: true, selected: (bookDrawManager.selectedIcon === 'VStamp') })} data-icon-id='VStamp' title='VStamp' />
          <div ref={WstampRef} className={classNames({ stampIcon: true, WStamp: true, selected: (bookDrawManager.selectedIcon === 'WStamp') })} data-icon-id='WStamp' title='WStamp' />
          <div ref={XstampRef} className={classNames({ stampIcon: true, XStamp: true, selected: (bookDrawManager.selectedIcon === 'XStamp') })} data-icon-id='XStamp' title='XStamp' />
          <div ref={YstampRef} className={classNames({ stampIcon: true, YStamp: true, selected: (bookDrawManager.selectedIcon === 'YStamp') })} data-icon-id='YStamp' title='YStamp' />
          <div ref={ZstampRef} className={classNames({ stampIcon: true, ZStamp: true, selected: (bookDrawManager.selectedIcon === 'ZStamp') })} data-icon-id='ZStamp' title='ZStamp' />
        </div>
        <div className='stampLetterGroup'>
          <div ref={t1stampRef} className={classNames({ stampIcon: true, OneStamp: true, selected: (bookDrawManager.selectedIcon === 'OneStamp') })} data-icon-id='1Stamp' />
          <div ref={t2stampRef} className={classNames({ stampIcon: true, TwoStamp: true, selected: (bookDrawManager.selectedIcon === 'TwoStamp') })} data-icon-id='2Stamp' />
          <div ref={t3stampRef} className={classNames({ stampIcon: true, ThreeStamp: true, selected: (bookDrawManager.selectedIcon === 'ThreeStamp') })} data-icon-id='3Stamp' />
          <div ref={t4stampRef} className={classNames({ stampIcon: true, FourStamp: true, selected: (bookDrawManager.selectedIcon === 'FourStamp') })} data-icon-id='4Stamp' />
          <div ref={t5stampRef} className={classNames({ stampIcon: true, FiveStamp: true, selected: (bookDrawManager.selectedIcon === 'FiveStamp') })} data-icon-id='5Stamp' />
          <div ref={t6stampRef} className={classNames({ stampIcon: true, SixStamp: true, selected: (bookDrawManager.selectedIcon === 'SixStamp') })} data-icon-id='6Stamp' />
          <div ref={t7stampRef} className={classNames({ stampIcon: true, SevenStamp: true, selected: (bookDrawManager.selectedIcon === 'SevenStamp') })} data-icon-id='7Stamp' />
          <div ref={t8stampRef} className={classNames({ stampIcon: true, EightStamp: true, selected: (bookDrawManager.selectedIcon === 'EightStamp') })} data-icon-id='8Stamp' />
          <div ref={t9stampRef} className={classNames({ stampIcon: true, NineStamp: true, selected: (bookDrawManager.selectedIcon === 'NineStamp') })} data-icon-id='9Stamp' />
          <div ref={t10stampRef} className={classNames({ stampIcon: true, TenStamp: true, selected: (bookDrawManager.selectedIcon === 'TenStamp') })} data-icon-id='10Stamp' />
        </div>
        <div className='stampLetterGroup'>
          <div ref={questionstampRef} className={classNames({ stampIcon: true, questionStamp: true, selected: (bookDrawManager.selectedIcon === 'questionStamp') })} data-icon-id='questionStamp' />
          <div ref={exclamationstampRef} className={classNames({ stampIcon: true, exclamationStamp: true, selected: (bookDrawManager.selectedIcon === 'exclamationStamp') })} data-icon-id='exclamationStamp' />
          <div ref={checkstampRef} className={classNames({ stampIcon: true, checkStamp: true, selected: (bookDrawManager.selectedIcon === 'checkStamp') })} data-icon-id='checkStamp' />
          <div ref={crossstampRef} className={classNames({ stampIcon: true, crossStamp: true, selected: (bookDrawManager.selectedIcon === 'crossStamp') })} data-icon-id='crossStamp' />
          <div ref={plusstampRef} className={classNames({ stampIcon: true, plusStamp: true, selected: (bookDrawManager.selectedIcon === 'plusStamp') })} data-icon-id='plusStamp' />
          <div ref={minusstampRef} className={classNames({ stampIcon: true, minusStamp: true, selected: (bookDrawManager.selectedIcon === 'minusStamp') })} data-icon-id='minusStamp' />
        </div>
      </div>
    </div>
  );
});

export default StampLetterPicker;
