/* eslint-disable indent, array-callback-return */
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import CanvasOrganizerService from './CanvasOrganizerService';
import Utils from './UtilsService';
import numberLineManager from '../managers/NumberLineManager';
import responseService from './ResponseService';

class ContentManager {
  isEditMode = () => {
    return true;
  }
}

class TestItem {
  static CORRECT_COLOR = '#80bd44';
  static INCORRECT_COLOR = '#bc4646';
}

export default class NumberLineService extends CanvasOrganizerService {
  constructor(model) {
    super();
    this.model = model;
    this.contentManager = new ContentManager();
  }

  model;
  canvas;

  contentManager;

  MAX_CHOICES = 3;
  HIGHEST_MAX_CHOICES = 10;
  TICK_LENGTH = 16;
  STACKED_UNIT_HEIGHT = 30;
  MIN_NUMBER_LINE_WIDTH = 300;
  MAX_NUMBER_LINE_WIDTH = 800;
  VERTICAL_INSET = 60;
  HORIZONTAL_INSET = 60;
  LINE_OUTSET = 20;
  TEXT_TOP_MARGIN = 4;
  FONT_SIZE = 16;
  TITLE_FONT_SIZE = 20;
  CONNECTOR_COLOR = '#3A9CFA';
  POINT_COLOR = '#3A9CFA';
  START_POINT_COLOR = this.POINT_COLOR;
  END_POINT_COLOR = this.POINT_COLOR;
  HOVER_COLOR = '#aaa'; // "#297ADC";
  SELECTED_COLOR = '#000'; // "#297ADC";

  RAY = 'ray';
  RAY_RIGHT_DIRECTION = 'ray_right_direction';
  RAY_RIGHT_DIRECTION_POINT_HOLLOW = 'ray_right_direction_point_hollow';
  RAY_LEFT_DIRECTION = 'ray_left_direction';
  RAY_LEFT_DIRECTION_POINT_HOLLOW = 'ray_left_direction_point_hollow';

  SEGMENT = 'segment';
  SEGMENT_LEFT_POINT_HOLLOW = 'segment_left_point_hollow';
  SEGMENT_RIGHT_POINT_HOLLOW = 'segment_right_point_hollow';
  SEGMENT_BOTH_POINTS_HOLLOW = 'segment_both_points_hollow';
  POINT = 'point';

  ELEMENT_TYPES = [
    this.POINT,
    this.SEGMENT,
    this.SEGMENT_LEFT_POINT_HOLLOW,
    this.SEGMENT_RIGHT_POINT_HOLLOW,
    this.SEGMENT_BOTH_POINTS_HOLLOW,
    this.RAY_RIGHT_DIRECTION,
    this.RAY_RIGHT_DIRECTION_POINT_HOLLOW,
    this.RAY_LEFT_DIRECTION,
    this.RAY_LEFT_DIRECTION_POINT_HOLLOW
  ];

  type = 'NumberLine';

  getLabel = () => {
    return 'numberLine';
  }

  // TODO
  isPreview = () => {
    return true;
  }

  isAutoScore = () => {
    return true;
  }

  getId = () => {
    return this.model.id;
  }

  getCanvasId = () => {
    return `${this.getId() }_canvas`;
  }

  initialize = () => {
    const settings = {
      height: this.getHeight(),
      width: this.model.graphWidth,
      selection: false,
    };
    this.canvas = new fabric.Canvas(this.getCanvasId(), settings);
    this.setCanvas(this.canvas);

    this.setDefaultEditMode();
    this.initializeNumberLine();
  }

  initializeNumberLine = () => {
    const self = this;

    self.dragging = false;
    this.model.maxChoices = this.model.maxChoices || this.MAX_CHOICES;
    this.model.graphWidth = this.model.graphWidth || this.NUMBER_LINE_WIDTH;

    this.model.minValue = this.model.minValue || 0;
    this.model.maxValue = this.model.maxValue || 10;
    this.model.stepValue = this.model.stepValue || 1;

    this.createArrowClass();
    this.createLineDoubleArrowClass();

    this.cachedDragLeft = this.model.graphWidth / 2; // Used to constrain line dragging to numberLine.

    if (typeof this.model.isStacked === 'undefined') {
      this.model.isStacked = true;
    }

    if (!this.model.elementTypes) {
      this.model.elementTypes = [this.POINT];
    }

    // Setting the validation to an empty object does not allow us to get it after the question is submitted.
    // if (!this.model.validation) {
    //   this.model.validation = {};
    // }
  }

  render = () => {
    if (this.canvas) {
      this.showGrid();
      this.drawCorrectAnswers();
    }
  }

  isStacked = () => {
    return this.model.isStacked;
  }

  isSnapToGrid = () => {
    return true;
  }

  static getTolerance = () => {
    return 0;
  }

  getSnapToPoint = (point) => {
    if (this.isSnapToGrid() && point.x !== Infinity && point.x !== -Infinity) {
      point.x = Math.round(point.x / this.model.stepValue) * this.model.stepValue;
    }

    point.y = Math.round(point.y);

    return point;
  }

  setDefaultEditMode = () => {
    this.setElementEditMode(this.POINT);
  }

  runtimeObjectReady = () => {
    this.setDefaultEditMode();
    this.createCanvas();
    this.drawNumberLine();
    this.createNumberLineControls();
  }

  editObjectReady = () => {
    this.setDefaultEditMode();
    this.createCanvas();
    this.createNumberLineEditControls();
    this.drawNumberLine();
    this.drawCorrectAnswers();
    this.initializeRichTextEditors();
  }

  createNumberLineControls = () => {
    const self = this;
    self.selectDefaultElementType();
  }

  selectDefaultElementType = () => {
    const firstElementType = this.model.elementTypes[0];
    // this.$get().find('.numberLineBottomControls .elementTypeButton').removeClass('active');
    this.setElementEditMode(firstElementType);
    // this.$get().find('.numberLineBottomControls .elementTypeButton.' + firstElementType).addClass('active');
  }

  setElementEditMode = (elementType) => {
    numberLineManager.setElementEditMode(elementType);
  }

  getElementEditMode = () => {
    return numberLineManager.elementEditMode;
  }

  getCurrentElementType = () => {
    let currentElementType = 'point';

    if (this.getElementEditMode().indexOf('segment') !== -1) {
      currentElementType = 'segment';
    } else if (this.getElementEditMode().indexOf('ray') !== -1) {
      currentElementType = 'ray';
    }

    return currentElementType;
  }

  getWidth = () => {
    return this.model.graphWidth;
  }

  isSamePoint = (pointA, pointB) => {
    return pointA.x === pointB.x && pointA.y === pointB.y;
  }

  isNumberLineReadOnly = () => {
    return this.readOnly || this.isEditReadOnly();
  }

  setIsReadOnly = (readOnly) => {
    const self = this;
    self.readOnly = readOnly;
    self.setFieldsReadOnly(readOnly);
  }

  getHeight = () => {
    let canvasHeight = this.STACKED_UNIT_HEIGHT + 2 * this.VERTICAL_INSET;

    // if (this.isStacked())
    if (this.model.isStacked) {
      canvasHeight = this.model.maxChoices * this.STACKED_UNIT_HEIGHT + 2 * this.VERTICAL_INSET;
    }
    return canvasHeight;
  }

  redrawNumberLine = () => {
    if (this.contentManager.isEditMode() && !this.isPreview()) {
      this.updateTestItemModel();
      this.drawCorrectAnswers();
    } else {
      this.updateResponseModel();
      this.drawResponse();
    }
  }

  drawNumberLine = () => {
    const self = this;
    let clickPoint = null;

    this.clearCanvas();

    this.canvas.setWidth(this.model.graphWidth);
    this.canvas.setHeight(this.getHeight());
    this.canvas.calcOffset();

    // this.$get(".canvasOrganizerBody").css("width", this.model.graphWidth + "px").css("height", this.getHeight() + "px");
    this.showGrid();

    this.canvas.off('object:moving');
    this.canvas.on('object:moving', (evt) => {
      self.setIsDragging(true);

      if (evt.target.isElementGroup) {
        self.deleteDragPoints(evt.target.id);
        self.dragGroup(evt.target);
      } else if (evt.target.elementType === 'dragStartPoint' || evt.target.elementType === 'dragEndPoint') {
        self.moveDragPoint(evt.target.parentGroupId);
      }
    });

    this.canvas.off('mouse:over');
    this.canvas.on('mouse:over', (evt) => {
      if (evt.target) {
        // If over an element group.
        if (evt.target.isElementGroup) {
          evt.target.isHover = true;
          self.colorGroupElement(evt.target);
        } else if (evt.target.parentGroupId) {
          // If over a drag point
          evt.target.isHover = true;
          self.colorPointElement(evt.target);
        }
      }
    });

    this.canvas.off('mouse:out');
    this.canvas.on('mouse:out', (evt) => {
      if (evt.target) {
        // If over an element group.
        if (evt.target.isElementGroup) {
          evt.target.isHover = false;
          self.colorGroupElement(evt.target);
        } else if (evt.target.parentGroupId) {
          // If over a drag point
          evt.target.isHover = false;
          self.colorPointElement(evt.target);
        }
      }
    });

    this.canvas.off('mouse:down');
    this.canvas.on('mouse:down', (evt) => {
      clickPoint = self.convertCanvasPointToPoint({ x: evt.e.offsetX, y: evt.e.offsetY });
    });

    this.canvas.off('mouse:up');
    this.canvas.on('mouse:up', (evt) => {
      let isElementGroup = false;
      let isDragPoint = false;
      let isSinglePoint = false;
      const point = self.convertCanvasPointToPoint({ x: evt.e.offsetX, y: evt.e.offsetY });

      if (evt.target) {
        isElementGroup = evt.target.isElementGroup;
        isDragPoint = evt.target.elementType === 'dragStartPoint' || evt.target.elementType === 'dragEndPoint';
        isSinglePoint = evt.target.elementType === 'point';
      }

      if (!isElementGroup) {
        self.showGroupElementSelected(null);
      }

      // Dragged group is dropped.
      if (isElementGroup) {
        const group = evt.target;
        const newGroup = self.dropGroup(group);
        if (self.isSamePoint(clickPoint, point)) {
          self.showGroupElementSelected(newGroup);
        } else {
          self.showGroupElementSelected(null);
        }
      } else if (isDragPoint) {
        // Dragged positioning end point is dropped.
        self.dropDragPoint(evt.target);
      } else if (isSinglePoint) {
        // Existing single point
        // If mousedown and mouseup on the same position, then remove point
        if (self.isSamePoint(clickPoint, point)) {
          self.removeElement(evt.target);
        } else {
          // If dragging point horizontally, then don't change y.
          self.canvas.remove(evt.target);
          self.addPointToNumberLine(point);
        }
      } else if (self.isPointInNumberLine(point)) {
        const yValue = self.getCurrentStackLevel();
        point.y = yValue;

        // Adding a point
        if (self.isPointEditMode()) {
          if (evt.target && evt.target.elementType === 'point') {
            // Do nothing
          } else {
            if (self.isStackAvailable()) {
              // Brand new point, need to get the y value.
              self.addPointToNumberLine(point);
            }
          }
        } else {
          if (self.isStackAvailable()) {
            const startPoint = point;
            let endPoint;

            if (self.getCurrentElementType() === this.SEGMENT) {
              endPoint = { x: point.x + this.model.stepValue, y: yValue };

              // If endPoint extends off the graph, nudge the segment back.
              if (!self.isPointInNumberLine(endPoint)) {
                startPoint.x -= this.model.stepValue;
                endPoint.x -= this.model.stepValue;
              }
            } else if (self.getCurrentElementType() === this.RAY) {
              if (self.getElementEditMode().indexOf('left') !== -1) {
                endPoint = { x: -Infinity, y: yValue };
              } else {
                endPoint = { x: +Infinity, y: yValue };
              }
              endPoint.isRayEndPoint = true;
            }

            startPoint.isHollow = self.isStartPointHollow(self.getElementEditMode());
            endPoint.isHollow = self.isEndPointHollow(self.getElementEditMode());

            self.addNumberLineElement(startPoint, endPoint, self.getCurrentElementType());
          }
        }
      }
      if (self.isDragging()) {
        self.setIsDragging(false);
      }

      this.updateResponseModel();
    });
  }

  isPointEditMode = () => {
    return this.getElementEditMode() === this.POINT;
  }

  removeElement = (canvasElement) => {
    this.canvas.remove(canvasElement);

    this.redrawNumberLine();
  }

  drawCorrectAnswers = () => {
    this.clear();

    if (this.model.validation?.correctAnswers) {
      let stackLevel = 0;
      this.model.validation?.correctAnswers.map((elementModel) => {
        // if (self.isStacked())
        if (this.model.isStacked) {
          stackLevel++;
        }

        if (elementModel.elementType === this.POINT) {
          const point = {
            x: elementModel.value,
            y: stackLevel
          };
          this.drawPoint(point, null);
        } else if (elementModel.elementType === this.SEGMENT) {
          const startPoint = {
            x: elementModel.point1.value,
            y: stackLevel,
            isHollow: elementModel.point1.isHollow
          };
          const endPoint = {
            x: elementModel.point2.value,
            y: stackLevel,
            isHollow: elementModel.point2.isHollow
          };

          this.addNumberLineElement(startPoint, endPoint, elementModel.elementType);
        } else if (elementModel.elementType === this.RAY) {
          const endPointX = elementModel.direction === 'positive' ? Infinity : -Infinity;
          const startPoint = {
            x: elementModel.point.value,
            y: stackLevel,
            isHollow: elementModel.point.isHollow
          };
          const endPoint = {
            x: endPointX,
            y: stackLevel,
            isHollow: false,
            isRayEndPoint: true
          };

          this.addNumberLineElement(startPoint, endPoint, elementModel.elementType);
        }
      });
    }

    // color everything as correct (ie, green)
    const groupElements = this.findCanvasElementGroups();
    if (groupElements) {
      groupElements.forEach((groupElement) => {
        groupElement.isCorrect = true;
        groupElement.isIncorrect = false;
        this.colorGroupElement(groupElement);
      });
    }

    const elementPoints = this.findCanvasObjectsByElementType('point');
    if (elementPoints) {
      elementPoints.forEach((element) => {
        element.isCorrect = true;
        element.isIncorrect = false;
        this.colorPointElement(element);
      });
    }
  }

  drawResponse = () => {
    const self = this;
    // var model = this.getModel();
    const responseModel = this.getResponseModel();

    this.clear();

    if (responseModel.answers) {
      let stackLevel = 0;

      responseModel.answers.map((elementModel) => {
        if (self.isStacked()) {
          stackLevel++;
        }

        if (elementModel.elementType === this.POINT) {
          const point = { x: elementModel.value, y: stackLevel };

          self.drawPoint(point);
        } else if (elementModel.elementType === this.SEGMENT) {
          const startPoint = { x: elementModel.point1.value, y: stackLevel, isHollow: elementModel.point1.isHollow };
          const endPoint = { x: elementModel.point2.value, y: stackLevel, isHollow: elementModel.point2.isHollow };

          self.addNumberLineElement(startPoint, endPoint, elementModel.elementType);
        } else if (elementModel.elementType === this.RAY) {
          const endPointX = (elementModel.direction === 'positive') ? Infinity : -Infinity;
          const startPoint = { x: elementModel.point.value, y: stackLevel, isHollow: elementModel.point.isHollow };
          const endPoint = { x: endPointX, y: stackLevel, isHollow: false, isRayEndPoint: true };

          self.addNumberLineElement(startPoint, endPoint, elementModel.elementType);
        }
      });
    }
  }

  setIsDragging = (dragging) => {
    this.dragging = dragging;
  }

  isDragging = () => {
    return this.dragging;
  }

  isStartPointHollow = (elementType) => {
    return elementType.indexOf('left_point_hollow') !== -1 ||
      elementType.indexOf('both_points_hollow') !== -1 ||
      elementType.indexOf('direction_point_hollow') !== -1;
  }

  isEndPointHollow = (elementType) => {
    return (elementType.indexOf('right_point_hollow') !== -1) ||
      elementType.indexOf('both_points_hollow') !== -1 ||
      elementType.indexOf('direction_point_hollow') !== -1;
  }

  createDragPoints = (startPoint, endPoint, groupId, groupElementType) => {
    const dragStartCanvasPoint = this.drawPoint(startPoint, { elementType: 'dragStartPoint' });
    const dragEndCanvasPoint = this.drawPoint(endPoint, { elementType: 'dragEndPoint' });

    dragStartCanvasPoint.parentGroupId = groupId;
    dragStartCanvasPoint.groupElementType = groupElementType;

    dragEndCanvasPoint.parentGroupId = groupId;
    dragEndCanvasPoint.groupElementType = groupElementType;
  }

  deleteDragPoints = (groupId) => {
    const self = this;
    const dragPoints = this.canvas.getObjects().filter((obj) => obj.parentGroupId === groupId);

    dragPoints.map((dragPoint) => {
      self.canvas.remove(dragPoint);
    });
  }

  dragGroup = (group) => {
    const startPointObject = group.getObjects().find((obj) => obj.elementType === 'startPoint');
    const endPointObject = group.getObjects().find((obj) => obj.elementType === 'endPoint');

    const startPoint = this.convertCanvasPointToPoint({ x: (group.left + startPointObject.left), y: group.top + startPointObject.top }, true);
    startPoint.isHollow = startPointObject.isHollow;
    const isStartPointInNumberLine = this.isPointInNumberLine(startPoint);

    const endPoint = this.convertCanvasPointToPoint({ x: (group.left + endPointObject.left), y: group.top + endPointObject.top }, true);
    const isEndPointInNumberLine = this.isPointInNumberLine(endPoint);
    endPoint.isHollow = endPointObject.isHollow;
    endPoint.isRayEndPoint = endPointObject.isRayEndPoint;

    if (!isStartPointInNumberLine) {
      group.set({ left: this.cachedDragLeft, top: this.cachedDragTop });
    }

    if (endPointObject.groupElementType !== this.RAY && !isEndPointInNumberLine) {
      group.set({ left: this.cachedDragLeft, top: this.cachedDragTop });
    }

    this.cachedDragLeft = group.left;
    this.cachedDragTop = group.top;
  }

  deleteGroupElement = (groupElement) => {
    const objects = groupElement.getObjects();
    const { groupId } = objects[0];
    groupElement.destroy();
    this.canvas.remove(groupElement);
    this.deleteDragPoints(groupId);
  }

  dropGroup = (group) => {
    const self = this;
    const objects = group.getObjects();
    const { elementType } = group;
    const { isSelected } = group;
    let startPoint = null;
    let endPoint = null;

    this.deleteGroupElement(group);

    objects.map((object) => {
      if (object.elementType === 'startPoint') {
        startPoint = self.convertCanvasPointToPoint({ x: object.left, y: object.top });
        startPoint.isHollow = object.isHollow;
      } else if (object.elementType === 'endPoint') {
        endPoint = self.convertCanvasPointToPoint({ x: object.left, y: object.top });
        endPoint.isHollow = object.isHollow;
        endPoint.isRayEndPoint = object.isRayEndPoint;

        if (elementType === this.RAY) {
          if (endPoint.x < startPoint.x) {
            endPoint.x = -Infinity;
          } else {
            endPoint.x = Infinity;
          }
        }
      }
    });

    const newGroup = this.addNumberLineElement(startPoint, endPoint, elementType);
    newGroup.isSelected = isSelected;

    return newGroup;
  }

  moveDragPoint = (groupId) => {
    const group = this.findCanvasGroup(groupId);
    const dragStartPointObject = this.canvas.getObjects()
      .find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragStartPoint');
    const dragEndPointObject = this.canvas.getObjects()
      .find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragEndPoint');

    if (group) {
      const objects = group.getObjects();
      group.destroy();
      this.canvas.remove(group);

      objects.map((object) => {
        if (object.elementType === 'connector') {
          this.canvas.add(object);
        }
      });
    }

    this.constrainObjectToNumberLine(dragStartPointObject);

    if (dragEndPointObject.groupElementType !== this.RAY) {
      this.constrainObjectToNumberLine(dragEndPointObject);
    }

    const connectorObject = this.canvas.getObjects().find((obj) => obj.groupId === groupId && obj.elementType === 'connector');

    connectorObject.set({ x1: dragStartPointObject.left, y1: dragStartPointObject.top, x2: dragEndPointObject.left, y2: dragEndPointObject.top });
  }

  constrainObjectToNumberLine = (object) => {
    const width = this.model.graphWidth;
    const height = this.getHeight();
    const left = this.HORIZONTAL_INSET;
    const top = this.VERTICAL_INSET;
    const right = width - this.HORIZONTAL_INSET;
    const bottom = height - this.VERTICAL_INSET;

    if (object.left < left) {
      object.left = left;
    }
    if (object.left > right) {
      object.left = right;
    }
    if (object.top < top) {
      object.top = top;
    }
    if (object.top > bottom) {
      object.top = bottom;
    }
  }

  isPointInNumberLine = (point) => {
    return point.x >= this.getXMin() &&
        point.x <= this.getXMax();
  }

  dropDragPoint = (canvasPoint) => {
    const groupId = canvasPoint.parentGroupId;

    const group = this.findCanvasGroup(groupId);
    const dragStartPointObject = this.canvas.getObjects().find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragStartPoint');
    const dragEndPointObject = this.canvas.getObjects().find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragEndPoint');
    const { groupElementType } = dragStartPointObject;
    const startPoint = this.convertCanvasPointToPoint({ x: dragStartPointObject.left, y: dragStartPointObject.top });
    const endPoint = this.convertCanvasPointToPoint({ x: dragEndPointObject.left, y: dragEndPointObject.top });
    startPoint.isHollow = dragStartPointObject.isHollow;
    endPoint.isHollow = dragEndPointObject.isHollow;
    endPoint.isRayEndPoint = dragEndPointObject.isRayEndPoint;
    const connectorObject = this.canvas.getObjects().find((obj) => obj.groupId === groupId && obj.elementType === 'connector');

    if (dragEndPointObject.isRayEndPoint) {
      if (endPoint.x > startPoint.x) {
        endPoint.x = Infinity;
      } else {
        endPoint.x = -Infinity;
      }
    }

    if (group) {
      group.destroy();
      this.canvas.remove(group);
    }

    this.canvas.remove(dragStartPointObject);
    this.canvas.remove(dragEndPointObject);
    this.canvas.remove(connectorObject);

    this.addNumberLineElement(startPoint, endPoint, groupElementType);
  }

  addTempStartPointToNumberLine = (point) => {
    return this.addPointToNumberLine(point, { elementType: 'tempStartPoint' });
  }

  addTempEndPointToNumberLine = (point) => {
    return this.addPointToNumberLine(point, { elementType: 'tempEndPoint' });
  }

  removeTempCanvasPoints = () => {
    this.findCanvasObjectsByElementType('tempStartPoint').map((canvasPoint) => this.canvas.remove(canvasPoint));
    this.findCanvasObjectsByElementType('tempEndPoint').map((canvasPoint) => this.canvas.remove(canvasPoint));
  }

  findCanvasPointByValue = (value) => {
    const objects = this.canvas.getObjects();
    // var canvasPoint = _.find(objects, {elementType:"point", point:{x:value}});
    const canvasPoint = objects.find((obj) => obj.elementType === 'point' && obj.point.x === value);

    return canvasPoint;
  }

  findCanvasSegmentByValue = (point1, point2) => {
    // const objects = this.canvas.getObjects();
    let canvasElement = null;

    const segmentElements = this.findCanvasObjectsByElementType(this.SEGMENT);

    segmentElements.map((segmentElement) => {
      if ((segmentElement.points.startPoint.x === point1.value && segmentElement.points.endPoint.x === point2.value) ||
          (segmentElement.points.endPoint.x === point1.value && segmentElement.points.startPoint.x === point2.value)) {
        canvasElement = segmentElement;
      }
    });

    return canvasElement;
  }

  findCanvasRayByValue = (point, direction) => {
    // const objects = this.canvas.getObjects();
    let canvasElement = null;

    const rayElements = this.findCanvasObjectsByElementType(this.RAY);

    rayElements.map((rayElement) => {
      const canvasRayDirection = (rayElement.points.endPoint.x > rayElement.points.startPoint.x) ? 'positive' : 'negative';

      if (rayElement.points.startPoint.x === point.value && canvasRayDirection === direction) {
        canvasElement = rayElement;
      }
    });

    return canvasElement;
  }

  convertCanvasPointToPoint = (canvasPoint) => {
    let point = { x: 0, y: 0 };

    const left = this.HORIZONTAL_INSET;
    const right = this.model.graphWidth - this.HORIZONTAL_INSET;
    const width = right - left;

    point.x = ((canvasPoint.x - left) / width) * (this.getXMax() - this.getXMin()) + this.getXMin();

    const top = this.VERTICAL_INSET;
    const bottom = this.getHeight() - this.VERTICAL_INSET;
    const height = bottom - top;

    if (this.isStacked()) {
      point.y = ((bottom - canvasPoint.y) / height) * this.model.maxChoices;
    }

    point = this.getSnapToPoint(point);

    // avoid -0
    if (point.x === 0) {
      point.x = 0;
    }

    return point;
  }

  getPoints = (elementType) => {
    const self = this;
    const objects = this.canvas.getObjects();
    const points = [];

    elementType = elementType || 'point';

    if (objects.length === 0) {
      return null;
    }

    objects.map((object) => {
      if (object.elementType === elementType) {
        const point = self.convertCanvasPointToPoint({ x: object.left, y: object.top });
        points.push(point);
      }
    });
    return points;
  }

  /**
   * Returns a model of the elements.
   *
   * Format: [{"elementType": "point", "value":4},
   *       {"elementType": "segment", "point1": {"value":4, "isHollow":true}, "point2": {"value":6, "isHollow":false}}
   *       {"elementType": "ray", "point": {"value":3, "isHollow":true}, "direction":"positive"}
   *       ]
   */
  getElementModels = () => {
    const elementGroups = this.findCanvasElementGroups();
    const elementPoints = this.findCanvasObjectsByElementType('point');
    const elementModels = [];

    // points
    elementPoints.map((canvasElementPoint) => {
      const elementModel = { elementType: 'point', value: canvasElementPoint.point.x, verticalOrder: canvasElementPoint.point.y };
      elementModels.push(elementModel);
    });

    // elementGroups "ray", "segment", etc.
    // $.each(elementGroups, function(index, canvasElementGroup) {
    elementGroups.map((canvasElementGroup) => {
      const { startPoint } = canvasElementGroup.points;
      const { endPoint } = canvasElementGroup.points;
      let elementModel = null;

      if (canvasElementGroup.elementType === this.SEGMENT) {
        elementModel = {
          elementType: canvasElementGroup.elementType,
          point1: { value: startPoint.x, isHollow: startPoint.isHollow },
          point2: { value: endPoint.x, isHollow: endPoint.isHollow },
          verticalOrder: startPoint.y
        };
      } else if (canvasElementGroup.elementType === this.RAY) {
        const direction = (endPoint.x > startPoint.x) ? 'positive' : 'negative';
        elementModel = {
          elementType: canvasElementGroup.elementType,
          point: { value: startPoint.x, isHollow: startPoint.isHollow },
          direction,
          verticalOrder: startPoint.y
        };
      }

      elementModels.push(elementModel);
    });
    // Added a vertical order (based on Y point value) to sort elements so they are always in the same order after elements are redrawn.
    elementModels.sort((a, b) => a.verticalOrder - b.verticalOrder);

    return elementModels;
  }

  getCurrentStackLevel = () => {
    return this.getElementModels().length + 1;
  }

  isStackAvailable = () => {
    return this.getCurrentStackLevel() <= this.model.maxChoices;
  }

  updateTestItemModel = () => {
    this.updateCanvasModel();
    this.updateNumberLineModel();
  };

  updateResponseModel = () => {
    const responseModel = this.getResponseModel();

    responseModel.answers = this.getElementModels();

    this.setResponseModel(responseModel);
  };

  showResponse = (callback) => {
    this.clear();
    this.drawResponse();

    if (callback) {
      callback();
    }
  }

  showCorrectAnswer = (callback) => {
    this.clear();

    this.drawCorrectAnswers();
    if (callback) {
      callback();
    }
  }

  clear = () => {
    this.clearPoints();

    this.findCanvasElementGroups().map((group) => {
      this.canvas.remove(group);
    });

    this.findCanvasObjectsByElementType('dragStartPoint').map((canvasPoint) => {
      this.canvas.remove(canvasPoint);
    });

    this.findCanvasObjectsByElementType('dragEndPoint').map((canvasPoint) => {
      this.canvas.remove(canvasPoint);
    });

    this.removeTempCanvasPoints();
  }

  clearAll = () => {
    this.clear();
    this.redrawNumberLine();
  }

  getNumberLinePoints = () => {
    if (!this.canvas) {
      return null;
    }
    return this.canvas.getObjects().filter((obj) => obj.elementType === 'point');
    // return _.filter(this.canvas.getObjects(), {elementType:"point"});
  }

  /**
   * Returns canvas objects that have a specified groupId.
   */
  findCanvasObjectsByGroup = (groupId) => {
    return this.canvas.getObjects().filter((obj) => obj.groupId === groupId);
  }

  /**
   * Returns canvas objects that have a specified groupId.
   */
  findCanvasGroup = (groupId) => {
    return this.canvas.getObjects().find((obj) => obj.id === groupId);
  }

  /**
   * Returns canvas objects that have a specified groupId.
   */
  findCanvasElementGroups = () => {
    this.canvas.getObjects();
    return this.canvas.getObjects().filter((obj) => obj.isElementGroup);
  }

  findCanvasObjectsByElementType = (elementType) => {
    return this.canvas.getObjects().filter((obj) => obj.elementType === elementType);
  }

  clearPoints = function redo() {
    // var self = this;
    const graphPoints = this.getNumberLinePoints();
    if (graphPoints === null) {
      return;
    }

    graphPoints.map((graphPoint) => this.canvas.remove(graphPoint));

    // $.each(graphPoints, function(index, graphPoint) {
    //   self.canvas.remove(graphPoint);
    // });
  };

  static getPromptCount = (model) => {
    return model.validation?.correctAnswers.length;
  }

  /**
   * Returns true if correctElements contains a response element with the same startPoint and slope.
   */
  static isRayMatch = (correctElements, responseModelElement) => {
    let isMatch = false;

    correctElements.forEach((correctElement) => {
      if (NumberLineService.isValueMatch(correctElement.point.value, responseModelElement.point.value) &&
          correctElement.point.isHollow === responseModelElement.point.isHollow &&
          correctElement.direction === responseModelElement.direction) {
        isMatch = true;
      }
    });

    return isMatch;
  }

  /**
   * Returns true if correctElements contains a response element with the same startPoint and endPoint or with startPoint and endPoint reversed.
   */
  static isSegmentMatch = (correctElements, responseModelElement) => {
    let isMatch = false;

    correctElements.forEach((correctElement) => {
      // this code copied from legacy player - don't want to mess with adding parens for fear of breaking it
      if ((NumberLineService.isValueMatch(correctElement.point1.value, responseModelElement.point1.value) &&
          NumberLineService.isValueMatch(correctElement.point2.value, responseModelElement.point2.value)) &&
          correctElement.point1.isHollow === responseModelElement.point1.isHollow && // eslint-disable-line no-mixed-operators
          correctElement.point2.isHollow === responseModelElement.point2.isHollow || // eslint-disable-line no-mixed-operators

          (NumberLineService.isValueMatch(correctElement.point1.value, responseModelElement.point2.value) &&
            NumberLineService.isValueMatch(correctElement.point2.value, responseModelElement.point1.value)) &&
            correctElement.point1.isHollow === responseModelElement.point2.isHollow && // eslint-disable-line no-mixed-operators
            correctElement.point2.isHollow === responseModelElement.point1.isHollow) {
        isMatch = true;
      }
    });

    return isMatch;
  }

  static isPointElementMatch = (correctElements, responseModelElement) => {
    let isMatch = false;

    correctElements.forEach((correctPoint) => {
      if (NumberLineService.isValueMatch(correctPoint.value, responseModelElement.value)) {
        isMatch = true;
      }
    });
    return isMatch;
  }

  static isValueMatch = (targetValue, value) => {
    const tolerance = NumberLineService.getTolerance();
    const lowerBound = targetValue * (1 - tolerance / 100);
    const upperBound = targetValue * (1 + tolerance / 100);

    return value >= lowerBound && value <= upperBound;
  }

  /**
   * Apply a color to a point element.
   */
  colorPointElement = (pointElement) => {
    let color = this.POINT_COLOR;

    if (pointElement.isHover) {
      color = this.HOVER_COLOR;
    } else if (pointElement.isCorrect) {
      color = TestItem.CORRECT_COLOR;
    } else if (pointElement.isIncorrect) {
      color = TestItem.INCORRECT_COLOR;
    }

    if (!pointElement.isHollow) {
      pointElement.set('fill', color);
    }
    pointElement.set('stroke', color);

    if (pointElement.isRayEndPoint) {
      pointElement.set('fill', 'transparent');
      pointElement.set('stroke', 'transparent');
    }

    this.canvas.renderAll();
  }

  /**
   * Set a group element's coloration based on its state. (e.g., isHover, isSelected).
   *
   */
  colorGroupElement = (canvasElement) => {
    const groupId = canvasElement.id;
    const dragPoints = this.canvas.getObjects().filter((obj) => obj.parentGroupId === groupId);
    let color = this.CONNECTOR_COLOR;

    if (canvasElement.isHover) {
      color = this.HOVER_COLOR;
    } else if (canvasElement.isCorrect) {
      color = TestItem.CORRECT_COLOR;
    } else if (canvasElement.isIncorrect) {
      color = TestItem.INCORRECT_COLOR;
    } else if (canvasElement.isSelected) {
      color = this.SELECTED_COLOR;
    }

    // color drag points
    dragPoints.map((dragPoint) => {
      if (!dragPoint.isRayEndPoint) {
        if (!dragPoint.isHollow) {
          dragPoint.set('fill', color);
        }
        dragPoint.set('stroke', color);
      }
    });

    // color child elements
    canvasElement.getObjects().map((childObject) => {
      if (!childObject.isRayEndPoint) {
        if (!childObject.isHollow) {
          childObject.set('fill', color);
        }
        childObject.set('stroke', color);
      }
    });
    this.canvas.renderAll();
  }

  /**
   * Color a group element to show isSelected after removing isSelected coloration from other group elements.
   *
   */
  showGroupElementSelected = (selectedGroupElement) => {
    const groupElements = this.findCanvasElementGroups();

    groupElements.map((groupElement) => {
      groupElement.isSelected = false;
      this.colorGroupElement(groupElement);
    });

    // TODO
    // this.$get().removeClass("elementSelected");

    if (selectedGroupElement) {
      if (typeof selectedGroupElement.isSelected === 'undefined') {
        selectedGroupElement.isSelected = false;
      }
      selectedGroupElement.isSelected = true;
      this.colorGroupElement(selectedGroupElement);
      // TODO
      // this.$get().addClass("elementSelected");
    }
  }

  /**
   * Delete a group element that has been flagged as isSelected.
   */
  deleteSelectedElement = () => {
    const groupElements = this.findCanvasElementGroups();

    groupElements.map((groupElement) => {
      if (groupElement.isSelected) {
        this.deleteGroupElement(groupElement);
        this.showGroupElementSelected(null);
      }
    });
    this.redrawNumberLine();
  }

  /**
   * Show a point element as correct by coloring it.
   */
  showPointElementCorrect = (pointElement) => {
    pointElement.isCorrect = true;
    pointElement.isIncorrect = false;
    this.colorPointElement(pointElement);
  }

  /**
   * Show a point element as correct by coloring it.
   */
  showPointElementIncorrect = (pointElement) => {
    pointElement.isCorrect = false;
    pointElement.isIncorrect = true;
    this.colorPointElement(pointElement);
  }

  /**
   * Show a group element (e.g., a line element) as correct by coloring it.
   */
  showGroupElementCorrect = (groupElement) => {
    groupElement.isCorrect = true;
    groupElement.isIncorrect = false;
    this.colorGroupElement(groupElement);
  }

  clearValidationFeedback = () => {
  }

  showValidationFeedback = () => {
    const self = this;
    const { model } = this;
    const responseModel = this.getResponseModel();

    // Initially color all grouped numberLine elements red.
    // NOTE: NEED TO COLOR THE DRAG POINTS TOO -- or delete them.
    const groupElements = self.findCanvasElementGroups();

    if (groupElements) {
      groupElements.forEach((groupElement) => {
        groupElement.isCorrect = false;
        groupElement.isIncorrect = true;
        self.colorGroupElement(groupElement);
      });
    }

    if (responseModel.answers) {
      responseModel.answers.forEach((responseModelElement) => {
        if (responseModelElement.elementType === this.POINT) {
          const correctElements = model.validation?.correctAnswers.filter((answer) => answer.elementType === 'point');
          // const isMatch = self.isPointElementMatch(correctElements, responseModelElement);
          const isMatch = NumberLineService.isPointElementMatch(correctElements, responseModelElement);
          const canvasPoint = self.findCanvasPointByValue(responseModelElement.value);
          canvasPoint.fill = (isMatch) ? TestItem.CORRECT_COLOR : TestItem.INCORRECT_COLOR;

          if (isMatch) {
            self.showPointElementCorrect(canvasPoint);
          } else {
            self.showPointElementIncorrect(canvasPoint);
          }
        } else if (responseModelElement.elementType === this.SEGMENT) {
          const correctElements = (model.validation?.correctAnswers || []).filter((answer) => answer.elementType === 'segment');
          // const isMatch = self.isSegmentMatch(correctElements, responseModelElement);
          const isMatch = NumberLineService.isSegmentMatch(correctElements, responseModelElement);
          const canvasElement = self.findCanvasSegmentByValue(responseModelElement.point1, responseModelElement.point2);
          // _.find(self.canvas.getObjects(), {elementType:"segment", points:responseModelElement.points});

          if (isMatch) {
            self.showGroupElementCorrect(canvasElement);
          }
        } else if (responseModelElement.elementType === this.RAY) {
          const correctElements = (model.validation?.correctAnswers || []).filter((answer) => answer.elementType === 'ray');
          // const isMatch = self.isRayMatch(correctElements, responseModelElement);
          const isMatch = NumberLineService.isRayMatch(correctElements, responseModelElement);
          const canvasElement = self.findCanvasRayByValue(responseModelElement.point, responseModelElement.direction);
          // _.find(self.canvas.getObjects(), {elementType:"segment", points:responseModelElement.points});

          if (isMatch) {
            self.showGroupElementCorrect(canvasElement);
          }
        }
      });
    }
    this.canvas.renderAll();
  };

  convertPointToCanvasPoint = (point, skipSnapTo) => {
    const canvasPoint = { x: 0, y: 0 };

    const left = this.HORIZONTAL_INSET;
    const right = this.model.graphWidth - this.HORIZONTAL_INSET;

    if (!skipSnapTo) {
      point = this.getSnapToPoint(point);
    }

    if (point.x === -Infinity) {
      canvasPoint.x = left - this.LINE_OUTSET;
    } else if (point.x === Infinity) {
      canvasPoint.x = right + this.LINE_OUTSET;
    } else {
      canvasPoint.x = (point.x - this.getXMin()) / (this.getXMax() - this.getXMin()) * (right - left) + left;
    }

    const bottom = this.getHeight() - this.VERTICAL_INSET;

    if (this.model.isStacked) {
      canvasPoint.y = bottom - (point.y * this.STACKED_UNIT_HEIGHT);
    } else {
      canvasPoint.y = bottom;
    }

    return canvasPoint;
  }

  drawPoint = (point, settings) => {
    const canvasPoint = this.convertPointToCanvasPoint(point, false);
    const left = canvasPoint.x;
    const top = canvasPoint.y;
    let pointColor = this.POINT_COLOR;
    const pointStrokeColor = this.STROKE;

    settings = settings || {};

    settings.elementType = settings.elementType || 'point';

    if (settings.elementType === 'tempStartPoint') {
      pointColor = this.START_POINT_COLOR;
    } else if (settings.elementType === 'tempEndPoint') {
      pointColor = this.END_POINT_COLOR;
    }

    settings.strokeWidth = settings.strokeWidth || 0;
    settings.radius = settings.radius || 6;
    settings.fill = settings.fill || pointColor;
    settings.stroke = settings.stroke || pointStrokeColor;
    settings.opacity = settings.opacity || this.OPACITY;
    settings.elementType = settings.elementType || 'point';

    if (point.isHollow) {
      settings.strokeWidth = 1;
      settings.stroke = pointColor;
      settings.radius = 5;
      settings.fill = '#fff';
    } else {
      point.isHollow = false;
    }

    if (point.isRayEndPoint) {
      settings.strokeWidth = 0;
      settings.stroke = 'transparent';
      settings.radius = 1;
      settings.fill = 'transparent';
    }

    const circle = new fabric.Circle({
      type: 'circle',
      elementType: settings.elementType,
      left,
      top,
      strokeWidth: settings.strokeWidth,
      radius: settings.radius,
      fill: settings.fill,
      stroke: settings.stroke,
      opacity: settings.opacity,
      originX: 'center',
      originY: 'center',
      lockMovementY: true,
      isHollow: point.isHollow,
      isRayEndPoint: point.isRayEndPoint,
      point
    });

    circle.hasInput = false;
    circle.hasControls = false;
    circle.hasBorders = false;

    this.canvas.add(circle);

    return circle;
  }

  getHeight = () => {
    let canvasHeight = this.STACKED_UNIT_HEIGHT + 2 * this.VERTICAL_INSET;

    if (this.model.isStacked) {
      canvasHeight = this.model.maxChoices * this.STACKED_UNIT_HEIGHT + 2 * this.VERTICAL_INSET;
    }
    return canvasHeight;
  }

  getXMin = () => {
    return this.getEvenlyDivided(this.model.minValue, this.model.stepValue);
  }

  getXMax = () => {
    return this.getEvenlyDivided(this.model.maxValue, this.model.stepValue);
  }

  getEvenlyDivided = (value, step) => {
    const sign = value < 0 ? -1 : 1;
    const ratio = Math.ceil(Math.abs(value) / step);

    return ratio * step * sign;
  }

  drawConnector = (startPoint, endPoint, elementType) => {
    let canvasConnectorObject = null;

    if (elementType.indexOf(this.SEGMENT) !== -1) {
      const canvasStartPoint = this.convertPointToCanvasPoint(startPoint, true);
      const canvasEndPoint = this.convertPointToCanvasPoint(endPoint, true);

      canvasConnectorObject = this.drawLine(canvasStartPoint.x, canvasStartPoint.y, canvasEndPoint.x, canvasEndPoint.y, {
        stroke: this.CONNECTOR_COLOR,
        perPixelTargetFind: true
      });
    } else if (elementType.indexOf(this.RAY) !== -1) {
      const canvasStartPoint = this.convertPointToCanvasPoint(startPoint, true);
      const canvasEndPoint = this.convertPointToCanvasPoint(endPoint, true);

      canvasConnectorObject = this.drawArrow(canvasStartPoint.x, canvasStartPoint.y, canvasEndPoint.x, canvasEndPoint.y, {
        stroke: this.CONNECTOR_COLOR,
        perPixelTargetFind: true
      });
    }

    return canvasConnectorObject;
  }

  findExistingPoint = (point, elementType) => {
    const points = this.getPoints(elementType);
    const existingPoint = points.find((p) => p.x === point.x && p.y === point.y);

    return existingPoint;
  }

  findCanvasPoint = (point) => {
    const objects = this.canvas.getObjects();
    // var canvasPoint = _.find(objects, {point:{x:point.x, y:point.y}});
    const canvasPoint = objects.find((p) => p.x === point.x && p.y === point.y);

    return canvasPoint;
  }

  addPointToNumberLine = (point, settings) => {
    settings = settings || {};

    let existingPoint = false;
    let canvasPoint = null;

    if (settings.elementType) {
      existingPoint = this.findExistingPoint(point, settings.elementType);
    }

    if (existingPoint) {
      canvasPoint = this.findCanvasPoint(point);

      if (canvasPoint) {
        this.canvas.remove(canvasPoint);
      }
    } else {
      // this.saveHistory(true);
      canvasPoint = this.drawPoint(point, settings);
      canvasPoint.point = point;
    }

    return canvasPoint;
  }

  findCanvasObjectsByGroup = (groupId) => {
    return this.canvas.getObjects().filter((obj) => obj.groupId === groupId);
  }

  groupNumberLineObjects = (groupId) => {
    const groupObjects = [];
    const objects = this.findCanvasObjectsByGroup(groupId);
    const elementType = objects[0].groupElementType;
    const newGroupId = uuidv4();

    // Need to clone existing objects in order to group them.
    objects.map((object) => {
      const clone = fabric.util.object.clone(object);
      // const clone = object.clone();
      clone.groupId = newGroupId;
      clone.groupElementType = object.groupElementType;
      clone.elementType = object.elementType;

      if (object.point) {
        clone.point = object.point;
        clone.isHollow = object.isHollow;
        clone.isRayEndPoint = object.isRayEndPoint;
        //        clone.fill = NumberLine.POINT_COLOR;
      }
      groupObjects.push(clone);
    });

    const group = new fabric.Group(groupObjects, {
      id: newGroupId,
      isElementGroup: true,
      elementType,
      originX: 'center',
      originY: 'center',
      hasControls: false,
      hasBorders: false,
      lockMovementX: false,
      lockMovementY: true,
      perPixelTargetFind: true
    });

    this.canvas.add(group);

    // $.each(objects, function(index, object) {
    objects.map((object) => {
      this.canvas.remove(object);
    });

    return group;
  }

  addNumberLineElement = (startPoint, endPoint, elementType) => {
    startPoint = this.getSnapToPoint(startPoint);
    endPoint = this.getSnapToPoint(endPoint);

    if (elementType === this.RAY) {
      endPoint.isRayEndPoint = true;
    }

    const canvasConnectorObject = this.drawConnector(startPoint, endPoint, elementType);
    const canvasStartPointObject = this.addPointToNumberLine(startPoint);
    const canvasEndPointObject = this.addPointToNumberLine(endPoint);

    const groupId = uuidv4();

    canvasConnectorObject.groupId = groupId;
    canvasStartPointObject.groupId = groupId;
    canvasEndPointObject.groupId = groupId;

    canvasConnectorObject.groupElementType = elementType;
    canvasStartPointObject.groupElementType = elementType;
    canvasEndPointObject.groupElementType = elementType;

    canvasConnectorObject.elementType = 'connector';
    canvasStartPointObject.elementType = 'startPoint';
    canvasEndPointObject.elementType = 'endPoint';

    const group = this.groupNumberLineObjects(groupId);
    group.elementType = elementType;
    group.points = { startPoint, endPoint };

    this.createDragPoints(startPoint, endPoint, group.id, elementType);

    return group;
  }

  showGrid = () => {
    const canvasWidth = this.model.graphWidth;
    const graphWidth = canvasWidth - (2 * this.HORIZONTAL_INSET);

    const tickCount = Math.round((this.model.maxValue - this.model.minValue) / this.model.stepValue);
    const gridUnitWidth = Math.round(graphWidth / tickCount);
    const numberLineLeft = this.HORIZONTAL_INSET - this.LINE_OUTSET;
    const numberLineRight = canvasWidth - this.HORIZONTAL_INSET + this.LINE_OUTSET;
    const numberLineTop = this.getHeight() - this.VERTICAL_INSET;
    const tickTop = numberLineTop - this.TICK_LENGTH / 2;
    const tickBottom = numberLineTop + this.TICK_LENGTH / 2;

    this.drawRect(0, 0, canvasWidth - 1, this.getHeight() - 1, {
      inactive: true,
      strokeWidth: this.LINE_STROKE_THIN,
      rx: 1,
      ry: 1,
      selectable: false,
    });

    // this.drawLine(xStart, (height-NumberLine.TICK_LENGTH), xStart, NumberLine.TICK_LENGTH, {stroke:stroke, strokeWidth:strokeWidth});
    const settings = this.inactiveObjectSettings();

    this.drawDoubleArrow(numberLineLeft, numberLineTop, numberLineRight, numberLineTop, settings);

    // Draw tick lines and axis values
    let xStart = this.HORIZONTAL_INSET;
    for (let i = 0; i <= tickCount; i++) {
      const xValue = Utils.roundToDecimal(this.model.minValue + i * this.model.stepValue);

      this.drawLine(xStart, tickTop, xStart, tickBottom, {
        stroke: 'black',
        strokeWidth: this.LINE_STROKE_THIN,
        selectable: false,
      });
      this.drawText(xValue.toString(), {
        left: xStart,
        top: tickBottom + this.TEXT_TOP_MARGIN,
        originX: 'center',
        fontSize: this.FONT_SIZE,
        selectable: false,
      });

      xStart += gridUnitWidth;
    }
  }

  getResponseModel = () => {
    const responseModel = responseService.getResponseModel(this.model.lessonElementId);
    return (responseModel) || {};
  }

  setResponseModel = (responseModel) => {
    responseService.responseChangeHandler(responseModel, this.model.lessonElementId);
  }
}
