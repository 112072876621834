import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/ClozeSpelling.scss';
import { LessonMode } from '../../../Constants';

import questionService from '../../services/QuestionService';

import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';

import OptionalImage from '../OptionalImage';
import clozeTypingService from '../../services/ClozeTypingService';
import clozeService from '../../services/ClozeService';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const ClozeSpelling = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const uiState = questionFeedbackManager.getUiState(lessonElementId) || {};
  const { noSubmit } = questionFeedbackManager;

  const {
    lessonElementState,
    model,
    readOnly,
    toolbar,
    userInputMap,
  } = questionService.initQuestionComponent({
    lessonElementId,
    questionClassName: 'cloze-question'
  });

  useStyleEvents(lessonElementId);

  const renderCloze = (_props = null) => {
    const answerBankPositionClassName = `answer-bank-position-${ model?.answerBankPosition || 'bottom'}`;
    const clozeQuestionInnerClassNames = classNames('cloze-question-inner', answerBankPositionClassName);
    const questionTitle = <HtmlComponent htmlStr={model.questionText || ''} />;
    return (
      <div className='cloze-question'>
        <div className='cloze-question-section-title'>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='test-item-question'>
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
            {questionTitle}
          </div>
          <OptionalImage model={model} runtime={true} />
        </div>
        <div className={clozeQuestionInnerClassNames}>
          <div className='cloze-question-section-body'>
            <div className='test-item-answers'>
              {clozeService.renderClozeBody({
                lessonElementId, model, renderBlankSection
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /** @param {import('html-react-parser').DOMNode} domNode */
  const renderBlankSection = (domNode) => {
    const inputSize = 1;
    return clozeTypingService.renderClozeTypingBlankSection(domNode, {
      lessonElementId,
      lessonElementState,
      model,
      readOnly,
      toolbar,
      uiState,
      userInputMap,
      noSubmit,
      inputSize
    });
  };
  return lessonElementId && model && lessonElementState
    /* && typeof uiState?.submitButtonVisible === 'boolean' */ ? renderCloze() : null;
});

export default ClozeSpelling;
