import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../css/AssesmentBuilder.scss';

import { addThemeTranslations } from '../i18n';

import Auth from '../lessonPlayer/services/AuthService';
import itemBankService from '../assessmentBuilder/services/ItemBankService';

import ContentPreview from '../assessmentBuilder/components/ContentPreview';

const ContentViewer = observer(() => {
  const {
    userManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const [contentItemId, setContentItemId] = useState('');
  const [courseContentItemId, setCourseContentItemId] = useState('');
  const [previewSource, setPreviewSource] = useState('');
  const [previewLessonElementId, setPreviewLessonElementId] = useState('');

  useEffect(() => {
    async function init() {
      if (window.location.hash) {
        const authKey = window.location.hash.substring(1);
        const user = await Auth.logInWithAuthKey(authKey);
        await Auth.setAuthKey(authKey);
        userManager.setUser(user);

        const urlParams = new URLSearchParams(window.location.search);
        const contentItemId = urlParams.get('contentItemId');
        const courseContentItemId = urlParams.get('courseId');
        const previewLessonElementId = urlParams.get('previewLessonElementId');
        const previewSource = urlParams.get('source');

        setContentItemId(contentItemId);
        setCourseContentItemId(courseContentItemId);
        setPreviewLessonElementId(previewLessonElementId);
        setPreviewSource(previewSource);

        if (userManager.isPublisherUser) {
          await itemBankService.fetchLessonProfilesForPublisher();
        } else {
          await itemBankService.getProfiles();
        }
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentPreview contentItemId={contentItemId}
        courseContentItemId={courseContentItemId}
        previewLessonElementId={previewLessonElementId}
        previewSource={previewSource} />
    </>
  );
});
export default ContentViewer;
