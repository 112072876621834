import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../../css/ArticleQuestion.scss';
import { LessonMode } from '../../../Constants';
import PrintQuestionNumber from './PrintQuestionNumber';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import { useTextHighlight } from '../../../hooks/useTextHighlight';
import highlightAnnotationService from '../../services/HighlightAnnotationService';
import useStyleEvents from '../../../hooks/useStyleEvents';

const ArticleQuestion = observer(({ lessonElementId }) => {
  const {
    questionFeedbackManager,
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const componentRef = useRef();
  useTextHighlight(componentRef, lessonElementId);

  useEffect(() => {
    if (toolbarManager.isHighlightAnnotationOpen || toolbarManager.isToolOn('StickyNotes')) {
      highlightAnnotationService.showHighlights(lessonElementId);
    }
  });

  useStyleEvents(lessonElementId, `${lessonElementId}-activity-view`);

  const model = lessonManager.getSlideModel(lessonElementId);
  const directions = model.directions || '';
  const textBody = model.text || '';

  return (
    <div className='article-activity-question' id={`${lessonElementId}-activity-view`}>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div ref={componentRef} className='test-item-question lesson-element article-question' id={`${lessonElementId}-article-view`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model}/> }
        {directions ?
          <div className='directions'><HtmlComponent htmlStr={directions} /></div>
          : null}
        {(questionFeedbackManager.isTTSOverride) ?
          <div className='body' data-ignore='1' tabIndex={0}><HtmlComponent htmlStr={textBody} /></div> :
          <div className='body' tabIndex={0}><HtmlComponent htmlStr={textBody} /></div>}
      </div>
    </div>
  );
});

export default ArticleQuestion;
