import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { LessonMode } from '../../../Constants';
import lessonManager from '../../managers/LessonManager';

const ToolbarLogo = (props) => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const reloadOldNumbers = (e) => {
    if (e.shiftKey && (lessonManager.playerMode === LessonMode.PREVIEW || lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW) && lessonManager.lessonOptions.hasSmallGotoCards) {
      const url = window.location.href;
      const stringToAdd = 'old=true&';
      const indexPosition = url.indexOf('?');
      if (indexPosition > -1) {
        const newUrl = url.slice(0, indexPosition + 1)
          + stringToAdd + url.slice(indexPosition + 1);
        window.open(newUrl);
      }
    }
  };

  return (
    <div aria-hidden='true' className='toolbar-logo-wrapper' onClick={reloadOldNumbers} tabIndex='-1'>
      <div className='toolbar-logo' tabIndex='-1' />
    </div>
  );
};

export default ToolbarLogo;
