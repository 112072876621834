import { Behavior } from './Behavior';
import UtilsService from '../services/UtilsService';
import questionService from '../services/QuestionService';
import { ContentType, LessonMode, PartialScoringType, QuestionFeedbackState } from '../../Constants';

export class ChoiceMatrixBehavior extends Behavior {
  checkForValidResponse = (response) => {
    // A response is valid if every answer is not empty
    return response.answers.every((answer) => answer !== '');
  }

  setCorrectAnswer = (state, model) => {
    const temp = {
      answers: UtilsService.safeMobxClone(model.validation?.correctAnswers || []),
      lessonElementId: model.lessonElementId
    };
    state.setCurrentResponse(UtilsService.safeMobxClone(temp));
  }

  resetStudentAnswer = (state, validation) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  isAutoScored = (model) => {
    return ContentType.getAutoscore(model.type) && !model.survey && !model.unscored;
  }

  getScore = (responseAnswer, model) => {
    const promptCount = model.prompts.length;
    // SubScore is the number of matching results
    const subScore = responseAnswer.answers.reduce((acc, studentAnswer, index) => {
      const correctAnswer = model.validation?.correctAnswers[index];
      const isCorrect = studentAnswer === correctAnswer;
      return acc + (isCorrect ? 1 : 0);
    }, 0);

    let score = 0;
    if (model.scoringType === PartialScoringType.EXACT) {
      score = (subScore === promptCount) ? model.maxScore : 0;
    } else if (model.scoringType === PartialScoringType.PARTIAL) {
      score = subScore / promptCount * model.maxScore;
    } else if (model.scoringType === PartialScoringType.PARTIAL_MATCH_RESPONSE) {
      score = subScore;
    }

    return score;
  }

  setResponseData = (data, responseAnswer) => {
    const { promptIndex, optionIndex } = data;
    responseAnswer.answers[promptIndex] = optionIndex.toString();
  }

  getAnswerFeedback = (id, responseAnswer) => {
    return true;
  }

  getInitializedResponse = (lessonElementId, model, activityId) => {
    const responseAnswer = {
      ...questionService.generateInitializedQuestionResponse({
        answers: model.prompts?.map(() => ''),
        isScored: false,
        isScoredCorrect: false,
        lessonElementId
      })
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    // Answers shown in question body. Nothing needs to be done here.
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;

        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectPrompt()}. ${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    // Assume answerId is the index of the prompt
    const currentAnswer = lessonElementState.currentResponseAnswer[answerId];
    const correctAnswer = lessonElementState.model.validation?.correctAnswers[answerId];
    return currentAnswer === correctAnswer;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new ChoiceMatrixBehavior();
