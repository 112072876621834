import GraphService from './GraphService';

export default class FreeGraphService extends GraphService {
  constructor(model) {
    super();
    this.model = model;
  }
}

//     function FreeGraph(id, questionText)
//     {
// TestItem.call(this, id, questionText);
//     }
//
//     FreeGraph.prototype = Object.create(Graph.prototype);
//     FreeGraph.prototype.constructor = Graph;
// FreeGraph.prototype.parent = TestItem.prototype;
//
// FreeGraph.BOX_WIDTH = 120;
// FreeGraph.BOX_HEIGHT = 80;
// FreeGraph.BOX_TOP_MARGIN = 40;
//
//     /**
//      * Prototype Functions
//      */
//     var proto = FreeGraph.prototype;
//
//     proto.type = "FreeGraph";
//
//     proto.getLabel = function() {
//
//         return "freeGraph";
//     }
//
//     proto.isAutoScore = function()
//     {
//     return false;
//     }
//
// proto.$getDrawCanvas = function()
// {
// return $("#" + this.getDrawCanvasId());
// };
//
// proto.getDrawCanvasId = function()
// {
// return this.getId() + "_drawCanvas";
// };
//
// proto.getCanvasHtml = function()
// {
// var html = '';
//
// html += this.getGraphControlsHtml();
//
// html += '<div class="canvasContainer">';
// html += '<div class="body canvasOrganizerBody editSection" style="height:' + this.getHeightPx() + ';width:' + this.getWidthPx() + '">';
// html += '<canvas id="' + this.getCanvasId() + '" class="canvas"></canvas>';
/// /html += '<div class="canvasTextInputContainer" style="height:' + this.getHeightPx() + '"></div>';
// html += '</div>';
//
// html += '<div class="body canvasOrganizerBody editSection" style="height:' + this.getHeightPx() + ';width:' + this.getWidthPx() + '">';
// html += '<canvas id="' + this.getDrawCanvasId() + '" class="canvas"></canvas>';
// html += '</div>';
// html += '</div>';
//
// return html;
// }
//
// proto.setDefaultEditMode = function()
// {
//     this.elementEditMode = Graph.DRAW;
// }
//
// proto.drawResponse = function()
// {
// var self = this;
// var responseModel = this.getResponseModel();
//
// if (responseModel.elementModels)
// {
// $.each(responseModel.elementModels, function(index, elementModel) {
// if (elementModel.elementType === "point")
// {
// self.drawPoint(elementModel.point);
// }
// else
// {
// self.addGraphElement(elementModel.points.startPoint, elementModel.points.endPoint, elementModel.elementType);
// }
// });
// }
// }
//
// /*proto.updateResponseModel = function()
// {
// var responseModel = {elementModels:this.getElementModels(), drawModels:this.findCanvasDrawObjects()};
//
// this.setResponseModel(responseModel);
// };*/
//
//
//
// proto.runtimeObjectReady = function()
// {
//     this.setDefaultEditMode();
//     this.createCanvas();
//     this.drawGraph();
//     this.createGraphControls();
// }
//
// proto.getElementTypeDatasource = function()
// {
// return [
//                 { text: "Free Draw", value: Graph.DRAW },
//                 { text: "Line", value: Graph.LINE },
//                 { text: "Ray", value: Graph.RAY },
//                 { text: "Segment", value: Graph.SEGMENT },
//                 { text: "Vector", value: Graph.VECTOR },
//                 { text: "Point", value: Graph.POINT }
//
//                 ]
// }
//
// proto.getTestItemEditorHtml = function()
//     {
// var html = '';
//
// html += this.getGraphEditorHtml();
//
//     return html;
//     };
//
//     /**
//      * Show as String
//      */
//     proto.toString = function() {
//
//         return "FreeGraph";
//     }
//
//
//
//
//     return FreeGraph;
//
// });
