import React, { useContext, useEffect, useState } from 'react';
import '../../css/DetailsDialog.scss';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';
import parse from 'html-react-parser';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';
import Loader from '../../lessonPlayer/components/Loader';
import itemBankService from '../services/ItemBankService';

// import UtilsService from '../../lessonPlayer/services/UtilsService';

import { register } from '../../i18n';
const t = register('DetailDialog');

const Standard = (props) => {
  const { standard } = props;
  const { id, name, description } = standard;

  // Per product, we are rendering all html tags here including wrapped <p>
  // const newParsedDesc = (description) ? UtilsService.reactHtmlParserWrapper(description) : '';
  const newParsedDesc = (description) ? parse(description) : '';

  return (
    <div key={id} className='standard'>
      <span className='name'>{name}</span>
      <span className='description'>{newParsedDesc}</span>
    </div>
  );
};

const Document = (props) => {
  const { document, isOpen, toggleDocState } = props;
  const { id, name, displayName, standards } = document;

  return (
    <React.Fragment key={id}>
      <div className='document clickable' data-value={id} onClick={toggleDocState}>
        <div className={(isOpen) ? 'caret-down' : 'caret-right'} />
        <span>{displayName || name}</span>
      </div>
      {isOpen && (
        <div className='standards'>
          {standards.map((standard) => {
            return (
              <Standard key={standard.id} standard={standard} />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const Tag = (props) => {
  const { tag } = props;
  const { id, name } = tag;

  return (
    <span key={id} className='tag'>{name}</span>
  );
};

const Category = (props) => {
  const { category, isOpen, toggleCategoryState } = props;
  const { id, name, tags } = category;

  return (
    <React.Fragment key={id}>
      <div className='category clickable' data-value={id} onClick={toggleCategoryState}>
        <div className={(isOpen) ? 'caret-down' : 'caret-right'} />
        <span>{name}</span>
      </div>
      {isOpen && (
        <div className='tags'>
          {tags.map((tag) => {
            return (
              <Tag key={tag.id} tag={tag} />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const DetailDialog = observer((props) => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  let standards = null;
  let tags = null;
  let text = null;
  let type = null;
  let guid = null;
  let name = null;
  let documents = null;
  let hasStandards = false;
  let hasTags = false;
  const [openDocs, setOpenDocs] = useState({});
  const [openCategories, setOpenCategories] = useState({});
  const [showSystemInfo, setShowSystemInfo] = useState(false);

  if (itemBankManager.detailInfo) {
    standards = itemBankManager.detailInfo.standards;
    tags = itemBankManager.detailInfo.tags;
    text = itemBankManager.detailInfo.text;
    type = itemBankManager.detailInfo.type;
    guid = itemBankManager.detailInfo.id;
    name = itemBankManager.detailInfo.name;
    documents = standards.documents;
    hasStandards = documents && documents.length > 0;
    hasTags = tags && tags.length > 0;
  }

  const modalType = 'item-details';

  const toggleDocState = (event, titleProps) => {
    const id = event.target.dataset.value || event.target.parentNode.dataset.value;
    setOpenDocs((prevState) => {
      return { ...prevState, [id]: !prevState[id] };
    });
  };

  const toggleCategoryState = (event) => {
    const id = event.target.dataset.value || event.target.parentNode.dataset.value;
    setOpenCategories((prevState) => {
      return { ...prevState, [id]: !prevState[id] };
    });
  };

  const toggleSystemInfo = (event) => {
    setShowSystemInfo(!showSystemInfo);
  };

  useEffect(() => {
    const docs = {};
    if (hasStandards) {
      documents.forEach((document, i) => {
        // initially set just the first document as open
        docs[document.id] = i === 0;
      });
      setOpenDocs(docs);
    }
    const categories = {};
    if (hasTags) {
      tags.forEach((category, i) => {
        // initially set just the first document as open
        categories[category.id] = i === 0;
      });
      setOpenCategories(categories);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStandards, hasTags]);

  return (
    <div className='detail-dialog'>
      <ReactModal
        disableResize={true}
        initHeight={600}
        initWidth={900}
        isOpen={itemBankManager.detailDialogOpen} top={50}>

        <div className='detail-dialog-body'>
          {!(itemBankManager.loading) ? (
            <>
              <div className='solution-header'>
                <div className='title-label basic'>{t('headerLabel')}</div>
                <div className='x-close-button mask-image' onClick={() => {
                  itemBankService.setDetailDialogOpen(false);
                }} />
              </div>
              <div className='scroll-area'>
                {modalType === 'item-details' && (
                  <>
                    <div className={`${modalType} standards-label`}>{t('questionStemLabel')}</div>
                    <div className='standards-underline' />
                    {text && (
                      <div className='container'>
                        <div className='text-content'>
                          <HtmlComponent htmlStr={text} />
                        </div>
                      </div>
                    )}
                    <div className={`${modalType} standards-label`}>{t('standardsLabel')}</div>
                    <div className='standards-underline' />
                    {hasStandards && (
                      <div className='container'>
                        {documents.map((document) => {
                          return (
                            <Document
                              key={document.id}
                              data-id={document.id}
                              document={document}
                              isOpen={openDocs[document.id]}
                              toggleDocState={toggleDocState} />
                          );
                        })}
                      </div>
                    )}
                    {!hasStandards && <div className='container'><div className='document'>{t('noStandardsLabel')}</div></div>}
                    <div className={`${modalType} standards-label`}>{t('tagsLabel')}</div>
                    <div className='standards-underline' />
                    {hasTags && (
                      <div className='container'>
                        {tags.map((category) => {
                          return (
                            <Category
                              key={category.id}
                              category={category}
                              isOpen={openCategories[category.id]}
                              toggleCategoryState={toggleCategoryState} />
                          );
                        })}
                      </div>
                    )}
                    {!hasTags && <div className='container'><div className='category'>{t('noTagsLabel')}</div></div>}
                    <div className='standards-underline' />
                    <div className='container'>
                      <React.Fragment key='system-info-section'>
                        <div className={`${modalType} standards-label`}>
                          <div className='clickable' onClick={toggleSystemInfo}>
                            <div className={(showSystemInfo) ? 'caret-down' : 'caret-right'} />
                            <span>{t('systemInformationLabel')}</span>
                          </div>
                        </div>
                        {showSystemInfo && (
                          <div className='category'>
                            <div className={`${modalType} standards-label`}>{t('itemTypeLabel')}</div>
                            <div className='standards-underline' />
                            {type && (
                              <div className='container'>
                                <div className='text-content'>
                                  {type}
                                </div>
                              </div>
                            )}
                            <div className={`${modalType} standards-label`}>{t('guidLabel')}</div>
                            <div className='standards-underline' />
                            {guid && (
                              <div className='container'>
                                <div className='text-content'>
                                  {guid}
                                </div>
                              </div>
                            )}
                            <div className={`${modalType} standards-label`}>{t('itemIdLabel')}</div>
                            <div className='standards-underline' />
                            {name && (
                              <div className='container'>
                                <div className='text-content'>
                                  {name}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    </div>
                  </>
                )}
              </div>
            </>
          )
            :
            <div className='dialog-loader'><Loader /></div>}
        </div>
      </ReactModal>
    </div>
  );
});

export default DetailDialog;
