import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import CheckboxTree from 'react-checkbox-tree';
import itemBankService from '../services/ItemBankService';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const StandardsSelectionTree = observer((props) => {
  const {
    itemBankManager,
    curriculumManager
  } = useContext(MobXProviderContext);

  const [searchElementIds, setSearchElementIds] = useState([]);

  const handleChangeStandards = async (searchElementIds, node, isTOCSearch = false) => {
    const searchStandards = [];
    // Convert the search curriculum element ids to a standards list
    if (curriculumManager.stdFrwkStandardsMap) {
      searchElementIds.forEach((elementId) => {
        // Get the standard for the cmapElement
        const stdFrwkElement = curriculumManager.stdFrwkStandardsMap.get(elementId);
        if (stdFrwkElement) {
          searchStandards.push(stdFrwkElement.standardId);
        }
      });
    }

    setSearchElementIds(searchElementIds);
    await itemBankManager.setSearchStandards(searchStandards);
    itemBankService.searchContentItemsForItemBank();
  };

  return (
    <>
      <div className='checkbox-tree-container'>
        <div className='filter-label' />
        <CheckboxTree
          checked={searchElementIds}
          expanded={curriculumManager.curriculumTreeExpanded}
          icons={{
            check: <span className='rct-icon rct-icon-check' />,
            uncheck: <span className='rct-icon rct-icon-uncheck' />,
            halfCheck: <span className='rct-icon rct-icon-half-check' />,
            expandClose: <span className='icon-expand-close' />,
            expandOpen: <span className='icon-expand-open' />,
            expandAll: <span className='rct-icon rct-icon-expand-all' />,
            collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
            parentClose: <span className='rct-icon rct-icon-parent-close' />,
            parentOpen: <span className='rct-icon rct-icon-parent-open' />,
            leaf: <span className='rct-icon rct-icon-leaf' />
          }}
          nativeCheckboxes={true}
          noCascade={true}
          nodes={curriculumManager.adoptedStandardsJson}
          onCheck={(checked, node) => handleChangeStandards(checked, node, true)}
          onExpand={(expanded) => curriculumManager.addCurriculumTreeExpanded(expanded)} />
      </div>
    </>
  );
});

export default StandardsSelectionTree;
