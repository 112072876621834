import decode from 'jwt-decode';
import { register } from '../../i18n';
import studentActivityManager from '../managers/StudentActivityManager';
import userManager from '../managers/UserManager';
import lessonManager from '../managers/LessonManager';
import { LessonMode } from '../../Constants';

const t = register('GlobalQuestionLabels');

export class AuthService {
  authKey = null;
  jwtToken = null;

  setAuthKey = (key) => {
    this.authKey = key;
  }

  setJwtToken = (key) => {
    this.jwtToken = key;
  }

  init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, LOGO) {
    this.ecms = API_ROOT;
    this.publisher = PUBLISHER_ROOT;
    this.publisherSatelliteCode = PUBLISHER_SATELLITE_CODE;
    this.JSONendpoints = ['/login'];
    this.logo = LOGO;
  }

  getToday = () => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  }

  async login(username, password) {
    // Set the publisher satellite code
    const publisherSatelliteCode = `${this.publisherSatelliteCode}`;
    // Get a token from api server using the fetch api
    const token = await this.post(`${this.ecms}/login`, {
      body: JSON.stringify({
        username,
        password,
        publisherSatelliteCode
      })
    });
    if (token) {
      this.setToken(token);
      return true;
    }
    return false;
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from session storage

    if (token === null || token === undefined || token === 'null') return false;
    if (this.isTokenExpired(token)) return false;
    if (!token.includes('Bearer')) return false;
    return true;
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  setToken(idToken) {
    try {
      // Saves user token
      this.setJwtToken(idToken);
      sessionStorage.setItem(`jwtToken_${this.publisherSatelliteCode}`, idToken);
    } catch (error) {
      // console.error(error);
    }
  }

  getToken() {
    try {
      // Retrieves the user token
      return sessionStorage.getItem(`jwtToken_${this.publisherSatelliteCode}`);
    } catch (error) {
      // console.error(error);
    }
  }

  logout() {
    try {
      // Clear user token and profile data from session storage
      sessionStorage.removeItem(`jwtToken_${this.publisherSatelliteCode}`);
    } catch (error) {
      // console.error(error);
    }
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }

  getAudioUrl() {
    return '';
  }

  convertToFormData(data) {
    let result = '';
    const entries = Object.entries(data);
    let key, value;
    for ([key, value] of entries) {
      if (typeof value === 'string') {
        result += `${key}=${encodeURIComponent(value)}&`;
      } else {
        result += `${key}=${encodeURIComponent(`${value}`)}&`;
      }
    }
    result = result.slice(0, -1); // remove trailing &
    return result;
  }

  async getCleverLoginUrl() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/clever/url?code=${this.publisherSatelliteCode}`);
    return result ? result.clever_url : null;
  }

  async getClassLinkLoginUrl() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/classlink/url?code=${this.publisherSatelliteCode}`);
    return result ? result.classlink_url : null;
  }

  async getOneRosterLoginEnabled() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/isOneRosterEnabled?code=${this.publisherSatelliteCode}`);
    return result ? result.oneroster_enabled : null;
  }

  async getOneRosterDistricts() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/ViewOneRosterDistricts?code=${this.publisherSatelliteCode}`);
    return result ? result.districts : null;
  }

  async loginWithOneRoster(username, password, institutionId) {
    // Get a token from api server using the fetch api
    try {
      const result = await this.fetch(`${this.ecms}/api/sso/oneroster/oauth/${this.publisherSatelliteCode}`, {
        method: 'POST',
        body: {
          username,
          password,
          institutionId
        }
      });
      this.setToken(result);
      return true;
    } catch (e) {
      console.error(e);
      // Setting the token in session storage
      return false;
    }
  }

  async logInWithAuthKey(authKey) {
    // remove any existing jwt so it isn't used in the checkUser call causing authKey to be ignored
    await this.logout();
    const response = await this.post(`${this.ecms}/api/checkUser`, {
      body: `authKey=${authKey}`
    });
    if (response) {
      this.setToken(response.jwtToken);
      return response;
    }
    return null;
  }

  isGoogleDoc = (url) => {
    return url.indexOf('docs.google.com') !== -1;
  }

  fixGoogleDocUrl = (url) => {
    const queryStringChar = url.indexOf('?') === -1 ? '?' : '&';
    const missingEmbedString = url.indexOf('embedded=true') === -1;

    if (missingEmbedString) {
      url = `${url + queryStringChar }embedded=true`;
    }
    return url;
  }

   getFlowpaperUrl = (model) => {
     let userId = '';
     if (userManager.isStudent) {
       userId = userManager.userId;
     }
     if (userManager.isTeacher && lessonManager.playerMode === LessonMode.SCORING) {
       userId = studentActivityManager.studentId;
     }

     const activityId = (studentActivityManager.activityId) ? studentActivityManager.activityId : '';
     const url = `${this.ecms}/api/launchFlowpaper?authKey=${this.authKey}&contentItemId=${model.contentItemId}&entityId=${model.lessonElementId}&activityId=${activityId}&studentId=${userId}`;
     return url;
   }

   async fetch(url, options) {
     // performs api calls sending the required authentication headers
     const headers = {
       Accept: 'application/json'
     };

     const JSONendpoint = this.JSONendpoints.some((endpoint) => { return (url.indexOf(endpoint) >= 0); });
     if (JSONendpoint) {
       headers['Content-Type'] = 'application/json';
     } else {
       headers['Content-Type'] = 'application/x-www-form-urlencoded';
     }

     if (this.loggedIn()) {
       headers.Authorization = this.getToken();
     } else if (this.jwtToken) { // work around: for lrnPlayer calls from canvas the token doesn't save to cookies properly.
       headers.Authorization = this.jwtToken;
     }

     if (typeof options.body === 'object' && !(options.body instanceof FormData)) {
       options.body = this.convertToFormData(options.body);
     }
     if (options.body && options.body instanceof FormData) {
       delete headers['Content-Type'];
     }

     return fetch(url, {
       headers,
       ...options
     }).then((response) => {
       const success = response.status >= 200 && response.status < 300; // Success status lies between 200 to 300
       if (success) {
         return url.includes('/login') ? response.headers.get('authorization') : response.json();
       } else {
         response.statusText && console.log(response.statusText);
         const error = new Error(response.statusText || `${t('errorGeneric')}`);
         error.message = response.statusText || `${t('errorGeneric')}`;
         error.response = response;
         throw error;
       }
     }).catch((e) => {
       const error = new Error(e.message);
       error.message = e.message;
       error.response = e;
       throw error;
     });
   }

   async request(url, { withoutCheck, ...options }) {
     return await this.fetch(url, { method: 'GET', ...options })
       .then((response) => (url.includes('/login') || response.status === 'SUCCESS' || withoutCheck ? response : null))
       .catch((error) => console.warn(error) || null);
   }

   async safe_fetch(url, options) {
     return await this.request(url, { ...options, method: 'GET' });
   }

   async post(url, options) {
     return await this.request(url, { ...options, method: 'POST' });
   }

   async put(url, options) {
     return await this.request(url, { ...options, method: 'PUT' });
   }

   async delete(url, options) {
     return await this.request(url, { ...options, method: 'DELETE' });
   }

  downloadContentItem = (contentItemId) => {
    window.open(`${this.ecms}/api/downloadResource?authKey=${this.authKey}&contentItemId=${contentItemId}`, '_blank');
  }

  getResourceUrlByFileName = (name) => {
    return `${this.ecms}/api/redirectToResource?authKey=${this.authKey}&resourceFolder=userFiles&resourceFileName=${name}`;
  }

  getResourceUrlByResourceId = (id) => {
    return `${this.ecms}/api/redirectToResource?authKey=${this.authKey}&resourceFolder=userFiles&entityId=${id}`;
  }

  getResourceUrlByContentItemId= (id) => {
    return `${this.ecms}/api/redirectToResource?contentItemId=${id}&authKey=${this.authKey}`;
  }

  getEntityIdFromStreamUrl = (str) => {
    let indexStart = str.indexOf('entityId=');
    if (indexStart > -1) {
      indexStart += 9;
    }
    return str.substr(indexStart, 32);
  }

  getStreamResourceUrl = (resourceId, type) => {
    return `${this.ecms}/api/redirectToStreamUrl?authKey=${this.authKey}&resourceFolder=userFiles&entityId=${resourceId}&entityTypeId=${type}&cacheBuster=${(new Date().getTime())}`;
  }

  noCache = () => {
    const date = new Date();
    return date.getTime();
  }
}
export default new AuthService();
