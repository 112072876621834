import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/ImageLabelCore.scss';
import '../../../css/ImageLabelTyping.scss';

import Auth from '../../services/AuthService';

import { ImageLabelMargins, LessonMode } from '../../../Constants';

import promptService from '../../services/PromptService';
import questionService from '../../services/QuestionService';
import responseService from '../../services/ResponseService';
import textQuestionUtilsService from '../../services/TextQuestionUtilsService';

import useWirisParser from '../../../hooks/useWirisParser';

import Guideline from '../tools/Guideline';

import FeedbackIcon from '../FeedbackIcon';
import HtmlComponent from '../HtmlComponent';
import RichTextEditor from '../RichTextEditor';
import useStyleEvents from '../../../hooks/useStyleEvents';
import UtilsService from '../../services/UtilsService';
import lessonService from '../../services/LessonService';
import PrintQuestionNumber from './PrintQuestionNumber';

const ImageLabel = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  const [promptElements, setPromptElements] = useState([]);
  const [imgBodyDimensions, setImgBodyDimensions] = useState({});

  const componentRef = useRef();
  const imageLabelQuestionSectionTitleRef = useRef();
  const imgRef = useRef();
  const imgBodyRef = useCallback((node) => {
    if (node !== null) {
      setImgBodyDimensions({ height: node.getBoundingClientRect().height, width: node.getBoundingClientRect().width });
    }
  }, []);
  const promptRef = useCallback((node) => {
    if (node !== null) {
      setPromptElements((prevState) => [...prevState, { height: node.getBoundingClientRect().height, width: node.getBoundingClientRect().width }]);
    }
  }, []);

  useStyleEvents(lessonElementId);
  useWirisParser(componentRef);

  const [loadingImageLabelTyping, setLoadingImageLabelTyping] = useState(true);

  const DEFAULT_IMAGE_WRAPPER_MAX_SIZE = 600;
  const DEFAULT_IMAGE_MAX_SIZE = 500;

  // TODO remove // const [imageLabelQuestionMaxWidth, setImageLabelQuestionMaxWidth] = useState(DEFAULT_IMAGE_WRAPPER_MAX_SIZE);

  const [imageLabelTitleOffset, setImageLabelTitleOffset] = useState(0);

  const [imageLabelImg, setImageLabelImg] = useState(null);

  const {
    lessonElementState,
    model,
    readOnly: isQuestionReadOnly,
    toolbar,
    uiState,
    userInputMap
  } = questionService.initQuestionComponent({
    includeUiFeedbackState: true,
    lessonElementId,
    questionClassName: 'image-label-question'
  });

  const showAnswerFeedback = uiState?.showAnswerFeedback || false;
  const isDisabled = isQuestionReadOnly || showAnswerFeedback;

  const { contentType } = model;
  const useMargin = (!model.maximizeImageSize && contentType !== 'text');

  const [altText, setAltText] = useState('');
  /** equivalent to componentDidMount(), i.e. only called on initial load */
  useEffect(() => {
    setLoadingImageLabelTyping(true);

    const img = new Image();
    img.onload = () => {
      setImageLabelImg(img);
    };
    img.src = Auth.getResourceUrlByFileName(model.croppedImageSource || model.imageSource);

    setLoadingImageLabelTyping(false);
    const doAction = async () => {
      if (UtilsService.isNullOrEmpty(model.altText) && !model.altTextLoaded) {
        const contentItem = await lessonService.fetchContentItem(model.contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        }
      }
    };
    doAction();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // on update
  useEffect(() => { // eslint-disable-line react-hooks/exhaustive-deps
    if (model.contentType === 'text') {
      const _imageLabelTitleOffset = imageLabelQuestionSectionTitleRef?.current?.clientHeight;
      if (_imageLabelTitleOffset && !imageLabelTitleOffset) {
        setImageLabelTitleOffset(_imageLabelTitleOffset || 0);
      }
    } else {
      // TODO unused
      // const _imgTitleRefDomRect = imageLabelQuestionSectionTitleRef?.current?.getBoundingClientRect?.();
      // if (_imgTitleRefDomRect?.top >= 0 && !imageLabelTitleOffset) {
      //   setImageLabelTitleOffset(_imgTitleRefDomRect.top);
      // }
    }
  });

  /** equivalent to componentWillUnmount(), i.e. called whenever component is unmounting */
  useEffect(() => () => {
    // placeholder
  });

  const renderImageLabel = (_props = null) => {
    let imageBodyStyle = {};
    let imageStyle = {};

    if (model.contentType === 'text') {
      imageBodyStyle = {
        height: (+model.textBodyHeight > 0 ? `${model.textBodyHeight}px` : 'auto'),
        // heightNum: (+model.textBodyHeight > 0 ? model.textBodyHeight : 0),
        margin: 0,
        maxWidth: (+model.textBodyWidth > 0 ? `${model.textBodyWidth}px` : 'auto'),
        width: (+model.textBodyWidth > 0 ? `${model.textBodyWidth}px` : 'auto'),
        widthNum: (+model.textBodyWidth > 0 ? model.textBodyWidth : 0)
      };
    } else if (model.maximizeImageSize) {
      let maxWidth;
      if (+model.maxSize > 0) {
        maxWidth = +model.maxSize;
      } else {
        maxWidth = Math.max((imageLabelImg?.width > 0 ? imageLabelImg.width : DEFAULT_IMAGE_WRAPPER_MAX_SIZE), DEFAULT_IMAGE_WRAPPER_MAX_SIZE);
      }

      let minWidth;
      if (+model.minSize > 0) {
        minWidth = Math.min(model.minSize, maxWidth);
      }

      imageBodyStyle = {
        height: 'unset',
        margin: 0,
        minWidth: minWidth || 'auto',
        maxWidth,
        width: 'unset'
      };
    } else {
      // `maximizeImageSize` is false, so we need to set the margins and the image container size
      // eslint-disable-next-line no-unused-vars
      const [leftMargin, rightMargin, _topMargin, _bottomMargin] = [model.leftMargin, model.rightMargin, model.topMargin, model.bottomMargin].map((marginString) => ImageLabelMargins[marginString]);

      imageBodyStyle = {
        // TODO remove // maxWidth: DEFAULT_IMAGE_WRAPPER_MAX_SIZE
        maxWidth: imageLabelImg?.width >= DEFAULT_IMAGE_MAX_SIZE ? DEFAULT_IMAGE_WRAPPER_MAX_SIZE :
          Math.min(DEFAULT_IMAGE_MAX_SIZE, Math.min((imageLabelImg?.width || 0), DEFAULT_IMAGE_MAX_SIZE)),
        marginLeft: leftMargin,
        marginRight: rightMargin
      };
      // TODO remove
      // imageStyle = {
      //   // TODO remove // margin: `${topMargin}px ${rightMargin}px ${bottomMargin}px ${leftMargin}px`,
      // };

      imageStyle = {
        width: Math.min(imageLabelImg.width, DEFAULT_IMAGE_MAX_SIZE)
      };
    }

    const imageLabelQuestionInnerClassNames = classNames('image-label-question-inner');
    const questionTitle = <HtmlComponent htmlStr={model.questionText || ''} />;

    return (
      <div className='image-label-question' ref={componentRef}/* style={{ maxWidth: imageLabelQuestionMaxWidth }} */>
        <div ref={imageLabelQuestionSectionTitleRef} className='image-label-question-section-title'>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='test-item-question'>
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
            {questionTitle}
          </div>
        </div>

        <div className={imageLabelQuestionInnerClassNames}
          style={model.contentType === 'text' ? {
            position: 'relative'
            // TODO remove // marginBottom: `${imageLabelTitleOffset || 0}px`,
            // TODO remove // top: `${imageLabelTitleOffset || 0}px`
          } : {}}>
          <div className='image-label-question-section-body'>
            <div className='test-item-answers'>
              <div className='image-label-typing-body'>
                <div className='image-label-typing-body-nodes'>
                  <div className={classNames('imageBodyContainer ', `${model.contentType}-type`)}>
                    {model.imageTitle && (
                      <div className='textInput imageLabelTitle'>
                        <HtmlComponent htmlStr={model.imageTitle} />
                      </div>
                    )}
                    <div ref={imgBodyRef} className='imageBody stop' style={imageBodyStyle}>
                      {model.contentType === 'text' ? (
                        <div className='textBody'>
                          <HtmlComponent htmlStr={model.textBody} />
                        </div>
                      ) : (
                        <img
                          ref={imgRef}
                          alt={model.altText || altText || ''}
                          className='test-item-image imageLabelImage'
                          src={Auth.getResourceUrlByFileName(model.croppedImageSource || model.imageSource)}
                          style={imageStyle} />
                      )}
                      {model.prompts.map((prompt, index) => {
                        return (
                          renderBlankSection(prompt, index, useMargin)
                        );
                      })}
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    );
  };

  const getPromptCoords = (prompt, promptElement) => {
    if (!prompt || !promptElement) {
      return {
        top: 0,
        left: 0,
        height: 'fit-content',
        width: 'fit-content'
      };
    }
    const height = (prompt.heightPercent) ? `${prompt.heightPercent}%` : 'fit-content';
    const width = (prompt.widthPercent) ? `${prompt.widthPercent}%` : 'fit-content';

    let top, left;
    if (model.targetType === 'box') {
      top = `${prompt.point.topPercent}%`;
      left = `${prompt.point.leftPercent}%`;
    } else {
      if (prompt.pointerDirection === 'left') {
        let leftCoord = Math.min(prompt.point.left, imgBodyDimensions.width - promptElement.width);
        if (useMargin) {
          leftCoord = prompt.point.left;
        }
        const topCoord = prompt.point.top - promptElement.height / 2;

        if (model.maximizeImageSize) {
          left = `${prompt.point.leftPercent}%`;
          top = `calc(${prompt.point.topPercent}% - ${promptElement.height / 2}px)`;
        } else {
          left = leftCoord;
          top = topCoord;
        }
      } else if (prompt.pointerDirection === 'right') {
        let leftCoord = Math.max(prompt.point.left - promptElement.width, 0);
        if (useMargin) {
          leftCoord = prompt.point.left;
        }
        const topCoord = prompt.point.top - promptElement.height / 2;

        if (model.maximizeImageSize) {
          left = `calc(${prompt.point.leftPercent}% - ${promptElement.width}px)`;
          top = `calc(${prompt.point.topPercent}% - ${promptElement.height / 2}px)`;
        } else {
          left = leftCoord;
          top = topCoord;
        }
      } else if (prompt.pointerDirection === 'down') {
        const leftCoord = prompt.point.left - promptElement.width / 2;
        let topCoord = Math.max(prompt.point.top - promptElement.height, 0);
        if (useMargin) {
          topCoord = prompt.point.top;
        }
        if (model.maximizeImageSize) {
          left = `calc(${prompt.point.leftPercent}% - ${promptElement.width / 2}px)`;
          top = `calc(${prompt.point.topPercent}% - ${promptElement.height}px)`;
        } else {
          left = leftCoord;
          top = topCoord;
        }
      } else if (prompt.pointerDirection === 'up') {
        const leftCoord = prompt.point.left - promptElement.width / 2;
        let topCoord = Math.min(prompt.point.top, imgBodyDimensions.height - promptElement.height);
        if (useMargin) {
          topCoord = prompt.point.top;
        }
        if (model.maximizeImageSize) {
          left = `calc(${prompt.point.leftPercent}% - ${promptElement.width / 2}px)`;
          top = `${prompt.point.topPercent}%`;
        } else {
          left = leftCoord;
          top = topCoord;
        }
      }
    }
    return {
      top,
      left,
      height,
      width
    };
  };

  const renderBlankSection = (prompt, index, useMargin) => {
    const dataId = prompt.id;
    const { top, left, height, width } = getPromptCoords(prompt, promptElements[index]);

    // default non-margin use.
    const elemStyle = {
      height,
      minHeight: 34,
      left,
      top,
      width
    };

    const innerStyle = {
      // TODO remove // minWidth: 'unset',
      whiteSpace: 'normal'
    };

    const currentUserInputValue = lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text || userInputMap?.get(dataId)?.text || '';

    // TODO unused // const currentResponseItem = responseItems[currentResponseItemIndex];

    const promptAnswerStatus = promptService.getAnswerStatusForCurrentPrompt({
      currentUserInputValue,
      dataId,
      model
    });

    const uiState = questionFeedbackManager.getUiState?.(lessonElementId);
    const { noSubmit } = questionFeedbackManager;

    const showAnswerFeedback = (uiState?.showAnswerFeedback && !noSubmit) || false;
    return (
      <div key={dataId}
        ref={promptRef}
        className={classNames('imageLabelTyping noFocusOutline image-label-section image-label-typing-blank-section',
          model.responseFormat,
          // prompt.pointerDirection, // TODO remove, this causes prompt position issues
          {
            [prompt.pointerDirection]: model.contentType === 'text' || model.targetType === 'arrow',
            'feedback': showAnswerFeedback,
            'has-list-number': !!model.numberedBlanks
          })}
        data-id={dataId}
        style={{
          ...elemStyle
          // TODO remove // minHeight: elemStyle.height
        }}>
        {model.numberedBlanks && <div className='list-number' />}
        <FeedbackIcon
          answerId={dataId}
          lessonElementId={lessonElementId}
          lessonElementState={lessonElementState}
          model={model}
          uiState={uiState}
          {...lessonElementState.currentResponseAnswer}
          {...textQuestionUtilsService.getFeedbackIconOptionsForDecoratingTextInputs({
            feedbackIconSvgTransform: 'translate(-13, 0)'
            // feedbackIconSvgTransform: 'translate(-13, -10)'
          })} />
        <div className={classNames('imageLabelTypingInner', model.targetType, {
          'has-content': !!currentUserInputValue
        })} style={{
          ...(prompt.widthPercent ? innerStyle : {})
        }}>
          <div className={classNames(`imageLabelResponse noFocusOutline normal ${promptAnswerStatus}`, {
            'arrow_box': model.targetType === 'arrow',
            'has-content': !!currentUserInputValue
          })} data-color={prompt.labelBackgroundColor}>
            {model.responseFormat !== 'numeric' ? (
              <RichTextEditor
                data={currentUserInputValue || ''}
                disabled={isDisabled}
                forceNoToolbar={model.responseFormat === 'text'}
                lessonElementId={lessonElementId}
                onChange={(data) => {
                  responseService.textChangeHandler(
                    data, lessonElementId, { dataId, lessonElementState }
                  );
                }}
                readOnly={isDisabled}
                skipLaunchMathImmediately
                tabIndex={-1}
                toolbar={model.responseFormat !== 'text' ? toolbar : []} />
            ) : (
              <input className={classNames('imageLabelTypingResponseText'/* , imageLabelTypingResponseClassNames */)}
                disabled={isDisabled}
                onChange={(event) => responseService.textChangeHandler(
                  event, lessonElementId, { dataId, lessonElementState }
                )}
                readOnly={isDisabled}
                type='number'
                value={currentUserInputValue || ''} />
            )}
          </div>
        </div>
      </div>
    );
  };
  return !loadingImageLabelTyping && model && (model.contentType === 'text' || imageLabelImg) && renderImageLabel();
});

export default ImageLabel;
