// eslint-disable-next-line no-unused-vars
import React from 'react';
import { register } from '../../i18n';
import ArticleWidget from '../components/lessonItems/widgets/Article';
import AudioWidget from '../components/lessonItems/widgets/Audio';
import ButtonWidget from '../components/lessonItems/widgets/Button';
import DialogButtonWidget from '../components/lessonItems/widgets/DialogButton';
import GridWidget from '../components/lessonItems/widgets/Grid';
import ImageWidget from '../components/lessonItems/widgets/Image';
import ResourceWidget from '../components/lessonItems/widgets/Resource';
import TextBlockWidget from '../components/lessonItems/widgets/TextBlock';
import VideoWidget from '../components/lessonItems/widgets/Video';
import PdfWidget from '../components/lessonItems/widgets/Pdf';
import DownloadList from '../components/lessonItems/widgets/DownloadList';
import Auth from './AuthService';

const t = register('Widgets');

export const Widgets = {
  ARTICLE: 'Article',
  AUDIO: 'Audio',
  BUTTON: 'Button',
  DIALOG_BUTTON: 'DialogButton',
  DOWNLOAD_LIST: 'DownloadList',
  GRID: 'Grid',
  IMAGE: 'Image',
  PDF: 'Pdf',
  RESOURCE: 'Resource',
  TEXT_BLOCK: 'TextBlock',
  VIDEO: 'Video',
  FILE: 'File',
  UPLOAD_FILE: 'Upload File',
  getLabel : (type) => {
    if(!type) {
      return '';
    }
    return t(type);
  }
};

export const Resource_Types = ['Article', 'Audio', 'Image', 'Pdf', 'Resource', 'Video'];

export class WidgetService {
  isResource = (model) => {
    if (model.type === Widgets.ARTICLE ||
      model.type === Widgets.AUDIO ||
      model.type === Widgets.IMAGE ||
      model.type === Widgets.PDF || model.type === Widgets.VIDEO) {
      return true;
    }
    return false;
  }

  getChild = (model, modelId) => {
    let foundModel = null;
    model.children.forEach((model, index) => {
      if (model.id === modelId) {
        foundModel = model;
        return false;
      }
    });
    return foundModel;
  };

  getContentUrl = (model) => {
    let url = '';
    if (model.type === Widgets.VIDEO ||
      model.type === Widgets.AUDIO ||
      model.type === Widgets.PDF) {
      url = Auth.getStreamResourceUrl(model.resourceId, model.entityTypeId);
    } else if (model.contentItem) {
      url = Auth.getResourceUrlByContentItemId(model.contentItem.id);
    }
    return url;
  }

  getWidgetComponent = (model) => {
    if (model.type === Widgets.TEXT_BLOCK) {
      return (TextBlockWidget);
    }
    if (model.type === Widgets.AUDIO) {
      return (AudioWidget);
    }
    if (model.type === Widgets.ARTICLE) {
      return (ArticleWidget);
    }
    if (model.type === Widgets.BUTTON) {
      return (ButtonWidget);
    }
    if (model.type === Widgets.DIALOG_BUTTON) {
      return (DialogButtonWidget);
    }
    if (model.type === Widgets.GRID) {
      return (GridWidget);
    }
    if (model.type === Widgets.IMAGE) {
      return (ImageWidget);
    }
    if (model.type === Widgets.RESOURCE) {
      return (ResourceWidget);
    }
    if (model.type === Widgets.VIDEO) {
      return (VideoWidget);
    }
    if (model.type === Widgets.PDF) {
      return (PdfWidget);
    }
    if (model.type === Widgets.DOWNLOAD_LIST) {
      return (DownloadList);
    }

    return (null);
  };
}

export default new WidgetService();
