import { action, computed, makeObservable, observable } from 'mobx';

export class CurriculumManager {
  @observable productCmaps = [];
  @observable expandedSelected = [];
  @observable cmapStandardsSelected = [];
  @observable cmapStandardsJson = [];

  @observable cmapStandardsMap = new Map();

  @observable viewMode = 'build';
  @observable previewId = 'null';

  @observable standardToBookmarkMap = new Map();
  @observable authorityStates = [];
  @observable adoptedStandardsJson = [];
  @observable stdFrwkStandardsMap = new Map();
  @observable standardDocuments = [];
  @observable standardsResponseData = null;

  @observable curriculumTreeExpanded = [];

  @observable bookmarkedStandardIds = [];

  constructor() {
    makeObservable(this);
  }

  @action setCmapStandardsJson = (json) => {
    this.cmapStandardsJson = json;
  }

  @action addCmapStandardsMapItem = (id, item) => {
    if (item && id) {
      if (!this.cmapStandardsMap.has(id)) {
        this.cmapStandardsMap.set(id, item);
      }
    }
  }

  @action addExpanded = (id) => {
    this.expandedSelected.push(id);
  }

  @action clearExpanded = () => {
    this.expandedSelected = [];
  }

  @action addCurriculumTreeExpanded = (arr) => {
    this.curriculumTreeExpanded = arr;
  }

  @action clearCurriculumTreeExpanded = () => {
    this.curriculumTreeExpanded = [];
  }

  @action setCmapStandardSelected = (selected) => {
    this.cmapStandardsSelected = selected;
    this.setSearchStandards(selected);
  }

  @action setCmapExpanded = (selected) => {
    this.expandedSelected = selected;
  }

  @action clearStandardsSelected = () => {
    this.cmapStandardsSelected = [];
  }

  @action setProductCmaps = (data) => {
    data.forEach((cm) => {
      const cmap = { value: cm.id, label: cm.name };
      this.productCmaps.push(cmap);
    });
  }

  @action setAuthorityStates(states) {
    states.forEach((st) => {
      const state = { value: st.id, label: st.fullName };
      this.authorityStates.push(state);
    });
  }

  @action setStandardDocuments(docs) {
    if (docs != null) {
      this.standardDocuments = docs;
      console.log(this.standardDocuments);
    }
  }

  @computed get standardBookmarkArray() {
    if (this.standardToBookmarkMap) {
      return Array.from(this.standardToBookmarkMap.values());
    }
    return null;
  }

  @action getStandardDocuments = () => this.standardDocuments;

  @action setBookmarkedStandardIds(ids) {
    if (ids != null) {
      this.bookmarkedStandardIds = ids;
    }
  }

  @action clearSearchResults = () => {
    this.itemBankItemsMap.clear();
  }

  @action loadStandardBookmarks = (bookmarkStandardData) => {
    const ids = [];
    if (bookmarkStandardData) {
      this.standardToBookmarkMap.clear();
      bookmarkStandardData.forEach((data) => {
        if (data.id !== null && data.id !== '' && data.id !== undefined) {
          this.standardToBookmarkMap.set(data.sourceId, data);
          ids.push(data.sourceId);
        }
      });
      this.setBookmarkedStandardIds(ids);
    }
  }

  @action setStandardsResponseData = async (val) => {
    this.standardsResponseData = val;
  }

  @action setAdoptedStandardsJson = async (json) => {
    this.adoptedStandardsJson = json;
  }

  @action clearAdoptedStandardsJson = () => {
    this.adoptedStandardsJson = [];
  }

  @action addStdFrwkStandardsMap = (id, item) => {
    if (item && id) {
      if (!this.stdFrwkStandardsMap.has(id)) {
        this.stdFrwkStandardsMap.set(id, item);
      }
    }
  }
}

export default new CurriculumManager();
