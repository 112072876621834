import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import ItemBankCard from './ItemBankCard';
import BasicPaginator from './BasicPaginator';
import Loader from '../../lessonPlayer/components/Loader';
import itemBankService from '../services/ItemBankService';
import { confirmDialog } from '../../lessonPlayer/components/dialogs';

const ResourceFilterItemList = observer((props) => {
  const {
    itemBankManager,
  } = useContext(MobXProviderContext);

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const items = itemBankManager.itemBankItems;

  const handleChange = async (e) => {
    const id = e.target.value;
    const { checked } = e.target;
    if (!checked && itemBankManager.existingBucketItemIdArray.includes(id)) {
      if (itemBankManager.isItemInLesson(id)) {
        handleDoNotRemove();
      } else {
        await itemBankService.removeResourceFromLesson(id);
      }
    }

    itemBankManager.changeSelectedBankItemArray(id, checked);
  };

  const handleDoNotRemove = async (event) => {
    const dialogObject = {
      title: 'Cannot Remove',
      text: 'This item has already been added to the lesson. If you wish to remove it, please do so in the lesson editor first.',
      showCancelButton: false,
      confirmButtonText: 'OK'
    };
    confirmDialog(dialogObject);
  };

  const handleForward = () => {
    if (itemBankManager.activePage < itemBankManager.totalPages) {
      itemBankManager.setActivePage(itemBankManager.activePage + 1);
      itemBankService.searchContentItemsForItemBank();
    }
  };

  const handleBack = () => {
    if (itemBankManager.activePage > 1) {
      itemBankManager.setActivePage(itemBankManager.activePage - 1);
      itemBankService.searchContentItemsForItemBank();
    }
  };

  return (
    <>
      <BasicPaginator handleBack={handleBack} handleForward={handleForward} label={itemBankManager.resultsLabel} showMe={(items && items.length > 0)} />
      <div className='resource-filter-items'>
        {(items && items.length > 0) ?
          items.map((item) => {
            const inSelected = itemBankManager.selectedBankItemArray.includes(item.id);
            const inExisting = itemBankManager.existingBucketItemIdArray.includes(item.id);
            const isSelected = (inSelected || inExisting);
            return (
              <ItemBankCard key={`${item.id}_ibcard`} customChangeHandler={handleChange} isDisabled={false} isSelected={isSelected}
                isShopping={true} libraryCardParams={item} skipRemove={true} />
            );
          }) : (itemBankManager.contentItemsLoading) ? <Loader /> : <div className='zero-items'>No Items Found</div>}
      </div>
    </>
  );
});

export default ResourceFilterItemList;
