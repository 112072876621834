import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

const DndKitDraggable = observer((props = {}) => {
  const {
    // style,
    activeStyle,
    className,
    draggableId,
    draggableInnerId,
    draggableValue,
    droppableId,
    index,
    isDragDisabled,
    title
  } = props;

  // eslint-disable-next-line no-empty-pattern
  const {
    /* managers */
  } = useContext(MobXProviderContext);

  const {
    // isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useDraggable({
    data: {
      activeStyle,
      draggableInnerId,
      draggableValue,
      draggableId,
      droppableId,
      index
    },
    disabled: isDragDisabled,
    id: draggableId
  });

  return (
    <div
      ref={setNodeRef}
      className={classNames(className, 'dnd-kit-draggable')}
      data-draggable-id={draggableId}
      data-draggable-index={index}
      style={{ transfrom: CSS.Translate.toString(transform) }}
      title={title}
      {...listeners}
      {...attributes}>
      {props.children}
    </div>
  );
});

export default DndKitDraggable;
