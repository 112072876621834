import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../css/AssesmentBuilder.scss';
import itemBankManager from '../assessmentBuilder/managers/ItemBankManager';

import lessonManager from '../lessonPlayer/managers/LessonManager';

import Auth from '../lessonPlayer/services/AuthService';
import itemBankService from '../assessmentBuilder/services/ItemBankService';

import PublisherPreview from '../lessonPlayer/components/PublisherPreview';
import lessonService from '../lessonPlayer/services/LessonService';
import UtilsService from '../lessonPlayer/services/UtilsService';

const PublisherViewer = observer(() => {
  const {
    userManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const [contentItemId, setContentItemId] = useState('');
  const [courseContentItemId, setCourseContentItemId] = useState('');

  useEffect(() => {
    async function init() {
      if (window.location.hash) {
        const authKey = window.location.hash.substring(1);
        const user = await Auth.logInWithAuthKey(authKey);
        await Auth.setAuthKey(authKey);
        userManager.setUser(user);

        const urlParams = new URLSearchParams(window.location.search);
        const contentItemId = urlParams.get('contentItemId');
        const courseContentItemId = urlParams.get('courseId');
        const optionsProfileId = urlParams.get('optionsProfileId');

        if (!UtilsService.isNullOrEmpty(contentItemId)) {
          setContentItemId(contentItemId);
          setCourseContentItemId(courseContentItemId);

          await lessonService.fetchLesson(contentItemId);

          if (!UtilsService.isNullOrEmpty(optionsProfileId)) {
            itemBankManager.setLessonProfileId(optionsProfileId);
          } else if (!UtilsService.isNullOrEmpty(lessonManager.lesson.optionsProfileId)) {
            itemBankManager.setLessonProfileId(lessonManager.lesson.optionsProfileId);
          } else {
            // leave it empty
          }
          await itemBankService.getProfilesForPublisher();
        }
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PublisherPreview contentItemId={contentItemId} courseContentItemId={courseContentItemId}/>
    </>
  );
});
export default PublisherViewer;
