import { fabric } from 'fabric';
import CanvasOrganizerService from './CanvasOrganizerService';

export default class CauseAndEffectService {
  canvasOrganizer;

  model;
  canvas;

  canvasHeight;
  canvasWidth;

  BOX_WIDTH = 120;
  BOX_HEIGHT = 80;
  BOX_TOP_MARGIN = 40;

  constructor(model) {
    this.model = model;
    this.canvasOrganizer = new CanvasOrganizerService(model);
  }

  initCanvas = () => {
    const maxBoxCountPerSection = Math.max(this.model.leftBoxCount, this.model.rightBoxCount);

    switch (maxBoxCountPerSection) {
    case 1:
      this.canvasHeight = 120;
      break;
    case 2:
      this.canvasHeight = 240;
      break;
    case 3:
      this.canvasHeight = 360;
      break;
    case 4:
      this.canvasHeight = 480;
      break;
    default:
      this.canvasHeight = this.canvasOrganizer.CANVAS_HEIGHT;
    }

    this.canvasWidth = this.canvasOrganizer.CANVAS_WIDTH;

    const settings = {
      height: this.canvasHeight,
      width: this.canvasOrganizer.CANVAS_WIDTH
    };
    this.canvas = new fabric.Canvas(this.model.id, settings);
    this.canvasOrganizer.setCanvas(this.canvas);

    this.canvasOrganizer.createArrowClass();
  }

  render = () => {
    if (this.canvas) {
      this.showBoxes();
    }
  }

  getHeight = () => {
    return this.CANVAS_HEIGHT;
  }

  showBoxes = () => {
    this.canvasOrganizer.clearCanvas();

    const strokeColor = 'transparent';

    // draw center boxes
    const centralBoxLeft = (this.canvasWidth - this.BOX_WIDTH) / 2;
    const centralBoxTop = (this.canvasHeight - this.BOX_HEIGHT) / 2;
    const centralLineStroke = this.model.centerText?.trim?.().length ? this.canvasOrganizer.LINE_STROKE_MED : this.canvasOrganizer.LINE_STROKE;
    const centralBox = this.canvasOrganizer.drawRectangle(
      centralBoxLeft, centralBoxTop, this.BOX_WIDTH, this.BOX_HEIGHT, centralLineStroke, strokeColor
    );

    const boxHorizontalMargin = 20;
    const rightBoxLeft = this.canvasWidth - this.BOX_WIDTH - boxHorizontalMargin;
    const boxTopMargin = this.BOX_TOP_MARGIN;

    const bodyHeight = this.canvasHeight;
    const totalLeftBoxHeight = this.model.leftBoxCount * (this.BOX_HEIGHT + boxTopMargin);
    const totalRightBoxHeight = this.model.rightBoxCount * (this.BOX_HEIGHT + boxTopMargin);

    // draw left boxes
    let leftBoxTop = (bodyHeight - totalLeftBoxHeight + boxTopMargin) / 2;
    for (let i = 0; (i < this.model.leftBoxCount || 0); i++) {
      const lineStroke = this.model.leftText[i] ? this.canvasOrganizer.LINE_STROKE_MED : this.canvasOrganizer.LINE_STROKE;
      const box = this.canvasOrganizer.drawRectangle(
        boxHorizontalMargin, leftBoxTop, this.BOX_WIDTH, this.BOX_HEIGHT, lineStroke, strokeColor
      );
      const offset = (i - (this.model.leftBoxCount - 1) / 2) * this.BOX_HEIGHT / 9;
      this.canvasOrganizer.drawOffsetArrowBetweenRectangles(box, centralBox, offset);

      leftBoxTop = leftBoxTop + this.BOX_HEIGHT + boxTopMargin;
    }

    // draw right boxes
    let rightBoxTop = (bodyHeight - totalRightBoxHeight + boxTopMargin) / 2;
    for (let i = 0; (i < this.model.rightBoxCount || 0); i++) {
      const lineStroke = this.model.rightText[i] ? this.canvasOrganizer.LINE_STROKE_MED : this.canvasOrganizer.LINE_STROKE;
      const box = this.canvasOrganizer.drawRectangle(
        rightBoxLeft, rightBoxTop, this.BOX_WIDTH, this.BOX_HEIGHT, lineStroke, strokeColor
      );
      const offset = 0;
      this.canvasOrganizer.drawOffsetArrowBetweenRectangles(centralBox, box, offset);

      rightBoxTop = rightBoxTop + this.BOX_HEIGHT + boxTopMargin;
    }
  }
}
