import parse from 'html-react-parser';
import { renderToStaticMarkup } from 'react-dom/server';
import clozeMathService from '../services/ClozeMathService';
import htmlService from '../services/HtmlService';
import promptService from '../services/PromptService';
import questionService from '../services/QuestionService';

import { ClozeBehavior } from './ClozeBehavior';

import utilsService from '../services/UtilsService';

export class ClozeComboBehavior extends ClozeBehavior {
  setResponseData = (data, _responseAnswer, model, { dataId, lessonElementState, isCustomBlankSection } = {}) => {
    const prompt = promptService.getCurrentPrompt({
      dataId,
      lessonElementState,
      model,
      promptType: 'anyModelPrompt'
    });

    if (prompt && prompt.rangeType === 'cloze') {
      if (!lessonElementState.currentResponseAnswer.userInputMap) {
        questionService.updateUserInputMap({
          model,
          lessonElementState
        });
      } else if (htmlService.isHtmlResponseFormat(model) || isCustomBlankSection) {
        lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
          dataId,
          text: data
        });
      } else {
        // `responseFormat` is 'text' or 'numeric' and using the native input element
        if (model.responseFormat === 'numeric') {
          if (utilsService.ensureNumericInput(data.target.value)) {
            lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
              dataId,
              text: data?.target?.value
            });
          }
        } else {
          lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
            dataId,
            text: data?.target?.value
          });
        }
      }
    } else {
      if (!lessonElementState.currentResponseAnswer.userInputMap) {
        const userInputMap = questionService.updateUserInputMap({
          model,
          lessonElementState
        });
        userInputMap.set(dataId, {
          ...data.originalData,
          text: data.label
        });
      } else {
        lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
          ...data.originalData,
          text: data.label
        });
      }
    }
  }

  setCorrectAnswer = (state, model) => {
    const userInputMap = new Map();
    for (const modelValidationPrompt of (model?.validation?.prompts || [])) {
      if (modelValidationPrompt && modelValidationPrompt.rangeType === 'clozeMultichoice') {
        const correctAnswer = modelValidationPrompt.answers.find((answer) => answer.isCorrect);
        userInputMap.set(modelValidationPrompt.id, {
          dataId: modelValidationPrompt.id,
          text: correctAnswer ? correctAnswer.text : ''
        });
      } else if (modelValidationPrompt && modelValidationPrompt.rangeType === 'cloze') {
        let answerText;
        if (promptService.isUserInputAlwaysCorrectIfFoundInModelPromptAnswers(model, modelValidationPrompt)) {
          answerText = modelValidationPrompt?.answers?.[0]?.text || '';
        } else {
          answerText = modelValidationPrompt?.answers?.find((answer) => answer.isCorrect)?.text || '';
        }

        if (answerText && ((model && !utilsService.isNullOrEmpty(model.mathText)) || model?.responseFormat === 'math')) {
          answerText = renderToStaticMarkup(parse(answerText, {
            replace: (domNode) => clozeMathService.appendDataIdToMathDomNodeThenConvertToMencloseIfApplicable(domNode, modelValidationPrompt.id, model)
          }));
        }
        const dataId = modelValidationPrompt?.id;
        userInputMap.set(dataId, {
          dataId,
          text: answerText || (model.mathText && renderToStaticMarkup(
            clozeMathService.renderClozeMathBlankPlaceholder(dataId, model, '?')
          )) || '',
          isCorrect: true
        });
      }
    }

    state.setCurrentResponse(utilsService.safeMobxClone({
      userInputMap, lessonElementId: model.lessonElementId
    }));
  }

  resetStudentAnswer = (state, _validation) => {
    state.setCurrentResponse(utilsService.safeMobxClone(state.cachedResponseAnswer));
  }
}

export default new ClozeComboBehavior();
