import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { register } from '../../../i18n';
import RichTextEditor, { BASIC_CKEDITOR_TOOLBAR } from '../RichTextEditor';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import bookDrawService from '../../services/BookDrawService';
import HtmlComponent from '../HtmlComponent';
import { confirmDialog } from '../dialogs';

const t = register('GlobalQuestionLabels');

const StampNoteEditor = observer(({ discussion, isChild = false }) => {
  const {
    // bookDrawManager,
    userManager
  } = useContext(MobXProviderContext);

  const [enteredText, setEnteredText] = useState(discussion.comment);
  const [replyOpen, setReplyOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  // const stamp = bookDrawManager.getStamp(bookDrawManager.selectedStamp.pageId, bookDrawManager.selectedStamp.stampId);

  const saveNoteRef = useRef();
  const deleteNoteRef = useRef();
  const replyNoteRef = useRef();
  const resolveNoteRef = useRef();
  const cancelNoteRef = useRef();
  const editNoteRef = useRef();
  const unResolveNoteRef = useRef();

  useAccessibilityClick(saveNoteRef, async (e) => {
    if (editOpen) {
      await bookDrawService.createOrUpdateDiscussion(enteredText, discussion.id, null);
    } else if (replyOpen) {
      await bookDrawService.createOrUpdateDiscussion(enteredText, null, discussion.id);
      setEnteredText(discussion.comment);
      await bookDrawService.fetchDiscussions();
    }
    setEditOpen(false);
    setReplyOpen(false);
  });

  useAccessibilityClick(replyNoteRef, async (e) => {
    if (!replyOpen) {
      setEnteredText('');
    }
    setReplyOpen(!replyOpen);
  });

  useAccessibilityClick(editNoteRef, async (e) => {
    if (!editOpen) {
      setEnteredText(discussion.comment);
    }
    setEditOpen(!editOpen);
  });

  useAccessibilityClick(resolveNoteRef, async (e) => {
    await bookDrawService.resolveUserDiscussion(discussion.id, true);
    await bookDrawService.fetchDiscussions();
  });

  useAccessibilityClick(unResolveNoteRef, async (e) => {
    await bookDrawService.resolveUserDiscussion(discussion.id, false);
    await bookDrawService.fetchDiscussions();
  });

  useAccessibilityClick(cancelNoteRef, async (e) => {
    setEnteredText(discussion.comment);
    setEditOpen(false);
    setReplyOpen(false);
  });

  useAccessibilityClick(deleteNoteRef, async (e) => {
    const result = await confirmDialog({
      title: `${UtilsService.deleteCheck()}`,
      text: 'This action cannot be undone.'
    });
    if (result) {
      await bookDrawService.deleteDiscussionNote(discussion.id);
      bookDrawService.clearNotes();
      await bookDrawService.fetchDiscussions();
    }
  });

  const readOnly = (discussion.commentedBy !== userManager.userId);
  return (
    <div className='discussion-wrapper'>
      <div className='existing-note' id={`${discussion.id}`} tabIndex={0}>
        <div className='note-user'>{discussion.displayName}</div>
        <div className='note-date'>{discussion.creationDateTime}</div>
        <div className='test-item-answers'>
          {((replyOpen || editOpen) && !discussion.resolved) ? (
            <div className='cke_topspace'>
              <RichTextEditor data={enteredText}
                onChange={(data) => setEnteredText(data)}
                readOnly={readOnly && !replyOpen} toolbar={BASIC_CKEDITOR_TOOLBAR} />
            </div>
          ) : <HtmlComponent htmlStr={enteredText} />}
        </div>
        {(discussion.resolved) ? (
          <div className='footer-buttons resolved'>
            <div className='note-date'>{t('resolved')}: {discussion.resolvedDate}</div>
            <button ref={unResolveNoteRef} className='open-button'>{t('reOpen')}</button>
          </div>
        )
          : (replyOpen || editOpen) ? (
            <div className='footer-buttons'>
              <button ref={saveNoteRef} className='submit-response primary'>{t('save')}</button>
              <button ref={cancelNoteRef} className='submit-response primary'>{t('cancel')}</button>
            </div>
          ) : (
            <div className='footer-buttons'>
              <button ref={replyNoteRef} className='submit-response primary'>{t('reply')}</button>
              {!isChild && <button ref={resolveNoteRef} className='submit-response primary'>{t('resolve')}</button>}
              {!readOnly && <button ref={editNoteRef} className='submit-response primary'>{t('edit')}</button>}
              {!readOnly && <button ref={deleteNoteRef} className='submit-response primary'>{t('delete')}</button>}
            </div>
          )}
      </div>
      <div className='discussion-child'>
        {
          (discussion.userDiscussions && discussion.userDiscussions.length > 0 && !discussion.resolved) &&
        discussion.userDiscussions.map((childDiscussion) => {
          return (<StampNoteEditor key={`${childDiscussion.id}-note`} discussion={childDiscussion} isChild={true} />);
        })
        }
      </div>
    </div>
  );
});

export default StampNoteEditor;
