import { useEffect, useState } from 'react';

/**
 * Hook to auto-detect & refresh state upon change in viewport (window) size.
 *
 * Usage: `const { width, height } = useViewportSize();`
 */
export const useViewportSize = () => {
  const [size, setSize] = useState(() => getViewportSize());

  useEffect(() => {
    // Use visualViewport api to track available height even on iOS virtual keyboard opening
    const onResize = () => {
      setSize((size) => {
        const newSize = getViewportSize();
        if (newSize.width === size.width && newSize.height === size.height) {
          return size;
        }
        return newSize;
      });
    };

    if (!visualViewport) {
      window.addEventListener('resize', onResize);
    } else {
      visualViewport.addEventListener('resize', onResize);
    }

    return () => {
      if (!visualViewport) {
        window.removeEventListener('resize', onResize);
      } else {
        visualViewport.removeEventListener('resize', onResize);
      }
    };
  }, []);

  return size;
};

export const getViewportSize = () => {
  return {
    width: visualViewport?.width || window.innerWidth,
    height: visualViewport?.height || window.innerHeight
  };
};
