import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import toolbarService from '../../services/ToolbarService';
import userWirisParser from '../../../hooks/useWirisParser';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import UtilsService from '../../services/UtilsService';
import usageFlag from '../../../images/summaryIcons/usageFlag.svg';
import speedFlag from '../../../images/summaryIcons/speedFlag.svg';
import { register } from '../../../i18n';

const t = register('SummaryInfo');
const t2 = register('PanelMenu');

const EngagementInfo = observer(() => {
  const {
    responseManager
  } = useContext(MobXProviderContext);

  const containerRef = useRef();
  const exitButton = useRef();
  userWirisParser(containerRef);

  useAccessibilityClick(exitButton, async () => {
    await toolbarService.exitToolHandler();
  });

  let totaltime = '';

  if (responseManager.studentReviewEngagementReport) {
    totaltime = UtilsService.secondsToMMSS(responseManager.studentReviewEngagementReport.timeOnTaskSummary);
  }
  const speed = (responseManager.studentReviewEngagementReport && responseManager.studentReviewEngagementReport.engagement);
  const usage = (responseManager.studentReviewEngagementReport && responseManager.studentReviewEngagementReport.supportToolUse);

  return (
    <div ref={containerRef} className='summary-info engagement'>
      <h1>{t2('engagementPanel')}</h1>
      <div className='summary-tally-list'>
        <div className='summary-tally'><strong>{totaltime}</strong><span>&nbsp;{t('totalTime', 'Total Time')}</span> </div>
        <table>
          <tbody>
            {(speed) && (
              <tr className='tally-row'>
                <td className='icon'><img alt='' src={speedFlag} /></td>
                <td className='no-icon' />
                <td className='label'>{t('speedWarning', 'Speed Warning')}</td>
              </tr>
            )}
            {(usage === 'disengaged') && (
              <tr className='tally-row'>
                <td className='icon'><img alt='' src={usageFlag} /></td>
                <td className='no-icon' />
                <td className='label'><span>{t('markedForReview', 'Marked for Review')}</span></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='summary-button-wrapper'>
        <button ref={exitButton} className='submit-activity-button primary ' tabIndex='0'>{t('exit', 'Exit')}</button>
      </div>
    </div>
  );
});

export default EngagementInfo;
