import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactModal from 'react-modal-resizable-draggable';

import '../../css/TranscriptDialog.scss';
import { register } from '../../i18n';

import audioService from '../services/AudioService';
import videoService from '../services/VideoService';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';

import HtmlComponent from './HtmlComponent';

const t = register('AriaLabels');
const t2 = register('Upload');

const TranscriptDialog = observer((props = {}) => {
  const { model } = props;

  const { audioManager, videoManager } = useContext(MobXProviderContext);

  const [transcriptText, setTranscriptText] = useState(`${t2('loadingFileBox')}`);

  const headerCloseButtonRef = useRef();

  const isVideo = model?.entityTypeId === 'video_resource';
  const isAudio = model?.entityTypeId === 'audio_resource';

  useAccessibilityClick(headerCloseButtonRef, (_event) => {
    props.setIsOpen?.(false);
  });

  useEffect(() => {
    (async () => {
      if (isVideo) {
        const transcriptInfo = videoManager.selectedVideoTranscriptOption;
        if (transcriptInfo) {
          const transcriptText = await videoService.fetchVideoTranscriptText(transcriptInfo);
          setTranscriptText(transcriptText || '');
        }
      } else if (isAudio) {
        const transcriptInfo = audioManager.selectedAudioTranscriptOption;
        if (transcriptInfo) {
          const transcriptText = await audioService.fetchAudioTranscriptText(transcriptInfo);
          setTranscriptText(transcriptText || '');
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='TranscriptDialog theme-container'>
      <ReactModal
        initHeight={500}
        initWidth={500}
        isOpen={props.isOpen}
        onRequestClose={() => {
          props.setIsOpen?.(false);
        }}>
        <div className='transcript-dialog-inner'>
          <div className='transcript-header'>
            <div className='transcript-header-title'>
              {t('transcript')}
              {/* TODO uncomment if we want the selected transcript language in the Transcript header title */}
              {/* {`Transcript ${!!transcriptInfo?.label && `(${transcriptInfo.label})`}`} */}
            </div>
            <div ref={headerCloseButtonRef} className='x-close-button mask-image' />
          </div>
          <div className='scroll-area'>
            <HtmlComponent htmlStr={transcriptText} />
          </div>
        </div>
      </ReactModal>
    </div>
  );
});

export default TranscriptDialog;
