import { toJS } from 'mobx';

import { set } from 'lodash';

import { LessonMode, QuestionFeedbackState } from '../../Constants';

import questionService from '../services/QuestionService';
import UtilsService from '../services/UtilsService';

import { Behavior } from './Behavior';

export class RelationshipsBehavior extends Behavior {
  checkForValidResponse = (responseAnswer, _model) => {
    const nestedPrompts = toJS(responseAnswer.prompts || []);
    const flatPrompts = UtilsService.flattenChildren(nestedPrompts);

    const hasSomeEmptyResponses = flatPrompts?.some?.((prompt) => {
      return !prompt?.content && !prompt?.sample;
    });
    return !hasSomeEmptyResponses;
  }

  isAutoScored = (_model) => {
    return false;
  }

  getScore = (_responseAnswer, _model) => {
    // this content type is not autoscored

  }

  setResponseData = ({ index, pathId, prompt, text }, responseAnswer, _model) => {
    if (index >= 0) {
      const responseAnswerPrompts = toJS(responseAnswer.prompts || {});

      const updatedPrompt = {
        ...prompt,
        content: text
      };

      set(responseAnswerPrompts, pathId, updatedPrompt);

      responseAnswer.prompts = responseAnswerPrompts;
    }
  }

  getAnswerFeedback = (_id, _responseAnswer) => {
    return true;
  }

  getInitializedResponse = (lessonElementId, model, _activityId) => {
    const prompts = toJS(model.tree || []);

    const responseAnswer = {
      ...questionService.generateInitializedQuestionResponse({
        isScored: false,
        isScoredCorrect: false,
        lessonElementId,
        prompts
      })
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (_model) => {
    return '';
  }

  // eslint-disable-next-line no-empty-pattern
  getQuestionFeedbackText = (_model, _correctFeedback, _incorrectFeedback, _correctFeedbackBody, _incorrectFeedbackBody, mode, _questionIsCorrect, currentAttemptCount, { /* lessonElementState */ }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    const textHeader = '';

    // TODO unused; this content type is not autoscored so we do not have this feedback
    // if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
    //   textHeader = correctFeedback;
    //   if (correctFeedbackBody) {
    //     textBody = correctFeedbackBody + ' ' + textBody;
    //   }
    // } else if (questionIsCorrect ===  QuestionFeedbackState.PARTIAL_CORRECT) {
    //   textHeader = `${UtilsService.partiallyCorrect()}`;
    //   let custom = '';
    //   if (incorrectFeedbackBody) {
    //     custom = incorrectFeedbackBody + ' ';
    //   }

    //   textBody = UtilsService.partiallyCorrectPrompt() + ' <br/>' + custom + textBody;

    // } else {
    //   textHeader = incorrectFeedback;
    //   if (incorrectFeedbackBody) {
    //     textBody = incorrectFeedbackBody + ' ' + textBody;
    //   }
    // }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (_answerId, _lessonElementState, _model) => {
    return false;
  }

  isQuestionCorrect = (lessonElementState, _lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }
    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }
    return QuestionFeedbackState.INCORRECT;
  }
}

export default new RelationshipsBehavior();
