import React, { useContext } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { PDFObject } from 'react-pdfobject';
import { MobXProviderContext, observer } from 'mobx-react';
import { register } from '../../../i18n';
import HtmlComponent from '../HtmlComponent';
import '../../../css/Tools.scss';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';

const t2 = register('AriaLabels');

const Help = observer(() => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const {
    resourceType,
    helpData,
    helpDisplayName,
    helpOpenId
  } = toolbarManager;

  function closeHelp() {
    toolbarManager.setIsHelpOpen(false);
    toolbarManager.toggleToolOff(helpOpenId);
  }

  let internals = null;
  if (resourceType === 'pdf_resource') {
    internals = <PDFObject url={helpData} />;
  } else if (resourceType === 'article_resource') {
    internals = <div className='article-container' data-allow='1'><HtmlComponent htmlStr={helpData} /></div>;
  }

  useReactModalResizeableDraggable('helpTool');

  return (
    <div className='helpTool'>
      <ReactModal
        initHeight={400}
        initWidth={800}
        isOpen={true}>
        <div className='modalBody'>
          <div className='tool-header'>
            <div className='title-label basic'>{helpDisplayName}</div>
            <div aria-label={t2('closedialog')} className='x-close-button' id='modal-close' onClick={closeHelp}
              role='button' tabIndex='0' />
          </div>
          {internals}
        </div>
      </ReactModal>
    </div>
  );
});

export default Help;
