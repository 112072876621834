import classNames from 'classnames';

import { ContentType } from '../../Constants';

import videoManager from '../managers/VideoManager';

import playerService from './PlayerService';

export default class VideoService {
  static getCustomVideoLayoutLabel = ({
    model,
    videoLabelPrefix = ''
  } = {}) => {
    if (this.isDividedLayoutVideoQuestion(model)) {
      videoLabelPrefix = videoLabelPrefix ? `${videoLabelPrefix}-divided-layout-child-` : 'divided-layout-child-';
      return `${videoLabelPrefix}${model.dividedLayoutChildWidth || 'large'}`;
    } else {
      return '';
    }
  }

  static isDividedLayoutVideoQuestion = (model) => {
    return model?.type === ContentType.VIDEO_QUESTION.type && model?.layout === 'divided';
  }

  static getVideoActivityParentClassNamesIfApplicable = ({
    isScoreModeVideoActivityParent,
    labelPrefix = 'flyout-wrapper'
  } = {}) => {
    if (isScoreModeVideoActivityParent) {
      return classNames(`${labelPrefix}-video-activity-parent`, {
        [`${labelPrefix}-video-activity-parent-scoring`]: playerService.isScoringMode()
      });
    }
  }

  static fetchVideoTranscriptText = async (transcriptInfo) => {
    transcriptInfo = transcriptInfo || videoManager.selectedVideoTranscriptOption;
    return fetch(transcriptInfo.remoteUrl, {
      headers: {
        'Content-Type': 'text/html'
      },
      method: 'GET',
      redirect: 'follow'
    }).then((response) => {
      return response.text();
    });
  }
}
