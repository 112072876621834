import styleService from './StyleService';

export default class WaveSurferService {
  static getDefaultWaveSurferOptions = ({
    audioRate = 1,
    autoCenter = true,
    autoScroll = true,
    autoplay = false,
    barAlign = '',
    barGap = 3,
    barHeight = NaN,
    barRadius = 2,
    barWidth = 4,
    container = 'body',
    cursorColor = styleService.getStyleVar('--theme-wavesurfer-waveform-cursor-color'),
    cursorWidth = 2,
    dragToSeek = false,
    fillParent = true,
    height = 100,
    hideScrollbar = false,
    interact = true,
    mediaControls = false,
    minPxPerSec = 1,
    normalize = false,
    progressColor = styleService.getStyleVar('--theme-wavesurfer-waveform-progress-color'),
    sampleRate,
    splitChannels = false,
    url = '',
    waveColor = styleService.getStyleVar('--theme-wavesurfer-waveform-wave-color')
  } = {}) => {
    const defaultWaveSurferOptions = {
      /** Audio rate */
      audioRate,
      /** If autoScroll is enabled, keep the cursor in the center of the waveform during playback */
      autoCenter,
      /** Automatically scroll the container to keep the current position in viewport */
      autoScroll,
      /** Play the audio on load */
      autoplay,
      /** Vertical bar alignment * */
      barAlign,
      /** Spacing between bars in pixels */
      barGap,
      /** A vertical scaling factor for the waveform */
      barHeight,
      /** Rounded borders for bars */
      barRadius,
      /** Render the waveform with bars like this: ▁ ▂ ▇ ▃ ▅ ▂ */
      barWidth,
      /** HTML element or CSS selector (required) */
      container,
      /** The color of the playpack cursor */
      cursorColor,
      /** The cursor width */
      cursorWidth,
      /** Allow to drag the cursor to seek to a new position */
      dragToSeek,
      /** Stretch the waveform to fill the container, true by default */
      fillParent,
      /** The height of the waveform in pixels */
      height,
      /** Hide the scrollbar */
      hideScrollbar,
      /** Pass false to disable clicks on the waveform */
      interact,
      /** Whether to show HTML5 audio element controls */
      mediaControls,
      /** Minimum pixels per second of audio (i.e. zoom level) */
      minPxPerSec,
      /** Stretch the waveform to the full height */
      normalize,
      /** The color of the progress mask */
      progressColor,
      /** Decoding sample rate. Doesn't affect the playback. Defaults to 8000 */
      sampleRate,
      /** Render each audio channel as a separate waveform */
      splitChannels,
      /** Audio URL */
      url,
      /** The color of the waveform */
      waveColor
    };
    return defaultWaveSurferOptions;
  }
}
