import React, { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import parse from 'html-react-parser';

import {
  DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';

import { snapCenterToCursor } from '@dnd-kit/modifiers';

import '../../../css/Cloze.scss';

import { ContentType, LessonMode } from '../../../Constants';

import dragDropService from '../../services/DragDropService';

import playerService from '../../services/PlayerService';
import promptService from '../../services/PromptService';
import questionService from '../../services/QuestionService';
import styleService from '../../services/StyleService';
import textQuestionUtilsService from '../../services/TextQuestionUtilsService';
import utilsService from '../../services/UtilsService';
import toolbarService from '../../services/ToolbarService';

import useForceUpdate from '../../../hooks/useForceUpdate';
import useWirisParser from '../../../hooks/useWirisParser';

import Guideline from '../tools/Guideline';

import DndKitDroppable from '../DndKitDroppable';
import DndKitDraggable from '../DndKitDraggable';
import DndKitOverlay from '../DndKitOverlay';

import FeedbackIcon from '../FeedbackIcon';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import useStyleEvents from '../../../hooks/useStyleEvents';
import responseService from '../../services/ResponseService';
import navMenuService from '../../services/NavMenuService';
import PrintQuestionNumber from './PrintQuestionNumber';

const ClozeDragDrop = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const wirisRef = useRef();
  useWirisParser(wirisRef);

  const forceUpdate = useForceUpdate();

  const [loadingDragDrop, setLoadingDragDrop] = useState(true);

  const [allowRenderDragDropAnswerBankSection, setAllowRenderDragDropAnswerBankSection] = useState(false);

  const [responseItemAnswerBankOptions, setResponseItemAnswerBankOptions] = useState([]);

  const [dragStartDndKitEvent, setDragStartDndKitEvent] = useState(null);

  const [dragEndResult, setDragEndResult] = useState(null);

  const { getStyleVar } = styleService;

  const { stripTagsAndEntities, stripWrappingParagraphTags } = utilsService;

  const {
    lessonElementState,
    model,
    userInputMap,
  } = questionService.initQuestionComponent({
    lessonElementId,
    questionClassName: 'cloze-question'
  });

  const sensors = useSensors(
    useSensor(KeyboardSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        tolerance: 5
      }
    })
  );

  const initResponseItemAnswerBankOptions = () => {
    dragDropService.initResponseItemAnswerBankOptions({
      model,
      setResponseItemAnswerBankOptions
    });
  };

  useStyleEvents(lessonElementId);

  /** equivalent to componentDidMount(), i.e. only called on initial load */
  useEffect(() => {
    dragDropService.handleDragDropInitUseEffect({
      initResponseItemAnswerBankOptions,
      setLoadingDragDrop
    });

    setTimeout(() => {
      setAllowRenderDragDropAnswerBankSection(true);
    }, 250);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {import('@dnd-kit/core').DragEndEvent} dndKitEvent
   */
  const onDragEnd = (dndKitEvent) => {
    dragDropService.handleDragEnd({
      dndKitEvent,
      forceUpdate,
      lessonElementState,
      lessonElementId,
      model,
      result: undefined,
      setDragEndResult
    });
  };

  /** equivalent to componentWillUnmount(), i.e. called whenever component is unmounting */
  useEffect(() => () => {
    return dragDropService.handleDragDropUnmountUseEffect(ContentType.CLOZE_DRAG_DROP);
  });

  const getAbsoluteDragDropResponseItemStyles = (responseItem) => {
    return dragDropService.getAbsoluteDragDropResponseItemStyles({
      model,
      responseItem
    });
  };

  const navToCurrentLessonElement = async () => {
    if (lessonManager.currentLessonElementId !== lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
    }
  };

  const renderClozeDragDrop = (_props = null) => {
    const isCustomAnswerBankStyling = model?.answerLayoutType === 'manual';
    const answerBankPositionClassName = `answer-bank-position-${ model?.answerBankPosition || 'bottom'}`;
    const clozeQuestionInnerClassNames = classNames('cloze-question-inner', answerBankPositionClassName);
    const questionTitle = <HtmlComponent htmlStr={model.questionText || ''} />;

    let answerBankBackgroundUrl = '';
    if (model.customAnswerLayoutBackground && model.customAnswerLayoutBackgroundHtml) {
      parse(model.customAnswerLayoutBackgroundHtml, {
        replace: (domNode) => {
          if (domNode.attribs?.src) {
            answerBankBackgroundUrl = domNode.attribs.src;
          }
        }
      });
    }
    return (
      <div ref={wirisRef} className='cloze-question'>
        <div className='cloze-question-section-title'>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='test-item-question'>
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
            {questionTitle}
          </div>
          <OptionalImage model={model} runtime={true} />
        </div>
        <DndContext
          modifiers={[snapCenterToCursor]}
          onDragEnd={(dndKitEvent) => {
            setDragStartDndKitEvent(null);
            onDragEnd(dndKitEvent);
          }}
          onDragStart={(dndKitEvent) => {
            navToCurrentLessonElement();
            setDragEndResult(null);
            setDragStartDndKitEvent(dndKitEvent);
          }}
          sensors={sensors}>
          <div className={clozeQuestionInnerClassNames}>
            <div className='cloze-question-section-body' onClick={toolbarService.handleContentResourceClick}>
              <div className='test-item-answers'>
                <div className='cloze-body cloze-drag-drop-body'>
                  <div className='cloze-body-nodes'>
                    {lessonElementState?.questionBehavior?.renderClozeBody({
                      lessonElementId, model, renderBlankSection
                    })}
                  </div>
                </div>
              </div>
            </div>

            {allowRenderDragDropAnswerBankSection && dragDropService.renderDragDropAnswerBankSection_dndKit({
              CONTENT_TYPE_OBJ: ContentType.CLOZE_DRAG_DROP,
              answerBankBackgroundUrl,
              dragEndResult,
              dragStartDndKitEvent,
              getAbsoluteDragDropResponseItemStyles,
              isCustomAnswerBankStyling,
              lessonElementId,
              lessonElementState,
              model,
              responseItemAnswerBankOptions
            })}
          </div>
          {createPortal(
            <DragOverlay className='overlay-cloze' dropAnimation={null} wrapperElement='span'>
              {dragStartDndKitEvent?.active?.data?.current?.draggableId ? (
                <DndKitOverlay
                  dragStartDndKitEvent={dragStartDndKitEvent}
                  model={model} />
              ) : null}
            </DragOverlay>, document.getElementById('the-body'))}

        </DndContext>
      </div>
    );
  };

  /** @param {import('html-react-parser').DOMNode} domNode */
  const renderBlankSection = (domNode, { lessonElementId } = {}) => {
    const dataId = domNode.attribs['data-id'];
    const uiState = questionFeedbackManager.getUiState?.(lessonElementId);
    const { noSubmit } = questionFeedbackManager;

    const showAnswerFeedback = (uiState?.showAnswerFeedback && !noSubmit) || false;

    let nodeClassNames = domNode.attribs['class'];
    nodeClassNames = classNames(nodeClassNames, {
      correct: false, // we do not want students seeing this className in the dev tools
      feedback: showAnswerFeedback,
      incorrect: false // we do not want students seeing this className in the dev tools
    }, `${model.responseFormat || ''}`);

    const styleStr = domNode.attribs['style'] || '';
    const styleObj = styleService.convertStrToStyleObj(styleStr);

    const responseAnswerPrompt = promptService.getCurrentPrompt({
      dataId,
      lessonElementState,
      model,
      promptType: 'responseAnswerPrompt',
    }) || {};

    const { id, text, ...responseAnswerPromptRest } = responseAnswerPrompt;

    userInputMap?.set(dataId, {
      dataId,
      text: responseAnswerPrompt?.text?.trim?.()/* || clozeText */,
      ...responseAnswerPromptRest
    });

    const currentUserInputValue = lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text || userInputMap?.get(dataId)?.text || '';
    const currentUserInputId = lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.id || userInputMap?.get(dataId)?.id || '';

    const responseItems = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || []);
    const currentResponseItemIndex = currentUserInputId ? responseItems?.findIndex((responseItem) => {
      return responseItem.id === currentUserInputId;
    }) || 0 : undefined;

    const { playerMode } = lessonManager;

    const clozeSectionInputWrapperClassNames = classNames('cloze-section-input-wrapper', nodeClassNames, playerMode, { 'list-number-wrapper': model.numberedBlanks });
    const isDragDisabled = playerService.isReadOnly(lessonElementId) || !currentUserInputId || false;

    return (
      <div key={dataId}
        className={classNames('cloze-blank-section cloze-section drag-drop-section', {
          // TODO remove // editorClassName
        }, nodeClassNames)}
        data-id={dataId}>
        <div className={clozeSectionInputWrapperClassNames}>
          {model.numberedBlanks && <div className='list-number' />}
          <DndKitDroppable droppableId={dataId}>
            <div className='cloze-blank-droppable'/* tabIndex='0' */>
              <div className={classNames('cloze-blank-droppable-inner', {
                'cloze-blank-droppable-inner-has-value': !!currentUserInputValue/* && !droppableSnapshot.draggingFromThisWith */
              })}
              data-prompt-id={dataId}
              style={{
                // note: order is important here as styleObj needs to potentially override default width & height
                width: getStyleVar('--theme-drag-drop-blank-section-default-width'),
                height: getStyleVar('--theme-drag-drop-blank-section-default-height'),
                ...styleObj,
                minWidth: 'fit-content',
                minHeight: 'fit-content'
              }}
              title={stripTagsAndEntities(currentUserInputValue)}>
                <DndKitDraggable key={dataId}
                  activeStyle={{
                    background: getStyleVar('--theme-drag-drop-option-bg-color'),
                    border: `1px solid ${getStyleVar('--theme-drag-drop-option-border-color')}`,
                    padding: '12px',
                    // zIndex: 999 // TODO // 999 is the default for dnd-kit draggables, but we can lower this here (if needed)
                  }}
                  draggableId={dataId}
                  draggableInnerId={currentUserInputId}
                  draggableValue={currentUserInputValue}
                  droppableId={dataId}
                  index={currentResponseItemIndex}
                  isDragDisabled={isDragDisabled}>
                  <div
                    className={classNames('cloze-drag-drop-prompt-option')}
                    style={{
                      minWidth: styleObj.width || getStyleVar('--theme-drag-drop-blank-section-default-width'),
                      minHeight: styleObj.height || getStyleVar('--theme-drag-drop-blank-section-default-height')
                    }}>
                    <div className={classNames('cloze-blank-droppable-inner-text',
                      `text-align-${model.answerAlignment || 'left'}`)}
                    data-answer-id={currentUserInputId || null}>
                      <HtmlComponent htmlStr={stripWrappingParagraphTags(currentUserInputValue)} useSpan />
                    </div>
                  </div>
                </DndKitDraggable>
              </div>
            </div>
          </DndKitDroppable>
        </div>
        <FeedbackIcon
          answerId={dataId}
          lessonElementId={lessonElementId}
          lessonElementState={lessonElementState}
          model={model}
          uiState={uiState}
          {...lessonElementState.currentResponseAnswer}
          {...textQuestionUtilsService.getFeedbackIconOptionsForDecoratingTextInputs()} />
      </div>
    );
  };
  return !loadingDragDrop && lessonElementId && model
    /* && typeof uiState?.submitButtonVisible === 'boolean' */ ? renderClozeDragDrop() : null;
});

export default ClozeDragDrop;
