import { useEffect, useState } from 'react';

export const useScrollBottomIndicator = (ref) => {
  const [canScrollBottom, setCanScrollBottom] = useState(false);

  const listener = () => {
    if (ref && ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      // parens around addition is important.
      if ((scrollTop + clientHeight) >= (scrollHeight - 2)) {
        setCanScrollBottom(false);
      } else {
        setCanScrollBottom(true);
      }
    }
  };

  useEffect(() => {
    if (!ref) {
      return;
    }
    const element = ref.current;
    if (element) {
      element.addEventListener('scroll', listener);
      element.addEventListener('DOMSubtreeModified', listener);
    }
    // detect and set initial.
    // setTimeout(()=>{
    //   listener();
    // }, 1000);

    return () => {
      if (element) {
        element.removeEventListener('scroll', listener);
        element.removeEventListener('DOMSubtreeModified', listener);
      }
    };
  });

  return canScrollBottom;
};
