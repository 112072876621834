import scoringManager from '../managers/ScoringManager';
import responseManager from '../managers/ResponseManager';
import lessonManager from '../managers/LessonManager';

import UtilsService from './UtilsService';
import Auth from './AuthService';
import { PartialScoringType } from '../../Constants';

import studentActivityManager from '../managers/StudentActivityManager';

export class ScoringService {
  fetchContentItemRubric = async (lessonElementId, contentItem, forResource) => {
    if (scoringManager.rubricMap.has(lessonElementId)) {
      return true;
    }
    let url = '';
    if (forResource) {
      url = `${Auth.ecms}/api/viewRubric?rubricId=${ contentItem.rubricEntityId }`;
    } else {
      url = `${Auth.ecms}/api/viewRubricForQuestion?contentItemId=${contentItem.id}`;
    }
    try {
      const response = await Auth.fetch(url, {
        method: 'GET'
      });

      if (response.status === 'SUCCESS') {
        const rubric = response;
        const state = responseManager.getLessonElementState(lessonElementId);
        if (rubric && rubric.categories && rubric.categories.length > 0) {
          scoringManager.setRubric(lessonElementId, rubric, state.isScored);
        }
        return true;
      } else {
        console.warn(response.status);
        console.warn(response.statusMessage);
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  setCategoryTempScoreFromTable = (lessonElementId, categoryId, str) => {
    scoringManager.setCategoryTempScoreFromTable(lessonElementId, categoryId, str);
  };

  setCategoryFullCreditFromTable = (lessonElementId) => {
    scoringManager.setCategoryFullCreditFromTable(lessonElementId);
  };

  rubricCellClick = (lessonElementId, clickIndex, categoryId) => {
    scoringManager.rubricCellClick(lessonElementId,
      clickIndex, categoryId);
  }

  initTempScores = () => {
    scoringManager.tempScores.clear();
    const testItems = lessonManager.elementsThatHaveState;
    testItems.forEach((model) => {
      if (model.isTestItem && !model.unscored) {
        const state = responseManager.getLessonElementState(model.lessonElementId);
        if (state && state.isScored) {
          scoringManager.setTempScore(model.lessonElementId, state.scoreValue);
        } else if (state && !state.isScored) {
          scoringManager.setTempScore(model.lessonElementId, '');
        }
      }
    });
  }

  setTempScore = (lessonElementId, score) => {
    scoringManager.setTempScore(lessonElementId, score);
  }

  updateTestItemScore = async (lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    const state = responseManager.getLessonElementState(lessonElementId);
    if (!model.isTestItem) {
      return;
    }

    const { activityId } = studentActivityManager;
    const { studentId } = studentActivityManager;
    let score = scoringManager.getTempScore(lessonElementId);
    if (score !== null && score?.length === 0) {
      score = null;
    }
    if (score === state.scoreValue) {
      return;
    }

    const subscore = scoringManager.getSubscore(lessonElementId);
    // Make sure we have an activityId
    if (!UtilsService.isNullOrEmpty(activityId)) {
      try {
        const postBody = {
          activityId,
          studentId,
          lessonElementId,
          subscore,
          score
        };

        const result = await Auth.fetch(`${Auth.ecms}/api/updateTestItemScore`, {
          method: 'POST',
          body: postBody,
        });
        if (result.status === 'SUCCESS') {
          responseManager.setScore(lessonElementId, model, score, subscore);
          return true;
        }
      } catch (e) {
        console.error(e);
        console.log('No activity id found.   updateTestItemScore function skipped.');
      }
    } else {
      lessonManager.setLoaded(0);
      console.log('No activity id found.   updateTestItemScore function skipped.');
    }
  }

  clearRubricSelections = (lessonElementId) => {
    scoringManager.clearRubricSelections(lessonElementId);
  };

  submitTeacherComment = async (lessonElementId) => {
    const state = responseManager.getLessonElementState(lessonElementId);
    // Make sure we have an activityId
    if (state) {
      try {
        const postBody = {
          entityId: state.responseId,
          entityTypeId: 'response',
          comment: state.comment
        };
        const result = await Auth.fetch(`${Auth.ecms}/api/addUpdateComment`, {
          method: 'POST',
          body: postBody,
        });
        if (result.status === 'SUCCESS') {
          state.setComment(result.comment);
          return true;
        }
      } catch (e) {
        console.error(e);
        console.log('No activity id found.   submitAssignmentComment function skipped.');
      }
    } else {
      console.log('No activity id found.   submitAssignmentComment function skipped.');
    }
  }

  getAdjustedScore = (model, tempScore = 0, promptCount, {
    scoringTypeOverride
  } = {}) => {
    let score = tempScore;

    if ((scoringTypeOverride || model.scoringType) === PartialScoringType.EXACT) {
      score = (tempScore === promptCount) ? model.maxScore : 0;
    } else if ((scoringTypeOverride || model.scoringType) === PartialScoringType.PARTIAL) {
      score = tempScore / promptCount * model.maxScore;
    } else if ((scoringTypeOverride || model.scoringType) === PartialScoringType.PARTIAL_MATCH_RESPONSE) {
      score = tempScore;
    }
    return score;
  }
}
export default new ScoringService();
