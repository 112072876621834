import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import QuestionFeedback from './QuestionFeedback';
import useWirisParser from '../../hooks/useWirisParser';
import lessonManager from '../managers/LessonManager';
import studentActivityManager from '../managers/StudentActivityManager';
import alignmentService from '../services/AlignmentService';
import StandardsFooter from './lessonItems/StandardsFooter';
import { useTextHighlight } from '../../hooks/useTextHighlight';
import { LessonMode } from '../../Constants';

const StandAlonePrintViewer = observer((props) => {
  const {
    lessonManager,
    studentActivityManager,
    alignmentManager,
  } = useContext(MobXProviderContext);
  const { lessonElementId, isLastActivityChild, isMultipart, isGroup, isFirstChild = false } = props;

  const CurrentComponent = lessonManager.getCurrentQuestionComponent(lessonElementId);
  const lessonElement = lessonManager.getLessonElement(lessonElementId);
  const componentRef = useRef();
  useWirisParser(componentRef);
  useTextHighlight(componentRef, lessonElementId);

  // TODO unused // const mode = lessonManager.playerMode;
  const model = lessonManager.getSlideModel(lessonElementId);
  const isActivity = (model) ? model.isActivity : false;
  const isActivityPart = (model) ? (model.isActivityPart && !isMultipart) : false;

  if (CurrentComponent) {
    CurrentComponent.displayName = model.type;
  }

  useEffect(() => {
    const doFetch = async () => {
      const alignmentPaths = alignmentManager.alignmentCoveragePaths.get(lessonElementId);

      if (alignmentPaths === undefined) {
        if (model && model.isActivityPart ) {
          await alignmentService.fetchContentItemAlignments(model.parentActivityElementId,
            '', '', lessonElement.entityId);
        }

        if (lessonElement && model ) {
          await alignmentService.fetchContentItemAlignments(lessonElement.id,
            '', '', lessonElement.entityId);
        }
      }
    };
    doFetch();
  });

  const finalClassName = (model) ?
    `lesson-element ${(isActivity) ? 'activity' : 'stand-alone'} ${model.type}  ${(model.extraClass) ? model.extraClass : ''}` : '';

  return (
    <div className={classNames({
      'stand-alone-wrapper': true,
      'is-last-activity-child': isLastActivityChild,
      'is-activity-part': isActivityPart,
      'is-group': isGroup,
      'is-multi-part': isMultipart,
      // added to make styling a bit easier
      'is-first-child': isFirstChild
    }, model.type)} id={`${lessonElementId}-standalone-wrapper`}>
      <div ref={componentRef} className={finalClassName} id={`${lessonElementId}-item-view`}>
        {(CurrentComponent && !isActivity) && (
          <>
            <CurrentComponent key={lessonElementId} lessonElementId={lessonElementId} model={model} />
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) ? (
              <>
                <QuestionFeedback isSurvey={model.survey} lessonElementId={lessonElementId} />
                <StandardsFooter key={`${lessonElementId}_print_stands`} lessonElementId={lessonElementId} />
              </>
            ) : (<></>)}
          </>
        )}
      </div>
    </div>
  );
});
StandAlonePrintViewer.displayName = 'StandAlonePrintViewer';
export default StandAlonePrintViewer;
