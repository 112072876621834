import React from 'react';
import WidgetService from '../../../services/WidgetService';

const GridWidget = (props) => {
  const { model } = props;
  const height = model.height || '';
  let hasHeight = false;
  const boxPadding = model.layout === 'absolute' ? '0' : model.boxPadding;
  const maxWidth = (model.maxWidth) ? `${model.maxWidth}px` : '';
  const backgroundColor = '';

  if (height) {
    hasHeight = true;
  }

  return (
    <div id={model.id} {...props} className='widgetGrid widgetContent' data-has-height={hasHeight}
      data-id={model.id} data-layout={model.layout} style={{ gridTemplateColumns: model.gridTemplateColumns, backgroundColor, height: `${height}px`, maxWidth }}>
      {model.boxes.map((box, index) => {
        const boxDirection = box.direction || 'column';
        const styleAtt = {};
        if (box.backgroundColor) {
          styleAtt.backgroundColor = box.backgroundColor;
        }

        styleAtt.flexDirection = box.direction;

        if (boxDirection === 'row') {
          if (box.justify) {
            styleAtt.justifyContent = box.justify;
          }

          if (box.align) {
            styleAtt.alignItems = box.align;
          }
        } else if (boxDirection === 'column') {
          if (box.justify) {
            styleAtt.justifyContent = box.align;
          }

          if (box.align) {
            styleAtt.alignItems = box.justify;
          }
        }
        const display = (model.display) ? model.display : 'visible';

        styleAtt.borderTopWidth = `${box.borderTopWidth}px`;
        styleAtt.borderRightWidth = `${box.borderRightWidth}px`;
        styleAtt.borderBottomWidth = `${box.borderBottomWidth}px`;
        styleAtt.borderLeftWidth = `${box.borderLeftWidth}px`;
        styleAtt.padding = `${boxPadding}px`;
        if (boxPadding === 0) {
          styleAtt.paddingRight = '15px';
        }

        box.childLayout = box.childLayout || '';
        box.subType = box.subType || '';

        box.imageResourceId = box.imageResourceId || '';

        return (
          <div key={box.id} className={`widgetBox ${display}`} data-child-layout={box.childLayout} data-image-resource-id={box.imageResourceId}
            data-subtype={box.subType} style={styleAtt}>
            {/* eslint-disable-next-line array-callback-return */}
            {box.childIds.map((childId, childIdIndex) => {
              const child = WidgetService.getChild(model, childId);
              if (child) {
                const url = WidgetService.getContentUrl(child);
                const Widget = WidgetService.getWidgetComponent(child);

                if (Widget) {
                  return (<Widget key={child.id} model={child} url={url} />);
                } else (<></>);
              } else {
                return (<></>);
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default GridWidget;
