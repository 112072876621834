import React, { useEffect, useRef, useState } from 'react';
import AnalogiesService from '../../lessonPlayer/services/AnalogiesService';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const TextInput = ({ id, left, top, width, height, text }) => {
  let props;
  if (text && text.length) {
    props = {
      readOnly: true,
      value: text
    };
  }

  return (
    <textarea
      {...props}
      className='canvasTextInput'
      data-id={id}
      style={{ left, top, width: width - 4, height: height - 4, fontWeight: 900 }}
      type='text'
    />
  );
};

const TextContainers = ({ model, canvas }) => {
  if (!canvas) {
    return null;
  }

  let textContainerCount = 0;
  const textArray = [
    model.centerText,
    model.leftText[0],
    model.leftText[1],
    model.rightText[0],
    model.rightText[1],
    model.leftEndText,
    model.rightEndText,
  ];

  return (
    <>
      {canvas.getObjects().map((obj) => {
        if (obj.type === 'rectangle') {
          const text = textArray[textContainerCount];
          const id = `promptId${ textContainerCount++}`;

          return (
            <TextInput
              key={id}
              height={obj.height}
              id={id}
              left={obj.left}
              text={text}
              top={obj.top}
              width={obj.width}
            />
          );
        }
        return null;
      })}
    </>
  );
};

const Analogies = ({ model }) => {
  const analogiesService = useRef(new AnalogiesService(model));

  const [canvasRenderingDone, setCanvasRenderingDone] = useState(false); // eslint-disable-line no-unused-vars

  useEffect(() => {
    async function drawToCanvas() {
      await analogiesService.current.initCanvas();
      await analogiesService.current.render();
      // after all canvas rendering is done, force a react re-render to overlay textareas on canvas objects
      setCanvasRenderingDone(true);
    }

    drawToCanvas();
  }, [model.id]);

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Graphic Organizer Type:</div>
        <div>Analogies</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div>Directions Line:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.directions} /></div>
      </div>
      <div className='body canvasOrganizerBody editSection' style={{ height: analogiesService.current.canvasHeight, width: analogiesService.current.canvasWidth }}>
        <canvas className='canvas' id={model.id} />
        <div className='canvasTextInputContainer' style={{ height: analogiesService.current.canvasHeight }}>
          <TextContainers
            canvas={analogiesService.current.canvas}
            model={model}
          />
        </div>
      </div>
    </div>
  );
};
export default Analogies;
