import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';
import { confirmDialog } from './dialogs';

import '../../css/MediaRecorderContainer.scss';

import { register } from '../../i18n';

import waveSurferService from '../services/WaveSurferService';
import utilsService from '../services/UtilsService';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';

import WaveSurferPlayer from './WaveSurferPlayer';

const t = register('MediaRecorder');
const t2 = register('GlobalQuestionLabels');

const MediaRecorderContainer = observer((props = {}) => {
  const { isVideo, lessonElementId, mimeMainType } = props;

  const MIME_TYPES = ['video/webm;codecs=vp8,opus', 'video/mp4'];
  const findSupportedMimeType = () => MIME_TYPES.find((mimeType) => MediaRecorder.isTypeSupported(mimeType));

  // const videoMimeType = 'video/webm;codecs=vp8,opus';
  // const videoMimeType = 'video/webm';
  // const videoMimeType = 'video/webm; codecs="opus,vp8"';

  const { questionFeedbackManager, uploadManager } = useContext(MobXProviderContext);

  const liveVideoFeedRef = useRef();
  const videoMediaRecorderRef = useRef();

  const videoRecordedPlayerRef = useRef();

  const startRecordingRef = useRef();
  const uploadRecordingRef = useRef();
  const stopRecordingRef = useRef();
  const clearRecordingRef = useRef();
  const cancelRecordingRef = useRef();

  const [disallowRenderWaveSurferPlayer, setDisallowRenderWaveSurferPlayer] = useState(false);

  const [videoRecorderPermission, setVideoRecorderPermission] = useState(false);

  const [videoRecorderAudioStream, setVideoRecorderAudioStream] = useState();
  const [videoRecorderVideoStream, setVideoRecorderVideoStream] = useState();
  const [videoRecorderCombinedStream, setVideoRecorderCombinedStream] = useState();

  const [currentRecordedVideoBlob, setCurrentRecordedVideoBlob] = useState();
  const [currentRecordedVideoBlobUrl, setCurrentRecordedVideoBlobUrl] = useState();

  const [currentBlob, setCurrentBlob] = useState();
  const [currentBlobUrl, setCurrentBlobUrl] = useState();

  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    // on mount
    (async () => {
      if (isVideo) {
        await initVideoRecorder();
      }
    })();

    // on unmount
    return async () => {
      await handleStopVideoRecorderStreamTracks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAccessibilityClick(startRecordingRef, async (_event) => {
    // setIsRecording(true);
    handleRecordStart();
  });

  useAccessibilityClick(uploadRecordingRef, async (_event) => {
    const date = new Date();
    const dateTimeStr = `${date.toLocaleDateString()}_${date.getTime()}`;

    const fileName = `recording_${ utilsService.stripInvalidFilenameChars(dateTimeStr) }.webm`;

    const blobToUpload = isVideo ? currentRecordedVideoBlob : currentBlob;

    const currentFile = new File([blobToUpload], fileName, {
      lastModified: date,
      size: blobToUpload.size,
      type: blobToUpload.type
    });

    await handleCloseMediaRecorderContainer();

    await props.uploadFile(currentFile, {
      mimeMainType // 'audio' | 'video'
    });
  });

  useAccessibilityClick(stopRecordingRef, async (_event) => {
    handleRecordEnd();
  });

  useAccessibilityClick(clearRecordingRef, async (_event) => {
    if (isVideo) {
      setDisallowRenderWaveSurferPlayer(true);

      setCurrentBlob(undefined);
      setCurrentBlobUrl(undefined);
      setCurrentRecordedVideoBlob(undefined);
      setCurrentRecordedVideoBlobUrl(undefined);

      await initVideoRecorder();

      setDisallowRenderWaveSurferPlayer(false);
    } else {
      setCurrentBlob(undefined);
      setCurrentBlobUrl(undefined);

      setDisallowRenderWaveSurferPlayer(true);
      setTimeout(() => {
        setDisallowRenderWaveSurferPlayer(false);
      }, 1);

      // await handleClearDurationSeconds();
    }
  });

  useAccessibilityClick(cancelRecordingRef, async (_event) => {
    await handleCloseMediaRecorderContainer();
  });

  const initVideoRecorder = async () => {
    if (!isVideo || videoMediaRecorderRef?.current) {
      return;
    }

    // get media permissions and then point media stream to the video `srcObject`
    if ('MediaRecorder' in window) {
      try {
        setVideoRecorderPermission(false);

        // create audio and video streams separately
        const audioStream = videoRecorderAudioStream || await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        const videoStream = videoRecorderVideoStream || await navigator.mediaDevices.getUserMedia({ audio: false, video: true });

        // combine both audio and video streams
        const combinedStream = videoRecorderCombinedStream || new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        // point live video player to `videoStream`
        // not including `audioStream` is intentional here
        // i.e. excluding `audioStream` from `liveVideoFeedRef` prevents feedback noise
        liveVideoFeedRef.current.srcObject = videoStream;

        const newMediaRecorder = new MediaRecorder(combinedStream, {
          mimeType: findSupportedMimeType(),
          videoBitsPerSecond: 1000000 // 360p
          // videoBitsPerSecond: 2500000 // 480p
        });

        videoMediaRecorderRef.current = newMediaRecorder;

        setVideoRecorderAudioStream(audioStream);
        setVideoRecorderVideoStream(videoStream);
        setVideoRecorderCombinedStream(newMediaRecorder.stream);

        setVideoRecorderPermission(true);
      } catch (error) {
        if (error?.message?.includes?.('Cannot set properties of null (setting \'srcObject\')')) {
          // This error is referring to `liveVideoFeedRef.current.srcObject`
          // --
          // It typically occurs if MediaRecorder is still initializing when the user quickly
          // launches, exits, then relaunches MediaRecorderContainer.
          // ---
          // We do not want to show an error dialog to the user for this case, since the issue
          // resolves itself once MediaRecorder has enough time to initialize.
          return;
        }
        console.error(error);
        confirmDialog({
          title: t('errorTitle'),
          text: error?.message || t2('errorGeneric'),
          showCancelButton: false,
          confirmButtonText: t('confirmOk')
        }).then(async () => {
          await handleCloseMediaRecorderContainer();
        });
      }
    } else {
      confirmDialog({
        title: t('errorTitle'),
        text: t('errorNoMediaRecorder'),
        showCancelButton: false,
        confirmButtonText: t('confirmOk')
      });
    }
  };

  const handleRecordStart = async () => {
    if (isVideo) {
      setCurrentBlob(undefined);
      setCurrentBlobUrl(undefined);
      setCurrentRecordedVideoBlob(undefined);
      setCurrentRecordedVideoBlobUrl(undefined);

      videoMediaRecorderRef.current.start();

      videoMediaRecorderRef.current.onstart = (event) => {
        setIsRecording(true);
      };

      videoMediaRecorderRef.current.ondataavailable = (event) => {
        // this event is triggered after a user stops recording a video
        if (typeof event?.data === 'undefined' || event?.data?.size === 0) {
          return;
        }

        const videoBlob = event.data;
        // TODO remove
        // const videoBlob = new Blob(blobParts, { type: videoMimeType });
        // const videoBlob = new Blob([event.data], { type: event.data.type });

        const videoUrl = URL.createObjectURL(videoBlob);

        setCurrentRecordedVideoBlob(videoBlob);
        setCurrentRecordedVideoBlobUrl(videoUrl);

        setIsRecording(false);
      };
    } else {
      setIsRecording(true);
    }
  };

  const handleRecordEnd = async () => {
    if (isVideo) {
      if (videoMediaRecorderRef?.current?.state !== 'inactive') {
        videoMediaRecorderRef?.current?.stop?.();
      }
    } else {
      setIsRecording(false);
    }
  };

  /**
   * Ensure all known `MediaRecorder` instances are inactive (and all known
   * recording streams have been stopped) for the active `lessonElement`.
   *
   * This allows the user's browser tab to stop displaying the 'record' icon,
   * and stops it from utilizing the user's webcam and/or microphone.
   */
  const handleStopVideoRecorderStreamTracks = async () => {
    if (uploadManager.waveSurferVideoMediaRecorderMap.has(lessonElementId)) {
      const waveSurferMediaRecorders = uploadManager.waveSurferVideoMediaRecorderMap.get(lessonElementId) || [];
      for (const waveSurferMediaRecorder of waveSurferMediaRecorders) {
        if (waveSurferMediaRecorder?.state !== 'inactive') {
          waveSurferMediaRecorder.stop();
          for (const track of waveSurferMediaRecorder.stream?.getTracks?.() || []) {
            track.stop();
          }
        }
      }
      uploadManager.removeFromWaveSurferVideoMediaRecorderMap(lessonElementId);
    }

    for (const audioTrack of videoRecorderAudioStream?.getTracks?.() || []) {
      audioTrack?.stop?.();
      videoRecorderAudioStream.removeTrack(audioTrack);
    }

    for (const videoTrack of videoRecorderVideoStream?.getTracks?.() || []) {
      videoTrack?.stop?.();
      videoRecorderVideoStream.removeTrack(videoTrack);
    }

    for (const track of videoRecorderCombinedStream?.getTracks?.() || []) {
      track?.stop?.();
      videoRecorderCombinedStream.removeTrack(track);
    }

    for (const track of videoMediaRecorderRef?.current?.stream?.getTracks?.() || []) {
      track?.stop?.();
      videoMediaRecorderRef?.current?.stream?.removeTrack(track);
    }
  };

  const handleChangeMedia = (blob) => {
    if (!blob) {
      return;
    }
    const blobUrl = URL.createObjectURL(blob);

    setCurrentBlob(blob);
    setCurrentBlobUrl(blobUrl);
  };

  const handleCloseMediaRecorderContainer = async () => {
    if (!isVideo) {
      setIsRecording(false);
      uploadManager.clearSelectedUploadResponseOption(lessonElementId);
      questionFeedbackManager.setSubmitDisabledState(lessonElementId, false);
    } else {
      setDisallowRenderWaveSurferPlayer(true);
      await handleRecordEnd();
      // await handleStopVideoRecorderStreamTracks(); // TODO remove, now called on unmount instead
      uploadManager.clearSelectedUploadResponseOption(lessonElementId);
      questionFeedbackManager.setSubmitDisabledState(lessonElementId, false);
    }
  };

  const renderVideoRecorder = () => {
    return (
      <div className='video-recorder-container'>
        <div className={classNames('video-recorder-wrapper', {
          'video-recorder-live-wrapper': !currentRecordedVideoBlobUrl,
          'video-recorder-play-wrapper': !currentRecordedVideoBlobUrl,
        })}>
          {/* live video feed */}
          <video ref={liveVideoFeedRef} autoPlay
            className={classNames('video-recorder-live', {
              'is-recording': isRecording,
              'has-recording': currentRecordedVideoBlobUrl
            })} />
          {/* recorded video */}
          {currentRecordedVideoBlobUrl ? (
            <video ref={videoRecordedPlayerRef}
              className='video-recorder-play'
              controls controlsList='nodownload'
              disablePictureInPicture
              src={currentRecordedVideoBlobUrl} />
          ) : null}
        </div>
      </div>
    );
  };

  const renderRecorderContainerActions = () => {
    return (
      <div className='recorder-container-actions-wrapper'>
        {!isRecording && !currentBlobUrl && (
          renderRecordDialogActionButton(startRecordingRef, 'startRecording')
        )}
        {!isRecording && currentBlobUrl && (
          renderRecordDialogActionButton(uploadRecordingRef, 'uploadRecording')
        )}
        {isRecording && (
          renderRecordDialogActionButton(stopRecordingRef, 'stopRecording')
        )}
        {renderRecordDialogActionButton(clearRecordingRef, 'clearRecording', {
          disabled: isRecording || !currentBlobUrl
        })}
        {renderRecordDialogActionButton(cancelRecordingRef, 'cancelRecording')}
      </div>
    );
  };

  const renderRecordDialogActionButton = (ref, buttonLabel, {
    disabled
  } = {}) => {
    return (
      <button
        ref={ref}
        className={classNames(buttonLabel, 'primary', {
          'pseudo-disabled-bg-opacity': disabled
        })}
        disabled={disabled}
        tabIndex='0'>
        {t(buttonLabel)}
      </button>
    );
  };

  const shouldRenderWaveSurferPlayer = !disallowRenderWaveSurferPlayer && (
    !(isVideo && isRecording && !videoMediaRecorderRef?.current)
  );

  return (
    <div className={classNames('MediaRecorderContainer', mimeMainType, 'theme-container')}>
      <div className='media-recorder-container-inner'>
        <div className='media-recorder-header'>
          <div className='media-recorder-header-title'>
            {!isVideo ? t('audioRecorder') : t('videoRecorder')}
          </div>
          {/* TODO remove, unused */}
          {/* <div className='x-close-button mask-image' onClick={async () => {
            await handleCloseMediaRecorderContainer();
          }} /> */}
        </div>
        <div className={classNames('scroll-area', mimeMainType, {
          recording: isRecording
        })}>
          <div className='recorder-container-content-wrapper'>
            {isVideo && renderVideoRecorder()}
            {shouldRenderWaveSurferPlayer ? (
              <WaveSurferPlayer
                currentBlob={currentBlob}
                currentBlobUrl={currentBlobUrl}
                internalOptions={{
                  ...waveSurferService.getDefaultWaveSurferOptions(),
                  barHeight: isRecording ? 5 : NaN,
                  media: (isVideo && !isRecording) ? videoRecordedPlayerRef?.current : undefined,
                  // eslint-disable-next-line no-nested-ternary
                  url: isVideo ? (!isRecording ? currentRecordedVideoBlobUrl : undefined)
                    : currentBlobUrl
                }}
                isRecording={isRecording}
                isVideo={isVideo}
                lessonElementId={lessonElementId}
                onChangeMedia={handleChangeMedia}
                onRecordEnd={handleRecordEnd}
                onRecordStart={handleRecordStart}
                videoRecordedPlayerRef={videoRecordedPlayerRef}
                videoRecorderPermission={videoRecorderPermission} />
            ) : (
              <div className='wavesurfer-blank-placeholder' />
            )}
            {renderRecorderContainerActions()}
          </div>
        </div>
      </div>
    </div>
  );
});

export default MediaRecorderContainer;
