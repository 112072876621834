import { action, makeObservable, observable } from 'mobx';
import UtilsService from '../services/UtilsService';

export class ScoringManager {
  @observable
  tempScores = new Map();

  @observable
  rubricMap= new Map();

  @observable
  selectedCells = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  setTempScore = (lessonElementId, score) => {
    this.tempScores.set(lessonElementId, score);
  }

  @action
  setCategoryTempScoreFromTable = (lessonElementId, categoryId, str) => {
    const rubric = this.rubricMap.get(lessonElementId);
    // TODO unused // const that = this;
    if (rubric) {
      // eslint-disable-next-line array-callback-return
      rubric.categories.map((category) => {
        if (category.categoryId === categoryId) {
          category.calculatedTempScore = str;
        }
      });
      this.calculateNewScoreFromRubricTable(lessonElementId);
    }
  };

  @action
  setCategoryFullCreditFromTable = (lessonElementId) => {
    const rubric = this.rubricMap.get(lessonElementId);
    // TODO unused // const that = this;
    if (rubric) {
      // eslint-disable-next-line array-callback-return
      rubric.categories.map((category) => {
        const maxScore = category.maxPoints;
        category.calculatedTempScore = maxScore;
      });
      this.calculateNewScoreFromRubricTable(lessonElementId);
    }
  };

  @action
  setRubric = (lessonElementId, rubric, isScored) => {
    this.rubricMap.set(lessonElementId, rubric);
  }

  getTempScore = (lessonElementId) => {
    if (this.tempScores.has(lessonElementId)) {
      return this.tempScores.get(lessonElementId);
    }
    return '0';
  }

  @action
  rubricCellClick = (lessonElementId, clickIndex, categoryId) => {
    const rubric = this.rubricMap.get(lessonElementId);
    const cellId = `${categoryId}_${clickIndex}_${lessonElementId}`;

    this.updateCellSelections(rubric, cellId);
  }

  @action
  updateCellSelections = (rubric, cellId) => {
    const cellIdParts = cellId.split('_');
    const categoryId = cellIdParts[0];

    // eslint-disable-next-line array-callback-return
    rubric.categories.map((category) => {
      if (category.categoryId === categoryId) {
        if (!this.selectedCells.has(cellId) || !this.selectedCells.get(cellId)) {
          this.selectedCells.set(cellId, true);
        } else {
          this.selectedCells.set(cellId, false);
        }
      }
    });
  }

  isSelectedCell = (categoryId, clickIndex, lessonElementId) => {
    const cellId = `${categoryId}_${clickIndex}_${lessonElementId}`;
    if (!this.selectedCells.has(cellId)) {
      return false;
    }
    return this.selectedCells.get(cellId);
  }

  @action
  calculateNewScoreFromRubricTable = (lessonElementId) => {
    const rubric = this.rubricMap.get(lessonElementId);
    let totalScore = 0.00;
    if (rubric) {
      // eslint-disable-next-line array-callback-return
      rubric.categories.map((category) => {
        if (!UtilsService.isNullOrEmpty(category.calculatedTempScore)) {
          totalScore += parseFloat(category.calculatedTempScore);
        }
      });
      this.tempScores.set(lessonElementId, totalScore.toFixed(2));
    }
  }

  clearRubricSelections = (lessonElementId) => {
    const rubric = this.rubricMap.get(lessonElementId);
    // TODO unused // const tempScore = this.tempScores.get(lessonElementId);

    const that = this;
    rubric.calculatedTempScore = '';

    // eslint-disable-next-line array-callback-return
    rubric.categories.map((category) => {
      // eslint-disable-next-line array-callback-return
      category.columns.map((_column, index) => {
        const cellId = `${category.categoryId}_${index}`;
        that.selectedCells.set(cellId, false);
        category.calculatedTempScore = '';
      });
    });
  }

  getSubscore = (lessonElementId) => {
    const rubric = this.rubricMap.get(lessonElementId);
    if (!rubric) {
      return null;
    }
    const that = this;
    const subscoreObject = {
      selectedRubricCells: [],
      values: []
    };
    // eslint-disable-next-line array-callback-return
    rubric.categories.map((category) => {
      const valueObject = {
        categoryId: category.categoryId,
        points: category.calculatedTempScore
      };
      subscoreObject.values.push(valueObject);
      // eslint-disable-next-line array-callback-return
      category.columns.map((_column, cellIndex) => {
        const cellId = `${category.categoryId}_${cellIndex}_${lessonElementId}`;
        if (that.selectedCells.get(cellId)) {
          subscoreObject.selectedRubricCells.push(cellId);
        }
      });
    });
    return JSON.stringify(subscoreObject);
  }

  @action
  setSubscore = (lessonElementId, subscoreObject, isScored) => {

    const rubric = this.rubricMap.get(lessonElementId);
    const that = this;
    if (rubric) {
      if (subscoreObject && subscoreObject.selectedRubricCells) {
        that.selectedCells.clear();
        // eslint-disable-next-line array-callback-return
        subscoreObject.selectedRubricCells.map((cellId) => {
          that.selectedCells.set(cellId, true);
        });
      }
      if (subscoreObject && subscoreObject.values) {
        // eslint-disable-next-line array-callback-return
        rubric.categories.map((category) => {
          // eslint-disable-next-line array-callback-return
          subscoreObject.values.map((value) => {
            if (category.categoryId === value.categoryId) {
              category.calculatedTempScore = (isScored) ? value.points : '';
            }
          });
        });
      }
    }
  }
}

export default new ScoringManager();
