import React from 'react';

import ActivityNavButton from './ActivityNavButton';

const ActivityNavButtonGroups = ({
  groupList, groupIndex, lessonElementId, slides
}) => {
  const finalList = [];
  for (const id of groupList) {
    const isVisible = slides.indexOf(id) > -1;
    if (isVisible) {
      finalList.push(id);
    }
  }

  return (
    <> {(finalList.length > 0) && (
      <div className='nav-button-group'>
        {finalList.map((childLessonElementId, index) => {
          return (
            <div key={`${childLessonElementId}-activity-button-ofgroup`}>

              <ActivityNavButton
                childElementId={childLessonElementId}
                index={groupIndex + index}
                lessonElementId={lessonElementId} />
            </div>
          );
        })}
      </div>
    )}
    </>
  );
};

export default ActivityNavButtonGroups;
