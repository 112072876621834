import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import navMenuService from '../../services/NavMenuService';

import ActivityNavButtonGroups from './ActivityNavButtonGroups';
import ActivityNavButton from './ActivityNavButton';
import { register } from '../../../i18n';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

const t = register('AriaLabels');

const ActivityNavigator = observer(({ activityLessonElementId, lessonElementId }) => {
  const {
    lessonManager, navMenuManager
  } = useContext(MobXProviderContext);

  const activityLessonElement = lessonManager.getLessonElement(activityLessonElementId);
  const navClasses = `activity-navigator ${ activityLessonElement.type}`;
  const slides = lessonManager.getFilteredSlides();
  const currentSlideId = slides[lessonManager.currentSlide];
  const scrollRef = useRef();
  const topScrollRef = useRef();
  const bottomScrollRef = useRef();

  useAccessibilityClick(topScrollRef, (_event) => {
    navMenuService.scrollUpHandler(scrollRef);
  });

  useAccessibilityClick(bottomScrollRef, (_event) => {
    navMenuService.scrollDownHandler(scrollRef);
  });

  useEffect(() => {
    if (activityLessonElementId !== navMenuManager.selectedParentActivityLessonElement?.id) {
      navMenuManager.setSelectedParentActivityLessonElement(activityLessonElement);
      navMenuManager.setActivityNavigatorLessonElementMap(new Map());
      navMenuService.initActivityNavigatorLessonElementMap({ activityLessonElement });

      navMenuManager.setPreviousActivityNavigatorPointY(null);

      const currentLessonElementId = lessonManager.getModelAndLessonElement(
        lessonManager.currentSlide)?.model?.lessonElementId;

      const selectedActivityNavigatorLessonElement = navMenuManager.activityNavigatorLessonElementMap.get(
        currentLessonElementId);

      navMenuManager.setSelectedActivityNavigatorLessonElementIndex(
        currentLessonElementId ?
          selectedActivityNavigatorLessonElement?.index || 0 : 0
      );
    }

    const element = document.getElementById(`${currentSlideId}-activity-nav`);
    if (element) {
      setTimeout(() => { element.scrollIntoView({ block: 'nearest', inline: 'nearest' }); }, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlideId]);

  return (
    <div className={navClasses}>
      <div ref={topScrollRef} aria-label={t('scrolldown')} className='activity-nav-scroll up'>
        <div aria-hidden className='icon-wrapper'>
          <div aria-hidden className='icon' />
        </div>
      </div>
      <div ref={scrollRef} aria-label={t('activityQuestionList')} className='activity-nav-button-wrapper' role='tablist'
        tabIndex='0'>
        {Array.from(navMenuManager.activityNavigatorLessonElementMap.values()).map(({
          childModel, groupList, index, isFirstInGroup
        }) => {
          const isVisible = slides.indexOf(childModel.lessonElementId) > -1;

          if (childModel.inGroup && isFirstInGroup) {
            return (
              <ActivityNavButtonGroups
                key={`${childModel.groupId}-activity-nav-group`}
                groupIndex={index}
                groupList={groupList}
                lessonElementId={lessonElementId}
                slides={slides} />
            );
          } else if (isVisible && !childModel.inGroup) {
            return (
              <ActivityNavButton key={`${childModel.lessonElementId}-activity-nav`}
                childElementId={childModel.lessonElementId}
                childModel={childModel}
                index={index}
                lessonElementId={lessonElementId} />
            );
          } else {
            return undefined;
          }
        }).filter((jsx) => jsx)}
      </div>
      <div ref={bottomScrollRef} aria-label={t('scrolldown')} className='activity-nav-scroll down'>
        <div aria-hidden className='icon-wrapper'>
          <div aria-hidden className='icon' />
        </div>
      </div>
    </div>
  );
});

export default ActivityNavigator;
