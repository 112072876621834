import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/Cloze.scss';
import { LessonMode } from '../../../Constants';

import questionService from '../../services/QuestionService';

import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';

import OptionalImage from '../OptionalImage';
import clozeTypingService from '../../services/ClozeTypingService';
import toolbarService from '../../services/ToolbarService';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const Cloze = observer(({ lessonElementId }) => {
  const {
    questionFeedbackManager,
    toolbarManager,
    lessonManager
  } = useContext(MobXProviderContext);

  const uiState = questionFeedbackManager.getUiState(lessonElementId) || {};
  const { noSubmit } = questionFeedbackManager;

  const {
    lessonElementState,
    model,
    readOnly,
    toolbar,
    userInputMap,
  } = questionService.initQuestionComponent({
    lessonElementId,
    questionClassName: 'cloze-question'
  });

  useStyleEvents(lessonElementId);

  useEffect(() => {
    // if blanks are in a table lets give them a special class to change them as needed
    const blanks = document.querySelectorAll('td .cloze-blank-section.cloze-section.range.noModify.readOnly.text');
    if (blanks) {
      blanks.forEach((blank) => {
        blank.classList.add('cloze-table-blank');
        const parentElement = blank.closest('TD');
        if (parentElement && parentElement.tagName === 'TD') {
          parentElement.classList.add('cloze-blank-cell');
        }
      });
    }
  },);

  const renderCloze = (_props = null) => {
    const answerBankPositionClassName = `answer-bank-position-${ model?.answerBankPosition || 'bottom'}`;
    const clozeQuestionInnerClassNames = classNames('cloze-question-inner', answerBankPositionClassName);
    const questionTitle = <HtmlComponent htmlStr={model.questionText || ''} />;
    return (
      <div className='cloze-question'>
        <div className='cloze-question-section-title'>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='test-item-question'>
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
            {questionTitle}
          </div>
          <OptionalImage model={model} runtime={true} />
        </div>
        <div className={clozeQuestionInnerClassNames} onClick={toolbarService.handleContentResourceClick}>
          <div className='cloze-question-section-body'>
            <div className='test-item-answers'>
              {lessonElementState?.questionBehavior?.renderClozeBody({
                lessonElementId, model, renderBlankSection
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /** @param {import('html-react-parser').DOMNode} domNode */
  const renderBlankSection = (domNode) => {
    return clozeTypingService.renderClozeTypingBlankSection(domNode, {
      lessonElementId,
      lessonElementState,
      model,
      readOnly,
      toolbar,
      uiState,
      userInputMap,
      noSubmit
    });
  };
  return lessonElementId && model && lessonElementState
    /* && typeof uiState?.submitButtonVisible === 'boolean' */ ? renderCloze() : null;
});

export default Cloze;
