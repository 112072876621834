/* eslint quotes: 0 */
/* eslint quote-props: 0 */
/* eslint semi: 0 */
// This file has to be in a JSON object format. ESLINT warnings are turned off for quotes for this reason
// *** IMPORTANT***
// If you make a change to this file you need to update the same changes in publisherThemes/defaultTranslationsFile.js, and
// publisherThemes/testTranslationsFile.js and then every publisher that has a translationFile in their theme folder.  For updating
//

const translations =
  // START of translations to update in override files don't put "const translations: =" in those files,
  {
    "AssessmentItemList": {
      "dropTargetInstructions": "drag and drop items here",
    },
    "BuilderLayout": {
      "randomizeButtonLabel": "Randomize",
      "exitButtonRollover": "Have you added your items?",
      "numItemsRollover": "Click here to preview a list of the questions that have been selected.",
      "itemBankShoppingCardDescription": "Select items to add to your assessment. Then select Add Items.",
      "itemBankAssessmentCardDescription": "Select items to place them in your Assessment. Select the arrows next to placed items to change their order.",
      "selectedItemsDescription": "",
      "builderHeaderName": "",
      "addItemsHeaderName": ""
    },
    "FlyupNavForScoring": {
      "questionLabel": "Question",
      "slideLabel": "Slide",
      "showManual": "Show only manually scored items:",
      "showUnscored": "Show only unscored items:",
      "showNotePad": "Show student notepad:",
      "selectionOne": "Highlights and Notes: Off",
      "selectionTwo": "Highlights and Notes: Teacher",
      "selectionThree": "Highlights and Notes: Student",
      "previousStudent": "Previous Student",
      "previousItem": "Previous Item",
      "nextItem": "Next Item",
      "nextStudent": "Next Student",
      "navInfoLabel": "view key commands"
    },
    "ImageTextMatch": {
      "dragCancelAnnouncement": "Dragging was canceled. Draggable item '{{sourceLabel}}' was dropped.",
      "dragEndAnnouncement": "Draggable item '{{sourceLabel}}' was dropped.",
      "dragOverAnnouncement": "Draggable item '{{sourceLabel}}' was moved over droppable area '{{targetLabel}}'.",
      "dragOverEndAnnouncement": "Draggable item '{{sourceLabel}}' was dropped over droppable area '{{targetLabel}}'.",
      "dragStartAnnouncement": "Picked up draggable item '{{sourceLabel}}'.",
      "screenReaderInstructions": ""
    },
    "ImageTextMatchFlip": {
      "flippableCard": "Flippable card"
    },
    "QuestionLabels": {
      "defaultQuestionBannerText": "Question {{questionNumber}} {{newLine}} {{summary}}",
      "defaultQuestionLabelText": "Question {{questionNumber}} {{newLine}} {{summary}}",
      "noSummaryLabelText": "Question {{questionNumber}}"
    },
    "VideoPlayer": {
      "previous": "Prev",
      "pause": "Pause",
      "play": "Play",
      "next": "Next"
    },
    "InfoPage": {
      "lessonBanner": "In this lesson you will cover ...",
      "assessmentBanner": "After taking this assessment, you will be able to ..."
    },
    "MediaRecorder": {
      "audioRecorder": "Audio Recorder",
      "cancelRecording": "Cancel Recording",
      "clearRecording": "Clear Recording",
      "confirmOk": "OK",
      "errorNoMediaRecorder": "The MediaRecorder API is not supported in your browser.",
      "errorTitle": "An error occurred:",
      "startRecording": "Start Recording",
      "stopRecording": "Stop Recording",
      "uploadRecording": "Upload Recording",
      "videoRecorder": "Video Recorder",
      "clickRecordToStart": "Click record to start",
      "initializing": "Initializing...",
      "pauseButton": "Pause Button",
      "playButton": "Play Button",
      "recording": "Recording",
      "volume": "Volume"
    },
    "SummaryInfo": {
      "outOf": "Out of",
      "item": "item",
      "items": "items",
      "summaryInfoHeader": "Activity",
      "results": "Results",
      "completed": "Completed",
      "markedForReview": "Marked For Review",
      "unanswered": "Unanswered",
      "correct": "Correct",
      "partiallyCorrect": "Partially correct",
      "incorrect": "Incorrect",
      "toBeScored": "To Be Scored",
      "notToBeScored": "Not Scored",
      "saveForLater": "Save for Later",
      "submit": "Submit",
      "exit": "Exit",
      "totalTime": "Total Time",
      "speedWarning": "Speed Warning"
    },
    "ToolNames": {
      "lessonMenu": "Lesson Tools",
      "moreTools": "More Tools",
      "annotationMenu": "Annotation Tools",
      "rulerMenu": "Rulers",
      "subjectMenu": "Subject Tools",
      "persistentMenu": "Persistent Tools",
      "settingsMenu": "Settings",
      "fontMenu": "Font",
      "calculatorsMenu": "Calculators",
      "audioMenu": "Audio Tools",
      "bookmarkTool": "Mark for Review",
      "revealTool": "Reveal",
      "answerTool": "Answers",
      "instructionTool": "Instructions",
      "textHelp": "Speak",
      "dictionaryTool": "Dictionary",
      "writingCheckListTool": "Writing Checklist",
      "protractorTool": "Protractor",
      "graphPaperLabel": "Graph Paper",
      "imperialRuler": "Imperial Ruler",
      "metricRuler": "Metric Ruler",
      "doubleRuler": "Ruler",
      "basicCalculator": "Basic Calculator",
      "scientificCalculator": "Scientific Calculator",
      "graphingCalculator": "Graphing Calculator",
      "lineReaderTool": "Line Reader",
      "strikethrough": "Strikethrough",
      "highlightTool": "Highlight",
      "stickyNotes": "Sticky Notes",
      "colorPicker": "Color",
      "translateTool": "Translate",
      "audioTool": "Speech/Translate",
      "exitButton": "Exit",
      "smallFont": "Small",
      "mediumFont": "Medium",
      "largeFont": "Large",
      "boldFont": "Bold",
      "helpPagesLabel": "Help",
      "bookNotesLabel": "Book Notes",
      "notepadLabel": "Notepad",
      "basicCalculatorInfo": "Calculator - owned and operated by Desmos, Inc.",
      "colorPickerInfo": "Choose a color scheme by selecting one of the buttons below.",
      "graphingCalculatorInfo": "Graphing Calculator - owned and operated by Desmos, Inc.",
      "scientificCalculatorInfo": "Scientific Calculator - owned and operated by Desmos, Inc.",
      "highlightInfo": "Highlight Options",
      "instructionTitle": "Student Instructions",
      "textHelpTitle": "Speech Toolbar",
      "textToSpeachInfo": "Listen"
    },
    "Upload": {
      "invalidUploadModeMsg": "File uploading is only active in assigned lessons.",
      "loadingFileBox": "Loading...",
      "recordAudio": "Record Audio",
      "recordVideo": "Record Video",
      "selectFiles": "Select files...",
      "uploadFile": "Upload File",
      "uploadingActive": "Uploading...",
      "uploadingInactive": "Drop files here to upload",
      "done": "Done"
    },
    "VideoQuestion": {
      "off": "Off"
    },
    "PanelMenu": {
      "itemAlignment": "Item Alignment",
      "activityAlignment": "Activity Alignment",
      "openMenu": "Open flyout menu",
      "closeMenu": "Close flyout menu",
      "engagementPanel": "Engagement",
      "rubricPanel": "Rubric",
      "teacherNotePanel": "Teacher Notes",
      "alignmentPanel": "Alignments",
      "hideButton": "hide rubric",
      "showButton": "show rubric",
      "yes": "Yes",
      "no": "No",
      "average": "Average",
      "timeOnTask": "Time On Task",
      "ifIncorrect": "If incorrect, did student select",
      "correctOn": "Correct on",
      "firstAttempt": "First Attempt",
      "student": "Student",
      "timeOn": "Time on",
      "timeOnItem": "Time on Item",
      "correctAnswer": "Correct Answer",
      "incorrectAnswer": "Incorrect Answer",
      "supportToolUse": "Support Tool Use",
      "total": "Total",
      "maxPoints": "Maximum Points",
      "activity": "Activity",
      "class": "Class",
      "reportingWeight": "REPORTING WEIGHT",
      "directions": "Directions",
      "summaryScoringMessage": "Scores are based on the first try."
    },
    "LessonItemNames": {
      "Analogies": "Analogies",
      "Article": "Article Resource",
      "ArticleQuestion": "Passage Activity",
      "Audio": "Audio Resource",
      "AudioHighlight": "Audio Highlight",
      "AudioQuestion": "Audio Activity",
      "Bookmark": "Bookmark",
      "BubbleWeb": "Bubble Web",
      "Categorize": "Categorize",
      "CategoryTree": "Categorize Tree",
      "CauseAndEffect": "Cause and Effect",
      "ChoiceMatrix": "Choice Matrix",
      "ChoiceMatrixMulti": "Multiple Choice Table",
      "CircleDefine": "Define",
      "ClipFragment": "ClipFragment Resource",
      "Cloze": "Fill In The Blank",
      "ClozeCombo": "Fill in the blank Combo",
      "ClozeDragDrop": "Fill In The Blank Drag-Drop",
      "ClozeMath": "Fill In The Blank Math",
      "ClozeMultichoice": "Fill In The Blank Multiple Choice",
      "ClozeSpelling": "Fill In The Blank Spelling",
      "CompareContrast": "Compare and Contrast",
      "CornellNotes": "Cornell Notes",
      "DownloadAutoscored": "Download Autoscored",
      "DoubleEntryJournal": "Double Entry Journal",
      "Draw": "Free-Draw",
      "FrayerModel": "Frayer Model",
      "FreeGraph": "Free Graph",
      "GoogleDoc": "Google Doc",
      "GoogleSheet": "Google Sheet",
      "GoogleSlide": "Google Slide",
      "Image": "Image Resource",
      "ImageLabel": "Image Label",
      "ImageLabelDragDrop": "Image Label Drag-Drop",
      "ImageLabelMultichoice": "Image Label Multiple Choice",
      "ImageHotSpot": "Image Hot Spot",
      "ImageMatch": "Image Match",
      "ImageTextMatch": "Image Text Match",
      "ImageTextMatchFlip": "Image Text Match Flip",
      "ImageQuestion": "Image Activity",
      "KWLChart": "KWLChart",
      "LessonPlan": "Lesson Plan",
      "LineGraph": "Line Graph",
      "LinkList": "Link List",
      "ListQuestion": "List Activity",
      "LongText": "Long Text",
      "Multichoice": "Multiple Choice",
      "Multiselect": "Multiple Select",
      "NumberLine": "Number Line",
      "OrderList": "Order List",
      "OrderSentences": "Order Sentences",
      "Pdf": "PDF",
      "PlacePoint": "Place Point",
      "Presentation": "Presentation",
      "Relationships": "Relationships",
      "Rubric": "Rubric",
      "Sequence": "Sequence",
      "ShortText": "Short Text",
      "Griddable": "Griddable",
      "StudentInstruction": "Student Instruction",
      "TChart": "TChart",
      "TeacherInstruction": "Teacher Instruction",
      "Text": "Text Resource",
      "TextHighlight": "Text Highlight",
      "TextSelector": "Text Selector",
      "HotText": "Hot Text",
      "Upload": "Upload",
      "UploadAutoscored": "Upload Autoscored",
      "UserFile": "UserFile Resource",
      "Venn": "Venn",
      "Video": "Video Resource",
      "VideoQuestion": "Video Activity",
      "MultipartActivity": "Multipart Activity",
      "BookmarkActivity": "Bookmark Activity",
      "BookletActivity": "Book Activity",
      "Vocabulary": "Vocabulary",
      "WebPage": "Web Page",
      "WordAssociation": "Word Association",
      "TitlePage": "Title Page",
      "SummaryPage": "Summary Page",
      "DI": "Differentiation Reason",
      "Section": "Section",
      "ItemResource": "Item Resource",
      "TestItem": "Test Item",
      "TestItemParent": "Test Item Parent"
    },
    "NavigationBar": {
      "progressLabel": "Progress",
      "progressTally": "{{completed}} of {{total}}",
      "differentiationReasonName": "Coverage Information",
      "studentInstructionName": "Student Instructions",
      "summaryPageName": "Review/End",
      "teacherInstructionName": "Teacher Instructions",
      "titlePageName": "Title",
      "goto": "Go To",
      "next": "Next",
      "previous": "Back"
    },
    "GlobalQuestionLabels": {
      "choose": "Choose",
      "enterHere": "Enter your text here",
      "enterText": "Enter text",
      "showTeacherInstruction": "Show Teacher Instruction",
      "hideTeacherInstruction": "Hide Teacher Instruction",
      "hint": "Hint",
      "sampleAnswer": "Sample Answer",
      "imageTitle": "Image Title: ",
      "showAnswer": "Show Student's Answer",
      "showMyAnswer": "Show My Answer",
      "submitButtonLabel": "Submit Answer",
      "tryAgainButtonLabel": "Try Again",
      "showAnswersButtonLabel": "Show Correct Answer",
      "hintButtonLabel": "Show Hint",
      "solutionButtonLabel": "Sample Answer",
      "saveAnswer": "Save Answer",
      "questions": "Questions",
      "ok": "OK",
      "save": "Save",
      "delete": "Delete",
      "deleteAll": "Delete All",
      "deleteElement": "Delete Element",
      "clearAll": "Clear All",
      "previewWarning": "You are in preview mode.  Your answer will not be submitted.",
      "previewWarning2": "You are in preview mode.  Your answer will not be saved.",
      "saveWarning": "Your first try answer was submitted. Your new answer will not be saved.",
      "saveCommit": "Your answer was submitted.",
      "saveCommit2": "Your answer was saved.",
      "partiallyCorrectPrompt": "You did not provide all possible correct answers",
      "partiallyCorrectSelectPrompt": "You did not select all of the correct answers",
      "partiallyCorrectSelectPrompt2": "Some of your answers are correct and some are incorrect",
      "errorGeneric": "Something went wrong.",
      "notSubmittedBody": "You have not answered one or more parts of this question. Do you still want to submit?",
      "cannotBe": "If you continue, this cannot be undone.",
      "exitCheck": "Are you sure you want to exit?",
      "dependencyMessage": "You need to answer questions",
      "and": "and",
      "toSee": "to see this item.",
      "highlightError": "Unable to Highlight",
      "highlightBody1": "Highlighting over existing sticky notes cannot be done.",
      "highlightBody2": "If you would like to highlight this selection, remove any existing sticky notes from the area and try again.",
      "maxSelected": "Maximum Answers Selected",
      "deselect": "Deselect an answer to select another.",
      "maxReached": "Maximum selections have been reached.",
      "deselect2": "To make another selection, first click one of your choices to deselect it.",
      "deleteCheck": "Are you sure you want delete this message?",
      "responseWarning": "Response number. Make sure you don't have any spaces in your response.",
      "errorAlert": "ERROR!",
      "submitCheck": "Are you sure you want to submit for a grade?",
      "notCompleteWarning": "Not all questions are completed, they will be scored as incorrect.",
      "noUnscoredScored": "There are no un-scored items in this activity.",
      "noManuallyScored": "There are no manually scored items in this activity.",
      "saveErrorMessage": "There was an error while saving your answer. If you see this message again, please close your lesson and try again later.",
      "createNewDiscussion": "Create a new discussion",
      "cancel": "Cancel",
      "example": "Example",
      "resolved": "RESOLVED",
      "resolve": "Resolve",
      "reply": "Reply",
      "edit": "Edit",
      "reOpen": "Re-Open",
      "correct": "Correct!",
      "partiallyCorrect": "Partially correct.",
      "incorrect": "Incorrect",
      "yes": "Yes",
      "no": "No",
      "close": "close",
      "unselected": "unselected",
      "lockError": "Your assignment was locked you cannot save your results.  Please talk to your instructor to resolve the issue.",
      "alreadySubmittedError": "Your assignment was already submitted you cannot save your results.  Please talk to your instructor to resolve the issue.",
      "generalSaveError": "Please talk to your instructor to resolve the issue.",
      "acceptedAnswers": "Accepted answers:",
      "name": "Name",
      "class": "Class",
      "internetLostHeader": "An Internet connection cannot be detected",
      "internetLostLine1": "Please double check your connection.",
      "internetLostLine2": "The application will not function until the",
      "internetLostLine3": "connection is restored."
    },
    "Widgets": {
      "article": "article",
      "audio": "audio",
      "image": "image",
      "pdf": "pdf",
      "resource": "resource",
      "video": "video",
      "file": "file",
      "uploadFile": "upload file"
    },
    "TeacherScoringPanel": {
      "headerLabelScore": "Score",
      "headerLabelUnscored": "Unscored",
      "giveFullCreditLabel": "Give Full Credit",
      "points": "Points",
      "usage": "Usage",
      "teacherComments": "Teacher Comments"
    },
    "Engagement": {
      "useOfTime": "Use of Time",
      "goodJob": "Good job!",
      "learning": "Learning takes time and you are working carefully and not rushing. Keep it up!",
      "slowDown": "Slow down!",
      "appears": "It appears that you are rushing and making mistakes. Take time to think. Carefully consider your answer(s) before pressing Submit",
      "useOfFeedback": "Use Of Feedback",
      "keepTrying": "Keep trying!",
      "wellDone": "Well Done!",
      "learning2": "Learning takes effort and you are actively using the supports such as Hints and Try Again. Keep up the good work!",
      "appears2": "It appears that you are not using the supports such as Hints and Try Again. Learning takes effort. Carefully read the feedback and try answering questions again."
    },
    "GraphPaper": {
      "select": "select",
      "line": "line",
      "ray": "ray",
      "segment": "segment",
      "vector": "vector",
      "point": "point",
      "draw": "draw"
    },
    "ResourceRecommendCartModal": {
      "add": "Add",
      "cancel": "Cancel",
      "newRecommendedItemsDescription": "Click 'Add' to keep the item or 'Cancel' to remove it.",
      "noRecommendedItemsDescription": "This activity has no available items.",
      "recommendedItemsDescription": "This activity has {{recommendedItemsCount}} available items. Select the items you want to add to this assessment.",
      "selectAll": "Select All",
      "selectFromRecommendations": "Select from Recommendations"
    },
    "ColorNames": {
      "default": "Default",
      "whiteOnBlack": "White on Black",
      "redOnWhite": "Red on White",
      "whiteOnRed": "White on Red",
      "greenOnWhite": "Green on White",
      "whiteOnGreen": "White on Green",
      "blueOnWhite": "Blue on White",
      "whiteOnBlue": "White on Blue",
      "blackOnYellow": "Black on Yellow",
      "yellowOnBlack": "Yellow on Black",
      "blackOnLightBlue": "Black on Light Blue",
      "blackOnGrey": "Black on Grey",
      "whiteOnMagenta": "White on Magenta"
    },
    "AriaLabels": {
      "scrolldown": "scroll down",
      "activityQuestionList": "Activity Question List",
      "showpreviouspage": "show previous page",
      "currentpage": "current page",
      "shownextpage": "show next page",
      "pageviewselection": "page view selection",
      "singlepagedisplay": "single page display",
      "doublepagedisplay": "double page display",
      "hearthebookreadoutloud": "hear the book read out loud",
      "addanote": "add a note",
      "addacharacterstamp": "add a character stamp",
      "addanemojistamp": "add an emoji stamp",
      "zoompage": "zoom page",
      "supportToolsUsedetailinformation": "Support Tools Use detail information",
      "timeontaskdetailinformation": "Time on task detail information",
      "alignmentPanelTab": "Alignment Panel Tab",
      "teacherFeedbackPanelTab": "Teacher Feedback Panel Tab",
      "rubricPanelTab": "Rubric Panel Tab",
      "engagementPanelTab": "Engagement Panel Tab",
      "viewkeyshortcutcommands": "view key shortcut commands",
      "previousstudentbutton": "previous student button",
      "nextstudentbutton": "next student button",
      "previousitembutton": "previous item button",
      "nextitembutton": "next item button",
      "currentlyviewing": "currently viewing",
      "scrollleft": "scroll left",
      "scrollright": "scroll right",
      "drawwithpencil": "draw with pencil",
      "drawwithpaintbrush": "draw with paint brush",
      "pencil": "pencil",
      "brush": "brush",
      "strokecolor": "stroke color",
      "fillcolor": "fill color",
      "brushsize": "brush size",
      "shape": "shape",
      "graphline": "graph line",
      "graphpoint": "graph point",
      "selectitems": "select items",
      "addtext": "add text",
      "copyelement": "copy element",
      "undo": "undo",
      "redo": "redo",
      "deleteelement": "delete element",
      "lock": "lock",
      "gotothepreviousslide": "goto the previous slide",
      "gotothenextslide": "goto the next slide",
      "closethenavigationmenu": "close the navigation menu",
      "showthenavigationmenu": "show the navigation menu",
      "categoryfinalscore": "category final score",
      "questiontype": "question type",
      "highlight": "highlight",
      "savinginprogess": "saving in progess",
      "savebutton": "save button",
      "exitbutton": "exit button",
      "closedialog": "close dialog",
      "teacherComments": "Teacher Comments",
      "scoreoverridefield": "score override field",
      "givefullcreditbutton": "give full credit button",
      "mainToolbar": "Main Toolbar",
      "studentName": "Student's Name",
      "settings": "Settings",
      "delete": "Delete",
      "download": "Download",
      "togglepreview": "Toggle preview",
      "previous": "Previous",
      "next": "Next",
      "closedCaption": "Closed Caption",
      "selectCaptionLanguage": "Select Caption Language",
      "transcript": "Transcript",
      "selectTranscriptLanguage": "Select Transcript Language",
      "pause": "Pause",
      "play": "Play",
      "volume": "Volume",
      "volumeControl": "Volume Control"
    },
    "DetailDialog": {
      "headerLabel": "Item Info",
      "questionStemLabel": "Question Stem",
      "standardsLabel": "Standards",
      "noStandardsLabel": "No standards",
      "tagsLabel": "Tags",
      "noTagsLabel": "No tags",
      "systemInformationLabel": "System Information",
      "itemTypeLabel": "Item Type",
      "guidLabel": "GUID",
      "itemIdLabel": "Item ID"
    },
    "UsageDialog": {
      "closeButtonLabel": "Close",
      "headerLabel": "Item Usage",
      "noResultsMessage": "This item has not been used by other users.",
      "modalDescriptionText": "This item has been used by the following users:",
    },
    "PrintPreview": {
      "copyright": "∙ © {{currentYear}}",
      "address": "∙ Beachwood, Ohio {{dateString}}",
      "publisher": "Publisher Name"
    },
    "DuplicateDialog": {
      "headerLabel": "Warning",
      "body": "Duplicate items or activities that contain duplicates cannot be added and will remain in the selection list.",
    },
    "ResourceItemFilter": {
      "standardsTab": "Standards",
      "tagsTab": "Tags",
      "itemBankFilterLabel": "Item Bank *",
      "itemBankFilterDescription": "First select an Item Bank. Then select options below to filter by Standards and Tags.",
      "cmapDescription": ""
    }

  }
// COPY END don't copy export to translation override files.
export default translations;
