import { Behavior } from './Behavior';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import UtilsService from '../services/UtilsService';

export class MultichoiceBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    // The answer is valid if any of the answers are selected
    return responseAnswer.answers.some(({ isCorrect }) => isCorrect);
  }

  setCorrectAnswer = (state, model) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(model.validation));
  }

  resetStudentAnswer = (state) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  getScore = (responseAnswer, model) => {
    // If the option marked with isCorrect matches the selected answer, the score is the maxScore. Otherwise it is 0.
    const studentAnswers = responseAnswer.answers;
    const correctAnswers = model.validation?.answers;
    if (correctAnswers && correctAnswers.length > 0) {
      const correctAnswer = correctAnswers.find(({ isCorrect }) => isCorrect);
      const studentAnswer = studentAnswers.find(({ isCorrect }) => isCorrect);
      let correct = false;
      if (correctAnswer && studentAnswer) {
        correct = correctAnswer.id === studentAnswer.id;
      }
      return correct ? model.maxScore : 0;
    }
    return 0;
  }

  setResponseData = (data, responseAnswer, model) => {
    const { action, id } = data;
    if (action === 'select') {
      // data is an id of one of the answers. We want to toggle the isCorrect property to true on that answer and to false on all others
      for (const answer of responseAnswer.answers) {
        answer.isCorrect = answer.id === id;
        if (answer.id === id) {
          answer.isEliminated = false;
        }
      }
    } else if (action === 'eliminate') {
      // We want to toggle isEliminated in the response answer
      for (const answer of responseAnswer.answers) {
        if (answer.id === id) {
          answer.isEliminated = !answer.isEliminated;
        }
      }
    }
  }

  getInitializedResponse = (lessonElementId, model) => {
    return {
      answers: model.answers.map(({ id }) => ({ id, isCorrect: false, isEliminated: false })),
      // extra field for ui control should probably be added to every
      // possible answer in a response model.
      isScoredCorrect: false,
      isScored: false,
      lessonElementId
    };
  }

  isAutoScored = (model) => {
    return (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
  }

  getAnswerFeedback = (id, responseAnswer) => {
    throw new Error('Behavior.getAnswerFeedback must be implemented');
  }

  getCorrectAnswersText = (model) => {
    // // Finds the correct answer id,
    // // finds the answer text for that id, and returns that text
    // if (model.validation?.answers?.length > 0) {
    //   const correctAnswer = model.validation.answers.find(({ isCorrect }) => isCorrect);
    //   if (!correctAnswer) {
    //     return '<div></div>';
    //   }
    //   const correctAnswerText = model.answers.find(({ id }) => id === correctAnswer.id).text;
    //   return `<div>${correctAnswerText}</div>`;
    // }
    // return '<div></div>';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody } `;
      }

      textBody = `${UtilsService.partiallyCorrectPrompt()}. <br/>${ custom }${textBody}`;
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (_answerId, lessonElementState, _model) => {
    // For multiple choice there is only one question so we don't have an array of answers so just return the
    // flag for the only answer
    // eslint-disable-next-line eqeqeq
    return lessonElementState.isScored && (lessonElementState.scoreValue == lessonElementState.maxScore);
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new MultichoiceBehavior();
