import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../css/BuilderLayout.scss';
import ChildItemBankCard from './ChildItemBankCard';
import DeleteGroupFromAssessmentButton from './DeleteGroupFromAssessmentButton';

const GroupChildElementItemList = observer((props) => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  const { items, groupId } = props;

  const itemIds = items.map((obj) => obj.id);

  return (
    <div>

      <DeleteGroupFromAssessmentButton key={`${groupId}_delb`} itemIds={itemIds} setErrorMessage={props.setErrorMessage} />

      {
        (!itemBankManager.contentItemsLoading && items && items.length > 0) &&
        items.map((item, index) => {
          item.isInLesson = true;
          return (
            <div key={`${item.id}_ibCL`} className='item-bank-card-controller lesson-list'>
              <div key={`${item.id}_ibn`} className='item-list-number'>{`${item.childIndex}`}</div>
              <ChildItemBankCard key={`${item.id}_ic`} libraryCardParams={item} skipRemove={true} />
            </div>
          );
        })
      }
    </div>
  );
});

export default GroupChildElementItemList;
