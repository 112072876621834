import { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/WordAssociation.scss';

import questionService from '../../services/QuestionService';
import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';

import HtmlComponent from '../HtmlComponent';

import RichTextEditor from '../RichTextEditor';
import useStyleEvents from '../../../hooks/useStyleEvents';
import { register } from '../../../i18n';

const t = register('GlobalQuestionLabels');

const WordAssociation = observer(({ lessonElementId }) => {
  // eslint-disable-next-line no-empty-pattern
  const { /* managers */ } = useContext(MobXProviderContext);

  const {
    lessonElementState,
    model,
    readOnly: isReadOnly,
    toolbar,
    uiState
  } = questionService.initQuestionComponent({
    includeUiFeedbackState: true,
    includeUserInputMap: false,
    lessonElementId
  });

  useStyleEvents(lessonElementId);

  const showAnswerFeedback = uiState?.showAnswerFeedback || false;
  const isDisabled = isReadOnly || showAnswerFeedback;

  const renderWordAssociationBox = ({ index, prompt } = {}) => {
    const keyId = `${model.sourceId}_${prompt.id}`;

    const hasSample = !!prompt.sample && !!utilsService.stripTagsAndEntities(prompt.sample);

    return (
      <div key={keyId}
        className={classNames('word-association-box', prompt.id)}>
        <div className='word-association-box-label'>
          <HtmlComponent htmlStr={prompt.label} />
        </div>
        <div className='word-association-box-response-wrapper'>
          {hasSample && (
            <div className='word-association-box-prompt-sample'>
              <div className='sample-label'>{t('example')}:</div>
              <HtmlComponent htmlStr={prompt.sample} />
            </div>
          )}
          {renderWordAssociationBoxInput({ index, prompt })}
        </div>
      </div>
    );
  };

  const renderWordAssociationBoxInput = ({ index, prompt } = {}) => {
    return (
      <div className='word-association-box-prompt-input'>
        <RichTextEditor
          data={prompt.text}
          disabled={isDisabled}
          lessonElementId={lessonElementId}
          onChange={(data) => responseService.responseChangeHandler({ text: data, index, prompt }, lessonElementId)}
          toolbar={toolbar} />
      </div>
    );
  };

  return (
    <>
      {!!model.directions && (
        <div className={classNames('directions word-association')}>
          <HtmlComponent htmlStr={model.directions} />
        </div>
      )}
      <div className='word-association-boxes'>
        {lessonElementState?.currentResponseAnswer?.prompts?.map((prompt, index) => {
          return renderWordAssociationBox({ index, prompt });
        })}
      </div>
    </>
  );
});

export default WordAssociation;
