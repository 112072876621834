import CryptoMD5 from 'crypto-js/md5';
import { toJS } from 'mobx';
import parse from 'html-react-parser';
import Auth from '../../lessonPlayer/services/AuthService';
import itemBankManager from '../managers/ItemBankManager';
import ItemBankItem from '../managers/observableObjects/ItemBankItem';
import lessonManager from '../../lessonPlayer/managers/LessonManager';
import lessonService from '../../lessonPlayer/services/LessonService';
import UtilsService from '../../lessonPlayer/services/UtilsService';
import { confirmDialog } from '../../lessonPlayer/components/dialogs';

export class ItemBankService {
  searchContentItemsForItemBank = async (
    id = null,
    searchQuestionTypes,
    page = 0,
    pageSize = 25,
    sortColumn = 'modificationDate',
    sortDirection = 'desc') => {
    try {
      itemBankManager.setContentItemsLoading(true);
      itemBankManager.clearSearchResults();
      // const apiUrlParams = `?pageSize=${pageSize}`;
      page = itemBankManager.activePage;
      const skip = !pageSize ? 0 : (page ? page - 1 : 0) * pageSize;
      // apiUrlParams += `&skip=${skip}`;
      // if (sortColumn) {
      //  apiUrlParams += `&sort[0][field]=${sortColumn}`;
      //  apiUrlParams += `&sort[0][dir]=${sortDirection}`;
      // }

      const questionTypeCriteria = (itemBankManager.searchQuestionTypes && itemBankManager.searchQuestionTypes.length > 0) ? itemBankManager.searchQuestionTypes.join(',') : '';
      const tagCriteria = (itemBankManager.searchTags && itemBankManager.searchTags.length > 0) ? itemBankManager.searchTags.join(',') : '';
      const searchTermCriteria = (itemBankManager.searchTerms && itemBankManager.searchTerms.length > 0) ? itemBankManager.searchTerms : '';
      const searchStandardsCriteria = (itemBankManager.searchStandards && itemBankManager.searchStandards.length > 0) ? itemBankManager.searchStandards.join(',') : '';
      const tagSearchOperatorCriteria = (itemBankManager.tagSearchOperator && itemBankManager.tagSearchOperator.length > 0) ? itemBankManager.tagSearchOperator : 'AND';
      const standardSearchOperatorCriteria = (itemBankManager.standardSearchOperator && itemBankManager.standardSearchOperator.length > 0) ? itemBankManager.standardSearchOperator : 'OR';
      const searchItemBankIdCriteria = (itemBankManager.searchItemBankId) ? itemBankManager.searchItemBankId : '';

      const response = await Auth.fetch(`${Auth.ecms}/api/searchContentItemsByItemBank`, {
        method: 'POST',
        body: {
          workspaceId: searchItemBankIdCriteria,
          searchTerms: searchTermCriteria,
          searchTags: tagCriteria,
          searchStandards: searchStandardsCriteria,
          searchTypes: 'item_resource',
          searchSubTypes: questionTypeCriteria,
          schools: '',
          tagSearchCondition: tagSearchOperatorCriteria,
          standardSearchCondition: standardSearchOperatorCriteria,
          searchScope: 'LICENSE',
          includeBlankCard: false,
          take: 25,
          skip,
          page,
          pageSize: 25
        }
      });
      if (response && response.status === 'SUCCESS') {
        const { contentItems } = response;
        for (const contentItem of contentItems) {
          const item = {
            id: contentItem.id,
            backgroundImg: contentItem.fullImageUrl || contentItem.imageUrl,
            cardClassName: 'item-bank',
            libraryResource: contentItem
          };
          itemBankManager.addItemBankItem(item);
        }

        itemBankManager.setTotalPages(Math.ceil(+response.pageTotal / pageSize));
        itemBankManager.setTotalResults(response.pageTotal);
        itemBankManager.setActivePage(page);
        itemBankManager.setContentItemsLoading(false);

        let toNumber = page * pageSize;
        const fromNumber = toNumber - (pageSize - 1);

        toNumber = (page === itemBankManager.totalPages) ? response.pageTotal : toNumber;
        const label = `${fromNumber} - ${toNumber} of ${response.pageTotal}`;
        itemBankManager.setResultsLabel(label);
      }
      itemBankManager.setContentItemsLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      itemBankManager.setContentItemsLoading(false);
      return false;
    }
  }

  fetchLessonProfiles = async () => {
    try {
      if (itemBankManager.lessonProfiles.length > 0) {
        return false;
      }
      const url = `${Auth.ecms}/api/viewLessonOptionsProfilesForSatellite`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        itemBankManager.loadProfiles(response.data);
      }
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  fetchLessonProfilesForPublisher = async () => {
    try {
      if (itemBankManager.lessonProfiles.length > 0) {
        return false;
      }
      const url = `${Auth.ecms}/api/viewLessonOptionsProfiles`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        itemBankManager.loadProfiles(response.data);
      }
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  fetchAvailableCategoriesWithTags = async (entityTypeIds) => {
    try {
      // This does a lot. It's cached, no need to do it again.
      if (itemBankManager.categoriesWithTags.size > 0) {
        return false;
      }
      if (!entityTypeIds) {
        entityTypeIds = 'article_resource,audio_resource,bookmark_resource,mathia_resource,google_doc_resource,google_sheet_resource,google_slide_resource,google_resource,file_resource,pdf_resource,item_resource,image_resource,video_resource,assessment,learnosity_item_resource,learnosity_activity_resource,lesson,lesson_plan,course_resource,curriculum_map,rubric';
      }
      const url = `${Auth.ecms}/api/getAvailableCategoriesAndTags?entityTypeIds=${entityTypeIds}`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        itemBankManager.loadCategoriesWithTags(response.data);
      }
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  fetchItemBankList = async (fetchAll = false) => {
    if (itemBankManager.itemBanks.length > 0) {
      return false;
    }
    try {
      let apiUrl = `${Auth.ecms}/api/viewMyLicensedWorkspaces`;
      if (fetchAll) {
        apiUrl += '?pageSize=9999';
      } else {
        apiUrl += '?take=25&skip=0&page=1&pageSize=25';
      }
      const response = await Auth.fetch(apiUrl, {
        method: 'GET',
      });
      if (response.status === 'SUCCESS') {
        if (response.data && response.data.length > 0) {
          itemBankManager.setItemBanks(response.data);
        }
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  removeItemFromLessonService = async (itemId, reload = true) => {
    itemBankManager.setContentItemsLoading(true);
    const itembankItem = itemBankManager.getItemBankItemFromLessonBucket(itemId);
    if (!itembankItem || itembankItem == 'undefined') {
      itemBankManager.setContentItemsLoading(false);
      itemBankManager.setLoading(false);
      return;
    }
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/deleteBuilderLessonElement`, {
        method: 'POST',
        body: {
          elementId: itembankItem.lessonElementId,
          lessonContentItemId: lessonManager.lessonContentItem.id,
          reactErrorType: true
        }
      });
      let statusMessage = '';
      if (response.status === 'SUCCESS') {
        if (response.removedElementIds) {
          for (const contentId of response.removedElementIds) {
            this.removeItemFromLesson(contentId);
            itembankItem.isInLesson = false;
          }
        }

        if (reload) {
          lessonService.fetchLessonContentItemBuilding(lessonManager.lessonContentItem.id);
        }

        itemBankManager.setContentItemsLoading(false);
        statusMessage = response.status;
      } else {
        statusMessage = response.statusMessage ? response.statusMessage : null;
      }
      itemBankManager.setContentItemsLoading(false);
      return statusMessage;
    } catch (e) {
      console.error(e);
      itemBankManager.setContentItemsLoading(false);
      return null;
    }
  }

  removeItemsFromLessonService = async (itemIds) => {
    for (const id of itemIds) {
      await this.removeItemFromLessonService(id, false);
    }

    lessonService.fetchLessonContentItemBuilding(lessonManager.lessonContentItem.id);
  }

  fetchLessonBankItems = async (contentItemId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewBuilderResourceStoreByEntity?contentItemId=${contentItemId}&includeTags=true&includeFirstStem=true&searchTypes=test_item,item_resource`, {
        method: 'GET',
      });
      itemBankManager.setLoading(true);
      if (response.status === 'SUCCESS') {
        if (response.contentItems && response.contentItems.length > 0) {
          response.contentItems.forEach((item) => {
            const newItem = new ItemBankItem();
            newItem.initItem(item);
            itemBankManager.addResourceItemBankItem(newItem);
          });
        }
      }
      itemBankManager.setLoading(false);
    } catch (e) {
      itemBankManager.setLoading(false);
      console.error(e);
      return null;
    }
  }

  moveItemToIndex = async (itemId, index) => {
    itemBankManager.clearActivityCardExpanded();
    itemBankManager.setContentItemsLoading(true);
    const itemBankItem = itemBankManager.getItemBankItemFromLessonBucket(itemId);
    if (itemBankItem) {
      try {
        const payload = {
          lessonContentItemId: lessonManager.lessonContentItem.id,
          elementId: itemBankItem.lessonElementId,
          parentElementId: lessonManager.firstSection,
          orderNum: index,
        };
        const response = await Auth.fetch(`${Auth.ecms}/api/moveLessonElement`, {
          method: 'POST',
          body: payload
        });
        if (response && response.status === 'SUCCESS') {
          await lessonService.fetchLessonContentItemBuilding(lessonManager.lessonContentItem.id);
        }
        itemBankManager.setContentItemsLoading(false);
        return true;
      } catch (e) {
        console.error(e);
        itemBankManager.setContentItemsLoading(false);
        return false;
      }
    }
  }

  moveItemDown = async (itemId) => {
    itemBankManager.setContentItemsLoading(true);
    const itemBankItem = itemBankManager.getItemBankItemFromLessonBucket(itemId);
    const newOrderNum = itemBankItem.orderNum + 1;
    if (itemBankItem && (itemBankItem.orderNum < (itemBankManager.lessonItems.size - 1))) {
      await this.moveItemToIndex(itemId, newOrderNum);
    }
  }

  moveItemUp = async (itemId) => {
    const itemBankItem = itemBankManager.getItemBankItemFromLessonBucket(itemId);
    const newOrderNum = itemBankItem.orderNum - 1;
    if (itemBankItem && itemBankItem.orderNum > 0) {
      await this.moveItemToIndex(itemId, newOrderNum);
    }
  }

  addItemToLesson = async (itemId, orderNumber = null, fromDrag = false) => {
    itemBankManager.clearActivityCardExpanded();
    const itemBankItem = itemBankManager.getItemBankItemFromResourceBucket(itemId);
    const entityTypeId = (itemBankItem.libraryResource.entityTypeId === 'test_item') ? 'TestItem' : 'ItemResource';

    itemBankManager.setContentItemsLoading(true);
    try {
      const payload = {
        lessonContentItemId: lessonManager.lessonContentItem.id,
        elementId: null,
        parentElementId: lessonManager.firstSection,
        name: null,
        entityId: itemBankItem.libraryResource.id,
        description: null,
        content: JSON.stringify([]),
        orderNum: orderNumber, // itemBankManager.lessonItems.size,
        // maxScore: '1.0',
        propagateTags: 'true'
      };
      const response = await Auth.fetch(`${Auth.ecms}/api/createBuilderLessonElementWithContent`, {
        method: 'POST',
        body: payload
      });
      if (response && response.status === 'SUCCESS') {
        if (response.lessonContents && response.lessonContents.length >= 1) {
          const contentItem = response.lessonContents[0];
          if (contentItem.structure && contentItem.structure.length > 0) {
            const elementItem = contentItem.structure[0];
            itemBankItem.setLessonElementId(elementItem.id);
            itemBankItem.setOrderNum(elementItem.orderNum);
            itemBankManager.addLessonItemBankItemFromBucket(itemBankItem, fromDrag);

            itemBankManager.setActivityParentLessonElementId(elementItem.id);
          }
        }
        itemBankManager.setContentItemsLoading(false);
        if (itemBankItem) {
          if (itemBankManager.isActivityType(itemBankItem.libraryResource.entitySubTypeId) && entityTypeId === 'ItemResource') {
            const result = await this.fetchActivityItemRecommendations(itemId);
            if (result) {
              itemBankManager.setIsNewAdd(true);
              itemBankManager.setParentRecommendedCartId(itemId);
              itemBankManager.setResourceRecommendAllSelected(true);

              // await this.createLessonElementFromItem();
              itemBankManager.clearRecommendedSelected();
              itemBankManager.clearRecommendedItems();
              itemBankManager.setResourceRecommendAllSelected(false);
            }
          }
        }
        if (response.rejectedIds && response.rejectedIds.length > 0) {
          itemBankManager.setDuplicateDialogOpen(true);
        }
      }
      return true;
    } catch (e) {
      console.error(e);
      itemBankManager.setContentItemsLoading(false);
      return false;
    }
  }

  shuffleLessonElements = async () => {
    itemBankManager.clearActivityCardExpanded();
    itemBankManager.setContentItemsLoading(true);
    try {
      const payload = {
        lessonContentItemId: lessonManager.lessonContentItem.id,
        parentElementId: lessonManager.firstSection,
      };
      const response = await Auth.fetch(`${Auth.ecms}/api/shuffleLessonElements`, {
        method: 'POST',
        body: payload
      });
      if (response && response.status === 'SUCCESS') {
        lessonService.fetchLessonContentItemBuilding(lessonManager.lessonContentItem.id);
      }
      itemBankManager.setContentItemsLoading(false);
      return true;
    } catch (e) {
      console.error(e);
      itemBankManager.setContentItemsLoading(false);
      return false;
    }
  }

  createLessonElementFromItem = async () => {
    const recommendedSelectedIds = itemBankManager.recommendedSelected;
    itemBankManager.setContentItemsLoading(true);

    // don't add duplicates
    const newAddIds = [];
    for (const existingId of recommendedSelectedIds) {
      if (!itemBankManager.lessonItemIdArray.includes(existingId)) {
        newAddIds.push(existingId);
      }
    }

    if (newAddIds.length < 1) {
      console.log('No new items to add.');
      itemBankManager.setContentItemsLoading(false);
      return false;
    }

    try {
      const payload = {
        lessonContentItemId: lessonManager.lessonContentItem.id,
        contentItemIds: newAddIds.join(','),
        parentElementId: itemBankManager.activityParentLessonElementId
      };
      const response = await Auth.fetch(`${Auth.ecms}/api/createLessonElementFromItem`, {
        method: 'POST',
        body: payload
      });
      if (response && response.status === 'SUCCESS') {
        await lessonService.fetchLessonContentItemBuilding(lessonManager.lessonContentItem.id);
        // get the lesson in here.
      }
      itemBankManager.setContentItemsLoading(false);
      return true;
    } catch (e) {
      console.error(e);
      itemBankManager.setContentItemsLoading(false);
      return false;
    }
  }

  processAddItemsToLesson = async () => {
    itemBankManager.clearActivityCardExpanded();
    itemBankManager.setContentItemsLoading(true);
    itemBankManager.setContentItemsLoadingProgress(true);
    const chunkSize = 5;
    const visibleItems = [];
    const items = itemBankManager.resourceBucketItemsArray;

    items.forEach((item, index) => {
      // things are marked showInStore foritems that we need, but aren't selectable, such as ActivityType children
      if (item.visibleInResourceBucket && item.libraryResource.showInStore) {
        visibleItems.push(item.id);
      }
    });

    itemBankManager.setProgressItemsTotal(visibleItems.length);

    let processedItemsCount = 0;
    for (let i = 0; i < visibleItems.length; i += chunkSize) {
      const chunkedBankItems = visibleItems.slice(i, i + chunkSize);
      await this.processBankItemsChunk(chunkedBankItems);
      // setProcessedItems((prev) => [...prev, ...processedChunk]);
      processedItemsCount += chunkedBankItems.length;
      itemBankManager.setProgressItemsCompleted(processedItemsCount);
    }

    await lessonService.fetchLessonContentItemBuilding(lessonManager.lessonContentItem.id);
    itemBankManager.setContentItemsLoading(false);
    itemBankManager.setContentItemsLoadingProgress(false);
    itemBankManager.setResourceRecommendAllSelected(false);
    itemBankManager.setProgressItemsTotal(0);
    itemBankManager.setProgressItemsCompleted(0);
  };

  processBankItemsChunk = async (chunkedBankItems) => {
    return new Promise((resolve) => {
      resolve(this.addItemsToLesson(chunkedBankItems));
    });
  };

  addItemsToLesson = async (chunkedBankItems) => {
    if (chunkedBankItems.length < 1) {
      return false;
    }

    const entityIds = chunkedBankItems.join(',');

    try {
      itemBankManager.setContentItemsLoading(true);

      const payload = {
        lessonContentItemId: lessonManager.lessonContentItem.id,
        elementId: null,
        parentElementId: lessonManager.firstSection,
        name: null,
        entityIds,
        description: null,
        content: JSON.stringify([]),
        orderNum: null,
        // maxScore: '1.0',
        propagateTags: 'true'
      };

      const response = await Auth.fetch(`${Auth.ecms}/api/createBuilderLessonElementWithContent`, {
        method: 'POST',
        body: payload
      });

      if (response && response.status === 'SUCCESS') {
        if (response.rejectedIds && response.rejectedIds.length > 0) {
          itemBankManager.setDuplicateDialogOpen(true);
        }
        return true;
      }
      return true;
    } catch (e) {
      console.error(e);
      itemBankManager.setProgressItemsTotal(0);
      itemBankManager.setProgressItemsCompleted(0);
      itemBankManager.setContentItemsLoading(false);
      itemBankManager.setContentItemsLoadingProgress(false);
      return false;
    }
  }

  getProfiles = async () => {
    await this.fetchLessonProfiles();
    const array = toJS(itemBankManager.lessonProfiles);
    itemBankManager.setProfiles(array);
    const option = this.findAndSetDefaultProfile(array);

    if (option) {
      itemBankManager.setSelectedProfileOption(option);
    }
  };

  getProfilesForPublisher = async (optionProfileId) => {
    await this.fetchLessonProfilesForPublisher();
    const array = toJS(itemBankManager.lessonProfiles);
    itemBankManager.setProfiles(array);
    const option = this.findAndSetDefaultProfile(array, optionProfileId);

    if (option) {
      itemBankManager.setSelectedProfileOption(option);
    }
  };

  isItemInLicensedBank = (item) => {
    if (!item.libraryResource.itemResourceInLicensedBank || item.libraryResource.entityTypeId === 'test_item') {
      return false;
    }
    return true;
  }

  removeItemFromLesson = (itemId) => {
    itemBankManager.removeItemFromLesson(itemId);
  }

  getArrayFromMap(map) {
    if (map) {
      return Array.from(toJS(map).values());
    }
    return [];
  }

  handleItemPreview = (data) => {
    itemBankManager.setOriginViewMode(itemBankManager.viewMode);
    let isPrint = false;

    if (lessonManager.lesson && lessonManager.lessonOptions) {
      if (lessonManager.lessonOptions.printOnly) {
        isPrint = lessonManager.lessonOptions.printOnly;
      }
    }

    if (data.isInLesson && !isPrint) {
      itemBankManager.setItemPreviewElementId(data.lessonElementId);
      itemBankManager.setOriginViewMode(itemBankManager.viewMode);
      this.setViewMode('preview');
    } else {
      this.setPreviewId(data.id);
      if (isPrint) {
        this.setPrintPreviewDialogOpen(true, false);
      } else {
        this.setViewMode('itemPreview');
      }
    }
  }

  handleShowItemDetails = async (item) => {
    this.setDetailDialogOpen(true);
    const results = await this.fetchStandardTagAlignmentsByContentId(item.id);
    this.setDetailDialogResults(item, results);
  }

  handleShowItemUsage = async (item) => {
    this.setUsageDialogOpen(true);
    const contentItemId = item?.id;
    const contentUsage = await this.fetchContentUsage(contentItemId);
    this.setUsageDialogResults(item, contentUsage);
  }

  handleRemoveItem = async (item) => {
    if (!this.isItemInLicensedBank(item)) {
      const result = await confirmDialog({
        title: 'Remove Item',
        text: 'This item is not from your item banks. If you choose to remove, you will no longer have access to this item.',
        showCancelButton: true,
        confirmButtonText: 'OK'
      });

      if (result.isConfirmed) {
        // await this.deleteLessonElement(item.id);
        await this.removeResourceFromLesson(item.id);
        // TODO: then get rid of the content item.
      }
    } else {
      await this.removeResourceFromLesson(item.id);
    }
  }

  handleOpenAddItems = async (item) => {
    if (item) {
      if (itemBankManager.isActivityType(item.libraryResource.entitySubTypeId)) {
        const result = await this.fetchActivityItemRecommendations(item.id);
        if (result) {
          itemBankManager.setIsNewAdd(false);
          itemBankManager.setActivityParentLessonElementId(item.lessonElementId);
          itemBankManager.toggleResourceRecommendCart(false);
        }
      }
    }
  }

  getLibraryCardOptions = (skipRemove, skipAddItems = true, skipAddPreview = false) => {
    const options = {};
    // define common options
    const preview = {
      key: 'preview',
      label: 'Preview',
      callback: this.handleItemPreview
    };
    const details = {
      key: 'details',
      label: 'Info',
      callback: this.handleShowItemDetails
    };

    const remove = {
      key: 'remove',
      label: 'Remove',
      callback: this.handleRemoveItem
    };

    const addItems = {
      key: 'addItems',
      label: 'Add Items',
      callback: this.handleOpenAddItems
    };

    if (!skipAddItems) {
      options.addItems = addItems;
    }

    const usage = {
      key: 'usage',
      label: 'Usage',
      callback: this.handleShowItemUsage
    };

    if (!skipAddPreview) {
      options.preview = preview;
    }
    options.details = details;
    options.usage = usage;

    if (!skipRemove) {
      options.remove = remove;
    }

    return options;
  }

  addResourcesToLesson = async (
    lessonContentItemId,
    contentItemIdArray // item bank id
  ) => {
    try {
      const contentItemIds = (contentItemIdArray && contentItemIdArray.length > 0) ? contentItemIdArray.join(',') : '';
      const response = await Auth.fetch(`${Auth.ecms}/api/addResourcesToEntity`, {
        method: 'POST',
        body: {
          lessonContentItemId,
          contentItemIds,
          workspaceContentItemId: null,
        }
      });
      if (response && response.status === 'SUCCESS') {
        // console.debug('addResourcesToLesson success');
        // itemBankManager.clearSelectedBankItems();
      }
      itemBankManager.setContentItemsLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      itemBankManager.setContentItemsLoading(false);
      return false;
    }
  }

  removeResourceFromLesson = async (
    contentItemId // item bank id
  ) => {
    try {
      const { entityId } = lessonManager.lessonContentItem;
      const response = await Auth.fetch(`${Auth.ecms}/api/deleteResourceFromEntity`, {
        method: 'POST',
        body: {
          entityId,
          contentItemId,
          workspaceContentItemId: null,
        }
      });
      if (response && response.status === 'SUCCESS') {
        itemBankManager.removeFromResourceStore(contentItemId);
      }
      itemBankManager.setContentItemsLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      itemBankManager.setContentItemsLoading(false);
      return false;
    }
  }

  fetchActivityItemRecommendations = async (contentItemId) => {
    let response = null;
    let succeeded = false;
    try {
      itemBankManager.setRecommendedItemsLoading(true);
      const apiUrl = `${Auth.ecms}/api/viewActivityItemRecommendations?contentItemId=${contentItemId}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      succeeded = response && response.status === 'SUCCESS';
      if (succeeded) {
        const { contentItems } = response;
        for (const contentItem of contentItems) {
          const item = {
            id: contentItem.id,
            backgroundImg: contentItem.fullImageUrl || contentItem.imageUrl,
            cardClassName: 'item-bank',
            libraryResource: contentItem
          };
          itemBankManager.addRecommendedItem(item);
        }

        itemBankManager.recommendedItems.map((item) => {
          const isInLesson = itemBankManager.isItemInLesson(item.id);
          if (isInLesson) {
            itemBankManager.addRecommendedSelected(item.id);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }

    itemBankManager.setRecommendedItemsLoading(false);
    return succeeded;
  }

  fetchLessonPlayerUrl = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const contentItemId = urlParams.get('contentItemId');
    const satelliteCode = urlParams.get('satelliteCode');

    let apiUrl = `${Auth.ecms}/api/getLessonPlayerUrls`;
    apiUrl += `?contentItemId=${contentItemId}`;
    apiUrl += `&publisherSatelliteCode=${satelliteCode}`;

    const response = await Auth.fetch(apiUrl, {
      method: 'GET'
    });
    let url = '';
    if (response) {
      url = response.teacherPreviewUrl;
    }
    url += `&authKey=${Auth.authKey}`;
    itemBankManager.setSatPreviewUrl(url);
  }

  fetchCmapList = async (workspaceId = null) => {
    let response = null;
    const succeeded = false;
    try {
      let apiUrlParams = '?includeBlankCard=false';
      apiUrlParams += '&searchTypes=curriculum_map';
      apiUrlParams += '&skip=0';
      apiUrlParams += '&pageSize=100';
      if (workspaceId) {
        apiUrlParams += `&workspaceId=${workspaceId}`;
      }
      const apiUrl = `${Auth.ecms}/api/searchLicencedItembankCmaps${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      const succeeded = response && response.status === 'SUCCESS';
      if (succeeded) {
        const items = response.contentItems;
        itemBankManager.setProductCmaps(items);
      }
    } catch (error) {
      console.error(error);
    }
    itemBankManager.setContentItemsLoading(false);
    return succeeded;
  }

  fetchFullCmap = async (cmapContentItemId) => {
    if (!cmapContentItemId || cmapContentItemId.length < 1) {
      return false;
    }

    let response = null;
    try {
      const apiUrlParams = `?cmapContentItemId=${cmapContentItemId}`;
      const apiUrl = `${Auth.ecms}${'/api/viewFullCurriculumMapJson'}${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }

    if (response && response.jsonString) {
      const jsonObj = JSON.parse(response.jsonString);
      const startObj = jsonObj.CurriculumMapElements[1];
      if (startObj) {
        const formattedJson = this.buildCmapStandardsJson(startObj.CurriculumMapElements, jsonObj.name);
        itemBankManager.setCmapStandardsJson(formattedJson);
        return true;
      }
    }
    return false;
  }

  fetchStandardTagAlignmentsByContentId = async (contentItemId) => {
    try {
      itemBankManager.setLoading(true);
      const apiUrlParams = `?contentItemId=${contentItemId}`;
      const apiUrl = `${Auth.ecms}/api/viewContentStandardsAndTags${apiUrlParams}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response && response.status === 'SUCCESS') {
        itemBankManager.setLoading(false);
        const tags = this.filterSelectedTags(response.tags);
        const results = {
          standards: response.standards,
          tags
        };
        return results;
      }
    } catch (error) {
      itemBankManager.setLoading(false);
      console.error(error);
      return false;
    }
  }

  fetchContentUsage = async (
    contentItemId,
    page,
    pageSize,
    sortColumn,
    sortDirection,
  ) => {
    try {
      if (!contentItemId) {
        return;
      }
      itemBankManager.setLoading(true);
      let apiUrl = `${Auth.ecms}/api/viewContentUsage`;
      apiUrl += `?contentItemId=${contentItemId}`;
      if (page && pageSize) {
        apiUrl += `?pageSize=${pageSize}`;
        apiUrl += `&skip=${(page - 1) * pageSize}`;
      }
      if (sortColumn) {
        apiUrl += `&sort[0][field]=${sortColumn}`;
        apiUrl += `&sort[0][dir]=${sortDirection}`;
      }
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response && response.contentUsage) {
        itemBankManager.setLoading(false);
        return response.contentUsage;
      } else {
        return [];
      }
    } catch (error) {
      itemBankManager.setLoading(false);
      console.error(error);
      return false;
    }
  }

  filterSelectedTags = (categories) => {
    const categoriesWithSelectedTags = [];
    categories.forEach((category, i) => {
      const selectedTags = [];
      // only show visible categories
      if (category.visible) {
        category.tags.forEach((tag, i) => {
          // determine if the tag is selected for this content item
          if (tag.selected) {
            selectedTags.push(tag);
          }
        });
        // only show the category if it has selected tags.
        if (selectedTags.length > 0) {
          category.tags = selectedTags;
          categoriesWithSelectedTags.push(category);
        }
      }
    });
    return categoriesWithSelectedTags;
  }

  buildCmapStandardsJson = (elemInput, cmapName) => {
    let index = 1;
    if (!elemInput || elemInput.length < 0) return [];
    return elemInput.map((obj) => {
      const cmapElementId = obj.id; // curriculumMapElement id is unique but the entity id is not so use this for the node value
      const standardId = obj.entityId || CryptoMD5(obj).toString() || null;
      let name = '';
      // get dropdown label `name` for standards AssignmentItemFilter
      if (obj.displayName || obj.name) {
        // if obj.displayName or obj.name, use that
        name = obj.displayName || obj.name;
      } else if (cmapName) {
        // else if cmapName, use that
        name = cmapName;
      } else /* if (obj.type === 'UNIT') */ {
        // else use default 'Unit' label
        // (edge case, will likely never happen)
        name = obj.unitName || 'Unit';
        name = `${name} ${index}`;
        index++;
      }
      name = this.stripHtmlTags(name);
      const showCheckbox = (obj.type === 'STANDARD');

      if (showCheckbox) {
        name = `<span class='standard-name'>${name}</span> <span class='standard-desc'>${obj.decodedDescription}</span>`;
      }

      name = UtilsService.replaceParagraphWithSpan(name);
      name = `<span class='full-name-wrapper'>${name}</span>`;
      name = parse(name);

      const cmapElement = {
        label: name,
        showCheckbox,
        standardId,
        value: cmapElementId
      };
      if (obj.CurriculumMapElements.length > 0) {
        itemBankManager.addExpanded(cmapElementId);
        cmapElement.children = this.buildCmapStandardsJson(obj.CurriculumMapElements, '');
      }
      // add a mapping by curriculumElementId so we can swap out the standard id later
      itemBankManager.addCmapStandardsMapItem(cmapElementId, cmapElement);
      return cmapElement;
    });
  }

  stripHtmlTags = (str, ...htmlTagsToKeep) => {
    if (typeof str === 'string') {
      return str.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
        return htmlTagsToKeep.includes(tag) ? `<${ endMark }${tag }>` : '';
      }).replace(/<!--.*?-->/g, '') // strip all html tags except those specified in `htmlTagsToKeep`
        .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig, '') // strip all html entities
        .trim();
    }
  }

  setViewMode = async (mode) => {
    if (mode === 'satPreview' && !itemBankManager.previewUrl) {
      await this.fetchLessonPlayerUrl();
    }
    itemBankManager.setViewMode(mode);
  }

  setPreviewId = (id) => {
    itemBankManager.setPreviewId(id);
  }

  resetSearch = async () => {
    itemBankManager.clearProductCmaps();
    itemBankManager.clearSelectedBankItems();
    itemBankManager.clearSearchResults();
    itemBankManager.resetSearchParameters();
    itemBankManager.resetPaginator();
    itemBankManager.clearSelectedCatToTags();
    itemBankManager.clearCmapStandardsJson();
    itemBankManager.setSelectedCmapOption(null);
    itemBankManager.setSearchStandardElementIds([]);
  }

  setDetailDialogOpen = (open, item, results) => {
    itemBankManager.setDetailDialogOpen(open);
  }

  setDetailDialogResults = (item, results) => {
    itemBankManager.setDetailDialogResults(item, results);
  }

  setUsageDialogOpen = (open) => {
    itemBankManager.setUsageDialogOpen(open);
    if (!open) {
      itemBankManager.setUsageDialogResults(null, null);
    }
    itemBankManager.setUsageDialogOpen(open);
  }

  setUsageDialogResults = (item, results) => {
    itemBankManager.setUsageDialogResults(item, results);
  }

  findAndSetDefaultProfile = (array) => {
    if (!itemBankManager.lessonProfileId) {
      return null;
    }
    const optionProfileId = itemBankManager.lessonProfileId;
    let foundOption = null;
    array.forEach((option, index) => {
      if (option.value === optionProfileId) {
        foundOption = option;
      }
    });
    return foundOption;
  };

  setSelectedCmapOption = async (selectedCmapOption) => {
    if (selectedCmapOption) {
      itemBankManager.setSelectedCmapOption(selectedCmapOption);
    }
  };

  setSearchStandardElementIds = async (searchStandardElementIds) => {
    if (searchStandardElementIds) {
      itemBankManager.setSearchStandardElementIds(searchStandardElementIds);
    }
  };

  setPrintPreviewDialogOpen = (open, isInLesson) => {
    itemBankManager.setPrintPreviewDialogOpen(open, isInLesson);
  };

  setDuplicateDialogOpen = (open) => {
    itemBankManager.setDuplicateDialogOpen(open);
  }
}

export default new ItemBankService();
