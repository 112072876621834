import React from 'react';
import classNames from 'classnames';

import '../../../css/CustomHtmlOption.scss';

import HtmlComponent from '../HtmlComponent';

const CustomHtmlOption = (props) => {
  const { data, innerProps, innerRef, isFocused } = props;

  innerProps.tabIndex = 0;

  innerProps.onMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  innerProps.onMouseMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  let dataLabel = data.label;
  if (dataLabel?.startsWith?.('<menclose')) {
    dataLabel = `<math>${dataLabel}</math>`;
  }
  return (
    <div ref={innerRef} className={classNames({
      'custom-html-dropdown-option': true,
      'is-focused': isFocused,
      'is-selected': props.isSelected
    })} {...innerProps}>
      <HtmlComponent htmlStr={dataLabel} />
    </div>
  );
};

export default CustomHtmlOption;
