import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/ImageTextMatchFlip.scss';
import { LessonMode } from '../../../Constants';

import { register } from '../../../i18n';

import Auth from '../../services/AuthService';

import userManager from '../../managers/UserManager';

import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';

import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';

import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const t = register('ImageTextMatchFlip');

const SourceFlipCard = observer(({ model, prompt, clickHandler, keyHandler }) => {
  const {
    responseManager,
  } = useContext(MobXProviderContext);

  const frontStyle = {};
  const backStyle = {};
  const wrapperStyle = { width: `${model.imageWidth}px`, height: `${model.imageHeight}px` };
  if (model.layout === 'manualLayout') {
    wrapperStyle.position = 'absolute';
    wrapperStyle.top = `${prompt.top}px`;
    wrapperStyle.left = `${prompt.left}px`;
  }

  let showLabel = false;
  let showHtml = false;
  let className = 'image';

  const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;

  let answer = null;

  if (currentResponseAnswer) {
    answer = currentResponseAnswer.answers.find(({ id }) => prompt.id === id);
  }

  if (model.frontImageResourceId) {
    frontStyle.backgroundImage = `url(${Auth.getResourceUrlByResourceId(model.frontImageResourceId)})`;
  }

  if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'image') {
    backStyle.backgroundImage = `url(${prompt.imageUrl})`;
  }

  if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'htmlText') {
    showLabel = true;
  }

  if (model.sourceDisplay === 'html' || model.sourceDisplay === 'htmlText') {
    showHtml = true;
    className = 'text';
  }

  let sourceFlipCardAriaLabel = '';
  if (answer?.selected || answer?.locked) {
    if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'image') {
      sourceFlipCardAriaLabel = prompt?.sourceLabelAria || '';
    } else if (model.sourceDisplay === 'htmlText' || model.sourceDisplay === 'html') {
      const htmlTextAriaLabel = prompt?.sourceLabelAria || utilsService.stripHtmlTagsAdvanced(prompt?.sourceHtml || prompt?.sourceText || '');
      sourceFlipCardAriaLabel = htmlTextAriaLabel;
    }
  }

  return (
    <>
      {!!answer && (
        <div aria-checked={(answer.selected || answer.locked)}
          aria-label={sourceFlipCardAriaLabel || t('flippableCard')}
          className={classNames('flip-container', { selected: (answer.selected || answer.locked) })}
          onClick={(e) => clickHandler(e, 'prompt', prompt.id)} onKeyUp={(e) => keyHandler(e, 'prompt', prompt.id)}
          role='checkbox'
          style={wrapperStyle}
          tabIndex={0}>
          <div className={classNames('flipper', 'source', className)}>
            <div className='front' style={frontStyle} />
            <div className='back' style={backStyle}>
              {showHtml && <HtmlComponent htmlStr={prompt.sourceHtml} />}
              {showLabel && <div className='promptText'>{prompt.sourceLabel}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

const TargetFlipCard = observer(({ model, target, clickHandler, keyHandler }) => {
  const {
    responseManager,
  } = useContext(MobXProviderContext);

  const frontStyle = {};
  const backStyle = {};
  const wrapperStyle = { width: `${model.imageWidth}px`, height: `${model.imageHeight}px` };
  const className = 'image';

  if (model.layout === 'manualLayout') {
    wrapperStyle.zIndex = 3;
    wrapperStyle.position = 'absolute';
    wrapperStyle.top = `${target.top}px`;
    wrapperStyle.left = `${target.left}px`;
  }

  const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;

  let answer = null;

  if (currentResponseAnswer) {
    answer = currentResponseAnswer.answers.find(({ id }) => target.text === id);
  }

  if (model.frontImageResourceId) {
    frontStyle.backgroundImage = `url(${Auth.getResourceUrlByResourceId(model.frontImageResourceId)})`;
  }

  if (target.targetImageUrl && target.targetImageUrl.indexOf('redirectToStream') > -1) {
    const id = Auth.getEntityIdFromStreamUrl(target.targetImageUrl);
    backStyle.backgroundImage = `url(${Auth.getResourceUrlByResourceId(id)}&entityTypeId=image-resource)`;
  } else {
    backStyle.backgroundImage = `url(${target.targetImageUrl})`;
  }

  const targetFlipCardAriaLabel = (answer.selected || answer.locked) ? target?.text || '' : t('flippableCard');

  return (
    <>
      {!!answer && (
        <div aria-checked={(answer.selected || answer.locked)}
          aria-label={targetFlipCardAriaLabel}
          className={classNames('flip-container', { selected: (answer.selected || answer.locked) })}
          onClick={(e) => clickHandler(e, 'target', target.text)}
          onKeyUp={(e) => keyHandler(e, 'target', target.text)}
          role='checkbox' style={wrapperStyle} tabIndex={0}>
          <div className={classNames('flipper', 'source', className)}>
            <div className='front' style={frontStyle} />
            <div className='back' style={backStyle}>
              <div className='promptText'>{target.text}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

const ImageTextMatchFlip = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  const readOnly = (lessonElementState && lessonElementState.readOnly) || lessonManager.isReadOnly;

  const processedAnswers = (!lessonElementState) ? [] : utilsService.shuffleArrayForUser([...model.targets], userManager.userId);

  // Create handlers for state change
  const handleClick = (_event, type, id) => {
    if (readOnly) {
      return;
    }
    responseService.responseChangeHandler({ id, type }, lessonElementId);
  };

  const handleKeyUp = (event, type, id) => {
    event.stopPropagation();
    if (event.key === ' ' || event.key === 'Enter') {
      handleClick(event, type, id);
    }
  };

  const SourceColumns = () => {
    return (
      <div className='source-column'>
        {
          model.prompts.map((prompt, index) => {
            return (
              <SourceFlipCard key={index} clickHandler={handleClick} keyHandler={handleKeyUp} model={model}
                prompt={prompt} />
            );
          })
        }
      </div>
    );
  };
  const TargetColumns = () => {
    return (
      <div className='target-column'>
        {
          processedAnswers.map((target, index) => {
            return (
              <TargetFlipCard key={index} clickHandler={handleClick} keyHandler={handleKeyUp} model={model}
                target={target} />
            );
          })
        }
      </div>
    );
  };

  const ManualLayout = () => {
    return (
      <>
        {
          model.prompts.map((prompt, index) => {
            return (
              <SourceFlipCard key={index} clickHandler={handleClick} keyHandler={handleKeyUp} model={model}
                prompt={prompt} />
            );
          })
        }
        {
          processedAnswers.map((target, index) => {
            return (
              <TargetFlipCard key={index} clickHandler={handleClick} keyHandler={handleKeyUp} model={model}
                target={target} />
            );
          })
        }
      </>
    );
  };

  useStyleEvents(lessonElementId);

  return (
    <div className='flip-select-question'>

      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div aria-labelledby={`test-item-question-${lessonElementId}`} className='test-item-answers'>
        <div className='card-container'>
          <div className={`${model.layout}`}>
            {
              (model.layout === 'columnLayout') && (
                <>
                  <SourceColumns />
                  <TargetColumns />
                </>
              )
            }
            {(model.layout === 'rowLayout') && (
              <>
                <TargetColumns />
                <SourceColumns />
              </>
            )}
            {
              (model.layout === 'manualLayout') && (
                <>
                  <ManualLayout />
                </>
              )
            }

          </div>
        </div>
      </div>
    </div>
  );
});
export default ImageTextMatchFlip;
