import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../css/BuilderLayout.scss';
import '../../css/LibraryItemBankCard.scss';
import ChildItemBankCard from './ChildItemBankCard';
import GroupChildElementItemList from './GroupChildElementItemList';
import DeleteItemFromAssessmentButton from './DeleteItemFromAssessmentButton';

const ChildElementItemList = observer((props) => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  const { childElementIds } = props;
  const items = itemBankManager.getLessonItemsArrayForIds(childElementIds);

  const masterItemList = [];
  const childMap = new Map();

  let childIndex = 1;
  for (const item of items) {
    item.childIndex = childIndex;
    const group = itemBankManager.activityGroupsMap.get(item.id);

    if (group) {
      const valArray = childMap.get(group.parentItemId) || [];
      valArray.push(item);
      childMap.set(group.parentItemId, valArray);
    } else {
      childMap.set(item.id, item);
    }
    ++childIndex;
  }

  for (const [key, value] of childMap) {
    masterItemList.push(value);
  }

  return (
    <div className='assessment-items child-items'>
      {
        (!itemBankManager.contentItemsLoading && masterItemList && masterItemList.length > 0) &&
        masterItemList.map((masterItem, index) => {
          if (Array.isArray(masterItem)) {
            masterItem.isInLesson = true;
            return (
              <div key={`${masterItem.id}_ibCL`} className='item-bank-card-controller lesson-list group-list'>
                <GroupChildElementItemList items={masterItem} />
              </div>
            );
          } else {
            masterItem.isInLesson = true;
            return (
              <div key={`${masterItem.id}_ibCL`} className='item-bank-card-controller lesson-list'>
                <div key={`${masterItem.id}_ibn`} className='item-list-number'>{`${masterItem.childIndex}`}</div>
                <ChildItemBankCard key={`${masterItem.id}_ic`} libraryCardParams={masterItem} skipRemove={true} />
                {(masterItem.libraryResource.deleteAllowed) &&
                <DeleteItemFromAssessmentButton key={`${masterItem.id}_delb`} itemId={masterItem.id} setErrorMessage={props.setErrorMessage} />}
              </div>
            );
          }
        })
      }
    </div>
  );
});

export default ChildElementItemList;
