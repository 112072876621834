import React from 'react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import dragDropService from './DragDropService';
import playerService from './PlayerService';
import styleService from './StyleService';
import utilsService from './UtilsService';

import HtmlComponent from '../components/HtmlComponent';

/** @deprecated */
export default class DeprecatedDragDropService {
  // TODO unused - remove
  /** @deprecated note: we are phasing out `react-beautiful-dnd` in favor of `@dnd-kit` */
  static renderDragDropAnswerBankSection_reactBeautifulDnd = ({
    CONTENT_TYPE_OBJ,
    answerBankBackgroundUrl,
    dragEndResult,
    getAbsoluteDragDropResponseItemStyles,
    isCustomAnswerBankStyling,
    lessonElementId,
    lessonElementState,
    model,
    responseItemAnswerBankOptions
  } = {}) => {
    const contentClassName = kebabCase(CONTENT_TYPE_OBJ.type);

    const { getStyleVar } = styleService;
    const { stripWrappingParagraphTags } = utilsService;

    const responseItems = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || []);

    return (
      <div className={`${contentClassName}-question-section-footer`}>
        {!!model.answerBankHeading && (
          <div className={`${contentClassName}-section-footer-title`}>
            <HtmlComponent htmlStr={model.answerBankHeading} />
          </div>
        )}
        <div className={`${contentClassName}-options-answer-bank-wrapper`}
          style={{
            height: isCustomAnswerBankStyling && model.answerLayoutHeight ?
              `${model.answerLayoutHeight }px` : 'auto'
          }}>
          <Droppable direction='horizontal' droppableId='drag-drop-options-answer-bank'>
            {(droppableProvided, droppableSnapshot) => {
              // TODO remove // const uiState = questionFeedbackManager.getUiState?.(lessonElementId);
              // TODO remove // const isDragDisabled = !uiState?.submitButtonVisible || false;
              const isDragDisabled = playerService.isReadOnly(lessonElementId);

              // TODO remove // const isAnswerBankCustomWidthAndNotDraggingResponseItem = isCustomAnswerBankStyling && model.answerLayoutWidth && !snapshot.isUsingPlaceholder;
              // TODO remove // const isAnswerBankCustomWidthAndDraggingResponseItem = isCustomAnswerBankStyling && model.answerLayoutWidth && snapshot.isUsingPlaceholder;

              return (
                <div ref={droppableProvided.innerRef}
                  className={classNames(`${contentClassName}-options-answer-bank`, {
                    [`${contentClassName}-options-answer-bank-default`]: !isCustomAnswerBankStyling,
                    [`${contentClassName}-options-answer-bank-custom`]: isCustomAnswerBankStyling
                  })}
                  {...droppableProvided.droppableProps}
                  style={{
                    background: !answerBankBackgroundUrl ? getStyleVar('--theme-drag-drop-dragover-color') : `url(${answerBankBackgroundUrl})`,
                    height: isCustomAnswerBankStyling && model.answerLayoutHeight ?
                      `${model.answerLayoutHeight }px` : 'auto',
                    width: isCustomAnswerBankStyling ? `${model.answerLayoutWidth }px` : 'auto',
                    ...(!isCustomAnswerBankStyling ? {
                      overflow: droppableSnapshot.isUsingPlaceholder ? 'hidden' : 'auto',
                      padding: getStyleVar('--theme-drag-drop-options-answer-bank-padding')
                    } : {})
                  }}>
                  {responseItemAnswerBankOptions?.map((responseItem, _index) => {
                    const modelResponseItemIndex = responseItems?.findIndex((modelResponseItem) => {
                      return modelResponseItem.id && modelResponseItem.id === responseItem.id;
                    });
                    const draggableResponseItemId = responseItem.id;
                    const sourceDroppableId = dragEndResult?.source?.droppableId;
                    const destinationDroppableId = dragEndResult?.destination?.droppableId;

                    const occupiedByPrompt = dragDropService.isOccupiedByPrompt(responseItem, destinationDroppableId);

                    const oldDroppedResponseItem = lessonElementState?.currentResponseAnswer?.userInputMap?.get(destinationDroppableId);
                    const oldDroppedResponseItemId = oldDroppedResponseItem?.id;

                    const isOldDroppedResponseItem = !!oldDroppedResponseItemId && destinationDroppableId === oldDroppedResponseItemId;

                    const isSourceAnswerBank = sourceDroppableId === 'drag-drop-options-answer-bank';
                    const isDestinationAnswerBank = destinationDroppableId === 'drag-drop-options-answer-bank';

                    const isSameSourceAndDestinationPrompt = !isSourceAnswerBank && !isDestinationAnswerBank && sourceDroppableId === destinationDroppableId
                      && oldDroppedResponseItemId && oldDroppedResponseItemId === responseItem.id;

                    const hiddenFromAnswerBank = !!(!model.answersPersist && !isOldDroppedResponseItem && occupiedByPrompt)
                      || isSameSourceAndDestinationPrompt;

                    return !hiddenFromAnswerBank && (
                      <Draggable key={draggableResponseItemId} draggableId={draggableResponseItemId} index={modelResponseItemIndex}
                        isDragDisabled={isDragDisabled}>
                        {(draggableProvided, draggableSnapshot) => {
                          const { style } = draggableProvided.draggableProps;
                          return (
                            <div
                              ref={draggableProvided.innerRef}
                              className={classNames(`${contentClassName}-option`, {
                                [`${contentClassName}-option-custom`]: isCustomAnswerBankStyling,
                                [`${contentClassName}-option-default`]: !isCustomAnswerBankStyling,
                                [`${contentClassName}-option-dragging`]: draggableSnapshot.isDragging,
                                [`${contentClassName}-option-no-transform`]: droppableSnapshot.isDraggingOver && !draggableSnapshot.isDragging
                              })}
                              {...draggableProvided.draggableProps}
                              style={{
                                ...style,
                                ...(!draggableSnapshot.isDropAnimating ? {} : { transitionDuration: '0.001s' }),
                                ...(!isCustomAnswerBankStyling ? {
                                  alignItems: 'center'
                                } : {
                                  ...getAbsoluteDragDropResponseItemStyles(responseItem),
                                }),
                                ...(draggableSnapshot.isDragging ? { maxWidth: '250px' } : {}),
                                maxWidth: '250px'
                              }}
                              {...draggableProvided.dragHandleProps}>
                              <HtmlComponent
                                htmlStr={stripWrappingParagraphTags(responseItem.text)} useSpan />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  }).filter((jsx) => jsx)}
                  <span className='custom-dnd-placeholder'>
                    {droppableProvided.placeholder}
                  </span>
                </div>
              );
            }}
          </Droppable>
        </div>
      </div>
    );
  }
}
