import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import SummaryInfo from './SummaryInfo';
import EngagementInfo from './EngagementInfo';

import { LessonMode } from '../../../Constants';
import SummarySection from './SummarySection';
import EngagementSummaryPage from './EngagementSummaryPage';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import '../../../css/SummaryPage.scss';
import { register } from '../../../i18n';

const t = register('PanelMenu');

const SummaryPage = observer(() => {
  const {
    lessonManager,
    questionFeedbackManager,
    userManager
  } = useContext(MobXProviderContext);

  const mode = lessonManager.playerMode;
  const { isBetaUser } = userManager;

  const [panelVisible, setPanelVisible] = useState(0);
  const panelZero = useRef();
  const panelOne = useRef();

  useAccessibilityClick(panelZero, () => {
    setPanelVisible(0);
  });

  useAccessibilityClick(panelOne, () => {
    setPanelVisible(1);
  });

  const { practiceAttempts } = questionFeedbackManager.questionFeedbackMode;
  const { isPractice } = questionFeedbackManager;

  return (
    <div className='summary-page-wrapper'>
      <div className='summary-left-column'>
        <div className='summary-info-wrapper' style={{ display: (panelVisible === 0) ? 'block' : 'none' }}>
          <SummaryInfo />
        </div>
        <div className='summary-info-wrapper' style={{ display: (panelVisible === 1) ? 'block' : 'none' }}>
          <EngagementInfo />
        </div>
      </div>
      <div className='summary-right-column'>
        <div className='summary-tabs-wrapper'>
          {(mode === LessonMode.REVIEW && isBetaUser) && (
            <button
              ref={panelZero}
              className={classNames({ 'lesson-summary-tab': true, 'left': true, 'selected': (panelVisible === 0) })}>{t('activity')}
            </button>
          )}
          {(mode === LessonMode.REVIEW && isBetaUser) && (
            <button
              ref={panelOne}
              className={classNames({ 'lesson-summary-tab': true, 'right': true, 'selected': (panelVisible === 1) })}>{t('engagementPanel')}
            </button>
          )}
        </div>
        <div className='summary-section-list' id='sectionSummaryPanel'
          style={{ display: (panelVisible === 0) ? 'block' : 'none' }}>
          <div className='summary-section-message-wrapper'>
            {(isPractice && practiceAttempts > 0) &&
              <span className='summary-section-scoring-message'>
                {t('summaryScoringMessage')}
              </span>
            }
          </div>
          {
            lessonManager.sectionLessonElementIds.map((lessonElementId, index) => <SummarySection key={`${lessonElementId}-summary-section`} lessonElementId={lessonElementId} />
            )
          }
        </div>
        <div className='summary-section-engagement-wrapper' id='engagementSummaryPanel'
          style={{ display: (panelVisible === 1) ? 'block' : 'none' }}>
          <EngagementSummaryPage />
        </div>
      </div>
    </div>
  );
});

export default SummaryPage;
