import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

// remove manuscript when all components are built and manuscript items are removed from
// preview, graded and review modes
import '../css/Player.scss';
import '../css/Manuscript.scss';
import '../css/TestItems.scss';
import '../css/CkeditorContentStyles.scss';

import { LessonMode } from '../Constants';

import useLessonInit from '../hooks/useLessonInit';
import FullpageLoader from '../lessonPlayer/components/FullpageLoader';
import InternetMessage from '../lessonPlayer/components/InternetMessage';
import PrintPreviewLayout from '../lessonPlayer/layout/PrintPreviewLayout';

const PrintPreview = observer(() => {
  const {
    lessonManager,
  } = useContext(MobXProviderContext);

  useLessonInit(LessonMode.PRINT_PREVIEW);

  useEffect(() => {
    const className = `${LessonMode.PRINT_PREVIEW} theme-override`;
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute('class', className);
  });

  return (
    <>
      {(lessonManager.isOnline && lessonManager.isLoaded && ((lessonManager.themeLoaded && lessonManager.translationsLoaded) || lessonManager.hasNoTheme)) ? (
        <div className='lesson-window'>
          <PrintPreviewLayout />
        </div>
      ) : (!lessonManager.isOnline) ?
        (
          <InternetMessage />
        )
        : (
          <FullpageLoader />
        )}
    </>
  );
});
export default PrintPreview;
