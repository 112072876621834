import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer, } from 'mobx-react';

import useWirisParser from '../../hooks/useWirisParser';
import PrintQuestionListViewer from '../components/PrintQuestionListViewer';
import PrintTitlePage from '../components/PrintTitlePage';
import navMenuService from '../services/NavMenuService';
import { register } from '../../i18n';

const t = register('PrintPreview');

// This activity has been replaced with a questionlist components, which is a list of standalone.
const PrintPreviewLayout = observer(({ lessonElementId, showQuestionBanner }) => {
  const {
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const wirisRef = useRef();
  useWirisParser(wirisRef);

  useEffect(() => {
    const init = async () => {
      const { isForceTeacher } = lessonManager;

      if (isForceTeacher) {
        toolbarManager.setIsToolbarAnswerFeedbackActive(true);
        // we have to load each slide once to show answers
        lessonManager.lessonElementIds.forEach(async (id) => {
          navMenuService.doNavigationForPrintPreview(id);
        });
      }
    };
    init();
  });

  const date = new Date();

  const dateString = date.toLocaleDateString();
  const currentYear = new Date().getFullYear();

  return (
    <>
      {(lessonManager && lessonManager.lessonElementIds) && (
        <div ref={wirisRef} className={`print-preview-questions ${lessonManager?.isForceTeacher ? 'answers-showing' : ''}`} id={`${lessonElementId}-print-preview-list`}>
          <div className='footer'>{`${t('publisher')} ${ t('copyright', { currentYear }) } ${ t('address', { dateString }) }`}
          </div>
          <table className='no-theme-override'>
            <tbody>
              <tr>
                <td aria-label='Print View'>
                  <PrintTitlePage />
                  <PrintQuestionListViewer lessonElementIds={lessonManager.lessonElementIds} />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td aria-label='Footer'>
                  <div className='footer-space'>&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </>
  );
});
export default PrintPreviewLayout;
