import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import AudioPlayer from './AudioPlayer';
import useWirisParser from '../../hooks/useWirisParser';
import QuestionButtonBar from './QuestionButtonBar';
import QuestionFeedback from './QuestionFeedback';
import { useScrollBottomIndicator } from '../../hooks/useScrollBottomIndicator';
import { useTextHighlight } from '../../hooks/useTextHighlight';
import QuestionBanner from './QuestionBanner';
import Auth from '../services/AuthService';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import responseService from '../services/ResponseService';
import questionService from '../services/QuestionService';

const StandAloneViewer = observer((props) => {
  const {
    lessonManager,
    // toolbarManager,
    audioManager,
    responseManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);
  const { lessonElementId, showQuestionBanner, isMultipart, isGroup, isFirstChild = false } = props;
  const CurrentComponent = lessonManager.getCurrentQuestionComponent(lessonElementId);

  const componentRef = useRef();
  useWirisParser(componentRef);
  useTextHighlight(componentRef, lessonElementId);

  // TODO unused // const mode = lessonManager.playerMode;
  const model = lessonManager.getSlideModel(lessonElementId);
  const isActivity = (model) ? model.isActivity : false;
  const isActivityPart = (model) ? (model.isActivityPart && !isMultipart) : false;
  const scrollBottomRef = useRef();
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const isLocked = (lessonElementState && lessonElementState.isDependencyLocked);

  if (CurrentComponent) {
    CurrentComponent.displayName = model.type;
  }
  let incorrectImageUrl = null;
  let correctImageUrl = null;

  if (model && model.imageFeedback && model.imageFeedback.length > 0) {
    model.imageFeedback.forEach((f) => {
      if (f.type === 'correct') {
        correctImageUrl = `url(${Auth.getResourceUrlByResourceId(f.resourceId)})`;
      }
      if (f.type === 'incorrect') {
        incorrectImageUrl = `url(${Auth.getResourceUrlByResourceId(f.resourceId)})`;
      }
    });
  }
  const canScrollBottom = useScrollBottomIndicator(scrollBottomRef);

  const finalClassName = (model) ?
    `lesson-element ${(isActivity) ? 'activity' : 'stand-alone'} ${model.type} ${(model.audioResourceId) ? 'has-audio-resource' : ''} ${(model.extraClass) ? model.extraClass : ''}` : '';

  const playAudio = (e) => {
    if (e.target.tagName.toLowerCase() === 'input' || e.target.tagName.toLowerCase() === 'button') {
      return;
    }
    if (lessonManager.playerMode === LessonMode.SCORING) {
      return;
    }
    if (model && model.audioFeedback && model.audioFeedback.length > 0) {
      const audio = model.audioFeedback.find((element) => element.type === 'contentObject');
      if (audio && !model.isGame) {
        const audioSrc = `${Auth.ecms}/api/redirectToStreamUrl?authKey=${Auth.authKey}&resourceFolder=userFiles&entityId=${audio.resourceId}&entityTypeId=audio_resource`;
        audioManager.playAudio(audioSrc);
      }
    }
  };
  const uiState = questionFeedbackManager.getUiState(lessonElementId);

  const showImages = (uiState) ? uiState.showFeedbackImagesNow : false;
  const isCorrect = responseManager.isQuestionCorrect(lessonElementId);
  const showCorrect = (isCorrect === QuestionFeedbackState.CORRECT) && correctImageUrl && showImages;
  const showIncorrect = (isCorrect === QuestionFeedbackState.INCORRECT) && incorrectImageUrl && showImages;
  const isSurvey = model.survey;

  useEffect(() => {
    async function doMouseClick(event) {
      // use model.isActivityPart because 'isActivityPart' is filtered for other reasons
      //
      if (model.isActivityPart) {
        await questionService.questionChildEventListener(event, lessonElementId);
      }
    }
    // if (model.isActivityPart) {
    //   scrollBottomRef.current.addEventListener('click', doMouseClick);
    // }
    if (model.isActivityPart && scrollBottomRef.current) {
      const parentModel = lessonManager.getSlideModel(model.parentActivityElementId);
      if ((parentModel && parentModel.showAllChildren)
        || (parentModel && parentModel.type === ContentType.MULTIPART_ACTIVITY.type) ||
        model.inGroup) {
        scrollBottomRef.current.addEventListener('click', doMouseClick);
      }
    }

    return (() => {
      if (scrollBottomRef && scrollBottomRef.current && model.isActivityPart) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollBottomRef.current.removeEventListener('click', doMouseClick, false);
      }
    });
  });

  useEffect(() => {
    const doAction = async () => {
      await responseService.alertDependencyLock(lessonElementState);
    };

    // If activity part we don't want the alert to popup on navigation to activity (See DEMO-2487)
    if (isLocked && !model.isActivityPart) {
      doAction();
    }
  }, [isLocked]);

  return (
    <>
      {(isLocked) ? (
        <div className={classNames({
          'stand-alone-wrapper': true,
          'is-activity-part': isActivityPart,
          'is-group': isGroup,
          'is-multi-part': isMultipart,

        }, model.type)} id={`${lessonElementId}-standalone-wrapper`}>
          <div className='dependency-message' />
        </div>
      ) : (
        <div ref={scrollBottomRef} className={classNames({
          'stand-alone-wrapper': true,
          'is-activity-part': isActivityPart,
          'is-group': isGroup,
          'is-multi-part': isMultipart,
          // added to make styling a bit easier
          'is-first-child': isFirstChild
        }, model.type)}
          data-current-item-number={(lessonElementId === lessonManager.currentLessonElementId) ? lessonManager.currentItemNumber : null}
          data-current-slide={(lessonElementId === lessonManager.currentLessonElementId) ? lessonManager.currentSlideNumber : null}
          id={`${lessonElementId}-standalone-wrapper`}>
          <div className={classNames('correct-image-feedback', { visible: showCorrect })} style={{ backgroundImage: correctImageUrl }} />
          <div className={classNames('correct-image-feedback', { visible: showIncorrect })} style={{ backgroundImage: incorrectImageUrl }} />
          <QuestionBanner isMultipart={isMultipart} lessonElementId={lessonElementId} showQuestionBanner={showQuestionBanner && isActivityPart} />
          {(model && model.audioResourceId) && <AudioPlayer lessonElementId={lessonElementId} />}
          <div ref={componentRef} className={finalClassName} id={`${lessonElementId}-item-view`} onClick={playAudio}>
            {(CurrentComponent && !isActivity) && (
              <>
                <CurrentComponent key={lessonElementId} lessonElementId={lessonElementId} model={model} />
              </>
            )}
          </div>
          {(model.isGame) ? <></> : (
            <div className='bottom-padding'>
              <QuestionButtonBar isSurvey={isSurvey} lessonElementId={lessonElementId} />
              <QuestionFeedback isSurvey={isSurvey} lessonElementId={lessonElementId} />
            </div>
          )}
          <div className='scroll-bottom-indicator' style={(canScrollBottom) ? {} : { visibility: 'hidden' }} />
        </div>
      )}
    </>
  );
});
StandAloneViewer.displayName = 'StandAloneViewer';
export default StandAloneViewer;
