import React, { useEffect, useState } from 'react';
import Auth from '../services/AuthService';
import '../../css/OptionalImage.scss';
import HtmlComponent from './HtmlComponent';
import UtilsService from '../services/UtilsService';
import lessonService from '../services/LessonService';
import { register } from '../../i18n';
const t = register('GlobalQuestionLabels');

const OptionalImage = ({ model, runtime }) => {
  const hasImg = model.croppedImageSource != null || model.imageSource != null;
  const src = model.croppedImageSource || model.imageSource;
  const imgSrc = Auth.getResourceUrlByFileName(src);
  const [altText, setAltText] = useState('');

  useEffect(() => {
    const doAction = async () => {
      if (hasImg && UtilsService.isNullOrEmpty(model.altText) && !model.altTextLoaded) {
        const contentItemId = src.substring(0, src.indexOf('.'));
        const contentItem = await lessonService.fetchContentItem(contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        }
      }
    };
    doAction();
  });

  return (
    <>
      {(runtime) ? (
        <div className='imageBodyContainer '>
          {model.imageTitle &&
          <div className='textInput imageLabelTitle'><HtmlComponent htmlStr={model.imageTitle} /></div>}
          {hasImg && (
            <div className='imageBody stop' id={`${model.lessonElementId}_${model.contentItemId}_image_body`}>
              <img alt={model.altText || altText || ''} className='test-item-image' id={`${model.lessonElementId}_${model.contentItemId}_image`} src={imgSrc} />
            </div>
          )}
        </div>
      )
        : (
          <>
            {model.imageTitle && (
              <div className='label-value-row'>
                <div>{t('imageTitle')}</div>
                <div className='ckeditor-text'><HtmlComponent htmlStr={model.imageTitle} /></div>
              </div>
            )}
            {hasImg && (
              <div>
                <img alt={model.altText} className='max-img-size optional-img' id={`${model.lessonElementId}_${model.contentItemId}_image`} src={imgSrc} />
              </div>
            )}
          </>
        )}
    </>
  );
};
export default OptionalImage;
