import UtilsService from '../services/UtilsService';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import { Behavior } from './Behavior';

export class LongTextBehavior extends Behavior {
  checkForValidResponse = (responseAnswer, model) => {
    // Valid if the response exists, is not empty, and is not the default value
    // TODO: Add the model to the parameters here so we can check for the default value. The other solution would be to include the default value in the response.
    return responseAnswer.text != null && UtilsService.stripTagsAndEntities(responseAnswer.text).trim() !== '' &&
    UtilsService.stripTagsAndEntities(responseAnswer.text).trim() !== UtilsService.stripTagsAndEntities(model.defaultText).trim();
  }

  setCorrectAnswer = (state, model) => {
  }

  resetStudentAnswer = (state) => {
  }

  getScore = (responseAnswer, model) => {
    // Never auto-scored so this value doesn't matter.
    return -1;
  }

  setResponseData = (data, responseAnswer, model) => {
    // No dependence on the model. Text is stored in data by ckeditor
    responseAnswer.text = data;
  }

  getInitializedResponse = (lessonElementId, model) => {
    const responseAnswer = {
      text: model.defaultText || '',

      lessonElementId
    };
    return responseAnswer;
  }

  isAutoScored = (model) => {
    return (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
  }

  getAnswerFeedback = (id, responseAnswer) => {
    // never used longText is not autoscored

  }

  getCorrectAnswersText = (model) => {
    // There is no correct answer for this question type.
    return '';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += '';
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, responseAnswer) => {
    // manual scored
    return false;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}
export default new LongTextBehavior();
