import {
  deserializeHighlights,
  doHighlight,
  removeHighlights,
  serializeHighlights
} from '@funktechno/texthighlighter/lib/index';

import parse from 'html-react-parser';

import { LessonMode } from '../../Constants';

import highlightAnnotationManager from '../managers/HighlightAnnotationManager';
import lessonManager from '../managers/LessonManager';
import toolbarManager from '../managers/ToolbarManager';
import userManager from '../managers/UserManager';
import studentActivityManager from '../managers/StudentActivityManager';

import Auth from './AuthService';

import utilsService from './UtilsService';

import { confirmDialog } from '../components/dialogs';
import { register } from '../../i18n';

const t = register('GlobalQuestionLabels');

export class HighlightAnnotationService {
  hasNoteAnchorWithinHighlightedSelection = ({
    selection
  } = {}) => {
    if (!selection?.rangeCount) {
      return false;
    }

    const range = selection.getRangeAt(0);

    const clonedRangeDocumentFragment = range?.cloneContents?.();

    if (!range || !clonedRangeDocumentFragment) {
      return false;
    }

    const rangeDiv = document.createElement('div');
    rangeDiv.appendChild(clonedRangeDocumentFragment.cloneNode(true));

    const rangeHtmlStr = rangeDiv.innerHTML;

    return rangeHtmlStr?.includes?.('note-anchor');
  }

  showHighlightErrorDialog = async () => {
    await confirmDialog({
      title: `${t('highlightError')}`,
      html: `${t('highlightBody1')}<br /><br />${t('highlightBody2')}`,
      showCancelButton: false,
      confirmButtonText: `${utilsService.confirmOk()}`
    });
  }

  async changeColor(color) {
    const { highlightColor, selectedHighlightElement } = highlightAnnotationManager;
    highlightAnnotationManager.setHighlightColor(color);
    if (selectedHighlightElement) {
      selectedHighlightElement.setAttribute('data-backgroundcolor', highlightColor);
      selectedHighlightElement.setAttribute('style', `background-color: ${ highlightColor}`);
      selectedHighlightElement.classList.remove('hlt-selected');
      await this.updateCurrentHighlights();
      toolbarManager.addContentToolUsage('HAT');
    }
  }

  async underlineSelectedHighlight() {
    if (highlightAnnotationManager.selectedHighlightElement) {
      if (highlightAnnotationManager.selectedHighlightElement.classList.contains('hlt-underline')) {
        highlightAnnotationManager.selectedHighlightElement.classList.remove('hlt-underline');
      } else {
        highlightAnnotationManager.selectedHighlightElement.classList.add('hlt-underline');
      }
      highlightAnnotationManager.selectedHighlightElement.classList.remove('hlt-selected');
      await this.updateCurrentHighlights();
      toolbarManager.addContentToolUsage('HAT');
    }
  }

  async circleSelectedHighlight() {
    if (highlightAnnotationManager.selectedHighlightElement) {
      if (highlightAnnotationManager.selectedHighlightElement.classList.contains('hlt-circle')) {
        highlightAnnotationManager.selectedHighlightElement.classList.remove('hlt-circle');
      } else {
        highlightAnnotationManager.selectedHighlightElement.classList.add('hlt-circle');
      }
      highlightAnnotationManager.selectedHighlightElement.classList.remove('hlt-selected');
      await this.updateCurrentHighlights();
      toolbarManager.addContentToolUsage('HAT');
    }
  }

  async cleanHighlightsDomForSerialize() {
    const hltSelected = document.querySelectorAll('.hlt-selected');
    hltSelected.forEach((hlt) => {
      hlt.classList.remove('hlt-selected');
    });

    const hltAbandoned = document.querySelectorAll('.note-anchor:not(.last)');

    hltAbandoned.forEach((hlt) => {
      removeHighlights(hlt);
    });
  }

  async cancelCreateNote() {
    if (highlightAnnotationManager.selectedHighlightElement
        && highlightAnnotationManager.selectedHighlightElement.classList.contains('note-anchor')
        && !highlightAnnotationManager.selectedHighlightElement.hasAttribute('data-note-count')) {
      removeHighlights(highlightAnnotationManager.selectedHighlightElement);
      await this.updateCurrentHighlights();
    }
    toolbarManager.setIsStickyNotesOpen(false);
  }

  async showHighlights(elementId) {
    // get the object from the map
    const textHighlight = highlightAnnotationManager.elementTextHighlightMap.get(elementId);

    if (textHighlight) {
      // Get the highlight id and check and see if it is already on the page, if so don't try to add it again.
      const parsedHighlight = parse(textHighlight.highlightJson);
      const cleanHighlightId = parsedHighlight[1]?.props?.id?.replace(/\\/g, '').replace(/"/g, '');
      const highlightDomElement = document.getElementById(cleanHighlightId);

      if (!highlightDomElement) {
        // get the highlight data
        const highlightData = textHighlight.highlightJson;
        const { domElementId } = textHighlight;
        const domElement = document.getElementById(domElementId);

        deserializeHighlights(domElement, highlightData);
      }
      // this.fetchTextHighlightNoteCountsForElement();
    }
  }

  async removeElementHighlights(elementId) {
    // get the object from the map
    const textHighlight = highlightAnnotationManager.elementTextHighlightMap.get(elementId);

    if (textHighlight) {
      // get the highlight data
      const { domElementId } = textHighlight;
      const domElement = document.getElementById(domElementId);
      removeHighlights(domElement);
    }
  }

  async removeScopeLessonElementHighlights() {
    const thisContainer = document.getElementById('root');
    const lessonElements = thisContainer.querySelectorAll('.lesson-element, .question-list-wrapper');
    for (const domElement of lessonElements) {
      removeHighlights(domElement);
    }
    const highlightableElements = document.querySelectorAll('.html-input, .paragraph-wrapper');
    for (const highlightableElement of highlightableElements) {
      highlightableElement.classList.remove('sticky-notes-active');
    }
  }

  async showScopeLessonElementHighlights() {
    const thisContainer = document.getElementById('root');
    const lessonElements = thisContainer.querySelectorAll('.lesson-element, .question-list-wrapper');
    for (const domElement of lessonElements) {
      const fullDomElementId = domElement.id;
      if (fullDomElementId) {
        const domElementId = fullDomElementId.split('-')[0];
        this.showHighlights(domElementId);
      }
    }
    const highlightableElements = document.querySelectorAll('.html-input, .paragraph-wrapper');
    for (const highlightableElement of highlightableElements) {
      highlightableElement.classList.add('sticky-notes-active');
    }
  }

  async showTextHighlightNotes(elementId, target) {
    if (!target.id) {
      target.setAttribute('id', utilsService.createGuid());
    }
    highlightAnnotationManager.setCurrentDomElementId(elementId);
    highlightAnnotationManager.setSelectedHighlightElement(target);

    if (highlightAnnotationManager.currentHighlightNotes === undefined ||
      highlightAnnotationManager.currentHighlightNotes === null ||
      highlightAnnotationManager.currentHighlightNotes.length < 1) {
      await this.fetchTextHighlightNotes();
    }

    toolbarManager.setIsStickyNotesOpen(true);
  }

  async showTextHighlightNoteCounts(countData) {
    for (const textHighlightNoteCount of countData) {
      // get the element by highlight id. set the style and note count
      const thisElement = document.getElementById(textHighlightNoteCount.highlightId);
      if (thisElement) {
        thisElement.setAttribute('data-note-count', textHighlightNoteCount.noteCount);
        thisElement.classList.add('last');
      }
    }
  }

  // FETCHES
  async fetchTextHighlights(viewStudent = false) {
    const urlParams = new URLSearchParams(window.location.search);
    const activityInstanceId = urlParams.get('activityInstanceId');
    const classroomId = urlParams.get('classroomId');
    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;
    let activityId = null;
    let sid = null;
    let cid = null;
    if (viewStudent) {
      sid = (studentActivityManager.studentId) ? studentActivityManager.studentId : urlParams.get('sid');
      if (lessonManager.playerMode === LessonMode.SCORING) {
        activityId = studentActivityManager.activityId;
      }
      cid = classroomId;
    }

    try {
      const response =
        await Auth.fetch(`${Auth.ecms}/api/viewTextHighlights?entityTypeId=${entityTypeId}&entityId=${entityId}&activityInstanceId=${activityInstanceId}&activityId=${activityId}&sid=${sid}&cid=${classroomId}`, {
          method: 'GET',
        });

      if (response.status === 'SUCCESS') {
        for (const textHighlight of response.textHighlights) {
          highlightAnnotationManager.addHighlightData(textHighlight.elementId, textHighlight);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async fetchTextHighlightNoteCountsForElement() {
    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;
    const elementId = lessonManager.currentLessonElementId;

    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewTextHighlightNoteCounts?entityTypeId=${entityTypeId}&entityId=${entityId}&elementId=${elementId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        await this.showTextHighlightNoteCounts(response.textHighlightNoteCounts);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async fetchTextHighlightNotes() {
    const highlightId = highlightAnnotationManager.selectedHighlightElement.id;
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewTextHighlightNotes?highlightId=${highlightId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        highlightAnnotationManager.addNoteData(highlightId, response.textHighlightNotes);
        // highlightAnnotationManager.setCurrentHighlightNotes();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async updateCurrentHighlights() {
    await this.cleanHighlightsDomForSerialize();

    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;
    const { currentDomElementId } = highlightAnnotationManager;
    if (!currentDomElementId || currentDomElementId === 'undefined') {
      // if the currentDomElementId is null, it means we changed context so don't update anything as it will zero out highlights
      return;
    }
    const element = document.getElementById(currentDomElementId);
    let serializedHighlights = serializeHighlights(element);
    if (serializedHighlights === null || serializedHighlights === '' || serializedHighlights === undefined) {
      serializedHighlights = '[]';
    }

    const urlParams = new URLSearchParams(window.location.search);
    const activityInstanceId = urlParams.get('activityInstanceId');
    let sid = null;
    if (lessonManager.playerMode === LessonMode.SCORING && userManager.isTeacher && highlightAnnotationManager.highlightUserMode === 'student') {
      sid = urlParams.get('sid');
    }

    // get element id from the dom
    const domElementId = highlightAnnotationManager.currentDomElementId;
    const elementId = (domElementId) ? domElementId.split('-')[0] : lessonManager.currentLessonElementId;
    try {
      const body = {
        entityId,
        entityTypeId,
        activityInstanceId,
        sid,
        elementId,
        domElementId: highlightAnnotationManager.currentDomElementId,
        highlightData: serializedHighlights
      };
      const data = await Auth.fetch(`${Auth.ecms}/api/createUpdateTextHighlight`, {
        method: 'POST',
        body
      });
      if (data && data.status === 'SUCCESS') {
        data.textHighlight.highlightJson = serializedHighlights;
        highlightAnnotationManager.addHighlightData(data.textHighlight.elementId, data.textHighlight);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async createUpdateHighlight(element = null, domElementId, lessonElementId, isStickyNote = false, { event, selection } = {}) {
    if (!element) {
      console.info('createUpdateHighlight: Element is missing or highlighter is off.');
      return false;
    }

    await this.cleanHighlightsDomForSerialize();

    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;

    const urlParams = new URLSearchParams(window.location.search);
    const activityInstanceId = urlParams.get('activityInstanceId');

    const options = {
      color: highlightAnnotationManager.highlightColor,
      highlightedClass: 'hlt-on',
      onAfterHighlight: (isStickyNote) ? highlightAnnotationManager.onAfterHighlightStickyNote : highlightAnnotationManager.onAfterHighlight
    };

    const keepRange = false;
    doHighlight(element, keepRange, options);

    let serializedHighlights = serializeHighlights(element);
    if (serializedHighlights === null || serializedHighlights === '' || serializedHighlights === undefined) {
      serializedHighlights = '[]';
    }

    let sid = null;
    if (lessonManager.playerMode === LessonMode.SCORING && userManager.isTeacher && highlightAnnotationManager.highlightUserMode === 'student') {
      sid = urlParams.get('sid');
    }

    // get element id from the dom
    const elementId = (domElementId) ? domElementId.split('-')[0] : lessonElementId;

    try {
      const body = {
        entityId,
        entityTypeId,
        activityInstanceId,
        sid,
        elementId,
        domElementId,
        highlightData: serializedHighlights
      };
      const data = await Auth.fetch(`${Auth.ecms}/api/createUpdateTextHighlight`, {
        method: 'POST',
        body
      });
      if (data && data.status === 'SUCCESS') {
        data.textHighlight.highlightJson = serializedHighlights;
        highlightAnnotationManager.addHighlightData(data.textHighlight.elementId, data.textHighlight);

        if (isStickyNote) {
          toolbarManager.setIsStickyNotesOpen(true);
        } else {
          toolbarManager.addContentToolUsage('HAT');
        }
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async updateHighlightNote(id, noteText) {
    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;
    const highlightId = highlightAnnotationManager.selectedHighlightElement.id;

    // get element id from the dom
    const domElementId = highlightAnnotationManager.currentDomElementId;
    let elementId = (domElementId) ? domElementId.split('-')[0] : lessonManager.currentLessonElementId;
    elementId = (elementId) || lessonManager.currentLessonElementId;

    try {
      const body = {
        id,
        entityId,
        entityTypeId,
        elementId,
        highlightId,
        noteText
      };
      const data = await Auth.fetch(`${Auth.ecms}/api/createUpdateTextHighlightNote`, {
        method: 'POST',
        body
      });
      if (data && data.status === 'SUCCESS') {
        await this.showTextHighlightNoteCounts(data.textHighlightNoteCounts);
        highlightAnnotationManager.updateNote(data.textHighlightNote);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async createHighlightNote(noteText) {
    const highlightId = highlightAnnotationManager.selectedHighlightElement?.id;
    if (!highlightId) {
      return;
    }
    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;

    // get element id from the dom
    const domElementId = highlightAnnotationManager.currentDomElementId;
    let elementId = (domElementId) ? domElementId.split('-')[0] : lessonManager.currentLessonElementId;
    elementId = (elementId) || lessonManager.currentLessonElementId;

    try {
      const body = {
        id: '',
        entityId,
        entityTypeId,
        elementId,
        highlightId,
        noteText
      };
      const data = await Auth.fetch(`${Auth.ecms}/api/createUpdateTextHighlightNote`, {
        method: 'POST',
        body
      });
      if (data && data.status === 'SUCCESS') {
        await this.showTextHighlightNoteCounts(data.textHighlightNoteCounts);
        highlightAnnotationManager.addNote(data.textHighlightNote);

        await this.updateCurrentHighlights();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async deleteAllHighlightNotes() {
    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;
    const internalHighlightId = highlightAnnotationManager.selectedHighlightElement.id;
    try {
      const body = {
        id: '',
        entityId,
        entityTypeId,
        elementId: lessonManager.currentLessonElementId,
        internalHighlightId
      };
      const data = await Auth.fetch(`${Auth.ecms}/api/deleteAllTextHighlightNotes`, {
        method: 'POST',
        body
      });
      if (data && data.status === 'SUCCESS') {
        highlightAnnotationManager.deleteAllHighlightNotes(internalHighlightId);
        const thisElement = document.getElementById(internalHighlightId);

        if (thisElement.classList.contains('note-anchor')) {
          removeHighlights(thisElement);
        } else {
          thisElement.classList.remove('hlt-selected');
          thisElement.classList.remove('note-anchor');
          thisElement.classList.remove('last');
          thisElement.removeAttribute('data-note-count');
        }

        await this.updateCurrentHighlights();

        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async deleteSelectedHighlight() {
    const selectedHighlightElement = highlightAnnotationManager.selectedHighlightElement?.id ?
      highlightAnnotationManager.selectedHighlightElement : document.querySelector('.hlt-selected');

    // get the json and remove the record.
    if (!selectedHighlightElement) {
      return false;
    }

    const { currentDomElementId } = highlightAnnotationManager;
    const currentLessonElementId = currentDomElementId?.split('-')[0];
    const currentDomElement = document.getElementById(currentDomElementId);
    const internalHighlightId = selectedHighlightElement.id;
    const span = selectedHighlightElement;
    removeHighlights(span);

    let serializedHighlights = serializeHighlights(currentDomElement);

    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;

    if (serializedHighlights === null || serializedHighlights === '' || serializedHighlights === undefined) {
      serializedHighlights = '[]';
    }

    try {
      const body = {
        entityId,
        entityTypeId,
        elementId: currentLessonElementId,
        domElementId: currentDomElementId,
        highlightData: serializedHighlights,
        internalHighlightId
      };

      const data = await Auth.fetch(`${Auth.ecms}/api/deleteTextHighlight`, {
        method: 'POST',
        body
      });

      if (data && data.status === 'SUCCESS') {
        if (data.textHighlight) {
          if (!data.textHighlight.hasHighlight) {
            highlightAnnotationManager.deleteHighlightData(currentLessonElementId);
          } else {
            data.textHighlight.highlightJson = serializedHighlights;
            highlightAnnotationManager.addHighlightData(data.textHighlight.elementId, data.textHighlight);
          }
        } else {
          // placeholder - no highlights found
        }
        highlightAnnotationManager.setSelectedHighlightElement(null);
        toolbarManager.addContentToolUsage('HAT');
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async deleteHighlightNote(noteId) {
    // get the json and remove the record.
    const { entityId } = lessonManager.lessonContentItem;
    const { entityTypeId } = lessonManager.lessonContentItem;

    try {
      const body = {
        entityId,
        entityTypeId,
        elementId: lessonManager.currentLessonElementId,
        domElementId: highlightAnnotationManager.currentDomElementId,
        noteId
      };

      const data = await Auth.fetch(`${Auth.ecms}/api/deleteTextHighlightNote`, {
        method: 'POST',
        body
      });

      if (data && data.status === 'SUCCESS') {
        await this.showTextHighlightNoteCounts(data.textHighlightNoteCounts);
        highlightAnnotationManager.removeNote(noteId);
        await this.updateCurrentHighlights();

        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async clearExistingHighlightData() {
    highlightAnnotationManager.clearAllHighlightAndNoteData();
  }

  async switchHighightAndNotesView(mode) {
    await this.removeElementHighlights(lessonManager.currentLessonElementId);
    await this.clearExistingHighlightData();

    if (mode === 'student') {
      await this.fetchTextHighlights(true);
      toolbarManager.setIsHighlightAnnotationOpen(false);
      toolbarManager.setIsStickyNotesActive(true);
      toolbarManager.buttonSettings.get('StickyNotes').toggle = true;
    }

    if (mode === 'teacher') {
      await this.fetchTextHighlights(false);
      toolbarManager.setIsHighlightAnnotationOpen(true);
      toolbarManager.setIsStickyNotesActive(true);
      toolbarManager.buttonSettings.get('StickyNotes').toggle = true;
      toolbarManager.buttonSettings.get('HAT').toggle = true;
    }

    if (mode === 'off') {
      toolbarManager.setIsHighlightAnnotationOpen(false);
      toolbarManager.setIsStickyNotesActive(false);
      toolbarManager.buttonSettings.get('StickyNotes').toggle = false;
      toolbarManager.buttonSettings.get('HAT').toggle = false;
    }
    // modes are off, student, teacher
    await this.showHighlights(lessonManager.currentLessonElementId);
  }
}

/** @param {RangyRange} rangyRange */
export const fixCharacterSelectionRange = (rangyRange) => {
  rangyRange.moveStart('character', 1);
  rangyRange.moveStart('character', -1);
  rangyRange.moveEnd('character', -1);
  rangyRange.moveEnd('character', 1);
};

// TODO unused
// export const selectWords = (selection, { additionalWordCount = 1 } = {}) => {
//   const rangyRange = selection.getRangeAt(0);
//   selection.expand('word', {
//     wordOptions: {
//       includeTrailingSpace: true
//     }
//   });
//   rangyRange.collapse(false);
//   // move whole range `additionalWordCount + 1` words ahead
//   rangyRange.move('word', additionalWordCount + 1);
//   // move start `additionalWordCount - 1` words back
//   rangyRange.moveStart('word', -additionalWordCount - 1);
//   // reselect the range
//   selection.setSingleRange(rangyRange);
// };

export default new HighlightAnnotationService();
