import lessonManager from '../managers/LessonManager';
import navMenuManager from '../managers/NavMenuManager';
import videoManager from '../managers/VideoManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import responseManager from '../managers/ResponseManager';

import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';

import navMenuService from './NavMenuService';
import playerService from './PlayerService';
import promptService from './PromptService';

import {
  ADVANCED_CKEDITOR_TOOLBAR,
  ADVANCED_MATH_CKEDITOR_TOOLBAR,
  MATH_ONLY_CKEDITOR_TOOLBAR,
  MATH_TOOLBAR,
  MINIMUM_TOOLBAR
} from '../components/RichTextEditor';

import responseService from './ResponseService';
import styleService from './StyleService';
import utilsService from './UtilsService';

export default class QuestionService {
  static mustHaveUserInputMap = (model) => {
    return model?.type?.includes?.(ContentType.CLOZE.type)
      || model?.type === ContentType.IMAGE_LABEL.type
      || model?.type === ContentType.IMAGE_LABEL_DRAG_DROP.type;
  }

  // TODO phase out responseAnswer in favor of lessonElementState.currentResponseAnswer
  static updateUserInputMap = ({
    lessonElementState,
    model,
    promptAnswerTypeToValidateForPreservation = null,
    responseAnswer,
    shouldPreserveCorrectUserInputs = false
  } = {}) => {
    const lessonElementId = lessonManager.currentLessonElementId;
    model = model || lessonManager.getSlideModel(lessonElementId);

    const userInputMap = this.generateUserInputMap({
      lessonElementState, model, promptAnswerTypeToValidateForPreservation, shouldPreserveCorrectUserInputs
    });

    if (lessonElementState) {
      lessonElementState.currentResponseAnswer.userInputMap = userInputMap;
      return lessonElementState.currentResponseAnswer.userInputMap;
    } else if (responseAnswer) {
      responseAnswer.userInputMap = userInputMap;
      return responseAnswer.userInputMap;
    } else {
      return userInputMap;
    }
  }

  static generateUserInputMap = ({
    lessonElementState,
    model,
    promptAnswerTypeToValidateForPreservation = null,
    shouldPreserveCorrectUserInputs = false
  } = {}) => {
    const userInputMap = new Map();
    const prompts = model?.prompts;

    if (Array.isArray(prompts)) {
      for (const modelPrompt of prompts) {
        const dataId = modelPrompt.id;
        if (shouldPreserveCorrectUserInputs) {
          let currentUserInput = lessonElementState.currentResponseAnswer?.userInputMap?.get?.(dataId);
          const currentUserInputValue = currentUserInput?.text;

          const currentPrompt = promptService.getCurrentPrompt({
            dataId, lessonElementState, model, promptType: 'modelPrompt'
          });

          const skipValidateForPreservation = promptAnswerTypeToValidateForPreservation ?
            promptAnswerTypeToValidateForPreservation !== currentPrompt.answerType : false;

          const isCorrectPromptAnswerStatus = !!currentUserInputValue && promptService.isCorrectPromptAnswerStatus({
            dataId, currentUserInputValue, model
          });

          currentUserInput = isCorrectPromptAnswerStatus || skipValidateForPreservation ? currentUserInput : {
            dataId, text: ''
          };

          userInputMap.set(dataId, currentUserInput || {
            dataId,
            text: ''
          });
        } else {
          const responseAnswerPrompt = promptService.getCurrentPrompt({
            dataId,
            lessonElementState,
            model,
            promptType: 'responseAnswerPrompt'
          }) || {};

          const modelPromptAnswer = modelPrompt?.answers?.find((answer) => {
            return (!answer.text || answer.text === responseAnswerPrompt.text) &&
              (answer.isCorrect || promptService.isUserInputAlwaysCorrectIfFoundInModelPromptAnswers(model, modelPrompt));
          });
          userInputMap.set(dataId, {
            ...responseAnswerPrompt,
            dataId,
            id: modelPromptAnswer?.id || promptService.getResponseItemPromptFromUserInputValue({
              currentUserInputValue: responseAnswerPrompt.text || '',
              dataId,
              model
            })?.id,
            text: responseAnswerPrompt.text || ''
          });
        }
      }
      return userInputMap;
    }
  }

  /**
   * @param {{
   *   isScored: boolean;
   *   isScoredCorrect: boolean;
   *   lessonElementId: string;
   * }}
   */
  static generateInitializedQuestionResponse = ({
    isScored = true, // i.e. an item capable of being scored; `scorable`
    lessonElementId,
    ...rest
  } = {}) => {
    const response = {
      isScored,
      lessonElementId,
      ...rest
    };
    return response;
  }

  static initQuestionComponent = ({
    includeUiFeedbackState = false,
    includeUserInputMap = true,
    lessonElementId,
    questionClassName,
  }) => {
    const model = lessonManager.getSlideModel(lessonElementId);

    let toolbar = null;

    if (model.responseFormat === 'html' || (!model.responseFormat && model.toolbarType)) {
      toolbar = MINIMUM_TOOLBAR;
      if (model.toolbarType === 'mathToolbar') {
        toolbar = MATH_TOOLBAR;
      } else if (model.toolbarType === 'advancedToolbar') {
        toolbar = ADVANCED_CKEDITOR_TOOLBAR;
      } else if (model.toolbarType === 'mathAdvancedToolbar') {
        toolbar = ADVANCED_MATH_CKEDITOR_TOOLBAR;
      }
    }

    if (model.responseFormat === 'math' || (model.mathText && !model.body)) {
      toolbar = MATH_ONLY_CKEDITOR_TOOLBAR;
    }

    const lessonElementState = responseManager.getLessonElementState(lessonElementId);

    if (!lessonElementState) {
      return (<div className={questionClassName} />);
    }

    const readOnly = playerService.isReadOnly(lessonElementId);

    const { currentResponseAnswer } = lessonElementState;

    const uiState = includeUiFeedbackState ? questionFeedbackManager.getUiState(lessonElementId) : undefined;

    const userInputMap = includeUserInputMap ? this.generateUserInputMap({
      model,
      lessonElementState,
    }) : undefined;

    return {
      currentResponseAnswer,
      lessonElementState,
      model,
      readOnly,
      toolbar,
      uiState,
      userInputMap
    };
  }

  static getModelsMap = ({
    condition = () => true,
    lessonElementIds = undefined,
    originalArray
  } = {}) => {
    lessonElementIds = Array.isArray(lessonElementIds) ? lessonElementIds : (Array.from(responseManager.lessonElementStates?.keys()) || []);
    const modelsMap = new Map();
    for (const lessonElementId of lessonElementIds) {
      const lessonElementState = responseManager.getLessonElementState(lessonElementId);
      const model = lessonManager.getSlideModel(lessonElementId);
      if (model && condition(lessonElementState, model, originalArray)) {
        modelsMap.set(lessonElementId, model);
      }
    }
    return modelsMap;
  }

  static getBehaviorQuestionFeedbackText = ({
    correctFeedback,
    correctFeedbackBody,
    incorrectFeedback,
    incorrectFeedbackBody,
    mode,
    // model,
    questionIsCorrect,
    currentAttemptCount
  } = {}) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += `${utilsService.previewWarning()}`;
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += utilsService.saveWarning();
      } else {
        textBody += utilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${utilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
        textBody = `${utilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${utilsService.partiallyCorrectPrompt()}. ${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }
    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';
    return finalText;
  }

  static isAutoScored = (model) => {
    return (ContentType.getAutoscore(model?.type) &&
      !model?.survey && !model?.unscored);
  }

  static activityWithQuestions = (lessonElementId = null) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    return model?.isActivityPart;
  }

  static isAnsweredSurveyQuestion = ({ lessonElementId, lessonElementState, model } = {}) => {
    const answered = !!(model?.survey && this.hasUserInteractedWithQuestion({
      lessonElementId,
      lessonElementState,
      model
    }));
    return answered;
  }

  static hasUserInteractedWithQuestion = ({ lessonElementId, lessonElementState } = {}) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    const behavior = responseManager.getQuestionBehavior(lessonElementId);

    if (behavior) {
      return behavior.checkForValidResponse(responseManager.getCurrentResponseAnswer(lessonElementId), model);
    }

    return false;
  }

  static handleClickActivityNavigationButton = async (event) => {
    const { openHintDialog, openSolutionDialog } = questionFeedbackManager;
    if (openHintDialog || openSolutionDialog) {
      return;
    }

    const closestElement = event.target.closest('.question-list-wrapper .stand-alone-wrapper');

    const currentLessonElement = lessonManager.getModelAndLessonElement(lessonManager.currentSlide)?.lessonElement;
    const lessonElementId = closestElement?.id?.slice?.(0, 32);

    if ((currentLessonElement?.id === lessonElementId) || lessonElementId === undefined) {
      return;
    }

    await navMenuService.navToSlideClickHandler(lessonElementId, {
      event,
      shouldScrollQuestionIntoView: true
    });
  }

  static getMultipartChildElement = ({
    childElementIndex
  } = {}) => {
    const childElementId = navMenuManager.activityNavigatorLessonElementIds?.[childElementIndex];
    const childElement = document.getElementById(`${childElementId}-standalone-wrapper`);
    navMenuManager.setPreviousActivityNavigatorPointY(null);
    return childElement;
  }

  /** If the active question is part of a multipart activity, move that question into view. */
  static scrollQuestionIntoView = (lessonElementId = null, {
    allowSetCurrentSlideIndex = true,
    fromNextSlideHandler = false,
    fromPreviousSlideHandler = false,
    fromCardHandler = false
  } = {}) => {
    const currentLessonElementId = lessonElementId || lessonManager.currentLessonElementId;

    if (allowSetCurrentSlideIndex) {
      const slides = lessonManager.getFilteredSlides();
      const index = (slides.length > 0) ? slides.indexOf(currentLessonElementId) : -2;
      if (slides.length > 0 && lessonManager.currentSlide !== index) {
        lessonManager.setCurrentSlideIndex(index);
      }
    }

    if (this.activityWithQuestions(currentLessonElementId)) {
      const model = lessonManager.getSlideModel(currentLessonElementId);

      let shouldScrollParent;
      if (model.isActivityPart || model.isActivity) {
        const parentModel = (model.isActivityPart) ?
          lessonManager.getSlideModel(model.parentActivityElementId) :
          model;
        const parentLessonElement = (model.isActivityPart) ?
          lessonManager.getLessonElement(model.parentActivityElementId) :
          lessonManager.getLessonElement(model.lessonElementId);

        let questionHtmlElementId;

        // Checking to see if we have a title page fixes bug in CF-4391.
        const noTitlePage = lessonManager.lessonOptions.hideTitlePage;

        const multiTopNavigate = ((!lessonManager.lessonOptions.navigateWithinActivity &&
          fromPreviousSlideHandler) || fromNextSlideHandler || fromCardHandler || noTitlePage);

        shouldScrollParent = (parentModel.type === ContentType.MULTIPART_ACTIVITY.type) &&
          (multiTopNavigate) &&
          (parentLessonElement.lessonElementIds[0] === currentLessonElementId);

        if (shouldScrollParent) {
          questionHtmlElementId = `${parentModel.lessonElementId }-question-list`;
        } else {
          questionHtmlElementId = `${model.lessonElementId }-item-view`;
        }
        const element = document.getElementById(questionHtmlElementId);

        if (element) {
          if (shouldScrollParent) {
            // Some themes scroll multiparts with the question-list-wrapper element and some scroll with
            // the activity-content element. That is why we set scrollTop = 0 on both.
            element.scrollTop = 0;
            element.parentNode.scrollTop = 0;
          } else {
            // Always using 'nearest' instead of 'start'. There seem to be a lot of issues
            // with the toolbar being pushed up when block is set to 'start'.
            const headerElement = element.parentElement;
            headerElement.scrollIntoView({ block: 'nearest', inline: 'nearest' });
          }
        }
      }
    }
  }

  static questionChildEventListener = async (event, lessonElementId) => {
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
    const isLocked = (lessonElementState && lessonElementState.isDependencyLocked);
    if (isLocked) {
      await responseService.alertDependencyLock(lessonElementState);
      return;
    }
    lessonManager.setIgnoreQuestionChildEventScrollListener(true);
    setTimeout(async () => {
      lessonManager.setIgnoreQuestionChildEventScrollListener(false);
    }, 300);

    videoManager.setSelectedSideNavVideoChildLessonElementId(lessonElementId);

    const currentActivityNavigatorLessonElementIndex = navMenuManager.activityNavigatorLessonElementMap.get(lessonElementId)?.index;
    navMenuManager.setSelectedActivityNavigatorLessonElementIndex(currentActivityNavigatorLessonElementIndex);

    await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
  };

  static navigatedAwayFromQuestion = (CONTENT_TYPE_OBJ) => {
    return this.getCurrentQuestionType() !== CONTENT_TYPE_OBJ.type;
  }

  static getCurrentQuestionType = (lessonElementId) => {
    return lessonManager.getSlideModel(lessonElementId || lessonManager.currentLessonElementId)?.type;
  }

  static getCurrentUserInputValue = ({ dataId, lessonElementState, userInputMap } = {}) => {
    return (
      lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text?.label
        || lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text
        || userInputMap?.get(dataId)?.text?.label
        || userInputMap?.get(dataId)?.text
        || ''
    );
  }

  static getGenericMultichoiceStyleObjs = ({
    model,
    viewportWidth = window.innerWidth
  } = {}) => {
    let answerContainerStyle, answerDivStyle;
    if (model.layout === 'grid') {
      const { getStyleVar } = styleService;

      const maxGridColumnsCountPerRow = this.getMaxGridColumnsCountPerRow({
        model, viewportWidth
      });
      const gridColumnCount = Math.min(model.gridColumnCount, maxGridColumnsCountPerRow);

      answerContainerStyle = {
        columnGap: getStyleVar('--theme-grid-column-gap'),
        display: 'grid',
        gridTemplateColumns: `repeat(${gridColumnCount}, ${model.gridColumnWidth}px)`
      };
      answerDivStyle = { width: `${model.gridColumnWidth}px` };
      return { answerContainerStyle, answerDivStyle };
    } else {
      answerContainerStyle = {};
      answerDivStyle = {};
      return { answerContainerStyle, answerDivStyle };
    }
  }

  static getMaxGridColumnsCountPerRow = ({
    activityWithQuestionsMaxContainerWidth = 960,
    model,
    viewportWidth = window.innerWidth
  } = {}) => {
    if (!model || model?.layout !== 'grid' || !model.gridColumnCount || !model.gridColumnWidth) {
      return 1;
    }

    const { getStyleVar } = styleService;
    const { stripNonNumeric } = utilsService;

    const { /* gridColumnCount\, */gridColumnWidth } = model;

    let maxContainerWidth;

    // if viewport is greater than '--theme-max-viewport-width-for-grid-wrapping',
    // just return the `gridColumnCount` defined in the model (i.e. do not force wrap)
    const maxViewportWidthForGridWrapping = +stripNonNumeric(getStyleVar('--theme-max-viewport-width-for-grid-wrapping'));
    if (viewportWidth > maxViewportWidthForGridWrapping) {
      return model.gridColumnCount;
    }

    if (!model.isActivityPart) {
      maxContainerWidth = Math.max(activityWithQuestionsMaxContainerWidth, (viewportWidth - gridColumnWidth));
    } else {
      maxContainerWidth = Math.min(activityWithQuestionsMaxContainerWidth, viewportWidth);
    }

    maxContainerWidth = Math.min(maxContainerWidth, viewportWidth);

    const GRID_COLUMN_GAP = +stripNonNumeric(getStyleVar('--theme-grid-column-gap'));

    // TODO unused // const totalGridWidth = (gridColumnWidth + GRID_COLUMN_GAP) * gridColumnCount;

    const maxGridColumnsCountPerRow = Math.floor(maxContainerWidth / (gridColumnWidth + GRID_COLUMN_GAP));

    return maxGridColumnsCountPerRow > 0 ? maxGridColumnsCountPerRow : (model?.gridColumnCount || 1);
  }
}
