import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

const HiddenAudioPlayer = observer((props) => {
  const {
    audioManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  return (
    <>

      <div className='audio-resource-control-hidden'>
        <audio autoPlay controls controlsList='nodownload' id='main-c2c-audio-player' />
      </div>
    </>
  );
});

export default HiddenAudioPlayer;
