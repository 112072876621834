import { camelCase } from 'lodash';
import { toJS } from 'mobx';
import styleManager from '../managers/StyleManager';
import lessonManager from '../managers/LessonManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import responseManager from '../managers/ResponseManager';
import { LessonMode } from '../../Constants';

export default class StyleService {
  /**
   * get the value of a CSS variable for the current active theme
   * ```
   * // example
   * getStyleVar('--theme-font-size'); // '15px'
   * ```
   */
  static getStyleVar = (styleVariable = '') => {
    return getComputedStyle(document.documentElement)
      ?.getPropertyValue(styleVariable)?.trim();
  }

  static setStyleVar = (styleVariable = '', value = '') => {
    // Sets a css variable at the top level to the value
    document.documentElement.style.setProperty(styleVariable, value);
    styleManager.setVariableOverride(styleVariable, value);
  }

  static resetStyleVar = (styleVariable = '') => {
    // Removes a css variable at the top level
    document.documentElement.style.setProperty(styleVariable, null);
    styleManager.removeVariableOverride(styleVariable);
  }

  static resetAllOverrideVariables = () => {
    // Removes all css variables that were added with setStyleVar
    for (const styleVariable in styleManager.variableOverrides) {
      StyleService.resetStyleVar(styleVariable);
    }
  }

  static getSvgCaretDown = () => {
    return (
      <svg className='bi bi-caret-down-fill' fill='currentColor' height='16' viewBox='0 0 16 16'
        width='16' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
      </svg>
    );
  }

  static convertStrToStyleObj = (styleStr = '') => {
    const regex = /([\w-]*)\s*:\s*([^;]*)/g;
    let match;
    const styleObj = {};
    // eslint-disable-next-line no-cond-assign
    while (match = regex.exec(styleStr)) {
      styleObj[camelCase(match[1])] = match[2].trim();
    }
    return styleObj;
  }

  /**
  Initialize .RevealText dom elements to add click events and set up state persistence.
   */
  static initRevealTextEventListener = (lessonElementId, containerId) => {
    const element = document.getElementById(containerId);

    if (element) {
      const revealTextElems = element.querySelectorAll('.RevealText');
      const revealTextIds = Array.from(revealTextElems).map((element) => element.id);
      const lessonElementState = responseManager.getLessonElementState(lessonElementId);
      const revealTextPersistenceData = lessonElementState?.getPersistentToolData('RevealText');

      revealTextIds.forEach((revealTextId, index) => {
        const revealTextElement = document.getElementById(revealTextId);

        // Set data attributes for lessonElementId and containerId.
        // These will be used when the .RevealText elements are clicked.
        // Set data-ignore='1' to prevent TTS from reading.
        revealTextElement.setAttribute('data-lesson-element-id', lessonElementId);
        revealTextElement.setAttribute('data-container-id', containerId);
        revealTextElement?.setAttribute('data-ignore', '1');

        // Set the click event listeners
        revealTextElement.addEventListener('click', this.revealTextEventListener);

        // Count it as available.
        questionFeedbackManager.addButtonAvailabilty('RevealText');
      });

      // If the lessonElement has existing 'RevealText' tool data,
      // set the revealed state of the .RevealText domElements.
      // NOTE: .RevealText state persistence is in an array-like object with elements:
      // { 'id':revealTextId, 'revealed':isRevealed }
      if (revealTextPersistenceData) {
        const revealTextPersistence = toJS(revealTextPersistenceData);

        for (const key in revealTextPersistence) {
          const revealTextElementData = revealTextPersistence[key];
          if (revealTextElementData && revealTextElementData.revealed) {
            const revealTextId = revealTextElementData.id;
            const revealTextElement = document.getElementById(revealTextId);

            revealTextElement?.classList.add('revealed');
            revealTextElement?.removeAttribute('data-ignore');
          }
        }
      }
    }
  }

  /**
  Remove the click events from all .RevealText dom elements within the containerId.
   */
  static removeRevealTextEventListener = (containerId) => {
    const element = document.getElementById(containerId);
    const revealTextElems = element?.querySelectorAll('.RevealText');

    if (element && revealTextElems) {
      const revealTextIds = Array.from(revealTextElems).map((element) => element.id);

      revealTextIds.forEach((revealTextId, index) => {
        const revealTextElement = document.getElementById(revealTextId);

        revealTextElement.removeEventListener('click', this.revealTextEventListener);
      });
    }
  }

  /**
  The click event listener for .RevealText dom elements.
   */
  static revealTextEventListener = (event) => {
    const clickedElement = event.target;
    const revealTextElement = clickedElement.closest('.RevealText');
    const lessonElementId = revealTextElement?.getAttribute('data-lesson-element-id');
    const containerId = revealTextElement?.getAttribute('data-container-id');

    // When a ".RevealText" element is clicked, check if it has already been revealed.
    // If not, add the ".revealed" className, and persist the state of all the .RevealText
    // elements in the clicked element's container.
    if (revealTextElement && !revealTextElement.classList.contains('revealed')) {
      // Add the ".revealed" className to the clicked element, and make the text readable.
      revealTextElement.classList.add('revealed');
      revealTextElement?.removeAttribute('data-ignore');

      // Persist the revealed state of the ".RevealText" elements.
      const data = [];

      const element = document.getElementById(containerId);

      if (element) {
        const revealTextElems = element.querySelectorAll('.RevealText');
        const revealTextIds = Array.from(revealTextElems).map((element) => element.id);

        revealTextIds.forEach((revealTextId, index) => {
          const revealTextElement = document.getElementById(revealTextId);
          const isRevealed = revealTextElement.classList.contains('revealed');

          data.push({ id: revealTextId, revealed: isRevealed });
        });

        this.persistRevealTextState(lessonElementId, data);
      }
    }

    questionFeedbackManager.addButtonUsage('RevealText');
  }

  /**
    Persist RevealText state if the lesson is an active assignment.
   */
  static persistRevealTextState(lessonElementId, data) {
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);

    if (lessonManager.playerMode === LessonMode.ACTIVITY_MODE) {
      lessonElementState?.setPersistentToolData('RevealText', data);
    }
  }
}
