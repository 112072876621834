import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import userWirisParser from '../../hooks/useWirisParser';
import HtmlComponent from './HtmlComponent';

const TitlePage = observer(() => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  if (!lessonManager.lesson || !lessonManager.lessonOptions) {
    return (<div className='title-page-wrapper' />);
  }

  const lessonName = lessonManager.lessonTitle;
  const lessonSubtitle = lessonManager.lessonOptions.subtitle;

  const containerRef = useRef();
  userWirisParser(containerRef);

  const hasTitlePageUrl = !!(lessonManager.lessonOptions.titlePageImageUrl);

  return (
    <div ref={containerRef} className='title-page-wrapper' style={(hasTitlePageUrl) ? { backgroundImage: 'none' } : {}}>
      <div className='title-bar-wrapper'>
        <div className='title-bar'>
          <div className='lesson-title'><HtmlComponent htmlStr={lessonName} /></div>
          {lessonSubtitle && <div className='lesson-subtitle'><HtmlComponent htmlStr={lessonSubtitle} /></div>}
        </div>
      </div>
    </div>
  );
});

export default TitlePage;
