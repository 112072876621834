import classNames from 'classnames';
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import HtmlComponent from '../HtmlComponent';

const StandardsFooter = observer(({ lessonElementId }) => {
  const {
    lessonManager, // eslint-disable-line no-unused-vars
    alignmentManager,
  } = useContext(MobXProviderContext);

  const items = [];
  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElement = lessonManager.getLessonElement(lessonElementId);

  const alignmentPaths = alignmentManager.alignmentCoveragePaths.get(lessonElementId);
  const standardTags = alignmentManager.taggedStandards.get(lessonElement.entityId);

  Object.values(alignmentManager.alignmentTreeStructure).forEach((item) => {
    if (alignmentPaths && alignmentPaths.indexOf(item.data.coveragePath) > -1) {
      items.push(item.data.name);
    }
  });

  return (

    <div className={classNames({'activity':model.isActivity},'print-standards-list')}>
      {(alignmentPaths) ? items.map((name, index) => {
        console.log(name);
        return (
          <span key={index} className='standard-code'><HtmlComponent htmlStr={name} /></span>
        );
      }) : <></>}
      {(standardTags && standardTags.length > 0) ? standardTags.map((name, index) => {
        console.log(name);
        return (
          <span key={index} className='standard-code'><HtmlComponent htmlStr={name} /></span>
        );
      }) : <></>}
    </div>

  );
});

export default StandardsFooter;
