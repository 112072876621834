import React, { useEffect } from 'react';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import AudioFeedback from '../AudioFeedback';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const HotText = ({ model, isManuscriptShort }) => {
  let body = '';
  if (model.body) {
    body = model.body.replaceAll('contenteditable', 'content');
  }

  useEffect(() => {
    for (const id of model.validation.correctAnswers) {
      const elem = document.querySelector(`span[data-id="${id}"]`);
      elem.className = 'selected-text correct';
    }

    for (const id of model.validation.incorrectAnswers) {
      const elem = document.querySelector(`span[data-id="${id}"]`);
      elem.className = 'selected-text';
    }
  });

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Hot Text</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div>Test:</div>
        <div><img alt='' src='../../images/test.svg' /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Question:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Text:</div>
        <div className='ckeditor-text text-area selectable-text'><HtmlComponent htmlStr={body} /></div>
      </div>
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <AudioFeedback model={model} />
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default HotText;
