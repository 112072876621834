import React from 'react';
import HtmlComponent from '../../HtmlComponent';

const TextBlockWidget = ({ model }) => {
  const text = model.text || 'Text Block';
  const display = (model.display) ? model.display : 'visible';

  return (
    <div className={`text-block ${display}`} id={model.id}>
      <div className='widgetText widgetContent ' data-allow='1'><HtmlComponent htmlStr={text} /></div>
    </div>
  );
};

export default TextBlockWidget;
