import { computed, makeObservable, observable } from 'mobx';
import lessonManager from '../LessonManager';
import { LessonMode } from '../../../Constants';

export class UiFeedbackState {
  @observable
  submitButtonVisible = false;

  @observable
  submitButtonDisabled = false;

  @observable
  tryAgainButtonVisible = false;

  @observable
  loadingButtonVisible = false;

  @observable
  showAnswersButtonVisible = false;

  @observable
  hintButtonVisible = false;

  @observable
  solutionButtonVisible = false;

  @observable
  showAnswerFeedback = false; // the feedback for the student response (feedback icons or what ever shows student
  // correct or incorrect

  @observable
  showQuestionFeedback = false; // feedback at the bottom of questions

  @observable
  showFeedbackImages = false;

  @observable
  showAnswers = false; // list of expected answers at the bottom of questions or if the question component needs to show
  // the final correct answer some other way

  @computed
  get showFeedbackImagesNow() {
    if (this.showFeedbackImages === true && (lessonManager.playerMode === LessonMode.ACTIVITY_MODE || lessonManager.playerMode === LessonMode.PREVIEW || lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW)) {
      return true;
    }
    return false;
  }

  @observable
  currentAttemptCount = 0;

  constructor() {
    makeObservable(this);
  }
}
