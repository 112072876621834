import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/Multichoice.scss';
import { LessonMode } from '../../../Constants';

import questionService from '../../services/QuestionService';
import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';

import { useViewportSize } from '../../../hooks/useViewportSize';

import FeedbackIcon from '../FeedbackIcon';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const Multichoice = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
    toolbarManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  const { width: viewportWidth } = useViewportSize();

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager
    .getLessonElementState(lessonElementId);
  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);

  const { isEliminatorActive } = toolbarManager;

  const readOnly = (lessonElementState && lessonElementState.readOnly)
    || (feedbackState && feedbackState.showAnswers)
    || lessonManager.isReadOnly;

  const { showAnswerFeedback } = feedbackState;
  const alignRadioSymbolTop = model.anserTopJustify;

  const optionNames = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  let correctAnswerId = '';
  // incase the publisher is previewing before putting in the question answers
  if (model.validation && model.validation.answers && model.validation.answers.length > 0) {
    const correctAnswer = model.validation.answers.find((answer) => answer.isCorrect);
    if (correctAnswer) {
      correctAnswerId = correctAnswer.id;
    }
  }

  useStyleEvents(lessonElementId);

  const handleDataChange = (selectedId) => {
    responseService.responseChangeHandler({
      action: isEliminatorActive ? 'eliminate' : 'select',
      id: selectedId
    }, lessonElementId);

    if (isEliminatorActive) {
      toolbarManager.addContentToolUsage('Eliminator');
    }
  };

  // Create handlers for state change
  const handleClick = (data) => {
    // Get the value of the radio button that was selected
    const selectedId = data.target.value;
    handleDataChange(selectedId);
  };

  const handleKeyPress = (event) => {
    // If space was pressed, select the value of the element that spawned the event
    // If an arrow key was pressed, select the next or previous element

    // We have actually selected the parent of the parent so we need to find the input inside of it.
    const getTargetValue = (target) => target.getElementsByTagName('input')[0]?.value;

    // One child of event.target is the radio button which has a value of the answer id
    const radioId = getTargetValue(event.target);
    if (radioId == null) {
      // If no radio button was selected, buttons will be handled by the browser without intervention
      return;
    }
    if (event.code === 'Space') {
      // Set the answer id to the selected radio button
      handleDataChange(radioId);
    } else if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
      // Move the focus to the next or previous radio button and focus that one
      const currentlySelectedIndex = processedAnswers.findIndex((answer) => answer.id === radioId);
      let nextIndex = (currentlySelectedIndex + (event.code === 'ArrowUp' ? -1 : 1)) % processedAnswers.length;
      if (nextIndex < 0) {
        nextIndex = processedAnswers.length - 1;
      }
      // Get the radio button element that should be focused
      const nextElement = event.target.parentElement.children[nextIndex];
      handleDataChange(getTargetValue(nextElement));
      nextElement.focus();
    }
  };

  // Prepare the radio buttons
  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;
  const randomize = model.isRandomized;
  const processedAnswers = (!lessonElementState) ? [] : (randomize) ? utilsService.shuffleArrayForUser([...model.answers], model.questionText) : model.answers;
  // The currently selected answer is the element of currentResponseAnswer.answers that has isCorrect==true
  const selectedAnswerId = (!lessonElementState) ? '' : currentResponseAnswer.answers.find((answer) => answer.isCorrect)?.id;
  const isAnswerEliminated = (!lessonElementState) ? false : (id) => {
    return currentResponseAnswer.answers.find((answer) => answer.id === id)?.isEliminated;
  };

  return (
    <div className='multi-choice-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div aria-labelledby={`test-item-question-${lessonElementId}`}
        className={`test-item-answers${(model.layout === 'grid') ? ' grid' : ''}${(model.layout === 'grid') ? ` col_${model.gridColumnCount}` : ''}`} role='radiogroup'
        style={questionService.getGenericMultichoiceStyleObjs({ model, viewportWidth })?.answerContainerStyle}>
        {
          processedAnswers.map(({ id, text }, index) => {
            const isSelected = id === selectedAnswerId;
            const isCorrectAnswer = id === correctAnswerId;
            const isEliminated = isAnswerEliminated(id);
            const symbol = optionNames[index];
            let visualClass = '';
            if (showAnswerFeedback && isSelected) {
              if (isCorrectAnswer) {
                visualClass = 'correct';
              } else {
                visualClass = 'incorrect';
              }
            }

            return (
              <label
                key={`${id}_${lessonElementId}`}
                aria-checked={isSelected} className={`radio-container ${isEliminated ? 'eliminated' : ''}`}
                htmlFor={`${id}_${lessonElementId}`} onKeyDown={handleKeyPress}
                role='radio'
                style={questionService.getGenericMultichoiceStyleObjs({ model, viewportWidth })?.answerDivStyle}
                tabIndex={readOnly ? -1 : 0}>
                <div className='radio-text-container' tabIndex={-1}>
                  <input checked={isSelected} disabled={readOnly} id={`${id}_${lessonElementId}`} onChange={handleClick}
                    tabIndex={-1} type='radio' value={id} />
                  <label className={`radio-symbol ${isSelected ? 'selected' : ''} ${visualClass} ${readOnly ? 'disabled' : ''} ${alignRadioSymbolTop ? 'align-top' : ''}`} htmlFor={`${id}_${lessonElementId}`} tabIndex={-1}>{symbol}</label>
                  <label htmlFor={`${id}_${lessonElementId}`} tabIndex={-1}><HtmlComponent htmlStr={text} /></label>
                  {isSelected && (
                    <div className={`correct-answer-symbol ${alignRadioSymbolTop ? 'align-top' : ''}`}>
                      <FeedbackIcon lessonElementId={lessonElementId} />
                    </div>
                  )}
                </div>
              </label>
            );
          })
        }
      </div>
    </div>
  );
});
export default Multichoice;
