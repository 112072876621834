import { Behavior } from './Behavior';
import { ContentType, LessonMode, PartialScoringType, QuestionFeedbackState } from '../../Constants';
import UtilsService from '../services/UtilsService';

export class MultiselectBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    // The answer is valid if any of the answers are selected
    return responseAnswer.answers.some(({ isCorrect }) => isCorrect);
  }

  setCorrectAnswer = (state, model) => {
    const response = UtilsService.safeMobxClone(model.validation);

    response.selectedIncorrectList = UtilsService.safeMobxClone([]);
    response.omittedList = UtilsService.safeMobxClone([]);
    response.trueCorrectList = UtilsService.safeMobxClone([]);

    state.setCurrentResponse(response);
  }

  resetStudentAnswer = (state) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  getScore = (responseAnswer, model) => {
    // There are different scoring types that need to be handled differently. The general thing we can do is count how many answers matched their isCorrect and how many did not
    const studentAnswers = responseAnswer.answers;
    const correctAnswers = model.validation?.answers;

    if (!responseAnswer.selectedIncorrectList || !responseAnswer.omittedList || !responseAnswer.trueCorrectList) {
      responseAnswer.selectedIncorrectList = [];
      responseAnswer.omittedList = [];
      responseAnswer.trueCorrectList = [];
    } else {
      responseAnswer.selectedIncorrectList.clear();
      responseAnswer.omittedList.clear();
      responseAnswer.trueCorrectList.clear();
    }

    const { trueCorrect, trueIncorrect, falseCorrect, falseIncorrect } = (correctAnswers || []).reduce((acc, { id, isCorrect }) => {
      // Find the student's answer
      const studentAnswer = studentAnswers.find(({ id: answerId }) => answerId === id);
      if (studentAnswer && studentAnswer.isCorrect === isCorrect) {
        // Then the student was correct
        if (studentAnswer && studentAnswer.isCorrect) {
          acc.trueCorrect++;
          responseAnswer.trueCorrectList.push(id);
        } else {
          acc.trueIncorrect++;
        }
      } else {
        // Then the student was incorrect, now we need to know whether it was falsely marked correct or falsely marked incorrect
        if (studentAnswer && studentAnswer.isCorrect) {
          // The student falsely marked the answer as correct
          acc.falseCorrect++;
          responseAnswer.selectedIncorrectList.push(id);
        } else {
          // The student falsely marked the answer as incorrect
          acc.falseIncorrect++;
          responseAnswer.omittedList.push(id);
        }
      }
      return acc;
    }, { trueCorrect: 0, trueIncorrect: 0, falseCorrect: 0, falseIncorrect: 0 });
    const { maxScore } = model;
    let score;
    switch (model.scoringType) {
    case (PartialScoringType.PARTIAL): {
      // In this case, you get a point for each true result. So this sums the trueCorrect and trueIncorrect. This is out of the total number of answers.
      const totalAnswers = correctAnswers.length;
      score = ((trueCorrect + trueIncorrect) / totalAnswers) * maxScore;
      break;
    }
    case (PartialScoringType.PARTIAL_MATCH_RESPONSE): {
      // In this case, you get a point for trueCorrect answers. This is out of the total correct answers.
      const totalCorrectAnswers = correctAnswers.filter(({ isCorrect }) => isCorrect).length;
      score = (trueCorrect / totalCorrectAnswers) * maxScore;
      break;
    }
    case PartialScoringType.EXACT: {
      // In this case, you get full points if you have no false results or no points otherwise
      score = (falseCorrect + falseIncorrect) === 0 ? maxScore : 0;
      break;
    }
    default: {
      break;
    }
    }
    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    // data is an id and checked of one of the answers. We want to set the isCorrect property to true on that answer
    const { action, id, checked } = data;
    const answer = responseAnswer.answers.find(({ id: answerId }) => answerId === id);
    if (action === 'select') {
      answer.isCorrect = checked;
      answer.isEliminated = false;
    } else if (action === 'eliminate') {
      answer.isEliminated = !answer.isEliminated;
    }
  }

  getInitializedResponse = (lessonElementId, model) => {
    return {
      answers: model.answers.map(({ id }) => ({ id, isCorrect: false, isEliminated: false })),
      lessonElementId,
      selectedIncorrectList: [],
      omittedList: [],
      trueCorrectList: []
    };
  }

  isAutoScored = (model) => {
    return (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
  }

  getCorrectAnswersText = (model) => {
    // Finds the joins an array of all answer text that are correct
    const correctAnswerTexts = (model.validation?.answers || []).reduce((acc, { id, isCorrect }) => {
      if (isCorrect) {
        const answerText = model.answers.find(({ id: answerId }) => answerId === id).text;
        acc.push(answerText);
      }
      return acc;
    }, []);
    return `<div>${correctAnswerTexts.join('')}</div>`;
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let numberOmitted = 0;
    let numberSelectedIncorrect = 0;
    let correctCount = 0;
    if (lessonElementState && lessonElementState.currentResponseAnswer) {
      numberOmitted = (lessonElementState.currentResponseAnswer.omittedList) ? lessonElementState.currentResponseAnswer.omittedList.length : 0;
      numberSelectedIncorrect = (lessonElementState.currentResponseAnswer.selectedIncorrectList) ? lessonElementState.currentResponseAnswer.selectedIncorrectList.length : 0;
      correctCount = (lessonElementState.currentResponseAnswer.trueCorrectList) ? lessonElementState.currentResponseAnswer.trueCorrectList.length : 0;
    }
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';
    const showPartialMessage = (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) || (questionIsCorrect === QuestionFeedbackState.INCORRECT);
    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if ((showPartialMessage) && (model.correctAnswerCount > 1) && (correctCount > 0)) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
      }
      if (numberOmitted > 0 && numberSelectedIncorrect === 0) {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt2()}.<br/>${ custom }${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody}<br/> ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model, isPracticeRunning) => {
    const response = lessonElementState.currentResponseAnswer;
    const answer = response.answers.find((element) => element.id === answerId);
    if (isPracticeRunning) {
      if (answer && response.selectedIncorrectList && response.omittedList && response.trueCorrectList) {
        if (answer.isCorrect && response.selectedIncorrectList.indexOf(answer.id) > -1) {
          return false;
        }
        if (!answer.isCorrect && response.omittedList.indexOf(answer.id) > -1) {
          return null;
        }
        if (answer.isCorrect && response.trueCorrectList.indexOf(answer.id) > -1) {
          return true;
        }
      }
    } else {
      const correctAnswers = model.validation?.answers;
      const correctAnswer = correctAnswers.find((element) => element.id === answerId);
      if (correctAnswer && answer && correctAnswer.isCorrect && answer.isCorrect) {
        return true;
      } else if (correctAnswer && answer && !correctAnswer.isCorrect && !answer.isCorrect) {
        return null;
      } else if (correctAnswer && answer && !correctAnswer.isCorrect && answer.isCorrect) {
        return false;
      }
      // TODO: This code was saying if the user didn't click this answer, but this answer was correct show the incorrect feedback. We don't want any feedback to show if the user doesn't click the specific answer.
      // else if ( correctAnswer && answer && correctAnswer.isCorrect && !answer.isCorrect ) {
      //   return false;
      // }
    }
    return null;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new MultiselectBehavior();
