import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import Auth from '../services/AuthService';

const AudioPlayer = observer((props) => {
  const { lessonElementId } = props;
  const { autoPlay } = props;

  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const audioResourceId = (model && model.audioResourceId) ? model.audioResourceId : null;
  const urlSource = (audioResourceId)
    ? `${Auth.ecms}/api/redirectToStreamUrl?authKey=${Auth.authKey}&resourceFolder=userFiles&entityId=${audioResourceId}&entityTypeId=audio_resource`
    : null;

  const play = (model.contentAudioAutoplay) ? model.contentAudioAutoplay : (autoPlay) || false;

  useEffect(() => {
    // If there are multiple audio players in a lesson we need to reload the audio element
    // whenever the lessonElementIds change. This fixes an issue that caused the same audio
    // to play in every audio player
    const audio = document.getElementById(`${lessonElementId}_audio`);
    if (audio) {
      audio.load();
    }
  }, [lessonElementId, lessonManager.currentLessonElementId]);

  // When playing an audio, grab all other audios and pause them.
  const pauseOtherAudio = (e) => {
    const audios = document.getElementsByTagName('audio');
    for (const audio of audios) {
      if (audio && audio !== e?.target) {
        audio.pause();
      }
    }
  };

  return (
    <>
      {urlSource && (
        <div className='audio-resource-control'>
          {(play) ? (
            <audio autoPlay controls controlsList='nodownload' id={`${lessonElementId}_audio`}
              onPlay={pauseOtherAudio}>
              <source src={urlSource} />
            </audio>
          ) : (
            <audio controls controlsList='nodownload' id={`${lessonElementId}_audio`} onPlay={pauseOtherAudio}>
              <source src={urlSource} />
            </audio>
          )}
        </div>
      )}
    </>
  );
});

export default AudioPlayer;
