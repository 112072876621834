import { toJS } from 'mobx';
import { QuestionFeedbackState } from '../../Constants';
import { Behavior } from './Behavior';
import UtilsService from '../services/UtilsService';

export class VennBehavior extends Behavior {
  checkForValidResponse = (responseAnswer, _model) => {
    const prompts = toJS(responseAnswer?.prompts || []);
    const hasSomeEmptyResponses = prompts.some?.(({ text } = {}) => !text);
    return !hasSomeEmptyResponses;
  }

  isAutoScored = (_model) => {
    return false;
    // const autoScore = ContentType.getAutoscore(model.type) && !model.survey && !model.unscored;
    // return autoScore;
  }

  getScore = (_responseAnswer, _model) => {
    // this content type is not autoscored

  }

  setResponseData = ({ event, index }, responseAnswer, _model) => {
    const prompts = toJS(responseAnswer.prompts);
    if (index >= 0) {
      const value = event?.target?.value || '';
      const data = {
        id: `promptId${index}`,
        text: value
      };
      prompts[index] = data;
      responseAnswer.prompts = prompts;
    }
  }

  getInitializedResponse = (_lessonElementId, model, _activityId) => {
    const commonTextLength = model.commonText?.length || 0;
    const leftTextLength = model.leftText?.length || 0;
    const rightTextLength = model.rightText?.length || 0;

    const promptsLength = commonTextLength + leftTextLength + rightTextLength;

    const prompts = Array(promptsLength).fill();

    // populate readonly `left` prompts using the readonly (model.leftText) values
    for (let i = 0, j = 0; j < leftTextLength; i++, j++) {
      prompts[j] = {
        id: `promptId${j}`,
        text: model.leftText[i] || ''
      };
    }
    // populate readonly `right` prompts using the readonly (model.rightText) values
    for (let i = 0, j = leftTextLength; j < (promptsLength - commonTextLength); i++, j++) {
      prompts[j] = {
        id: `promptId${j}`,
        text: model.rightText[i] || ''
      };
    }
    // populate readonly `center/common` prompts using the readonly (model.commonText) values
    for (let i = 0, j = (leftTextLength + rightTextLength); j < promptsLength; i++, j++) {
      prompts[j] = {
        id: `promptId${j}`,
        text: model.commonText[i] || ''
      };
    }

    const responseAnswer = {
      isScored: false,
      isScoredCorrect: false,
      prompts: [...prompts]
    };

    return responseAnswer;
  }

  getCorrectAnswersText = (_model) => {
    // method is unused by this response type
    return null;
  }

  setCorrectAnswer = (_state, _model) => {
    // this content type is not autoscored

  }

  resetStudentAnswer = (_state) => {
    // method is unused by this content type
    // state.currentResponseAnswer = UtilsService.safeMobxClone(state.cachedResponseAnswer);
  }

  getQuestionFeedbackText = (
    _model, _correctFeedback, _incorrectFeedback, _correctFeedbackBody, _incorrectFeedbackBody,
    _mode, _questionIsCorrect, _currentAttemptCount/* , { lessonElementState } */
  ) => {
    const textHeader = '';
    const textBody = UtilsService.saveCommit();

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;

    // TODO unused; this content type is not autoscored so we do not have this feedback
    // let textBody = '';
    // if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
    //   textBody += UtilsService.previewWarning();
    // } else {
    //   if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount>1) {
    //     textBody += UtilsService.saveWarning();
    //   } else {
    //     textBody += UtilsService.saveCommit();
    //   }
    // }

    // let textHeader = '';

    // if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
    //   textHeader = correctFeedback;
    //   if (correctFeedbackBody) {
    //     textBody = correctFeedbackBody+' '+textBody;
    //   }
    // } else if ( questionIsCorrect ===  QuestionFeedbackState.PARTIAL_CORRECT ) {
    //   textHeader = `${UtilsService.partiallyCorrect()}`;
    //   let custom = '';
    //   if (incorrectFeedbackBody) {
    //     custom = incorrectFeedbackBody + ' ';
    //   }

    //   textBody = UtilsService.partiallyCorrectPrompt() + ' <br/>' + custom + textBody;
    // } else {
    //   textHeader = incorrectFeedback;
    //   if (incorrectFeedbackBody) {
    //     textBody = incorrectFeedbackBody+' '+textBody;
    //   }
    // }
  }

  isAnswerCorrect = (_answerId, _lessonElementState, _model) => {
    // return lessonElementState.isScored && (lessonElementState.scoreValue === lessonElementState.maxScore);
    return false;
  }

  isQuestionCorrect = (lessonElementState, _lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }
    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }
    return QuestionFeedbackState.INCORRECT;
  }
}

export default new VennBehavior();
