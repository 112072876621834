import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { toJS } from 'mobx';

import classNames from 'classnames';

import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

import '../../../css/OrderSentences.scss';
import { LessonMode } from '../../../Constants';

import responseService from '../../services/ResponseService';
import playerService from '../../services/PlayerService';

import HtmlComponent from '../HtmlComponent';
import Guideline from '../tools/Guideline';
import OptionalImage from '../OptionalImage';
import useStyleEvents from '../../../hooks/useStyleEvents';
import navMenuService from '../../services/NavMenuService';
import PrintQuestionNumber from './PrintQuestionNumber';

const Droppable = (props) => {
  const { setNodeRef, isOver } = useDroppable({
    id: props.id,
  });

  return (
    <div ref={setNodeRef} className={classNames(props.className, {
      duringDragOver: isOver
    })}>
      {props.children}
    </div>
  );
};

const Draggable = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    disabled: props.disabled,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div ref={setNodeRef} className={props.className} style={style} {...listeners}
      {...attributes}>
      {props.children}
    </div>
  );
};

const OrderPrompt = ({ model, id, orderedPromptItems, dragOverlay, showAnswers = false, index = null }) => {
  let isCorrect = false;
  if (showAnswers) {
    const correctAnswerId = model.validation?.prompts[index];
    const submittedAnswerId = id;
    isCorrect = (correctAnswerId === submittedAnswerId);
  }

  let promptText;
  for (const promptItem of orderedPromptItems) {
    if (promptItem.id === id) {
      promptText = promptItem.text;
    }
  }
  return (
    <div
      className={classNames({
        orderSentencePrompt: true,
        inlineDisplay: true,
        draggable: true,
        duringDrag: dragOverlay,
        correct: (showAnswers && isCorrect),
        incorrect: (showAnswers && !isCorrect),
      })}>
      <HtmlComponent htmlStr={promptText} useSpan={true} />
    </div>
  );
};

const OrderSentences = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const sensors = useSensors(
    useSensor(KeyboardSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      }
    })
  );

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
  const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;
  const showAnswers = (feedbackState && showAnswerFeedback);

  const disabled = lessonElementState.isSubmitted || playerService.isReadOnly(lessonElementId);

  const [orderedPromptItems, setOrderedPromptItems] = useState(toJS(lessonElementState.currentResponseAnswer.promptObjects));
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    setOrderedPromptItems(toJS(lessonElementState.currentResponseAnswer.promptObjects));
  }, [lessonElementState.currentResponseAnswer.promptObjects]);

  const reorder = (sentences = [], active, over) => {
    const dragIndex = sentences.findIndex((sentence) => {
      return sentence.id === active.id;
    });

    const dropIndex = sentences.findIndex((sentence) => {
      return sentence.id === over.id;
    });

    const result = Array.from(sentences);
    const [removed] = result.splice(dragIndex, 1);

    // Insert draggable AFTER droppable
    if (dropIndex - 1 === dragIndex) {
      result.splice(dropIndex, 0, removed);
    }
    // Insert draggable BEFORE droppable
    else {
      if (dropIndex < dragIndex) {
        result.splice(dropIndex, 0, removed);
      } else {
        result.splice(dropIndex - 1, 0, removed);
      }
    }

    return result;
  };

  const navToCurrentLessonElement = async () => {
    if (lessonManager.currentLessonElementId !== lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
    }
  };

  const handleDragStart = (event) => {
    navToCurrentLessonElement();
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    setActiveId(null);
    if (event.over === null) {
      return;
    }

    const orderPrompts = reorder(
      lessonElementState.currentResponseAnswer.promptObjects,
      event.active,
      event.over
    );
    setOrderedPromptItems(orderPrompts);
    responseService.responseChangeHandler(orderPrompts, lessonElementId);
  };

  useStyleEvents(lessonElementId);

  return (
    <>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question' id='test-item-question'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='promptWrapper'>
        <DndContext
          modifiers={[snapCenterToCursor]}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          sensors={sensors}
        >
          {orderedPromptItems.map((orderPrompt, index) => {
            return (
              <Droppable key={index} className='droppable-container' id={orderPrompt.id}>
                <Draggable
                  className='draggable-container'
                  disabled={disabled}
                  id={orderPrompt.id}
                >
                  <OrderPrompt dragOverlay={false} id={orderPrompt.id} index={index} model={model}
                    orderedPromptItems={orderedPromptItems} showAnswers={showAnswers} />
                </Draggable>
              </Droppable>
            );
          })}
          <DragOverlay className='drag-overlay'
            dropAnimation={{
              duration: 0,
            }}
            style={{
              maxWidth: 250,
            }}>
            {activeId ?
              <OrderPrompt dragOverlay={true} id={activeId} model={model} orderedPromptItems={orderedPromptItems} />
              : null}
          </DragOverlay>
        </DndContext>
      </div>
    </>
  );
});
OrderSentences.displayName = 'OrderSentences';
export default OrderSentences;
