import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../css/GenericProgressBar.scss';

import { register } from '../../i18n';

const t = register('NavigationBar');

const GenericProgressBar = observer((props) => {
  const {
    itemBankManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  // if (!completed ||
  //   !total) {
  //   return (<></>);
  // }
  const completed = itemBankManager.progressItemsCompleted;
  const total = itemBankManager.progressItemsTotal;
  // const label = `${t('progressTally', { completed, total })}`;
  const label = `${t('progressTally', { completed, total })}`;
  const progress = (completed < 1) ? 0 : (completed / total) * 100;

  const dataStyle = {
    width: `${progress}%`
  };

  return (
    <div className='loader-wrapper'>
      <div className='generic-progress-bar'>
        <div className='bar-container'>
          <div className='bar-data' style={dataStyle}>
            {/* <span style={progresstext}>{`${progress}%`}</span> */}
          </div>
        </div>
        <div className='bar-label'>
          <span>{t('progressLabel')} </span><span className='progress-value'>{label}</span>
        </div>
      </div>
    </div>

  );
});

export default GenericProgressBar;
