import { fabric } from 'fabric';
import CanvasOrganizerService from './CanvasOrganizerService';

export default class VennService {
  canvasOrganizer;

  model;
  canvas;

  canvasHeight;
  canvasWidth;

  RADIUS = 65;
  ORBIT_RADIUS = 215;
  ORBIT_RADIUS_OFFSET = 50;

  ENTRY_BOX_WIDTH = 95;
  ENTRY_BOX_HEIGHT = 49;

  ENTRY_BOX_COMMON_WIDTH = 95; // TODO remove // 100;
  ENTRY_BOX_COMMON_HEIGHT = 49; // TODO remove // 50;

  ENTRY_BOX_STROKE_WIDTH = 1;
  SPACING_HEIGHT = 5;

  CANVAS_WIDTH = 670;
  CANVAS_HEIGHT = 440;

  CENTRAL_CIRCLE_COLOR = '#e6e7e8';
  CENTRAL_CIRCLE_STROKE = '#231f20';
  CENTRAL_CIRCLE_STROKE_WIDTH = 1;
  CENTRAL_CIRCLE_OPACITY = 0.5;
  CENTRAL_CIRCLE_OFFSET = 15;

  constructor(model) {
    this.model = model;
    this.canvasOrganizer = new CanvasOrganizerService(model);
  }

  initCanvas = () => {
    this.canvasHeight = this.CANVAS_HEIGHT;
    this.canvasWidth = this.CANVAS_WIDTH;

    const settings = {
      height: this.CANVAS_HEIGHT,
      width: this.CANVAS_WIDTH
    };
    this.canvas = new fabric.Canvas(this.model.id, settings);
    this.canvasOrganizer.setCanvas(this.canvas);

    this.canvasOrganizer.createArrowClass();
  }

  render = () => {
    if (this.canvas) {
      this.showBoxes();
    }
  }

  getHeight = () => {
    return this.CANVAS_HEIGHT;
  }

  showBoxes = () => {
    this.canvasOrganizer.clearCanvas();

    const bodyHeight = this.canvasHeight;

    const rectangleStrokeColor = 'transparent';

    // draw circle and center box
    const centralCircleLeft = (this.canvasWidth / 2) - this.RADIUS;
    const centralCircleTop = (bodyHeight / 2) - this.ORBIT_RADIUS;
    const centerX = this.CANVAS_WIDTH / 2;
    // const centerY = bodyHeight / 2;

    const hasInput = false;
    const noFill = false;

    if (bodyHeight) {
      // draw left side
      const centerLeftX = centerX - (centerX / 2);
      const centerLeftXLeft = centerLeftX - this.ORBIT_RADIUS + this.ORBIT_RADIUS_OFFSET;
      this.canvasOrganizer.drawCircle(
        centerLeftXLeft, centralCircleTop, this.ORBIT_RADIUS, hasInput, noFill,
        this.CENTRAL_CIRCLE_COLOR, this.CENTRAL_CIRCLE_STROKE, this.CENTRAL_CIRCLE_STROKE_WIDTH,
        this.CENTRAL_CIRCLE_OPACITY
      );

      // draw right side
      const centerRightX = centerX + (centerX / 2);
      const centerRightXLeft = centerRightX - this.ORBIT_RADIUS - this.ORBIT_RADIUS_OFFSET;
      this.canvasOrganizer.drawCircle(
        centerRightXLeft, centralCircleTop, this.ORBIT_RADIUS, hasInput, noFill,
        this.CENTRAL_CIRCLE_COLOR, this.CENTRAL_CIRCLE_STROKE, this.CENTRAL_CIRCLE_STROKE_WIDTH,
        this.CENTRAL_CIRCLE_OPACITY
      );

      // draw outer boxes prep
      const numBoxes = this.model.leftCircleCount;
      const boxesHeight = numBoxes * this.ENTRY_BOX_HEIGHT;
      const spacingHeight = (numBoxes - 1) * this.SPACING_HEIGHT;
      let commonTop = (bodyHeight - boxesHeight - spacingHeight) / 2;
      // draw left outer boxes
      for (let i = 0; i < numBoxes; i++) {
        const lineStroke = this.model.leftText?.[i] ? this.canvasOrganizer.LINE_STROKE_MED : this.ENTRY_BOX_STROKE_WIDTH;
        this.canvasOrganizer.drawRectangle(
          (centerRightXLeft - this.ORBIT_RADIUS + this.ENTRY_BOX_WIDTH), commonTop,
          this.ENTRY_BOX_WIDTH, this.ENTRY_BOX_HEIGHT, lineStroke, rectangleStrokeColor
        );
        commonTop = commonTop + this.SPACING_HEIGHT + this.ENTRY_BOX_HEIGHT;
      }
      // draw right outer boxes
      commonTop = (bodyHeight - boxesHeight - spacingHeight) / 2;
      for (let i = 0; i < numBoxes; i++) {
        const lineStroke = this.model.rightText?.[i] ? this.canvasOrganizer.LINE_STROKE_MED : this.ENTRY_BOX_STROKE_WIDTH;
        this.canvasOrganizer.drawRectangle(
          (centerRightXLeft + this.ORBIT_RADIUS), commonTop,
          this.ENTRY_BOX_WIDTH, this.ENTRY_BOX_HEIGHT, lineStroke, rectangleStrokeColor
        );
        commonTop = commonTop + this.SPACING_HEIGHT + this.ENTRY_BOX_HEIGHT;
      }

      // draw common boxes
      const commonBoxes = this.model.commonCircleCount;
      const commonBoxesHeight = commonBoxes * this.ENTRY_BOX_HEIGHT;
      const commonSpacingHeight = (commonBoxes - 1) * this.SPACING_HEIGHT;
      commonTop = (bodyHeight - commonBoxesHeight - commonSpacingHeight) / 2;
      for (let i = 0; i < commonBoxes; i++) {
        const lineStroke = this.model.commonText?.[i] ? this.canvasOrganizer.LINE_STROKE_MED : this.ENTRY_BOX_STROKE_WIDTH;
        this.canvasOrganizer.drawRectangle(
          (centralCircleLeft + this.CENTRAL_CIRCLE_OFFSET), commonTop,
          this.ENTRY_BOX_COMMON_WIDTH, this.ENTRY_BOX_COMMON_HEIGHT, lineStroke, rectangleStrokeColor
        );
        commonTop = commonTop + this.SPACING_HEIGHT + this.ENTRY_BOX_HEIGHT;
      }
    }
  }
}
