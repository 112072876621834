import { action, computed, makeObservable, observable } from 'mobx';
import { QFMFlagKeys, QuestionFeedbackMode } from './obseverableObjects/QuestionFeedbackMode';
import { UiFeedbackState } from './obseverableObjects/UiFeedbackState';
import { ContentType } from '../../Constants';
import { register } from '../../i18n';

const t = register('GlobalQuestionLabels');

export class QuestionFeedbackManager {
  @observable
  questionFeedbackMode = null;

  @observable
  lessonElementFeedbackTracker = new Map();

  @computed
  get submitButtonLabel() {
    return t('submitButtonLabel');
  }

  @computed
  get tryAgainButtonLabel() {
    return t('tryAgainButtonLabel');
  }

  @computed
  get showAnswersButtonLabel() {
    return t('showAnswersButtonLabel');
  }

@computed
  get hintButtonLabel() {
    return t('hintButtonLabel');
  }

@computed
get solutionButtonLabel() {
  return t('solutionButtonLabel');
}

@observable
  openHintDialog = false;

  @observable
  openSolutionDialog = false;

  buttonAvailability = [];
  buttonUsage = [];

  constructor() {
    makeObservable(this);
  }

  @computed get correctFeedback() {
    return this.questionFeedbackMode.correctFeedback;
  }

  @computed get incorrectFeedback() {
    return this.questionFeedbackMode.incorrectFeedback;
  }

  @computed get hasFeedback() {
    return (this.questionFeedbackMode.testItemFeedback && this.questionFeedbackMode.testItemFeedback !== 'none');
  }

  @computed get isPractice() {
    return this.questionFeedbackMode.isPractice;
  }

  @computed get isTTSOverride() {
    return this.questionFeedbackMode.isTTSOverride;
  }

  @computed get noSubmit() {
    const mssb = this.questionFeedbackMode.customPracticeMatrix.get(QFMFlagKeys.mssb);
    return !this.hasFeedback && !mssb;
  }

  getShowAnswerFeedback = (lessonElementId) => {
    const ui = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ui) {
      return ui.showAnswerFeedback;
    }
    return false;
  }

  getShowAnswers = (lessonElementId) => {
    const ui = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ui) {
      return ui.showAnswers;
    }
    return false;
  }

  getShowQuestionFeedback = (lessonElementId) => {
    const ui = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ui) {
      return ui.showQuestionFeedback;
    }
    return false;
  }

  getUiState = (lessonElementId) => {
    return this.lessonElementFeedbackTracker.get(lessonElementId);
  }

  @action
  addButtonUsage = (buttonId) => {
    this.buttonUsage.push(buttonId);
  }

  @action
  addButtonAvailabilty = (buttonId) => {
    this.buttonAvailability.push(buttonId);
  }

  @action
  clearButtonUsage = () => {
    this.buttonUsage = [];
  }

  @action
  clearButtonAvailability = () => {
    this.buttonAvailability = [];
  }

  @action
  setQuestionFeedbackMode = (lessonOptions, modeId) => {
    this.questionFeedbackMode = new QuestionFeedbackMode();
    this.questionFeedbackMode.setStateFromOptions(lessonOptions, modeId);
  }

  @action
  initLessonElementFeedbackState = (lessonElement) => {
    const ufs = new UiFeedbackState();
    ufs.currentAttemptCount = 0;
    this.lessonElementFeedbackTracker.set(lessonElement, ufs);
  }

  @action
  setCurrentAttemptCount = (lessonElementId, count) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.currentAttemptCount = count;
    }
  }

  @action
  setSubmitVisibleState = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.submitButtonVisible = state;
    }
  }

  @action
  setSubmitDisabledState = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.submitButtonDisabled = state;
    }
  }

  @action
  setTryAgainVisibleState = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.tryAgainButtonVisible = state;

      if (state) {
        this.addButtonAvailabilty('tryAgain');
      }
    }
  }

  @action
  setLoadingButtonState = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.loadingButtonVisible = state;
    }
  }

  @action
  setShowAnswersVisibleState = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.showAnswersButtonVisible = state;
    }
  }

  @action
  setShowHintVisibleState = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.hintButtonVisible = state;

      if (state) {
        this.addButtonAvailabilty('showHint');
      }
    }
  }

  @action
  setShowSolutionVisibleState = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.solutionButtonVisible = state;

      if (state) {
        this.addButtonAvailabilty('showSolution');
      }
    }
  }

  @action
  setTryAgainButtonLabel = (label) => {
    this.tryAgainButtonLabel = label;
  }

  @action
  setShowAnswerButtonLabel = (label) => {
    this.showAnswersButtonLabel = label;
  }

  @action
  setHintButtonLabel = (label) => {
    this.hintButtonLabel = label;
  }

  @action
  setSolutionButtonLabel = (label) => {
    this.solutionButtonLabel = label;
  }

  @action
  setShowAnswerFeedback = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.showAnswerFeedback = state;
    }
  }

  @action
  setShowQuestionFeedback = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.showQuestionFeedback = state;
    }
  }

  @action
  setShowFeedbackImages = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.showFeedbackImages = state;
    }
  }

  @action
  setShowAnswers = (lessonElementId, state) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.showAnswers = state;
      if (state) {
        this.addButtonAvailabilty('showAnswer');
      }
    }
  }

  @action
  setSolutionDialogOpen = (flag) => {
    this.openSolutionDialog = flag;
  }

  @action
  setHintDialogOpen = (flag) => {
    this.openHintDialog = flag;
  }

  @action
  endPractice = (lessonElementId) => {
    const ufs = this.lessonElementFeedbackTracker.get(lessonElementId);
    if (ufs) {
      ufs.currentAttemptCount = this.questionFeedbackMode.practiceAttempts;
    }
  }

  getSubmitButtonLabel = (model) => {
    if (model.type === ContentType.LONG_TEXT.type && model.unscored) {
      return `${t('saveAnswer')}`;
    }
    return this.submitButtonLabel;
  }
}
export default new QuestionFeedbackManager();
