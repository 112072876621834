import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import NumberLineService from '../../lessonPlayer/services/NumberLineService';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import AudioFeedback from '../AudioFeedback';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

import '../../css/NumberLine.scss';

const NumberLine = observer(({ model, isManuscriptShort }) => {
  const {
    numberLineManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const numberLineService = useRef(new NumberLineService(model));

  useEffect(() => {
    numberLineService.current.initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    numberLineService.current.render();
  });

  return (
    <div className='element-container NumberLine' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Number Line</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Direction Line:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.directions} /></div>
      </div>
      <div className='number-line-container' id={`_${numberLineService.current.getId()}`}>
        <div className='editSection'>
          <div className='body canvasOrganizerBody' style={{ height: numberLineService.current.getHeight(), width: model.graphWidth }}>
            <canvas className='canvas' id={numberLineService.current.getCanvasId()} />
          </div>
          <div className='bottomControlsContainer'>
            <div className='numberLineBottomControls'>
              {numberLineService.current.ELEMENT_TYPES.map((elementType, index) => { // eslint-disable-line array-callback-return
                if (model.elementTypes.includes(elementType)) {
                  return (
                    <button
                      key={elementType}
                      className={`elementTypeButton noFocusOutline icon ${elementType}`}
                      data-element-type={elementType} />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <AudioFeedback model={model} />
          <DOK model={model} />
        </>
      )}
    </div>
  );
});

export default NumberLine;
