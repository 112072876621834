import React from 'react';
import '../../css/ResourceIcon.scss';

const ResourceIcon = (props) => {
  const { className, maskOff, altText } = props;
  let  wrapperClassNameFinal = `resource-wrapper ${(className) || ''}`;
  const classNameFinal = `resource-icon ${(className) || ''}`;
  if (maskOff) {
    wrapperClassNameFinal = `${wrapperClassNameFinal} mask-off`;
  }

  return (
    <div aria-hidden='true' className={wrapperClassNameFinal} tabIndex='-1'>
      <div className={classNameFinal} tabIndex='-1' aria-label={altText} />
    </div>
  );
};

export default ResourceIcon;
