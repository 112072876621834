/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { register } from '../../../i18n';
import publisherManager from '../../managers/PublisherManager';

const t = register('ToolNames');

const TextToSpeechControls = observer((props) => {
  const {
    publisherManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const { showWebReader } = toolbarManager;

  useEffect(() => {
    const webreader = document.getElementById('webreader_script');
    // Add ReadSpeaker script if we don't have it when component mounts.
    if (!webreader) {
      window.rsConf = {
        general: { usePost: true },
        settings: { hlicon: 'iconon' }
      };
      const scriptRs = document.createElement('script');
      scriptRs.src = `https://cdn1.readspeaker.com/script/${publisherManager.webReaderId}/webReader/webReader.js?pids=wr`;
      scriptRs.async = true;
      scriptRs.id = 'webreader_script';
      document.body.appendChild(scriptRs);
    }
    // If we have ReadSpeaker script and it is toggled on in the toolbar, add the menu toggler button
    // If we have ReadSpeaker scipt and it is toggled off in the toolbar, destroy the active player
    if (webreader) {
      if (showWebReader) {
        rspkr.tools.toggler.add();
      } else {
        rspkr.ui && rspkr.ui.destroyActivePlayer();
      }
    }
  }, [showWebReader]);

  return (
    <>
      {(showWebReader && publisherManager.hasWebReaderId && publisherManager.rsHref) && (
        <div className='rs_skip rsbtn rs_preserve' id='readspeaker_button1'>
          {/* eslint-disable-next-line jsx-a11y/no-access-key */}
          <a accessKey='L' className='rsbtn_play' href={publisherManager.rsHref} rel='nofollow noopenner'
            title='Listen to this page using ReadSpeaker webReader'>
            <span className='rsbtn_left rsimg rspart'>
              <span className='rsbtn_text'>
                <span>{t('textToSpeachInfo')}</span>
              </span>
            </span>
            <span className='rsbtn_right rsimg rsplay rspart' />
          </a>
        </div>
      )}
    </>
  );
});
export default TextToSpeechControls;
