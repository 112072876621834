/* eslint-disable indent, array-callback-return */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import FontFaceObserver from 'fontfaceobserver';
import classNames from 'classnames';
import { TOOL_TYPE } from '../../managers/BookDrawManager';
import ColorPicker from '../tools/ColorPicker';
import Auth from '../../services/AuthService';
import bookDrawService from '../../services/BookDrawService';

import Utils from '../../services/UtilsService';

import '../../../css/BookActivity.scss';
import '../../../css/BookStampAnnotations.scss';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import DirectionsFlyout from '../tabbedFlyout/DirectionsFlyout';
import StampIconPicker from '../StampIconPicker';
import StampLetterPicker from '../StampLetterPicker';
import BookPage from './BookPage';
import NoteIconPicker from '../NoteIconPicker';
import BookNotes from '../tools/BookNotes';
import navMenuService from '../../services/NavMenuService';
import styleService from '../../services/StyleService';
import AudioPlayer from '../AudioPlayer';
import useStyleEvents from '../../../hooks/useStyleEvents';
import { register } from '../../../i18n';
const t = register('PanelMenu');
const t2 = register('AriaLabels');

const brushSizeOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const zoomOptions = [
  { value: -1, label: 'fit' },
  { value: 0.5, label: '50%' },
  { value: 0.75, label: '75%' },
  { value: 0.9, label: '90%' },
  { value: 1.0, label: '100%' },
  { value: 1.25, label: '125%' },
  { value: 1.50, label: '150%' },
  { value: 2.00, label: '200%' },
];

const brushSizeCustomStyles = {
  option: (provided) => ({
    ...provided,
    padding: '5px 7px',
    margin: 0,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 50,
    height: 20,
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    padding: 0,
    marginLeft: '-4px',
    marginTop: '-2px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    marginLeft: '4px',
  }),
  input: () => ({
    color: 'transparent',
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: 0,
    marginLeft: '4px',
    marginTop: '-2px',
  })
};

const zoomCustomStyles = {
  option: (provided) => ({
    ...provided,
    padding: '4px 2px',
    margin: 0,
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    height: 24,
    width: 100,
    padding: 0,
    margin: 0,
    minHeight: 20,
    backgroundColor: 'white',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    marginLeft: '12px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 4px',
  }),
  input: () => ({
    color: 'transparent',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 24,
  }),
};

const brushSizePlaceholder = <div className='brushSizeToolButton drawToolbarButton' title='stroke width' />;

const getPageStyle = (page, model, isRightSide) => {
  const styleAttr = { width: '100%' };
  if (page.backgroundImageEntityId) {
    const backgroundImageUrl = Auth.getResourceUrlByResourceId(page.backgroundImageEntityId);
    let backgroundWidth = model.pageWidth;

    if (page.singlePageBackground) {
      backgroundWidth = model.pageWidth / 2;
    }
    styleAttr.backgroundImage = `url(${backgroundImageUrl})`;
    styleAttr.backgroundSize = `${backgroundWidth}px ${model.pageHeight}px`;
    if (isRightSide && !page.singlePageBackground) {
      styleAttr.backgroundPosition = `-${model.pageWidth / 2}px 0`;
    }
  }
  return styleAttr;
};

const PageSpreads = ({ model, isSinglePageView, entityId }) => {
  let pages;

  if (isSinglePageView) {
    pages = model.pages;
  } else {
    pages = model.pages.reduce((accumulator, currentValue, currentIndex, array) => {
      if (currentIndex % 2 === 0) {
        accumulator.push(array.slice(currentIndex, currentIndex + 2));
      }
      return accumulator;
    }, []);
  }

  const contentStyleAttrLeft = {};
  const contentStyleAttrRight = {};
  const drawContainerStyleAttr = {};

  let pageWidth = (!Utils.isNullOrEmpty(model.pageWidth)) ? model.pageWidth : '';

  if (!Utils.isNullOrEmpty(pageWidth)) {
    pageWidth = (isSinglePageView) ? pageWidth / 2 : pageWidth;
  }

  drawContainerStyleAttr.width = `${pageWidth}px`;
  drawContainerStyleAttr.height = (!Utils.isNullOrEmpty(model.pageHeight)) ? `${model.pageHeight}px` : '';

  // Setting page margins
  contentStyleAttrLeft.paddingTop = (!Utils.isNullOrEmpty(model.marginTop)) ? `${model.marginTop}px` : '';
  contentStyleAttrLeft.paddingBottom = (!Utils.isNullOrEmpty(model.marginBottom)) ? `${model.marginBottom}px` : '';
  contentStyleAttrRight.paddingTop = (!Utils.isNullOrEmpty(model.marginTop)) ? `${model.marginTop}px` : '';
  contentStyleAttrRight.paddingBottom = (!Utils.isNullOrEmpty(model.marginBottom)) ? `${model.marginBottom}px` : '';
  contentStyleAttrLeft.paddingLeft = (!Utils.isNullOrEmpty(model.marginOuter)) ? `${model.marginOuter}px` : '';
  contentStyleAttrLeft.paddingRight = (!Utils.isNullOrEmpty(model.marginInner)) ? `${model.marginInner}px` : '';
  contentStyleAttrRight.paddingLeft = (!Utils.isNullOrEmpty(model.marginInner)) ? `${model.marginInner}px` : '';
  contentStyleAttrRight.paddingRight = (!Utils.isNullOrEmpty(model.marginOuter)) ? `${model.marginOuter}px` : '';
  contentStyleAttrRight.width = pageWidth;
  contentStyleAttrLeft.width = pageWidth;
  return (
    <>
      {
        pages.map((item, index) => {
          let pageIndex = 0;
          let pageNumber = 1;
          let pageIndex2 = 0;
          let page = item;
          let page2 = null;
          if (!isSinglePageView) {
            page = item[0];
            page2 = item[1];
          }

          pageIndex = (isSinglePageView) ? index : (index * 2);
          pageIndex2 = pageIndex + 1;
          pageNumber = pageIndex + model.startPageNumber;

          const pageSpreadId = `pageSpread_${page.id}`;

          let styleAttr1 = getPageStyle(page, model, false);
          let styleAttr2 = null;

          if (!isSinglePageView) {
            styleAttr2 = getPageStyle(page2, model, true);
          } else if (index % 2 !== 0) {
            styleAttr1 = getPageStyle(page, model, true);
            styleAttr1.width = pageWidth;
          }

          return (
            <div key={pageSpreadId} className='pageSpread' data-id={page.id} data-page-index={pageIndex}
              data-page-number={pageNumber}
              id={pageSpreadId} style={{
                   fontSize: (!Utils.isNullOrEmpty(model.defaultFontSize)) ? `${model.defaultFontSize }px` : '',
                   lineHeight: (!Utils.isNullOrEmpty(model.defaultLineHeight)) ? model.defaultLineHeight : '',
                   fontFamily: (!Utils.isNullOrEmpty(model.defaultFont)) ? model.defaultFont : '',
                   width: `${pageWidth}px`,
                   height: (!Utils.isNullOrEmpty(model.pageHeight)) ? `${model.pageHeight }px` : ''
                 }}>
              {(isSinglePageView) ? (
                <BookPage key={`pageActive_${page.id}`} contentStyleAttr={contentStyleAttrLeft} drawContainerStyleAttr={drawContainerStyleAttr}
                  entityId={entityId}
                  page={page}
                  pageClass='isLeftPage'
                  pageIndex={pageIndex} styleAttr={styleAttr1} />
              ) : (
                <>
                  <BookPage key={`pageactive_${page.id}`} contentStyleAttr={contentStyleAttrLeft} drawContainerStyleAttr={drawContainerStyleAttr}
                    entityId={entityId}
                    page={page}
                    pageClass='isLeftPage'
                    pageIndex={pageIndex} styleAttr={styleAttr1} />
                  <BookPage key={`pageactive_${page2.id}`} contentStyleAttr={contentStyleAttrRight} drawContainerStyleAttr={drawContainerStyleAttr}
                    entityId={entityId}
                    page={page2}
                    pageClass='isRightPage'
                    pageIndex={pageIndex2} styleAttr={styleAttr2} />
                </>
              )}
            </div>
          );
        })
      }
    </>
);
};

const BookActivity = observer(({ lessonElementId }) => {
  const {
    bookDrawManager,
    lessonManager,
  } = useContext(MobXProviderContext);

  lessonManager.interactiveElementId = lessonElementId;
  useStyleEvents(lessonElementId, `${lessonElementId}-activity-view`);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElement = lessonManager.getLessonElement(lessonElementId);
  const { entityId } = lessonElement;
   // eslint-disable-line react-hooks/exhaustive-deps
  bookDrawManager.init(lessonElementId, model.pages, entityId);

  const [rerender, setRerender] = useState(false);
  const [zoomOption, setZoomOption] = useState(zoomOptions[0]);
  const [brushOption, setBrushOption] = useState(brushSizeOptions[0]);
  const [pageView, setPageView] = useState('double');
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [characterOpen, setCharacterOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [audioOpen, setAudioOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(model.startPageNumber);

  const pencilButtonRef = useRef();
  const emojiButtonRef = useRef();
  const characterButtonRef = useRef();
  const audioRef = useRef();
  const noteButtonRef = useRef();
  const openButton = useRef();

  const deleteButtonRef = useRef();
  const selectButtonRef = useRef();
  const nextRef = useRef();
  const previousRef = useRef();
  const singleRef = useRef();
  const doubleRef = useRef();
  const toolBarRef = useRef();

  const pageWrapper = useRef();
  const pageOuter = useRef();

  const deselectButtons = () => {
    bookDrawService.setDrawingMode(false);
    bookDrawService.deselectToolbarButtons();
    bookDrawManager.setTool(null);
    hideStampMenus();
  };

  const hideStampMenus = () => {
    setEmojiOpen(false);
    setCharacterOpen(false);
    setNoteOpen(false);
  };

  useAccessibilityClick(deleteButtonRef, () => {
    bookDrawService.deleteDrawObjects();
    bookDrawService.deleteCurrentStamp();
  });

  useAccessibilityClick(selectButtonRef, () => {
    handleSelect();
  });

  useAccessibilityClick(characterButtonRef, () => {
    deselectButtons();
    if (!characterOpen) {
      bookDrawService.selectToolbarButton('characterToolButton');
    }
    setCharacterOpen(!characterOpen);
  });

  useAccessibilityClick(noteButtonRef, () => {
    deselectButtons();
    if (!noteOpen) {
      bookDrawService.selectToolbarButton('noteToolButton');
    }
    setNoteOpen(!noteOpen);
  });

  useAccessibilityClick(emojiButtonRef, () => {
    deselectButtons();
    if (!emojiOpen) {
      bookDrawService.selectToolbarButton('emojiToolButton');
    }
    setEmojiOpen(!emojiOpen);
  });

  useAccessibilityClick(pencilButtonRef, () => {
    deselectButtons();
    bookDrawManager.setSelectedStamp(null);
    if (!bookDrawManager.drawingMode) {
      bookDrawService.onClickPencilTool();
    }
  });

  useAccessibilityClick(previousRef, (e) => {
    if (model.pages && model.pages.length > 0) {
      if (pageNumber === model.startPageNumber) {

      } else {
        const newPage = pageNumber - 1;
        const page = model.pages[newPage - (model.startPageNumber)];
        if (page) {
          const pageElement = document.getElementById(`${page.id}`);
          if (pageElement) {
            pageElement.scrollIntoView({ block: 'start', inline: 'nearest' });
          }
        }
        setPageNumber(newPage);
      }
    }
  });

  useAccessibilityClick(nextRef, (e) => {
    if (model.pages && model.pages.length > 0) {
      const limit = model.pages.length + model.startPageNumber;
      if (pageNumber === (limit - 1)) {

      } else {
        const newPage = pageNumber + 1;
        const page = model.pages[newPage - (model.startPageNumber)];
        if (page) {
          const pageElement = document.getElementById(`${page.id}`);
          if (pageElement) {
            pageElement.scrollIntoView({ block: 'start', inline: 'nearest' });
          }
        }
        setPageNumber(newPage);
      }
    }
  });

  useAccessibilityClick(openButton, () => {
    navMenuService.toggleInteractiveFlyout();
  });

  useAccessibilityClick(audioRef, () => {
    deselectButtons();
    setAudioOpen(!audioOpen);
  });

  const handleResize = () => {
    if (bookDrawManager.zoom === -1) {
      scaleToFit();
    }
  };

  useEffect(() => {
    const init = async () => {
      if (lessonManager.currentParentLessonElementId !== lessonElementId) {
         setRerender(!rerender);
      }
      await bookDrawService.setPages(model, lessonElementId);
      const font = new FontFaceObserver('Facit-Regular');
      await font.load();
      await bookDrawService.initialize();
      await bookDrawService.runtimeObjectReady(model);
      await bookDrawService.fetchAnnotations();
      await bookDrawService.fetchDiscussionSummary(lessonElementId);

      deselectButtons();

      if (pageNumber > model.startPageNumber || pageNumber < model.startPageNumber) {
        const newPage = model.startPageNumber;
        const page = model.pages[newPage - (model.startPageNumber)];
        if (page) {
          const pageElement = document.getElementById(`${page.id}`);
          if (pageElement) {
            pageElement.scrollIntoView({ block: 'start', inline: 'nearest' });
          }
        }
        setPageNumber(newPage);
      }

      if (bookDrawManager.zoom === -1) {
        scaleToFit();
      }
    };
    init();
    const el = document.getElementById(`${model.id}_questionFlyout`);
    if (el) {
      el.addEventListener('transitionend', handleResize, false);
    }
    window.onresize = handleResize;

    styleService.initRevealTextEventListener(`${lessonElementId}-activity-view`);

    return () => {
      bookDrawService.clearPages();
      const el = document.getElementById(`${model.id}_questionFlyout`);
      if (el) {
        el.removeEventListener('transitionend', handleResize, false);
      }

      styleService.removeRevealTextEventListener(`${lessonElementId}-activity-view`);
    };
    // eslint-disable-next-line no-unreachable
    window.onresize = null;
  }, [lessonManager.currentParentLessonElementId, pageView]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
     hideStampMenus();
  }, [bookDrawManager.selectedIcon]);

  useEffect(() => {
    if (bookDrawManager.tool === TOOL_TYPE.SELECTION) {
      bookDrawService.selectToolbarButton('selectToolButton');
    } else if (!bookDrawManager.tool) {
      bookDrawService.deselectDrawObjectsOnAllPages();
    }
  }, [bookDrawManager.tool]);

  const imageTitle = model.imageTitle || '';
  let altText = model.altText || '';

  if (!altText) {
    if (imageTitle) {
      altText = Utils.stripTagsAndEntities(model.imageTitle) || '';
    }
  }

  const handleChangeStrokeColor = (color) => {
    bookDrawManager.setSelectedIcon('');
    bookDrawService.setStrokeColor(color.hex);
  };

  const handleChangeBrushSize = (data) => {
    setBrushOption(data);
    ReactTooltip.hide();
    bookDrawManager.setSelectedIcon('');
    bookDrawService.setStrokeWidth(data.value);
  };

  const scaleToFit = () => {
    if (!pageWrapper?.current || !pageOuter?.current) {
      return;
    }
    const { pageWidth } = model;

    const $pagesContainer = pageOuter.current;
    const $media = pageWrapper.current;
    const containerWidth = $media.clientWidth - 20;
    let scale = containerWidth / pageWidth;

    if (pageView === 'single') {
      scale *= 2;
    }

    $pagesContainer.style.transformOrigin = '0 0';
    $pagesContainer.style.transform = `scale(${scale})`;
    $pagesContainer.style.marginLeft = '2px';

  };

  const scaleScreen = (value) => {
    if (!pageWrapper?.current || !pageOuter?.current) {
      return;
    }

    if (value === -1) {
      scaleToFit();
    } else {
      let scale = value;

      const $pagesContainer = pageOuter.current;
      const $mediaContainer = pageWrapper.current;

      if (pageView === 'single') {
        scale *= 2;
      }

      $pagesContainer.style.transformOrigin = '0 0';
      $pagesContainer.style.transform = `scale(${scale})`;

      // Center the pagesContainer.
      const mediaContainerWidth = $mediaContainer.clientWidth;
      const pagesContainerWidth = $pagesContainer.clientWidth * scale;
      let leftMargin = (mediaContainerWidth - pagesContainerWidth) / 2;

      if (leftMargin < 0) {
        leftMargin = 0;
      }
      $pagesContainer.style.marginLeft = `${leftMargin}px`;
    }
  };

  const handleChangeZoom = (e) => {
    deselectButtons();
    setZoomOption(e);
    bookDrawService.setZoom(e.value);
    scaleScreen(e.value);
  };

  const handleSelect = () => {
    if (bookDrawManager.tool === TOOL_TYPE.SELECTION) {
      bookDrawManager.setTool(null);
      bookDrawManager.setSelectedStamp(null);
      bookDrawService.deselectToolbarButtons();
    } else {
      bookDrawManager.setTool(TOOL_TYPE.SELECTION);
      bookDrawService.select();
    }
  };

  const handleToolBarClick = (e) => {
    if (toolBarRef && toolBarRef.current === e.target) {
      deselectButtons();
    }
  };

  const handleClickRadio = (value) => {
    setPageNumber(model.startPageNumber);
    const $pageSpread = document.getElementById(`pageSpread_${model.pages[0].id}`);
    if ($pageSpread) {
      $pageSpread.scrollIntoView({ block: 'start', inline: 'nearest' });
    }
    setPageView(value);
  };

  const handleKeyPressRadio = (event) => {
    // If space was pressed, select the value of the element that spawned the event
    // If an arrow key was pressed, select the next or previous element

    // We have actually selected the parent of the parent so we need to find the input inside of it.
    const getTargetValue = (target) => target.getElementsByTagName('input')[0]?.value;
    // One child of event.target is the radio button which has a value of the answer id

    const radioValue = getTargetValue(event.target);

    if (radioValue == null) {
      // If no radio button was selected, buttons will be handled by the browser without intervention
      return;
    }
    if (event.type === 'click' || event.code === 'Space' || event.code === 'Enter') {
      // Set the answer id to the selected radio button
      handleClickRadio(radioValue);
    } else if (event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
      // Move the focus to the next or previous radio button and focus that one
      if (radioValue === 'single') {
        handleClickRadio('double');
        const radio = document.getElementById('book-activity-double');
        if (radio) {
          radio.focus();
        }
      } else {
        handleClickRadio('single');
        const radio = document.getElementById('book-activity-single');
        if (radio) {
          radio.focus();
        }
      }
    }
  };

  const handleScroll = (e) => {
    if (!pageWrapper?.current || !pageOuter?.current) {
      return;
    }

    if (!model.pages || model.pages.length === 0) {
      return;
    }

    const $media = pageWrapper.current;
    const $pagesContainer = pageOuter.current; // eslint-disable-line no-unused-vars
    const mediaHeight = $media.offsetHeight;

    let index = 0;
    for (const page of model.pages) {
      const $pageSpread = document.getElementById(`${page.id}`);

      const newPage = index + model.startPageNumber;

      if (!$pageSpread) {
        continue;
      }
      const pageSpreadPositionTop = $pageSpread.getBoundingClientRect().top;

      if (pageSpreadPositionTop > -(mediaHeight / 2)) {
        setPageNumber(newPage);
        return;
      }
      index += 1;
    }
  };

  return (
    <div className={classNames({
      bookletActivityWrapper: true,
      select: (bookDrawManager.tool === TOOL_TYPE.SELECTION),
      pencil: (bookDrawManager.tool === TOOL_TYPE.PENCIL),
      stamps: (bookDrawManager.tool === TOOL_TYPE.STAMPS),
      notes: (bookDrawManager.tool === TOOL_TYPE.NOTES)
    }, bookDrawManager.selectedIcon)} id={`${lessonElementId}-activity-view`}>
      {(model.directions) ?
        <DirectionsFlyout directions={model.directions} isActivity={true} lessonElementId={model.lessonElementId} /> : ''}

      <div ref={toolBarRef} className='drawToolbar' onClick={handleToolBarClick} role='toolbar'>

        <ReactTooltip border effect='solid' type='light' />
        <div className='leftButtonContainer' tabIndex='-1'>
          <div ref={previousRef} aria-label={t2('showpreviouspage')} className='showPreviousPageButton'
            data-tip={t2('showpreviouspage')} role='button' tabIndex='0'>&lt;
          </div>
          <div className='currentPageContainer' tabIndex='-1'>
            <input aria-label={t2('currentpage')} className='currentPageNumber' data-tip={t2('currentpage')} readOnly
              tabIndex='0'
              type='number' value={pageNumber} />
          </div>
          <div ref={nextRef} aria-label={t2('shownextpage')} className='showNextPageButton' data-tip={t2('shownextpage')}
            role='button' tabIndex='0'>&gt;
          </div>
          <div aria-label={t2('pageviewselection')} className='pageRadioWrapper' role='radiogroup' tabIndex={-1}>
            <div ref={singleRef} aria-label={t2('singlepagedisplay')} data-tip={t2('singlepagedisplay')}
                 onClick={handleKeyPressRadio} onKeyDown={handleKeyPressRadio} tabIndex='0'
                 className={classNames({ 'showSinglePageViewButton': true, 'selected': (pageView === 'single') })}
                 // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                 role={'radio'}><input checked={(pageView === 'single')} id='book-activity-single' onChange={() => handleClickRadio('single')}
                   tabIndex={-1} type='radio'
                   value='single' />
            </div>
            {/* eslint-disable-next-line jsx-a11y/role-has-required-aria-props */}
            <div ref={doubleRef} aria-label={t2('doublepagedisplay')} className={classNames({ showDoublePageViewButton: true, selected: (pageView === 'double') })} data-tip={t2('doublepagedisplay')}
              onClick={handleKeyPressRadio}
              onKeyDown={handleKeyPressRadio} role='radio'
              tabIndex='0'><input checked={(pageView === 'double')} id='book-activity-double' onChange={() => handleClickRadio('double')}
                tabIndex={-1} type='radio'
                value='double' />
            </div>
          </div>
        </div>

        <div className='rightButtonContainer' tabIndex='-1'>
          {(model && model.audioResourceId) && (
          <div ref={audioRef} aria-label={t2('hearthebookreadoutloud')} className='audioToolButton drawToolbarButton' data-tip={t2('hearthebookreadoutloud')}
            data-tool='audioPlayback' role='button' tabIndex='0' title='Playback'>
            <div className='audio-icon' />
          </div>
)}
          {audioOpen && <AudioPlayer autoPlay={true} lessonElementId={lessonElementId} />}
          <div ref={noteButtonRef} aria-label={t2('addanote')} className='noteToolButton drawToolbarButton' data-tip={t2('addanote')}
            data-tool='noteStamp' role='button' tabIndex='0' title='notes' />
          <NoteIconPicker open={noteOpen} />
          <div ref={characterButtonRef} aria-label={t2('addacharacterstamp')} className='characterToolButton drawToolbarButton' data-tip={t2('addacharacterstamp')}
            data-tool='characterStamp' role='button' tabIndex='0' title='Emo' />
          <StampLetterPicker open={characterOpen} />
          <div ref={emojiButtonRef} aria-label={t2('addanemojistamp')} className='emojiToolButton drawToolbarButton' data-tip={t2('addanemojistamp')}
            data-tool='emojiStamp' role='button' tabIndex='0' title='Emo' />
          <StampIconPicker open={emojiOpen} />
          <div ref={pencilButtonRef} aria-label={t2('drawwithpencil')} className='pencilToolButton drawToolbarButton' data-tip={t2('pencil')}
            data-tool='pencil' role='button' tabIndex='0' title='pencil' />
          <div className={classNames({ pencilMenu: true, showPencilMenu: bookDrawManager.drawingMode })}
            tabIndex='-1'>
            <div aria-label={t2('strokecolor')} className='strokeColorButton drawToolbarButton' data-tip={t2('strokecolor')} role='menu'
              style={{ display: 'inline-block' }} tabIndex='0' title='stroke color'>
              <ColorPicker color={bookDrawService.currentStrokeColor} onChange={handleChangeStrokeColor} />
            </div>
            <div className='drawBrushSizeMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t2('brushsize')} className='drawBrushSizeMenu drawToolMenu' data-tip={t2('brushsize')} role='menu'
                style={{ width: '40px' }} tabIndex='-0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  onChange={handleChangeBrushSize}
                  options={brushSizeOptions}
                  placeholder={brushSizePlaceholder}
                  styles={brushSizeCustomStyles}
                  value={brushOption}
                />
              </div>
            </div>
          </div>
          <div ref={selectButtonRef} aria-label={t2('selectitems')} className='selectToolButton drawToolbarButton' data-tip={t2('selectitems')}
            role='button' tabIndex='0' title='select items' />
          <div ref={deleteButtonRef} aria-label={t2('deleteelement')} className='drawDeleteElementButton drawToolbarButton' data-tip={t2('deleteelement')}
            role='button' tabIndex='0' title={t2('deleteelement')} />
          <div className='zoomMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
            <div aria-label={t2('zoompage')} className='zoomMenu drawToolMenu' data-tip={t2('zoompage')} role='menu'
              tabIndex='0'>
              <Select
                onChange={handleChangeZoom}
                options={zoomOptions}
                styles={zoomCustomStyles}
                value={zoomOption}
              />
            </div>
          </div>
        </div>
      </div>
      <div ref={openButton} className={classNames({ open: lessonManager.isInteractiveFlyoutOpen }, 'q-flyout-tab', 'inside', 'open-button-wrapper')}>
        <div aria-hidden className='icon-wrapper' tabIndex='-1'>
          <div aria-hidden aria-label={(!lessonManager.isInteractiveFlyoutOpen) ? `${t('openMenu', 'Open flyout menu')}` : `${t('closeMenu', 'Close flyout menu')}`}
            className='open-button'
            tabIndex='-1' />
        </div>
      </div>
      {(bookDrawManager.isNotesOpen) && <BookNotes />}
      <div ref={pageWrapper} className='childContainerWrapper' onScroll={handleScroll}>
        <div ref={pageOuter} className='childContainer'>
          <PageSpreads isSinglePageView={pageView === 'single'} model={model} />
        </div>
      </div>

    </div>
  );
});

export default BookActivity;
