import React from 'react';
import '../../../css/QuestionLogo.scss';

const QuestionLogo = (props) => {
  const className = `question-logo ${props.className}`;
  return (
    <div aria-hidden='true' className='question-logo-wrapper' tabIndex='-1'>
      <div className={className} tabIndex='-1' />
    </div>
  );
};

export default QuestionLogo;
