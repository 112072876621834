/* eslint-disable import/no-named-default */
/* eslint-disable max-len */
import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { default as ReactSelect } from 'react-select';
import Iframe from 'react-iframe';

import '../../css/ContentPreview.scss';

import Auth from '../../lessonPlayer/services/AuthService';
import itemBankService from '../services/ItemBankService';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';

import LessonProfileSelectDialog from './LessonProfileSelectDialog';

const ContentPreview = observer((props) => {
  const {
    lessonManager,
    itemBankManager
  } = useContext(MobXProviderContext);

  const [profileParam, setProfileParam] = useState('');
  const exitPreviewRef = useRef();
  const { contentItemId, courseContentItemId, previewLessonElementId, previewSource } = props;

  const path = (previewLessonElementId && previewLessonElementId !== '') ? 'preview' : 'itemPreview';

  useAccessibilityClick(exitPreviewRef, (_e) => {
    if (previewSource && previewSource === 'ext') {
      window.close();
    } else {
      const originViewMode = (itemBankManager.originViewMode) ? itemBankManager.originViewMode : 'build';
      itemBankService.setViewMode(originViewMode);
      itemBankService.setPreviewId(null);
      itemBankManager.setLessonProfileId(null);
    }
  });

  const goToProfile = async () => {
    const option = itemBankService.findAndSetDefaultProfile(itemBankManager.profiles);
    if (option) {
      itemBankManager.setSelectedProfileOption(option);

      if (itemBankManager.lessonProfileId) {
        setProfileParam(`&profileId=${itemBankManager.lessonProfileId}`);
      }
    }
  };

  const handleChangeProfile = async (selected) => {
    if (selected.value) {
      itemBankManager.setLessonProfileId(selected.value);
      setProfileParam(`&profileId=${selected.value}`);
      localStorage.setItem('chosen-profile-id', selected.value);
    }
    itemBankManager.setSelectedProfileOption(selected);
  };

  return (
    <>
      <LessonProfileSelectDialog goToProfile={goToProfile} previewSource={previewSource} />

      <>
        <div className='content-preview-wrapper'>
          <div className='filter-control-bar'>
            <h1>Preview Item</h1>
            <div className='button-wrapper'>
              {itemBankManager.profiles.length > 0 && (
                <div className='profile-picker-wrapper'>
                  <ReactSelect
                    className='profile-select'
                    classNamePrefix='profile-select'
                    closeMenuOnSelect={true}
                    onChange={handleChangeProfile}
                    options={itemBankManager.profiles}
                    value={itemBankManager.selectedProfileOption} />
                </div>
              )}
              <div className='header-space' />
              <button ref={exitPreviewRef} className='preview-button control-button' type='button'>Exit</button>
            </div>
          </div>

          <div className='builder-content-wrapper'>
            <Iframe /* key='item-preview' */
              className='item-preview-frame'
              id={`item_preview_${contentItemId}`}
              url={`../${path}?contentItemId=${contentItemId}&courseId=${courseContentItemId}&previewLessonElementId=${previewLessonElementId}${profileParam}&sat=${lessonManager.isSat}#${Auth.authKey}`} />
          </div>
        </div>
      </>
    </>
  );
});

export default ContentPreview;
