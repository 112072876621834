import questionService from '../services/QuestionService';

import { ClozeBehavior } from './ClozeBehavior';

import utilsService from '../services/UtilsService';

export class ClozeMultichoiceBehavior extends ClozeBehavior {
  setResponseData = (data, _responseAnswer, model, { dataId, lessonElementState } = {}) => {
    if (!lessonElementState.currentResponseAnswer.userInputMap) {
      const userInputMap = questionService.updateUserInputMap({
        model, lessonElementState
      });
      userInputMap.set(dataId, {
        ...data.originalData,
        text: data.label
      });
    } else {
      lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
        ...data.originalData,
        text: data.label
      });
    }
  }

  setCorrectAnswer = (state, model) => {
    const userInputMap = new Map();
    for (const prompt of model?.validation?.prompts) {
      const correctAnswer = prompt.answers.find((answer) => answer.isCorrect);
      userInputMap.set(prompt.id, {
        dataId: prompt.id,
        text: correctAnswer.text
      });
    }
    state.setCurrentResponse(utilsService.safeMobxClone({
      userInputMap, lessonElementId: model.lessonElementId
    }));
  }

  resetStudentAnswer = (state, _validation) => {
    state.setCurrentResponse(utilsService.safeMobxClone(state.cachedResponseAnswer));
  }
}

export default new ClozeMultichoiceBehavior();
