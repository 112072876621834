import React from 'react';

const QuestionDependency = ({ model }) => {
  return (
    <>
      <div className='label-value-row'>
        <div>Question Dependency:</div>
        <div>{(model.dependentOn) ? 'ON' : 'OFF'}</div>
      </div>
      {model.dependentOn && (
        <div className='label-value-row'>
          <div>Dependent On:</div>
          <div>{model.dependentOn}</div>
        </div>
      )}
    </>
  );
};
export default QuestionDependency;
