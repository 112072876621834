import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../../css/BubbleWebGraphicOrganizer.scss';
import { LessonMode } from '../../../Constants';
import bubbleWebService from '../../services/BubbleWebService';

import OptionalImage from '../OptionalImage';
import HtmlComponent from '../HtmlComponent';
import BubbleWebTextLayout from './BubbleWebTextLayout';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const BubbleWebGraphicOrganizer = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager
  } = useContext(MobXProviderContext);

  const [rerender, setRerender] = useState(false);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const readOnly = (lessonElementState && lessonElementState.readOnly) || lessonManager.isReadOnly;

  useStyleEvents(lessonElementId);

  useEffect(() => {
    const init = async () => {
      bubbleWebService.createCanvas(model);
      bubbleWebService.showBoxes(model, bubbleWebService.getCanvasWidth(), bubbleWebService.getCanvasHeight());
      if (rerender === false) {
        setRerender(true);
      }
    };
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='bubble-web-graphic-organizer'>
      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.directions} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div aria-labelledby={`test-item-question-${lessonElementId}`} className='test-item-answers'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <div className='canvas-wrapper' style={{ height: `${bubbleWebService.getCanvasHeight()}px`, width: `${bubbleWebService.getCanvasWidth()}px` }}>
          <canvas className='canvas' id={`${model.lessonElementId}_canvas`} />
          {rerender && <BubbleWebTextLayout canvas={bubbleWebService.canvas} lessonElementId={lessonElementId} readOnly={readOnly} />}
        </div>
      </div>
    </div>
  );
});
export default BubbleWebGraphicOrganizer;
