import { action, computed, makeObservable, observable } from 'mobx';

export const STAMP_TYPES = {
  STAMP: 'stamp',
  NOTE: 'note'
};

export class AnnotationStamp {
  constructor() {
    makeObservable(this);
  }

  id = null;

  @observable
  type = STAMP_TYPES.STAMP;

  @observable
  iconId = null;

  @observable
  discussions = new Map();

  @observable
  hasDiscussion = false;

  @observable
  pageId = '';

  @observable
  leftPercent = 0;

  @observable
  topPercent = 0;

  @observable
  summaryCount = -1;

  @action
  setId = (id) => {
    this.id = id;
  }

  @action
  setType = (type) => {
    this.type = type;
  }

  @action
  setHasDiscussion = (toggle) => {
    this.hasDiscussion = toggle;
  }

  @action
  setPageId = (id) => {
    this.pageId = id;
  }

  @action
  setIconId = (id) => {
    this.iconId = id;
  }

  @action
  setTopPercent = (value) => {
    this.topPercent = value;
  }

  @action
  setLeftPercent = (value) => {
    this.leftPercent = value;
  }

  @action
  setData(data) {
    this.leftPercent = data.leftPercent;
    this.topPercent = data.topPercent;
    const newID = this.convertOldIdToNew(data.iconId);
    this.iconId = newID;
    this.pageId = data.pageId;
    this.hasDiscussion = data.hasDiscussion;
    this.type = data.type;

    this.id = data.id;
  }

  @action
  setDiscussion = (id, discussion) => {
    this.discussions.set(id, discussion);
    this.hasDiscussion = true;
  }

  @computed
  get discussionsArray() {
    const valIt = this.discussions.values();

    if (valIt) {
      return Array.from(valIt);
    }
    return null;
  }

  @action
  removeDiscussion = (id) => {
    this.discussions.delete(id);
    if (this.discussions.size === 0) {
      this.hasDiscussion = false;
    }
  }

  hasDiscussionId = (discussion, id) => {
    if (discussion.id === id) {
      return true;
    }
    return false;
  }

  @action resolveDiscussion = (discussionId, isResolved) => {
    for (const discussion of this.discussionsArray) {
      if (this.hasDiscussionId(discussion, discussionId)) {
        discussion.resolved = isResolved;
        return;
      }
    }
  }

  @action
  setSummaryCount = (count) => {
    this.summaryCount = count;
  }

  @computed
  get discussionCount() {
    if (this.summaryCount === -1) {
      return this.discussions.size;
    } else {
      return this.summaryCount;
    }
  }

  convertOldIdToNew = (id) => {
    switch (id) {
    case 'happy-stamp': {
      return 'happyStamp';
    }

    case 'neutral-stamp': {
      return 'neutralStamp';
    }

    case 'sad-stamp': {
      return 'sadStamp';
    }

    case 'thumbs-up-stamp': {
      return 'thumbsUpStamp';
    }

    case 'thumbs-down-stamp': {
      return 'thumbsDownStamp';
    }

    case 'heart-stamp': {
      return 'heartStamp';
    }

    case 'star-stamp': {
      return 'starStamp';
    }

    case 'A-stamp': {
      return 'AStamp';
    }

    case 'B-stamp': {
      return 'BStamp';
    }

    case 'C-stamp': {
      return 'CStamp';
    }

    case 'D-stamp': {
      return 'DStamp';
    }

    case 'E-stamp': {
      return 'EStamp';
    }

    case 'F-stamp': {
      return 'FStamp';
    }

    case 'G-stamp': {
      return 'GStamp';
    }

    case 'H-stamp': {
      return 'HStamp';
    }

    case 'I-stamp': {
      return 'IStamp';
    }

    case 'J-stamp': {
      return 'JStamp';
    }

    case 'K-stamp': {
      return 'KStamp';
    }

    case 'L-stamp': {
      return 'LStamp';
    }

    case 'M-stamp': {
      return 'MStamp';
    }

    case 'N-stamp': {
      return 'NStamp';
    }

    case 'O-stamp': {
      return 'OStamp';
    }

    case 'P-stamp': {
      return 'PStamp';
    }

    case 'Q-stamp': {
      return 'QStamp';
    }

    case 'R-stamp': {
      return 'RStamp';
    }

    case 'S-stamp': {
      return 'SStamp';
    }

    case 'T-stamp': {
      return 'TStamp';
    }

    case 'U-stamp': {
      return 'UStamp';
    }

    case 'V-stamp': {
      return 'VStamp';
    }

    case 'W-stamp': {
      return 'WStamp';
    }

    case 'X-stamp': {
      return 'XStamp';
    }

    case 'Y-stamp': {
      return 'YStamp';
    }

    case 'Z-stamp': {
      return 'ZStamp';
    }

    case '1-stamp': {
      return 'OneStamp';
    }

    case '2-stamp': {
      return 'TwoStamp';
    }

    case '3-stamp': {
      return 'ThreeStamp';
    }

    case '4-stamp': {
      return 'FourStamp';
    }

    case '5-stamp': {
      return 'FiveStamp';
    }

    case '6-stamp': {
      return 'SixStamp';
    }

    case '7-stamp': {
      return 'SevenStamp';
    }

    case '8-stamp': {
      return 'EightStamp';
    }

    case '9-stamp': {
      return 'NineStamp';
    }

    case '10-stamp': {
      return 'TenStamp';
    }

    case 'question-stamp': {
      return 'questionStamp';
    }

    case 'exclamation-stamp': {
      return 'exclamationStamp';
    }

    case 'check-stamp': {
      return 'checkStamp';
    }

    case 'cross-stamp': {
      return 'crossStamp';
    }

    case 'plus-stamp': {
      return 'plusStamp';
    }

    case 'minus-stamp': {
      return 'minusStamp';
    }

    case 'sticky-note-blue-stamp': {
      return 'noteBlue';
    }

    case 'sticky-note-red-stamp': {
      return 'noteRed';
    }

    case 'sticky-note-yellow-stamp': {
      return 'noteYellow';
    }

    case 'sticky-note-green-stamp': {
      return 'noteGreen';
    }
    default: {
      return id;
    }
    }
  }

  @computed
  get styleAttr() {
    const style = {
      left: `${this.leftPercent}%`,
      top: `${this.topPercent}%`,
    };
    return style;
  }
}
