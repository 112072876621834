import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import RichTextEditor, { BASIC_CKEDITOR_TOOLBAR } from '../RichTextEditor';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import highlightAnnotationService from '../../services/HighlightAnnotationService';
import { LessonMode } from '../../../Constants';
import { register } from '../../../i18n';

const t = register('GlobalQuestionLabels');

const NoteEditor = observer(({ noteObject, isReadOnly = true }) => {
  const {
    userManager,
    highlightAnnotationManager,
    lessonManager
  } = useContext(MobXProviderContext);

  const [enteredText, setEnteredText] = useState(noteObject.noteText);

  const saveNoteRef = useRef();
  useAccessibilityClick(saveNoteRef, (e) => {
    highlightAnnotationService.updateHighlightNote(noteObject.id, enteredText);
  });

  const deleteNoteRef = useRef();
  useAccessibilityClick(deleteNoteRef, (e) => {
    highlightAnnotationService.deleteHighlightNote(noteObject.id, enteredText);
  });

  const myNote = (noteObject.creatorId === userManager.userId);
  const teacherStyle = ((!userManager.isTeacher && !myNote) ||
    (userManager.isTeacher && myNote && lessonManager.playerMode === LessonMode.SCORING && highlightAnnotationManager.highlightUserMode === 'student'))
    ? 'note-user teacher-font' : 'note-user';

  return (
    <div className='existing-note' id={`${noteObject.id}`}>
      <div className={teacherStyle}>{noteObject.displayName}</div>
      <div className='note-date'>{noteObject.displayDate}</div>
      <div className='test-item-answers'>
        <div className='cke_topspace'>
          <RichTextEditor data={enteredText}
            onChange={(data) => setEnteredText(data)}
            readOnly={!myNote} toolbar={BASIC_CKEDITOR_TOOLBAR} />
        </div>
      </div>
      {myNote && (
        <div className='footer-buttons'>
          <button ref={saveNoteRef} className='submit-response primary'>{t('save')}</button>
          <button ref={deleteNoteRef} className='submit-response primary'>{t('delete')}</button>
        </div>
      )}
    </div>
  );
});

export default NoteEditor;
