import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { default as ReactSelect } from 'react-select';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import '../../css/BuilderLayout.scss';
import '../../css/ResourceItemFilter.scss';
import itemBankService from '../services/ItemBankService';
import TagsFilter from './TagsFilter';
import CmapFilter from './CmapFilter';
import classNames from 'classnames';
import CurriculumFrameworkFilter from './CurriculumFrameworkFilter';
import { confirmDialog } from '../../lessonPlayer/components/dialogs';
import CheckOption from './ChecksOption';

import { register } from '../../i18n';

const t = register('ResourceItemFilter');

const ResourceItemFilter = observer((props) => {
  const {
    itemBankManager,
  } = useContext(MobXProviderContext);

  const [panelVisible, setPanelVisible] = useState(0);
  const [currentSearchItemBankId, setCurrentSearchItemBankId] = useState(null);
  const panelZero = useRef();
  const panelOne = useRef();
  const panelTwo = useRef();
  const resetFilters = useRef();

  useAccessibilityClick(panelZero, () => {
    if (!itemBankManager.searchItemBankId) {
      handleDoNotNav();
    } else {
      setPanelVisible(0);
    }
  });

  useAccessibilityClick(panelOne, () => {
    if (!itemBankManager.searchItemBankId) {
      handleDoNotNav();
    } else {
      setPanelVisible(1);
    }
  });

  useAccessibilityClick(panelTwo, () => {
    if (!itemBankManager.searchItemBankId) {
      handleDoNotNav();
    } else {
      setPanelVisible(2);
    }
  });

  useAccessibilityClick(resetFilters, () => {
    itemBankService.resetSearch();
    setPanelVisible(0);
  });

  const handleDoNotNav = async (event) => {
    const dialogObject = {
      title: 'Selection Required',
      text: 'Please select an Item Bank.',
      showCancelButton: false,
      confirmButtonText: 'OK'
    };
    confirmDialog(dialogObject);
  };

  const handleChangeItemBank = async (selected) => {
    itemBankService.resetSearch();
    setCurrentSearchItemBankId(null);
    itemBankManager.setSelectedItemBank(selected);
    // console.log(selected);
    itemBankManager.resetPaginator();
    await itemBankManager.setSearchItemBankId(selected.value);
    setCurrentSearchItemBankId(selected.value);
    itemBankService.searchContentItemsForItemBank();
  };

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div ref={resetFilters} className='reset-filters'>Reset Filters</div>
      <div className='filter-instruction'>
        <div>{t('itemBankFilterDescription')}</div>
      </div>
      <div className='bank-selector'>
        <div className='filter-label'>{t('itemBankFilterLabel')}</div>
        <ReactSelect
          allowSelectAll={true}
          closeMenuOnSelect={true}
          components={{ CheckOption }}
          hideSelectedOptions={false}
          onChange={handleChangeItemBank}
          options={itemBankManager.itemBanks}
          value={itemBankManager.selectedItemBank} />
      </div>
      <div className='resource-item-filter'>

        <div className='filter-column'>
          {/* DEMO-1933 - remove search options filter, leaving in case we revisit it later
        <div id='cmapPanel' style={{ display: (panelVisible === 0) ? 'block' : 'none' }}>
          <TagsSearchFilter/>
        </div> */}
          <div className='tabs-wrapper'>
            <button ref={panelZero} className={classNames({ 'lesson-summary-tab': true, 'left': true, 'selected': (panelVisible === 0) })}>{t('standardsTab')}</button>
            <button ref={panelOne} className={classNames({ 'lesson-summary-tab': true, 'right': true, 'selected': (panelVisible === 1) })}>{t('tagsTab')}</button>
            {/* <button className={classNames({ 'lesson-summary-tab': true, 'right': true, 'selected': (panelVisible === 2) })} ref={panelTwo}>Curriculum Framework</button> */}
          </div>
          <div className='panel-wrapper'>
            <div className='cmap-section' id='cmapPanel'
              style={{ display: (panelVisible === 0) ? 'block' : 'none' }}>
              {currentSearchItemBankId && (
                <>
                  <div className='cmap-description'>{t('cmapDescription')}</div>
                  <CmapFilter itemBankId={currentSearchItemBankId} />
                </>
              )}
            </div>
            <div id='cmapPanel' style={{ display: (panelVisible === 1) ? 'block' : 'none' }}>
              <TagsFilter />
            </div>
            <div className='curriculum-framework-section' id='curriculumFrameworkPanel'
              style={{ display: (panelVisible === 2) ? 'block' : 'none' }}>
              <CurriculumFrameworkFilter />
            </div>
          </div>
        </div>
      </div>

    </>
  );
});

export default ResourceItemFilter;
