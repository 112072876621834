import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';

import parse from 'html-react-parser';

import {
  DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';

import { snapCenterToCursor } from '@dnd-kit/modifiers';

import '../../../css/ImageLabelCore.scss';
import '../../../css/ImageLabelDragDrop.scss';

import Auth from '../../services/AuthService';

import { ContentType, ImageLabelMargins, LessonMode } from '../../../Constants';

import dragDropService from '../../services/DragDropService';
import playerService from '../../services/PlayerService';
import promptService from '../../services/PromptService';
import questionService from '../../services/QuestionService';
import styleService from '../../services/StyleService';
import textQuestionUtilsService from '../../services/TextQuestionUtilsService';
import utilsService from '../../services/UtilsService';

import useForceUpdate from '../../../hooks/useForceUpdate';
import useWirisParser from '../../../hooks/useWirisParser';

import Guideline from '../tools/Guideline';

import DndKitDraggable from '../DndKitDraggable';
import DndKitDroppable from '../DndKitDroppable';
import DndKitOverlay from '../DndKitOverlay';

import FeedbackIcon from '../FeedbackIcon';
import HtmlComponent from '../HtmlComponent';
import useStyleEvents from '../../../hooks/useStyleEvents';
import lessonService from '../../services/LessonService';
import UtilsService from '../../services/UtilsService';
import responseService from '../../services/ResponseService';
import navMenuService from '../../services/NavMenuService';
import PrintQuestionNumber from './PrintQuestionNumber';

const ImageLabelDragDrop = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  const componentRef = useRef();
  const imageLabelQuestionSectionTitleRef = useRef();
  const imgBodyRef = useRef();
  const imgRef = useRef();

  useStyleEvents(lessonElementId);
  useWirisParser(componentRef);

  const forceUpdate = useForceUpdate();

  const [loadingDragDrop, setLoadingDragDrop] = useState(true);

  const [allowRenderDragDropAnswerBankSection, setAllowRenderDragDropAnswerBankSection] = useState(false);

  const DEFAULT_MIN_SIZE = 475;
  const DEFAULT_MAX_SIZE = 600;
  // TODO remove // const [imageLabelQuestionMaxWidth, setImageLabelQuestionMaxWidth] = useState(DEFAULT_MAX_SIZE);

  const [imageLabelTitleOffset, setImageLabelTitleOffset] = useState(0);

  const [imageLabelImg, setImageLabelImg] = useState(null);

  const [responseItemAnswerBankOptions, setResponseItemAnswerBankOptions] = useState([]);

  const [dragStartDndKitEvent, setDragStartDndKitEvent] = useState(null);

  const [dragEndResult, setDragEndResult] = useState(null);

  const { getStyleVar } = styleService;

  const { stripTagsAndEntities, stripWrappingParagraphTags } = utilsService;

  const {
    lessonElementState,
    model,
    userInputMap
  } = questionService.initQuestionComponent({
    lessonElementId,
    questionClassName: 'image-label-question'
  });

  const sensors = useSensors(
    useSensor(KeyboardSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        tolerance: 5
      }
    })
  );

  const { contentType } = model;
  const useMargin = (!model.maximizeImageSize && contentType !== 'text');
  const [altText, setAltText] = useState('');
  const initResponseItemAnswerBankOptions = () => {
    dragDropService.initResponseItemAnswerBankOptions({
      model,
      setResponseItemAnswerBankOptions
    });
  };

  const handleImgLoad = (event) => {
    if (!model.maximizeImageSize && contentType !== 'text') {
      const [leftMargin, rightMargin, topMargin, bottomMargin] = [model.leftMargin, model.rightMargin, model.topMargin, model.bottomMargin].map((marginString) => ImageLabelMargins[marginString]);

      if (imgBodyRef.current && imgRef.current && event) {
        // TODO remove // const width = `${imgRef.current.naturalWidth + leftMargin + rightMargin}px`;
        // TODO remove // const height = `${imgRef.current.naturalHeight + topMargin + bottomMargin}px`;
        const width = `${event.naturalWidth + leftMargin + rightMargin}px`;
        const height = `${event.naturalHeight + topMargin + bottomMargin}px`;
        imgBodyRef.current.style.width = width;
        imgBodyRef.current.style.height = height;
      }
    }
  };

  /** equivalent to componentDidMount(), i.e. only called on initial load */
  useEffect(() => {
    dragDropService.handleDragDropInitUseEffect({
      initResponseItemAnswerBankOptions,
      setLoadingDragDrop
    });
    const img = new Image();
    img.onload = () => {
      setImageLabelImg(img);
    };
    img.src = Auth.getResourceUrlByFileName(model.croppedImageSource || model.imageSource);
    setTimeout(() => {
      setAllowRenderDragDropAnswerBankSection(true);
    }, 250);
    const doAction = async () => {
      if (UtilsService.isNullOrEmpty(model.altText) && !model.altTextLoaded) {
        const contentItem = await lessonService.fetchContentItem(model.contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        }
      }
    };
    doAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {import('@dnd-kit/core').DragEndEvent} dndKitEvent
   */
  const onDragEnd = (dndKitEvent) => {
    dragDropService.handleDragEnd({
      dndKitEvent,
      forceUpdate,
      lessonElementState,
      lessonElementId,
      model,
      result: undefined,
      setDragEndResult
    });
  };

  // on update
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const uiState = questionFeedbackManager.getUiState?.(lessonElementId);
    if (uiState?.showAnswerFeedback) {
      setDragEndResult(null);
    }

    if (model.contentType === 'text') {
      const _imageLabelTitleOffset = imageLabelQuestionSectionTitleRef?.current?.clientHeight;
      if (_imageLabelTitleOffset && !imageLabelTitleOffset) {
        setImageLabelTitleOffset(_imageLabelTitleOffset || 0);
      }
    } else {
      // TODO unused
      // const _imgTitleRefDomRect = imageLabelQuestionSectionTitleRef?.current?.getBoundingClientRect?.();
      // if (_imgTitleRefDomRect?.top >= 0 && !imageLabelTitleOffset) {
      //   setImageLabelTitleOffset(_imgTitleRefDomRect.top);
      // }
    }
  });

  /** equivalent to componentWillUnmount(), i.e. called whenever component is unmounting */
  useEffect(() => () => {
    return dragDropService.handleDragDropUnmountUseEffect(ContentType.IMAGE_LABEL_DRAG_DROP);
  });

  const getAbsoluteDragDropResponseItemStyles = (responseItem) => {
    return dragDropService.getAbsoluteDragDropResponseItemStyles({
      model,
      responseItem
    });
  };

  // TODO remove // const runtime = true;

  const navToCurrentLessonElement = async () => {
    if (lessonManager.currentLessonElementId !== lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
    }
  };

  const renderImageLabelDragDrop = (_props = null) => {
    let imageBodyStyle = {};
    const imageStyle = {};
    // TODO remove // const imageMaxSize = (model.maxSize) ? `${model.maxSize}px` : 'auto';

    if (model.contentType === 'text') {
      imageBodyStyle = {
        height: (+model.textBodyHeight > 0 ? `${model.textBodyHeight}px` : 'auto'),
        // heightNum: (+model.textBodyHeight > 0 ? model.textBodyHeight : 0),
        margin: 0,
        maxWidth: (+model.textBodyWidth > 0 ? `${model.textBodyWidth}px` : 'auto'),
        width: (+model.textBodyWidth > 0 ? `${model.textBodyWidth}px` : 'auto'),
        widthNum: (+model.textBodyWidth > 0 ? model.textBodyWidth : 0)
      };
    } else if (model.maximizeImageSize) {
      let maxWidth;
      if (+model.maxSize > 0) {
        maxWidth = +model.maxSize;
      } else {
        maxWidth = Math.min(DEFAULT_MAX_SIZE, Math.max((imageLabelImg?.width > 0 ? imageLabelImg.width : DEFAULT_MAX_SIZE), DEFAULT_MAX_SIZE));
      }

      let minWidth;
      if (+model.minSize > 0) {
        minWidth = Math.min(model.minSize, maxWidth);
      }

      imageBodyStyle = {
        height: 'unset',
        margin: 0,
        minWidth: minWidth || 'auto',
        maxWidth,
        // width: maxWidth // TODO remove
      };
      // TODO remove
      // imageStyle = {
      //   // maxWidth: +model?.maxSize || DEFAULT_MAX_SIZE
      // };
    } else {
      // `maximizeImageSize` is false, so we need to set the margins and the image container size
      // eslint-disable-next-line no-unused-vars
      const [leftMargin, rightMargin, _topMargin, _bottomMargin] = [model.leftMargin, model.rightMargin, model.topMargin, model.bottomMargin].map((marginString) => ImageLabelMargins[marginString]);

      const imageBodyWidth = Math.min(DEFAULT_MIN_SIZE, Math.min((imageLabelImg?.width || 0), DEFAULT_MIN_SIZE));

      imageBodyStyle = {
        marginLeft: leftMargin,
        marginRight: rightMargin,
        maxWidth: imageBodyWidth,
        width: imageBodyWidth
      };
      // TODO remove
      // imageStyle = {
      //   // TODO remove // margin: `${topMargin}px ${rightMargin}px ${bottomMargin}px ${leftMargin}px`,
      // };
    }

    const answerBankPosition = model.answerBankPosition || 'bottom';

    const isCustomAnswerBankStyling = model?.answerLayoutType === 'manual';
    const answerBankPositionClassName = `answer-bank-position-${ answerBankPosition}`;
    const imageLabelQuestionInnerClassNames = classNames('image-label-question-inner', answerBankPositionClassName);
    const questionTitle = <HtmlComponent htmlStr={model.questionText || ''} />;

    let answerBankBackgroundUrl = '';
    if (model.customAnswerLayoutBackground && model.customAnswerLayoutBackgroundHtml) {
      parse(model.customAnswerLayoutBackgroundHtml, {
        replace: (domNode) => {
          if (domNode.attribs?.src) {
            answerBankBackgroundUrl = domNode.attribs.src;
          }
        }
      });
    }

    return (
      <div className={classNames('image-label-question', {
        'image-label-question-larger-width': answerBankPosition === 'left' || answerBankPosition === 'right'
      })} ref={componentRef}/* style={{ maxWidth: imageLabelQuestionMaxWidth }} */>
        <div ref={imageLabelQuestionSectionTitleRef} className='image-label-question-section-title'>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='test-item-question'>
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
            {questionTitle}
          </div>
        </div>

        <DndContext
          modifiers={[snapCenterToCursor]}
          onDragEnd={(dndKitEvent) => {
            setDragStartDndKitEvent(null);
            onDragEnd(dndKitEvent);
          }}
          onDragStart={(dndKitEvent) => {
            navToCurrentLessonElement();
            setDragEndResult(null);
            setDragStartDndKitEvent(dndKitEvent);
          }}
          sensors={sensors}>
          {/* {(runtime) ? ( *//* TODO remove */}
          <div className={imageLabelQuestionInnerClassNames}
            style={model.contentType === 'text' ? {
              position: 'relative'
              // TODO remove // marginBottom: `${imageLabelTitleOffset || 0}px`,
              // TODO remove // top: `${imageLabelTitleOffset || 0}px`
            } : {}}>
            <div className='image-label-question-section-body' style={{ minWidth: '50%' }}>
              <div className='test-item-answers'>
                <div className='image-label-drag-drop-body'>
                  <div className='image-label-drag-drop-body-nodes'>
                    <div className={classNames('imageBodyContainer ', `${model.contentType}-type`)}>
                      {model.imageTitle && (
                        <div className='textInput imageLabelTitle'>
                          <HtmlComponent htmlStr={model.imageTitle} />
                        </div>
                      )}
                      <div ref={imgBodyRef}
                        className={classNames('imageBody stop', `imageBody-${(model.answerBankPosition || 'bottom')}`)}
                        style={imageBodyStyle}>
                        {model.contentType === 'text' ? (
                          <div className='textBody'>
                            <HtmlComponent htmlStr={model.textBody} />
                          </div>
                        ) : (
                          <img
                            ref={imgRef}
                            alt={model.altText || altText || ''}
                            className='test-item-image imageLabelImage'
                            onLoad={handleImgLoad}
                            src={Auth.getResourceUrlByFileName(model.croppedImageSource || model.imageSource)}
                            style={imageStyle} />
                        )}
                        {model.prompts.map((prompt, index) => {
                          return (
                            renderBlankSection(prompt, index, useMargin)
                          );
                        })}
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>

            {allowRenderDragDropAnswerBankSection && dragDropService.renderDragDropAnswerBankSection_dndKit({
              CONTENT_TYPE_OBJ: ContentType.IMAGE_LABEL_DRAG_DROP,
              answerBankBackgroundUrl,
              dragEndResult,
              dragStartDndKitEvent,
              getAbsoluteDragDropResponseItemStyles,
              isCustomAnswerBankStyling,
              lessonElementId,
              lessonElementState,
              model,
              responseItemAnswerBankOptions
            })}
          </div>
          {/* ) : <></>} *//* TODO remove */}
          {createPortal(
            <DragOverlay dropAnimation={null}>
              {dragStartDndKitEvent?.active?.data?.current?.draggableId ? (
                <DndKitOverlay
                  dragStartDndKitEvent={dragStartDndKitEvent}
                  model={model} />
              ) : null}
            </DragOverlay>, document.getElementById('the-body'))}

        </DndContext>
      </div>
    );
  };

  const renderBlankSection = (prompt, index, useMargin) => {
    const dataId = prompt.id;
    // const showAnswerFeedback = lessonElementState.showAnswerFeedback;

    const promptPointLeftPercent = prompt?.point?.leftPercent ? `${prompt.point.leftPercent}%` : 'auto';
    const promptPointTopPercent = prompt?.point?.topPercent ? `${prompt.point.topPercent}%` : 'auto';

    const elemStyleWidth = prompt.widthPercent ? `${prompt.widthPercent}%`
      : 'fit-content';

    const elemStyleHeight = prompt.heightPercent ? `${prompt.heightPercent}%`
      : 'fit-content';

    let topPct = promptPointTopPercent;
    if (model.contentType === 'text' && topPct && prompt.pointerDirection === 'right') {
      topPct = `calc(${topPct && topPct !== 'auto' ? topPct : 0} - 16.5px)`;
    }

    // TODO
    // else if (model.contentType === 'image' && model.answerBankPosition === 'top' && topPct) {
    //   topPct = `calc((${imageLabelTitleOffset || 0}px - ${getStyleVar('--toolbar-height')}) + ${topPct || 0})`;
    // }

    // default non-margin use.
    let elemStyle = {
      height: elemStyleHeight,
      minHeight: 25,
      left: promptPointLeftPercent,
      top: topPct,
      width: elemStyleWidth
    };

    if (useMargin) {
      let promptPointTop = prompt.point.top;

      if (promptPointTop && prompt.pointerDirection === 'right') {
        promptPointTop -= 16.5;
      }

      // TODO remove
      // eslint-disable-next-line no-unused-vars
      // const [_leftMargin, _rightMargin, topMargin, bottomMargin] = [
      //   model.leftMargin === 'none' ? 'trueNone' : model.leftMargin,
      //   model.rightMargin === 'none' ? 'trueNone' : model.rightMargin,
      //   model.topMargin === 'none' ? 'trueNone' : model.topMargin,
      //   model.bottomMargin === 'none' ? 'trueNone' : model.bottomMargin
      // ].map(marginString => ImageLabelMargins[marginString]);

      const NUMBERED_BLANK_HEIGHT = 28;
      const CUSTOM_STYLE_TOP_OFFSET = model?.numberedBlanks ? NUMBERED_BLANK_HEIGHT : 0;

      // const topMargin = !model.topMargin || model.topMargin === 'none' ? CUSTOM_TOP_MARGIN : ImageLabelMargins[model.topMargin];
      // const bottomMargin = !model.bottomMargin || model.bottomMargin === 'none' ? CUSTOM_BOTTOM_MARGIN : ImageLabelMargins[model.bottomMargin];

      let elemStyleTop;
      if (model.responseFormat === 'text' || model.responseFormat === 'numeric') {
        elemStyleTop = `calc(${promptPointTop}px + ${imageLabelTitleOffset}px)`;
      // TODO
      // } else {
      //   elemStyleTop = `calc(${promptPointTop}px + ${imageLabelTitleOffset}px + ${topMargin}px + ${bottomMargin}px)`;
      // }
      } else {
        elemStyleTop = `calc(${promptPointTop}px + ${CUSTOM_STYLE_TOP_OFFSET}px`;
      }

      elemStyle = {
        ...elemStyle,
        height: elemStyleHeight,
        left: promptPointLeftPercent,
        top: elemStyleTop,
        width: elemStyleWidth
      };
    }

    const innerStyle = {
      // TODO remove // minWidth: 'unset',
      whiteSpace: 'normal'
    };

    const responseAnswerPrompt = promptService.getCurrentPrompt({
      dataId,
      lessonElementState,
      model,
      promptType: 'responseAnswerPrompt'
    }) || {};

    const { id, text, ...responseAnswerPromptRest } = responseAnswerPrompt;

    userInputMap?.set(dataId, {
      dataId,
      text: responseAnswerPrompt?.text?.trim?.(),
      ...responseAnswerPromptRest
    });

    const currentUserInputValue = lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text || userInputMap?.get(dataId)?.text || '';

    const currentUserInputId = lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.id || userInputMap?.get(dataId)?.id || '';

    const responseItems = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || []);
    const currentResponseItemIndex = currentUserInputId ? responseItems?.findIndex((responseItem) => {
      return responseItem.id === currentUserInputId;
    }) || 0 : undefined;

    // TODO unused // const currentResponseItem = responseItems[currentResponseItemIndex];

    const promptAnswerStatus = promptService.getAnswerStatusForCurrentPrompt({
      currentUserInputValue,
      dataId,
      model
    });

    const uiState = questionFeedbackManager.getUiState?.(lessonElementId);
    const { noSubmit } = questionFeedbackManager;

    const showAnswerFeedback = (uiState?.showAnswerFeedback && !noSubmit) || false;

    const isDragDisabled = playerService.isReadOnly(lessonElementId) /* || !currentUserInputId */ || false;

    return (
      <div key={dataId}
        className={classNames('imageLabelDrag dragDrop noFocusOutline image-label-drag-drop-section image-label-drag-drop-blank-section',
          model.responseFormat,
          // prompt.pointerDirection, // TODO remove, this causes prompt position issues
          {
            [prompt.pointerDirection]: model.contentType === 'text' || model.targetType === 'arrow',
            feedback: showAnswerFeedback
          })}
        data-id={dataId}
        style={{
          ...elemStyle
          // TODO remove // minHeight: elemStyle.height
        }}>
        <FeedbackIcon
          answerId={dataId}
          lessonElementId={lessonElementId}
          lessonElementState={lessonElementState}
          model={model}
          uiState={uiState}
          {...lessonElementState.currentResponseAnswer}
          {...textQuestionUtilsService.getFeedbackIconOptionsForDecoratingTextInputs({
            feedbackIconSvgTransform: 'translate(-13, -10)'
          })} />

        <div className={classNames('imageLabelDragInner', model?.targetType, {
          'has-content': !!currentUserInputValue
        })} style={{
          ...(prompt.widthPercent ? innerStyle : {})
        }}>
          <div className={classNames(`imageLabelResponse noFocusOutline normal ${promptAnswerStatus}`, {
            'arrow_box': model?.targetType === 'arrow',
            'has-content': !!currentUserInputValue
          })} data-color={prompt.labelBackgroundColor}>
            <DndKitDroppable droppableId={dataId}>
              <div className={classNames('image-label-response imageLabelResponseText noFocusOutline', {
                imageLabelResponseNumbered: model.numberedBlanks
              })}
                data-prompt-id={dataId}
                data-prompt-num={index + 1}>
                <div className='image-label-blank-droppable'/* tabIndex='0' */>
                  <div className={classNames('image-label-blank-droppable-inner', {
                    'image-label-blank-droppable-inner-has-value': !!currentUserInputValue/* && !droppableSnapshot.draggingFromThisWith */
                  })}
                  title={stripTagsAndEntities(currentUserInputValue || '')}>
                    <DndKitDraggable key={dataId}
                      activeStyle={{
                        background: getStyleVar('--theme-drag-drop-option-bg-color'),
                        border: `1px solid ${getStyleVar('--theme-drag-drop-option-border-color')}`,
                        padding: '12px'
                        // zIndex: 999 // TODO // 999 is the default for dnd-kit draggables, but we can lower this here (if needed)
                      }}
                      draggableId={dataId}
                      draggableInnerId={currentUserInputId}
                      draggableValue={currentUserInputValue}
                      droppableId={dataId}
                      index={currentResponseItemIndex}
                      isDragDisabled={isDragDisabled}>
                      <div
                        className={classNames('image-label-drag-drop-prompt-option')}
                        style={{
                          minHeight: elemStyleHeight,
                          minWidth: elemStyleWidth,
                          color: getStyleVar('--theme-drag-drop-option-text-color'),
                          // userSelect: 'none'
                        }}>
                        <div className={classNames('image-label-blank-droppable-inner-text',
                          `text-align-${model.answerAlignment || 'left'}`)}
                        data-answer-id={currentUserInputId || null}>
                          <HtmlComponent htmlStr={stripWrappingParagraphTags(currentUserInputValue)} useSpan />
                        </div>
                      </div>
                    </DndKitDraggable>
                  </div>
                </div>

              </div>
            </DndKitDroppable>
          </div>
        </div>
      </div>
    );
  };
  return !loadingDragDrop && (model?.contentType === 'text' || imageLabelImg) && renderImageLabelDragDrop();
});

export default ImageLabelDragDrop;
