import { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/ChartGraphicOrganizer.scss';

import chartGraphicOrganizerService from '../../services/ChartGraphicOrganizerService';
import useStyleEvents from '../../../hooks/useStyleEvents';

const KWLChart = observer(({ lessonElementId }) => {
  // eslint-disable-next-line no-empty-pattern
  const { /* managers here */ } = useContext(MobXProviderContext);

  const {
    columnPercentWidth,
    columns,
    currentResponseAnswer,
    directions,
    feedbackState,
    hasColumnContent,
    hasDirections,
    hasTitle,
    isSingleHeader,
    lessonElementState,
    model,
    numColumns,
    readOnly,
    showAnswers,
    singleHeaderText,
    title,
    toolbar
  } = chartGraphicOrganizerService.initChartGraphicOrganizer({ lessonElementId });

  useStyleEvents(lessonElementId);

  return chartGraphicOrganizerService.renderChart({
    chartPrefixClassName: 'kwlchart',
    columnPercentWidth,
    columns,
    currentResponseAnswer,
    directions,
    feedbackState,
    hasColumnContent,
    hasDirections,
    hasTitle,
    isSingleHeader,
    lessonElementId,
    lessonElementState,
    model,
    numColumns,
    readOnly,
    showAnswers,
    singleHeaderText,
    title,
    toolbar
  });
});

export default KWLChart;
