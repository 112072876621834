import audioManager from '../managers/AudioManager';
import highlightAnnotationManager from '../managers/HighlightAnnotationManager';
import lessonManager from '../managers/LessonManager';
import navMenuManager from '../managers/NavMenuManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import responseManager from '../managers/ResponseManager';
import scoringManager from '../managers/ScoringManager';
import studentActivityManager from '../managers/StudentActivityManager';
import timeManager from '../managers/TimeManager';
import toolbarManager from '../managers/ToolbarManager';
import userManager from '../managers/UserManager';
import videoManager from '../managers/VideoManager';

import { ContentType, FlyoutOpenState, FlyoutSide, FlyoutTabs, LessonMode, LessonSlideIds } from '../../Constants';

import alignmentService from './AlignmentService';
import highlightAnnotationService from './HighlightAnnotationService';
import questionService from './QuestionService';
import responseService from './ResponseService';
import scoringService from './ScoringService';
import studentActivityService from './StudentActivityService';
import toolbarService from './ToolbarService';
import htmlService from './HtmlService';

export class NavMenuService {
  cardWidth = 500;

  // do not remove saveLock without a code review and a reason.
  // this prevents too many server hits and  race conditions
  // between saving events
  saveLock = false;

  initActivityNavigatorLessonElementMap = ({ activityLessonElement } = {}) => {
    const activityNavigatorLessonElementMap = new Map();

    for (let index = 0; index < activityLessonElement?.lessonElementIds?.length; index++) {
      const childElementId = activityLessonElement.lessonElementIds[index];

      const childModel = lessonManager.getSlideModel(childElementId);
      const parentModel = lessonManager.getSlideModel(childModel?.parentActivityElementId);

      if (childModel.inGroup) {
        const groupList = childModel.inGroup ? activityLessonElement.lessonElementGroups.get(childModel.groupId) || [] : [];
        const isFirstInGroup = groupList.indexOf(childModel.lessonElementId) === 0;
        activityNavigatorLessonElementMap.set(childModel.lessonElementId, {
          childModel,
          parentModel,
          groupList,
          index,
          isFirstInGroup
        });
      } else {
        activityNavigatorLessonElementMap.set(childModel.lessonElementId, {
          childModel,
          parentModel,
          index
        });
      }
    }
    navMenuManager.setActivityNavigatorLessonElementMap(activityNavigatorLessonElementMap);
  }

  async previousSlideHandler(event) {
    try {
      lessonManager.setIgnoreQuestionChildEventScrollListener(true);
      setTimeout(async () => {
        lessonManager.setIgnoreQuestionChildEventScrollListener(false);
      }, 300);

      const array = navMenuManager.activityNavigatorLessonElementValues;
      const filteredArray = array.filter((item) => {
        if (lessonManager.getFilteredSlides().indexOf(item) > -1) {
          return true;
        }
        return false;
      });

      const isFirstIndex = (filteredArray.length <= 0) || lessonManager.currentLessonElementId === filteredArray[0];

      if (lessonManager.lessonOptions.navigateWithinActivity && (questionService.activityWithQuestions(lessonManager.currentLessonElementId) && !isFirstIndex)) {
        const lessonElementId = undefined;
        await this.navToSlideClickHandler(lessonElementId, {
          fromPreviousSlideHandler: true
        });
      } else {
        if (!this.saveLock) {
          this.saveLock = true;
          await this.doPreNavigationActions(false);
          lessonManager.previousSlide();
          await this.doPostNavigationActions({ event, fromPreviousSlideHandler: true });
          this.saveLock = false;
        }
      }
    } catch (error) {
      this.saveLock = false;
      console.error(error);
    }
  }

  async nextSlideHandler(event) {
    try {
      lessonManager.setIgnoreQuestionChildEventScrollListener(true);
      setTimeout(async () => {
        lessonManager.setIgnoreQuestionChildEventScrollListener(false);
      }, 300);

      const array = navMenuManager.activityNavigatorLessonElementValues;
      const filteredArray = array.filter((item) => {
        if (lessonManager.getFilteredSlides().indexOf(item) > -1) {
          return true;
        }
        return false;
      });

      const isLastIndex = (filteredArray.length <= 0) || lessonManager.currentLessonElementId === filteredArray[filteredArray.length - 1];

      if (lessonManager.lessonOptions.navigateWithinActivity && questionService.activityWithQuestions(lessonManager.currentLessonElementId) && !isLastIndex) {
        const lessonElementId = undefined;
        await this.navToSlideClickHandler(lessonElementId, {
          fromNextSlideHandler: true
        });
      } else {
        if (!this.saveLock) {
          this.saveLock = true;
          await this.doPreNavigationActions(false);
          lessonManager.nextSlide();
          await this.doPostNavigationActions({ event, fromNextSlideHandler: true });
          this.saveLock = false;
        }
      }
    } catch (error) {
      this.saveLock = false;
      console.error(error);
    }
  }

  async navToSlideClickHandler(lessonElementId, {
    event,
    fromNextSlideHandler = false,
    fromPreviousSlideHandler = false,
    fromCardHandler = false,
    shouldReturnIfSameLessonElementId = true,
    shouldScrollQuestionIntoView = true,
    sideNavVideoProgressValue,
    sideNavAudioProgressValue
  } = {}) {
    try {
      if (window.ReadSpeaker && window.ReadSpeaker.ui?.getActivePlayer?.()) {
        window.ReadSpeaker.ui.getActivePlayer().close();
        window.ReadSpeaker.ui.destroyActivePlayer();
      }

      let model, parentModel;
      const isSameLessonElementId = lessonElementId === lessonManager.currentLessonElementId;
      let isSameParent = false;
      const lessonElement = lessonManager.getLessonElement(lessonElementId);
      const currentElement = lessonManager.getLessonElement(lessonManager.currentLessonElementId);

      if (!isSameLessonElementId && lessonElement && currentElement &&
        lessonElement.isActivityPart && currentElement.isActivityPart) {
        if (lessonElement.parentActivityElementId === currentElement.parentActivityElementId) {
          isSameParent = true;
        }
      }

      if ((shouldReturnIfSameLessonElementId && isSameLessonElementId)) {
        return;
      }
      if ((shouldReturnIfSameLessonElementId && isSameParent && fromCardHandler)) {
        return;
      }

      if (fromNextSlideHandler || fromPreviousSlideHandler) {
        const oldIndex = navMenuManager.selectedActivityNavigatorLessonElementIndex;
        const newIndex = fromNextSlideHandler ? oldIndex + 1 : oldIndex - 1;

        if (!navMenuManager.activityNavigatorLessonElementValues[newIndex]) {
          return;
        }

        const {
          childModel: _childModel,
          parentModel: _parentModel,
        } = navMenuManager.activityNavigatorLessonElementValues[newIndex];

        model = _childModel;
        parentModel = _parentModel;

        lessonElementId = _childModel.lessonElementId;
      } else {
        model = lessonManager.getSlideModel(lessonElementId);
        parentModel = lessonManager.getSlideModel(model?.parentActivityElementId);
      }

      let selectedChildLessonElement;

      if (questionService.activityWithQuestions(lessonElementId)) {
        selectedChildLessonElement = navMenuManager.activityNavigatorLessonElementMap.get(lessonElementId);
        if (selectedChildLessonElement?.index >= 0) {
          navMenuManager.setSelectedActivityNavigatorLessonElementIndex(
            selectedChildLessonElement.index
          );
        }
      }

      if (parentModel?.type === ContentType.VIDEO_QUESTION.type) {
        this.updateVideoQuestionNavSettings({
          model, parentModel, sideNavVideoProgressValue
        });
      } else if (parentModel?.type === ContentType.AUDIO_QUESTION.type) {
        this.updateAudioQuestionNavSettings({
          model, parentModel, sideNavAudioProgressValue
        });
      }

      const slides = lessonManager.getFilteredSlides();
      let index = slides.indexOf(lessonElementId);

      // if we are navigating to an activity and the first item isn't visible
      // find one that is.
      if (model?.isActivity && index < 0) {
        for (const childId of lessonElement.lessonElementIds) {
          index = slides.indexOf(childId);
          if (index > -1) {
            break;
          }
        }
      }

      const skipSave = (questionFeedbackManager.noSubmit) ? false : event?.type === 'scroll';

      if (videoManager.isVideoTranscriptDialogOpen) {
        videoManager.setIsVideoTranscriptDialogOpen(false);
      }

      if (!this.saveLock) {
        this.saveLock = true;
        await this.doPreNavigationActions(skipSave);
        lessonManager.setCurrentSlideIndex(index);
        await this.doPostNavigationActions({
          event,
          shouldScrollQuestionIntoView,
          fromNextSlideHandler,
          fromCardHandler
        });
        this.saveLock = false;
      }
    } catch (error) {
      this.saveLock = false;
      console.error(error);
    }
  }

  updateVideoQuestionNavSettings = ({
    model,
    parentModel,
    sideNavVideoProgressValue
  } = {}) => {
    if (parentModel?.type !== ContentType.VIDEO_QUESTION.type) {
      return;
    }
    let updatedProgressValue;
    if (!isNaN(sideNavVideoProgressValue) && typeof sideNavVideoProgressValue === 'number') {
      updatedProgressValue = sideNavVideoProgressValue;
    } else {
      updatedProgressValue = model?.showTime;
    }
    if (updatedProgressValue !== videoManager.sideNavVideoProgressValue &&
      (parentModel.pauseAtChildShowTime || !videoManager.isVideoPlaying)
    ) {
      videoManager.setSideNavVideoProgressValue(updatedProgressValue);
    }
  }

  updateAudioQuestionNavSettings = ({
    model,
    parentModel,
    sideNavAudioProgressValue
  } = {}) => {
    if (parentModel?.type !== ContentType.AUDIO_QUESTION.type) {
      return;
    }
    let updatedProgressValue;
    if (!isNaN(sideNavAudioProgressValue) && typeof sideNavAudioProgressValue === 'number') {
      updatedProgressValue = sideNavAudioProgressValue;
    } else {
      updatedProgressValue = model?.showTime;
    }
    if (updatedProgressValue !== audioManager.sideNavAudioProgressValue &&
      (parentModel.pauseAtChildShowTime || !audioManager.isAudioPlaying)
    ) {
      audioManager.setSideNavAudioProgressValue(updatedProgressValue);
    }
  }

  gotoButtonHandler = (_event, toggle) => {
    navMenuManager.toggleGotoMenuState(toggle);
  }

  scrollDownHandler = (ref) => {
    if (ref.current) {
      ref.current.scrollTop += 100;
    }
  }

  scrollUpHandler = (ref) => {
    if (ref.current) {
      ref.current.scrollTop -= 100;
    }
  }

  scrollRightHandler = (ref) => {
    if (ref.current) {
      ref.current.scrollLeft += (this.cardWidth + 22);
    }
  }

  scrollLeftHandler = (ref) => {
    if (ref.current) {
      ref.current.scrollLeft -= (this.cardWidth + 22);
    }
  }

  toggleSectionOpen = (lessonElementId) => {
    const toggle = navMenuManager.summarySectionOpenToggles.get(lessonElementId);
    if (toggle === undefined || toggle === null) {
      return;
    }
    navMenuManager.setSummarySectionOpen(lessonElementId, !toggle);
  }

  doPreNavigationActions = async (skipSave = false) => {
    const mode = lessonManager.playerMode;
    const model = lessonManager.getSlideModel(lessonManager.currentLessonElementId);
    if (model === null) { // can be null in item preview mode.
      return;
    }

    const state = responseManager.getLessonElementState(lessonManager.currentLessonElementId);

    if (state && state.correctAnswerShowing) {
      state.questionBehavior.resetStudentAnswer(state, false);
      state.turnOffAnswerShowing();
      if (state.isSubmitted) {
        responseManager.scoreResponse(model, lessonManager.currentLessonElementId);
      }
    }

    audioManager.clearAudioQue();

    // Only close resource if we are leaving the activity.
    if (!model?.isActivityPart && toolbarManager.isResourcesOpen) {
      toolbarManager.setIsResourcesOpen(false);
      toolbarManager.toggleToolOff(toolbarManager?.resourceOpenId);
    }

    const allowSave = !skipSave;

    if (allowSave && mode === LessonMode.ACTIVITY_MODE) {
      toolbarService.buildToolPersistence(lessonManager.currentLessonElementId);
      if (model.isActivityPart) {
        toolbarService.buildToolPersistence(model.parentActivityElementId);
      }
      await timeManager.stopLessonElementTimer(lessonManager.currentLessonElementId);
      await responseService.saveSingleResponse(lessonManager.currentLessonElementId);
    }

    if (lessonManager.playerMode === LessonMode.SCORING) {
      await scoringService.updateTestItemScore(lessonManager.currentLessonElementId);
      await scoringService.submitTeacherComment(lessonManager.currentLessonElementId);
    }

    if (questionFeedbackManager.noSubmit && (lessonManager.playerMode === LessonMode.PREVIEW || lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW) &&
      state && state.questionBehavior && state.currentResponseAnswer) {
      const answered = state.questionBehavior.checkForValidResponse(state.currentResponseAnswer, model);

      if ((answered && model.isTestItem)) {
        responseManager.setPreviewResponseSubmitted(lessonManager.currentLessonElementId, true);
      }
    }

    if (toolbarManager.isStickyNotesOpen) {
      await highlightAnnotationService.cancelCreateNote();
    }
  }

  toggleInteractiveFlyout = () => {
    if (lessonManager.isInteractiveFlyoutOpen) {
      lessonManager.setIsInteractiveFlyoutOpen(false);
    } else {
      lessonManager.setIsInteractiveFlyoutOpen(true);
    }
  }

  setInteractiveFlyoutOpen = (val) => {
    lessonManager.setIsInteractiveFlyoutOpen(val);
  }

  async doPostNavigationActions({
    event,
    shouldScrollQuestionIntoView = true,
    fromNextSlideHandler = false,
    fromPreviousSlideHandler = false,
    fromCardHandler = false,
  } = {}) {
    const lessonElementId = lessonManager.currentLessonElementId;
    const model = lessonManager.getSlideModel(lessonElementId);
    if (model === null) { //can be null in item_preview mode
      return;
    }
    const { isActivityPart } = model;
    const parentLessonElementId = (isActivityPart) ? model.parentActivityElementId : null;

    if (lessonManager.isInteractiveViewer) {
      const { interactiveElementId } = lessonManager;

      if (parentLessonElementId !== interactiveElementId) {
        lessonManager.setIsInteractiveFlyoutOpen(false);
        lessonManager.setInteractiveElementId(parentLessonElementId);
      } else {
        lessonManager.setIsInteractiveFlyoutOpen(true);
      }
    }

    questionFeedbackManager.setHintDialogOpen(false);
    questionFeedbackManager.setSolutionDialogOpen(false);
    htmlService.closeMathTypeModal();

    // Only close resource if we are leaving the activity.
    if (!model?.isActivityPart && toolbarManager.isResourcesOpen) {
      toolbarManager.setIsResourcesOpen(false);
      toolbarManager.toggleToolOff(toolbarManager?.resourceOpenId);
    }

    if (shouldScrollQuestionIntoView) {
      questionService.scrollQuestionIntoView(lessonElementId, {
        shouldScrollQuestionIntoView,
        fromNextSlideHandler,
        fromPreviousSlideHandler,
        fromCardHandler
      });
    }

    const lessonElement = lessonManager.getLessonElement(lessonManager.currentLessonElementId);
    const lessonElementState = responseManager.getLessonElementState(lessonManager.currentLessonElementId);

    responseService.loadSlide(lessonElementId, lessonManager.playerMode);

    if (this.shouldGetLessonAlignments() && lessonManager.lessonContentItem) {
      await alignmentService.fetchServerLessonAlignmentData(lessonManager.lessonContentItem.id,
        lessonManager.courseContentItemId, studentActivityManager.activityId);

      if (model && model.isActivityPart) {
        await alignmentService.fetchContentItemAlignments(model.parentActivityElementId,
          lessonManager.courseContentItemId, studentActivityManager.activityId, lessonElement.entityId);
      }

      if (lessonElement && model && studentActivityManager && lessonManager) {
        await alignmentService.fetchContentItemAlignments(lessonElement.id,
          lessonManager.courseContentItemId, studentActivityManager.activityId, lessonElement.entityId);
      }
    }

    let gotRubric = false;

    if (this.shouldGetRubric() &&
      model && model.rubricEntityId) {
      if (!scoringManager.rubricMap.has(lessonElement.id)) {
        gotRubric = await scoringService.fetchContentItemRubric(lessonElement.id, model);
      } else {
        gotRubric = true;
      }

      if (gotRubric) {
        const lessonElementState = responseManager.getLessonElementState(lessonElement.id);
        if (lessonElementState && lessonElementState.subscore) {
          scoringManager.setSubscore(lessonElement.id, JSON.parse(lessonElementState.subscore), lessonElementState.isScored);
        }
      }
    }

    if (lessonManager.playerMode === LessonMode.ACTIVITY_MODE) {
      if (lessonManager.currentLessonElementId !== LessonSlideIds.SUMMARY_PAGE && lessonManager.currentLessonElementId !== LessonSlideIds.TITLE_PAGE) {
        await studentActivityService.saveLastViewed(lessonManager.currentLessonElementId);
        timeManager.startLessonElementTimer(lessonManager.currentLessonElementId);
      }
    }

    toolbarService.toggleTools();

    if (lessonManager.playerMode === LessonMode.ACTIVITY_MODE || lessonManager.playerMode === LessonMode.SCORING) {
      toolbarService.loadToolPersistence(lessonManager.currentLessonElementId, lessonElementState);
      if (model.isActivityPart) {
        const parentState = responseManager.getLessonElementState(model.parentActivityElementId);
        toolbarService.loadToolPersistence(model.parentActivityElementId, parentState.toolPersistence);
      }
    }
    if (lessonManager.playerMode === LessonMode.PREVIEW || lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW) {
      if (lessonElementState && lessonElementState.isSurvey && !lessonElementState.isUnScored) {
        responseManager.addNotScoredList(lessonElementId);
      }
    }

    if (toolbarManager.isHighlightAnnotationOpen || toolbarManager.isToolOn('StickyNotes')) {
      const highlightableElements = document.querySelectorAll('.html-input, .paragraph-wrapper');
      for (const highlightableElement of highlightableElements) {
        highlightableElement.classList.add('sticky-notes-active');
      }
      if (model.isActivityPart) {
        const parentLessonElement = lessonManager.getLessonElement(model.parentActivityElementId);
        if (parentLessonElement && parentLessonElement.lessonElementIds) {
          await highlightAnnotationService.showHighlights(parentLessonElement?.id);
          for (const childLessonIds of parentLessonElement.lessonElementIds) {
            await highlightAnnotationService.showHighlights(childLessonIds);
          }
        }
      } else {
        // reset the current dom element as we have changed context
        highlightAnnotationManager.setCurrentDomElementId(null);
        await highlightAnnotationService.showHighlights(lessonManager.currentLessonElementId);
      }
    }
  }

  doNavigationForPrintPreview = async (id) => {
    const model = lessonManager.getSlideModel(id);
    const lessonElement = lessonManager.getLessonElement(id);
    if (!model.isActivity) {
      responseService.loadSlide(id, LessonMode.PREVIEW);
    }
  }

  shouldGetTeacherComments = () => {
    const mode = lessonManager.playerMode;
    if ((mode === LessonMode.SCORING || mode === LessonMode.REVIEW) &&
    (lessonManager.currentLessonElementId !== LessonSlideIds.TITLE_PAGE &&
        lessonManager.currentLessonElementId !== LessonSlideIds.SUMMARY_PAGE)) {
      return true;
    }
    return false;
  }

  shouldGetRubric = () => {
    const mode = lessonManager.playerMode;
    if ((mode === LessonMode.SCORING || mode === LessonMode.REVIEW ||
      ((mode === LessonMode.PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) && userManager.canViewAsTeacher)) &&
      (lessonManager.currentLessonElementId !== LessonSlideIds.TITLE_PAGE &&
      lessonManager.currentLessonElementId !== LessonSlideIds.SUMMARY_PAGE)) {
      return true;
    }
    return false;
  }

  shouldGetLessonAlignments = () => {
    if ((lessonManager.playerMode === LessonMode.REVIEW || lessonManager.playerMode === LessonMode.PREVIEW || lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW || lessonManager.playerMode === LessonMode.SCORING ||
      lessonManager.playerMode === LessonMode.PRINT_PREVIEW) &&
         lessonManager.currentLessonElementId !== LessonSlideIds.TITLE_PAGE &&
         lessonManager.currentLessonElementId !== LessonSlideIds.SUMMARY_PAGE) {
      return true;
    }
    return false;
  }

  initFlyoutOpenState = () => {
    const mode = lessonManager.playerMode;
    if (!lessonManager.structureMap) {
      return;
    }
    const lessonElementIds = Object.keys(lessonManager.structureMap);

    lessonElementIds.forEach((lessonElementId) => {
      const model = lessonManager.getSlideModel(lessonElementId);
      if (model && !navMenuManager.flyoutOpenStates.has(lessonElementId)) {
        const value = (mode === LessonMode.SCORING || mode === LessonMode.REVIEW) ?
          { open: FlyoutOpenState.OPEN, tab: FlyoutTabs.SCORE, side: FlyoutSide.RIGHT } :
          { open: FlyoutOpenState.CLOSED, tab: FlyoutTabs.NONE, side: (model?.isActivity) ? FlyoutSide.LEFT : FlyoutSide.RIGHT };
        navMenuManager.setFlyoutOpenState(value, lessonElementId);
      }
    });
  }

  initFlyupOpenState = (value, lessonElementId) => {
    if (navMenuManager.flyupOpenStates.size === 0 || !navMenuManager.flyupOpenStates.has(lessonElementId)) {
      navMenuManager.setFlyupOpenState(value, lessonElementId);
    }
  }

  toggleFlyupPanel = (lessonElementId) => {
    const state = navMenuManager.getFlyupOpenState(lessonElementId);
    if (state === FlyoutOpenState.OPEN) {
      navMenuManager.setFlyupOpenState(FlyoutOpenState.CLOSED, lessonElementId);
    } else {
      navMenuManager.setFlyupOpenState(FlyoutOpenState.OPEN, lessonElementId);
    }
  }

  toggleFlyoutPanel = (closeFirst, openState, lessonElementId) => {
    const os = navMenuManager.getFlyoutOpenState(lessonElementId);
    if (closeFirst && (os.open === FlyoutOpenState.OPEN || os.open === FlyoutOpenState.OPEN_WIDE)) {
      navMenuManager.setFlyoutOpenState({ open: FlyoutOpenState.CLOSED, tab: os.tab, side: os.side }, lessonElementId);
      return true; // we closed the panel
    } else {
      navMenuManager.setFlyoutOpenState({ open: openState, tab: os.tab, side: os.side }, lessonElementId);
      return false; // panel is open
    }
  }

  setFlyoutPanelTab = (tab, lessonElementId) => {
    const os = navMenuManager.getFlyoutOpenState(lessonElementId);
    navMenuManager.setFlyoutOpenState({ open: os.open, tab, side: os.side }, lessonElementId);
  }

  setCardWidths = () => {
    const buttonWidth = parseInt(getComputedStyle(document.documentElement)
      .getPropertyValue('--goto-nav-scroll-width'));

    const rootElement = document.documentElement;
    const width = rootElement.clientWidth;

    this.cardWidth = width - (buttonWidth * 2);
  }
}
export default new NavMenuService();
