import React from 'react';
import NumberCircle from '../NumberCircle';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import AudioFeedback from '../AudioFeedback';
import Auth from '../../lessonPlayer/services/AuthService';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const ImageLabelDragDrop = ({ model, isManuscriptShort }) => {
  let imgSrc = `${Auth.ecms}/api/redirectToResource?contentItemId=${model.contentItemId}&authKey=${Auth.authKey}`;
  if (!model.contentItemId) {
    imgSrc = model.imageSource;
  }

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Label Image Drag Drop</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Question:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Image Title:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.imageTitle} /></div>
      </div>
      <div className='maximum-image-size' style={{ position: 'relative' }}>
        {model.prompts.map((prompt, idx) => {
          return (
            <div key={prompt.id} className='image-label' style={{ top: `${prompt.point.topPercent}%`, left: `${prompt.point.leftPercent}%` }}>
              <NumberCircle number={idx + 1} />
            </div>
          );
        })}
        <img alt='' className='maximum-image-size' src={imgSrc} />
      </div>
      <div>
        {model.validation.prompts.map((prompt, idx) => {
          return (
            <React.Fragment key={prompt.id}>
              <div className='standalone-label has-number-circles'><NumberCircle number={idx + 1} />Accepted Answers:</div>
              <ul>
                {prompt.answers.map((answer, answerIdx) => {
                  return (
                    <li key={answerIdx} className='ckeditor-text answer'><HtmlComponent htmlStr={answer.text} /></li>
                  );
                })}
              </ul>
            </React.Fragment>
          );
        })}
      </div>
      <div className='standalone-label'>Distractors:</div>
      <ul>
        {model.validation.distractors && model.validation.distractors.map((distractor, idx) => {
          return (
            <li key={idx} className='ckeditor-text answer'><HtmlComponent htmlStr={distractor.text} /></li>
          );
        })}
      </ul>
      {/* advanced options */}
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
          <div className='label-value-row'>
            <div>Answer Format:</div>
            <div>{model.responseFormat}</div>
          </div>
        </>
      )}
      <div className='label-value-row'>
        <div>Answer Bank Heading:</div>
        <HtmlComponent htmlStr={model.answerBankHeading} />
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Answer Bank Position:</div>
            <div>{model.answerBankPosition}</div>
          </div>
          <div className='label-value-row'>
            <div>Answer Bank Layout:</div>
            <div>{model.answerLayoutType}</div>
          </div>
          <div className='label-value-row'>
            <div>Answer Bank Options Persist:</div>
            <div>{model.answersPersist}</div>
          </div>
          <div className='label-value-row'>
            <div>Show Duplicate Accepted Answers:</div>
            <div>{model.duplicateAnswers}</div>
          </div>
          <div className='label-value-row'>
            <div>Numbered Blanks:</div>
            <div>{(model.numberedBlanks) ? 'ON' : 'OFF'}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <AudioFeedback model={model} />
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default ImageLabelDragDrop;
