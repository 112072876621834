/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { autorun } from 'mobx';

import ReactPlayer from 'react-player';

import { Scrubber } from 'react-scrubber';

import Select from 'react-select';

import classNames from 'classnames';

import '../../../css/VideoQuestion.scss';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import { ContentType } from '../../../Constants';

import { register } from '../../../i18n';

import Auth from '../../services/AuthService';

import dropdownService from '../../services/DropdownService';
import navMenuService from '../../services/NavMenuService';
import playerService from '../../services/PlayerService';
import utilsService from '../../services/UtilsService';
import videoService from '../../services/VideoService';

import useStyleEvents from '../../../hooks/useStyleEvents';

import DirectionsFlyout from '../tabbedFlyout/DirectionsFlyout';
import Guideline from '../tools/Guideline';
import TranscriptDialog from '../TranscriptDialog';

const t = register('VideoQuestion');
const t2 = register('AriaLabels');

const VideoQuestion = observer(({ lessonElementId, model, useDirectionsFlyout = true }) => {
  const {
    lessonManager,
    navMenuManager,
    toolbarManager,
    videoManager
  } = useContext(MobXProviderContext);

  const [cachedExternalVideoState, setCachedExternalVideoState] = useState({
    loaded: 0,
    loadedSeconds: 0,
    played: 0,
    playedSeconds: 0
  });

  const isExternalStreaming = (model.mediaType === 'bookmark');

  const [selectedCCOption, setSelectedCCOption] = useState('');

  const [endTime, setEndTime] = useState(isExternalStreaming ? model.endTime : 0);
  const [endTimeDisplay, setEndTimeDisplay] = useState(utilsService.formatTimeMMSS(isExternalStreaming ? model.endTime : 0));

  const [externalViewedMarkerIndexList, setExternalViewedMarkerIndexList] = useState([]);

  const [scrubberValue, setScrubberValue] = useState();

  const [volumeValue, setVolumeValue] = useState(5);

  const [showSubtitleOptions, setShowSubtitleOptions] = useState(false);
  const [subtitleOptions, setSubtitleOptions] = useState();

  const [showTranscriptOptions, setShowTranscriptOptions] = useState(false);
  const [transcriptOptions, setTranscriptOptions] = useState();

  const ccButtonRef = useRef();
  const ccSelectRef = useRef();
  const externalPlayerRef = useRef();
  const nextRef = useRef();
  const pauseRef = useRef();
  const playRef = useRef();
  const previousRef = useRef();
  const scrubberRef = useRef();
  const scrubberWrapperRef = useRef();
  const transcriptButtonRef = useRef();
  const transcriptSelectRef = useRef();
  const videoRef = useRef();

  useStyleEvents(lessonElementId, `${lessonElementId}-activity-view`);

  useEffect(() => {
    if (!videoManager.shouldCallVideoScrubChangeHandler) {
      videoManager.setSelectedSideNavVideoChildLessonElementId(null);
      videoManager.setSideNavVideoProgressValue(null);
    }
    videoManager.setAllowShowDividedVideoQuestions(!!model.showAllChildren);
    videoManager.setIsVideoPlaying(false);
    videoManager.setPreviousVideoTimeProgress(0);
    videoManager.setSelectedVideoTranscriptOption(null);

    // TODO remove // const { disposer, interval } = listenForSideNavVideoProgressValueChange();
    const { disposer } = listenForSideNavVideoProgressValueChange();

    if (!isExternalStreaming) {
      addEventListeners();
      loadVideoAndTrackOptions();

      setEndTimeDisplay(utilsService.formatTimeMMSS(endTime));

      return () => {
        removeEventListeners();
        // TODO remove // clearInterval(interval);
        return () => {
          disposer();
        };
      };
    } else {
      // for external videos, state endTime needs to be initialized as model.endTime
      // (otherwise it will always be 0)
      setEndTime(model.endTime);
      setEndTimeDisplay(utilsService.formatTimeMMSS(model.endTime));
      return () => {
        disposer();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonElementId]);

  const listenForSideNavVideoProgressValueChange = () => {
    if (isExternalStreaming) {
      const disposer = autorun(() => {
        if (videoManager.shouldCallVideoScrubChangeHandler) {
          handleScrubChange(videoManager.sideNavVideoProgressValue, { forceUpdateProgress: true });
          videoManager.setShouldCallVideoScrubChangeHandler(false);
        }
      });
      return { disposer };
    } else {
      // TODO remove // const interval = setInterval(() => {
      const disposer = autorun(() => {
        if (videoManager.shouldCallVideoScrubChangeHandler) {
          handleScrubChange(videoManager.sideNavVideoProgressValue);
          videoManager.setShouldCallVideoScrubChangeHandler(false);
        }
      // TODO remove // }, 1000);
      });
      // TODO remove // return { interval };
      return { disposer };
    }
  };

  useEffect(() => () => {
    // on unmount
    videoManager.setIsVideoTranscriptDialogOpen(false);
  }, [videoManager]);

  function loadVideoAndTrackOptions() {
    const videoRefCurrent = videoRef.current;
    videoRefCurrent.src = redirectUrl;
    videoRefCurrent.load();

    setOptionsForSubtitlesAndTranscripts();
  }

  function addEventListeners() {
    const videoRefCurrent = videoRef.current;
    videoRefCurrent.addEventListener('loadedmetadata', initializeVideo);
    videoRefCurrent.addEventListener('timeupdate', updateProgress);
    videoRefCurrent.addEventListener('ended', videoEndedActions);
  }

  function removeEventListeners() {
    const videoRefCurrent = videoRef.current;
    if (videoRefCurrent) {
      videoRefCurrent.removeEventListener('loadedmetadata', initializeVideo);
      videoRefCurrent.removeEventListener('timeupdate', updateProgress);
      videoRefCurrent.addEventListener('ended', videoEndedActions);
    }
  }

  async function setOptionsForSubtitlesAndTranscripts() {
    if (model.subtitles?.length) {
      // set options for subtitles
      const _subtitleOptions = [{ value: -1, label: `${t('off', 'Off')}` }].concat((model.subtitles || []).map((subtitleObj, index) => {
        const remoteUrl = Auth.getResourceUrlByFileName(subtitleObj.remoteFileName);
        const trackRef = videoRef.current.getElementsByTagName('track')[index];
        trackRef.setAttribute('src', remoteUrl);
        return {
          label: subtitleObj.label,
          original: subtitleObj,
          remoteUrl,
          value: index
        };
      }));
      setSubtitleOptions(_subtitleOptions);
    }

    if (model.transcripts?.length) {
      // set options for transcripts
      const _transcriptOptions = [{ value: -1, label: `${t('off', 'Off')}` }].concat((model.transcripts || []).map((transcriptsObj, index) => {
        const remoteUrl = Auth.getResourceUrlByFileName(transcriptsObj.remoteFileName);
        return {
          label: transcriptsObj.label,
          original: transcriptsObj,
          remoteUrl,
          value: index
        };
      }));

      const hasOnlyOneTranscript = model.transcripts?.length === 1;
      if (hasOnlyOneTranscript) {
        // set 'selected transcript option' to the only one we have
        videoManager.setSelectedVideoTranscriptOption(_transcriptOptions[1]);
      }
      setTranscriptOptions(_transcriptOptions);
    }
  }

  useAccessibilityClick(ccButtonRef, (_event) => {
    if (showSubtitleOptions) {
      setShowSubtitleOptions(false);
      return;
    }
    setShowSubtitleOptions(true);
    ccSelectRef?.current?.focus?.();
  });

  useAccessibilityClick(transcriptButtonRef, (_event) => {
    const hasOnlyOneTranscript = model.transcripts?.length === 1;
    if (hasOnlyOneTranscript) {
      // no need to show transcript options since there is only one
      // i.e. we can immediately toggle transcript dialog on/off without opening a select dropdown first
      videoManager.setIsVideoTranscriptDialogOpen(!videoManager.isVideoTranscriptDialogOpen);
    } else {
      if (showTranscriptOptions) {
        setShowTranscriptOptions(false);
        return;
      }
      setShowTranscriptOptions(true);
      transcriptSelectRef?.current?.focus?.();
    }
  });

  const handleCCChange = (option) => {
    setSelectedCCOption(option);
    for (let index = 0; index < (videoRef?.current?.textTracks?.length || 0); index++) {
      const track = videoRef?.current?.textTracks[index];
      if (option.value >= 0 && option.value === index) {
        track.mode = 'showing';
      } else {
        track.mode = 'disabled';
      }
    }
    setShowSubtitleOptions(false);
  };

  const handleTranscriptChange = (option) => {
    videoManager.setSelectedVideoTranscriptOption(option);

    setShowTranscriptOptions(false);

    const isTranscriptSelected = option?.value >= 0;

    if (!isTranscriptSelected) {
      // ensure transcript dialog is closed since no transcript is selected
      if (videoManager.isVideoTranscriptDialogOpen) {
        videoManager.setIsVideoTranscriptDialogOpen(false);
      }
    } else if (videoManager.isVideoTranscriptDialogOpen) {
      // close for a fraction of a second, then reopen to refresh the transcript text
      videoManager.setIsVideoTranscriptDialogOpen(false);
      setTimeout(() => {
        videoManager.setIsVideoTranscriptDialogOpen(true);
      }, 400);
    } else if (!videoManager.isVideoTranscriptDialogOpen) {
      videoManager.setIsVideoTranscriptDialogOpen(true);
    }
  };

  const lessonElement = lessonManager.getLessonElement(lessonElementId);
  const childElementIds = lessonElement.lessonElementIds;

  let markerValuesEnt = null;
  if (model && model.childShowTimes && model.childIds) {
    markerValuesEnt = Object.entries(model.childShowTimes)
      .filter(([key, _value]) => model.childIds.includes(key))
      .sort((a, b) => a[1] - b[1]);
  }

  const markerValues = [];
  if (markerValuesEnt) {
    for (const mv of markerValuesEnt) {
      markerValues.push(mv[1]);
    }
  } else {
    markerValues.push(endTime - 1);
  }

  let viewedMarkerIndexList = [];

  let redirectUrl = null;
  if (!isExternalStreaming) {
    // eslint-disable-next-line max-len
    redirectUrl = `${Auth.ecms}/api/redirectToStreamUrl?authKey=${Auth.authKey}&entityId=${model.entityId}&entityTypeId=${model.entityTypeId}&cacheBuster=${(new Date().getTime())}`;
  } else {
    redirectUrl = model.sourceUrl;
  }

  // function handleScrubStart() {
  //   // placeholder
  // }

  function handleScrubEnd() {
    // placeholder
  }

  const handleVolumeChange = (event) => {
    const volume = parseInt(event.target.value);
    setVolumeValue(volume);

    if (!isExternalStreaming) {
      videoRef.current.volume = volume * 0.1;
    }
  };

  function handleScrubChange(value, { event, forceUpdateProgress = false } = {}) {
    const currentTime = !isExternalStreaming ? (
      videoRef?.current?.currentTime || 0
    ) : (
      externalPlayerRef?.current?.getCurrentTime?.() || 0
    );

    if (isExternalStreaming) {
      if (!externalPlayerRef?.current?.player?.isReady) {
        // external video player is still initializing, so return without running any of the below logic
        return;
      }
      if (!externalPlayerRef?.current?.getSecondsLoaded()) {
        handleExternalPlayerManualDataLoad(value);
        return;
      }
    }

    // user triggered play/pause via pressing spacebar or enter on focused scrubber container
    if (event?.key === ' ' || event?.key === 'Enter') {
      if (videoManager.isVideoPlaying) {
        handlePauseVideo();
        return;
      } else {
        handlePlayVideo();
        return;
      }
    }

    if (event?.key === 'ArrowLeft' || event?.key === 'ArrowRight') {
      if (videoManager.isVideoPlaying) {
        handlePauseVideo();
      }
      const SECONDS_VARIATION = 1;
      const SECONDS_VARIATION_SHIFT_MULTIPLIER = 8;

      const multiplier = event.shiftKey ? SECONDS_VARIATION_SHIFT_MULTIPLIER : 1;

      if (event.key === 'ArrowLeft') {
        // DECREASE scrub position by `SECONDS_VARIATION`
        const proposedValue = Math.ceil(currentTime) - (SECONDS_VARIATION * multiplier);
        value = proposedValue >= 0 ? proposedValue : 0;
      } else if (event.key === 'ArrowRight') {
        // INCREASE scrub position by `SECONDS_VARIATION`
        const proposedValue = Math.ceil(currentTime) + (SECONDS_VARIATION * multiplier);
        value = proposedValue < endTime ? proposedValue : endTime;
      }
    } else if (event?.key && !value) {
      return;
    }

    if (videoManager.isVideoTranscriptDialogOpen) {
      handleChangeVideoScrubberSeekPositionManually();
    }

    if (isNaN(value)) {
      value = 0;
    }

    videoManager.setPreviousVideoTimeProgress(Math.floor(value));
    setScrubberValue(value);

    if (!isExternalStreaming) {
      if (videoRef?.current) {
        videoRef.current.currentTime = value;
      }
      updateProgress(value);
      videoManager.setPreviousVideoTimeProgress(0);
    } else {
      externalPlayerRef?.current?.seekTo?.(value);
      const videoState = undefined;

      updateProgressExternal(videoState, value, { forceUpdateProgress });

      videoManager.setPreviousVideoTimeProgress(0);
    }
  }

  /**
   * Typically react-scrubber handles changing of the 'video scrubber seek position' automatically
   * (i.e. by design, it stops 'seeking' on `mousemove` when `mouseup` is triggered).
   *
   * But there is an issue where this does not work automatically when `TranscriptDialog` is open.
   *
   * So the purpose of this method is to manually handle the video scrubber seek position on change -
   * usable for cases that need it handled manually, such as when `TranscriptDialog` is open.
   */
  function handleChangeVideoScrubberSeekPositionManually(event = undefined) {
    if (event?.type === 'mouseup') {
      scrubberRef.current.handleSeekEnd();
      // eslint-disable-next-line no-useless-return
    } else {
      const isPointerWithinVideoPlayerToolbar = scrubberRef?.current?.state?.hover;
      if (!isPointerWithinVideoPlayerToolbar) {
        scrubberRef.current.handleSeekEnd();
      }
    }
  }

  /**
   * External video player needs data when seeking new position, else it will show infinite spinner.
   *
   * This method will manually trigger data to load for external video player and set to `progress` position`.
   */
  const handleExternalPlayerManualDataLoad = (progress) => {
    const shouldPauseAfterDataLoad = !externalPlayerRef?.current?.player?.isPlaying;
    handlePlayVideo();
    setTimeout(() => {
      if (shouldPauseAfterDataLoad) {
        handlePauseVideo();
      }
      externalPlayerRef?.current?.seekTo(progress);
    }, 500);
  };

  const handlePauseVideo = () => {
    if (!isExternalStreaming) {
      videoRef?.current?.pause?.();
    }
    videoManager.setIsVideoPlaying(false);
  };

  const handlePlayVideo = async () => {
    if (!isExternalStreaming) {
      await videoRef.current.play();
    }
    videoManager.setIsVideoPlaying(true);
  };

  useAccessibilityClick(playRef, (_event) => {
    handlePlayVideo();
    const currentTime = isExternalStreaming ? cachedExternalVideoState?.playedSeconds : videoRef?.current?.currentTime;
    videoManager.setAllowShowDividedVideoQuestions(
      !model.hideChildrenWhilePlaying && (!!model.showAllChildren || currentTime > 0 || videoManager.previousVideoTimeProgress !== 0)
    );
  });

  useAccessibilityClick(pauseRef, (_event) => {
    handlePauseVideo();
    videoManager.setAllowShowDividedVideoQuestions(true);
  });

  useAccessibilityClick(nextRef, (event) => {
    navMenuService.nextSlideHandler(event);
  });

  useAccessibilityClick(previousRef, (event) => {
    navMenuService.previousSlideHandler(event);
  });

  const { customDropdownStyles } = dropdownService.getDropdownConfig({
    model,
    shouldHideControl: true,
    shouldHideValueContainer: true
  });

  const customVideoContentLabel = videoService.getCustomVideoLayoutLabel({
    model,
    videoLabelPrefix: 'v-content'
  });

  function initializeVideo() {
    if (!isExternalStreaming && videoRef && videoRef.current) {
      let videoDuration = Math.floor(videoRef.current.duration);
      if (model && model.endTime) {
        videoDuration = model.endTime;
      }
      setEndTime(videoDuration);
      setEndTimeDisplay(utilsService.formatTimeMMSS(videoDuration));

      if (model && model.startTime) {
        setScrubberValue(model.startTime);
        videoRef.current.currentTime = model.startTime;
      } else {
        setScrubberValue(0);
        videoRef.current.currentTime = 0;
      }
    }
  }

  const updateProgress = (playedSeconds) => {
    const currentTime = (typeof playedSeconds === 'number' ? playedSeconds : undefined) || videoRef?.current?.currentTime || 0;
    if (currentTime === 0 && scrubberValue !== 0) {
      return;
    }
    const progress = Math.floor(currentTime);

    setScrubberValue(progress);

    if (playerService.isScoringMode()) {
      return;
    }

    if (markerValues.includes(progress)) {
      const index = markerValues.indexOf(progress);

      const isStartMarker = currentTime < 0.30;

      const isEndMarker = currentTime > model.endTime - 1;

      // eslint-disable-next-line max-len
      if (!viewedMarkerIndexList.includes(progress) || isStartMarker || isEndMarker || progress !== Math.floor(videoManager.previousVideoTimeProgress)) {
        if (model.pauseAtChildShowTime) {
          handlePauseVideo();
          videoManager.setAllowShowDividedVideoQuestions(true);
          videoManager.setPreviousVideoTimeProgress(progress);
        } else {
          videoManager.setAllowShowDividedVideoQuestions(!model.hideChildrenWhilePlaying || !videoManager.isVideoPlaying);
        }

        const proposedLessonElementId = childElementIds[index];

        if (proposedLessonElementId) {
          const proposedActivityNavigatorLessonElement = navMenuManager.activityNavigatorLessonElementMap.get(proposedLessonElementId);

          const { currentActivityNavigatorLessonElementId } = navMenuManager;

          lessonManager.setIsInteractiveFlyoutOpen(true);

          let lessonElementId;
          if (!proposedActivityNavigatorLessonElement?.groupList?.includes?.(currentActivityNavigatorLessonElementId)) {
            lessonElementId = proposedLessonElementId;
          } else {
            lessonElementId = currentActivityNavigatorLessonElementId;
          }

          navMenuService.navToSlideClickHandler(lessonElementId, {
            shouldReturnIfSameLessonElementId: false,
            sideNavVideoProgressValue: progress
          });
          if (!viewedMarkerIndexList.includes(progress)) {
            viewedMarkerIndexList.push(progress);
          }
        }
      }
    }
  };

  function updateProgressExternal(videoState, playedSeconds, { forceUpdateProgress = false } = {}) {
    if (videoState) {
      setCachedExternalVideoState(videoState);
    } else {
      setCachedExternalVideoState({
        ...cachedExternalVideoState,
        playedSeconds
      });
    }
    const currentTime = (typeof playedSeconds === 'number' ? playedSeconds : undefined) || videoState?.playedSeconds || 0;

    if (!forceUpdateProgress && currentTime === 0 && scrubberValue !== 0) {
      return;
    }

    const progress = Math.floor(currentTime);

    if (progress > endTime) {
      videoManager.setIsVideoPlaying(false);
      videoManager.setAllowShowDividedVideoQuestions(!!model.showAllChildren || !!videoManager.previousVideoTimeProgress);
      return;
    }

    setScrubberValue(progress);

    if (playerService.isScoringMode()) {
      return;
    }

    if (markerValues.includes(progress)) {
      const progressIndex = markerValues.indexOf(progress);

      const isStartMarker = currentTime < 0.40;

      const isEndMarker = currentTime > model.endTime - 1;

      if (!externalViewedMarkerIndexList.includes(progress)
        || isStartMarker || isEndMarker
        || progress !== Math.floor(videoManager.previousVideoTimeProgress)
      ) {
        if (model.pauseAtChildShowTime) {
          if (videoManager.isVideoPlaying) {
            handlePauseVideo();
          }
          videoManager.setAllowShowDividedVideoQuestions(true);
          videoManager.setPreviousVideoTimeProgress(progress);
        } else {
          videoManager.setAllowShowDividedVideoQuestions(!model.hideChildrenWhilePlaying || !videoManager.isVideoPlaying);
        }

        const proposedLessonElementId = childElementIds[progressIndex];

        if (proposedLessonElementId) {
          const proposedActivityNavigatorLessonElement = navMenuManager.activityNavigatorLessonElementMap.get(proposedLessonElementId);

          const { currentActivityNavigatorLessonElementId } = navMenuManager;

          lessonManager.setIsInteractiveFlyoutOpen(true);

          let lessonElementId;
          if (!proposedActivityNavigatorLessonElement?.groupList?.includes?.(currentActivityNavigatorLessonElementId)) {
            lessonElementId = proposedLessonElementId;
          } else {
            lessonElementId = currentActivityNavigatorLessonElementId;
          }

          navMenuService.navToSlideClickHandler(lessonElementId, {
            shouldReturnIfSameLessonElementId: false,
            sideNavVideoProgressValue: progress
          });
          setExternalViewedMarkerIndexList(Array.from(new Set([...viewedMarkerIndexList, progress])));
        }
      }
    }
  }

  function videoEndedActions() {
    viewedMarkerIndexList = [];
  }

  const isStandaloneVideoResource = model.type === ContentType.VIDEO.type;

  // TODO unused // const STANDALONE_VIDEO_TOP_OFFSET = 20;

  // TODO unused
  // let allowShowPlayButton;
  // if (!isStandaloneVideoResource) {
  //   // for Video Activity question type (i.e. not standalone video resource), it's safe to show the 'play' button whenever paused,
  //   // as the user is unable to scroll around within the page, so the button doesn't jump around.
  //   allowShowPlayButton = true;
  // } else if (videoRef?.current?.currentTime < 1) {
  //   // restricting the image of a 'play button' (to the constraints of a standalone Video Resource element)
  //   // becomes problematic when scrolling around the page, causing the 'play button' image to jump around.
  //   // so for this case we are only allowing the 'play button' image to show essentially on 'zero state'.
  //   // i.e. only show the 'play button' (on pause) when video has played less than one second of content.
  //   allowShowPlayButton = true;
  // }

  const renderClosedCaptionButton = () => {
    return (
      <>
        <div ref={ccButtonRef} aria-label={t2('closedCaption')} className='small-button cc-button' />
        <div className='cc-placeholder'>
          <div className={classNames('cc-select', {
            // eslint-disable-next-line quote-props
            'showSubtitleOptions': showSubtitleOptions
          })}>
            <Select
              ref={ccSelectRef}
              aria-label={t2('selectCaptionLanguage')}
              closeMenuOnSelect={false}
              isSearchable={false}
              menuPlacement='top'
              onChange={handleCCChange}
              openMenuOnFocus={true}
              options={subtitleOptions}
              styles={customDropdownStyles}
              value={selectedCCOption} />
          </div>
        </div>
      </>
    );
  };

  const renderTranscriptButton = () => {
    return (
      <>
        <div ref={transcriptButtonRef} aria-label={t2('transcript')} className='small-button transcript-button' />
        <div className='transcript-placeholder'>
          <div className={classNames('transcript-select', {
            // eslint-disable-next-line quote-props
            'showTranscriptOptions': showTranscriptOptions
          })}>
            <Select
              ref={transcriptSelectRef}
              aria-label={t2('selectTranscriptLanguage')}
              closeMenuOnSelect={false}
              isSearchable={false}
              menuPlacement='top'
              onChange={handleTranscriptChange}
              openMenuOnFocus={true}
              options={transcriptOptions}
              styles={customDropdownStyles}
              value={videoManager.selectedVideoTranscriptOption} />
          </div>
        </div>
      </>
    );
  };

  const VIDEO_NAV_BUTTONS_INITIAL_WIDTH = 230;
  const CC_BUTTON_WIDTH_OFFSET = 22;
  const TRANSCRIPT_BUTTON_WIDTH_OFFSET = 22;

  let videoNavButtonsMinWidth = VIDEO_NAV_BUTTONS_INITIAL_WIDTH;
  if (model.subtitles?.length) {
    videoNavButtonsMinWidth += CC_BUTTON_WIDTH_OFFSET;
  }
  if (model.resourceItem?.transcripts?.length) {
    videoNavButtonsMinWidth += TRANSCRIPT_BUTTON_WIDTH_OFFSET;
  }

  return (
    <>
      <div className={classNames('v-content', customVideoContentLabel)} id={`${lessonElementId}-activity-view`}>
        {(model.text && useDirectionsFlyout) ? (
          <DirectionsFlyout directions={model.text} isActivity={true} lessonElementId={model.lessonElementId} />
        ) : ''}
        {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}

        {(!isExternalStreaming) ? (
          <>
            <video ref={videoRef}
              className={classNames({
                'standalone-video-resource': isStandaloneVideoResource,
                'standalone-video-resource-no-directions': isStandaloneVideoResource && !model.text
              })}
              crossOrigin='anonymous'
              id='question-video' onClick={() => {
                if (videoManager.isVideoPlaying) {
                  pauseRef.current.click();
                } else {
                  playRef.current.click();
                }
              }}>
              {model.subtitles?.map?.((subtitle, index) => {
                return (
                  <track
                    key={`subtitle_key_${index}`}
                    data-filename={subtitle.remoteFileName}
                    kind='subtitles'
                    label={subtitle.label}
                    srcLang={subtitle.languageCode} />
                );
              })}
            </video>
            {!videoManager.isVideoPlaying && (
              <div className={classNames('question-video-play-img', customVideoContentLabel, {
                'standalone-video-resource': isStandaloneVideoResource
              })}
              // eslint-disable-next-line react/jsx-indent-props
              onClick={() => {
                playRef.current.click();
              }} />
            )}
          </>
        ) : (
          <ReactPlayer
            ref={externalPlayerRef}
            className='react-video-player'
            config={{
              youtube: {
                playerVars: { showinfo: 0 }
              }
            }}
            height='100%'
            onPause={() => {
              if (videoManager.isVideoPlaying) {
                handlePauseVideo();
              }
            }}
            onPlay={() => {
              if (!videoManager.isVideoPlaying) {
                handlePlayVideo();
              }
            }}
            onProgress={(state) => {
              updateProgressExternal(state);
            }}
            playing={videoManager.isVideoPlaying}
            progressInterval={1000}
            url={redirectUrl}
            volume={volumeValue * 0.1}
            width='100%' />
        )}

        <div className={`video-and-controls-container video-and-controls-container-${lessonManager.playerMode}`}>
          <div className='video-control-row'>
            <div className='nav-buttons' style={{ minWidth: videoNavButtonsMinWidth }}>
              {(videoManager.isVideoPlaying) ?
                <button ref={pauseRef} aria-label={t2('pause')} className='pauseButton controlIcon' type='button' />
                :
                <button ref={playRef} aria-label={t2('play')} className='playButton controlIcon' type='button' />}
              <div aria-label={t2('volume')} className='volumeIcon' />
              <input aria-label={t2('volumeControl')}
                className='volumeControl'
                max={10}
                min={0}
                onChange={handleVolumeChange}
                type='range'
                value={volumeValue} />
              {!!model.subtitles?.length && renderClosedCaptionButton()}
              {!!model.transcripts?.length && renderTranscriptButton()}

            </div>
            <div className='time-display'>{utilsService.formatTimeMMSS(scrubberValue)}{' / '}{endTimeDisplay}</div>
            <div ref={scrubberWrapperRef}
              className='scrubber-container'
              onKeyDown={(event) => handleScrubChange(undefined, { event })}
              onMouseUp={(event) => {
                if (videoManager.isVideoTranscriptDialogOpen) {
                  handleChangeVideoScrubberSeekPositionManually(event);
                }
              }}
              style={{ height: '40px', width: '100%' }}
              tabIndex={0}>
              <Scrubber
                ref={scrubberRef}
                markers={markerValues}
                max={(endTime > 0 ? endTime : model.endTime)/* + (isStandaloneVideoResource ? 0 : 1) */}
                min={(model.startTime) ? model.startTime : 0}
                onScrubChange={handleScrubChange}
                onScrubEnd={handleScrubEnd}
                onScrubStart={handleScrubChange}
                value={scrubberValue} />
            </div>
          </div>
        </div>

      </div>
      {videoManager.isVideoTranscriptDialogOpen && (
        <TranscriptDialog
          isOpen={videoManager.isVideoTranscriptDialogOpen}
          model={model}
          setIsOpen={videoManager.setIsVideoTranscriptDialogOpen} />
      )}
    </>
  );
});
export default VideoQuestion;
