import { action, makeObservable, observable } from 'mobx';

export class GraphPaperManager {
  constructor() {
    makeObservable(this);
  }

  @observable textContainers = [];

  dragProps = null;
  lessonElementId = null;

  @observable responseModel = {};

  @action
  getResponseModel = () => {
    return this.responseModel;
  }

  @action
  setResponseModel = (model) => {
    this.responseModel = model;
  }

  // // prevent data for a previous slide from being used in the current slide
  // @action init(lessonElementId) {
  //   if (this.lessonElementId !== lessonElementId) {
  //     this.lessonElementId = lessonElementId;
  //     this.textContainers = [];
  //     this.dragProps = null;
  //   }
  // }

  @action addTextContainer(textContainer) {
    this.textContainers = [...this.textContainers];
    this.textContainers.push(textContainer);
  }

  @action initializeTextContainers(textModels) {
    const textContainers = [];
    if (!textModels) {
      return;
    }

    textModels.forEach((textModel) => {
      const textContainer = {
        textId: textModel.id,
        top: textModel.position.top,
        left: textModel.position.left,
        text: textModel.text,
        opacity: 1,
      };
      textContainers.push(textContainer);
    });

    this.textContainers = textContainers;
  }

  @action setTextContainerText(textId, data) {
    const textContainer = this.textContainers.find((container) => container.textId === textId);
    textContainer.text = data;
    this.textContainers = [...this.textContainers];
  }

  findTextContainer(textId) {
    const textContainer = this.textContainers.find((container) => container.textId === textId);
    return textContainer;
  }

  @action deleteTextContainer(textId) {
    const newTextContainers = this.textContainers.filter((container) => container.textId !== textId);
    this.textContainers = newTextContainers;
  }

  @action dragStartTextContainer(textId, clientX, clientY, width, height) {
    this.dragProps = {
      textId,
      clientX,
      clientY,
      width,
      height,
    };
    const textContainer = this.findTextContainer(this.dragProps.textId);
    textContainer.opacity = 0.5;
    this.textContainers = [...this.textContainers];
  }

  @action dropTextContainer(clientX, clientY) {
    if (this.dragProps) {
      // const textContainer = this.textContainers.find((container) => container.textId === this.dragProps.textId);
      const textContainer = this.findTextContainer(this.dragProps.textId);

      let left = clientX - this.dragProps.clientX + textContainer.left;
      let top = clientY - this.dragProps.clientY + textContainer.top;

      // adjust if dropped outside the container
      if (left < 0) {
        left = 0;
      }
      if (left + this.dragProps.width > 600) {
        left = 600 - this.dragProps.width;
      }
      if (top < 0) {
        top = 0;
      }
      if (top + this.dragProps.height > 600) {
        top = 600 - this.dragProps.height;
      }

      textContainer.left = left;
      textContainer.top = top;
      textContainer.opacity = 1;

      this.textContainers = [...this.textContainers];

      this.dragProps = null;
    }
  }
}

export default new GraphPaperManager();
