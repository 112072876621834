import { action, makeObservable, observable } from 'mobx';

export class ClozeMultichoiceManager {
  constructor() {
    makeObservable(this);
  }

  @observable multichoiceDropdownItems = new Map();

  @action setMultichoiceDropdownItems(dataId, dropdownItems) {
    this.multichoiceDropdownItems.set(dataId, dropdownItems);
  }
}

export default new ClozeMultichoiceManager();
