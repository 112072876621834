import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import responseService from '../services/ResponseService';
import toolbarManager from '../managers/ToolbarManager';
import playerService from '../services/PlayerService';

const FeedbackIcon = observer((props = {}) => {
  const {
    answerId,
    feedbackIconStyleObj = {},
    feedbackIconSvgHeight = '23',
    feedbackIconSvgTransform = 'translate(0, 0)',
    feedbackIconSvgWidth = '23',
    isCorrectOverride = null,
    lessonElementId,
    lessonElementState = null,
    model = null,
    uiState = null
  } = props;

  const { questionFeedbackManager, responseManager } = useContext(MobXProviderContext);

  const feedbackState = uiState || questionFeedbackManager.getUiState(lessonElementId);
  const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;

  let isScored = false;
  const state = lessonElementState || responseManager.getLessonElementState(lessonElementId);

  if ((state && state.isScored) || playerService.isScoringMode) {
    isScored = true;
  }

  const isAutoScored = responseService.isAutoScored(lessonElementId);

  let isScoredCorrect = (isCorrectOverride === null) ?
    responseService.isAnswerCorrect(lessonElementId, answerId, { lessonElementState, model })
    : isCorrectOverride;

  if (toolbarManager.isToolbarAnswerFeedbackActive) {
    isScored = typeof isCorrectOverride === 'boolean' ? isCorrectOverride : true;
    isScoredCorrect = typeof isCorrectOverride === 'boolean' ? isCorrectOverride : true;
  }

  return (
    <>
      {(!feedbackState || !showAnswerFeedback || !isAutoScored || isScoredCorrect === null) ? <></> : (
        <div className={classNames('feedback-icon', { hidden: !showAnswerFeedback })}>
          {(feedbackState && showAnswerFeedback && isScoredCorrect && isScored && isAutoScored) && (
            <svg
              className='feedback-svg'
              height={feedbackIconSvgHeight}
              style={feedbackIconStyleObj}
              transform={feedbackIconSvgTransform}
              viewBox='0 0 23 23'
              width={feedbackIconSvgWidth}>
              <path d='M1062.292-6225.672l7.042-23 7.09 22.852-18.54-14.474h23z' fill='#508d14' transform='translate(-1057.885 6248.672)' />
            </svg>
          )}
          {// isScoredCorrect must check false and not use "!isScoredCorrect"
            // because it could be null which means something else
            (feedbackState && showAnswerFeedback && isScoredCorrect === false && isScored && isAutoScored) && (
              <svg
                className='feedback-svg'
                height={feedbackIconSvgHeight}
                style={feedbackIconStyleObj}
                transform={feedbackIconSvgTransform}
                viewBox='0 0 23 23'
                width={feedbackIconSvgWidth}>
                <path d='M3842.348-1199.53l3.011-3.121 8.45 8.434 8.472-8.434 3.067 3.121-8.426 8.363 8.426 8.511-3.067 3-8.472-8.444-8.45 8.444-3.011-3 8.421-8.511z' fill='#cd373d' transform='translate(-3842.348 1202.651)' />
              </svg>
            )
          }
        </div>
      )}
    </>
  );
});

export default FeedbackIcon;
