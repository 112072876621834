import React from 'react';

const Google = ({ model }) => {
  const types = {
    GoogleDoc: 'Google Doc',
    GoogleSheet: 'Google Sheet',
    GoogleSlide: 'Google Slide',
  };
  const type = types[model.type];

  return (
    <>
      <div className='top-label'>Google:</div>
      <div className='element-container' id={`_${model.id}`}>
        <div className='label-value-row'>
          <div>Google Type:</div>
          <div>{type}</div>
        </div>
        <div className='label-value-row'>
          <div>Title:</div>
          <div>{model.title}</div>
        </div>
        <div className='label-value-row'>
          <div>Source URL:</div>
          <div>{model.sourceUrl}</div>
        </div>
      </div>
    </>
  );
};

export default Google;
