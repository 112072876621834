import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { default as ReactSelect } from 'react-select';
import itemBankService from '../services/ItemBankService';
import CheckOption from './ChecksOption';
// import _ from 'lodash';

const TagsFilter = observer((props) => {
  const {
    itemBankManager,
  } = useContext(MobXProviderContext);

  const [refreshData, setRefreshData] = useState();

  useEffect(() => {
    const fetchInitData = async () => {
      await itemBankService.fetchItemBankList(true);
    };
    fetchInitData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSearchTerms = async (value) => {
    await itemBankManager.setSearchTerms(value);
    if (value.length === 0 || value.length > 2) {
      itemBankManager.resetPaginator();
      itemBankService.searchContentItemsForItemBank();
    }
  };

  // commented out because it was removed from this control... keep in case they want it
  // const handleChangeItemBank = async (selected) => {
  //   itemBankManager.setSelectedItemBank(selected);
  //   //console.log(selected);
  //   itemBankManager.resetPaginator();
  //   await itemBankManager.setSearchItemBankId(selected.value);
  //   itemBankService.searchContentItemsForItemBank();
  // };

  const handleChangeQuestionTypes = async (selected) => {
    itemBankManager.resetPaginator();
    await itemBankManager.setSearchQuestionTypes(selected);
    itemBankService.searchContentItemsForItemBank();
  };

  const handleChangeCategories = async (value, catId) => {
    const currentMap = itemBankManager.selectedCatToTags;
    currentMap.set(catId, value);
    let mergedTags = [];
    for (const ctPair of currentMap) {
      // eslint-disable-next-line no-unused-vars
      const [k, v] = ctPair;
      const theseVals = v.map((ct) => ct.value);
      mergedTags = mergedTags.concat(theseVals);
    }
    itemBankManager.resetPaginator();
    await itemBankManager.setSelectedCatToTags(currentMap);
    await itemBankManager.setSearchTags(mergedTags);
    setTimeout(() => {
      itemBankService.searchContentItemsForItemBank();
      setRefreshData(Math.floor(Math.random() * 1000));
    }, 250);
  };

  // eslint-disable-next-line no-unused-vars
  // const handleSearchOperator = async (event) => {
  //   console.log(event.target.value);
  //   await itemBankManager.setSearchOperator(event.target.value);
  //   await itemBankService.searchContentItemsForItemBank();
  // };

  const getTagItemsAsOptions = (tagsObj) => {
    const options = tagsObj.map((tag, index) => ({
      key: tag.id + index,
      label: tag.name,
      value: tag.id
    }));
    return options;
  };

  const categoryItems = () => {
    const categories = [];
    // eslint-disable-next-line array-callback-return
    itemBankManager.categoriesWithTagsArray.map((pItem) => {
      const selectedValues = (itemBankManager.selectedCatToTags.size > 0 && itemBankManager.selectedCatToTags.get(pItem.id)) ? itemBankManager.selectedCatToTags.get(pItem.id) : [];
      categories.push(
        <div key={`${pItem.id}_a`} refresh-data-attr={refreshData}>
          <div className='filter-label'>{pItem.name}</div>
          <ReactSelect
            allowSelectAll={true}
            closeMenuOnSelect={false}
            components={{ CheckOption }}
            hideSelectedOptions={false}
            isDisabled={!itemBankManager.searchItemBankId}
            isMulti
            onChange={(event) => handleChangeCategories(event, pItem.id)}
            options={getTagItemsAsOptions(pItem.tags)}
            value={selectedValues}
          />
        </div>
      );
    });
    return categories;
  };

  return (
    <>
      <div>
        {/*
        Commented out to move above tabs, in case they want it moved back... keep
        <div className='filter-label'>Item Bank *</div>
        <ReactSelect
          options={itemBankManager.itemBanks}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          components={{ CheckOption }}
          onChange={handleChangeItemBank}
          allowSelectAll={true}
          value={itemBankManager.selectedItemBank} /> */}
        <div className='filter-label'>Search</div>
        <input className='search-box' disabled={!itemBankManager.searchItemBankId} onChange={(e) => handleChangeSearchTerms(e.target.value)} type='text'
          value={itemBankManager.searchTerms} />
        <div className='question-types-control'>
          <div className='filter-label'>Question Types</div>
          <ReactSelect
            allowSelectAll={true}
            closeMenuOnSelect={false}
            components={{ CheckOption }}
            hideSelectedOptions={false}
            isDisabled={!itemBankManager.searchItemBankId}
            isMulti
            onChange={handleChangeQuestionTypes}
            options={itemBankManager.questionTypeOptions}
            value={itemBankManager.bucketQuestionTypes} />
        </div>
      </div>
      <div className='filter-label' />
      <div>
        {categoryItems()}
      </div>
    </>
  );
});

export default TagsFilter;
