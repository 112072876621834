import Auth from './AuthService';
import publisherManager from '../managers/PublisherManager';

export class PublisherService {
  questionCount;
  currentSection = null;

  async fetchMyPublisherOptions() {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewMyPublisherOptions`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        publisherManager.setPublisherOptionsFromResponse(response);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default new PublisherService();
