/* eslint-disable max-len */
import React, { useContext, useRef } from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { MobXProviderContext, observer } from 'mobx-react';
import Auth from '../../lessonPlayer/services/AuthService';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';
import ResourceItemCheckbox from './ResourceItemCheckbox';
import itemBankService from '../services/ItemBankService';
import '../../css/LibraryItemBankCard.scss';
import UtilsService from '../../lessonPlayer/services/UtilsService';

const ChildItemBankCard = observer((props) => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  const { onBodyClick, libraryCardParams, isSelected, isDisabled, isShopping, skipRemove, customChangeHandler } = props;
  const { id, backgroundImg, cardClassName, libraryResource } = libraryCardParams;

  // let questionStem = 'Mock Stem';
  // eslint-disable-next-line no-nested-ternary
  const questionStem = (libraryResource.questionStem) ? libraryResource.questionStem : (libraryResource.name) ? libraryResource.name : '';

  let strippedText = questionStem.replace('<p>', '<div>').replace('</p>', '</div>');
  strippedText = `<div>${strippedText}</div>`;

  const libraryCardKebabOptions = itemBankService.getLibraryCardOptions(skipRemove);
  const kababOptionKeys = Object.keys(libraryCardKebabOptions);
  const cardRef = useRef();
  const titleRef = useRef();
  const authBackgroundImage = (UtilsService.isNullOrEmpty(backgroundImg)) ? null : Auth.getResourceUrlByContentItemId(id);
  const questionTypeText = itemBankManager.getQuestionTypeText(libraryResource.entitySubTypeId);
  const subTypeId = (libraryResource.entitySubTypeId) ? libraryResource.entitySubTypeId : '';
  const entityTypeId = (libraryResource.entityTypeId === 'test_item') ? 'TestItem' : 'ItemResource';
  const notInLicensedBank = (libraryResource.deleteAllowed) ? '' : 'not-licensed-bank';

  useAccessibilityClick(cardRef, () => {
    itemBankService.handleShowItemDetails(libraryCardParams);
  });

  useAccessibilityClick(titleRef, () => {
    itemBankService.handleItemPreview(libraryCardParams);
  });

  const RenderMenuContent = () => {
    return (
      <>
        {(libraryCardKebabOptions) ? (
          <Menu arrow={true} menuButton={<div className='menu-icon' />} transition>
            {
              kababOptionKeys.map((kebabOptionKey) => {
                const kebabOption = libraryCardKebabOptions[kebabOptionKey];
                return (
                  <MenuItem key={kebabOption.key}
                    name={kebabOption.label}
                    onClick={() => kebabOption.callback(libraryCardParams)}>
                    {kebabOption.label}
                  </MenuItem>
                );
              })
            }
          </Menu>
        ) : <></>}
      </>
    );
  };

  return (
    <div>
      <ResourceItemCheckbox isDisabled={isDisabled} isSelected={isSelected} itemId={id} onChangeHandler={customChangeHandler}
        showMe={isShopping} />
      <div className={`library-card item-bank-card child-element ${cardClassName} ${subTypeId} ${subTypeId} ${entityTypeId} ${notInLicensedBank}`} style={{ backgroundImage: (authBackgroundImage) ? `url(${authBackgroundImage})` : '' }}>
        <div ref={titleRef} className={`library-card-title ${onBodyClick ? 'clickable' : ''}`}>
          <div className='library-card-title-text'>
            <HtmlComponent htmlStr={strippedText} />
          </div>
        </div>
        <div className='library-card-footer'>
          <div ref={cardRef} className='resource-type-text'>{questionTypeText}</div>
          <div className='menu-button-wrapper'>
            <RenderMenuContent />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChildItemBankCard;
