import React, { useContext } from 'react';
import '../../css/DuplicateDialog.scss';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';

import itemBankService from '../services/ItemBankService';


import { register } from '../../i18n';

const t = register('DuplicateDialog');

const DuplicateDialog = observer(() => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  return (
    <>
      {(itemBankManager.duplicateDialogOpen) && (
        <div className='duplicate-dialog'>
          <ReactModal
            disableResize={true}
            initHeight={200}
            initWidth={350}
            isOpen={itemBankManager.duplicateDialogOpen}
            onRequestClose={() => { itemBankService.setDuplicateDialogOpen(false); }} top={250}>
            <div className='duplicate-dialog-body'>

              <div className='solution-header'>
                <div className='title-label basic'>{t('headerLabel')}</div>
                <div className='x-close-button mask-image' onClick={() => {
                  itemBankService.setDuplicateDialogOpen(false);
                }} />
              </div>
              <div className='duplicate-dialog-body-message'> {t('body')}</div>

            </div>

          </ReactModal>
        </div>
      )}
    </>
  );
});

export default DuplicateDialog;
