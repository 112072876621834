import Auth from './AuthService';
import UtilsService from './UtilsService';
import Utils from './UtilsService';

import { Accommodations, ContentType, LessonMode, LessonSlideIds } from '../../Constants';

import { addThemeTranslations, register } from '../../i18n';

import publisherManager from '../managers/PublisherManager';
import lessonManager from '../managers/LessonManager';
import itemBankManager from '../../assessmentBuilder/managers/ItemBankManager';
import navMenuManager from '../managers/NavMenuManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import responseManager from '../managers/ResponseManager';
import toolbarManager from '../managers/ToolbarManager';
import userManager from '../managers/UserManager';

import responseService from './ResponseService';
import toolbarService from './ToolbarService';

import { confirmDialog } from '../components/dialogs';

const t = register('QuestionLabels');
const t2 = register('GlobalQuestionLabels');

export class LessonService {
  currentSection = null;
  activityLessonElements = [];
  currentSectionNumber = 1;
  translationLoopCount = 0;

  async fetchContentItem(contentItemId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewContentItem?contentItemId=${contentItemId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        if (response.contentItems && response.contentItems.length > 0) {
          const contentItem = response.contentItems[0];
          return contentItem;
        }
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async fetchResourceItem(contentItemId) {
    try {
      const url = `${Auth.ecms}/api/viewResource?contentItemId=${contentItemId}`;
      const response = await Auth.fetch(url, {
        method: 'GET',
      });
      if (response.status === 'SUCCESS') {
        if (response.resources && response.resources.length > 0) {
          const resourceItem = response.resources[0];
          return resourceItem;
        }
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async fetchItemStructureAndContent(contentItemId) {
    try {
      const url = `${Auth.ecms}/api/viewItemStructureAndContent?contentItemId=${contentItemId}`;
      const response = await Auth.fetch(url, {
        method: 'GET',
      });
      if (response.status === 'SUCCESS') {
        if (response.structure && response.structure.length > 0) {
          return response;
        }
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async fetchLessonContentItem(contentItemId) {
    try {
      const contentItem = await this.fetchContentItem(contentItemId);
      lessonManager.setContentItem(contentItem);
    } catch (e) {
      console.error(e);
    }
  }

  async fetchLesson(contentItemId, feedbackModeRequested, profileId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getLesson?contentItemId=${contentItemId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        if (response.lesson) {
          const { lesson } = response;
          lessonManager.setLesson(lesson);

          let { lessonOptions } = lessonManager;

          if (!profileId) {
            lessonOptions = JSON.parse(lesson.optionsJson);
          }

          if (lesson.metadataJson) {
            const metaData = JSON.parse(lesson.metadataJson);
            lessonOptions.title = metaData.title;
            lessonOptions.titlePageImageUrl = metaData.titlePageImageUrl;
            lessonOptions.itemFlow = metaData.itemFlow;
            lessonOptions.subtitle = metaData.subtitle;
            lessonOptions.resourceList = metaData.resourceList;
          }

          lessonManager.dependencies = {};
          if (lessonOptions && lessonOptions.itemFlow && lessonOptions.itemFlow.dependencies) {
            lessonManager.dependencies = lessonOptions.itemFlow.dependencies;
            responseManager.setSequentialItemDependency(lessonOptions.itemFlow.sequentialItemDependency);
          }

          await toolbarService.processAccommodations();

          if (lessonOptions && lessonOptions.tools) {
            const adjustedToolbarOptions = lessonOptions.tools.filter((obj) => {
              return !toolbarManager.excludedButtonTypes.includes(obj.type);
            });

            lessonOptions.tools = adjustedToolbarOptions;
          }

          if (feedbackModeRequested) {
            lessonOptions.feedbackModeRequested = feedbackModeRequested;
          } else {
            lessonOptions.feedbackModeRequested = null;
          }

          lessonManager.setLessonOptions(lessonOptions);

          questionFeedbackManager.setQuestionFeedbackMode(lessonOptions, null);

          let toolbarOptions = null;
          if (lessonOptions && lessonOptions.tools) {
            toolbarOptions = lessonOptions.tools;
          }
          const { contentItem } = lesson;
          lessonManager.setContentItem(contentItem);

          if (lessonOptions && lessonOptions.publisherThemeId) {
            await this.fetchPublisherTheme(lessonOptions.publisherThemeId);
          } else {
            lessonManager.setThemeLoaded(true);
            //lessonManager.setTranslationsLoaded(true);
          }

          await toolbarService.initToolbar(lessonOptions, toolbarOptions);
          const { isStudent } = userManager;

          toolbarService.setTeacherToolVisibility(!isStudent, toolbarOptions);
        }
      }
    } catch (e) {
      console.error(e);
      lessonManager.setLoaded(0);
    }
  }

  async fetchLessonForItemBuilding(contentItemId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getLesson?contentItemId=${contentItemId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        if (response.lesson) {
          const { lesson } = response;
          lessonManager.setLesson(lesson);

          const lessonOptions = JSON.parse(lesson.optionsJson);

          if (lesson.metadataJson) {
            const metaData = JSON.parse(lesson.metadataJson);
            lessonOptions.title = metaData.title;
            lessonOptions.titlePageImageUrl = metaData.titlePageImageUrl;
            lessonOptions.subtitle = metaData.subtitle;
          }

          lessonManager.setLessonOptions(lessonOptions);
          const { contentItem } = lesson;
          lessonManager.setContentItem(contentItem);
          lessonManager.setThemeLoaded(true);
          // Setting this in fetchBuilderLessonData once we get our translations
          // lessonManager.setTranslationsLoaded(true);
        }
      }
    } catch (e) {
      console.error(e);
      lessonManager.setLoaded(0);
    }
  }

  async fetchLessonContent(contentItemId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getLessonContent?contentItemId=${contentItemId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        if (response.lessonContent) {
          const { lessonContent } = response;
          let content = JSON.parse(lessonContent.content);

          content = await this.fetchVideoOrAudioLessonContentResourceItemsIfApplicable({ content });

          lessonManager.contentMap = Utils.mapById(content);
          lessonManager.structureMap = Utils.mapById(lessonContent.structure);

          const rootObj = lessonContent.structure.find((element) => element.entityId === lessonContent.id);
          this.activityLessonElements = [];
          await this.traverseLessonElements(rootObj);

          if (lessonManager.playerMode === LessonMode.PRINT_PREVIEW) {
            this.setQuestionNumbersPrintVersion();
          } else if (lessonManager.isOld) {
            this.oldSetQuestionNumbers();
          } else {
            this.setQuestionNumbers();
          }

          if (!Utils.isObjectNullOrEmpty(lessonManager.dependencies)) {
            await this.setLessonElementDependencies(rootObj);
          }
          this.buildActivityGroups();
          this.buildTitleAndSummaryPages();
          lessonManager.setRootObject(rootObj);
          lessonManager.setCurrentSlideIndex(0);
          navMenuManager.initSummarySectionToggles(lessonManager.sectionList);
          await responseService.initSlideResponses();
          // do TextHelp
          if(lessonManager.playerMode != LessonMode.PRINT_PREVIEW) {
            await this.initializeSubscribedTTS();
          }

        }
      }
      return null;
    } catch (e) {
      console.error(e);
    }
  }

  async initializeSubscribedTTS() {
    if (publisherManager.hasTextHelpId) {
      lessonManager.setLoaded(1);
      const isLoaded = document.querySelectorAll('[data-speechstream-config]');

      // Is the toolbar loader ready?
      const toolbarLoaderLoadedListener = async (e) => {
        window.removeEventListener('toolbarLoaderLoaded', toolbarLoaderLoadedListener);
        setTimeout(async () => {
          try {
            await toolbarService.initTextHelpApi();
            lessonManager.setLoaded(-1);
          } catch (e) {
            lessonManager.setLoaded(-1);
          }
        }, 0);// zero ensures this fires after render no need for anything more
      };

      // Is the loader loaded?
      const speechToolbarLoadedListener = (e) => {
        window.removeEventListener('speechToolbarLoaded', speechToolbarLoadedListener);
      };

      if (!isLoaded.length > 0) {
        try {
          const configId = publisherManager.textHelpId;
          const scriptTh = document.createElement('script');
          scriptTh.src = 'https://toolbar.speechstream.net/SpeechStream/3.9.5/speechstream.js';

          scriptTh.setAttribute('data-speechstream-config', configId);
          // scriptTh.async = true;
          scriptTh.type = 'text/javascript';
          document.body.appendChild(scriptTh);
          window.addEventListener('toolbarLoaderLoaded', toolbarLoaderLoadedListener, false);
          window.addEventListener('speechToolbarLoaded', speechToolbarLoadedListener, false);
          setTimeout(async () => { // if texthelp doesn't load in 8 seconds bail
            if (!lessonManager.isLoaded) {
              lessonManager.setLoaded(0);
              console.error('Text help loading timed out');
            }
          }, 8000);
        } catch (e) {
          lessonManager.setLoaded(-1);
        }
      }
    } else if (publisherManager.hasWebReaderId) {
      const encUrl = encodeURI(window.location.hostname);
      const rsHref = `//app-na.readspeaker.com/cgi-bin/rsent?customerid=${ publisherManager.webReaderId }&lang=en_us&voice=Mark&readclass=tRdbl&url=${ encUrl }`;
      publisherManager.setRsRef(rsHref);
    } else {
      lessonManager.setLoaded(-1);
    }
  }

  async fetchVideoOrAudioLessonContentResourceItemsIfApplicable({ content } = {}) {
    for (const contentPart of content) {
      const { entityTypeId } = contentPart;
      if ((entityTypeId === 'video_resource' || entityTypeId === 'audio_resource') && contentPart.resourceContentItemId) {
        // fetch any potential subtitles & transcripts associated with the video/audio resource
        // eslint-disable-next-line no-await-in-loop
        const resourceItem = await this.fetchResourceItem(contentPart.resourceContentItemId);
        contentPart.resourceItem = resourceItem;
        // ensure we have latest subtitles & transcripts attached to the model
        contentPart.subtitles = resourceItem.subtitles;
        contentPart.transcripts = resourceItem.transcripts;
        // TODO remove
        // contentPart.subtitles = contentPart.subtitles?.length ? contentPart.subtitles : resourceItem.subtitles;
        // contentPart.transcripts = contentPart?.transcripts?.length ? contentPart.transcripts : resourceItem.transcripts;
      }
    }
    return content;
  }

  async fetchLessonContentItemBuilding(contentItemId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getLessonContent?contentItemId=${contentItemId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        if (response.lessonContent) {
          const { lessonContent } = response;
          const content = JSON.parse(lessonContent.content);

          lessonManager.contentMap = Utils.mapById(content);
          lessonManager.structureMap = Utils.mapById(lessonContent.structure);

          let firstSection = null;
          const lessonElementIds = Object.keys(lessonManager.structureMap);
          lessonElementIds.forEach(async (lessonElementId) => {
            const element = lessonManager.structureMap[lessonElementId];
            if (element.type === ContentType.SECTION.type && firstSection === null) {
              firstSection = lessonElementId;
            }

            if (element.entityTypeId === ContentType.ITEM_RESOURCE.type || element.entityTypeId === ContentType.TEST_ITEM.type || element.entityTypeId === ContentType.TEST_ITEM_PARENT.type) {
              // ßconsole.log('STRUCTURE', element);
              if (itemBankManager.isActivityType(element.type) && element.content !== null && element.lessonElementIds) {
                const elementContent = JSON.parse(element.content);
                const { childGroups } = elementContent;
                for (let i = 0; i < childGroups.length; ++i) {
                  const thisChildGroup = childGroups[i];
                  if (thisChildGroup.type === 'group') {
                    for (let x = 0; x < thisChildGroup.itemIds.length; ++x) {
                      const groupItem = childGroups.find((group) => group.id === thisChildGroup.itemIds[x]);
                      // console.log('CONTENT', groupItem.childId);
                      itemBankManager.activityGroupsMap.set(groupItem.childId, groupItem);
                    }
                  }
                }

                // this activity has a group, so flag it.
                const contentModel = lessonManager.contentMap[element.entityId];
                if (contentModel && contentModel.childGroups && contentModel.childGroups.length > 0) {
                  itemBankManager.addElementHasGroupArray(lessonElementId);
                }
              }

              itemBankManager.addLessonItemBankItem({ id: element.entityId, placeHolder: true, lessonElementId: element.id, orderNum: element.orderNum, childElementIds: element.lessonElementIds });
            }
          });
          lessonManager.firstSection = firstSection;

          const rootObj = lessonContent.structure.find((element) => element.entityId === lessonContent.id);
          lessonManager.setRootObject(rootObj);
        }
      }
      return null;
    } catch (e) {
      console.error(e);
    }
  }

  async fetchPublisherTheme(publisherThemeId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewPublisherTheme?id=${publisherThemeId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        lessonManager.setPublisherTheme(response.data[0]);

        if (lessonManager.publisherTheme && !Utils.isNullOrEmpty(lessonManager.publisherTheme.fullThemeCssUrl)) {
          const authKey = `authKey=${Auth.authKey}`;
          let dividerChar = lessonManager.publisherTheme.fullThemeCssUrl.includes('?') ? '&' : '?';
          const themeCss = `${lessonManager.publisherTheme.fullThemeCssUrl}${dividerChar}${authKey}&${Auth.noCache()}`;
          const link = document.createElement('link');

          link.rel = 'stylesheet';
          link.type = 'text/css';
          link.href = themeCss;
          document.querySelector('head').appendChild(link);

          dividerChar = lessonManager.publisherTheme.fullThemeJavascriptUrl.includes('?') ? '&' : '?';
          const themeJsUrl = `${lessonManager.publisherTheme.fullThemeJavascriptUrl}${dividerChar}${authKey}&${Auth.noCache()}`;
          const script = document.createElement('script');

          script.type = 'text/javascript';
          script.src = themeJsUrl;
          document.querySelector('head').appendChild(script);
        }
        lessonManager.setThemeLoaded(true);
        //lessonManager.setTranslationsLoaded(true);
      }
      return null;
    } catch (e) {
      lessonManager.setThemeLoaded(true);
      //lessonManager.setTranslationsLoaded(true);
      console.error(e);
    }
  }

  async fetchBuilderLessonData(contentItemId) {
    lessonManager.setPlayerMode(LessonMode.ASSESSMENT_BUILDER);

    await this.fetchLessonForItemBuilding(contentItemId);

    const { lessonOptions } = lessonManager;

    if (!UtilsService.isNullOrEmpty(lessonOptions.publisherTranslationId)) {
      const translationOverrides = await this.fetchTranslations(lessonOptions.publisherTranslationId);
      addThemeTranslations('theme', translationOverrides);
    }
    lessonManager.setTranslationsLoaded(true);

    await this.fetchLessonContentItemBuilding(contentItemId);
  }

  async fetchLessonData(contentItemId, mode, feedbackModeRequested, profileId) {
    if (mode) {
      lessonManager.setPlayerMode(mode);
    }
    if (profileId) {
      await this.fetchLessonProfile(profileId);
    }
    await this.fetchLesson(contentItemId, feedbackModeRequested, profileId);
    const { lessonOptions } = lessonManager;

    if (!UtilsService.isNullOrEmpty(lessonOptions.publisherTranslationId)) {
      const translationOverrides = await this.fetchTranslations(lessonOptions.publisherTranslationId);
      addThemeTranslations('theme', translationOverrides);
    }
    lessonManager.setTranslationsLoaded(true);

    await this.fetchLessonContent(contentItemId);
  }

  fetchLessonProfile = async (id) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getLessonOptionsProfile?id=${id}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        const lessonOptions = JSON.parse(response.data[0].optionsJson);
        lessonManager.setLessonOptions(lessonOptions);
      }
    } catch (e) {
      console.error(e);
    }
  }

  fetchTranslations = async (id) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewPublisherTranslationContent?id=${id}`, {
        method: 'GET',
      });

      let translationContent = {};

      if (response.status === 'SUCCESS') {
        translationContent = JSON.parse(response.translationContentJson);
      }
      return translationContent;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  setIsInteractiveFlyoutOpen = (toggle) => {
    lessonManager.setIsInteractiveFlyoutOpen(toggle);
  }

  // sets the model category, question number and activity question range on each lesson model obj
  traverseLessonElements = async (lessonElement) => {
    if (lessonElement.entityId) {
      const model = lessonManager.contentMap[lessonElement.entityId];
      if (model) {
        if (model.entityTypeId && model.entityTypeId === 'bookmark_resource' && model.type === 'WebPage') {
          // this has to be done because the webpage instruction, bookmark activity and bookmark resource
          // have model.type values that are identical.
          model.type = 'Bookmark';
        }

        model.isQuestion = model.category === 'Test Item';
        model.isGraphicOrganizer = model.category === 'Graphic Organizer';
        model.isGoogle = model.category === 'Google';
        model.isStudentInstruction = (model.type === ContentType.STUDENT_INSTRUCTION.type);
        model.isInstruction = model.category === 'Instruction';
        model.isInteractiveActivity = lessonManager.interactiveActivityTypes.has(model.type);
        model.isResource = lessonManager.resourceTypes.has(model.type);
        model.isActivity = lessonManager.activityTypes.has(model.type);
        model.isSection = lessonElement.type === ContentType.SECTION.type;
        model.lessonElementId = lessonElement.id;
        model.isTestItem = (model.isGraphicOrganizer || model.isQuestion);
        model.isMultiAnswer = lessonManager.multiAnswerTypes.has(model.type);
        model.isGame = lessonManager.gameTypes.has(model.type);

        if (model.isActivity && lessonElement.lessonElementIds.length === 0) {
          model.isInstruction = true;
          model.isTestItem = false;
          model.category = 'Instruction';
        }

        if (model.isTestItem) {
          // a test item is either a question or a graphic organizer
          questionFeedbackManager.initLessonElementFeedbackState(lessonElement.id);

          if (model.type === 'Cloze' || model.type === 'ClozeDragDrop' || model.type === 'ClozeMultichoice'
            || model.type === 'ClozeSpelling' || model.type === 'ClozeCombo') {
            lessonManager.clozeIds.push(model.id);
          }
        } else if (model.isActivity) {
          this.activityLessonElements.push(lessonElement);

          const elementIds = lessonElement.lessonElementIds;

          elementIds.forEach((id, index) => {
            lessonManager.activityPartIds.add(id);
            this.setParentLessonElementId(id, lessonElement.id);
          });
        } else {
          // empty block
          // unknown category
        }

        if (this.currentSection) {
          model.sectionLessonElementId = this.currentSection;
        }
        let addSlide = true;

        if (userManager.isStudent &&
          model.type === ContentType.TEACHER_INSTRUCTION.type) {
          addSlide = false;
        }

        if (userManager.isStudent && model.hideFromStudents) {
          addSlide = false;
        }

        if (addSlide) {
          lessonManager.addSlide(lessonElement.id);
        }
      } else {
        if (lessonElement.type === ContentType.DIFFERENTIATION_REASON.type) {
          const model = JSON.parse(lessonElement.content);
          if (model.showCoverage) {
            model.isDiInfo = true;
            model.id = lessonElement.id;
            model.reasonId = lessonElement.entityId;
            model.type = ContentType.DIFFERENTIATION_REASON.type;
            lessonManager.contentMap[lessonElement.entityId] = model;
            lessonManager.addSlide(lessonElement.id);
          }
        }
      }
    } else {
      if (lessonElement.type === 'Section') {
        this.currentSection = lessonElement.id;
        let model = JSON.parse(lessonElement.content);
        if (!model) {
          model = {};
        }
        model.sectionNumber = this.currentSectionNumber;
        this.currentSectionNumber++;
        model.isSection = true;
        lessonManager.sectionList.set(lessonElement.id, model);
      }
    }

    for (const lessonElementId of lessonElement.lessonElementIds) {
      const nextLessonElement = lessonManager.structureMap[lessonElementId];
      await this.traverseLessonElements(nextLessonElement);
    }
  }

  // sets the list of dependency question numbers on each lesson model obj
  setLessonElementDependencies = async (lessonElement) => {
    if (lessonElement.entityId) {
      const model = lessonManager.contentMap[lessonElement.entityId];
      if (model) {
        const dependentOnList = lessonManager.dependencies[lessonElement.id];
        if (dependentOnList) {
          const questionNumbers = [];
          for (let x = 0; x < dependentOnList.length; ++x) {
            const id = dependentOnList[x];
            const dependentOnElement = lessonManager.structureMap[id];
            if (dependentOnElement) {
              const dependentOnContent = lessonManager.contentMap[dependentOnElement.entityId];
              if (dependentOnContent) {
                responseManager.setLessonItemDependents(lessonElement.id, id);
                questionNumbers.push(dependentOnContent.questionNumber);
              }
            }
          }
          const numberList = questionNumbers.join(',');
          model.dependentOn = numberList;
        }
      }
    }

    for (const lessonElementId of lessonElement.lessonElementIds) {
      const nextLessonElement = lessonManager.structureMap[lessonElementId];
      await this.setLessonElementDependencies(nextLessonElement);
    }
  }

  getSlideModel = (lessonElementId) => {
    return lessonManager.getSlideModel(lessonElementId);
  }

  setOnlyShowManual = async (toggle) => {
    const success = lessonManager.setOnlyShowManual(toggle);

    if (!success) {
      await confirmDialog({
        title: `${UtilsService.noManuallyScored()}`,
        showCancelButton: false,
        confirmButtonText: `${t2('close')}`
      });
    }
  }

  setOnlyShowDoNotScore = async (toggle) => {
    const success = lessonManager.setOnlyShowDoNotScore(toggle);

    if (!success) {
      await confirmDialog({
        title: `${t2('noUnscoredScored')}`,
        showCancelButton: false,
        confirmButtonText: `${t2('close')}`
      });
    }
  }

  setParentLessonElementId = (childId, parentActivityId) => {
    const childElement = lessonManager.structureMap[childId];
    const childModel = lessonManager.contentMap[childElement.entityId];
    childModel.parentActivityElementId = parentActivityId;
    childModel.lessonElementId = childId;
    childModel.isActivityPart = true;
    if (this.currentSection) {
      childModel.sectionLessonElementId = this.currentSection;
    }
  }

  getCurrentLessonElementId = () => {
    return lessonManager.currentLessonElementId;
  }

  isActivity = (lessonElementId) => {
    return lessonManager.activityPartIds.has(lessonElementId);
  }

  isActivityPart = (lessonElementId) => {
    return lessonManager.activityPartIds.has(lessonElementId);
  }

  getSlideSummary = (lessonElement, model) => {
    if (!lessonElement) {
      return '';
    }

    // The lessonElement content JSON property can contain a "slideSummary"
    // that overrides the model.slideSummary.
    const elementContent = JSON.parse(lessonElement.content);
    const elementSlideSummary = elementContent?.slideSummary;
    const contentSlideSummary = model?.slideSummary;

    return elementSlideSummary || contentSlideSummary;
  }

  getQuestionLabel = (model, {
    forceLongName = false,
    fromQuestionBanner = false
  } = {}) => {
    const lessonItem = lessonManager.getLessonElement(model.lessonElementId);
    const slideSummary = this.getSlideSummary(lessonItem, model);

    if (lessonManager.lessonOptions.hasSmallGotoCards && !forceLongName) {
      if (model.isActivity) {
        if (lessonItem.lessonElementIds.length === 0) {
          return ContentType.getLabel(model.type);
        } else {
          return `${model.questionRange}`;
        }
      }

      return `Q${model.questionNumber}`;
    }

    let summary = '';
    let rangeStr = '';

    if (model.isActivity) {
      rangeStr = `${model.questionRange}`;
    }

    if (!Utils.isNullButNotEmpty(slideSummary)) {
      summary = Utils.truncateText(Utils.stripTagsAndEntities(slideSummary), 50, { noEllipsis: true });
    } else {
      summary = ContentType.getLabel(model.type);
    }

    const newLine = (forceLongName) ? '' : '<br/>';
    if (model.isActivity && (lessonItem.lessonElementIds.length === 0 || !rangeStr) && lessonManager.lessonOptions.hasCardGoToText) {
      return summary;
    } else if (model.isActivity && lessonItem.lessonElementIds.length > 0) {
      const typeLabel = (!Utils.isNullButNotEmpty(slideSummary)) ? slideSummary : ContentType.getLabel(model.type);
      let finalString = '';
      if (lessonManager.lessonOptions.hasCardQuestionNumber &&
        lessonManager.lessonOptions.hasCardGoToText && rangeStr) {
        finalString = `${t2('questions')} ${rangeStr} ${'<br/>'} ${typeLabel}`;
      } else if (!lessonManager.lessonOptions.hasCardQuestionNumber &&
        lessonManager.lessonOptions.hasCardGoToText) {
        finalString = `${typeLabel}`;
      } else if (lessonManager.lessonOptions.hasCardQuestionNumber &&
        !lessonManager.lessonOptions.hasCardGoToText && rangeStr) {
        finalString = `${t2('questions')} ${rangeStr}`;
      }

      return finalString;
    }

    if (model.isResource && lessonManager.lessonOptions.hasCardGoToText) {
      return summary;
    }

    const hasFinalQuestionNumber = ((!Utils.isNullOrEmptyOrUndefined(model.questionNumber)) && lessonManager.lessonOptions.hasCardQuestionNumber);
    const hasFinalSummary = (!Utils.isNullOrEmptyOrUndefined(summary) && lessonManager.lessonOptions.hasCardGoToText);

    if (fromQuestionBanner) {
      return t('defaultQuestionBannerText', {
        questionNumber: model.questionNumber,
        newLine,
        summary
      });
    } else {
      if (hasFinalQuestionNumber && hasFinalSummary) {
        return t('defaultQuestionLabelText', {
          questionNumber: model.questionNumber,
          newLine,
          summary
        });
      } else if (!hasFinalQuestionNumber && hasFinalSummary) {
        return summary;
      } else if (hasFinalQuestionNumber && !hasFinalSummary) {
        return t('noSummaryLabelText', {
          questionNumber: model.questionNumber
        });
      }
    }
  }

  buildActivityGroups = () => {
    if (this.activityLessonElements.length > 0) {
      this.activityLessonElements.forEach((activityLessonElement) => {
        // For lessons, the grouping information is in the structure. There's also grouping
        // information in the content, but that applies to the item outside the
        // lesson, and the structure applies to the item inside the lesson.

        // But ... there's old test item content on production where the structure values don't
        // line up.  So only observe the real rules for item resources.
        if (activityLessonElement.entityTypeId === ContentType.ITEM_RESOURCE.type) {
          const activityStructure = lessonManager.structureMap[activityLessonElement.id];
          if (activityStructure && activityStructure.content) {
            const { childGroups } = JSON.parse(activityStructure.content);
            if (!childGroups) {
              return;
            }

            for (let i = 0; i < childGroups.length; ++i) {
              const childGroup = childGroups[i];
              if (childGroup.type === 'group') {
                const lessonElementIds = [];
                for (let x = 0; x < childGroup.itemIds.length; ++x) {
                  const groupItem = childGroups.find((group) => group.id === childGroup.itemIds[x]);
                  // Old content may not have the correct link between groupItem.childId and content
                  // in the contentMap, but it will have the correct link between groupItem.childElementId,
                  // the child element in the structureMap, and that element's entityId in the contentMap.
                  // We were already treating the structure as the source of truth, and this doubles
                  // down on that.
                  const childStructure = lessonManager.structureMap[groupItem.childElementId];
                  const model = lessonManager.contentMap[childStructure.entityId];
                  lessonElementIds.push(model.lessonElementId);
                  model.inGroup = true;
                  model.groupId = childGroup.id;
                  // const indexOf = activityLessonElement.lessonElementIds.indexOf(model.lessonElementId);
                  // activityLessonElement.lessonElementIds.splice(indexOf, 1);
                }
                if (activityLessonElement.lessonElementGroups === undefined) {
                  activityLessonElement.lessonElementGroups = new Map();
                }
                activityLessonElement.lessonElementGroups.set(childGroup.id, lessonElementIds);
              }
            }
          }
        } else {
          // For test items, revert back to the old "read groups from the content" rules,
          // which aren't technically correct but have the virtue of working for existing
          // content ...
          const activityModel = lessonManager.contentMap[activityLessonElement.entityId];
          const { childGroups } = activityModel;
          if (!childGroups) {
            return;
          }

          for (let i = 0; i < childGroups.length; ++i) {
            const childGroup = childGroups[i];
            if (childGroup.type === 'group') {
              const lessonElementIds = [];
              for (let x = 0; x < childGroup.itemIds.length; ++x) {
                const groupItem = childGroups.find((group) => group.id === childGroup.itemIds[x]);
                const model = lessonManager.contentMap[groupItem.childId];
                lessonElementIds.push(model.lessonElementId);
                model.inGroup = true;
                model.groupId = childGroup.id;
                // const indexOf = activityLessonElement.lessonElementIds.indexOf(model.lessonElementId);
                // activityLessonElement.lessonElementIds.splice(indexOf, 1);
              }
              if (activityLessonElement.lessonElementGroups === undefined) {
                activityLessonElement.lessonElementGroups = new Map();
              }
              activityLessonElement.lessonElementGroups.set(childGroup.id, lessonElementIds);
            }
          }
        }
      });
    }
  }

  buildStandaloneItemActivityGroups = () => {
    if (this.activityLessonElements.length > 0) {
      this.activityLessonElements.forEach((activityLessonElement) => {
        // For standalone items, the grouping information is in the content.
        const activityModel = lessonManager.contentMap[activityLessonElement.entityId];
        const { childGroups } = activityModel;
        if (!childGroups) {
          return;
        }

        for (let i = 0; i < childGroups.length; ++i) {
          const childGroup = childGroups[i];
          if (childGroup.type === 'group') {
            const lessonElementIds = [];
            for (let x = 0; x < childGroup.itemIds.length; ++x) {
              const groupItem = childGroups.find((group) => group.id === childGroup.itemIds[x]);
              const model = lessonManager.contentMap[groupItem.childId];
              lessonElementIds.push(model.lessonElementId);
              model.inGroup = true;
              model.groupId = childGroup.id;
              // const indexOf = activityLessonElement.lessonElementIds.indexOf(model.lessonElementId);
              // activityLessonElement.lessonElementIds.splice(indexOf, 1);
            }
            if (activityLessonElement.lessonElementGroups === undefined) {
              activityLessonElement.lessonElementGroups = new Map();
            }
            activityLessonElement.lessonElementGroups.set(childGroup.id, lessonElementIds);
          }
        }
      });
    }
  }

  oldSetQuestionNumbers = () => {
    const customNumbers = (lessonManager &&
      lessonManager.lessonOptions && lessonManager.lessonOptions.customNumbering) ?
      lessonManager.lessonOptions.customNumbering : false;

    let currentCount = 1;
    const activityElements = [];
    lessonManager.slideList.forEach((lessonElementId, slideZeroIndex) => {
      const lessonElement = lessonManager.getLessonElement(lessonElementId);
      if (lessonElement) {
        const model = lessonManager.contentMap[lessonElement.entityId];
        if (model) {
          if (!model.isActivity) {
            if (customNumbers) {
              if (lessonElement.questionNum) {
                model.questionNumber = lessonElement.questionNum;
              } else if (lessonElement.type === 'Rubric' || lessonElement.type === 'StudentInstruction') {
                model.questionNumber = '';
              } else {
                model.questionNumber = currentCount;
              }
              // model.questionNumberActual = currentCount;
              ++currentCount;
            } else {
              if (model.isTestItem || model.isStudentInstruction) {
                model.questionNumber = currentCount;
                ++currentCount;
              } else {
                model.questionNumber = '';
              }
            }
          } else {
            // don't increment this is used only when activity has no children
            activityElements.push(lessonElement);
          }
        }
      }
    });

    activityElements.forEach((activityElement) => {
      const childIds = activityElement.lessonElementIds;
      const activityModel = lessonManager.contentMap[activityElement.entityId];
      let firstModel = null;
      let firstIndex = 0;
      let secondModel = null;
      for (let i = 0; i < childIds.length; ++i) {
        const childE = lessonManager.getLessonElement(childIds[i]);
        const childM = lessonManager.contentMap[childE.entityId];

        if (childM.isTestItem) {
          firstModel = childM;
          firstIndex = i;
          break;
        }
      }

      for (let i = childIds.length - 1; i > firstIndex; --i) {
        const childE = lessonManager.getLessonElement(childIds[i]);
        const childM = lessonManager.contentMap[childE.entityId];

        if (childM.isTestItem) {
          secondModel = childM;
          break;
        }
      }
      const q = (lessonManager.lessonOptions.hasSmallGotoCards) ? 'Q' : '';

      if (childIds.length === 0) {
        activityModel.questionRange = '';
      } else if (childIds.length === 1) {
        activityModel.questionRange = (firstModel && firstModel.questionNumber) ? `${q}${firstModel.questionNumber}` : '';
        // activityModel.questionRangeActual = (firstModel && firstModel.questionNumberActual) ? firstModel.questionNumberActual : '';
      } else {
        const f = (firstModel && firstModel.questionNumber) ? firstModel.questionNumber.toString() : '';
        const s = (secondModel && secondModel.questionNumber) ? secondModel.questionNumber.toString() : '';

        if (Utils.isNullOrEmpty(s)) {
          activityModel.questionRange = `${q}${f}`;
        } else if (Utils.isNullOrEmpty(f)) {
          activityModel.questionRange = `${q}${s}`;
        } else {
          activityModel.questionRange = `${q}${f} - ${q}${s}`;
        }
      }
    });
  }

  // the actual question and ranges are commented out below intemtionally. They might want them back.
  setQuestionNumbers = () => {
    const customNumbers = (lessonManager &&
      lessonManager.lessonOptions && lessonManager.lessonOptions.customNumbering) ?
      lessonManager.lessonOptions.customNumbering : false;

    let currentCount = 1;
    const activityElements = [];
    lessonManager.slideList.forEach((lessonElementId, slideZeroIndex) => {
      const lessonElement = lessonManager.getLessonElement(lessonElementId);
      if (lessonElement) {
        const model = lessonManager.contentMap[lessonElement.entityId];

        if (model) {
          if (!model.isActivity) {
            if (model.isTestItem) {
              if (customNumbers) {
                model.questionNumber = lessonElement.questionNum;
              } else {
                model.questionNumber = currentCount;
                ++currentCount;
              }
            } else {
              model.questionNumber = '';
            }
          } else {
            model.questionNumber = '';
            // don't increment this is used only when activity has no children
            activityElements.push(lessonElement);
          }
        }
      }
    });

    activityElements.forEach((activityElement) => {
      const childIds = activityElement.lessonElementIds;
      const activityModel = lessonManager.contentMap[activityElement.entityId];
      let firstModel = null;
      let firstIndex = 0;
      let secondModel = null;
      for (let i = 0; i < childIds.length; ++i) {
        const childE = lessonManager.getLessonElement(childIds[i]);
        const childM = lessonManager.contentMap[childE.entityId];

        if (childM.isTestItem) {
          firstModel = childM;
          firstIndex = i;
          break;
        }
      }

      for (let i = childIds.length - 1; i > firstIndex; --i) {
        const childE = lessonManager.getLessonElement(childIds[i]);
        const childM = lessonManager.contentMap[childE.entityId];

        if (childM.isTestItem) {
          secondModel = childM;
          break;
        }
      }
      const q = (lessonManager.lessonOptions.hasSmallGotoCards) ? 'Q' : '';
      if (childIds.length === 0) {
        activityModel.questionRange = '';
      } else if (childIds.length === 1) {
        activityModel.questionRange = (firstModel && firstModel.questionNumber) ? `${q}${firstModel.questionNumber}` : '';
      } else {
        const f = (firstModel && firstModel.questionNumber) ? firstModel.questionNumber.toString() : '';
        const s = (secondModel && secondModel.questionNumber) ? secondModel.questionNumber.toString() : '';

        if (Utils.isNullOrEmpty(s)) {
          activityModel.questionRange = `${q}${f}`;
        } else if (Utils.isNullOrEmpty(f)) {
          activityModel.questionRange = `${q}${s}`;
        } else {
          activityModel.questionRange = `${q}${f} - ${q}${s}`;
        }
      }
    });
  }

  setQuestionNumbersPrintVersion = () => {
    let currentNum = 0;
    let indexOffsetBecauseOfInstruction = 0;

    lessonManager.slideList.forEach((lessonElementId, slideZeroIndex) => {
      const model = lessonManager.getSlideModel(lessonElementId);
      if (model && model.isActivity) {
        ++currentNum;
        model.questionNumber = `${currentNum}.0`;
        model.parentRawQuestionNumber = currentNum;
      } else if (model && model.isActivityPart) {
        // We don't display the instruction question nums so if there is an instruction in a multipart
        // we just set its question num to whatever the current num is and also increase an offset to make
        // sure we are showing the correct letter for the activity parts.
        if (model.isInstruction) {
          model.questionNumber = `${currentNum}`;
          ++indexOffsetBecauseOfInstruction;
          return;
        }
        const parentModel = lessonManager.getSlideModel(model.parentActivityElementId);
        const parentLessonElement = lessonManager.getLessonElement(model.parentActivityElementId);
        const index = parentLessonElement.lessonElementIds.indexOf(model.lessonElementId);
        model.questionNumber = `${parentModel.parentRawQuestionNumber}.${lessonManager.LETTER_TABLE[index - indexOffsetBecauseOfInstruction]}`;
        // Resetting the offset once we are done with this activity
        if (index === parentLessonElement.lessonElementIds.length - 1) {
          indexOffsetBecauseOfInstruction = 0;
        }
      } else {
        ++currentNum;
        model.questionNumber = `${currentNum}`;
      }
    });
  }

  getLessonTitleBackgroundUrl = () => {
    if (lessonManager.lessonOptions.titlePageImageUrl) {
      return Auth.getResourceUrlByFileName(lessonManager.lessonOptions.titlePageImageUrl);
    }
    return '';
  }

  getLessonSlideBackgroundUrl = () => {
    if (lessonManager.lessonOptions.slideBackgroundImageResourceId) {
      return Auth.getResourceUrlByResourceId(lessonManager.lessonOptions.slideBackgroundImageResourceId);
    }
    return '';
  }

  buildTitleAndSummaryPages = () => {
    const hasTitlePage = !lessonManager.lessonOptions.hideTitlePage;
    const { hasSummaryPage } = lessonManager.lessonOptions;

    if (hasTitlePage && lessonManager.playerMode !== LessonMode.PRINT_PREVIEW) {
      lessonManager.slideList.unshift(LessonSlideIds.TITLE_PAGE);
      lessonManager.contentMap[LessonSlideIds.TITLE_PAGE] = { type: ContentType.TITLE_PAGE.type, isTitlePage: true };
      lessonManager.structureMap[LessonSlideIds.TITLE_PAGE] = { type: ContentType.TITLE_PAGE.type, entityId: LessonSlideIds.TITLE_PAGE };
    }

    if ((hasSummaryPage || lessonManager.playerMode === LessonMode.SCORING) && lessonManager.playerMode !== LessonMode.PRINT_PREVIEW) {
      lessonManager.slideList.push(LessonSlideIds.SUMMARY_PAGE);
      lessonManager.contentMap[LessonSlideIds.SUMMARY_PAGE] = { type: ContentType.SUMMARY_PAGE.type, isSummaryPage: true };
      lessonManager.structureMap[LessonSlideIds.SUMMARY_PAGE] = { type: ContentType.SUMMARY_PAGE.type, entityId: LessonSlideIds.SUMMARY_PAGE };
    }
  }

  showActivityFlyout = (hasAlignments) => {
    const { currentLessonElementId } = lessonManager;

    if (currentLessonElementId === LessonSlideIds.SUMMARY_PAGE || currentLessonElementId === LessonSlideIds.TITLE_PAGE) {
      return false;
    }

    const isTeacher = userManager.canViewAsTeacher;
    const { isStudent } = userManager;

    if (isStudent) {
      return false;
    } else if (isTeacher) {
      if (lessonManager.playerMode === LessonMode.SCORING) {
        return true;
      }
      if ((lessonManager.playerMode === LessonMode.PREVIEW || lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW) && hasAlignments) {
        return true;
      }
    }
    return false;
  }

  showStandaloneFlyout = (hasAlignments, hasRubric, hasTeacherNotes) => {
    const { currentLessonElementId } = lessonManager;

    if ((currentLessonElementId === LessonSlideIds.SUMMARY_PAGE && lessonManager.playerMode !== LessonMode.SCORING) || currentLessonElementId === LessonSlideIds.TITLE_PAGE) {
      return false;
    }

    if (lessonManager.playerMode === LessonMode.REVIEW) {
      return true;
    }
    if (lessonManager.playerMode === LessonMode.SCORING) {
      return true;
    }
    if ((lessonManager.playerMode === LessonMode.PREVIEW ||
          lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW ||
          lessonManager.playerMode === LessonMode.ITEM_PREVIEW) &&
          (hasAlignments || hasRubric || hasTeacherNotes)) {
      return true;
    }

    return false;
  }

  showFlyoutFlip = () => {
    return ((lessonManager.playerMode === LessonMode.REVIEW) ||
      (lessonManager.playerMode === LessonMode.SCORING));
  }

  showFlyoutScore = () => {
    return ((lessonManager.playerMode === LessonMode.REVIEW) ||
      (lessonManager.playerMode === LessonMode.SCORING));
  }

  showFlyoutTeacherNotes = () => {
    return ((lessonManager.playerMode === LessonMode.PREVIEW) ||
      (lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW) ||
      (lessonManager.playerMode === LessonMode.ITEM_PREVIEW) ||
      (lessonManager.playerMode === LessonMode.SCORING));
  }

  showFlyoutAlignments = (hasAlignments) => {
    return (((lessonManager.playerMode === LessonMode.PREVIEW ||
      lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW ||
      lessonManager.playerMode === LessonMode.ITEM_PREVIEW) && hasAlignments) ||
      (lessonManager.playerMode === LessonMode.REVIEW && hasAlignments) ||
      (lessonManager.playerMode === LessonMode.SCORING && hasAlignments));
  }

  showFlyoutRubric = (hasRubric, lessonElementId) => {
    const { isStudent } = userManager;

    const model = lessonManager.getSlideModel(lessonElementId);

    const { hasStudentRubric } = model;

    return ((hasRubric && (lessonManager.playerMode === LessonMode.PREVIEW ||
      lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW ||
      lessonManager.playerMode === LessonMode.ITEM_PREVIEW)) ||
      (hasRubric && isStudent && hasStudentRubric && lessonManager.playerMode === LessonMode.REVIEW));
  }

  setCourseContentItemId = (courseContentItemId) => {
    lessonManager.courseContentItemId = courseContentItemId;
  }

  async getResource(contentItemId) {
    const resource = await this.fetchResourceItem(contentItemId);
    lessonManager.setResourceUrl(resource.presignedAwsUrl);
  }

  excludeContentLanguage() {
    let exclude = false;
    if (lessonManager.isAssessment) {
      exclude = toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_CONTENT_LANGUAGE);
    }
    return exclude;
  }

  async turnOffCurrentAudio() {
    if (lessonManager.currentAudioId) {
      const playerElement = document.getElementById(lessonManager.currentAudioId);
      if (playerElement) {
        playerElement.style.display = 'none';
        playerElement.firstChild.load();

        const audioNodes = document.querySelectorAll('.ck-cartResourceItem[data-entity-type-id="audio_resource"]');
        if (audioNodes && audioNodes.length > 0) {
          for (const node of audioNodes) {
            node.classList.remove('selected');
          }
        }
      } else {
        lessonManager.setCurrentAudioId(null);
      }
    }
  }

  async showInlineAudioPlayer(node) {
    const dataNodeId = node.getAttribute('data-node-id');
    const playerElement = document.getElementById(dataNodeId);

    if (playerElement) {
      if (playerElement.style.display === 'none') {
        await this.turnOffCurrentAudio();

        playerElement.style.display = 'block';
        node.classList.add('selected');
        playerElement.firstChild.play();
        lessonManager.setCurrentAudioId(dataNodeId);
      } else {
        playerElement.style.display = 'none';
        node.classList.remove('selected');
        playerElement.firstChild.load();
      }
    } else {
      await this.turnOffCurrentAudio();

      const commonId = Math.floor(Math.random() * 99999999);
      node.setAttribute('data-node-id', commonId);
      const contentItemId = node.getAttribute('data-content-item-id');
      const contentItem = await this.fetchContentItem(contentItemId);
      const audioDiv = document.createElement('div');
      audioDiv.classList.add('embeddedAudioPlayer');
      audioDiv.classList.add('audioResourceControl');
      audioDiv.id = commonId;
      const audioElement = document.createElement('audio');
      const audioSrc = `${Auth.ecms}/api/redirectToStreamUrl?authKey=${Auth.authKey}&resourceFolder=userFiles&entityId=${contentItem.entityId}&entityTypeId=audio_resource`;
      audioElement.src = audioSrc;
      audioElement.controls = true;
      audioElement.play();
      audioDiv.appendChild(audioElement);
      // Check if there is parent p, ul, or ol tag. If so use one of them otherwise use the parent html-input element
      const closest = (node.closest('p') || node.closest('ul') || node.closest('ol')
        ? node.closest('p') || node.closest('ul') || node.closest('ol')
        : node.closest('.html-input'));

      if (closest) {
        closest.prepend(audioDiv);
      } else {
        node.previousSibling.prepend(audioDiv);
      }

      node.classList.add('selected');

      lessonManager.setCurrentAudioId(commonId);
    }
  }
}
export default new LessonService();
