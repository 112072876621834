import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import '../../css/NoteIconPicker.scss';
import { MobXProviderContext, observer } from 'mobx-react';
import { TOOL_TYPE } from '../managers/BookDrawManager';

const NoteIconPicker = observer((props) => {
  const {
    bookDrawManager
  } = useContext(MobXProviderContext);

  const noteYellowRef = useRef();
  const noteBlueRef = useRef();
  const noteRedRef = useRef();
  const noteGreenRef = useRef();

  const handleClick = (e) => {
    const { iconId } = e.target.dataset;
    bookDrawManager.setSelectedIcon(iconId);
    bookDrawManager.setTool(TOOL_TYPE.NOTES);
  };

  useAccessibilityClick(noteYellowRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(noteBlueRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(noteRedRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(noteGreenRef, (e) => {
    handleClick(e);
  });

  return (
    <div className={classNames({ noteIconPicker: true, menuOpen: props.open })}>
      <div className='noteIconPickerInner'>
        <div className='noteIconGroup'>
          <div ref={noteYellowRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'noteYellow') }, 'stampIcon', 'noteYellow')}
            data-icon-id='noteYellow' title='noteYellow' />
          <div ref={noteBlueRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'noteBlue') }, 'stampIcon', 'noteBlue')}
            data-icon-id='noteBlue'
            title='noteBlue' />
          <div ref={noteRedRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'noteRed') }, 'stampIcon', 'noteRed')}
            data-icon-id='noteRed' title='noteRed' />
          <div ref={noteGreenRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'noteGreen') }, 'stampIcon', 'noteGreen')}
            data-icon-id='noteGreen'
            title='thumbs-upStamp' />
        </div>
      </div>
    </div>
  );
});

export default NoteIconPicker;
