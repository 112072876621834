import lessonManager from '../managers/LessonManager';
import responseManager from '../managers/ResponseManager';

import { ContentType, QuestionFeedbackState } from '../../Constants';

import questionService from './QuestionService';
import utilsService from './UtilsService';

export default class PromptService {
  static isAllCorrectPromptAnswerStatus = ({
    model, responseAnswer
  } = {}) => {
    if (!model || !responseAnswer) {
      return;
    }
    // TODO remove // const userInputMap = new Map(toJS(responseAnswer.userInputMap));
    return model?.validation?.prompts?.every((prompt) => {
      const dataId = prompt.id;

      const currentUserInput = responseAnswer.userInputMap?.get(dataId);
      const currentUserInputValue = currentUserInput?.text;

      const result = this.isCorrectPromptAnswerStatus({
        dataId, currentUserInputValue, model
      });
      return result;
    });
  }

  static isSomeCorrectPromptAnswerStatus = ({
    model, responseAnswer
  } = {}) => {
    // TODO remove // const userInputMap = new Map(toJS(responseAnswer?.userInputMap));
    return model?.validation?.prompts?.some((prompt) => {
      const dataId = prompt.id;

      const currentUserInput = responseAnswer?.userInputMap?.get(dataId);
      const currentUserInputValue = currentUserInput?.text;

      return this.isCorrectPromptAnswerStatus({
        currentUserInputValue, dataId, model
      });
    });
  }

  static isCorrectPromptAnswerStatus = ({
    dataId,
    currentUserInputValue,
    model
  } = {}) => {
    return this.getAnswerStatusForCurrentPrompt({
      dataId, currentUserInputValue, model
    }) === 'correct';
  }

  static isIncorrectPromptAnswerStatus = ({
    dataId, currentUserInputValue, model
  } = {}) => {
    return this.getAnswerStatusForCurrentPrompt({
      dataId, currentUserInputValue, model
    }) === 'incorrect';
  }

  static isUserInputAlwaysCorrectIfFoundInModelPromptAnswers = (model, modelValidationPrompt) => {
    switch (model?.type) {
    case ContentType.CLOZE.type:
      return true;
    case ContentType.CLOZE_DRAG_DROP.type:
      return true;
    case ContentType.CLOZE_MATH.type:
      return modelValidationPrompt?.answerType !== 'multichoice';
    case ContentType.CLOZE_COMBO.type: {
      if (modelValidationPrompt && modelValidationPrompt.rangeType === 'cloze') {
        return true;
      }
      return false;
    }
    default:
      return false;
    }
  }

  static getScoreFromPrompts = ({
    model, responseAnswer
  } = {}) => {
    if (!model || !responseAnswer) {
      return 0;
    }
    // TODO remove // const userInputMap = new Map(toJS(responseAnswer.userInputMap));

    const obj = model?.validation?.prompts?.reduce((acc, prompt) => {
      const dataId = prompt.id;

      const currentUserInput = responseAnswer?.userInputMap?.get(dataId);
      const currentUserInputValue = currentUserInput?.text;

      const result = this.isCorrectPromptAnswerStatus({
        dataId, currentUserInputValue, model
      });
      if (result) {
        acc.score++;
      }
      return acc;
    }, { score: 0 });
    return obj?.score;
  }

  /**
   * @returns {0 | 1 | 2}
   *
   * ```
   * QuestionFeedbackState.INCORRECT       // 0, or
   * QuestionFeedbackState.CORRECT         // 1, or
   * QuestionFeedbackState.PARTIAL_CORRECT // 2
   * ```
   */
  static getQuestionFeedbackCodeForAllPrompts = ({ lessonElementState } = {}) => {
    lessonElementState = lessonElementState || responseManager.getLessonElementState(lessonManager.currentLessonElementId);
    const lessonElementId = lessonElementState?.currentResponseAnswer?.lessonElementId;
    const model = lessonManager.getSlideModel(lessonElementId);

    if (!lessonElementState?.currentResponseAnswer?.userInputMap) {
      questionService.updateUserInputMap({ lessonElementState });
      lessonElementState = responseManager.getLessonElementState(lessonElementId);
    }

    const responseAnswer = lessonElementState?.currentResponseAnswer;

    const isAllCorrect = this.isAllCorrectPromptAnswerStatus({ model, responseAnswer });
    if (isAllCorrect) {
      return QuestionFeedbackState.CORRECT;
    } else {
      const isSomeCorrect = this.isSomeCorrectPromptAnswerStatus({ model, responseAnswer });
      return isSomeCorrect ? QuestionFeedbackState.PARTIAL_CORRECT : QuestionFeedbackState.INCORRECT;
    }
  }

  static getAnswerStatusForCurrentPrompt = ({
    dataId,
    currentUserInputValue,
    model,
    onlyCheckForFound = false,
    responseItems = null // optional []
  } = {}) => {
    const modelPrompt = this.getCurrentPrompt({
      dataId,
      model
    });
    const isCaseInsensitive = model?.isCaseSensitive === false;

    let userInputValueToCompare;

    if (model.responseFormat === 'math' || model.mathText || currentUserInputValue?.includes?.('<math') || currentUserInputValue?.includes?.('data-mathml')) {
      userInputValueToCompare = currentUserInputValue?.includes?.('data-mathml') && (model.responseFormat !== 'math' && !model.mathText) ?
        utilsService.findMathInput(currentUserInputValue || '') : currentUserInputValue;
      userInputValueToCompare = utilsService.cleanMathMLForScoring(userInputValueToCompare || '', {
        model, stripAttributes: true
      });
      userInputValueToCompare = utilsService.stripWrappingParagraphTags(userInputValueToCompare || '');
    } else if (model.responseFormat === 'text' || model.responseFormat === 'html' || model.responseFormat === 'image' || model.responseFormat === 'numeric') {
      userInputValueToCompare = utilsService.cleanStringForScoring(currentUserInputValue || '').trim();
    } else {
      userInputValueToCompare = currentUserInputValue;
    }
    userInputValueToCompare = isCaseInsensitive ? userInputValueToCompare?.toLowerCase?.() : userInputValueToCompare;

    const found = (responseItems || modelPrompt?.answers)?.find((answer) => {
      let answerTextToCompare;

      if (model.responseFormat === 'math' || model.mathText || answer.text?.includes?.('<math') || answer?.text?.includes?.('data-mathml')) {
        answerTextToCompare = answer?.text?.includes?.('data-mathml') && (model.responseFormat !== 'math' && !model.mathText) ?
          utilsService.findMathInput(answer.text || '') : answer.text;
        answerTextToCompare = utilsService.cleanMathMLForScoring(answerTextToCompare || '', {
          model,
          stripAttributes: true
        });
        answerTextToCompare = utilsService.stripWrappingParagraphTags(answerTextToCompare || '');
      } else if (model.responseFormat === 'text' || model.responseFormat === 'html' || model.responseFormat === 'image') {
        answerTextToCompare = utilsService.cleanStringForScoring(answer.text || '').trim();
      } else {
        answerTextToCompare = answer.text || '';
      }

      answerTextToCompare = isCaseInsensitive ? answerTextToCompare?.toLowerCase?.() : answerTextToCompare;

      let isPotentiallyCorrect;
      if (this.isUserInputAlwaysCorrectIfFoundInModelPromptAnswers(model, modelPrompt)) {
        isPotentiallyCorrect = true;
      } else {
        isPotentiallyCorrect = typeof answer.isCorrect !== 'boolean' || answer.isCorrect;
      }

      if (onlyCheckForFound) {
        return (!!userInputValueToCompare || answer.blankOkay) && answerTextToCompare === userInputValueToCompare;
      }
      return isPotentiallyCorrect && (!!userInputValueToCompare || answer.blankOkay) && answerTextToCompare === userInputValueToCompare;
    });

    if (onlyCheckForFound && found) {
      return 'found';
    } else if (onlyCheckForFound && !found) {
      return 'not_found';
    } else if (found) {
      return 'correct';
    } else {
      return 'incorrect';
    }
  }

  /**
   * @param {{
   *  dataId,
   *  model,
   *  promptType: 'anyModelPrompt' | 'responseAnswerPrompt' | 'modelPrompt' | 'modelValidationPrompt' | 'modelResponseItemsPrompt',
   *  lessonElementState // optional
   * }} obj
   * @returns current `prompt` associated with the `dataId` passed. `promptType` will also be included in the returned object, in case we need to know where the prompt came from.
   */
  static getCurrentPrompt = ({
    dataId,
    lessonElementState, // optional
    model,
    promptType = 'anyModelPrompt'
  } = {}) => {
    lessonElementState = lessonElementState || responseManager.getLessonElementState(model.lessonElementId);
    /* ANY model prompt */
    if (promptType === 'anyModelPrompt') {
      let prompts;
      if (model?.validation?.prompts) {
        prompts = model.validation.prompts;
        promptType = 'modelValidationPrompt';
      } else if (model?.prompts) {
        prompts = model.prompts;
        promptType = 'modelPrompt';
      } else if (model?.responseItems) {
        prompts = model.responseItems;
        promptType = 'modelResponseItemsPrompt';
      }
      const prompt = (prompts || []).find((prompt) => prompt.id === dataId);
      return prompt ? { ...prompt, promptType } : undefined;
    /* SPECIFIED model prompt */
    } else if (promptType.includes('model')) {
      let prompts;
      if (promptType === 'modelValidationPrompt') {
        prompts = model?.validation?.prompts;
      } else if (promptType === 'modelPrompt') {
        prompts = model?.prompts;
      } else if (promptType === 'modelResponseItemsPrompt') {
        prompts = model?.responseItems;
      }
      const prompt = (prompts || []).find((prompt) => prompt.id === dataId);
      return prompt ? { ...prompt, promptType } : undefined;
    /* USER responseAnswer prompt */
    } else if (promptType === 'responseAnswerPrompt') {
      const prompt = lessonElementState?.currentResponseAnswer?.prompts?.find((prompt) => prompt.id === dataId);
      if (!prompt) {
        return undefined;
      }

      return {
        ...prompt,
        promptType,
        text: prompt.text
      };
    }
  }

  /**
   * @returns {{ id: string; text: string; }}
   */
  static getResponseItemPromptFromUserInputValue = ({
    currentUserInputValue,
    dataId,
    model
  } = {}) => {
    if (!model?.responseItems?.length) {
      return undefined;
    }
    const responseItems = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || []);
    const currentResponseItemPrompt = (responseItems || []).find((responseItem) => {
      const answerStatus = this.getAnswerStatusForCurrentPrompt({
        responseItems: [responseItem],
        currentUserInputValue,
        dataId,
        model,
        onlyCheckForFound: true
      });
      return answerStatus === 'found';
    });
    return currentResponseItemPrompt;
  }

  static getPromptElementByDataId = (dataId) => {
    return document.querySelector(`[data-id="${dataId}"]`);
  }
}
