import React from 'react';

const DOK = ({ model }) => {
  if (!model.dok) {
    return null;
  }

  return (
    <>
      {(model.dok.level === 'na') ? (
        <div className='label-value-row'>
          <div>DOK:</div>
          <div>n/a</div>
        </div>
      )
        : (
          <>
            <div className='standalone-label'>DOK:</div>
            <div className='sub-rows'>
              <div className='label-value-row'>
                <div>Level:</div>
                <div>{model.dok.level}</div>
              </div>
              <div className='label-value-row'>
                <div>Verb:</div>
                <div>{model.dok.verb}</div>
              </div>
            </div>
          </>
        )}
    </>
  );
};
export default DOK;
