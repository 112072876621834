import React, { useRef } from 'react';
import Auth from '../../../services/AuthService';
import useAccessibilityClick from '../../../../hooks/useAccessibilityClick';
import { Widgets } from '../../../services/WidgetService';
import HtmlComponent from '../../HtmlComponent';

const DISPLAY_OPTIONS = [
  { text: 'Visible', value: 'visible' },
  { text: 'Hidden', value: 'hidden' },
  { text: 'Download', value: 'download' }];

const ResourceWidget = (props) => {
  const { model } = props;

  const modelType = (model.type) ? model.type.toLowerCase() : '';
  const download = model.display === DISPLAY_OPTIONS[2].value;
  let simpleType = Widgets.getLabel(modelType);
  if (simpleType === Widgets.getLabel('resource')) {
    simpleType = Widgets.getLabel('file');
  }
  // const [open,setOpen] = useState(false);

  const resourceType = `${modelType }_resource`;
  const colorClass = `resourceCardColor_${ resourceType}`;
  const typeColorClass = `resourceCardTypeColor_${ resourceType}`;
  const iconClass = `resourceItemIcon_${ resourceType}`;
  const display = (model.display) ? model.display : 'visible';

  if (simpleType === 'file') {
    simpleType = Widgets.getLabel('uploadFile');
  }

  const styleAttr = {};
  if (model.contentItem && model.contentItem.imageUrl) {
    const imageUrl = Auth.getResourceUrlByContentItemId(model.contentItem.id);
    styleAttr.backgroundImage = `url('${imageUrl}')`;
  }

  const fileRef = useRef();
  const downloadRef = useRef();

  useAccessibilityClick(fileRef, () => {
  // setOpen(true);
  });

  useAccessibilityClick(downloadRef, () => {
    const { contentItemId } = model;
    Auth.downloadContentItem(contentItemId);
  });

  return (
    <div className={`widgetResource widgetContent ${display}`}>
      {(model.resourceId && download) ? (
        <div className='fileItem media'>

          {model.contentItem && (
            <>

              <div className={`fileIcon cartResourceItemIcon icon ${colorClass} sideBySide`} style={styleAttr}>
                {(!model.contentItem.imageUrl) &&
                  <div className={`cartResourceItemIconImage icon center ${iconClass}`} />}
              </div>
              <div ref={fileRef} className='fileInfo sideBySide'>
                <div className={`cartResourceItemType ${typeColorClass} text-uppercase`}>{simpleType}</div>
                <div className='fileName'><HtmlComponent htmlStr={model.contentItem.name} /></div>
                <div className='fileDescription'><HtmlComponent htmlStr={model.contentItem.description} /></div>
              </div>
              <div ref={downloadRef} className='fileDownloadIcon' />
            </>
          )}
        </div>
      ) :
        <div className='widgetText'><HtmlComponent htmlStr={model.contentItem.name} /></div>}

      <div className='' />
    </div>
  );
};

export default ResourceWidget;
