import React, { useContext, useEffect, useRef, useState } from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { MobXProviderContext, observer } from 'mobx-react';
import Auth from '../../lessonPlayer/services/AuthService';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';
import SimplePillList from './SimplePillList';
import ChildElementItemList from './ChildElementItemList';
import ResourceItemCheckbox from './ResourceItemCheckbox';
import itemBankService from '../services/ItemBankService';
import '../../css/LibraryItemBankCard.scss';
import UtilsService from '../../lessonPlayer/services/UtilsService';

const ItemBankCard = observer((props) => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);
  const urlParams = new URLSearchParams(window.location.search);

  const satPreview = urlParams.get('satPreview') === 'true';

  const { onBodyClick, libraryCardParams, isSelected, isDisabled, isShopping, skipRemove, skipAddItems = true, customChangeHandler, refreshCallback } = props;
  const { id, backgroundImg, cardClassName, libraryResource, childElementIds } = libraryCardParams;

  const [arrowClass, setArrowClass] = useState('collapsed-icon');

  const questionStem = (libraryResource.questionStem) ? libraryResource.questionStem : (libraryResource.name) ? libraryResource.name : '';

  let strippedText = questionStem.replace('<p>', '<div>').replace('</p>', '</div>');
  strippedText = `<div>${strippedText}</div>`;

  // const isActivityType = itemBankManager.isActivityType(libraryResource.entitySubTypeId);

  const hasChildElements = (childElementIds && childElementIds.length > 0);
  const hasChildElementsClass = (hasChildElements) ? 'has-child-elements' : '';

  const libraryCardKebabOptions = itemBankService.getLibraryCardOptions(skipRemove, skipAddItems,satPreview);
  const kababOptionKeys = Object.keys(libraryCardKebabOptions);
  const cardRef = useRef();
  const showHideRef = useRef();
  const authBackgroundImage = (UtilsService.isNullOrEmpty(backgroundImg)) ? null : Auth.getResourceUrlByContentItemId(id);
  const questionTypeText = itemBankManager.getQuestionTypeText(libraryResource.entitySubTypeId);
  const subTypeId = (libraryResource.entitySubTypeId) ? libraryResource.entitySubTypeId : '';
  const entityTypeId = (libraryResource.entityTypeId) ? libraryResource.entityTypeId : '';
  const notInLicensedBank = (libraryResource.itemResourceInLicensedBank) ? '' : 'not-licensed-bank';

  useEffect(() => {
    const arrowDir = (itemBankManager.activityCardExpanded.includes(id)) ? 'expanded-icon' : 'collapsed-icon';
    setArrowClass(arrowDir);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useAccessibilityClick(cardRef, () => {
    itemBankService.handleShowItemDetails(libraryCardParams);
  });

  useAccessibilityClick(showHideRef, () => {
    // keep one open at a time
    if (!itemBankManager.activityCardExpanded.includes(id)) {
      itemBankManager.clearActivityCardExpanded();
      itemBankManager.addActivityCardExpanded(id);
    } else {
      itemBankManager.clearActivityCardExpanded();
    }

    const arrowDir = (itemBankManager.activityCardExpanded.includes(id)) ? 'expanded-icon' : 'collapsed-icon';
    setArrowClass(arrowDir);
  });

  const RenderMenuContent = () => {
    return (
      <>
        {(libraryCardKebabOptions) ? (
          <Menu arrow={true} menuButton={<div className='menu-icon' />} transition>
            {
              kababOptionKeys.map((kebabOptionKey) => {
                const kebabOption = libraryCardKebabOptions[kebabOptionKey];
                return (
                  <MenuItem key={kebabOption.key}
                    name={kebabOption.label}
                    onClick={async () => {
                      await kebabOption.callback(libraryCardParams);
                      if (kebabOptionKey === 'remove' && refreshCallback) {
                        refreshCallback();
                      }
                    }}>
                    {kebabOption.label}
                  </MenuItem>
                );
              })
            }
          </Menu>
        ) : <></>}
      </>

    );
  };

  return (
    <div>
      <ResourceItemCheckbox isDisabled={isDisabled} isSelected={isSelected} itemId={id} onChangeHandler={customChangeHandler}
        showMe={isShopping} />
      <div className={`library-card item-bank-card ${cardClassName} ${subTypeId} ${entityTypeId} ${hasChildElementsClass} ${notInLicensedBank}`} style={{ backgroundImage: (authBackgroundImage) ? `url(${authBackgroundImage})` : '' }}>
        <div className={`library-card-title ${onBodyClick ? 'clickable' : ''} ${subTypeId}`}>
          <div className='library-card-title-text'>
            <HtmlComponent htmlStr={strippedText} />
          </div>
        </div>
        <div className='library-card-tag-section'>
          <SimplePillList assessmentItem={null} isFullList={true} item={libraryCardParams} pillClass={subTypeId}
            pills={libraryResource.categoryTags} />
        </div>
        <div className='library-card-footer'>
          {(hasChildElements) && (
            <div className='menu-arrow-wrapper'>
              <div ref={showHideRef} className={arrowClass} />
            </div>
          )}
          <div ref={cardRef} className='resource-type-text'>{questionTypeText}</div>
          <div className='menu-button-wrapper'>
            <RenderMenuContent />
          </div>
        </div>
        {(hasChildElements && itemBankManager.activityCardExpanded.includes(id)) &&
          <ChildElementItemList childElementIds={childElementIds} />}
      </div>
    </div>
  );
});

export default ItemBankCard;
