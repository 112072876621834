import { fabric } from 'fabric';
import CanvasOrganizerService from './CanvasOrganizerService';

export default class AnalogiesService {
  constructor(model) {
    this.model = model;
    this.canvasOrganizer = new CanvasOrganizerService(model);
  }

  canvasOrganizer;

  model;
  canvas;

  canvasHeight;
  canvasWidth;

  initCanvas = () => {
    this.initialize();

    const width = this.HORIZONTAL_MARGIN + this.BOX_WIDTH + this.HORIZONTAL_SPACING + this.ENTRY_BOX_WIDTH
        + this.HORIZONTAL_SPACING + this.RHOMBUS_WIDTH + this.HORIZONTAL_SPACING
        + this.ENTRY_BOX_WIDTH + this.HORIZONTAL_SPACING + this.BOX_WIDTH + this.HORIZONTAL_MARGIN;

    this.canvasHeight = this.CANVAS_HEIGHT;
    this.canvasWidth = width;

    const settings = {
      height: this.CANVAS_HEIGHT,
      width,
    };
    this.canvas = new fabric.Canvas(this.model.id, settings);
    this.canvasOrganizer.setCanvas(this.canvas);
  }

  render = () => {
    if (this.canvas) {
      this.showBoxes();
    }
  }

  CANVAS_HEIGHT = 250;
  RHOMBUS_WIDTH = 175;
  RHOMBUS_HEIGHT = 125;
  BOX_WIDTH = 100;
  BOX_HEIGHT = 50;
  ENTRY_BOX_WIDTH = 125;
  ENTRY_BOX_HEIGHT = 50;
  LINE_LENGTH = 175;
  ENTRY_BOX_COUNT = 2;
  HORIZONTAL_SPACING = 5;
  HORIZONTAL_MARGIN = 20;

  initialize = function () {
    if (!this.model.leftBoxCount) {
      this.model.leftBoxCount = 2;
    }

    this.model.leftText = this.model.leftText || ['', ''];
    this.model.centerText = this.model.centerText || 'as';
    this.model.rightText = this.model.rightText || ['', ''];
    this.model.leftEndText = this.model.leftEndText || 'is to';
    this.model.rightEndText = this.model.rightEndText || 'is to';
  }

  getHeight = function () {
    return this.CANVAS_HEIGHT;
  }

  showBoxes = function () {
    const centralRhombusLeft = (this.canvasWidth - this.RHOMBUS_WIDTH) / 2;
    const centralRhombusTop = (this.canvasHeight - this.RHOMBUS_HEIGHT) / 2;
    const centralRhombus = this.canvasOrganizer.drawRhombus(centralRhombusLeft, centralRhombusTop, this.RHOMBUS_WIDTH, this.RHOMBUS_HEIGHT);

    const centralBoxLeft = (this.canvasWidth - this.BOX_WIDTH) / 2;
    const centralBoxTop = (this.canvasHeight - this.BOX_HEIGHT) / 2;
    let lineStroke = this.model.centerText ? this.canvasOrganizer.LINE_STROKE : this.canvasOrganizer.LINE_STROKE_THINNER;
    const centralBox = this.canvasOrganizer.drawRectangle(centralBoxLeft, centralBoxTop, this.BOX_WIDTH, this.BOX_HEIGHT, lineStroke);

    const rightBoxLeft = centralRhombusLeft + this.RHOMBUS_WIDTH + this.HORIZONTAL_SPACING;
    const leftBoxLeft = centralRhombusLeft - this.ENTRY_BOX_WIDTH - this.HORIZONTAL_SPACING;
    const boxTopMargin = 40;

    // draw left side
    const totalLeftBoxHeight = 2 * (this.ENTRY_BOX_HEIGHT + boxTopMargin);
    let leftBoxTop = (this.canvasHeight - totalLeftBoxHeight + boxTopMargin) / 2;
    lineStroke = this.model.leftText[0] ? this.canvasOrganizer.LINE_STROKE : this.canvasOrganizer.LINE_STROKE_THINNER;
    this.canvasOrganizer.drawRectangle(leftBoxLeft, leftBoxTop, this.ENTRY_BOX_WIDTH, this.ENTRY_BOX_HEIGHT, lineStroke);
    leftBoxTop = leftBoxTop + this.ENTRY_BOX_HEIGHT + boxTopMargin;
    lineStroke = this.model.leftText[1] ? this.canvasOrganizer.LINE_STROKE : this.canvasOrganizer.LINE_STROKE_THINNER;
    this.canvasOrganizer.drawRectangle(leftBoxLeft, leftBoxTop, this.ENTRY_BOX_WIDTH, this.ENTRY_BOX_HEIGHT, lineStroke);

    // draw right side
    const totalRightBoxHeight = 2 * (this.ENTRY_BOX_HEIGHT + boxTopMargin);
    let rightBoxTop = (this.canvasHeight - totalRightBoxHeight + boxTopMargin) / 2;
    lineStroke = this.model.rightText[0] ? this.canvasOrganizer.LINE_STROKE : this.canvasOrganizer.LINE_STROKE_THINNER;
    this.canvasOrganizer.drawRectangle(rightBoxLeft, rightBoxTop, this.ENTRY_BOX_WIDTH, this.ENTRY_BOX_HEIGHT, lineStroke);
    rightBoxTop = rightBoxTop + this.ENTRY_BOX_HEIGHT + boxTopMargin;
    lineStroke = this.model.rightText[1] ? this.canvasOrganizer.LINE_STROKE : this.canvasOrganizer.LINE_STROKE_THINNER;
    this.canvasOrganizer.drawRectangle(rightBoxLeft, rightBoxTop, this.ENTRY_BOX_WIDTH, this.ENTRY_BOX_HEIGHT, lineStroke);

    // draw lines from rhombus
    this.canvasOrganizer.drawLineFromShape(centralRhombus, this.LINE_LENGTH, this.canvasOrganizer.LEFT);
    this.canvasOrganizer.drawLineFromShape(centralRhombus, this.LINE_LENGTH, this.canvasOrganizer.RIGHT);

    // draw left and rightmost rectangles
    lineStroke = this.model.leftEndText ? this.canvasOrganizer.LINE_STROKE : this.canvasOrganizer.LINE_STROKE_THINNER;
    this.canvasOrganizer.drawRectangle(centralBoxLeft - centralBox.width - this.LINE_LENGTH,
      centralBoxTop, this.BOX_WIDTH, this.BOX_HEIGHT,
      lineStroke);
    lineStroke = this.model.rightEndText ? this.canvasOrganizer.LINE_STROKE : this.canvasOrganizer.LINE_STROKE_THINNER;
    this.canvasOrganizer.drawRectangle(centralBoxLeft + centralBox.width + this.LINE_LENGTH,
      centralBoxTop, this.BOX_WIDTH, this.BOX_HEIGHT,
      lineStroke);
  }
}
