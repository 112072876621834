import React, { useContext, useRef } from 'react';
import '../../css/UsageDialog.scss';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import Loader from '../../lessonPlayer/components/Loader';
import itemBankService from '../services/ItemBankService';

import { register } from '../../i18n';
const t = register('UsageDialog');

const UsageDialog = observer((props) => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  const closeRef = useRef();
  useAccessibilityClick(closeRef, (e) => {
    itemBankService.setUsageDialogOpen(false);
  });

  const usageResults = itemBankManager.usageResults;

  return (
    <div className='usage-dialog'>
      <ReactModal
        disableResize={true}
        initHeight={600}
        initWidth={540}
        isOpen={itemBankManager.usageDialogOpen} top={50}>

        <div className='usage-dialog-body'>
          {!(itemBankManager.loading) ? (
            <>
              <div className='solution-header'>
                <div className='title-label basic'>{t('headerLabel')}</div>
                <div className='x-close-button mask-image' onClick={() => {
                  itemBankService.setUsageDialogOpen(false);
                }} />
              </div>
              <div className='descriptionText'>{(!itemBankManager.loading && (usageResults && usageResults.length < 1)) ? t('noResultsMessage') : t('modalDescriptionText')}</div>
              <div className='scroll-area'>
                
                <div className='userUsageList'>
                  {(usageResults && usageResults.length > 0) &&
                    usageResults.map((usageItem, index) => {
                      return (
                        <div key={index} className='user'>
                          {usageItem.ownerDisplayName}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </>
          )
            :
            <div className='dialog-loader'><Loader /></div>}
        </div>
        <div className='footer-buttons'>
          <button ref={closeRef} className='close-button'>{t('closeButtonLabel')}</button>
        </div>
      </ReactModal>
    </div>
  );
});

export default UsageDialog;
