import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer, } from 'mobx-react';

import useWirisParser from '../../../hooks/useWirisParser';

import QuestionListViewer from '../QuestionListViewer';
import useStyleEvents from '../../../hooks/useStyleEvents';

// This activity has been replaced with a questionlist components, which is a list of standalone.
const MultipartActivity = observer(({ lessonElementId, showQuestionBanner }) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const wirisRef = useRef();
  useWirisParser(wirisRef);

  const childModel = lessonManager.getSlideModel(lessonElementId);
  const activityModel = lessonManager.getSlideModel(childModel.parentActivityElementId);
  const parentLessonElement = lessonManager.getLessonElement(childModel.parentActivityElementId);
  lessonManager.setIsQuestionListView(true);

  useStyleEvents(lessonElementId, `${lessonElementId}-activity-view`);

  return (
    <>
      {(parentLessonElement && parentLessonElement.lessonElementIds) && (
        <div ref={wirisRef} className={`activity-content ${activityModel.type}`} id={`${lessonElementId}-activity-view`}>
          <QuestionListViewer directions={activityModel.directions} isMultiPart={true} lessonElementIds={parentLessonElement.lessonElementIds} parentElementId={childModel.parentActivityElementId}
            showQuestionBanner={showQuestionBanner} />
        </div>
      )}
    </>
  );
});
export default MultipartActivity;
