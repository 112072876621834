import _ from 'lodash';
import { toJS } from 'mobx';
import { Behavior } from './Behavior';
import { ContentType, LessonMode, PartialScoringType, QuestionFeedbackState } from '../../Constants';
import UtilsService from '../services/UtilsService';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';

export class TextHighlightBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    if (responseAnswer && responseAnswer.ranges && responseAnswer.ranges.length > 0) {
      return true;
    }
    return false;
  }

  // just for this question type
  //
  getComboStudentRanges = (responseModel, studentRanges) => {
    let currentComboRange = null;
    const comboStudentRanges = [];

    if (studentRanges) {
      studentRanges = _.sortBy(studentRanges, 'startIndex');

      studentRanges.forEach((studentRange, index) => {
        if (currentComboRange) {
          if (studentRange.startIndex === (currentComboRange.endIndex + 1)) {
            currentComboRange.endIndex = studentRange.endIndex;
            currentComboRange.text = `${currentComboRange.text} ${studentRange.text}`;
            currentComboRange.ids.push(studentRange.id);
            if (index === studentRanges.length - 1) {
              comboStudentRanges.push(currentComboRange);
            }
          } else {
            comboStudentRanges.push(currentComboRange);
            currentComboRange = _.clone(studentRange);
            currentComboRange.ids = [studentRange.id];
          }
        } else {
          currentComboRange = _.clone(studentRange);
          currentComboRange.ids = [studentRange.id];
        }
      });
    }

    // Remove comboStudentRanges that are a single responseRange.
    const filteredComboStudentRanges = [];
    comboStudentRanges.forEach((comboRange) => {
      if (comboRange.ids.length > 1) {
        filteredComboStudentRanges.push(comboRange);
      }
    });
    return filteredComboStudentRanges;
  }

  getScore = (responseModel, model) => {
    let score = 0;
    // TODO unused // let isCorrect = true;
    let tempScore = 0;

    const correctRanges = (model.validation && model.validation.ranges) ? toJS(model.validation.ranges) : [];
    const studentRanges = (responseModel.ranges) ? toJS(responseModel.ranges) : [];
    let correctStudentRangeIds = [];
    const promptCount = correctRanges.length;

    if (correctRanges && promptCount > 0 && studentRanges && studentRanges.length > 0) {
      correctRanges.forEach((correctRange) => {
        const studentRange = _.find(studentRanges, { startIndex: correctRange.startIndex });
        if (studentRange && studentRange.endIndex === correctRange.endIndex) {
          tempScore++;
          correctStudentRangeIds.push(studentRange.id);
        }
      });

      const comboStudentRanges = this.getComboStudentRanges();
      correctRanges.forEach((correctRange) => {
        const comboStudentRange = _.find(comboStudentRanges, { startIndex: correctRange.startIndex });
        if (comboStudentRange && comboStudentRange.endIndex === correctRange.endIndex) {
          tempScore++;
          correctStudentRangeIds = correctStudentRangeIds.concat(comboStudentRange.ids);
        }
      });
      studentRanges.forEach((studentRange) => {
        if (correctStudentRangeIds.indexOf(studentRange.id) === -1) {
          tempScore--;
        }
      });

      if (tempScore < 0) {
        tempScore = 0;
      }
    } else {
      tempScore = 0;
    }
    if (model.scoringType === PartialScoringType.EXACT) {
      score = (tempScore === promptCount) ? model.maxScore : 0;
    } else if (model.scoringType === PartialScoringType.PARTIAL) {
      score = tempScore / promptCount * model.maxScore;
    } else if (model.scoringType === PartialScoringType.PARTIAL_MATCH_RESPONSE) {
      score = tempScore;
    }

    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    const newArray = [];
    responseAnswer.ranges.forEach((item) => {
      newArray.push(item);
    });
    newArray.push(data);
    responseAnswer.ranges = newArray;
  }

  removeResponseData = (data, responseAnswer) => {
    const newArray = [];
    responseAnswer.ranges.forEach((item) => {
      if (item.id !== data.rangeId) {
        newArray.push(item);
      }
    });

    responseAnswer.ranges = newArray;
  }

  getInitializedResponse = (lessonElementId, model) => {
    return {
      ranges: [],
      lessonElementId
    };
  }

  isAutoScored = (model) => {
    return (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
  }

  getCorrectAnswersText = () => {
    return null;
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let numberOmitted = 0;
    let numberSelectedIncorrect = 0;
    let numberSelectedCorrect = 0;
    let correctRanges;
    if (lessonElementState && lessonElementState.currentResponseAnswer && model && model.validation) {
      const studentRanges = lessonElementState.currentResponseAnswer.ranges ? lessonElementState.currentResponseAnswer.ranges : [];
      correctRanges = model.validation.ranges ? model.validation.ranges : [];
      numberOmitted = correctRanges.length;
      studentRanges.forEach((studentRange) => {
        if (this.isAnswerCorrect(studentRange.id, lessonElementState, model)) {
          numberSelectedCorrect++;
          numberOmitted--;
        } else {
          numberSelectedIncorrect++;
        }
      });
    }

    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    const showPartialMessage = (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) || (questionIsCorrect === QuestionFeedbackState.INCORRECT);

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if ((showPartialMessage) && (correctRanges?.length > 1) && (numberSelectedCorrect > 0)) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
      }
      if (numberOmitted > 0 && numberSelectedIncorrect === 0) {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt2()}.<br/>${ custom }${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody}<br/> ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  setCorrectAnswer = (state, model) => {

  }

  resetStudentAnswer = (state) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  // going away-- no not for every question type :-)
  isAnswerCorrect = (answerId, lessonElementState, model) => {
    const responseModel = lessonElementState.currentResponseAnswer;

    const correctRanges = (model.validation && model.validation.ranges) ? toJS(model.validation.ranges) : [];
    const studentRanges = (responseModel.ranges) ? toJS(responseModel.ranges) : [];
    let correctStudentRangeIds = [];
    const promptCount = correctRanges.length;

    if (correctRanges && promptCount > 0 && studentRanges && studentRanges.length > 0) {
      const studentRange = _.find(studentRanges, { id: answerId });
      if (!studentRange) {
        return false;
      }
      let isCorrect = false;
      correctRanges.forEach((correctRange) => {
        if (studentRange.endIndex === correctRange.endIndex &&
          studentRange.startIndex === correctRange.startIndex) {
          isCorrect = true;
        }
      });

      if (isCorrect) {
        return isCorrect;
      }

      const comboStudentRanges = this.getComboStudentRanges();
      correctRanges.forEach((correctRange) => {
        const comboStudentRange = _.find(comboStudentRanges, { startIndex: correctRange.startIndex });
        if (comboStudentRange && comboStudentRange.endIndex === correctRange.endIndex) {
          correctStudentRangeIds = correctStudentRangeIds.concat(comboStudentRange.ids);
        }
      });
    }
    if (correctStudentRangeIds.indexOf(answerId) >= 0) {
      return true;
    }
    return false;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    const uiState = questionFeedbackManager.getUiState(lessonElementId);

    if (lessonElementState.scoreValue >= lessonElementState.maxScore || uiState.showAnswers) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new TextHighlightBehavior();
