import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactSelect from 'react-select';
import Iframe from 'react-iframe';

import '../../css/PublisherPreview.scss';

import Auth from '../services/AuthService';
import UtilsService from '../services/UtilsService';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';

const PublisherPreview = observer((props) => {
  const {
    lessonManager,
    itemBankManager
  } = useContext(MobXProviderContext);

  const devices = [
    { value: 'desktop', label: 'Desktop', width: '100%', height: '100%' },
    { value: 'ipadProLandscape', label: 'iPad Pro (landscape)', width: 2388, height: 1668 },
    { value: 'ipadProPortrait', label: 'iPad Pro (portrait)', width: 1668, height: 2388 },

    { value: 'smallDesktop', label: 'Small Desktop 1024 x 768', width: 1024, height: 768 },

    { value: 'phoneLandscape', label: 'iPhone 11 (landscape)', width: 1792, height: 828 },
    { value: 'phonePortrait', label: 'iPhone 11 (portrait)', width: 828, height: 1792 },
    { value: 'phone14Landscape', label: 'iPhone 14 (landscape)', width: 2532, height: 1170 },
    { value: 'phone14Portrait', label: 'iPhone 14 (portrait)', width: 1170, height: 2532 },
    { value: 'chromeBook', label: 'Chromebook (10 inch)', width: 1920, height: 1200 },
  ];

  const [selectedDevice, setSelectedDevice] = useState(devices[0]);
  const [styleObj, setStyleObj] = useState({
    width: '100%',
    height: '100%',
    scale: 'scale(1)',
    marginTop: '0',
    marginLeft: '0',
  });

  const [profileParam, setProfileParam] = useState('');
  const exitPreviewRef = useRef();
  const { contentItemId, courseContentItemId, } = props;

  const path = 'preview';

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const optionsProfileId = urlParams.get('optionsProfileId');
    if (!UtilsService.isNullOrEmpty(optionsProfileId)) {
      setProfileParam(`&profileId=${optionsProfileId}`);
      itemBankManager.setLessonProfileId(optionsProfileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAccessibilityClick(exitPreviewRef, (_e) => {
    window.close();
  });

  const handleChangeProfile = async (selected) => {
    if (selected.value) {
      itemBankManager.setLessonProfileId(selected.value);
      setProfileParam(`&profileId=${selected.value}`);
    }
    itemBankManager.setSelectedProfileOption(selected);
  };

  const handleChangeDevice = async (selected) => {
    const style = {
      width: '100%',
      height: '100%',
      scale: 'scale(1)',
      marginTop: '0',
      marginLeft: '0',
    };

    if (selected.value !== 'desktop') {
      const sWidth = selected.width;
      const sHeight = selected.height;
      const windowWidth = document.getElementById('builder-content-wrapper').offsetWidth;
      const windowHeight = document.getElementById('builder-content-wrapper').offsetHeight;
      const marginTop = 0;
      const marginLeft = 0;

      if (sWidth < windowWidth && sHeight < windowHeight) {
        style.scale = 'scale(1)';
      } else {
        let scale = (windowWidth) / sWidth;
        const hscale = (windowHeight) / sHeight;

        if (hscale < scale) {
          scale = hscale;
        }

        style.scale = `scale(${scale})`;
      }

      style.height = `${sHeight}px`;
      style.width = `${sWidth}px`;
    }
    setStyleObj(style);
    setSelectedDevice(selected);
  };

  return (

    <>
      <div className='publisher-preview'>
        <div className='filter-control-bar'>
          <div>Preview Lesson</div>
          <div className='button-wrapper'>
            {itemBankManager.profiles.length > 0 && (
              <div className='profile-picker-wrapper'>
                <label className='select-label'>Select Profile:</label>
                <ReactSelect
                  className='profile-select'
                  classNamePrefix='profile-select'
                  closeMenuOnSelect={true}
                  onChange={handleChangeProfile}
                  options={itemBankManager.profiles}
                  value={itemBankManager.selectedProfileOption} />
              </div>
            )}

            <div className='profile-picker-wrapper'>
              <label className='select-label'>Select Device:</label>
              <ReactSelect
                className='profile-select'
                classNamePrefix='profile-select'
                closeMenuOnSelect={true}
                onChange={handleChangeDevice}
                options={devices}
                value={selectedDevice} />
            </div>

          </div>
          <button ref={exitPreviewRef} className='preview-button control-button' type='button'>Exit</button>
        </div>

        <div className='builder-content-wrapper' id='builder-content-wrapper'>
          <div className={`frame-wrapper ${selectedDevice.value}`} style={{
            width: styleObj.width,
            transform: styleObj.scale,
            height: styleObj.height,
            transformOrigin: '0 0'
          }}>
            <Iframe /* key='item-preview' */
              className='item-preview-frame'
              url={`../${path}?contentItemId=${contentItemId}&courseId=${courseContentItemId}${profileParam}&sat=${lessonManager.isSat}#${Auth.authKey}`}
              value={`item_preview_${contentItemId}`} />
          </div>
        </div>
      </div>
    </>

  );
});

export default PublisherPreview;
