/* eslint-disable max-len */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { toJS } from 'mobx';

// eslint-disable-next-line import/no-named-default
import { default as ReactSelect } from 'react-select';
import ReactTooltip from 'react-tooltip';

import Iframe from 'react-iframe';
import classNames from 'classnames';

import { /* closestCenter, */ DndContext, DragOverlay, useDraggable, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { confirmDialog } from '../../lessonPlayer/components/dialogs';
import Loader from '../../lessonPlayer/components/Loader';
import UtilsService from '../../lessonPlayer/services/UtilsService';
import Auth from '../../lessonPlayer/services/AuthService';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';
import lessonService from '../../lessonPlayer/services/LessonService';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import ResourceBucketItemList from '../components/ResourceBucketItemList';
import ResourceItemFilter from '../components/ResourceItemFilter';
import AddSelectedItemsToAssessmentButton from '../components/AddSelectedItemsToAssessmentButton';
import AssessmentItemList from '../components/AssessmentItemList';
import ResourceFilterItemList from '../components/ResourceFilterItemList';
import ResourceCartModal from '../components/ResourceCartModal';
import ResourceRecommendCartModal from '../components/ResourceRecommendCartModal';
import CheckOption from '../components/ChecksOption';
import itemBankService from '../services/ItemBankService';
import '../../css/BuilderLayout.scss';
import DetailDialog from '../components/DetailDialog';
import DuplicateDialog from '../components/DuplicateDialog';
import PrintPreviewDialog from '../components/PrintPreviewDialog';
import UsageDialog from '../components/UsageDialog';
import ContentPreview from '../components/ContentPreview';
import ItemBankCard from '../components/ItemBankCard';

import { register } from '../../i18n';

// import { arrayMove } from '@dnd-kit/sortable';

const t = register('BuilderLayout');

// Config for dndKit draggable.
const Draggable = (props) => {
  const { data, id, children, disabled } = props;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: {
      item: data
    },
    disabled,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    padding: 0,
    margin: '0',
    fontFamily: 'Facit-Regular',
    lineHeight: 1.4,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

const BuilderLayout = observer((props) => {
  const {
    toolbarManager,
    userManager,
    lessonManager,
    itemBankManager
  } = useContext(MobXProviderContext);

  // eslint-disable-next-line no-unused-vars
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedProfileId, setSelectedProfileId] = useState('');
  const [selectedProfileOption, setSelectedProfileOption] = useState({ value: '', label: '' });
  const [profiles, setProfiles] = useState([]);
  const [profileParam, setProfileParam] = useState('');
  const [message, setMessage] = useState(null); // eslint-disable-line no-unused-vars

  const [dragging, setDragging] = useState({ isDragging: false, item: {} });
  const [useOverlay, setUseOverlay] = useState(false);
  const [useLessonOverlay, setUseLessonOverlay] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  const exitRef = useRef();
  const previewRef = useRef();
  const addItemsRef = useRef();
  const exitAddItemsRef = useRef();
  const exitPreviewRef = useRef();
  const editItemsRef = useRef();
  const shuffleItemsRef = useRef();
  const viewCartRef = useRef();
  const { viewMode, previewId } = itemBankManager;

  useAccessibilityClick(exitRef, async () => {
    const result = await confirmDialog();
    if (result.isConfirmed) {
      await UtilsService.exitPlayer('Builder');
    }
  });

  useAccessibilityClick(addItemsRef, async (e) => {
    await itemBankService.resetSearch();
    itemBankService.setViewMode('addItems');
    itemBankService.setPreviewId(null);
  });

  useAccessibilityClick(exitAddItemsRef, async (e) => {
    await itemBankService.resetSearch();

    if (itemBankManager.resourceBucketItemsArray.length > 0) {
      itemBankService.setViewMode('build');
    } else {
      const result = await confirmDialog();
      if (result.isConfirmed) {
        await UtilsService.exitPlayer('Builder');
      }
    }
    itemBankService.setPreviewId(null);
  });

  useAccessibilityClick(exitPreviewRef, (e) => {
    const originViewMode = (itemBankManager.originViewMode) ? itemBankManager.originViewMode : 'build';
    itemBankService.setViewMode(originViewMode);
    itemBankService.setPreviewId(null);
  });

  const findAndSetDefault = (array) => {
    if (!lessonManager.lesson.optionsProfileId) {
      return null;
    }
    const optionProfileId = lessonManager.lesson.optionsProfileId;
    let foundOption = null;
    array.forEach((option, index) => {
      if (option.value === optionProfileId) {
        foundOption = option;
      }
    });
    return foundOption;
  };

  const getProfiles = async () => {
    await itemBankService.fetchLessonProfiles();
    const array = toJS(itemBankManager.lessonProfiles);
    setProfiles(array);

    itemBankService.setPreviewId(null);
    const option = findAndSetDefault(array);
    if (option) {
      setSelectedProfileOption(option);
    }
  };

  useAccessibilityClick(previewRef, (e) => {
    const urlParams = new URLSearchParams(window.location.search);

    const satPreview = urlParams.get('satPreview');
    if (satPreview === 'true') {
      itemBankService.setViewMode('satPreview');
    } else {
      itemBankManager.setItemPreviewElementId('');
      let isPrint = false;
      if (lessonManager.lesson && lessonManager.lessonOptions) {
        if (lessonManager.lessonOptions.printOnly) {
          isPrint = lessonManager.lessonOptions.printOnly;
        }
      }
      if (isPrint) {
        itemBankService.setPrintPreviewDialogOpen(true, true);
      } else {
        itemBankService.setViewMode('preview');
      }
    }
  });

  useAccessibilityClick(viewCartRef, (e) => {
    if (itemBankManager.selectedBankItemArray.length > 0) {
      itemBankManager.toggleResourceCart();
    }
  });

  useAccessibilityClick(editItemsRef, async (e) => {
    await itemBankService.addResourcesToLesson(itemBankManager.lessonContentItemId, itemBankManager.selectedBankItemArray);
    await itemBankService.resetSearch();
    itemBankService.setViewMode('build');
    itemBankService.setPreviewId(null);
  });

  useAccessibilityClick(shuffleItemsRef, async (e) => {
    await itemBankService.shuffleLessonElements();
    await getLesson();
  });

  const getLesson = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const externalUrl = urlParams.get('appUrl');
      const lessonContentItemId = urlParams.get('contentItemId');

      await itemBankService.fetchLessonBankItems(lessonContentItemId);
      await itemBankManager.setLessonContentItemId(lessonContentItemId);

      toolbarManager.setExternalUrl(externalUrl);

      await lessonService.fetchBuilderLessonData(lessonContentItemId);

      const isSatParam = urlParams.get('sat') === 'true';
      lessonManager.setIsSat(isSatParam);
    } catch (e) {
      console.log(e);
    }
  };

  // config for dndKit sensores
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating drag
    // This allows card body and kebab click to have time to fire
    // on mouse down.
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(
    mouseSensor,
  );

  useEffect(() => {
    async function init() {
      if (window.location.hash) {
        const authKey = window.location.hash.substring(1);
        const user = await Auth.logInWithAuthKey(authKey);
        Auth.setAuthKey(authKey);
        userManager.setUser(user);
        setFirstLoading(true);
        await getLesson(false);
        await getProfiles();
        await itemBankService.fetchAvailableCategoriesWithTags();
        await lessonService.fetchPublisherTheme(lessonManager.lessonOptions.publisherThemeId);
        setFirstLoading(false);
      }
    }
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { lessonTitle } = lessonManager;
  let title = null;
  if (lessonTitle) {
    let headerName = '';
    if (viewMode === 'build') {
      headerName = t('builderHeaderName');
    } else if (viewMode === 'addItems') {
      headerName = t('addItemsHeaderName');
    }
    title = headerName ? `${headerName}: ${lessonTitle}` : lessonTitle;
  }

  const handleChangeQuestionTypes = async (selected) => {
    await itemBankManager.setBucketQuestionTypes(selected);
  };

  const handleChangeProfile = async (selected) => {
    setSelectedProfileId(selected.value);

    if (selected.value) {
      setProfileParam(`&profileId=${selected.value}`);
    }
    setSelectedProfileOption(selected);
  };

  const doAdd = async (itemId, destinationZoneIndex, isInLesson = false, decrementIndex = false) => {
    if (isInLesson) {
      // this is an reorder
      if (decrementIndex) {
        // We decrement the drop zone index when a reorder drag is dropped on a zone
        // with higher index or the last zone as the zones are 1 higher than the position
        await itemBankService.moveItemToIndex(itemId, destinationZoneIndex - 1);
      } else {
        await itemBankService.moveItemToIndex(itemId, destinationZoneIndex);
      }
    } else {
      // this is an add
      await itemBankService.addItemToLesson(itemId, destinationZoneIndex, true);
    }
    await getLesson();
  };

  const onDragStart = ({ active }) => {
    const draggableId = active.id;

    // don't allow drag while reloading
    if (itemBankManager.contentItemsLoading || itemBankManager.loading) {
      return;
    }

    let item = active?.data?.current?.item; // ItemBankItem object
    if (!item && dragging) {
      item = dragging.item;
    }

    if (item.isInLesson) {
      setUseLessonOverlay(true);
      setUseOverlay(false);
    } else {
      setUseLessonOverlay(false);
      setUseOverlay(true);
    }

    // set dragging state and cache dragged item
    setDragging({ isDragging: true, item });
  };

  const onDragEnd = async ({ over, active }) => {
    // Get the current item
    let item = active?.data?.current?.item; // ItemBankItem object
    if (!item && dragging) {
      item = dragging.item;
    }

    // check if dropped on a drop zone, otherwise do nothing
    if (over?.id) {
      const destinationZoneIndex = over.id;

      if (destinationZoneIndex == 'bucket_list_Droppable') {
        // this is a drop in the bucket list
        if (active.id) {
          await itemBankService.removeItemFromLessonService(active.id);
        }
      } else {
        // This is a drop on assessment list
        const isLastZone = over?.data?.current;
        let isMoveDownDrag = false;
        // see if we are moving down so we can adjust the index
        if (active.id) {
          const dragItemIndex = item.orderNum;
          isMoveDownDrag = destinationZoneIndex > dragItemIndex;
        }
        // call the BE to add the new item;
        await doAdd(item.id, destinationZoneIndex, item.isInLesson, isLastZone || isMoveDownDrag);
      }
    } else {
      // refresh the list because sometimes the item stays hidden if not dropped on a zone.
      if (!itemBankManager.loading && !itemBankManager.contentItemsLoading) {
        itemBankManager.setLoading(true);
      }
      await getLesson();
      itemBankManager.setLoading(false);
    }

    // reset dragging
    setDragging({ isDragging: false, item: null });
  };

  return (
    <>
      <DetailDialog />
      <UsageDialog />
      <DuplicateDialog />
      <PrintPreviewDialog />
      {firstLoading ? <Loader /> : (
        <div className='assessment-builder-wrapper'>
          {(viewMode === 'build') && (
            <>
              <div className='builder-control-bar'>
                <h1><HtmlComponent htmlStr={title} /></h1>
                <div className='button-wrapper'>
                  <button ref={addItemsRef} className='add-items-button control-button'>Add More Items</button>
                  <button ref={previewRef} className='preview-button control-button'>Preview</button>
                  <button ref={exitRef} className='preview-button control-button'>Exit</button>
                </div>
              </div>
              <DndContext
              // TODO commented out because it was causing draggable items to 'jump' to whatever droppable area was closest to it.
              // collisionDetection={closestCenter}
                modifiers={[snapCenterToCursor]}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                sensors={sensors}>

                <div className='builder-content-wrapper'>
                  <div className='builder-resource-list-wrapper'>
                    {/* Drag overlay definition for itembankitems rendered in ResourceBucketItemList */}
                    {(useOverlay && !useLessonOverlay) && (
                      <DragOverlay className='bl-drag-overlay'>
                        {(dragging && dragging.isDragging && dragging.item) ? (
                          <div className='item-drag-container'>
                            <ItemBankCard key={`${dragging.item.id}_drgOvl`} className='item-drop-target' includeRemove={false} libraryCardParams={dragging.item}
                              refreshCallback={getLesson} />
                          </div>
                        ) :
                          null}
                      </DragOverlay>
                    )}
                    <div className='resource-header-wrapper'>
                      <div className='resource-bank-header'>Selected Items</div>
                      <div className='add-all-button-wrapper'>
                        <AddSelectedItemsToAssessmentButton />
                        <div className='add-all-label'>Place all items shown below</div>
                      </div>
                    </div>
                    <div className='question-types-control'>
                      <ReactSelect
                        allowSelectAll={true}
                        closeMenuOnSelect={false}
                        components={{ CheckOption }}
                        hideSelectedOptions={false}
                        isMulti
                        onChange={handleChangeQuestionTypes}
                        options={itemBankManager.questionTypeOptions}
                        value={itemBankManager.bucketQuestionTypes} />
                    </div>
                    <div className='selected-items-description'>{t('selectedItemsDescription')}</div>
                    <ResourceBucketItemList Draggable={Draggable} dragging={dragging} refreshCallback={getLesson} />
                  </div>
                  <div className='builder-assessment-list-wrapper'>
                    <div className='page-description'>
                      <div>{t('itemBankAssessmentCardDescription')}</div>
                      {message &&
                      <div className='message'><span>Message: </span><span>{message}</span></div>}
                    </div>
                    {(itemBankManager.lessonParentItemsArray && itemBankManager.lessonParentItemsArray.length > 1) && (
                      <div className='builder-shuffle-button-wrapper'>
                        <button ref={shuffleItemsRef} className='control-button inverse on-background shuffle-items-button'>{t('randomizeButtonLabel')}</button>
                      </div>
                    )}
                    <AssessmentItemList Draggable={Draggable} dragging={dragging} refreshCallback={getLesson} setErrorMessage={setMessage}
                      useLessonOverlay={useLessonOverlay} useOverlay={useOverlay} />
                  </div>
                </div>
              </DndContext>
              {itemBankManager.isResourceRecommendCartOpen && (
                <ResourceRecommendCartModal lessonContentItemId={itemBankManager.lessonContentItemId} />
              )}
            </>
          )}
          {(viewMode === 'addItems') && (
            <>
              <div className='filter-control-bar'>
                <h1><HtmlComponent htmlStr={title} /></h1>
                <div className='button-wrapper'>
                  <div className='num-items-label'>Items Selected</div>
                  <div ref={viewCartRef} className={classNames({ 'num-items-display': true, 'disabled': (itemBankManager.selectedBankItemArray.length < 1) })}
                    data-for='numItemsRollover' data-tip>{itemBankManager.selectedBankItemArray.length}
                  </div>
                  <ReactTooltip effect='solid' id='numItemsRollover' type='light'>{t('numItemsRollover')}</ReactTooltip>
                  <div className='header-space' />
                  <button ref={editItemsRef} className={classNames({ 'edit-button': true, 'control-button': true, 'disabled': (itemBankManager.selectedBankItemArray.length < 1) })} disabled={itemBankManager.selectedBankItemArray.length < 1}>Add Items</button>
                  <button ref={exitAddItemsRef} className='exit-button control-button' data-for='exitButtonRollover' data-tip>Exit</button>
                  <ReactTooltip effect='solid' id='exitButtonRollover' type='light'>{t('exitButtonRollover')}</ReactTooltip>
                </div>
              </div>
              <div className='builder-content-wrapper'>
                <div className='filter-resource-list-wrapper'>
                  <ResourceItemFilter />
                </div>
                <div className='filter-results-list-wrapper'>
                  <div className='page-description'>
                    <div>{t('itemBankShoppingCardDescription')}</div>
                    {message &&
                    <div className='message'><span>Message: </span><span>{message}</span></div>}
                  </div>
                  <ResourceFilterItemList />
                </div>
                {itemBankManager.isResourceCartOpen && <ResourceCartModal lessonContentItemId={itemBankManager.lessonContentItemId} />}
              </div>
            </>
          )}
          {(viewMode === 'itemPreview') &&
          <ContentPreview contentItemId={previewId} />}
          {(viewMode === 'preview' && profiles.length > 0) && (
            <>
              <div className='filter-control-bar'>
                <h1><HtmlComponent htmlStr={title} /></h1>
                {profiles.length > 0 && (
                  <div className='profile-picker-wrapper'>
                    <div>Select Lesson Option Profile:</div>
                    <ReactSelect
                      className='profile-select'
                      classNamePrefix='profile-select'
                      closeMenuOnSelect={true}
                      onChange={handleChangeProfile}
                      options={profiles}
                      value={selectedProfileOption} />
                  </div>
                )}
                <div className='button-wrapper'>
                  <div className='header-space' />
                  <button ref={exitAddItemsRef} className='preview-button control-button' type='button'>Done</button>
                </div>
              </div>

              <div className='builder-content-wrapper'>
                <Iframe
                // key='preview'
                  className='item-preview-frame'
                  id={`preview_${lessonManager.lessonContentItem.id}`}
                  url={`../preview?contentItemId=${lessonManager.lessonContentItem.id}&previewLessonElementId=${itemBankManager.itemPreviewElementId}${profileParam}&sat=${lessonManager.isSat}#${Auth.authKey}`} />
              </div>
            </>
          )}
          {(viewMode === 'satPreview') && (
            <>
              <div className='filter-control-bar'>
                <h1><HtmlComponent htmlStr={title} /></h1>
                <div className='button-wrapper'>
                  <div className='header-space' />
                  <button ref={exitAddItemsRef} className='preview-button control-button' type='button'>Done</button>
                </div>
              </div>
              <div className='builder-content-wrapper'>
                <Iframe
                // key='preview'
                  className='item-preview-frame'
                  id={`preview_${lessonManager.lessonContentItem.id}`}
                  url={itemBankManager.satPreviewUrl} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
});

export default BuilderLayout;
