let ecmsBaseUrl;
let publisherBaseUrl;
const publisherSatelliteCode = 'LESSON_SERVICE';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'lesson.content2classroom.com') {
  ecmsBaseUrl = 'https://lesson.content2classroom.com';
  publisherBaseUrl = 'https://publisher.content2classroom.com';
} else if (hostname === 'staging-lesson.content2classroom.com') {
  ecmsBaseUrl = 'https://staging-lesson.content2classroom.com';
  publisherBaseUrl = 'https://staging-publisher.content2classroom.com';
} else if (hostname === 'qa-lesson.content2classroom.com') {
  ecmsBaseUrl = 'https://qa-lesson.content2classroom.com';
  publisherBaseUrl = 'https://qa-publisher.content2classroom.com';
} else if (hostname === 'qaprod-lesson.content2classroom.com') {
  ecmsBaseUrl = 'https://qaprod-lesson.content2classroom.com';
  publisherBaseUrl = 'https://qaprod-publisher.content2classroom.com';
} else if (hostname === 'ast-lesson.content2classroom.com') {
  ecmsBaseUrl = 'https://ast-lesson.content2classroom.com';
  publisherBaseUrl = 'https://ast-publisher.content2classroom.com';
} else if (hostname === 'dev.content2classroom.com') {
  ecmsBaseUrl = 'https://qa-lesson.content2classroom.com';
  publisherBaseUrl = 'https://qa-publisher.content2classroom.com';
} else {
  if (process.env.REACT_APP_URI_SCHEME === 'https') {
    ecmsBaseUrl = 'https://localhost:8080';
    publisherBaseUrl = 'https://localhost:7777';
  } else {
    ecmsBaseUrl = 'http://localhost:8080';
    publisherBaseUrl = 'http://localhost:7777';
  }
}

export const API_ROOT = `${ecmsBaseUrl}`;
export const PUBLISHER_ROOT = `${publisherBaseUrl}`;
export const PUBLISHER_SATELLITE_CODE = `${publisherSatelliteCode}`;
