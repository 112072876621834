import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/QuestionFlyout.scss';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import ActivityChildViewer from '../activityViewer/ActivityChildViewer';
import StandAloneViewer from '../StandAloneViewer';
import navMenuService from '../../services/NavMenuService';
import { register } from '../../../i18n';

const t = register('PanelMenu');

const BookActivityQuestionFlyout = observer(({
  // TODO unused // model,
  isActivity,
  lessonElementId,
  showQuestionBanner
}) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const openButton = useRef();

  const childModel = lessonManager.getSlideModel(lessonElementId);

  const parentModel = lessonManager.getSlideModel(childModel?.parentActivityElementId);

  lessonManager.setIsQuestionListView(false);

  useAccessibilityClick(openButton, () => {
    navMenuService.toggleInteractiveFlyout();
  });

  useEffect(() => {
    if (parentModel && parentModel.dividedLayoutChildWidth) {
      const r = document.querySelector(':root');
      if (parentModel.dividedLayoutChildWidth === 'small') {
        r.style.setProperty('--flyout-wide-width', '30%');
      }
      if (parentModel.dividedLayoutChildWidth === 'medium') {
        r.style.setProperty('--flyout-wide-width', '40%');
      }
      if (parentModel.dividedLayoutChildWidth === 'large') {
        r.style.setProperty('--flyout-wide-width', '50%');
      }
      if (!isNaN(parentModel.dividedLayoutChildWidth)) {
        r.style.setProperty('--flyout-wide-width', `${100 - Number(parentModel.dividedLayoutChildWidth)}%`);
      }
    }
  },);

  const activityLessonElement = lessonManager.getLessonElement(childModel.parentActivityElementId);
  const groupListIds = activityLessonElement?.lessonElementGroups?.get?.(childModel.groupId) || [];

  return ((lessonElementId) ? (
    <div className={classNames('q-flyout-wrapper', 'question-flyout', {
      wide: (lessonManager.isInteractiveFlyoutOpen),
      closed: (!lessonManager.isInteractiveFlyoutOpen),
      activity: isActivity
    }, parentModel.type)} id={`${parentModel.id}_questionFlyout`}>
      <div className={classNames('q-flyout-tab-bar')}>
        <div ref={openButton} className='q-flyout-tab open-button-wrapper'>
          <div aria-hidden className='icon-wrapper' tabIndex='-1'>
            <div aria-hidden aria-label={(!lessonManager.isInteractiveFlyoutOpen) ? `${t('openMenu', 'Open flyout menu')}` : `${t('closeMenu', 'Close flyout menu')}`}
              className='open-button'
              tabIndex='-1' />
          </div>
        </div>
      </div>
      <div className='q-flyout-panel-wrapper'>
        <div className={classNames('q-flyout-panel')}>
          <>
            {(groupListIds?.length || parentModel?.showAllChildren) && (
              <ActivityChildViewer
                lessonElementId={lessonElementId}
                model={childModel}
                showAllChildren={parentModel?.showAllChildren}
                showQuestionBanner={showQuestionBanner} />
            )}

            {!groupListIds?.length && !parentModel?.showAllChildren && (
              <StandAloneViewer
                lessonElementId={lessonElementId}
                model={childModel}
                showQuestionBanner={showQuestionBanner} />
            )}
          </>
        </div>
      </div>
    </div>
  ) :
    <></>);
});

export default BookActivityQuestionFlyout;
