import { useEffect } from 'react';

const useReactModalResizeableDraggable = (containerElementClassname) => {
  // see https://stackoverflow.com/questions/1517924/javascript-mapping-touch-events-to-mouse-events
  const touchHandler = (e) => {
    // list of touches on the screen
    const touches = e.changedTouches;
    // grab first touch
    const first = touches[0];
    let type = '';

    // map touch event to corresponding mouse event
    switch (e.type) {
    case 'touchstart': type = 'mousedown'; break;
    case 'touchmove': type = 'mousemove'; break;
    case 'touchend': type = 'mouseup'; break;
    }

    // create simulated mouse event with settings
    const simulatedEvent = new MouseEvent(type, {
      bubbles: true,
      cancelable: true,
      view: window,
      detail: 1,
      screenX: first.screenX,
      screenY: first.screenY,
      clientX: first.clientX,
      clientY: first.clientY,
      ctrlKey: false,
      altKey: false,
      shiftKey: false,
      metaKey: false,
      button: 0
    });

    // fire event on the element
    first.target.dispatchEvent(simulatedEvent);
    e.preventDefault();
  };

  useEffect(() => {
    // containerElementClassname is needed to make sure we are adding the event listeners to the correct
    // modal. If there are multiple modals open at the same time it will add the event listeners to the first
    // one, but not the ones open afterward.
    const containerElement = document.querySelector(`.${containerElementClassname}`);
    // Instead of adding the event listeners to the entire modal, we add them to the drag area and the resizer.
    // This fixes an issue of content inside of modals not being able to be pressed on touch screen. Now
    // the content won't be mapped from touch to click, just the drag area and resizer.
    const flexibleModalDragArea = containerElement ?
      containerElement.querySelector('.flexible-modal-drag-area') : document.querySelector('.flexible-modal');
    const flexibleModalResizer = containerElement ?
      containerElement.querySelector('.flexible-modal-resizer') : document.querySelector('.flexible-modal');
    if (flexibleModalDragArea) {
      flexibleModalDragArea.addEventListener('touchstart', touchHandler, true);
      flexibleModalDragArea.addEventListener('touchmove', touchHandler, true);
      flexibleModalDragArea.addEventListener('touchend', touchHandler, true);
      flexibleModalDragArea.addEventListener('touchcancel', touchHandler, true);
    }
    if (flexibleModalResizer) {
      flexibleModalResizer.addEventListener('touchstart', touchHandler, true);
      flexibleModalResizer.addEventListener('touchmove', touchHandler, true);
      flexibleModalResizer.addEventListener('touchend', touchHandler, true);
      flexibleModalResizer.addEventListener('touchcancel', touchHandler, true);
    }

    return () => {
      if (flexibleModalDragArea) {
        flexibleModalDragArea.removeEventListener('touchstart', touchHandler, true);
        flexibleModalDragArea.removeEventListener('touchmove', touchHandler, true);
        flexibleModalDragArea.removeEventListener('touchend', touchHandler, true);
        flexibleModalDragArea.removeEventListener('touchcancel', touchHandler, true);
      }
      if (flexibleModalResizer) {
        flexibleModalResizer.removeEventListener('touchstart', touchHandler, true);
        flexibleModalResizer.removeEventListener('touchmove', touchHandler, true);
        flexibleModalResizer.removeEventListener('touchend', touchHandler, true);
        flexibleModalResizer.removeEventListener('touchcancel', touchHandler, true);
      }
    };
  }, []);
};

export default useReactModalResizeableDraggable;
