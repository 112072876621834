import _ from 'lodash';
import { Behavior } from './Behavior';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import scoringService from '../services/ScoringService';
import utilsService from '../services/UtilsService';

export class OrderSentencesBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    return (responseAnswer.prompts.length > 0);
  }

  setCorrectAnswer = (state, model) => {
    const promptObjects = [];
    for (const rPrompt of model.validation?.prompts) {
      for (const mPrompt of model.prompts) {
        if (rPrompt === mPrompt.id) {
          const orderPrompt = {
            id: mPrompt.id,
            text: mPrompt.text
          };
          promptObjects.push(orderPrompt);
        }
      }
    }
    const prompts = promptObjects.map((p) => p.id);
    const responseAnswer = {
      promptObjects,
      prompts,
      lessonElementId: model.lessonElementId
    };

    state.setCurrentResponse(responseAnswer);
  }

  resetStudentAnswer = (state) => {
    state.setCurrentResponse(utilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  getScore = (responseAnswer, model) => {
    let tempScore = 0;
    const prompts = model.validation?.prompts || [];
    const studentResponse = responseAnswer.prompts;
    const promptCount = model.prompts.length;

    if ((prompts && prompts.length > 0) && (studentResponse && studentResponse.length > 0)) {
      for (const prompt of prompts.entries()) {
        const pIndex = prompt[0];
        const validationPromptId = prompt[1];
        const studentResponseId = studentResponse[pIndex];

        if (studentResponseId === validationPromptId) {
          tempScore++;
        }
      }
    }

    const score = scoringService.getAdjustedScore(model, tempScore, promptCount);

    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    responseAnswer.promptObjects = data;
    const prompts = data.map((p) => p.id);
    responseAnswer.prompts = prompts;
  }

  getInitializedResponse = (lessonElementId, model) => {
    const promptObjects = [];

    model.prompts.forEach((prompt, index) => {
      const newPrompt = {
        id: prompt.id,
        text: prompt.text
      };
      promptObjects.push(newPrompt);
    });

    // const prompts = promptObjects.map(p => p.id);
    const responseAnswer = {
      promptObjects,
      prompts: [],
      lessonElementId
    };
    return responseAnswer;
  }

  isAutoScored = (model) => {
    const auto = (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
    return auto;
  }

  getAnswerFeedback = (id, responseAnswer) => {
    console.log('OL - getAnswerFeedback');
    throw new Error('Behavior.getAnswerFeedback must be implemented');
  }

  getCorrectAnswersText = (model) => {
    // Removed because accepted answers is not needed for this question type (CF-3749).
    // // Finds the correct answer id,
    // // finds the answer text for that id, and returns that text
    // if (model?.validation?.answers?.length > 0) {
    //   const correctAnswer = model.validation.answers.find(({ isCorrect }) => isCorrect);
    //   const correctAnswerText = model.answers.find(({ id }) => id === correctAnswer.id).text;
    //   return `<div>${correctAnswerText}</div>`;
    // }
    // return '<div></div>';
    return '';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += utilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += utilsService.saveWarning();
      } else {
        textBody += utilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${utilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody } `;
      }
      textBody = `${utilsService.partiallyCorrectPrompt()}. <br/>${ custom }${textBody}`;
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    // NA for this type.
    return null;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new OrderSentencesBehavior();
