import { Behavior } from './Behavior';
import questionService from '../services/QuestionService';
import { QuestionFeedbackState } from '../../Constants';

export class DefaultBehavior extends Behavior {
  checkForValidResponse = (response, model) => {
    // Can also return a object with optional keys `isValid`, `title`, `text`, and `allowSubmit` to control the dialog box
    return true;
  }

  isAutoScored = (model) => {
    return false;
  }

  setCorrectAnswer = (state, model) => {

  }

  resetStudentAnswer = (state) => {

  }

  getScore = (responseAnswer, model) => {
    return 1;
  }

  setResponseData = (data, responseAnswer) => {

  }

  getAnswerFeedback = (id, responseAnswer) => {
    return true;
  }

  getInitializedResponse = (lessonElementId, model, activityId) => {
    const responseAnswer = {
      ...questionService.generateInitializedQuestionResponse({
        lessonElementId
      })
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    return '';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    return '';
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    // For short Answers there is only one question so we don't have an array of answers so just return the
    // flag for the only answer
    return false;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new DefaultBehavior();
