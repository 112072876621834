import React from 'react';
import RubricView from './RubricView';
import { register } from '../../../i18n';

const t = register('PanelMenu');

const RubricPanel = ({ lessonElementId }) => {
  return (
    <div className='rubric-panel flyout-panel'>
      <div className='panel-header'>{t('rubricPanel')}</div>
      <RubricView lessonElementId={lessonElementId} />
    </div>
  );
};

export default RubricPanel;
