import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import HtmlComponent from './HtmlComponent';
import lessonService from '../services/LessonService';

const QuestionBanner = observer(({ lessonElementId, showQuestionBanner, isMultipart }) => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  // We shouldn't show section title in item number banner
  // const title = lessonManager.currentSectionTitle;

  const questionNumberStr = lessonService.getQuestionLabel(model, {
    forceLongName: true,
    fromQuestionBanner: true
  });

  return (
    <>
      {(showQuestionBanner && !isMultipart) ? (
        <div className='question-header'>
          {/* <HtmlComponent htmlStr={title}/> */}
          {!model.isInstruction && <HtmlComponent className='question-number' htmlStr={questionNumberStr} />}
        </div>
      ) : <></>}
    </>
  );
});

export default QuestionBanner;
