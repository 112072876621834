import React from 'react';

const DefaultActivity = ({ model }) => {
  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Activity Type:</div>
        <div className='not-implemented'>{model.type} (*** not implemented yet ***)</div>
      </div>
      <div className='label-value-row'>
        <div>Questions:</div>
        <div>{model.questionRange}</div>
      </div>
    </div>
  );
};
export default DefaultActivity;
