import React, { useContext } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { PDFObject } from 'react-pdfobject';
import { MobXProviderContext } from 'mobx-react';
import '../../../css/Tools.scss';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';
import { register } from '../../../i18n';

const t = register('ToolNames');
const t2 = register('AriaLabels');

function GraphingCalculator() {
  const {
    toolbarManager,
    lessonManager
  } = useContext(MobXProviderContext);

  let url = 'https://www.desmos.com/calculator';

  if (lessonManager &&
    lessonManager.lessonOptions &&
    lessonManager.lessonOptions.hasTexasTools) {
    url = 'https://www.desmos.com/testing/texas/graphing';
  }

  // CF-4158 If browser doesn't support embedded pdfs (Most mobile browsers) instead of using PDFObject
  // we can use an Iframe.
  const doesBrowserSupportPdfs = PDFObject.supportsPDFs();

  function closeCalc() {
    toolbarManager.setIsGraphingCalculatorOpen(false);
    toolbarManager.toggleToolOff('GraphingCalculator');
  }

  useReactModalResizeableDraggable('calculatorTool');

  return (
    <div className='calculatorTool'>
      <ReactModal
        initHeight={500}
        initWidth={700}
        isOpen={true}>
        <div className='calculatorBody'>
          <div className='tool-header'>
            <div className='title-label basic'>{t('graphingCalculatorInfo')}</div>
            <div aria-label={t2('closedialog')} className='x-close-button' id='modal-close' onClick={closeCalc} role='button'
              tabIndex='0' />
          </div>
          {doesBrowserSupportPdfs ?
            <PDFObject url={url} />
            : <iframe height='95%' src={url} title='calculator-iframe' width='100%' />}
        </div>
      </ReactModal>
    </div>
  );
}

export default GraphingCalculator;
