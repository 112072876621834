import React, { useRef } from 'react';
import useAccessibilityClick from '../../../../hooks/useAccessibilityClick';
import DefaultDialog from '../../dialogs';
import StyleService from '../../../services/StyleService';
import HtmlComponent from '../../HtmlComponent';

const ButtonWidget = (props) => {
  const { model } = props;

  const labelContentsId = `buttonContents_${model.id}`;
  const labelBackgroundId = `buttonBackground_${model.id}`;
  const buttonBackgroundColor = model.style.buttonBackgroundColor || '#3A9CFA';
  const buttonColor = model.style.buttonColor || StyleService.getStyleVar('--theme-font-color');
  const buttonBackgroundOpacity = model.style.buttonBackgroundOpacity || '1';
  const display = (model.display) ? model.display : 'visible';
  const width = model.buttonWidth || '';
  const height = model.buttonHeight || '';

  const borderRadius = model.borderRadius || '16px';
  const label = model.label || '';
  const buttonRef = useRef();

  useAccessibilityClick(buttonRef, () => {
    DefaultDialog.fire({
      title: model.heading,
      html: model.text,
      showCancelButton: false,
      buttonsStyling: false,
      confirmButtonText: `${UtilsService.confirmOk()}` ,
      showClass: {
        backdrop: 'swal2-noanimation',
        popup: '',
        icon: ''
      },
      hideClass: {
        popup: '',
      }
    });
  });

  return (
    <div ref={buttonRef} className={`widgetButton widgetContent ${display}`} id={model.id} style={{ borderRadius, width: `${width}px`, height: `${height}px` }}>
      <div className='widgetButtonBackground' id={labelBackgroundId} style={{ borderRadius, backgroundColor: buttonBackgroundColor, opacity: buttonBackgroundOpacity }}>
        <div className='widgetButtonContents' id={labelContentsId} style={{ borderRadius, placeHolder: 'Your label', color: buttonColor }}><HtmlComponent htmlStr={label} /></div>
      </div>
    </div>
  );
};

export default ButtonWidget;
