import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { register } from '../../i18n';
import HtmlComponent from './HtmlComponent';

const t = register('GlobalQuestionLabels');

const PrintTitlePage = observer(() => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  if (!lessonManager.lesson || !lessonManager.lessonOptions) {
    return (<div className='print-title-page-wrapper' />);
  }

  const lessonName = lessonManager.lessonTitle;
  return (
    <div className='print-title-page-wrapper'>
      <div className='print-title-page-form'>
        <div className='name-title'>{t('name', 'Name')}:</div>
        <div className='line' />
        <div className='class-title'>{t('class', 'Class')}:</div>
        <div className='line' />
      </div>
      <div className='lesson-title'><HtmlComponent htmlStr={lessonName} /></div>
    </div>
  );
});

export default PrintTitlePage;
