import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactTooltip from 'react-tooltip';

import '../../css/ToolbarLayout.scss';
import { register } from '../../i18n';
import TextToSpeechControls from '../components/tools/TextToSpeechControls';

import toolbarService from '../services/ToolbarService';
import utilsService from '../services/UtilsService';

import { useSizeChange } from '../../hooks/useSizeChange';

import BasicCalculator from '../components/tools/BasicCalculator';
import ButtonGroupList from '../components/toolbar/ButtonGroupList';
import CmapDetailDialog from '../components/tabbedFlyout/CmapDetailDialog';
import CombinedRuler from '../components/tools/CombinedRuler';
import CustomaryRuler from '../components/tools/CustomaryRuler';
import GlobalColorPicker from '../components/tools/GlobalColorPicker';
import GraphPaper from '../components/tools/GraphPaper';
import GraphingCalculator from '../components/tools/GraphingCalculator';
import Help from '../components/tools/Help';
import Highlighter from '../components/tools/Highlighter';
import HtmlComponent from '../components/HtmlComponent';
import Instruction from '../components/tools/Instruction';
import MetricRuler from '../components/tools/MetricRuler';
import Notepad from '../components/tools/Notepad';
import Protractor from '../components/tools/Protractor';
import ResourcePreviewDialog from '../components/tools/ResourcePreviewDialog';
import Reveal from '../components/tools/Reveal';
import ScientificCalculator from '../components/tools/ScientificCalculator';
import StickyNotes from '../components/tools/StickyNotes';
import TextHelpCustom from '../components/tools/TextHelpCustom';
import ToolbarLogo from '../components/toolbar/ToolbarLogo';
import navMenuService from '../services/NavMenuService';

const t = register('AriaLabels')

const ToolbarLayout = observer((props) => {
  const {
    lessonManager, toolbarManager, alignmentManager, publisherManager
  } = useContext(MobXProviderContext);
  const [disabled, setDisabled] = useState(false);
  const isResizingRef = useRef(false);
  const disabledDecounceRef = useRef(-1);

  // eslint-disable-next-line no-unused-vars
  const [toolbarRef, _] = useSizeChange(async (dims, ref) => {
    const element = ref.current;
    const overflowing = element.offsetWidth < element.scrollWidth || true;
    if (overflowing && !isResizingRef.current) {
      setDisabled(true);
      isResizingRef.current = true;
      await toolbarService.resizeToFit(element);
      clearTimeout(disabledDecounceRef.current);
      disabledDecounceRef.current = setTimeout(() => {
        setDisabled(false);
      }, 75);
      isResizingRef.current = false;
    }
    navMenuService.setCardWidths();
  });

  const finalTitleAndSubtitle = utilsService.stripTagsAndEntities(lessonManager.lessonTitleAndSubtitle);

  return (
    <div ref={toolbarRef} aria-label={t('mainToolbar')} className='toolbar-wrapper' data-ignore='1'
      role='menu' style={{ opacity: disabled ? 0 : 1 }}>
      {(publisherManager.hasWebReaderId && publisherManager.rsHref ) && <TextToSpeechControls/>}
      <ToolbarLogo />
      {lessonManager.lessonOptions.showTitle && (
        <>
          <div aria-label={finalTitleAndSubtitle} className='toolbar-title-bar'
            data-tip={finalTitleAndSubtitle}>
            <HtmlComponent ariaHidden={true} htmlStr={finalTitleAndSubtitle} />
          </div>
          <ReactTooltip border className='react-tooltip' effect='solid' html={true}
            overridePosition={({ left, top }, _e, _t, node) => {
              return {
                top,
                left: typeof node === 'string' ? left : Math.max(left, 0),
              };
            }}
            place='bottom'
            type='light' />
        </>
      )}
      <ButtonGroupList />

      {toolbarManager.isTextHelpOpen && <TextHelpCustom />}
      {toolbarManager.isStickyNotesOpen && <StickyNotes />}
      {toolbarManager.isNotepadOpen && <Notepad />}
      {toolbarManager.isHighlightAnnotationOpen && <Highlighter />}

      {toolbarManager.isBasicCalculatorOpen && <BasicCalculator />}
      {toolbarManager.isScientificCalculatorOpen && <ScientificCalculator />}
      {toolbarManager.isGraphingCalculatorOpen && <GraphingCalculator />}

      {toolbarManager.isCustomaryRulerOpen && <CustomaryRuler />}
      {toolbarManager.isMetricRulerOpen && <MetricRuler />}
      {toolbarManager.isCombinedRulerOpen && <CombinedRuler />}

      {toolbarManager.isProtractorOpen && <Protractor />}

      {toolbarManager.isGraphPaperOpen && <GraphPaper />}

      {toolbarManager.isRevealOpen && <Reveal />}
      {toolbarManager.isResourcesOpen && <ResourcePreviewDialog />}
      {toolbarManager.isHelpOpen && <Help />}

      {toolbarManager.isInstructionOpen && <Instruction />}
      {toolbarManager.isColorToolOpen && <GlobalColorPicker />}
      {(alignmentManager.cmapDetailDialogIsOpen) && <CmapDetailDialog />}

    </div>
  );
});

export default ToolbarLayout;
