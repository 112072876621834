import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { LessonMode } from '../../../Constants';
import HtmlComponent from '../HtmlComponent';
import FeedbackIcon from '../FeedbackIcon';
import OptionalImage from '../OptionalImage';
import responseService from '../../services/ResponseService';
import Guideline from '../tools/Guideline';
import '../../../css/Griddable.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const Griddable = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const inputRowRef = useRef(null);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);

  const { currentResponseAnswer } = lessonElementState;
  const { readOnly } = lessonElementState;
  const { showAnswers, showAnswerFeedback } = feedbackState;

  // Define state
  const hasSignInput = model.hasSignInput ?? true; // Whether to allow the user to change between positive and negative
  const columnCount = model.columnCount ?? 6; // The total number of columns for number inputs including the decimal point
  const decimalType = model.decimalType ?? 'fixed'; // "fixed" means the publisher decided the location of the decimal while "floating" means the student can enter one anywhere
  const decimalColumn = model.decimalColumn ?? 1; // The right aligned column location of the decimal point in fixed mode. So 0 would mean the last column and 1 would mean one number can be entered after the decimal point

  const textBody = model.questionText ?? '';

  // Helper functions for getting an manipulating the answer
  const currentAnswer = currentResponseAnswer.text;
  const answerColumns = currentAnswer.split('');
  const sign = answerColumns[0];
  const answerNumber = answerColumns.slice(1);
  const correctAnswer = model.validation?.answers?.[0]?.text;

  useStyleEvents(lessonElementId);

  const getCurrentAnswer = () => {
    // The behavior has the data needed for this accessibility helper function.
    const behavior = lessonElementState.questionBehavior;
    const valid = behavior.checkForValidResponse(currentResponseAnswer);
    if (valid) {
      const fullNumber = behavior._toNumber(currentAnswer); // Soooo, will I fix this so that is not a private method. Well if you're seeing this, no!
      return `${fullNumber > 0 ? 'Positive' : 'Negative'} ${Math.abs(fullNumber)}`;
    } else {
      return 'Invalid';
    }
  };

  const getCorrectAnswer = () => {
    const behavior = lessonElementState.questionBehavior;
    // We assume the correct answer is valid
    const fullNumber = behavior._toNumber(correctAnswer); // Soooo, will I fix this so that is not a private method. Well if you're seeing this, no!
    return `${fullNumber > 0 ? 'Positive' : 'Negative'} ${Math.abs(fullNumber)}`;
  };

  const getAnswerNumberDecimalColumn = () => {
    // The decimal column number is right aligned
    return columnCount - decimalColumn - 1;
  };

  const getPossibleSignValues = () => {
    return ['+', '-'];
  };

  const setSign = (sign) => {
    const possibleValues = getPossibleSignValues();
    if (possibleValues.includes(sign)) {
      const newAnswer = [...answerColumns];
      newAnswer[0] = sign;
      responseService.responseChangeHandler(newAnswer.join(''), lessonElementId);
    }
  };

  const getPossibleValues = () => {
    const values = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (decimalType === 'floating') {
      values.unshift('.');
    }
    return values;
  };

  const setColumnValue = (columnIndex, value) => {
    const possibleValues = getPossibleValues();
    // The answer columns are offset by one because of the sign column
    const answerColumnIndex = columnIndex + 1;
    if (possibleValues.includes(value)) {
      let newAnswer = [...answerColumns];
      if (value === '.') {
        // Then we need to remove all other decimal points and replace them with spaces
        newAnswer = newAnswer.map((columnValue) => (columnValue === '.' ? ' ' : columnValue));
      }
      newAnswer[answerColumnIndex] = value;
      // Checking to see if user clicked the same value again, if so we remove the value and replace with a space
      if (answerColumns[answerColumnIndex] === value) {
        newAnswer[answerColumnIndex] = ' ';
      }
      responseService.responseChangeHandler(newAnswer.join(''), lessonElementId);
    }
  };

  return (
    <div className='griddable-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question griddable'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={textBody} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='test-item-answers'>
        {showAnswerFeedback && <div className='feedback-icon-container'><FeedbackIcon lessonElementId={lessonElementId} /></div>}
        <table className='griddable-table'>
          <tbody>
            <tr ref={inputRowRef} className='input-row'>
              {hasSignInput && (
                <EditableCell
                  disabled={readOnly}
                  fullNumber={showAnswers ? getCorrectAnswer() : getCurrentAnswer()}
                  index={-1}
                  onChange={(value) => setSign(value)}
                  validCharacters={getPossibleSignValues()}
                  value={sign}
                />
              )}
              {
                answerNumber.map((value, index) => {
                  const isDecimalColumn = index === getAnswerNumberDecimalColumn();
                  const disabled = readOnly || (isDecimalColumn && decimalType === 'fixed'); // You cannot enter anything into the fixed decimal column
                  return (
                    <EditableCell
                      key={index}
                      disabled={disabled}
                      fullNumber={showAnswers ? getCorrectAnswer() : getCurrentAnswer()}
                      index={index}
                      onChange={(value) => setColumnValue(index, value)}
                      validCharacters={getPossibleValues()}
                      value={value}
                    />
                  );
                })
              }
            </tr>
            <tr className='bubble-row'>
              {hasSignInput && (
                <td className='bubble-column-cell'>
                  <BubbleColumn
                    disabled={readOnly}
                    onSelect={setSign}
                    selectedValue={sign}
                    values={getPossibleSignValues()}
                  />
                </td>
              )}
              {
                answerNumber.map((selectedValue, index) => {
                  const possibleValues = getPossibleValues();
                  const hasBubbles = decimalType === 'floating' || index !== getAnswerNumberDecimalColumn(); // If we are fixed and this is the decimal column, we don't need to show bubbles
                  return (
                    <td key={index} className='bubble-column-cell'>
                      <BubbleColumn
                        disabled={readOnly}
                        onSelect={(value) => setColumnValue(index, value)}
                        selectedValue={selectedValue}
                        values={possibleValues}
                        visible={hasBubbles}
                      />
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default Griddable;

function positiveMod(n, m) {
  return ((n % m) + m) % m;
}

const EditableCell = ({ value, onChange, validCharacters, index, fullNumber, directInput = false, arrowInput = true, disabled = false }) => {
  /*
  A component that allows the user to select it and change the value of the cell within a list of valid characters.
  Or I guess it calls a handler that is expected to change the value of the cell.
  */
  const disable = disabled || (!directInput && !arrowInput); // If there is no possible input, we disable the cell

  const handleKeyPress = (event) => {
    // When the input area is focused, the user can can configurably either enter directly enter a value or navigate through valid values using the arrow keys
    const { key } = event;
    if (directInput && validCharacters.includes(key)) {
      // This has the disadvantage of not allowing key presses to represent other characters. For example, it may be useful to have the keypress "=" represent the character "+" instead of having to enter "shift + ="
      event.preventDefault();
      onChange(key);
    }

    if (arrowInput) {
      if (key === 'ArrowUp') {
        event.preventDefault();
        const currentIndex = validCharacters.indexOf(value);
        // If nothing is selected, select the final value. Otherwise, select the previous value or wrap around to the last value.
        const newIndex = currentIndex >= 0 ? positiveMod(currentIndex - 1, validCharacters.length) : validCharacters.length - 1;
        onChange(validCharacters[newIndex]);
      } else if (key === 'ArrowDown') {
        event.preventDefault();
        const currentIndex = validCharacters.indexOf(value);
        // If nothing is selected, select the first value. Otherwise, select the next value or wrap around to the first value.
        const newIndex = currentIndex >= 0 ? positiveMod(currentIndex + 1, validCharacters.length) : 0;
        onChange(validCharacters[newIndex]);
      }
    }
  };

  let accessibleName = value;
  if (value === '+') {
    accessibleName = 'positive';
  } else if (value === '-') {
    accessibleName = 'negative';
  }

  const readName = `${accessibleName}. Current answer: ${fullNumber}`;

  return (
    <td aria-atomic='true' aria-label={readName} aria-live='assertive' className={`${disable ? 'input-cell' : 'input-cell editable'}`}
      onKeyDown={handleKeyPress} tabIndex={disable ? -1 : 0}>
      <div className='editable-cell-content-box'>{value}</div>
    </td>
  );
};

const BubbleColumn = ({ values, onSelect, selectedValue, disabled, visible = true }) => {
  const takesInput = !disabled && visible;
  const handleClick = (value) => {
    if (takesInput) {
      onSelect(value);
    }
  };

  return (
    <div className='bubble-column'>
      {
        values.map((value, index) => {
          const selected = selectedValue === value;
          return (
            <div key={index} className='bubble-container' onClick={() => handleClick(value)} style={{ opacity: visible ? 1 : 0 }}>
              <div className={`bubble ${selected ? 'selected' : ''} ${!takesInput ? 'disabled' : ''}`}>{value}</div>
            </div>
          );
        })
      }
    </div>
  );
};
