import { action, computed, makeObservable, observable, toJS } from 'mobx';
import _ from 'lodash';
import lessonManager from './LessonManager';

import { LessonElementState } from './obseverableObjects/LessonElementState';
import { EngagementData } from './obseverableObjects/EngagementData';

import { ContentType, QuestionFeedbackState, ScoreState } from '../../Constants';

import utilsService from '../services/UtilsService';

export class ResponseManager {
  @observable
  lessonElementStates = new Map();

  @observable
  submittedResponseList = [];

  @observable
  correctList = [];

  @observable
  incorrectList = [];

  @observable
  partiallyCorrectList = [];

  @observable
  notScoredList = [];

  @observable
  unscoredList = [];

  @observable
  markedForReviewList = [];

  @observable
  engagementMap = new Map();

  @observable
  classroomSummaryReport = null;

  @observable
  studentReviewEngagementReport = null;

  @observable
  isSubmitting = false;

  @observable dependencyFlowMap = new Map();
  @observable sequentialItemDependency = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setSequentialItemDependency = (dependency) => {
    // protects against undefined
    this.sequentialItemDependency = (dependency) || false;
  }

  @action
  setIsSubmitting = (isSubmitting) => {
    this.isSubmitting = isSubmitting;
  }

  @action
  setReadOnly = (lessonElementId, toggle) => {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    if (lessonElementState) {
      lessonElementState.readOnly = toggle;
    }
  }

  getCurrentResponseAnswer(lessonElementId) {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    return lessonElementState.currentResponseAnswer;
  }

  @action
  setResponseId = (id, lessonElementId) => {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    lessonElementState.responseId = id;
  }

  @action
  setPreviewResponseSubmitted = (lessonElementId, toggle) => {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    if (lessonElementState) {
      lessonElementState.isSubmitted = toggle;
      if (!lessonElementState.cachedResponseAnswer) {
        lessonElementState.setCachedResponse(utilsService.safeMobxClone(lessonElementState.currentResponseAnswer));
      }

      this.submittedResponseList.remove(lessonElementId);
      if (toggle) {
        this.submittedResponseList.push(lessonElementId);
      }
    }
  }

  @action
  setCurrentResponseSubmitted(lessonElementId, scoreState, model) {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    if (lessonElementState && lessonElementState.serverResponse && !lessonElementState.serverResponse.isSubmitted) {
      lessonElementState.isSubmitted = true;
      lessonElementState.isSaved = true;
      if (scoreState !== ScoreState.NOT_SCORED) {
        lessonElementState.isScored = true;
      }
      if (!lessonElementState.cachedResponseAnswer) {
        lessonElementState.setCachedResponse(utilsService.safeMobxClone(lessonElementState.currentResponseAnswer));
      }

      // we can fill this here or from a routine using lesson element state
      if (!this.submittedResponseList.includes(lessonElementId)) {
        this.submittedResponseList.push(lessonElementId);
      }
    }
  }

  @action
  setCurrentResponseSaved(lessonElementId, setSubmitted) {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    if (lessonElementState && lessonElementState.serverResponse) {
      lessonElementState.isSaved = true;

      if (setSubmitted) {
        lessonElementState.setSubmitted(setSubmitted);
        if (!lessonElementState.cachedResponseAnswer) {
          lessonElementState.setCachedResponse(utilsService.safeMobxClone(lessonElementState.currentResponseAnswer));
        }
        if (!this.submittedResponseList.includes(lessonElementId)) {
          this.submittedResponseList.push(lessonElementId);
        }
      }
    }
  }

  @action
  scoreResponse(model, lessonElementId) {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    if (!lessonElementState) {
      return;
    }

    const { questionBehavior } = lessonElementState;
    const { currentResponseAnswer } = lessonElementState;

    if (questionBehavior && currentResponseAnswer) {
      lessonElementState.scoreValue = questionBehavior.getScore(currentResponseAnswer, model);
      lessonElementState.isSubmitted = true;
      lessonElementState.isSaved = true;
      lessonElementState.isScored = true;
      const isCorrect = questionBehavior.isQuestionCorrect(lessonElementState, lessonElementId);

      if (lessonElementState.cachedCorrectState === null) {
        switch (isCorrect) {
        case QuestionFeedbackState.CORRECT: {
          this.addCorrectList(lessonElementId);
          break;
        }
        case QuestionFeedbackState.INCORRECT: {
          this.addIncorrectList(lessonElementId);
          break;
        }
        case QuestionFeedbackState.PARTIAL_CORRECT: {
          this.addPartiallyCorrectList(lessonElementId);
          break;
        }
        default: {
          break;
        }
        }
      }

      if (lessonElementState.cachedCorrectState === null) {
        lessonElementState.setCachedCorrectState(isCorrect);
      }
    }
  }

  @action
  addIncorrectList = (lessonElementId) => {
    const lessonState = this.getLessonElementState(lessonElementId);
    if (lessonState.isTestItem) {
      this.notScoredList.remove(lessonElementId);
      this.correctList.remove(lessonElementId);
      this.partiallyCorrectList.remove(lessonElementId);
      this.incorrectList.remove(lessonElementId);
      this.incorrectList.push(lessonElementId);
    }
  }

  @action
  addNotScoredList = (lessonElementId) => {
    const lessonState = this.getLessonElementState(lessonElementId);
    if (lessonState.isTestItem) {
      this.notScoredList.remove(lessonElementId);
      this.correctList.remove(lessonElementId);
      this.partiallyCorrectList.remove(lessonElementId);
      this.incorrectList.remove(lessonElementId);
      this.notScoredList.push(lessonElementId);
    }
  }

  @action
  addCorrectList = (lessonElementId) => {
    const lessonState = this.getLessonElementState(lessonElementId);
    if (lessonState.isTestItem) {
      this.notScoredList.remove(lessonElementId);
      this.correctList.remove(lessonElementId);
      this.partiallyCorrectList.remove(lessonElementId);
      this.incorrectList.remove(lessonElementId);
      this.correctList.push(lessonElementId);
    }
  }

  @action
  addPartiallyCorrectList = (lessonElementId) => {
    const lessonState = this.getLessonElementState(lessonElementId);
    if (lessonState.isTestItem) {
      this.notScoredList.remove(lessonElementId);
      this.correctList.remove(lessonElementId);
      this.partiallyCorrectList.remove(lessonElementId);
      this.incorrectList.remove(lessonElementId);
      this.partiallyCorrectList.push(lessonElementId);
    }
  }

  @action
  updateTeacherNote = (notes, lessonElementId) => {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    lessonElementState.setComment(notes);
  }

  @action
  async setResponseData(lessonElementId, data, model, { dataId, lessonElementState, oldDataId, ...rest } = {}) {
    lessonElementState = lessonElementState || this.lessonElementStates.get(lessonElementId);
    if (!lessonElementState) {
      return;
    }
    await lessonElementState.questionBehavior.setResponseData(
      data, lessonElementState.currentResponseAnswer, model, {
        dataId,
        lessonElementState,
        oldDataId,
        ...rest
      }
    );
  }

  @action
  async removeResponseData(lessonElementId, data, model, { dataId, lessonElementState, oldDataId, ...rest } = {}) {
    lessonElementState = lessonElementState || this.lessonElementStates.get(lessonElementId);
    if (!lessonElementState) {
      return;
    }
    await lessonElementState.questionBehavior.removeResponseData(
      data, lessonElementState.currentResponseAnswer, model, {
        dataId,
        lessonElementState,
        oldDataId,
        ...rest
      }
    );
  }

  @action setScore = (lessonElementId, model, score, subscore) => {
    const lessonElementState = this.getLessonElementState(lessonElementId);

    if (lessonElementState && score !== null && !model?.unscored) {
      lessonElementState.scoreValue = score;
      if(subscore) {
        lessonElementState.subscore = subscore;
      }
      const isCorrect = lessonElementState.questionBehavior.isQuestionCorrect(lessonElementState, lessonElementId);
      switch (isCorrect) {
      case QuestionFeedbackState.CORRECT: {
        this.addCorrectList(lessonElementId);
        break;
      }
      case QuestionFeedbackState.INCORRECT: {
        this.addIncorrectList(lessonElementId);
        break;
      }
      case QuestionFeedbackState.PARTIAL_CORRECT: {
        this.addPartiallyCorrectList(lessonElementId);
        break;
      }
      default: {
        break;
      }
      }
    }
  }

  @action
  createLessonElementState = (
    activityId, responseId, maxScore, lessonElementId,
    contentObjectId, responseAnswer, viewedTime, scoreNow,
    toolPersistence, isSaved, isSubmitted, isScored,
    scoreValue, scoreState, teacherNote, markedForReview,
    subscore, behavior, clearOld, isTestItem, submittedItemScore, submittedMaxScore, isSurvey, isUnScored
  ) => {
    let lessonElementState = null;

    if (clearOld && this.lessonElementStates.has(lessonElementId)) {
      this.lessonElementStates.delete(lessonElementId);
      lessonElementState = new LessonElementState();
    } else {
      lessonElementState = this.lessonElementStates.get(lessonElementId);
      if (!lessonElementState) {
        lessonElementState = new LessonElementState();
      }
    }

    lessonElementState.isSaved = isSaved;
    lessonElementState.responseId = responseId;
    lessonElementState.isSubmitted = isSubmitted;
    lessonElementState.isScored = isScored;
    lessonElementState.scoreValue = scoreValue;
    lessonElementState.maxScore = maxScore;
    lessonElementState.setCurrentResponse(responseAnswer);
    lessonElementState.questionBehavior = behavior;
    lessonElementState.comment = teacherNote;
    lessonElementState.markedForReview = markedForReview;
    lessonElementState.viewedTime = viewedTime;
    lessonElementState.isTestItem = isTestItem;
    lessonElementState.toolPersistence = toolPersistence;
    lessonElementState.submittedItemScore = submittedItemScore;
    lessonElementState.submittedMaxScore = submittedMaxScore;
    lessonElementState.isSurvey = isSurvey;
    lessonElementState.isUnScored = isUnScored;

    this.updateMarkedForReviewList(lessonElementState.markedForReview, lessonElementId);

    lessonElementState.scoreState = scoreState;
    if (utilsService.isNullOrEmpty(subscore)) {
      lessonElementState.subscore = null;
    } else {
      lessonElementState.subscore = subscore;
    }

    const serverResponse = {
      activityId,
      lessonElementId,
      contentObjectId,
      responseContent: JSON.stringify(responseAnswer),
      viewedTime,
      scoreNow,
      toolPersistence: JSON.stringify(toolPersistence),
      markedForReview
    };

    lessonElementState.serverResponse = serverResponse;

    // this will make the lessonElementState and child objects observable
    this.lessonElementStates.set(lessonElementId, lessonElementState);

    if (isUnScored) {
      this.unscoredList.remove(lessonElementId);
      this.unscoredList.push(lessonElementId);
    }

    if (!this.submittedResponseList.includes(lessonElementId) && (lessonElementState.isSubmitted)) {
      // separate if because question. added this to correct the number of what is considered submitted.
      // todo: why do we consider non-test items submitted?
      if (lessonElementState.isTestItem) {
        this.submittedResponseList.push(lessonElementId);
      }
    }
  }

  @action updateMarkedForReviewList = (markedForReview, lessonElementId) => {
    if (markedForReview && this.markedForReviewList && this.markedForReviewList.indexOf(lessonElementId) === -1) {
      this.markedForReviewList.push(lessonElementId);
    } else if (!markedForReview && this.markedForReviewList && this.markedForReviewList.indexOf(lessonElementId) >= 0) {
      this.markedForReviewList.remove(lessonElementId);
    }
  }

  @action
  setLessonItemDependents = (dependentId, controllerId) => {
    if (this.dependencyFlowMap.has(dependentId)) {
      const controllerArray = this.dependencyFlowMap.get(dependentId);
      controllerArray.push(controllerId);
      this.dependencyFlowMap.set(dependentId, controllerArray);
    } else {
      const newControllerArray = [controllerId];
      this.dependencyFlowMap.set(dependentId, newControllerArray);
    }
  }

  @action
  getUpdatedServerResponse = (
    lessonElementState, activityId, viewedTime, scoreNow,
    contentItemId, noSubmit = false, { model } = {}
  ) => {
    let responseAnswer = utilsService.safeMobxClone(lessonElementState.currentResponseAnswer);

    if (lessonElementState.cachedResponseAnswer && !noSubmit) {
      responseAnswer = utilsService.safeMobxClone(lessonElementState.cachedResponseAnswer);
    }

    lessonElementState.serverResponse.activityId = activityId;
    lessonElementState.serverResponse.responseContent = this.getServerResponseContentJsonStr({ model, responseAnswer });
    lessonElementState.serverResponse.viewedTime = viewedTime;
    lessonElementState.serverResponse.scoreNow = scoreNow;

    if (lessonElementState.toolPersistence) {
      lessonElementState.serverResponse.toolPersistence = JSON.stringify(lessonElementState.toolPersistence);
    } else {
      lessonElementState.serverResponse.toolPersistence = JSON.stringify({});
    }
    // This seems a bit odd, as we are changing state prior to this.
    lessonElementState.serverResponse.markedForReview = lessonElementState.markedForReview;
    lessonElementState.serverResponse.contentItemId = contentItemId;
    return toJS(lessonElementState.serverResponse);
  }

  getServerResponseContentJsonStr = ({
    model, responseAnswer
  } = {}) => {
    if (!responseAnswer?.userInputMap) {
      const responseContentJson = responseAnswer;
      return JSON.stringify(responseContentJson || {});
    } else if (responseAnswer?.userInputMap) {
      const { prompts, userInputMap, ...rest } = responseAnswer;
      const userInputArr = Array.from(userInputMap?.values?.() || []).map((data) => {
        let dataText = data.text || '';

        if (model.type === ContentType.CLOZE.type && model.responseFormat === 'text') {
          dataText = utilsService.stripTagsAndEntities(dataText);
        } else if (model.type === ContentType.CLOZE.type && model.responseFormat === 'numeric') {
          dataText = utilsService.stripNonNumeric(dataText);
        } else if (dataText?.includes?.('data-mathml') && (model.responseFormat !== 'math' && !model.mathText)) {
          const { stripZeroWidthChars } = utilsService;
          const stripPWrap = utilsService.stripWrappingParagraphTags;
          dataText = stripZeroWidthChars(stripPWrap(utilsService.findMathInput(dataText)));
        }

        return {
          id: data.dataId,
          text: dataText
        };
      });
      const responseContentJson = {
        ...rest,
        prompts: userInputArr
      };
      return JSON.stringify(responseContentJson);
    }
  }

  @action
  setResponseComment = (lessonElementId, comment) => {
    const state = this.getLessonElementState(lessonElementId);
    state.setComment(comment);
  }

  @action
  setResponseComments = (discussions) => {
    const lessonElementIds = this.lessonElementStates.keys();

    for (const lessonElementId of lessonElementIds) {
      const lessonElement = lessonManager.getLessonElement(lessonElementId);
      if (!lessonElement) {
        continue;
      }
      const model = lessonManager.contentMap[lessonElement.entityId];
      if (!model) {
        continue;
      }

      const lessonElementState = this.getLessonElementState(lessonElementId);
      if (lessonElementState) {
        const discussion = _.find(discussions, { entityId: lessonElementState.responseId });
        if (discussion && discussion.comment) {
          lessonElementState.setComment(discussion.comment);
        }

        if (model.isActivityPart) {
          const parentState = this.getLessonElementState(model.parentActivityElementId);
          if (parentState && discussion && discussion.comment) {
            parentState.setComment('Child has comment');  // this is just a placeholder to force
            // goto cards to show the teacher feedback flag when a child item has a comment
            // user should never see this comment.
          }
        }
      }
    }
  }

  @action
  setMarkedForReview = (lessonElementId, flag) => {
    const state = this.getLessonElementState(lessonElementId);
    if (state) {
      state.markedForReview = flag;
      this.updateMarkedForReviewList(state.markedForReview, lessonElementId);
    }
  }

  @computed get markedForReviewItemCount() {
    return (this.markedForReviewList) ? this.markedForReviewList.length : 0;
  }

  @computed get numberSubmittedResponses() {
    return this.submittedResponseList?.length || 0;
  }

  @computed get totalTestItemResponses() {
    const testItems = [];

    if (this.lessonElementStates) {
      this.lessonElementStates.forEach((item, key) => {
        if (item.isTestItem && (!item.isUnScored)) {
          if (testItems.indexOf(key) < 0) {
            testItems.push((key));
          }
        }
      });
    }

    return testItems.length;
  }

  @computed get notScoredItemCount() {
    return (this.notScoredList) ? this.notScoredList.length : 0;
  }

  @computed get notToBeScoredItemCount() {
    return (this.unscoredList) ? this.unscoredList.length : 0;
  }

  @computed get correctItemCount() {
    return (this.correctList) ? this.correctList.length : 0;
  }

  @computed get incorrectItemCount() {
    return (this.incorrectList) ? this.incorrectList.length : 0;
  }

  @computed get partiallyCorrectItemCount() {
    return (this.partiallyCorrectList) ? this.partiallyCorrectList.length : 0;
  }

  isAnswerCorrect = (lessonElementId, answerId, model, isPracticeRunning, { lessonElementState } = {}) => {
    if (lessonElementState || this.lessonElementStates.has(lessonElementId)) {
      lessonElementState = lessonElementState || this.lessonElementStates.get(lessonElementId);
      const behavior = lessonElementState.questionBehavior;
      if (behavior) {
        return behavior.isAnswerCorrect(answerId, lessonElementState, model, isPracticeRunning);
      }
    }
    return false;
  }

  isAutoScored = (lessonElementState, model) => {
    if (lessonElementState && model && lessonElementState.questionBehavior) {
      const behavior = lessonElementState.questionBehavior;
      if (behavior) {
        return behavior.isAutoScored(model);
      }
    }
    return false;
  }

  /**
   * @returns {0 | 1 | 2}
   *
   * ```
   * QuestionFeedbackState.INCORRECT       // 0, or
   * QuestionFeedbackState.CORRECT         // 1, or
   * QuestionFeedbackState.PARTIAL_CORRECT // 2
   * ```
   */
  isQuestionCorrect = (lessonElementId) => {
    if (this.lessonElementStates.has(lessonElementId)) {
      const lessonElementState = this.lessonElementStates.get(lessonElementId);
      const behavior = lessonElementState.questionBehavior;
      if (behavior) {
        return behavior.isQuestionCorrect(lessonElementState, lessonElementId);
      }
    }
    return QuestionFeedbackState.INCORRECT;
  }

  getQuestionBehavior = (lessonElementId) => {
    const lessonElementState = this.lessonElementStates.get(lessonElementId);
    if (lessonElementState) {
      return lessonElementState.questionBehavior;
    }
    return null;
  }

  getLessonElementState = (lessonElementId) => {
    return this.lessonElementStates.get(lessonElementId);
  }

  checkForValidResponse = (lessonElementId, model) => {
    const behavior = this.getQuestionBehavior(lessonElementId);
    if (behavior) {
      return behavior.checkForValidResponse(this.getCurrentResponseAnswer(lessonElementId), model);
    }
    return false;
  }

  @action
  setEngagementData = (lessonElementId, data) => {
    if (this.engagementMap.has(lessonElementId)) {
      this.engagementMap.delete(lessonElementId);
    }
    const engagementData = new EngagementData();
    engagementData.setEngagmentData(data);
    this.engagementMap.set(lessonElementId, engagementData);
  }

  @action
  setClassEngagementReport = (data) => {
    if (this.classroomSummaryReport === null) {
      this.classroomSummaryReport = new EngagementData();
    }
    this.classroomSummaryReport.setEngagmentData(data);
  }

  @action
  setStudentReviewEngagementReport = (data) => {
    if (this.studentReviewEngagementReport === null) {
      this.studentReviewEngagementReport = new EngagementData();
    }
    this.studentReviewEngagementReport.setEngagmentData(data);
  }

  @action
  clearResponses = () => {
    this.lessonElementStates.clear();
  }
}

export default new ResponseManager();
