import { fabric } from 'fabric';

export default class CanvasOrganizerService {
  CANVAS_HEIGHT = 600;
  CANVAS_WIDTH = 600;
  LINE_STROKE_THINNER = 0.5;
  LINE_STROKE_THIN = 1;
  LINE_STROKE = 2;
  LINE_STROKE_MED = 3;
  LINE_STROKE_HEAVY = 4;
  CORNER_RADIUS = 5;

  STROKE = '#666';
  FILL = '#fff';
  OPACITY = 1;

  UP = 'UP';
  DOWN = 'DOWN';
  LEFT = 'LEFT';
  RIGHT = 'RIGHT';

  canvas;

  constructor(model) {
    this.model = model;
  }

  setCanvas(canvas) {
    this.canvas = canvas;
  }

  createCanvas = () => {
  //   // this.$getCanvas(true).attr('width', this.$getBody().width());
  //   // this.$getCanvas(true).attr('height', this.$getBody().height());
  //   this.canvas = new fabric.Canvas(this.getCanvasId(true));
  }

  clearCanvas = () => {
    this.canvas.clear().renderAll();
    // this.$getTextInputContainer().find(".canvasTextInput").remove();
  }

  drawOffsetArrowBetweenRectangles = (rect1, rect2, offset) => {
    const x1 = rect1.left + rect1.width;
    const y1 = rect1.top + (rect1.height / 2);
    const x2 = rect2.left;
    const y2 = rect2.top + (rect2.height / 2) + offset;
    this.drawArrow(x1, y1, x2, y2);
  }

  drawDoubleArrow = (x1, y1, x2, y2, settings) => {
    settings = settings || {};

    if (typeof settings.perPixelTargetFind === 'undefined') {
      settings.perPixelTargetFind = false;
    }
    settings.strokeWidth = settings.strokeWidth || this.LINE_STROKE;
    settings.stroke = settings.stroke || 'black';

    const lineDoubleArrow = new fabric.LineDoubleArrow([x1, y1, x2, y2], settings);

    lineDoubleArrow.lockMovementX = true;
    lineDoubleArrow.lockMovementY = true;
    lineDoubleArrow.selectable = false;

    this.canvas.add(lineDoubleArrow);
    return lineDoubleArrow;
  }

  drawArrow = (x1, y1, x2, y2, settings) => {
    settings = settings || {};

    if (typeof settings.perPixelTargetFind === 'undefined') {
      settings.perPixelTargetFind = false;
    }

    const lineArrow = new fabric.LineArrow([x1, y1, x2, y2], {
      strokeWidth: this.LINE_STROKE,
      stroke: settings.stroke || 'black',
      strokeDashArray: settings.strokeDashArray || null,
      perPixelTargetFind: settings.perPixelTargetFind
    });

    lineArrow.lockMovementX = true;
    lineArrow.lockMovementY = true;
    lineArrow.selectable = false;

    this.canvas.add(lineArrow);
    return lineArrow;
  }

  drawText = (txt, settings) => {
    settings = this.inactiveObjectSettings(settings);
    if (!settings) {
      return '';
    }
    settings.fontFamily = settings.fontFamily || 'Helvetica';

    const text = new fabric.Text(txt, settings);
    return this.canvas.add(text);
  }

  fixObjectSettings = (settings) => {
    settings = settings || {};

    if (settings.inactive) {
      settings.hasControls = false;
      settings.lockMovementX = true;
      settings.lockMovementY = true;
      settings.selectable = false;
    }
    return settings;
  }

  inactiveObjectSettings = (settings) => {
    settings = settings || {};
    settings.inactive = true;
    return this.fixObjectSettings(settings);
  }

  drawRect = (left, top, width, height, settings) => {
    settings = this.fixObjectSettings(settings);
    settings.type = 'rectangle';
    settings.left = left;
    settings.top = top;
    settings.width = width;
    settings.height = height;
    settings.strokeWidth = settings.strokeWidth || this.LINE_STROKE;
    settings.stroke = settings.stroke || 'black';
    settings.fill = settings.fill || 'white';
    settings.rx = settings.rx || this.canvasOrganizer.CORNER_RADIUS;
    settings.ry = settings.ry || this.canvasOrganizer.CORNER_RADIUS;

    // create a rectangle object
    const rect = new fabric.Rect(settings);

    this.canvas.add(rect);
    return rect;
  }

  drawRhombus = function (left, top, width, height) {
    const rhombusShape = [{
      x: 0,
      y: height / 2
    },
    {
      x: width / 2,
      y: height
    },
    {
      x: width,
      y: height / 2
    },
    {
      x: width / 2,
      y: 0
    }];

    const rhombus = new fabric.Polygon(rhombusShape, {
      type: 'rhombus',
      top,
      left,
      fill: 'white',
      stroke: 'black',
      strokeWidth: this.LINE_STROKE
    });

    rhombus.lockMovementX = true;
    rhombus.lockMovementY = true;
    rhombus.selectable = false;

    this.canvas.add(rhombus);

    return rhombus;
  }

  drawRectangle = function (left, top, width, height, lineStroke, strokeColor, strokeFill, radiusX, radiusY) {
    if (!lineStroke) {
      lineStroke = this.LINE_STROKE;
    }

    radiusX = radiusX || this.CORNER_RADIUS;
    radiusY = radiusY || this.CORNER_RADIUS;

    strokeColor = strokeColor || 'black';
    strokeFill = strokeFill || 'white';

    // create a rectangle object
    const rect = new fabric.Rect({
      type: 'rectangle',
      left,
      top,
      fill: strokeFill,
      stroke: strokeColor,
      strokeWidth: lineStroke,
      width,
      height,
      rx: radiusX,
      ry: radiusY
    });

    rect.hasControls = false;
    rect.hasBorders = false;

    // "add" rectangle onto canvas
    this.canvas.add(rect);
    return rect;
  }

  drawLineFromShape = function (shape, length, direction) {
    let x1 = 0;
    let x2 = 0;
    let y1 = 0;
    let y2 = 0;

    if (direction === this.UP) {
      x1 = shape.left + shape.width / 2;
      x2 = shape.left + shape.width / 2;
      y1 = shape.top;
      y2 = shape.top - length;
    } else if (direction === this.DOWN) {
      x1 = shape.left + shape.width / 2;
      x2 = shape.left + shape.width / 2;
      y1 = shape.top + shape.height;
      y2 = y1 + length;
    } else if (direction === this.LEFT) {
      x1 = shape.left;
      x2 = shape.left - length;
      y1 = shape.top + shape.height / 2;
      y2 = shape.top + shape.height / 2;
    } else if (direction === this.RIGHT) {
      x1 = shape.left + shape.width;
      x2 = x1 + length;
      y1 = shape.top + shape.height / 2;
      y2 = shape.top + shape.height / 2;
    }

    this.drawLine(x1, y1, x2, y2);
  }

  drawLine = (x1, y1, x2, y2, settings) => {
    settings = settings || {};
    settings.strokeWidth = settings.strokeWidth || this.LINE_STROKE;
    settings.stroke = settings.stroke || 'black';
    settings.hasControls = false;
    settings.hasBorders = false;
    settings.lockMovementX = true;
    settings.lockMovementY = true;

    if (typeof settings.perPixelTargetFind === 'undefined') {
      settings.perPixelTargetFind = false;
    }

    const line = new fabric.Line([x1, y1, x2, y2], settings);

    this.canvas.add(line);

    return line;
  }

  drawCircle = (left, top, radius, hasInput, noFill, fill, stroke, strokeWidth, opacity) => {
    fill = fill || this.FILL;
    stroke = stroke || this.STROKE;
    strokeWidth = strokeWidth || this.LINE_STROKE;
    opacity = opacity || this.OPACITY;

    if (noFill) {
      fill = 'rgba(0,0,0,0)';
    }
    const circle = new fabric.Circle({
      fill,
      left,
      opacity,
      radius,
      stroke,
      strokeWidth,
      top,
      type: 'circle'
    });

    circle.hasInput = hasInput;
    circle.hasControls = false;
    circle.hasBorders = false;
    circle.lockMovementX = true;
    circle.lockMovementY = true;
    circle.selectable = false;

    this.canvas.add(circle);

    return circle;
  }

  // drawArrowHead doesn't work in the port to react so triangles are added for the arrowheads in the drawArrow method.
  createArrowClass = () => {
    fabric.LineArrow = fabric.util.createClass(fabric.Line, {

      type: 'lineArrow',

      initialize(element, options) {
        options || (options = {});

        this.callSuper('initialize', element, options);
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'));
      },

      drawArrowHead(ctx, x, y, rot) {
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(rot);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-5, -12);
        ctx.lineTo(5, -12);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
      },

      _render(ctx) {
        this.callSuper('_render', ctx);

        // do not render if width/height are zeros or object is not visible
        // if (this.width === 0 || this.height === 0 || !this.visible) return;
        // Width/height are 0 if ray is perfectly vertical/horizontal so checks were remooved.
        // In html_publisher, width/height are always > 1 and the checks were fine.  I have no idea what's different.
        if (!this.visible) return;

        // var xDiff = this.x2 - this.x1;
        // var yDiff = this.y2 - this.y1;
        const rot = -Math.atan2(this.x1 - this.x2, this.y1 - this.y2);

        this.drawArrowHead(ctx, (this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2, rot + Math.PI);
        //			    this.drawArrowHead(ctx, (this.x1 - this.x2) / 2, (this.y1 - this.y2) / 2, rot);
      }
    });

    fabric.LineArrow.fromObject = function (object, callback) {
      return new fabric.LineArrow([object.x1, object.y1, object.x2, object.y2], object);
      //			    callback && callback(new fabric.LineArrow([object.x1, object.y1, object.x2, object.y2],object));
    };
    fabric.LineArrow.async = true;
  }

  // drawArrowHead doesn't work in the port to react so triangles are added for the arrowheads in the drawDoubleArrow method.
  createLineDoubleArrowClass = () => {
    fabric.LineDoubleArrow = fabric.util.createClass(fabric.Line, {

      type: 'lineDoubleArrow',

      initialize(element, options) {
        options || (options = {});

        this.callSuper('initialize', element, options);
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'));
      },

      drawArrowHead(ctx, x, y, rot) {
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(rot);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-5, -12);
        ctx.lineTo(5, -12);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
      },

      _render(ctx) {
        this.callSuper('_render', ctx);

        // do not render if width/height are zeros or object is not visible
        // if (this.width === 0 || this.height === 0 || !this.visible) return;
        // Width/height are 0 if ray is perfectly vertical/horizontal so checks were remooved.
        // In html_publisher, width/height are always > 1 and the checks were fine.  I have no idea what's different.
        if (!this.visible) return;

        // var xDiff = this.x2 - this.x1;
        // var yDiff = this.y2 - this.y1;
        const rot = -Math.atan2(this.x1 - this.x2, this.y1 - this.y2);

        this.drawArrowHead(ctx, (this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2, rot + Math.PI);
        this.drawArrowHead(ctx, (this.x1 - this.x2) / 2, (this.y1 - this.y2) / 2, rot);
      }
    });

    fabric.LineDoubleArrow.fromObject = function (object, callback) {
      return new fabric.LineDoubleArrow([object.x1, object.y1, object.x2, object.y2], object);
    };

    fabric.LineDoubleArrow.async = true;
  }
}
