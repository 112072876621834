import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../css/BuilderLayout.scss';

import { DragOverlay, useDroppable } from '@dnd-kit/core';

import ItemBankCard from './ItemBankCard';
import itemBankService from '../services/ItemBankService';
import DeleteItemFromAssessmentButton from './DeleteItemFromAssessmentButton';
import MoveItemUpButton from './MoveItemUpButton';
import MoveItemDownButton from './MoveItemDownButton';
import Loader from '../../lessonPlayer/components/Loader';
import GenericProgressBar from '../../lessonPlayer/components/GenericProgressBar';

import { register } from '../../i18n';

const t = register('AssessmentItemList');

const Droppable = (props) => {
  const { id, data, children } = props;
  const { isOver, setNodeRef } = useDroppable({
    id,
    data
  });

  const style = {
    backgroundColor: isOver ? '#d2e9f7' : '',
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

// const Draggable = (props) => {
//   const { id, children, disabled } = props;
//   const { attributes, listeners, setNodeRef, transform } = useDraggable({
//     id,
//     disabled,
//   });

//   const style = {
//     transform: CSS.Translate.toString(transform),
//     padding: 0,
//     margin: '0',
//     fontFamily: 'Facit-Regular',
//     lineHeight: 1.4,
//   };

//   return (
//     <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
//       {children}
//     </div>
//   );
// };

const AssessmentItemList = observer((props) => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);
  const { Draggable, dragging, refreshCallback, useOverlay, useLessonOverlay } = props;

  const items = itemBankManager.lessonParentItemsArray;

  const readOnly = false;

  const moveItemUp = async (itemId) => {
    await itemBankService.moveItemUp(itemId);
    if (refreshCallback) {
      refreshCallback();
    }
  };

  const moveItemDown = async (itemId) => {
    await itemBankService.moveItemDown(itemId);
    if (refreshCallback) {
      refreshCallback();
    }
  };

  return (
    <div className='assessment-items'>
      {
        itemBankManager.contentItemsLoadingProgress && <GenericProgressBar />
      }
      {
        itemBankManager.contentItemsLoading && <Loader />
      }
      {((itemBankManager.lessonItems.size <= 0) && (!itemBankManager.contentItemsLoading)) && (
        <div key='firstItem_iD_el' className='empty-list'>

          <ItemDropZone key='firstItem_iDz' disabled={readOnly} dragging={dragging} zoneIndex={0} />
          <div className='empty-list-statement'>No items to display</div>
        </div>
      )}
      {
        (!itemBankManager.contentItemsLoading && items && items.length > 0) &&
          // eslint-disable-next-line array-callback-return
          items.map((item, index) => {
            const { libraryResource } = item;
            const zoneIndex = index;
            const lastZoneIndex = items.length; // this zone gets an incremented index for add, but on move we will subtract that later
            if (!item.placeHolder && libraryResource.showInStore) {
              const first = (item.orderNum === 0);
              const last = items.length === 1 ? true : (item.orderNum === (items.length - 1));
              item.isInLesson = true;
              const isActivityType = itemBankManager.isActivityType(item.libraryResource.entitySubTypeId);
              const isTestItem = (item.libraryResource.entityTypeId === 'test_item');
              const containsGroup = itemBankManager.elementHasGroupArray.includes(item.lessonElementId);

              return (
                <>
                  <ItemDropZone key={`${item.id}_iDz`} disabled={readOnly} dragging={dragging} last={last}
                    zoneIndex={zoneIndex} />
                  <div key={`${item.id}_ibCL`} className='item-bank-card-controller lesson-list'>
                    <div key={`${item.id}_ibn`} className='item-list-number'>{`${index + 1}`}</div>
                    {/* Drag overlay definition for itembankitems rendered in ResourceBucketItemList */}
                    {(useLessonOverlay && !useOverlay) && (
                      <DragOverlay className='al-drag-overlay'>
                        {(dragging && dragging.isDragging && dragging.item && dragging.item.id === item.id) ? (
                          <div className='item-drag-container'>
                            <ItemBankCard key={`${item.id}_ibc`} libraryCardParams={item} refreshCallback={refreshCallback} skipAddItems={!isActivityType || isTestItem || containsGroup}
                              skipRemove={true} />
                          </div>
                        ) :
                          null}
                      </DragOverlay>
                    )}
                    {(!dragging || !dragging.isDragging || dragging.item.id !== item.id) ? (
                      <>
                        <Draggable key={`${item.id}_drg`} data={item} disabled={false} id={item.id}
                          index={index}>
                          <div key={`${item.id}_dc`} className='item-draggable-container'>
                            <ItemBankCard key={`${item.id}_ibc`} libraryCardParams={item} refreshCallback={refreshCallback} skipAddItems={!isActivityType || isTestItem || containsGroup}
                              skipRemove={true} />
                          </div>
                        </Draggable>
                        <DeleteItemFromAssessmentButton key={`${item.id}_delb`} itemId={item.id} refreshCallback={refreshCallback} setErrorMessage={props.setErrorMessage} />
                        <div key={`${item.id}_mbw`} className='move-button-wrapper'>
                          {(!first) && <MoveItemUpButton key={`${item.id}_ub`} callback={moveItemUp} itemId={item.id} />}
                          {(!last) && <MoveItemDownButton key={`${item.id}_db`} callback={moveItemDown} itemId={item.id} />}
                        </div>
                      </>
                    ) : null}
                  </div>
                  {last && (

                    <ItemDropZone key={`${item.id}_iDlz`} disabled={readOnly} dragging={dragging} last={last}
                      zoneIndex={lastZoneIndex} />
                  )}
                </>
              );
            }
          })
      }
    </div>
  );
});

export default AssessmentItemList;

const ItemDropZone = ({ dragging, zoneIndex, last = false }) => {
  const opacity = (dragging && dragging.isDragging) ? 1 : 0;
  return (
    <Droppable
      key={`${zoneIndex}_Droppable`}
      data={last}
      direction='horizontal'
      id={zoneIndex.toString()}>
      <div key={`${zoneIndex}_item-drop-zone`} className='item-drop-zone'>
        <div key={`${zoneIndex}_item-drop-container`} className='item-drop-container' style={{ opacity }}>
          {/* <AnswerBox answer={answer.answer} answerId={answer.answerId} dimensions={answer.dimensions} textStyle={answer.textStyle} /> */}
          <div key={`${zoneIndex}_item-drop-target`} className='item-drop-target'>{t('dropTargetInstructions')}</div>
        </div>
      </div>
    </Droppable>
  );
};
