import parse from 'html-react-parser';

import classNames from 'classnames';
import UtilsService from './UtilsService';
import clozeMathService from './ClozeMathService';
import htmlService from './HtmlService';

export default class ClozeService {
  static renderClozeBody = (props = {}) => {
    const {
      lessonElementId,
      lessonElementState,
      modalBody,
      modalLeft,
      modalTop,
      model,
      renderBlankSection,
      setModalBody,
      setModalLeft,
      setModalTop, noSubmit
    } = props;

    /**
     * Intended for rendering body of `ClozeMath` questions **only**.
     *
     * Other `Cloze` question types should use `ClozeService.renderClozeBody`
     */
    const renderClozeMathBody = (parsedClozeBody) => {
      return clozeMathService.renderClozeMathBody({
        lessonElementId,
        lessonElementState,
        modalBody,
        modalLeft,
        modalTop,
        model,
        parsedClozeBody,
        setModalBody,
        setModalLeft,
        setModalTop
      });
    };

    /** Intended for rendering body of all `Cloze` question types **except** `ClozeMath` */
    const renderTypicalClozeBody = (parsedClozeBody) => {
      return parsedClozeBody;
      // TODO remove
      // const isMultipartActivity = questionService.isMultipartActivity();
      // if (!isMultipartActivity) {
      //   return parsedClozeBody;
      // } else {
      //   // TODO there is an issue where Wiris is not rendering for multipart activities
      //   // TODO (likely due to conflicting with MathJax)
      //   // TODO so for now, we are wrapping multipart activities in MathJaxContext to use it
      //   // TODO as a fallback for Wiris
      //   // return (
      //   //   <MathJaxContext config={{ chtml: { scale: 1.5 }, options: { enableMenu: false } }}
      //   //     hideUntilTypeset='every'>
      //   //     <MathJax dynamic hideUntilTypeset='every' inline>
      //   //       {parsedClozeBody}
      //   //     </MathJax>
      //   //   </MathJaxContext>
      //   // );
      //   return parsedClozeBody;
      // }
    };

    const mathText = (model && !UtilsService.isNullOrEmpty(model.mathText)) ? model.mathText : '';
    const polyfilledClozeBody = htmlService.getPolyfilledHtmlString((model?.body || mathText || ''));

    const parsedClozeBody = parse((polyfilledClozeBody || ''), {
      replace: (domNode) => {
        // note: react inline styles and html-react-parser do not support '!important'
        // as a result, style keys having a value including '!important' will not be rendered to the DOM at all
        // so here we remove occurrences of '!important' within the style string to allow all styles to at least be included in the DOM
        if (domNode?.attribs?.style?.includes?.('!important')) {
          domNode.attribs.style = domNode.attribs.style.replace(/!important/g, '');
        }

        // append custom className for 'table' tag names
        // i.e. <table> will get a `.custom-table` sibling className
        if (domNode.name === 'table') {
          domNode.attribs = {
            ...domNode.attribs,
            class: classNames(domNode.attribs.class, `custom-${domNode.name}`)
          };
        }
        if (domNode.attribs?.['data-id']) {
          return renderBlankSection(domNode, { lessonElementId, noSubmit });
        } else if (domNode.name === 'p') {
          let pClassNames = domNode.attribs.class;
          pClassNames = classNames(pClassNames, 'paragraph-wrapper');
          // domNode.name = 'p'; // this causes dom errors, as there is a chance `paragraph-wrapper` will have `div` children, but `div` is not supposed to be a descendant of `p`
          domNode.name = 'section';
          domNode.attribs = {
            ...domNode.attribs,
            class: pClassNames
          };
          return domNode;
        } else if ((model && !UtilsService.isNullOrEmpty(model.mathText)) && domNode.name === 'mspace') {
          if (domNode.attribs?.linebreak === 'newline') {
            domNode.attribs.class = classNames(
              domNode.attribs.class, 'mspace-newline'
            );
          }
        } else if ((model && !UtilsService.isNullOrEmpty(model.mathText)) && domNode.name === 'menclose') {
          const dataId = domNode.children?.find((child) => {
            // if applicable, get dataId of current 'menclose' node from within its children & apply it as a 'data-id' attribute for itself
            if (child.name !== 'mn') {
              return false;
            } else {
              const potentialDataId = child.children?.[0]?.data;
              if (potentialDataId) {
                const isValidDataIdExistingInPrompts = !model?.prompts || !!model.prompts.some?.((prompt) => {
                  return prompt.blankId === potentialDataId;
                });
                return isValidDataIdExistingInPrompts;
              } else {
                return false;
              }
            }
          })?.children?.[0]?.data;

          domNode.attribs = {
            ...domNode.attribs || {},
            class: classNames(/* domNode.attribs?.class, */'cloze-math-input'),
            ...(dataId ? {
              'data-id': dataId,
              'children': [],
              'notation': 'none'
            } : {
              notation: domNode.attribs?.notation || 'none'
            })
          };
          return renderBlankSection(domNode);
        } else if (!domNode.name && domNode?.parent?.attribs?.class?.includes('paragraph-wrapper')) {
          if (typeof domNode.data !== 'string' || domNode.data.trim()) {
            return <span>{domNode.data}</span>;
          }
        }
      }
    });

    return (
      <div className={classNames('cloze-body', {
        'cloze-math-body': !!(model && !UtilsService.isNullOrEmpty(model.mathText))
      })}>
        {(model && !UtilsService.isNullOrEmpty(model.mathText)) ?
          renderClozeMathBody(parsedClozeBody) : renderTypicalClozeBody(parsedClozeBody)}
      </div>
    );
  }
}
