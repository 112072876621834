import React from 'react';
import UtilsService from '../../../services/UtilsService';
import WidgetService, { Resource_Types/* , Widgets */ } from '../../../services/WidgetService';

const getEmptyBoxModel = () => {
  return { id: UtilsService.createGuid(), childIds: [], direction: 'column', justify: 'flex-start', align: 'flex-start' };
};

const getStyle = (box, boxDirection) => {
  const styleAtt = {};

  if (box.backgroundColor) {
    styleAtt.backgroundColor = box.backgroundColor;
  }
  styleAtt.flexDirection = box.direction;

  if (boxDirection === 'row') {
    if (box.justify) {
      styleAtt.justifyContent = box.justify;
    }
    if (box.align) {
      styleAtt.alignItems = box.align;
    }
  } else if (boxDirection === 'column') {
    if (box.justify) {
      styleAtt.justifyContent = box.align;
    }
    if (box.align) {
      styleAtt.alignItems = box.justify;
    }
  }
  styleAtt.borderTopWidth = `${box.borderTopWidth}px`;
  styleAtt.borderRightWidth = `${box.borderRightWidth}px`;
  styleAtt.borderBottomWidth = `${box.borderBottomWidth}px`;
  styleAtt.borderLeftWidth = `${box.borderLeftWidth}px`;
  return styleAtt;
};

const DownloadList = (props) => {
  const { model } = props;

  model.children = model.children || [];

  model.box = model.box || getEmptyBoxModel();

  const height = model.height || '';
  let hasHeight = false;
  if (height) {
    hasHeight = true;
  }
  const boxPadding = model.layout === 'absolute' ? '0' : model.boxPadding;
  const backgroundColor = '';
  const { box } = model;
  const boxDirection = box.direction || 'column';
  const stylAtt = getStyle(box, boxDirection);
  const display = (model.display) ? model.display : 'visible';

  stylAtt.padding = `${boxPadding}px`;
  box.imageResourceId = box.imageResourceId || '';
  return (
    <div id={model.id} {...props} className={`widget widgetGrid widgetContent download-list ${display}`} data-has-height={hasHeight}
      data-id={model.id} data-layout={model.layout} style={{ gridTemplateColumns: model.gridTemplateColumns, backgroundColor, height: `${height}px` }}>
      <div className='widgetBox' data-id={box.id} data-image-resource-id={box.imageResourceId} style={stylAtt}>

        {/* eslint-disable-next-line array-callback-return */}
        {box.childIds.map((childId, childIndex) => {
          const child = WidgetService.getChild(model, childId);
          if (child) {
            const type = {};
            if (Resource_Types.indexOf(child.type) > -1) {
              type.type = 'Resource';
            } else {
              type.type = child.type;
            }
            const Widget = WidgetService.getWidgetComponent(type);
            if (Widget) {
              return (<Widget key={child.id} model={child} />);
            } else (<></>);
          } else {
            return (<></>);
          }
        })}

      </div>
    </div>
  );
};

export default DownloadList;
