import React from 'react';
import DOK from '../DOK';
import QuestionDependency from '../QuestionDependency';
import Auth from '../../lessonPlayer/services/AuthService';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const Draw = ({ model, isManuscriptShort }) => {
  const imgSrc = `${Auth.ecms}/api/redirectToResource?authKey=${Auth.authKey}&resourceFolder=userFiles&resourceFileName=${model.imageSource}`;

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Free-Draw</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Directions:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.directions} /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Image Title:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.imageTitle} /></div>
      </div>
      <div className='draw-container'>
        <img alt={model.altText} className='max-img-size' src={imgSrc} />
      </div>
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Student Answer Format:</div>
            <div>{model.responseFormat}</div>
          </div>
          <div className='label-value-row'>
            <div>Student Answer Toolbar:</div>
            <div>{model.toolbarType}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
        </>
      )}
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default Draw;
