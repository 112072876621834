import React, { useContext, useRef } from 'react';
import { MobXProviderContext } from 'mobx-react';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import Auth from '../../services/AuthService';
import '../../../css/WebPage.scss';
import blankThumb from '../../../images/content-object-icons/bookmark-blank-thumbnail.png';
import useStyleEvents from '../../../hooks/useStyleEvents';

const WebPage = ({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);

  // C2C-5450: Back and Forward buttons should be hidden
  // const backRef = useRef();
  // const forwardRef = useRef();

  // useAccessibilityClick(backRef, (e) => {
  //   const element = document.getElementById(`${lessonElementId}-webpage-iframe`);
  //   if ( element ) {
  //     window.history.back();
  //   }

  // });

  // useAccessibilityClick(forwardRef, (e) => {
  //   const element = document.getElementById(`${lessonElementId}-webpage-iframe`);
  //   if ( element ) {
  //     window.history.forward();
  //   }

  // });

  useStyleEvents(lessonElementId);

  return (
    <div className='webpage-activity-question'>
      <div className='webpage-directions'><HtmlComponent htmlStr={model.text} /></div>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      {(model.display === 'iframe') && (
        <>
          {/* <div className={'web-buttons'}>
            <button className={'primary'} ref={backRef} >Back</button><button ref={forwardRef} className={'primary'}>Forward</button>
          </div> */}
          <div className='test-item-question lesson-element webpage-question iframe' id={`${lessonElementId}-webpage-view`}>
            <iframe key={lessonElementId} allowFullScreen={true} id={`${lessonElementId}-webpage-iframe`} src={model.url}
              title={model.title} />
          </div>
        </>
      )}
      {(model.display === 'link') && (
        <div className='test-item-question lesson-element webpage-question link' id={`${lessonElementId}-webpage-view`}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={model.url} target='_blank'>{model.url}</a>
        </div>
      )}
      {(model.display === 'thumb' && model.imageUrl) && (
        <div className='test-item-question lesson-element webpage-question thumb' id={`${lessonElementId}-webpage-view`}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={model.url} target='_blank'><img alt='Click to view link' src={Auth.getResourceUrlByFileName(model.imageUrl)} /></a>
        </div>
      )}
      {(model.display === 'thumb' && !model.imageUrl) && (
        <div className='test-item-question lesson-element webpage-question thumb' id={`${lessonElementId}-webpage-view`}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={model.url} target='_blank'><img alt='Click to view link' src={blankThumb} /></a>
        </div>
      )}
    </div>
  );
};
export default WebPage;
