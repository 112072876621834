import { ContentType, LessonMode, QuestionFeedbackState, ScoreState } from '../../Constants';
import { register } from '../../i18n';
import { confirmDialog, dependentAlert } from '../components/dialogs';

import audioManager from '../managers/AudioManager';
import lessonManager from '../managers/LessonManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import responseManager from '../managers/ResponseManager';
import studentActivityManager from '../managers/StudentActivityManager';
import timeManager from '../managers/TimeManager';
import toolbarManager from '../managers/ToolbarManager';
import userManager from '../managers/UserManager';

import { QFMFlagKeys } from '../managers/obseverableObjects/QuestionFeedbackMode';

import Auth from './AuthService';
// eslint-disable-next-line import/no-cycle
import questionService from './QuestionService';
import scoringService from './ScoringService';
// eslint-disable-next-line import/no-cycle
import studentActivityService from './StudentActivityService';
import UtilsService from './UtilsService';

import createQuestionBehavior from '../questionBehavior/BehaviorFactory';

const t = register('GlobalQuestionLabels');

class ResponseService {
  loadSlide(lessonElementId, mode) {
    const model = lessonManager.getSlideModel(lessonElementId);
    const state = responseManager.getLessonElementState(lessonElementId);
    const { noSubmit } = questionFeedbackManager;

    if (!model) {
      return;
    }
    responseManager.setReadOnly(lessonElementId, (state && state.isSubmitted && !noSubmit));

    if (model && (state || model.isStudentInstruction) && model.isActivityPart) {
      const parentElement = lessonManager.getLessonElement(model.parentActivityElementId);
      if (parentElement && parentElement.lessonElementIds && parentElement.lessonElementIds.length > 0) {
        parentElement.lessonElementIds.forEach((leid) => {
          if (leid !== lessonElementId) {
            const childModel = lessonManager.getSlideModel(leid);
            const childState = responseManager.getLessonElementState(leid);
            this.doFeedbackLogic(leid, childModel, mode, childState);
          }
        });
      }
    }
    this.doFeedbackLogic(lessonElementId, model, mode, state);
  }

  async submitButtonHandler(event, lessonElementId) {
    questionFeedbackManager.setSubmitVisibleState(lessonElementId, false);
    questionFeedbackManager.setLoadingButtonState(lessonElementId, true);
    const mode = lessonManager.playerMode;
    const model = lessonManager.getSlideModel(lessonElementId);

    const state = responseManager.getLessonElementState(lessonElementId);
    const currentResponse = state.currentResponseAnswer;

    const responseIsOk = responseManager.checkForValidResponse(lessonElementId, model);
    if (responseIsOk === null) {
      questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);
      questionFeedbackManager.setLoadingButtonState(lessonElementId, false);

      throw new Error('checkForValidResponse returned null');
    }
    // responseIsOk can be a boolean or it can be an object with keys 'isValid', 'title', 'text', 'allowSubmit'
    // If it is a boolean, default values are used

    const isValid = responseIsOk === true || (responseIsOk && responseIsOk.isValid);
    if (!isValid && !state.isSubmitted && !model.unscored) {
      let dialogObject;
      // The user can submit if it is a boolean or if allowSubmit has not been specified or is true
      // This change was made per CF-2841. There is no distinction in the message between singular or multi.
      const canSubmit = typeof responseIsOk === 'boolean' || !('allowSubmit' in responseIsOk) || responseIsOk.allowSubmit;
      if (currentResponse) {
        dialogObject = {
          title: `${t('notSubmittedBody')}`,
          text: `${t('cannotBe')}`,
        };
      }

      if (typeof responseIsOk === 'object') {
        // Then we should overwrite the dialogObject with the values from the responseIsOk object
        dialogObject = {
          ...dialogObject,
          ...responseIsOk
        };
        // But we also have to handle the special case where the user is not allowed to submit
        if (!canSubmit) {
          dialogObject.showCancelButton = false;
          dialogObject.confirmButtonText = `${t('ok')}`;
        }
      }
      const dialogResult = await confirmDialog(dialogObject);
      if (dialogResult.isDismissed || !canSubmit) {
        questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);
        questionFeedbackManager.setLoadingButtonState(lessonElementId, false);
        return;
      }
    }
    if (model.unscored && !isValid) {
      questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);
      questionFeedbackManager.setLoadingButtonState(lessonElementId, false);
      return;
    }

    const succes = await this.handleSubmitResponse(lessonElementId);

    if (!succes) {
      questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);
      questionFeedbackManager.setLoadingButtonState(lessonElementId, false);
      return;
    }

    const isAutoScored = state.questionBehavior.isAutoScored(model);
    const { hasFeedback } = questionFeedbackManager;
    if (isAutoScored && hasFeedback && ((mode === LessonMode.PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) || mode === LessonMode.ACTIVITY_MODE)) {
      this.scoreResponse(lessonElementId);
      const isQuestionCorrect = responseManager.isQuestionCorrect(lessonElementId);
      let incorrectImageUrl = null;
      let correctImageUrl = null;
      let currentImage = null;

      if (model && model.imageFeedback && model.imageFeedback.length > 0) {
        model.imageFeedback.forEach((f) => {
          if (f.type === 'correct') {
            correctImageUrl = `url(${Auth.getResourceUrlByResourceId(f.resourceId)})`;
          }
          if (f.type === 'incorrect') {
            incorrectImageUrl = `url(${Auth.getResourceUrlByResourceId(f.resourceId)})`;
          }
        });
      }

      if (isQuestionCorrect === QuestionFeedbackState.CORRECT) {
        currentImage = correctImageUrl;
      }

      if (isQuestionCorrect === QuestionFeedbackState.INCORRECT) {
        currentImage = incorrectImageUrl;
      }

      if (currentImage) {
        questionFeedbackManager.setShowFeedbackImages(lessonElementId, true);
        setTimeout(() => {
          questionFeedbackManager.setShowFeedbackImages(lessonElementId, false);
        }, 3000);
      }

      if (model && model.audioFeedback && model.audioFeedback.length > 0) {
        let audio = null;

        if (isQuestionCorrect === QuestionFeedbackState.CORRECT) {
          audio = model.audioFeedback.find((element) => element.type === 'correct');
        }

        if (isQuestionCorrect === QuestionFeedbackState.INCORRECT) {
          audio = model.audioFeedback.find((element) => element.type === 'incorrect');
        }

        if (audio) {
          const audioSrc = `${Auth.ecms}/api/redirectToStreamUrl?authKey=${Auth.authKey}&resourceFolder=userFiles&entityId=${audio.resourceId}&entityTypeId=audio_resource`;
          audioManager.playAudio(audioSrc);
        }
      }
      if (!state.cachedResponseAnswer) {
        state.setCachedResponse(UtilsService.safeMobxClone(state.currentResponseAnswer));
      }
    } else if (model.isTestItem && !isAutoScored && hasFeedback &&
        (mode === LessonMode.PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW || mode === LessonMode.ACTIVITY_MODE)
    ) {
      if (!model.unscored) {
        responseManager.addNotScoredList(lessonElementId);
      }
    }
    if (state.isSubmitted && state.isTestItem) {
      this.doDependencyLogic(lessonElementId);
    }
    this.doFeedbackLogic(lessonElementId, model, mode, state);
  }

  tryAgainButtonHandler = async (event, lessonElementId) => {
    questionFeedbackManager.setTryAgainVisibleState(lessonElementId, false);
    const mode = lessonManager.playerMode;
    const model = lessonManager.getSlideModel(lessonElementId);
    let lessonElementState = responseManager.getLessonElementState(lessonElementId);
    responseManager.setPreviewResponseSubmitted(lessonElementId, false);

    if (!!event && lessonElementState?.questionBehavior?.tryAgainButtonHandler) {
      const result = await lessonElementState.questionBehavior.tryAgainButtonHandler({
        event, lessonElementId, lessonElementState, model
      });
      if (result?.lessonElementState) {
        lessonElementState = result.lessonElementState;
      }
    }

    this.doFeedbackLogic(lessonElementId, model, mode, lessonElementState);
    responseManager.setReadOnly(lessonElementId, false);
    questionFeedbackManager.addButtonUsage('tryAgain');
  }

  showAnswersButtonHandler = (event, lessonElementId, toggle) => {
    const mode = lessonManager.playerMode;
    const wrongPracticeMode = (mode === LessonMode.SCORING || mode === LessonMode.REVIEW);
    const { hasFeedback } = questionFeedbackManager;
    const { isPractice } = questionFeedbackManager;
    const currentUiMode = questionFeedbackManager.lessonElementFeedbackTracker.get(lessonElementId);
    const { currentAttemptCount } = currentUiMode;
    const { practiceAttempts } = questionFeedbackManager.questionFeedbackMode;
    const isPracticeRunning = (isPractice && !wrongPracticeMode && (currentAttemptCount < practiceAttempts));

    if (hasFeedback || mode === LessonMode.REVIEW || mode === LessonMode.SCORING) {
      const state = responseManager.getLessonElementState(lessonElementId);
      const model = lessonManager.getSlideModel(lessonElementId);
      if (state.questionBehavior && model.validation && toggle) {
        if (state.questionBehavior.isAutoScored(model)) {
          state.questionBehavior.setCorrectAnswer(state, model, isPracticeRunning);
          state.turnOnAnswerShowing();
          if (state.isSubmitted) {
            responseManager.scoreResponse(model, lessonElementId);
          }
        }
      } else if (state.questionBehavior && model.validation && !toggle) {
        if (state.questionBehavior.isAutoScored(model)) {
          state.questionBehavior.resetStudentAnswer(state, isPracticeRunning);
          state.turnOffAnswerShowing();
          if (state.isSubmitted) {
            responseManager.scoreResponse(model, lessonElementId);
          }
        }
      }
      if (mode === LessonMode.SCORING) {
        questionFeedbackManager.setShowQuestionFeedback(lessonElementId, (!toggle && state.isSubmitted));
      }
      if (isPracticeRunning) {
        questionFeedbackManager.endPractice(lessonElementId);
        this.doFeedbackLogic(lessonElementId, model, mode, state);
        this.doDependencyLogic(lessonElementId);
      }
      questionFeedbackManager.setShowAnswers(lessonElementId, toggle);
    }
    if (toggle) {
      questionFeedbackManager.addButtonUsage('showAnswer');
    }
  }

  handleSubmitResponse = async (lessonElementId) => {
    const mode = lessonManager.playerMode;
    const model = lessonManager.getSlideModel(lessonElementId);
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);

    if (mode === LessonMode.ACTIVITY_MODE && (!lessonElementState.isScored || model.unscored) && !model.isGame) {
      const lessonElement = lessonManager.getLessonElement(lessonElementId);
      const isScoreNow = true;
      const activityId = studentActivityManager.studentActivityId;

      const viewedTime = timeManager.getViewTime(lessonElementId);

      const noSubmit = false;
      const serverResponse = responseManager.getUpdatedServerResponse(
        lessonElementState,
        activityId,
        viewedTime,
        isScoreNow,
        lessonElement.entityId,
        noSubmit, {
          model
        }
      );

      const success = await this.submitResponses([serverResponse]);
      if (success) {
        const currentUiState = questionFeedbackManager.lessonElementFeedbackTracker.get(lessonElementId);
        const { currentAttemptCount } = currentUiState;
        questionFeedbackManager.setCurrentAttemptCount(lessonElementId, currentAttemptCount + 1);
        return true;
      } else {
        questionFeedbackManager.setLoadingButtonState(lessonElementId, false);
        return false;
      }
    } else {
      questionFeedbackManager.setLoadingButtonState(lessonElementId, false);
      responseManager.setPreviewResponseSubmitted(lessonElementId, true);
      responseManager.setReadOnly(lessonElementId, true);
      const currentUiState = questionFeedbackManager.lessonElementFeedbackTracker.get(lessonElementId);
      const { currentAttemptCount } = currentUiState;
      questionFeedbackManager.setCurrentAttemptCount(lessonElementId, currentAttemptCount + 1);
      return true;
    }
  }

  doDependencyLogic = (lessonElementId) => {
    const sequential = responseManager.sequentialItemDependency;
    const nonSequential = (!responseManager.sequentialItemDependency && responseManager.dependencyFlowMap.size > 0);

    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
    const currentUiMode = questionFeedbackManager.lessonElementFeedbackTracker.get(lessonElementId);
    const { currentAttemptCount } = currentUiMode;
    const { practiceAttempts } = questionFeedbackManager.questionFeedbackMode;
    const attemptsLeft = currentAttemptCount < practiceAttempts;
    const isCorrect = lessonElementState.maxScore === lessonElementState.scoreValue;

    const doNonSequential = (nonSequential && (isCorrect || !attemptsLeft ||
      !questionFeedbackManager.isPractice));

    if (lessonElementState.dependentList.length > 0) {
      if (sequential || doNonSequential) {
        lessonElementState.dependentList.forEach((id) => {
          const dependentState = responseManager.getLessonElementState(id);
          if (dependentState) {
            dependentState.removeController(lessonElementId);
          }
        });
        lessonElementState.removeAllDependents();
      }
    }
  }

  saveResponses = async (serverResponses, forceSubmit) => {
    if (responseManager.isSubmitting) {
      return false;
    }
    responseManager.setIsSubmitting(true);

    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/submitResponses`, {
        method: 'POST',
        body: { responses: JSON.stringify(serverResponses) }
      });
      if ((result.status === 'SUCCESS')) {
        const { submittedResponses } = result;
        submittedResponses.forEach((submittedResponse) => {
          const { lessonElementId } = submittedResponse;
          responseManager.setResponseId(submittedResponse.id, lessonElementId);
          responseManager.setCurrentResponseSaved(lessonElementId, forceSubmit);
        });
        responseManager.setIsSubmitting(false);
      } else {
        if (result.status === 500 || result.status === 503 || result.status === 504) {
          await this.fireSaveErrorAlert();
        }
        responseManager.setIsSubmitting(false);
      }
    } catch (e) {
      responseManager.setIsSubmitting(false);
      await this.fireSaveErrorAlert();
      console.error(e);
    }
  }

  setMarkedForReview = (lessonElementId, toogle) => {
    responseManager.setMarkedForReview(lessonElementId, toogle);
  }

  submitResponses = async (serverResponses) => {
    if (responseManager.isSubmitting) {
      return false;
    }
    responseManager.setIsSubmitting(true);

    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/submitResponses`, {
        method: 'POST',
        body: { responses: JSON.stringify(serverResponses) }
      });
      if (result.status === 'SUCCESS') {
        const { submittedResponses } = result;
        submittedResponses.forEach((submittedResponse) => {
          const { lessonElementId } = submittedResponse;
          const model = lessonManager.getSlideModel(lessonElementId);

          responseManager.setResponseId(submittedResponse.id, lessonElementId);
          if (submittedResponse.validation) {
            const model = lessonManager.getSlideModel(lessonElementId);
            if (!model.validation) {
              lessonManager.setModelValidation(lessonElementId, JSON.parse(submittedResponse.validation));
            }
          }
          responseManager.setCurrentResponseSubmitted(lessonElementId, result.scoreState, model);

          responseManager.setReadOnly(lessonElementId, true);
        });
        responseManager.setIsSubmitting(false);
        return true;
      } else {
        await this.fireSaveErrorAlert();
        console.info(result.status);
        console.info(result.statusMessage);
        responseManager.setIsSubmitting(false);
        return false;
      }
    } catch (e) {
      // If there is a connection error, attempt to submit the response queue again after a delay.
      if (UtilsService.isConnectionError(e)) {
        await this.fireSaveErrorAlert();
        // evotextUtil.showOverlay($('body'), 'loading', '', {fullScreen:true});
      } else if (UtilsService.isLockedError(e)) {
        await this.fireSaveErrorAlert(t('lockError'));
      } else if (UtilsService.isReviewError(e)) {
        await this.fireSaveErrorAlert(t('alreadySubmittedError'));
      } else if (UtilsService.isInternalError(e)) {
        const message = e.message || t('errorGeneric');
        await this.fireSaveErrorAlert(`${message} ${t('generalSaveError')}`);
      } else if (UtilsService.isSystemError(e)) {
        await this.fireSaveErrorAlert();
      } else {
        await this.fireSaveErrorAlert();
      }

      console.error(e);
      responseManager.setIsSubmitting(false);
      return false;
    }
  }

  submitLessonForGrade = async () => {
    const { activityId } = studentActivityManager;
    const total = responseManager.totalTestItemResponses;
    const submittedCount = responseManager.numberSubmittedResponses;
    const incompleteResponseCount = (total - submittedCount);

    let result;
    if (incompleteResponseCount > 0) {
      result = await confirmDialog({
        title: `${UtilsService.submitCheck()}`,
        text: `${UtilsService.notCompleteWarning()}`,
      });
    } else {
      result = await confirmDialog({
        title: `${UtilsService.submitCheck()}`,
        text: `${UtilsService.cannotBe()}`,
      });
    }
    if (result.isConfirmed) {
      await this.submitActivityInstance(activityId);
    }
  }

  saveSingleResponse = async (lessonElementId) => {
    const mode = lessonManager.playerMode;

    if (mode === LessonMode.ACTIVITY_MODE) {
      const activityId = studentActivityManager.studentActivityId;
      const lessonElementState = responseManager.getLessonElementState(lessonElementId);
      const lessonElement = lessonManager.getLessonElement(lessonElementId);
      const model = lessonManager.getSlideModel(lessonElementId);
      const { noSubmit } = questionFeedbackManager;
      let forceSubmit = false;

      if (noSubmit && lessonElementState && lessonElementState.questionBehavior) {
        const answered = lessonElementState.questionBehavior.checkForValidResponse(lessonElementState.currentResponseAnswer, model);
        if (answered && model.isTestItem) {
          forceSubmit = true;
        }
      }

      if (model.survey && lessonElementState && lessonElementState.questionBehavior) {
        const answered = lessonElementState.questionBehavior.checkForValidResponse(lessonElementState.currentResponseAnswer, model);
        if (answered && model.isTestItem && model.survey) {
          forceSubmit = true;
        }
      }

      if (lessonElementState && !model.isGame) {
        const viewTime = timeManager.getViewTime(lessonElementId);
        const { noSubmit } = questionFeedbackManager;
        const serverResponse = responseManager.getUpdatedServerResponse(
          lessonElementState,
          activityId,
          viewTime,
          false,
          lessonElement.entityId,
          noSubmit, {
            model
          }
        );
        const responses = [serverResponse];
        await this.saveResponses(responses, forceSubmit);
      }
    }
  }

  saveAllResponses = async () => {
    const questionList = lessonManager.elementsThatHaveState;
    const activityId = studentActivityManager.studentActivityId;
    const mode = lessonManager.playerMode;
    const responses = [];
    if (mode === LessonMode.ACTIVITY_MODE) {
      for (let i = 0; i < questionList.length; ++i) {
        const model = questionList[i];
        if (!model.isGame) {
          const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
          const lessonElement = lessonManager.getLessonElement(model.lessonElementId);
          if (lessonElementState) {
            const viewTime = timeManager.getViewTime(model.lessonElementId);
            const { noSubmit } = questionFeedbackManager;
            const serverResponse = responseManager.getUpdatedServerResponse(
              lessonElementState,
              activityId,
              viewTime,
              false,
              lessonElement.entityId,
              noSubmit, {
                model
              }
            );
            responses.push(serverResponse);
          }
        }
      }
      if (responses.length >= 0) {
        await this.saveResponses(responses);
      }
    }
  }

  fetchTeacherComments = async (lessonElementId) => {
    if (!lessonElementId) {
      return;
    }
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);

    if (!lessonElementState) {
      return;
    }

    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/viewComment?entityId=${lessonElementState.responseId}`, {
        method: 'GET'
      });
      if (result.status === 'SUCCESS') {
        responseManager.setResponseComment(lessonElementId, result.comment);
      } else {
        console.info(result.status);
        console.info(result.statusMessage);
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  fetchAllTeacherComments = async () => {
    const slides = lessonManager.getFilteredSlides();
    const responseIds = [];

    slides.forEach((lessonElementId) => {
      const lessonElementState = responseManager.getLessonElementState(lessonElementId);
      if (lessonElementState && lessonElementState.responseId) {
        const newId = `'${lessonElementState.responseId}'`;
        responseIds.push(newId);
      }
    });

    if (responseIds.length === 0) {
      return;
    }

    const idString = responseIds.toString();

    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/viewComments?entityIds=${idString}`, {
        method: 'GET'
      });
      if (result.status === 'SUCCESS') {
        if (result.comments && result.comments.discussions && result.comments.discussions.length > 0) {
          responseManager.setResponseComments(result.comments.discussions);
        }
      } else {
        console.info(result.status);
        console.info(result.statusMessage);
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  doScoringModeStartUp = async (activityId, studentViewId, lessonElementIdRequested, isNext) => {
    lessonManager.lessonElementIdRequested = lessonElementIdRequested;
    lessonManager.createSlideLabels();
    if (isNext) {
      lessonManager.setStartWithOnlyManual();
    }

    if (studentActivityManager.students.length === 0) {
      await studentActivityService.fetchActivityUsers(activityId, studentViewId);
    }
    const student = studentActivityManager.currentStudent;
    studentActivityManager.setStudentName(student);
    studentActivityManager.setStudentId(student.id);
    lessonManager.manualSlideList = [];
    lessonManager.doNotScoreSlideList = [];

    await this.fetchResponses(activityId);
    await this.fetchAllTeacherComments();
    scoringService.initTempScores();

    if (!UtilsService.isNullOrEmpty(lessonElementIdRequested)) {
      const index = lessonManager.slideList.indexOf(lessonElementIdRequested);
      if (index > 0 && index < lessonManager.slideList.length) {
        lessonManager.setCurrentSlideIndex(index);
      }
    }
  }

  processReturnedResponses = async (model, responses, activityId, mode) => {
    const type = (model.type) ? model.type : null;
    const behavior = createQuestionBehavior(type);
    const { lessonElementId } = model;
    const response = responses[model.id];
    if (response) {
      const mustHaveUserInputMap = questionService.mustHaveUserInputMap(model);

      if (response.validation && (!model.validation) &&
        ((mode === LessonMode.ACTIVITY_MODE) ||
        (mode === LessonMode.SCORING) ||
        (mode === LessonMode.REVIEW))) {
        lessonManager.setModelValidation(lessonElementId, JSON.parse(response.validation));
      }
      let responseAnswer = await behavior.getInitializedResponse(lessonElementId, model, activityId);

      if (!UtilsService.isNullOrEmpty(response.content) && response.content !== '{}') {
        responseAnswer = JSON.parse(response.content);
      }
      let toolPersistence = {};
      if (!UtilsService.isNullOrEmpty(response.toolPersistence) && response.toolPersistence !== '{}') {
        try {
          toolPersistence = JSON.parse(response.toolPersistence);
        } catch (e) {
          // It's not JSON, leave it []
        }
      }

      const isScored = (!UtilsService.isNullOrEmpty(response.scoreState) &&
        !(response.scoreState === ScoreState.NOT_SCORED) && !model?.unscored);

      responseManager.createLessonElementState(
        activityId, response.id, response.maxScore, response.lessonElementId,
        model.id, responseAnswer, response.viewedTime,
        false, toolPersistence, true, response.locked,
        isScored, response.submittedItemScore, response.scoreState,
        response.note, response.markedForReview, response.subscore, behavior,
        true, model.isTestItem, response.submittedItemScore, response.submittedMaxScore, model.survey, model.unscored);

      const state = responseManager.getLessonElementState(lessonElementId);
      if (mustHaveUserInputMap) {
        state.currentResponseAnswer.prompts = responseAnswer.prompts;
        state.currentResponseAnswer.userInputMap = questionService.updateUserInputMap({
          model,
          lessonElementState: {
            ...state,
            prompts: responseAnswer.prompts
          }
        });
        responseAnswer.userInputMap = questionService.updateUserInputMap({ model, responseAnswer });
      }

      if (questionFeedbackManager.noSubmit && (behavior.checkForValidResponse(state.currentResponseAnswer, model) ||
        (mustHaveUserInputMap && responseAnswer?.prompts?.some?.((userInput) => !!userInput.text)))
      ) {
        state.setSubmitted(true);
      }

      const isCorrect = behavior.isQuestionCorrect(state, lessonElementId);
      const isAutoScored = behavior.isAutoScored(model);
      if (!isScored && model.isTestItem && !model.unscored && !isAutoScored) {
        responseManager.addNotScoredList(lessonElementId);
      }

      const isAnsweredSurvey = questionService.isAnsweredSurveyQuestion({
        lessonElementId,
        lessonElementState: state,
        model
      });

      if ((state.isSubmitted && model.isTestItem) || isAnsweredSurvey) {
        if (!responseManager.submittedResponseList.includes(lessonElementId)) {
          responseManager.submittedResponseList.push(lessonElementId);
        }
      }

      if (state.isSubmitted && isScored) {
        switch (isCorrect) {
        case QuestionFeedbackState.CORRECT: {
          responseManager.addCorrectList(lessonElementId);
          break;
        }
        case QuestionFeedbackState.INCORRECT: {
          responseManager.addIncorrectList(lessonElementId);
          break;
        }
        case QuestionFeedbackState.PARTIAL_CORRECT: {
          responseManager.addPartiallyCorrectList(lessonElementId);
          break;
        }
        default: {
          break;
        }
        }
      } else if (state.isSubmitted && !isScored && !model.unscored && !isAutoScored) {
        responseManager.addNotScoredList(lessonElementId);
      }

      if (state.isSubmitted) {
        state.setCachedCorrectState(isCorrect);
      }

      if (response.engagementData) {
        responseManager.setEngagementData(response.lessonElementId, response.engagementData);
      }

      if (response.viewedTime && (mode === LessonMode.ACTIVITY_MODE)) {
        timeManager.contentItemViewedTime.set(lessonElementId, response.viewedTime);
      }

      if (questionFeedbackManager.isPractice && response.locked) {
        questionFeedbackManager.setCurrentAttemptCount(response.lessonElementId, -1);
        // this person has submitted, go to finished
      }

      if (response.locked || mode === LessonMode.REVIEW || mode === LessonMode.SCORING) {
        responseManager.setReadOnly(lessonElementId, true);

        // note that the behavior.getScore() method typically returns an enum for feedback purposes, such as -1, 0, 1 (NOT the actual score)
        // setting the result directly to scoreValue breaks manual scoring, so need to comment out the following line
        // state.scoreValue = behavior.getScore(state.currentResponseAnswer, model);

        // we can call behavior.getScore() without setting it to something, as it sets internal flags that the feedback component is listening to
        behavior.getScore(state.currentResponseAnswer, model);

        questionFeedbackManager.setShowQuestionFeedback(lessonElementId, state.isSubmitted);
        state.setCachedResponse(UtilsService.safeMobxClone(state.currentResponseAnswer));

        if (mode === LessonMode.SCORING) {
          if ((model.unscored) && model.isTestItem) {
            lessonManager.addDoNotScoreSlide(response.lessonElementId);
          } else if ((!isAutoScored && model.isTestItem) || (model.survey && model.isTestItem)) {
            lessonManager.addManuallyScoredSlide(response.lessonElementId);
          }
          lessonManager.setStartWithOnlyManual();
        }

        questionFeedbackManager.setSubmitVisibleState(lessonElementId, false);
        questionFeedbackManager.setTryAgainVisibleState(lessonElementId, false);
        questionFeedbackManager.setShowAnswers(lessonElementId, false);
        if (isAutoScored) {
          questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);
          questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, true);
        }
      }
    }
  }

  fetchClassEngagementSummary = async (activityId) => {
    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/viewClassroomActivityElementEngagement?activityId=${activityId}`, {
        method: 'GET'
      });
      if (result.status === 'SUCCESS') {
        for (const property in result.data) {
          responseManager.setClassEngagementReport(result.data[property]);
        }
      } else {
        console.info(result.status);
        console.info(result.statusMessage);
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  fetchStudentReviewEngagementSummary = async (activityId, studentId) => {
    let studentParam = '';
    if (!UtilsService.isNullOrEmpty(studentId)) {
      studentParam = `&studentId=${studentId}`;
    }
    const finalParams = `?activityId=${activityId}${studentParam}`;
    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/viewActivityEngagement${finalParams}`, {
        method: 'GET'
      });
      if (result.status === 'SUCCESS') {
        responseManager.setStudentReviewEngagementReport(result.data);
      } else {
        console.info(result.status);
        console.info(result.statusMessage);
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  fetchResponses = async (activityId) => {
    const assignmentData = await this.fetchActivityStudentResponsesAndScores(activityId);
    if (assignmentData === null) {
      return false;
    }
    const responses = assignmentData.responsesScores;
    const questionList = lessonManager.elementsThatHaveState;
    const mode = lessonManager.playerMode;

    if (mode === LessonMode.SCORING) {
      studentActivityManager.setStudentActivityId(activityId);
      if (toolbarManager.isNotepadOpen) {
        await studentActivityService.fetchStudentNotepadNote(activityId);
      }
    }

    // don't use forEach function here or await will not work on each call
    for (let x = 0; x < questionList.length; ++x) {
      await this.processReturnedResponses(questionList[x], responses, activityId, mode);
    }
    return true;
  }

  isAnswerCorrect = (lessonElementId, answerId, { lessonElementState, model } = {}) => {
    model = model || lessonManager.getSlideModel(lessonElementId);
    const wrongMode = (lessonManager.playerMode === LessonMode.SCORING || lessonManager.playerMode === LessonMode.REVIEW);
    const { isPractice, practiceAttempts } = questionFeedbackManager.questionFeedbackMode;
    const uiTracker = questionFeedbackManager.lessonElementFeedbackTracker.get(lessonElementId);
    let isPracticeRunning = false;

    if (uiTracker && (uiTracker.currentAttemptCount < practiceAttempts) && isPractice && !wrongMode) {
      isPracticeRunning = true;
    }
    return responseManager.isAnswerCorrect(lessonElementId, answerId, model, isPracticeRunning, { lessonElementState });
  }

  isAutoScored = (lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
    return responseManager.isAutoScored(lessonElementState, model);
  }

  fetchLessonElementResponseContent = async (activityId, lessonElementId) => {
    const userId = (userManager.isStudent) ? userManager.userId : studentActivityManager.studentId;
    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/viewActivityResponseContent?userId=${userId}&activityId=${activityId}&entityId=${lessonElementId}`, {
        method: 'GET'
      });
      if (result.status === 'SUCCESS') {
        return result.contentItems;
      } else {
        if (result.status === 500 || result.status === 503 || result.status === 504) {
          // await this.fireSaveErrorAlert();
          // TODO: Create a new alert for this
        }
        console.info(result.status);
        console.info(result.statusMessage);
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  uploadAndAttachFileResource = async (file, activityId, contentObjectId, lessonElementId, entityTypeId = 'content_item', {
    mimeMainType
  } = {}) => {
    // Upload the file
    // const uploadFileId = `files_${contentObjectId}`;
    try {
      const formData = new FormData();
      formData.append('file', file);

      if (mimeMainType) {
        formData.append('mimeMainType', mimeMainType);
      }

      const uploadResult = await Auth.fetch(`${Auth.ecms}/api/uploadFileResource?entityId=${contentObjectId}&entityTypeId=${entityTypeId}`, {
        method: 'POST',
        body: formData
      });
      if (uploadResult.status !== 'SUCCESS' && uploadResult.status !== 'WARNING') {
        if (uploadResult.status === 500 || uploadResult.status === 503 || uploadResult.status === 504) {
          // await this.fireSaveErrorAlert();
          // TODO: Create a new alert for this
        }
        console.info(uploadResult.status);
        console.info(uploadResult.statusMessage);
        return null;
      }
      // Otherwise the file was uploaded successfully and we need to attach it
      const { contentItemId } = uploadResult;
      const attachResult = await Auth.fetch(`${Auth.ecms}/api/attachActivityResponseContent`, {
        method: 'POST',
        body: {
          activityId,
          entityId: lessonElementId,
          contentItemId
        }
      });
      if (attachResult.status !== 'SUCCESS') {
        if (attachResult.status === 500 || attachResult.status === 503 || attachResult.status === 504) {
          // await this.fireSaveErrorAlert();
          // TODO: Create a new alert for this
        }
        console.info(attachResult.status);
        console.info(attachResult.statusMessage);
        return null;
      }
      return contentItemId;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  updateContentItemName = async (contentItemId, name) => {
    // ContentItemId is the id returned from viewActivityResponseContent
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/updateContentItemName`, {
        method: 'POST',
        body: {
          id: contentItemId,
          name
        }
      });
      if (response.status === 'SUCCESS') {
        return true;
      } else {
        if (response.status === 500 || response.status === 503 || response.status === 504) {
          // await this.fireSaveErrorAlert();
          // TODO: Create a new alert for this
        }
        console.info(response.status);
        console.info(response.statusMessage);
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  removeActivityResponseContent = async (activityId, lessonElementId, contentItemId) => {
    // ContentItemId is the id returned from viewActivityResponseContent
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/removeActivityResponseContent`, {
        method: 'POST',
        body: {
          activityId,
          entityId: lessonElementId,
          contentItemId
        }
      });
      if (response.status === 'SUCCESS') {
        return true;
      } else {
        if (response.status === 500 || response.status === 503 || response.status === 504) {
          // await this.fireSaveErrorAlert();
          // TODO: Create a new alert for this
        }
        console.info(response.status);
        console.info(response.statusMessage);
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  fetchActivityStudentResponsesAndScores = async (activityId) => {
    if (studentActivityManager.studentId) {
      // Requesting a student's data.
      try {
        const result = await Auth.fetch(`${Auth.ecms}/api/getActivityStudentResponsesAndScores?activityId=${activityId}&studentId=${studentActivityManager.studentId}`, {
          method: 'GET'
        });
        if (result.status === 'SUCCESS') {
          return result.responsesAndScores;
        } else {
          if (result.status === 500 || result.status === 503 || result.status === 504) {
            await this.fireSaveErrorAlert();
          }
          // console.info(result.status);
          // console.info(result.statusMessage);
          return null;
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    } else {
      // User is the student; get their data.
      try {
        const result = await Auth.fetch(`${Auth.ecms}/api/getActivityResponsesAndScores?activityId=${activityId}`, {
          method: 'GET'
        });
        if (result.status === 'SUCCESS') {
          return result.responsesAndScores;
        } else {
          if (result.status === 500 || result.status === 503 || result.status === 504) {
            await this.fireSaveErrorAlert();
          }
          console.info(result.status);
          console.info(result.statusMessage);
          return null;
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }

  submitActivityInstance = async (activityId) => {
    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/submitActivityInstance`, {
        method: 'POST',
        body: { activityId },
      });
      if (result.status === 'SUCCESS') {
        await UtilsService.exitPlayer();
      } else {
        if (result.status === 500 || result.status === 503 || result.status === 504) {
          await this.fireSaveErrorAlert();
        }
        console.info(result.status);
        console.info(result.statusMessage);
      }
    } catch (e) {
      if (UtilsService.isConnectionError(e)) {
        await this.fireSaveErrorAlert();
      } else if (UtilsService.isLockedError(e)) {
        await this.fireSaveErrorAlert(t('lockError'));
      } else if (UtilsService.isReviewError(e)) {
        await this.fireSaveErrorAlert(t('alreadySubmittedError'));
      } else if (UtilsService.isInternalError(e)) {
        const message = e.message || t('errorGeneric');
        await this.fireSaveErrorAlert(`${message} ${t('generalSaveError')}`);
      } else if (UtilsService.isSystemError(e)) {
        await this.fireSaveErrorAlert();
      }
      console.error(e);
    }
  }

  fireSaveErrorAlert = async (message, title) => {
    await confirmDialog({
      title: (title) || `${UtilsService.errorAlert()}`,
      text: (message) || `${UtilsService.saveErrorMessage()}`,
      confirmButtonText: `${t('close')}`
    });
  }

  clearFeedback = (lessonElementId) => {
    questionFeedbackManager.setShowAnswerFeedback(lessonElementId, false);
    questionFeedbackManager.setShowQuestionFeedback(lessonElementId, false);
    questionFeedbackManager.setShowAnswers(lessonElementId, false);
  }

  scoreResponse = (lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    responseManager.scoreResponse(model, lessonElementId);
  };

  textChangeHandler = (data, lessonElementId, { dataId = '', lessonElementState, oldDataId = '', ...rest } = {}) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    responseManager.setResponseData(lessonElementId, data, model, { dataId, lessonElementState, oldDataId, ...rest });
  }

  teacherNotesChanged = (notes, lessonElementId) => {
    responseManager.updateTeacherNote(notes, lessonElementId);
  }

  responseChangeHandler = async (data, lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    await responseManager.setResponseData(lessonElementId, data, model);
  }

  responseRemoveHandler = async (data, lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    await responseManager.removeResponseData(lessonElementId, data, model);
  }

  getResponseModel = (lessonElementId) => {
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
    return lessonElementState.currentResponseAnswer;
  }

  isMarkedForReview = (lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    if (model.isActivity) {
      const parentLessonElement = lessonManager.getLessonElement(lessonElementId);
      if (parentLessonElement.lessonElementIds && parentLessonElement.lessonElementIds.length > 0) {
        for (let i = 0; i < parentLessonElement.lessonElementIds.length; i++) {
          const elementId = parentLessonElement.lessonElementIds[i];
          if (responseManager.markedForReviewList.includes(elementId)) {
            return true;
          }
        }
      }
      return false;
    }
    return responseManager.markedForReviewList.includes(lessonElementId);
  }

  getShowAnswerText = (lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    const behavior = responseManager.getQuestionBehavior(lessonElementId);

    if (behavior && model?.validation) {
      return behavior.getCorrectAnswersText(model);
    } else {
      return '';
    }
  }

  getQuestionFeedbackText = (lessonElementId) => {
    const mode = lessonManager.playerMode;
    const model = lessonManager.getSlideModel(lessonElementId);
    const { hasFeedback } = questionFeedbackManager;

    const behavior = responseManager.getQuestionBehavior(lessonElementId);
    if (!behavior) {
      return '';
    }

    const lessonElementState = responseManager.getLessonElementState(lessonElementId);

    const isQuestionCorrect = responseManager.isQuestionCorrect(lessonElementId);

    let modelCorrectFeedback = '';
    let modelIncorrectFeedback = '';
    let modelCorrectFeedbackBody = '';
    let modelIncorrectFeedbackBody = '';

    if (model?.validation?.feedback?.level === 'answer') {
      // NOTE: `userAnswer.isCorrect` (when true) is what the user marked as the 'correct' answer.
      // This does not necessarily mean it is the actual correct answer.
      const chosenUserAnswer = (lessonElementState?.currentResponseAnswer?.answers?.find((userAnswer) => {
        const userMarkedAnswerAsCorrect = userAnswer?.isCorrect;
        return userMarkedAnswerAsCorrect;
      }));
      const chosenUserAnswerFeedback = chosenUserAnswer ? model?.validation?.feedback?.answers?.[chosenUserAnswer?.id] : undefined;

      modelCorrectFeedback = chosenUserAnswerFeedback?.heading;
      modelIncorrectFeedback = chosenUserAnswerFeedback?.heading;
      modelCorrectFeedbackBody = chosenUserAnswerFeedback?.body;
      modelIncorrectFeedbackBody = chosenUserAnswerFeedback?.body;
    } else if (model?.validation?.feedback) {
      modelCorrectFeedback = model.validation.feedback.correctFeedback;
      modelIncorrectFeedback = model.validation.feedback.incorrectFeedback;
      modelCorrectFeedbackBody = model.validation.feedback.correctFeedbackBody;
      modelIncorrectFeedbackBody = model.validation.feedback.incorrectFeedbackBody;
    }

    let finalCorrect = questionFeedbackManager.correctFeedback;
    let finalIncorrect = questionFeedbackManager.incorrectFeedback;

    if (finalCorrect !== UtilsService.correct()) {
      finalCorrect = UtilsService.correct();
    }

    if (finalIncorrect !== UtilsService.incorrect()) {
      finalIncorrect = UtilsService.incorrect();
    }

    const correctFeedback = modelCorrectFeedback || finalCorrect;
    const incorrectFeedback = modelIncorrectFeedback || finalIncorrect;

    let result = null;

    if (mode === LessonMode.SCORING && lessonElementState.questionBehavior.isAutoScored(lessonElementId)) {
      if (isQuestionCorrect === QuestionFeedbackState.CORRECT) {
        result = '<div class=\'question-feedback-header\'>';
        result += correctFeedback;
        result += '</div>';
      } else if (isQuestionCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
        result = '<div class=\'question-feedback-header\'>';
        result += incorrectFeedback;
        result += '</div>';
      } else {
        result = '<div class=\'question-feedback-header\'>';
        result += incorrectFeedback;
      }
    }

    if (result !== null) {
      return result;
    }

    if ((!hasFeedback || !behavior.isAutoScored(model)) && mode !== LessonMode.REVIEW) {
      if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
        result = t('previewWarning2');
      } else if (mode === LessonMode.ACTIVITY_MODE) {
        result = t('saveCommit2');
      }
    } else {
      let currentCount = 0;
      const currentUiMode = questionFeedbackManager.lessonElementFeedbackTracker.get(lessonElementId);
      if (currentUiMode) {
        currentCount = currentUiMode.currentAttemptCount;
      }
      if (typeof behavior.getQuestionFeedbackText != 'undefined') {
        result = behavior.getQuestionFeedbackText(model,
          correctFeedback,
          incorrectFeedback,
          modelCorrectFeedbackBody,
          modelIncorrectFeedbackBody,
          mode,
          isQuestionCorrect,
          currentCount,
          { lessonElementState });
      }
      if (UtilsService.isNullOrEmpty(result)) {
        result = t('saveCommit2');
      }
    }
    return result;
  }

  initSlideResponses = async () => {
    const questionList = lessonManager.elementsThatHaveState;
    for (const model of questionList) {
      const type = (model.type) ? model.type : null;
      const questionBehavior = createQuestionBehavior(type);
      const { lessonElementId } = model;
      if (!responseManager.lessonElementStates.has(lessonElementId)) {
        this.clearFeedback(lessonElementId);
        const activityId = '';
        const responseId = '';
        const contentObjectId = model.id;
        const viewedTime = 0;
        const scoreNow = false;
        const toolPersistence = {};
        const isSaved = false;
        const isSubmitted = false;
        const isScored = false;
        const scoreValue = 0;
        const scoreState = '';
        const teacherNote = null;
        const markedForReview = false;
        const subscore = null;
        const clearOld = true;
        const responseAnswer = await questionBehavior.getInitializedResponse(lessonElementId, model);

        let maxScore = 0;
        if (model.isTestItem) {
          maxScore = model.maxScore;
        }
        if (!(model.isTestItem || model.isStudentInstruction || model.isActivity || model.isResource || model.isInstruction)) {
          // Then this is not a valid type to have an elementState
          throw new Error('Tried to create lessonElementState for invalid type. Check whether lessonManager.shouldHaveElementState(model) is set correctly.');
        }
        // Now we can create the lessonElementState
        responseManager.createLessonElementState(
          activityId, responseId, maxScore, lessonElementId,
          contentObjectId, responseAnswer, viewedTime, scoreNow,
          toolPersistence, isSaved, isSubmitted, isScored,
          scoreValue, scoreState, teacherNote, markedForReview,
          subscore, questionBehavior, clearOld, model.isTestItem, 0, 0, model.survey, model.unscored
        );

        if (!questionBehavior.isAutoScored(model) && !model.unscored) {
          responseManager.addNotScoredList(lessonElementId);
        }
      }
    }
  }

  doFeedbackLogic = (lessonElementId, model, mode, lessonElementState) => {
    if (lessonElementState && lessonElementState.isSubmitted && !questionFeedbackManager.noSubmit) {
      responseManager.setReadOnly(lessonElementId, true);
    }
    questionFeedbackManager.setSubmitVisibleState(lessonElementId, false); // Show "Submit" button
    questionFeedbackManager.setTryAgainVisibleState(lessonElementId, false); // Show "Try Again" button
    questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, false);
    questionFeedbackManager.setShowHintVisibleState(lessonElementId, false); // Show "Show Hint" button
    questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, false); // Show "Show solution" button
    questionFeedbackManager.setShowAnswerFeedback(lessonElementId, false);
    questionFeedbackManager.setShowQuestionFeedback(lessonElementId, false); // Show the popup below with the feedback text
    questionFeedbackManager.setShowAnswers(lessonElementId, false); // Swap in real answers
    questionFeedbackManager.setLoadingButtonState(lessonElementId, false);

    if (!model || !model.isTestItem) {
      return;
    }
    const { hasFeedback, noSubmit } = questionFeedbackManager;
    const { isPractice, practiceAttempts, customPracticeMatrix } = questionFeedbackManager.questionFeedbackMode;
    const { isSubmitted, questionBehavior } = lessonElementState;

    const currentUiMode = questionFeedbackManager.lessonElementFeedbackTracker.get(lessonElementId);
    const { currentAttemptCount } = currentUiMode;
    const hasHint = (!UtilsService.isNullOrEmpty(model.hint));
    const hasSolution = (model.validation && !UtilsService.isNullOrEmpty(model.validation.solution));
    const isAutoScored = questionBehavior.isAutoScored(model);
    const attemptsLeft = currentAttemptCount < practiceAttempts && currentAttemptCount >= 0;
    const isCorrect = (lessonElementState.questionBehavior.isQuestionCorrect(lessonElementState, lessonElementId) === QuestionFeedbackState.CORRECT);

    const forceShowAnswers = toolbarManager.isToolbarAnswerFeedbackActive;

    // If the question has a hint or a sample solution we want those to show when the toolbar answers button is clicked.
    if (forceShowAnswers) {
      questionFeedbackManager.setSubmitVisibleState(lessonElementId, false);
      questionFeedbackManager.setTryAgainVisibleState(lessonElementId, false);
      questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, false);
      if (hasHint) {
        questionFeedbackManager.setShowHintVisibleState(lessonElementId, true);
      } else {
        questionFeedbackManager.setShowHintVisibleState(lessonElementId, false);
      }
      if (hasSolution) {
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, true);
      } else {
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, false);
      }
      questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);
      questionFeedbackManager.setShowQuestionFeedback(lessonElementId, false);
      questionFeedbackManager.setShowAnswers(lessonElementId, true);

      // If the answer is already showing no need to show it again
      if (!lessonElementState.correctAnswerShowing) {
        const mode = lessonManager.playerMode;
        const wrongPracticeMode = (mode === LessonMode.SCORING || mode === LessonMode.REVIEW);
        const isPracticeRunning = (isPractice && !wrongPracticeMode && (currentAttemptCount < practiceAttempts));
        if (lessonElementState.questionBehavior.isAutoScored(model)) {
          lessonElementState.setCachedResponse(UtilsService.safeMobxClone(lessonElementState.currentResponseAnswer));
          lessonElementState.questionBehavior.setCorrectAnswer(lessonElementState, model, isPracticeRunning);
          lessonElementState.turnOnAnswerShowing();
        }
      }

      return;
    }

    // lessonElementState.maxScore === lessonElementState.scoreValue;
    const unscoreNotSaved = (model.unscored && model.type === ContentType.LONG_TEXT.type);
    let unscoreSaved = false;
    if (unscoreNotSaved) {
      unscoreSaved = model.unscored && lessonElementState.questionBehavior.checkForValidResponse(lessonElementState.currentResponseAnswer, model);
    }
    if (mode === LessonMode.ACTIVITY_MODE || mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      /// ////// no feedback or manual or not practice
      if (!isSubmitted && !isPractice && !noSubmit) {
        questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);
        return;
      }

      if (unscoreSaved && (hasFeedback)) {
        const showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.msht);
        const showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.msso);

        responseManager.setReadOnly(lessonElementId, false);
        questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);

        questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, showSolution);
        questionFeedbackManager.setShowQuestionFeedback(lessonElementId, true);
        questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);

        return;
      }

      if (unscoreNotSaved && (hasFeedback)) {
        const showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.msht);

        responseManager.setReadOnly(lessonElementId, false);
        questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);

        questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);

        return;
      }

      if ((isSubmitted) && ((hasFeedback && !isPractice) || !hasFeedback || !isAutoScored)) {
        const showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.msht);
        const showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.msso);

        questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, showSolution);
        questionFeedbackManager.setShowQuestionFeedback(lessonElementId, true);
        if (isAutoScored && hasFeedback) {
          questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);
        }
        return;
      }

      /// ///////////////////////////////////////////////////////////
      /// feedback with practice and autoscore
      if (!isSubmitted && isPractice && attemptsLeft) {
        questionFeedbackManager.setSubmitVisibleState(lessonElementId, true);
        let showHint = false;
        let showSolution = false;

        switch (currentAttemptCount) {
        case 0:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a1bht);
          break;
        case 1:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a2bht);
          break;
        case 2:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a3bht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a3bso);
          break;

        default:
          break;
        }
        questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, showSolution);
        return;
      }

      if (isSubmitted && isPractice && !attemptsLeft && !isCorrect) {
        let showHint = false;
        let showSolution = false;
        let showAnswers = false;

        switch (practiceAttempts) {
        case (1): {
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a1iht);
          showAnswers = customPracticeMatrix.get(QFMFlagKeys.a1ica);
          break;
        }
        case (2): {
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a2iht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a2iso);
          showAnswers = customPracticeMatrix.get(QFMFlagKeys.a2ica);// using the atempt 2 correct because that's a reasonable result when out of attmpts
          break;
        }
        case (3): {
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a3iht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a3iso);
          showAnswers = customPracticeMatrix.get(QFMFlagKeys.a3ica);
          break;
        }
        default:
          break;
        }

        questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, showSolution);
        questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, showAnswers);
        questionFeedbackManager.setShowQuestionFeedback(lessonElementId, true);
        questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);

        return;
      }

      if (isSubmitted && isPractice && !isCorrect && attemptsLeft) {
        let showHint = false;
        let showSolution = false;
        let showAnswers = false;

        questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);
        questionFeedbackManager.setShowQuestionFeedback(lessonElementId, true);
        questionFeedbackManager.setTryAgainVisibleState(lessonElementId, true);

        switch (currentAttemptCount) {
        case 1:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a1iht);
          showAnswers = customPracticeMatrix.get(QFMFlagKeys.a1ica);
          break;
        case 2:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a2iht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a2iso);
          showAnswers = customPracticeMatrix.get(QFMFlagKeys.a2ica);
          break;
        default:
          break;
        }
        questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, showSolution);
        questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, showAnswers);
        return;
      }

      if ((isSubmitted && isPractice && isCorrect)) {
        let showHint = false;
        let showSolution = false;
        let showAnswers = false;

        questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);
        questionFeedbackManager.setShowQuestionFeedback(lessonElementId, true);

        switch (currentAttemptCount) {
        case -1:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a1cht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a1cso);
          break;
        case 1:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a1cht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a1cso);
          showAnswers = false; // If the student got it right on their first attempt, don't show the answers
          questionFeedbackManager.setCurrentAttemptCount(lessonElementId, -1);
          break;
        case 2:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a2cht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a2cso);
          showAnswers = customPracticeMatrix.get(QFMFlagKeys.a2cca);
          questionFeedbackManager.setCurrentAttemptCount(lessonElementId, practiceAttempts);
          break;
        case 3:
          showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.a3cht);
          showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.a3cso);
          showAnswers = customPracticeMatrix.get(QFMFlagKeys.a3cca);
          questionFeedbackManager.setCurrentAttemptCount(lessonElementId, practiceAttempts);
          break;
        default:
          break;
        }
        questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);
        questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, showSolution);
        questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, showAnswers);
      }
    } else if (mode === LessonMode.REVIEW) {
      const showAnswers = customPracticeMatrix.get(QFMFlagKeys.rmca) && isAutoScored;
      const showHint = hasHint && customPracticeMatrix.get(QFMFlagKeys.rmht);
      const showSolution = hasSolution && customPracticeMatrix.get(QFMFlagKeys.rmso);
      questionFeedbackManager.setShowAnswerFeedback(lessonElementId, true);
      questionFeedbackManager.setShowQuestionFeedback(lessonElementId, true);
      questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, showAnswers);
      questionFeedbackManager.setShowHintVisibleState(lessonElementId, showHint);
      questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, showSolution);
    } else if (mode === LessonMode.SCORING) {
      const showAnswers = isAutoScored;
      questionFeedbackManager.setShowAnswerFeedback(lessonElementId, lessonElementState.isSubmitted);
      questionFeedbackManager.setShowQuestionFeedback(lessonElementId, lessonElementState.isSubmitted);
      questionFeedbackManager.setShowAnswersVisibleState(lessonElementId, showAnswers);
      questionFeedbackManager.setShowHintVisibleState(lessonElementId, hasHint);
      questionFeedbackManager.setShowSolutionVisibleState(lessonElementId, hasSolution);
    }
  }

  setShowFeedbackImages = (lessonElementId, toggle) => {
    questionFeedbackManager.setShowFeedbackImages(lessonElementId, toggle);
  }

  setSolutionDialogOpen = (flag) => {
    questionFeedbackManager.setSolutionDialogOpen(flag);
    if (flag) {
      questionFeedbackManager.addButtonUsage('showSolution');
    }
  }

  setHintDialogOpen = (flag) => {
    questionFeedbackManager.setHintDialogOpen(flag);
    if (flag) {
      questionFeedbackManager.addButtonUsage('showHint');
    }
  }

  setupRuntimeDependency = () => {
    const enforce = responseManager.sequentialItemDependency || responseManager.dependencyFlowMap.size > 0;
    if (enforce) {
      if (responseManager.sequentialItemDependency) {
        const questionList = lessonManager.respondableModelList;
        questionList.forEach((model, index) => {
          if (index > 0 /* && (!model.survey && !model.unscored) */) { // in this mode don't restrict first test item
            const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
            const controllerModel = questionList[index - 1];
            const controllerState = responseManager.getLessonElementState(controllerModel.lessonElementId);

            if (controllerModel && controllerState && lessonElementState) {
              lessonElementState.addController(controllerModel.lessonElementId);
              controllerState.addDependent(model.lessonElementId);
            }
          }
        });
      } else {
        const iterator = responseManager.dependencyFlowMap.keys();
        let next = iterator.next();
        while (!next.done) {
          const lessonElementId = next.value;
          const lessonElement = lessonManager.getLessonElement(lessonElementId);
          if (lessonElement && !UtilsService.isNullOrEmpty(lessonElementId)) {
            const controllerArray = responseManager.dependencyFlowMap.get(lessonElementId);
            const dependentState = responseManager.getLessonElementState(lessonElementId);

            if (dependentState && controllerArray && controllerArray.length > 0) {
              controllerArray.forEach((id) => {
                const controllerState = responseManager.getLessonElementState(id);
                if (!controllerState.isSubmitted) {
                  dependentState.addController(id);
                  controllerState.addDependent(lessonElementId);
                }
                if (lessonElement.lessonElementIds && lessonElement.lessonElementIds.length > 0) {
                  lessonElement.lessonElementIds.forEach((childId) => {
                    if (!controllerState.isSubmitted) {
                      const childState = responseManager.getLessonElementState(childId);
                      childState.addController(id);
                      controllerState.addDependent(childId);
                    }
                  });
                }
              });
            }
          }
          next = iterator.next();
        }
      }
    }
  }

  alertDependencyLock = async (lessonElementState) => {
    return await dependentAlert({ lessonElementState });
  }
}

export default new ResponseService();
