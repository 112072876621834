import { useCallback, useRef } from 'react';

function getRefDimensions(ref) {
  if (!ref) {
    return { width: 0, height: 0 };
  }
  return { width: ref.clientWidth, height: ref.clientHeight };
}

function useSizeChange(callback) {
  // Store a referrence to the element as well as its dimensions
  const ref = useRef();
  const resizeObserver = useRef();

  function handleResize() {
    const dims = getRefDimensions(ref.current);
    callback(dims, ref);
  }

  const setRef = useCallback((elem) => {
    if (elem === null) {
      if (resizeObserver.current) resizeObserver.current.disconnect();
      ref.current = null;
    }

    if (elem && elem !== ref.current) {
      ref.current = elem;
      // Remove the observer
      if (resizeObserver.current) resizeObserver.current.disconnect();
      resizeObserver.current = new window.ResizeObserver(handleResize);
      resizeObserver.current.observe(ref.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We return the reference so it can be assigned as well as the dimension state
  return [setRef, ref];
}

export { useSizeChange };
