import React from 'react';
import HtmlComponent from '../lessonPlayer/components/HtmlComponent';

const CustomFeedback = ({ model }) => {
  const { feedback } = model.validation;

  let itemLevelFeedback = feedback && feedback.level === 'item';
  const answerLevelFeedback = feedback && feedback.answers && feedback.level === 'answer';
  // default to item level feedback if feedback is on but feedback level isn't specified
  if (feedback && !itemLevelFeedback && !answerLevelFeedback) {
    // have to check all these because image hot spot editor doesn't remove the feedback prop, but should
    if (feedback.correctFeedback || feedback.incorrectFeedback || feedback.correctFeedbackBody || feedback.incorrectFeedbackBody) {
      itemLevelFeedback = true;
    } else {
      model.validation.feedback = undefined;
    }
  }

  return (
    <>
      <div className='label-value-row'>
        <div>Custom Feedback:</div>
        <div>{(model.validation.feedback) ? 'ON' : 'OFF'}</div>
      </div>
      {itemLevelFeedback && (
        <div className='sub-rows'>
          <div>Item Level Feedback:</div>
          <div className='sub-rows'>
            <div className='standalone-label'>Correct:</div>
            <div className='sub-rows'>
              <div className='label-value-row'>
                <div>Headline:</div>
                <div>{model.validation.feedback.correctFeedback}</div>
              </div>
              <div className='label-value-row'>
                <div>Body:</div>
                <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.feedback.correctFeedbackBody} /></div>
              </div>
            </div>
            <div className='standalone-label'>Incorrect:</div>
            <div className='sub-rows'>
              <div className='label-value-row'>
                <div>Headline:</div>
                <div>{model.validation.feedback.incorrectFeedback}</div>
              </div>
              <div className='label-value-row'>
                <div>Body:</div>
                <div className='ckeditor-text'> <HtmlComponent htmlStr={model.validation.feedback.incorrectFeedbackBody} /></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {answerLevelFeedback && (
        <div className='sub-rows'>
          <div>Individual Answer Feedback</div>
          <div className='sub-rows'>
            {model.answers.map((answer, idx) => {
              const answerId = answer.id;
              const { answers } = model.validation.feedback;
              const feedback = answers[answerId];
              const answerLetter = String.fromCharCode(65 + idx);

              return (
                <React.Fragment key={answerId}>
                  {feedback && (
                    <>
                      <div>{`${answerLetter}:`}</div>
                      <div className='sub-rows'>
                        <div className='label-value-row'>
                          <div>Headline:</div>
                          <div>{feedback.heading}</div>
                        </div>
                        <div className='label-value-row'>
                          <div>Body:</div>
                          <div className='ckeditor-text'> <HtmlComponent htmlStr={feedback.body} /></div>
                        </div>
                      </div>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default CustomFeedback;
