import React, { useContext, useEffect } from 'react';
import Loader from '../Loader';
import '../../../css/DiInfoPage.scss';
import { StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment } from 'react-complex-tree';
import { MobXProviderContext, observer } from 'mobx-react';
import alignmentService from '../../services/AlignmentService';
import { register } from '../../../i18n';

const t = register('InfoPage');

const customItemArrow = ({ item, context }) => {
  return (
    <>
      {(item.hasChildren) ? (context.isExpanded) ? <div className='expanded-icon' /> : <div className='closed-icon' /> : null}
    </>
  );
};

const customItemRender = (props) => {
  const { children, item, title, context } = props;

  context.interactiveElementProps.tabIndex = '0';
  let typeName = null;
  if (item.data.type === 'UNIT') {
    typeName = `${item.data.unitName} ${item.data.orderNum + 1 }`;
  } else if (item.data.type === 'CONTENT') {
    typeName = item.data.subCategoryName;
  } else {
    // eslint-disable-next-line no-unused-vars
    typeName = item.data.type;
  }

  return (
    <>
      {(item.data.covered) ? (
        <li
          {...context.itemContainerWithChildrenProps}
          className='tree-item'
        >
          <div
            className='item-container' {...context.itemContainerWithoutChildrenProps} {...context.interactiveElementProps}>
            {title}
            {(item.data.type !== 'CMAP') &&
            <div dangerouslySetInnerHTML={{ __html: item.data.description }} className='tree-item-description' />}

          </div>
          {children}
        </li>
      ) :
        <li>{children}</li>}
    </>
  );
};

const getTitle = (item) => {
  if (item.data.type === 'CONTENT') {
    return '';
  }
  return item.data.name;
};

const customTreeContainer = (props, root) => {
  return (
    <>
      <div className='alignment-tree-course-name'>{root.data.name}</div>
      <div className='alignment-tree-container' {...props.containerProps}>{props.children}</div>
    </>
  );
};

const DiInfoPage = observer(({ lessonElementId }) => {
  const {
    alignmentManager,
    lessonManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);

  useEffect(() => {
    if (alignmentManager.lessonCoverageAlignments.length === 0) {
      alignmentService.fetchHistoricalContentAlignments(lessonManager.lessonContentItem.id,
        model.reasonId,
        lessonManager.courseContentItemId);
    }
  });

  let treeStructure = null;
  let root = null;
  if (alignmentManager.lessonAlignmentTreeStructure) {
    treeStructure = alignmentManager.lessonAlignmentTreeStructure;
    root = treeStructure.root;
  }

  let banner = t('lessonBanner', 'In this lesson you will cover ...');
  if (lessonManager.isAssessment) {
    banner = t('assessmentBanner', 'After taking this assessment, you will be able to ...');
  }
  return (

    <div className='di-info-detail'>
      <div className='di-info-body'>
        {(treeStructure) ? (
          <>
            <div className='di-info-header'>
              <div className='title-label basic'>{banner}</div>
            </div>
            <div className='scroll-area'>
              <div className='tree-wrapper'>
                <UncontrolledTreeEnvironment
                  canDragAndDrop={false}
                  canReorderItems={false}
                  dataProvider={new StaticTreeDataProvider(treeStructure, (item, data) => ({ ...item, data }))}
                  getItemTitle={getTitle}
                  renderItem={(props) => customItemRender(props)}
                  renderItemArrow={customItemArrow}
                  renderItemTitle={(props) => {
                    const { type } = props.item.data;
                    let cl = ':';
                    if (type === 'CMAP') {
                      cl = '';
                    }
                    return (<div dangerouslySetInnerHTML={{ __html: `${props.title}${cl}` }} className={`tree-item-title ${type}`} />);
                  }}
                  renderItemsContainer={(props) => {
                    const { children, containerProps } = props;
                    return (<ul className={`alignment-tree-list ${(containerProps.role) ? containerProps.role : ''}`} {...containerProps}>{children}</ul>)
                  }}
                  renderTreeContainer={(props) => customTreeContainer(props, root)}
                  viewState={{
                    'tree-1': {
                      expandedItems: alignmentManager.lessonExpandedItems,
                      selectedItems: null
                    },
                  }}
                >
                  <Tree rootItem='root' treeId='tree-1' treeLabel={`${root.data.name}`} />
                </UncontrolledTreeEnvironment>
              </div>
            </div>
          </>
        )
          :
          <div className='dialog-loader'><Loader /></div>}
      </div>

    </div>
  );
});

export default DiInfoPage;
