import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import '../../css/DndKit.scss';

import dragDropService from '../services/DragDropService';
import styleService from '../services/StyleService';
import utilsService from '../services/UtilsService';

import HtmlComponent from './HtmlComponent';

const DndKitOverlay = observer((props = {}) => {
  const {
    dragStartDndKitEvent,
    model
  } = props;

  const { activeStyle, draggableId, droppableId, draggableInnerId, draggableValue } = dragStartDndKitEvent.active.data.current;

  // eslint-disable-next-line no-empty-pattern
  const {
    /* managers */
  } = useContext(MobXProviderContext);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useDraggable({ id: draggableId });

  const { getStyleVar } = styleService;
  const { stripWrappingParagraphTags } = utilsService;

  const isDragging = true;

  const isCustomAnswerBankStyling = model.answerLayoutType === 'manual';

  const currentResponseItem = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || []).find((responseItem) => {
    return responseItem.id === draggableInnerId;
  });

  let absoluteDragDropResponseItemStyles = {};
  if (droppableId === 'drag-drop-options-answer-bank') {
    absoluteDragDropResponseItemStyles = {
      padding: getStyleVar('--theme-drag-drop-option-padding'),
      ...dragDropService.getAbsoluteDragDropResponseItemStyles({
        model,
        responseItem: currentResponseItem,
      })
    };
  } else {
    absoluteDragDropResponseItemStyles = {
      padding: getStyleVar('--theme-drag-drop-option-padding'),
      ...(isCustomAnswerBankStyling && currentResponseItem ?
        dragDropService.getAbsoluteDragDropResponseItemStyles({
          model,
          responseItem: currentResponseItem,
        }) : {}),
      // TODO remove // minWidth: 'max-content',
      // TODO remove // position: 'unset',
      alignItems: 'center',
      left: 'unset',
      top: 'unset'
    };
  }

  return (
    <div
      ref={setNodeRef}
      className={classNames('dnd-kit-overlay', {
        'dnd-kit-overlay-dragging': isDragging
      })}
      style={{
        color: getStyleVar('--theme-drag-drop-option-text-color'),
        userSelect: 'none',
        ...(isDragging ? activeStyle || {} : {}),
        ...(transform ? {
          transform: CSS.Transform.toString(transform)
        } : {}),
        ...absoluteDragDropResponseItemStyles
      }}
      {...listeners}
      {...attributes}>
      <div className='dnd-drag-drop-overlay-inner-text' data-answer-id={draggableId || null}>
        <HtmlComponent htmlStr={stripWrappingParagraphTags(draggableValue)} useSpan />
      </div>
    </div>
  );
});

export default DndKitOverlay;
