import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import RichTextEditor, { MINIMUM_TOOLBAR } from '../RichTextEditor';
import UtilsService from '../../services/UtilsService';
import Loader from '../Loader';
import RubricViewForScoring from './RubricViewForScoring';
import RubricScoreTable from './RubricScoreTable';
import navMenuService from '../../services/NavMenuService';
import responseService from '../../services/ResponseService';
import scoringService from '../../services/ScoringService';
import useWirisParser from '../../../hooks/useWirisParser';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import '../../../css/FlyoutTeacherScorePanel.scss';
import { ContentType, LessonSlideIds, ScoreState } from '../../../Constants';
import timeManager from '../../managers/TimeManager';
import { register } from '../../../i18n';

const t = register('TeacherScoringPanel');
const t2 = register('AriaLabels');



const TeacherScorePanel = observer(({ lessonElementId }) => {
  const {
    responseManager,
    lessonManager,
    scoringManager
  } = useContext(MobXProviderContext);

  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const model = lessonManager.getSlideModel(lessonElementId);
  const teacherScoreRef = useRef();

  const handleChange = (event) => {
    let str = event.target.value;
    if (UtilsService.ensureNumericInput(str)) {
      const num = parseFloat(str);
      const maxScore = parseFloat(lessonElementState.maxScore);

      if (num > maxScore) {
        str = maxScore.toFixed(2);
      }
      if (num < 0) {
        str = '';
      }
      scoringService.setTempScore(lessonElementId, str);
    }
  };

  const handleBlur = (event) => {
    const str = event.target.value;
    if (UtilsService.isNullOrEmpty(str) || str.trim() === '.') {
      scoringService.setTempScore(lessonElementId, '');
    }
  };

  let teacherComment = '';

  if(lessonElementState && !UtilsService.isNullOrEmpty(lessonElementState.comment)) {
    teacherComment = lessonElementState.comment;
  }

  const textRef = useRef();
  useWirisParser(textRef);

  const isSummary = (lessonManager.currentLessonElementId === LessonSlideIds.SUMMARY_PAGE);

  const rubricScored = (lessonElementState && (lessonElementState.scoreState === ScoreState.MANUALLY_SCORED ||
    lessonElementState.scoreState === ScoreState.NOT_SCORED) &&
    scoringManager.rubricMap.has(lessonElementId));

  const time = (lessonElementState && lessonElementState.viewedTime && lessonElementState.viewedTime > 0) ?
    UtilsService.secondsToMMSS(lessonElementState.viewedTime) : (timeManager.getViewTime(lessonElementId) > 0) ?
      UtilsService.secondsToMMSS(timeManager.getViewTime(lessonElementId)) : '00:00';

  useEffect(() => {
    // If user clicks show manualy scored items in toolbar, we didn't have the rubric because the call to fetch the rubric only happened on post
    // navigation. We now check and see if we already have the rubric and if we don't we call fetchContentItemRubric.
    if (navMenuService.shouldGetRubric() && !rubricScored) {
      scoringService.fetchContentItemRubric(lessonElementId, model);
    }
    if (teacherScoreRef && teacherScoreRef.current) {
      teacherScoreRef.current.focus();
    }
  }, [lessonElementId, model, rubricScored]);

  const fullCreditButtonRef = useRef();

  useAccessibilityClick(fullCreditButtonRef, (e) => {
    const maxScore = lessonElementState.maxScore ? parseFloat(lessonElementState.maxScore) : 0;
    if (lessonElementState.maxScore !== scoringManager.getTempScore(lessonElementId)) {
      scoringService.setTempScore(lessonElementId, maxScore);
    }
  });

  const fullCreditRubricButtonRef = useRef();

  useAccessibilityClick(fullCreditRubricButtonRef, (e) => {
    scoringService.setCategoryFullCreditFromTable(lessonElementId);
  });

  return (
    <div className='teacher-score-panel flyout-panel'>
      <div className='panel-header'>
        {isSummary ? '' : (model.unscored) ? t('headerLabelUnscored') : t('headerLabelScore')}
        {(rubricScored && (!model.unscored && lessonElementState.maxScore)) ? (
          <button ref={fullCreditRubricButtonRef} aria-label={t2('givefullcreditbutton')}
            className='fullCreditButton primary'>
            <div aria-hidden className='label'>{t('giveFullCreditLabel', 'Give full credit')}</div>
          </button>
        )
          :
          <></>}
      </div>
      <div className='ui-stack'>
        <div className='top-ui'>
          {(lessonElementState && model && model.isTestItem) ? (
            <>
              {(rubricScored) && <RubricViewForScoring lessonElementId={lessonElementId} />}

              <div className='teacher-score-wrapper'>
                <div className='teacher-score-container'>
                  {(rubricScored && !model.unscored) && <RubricScoreTable lessonElementId={lessonElementId} />}
                  {(!rubricScored && scoringManager.tempScores.has(lessonElementId) && !model.unscored) && (
                    <div className='teacher-score-container'>
                      <div className='teacher-score'>
                        <span>{t('points')}:&nbsp;</span>
                        <input ref={teacherScoreRef} aria-label={t2('scoreoverridefield')} onBlur={handleBlur} onChange={handleChange}
                          type='text'
                          value={scoringManager.getTempScore(lessonElementId)} />
                        <span>/</span><span>{lessonElementState.maxScore}</span>
                        <span>
                          {lessonElementState.maxScore && (
                            <button ref={fullCreditButtonRef} aria-label={t2('givefullcreditbutton')}
                              className='fullCreditButton primary'>
                              <div aria-hidden className='label'>{t('giveFullCreditLabel')}</div>
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className='teacher-usage-container'>
                    <div className='teacher-usage'><span>{t('usage')}:&nbsp;</span><span className='usage-value'>{time}</span> </div>
                  </div>
                </div>
              </div>
              <div className='teacher-notes-wrapper'>
                <div className='teacher-notes-container'>
                  <div className='teacher-notes-textbox-wrapper'>
                    <div className='teacher-comments-label'>{t('teacherComments')}:</div>
                    <RichTextEditor
                      data={teacherComment}
                      onBlur={(data) => {
                        responseService.teacherNotesChanged(data, lessonElementId);
                      }} toolbar={MINIMUM_TOOLBAR} />
                  </div>
                </div>
              </div>
            </>
          )
            :
            (lessonElementState && model && model.type === ContentType.STUDENT_INSTRUCTION.type) ? (
              <>
                <div className='teacher-score-wrapper'>

                  <div className='teacher-score-container'>
                    <div className='teacher-usage-container'>
                      <div className='teacher-usage'><span>{t('usage')}:&nbsp;</span><span className='usage-value'>{time}</span></div>
                    </div>
                  </div>

                </div>
                <div className='teacher-notes-wrapper'>
                  <div className='teacher-notes-container'>
                    <div className='teacher-notes-textbox-wrapper'>
                      <div className='teacher-comments-label'>{t('teacherComments')}:</div>
                      <RichTextEditor
                        data={teacherComment}
                        onBlur={(data) => {
                          responseService.teacherNotesChanged(data, lessonElementId);
                        }} toolbar={MINIMUM_TOOLBAR} />
                    </div>
                  </div>
                </div>
              </>
            )
              : (
                <div className='no-teacher-notes'>
                </div>
              )}
        </div>
      </div>
    </div>
  );
});

export default TeacherScorePanel;
