import Utils from './UtilsService';

import { addThemeTranslations } from '../../i18n';

import lessonManager from '../managers/LessonManager';
import responseService from './ResponseService';
import toolbarService from './ToolbarService';
import lessonService from './LessonService';
import navMenuManager from '../managers/NavMenuManager';
import userManager from '../managers/UserManager';
import toolbarManager from '../managers/ToolbarManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import responseManager from '../managers/ResponseManager';

export class ItemPreviewService {
  async fetchItemPreviewData(contentItemId, mode, feedbackModeRequested, profileId) {
    if (mode) {
      lessonManager.setPlayerMode(mode);
    }

    if (profileId) {
      await lessonService.fetchLessonProfile(profileId);
    }
    await this.fetchPlaceholderLesson(contentItemId, feedbackModeRequested, profileId);

    const { lessonOptions } = lessonManager;
    // Getting translation overrides for item preview
    if (!Utils.isNullOrEmpty(lessonOptions.publisherTranslationId)) {
      const translationOverrides = await lessonService.fetchTranslations(lessonOptions.publisherTranslationId);
      addThemeTranslations('theme', translationOverrides);
    }
    lessonManager.setTranslationsLoaded(true);

    await this.fetchPlaceholderLessonContent(contentItemId);
  }

  async fetchPlaceholderLesson(contentItemId, feedbackModeRequested, profileId) {
    try {
      let lessonOptions = null;
      if (profileId) {
        lessonOptions = lessonManager.lessonOptions;
      } else {
        lessonOptions = {
          hasSummaryPage: false,
          copyright: '',
          customNumbering: false,
          correctFeedback: 'CORRECT!',
          hasItemNumberBanner: false,
          practiceAttempts: '1',
          hasHat: false,
          showSubtitleHeader: false,
          hasItem: false,
          itemFlow: {
            sequentialItemDependency: false,
            dependencies: {

            }
          },
          testItemFeedback: 'immediate',
          hasSectionBanner: false,
          titlePageImageUrl: '',
          incorrectFeedback: 'INCORRECT',
          title: 'Item Preview',
          hideTitlePage: true,
          isPractice: false,
          showTitle: false,
          popupActivityNavigation: false,
          hasSmallGotoCards: false,
          hasCardQuestionNumber: true,
          hasCardGoToText: true,
          hasNumberedGotoCards: true,
          smallCardQuestionNumber: true,
          navigateWithinActivity: false,
          forceLandscapeOnMobile: false,
          simpleToolbar: false,
          feedbackModes: [
            {
              modeId: 'mode_1',
              modeName: 'Default Mode',
              isDefault: true,
              testItemFeedback: 'immediate',
              isPractice: false,
              practiceAttempts: '1',
              customPracticeMatrix: {
              },
              correctFeedback: 'CORRECT!',
              incorrectFeedback: 'INCORRECT'
            }
          ],
          modeId: 'mode_1',
          studentMathToolbar: 'advanced',
          hasToolbar: true,
          tools: [
            {
              type: 'Logout',
              label: 'Exit',
              toolbar: 'header',
              buttonType: 'action',
              target: 'lesson',
              isDefaultOn: true
            }
          ],
          themeCss: '',
          returnValidationForManuallyScored: false
        };
      }

      const response = {
        lesson: {
          id: 'item-preview-lesson-id',
          permanent: false,
          standardsRequired: true,
          hasAlignments: false,
          duration: 45,
          imageFilename: null,
          imageUrl: 'http://localhost:8080/api/redirectToResource?resourceFolder=userFiles&resourceFileName=',
          creationDate: '09/20/2022',
          modificationDate: '09/20/2022',
          entityType: 'lesson',
          contentItem: {
            id: 'item-preview-lesson-id',
            name: 'Assessment Name',
            subtitle: '',
            description: 'Item Preview Description',
            imageUrl: null,
            altText: null,
            color: '',
            lockedById: null,
            entityId: 'item-preview-lesson-id',
            entityType: 'LESSON',
            entityTypeId: 'lesson',
            ownerId: 'item-preview-owner-id',
            orderNum: 0,
            lockDate: null,
            lockOwner: false,
            categoryTags: [],
            standardFrameworkElements: [],
            displayName: 'Assessment Name'
          },
          themeClassName: 'theme01',
          optionsJson: '',
          differentiations: []
        },
        statusMessage: '',
        status: 'SUCCESS'
      };

      if (response.lesson) {
        const { lesson } = response;
        lessonManager.setLesson(lesson);

        lessonManager.dependencies = {};
        if (lessonOptions && lessonOptions.itemFlow && lessonOptions.itemFlow.dependencies) {
          lessonManager.dependencies = lessonOptions.itemFlow.dependencies;
          responseManager.setSequentialItemDependency(lessonOptions.itemFlow.sequentialItemDependency);
        }

        await toolbarService.processAccommodations();

        if (lessonOptions && lessonOptions.tools) {
          const adjustedToolbarOptions = lessonOptions.tools.filter((obj) => {
            return !toolbarManager.excludedButtonTypes.includes(obj.type);
          });

          lessonOptions.tools = adjustedToolbarOptions;
        }

        if (feedbackModeRequested) {
          lessonOptions.feedbackModeRequested = feedbackModeRequested;
        } else {
          lessonOptions.feedbackModeRequested = null;
        }

        lessonManager.setLessonOptions(lessonOptions);

        questionFeedbackManager.setQuestionFeedbackMode(lessonOptions, null);

        let toolbarOptions = null;
        if (lessonOptions && lessonOptions.tools) {
          toolbarOptions = lessonOptions.tools;
        }
        const { contentItem } = lesson;
        lessonManager.setContentItem(contentItem);

        if (lessonOptions && lessonOptions.publisherThemeId) {
          await lessonService.fetchPublisherTheme(lessonOptions.publisherThemeId);
        } else {
          lessonManager.setThemeLoaded(true);
        }

        await toolbarService.initToolbar(lessonOptions, toolbarOptions);
        const { isStudent } = userManager;

        toolbarService.setTeacherToolVisibility(!isStudent, toolbarOptions);
        await lessonService.initializeSubscribedTTS();
      }
    } catch (e) {
      console.error(e);
      lessonManager.setLoaded(0);
    }
  }

  async fetchPlaceholderLessonContent(contentItemId) {
    try {
      const fullItemResponse = await lessonService.fetchItemStructureAndContent(contentItemId);
      const fullItemResponseContent = fullItemResponse.content;
      const fullItemResponseStructure = fullItemResponse.structure;

      if (fullItemResponse) {
        if (fullItemResponseContent) {
          const content = JSON.parse(fullItemResponseContent);
          const placeholderLessonContent = [];
          placeholderLessonContent.push(content);

          const rootStructure =
          [
            {
              id: 'lesson-id',
              name: '',
              orderNum: 0,
              diOrderNum: null,
              questionNum: null,
              type: 'Lesson',
              entityId: 'item-preview-lesson-id',
              entityTypeId: 'Lesson',
              lessonId: 'item-preview-lesson-id',
              lockDate: null,
              lockedById: null,
              hasNotes: false,
              hasMetadata: false,
              metadataCount: 0,
              maxScore: null,
              lessonElementIds: [
                contentItemId
              ],
              content: null
            }
          ];

          const structure = [...rootStructure, ...fullItemResponseStructure];

          lessonManager.contentMap = Utils.mapById(content);
          lessonManager.structureMap = Utils.mapById(structure);

          const rootObj = structure.find((element) => element.entityId === contentItemId);
          this.activityLessonElements = [];
          await lessonService.traverseLessonElements(rootObj);
          lessonService.setQuestionNumbers();
          if (!Utils.isObjectNullOrEmpty(lessonManager.dependencies)) {
            await lessonService.setLessonElementDependencies(rootObj);
          }
          // For standalone items, we build groups differently.
          lessonService.buildStandaloneItemActivityGroups();
          lessonService.buildTitleAndSummaryPages();
          lessonManager.setRootObject(rootObj);
          // This is set elsewhere. If we set it here there is bug that tries to render components
          // before lessonElementStates are created
          // lessonManager.setCurrentSlideIndex(0);
          navMenuManager.initSummarySectionToggles(lessonManager.sectionList);
          await responseService.initSlideResponses();
        }
      }
      return null;
    } catch (e) {
      console.error(e);
    }
  }
}
export default new ItemPreviewService();
