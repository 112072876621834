import Auth from './AuthService';
import studentActivityManager from '../managers/StudentActivityManager';
import lessonManager from '../managers/LessonManager';
import toolbarManager from '../managers/ToolbarManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import { LessonMode, LessonSlideIds } from '../../Constants';
import responseManager from '../managers/ResponseManager';
import responseService from './ResponseService';
import scoringManager from '../managers/ScoringManager';
import navMenuService from './NavMenuService';
import timeManager from '../managers/TimeManager';
import highlightAnnotationManager from '../managers/HighlightAnnotationManager';
import highlightAnnotationService from './HighlightAnnotationService';
import UtilsService from './UtilsService';

export class StudentActivityService {
   fetchStudentActivity = async (activityId) => {
     try {
       const response = await Auth.fetch(`${Auth.ecms}/api/viewActivityStatus?activityId=${activityId}`, {
         method: 'GET',
       });
       if (response.status === 'SUCCESS') {
         studentActivityManager.setStudentActivity(response, activityId);
         if (response.additionalPropertiesJson &&
            (lessonManager.playerMode === LessonMode.ACTIVITY_MODE ||
              lessonManager.playerMode === LessonMode.REVIEW ||
              lessonManager.playerMode === LessonMode.SCORING)) {
           let modeId = null;
           if (!UtilsService.isNullOrEmpty(response.additionalPropertiesJson)) {
             const additionalProperties = JSON.parse(response.additionalPropertiesJson);
             if (typeof additionalProperties !== 'undefined') {
               modeId = additionalProperties.publisherModeId;
             }
           }

           questionFeedbackManager.setQuestionFeedbackMode(lessonManager.lessonOptions, modeId);
         }
         return true;
       }
     } catch (e) {
       console.error(e);
       return false;
     }
   }

  fetchActivityUsers = async (activityId, studentId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewActivityUsers?activityId=${activityId}`, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        studentActivityManager.setActivityUsers(response.users, studentId);
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  saveLastViewed = async (lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);

    if (lessonElementId !== LessonSlideIds.SUMMARY_PAGE && lessonElementId !== LessonSlideIds.TITLE_PAGE) {
      await Auth.fetch(`${Auth.ecms}/api/markLastContentItemViewed`,
        {
          method: 'POST',
          body: {
            activityId: studentActivityManager.activityId,
            contentObjectId: model.id
          }
        });
    }
  }

  saveActivityNote = async (noteText) => {
    const response = await Auth.fetch(`${Auth.ecms}/api/createUpdateNotepadNoteController`,
      {
        method: 'POST',
        body: {
          activityId: studentActivityManager.activityId,
          noteText
        }
      });

    if (response.status === 'SUCCESS') {
      studentActivityManager.setActivityNoteText(noteText);
    }
  }

  previousStudentHandler = async () => {
    lessonManager.setLoaded(0);// just make sure to clear it;
    lessonManager.setLoaded(1);
    await navMenuService.doPreNavigationActions();
    const student = studentActivityManager.getPreviousStudent();
    responseManager.clearResponses();
    scoringManager.rubricMap.clear();
    await responseService.doScoringModeStartUp(student.activityId, student.id, null, true);
    await navMenuService.doPostNavigationActions();
    lessonManager.setLoaded(0);
    // no need to slow down the highlights with async, these will show post loaded
    if (highlightAnnotationManager.highlightUserMode && highlightAnnotationManager.highlightUserMode !== 'off') {
      highlightAnnotationService.switchHighightAndNotesView(highlightAnnotationManager.highlightUserMode);
    }
  }

  nextStudentHandler = async () => {
    lessonManager.setLoaded(0);// just make sure to clear it;
    lessonManager.setLoaded(1);
    await navMenuService.doPreNavigationActions();
    const student = studentActivityManager.getNextStudent();
    responseManager.clearResponses();
    scoringManager.rubricMap.clear();
    await responseService.doScoringModeStartUp(student.activityId, student.id, null, true);
    await navMenuService.doPostNavigationActions();
    lessonManager.setLoaded(0);
    // no need to slow down the highlights with async, these will show post loaded
    if (highlightAnnotationManager.highlightUserMode && highlightAnnotationManager.highlightUserMode !== 'off') {
      highlightAnnotationService.switchHighightAndNotesView(highlightAnnotationManager.highlightUserMode);
    }
  }

  markActivityElementAsViewed = async (lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    if (lessonElementId !== LessonSlideIds.SUMMARY_PAGE && lessonElementId !== LessonSlideIds.TITLE_PAGE) {
      const lessonElementState = responseManager.getLessonElementState(lessonElementId);
      let toolPersistence = {};
      if (lessonElementState && lessonElementState.toolPersistence) {
        toolPersistence = lessonElementState.toolPersistence;
      }

      const toolUsage = toolbarManager.contentToolUsage;
      const toolAvailability = toolbarManager.contentToolAvailability;
      const { buttonUsage } = questionFeedbackManager;
      const { buttonAvailability } = questionFeedbackManager;
      const viewTime = UtilsService.ensureNumericInput(timeManager.contentItemViewedTime.get(lessonElementId)) ?
        timeManager.contentItemViewedTime.get(lessonElementId) : 0;

      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/markActivityElementAsViewed`,
          {
            method: 'POST',
            body: {
              activityId: studentActivityManager.activityId,
              entityId: lessonElementId,
              contentObjectId: model.id,
              viewedTime: viewTime,
              playedTime: 0,
              markedForReview: lessonElementState.markedForReview,
              toolPersistence: JSON.stringify(toolPersistence),
              toolUsage: toolUsage.join(),
              toolAvailability: toolAvailability.join(),
              buttonUsage: buttonUsage.join(),
              buttonAvailability: buttonAvailability.join()
            }
          });

        if (response && response.status === 'SUCCESS') {
          toolbarManager.clearContentToolUsage();
          // toolbarManager.clearContentToolAvailability();
          questionFeedbackManager.clearButtonUsage();
          questionFeedbackManager.clearButtonAvailability();
          return true;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  }

  fetchStudentNotepadNote = async (activityId) => {
    if (studentActivityManager.studentId) {
      // Requesting a student's data.
      try {
        const result = await Auth.fetch(`${Auth.ecms}/api/getStudentNotepadNote?activityId=${activityId}&studentId=${studentActivityManager.studentId}`, {
          method: 'GET'
        });
        if (result.status === 'SUCCESS') {
          studentActivityManager.setActivityNoteText(result.noteText);
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }

   getLastViewedItem = () => {
     return studentActivityManager.lastContentItemViewed;
   }
}
export default new StudentActivityService();
