import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Auth from '../../services/AuthService';
import HtmlComponent from '../HtmlComponent';
import UtilsService from '../../services/UtilsService';
import lessonService from '../../services/LessonService';

const ImageResource = observer(({ lessonElementId, model }) => {
  const imgSrc = `${Auth.ecms}/api/redirectToResource?contentItemId=${model.resourceContentItemId}&authKey=${Auth.authKey}`;
  const imgSize = `${model.scale * 100}%`;

  const [altText, setAltText] = useState('');

  useEffect(() => {
    const doAction = async () => {
      if (UtilsService.isNullOrEmpty(model.altText) && !model.altTextLoaded) {
        const contentItem = await lessonService.fetchContentItem(model.contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        }
      }
    };
    doAction();
  });

  return (
    <>
      <div className='element-container' id={`_${model.id}`}>
        <div>
          {model.text &&
          <div className='textInput imageLabelTitle'><HtmlComponent htmlStr={model.text} /></div>}
        </div>
        <img alt={model.altText || altText || ''} className='max-img-size' src={imgSrc} style={{ width: imgSize }} />
      </div>
    </>
  );
});
export default ImageResource;
