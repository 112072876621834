import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import GraphicOrganizerTextField from './GraphicOrganizerTextField';
import UtilsService from '../../services/UtilsService';

const BubbleWebTextLayout = observer(({ lessonElementId, canvas, readOnly }) => {
  const {
    lessonManager,
    responseManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;
  const hasAResponse = (currentResponseAnswer && currentResponseAnswer.prompts && currentResponseAnswer.prompts.length > 0);

  let textContainerCount = 0;

  return (
    <div className='graphic-organizer text-area-layout'>
      {
        canvas.getObjects().map((obj) => {
          let text = (textContainerCount === 0) ? model.centerText : model.circleText[textContainerCount - 1];

          const isReadOnly = (readOnly || !UtilsService.isNullOrEmpty(text));
          const isBold = !UtilsService.isNullOrEmpty(text);

          if (obj.type === 'circle') {
          // check hasInput for circle now... may be used later for other objs.
            if (obj.hasInput) {
              const id = `promptId${ textContainerCount++}`;
              if (hasAResponse) {
                const foundPrompt = currentResponseAnswer.prompts.find((e) => e.id === id);
                if (foundPrompt) {
                  // don't check for read only again in case the user hasn't submitted.
                  text = foundPrompt.text;
                }
              }
              const width = 2 * Math.sqrt(obj.radius * obj.radius / 2);
              const delta = (2 * obj.radius - width) / 2;
              return (
                <GraphicOrganizerTextField key={model.lessonElementId + id} height={width} isBold={isBold} left={obj.left + delta}
                  lessonElementId={model.lessonElementId} promptId={id} readOnly={isReadOnly} text={text}
                  top={obj.top + delta} width={width} />
              );
            }
          }

          return (<div key={model.lessonElementId + textContainerCount} style={{ display: 'none' }} />);
        })
      }
    </div>
  );
});

export default BubbleWebTextLayout;
