import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

// remove manuscript when all components are built and manuscript items are removed from
// preview, graded and review modes
import '../css/Player.scss';
import '../css/Manuscript.scss';
import '../css/TestItems.scss';
import '../css/CkeditorContentStyles.scss';

import { addThemeTranslations } from '../i18n';

import { LessonMode } from '../Constants';

import useLessonInit from '../hooks/useLessonInit';
import InternetMessage from '../lessonPlayer/components/InternetMessage';

import BackgroundShade from '../lessonPlayer/layout/BackgroundShade';
import ContentLayout from '../lessonPlayer/layout/ContentLayout';
import FooterLayout from '../lessonPlayer/layout/FooterLayout';
import FullpageLoader from '../lessonPlayer/components/FullpageLoader';
import HiddenAudioPlayer from '../lessonPlayer/components/HiddenAudioPlayer';
import ScoringToolbarLayout from '../lessonPlayer/layout/ScoringToolbarLayout';

const ScoreMode = observer(() => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  useLessonInit(LessonMode.SCORING);

  useEffect(() => {
    const className = `${LessonMode.SCORING} theme-override`;
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute('class', className);

  });

  return (
    <>
      {(lessonManager.isOnline && lessonManager.isLoaded && ((lessonManager.themeLoaded && lessonManager.translationsLoaded) || lessonManager.hasNoTheme)) ? (
        <div className='lesson-window'>
          <BackgroundShade>
            <HiddenAudioPlayer />
            <ScoringToolbarLayout />
            <ContentLayout isScoring={true} />
            <FooterLayout />
          </BackgroundShade>
        </div>
      ) : (!lessonManager.isOnline) ?
        (
          <InternetMessage />
        )
        : (
          <FullpageLoader />
        )}
    </>
  );
});
export default ScoreMode;
