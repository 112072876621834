/* eslint-disable array-callback-return */
import { fabric } from 'fabric';
import FreeGraphService from './FreeGraphService';
import bookDrawManager, { TOOL_TYPE } from '../managers/BookDrawManager';
import Auth from './AuthService';
import { AnnotationStamp, STAMP_TYPES } from '../managers/obseverableObjects/AnnotationStamp';
import UtilsService from './UtilsService';
import DefaultDialog from '../components/dialogs';
import lessonManager from '../managers/LessonManager';
import { LessonMode } from '../../Constants';
import studentActivityManager from '../managers/StudentActivityManager';
import userManager from '../managers/UserManager';

export class BookDrawService extends FreeGraphService {
  setPages(model, lessonElementId) {
    // this.drawCanvas = [];
    this.pageIds = [];
    for (const page of model.pages) {
      this.pageIds.push(page.id);
    }
  }

  drawCanvas = [];

  lessonElementId = null;
  pageIds = [];

  currentStrokeWidth = 1;
  currentStrokeColor = '#000';
  currentFillColor = 'transparent';

  BOX_WIDTH = 120;
  BOX_HEIGHT = 80;
  BOX_TOP_MARGIN = 40;
  TRIANGLE = 'triangle';
  SQUARE = 'square';
  CIRCLE = 'circle';
  STAR = 'star';

  type = 'Booklet';

  getLabel = () => {
    return 'booklet';
  }

  isAutoScore = () => {
    return false;
  }

  isOrganizer = () => {
    return false;
  }

  isTestItem = () => {
    return true;
  }

  getCanvasId = (pageId) => {
    return `${this.getId()}_${pageId}_canvas`;
  }

  initialize = () => {
    this.setDefaultEditMode();
  }

  setStrokeColor = (color) => {
    this.currentStrokeColor = color;

    for (const pageId of this.pageIds) {
      const activeObject = this.drawCanvas[pageId].getActiveObject();
      if (activeObject) {
        activeObject.stroke = color;
        activeObject.dirty = true;
        this.drawCanvas[pageId].renderAll();
        this.saveAnnotation(pageId, this.getDrawCanvasJson(pageId));
      } else {
        const groupElements = this.findCanvasElementGroups(pageId);
        groupElements.forEach((groupElement) => {
          if (groupElement.isSelected) {
            this.colorGroupElement(groupElement, color);
            this.updateState();
            this.saveAnnotation(pageId, this.getDrawCanvasJson(pageId));
          }
        });
      }
    }

    // workaround to force the color change of the current tool - recreate the fabric pencil or paint tool
    const tool = this.getSelectedToolbarButton();
    if (tool === TOOL_TYPE.PENCIL) {
      this.onClickPencilTool();
    }
  }

  findCanvasElementGroups = (pageId) => {
    return this.drawCanvas[pageId].getObjects().filter((obj) => obj.isElementGroup);
  }

  setStrokeWidth = (value) => {
    this.setBrushWidth(value);
    this.currentStrokeWidth = value;

    for (const pageId of this.pageIds) {
      const activeObject = this.drawCanvas[pageId].getActiveObject();
      if (activeObject) {
        activeObject.strokeWidth = value;
        activeObject.dirty = true;
        this.drawCanvas[pageId].renderAll();
        this.saveAnnotation(pageId, this.getDrawCanvasJson(pageId));
      }
    }
  };

  getId = () => {
    return this.model.id;
  }

  getImageTitleId = () => {
    return `${this.getId() }_imageLabelTitle`;
  };

  getDrawCanvasId = (pageId) => {
    return `${pageId}_drawCanvas`;
  };

  deselectAllDrawObjects = (pageId) => {
    const self = this;
    const objects = this.drawCanvas[pageId]?.getObjects();

    if (objects?.length > 0) {
      objects.map((object, index) => {
        if (object && object.active) {
          object.set({ active: false });
        }
      });
      self.drawCanvas[pageId].renderAll();
    }
  }

  deselectDrawObjectsOnAllPages = () => {
    for (const pageId of this.pageIds) {
      this.drawCanvas[pageId]?.discardActiveObject().renderAll();
    }
  }

  selectFirstDrawObject = (pageId) => {
    const self = this;
    const objects = this.drawCanvas[pageId].getObjects();

    if (objects.length > 0) {
      objects[0].set({ active: true });

      self.drawCanvas[pageId].renderAll();
    }
  }

  deleteDrawObjects = () => {
    for (const pageId of this.pageIds) {
      const activeObjects = this.drawCanvas[pageId].getActiveObjects();

      if (activeObjects.length > 0) {
        activeObjects.forEach((object) => {
          this.drawCanvas[pageId].remove(object);
        });
        this.drawCanvas[pageId].discardActiveObject().renderAll();
        this.saveAnnotation(pageId, this.getDrawCanvasJson(pageId));
      }
    }
  }

  deleteCurrentStamp = async () => {
    if (bookDrawManager.selectedStamp) {
      await this.deleteAnnotation(bookDrawManager.selectedStamp.stampId);
    }
  }

  setDefaultEditMode = () => {
    this.setElementEditMode(this.DRAW);
  }

  setElementEditMode = (elementType) => {
    const isDrawEditMode = elementType === this.DRAW;
    this.elementEditMode = elementType;

    // TODO: fix this
    // this.$get().attr("data-draw-mode", this.elementEditMode);

    if (isDrawEditMode) {
      bookDrawManager.setDrawEditMode(true);
      this.setDrawingMode(true, TOOL_TYPE.PENCIL);
    } else {
      bookDrawManager.setDrawEditMode(false);
    }
  }

  /**
   * Returns the canvas JSON.
   */
  getDrawCanvasJson = (pageId) => {
    let json = {};
    if (this.drawCanvas != null && this.drawCanvas[pageId] !== undefined) {
      json = this.drawCanvas[pageId].toDatalessJSON();
    }

    return json;
  };

  createDrawCanvas = (model) => {
    const self = this;
    const width = model.pageWidth / 2;
    const height = model.pageHeight;
    const settings = {
      width,
      height,
    };

    for (const pageId of this.pageIds) {
      if (!this.drawCanvas[pageId]) {
        const drawCanvas = new fabric.Canvas(this.getDrawCanvasId(pageId), settings);

        this.drawCanvas[pageId] = drawCanvas;

        this.drawCanvas[pageId].off('mouse:down');
        this.drawCanvas[pageId].on('mouse:down', (evt) => {
          // if (utils.isMobile()) {
          //   $("body").addClass("noContentScroll");
          // }

          // If no target of click, then switch to the graph canvas.
          if (!evt.target && this.drawCanvas[pageId] !== undefined && !this.drawCanvas[pageId].isDrawingMode) {
            this.setElementEditMode('');
          }
        });

        this.drawCanvas[pageId].off('mouse:up');
        this.drawCanvas[pageId].on('mouse:up', (evt) => {
          self.saveAnnotation(pageId, self.getDrawCanvasJson(pageId));
        });
      } else {
        const div = document.getElementById(this.getDrawCanvasId(pageId));
        this.drawCanvas[pageId].initialize(div, settings);
        this.drawCanvas[pageId].off('mouse:up');
        this.drawCanvas[pageId].on('mouse:up', (evt) => {
          self.saveAnnotation(pageId, self.getDrawCanvasJson(pageId));
        });
      }
    }
  }

  setBrushWidth = (width) => {
    for (const pageId of this.pageIds) {
      this.drawCanvas[pageId].freeDrawingBrush.width = width;
    }
  }

  setDrawCanvasFromJson = (pageId, json) => {
    this.drawCanvas[pageId].loadFromJSON(json, () => {
      this.drawCanvas[pageId].renderAll();
    });
  }

  onGraphClickNoTarget = () => {
    this.deselectAllElements();
    this.setElementEditMode(this.DRAW);
    this.setDrawingMode(false);
  }

  deleteFromDraw = () => {
    for (const pageId of this.pageIds) {
      this.deleteDrawObjects(pageId);
    }
  }

  select = () => {
    this.setDrawingMode(false);
    this.selectToolbarButton('selectToolButton');
    bookDrawManager.setTool('selection');
  }

  createRuntimeEvents = () => {
    const self = this;
    for (const pageId of this.pageIds) {
      self.drawCanvas[pageId].on(
        'selection:created', () => {
          // console.log('drawCanvas selection created');
          // self.setAllObjectControlsOff();
        },
        'path:created', () => {
          // console.log('drawCanvas path created');
          self.saveAnnotation(pageId, self.getDrawCanvasJson(pageId));
        },
        'object:modified', () => {
          // console.log('drawCanvas object modified');
          self.saveAnnotation(pageId, self.getDrawCanvasJson(pageId));
        },
        'object:added', () => {
          // console.log('drawCanvas object added');
          self.saveAnnotation(pageId, self.getDrawCanvasJson(pageId));
        });
    }
  }

  onClickPencilTool = (event, ui) => {
    this.setElementEditMode(this.DRAW);
    this.setDrawingMode(true, TOOL_TYPE.PENCIL);
    this.selectToolbarButton('pencilToolButton');
    for (const pageId of this.pageIds) {
      this.drawCanvas[pageId].freeDrawingBrush = new fabric.PencilBrush(this.drawCanvas[pageId]);
      this.drawCanvas[pageId].freeDrawingBrush.isSpray = false;

      this.drawCanvas[pageId].freeDrawingBrush.width = this.currentStrokeWidth;
      this.drawCanvas[pageId].freeDrawingBrush.color = this.currentStrokeColor;
      this.drawCanvas[pageId].freeDrawingBrush.fill = this.currentFillColor;
    }
  };

  setZoom=(zoom) => {
    bookDrawManager.zoom = zoom;
  }

  setDrawingMode = (drawMode, tool) => {
    for (const pageId of this.pageIds) {
      if (this.drawCanvas[pageId]) {
        if (typeof drawMode === 'undefined') {
          drawMode = this.drawCanvas[pageId].isDrawingMode;
        }
        this.drawCanvas[pageId].isDrawingMode = drawMode;

        if (this.drawCanvas[pageId].isDrawingMode) {
          this.deselectAllDrawObjects(pageId);
        } else {
          this.selectFirstDrawObject(pageId);
        }

        bookDrawManager.setDrawingMode(drawMode);

        bookDrawManager.setTool(tool);

        this.drawCanvas[pageId].renderAll();
      }
    }
  }

  selectToolbarButton = (button) => {
    if (this.drawCanvas) {
      const toolbarButtons = document.getElementsByClassName('drawToolbarButton');
      Array.from(toolbarButtons).forEach((button) => button.classList.remove('drawToolbarButtonSelected'));

      const selectedButton = document.getElementsByClassName(button)[0];
      selectedButton.classList.add('drawToolbarButtonSelected');
    }
  }

  deselectToolbarButtons = () => {
    if (this.drawCanvas) {
      const toolbarButtons = document.getElementsByClassName('drawToolbarButton');

      Array.from(toolbarButtons).forEach((button) => {
        button.classList.remove('drawToolbarButtonSelected');
        button.blur();
      });
    }
  }

  getSelectedToolbarButton = () => {
    if (this.drawCanvas) {
      const toolbarButtons = document.getElementsByClassName('drawToolbarButtonSelected');
      const selectedButton = toolbarButtons[0];
      return selectedButton?.dataset?.tool;
    }
  }

  runtimeObjectReady = (model) => {
    this.setDefaultEditMode();
    this.createDrawCanvas(model);
    this.createRuntimeEvents();
  }

  handleAddStamp = (e, pageRef, page) => {
    if (bookDrawManager.tool === TOOL_TYPE.STAMPS || bookDrawManager.tool === TOOL_TYPE.NOTES) {
      const scaleX = pageRef.current.getBoundingClientRect().width / pageRef.current.offsetWidth;
      const x = ((e.clientX - pageRef.current.getBoundingClientRect().x) - 10) / scaleX;
      const y = ((e.clientY - pageRef.current.getBoundingClientRect().y) - 10) / scaleX;
      const containerWidth = pageRef.current.offsetWidth;
      const containerHeight = pageRef.current.offsetHeight;
      const leftPercent = 100 * x / containerWidth;
      const topPercent = 100 * y / containerHeight;
      const stampIcon = bookDrawManager.selectedIcon;
      const hasDiscussion = false;

      const stamp = new AnnotationStamp();
      stamp.setData({
        id: UtilsService.createGuid(),
        type: (bookDrawManager.tool === TOOL_TYPE.STAMPS) ? STAMP_TYPES.STAMP : STAMP_TYPES.NOTE,
        iconId: stampIcon,
        hasDiscussion,
        pageId: page.id,
        leftPercent,
        topPercent
      });

      bookDrawManager.addStamp(page.id, stamp);

      bookDrawManager.setSelectedIcon(null);
      bookDrawManager.setTool(null);
      this.saveAnnotation(page.id, stamp);
      bookDrawManager.selectStamp(page.id, stamp.id);
    }
  }

  saveAnnotation = async (pageId, data) => {
    let id = `${bookDrawManager.entityId.slice(0, 10)}${pageId.slice(0, 10)}${userManager.userId.slice(0, 10)}`;
    const isStamp = (data.type === STAMP_TYPES.STAMP || data.type === STAMP_TYPES.NOTE);
    if (data.type && isStamp) {
      id = data.id;
    } else {
      data.pageId = pageId;
    }
    try {
      await Auth.fetch(`${Auth.ecms}/api/saveEntityAnnotation`,
        {
          method: 'POST',
          body: {
            entityId: bookDrawManager.entityId,
            entityTypeId: 'content_item',
            annotationId: id,
            annotation: JSON.stringify(data)
          },
        });
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  fetchAnnotations = async () => {
    try {
      const url = (lessonManager.playerMode === LessonMode.SCORING) ?
        `${Auth.ecms}/api/viewEntityAnnotations?entityId=${bookDrawManager.entityId}&userId=${studentActivityManager.studentId}` :
        `${Auth.ecms}/api/viewEntityAnnotations?entityId=${bookDrawManager.entityId}`;
      const response = await Auth.fetch(url, {
        method: 'GET',
      });

      if (response.status === 'SUCCESS') {
        const annotationDataArray = response.data;
        if (annotationDataArray && annotationDataArray.length > 0) {
          annotationDataArray.forEach((annotationData) => {
            // const entityId = annotationData.entityId;

            const annotation = JSON.parse(annotationData.annotation);
            const { type } = annotation;
            const { pageId } = annotation;
            let id = `${bookDrawManager.entityId.slice(0, 10)}${pageId.slice(0, 10)}${userManager.userId.slice(0, 10)}`;
            if (lessonManager.playerMode === LessonMode.SCORING) {
              id = `${bookDrawManager.entityId.slice(0, 10)}${pageId.slice(0, 10)}${studentActivityManager.studentId.slice(0, 10)}`;
            }

            if (id !== annotationData.id && type !== STAMP_TYPES.STAMP && type !== STAMP_TYPES.NOTE) {
              return;
            }
            if (type === STAMP_TYPES.STAMP && annotation.iconId.includes('sticky-note')) {
              annotation.type = STAMP_TYPES.NOTE;
            }
            if (type === STAMP_TYPES.NOTE || type === STAMP_TYPES.STAMP) {
              const stamp = new AnnotationStamp();
              stamp.setData(annotation);
              bookDrawManager.addStamp(pageId, stamp);
            } else {
              this.setDrawCanvasFromJson(pageId, annotationData.annotation);
            }
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  fetchDiscussions = async () => {
    try {
      if (bookDrawManager.selectedStamp === null) {
        return;
      }

      const response = await Auth.fetch(`${Auth.ecms}/api/viewUserDiscussionForEntityContext?parentEntityId=${bookDrawManager.entityId}&entityId=${bookDrawManager.selectedStamp.stampId}&entityTypeId=content_item&contextType=stampAnnotationDiscussion`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        bookDrawManager.addDiscussions(response.userDiscussions);
      }
    } catch (e) {
      console.error(e);
    }
  }

  createOrUpdateDiscussion = async (comment, discussionId, parentId) => {
    try {
      if (bookDrawManager.selectedStamp === null) {
        return;
      }

      const response = await Auth.fetch(`${Auth.ecms}/api/addUpdateUserDiscussion`, {
        method: 'POST',
        body: {
          elementId: bookDrawManager.selectedStamp.stampId,
          parentEntityId: bookDrawManager.entityId,
          parentId: (parentId) || null,
          entityId: bookDrawManager.selectedStamp.stampId,
          entityTypeId: 'content_item',
          discussionId: (discussionId) || null,
          contextType: 'stampAnnotationDiscussion',
          comment
        },
      });

      if (response.status === 'SUCCESS') {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);

      return false;
    }
  }

  deleteDiscussionNote = async (discussionId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/deleteUserDiscussion`, {
        method: 'POST',
        body: {
          parentEntityId: bookDrawManager.entityId,
          discussionId
        },
      });

      if (response.status !== 'SUCCESS') {
        DefaultDialog.fire({
          title: `${t('errorAlert')}`,
          html: response.statusMessage,
          showCancelButton: false,
          buttonsStyling: false,
          confirmButtonText: `${UtilsService.confirmOk()}` ,
          showClass: {
            backdrop: 'swal2-noanimation',
            popup: '',
            icon: ''
          },
          hideClass: {
            popup: '',
          }
        });
      }
    } catch (e) {
      console.error(e);

      return false;
    }
  }

  resolveUserDiscussion= async (discussionId, isResolved) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/resolveUserDiscussion`, {
        method: 'POST',
        body: {
          parentEntityId: bookDrawManager.entityId,
          discussionId,
          isResolved
        },
      });
      if (response.status === 'SUCCESS') {
        bookDrawManager.resolveDiscussion(discussionId, isResolved);
      }
    } catch (e) {
      console.error(e);

      return false;
    }
  }

  fetchDiscussionSummary = async (lessonElementId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewUserDiscussionSummaries`, {
        method: 'POST',
        body: {
          parentEntityId: bookDrawManager.entityId,
          discussionAccessIds: bookDrawManager.getStampIds(),
          contextType: 'stampAnnotationDiscussion'
        }
      });
      if (response.status === 'SUCCESS') {
        bookDrawManager.setDiscussionCounts(response.userDiscussionSummaries, lessonElementId);
      }
    } catch (e) {
      console.error(e);

      return false;
    }
  }

  deleteAnnotation = async (stampId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/deleteEntityAnnotation`, {
        method: 'POST',
        body: {
          id: stampId
        }
      });
      if (response.status === 'SUCCESS') {
        bookDrawManager.deleteCurrentStamp();
      }
    } catch (e) {
      console.error(e);

      return false;
    }
  }

  clearNotes = () => {
    bookDrawManager.clearNotes();
  }

  clearPages = () => {
    this.drawCanvas = [];
    this.pageIds = [];
  }
}
export default new BookDrawService();
