import React from 'react';
import { observer } from 'mobx-react';

import '../../../css/VideoResource.scss';

import VideoQuestion from './VideoQuestion';

const VideoResource = observer(({ lessonElementId, model }) => {
  // Video resource should use directions flyout the same way video activity does.
  return (
    <>
      <VideoQuestion lessonElementId={lessonElementId} model={model} openQuestionSlide={() => {}} />
    </>
  );
});
export default VideoResource;
