import { register } from './i18n';

const t = register('LessonItemNames');

export const LessonMode = {
  ACTIVITY_MODE: 'activity_mode', // student doing an activity (assignment/assessment) not to be confused with
  ASSESSMENT_BUILDER: 'assessment_builder', // Building assessments from item banks
  ITEM_PREVIEW: 'item_preview', // admin or teacher view of basic resource item
  MANUSCRIPT: 'manuscript', // editor getting a printable view of the lesson
  PREVIEW: 'preview', // teacher just looking at the lesson/assessment
  PRINT_PREVIEW: 'print_preview', // teacher printing the lesson
  PUBLISHER_PREVIEW: 'publisher_preview', // teacher just looking at the lesson/assessment
  REVIEW: 'review', // student reviewing a lesson after it's been graded.
  SCORING: 'scoring', // teacher manually grading the assessment/assignment or confirming autograded scores.
  STUDENT_PREVIEW: 'student_preview', // student just looking at the lesson/assessment
  // the question type called activity
};

export const Accommodations = {
  ASSESSMENT_BASIC_CALCULATOR: 'ASM_BASIC_CALC',
  ASSESSMENT_CONTENT_LANGUAGE: 'ASM_CONTENT_LANG',
  ASSESSMENT_GRAPHING_CALCULATOR: 'ASM_GRAPH_CALC',
  ASSESSMENT_SCIENTIFIC_CALCULATOR: 'ASM_SCI_CALC',
  ASSESSMENT_SPELLCHECKER: 'ASM_SPELLCHECKER',
  ASSESSMENT_TEXT_SPEECH: 'ASM_TEXT_SPEECH',
  ASSESSMENT_TRANSLATION: 'ASM_TRANSLATION',
  LESSON_BASIC_CALCULATOR: 'LSN_BASIC_CALC',
  LESSON_CONTENT_LANGUAGE: 'LSN_CONTENT_LANG',
  LESSON_DICTIONARY: 'LSN_DICTIONARY',
  LESSON_GRAPHING_CALCULATOR: 'LSN_GRAPH_CALC',
  LESSON_SCIENTIFIC_CALCULATOR: 'LSN_SCI_CALC',
  LESSON_SPELLCHECKER: 'LSN_SPELLCHECKER',
  LESSON_TEXT_SPEECH: 'LSN_TEXT_SPEECH',
  LESSON_TRANSLATION: 'LSN_TRANSLATION'
};

export const ContentType = {
  ANALOGIES: { type: 'Analogies', label: 'Analogies', catagory: 'graphicOrganizer', autoscore: false },
  ARTICLE: { type: 'Article', label: 'Article Resource', catagory: 'resource', autoscore: false },
  ARTICLE_QUESTION: { type: 'ArticleQuestion', label: 'Passage Activity', catagory: 'testItem', autoscore: false },
  AUDIO: { type: 'Audio', label: 'Audio Resource', catagory: 'resource', autoscore: false },
  AUDIO_HIGHLIGHT: { type: 'AudioHighlight', label: 'Audio Highlight', catagory: 'testItem', autoscore: false },
  AUDIO_QUESTION: { type: 'AudioQuestion', label: 'Audio Activity', catagory: 'testItem', autoscore: false },
  BOOKMARK: { type: 'Bookmark', label: 'Bookmark', catagory: 'resource', autoscore: false },
  BUBBLE_WEB: { type: 'BubbleWeb', label: 'Bubble Web', catagory: 'graphicOrganizer', autoscore: false },
  CATEGORIZE: { type: 'Categorize', label: 'Categorize', catagory: 'testItem', autoscore: true },
  CATEGORY_TREE: { type: 'CategoryTree', label: 'Categorize Tree', catagory: 'graphicOrganizer', autoscore: false },
  CAUSE_AND_EFFECT: { type: 'CauseAndEffect', label: 'Cause and Effect', catagory: 'graphicOrganizer', autoscore: false },
  CHOICE_MATRIX: { type: 'ChoiceMatrix', label: 'Choice Matrix', catagory: 'testItem', autoscore: true },
  CHOICE_MATRIX_MULTI: { type: 'ChoiceMatrixMulti', label: 'Multiple Choice Table', catagory: 'testItem', autoscore: true },
  CIRCLE_DEFINE: { type: 'CircleDefine', label: 'Define', catagory: 'graphicOrganizer', autoscore: false },
  CLIP_FRAGMENT: { type: 'ClipFragment', label: 'ClipFragment Resource', catagory: 'resource', autoscore: false },
  CLOZE: { type: 'Cloze', label: 'Fill In The Blank', catagory: 'testItem', autoscore: true },
  CLOZE_COMBO: { type: 'ClozeCombo', label: 'Fill In The Blank Combo', catagory: 'testItem', autoscore: true },
  CLOZE_DRAG_DROP: { type: 'ClozeDragDrop', label: 'Fill In The Blank Drag-Drop', catagory: 'testItem', autoscore: true },
  CLOZE_MATH: { type: 'ClozeMath', label: 'Fill In The Blank Math', catagory: 'testItem', autoscore: true },
  CLOZE_MULTICHOICE: { type: 'ClozeMultichoice', label: 'Fill In The Blank Multiple Choice', catagory: 'testItem', autoscore: true },
  CLOZE_SPELLING: { type: 'ClozeSpelling', label: 'Fill In The Blank Spelling', catagory: 'testItem', autoscore: true },
  COMPARE_CONTRAST: { type: 'CompareContrast', label: 'Compare and Contrast', catagory: 'graphicOrganizer', autoscore: false },
  CORNELL_NOTES: { type: 'CornellNotes', label: 'Cornell Notes', catagory: 'graphicOrganizer', autoscore: false },
  DOWNLOAD_AUTOSCORED: { type: 'DownloadAutoscored', label: 'Download Autoscored', catagory: 'instruction', autoscore: false },
  DOUBLE_ENTRY_JOURNAL: { type: 'DoubleEntryJournal', label: 'Double Entry Journal', catagory: 'testItem', autoscore: false },
  DRAW: { type: 'Draw', label: 'Free-Draw', catagory: 'testItem', autoscore: false },
  FRAYER_MODEL: { type: 'FrayerModel', label: 'Frayer Model', catagory: 'graphicOrganizer', autoscore: false },
  FREE_GRAPH: { type: 'FreeGraph', label: 'Free Graph', catagory: 'testItem', autoscore: true },
  GOOGLE_DOC: { type: 'GoogleDoc', label: 'Google Doc', catagory: 'testItem', autoscore: false },
  GOOGLE_SHEET: { type: 'GoogleSheet', label: 'Google Sheet', catagory: 'testItem', autoscore: false },
  GOOGLE_SLIDE: { type: 'GoogleSlide', label: 'Google Slide', catagory: 'testItem', autoscore: false },
  IMAGE: { type: 'Image', label: 'Image Resource', catagory: 'resource', autoscore: false },
  IMAGE_LABEL: { type: 'ImageLabel', label: 'Image Label', catagory: 'testItem', autoscore: true },
  IMAGE_LABEL_DRAG_DROP: { type: 'ImageLabelDragDrop', label: 'Image Label Drag-Drop', catagory: 'testItem', autoscore: true },
  IMAGE_LABEL_MULTICHOICE: { type: 'ImageLabelMultichoice', label: 'Image Label Multiple Choice', catagory: 'testItem', autoscore: true },
  IMAGE_HOT_SPOT: { type: 'ImageHotSpot', label: 'Image Hot Spot', catagory: 'testItem', autoscore: true },
  IMAGE_MATCH: { type: 'ImageMatch', label: 'Image Match', catagory: 'testItem', autoscore: true },
  IMAGE_TEXT_MATCH: { type: 'ImageTextMatch', label: 'Image Text Match', catagory: 'testItem', autoscore: true },
  IMAGE_TEXT_MATCH_FLIP: { type: 'ImageTextMatchFlip', label: 'Image Text Match Flip', catagory: 'testItem', autoscore: false },
  IMAGE_QUESTION: { type: 'ImageQuestion', label: 'Image Activity', catagory: 'testItem', autoscore: false },
  KWL_CHART: { type: 'KWLChart', label: 'KWLChart', catagory: 'graphicOrganizer', autoscore: false },
  LESSON_PLAN: { type: 'LessonPlan', label: 'Lesson Plan', catagory: 'resource', autoscore: false },
  LINE_GRAPH: { type: 'LineGraph', label: 'Line Graph', catagory: 'testItem', autoscore: true },
  LINK_LIST: { type: 'LinkList', label: 'Link List', catagory: 'instruction', autoscore: false },
  LIST_QUESTION: { type: 'ListQuestion', label: 'List Activity', catagory: 'testItem', autoscore: false },
  LONG_TEXT: { type: 'LongText', label: 'Long Text', catagory: 'testItem', autoscore: false },
  MULTICHOICE: { type: 'Multichoice', label: 'Multiple Choice', catagory: 'testItem', autoscore: true },
  MULTISELECT: { type: 'Multiselect', label: 'Multiple Select', catagory: 'testItem', autoscore: true },
  NUMBER_LINE: { type: 'NumberLine', label: 'Number Line', catagory: 'testItem', autoscore: true },
  ORDER_LIST: { type: 'OrderList', label: 'Order List', catagory: 'testItem', autoscore: true },
  ORDER_SENTENCES: { type: 'OrderSentences', label: 'Order Sentences', catagory: 'testItem', autoscore: true },
  PDF: { type: 'Pdf', label: 'PDF', catagory: 'resource', autoscore: false },
  PLACE_POINT: { type: 'PlacePoint', label: 'Place Point', catagory: 'testItem', autoscore: true },
  PRESENTATION: { type: 'Presentation', label: 'Presentation', catagory: 'instruction', autoscore: false },
  RELATIONSHIPS: { type: 'Relationships', label: 'Relationships', catagory: 'graphicOrganizer', autoscore: false },
  RUBRIC: { type: 'Rubric', label: 'Rubric', catagory: 'resource', autoscore: false },
  SEQUENCE: { type: 'Sequence', label: 'Sequence', catagory: 'graphicOrganizer', autoscore: false },
  SHORT_TEXT: { type: 'ShortText', label: 'Short Text', catagory: 'testItem', autoscore: true },
  GRIDDABLE: { type: 'Griddable', label: 'Griddable', catagory: 'testItem', autoscore: true },
  STUDENT_INSTRUCTION: { type: 'StudentInstruction', label: 'Student Instruction', category: 'Instruction', autoscore: false },
  T_CHART: { type: 'TChart', label: 'TChart', catagory: 'graphicOrganizer', autoscore: false },
  TEACHER_INSTRUCTION: { type: 'TeacherInstruction', label: 'Teacher Instruction', category: 'Instruction', autoscore: false },
  TEXT: { type: 'Text', label: 'Text Resource', catagory: 'testItem', autoscore: false },
  TEXT_HIGHLIGHT: { type: 'TextHighlight', label: 'Text Highlight', catagory: 'testItem', autoscore: true },
  TEXT_SELECTOR: { type: 'TextSelector', label: 'Text Selector', category: 'testItem', autoscore: true },
  HOT_TEXT: { type: 'HotText', label: 'Hot Text', category: 'testItem', autoscore: true },
  KEYBOARDING: { type: 'Keyboarding', label: 'Key Drill', category: 'resource', autoscore: false },
  UPLOAD: { type: 'Upload', label: 'Upload', catagory: 'testItem', autoscore: false },
  UPLOAD_AUTOSCORED: { type: 'UploadAutoscored', label: 'Upload Autoscored', catagory: 'testItem', autoscore: false },
  USER_FILE: { type: 'UserFile', label: 'UserFile Resource', catagory: 'resource', autoscore: false },
  VENN: { type: 'Venn', label: 'Venn', catagory: 'graphicOrganizer', autoscore: false },
  VIDEO: { type: 'Video', label: 'Video Resource', catagory: 'resource', autoscore: false },
  VIDEO_QUESTION: { type: 'VideoQuestion', label: 'Video Activity', catagory: 'testItem', autoscore: false },
  MULTIPART_ACTIVITY: { type: 'MultipartActivity', label: 'Multipart Activity', catagory: 'testItem', autoscore: false },
  BOOKMARK_ACTIVITY: { type: 'BookmarkActivity', label: 'Bookmark Activity', catagory: 'testItem', autoscore: false },
  BOOK_ACTIVITY: { type: 'BookletActivity', label: 'Book Activity', catagory: 'testItem', autoscore: false },
  VOCABULARY: { type: 'Vocabulary', label: 'Vocabulary', catagory: 'testItem', autoscore: false },
  WEB_PAGE: { type: 'WebPage', label: 'Web Page', catagory: 'instruction', autoscore: false },
  WORD_ASSOCIATION: { type: 'WordAssociation', label: 'Word Association', catagory: 'graphicOrganizer', autoscore: false },
  TITLE_PAGE: { type: 'TitlePage', label: 'Title Page', catagory: 'instruction', autoscore: false },
  SUMMARY_PAGE: { type: 'SummaryPage', label: 'Summary Page', catagory: 'instruction', autoscore: false },
  DIFFERENTIATION_REASON: { type: 'DI', label: 'Differentiation Reason', category: 'instruction', autoscore: false },
  SECTION: { type: 'Section', label: 'Section', category: 'instruction', autoscore: false },
  ITEM_RESOURCE: { type: 'ItemResource', label: 'Item Resource', category: 'instruction', autoscore: false },
  TEST_ITEM: { type: 'TestItem', label: 'Test Item', category: 'instruction', autoscore: false },
  TEST_ITEM_PARENT: { type: 'TestItemParent', label: 'Test Item Parent', category: 'instruction', autoscore: false },
  getLabel: (type) => {
    const contentTypes = Object.values(ContentType);
    for (let i = 0; i < contentTypes.length; ++i) {
      if (contentTypes[i].type === type) {
        return t(type);
      }
    }
    return '';
  },
  getAutoscore: (type) => {
    const contentTypes = Object.values(ContentType);
    for (let i = 0; i < contentTypes.length; ++i) {
      if (contentTypes[i].type === type) {
        return contentTypes[i].autoscore;
      }
    }
    return false;
  },
};

export const LessonSlideIds = {
  TITLE_PAGE: 'titlePage',
  SUMMARY_PAGE: 'summaryPage'
};

export const FlyoutOpenState = {
  OPEN: 1,
  CLOSED: 0,
  OPEN_WIDE: 2
};

export const FlyoutSide = {
  LEFT: 1,
  RIGHT: 0
};

export const QuestionFeedbackState = {
  CORRECT: 1,
  INCORRECT: 0,
  PARTIAL_CORRECT: 2
};

export const ActivityStatus = {
  ACTIVITY_CLOSED: 'closed',
  ACTIVITY_COMPLETED: 'completed',
  ACTIVITY_LOCKED: 'locked',
  ACTIVITY_PRESENTATION: 'presentation',
  ACTIVITY_READY: 'ready',
  ACTIVITY_STARTED: 'started',
};

export const FlyoutTabs = {
  NONE: -1,
  ALIGN: 0,
  SCORE: 1,
  NOTES: 2,
  RUBRIC: 3,
  ENGAGEMENT: 4
};

export const ScoreState = {
  NOT_SCORED: 'NotScored',
  AUTO_SCORED: 'AutoScored',
  MANUALLY_SCORED: 'ManuallyScored',
  MANUALLY_OVERRIDDEN: 'ManuallyOverridden',
};

export const PartialScoringType = {
  EXACT: 'exact',
  PARTIAL: 'partial',
  PARTIAL_MATCH_RESPONSE: 'partialMatchResponse'
};

export const ImageLabelMargins = {
  trueNone: 0,
  none: 50,
  small: 125,
  medium: 250,
  large: 374
};

export const UploadResponseOption = {
  OPTION_RECORD_VIDEO: {
    className: 'option-record-video',
    label: 'recordVideo',
    modelKey: 'allowRecordVideo',
    value: 'OPTION_RECORD_VIDEO'
  },
  OPTION_RECORD_AUDIO: {
    className: 'option-record-audio',
    label: 'recordAudio',
    modelKey: 'allowRecordAudio',
    value: 'OPTION_RECORD_AUDIO'
  },
  OPTION_UPLOAD_FILE: {
    className: 'option-upload-file',
    label: 'uploadFile',
    modelKey: 'allowUploadFile',
    value: 'OPTION_UPLOAD_FILE'
  }
};

export const WebmTrackType = {
  AUDIO: 2,
  COMPLEX: 3,
  VIDEO: 1
};

export const CKEDITOR_DEV_LICENSE_KEY = 'UzNGT2ZsNTJhZ2hCSTRZZFU3MjRYem41S0FTNkN4aWljdm43UUF1Y0tGK25PaEd1Wm55QVgyMlduZHM2bWxBUy1NakF5TlRBeE1ETT0=';

export const WPROOFREADER_SOURCE_URL = 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js';

export const GUID_SIZE = 32;

export const ONE_HOUR_IN_SECONDS = 3600;

export const ONE_FULL_CIRCLE_ROTATION = 360;

export const COMMON_TRIGONOMETRY_ANGLES = [0, 30, 45, 60, 90, 180, 270, 360];

export const PLAYABLE_UPLOADED_FILE_TYPES = ['MP3', 'MP4', 'WEBM'];
export const PREVIEWABLE_UPLOADED_FILE_TYPES = ['GIF', 'JPG', 'JPEG', 'PNG'];

/*
Options for global color picker tool. These are the theme overrides that the user can select from.
Each theme has one required key, humanName, and a bunch of override groups with a
corresponding css value (mostly color, but could be anything).
Override groups are defined below in GlobalColorVariableMap.
If a theme does not include one of the override groups,
the default value is used for those style variables.
*/
export const GlobalColorOptions = {
  default: {
    humanName: 'Default'
  },
  whiteOnBlack: {
    background: '#000000',
    containerBackground: '#222222',
    containerText: '#ffffff',
    buttonBackgroundHover: '#222222',
    buttonBackgroundClick: '#222222',
    text: '#ffffff',
    inverseText: '#000000',
    borders: '#ffffff',
    buttonBorder: '#ffffff',
    buttonBackground: '#000000',
    lighterBackground: '#707070',
    humanName: 'White on Black'
  },
  redOnWhite: {
    background: '#ffffff',
    text: '#ff0000',
    borders: '#ff0000',
    buttonBackgroundHover: '#f0f0f0',
    buttonBackgroundClick: '#f0f0f0',
    buttonBorder: '#ff0000',
    buttonBackground: '#ffffff',
    lighterBackground: '#ffffff',
    humanName: 'Red on White'
  },
  whiteOnRed: {
    background: '#ff0000',
    containerBackground: '#ff3535',
    containerText: '#ffffff',
    buttonBackgroundHover: '#ff3535',
    buttonBackgroundClick: '#ff3535',
    text: '#ffffff',
    inverseText: '#ff0000',
    borders: '#ffffff',
    buttonBorder: '#ffffff',
    buttonBackground: '#ff0000',
    lighterBackground: '#ff5555',
    humanName: 'White on Red'
  },
  greenOnWhite: {
    background: '#ffffff',
    text: '#80bd44',
    borders: '#80bd44',
    buttonBackgroundHover: '#f0f0f0',
    buttonBackgroundClick: '#f0f0f0',
    buttonBorder: '#80bd44',
    buttonBackground: '#ffffff',
    lighterBackground: '#ffffff',
    humanName: 'Green on White'
  },
  whiteOnGreen: {
    background: '#4c9603',
    containerBackground: '#55a803',
    containerText: '#ffffff',
    buttonBackgroundHover: '#55a803',
    buttonBackgroundClick: '#55a803',
    lighterBackground: '#66c804',
    text: '#ffffff',
    inverseText: '#4c9603',
    borders: '#ffffff',
    buttonBorder: '#ffffff',
    buttonBackground: '#4c9603',
    humanName: 'White on Green'
  },
  blueOnWhite: {
    background: '#ffffff',
    text: '#006e9f',
    borders: '#006e9f',
    buttonBackgroundHover: '#f0f0f0',
    buttonBackgroundClick: '#f0f0f0',
    lighterBackground: '#ffffff',
    buttonBorder: '#006e9f',
    buttonBackground: '#ffffff',
    humanName: 'Blue on White'
  },
  whiteOnBlue: {
    background: '#1d50a2',
    containerBackground: '#205ec0',
    containerText: '#ffffff',
    buttonBackgroundHover: '#205ec0',
    buttonBackgroundClick: '#205ec0',
    text: '#ffffff',
    inverseText: '#1d50a2',
    borders: '#ffffff',
    buttonBorder: '#ffffff',
    buttonBackground: '#1d50a2',
    lighterBackground: '#2464cc',
    humanName: 'White on Blue'
  },
  blackOnYellow: {
    background: '#d6d801',
    containerBackground: '#eaec03',
    containerText: '#000000',
    buttonBackgroundHover: '#eaec03',
    buttonBackgroundClick: '#eaec03',
    text: '#000000',
    inverseText: '#d6d801',
    borders: '#000000',
    buttonBorder: '#000000',
    buttonBackground: '#d6d801',
    lighterBackground: '#fafe10',
    humanName: 'Black on Yellow'
  },
  yellowOnBlack: {
    background: '#000000',
    containerBackground: '#222222',
    containerText: '#ffffff',
    buttonBackgroundHover: '#222222',
    buttonBackgroundClick: '#222222',
    text: '#d6d801',
    inverseText: '#000000',
    borders: '#d6d801',
    buttonBorder: '#d6d801',
    buttonBackground: '#000000',
    lighterBackground: '#707070',
    humanName: 'Yellow on Black'
  },
  blackOnLightBlue: {
    background: '#77b8f5',
    containerBackground: '#8ec5f8',
    containerText: '#000000',
    buttonBackgroundHover: '#8ec5f8',
    buttonBackgroundClick: '#8ec5f8',
    text: '#000000',
    inverseText: '#77b8f5',
    borders: '#000000',
    buttonBorder: '#000000',
    buttonBackground: '#77b8f5',
    lighterBackground: '#a0cdf8',
    humanName: 'Black on Light Blue'
  },
  blackOnGrey: {
    background: '#dadadb',
    text: '#000000',
    borders: '#000000',
    buttonBackgroundHover: '#ffffff',
    buttonBackgroundClick: '#ffffff',
    buttonBorder: '#000000',
    buttonBackground: '#dadadb',
    lighterBackground: '#e6e6e6',
    humanName: 'Black on Grey'
  },
  whiteOnMagenta: {
    background: '#ce4ea8',
    containerBackground: '#df55b6',
    containerText: '#ffffff',
    buttonBackgroundHover: '#df55b6',
    buttonBackgroundClick: '#df55b6',
    text: '#ffffff',
    inverseText: '#ce4ea8',
    borders: '#ffffff',
    buttonBorder: '#ffffff',
    buttonBackground: '#ce4ea8',
    lighterBackground: '#da77bc',
    humanName: 'White on Magenta'
  }
};

/*
In order to get contrast working properly, we need to divide the styles to override into a set of categories:
(Styles are applied in the order they are listed here so that the last one overrides the first one. This should work since ES2015.)

Background: By common definition.
ToolbarBackground: The background of the toolbar.
FooterBackground: The background of the footer.

Text: The text color.

ButtonBackground: The background of the buttons.
ButtonBackgroundHover: The background of the buttons when hovered.
ButtonBackgroundClick: The background of the buttons when clicked.
ButtonBorder: The border of the buttons.
ButtonText: The text color of the buttons.

Borders: Toolbar and footer borders
ToolbarBorder: The border of the toolbar.
FooterBorder: The border of the footer.

ContainerBackground: The background of containers (i.e. The background for the title on the title page).
ContainerText: The color of text in the containers (i.e. The title).
*/
export const GlobalColorVariableMap = {
  background: ['--toolbar-background',
    '--goto-card-selected-bg-color',
    '--goto-card-inner-bg-color',
    '--goto-card-bg-color',
    '--activity-nav-button-bg-color',
    '--activity-nav-button-select-bg-color',

    '--theme-background-color',
    '--footer-background',
    '--model-border-color',
    '--theme-table-header-bg-color', '--theme-hint-dialog-bg-color',
    '--text-selector-selected-bg-color',
    '--theme-drag-drop-option-bg-color',
    '--theme-drag-drop-dragover-color',
    '--theme-text-input-bg-color',
    '--theme-dropdown-closed-menu-focus'
  ],

  toolbarBackground: ['--toolbar-background'], // Overrides background
  footerBackground: ['--footer-background'], // Overrides background

  text: [
    '--toolbar-button-font-color', '--toolbar-icon-background-color',
    '--theme-font-color', '--theme-first-color',
    '--theme-primary-button-color',
    '--modal-header-font-color',
    '--goto-card-selected-color',
    '--hot-text-color',
    '--theme-title-font-color',
    '--theme-numbered-circle-color',
    '--theme-drag-drop-option-text-color',
    '--theme-text-input-font-color',
    '--theme-dropdown-open-menu-text'
  ],
  inverseText: ['--theme-inverse-font-color'],

  buttonBorder: ['--theme-primary-button-border-color',
    '--nav-border-color',
    '--theme-pop-color',
  ],
  buttonBackground: [
    '--theme-primary-button-bg-color',
    '--nav-background-color',
    '--theme-primary-button-click',
    '--theme-primary-button-hover',
    '--nav-button-click-color',
    '--nav-button-hover-color'
  ],
  buttonBackgroundHover: [
    '--theme-primary-button-hover', '--nav-button-hover-color'
  ], // Overrides buttonBackground
  buttonBackgroundClick: [
    '--theme-primary-button-click', '--nav-button-click-color'
  ], // Overrides buttonBackground

  borders: ['--hot-text-border-color', '--toolbar-border-color', '--footer-border',
    '--theme-blank-content-border-color', '--goto-card-border-selected',
    '--theme-drag-drop-option-border-color', '--theme-dashed-border-color'],

  toolbarBorder: ['--toolbar-border-color'], // Overrides borders
  footerBorder: ['--footer-border'], // Overrides borders

  containerBackground: ['--theme-second-color', '--toolbar-toggle-on-bg-color', '--toolbar-hover-bg-color'],
  containerText: ['--theme-title-font-color', '--summary-font-color'],
  lighterBackground: [
    '--theme-blank-content-color',
    '--theme-correct-feedback-bg-color',
    '--theme-incorrect-feedback-bg-color',
    '--theme-nuetral-feedback-bg-color',
    '--hot-text-bg-color',
    '--theme-second-color',
    '--theme-second-background-color'
  ]
};

export const GlobalFontSizes = {
  '--theme-font-size-banner': 0,
  '--theme-font-size-banner-xlarge': 0,
  '--theme-font-size-large': 0,
  '--theme-font-size-small': 0,
  '--theme-font-size-subbanner': 0,
  '--theme-font-size-tiny': 0,
  '--theme-font-size-xlarge': 0,
  '--theme-font-size-xxlarge': 0,
  '--theme-font-size-xxxlarge': 0,
  '--theme-font-size': 0,
  '--theme-secondary-font-size': 0,
  '--theme-numbered-circle-font-size': 0,
  '--goto-card-font-size': 0,
  '--summary-card-font-size': 0,
  '--nav-button-font-size': 0,
  '--theme-ckeditor-font-size': 0
};
