import React from 'react';

const RadioButton = ({ selected }) => {
  const fill = (selected) ? '#666' : '#fff';

  return (
    <svg height='18' width='18'>
      <circle cx='9' cy='9' fill='none' r='8'
        stroke='#666' strokeWidth='2' />
      <circle cx='9' cy='9' fill={fill} r='5' />
    </svg>
  );
};
export default RadioButton;
