import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import Moveable from 'react-moveable';

import '../../../css/Tools.scss';

import { LessonMode } from '../../../Constants';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import { register } from '../../../i18n';

import toolbarManager from '../../managers/ToolbarManager';

import htmlService from '../../services/HtmlService';

const t = register('AriaLabels')

const Guideline = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    responseManager,
  } = useContext(MobXProviderContext);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const savePersistence = (data) => {
    if (lessonManager.playerMode === LessonMode.ACTIVITY_MODE) {
      lessonElementState?.setPersistentToolData('Guideline', data);
    }
  };

  const getPersistence = () => {
    return lessonElementState?.getPersistentToolData('Guideline');
  };

  const [isLoadingGuideline, setIsLoadingGuideline] = useState(false);

  // Get references to the tool and the container
  const guidelineRootRef = useRef(null);
  const guidelineToolRef = useRef(null);

  // Locking
  const lockedRef = useRef(null);
  const [locked, setLocked] = useState(false);
  const clickLock = () => {
    setLocked((locked) => !locked);
  };
  useAccessibilityClick(lockedRef, clickLock);

  // Position and boundary
  const [boundary, setBoundary] = useState();
  const [yPos, _setYPos] = useState(5);
  const setYPos = (y) => {
    // TODO this is preventing the ability to move the Guideline past a certain point for scrollable content
    // Clamp in the boundary if it exists
    // if (boundary) {
    //   y = Math.min(Math.max(y, boundary.top), boundary.bottom);
    // }
    savePersistence({
      boundary,
      id: 'Guideline',
      isActive: toolbarManager.isToolOn('Guideline'),
      locked,
      y
    });
    _setYPos(y);
  };

  useEffect(() => {
    initGuideline();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initGuidelineBoundary();
  }, [guidelineRootRef]);

  const resetGuideline = (yPos = 20) => {
    setIsLoadingGuideline(true);
    setTimeout(() => {
      setBoundary(undefined);
      setYPos(yPos);
      initGuideline();
      setIsLoadingGuideline(false);
      setTimeout(() => {
        initGuidelineBoundary();
      }, 500);
    }, 500);
  };

  const initGuideline = () => {
    // We want to recall the position of the guideline while navigating around if possible
    // The persistent object is assumed to be the same object on each render so we can use it to recall the position
    const recalled = getPersistence();
    if (recalled) {
      if (isNaN(recalled.y)) {
        recalled.y = 0;
      }
      setYPos(recalled.y);
      setBoundary(recalled.boundary);
      setLocked(recalled.locked);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const initGuidelineBoundary = () => {
    // When the container ref is set, we get the boundary
    if (guidelineRootRef.current) {
      const parent = guidelineRootRef.current.parentElement;
      const parentRect = parent.getBoundingClientRect();
      setBoundary({
        top: 0,
        bottom: parentRect.bottom - parentRect.top,
      });
    }
  };

  return !isLoadingGuideline && (
    <div ref={guidelineRootRef} className='guidelineContainer' style={{ height: '0' }}>
      <div ref={guidelineToolRef} className='guidelineTool' style={{ transform: `translateY(${yPos}px)` }}>
        <div
          ref={lockedRef}
          aria-label={t('lock')}
          className={`padlock ${(locked) ? 'locked' : '' }`}
          role='button'
        />
      </div>
      <Moveable
        // TODO remove // container={guidelineRootRef.current?.parentElement?.closest?.('.lesson-element')}
        container={null}
        draggable={!locked}
        onDrag={(event) => {
          const [translateXNum] = event.translate;

          if (!htmlService.isWholeElementInViewport(event.target)) {
            event.target.scrollIntoView({ block: 'nearest', inline: 'nearest' });
          }

          // we will reverse any X (horizontal) translation performed, as we only want the Guideline to move vertically
          const transformWithHorizontalTranslationReversed = `${event.transform?.trim?.() || '' } translateX(${ -translateXNum }px)`;

          event.target.style.transform = transformWithHorizontalTranslationReversed;
        }}
        onDragEnd={(event) => {
          const { top: guidelineTop } = event.target?.getBoundingClientRect?.() || {};

          const guidelineRootParentElement = event.target?.parentElement?.parentElement;

          const { top: elementTop } = guidelineRootParentElement?.getBoundingClientRect?.() || {};

          if (guidelineTop < elementTop) {
            resetGuideline();
          }

          if (event.lastEvent?.translate) {
            // eslint-disable-next-line no-unused-vars
            const [_translateXNum, translateYNum] = event.lastEvent.translate;
            setYPos(translateYNum);
          }
        }}
        onDragStart={(event) => {
          event.set([0, yPos]);
        }}
        origin={false}
        target={guidelineToolRef.current}
        zoom={0}
      />
    </div>
  );
});

export default Guideline;
