import { Behavior } from './Behavior';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import scoringService from '../services/ScoringService';
import UtilsService from '../services/UtilsService';
import textQuestionUtilsService from '../services/TextQuestionUtilsService';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';

export class HotTextBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    if (responseAnswer && responseAnswer.studentAnswers && responseAnswer.studentAnswers.length > 0) {
      return true;
    }
    return false;
  }

  setCorrectAnswer = (state, model) => {
    const correctAnswers = UtilsService.safeMobxClone(model.validation?.correctAnswers);

    const responseAnswer = {
      isScored: true,
      isScoredCorrect: true,
      lessonElementId: model.lessonElementId,
      studentAnswers: correctAnswers,
      omittedList: state.currentResponseAnswer.omittedList,
      trueCorrectList: state.currentResponseAnswer.trueCorrectList,
      selectedIncorrectList: state.currentResponseAnswer.selectedIncorrectList
    };

    state.setCurrentResponse(UtilsService.safeMobxClone(responseAnswer));
    textQuestionUtilsService.setCorrectAnswersText(model, responseAnswer);
  }

  resetStudentAnswer = (state, isPracticeRunning) => {
    const responseAnswer = UtilsService.safeMobxClone(state.cachedResponseAnswer);
    textQuestionUtilsService.resetStudentAnswersTextHotText(responseAnswer);
    state.setCurrentResponse(UtilsService.safeMobxClone(responseAnswer));
  }

  getScore = (responseAnswer, model) => {
    if (!model.validation) {
      return 0;
    }

    // Checking to see if 'Show Correct Answer Button' is clicked, if so we return the length of the correct answers array.
    const uiState = questionFeedbackManager.getUiState(model.lessonElementId);
    const isShowAnswersActive = !uiState.showAnswers && uiState.showAnswersButtonVisible;
    if (isShowAnswersActive) {
      return model.validation.correctAnswers.length;
    }

    let tempScore = 0;
    const correctRanges = model.validation.correctAnswers;
    const incorrectRanges = model.validation.incorrectAnswers;
    const studentRanges = responseAnswer.studentAnswers;
    const promptCount = (model.validation.correctAnswers.length + model.validation.incorrectAnswers.length);
    responseAnswer.omittedList.clear();
    responseAnswer.selectedIncorrectList.clear();
    responseAnswer.trueCorrectList.clear();

    for (const correctRange of correctRanges) {
      if (studentRanges.includes(correctRange)) {
        tempScore++;
        responseAnswer.trueCorrectList.push(correctRange);
      } else {
        responseAnswer.omittedList.push(correctRange);
      }
    }

    for (const incorrectRange of incorrectRanges) {
      if (studentRanges.includes(incorrectRange)) {
        responseAnswer.selectedIncorrectList.push(incorrectRange);
      } else {
        tempScore++;
      }
    }

    if (tempScore < 0) {
      tempScore = 0;
    }

    const score = scoringService.getAdjustedScore(model, tempScore, promptCount);

    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    responseAnswer.studentAnswers = data;
  }

  getInitializedResponse = (lessonElementId, model) => {
    return {
      studentAnswers: [],
      lessonElementId,
      omittedList: [],
      selectedIncorrectList: [],
      trueCorrectList: [],
    };
  }

  isAutoScored = (model) => {
    return (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
  }

  getAnswerFeedback = (id, responseAnswer) => {

  }

  getCorrectAnswersText = (model) => {
    return null;
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let numberOmitted = 0;
    let numberSelectedIncorrect = 0;
    let correctCount = 0;
    if (lessonElementState && lessonElementState.currentResponseAnswer) {
      numberOmitted = (lessonElementState.currentResponseAnswer.omittedList) ? lessonElementState.currentResponseAnswer.omittedList.length : 0;
      numberSelectedIncorrect = (lessonElementState.currentResponseAnswer.selectedIncorrectList) ? lessonElementState.currentResponseAnswer.selectedIncorrectList.length : 0;
      correctCount = (lessonElementState.currentResponseAnswer.trueCorrectList) ? lessonElementState.currentResponseAnswer.trueCorrectList.length : 0;
    }

    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    const showPartialMessage = (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) || (questionIsCorrect === QuestionFeedbackState.INCORRECT);
    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if ((showPartialMessage) && (model.correctAnswerCount > 1) && (correctCount > 0)) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
      }
      if (numberOmitted > 0 && numberSelectedIncorrect === 0) {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt2()}.<br/>${ custom }${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody}<br/> ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  // going away-- no not for every question type :-)
  isAnswerCorrect = (answerId, lessonElementState, model) => {
    return lessonElementState.isScored && (lessonElementState.scoreValue === lessonElementState.maxScore);
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new HotTextBehavior();
