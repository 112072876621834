import React from 'react';

import { renderToStaticMarkup } from 'react-dom/server';

import parse, { domToReact } from 'html-react-parser';

import clozeMathService from './ClozeMathService';

import RichTextEditor from '../components/RichTextEditor';

export default class HtmlService {
  static isHtmlResponseFormat = (model, { considerMathAsHtml = true } = {}) => {
    if (considerMathAsHtml && (model.responseFormat === 'math' || model.mathText)) {
      return true;
    } else {
      return model.responseFormat === 'html';
    }
  }

  /** @param {Element} element */
  static isWholeElementInViewport = (element) => {
    if (!element) {
      return true;
    }
    const bounding = element.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= window.innerWidth && bounding.bottom <= window.innerHeight
    );
  }

  /**
   * @param {Element} element
   * @param {Event} event
   */
  static getElementInViewportPercentage = (element, event) => {
    if (!element) {
      return;
    }
    // get the relevant measurements and positions
    const viewportHeight = window.innerHeight;
    const scrollTop = event?.target?.scrollTop || window.scrollY;
    const elementOffsetTop = element.offsetTop;
    const elementHeight = element.offsetHeight;
    // calculate percentage of element visible in viewport
    const distance = (scrollTop + viewportHeight) - elementOffsetTop;
    const percentage = Math.round(distance / ((viewportHeight + elementHeight) / 100));
    // restrict the range to between 0 and 100
    return Math.min(100, Math.max(0, percentage));
  }

  /**
   * useful for when we want to have an active (but hidden) CKEditor instance
   * in the DOM for monitoring and executing certain functions,
   * such as initializing and interacting with the wiris MathType modal.
   */
  static renderHiddenRichTextEditorInstance = (functionToCallOnChange = 'handleChangeMathJaxEditor') => {
    return (
      <RichTextEditor
        additionalClassNames='hidden-ckeditor'
        onInit
        onReady={(ckeditorInstance) => {
          window.currentWirisInstanceForHiddenCkeditor = window.WirisPlugin.currentInstance;
          ckeditorInstance?.model?.document?.on?.('change:data', (_event, _data) => {
            if (functionToCallOnChange === 'handleChangeMathJaxEditor') {
              const data = ckeditorInstance.getData();
              // only save mathml data changes if they were made via the Wiris MathType modal
              if (data?.includes?.('wiris')) {
                clozeMathService.handleChangeMathJaxEditor({
                  ckeditorInstance
                });
              }
            } else {
              // ...
            }
          });
        }}
        skipLaunchMathImmediately
      />
    );
  };

  static resetHiddenCkeditorInstance = () => {
    const ckeditorInstance = this.getHiddenCkeditorInstanceFromDOM();
    ckeditorInstance.dataId = null;
    ckeditorInstance.lessonElementId = null;
    ckeditorInstance.modelPrompt = null;
    ckeditorInstance.responseAnswerPrompt = null;
    ckeditorInstance.setData('');
  }

  static getHiddenCkeditorInstanceFromDOM = () => {
    const ckeditorInstance = this.getHiddenCkeditorInstanceDocumentElement()?.ckeditorInstance;
    return ckeditorInstance;
  }

  static getHiddenCkeditorInstanceDocumentElement = () => {
    // const ckeditorInstance = document.getElementsByClassName('hidden-ckeditor')?.[0]?.children?.[0];
    const ckeditorInstance = document.querySelector('.hidden-ckeditor')?.querySelector('.ck');
    return ckeditorInstance;
  }

  static closeMathTypeModal = () => {
    window?.currentWirisInstanceForHiddenCkeditor?.core?.contentManager?.modalDialogInstance?.close?.();
    window?.WirisPlugin?.core?.contentManager?.modalDialogInstance?.close?.();
  }

  static getMathTypeModalDocumentElement = () => {
    return document.querySelector('.wrs_modal_dialogContainer');
    // return document.querySelector('.hidden-ckeditor-wrs_modal_dialogContainer');
  }

  static getMathTypeModalResizerElement = () => {
    return document.querySelector('.wrs_bottom_right_resizer');
  }

  /**
   * Parse through a raw html string, run a series of polyfills needed to
   * make certain html functionalities work, then convert back to a string and return it.
   */
  static getPolyfilledHtmlString = (htmlStr) => {
    return renderToStaticMarkup(parse((htmlStr), {
      replace: (domNode) => {
        if (domNode.name === 'mfenced' && domNode.attribs?.open) {
          // there is an issue where React renders all 'open' props as a boolean,
          // but this breaks mfenced since 'open' is supposed to be a string.
          //
          // more info here:
          // https://github.com/fast-reflexes/better-react-mathjax/issues/23#issuecomment-1205580864
          // https://developer.mozilla.org/en-US/docs/Web/MathML/Element/mfenced
          //
          // so here we modify the parsing to ensure the 'open' fencing is rendered
          const { close, open, ...mfencedAttribs } = domNode.attribs || {};
          const parsed = (
            <mrow {...mfencedAttribs}>
              <mo fence='true' stretchy='false'>{open}</mo>
              {domToReact(domNode.children)}
              <mo fence='true' stretchy='false'>{close}</mo>
            </mrow>
          );
          return parsed;
        }
      }
    }));
  }

  static isMobile() {
    return !!(
      navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
  }
}
