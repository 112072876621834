import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import '../../../css/StudentInstruction.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';

const StudentInstruction = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);

  useStyleEvents(lessonElementId);

  return (
    <div className='student-instruction-wrapper'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <HtmlComponent htmlStr={model.text} />
    </div>
  );
});
export default StudentInstruction;
