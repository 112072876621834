import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import utilsService from '../services/UtilsService';

import AudioPlayer from './AudioPlayer';
import HtmlComponent from './HtmlComponent';
import StandAloneViewer from './StandAloneViewer';
import useTextHighlight from '../../hooks/useTextHighlight';

const QuestionListViewer = observer(({
  directions, isGroup, isMultiPart, lessonElementIds, showQuestionBanner, parentElementId
}) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const questionListRef = useRef();
  useTextHighlight(questionListRef, parentElementId);

  const slides = lessonManager.getFilteredSlides();
  const children = [];
  for (const child of lessonElementIds) {
    if (slides.indexOf(child) > -1) {
      children.push(child);
    }
  }

  const activityModel = lessonManager.getSlideModel(parentElementId);
  const hasAudioResource = !!((activityModel && activityModel.audioResourceId));

  return (
    <>
      <div ref={questionListRef} className={classNames(
        'question-list-wrapper', {
          'has-audio-resource': hasAudioResource,
          'has-directions': !!directions
        }
      )} id={`${parentElementId}-question-list`}>
        {isMultiPart && <AudioPlayer lessonElementId={parentElementId} />}
        {(isMultiPart && !utilsService.isNullOrEmpty(directions)) && (
          <div className='multipart-directions' id='view-area'>
            <HtmlComponent htmlStr={directions} />
          </div>
        )}
        {
          children.map((childElementId, index) => {
            const isFirstChild = index === 0;
            return (
              <StandAloneViewer
                key={`${childElementId }-${ index}`}
                isFirstChild={isFirstChild}
                isGroup={isGroup}
                isMultipart={isMultiPart}
                lessonElementId={childElementId}
                showQuestionBanner={showQuestionBanner} />
            );
          })
        }
      </div>
    </>
  );
});
export default QuestionListViewer;
