import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../css/ActivityProgressBar.scss';

import questionService from '../services/QuestionService';
import { register } from '../../i18n';

const t = register('NavigationBar');

const ActivityProgressBar = observer(() => {
  const {
    responseManager,
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  if (!lessonManager ||
    !lessonManager.lessonOptions ||
    !lessonManager.lessonOptions.hasProgressBar) {
    return (<></>);
  }

  let completed = responseManager.numberSubmittedResponses;
  const total = responseManager.totalTestItemResponses + responseManager.notToBeScoredItemCount;

  const uncountedSurveyModelsMap = questionService.getModelsMap({
    condition: (lessonElementState, model, submittedResponseList) => {
      return (
        questionService.isAnsweredSurveyQuestion({
          lessonElementId:model.lessonElementId, lessonElementState, model
        }) && !submittedResponseList?.includes?.(model.lessonElementId)
      );
    },
    originalArray: responseManager.submittedResponseList
  });
  completed += uncountedSurveyModelsMap.size;

  const label = `${t('progressTally', { completed, total })}`;
  const progress = (completed < 1) ? 0 : (completed / total) * 100;

  const dataStyle = {
    width: `${progress}%`
  };

  return (
    <div className='activity-progress-bar'>
      <div className='bar-container'>
        <div className='bar-data' style={dataStyle}>
          {/* <span style={progresstext}>{`${progress}%`}</span> */}
        </div>
      </div>
      <div className='bar-label'>
        <span>{t('progressLabel')} </span><span className='progress-value'>{label}</span>
      </div>
    </div>

  );
});

export default ActivityProgressBar;
