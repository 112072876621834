import React, { useContext } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';
import { StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment } from 'react-complex-tree';
import Loader from '../Loader';
import '../../../css/CmapDetailDialog.scss';
import alignmentService from '../../services/AlignmentService';
import { register } from '../../../i18n';

const t = register('PanelMenu');

const customItemArrow = ({ item, context }) => {
  return (
    <>
      {(item.hasChildren) ? (context.isExpanded) ? <div className='expanded-icon' /> : <div className='closed-icon' /> : null}
    </>
  );
};

const customItemRender = ({ item, title, arrow, depth, context, children }) => {
  context.interactiveElementProps.tabIndex = '0';

  let typeName = null;
  if (item.data.type === 'UNIT') {
    typeName = `${item.data.unitName} ${item.data.orderNum + 1 }`;
  } else if (item.data.type === 'CONTENT') {
    typeName = item.data.subCategoryName;
  } else {
    typeName = item.data.type;
  }

  return (
    <li
      {...context.itemContainerWithChildrenProps}
      className='tree-item'
    >
      <div
        className='item-container' {...context.itemContainerWithoutChildrenProps} {...context.interactiveElementProps}>
        {arrow}

        {(item.data.type !== 'CMAP') && (
          <div className='tree-item-type-wrapper'>
            <div dangerouslySetInnerHTML={{ __html: typeName }} className='tree-item-type' />
            {(item.data.weight) && <div className='tree-item-weight'>{t('reportingWeight')} {item.data.weight}</div>}
          </div>
        )}

        {title}
        {(item.data.type !== 'CMAP') &&
        <div dangerouslySetInnerHTML={{ __html: item.data.description }} className='tree-item-description' />}

      </div>
      {children}
    </li>
  );
};

const getTitle = (item) => {
  if (item.data.type === 'CONTENT') {
    return '';
  }
  return item.data.name;
};

const CmapDetailDialog = observer((props) => {
  const {
    alignmentManager
  } = useContext(MobXProviderContext);

  return (

    <div className='cmap-detail-dialog'>
      <ReactModal
        initHeight={400}
        initWidth={800}
        isOpen={alignmentManager.cmapDetailDialogIsOpen}>
        <div className='cmap-dialog-body'>
          {(alignmentManager.cmapLoaded) ? (
            <>
              <div className='cmap-header'>
                <div className='title-label basic'>{alignmentManager.cmapContentItem.displayName}</div>
                <div className='x-close-button mask-image' onClick={() => { alignmentService.openCmapDialog(false); }} />
              </div>
              <div className='scroll-area'>
                <div className='tree-wrapper'>
                  <UncontrolledTreeEnvironment
                    canDragAndDrop={false}
                    canReorderItems={false}
                    dataProvider={new StaticTreeDataProvider(alignmentManager.detailCmapTreeStructure, (item, data) => ({ ...item, data }))}
                    getItemTitle={getTitle}
                    renderItem={(props) => customItemRender(props)}
                    renderItemArrow={customItemArrow}
                    renderItemTitle={({ title }) => <div dangerouslySetInnerHTML={{ __html: title }} className='tree-item-title' />}
                    renderItemsContainer={({ children, containerProps }) => <ul className={`alignment-tree-list ${(containerProps.role) ? containerProps.role : ''}`} {...containerProps}>{children}</ul>}
                    renderTreeContainer={({ children, containerProps }) => <div className='alignment-tree-container' {...containerProps}>{children}</div>}
                    viewState={{
                      'tree-1': {
                        expandedItems: alignmentManager.expandedCmapItems,
                        selectedItems: null
                      },
                    }}
                  >
                    <Tree rootItem='root' treeId='tree-1' treeLabel='Cmap Details' />
                  </UncontrolledTreeEnvironment>
                </div>
              </div>
            </>
          )
            :
            <div className='dialog-loader'><Loader /></div>}
        </div>
      </ReactModal>
    </div>
  );
});

export default CmapDetailDialog;
