import { ContentType } from '../../Constants';

import activityBehavior from './ActivityBehavior';
import bubbleWebBehavior from './BubbleWebBehavior';
import categorizeBehavior from './CategorizeBehavior';
import causeAndEffectBehavior from './CauseAndEffectBehavior';
import choiceMatrixBehavior from './ChoiceMatrixBehavior';
import choiceMatrixMultiBehavior from './ChoiceMatrixMultiBehavior';
import clozeBehavior from './ClozeBehavior';
import clozeComboBehavior from './ClozeComboBehavior';
import clozeDragDropBehavior from './ClozeDragDropBehavior';
import clozeMathBehavior from './ClozeMathBehavior';
import clozeMultichoiceBehavior from './ClozeMultichoiceBehavior';
import clozeSpellingBehavior from './ClozeSpellingBehavior';
import defaultBehavior from './DefaultBehavior';
import drawBehavior from './DrawBehavior';
import griddableBehavior from './GriddableBehavior';
import hotTextBehavior from './HotTextBehavior';
import imageHotSpotBehavior from './ImageHotSpotBehavior';
import imageLabelBehavior from './ImageLabelBehavior';
import imageLabelDragDropBehavior from './ImageLabelDragDropBehavior';
import imageLabelMultichoiceBehavior from './ImageLabelMultichoiceBehavior';
import imageTextMatchBehavior from './ImageTextMatchBehavior';
import imageTextMatchFlipBehavior from './ImageTextMatchFlipBehavior';
import kwlChartBehavior from './KWLChartBehavior';
import longTextBehavior from './LongTextBehavior';
import multichoiceBehavior from './MultichoiceBehavior';
import multiselectBehavior from './MultiselectBehavior';
import numberLineBehavior from './NumberLineBehavior';
import orderListBehavior from './OrderListBehavior';
import orderSentencesBehavior from './OrderSentencesBehavior';
import relationshipsBehavior from './RelationshipsBehavior';
import shortTextBehavior from './ShortTextBehavior';
import studentInstructionBehavior from './StudentInstructionBehavior';
import tChartBehavior from './TChartBehavior';
import textHighlightBehavior from './TextHighlightBehavior';
import textSelectorBehavior from './TextSelectorBehavior';
import uploadBehavior from './UploadBehavior';
import vennBehavior from './VennBehavior';
import wordAssociationBehavior from './WordAssociationBehavior';

const createQuestionBehavior = (type) => {
  switch (type) {
  case ContentType.BUBBLE_WEB.type: {
    return bubbleWebBehavior;
  }
  case ContentType.CATEGORIZE.type: {
    return categorizeBehavior;
  }
  case ContentType.CAUSE_AND_EFFECT.type: {
    return causeAndEffectBehavior;
  }
  case ContentType.CHOICE_MATRIX.type: {
    return choiceMatrixBehavior;
  }
  case ContentType.CHOICE_MATRIX_MULTI.type: {
    return choiceMatrixMultiBehavior;
  }
  case ContentType.CLOZE.type: {
    return clozeBehavior;
  }
  case ContentType.CLOZE_COMBO.type: {
    return clozeComboBehavior;
  }
  case ContentType.CLOZE_DRAG_DROP.type: {
    return clozeDragDropBehavior;
  }
  case ContentType.CLOZE_MATH.type: {
    return clozeMathBehavior;
  }
  case ContentType.CLOZE_MULTICHOICE.type: {
    return clozeMultichoiceBehavior;
  }
  case ContentType.CLOZE_SPELLING.type: {
    return clozeSpellingBehavior;
  }
  case ContentType.DRAW.type: {
    return drawBehavior;
  }
  case ContentType.GRIDDABLE.type: {
    return griddableBehavior;
  }
  case ContentType.IMAGE_HOT_SPOT.type: {
    return imageHotSpotBehavior;
  }
  case ContentType.IMAGE_LABEL.type: {
    return imageLabelBehavior;
  }
  case ContentType.KWL_CHART.type: {
    return kwlChartBehavior;
  }
  case ContentType.IMAGE_LABEL_DRAG_DROP.type: {
    return imageLabelDragDropBehavior;
  }
  case ContentType.IMAGE_LABEL_MULTICHOICE.type: {
    return imageLabelMultichoiceBehavior;
  }
  case ContentType.IMAGE_TEXT_MATCH.type: {
    return imageTextMatchBehavior;
  }
  case ContentType.IMAGE_TEXT_MATCH_FLIP.type: {
    return imageTextMatchFlipBehavior;
  }
  case ContentType.LONG_TEXT.type: {
    return longTextBehavior;
  }
  case ContentType.MULTICHOICE.type: {
    return multichoiceBehavior;
  }
  case ContentType.MULTISELECT.type: {
    return multiselectBehavior;
  }
  case ContentType.NUMBER_LINE.type: {
    return numberLineBehavior;
  }
  case ContentType.ORDER_LIST.type: {
    return orderListBehavior;
  }
  case ContentType.ORDER_SENTENCES.type: {
    return orderSentencesBehavior;
  }
  case ContentType.RELATIONSHIPS.type: {
    return relationshipsBehavior;
  }
  case ContentType.TEXT_HIGHLIGHT.type: {
    return textHighlightBehavior;
  }
  case ContentType.TEXT_SELECTOR.type: {
    return textSelectorBehavior;
  }
  case ContentType.HOT_TEXT.type: {
    return hotTextBehavior;
  }
  case ContentType.SHORT_TEXT.type: {
    return shortTextBehavior;
  }
  case ContentType.STUDENT_INSTRUCTION.type: {
    return studentInstructionBehavior;
  }
  case ContentType.T_CHART.type: {
    return tChartBehavior;
  }
  case ContentType.UPLOAD.type: {
    return uploadBehavior;
  }
  case ContentType.VENN.type: {
    return vennBehavior;
  }
  case ContentType.WORD_ASSOCIATION.type: {
    return wordAssociationBehavior;
  }
  case ContentType.BOOKMARK_ACTIVITY.type:
  case ContentType.IMAGE_QUESTION.type:
  case ContentType.ARTICLE_QUESTION.type: {
    return activityBehavior;
  }
  default: {
    return defaultBehavior;
  }
  }
};

export default createQuestionBehavior;
