import React from 'react';

const DefaultQuestion = ({ model }) => {
  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div className='not-implemented'>{model.type} (*** not implemented yet ***)</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
    </div>
  );
};
export default DefaultQuestion;
