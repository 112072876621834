import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import Utils from '../../services/UtilsService';
import HtmlComponent from '../HtmlComponent';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import { FlyoutOpenState, FlyoutTabs } from '../../../Constants';
import navMenuService from '../../services/NavMenuService';
import scoringService from '../../services/ScoringService';
import { register } from '../../../i18n';

const t = register('PanelMenu')

const RubricView = observer(({ lessonElementId }) => {
  const {
    scoringManager,
    navMenuManager,
  } = useContext(MobXProviderContext);

  const rubric = scoringManager.rubricMap.get(lessonElementId);
  const hideButton = useRef();
  useAccessibilityClick(hideButton, () => {
    const os = navMenuManager.getFlyoutOpenState(lessonElementId);
    if (os.open === FlyoutOpenState.OPEN) {
      navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN_WIDE, lessonElementId);
    } else {
      navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN, lessonElementId);
    }
  });

  const [buttonLabel, setButtonLabel] = useState(`${t('hideButton')}`);

  const openState = navMenuManager.getFlyoutOpenState(lessonElementId);
  useEffect(() => {
    if (openState && openState?.open === FlyoutOpenState.OPEN_WIDE && openState?.tab === FlyoutTabs.SCORE) {
      setButtonLabel(`${t('hideButton')}`);
    } else {
      setButtonLabel(`${t('showButton')}`);
    }
  }, [openState]);

  return (
    <>
      {(!rubric) ? <div className='rubric-wrapper' /> : (
        <div className='rubric-wrapper'>
          <div className='rubric-container'>
            <button ref={hideButton} className='hide'>{buttonLabel}</button>
            <table className='rubric font-content-container'>
              <thead>
                <tr>
                  <th className='no-border font-content-container' />
                  {rubric.columns.map((column) => {
                    return (<th key={`rebric_header_${Utils.getKey()}`} className='columnHeader font-content-container'><HtmlComponent htmlStr={column} /></th>);
                  })}
                  <th className='no-border font-content-container'>{t('maxPoints')}</th>
                </tr>
              </thead>
              <tbody>
                {rubric.categories.map((category) => {
                  return (
                    <tr key={`rebric_row_${Utils.getKey()}`}>
                      <td className={classNames({ 'category-label': true, 'font-content-container': true })}>
                        <HtmlComponent htmlStr={category.name} />
                      </td>
                      {category.columns.map((column, clickIndex) => {
                        return (
                          <td key={`rebric_inner_col_${Utils.getKey()}`}
                            className={classNames({ 'rubric-cell': true, 'font-content-container': true, 'selected': scoringManager.isSelectedCell(category.categoryId, clickIndex, lessonElementId) })}
                            onClick={() => { scoringService.rubricCellClick(lessonElementId, clickIndex, category.categoryId); }}>
                            <HtmlComponent htmlStr={column} />
                          </td>
                        );
                      })}
                      <td className='max-points font-content-container'>{category.maxPoints}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
});

export default RubricView;
