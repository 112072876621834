import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../../css/FlyoutEngagementPanel.scss';
import responseService from '../../services/ResponseService';
import UtilsService from '../../services/UtilsService';
import { register } from '../../../i18n';

const t = register('PanelMenu');
const t2 = register('AriaLabels');

const EngagementPanel = observer(({ lessonElementId }) => {
  const {
    questionFeedbackManager,
    responseManager,
    scoringManager,
    studentActivityManager
  } = useContext(MobXProviderContext);
  const engagementReport = responseManager.engagementMap.get(lessonElementId);
  const summaryReport = responseManager.classroomSummaryReport;

  const score = scoringManager.getTempScore(lessonElementId);
  const state = responseManager.getLessonElementState(lessonElementId);
  const isCorrect = (score === state.maxScore);
  const firstAttemptStr = (isCorrect) ? `${t('yes')}` : `${t('no')}`;
  useEffect(() => {
    const { activityId } = studentActivityManager;
    const fetchClassEngagementSummary = async () => {
      await responseService.fetchClassEngagementSummary(activityId);
    };
    fetchClassEngagementSummary().then();
  });

  return (
    <div className='engagement-panel flyout-panel'>
      <div className='panel-header'>{t('engagementPanel', 'Engagement')}</div>

      <div className='engagement-panel-wrapper'>
        {(engagementReport) && (
          <>
            <div className='engagement-title-wrapper'>
              <div className='engagement-title'>{t('timeOnTask')}</div>
              <div aria-label={t2('timeontaskdetailinformation')} className='tree-standard-info' role='button' tabIndex='0' />
            </div>
            <div className='table-wrapper'>
              <table className='time-on-task-table left-table'>
                <thead>
                  <tr>
                    <th className='no-border no-label' />
                    <th className='no-border labeled-header'>{t('student')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='category-label '>{t('timeOnItem')}</td>
                    <td className='value-cell '>{UtilsService.secondsToMMSS(engagementReport.averageTimePerItem)}</td>
                  </tr>
                  <tr>
                    <td className='category-label '>{t('timeOn')}<br />{t('correctAnswer')}</td>
                    <td className='value-cell '>{UtilsService.secondsToMMSS(engagementReport.averageTimePerCorrect)}</td>
                  </tr>
                  <tr>
                    <td className='category-label '>{t('timeOn')}<br />{t('incorrectAnswer')}</td>
                    <td className='value-cell '>{UtilsService.secondsToMMSS(engagementReport.averageTimePerIncorrect)}</td>
                  </tr>
                </tbody>
              </table>
              {(summaryReport) && (
                <table className='time-on-task-table right-table'>
                  <thead>
                    <tr>
                      <th className='no-border labeled-header'>
                        <span>{t('class')}</span><br /><span>{t('average')}</span>
                        <div aria-label={t2('timeontaskdetailinformation')} className='tree-standard-info' role='button' tabIndex='0' />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='value-cell '>{UtilsService.secondsToMMSS(summaryReport.averageTimePerItem)}</td>
                    </tr>
                    <tr>
                      <td className='value-cell '>{UtilsService.secondsToMMSS(summaryReport.averageTimePerCorrect)}</td>
                    </tr>
                    <tr>
                      <td className='value-cell '>{UtilsService.secondsToMMSS(summaryReport.averageTimePerIncorrect)}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className='engagement-title-wrapper'>
              <div className='engagement-title'>{t('supportToolUse')}</div>
              <div aria-label={t2('supportToolsUsedetailinformation')} className='tree-standard-info' role='button' tabIndex='0' />
            </div>
            <div className='table-wrapper'>
              <table className='correct-table left-table'>
                <thead>
                  <tr>
                    <th className='no-border no-label' />
                    <th className='no-border labeled-header'>{t('student')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='category-label '>{t('correctOn')}<br />{t('firstAttempt')}</td>
                    <td className='value-cell '>{firstAttemptStr}</td>
                  </tr>
                </tbody>
              </table>
              {(summaryReport) && (
                <table className='correct-table right-table'>
                  <thead>
                    <th className='no-border labeled-header'>
                      <span>{t('class')}</span><br /><span>{t('average')}</span>
                      <div aria-label={t2('timeontaskdetailinformation')} className='tree-standard-info' role='button' tabIndex='0' />
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='value-cell '>
                        <span>{summaryReport.correctCount}</span><span>/</span><span>{studentActivityManager.students.length}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className='small-info'>{t('ifIncorrect')}:</div>
            <div className='table-wrapper last'>
              <table className='tool-tabel left-table'>
                <tbody>
                  <tr>
                    <td className='category-label '>{questionFeedbackManager.tryAgainButtonLabel}</td>
                    <td className='value-cell '>{(isCorrect) ? 'N/A' : (engagementReport.tryAgainCount > 0) ? `${t('yes')}` : `${t('no')}`}</td>
                  </tr>
                  <tr>
                    <td className='category-label '>{questionFeedbackManager.hintButtonLabel}</td>
                    <td className='value-cell '>{(isCorrect) ? 'N/A' : (engagementReport.showHintCount > 0) ? `${t('yes')}` : `${t('no')}`}</td>
                  </tr>
                  <tr>
                    <td className='category-label '>{questionFeedbackManager.solutionButtonLabel}</td>
                    <td className='value-cell '>{(isCorrect) ? 'N/A' : (engagementReport.showSolutionCount > 0) ? `${t('yes')}` : `${t('no')}`}</td>
                  </tr>
                  <tr>
                    <td className='category-label '>{questionFeedbackManager.showAnswersButtonLabel}</td>
                    <td className='value-cell '>{(isCorrect) ? 'N/A' : (engagementReport.showAnswerCount > 0) ? `${t('yes')}` : `${t('no')}`}</td>
                  </tr>
                </tbody>
              </table>
              {(summaryReport) && (
                <table className='tool-tabel right-table'>

                  <tbody>
                    <tr>
                      <td className='value-cell '><span>{summaryReport.tryAgainCount}</span><span>/</span><span>{summaryReport.tryAgainAvailable}</span></td>
                    </tr>
                    <tr>

                      <td className='value-cell '><span>{summaryReport.showHintCount}</span><span>/</span><span>{summaryReport.showHintAvailable}</span></td>
                    </tr>
                    <tr>
                      <td className='value-cell '><span>{summaryReport.showSolutionCount}</span><span>/</span><span>{summaryReport.showSolutionAvailable}</span></td>
                    </tr>
                    <tr>
                      <td className='value-cell '><span>{summaryReport.showAnswerCount}</span><span>/</span><span>{summaryReport.showAnswerAvailable}</span></td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
      </div>

    </div>
  );
});

export default EngagementPanel;
