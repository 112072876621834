import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/ChoiceMatrixMulti.scss';
import { LessonMode } from '../../../Constants';

import Guideline from '../tools/Guideline';

import FeedbackIcon from '../FeedbackIcon';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';

import responseService from '../../services/ResponseService';

import { confirmDialog } from '../dialogs';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const getWidths = (model) => {
  let answerColumnWidths = [];
  if (model.answerColumnWidths === undefined) {
    if (model.options && model.options.length > 0) {
      for (let x = 0; x < model.options.length; x++) {
        answerColumnWidths.push('100');
      }
    }
    return answerColumnWidths;
  } else {
    answerColumnWidths = model.answerColumnWidths.map((w) => parseInt(w));
    return answerColumnWidths;
  }
};

const ChoiceMatrixMulti = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager,
    responseManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  // Define state
  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
  const { currentResponseAnswer } = lessonElementState;

  const readOnly = lessonElementState.readOnly || lessonManager.isReadOnly;
  const { showAnswers } = feedbackState;

  const textBody = model.questionText || '';
  const tableHeader = model.answerTextHeader; // In the top left box
  const isMultiChoice = (model.answerType === 'mchoice' || model.answerType === undefined); // If false then this is multiselect
  const labelInCell = (model.answerPosition === 'cells' || model.answerPosition === undefined); // If this is false then the labels are in the header
  const { fixedWidths } = model;
  const promptColumnWidth = parseInt(model.promptColumnWidth);
  const { prompts } = model; // On the left side
  const noPrompts = prompts.length === 1 && prompts[0].text.length === 0;
  const answerColumnWidths = getWidths(model);
  const answerOptions = model.options.filter((option) => option.length > 0); // Either in the header or in the cells
  const noHeader = (!tableHeader || tableHeader.length === 0) && (answerOptions.every((o) => o.length === 0) || labelInCell);

  const isCorrectOption = (promptId, answerIndex) => {
    const correctAnswers = model.validation?.correctAnswers;
    if (correctAnswers == null) {
      return false;
    }
    const promptIndex = prompts.findIndex((p) => p.id === promptId);
    if (correctAnswers[promptIndex]) {
      const correctAnswerIndices = correctAnswers[promptIndex].split(',').map((a) => parseInt(a));
      return correctAnswerIndices.includes(answerIndex);
    }
    return false;
  };

  const isSelectedOption = (promptId, answerIndex) => {
    const selectedAnswers = currentResponseAnswer.answers;
    const promptIndex = prompts.findIndex((p) => p.id === promptId);
    const selectedAnswerIndices = selectedAnswers[promptIndex].split(',').map((a) => parseInt(a));
    return selectedAnswerIndices.includes(answerIndex);
  };

  const onSelected = async (promptId, newState, answerIndex) => {
    const data = { promptId, answerIndex };

    if (readOnly) {
      return;
    }

    const currentlySelected = currentResponseAnswer.answers.reduce((count, item) => {
      const values = item.split(',');
      const numberValues = values.filter((value) => value !== '' && !isNaN(Number(value)));
      return count + numberValues.length;
    }, 0);

    if (model.correctAnswerCount && !model.allowSelectAll && !model.unscored && newState && currentlySelected >= model.correctAnswerCount && !isMultiChoice) {
      // Then we are trying to select an answer when we already have the maximum number of answers selected
      await confirmDialog({
        title: `${UtilsService.maxSelected()}`,
        text: `${UtilsService.deselect()}`,
        showCancelButton: false,
        confirmButtonText: `${UtilsService.confirmOk()}`
      });
    } else {
      await responseService.responseChangeHandler(data, lessonElementId);
    }
  };

  useStyleEvents(lessonElementId);

  return (
    <div className='choice-matrix-multi-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={textBody} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='test-item-answers'>
        <table className='choice-matrix-multi-table' style={{ width: fixedWidths ? 'auto' : '100%' }}>
          <thead>
            {!noHeader && (
              <tr>
                <td className='choice-matrix-header' style={{ width: fixedWidths ? promptColumnWidth : 'auto' }}><HtmlComponent htmlStr={tableHeader} /></td>
                {answerOptions.map((answer, i) => <td key={i} className={`choice-matrix-header answer-header ${ labelInCell ? 'empty' : 'full' }`} style={{ width: fixedWidths ? answerColumnWidths[i] : 'auto' }}><HtmlComponent htmlStr={labelInCell ? '' : answer} /></td>)}
              </tr>
            )}
          </thead>
          <tbody>
            {
              !noPrompts && prompts.map(({ id, text }) => (
                <RowContainer key={id}
                  isMultiChoice={isMultiChoice}
                >
                  <td className='choice-matrix-cell' style={{ width: fixedWidths ? promptColumnWidth : 'auto' }}><HtmlComponent htmlStr={text} /></td>
                  {
                    answerOptions.map((answer, j) => (
                      <ChoiceCell key={j}
                        answer={answer}
                        answerIndex={j}
                        isCorrectAnswer={isCorrectOption(id, j)}
                        isMultiChoice={isMultiChoice}
                        isSelected={isSelectedOption(id, j)}
                        labelInCell={labelInCell}
                        lessonElementId={lessonElementId}
                        lessonElementState={lessonElementState}
                        model={model}
                        onSelected={onSelected}
                        promptId={id}
                        readOnly={readOnly}
                        showAnswers={showAnswers}
                      />
                    ))
                  }
                </RowContainer>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});

const RowContainer = ({ isMultiChoice, children }) => {
  if (isMultiChoice) {
    // Then this should be a radio group
    return (
      <tr
        role='radiogroup'
      >{children}
      </tr>
    );
  } else {
    return <tr>{children}</tr>;
  }
};

const ChoiceCell = ({
  answer, answerIndex, isCorrectAnswer, isMultiChoice,
  isSelected, labelInCell, lessonElementId, lessonElementState,
  model, onSelected, promptId, readOnly, showAnswers
}) => {
  const id = `${promptId}-${answer}`;
  const inputType = isMultiChoice ? 'radio' : 'checkbox';
  const symbolType = `${inputType }-symbol`;
  const tabIndex = 0;

  let visualClass = 'choice-input';
  if (showAnswers) {
    if (isCorrectAnswer) {
      visualClass = 'correct choice-input';
    } else {
      visualClass = 'incorrect choice-input';
    }
  }

  const [focused, setFocused] = useState(false);

  const showFeedback = isSelected || isCorrectAnswer;

  const callOnSelected = (data) => {
    const { checked } = data.target;
    onSelected(promptId, checked, answerIndex);
  };

  const renderFeedbackIcon = () => {
    return (
      <div className='correct-answer-symbol'>
        <FeedbackIcon
          isCorrectOverride={isSelected && isCorrectAnswer} isScored={lessonElementState.isScored}
          lessonElementId={lessonElementId} />
      </div>
    );
  };

  return (
    <td className='choice-matrix-cell'>
      {model.answerPosition === 'cells' && showFeedback && renderFeedbackIcon()}
      <label className='selector-container' htmlFor={id}>
        {model.answerPosition !== 'cells' && showFeedback && renderFeedbackIcon()}
        <div className='text-container'>
          {labelInCell ? <label htmlFor={id}><HtmlComponent htmlStr={answer} /></label> : ''}
          <label className={`symbol outline-separate ${readOnly ? 'disabled' : ''} ${isSelected ? 'selected' : ''} ${symbolType} ${visualClass} ${focused ? 'focused' : ''}`} htmlFor={id} />
          {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
          <input
            aria-checked={isSelected}
            checked={isSelected}
            className={visualClass} disabled={readOnly} id={id}
            name={promptId} onBlur={() => setFocused(false)} onChange={callOnSelected} onFocus={() => setFocused(true)}
            tabIndex={tabIndex} type={inputType} value={id} />
        </div>
      </label>
    </td>
  );
};

export default ChoiceMatrixMulti;
