import UtilsService from '../services/UtilsService';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import { Behavior } from './Behavior';

export class BubbleWebBehavior extends Behavior {
  checkForValidResponse = (responseAnswer, model) => {
    const { prompts } = responseAnswer;
    // we only need one non-blank answer.  Also, this question type supports
    // only non-html strings so no need to strip tags;
    if (prompts && prompts.length > 0) {
      return (!UtilsService.isNullOrEmpty(prompts[0].text));
    }
    return false;
  }

  setCorrectAnswer = (state, model) => {
  }

  resetStudentAnswer = (state) => {
  }

  getScore = (responseAnswer, model) => {
    // Never auto-scored so this value doesn't matter.
    return -1;
  }

  setResponseData = (data, responseAnswer) => {
    // No dependence on the model. response is a prompt array of strings;

    if (responseAnswer.prompts && responseAnswer.prompts.length > 0) {
      const foundPrompt = responseAnswer.prompts.find((e) => e.id === data.id);
      if (foundPrompt) {
        foundPrompt.text = data.text;
      } else {
        responseAnswer.prompts.push(data);
      }
    } else {
      responseAnswer.prompts.push(data);
    }
  }

  getInitializedResponse = (lessonElementId, model) => {
    const responseAnswer = {
      prompts: [],

      lessonElementId
    };
    return responseAnswer;
  }

  isAutoScored = (model) => {
    return (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
  }

  getAnswerFeedback = (id, responseAnswer) => {
    // never used BubbleWeb is not autoscored
    return '';
  }

  getCorrectAnswersText = (model) => {
    // There is no correct answer for this question type.
    return '';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += '';
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, responseAnswer) => {
    // manual scored
    return false;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}
export default new BubbleWebBehavior();
