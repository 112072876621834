import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactTooltip from 'react-tooltip';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import '../../../css/ToolbarButton.scss';
import { LessonMode } from '../../../Constants';

// import { register } from '../../../i18n';
import lessonManager from '../../managers/LessonManager';

import styleService from '../../services/StyleService';
import toolbarService from '../../services/ToolbarService';
import utilsService from '../../services/UtilsService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

// const t = register('ToolNames');

const ToolbarButton = observer(({ /* aRole, */buttonId }) => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const [translatedButtonName, setTranslatedButtonName] = useState('');
  const [toolbarButtonLabelEllipsifyValue, setToolbarButtonLabelEllipsifyValue] = useState('');

  const [disabled, setDisabled] = useState(false);

  const button = toolbarManager.getButtonSettings(buttonId);
  const buttonRef = useRef();

  // on update
  useEffect(() => { // eslint-disable-line react-hooks/exhaustive-deps
    if (!button?.name) {
      return;
    }

    if (((lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW ||
      lessonManager.playerMode === LessonMode.ITEM_PREVIEW) &&
      buttonId === 'Logout') || button.disabled) {
      setDisabled(true);
    }

    setTranslatedButtonName(button.name());

    const _toolbarButtonLabelEllipsifyValue = styleService.getStyleVar('--toolbar-button-label-ellipsify');
    setToolbarButtonLabelEllipsifyValue(_toolbarButtonLabelEllipsifyValue || '');
  });

  const doClick = (_event) => {
    if (button && !button.disabled) {
      toolbarService.doButtonAction(button.id);
    }
  };

  useAccessibilityClick(buttonRef, doClick);

  if (!button) {
    return (<></>);
  }

  let toolbarButtonSecondaryId;
  if (translatedButtonName !== button.name()) {
    toolbarButtonSecondaryId = kebabCase(translatedButtonName);
  }

  const renderToolbarButtonLabel = () => {
    const allowEllipsify = toolbarButtonLabelEllipsifyValue !== 'none';

    const toolbarButtonLabelClassNames = classNames(
      `button-label-${button.id}`,
      'toolbar-button-label',
      toolbarButtonSecondaryId, {
        'toolbar-button-label-ellipsify': allowEllipsify
      }
    );

    const dataTip = allowEllipsify ? (
      utilsService.stripTagsAndEntities(translatedButtonName)
    ) : undefined;

    return (
      <>
        <div className={toolbarButtonLabelClassNames}
          data-tip={dataTip}
          tabIndex='-1'>
          {translatedButtonName}
        </div>

        {allowEllipsify ? (
          <ReactTooltip border className='react-tooltip' effect='solid'
            place='bottom' type='light' />
        ) : null}
      </>
    );
  };

  return (
    <div ref={buttonRef}
      aria-label={translatedButtonName}
      className={classNames('toolbar-button-wrapper', button.id, toolbarButtonSecondaryId, {
        'toggle-on': button.toggle,
        'toggle-off': !button.toggle,
        'visible': button.visible,
        'visible-none': !button.visible,
        'disabled': disabled
      })}
      role='menuitem'
      tabIndex='0'>
      {button.icon && (
        <div className={classNames('toolbar-icon-wrapper', button.id, toolbarButtonSecondaryId)} tabIndex='-1'>
          <div className={classNames('toolbar-icon', button.id, toolbarButtonSecondaryId)} tabIndex='-1' />
        </div>
      )}
      {renderToolbarButtonLabel()}
    </div>
  );
});

export default ToolbarButton;
