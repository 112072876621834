import React from 'react';
import GotoMenu from '../components/footer/GotoMenu';
import Navbar from '../components/footer/Navbar';

const FooterLayout = (props) => {
  return (
    <div className='footer-wrapper'>
      <GotoMenu />
      <Navbar />
    </div>
  );
};

export default FooterLayout;
