import { renderToStaticMarkup } from 'react-dom/server';
import { register } from '../../i18n';
import { Behavior } from './Behavior';
import questionService from '../services/QuestionService';
import UtilsService from '../services/UtilsService';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';

const t = register('GlobalQuestionLabels');

export class GriddableBehavior extends Behavior {
  checkForValidResponse = (response) => {
    const { text } = response;
    const validNumberRegex = /^[+-]\s*([0-9]*\.[0-9]+|[0-9]+\.?[0-9]*)\s*$/;
    /*
    Since I hate just seeing random regex:
    Must start with either + or -
    Then can have any number of spaces preceding the number
    Then we have two ways of constructing the main body of the number
    1. 0 or more digits followed a decimal point followed by 1 or more digits (edge case like "+   .1 ")
    2. 1 or more digits maybe followed by a decimal point followed by 0 or more digits (edge case like "+  3")
    Then we can have any number of spaces following the number
    Then the string must end so that we don't match substrings

    If this regex holds, we can get rid of the sign, strip the spaces, and parse the number as a float
    */
    return {
      isValid: validNumberRegex.test(text),
      title: `${UtilsService.responseWarning()}`,
    };
  }

  _toNumber = (text) => {
    // Strip the sign and spaces
    const hasSign = text.startsWith('+') || text.startsWith('-');
    const sign = hasSign ? text[0] : '+';
    const number = text.substring(hasSign ? 1 : 0).replace(/\s/g, '');
    return sign === '-' ? -parseFloat(number) : parseFloat(number);
  }

  setCorrectAnswer = (state, model) => {
    let validAnswer = model.validation?.answers[0].text;

    const hasSign = (validAnswer) ? (validAnswer.startsWith('+') || validAnswer.startsWith('-')) : false;

    if (!hasSign) {
      validAnswer = `+${validAnswer}`;
    }

    const temp = {
      text: validAnswer,
      lessonElementId: model.lessonElementId
    };

    state.setCurrentResponse(UtilsService.safeMobxClone(temp));
  }

  resetStudentAnswer = (state, validation) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  isAutoScored = (model) => {
    const autoScore = ContentType.getAutoscore(model.type) && !model.survey && !model.unscored;
    return autoScore;
  }

  getScore = (responseAnswer, model) => {
    const numberAnswer = this._toNumber(responseAnswer.text);
    for (let i = 0; i < model.validation?.answers.length; i++) {
      const answerText = model.validation.answers[i].text;
      const answerNumber = this._toNumber(answerText);
      if (numberAnswer === answerNumber) {
        return model.maxScore;
      }
    }
    return 0;
  }

  setResponseData = (data, responseAnswer) => {
    responseAnswer.text = data;
  }

  getAnswerFeedback = (id, responseAnswer) => {
    return true;
  }

  _getInitialAnswer = (model) => {
    const columnCount = model.columnCount ?? 6;
    const decimalColumn = model.decimalColumn ?? 1;
    const decimalType = model.decimalType ?? 'fixed';
    const answer = (`+${ ' '.repeat(columnCount)}`).split('');
    if (decimalType === 'fixed') {
      // Place the decimal at the correct right aligned position
      answer[columnCount - decimalColumn] = '.';
    }
    return answer.join('');
  };

  getInitializedResponse = (lessonElementId, model, activityId) => {
    const responseAnswer = {
      ...questionService.generateInitializedQuestionResponse({
        text: this._getInitialAnswer(model),
        isScored: false,
        isScoredCorrect: false,
        lessonElementId
      })
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    const correctAnswers = model.validation?.answers.map((answer) => {
      return this._toNumber(answer.text);
    });

    return renderToStaticMarkup(
      <>
        {
          correctAnswers.map((answer, index) => {
            return (
              <span key={index}>
                {answer}
              </span>
            );
          })
        }
      </>
    );
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectPrompt()}. ${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (_answerId, lessonElementState, _model) => {
    // There is only one answer.
    // eslint-disable-next-line eqeqeq
    return lessonElementState.isScored && (lessonElementState.scoreValue == lessonElementState.maxScore);
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }

  setQuestionScores = (scoreValue, maxScore, lessonElementId, responseManager) => {
    if (scoreValue === maxScore) {
      responseManager.setResponseStateCorrect(lessonElementId, true);
      responseManager.setResponseStateScored(lessonElementId, true);
    }
  }
}

export default new GriddableBehavior();
