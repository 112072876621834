import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import '../../css/BuilderLayout.scss';
import ItemBankCard from './ItemBankCard';
import itemBankService from '../services/ItemBankService';
import Loader from '../../lessonPlayer/components/Loader';
import AddItemToAssessmentButton from './AddItemToAssessmentButton';

import { useDroppable } from '@dnd-kit/core';

const Droppable = (props) => {
  const { id, data, children, className } = props;
  const { isOver, setNodeRef } = useDroppable({
    id,
    data
  });

  const style = {
    backgroundColor: isOver ? '#d2e9f7' : '',
  };

  return (
    <div ref={setNodeRef} style={style} className={className}>
      {children}
    </div>
  );
};

const ResourceBucketItemList = observer((props) => {
  const {
    itemBankManager,
    lessonManager
  } = useContext(MobXProviderContext);
  const { Draggable, dragging, refreshCallback } = props;

  useEffect(() => {
    async function getResourceBankItems() {
      if (lessonManager.lessonContentItem && lessonManager.lessonContentItem.id) {
        await itemBankService.fetchLessonBankItems(lessonManager.lessonContentItem.id);
        if (itemBankManager.lessonItems.size === 0 && itemBankManager.resourceBucketItems.size === 0) {
          itemBankManager.setViewMode('addItems');
        }
      }
    }
    getResourceBankItems().then();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonManager.lessonContentItem]);

  const items = itemBankManager.resourceBucketItemsArray;

  return (
    <Droppable
      className='resource-bank-items'
      key={`bucket_list_Droppable`}
      direction='horizontal'
      id={`bucket_list_Droppable`}>
      <div className='resource-bank-item-list'>
        {
          (items && items.length > 0) &&
            items.map((item, idx) => {
              const { libraryResource } = item;
              if (item.visibleInResourceBucket && libraryResource.showInStore) {
                return (
                  <div key={`${item.id}_ibCR-${idx}`} className='item-bank-card-controller resource-list'>
                    {(!dragging || !dragging.isDragging || dragging.item.id !== item.id) ? (
                      <>
                        <Draggable key={`${item.id}_drg`} disabled={false} id={item.id} data={item} index={idx}>
                          <div className='item-draggable-container'>
                            <ItemBankCard className="item-drop-target" includeRemove={true} libraryCardParams={item} refreshCallback={refreshCallback} />
                          </div>
                        </Draggable>
                        <AddItemToAssessmentButton itemId={item.id} refreshCallback={refreshCallback} />
                      </>
                    ) : null}
                  </div>
                );
              }
              return (<React.Fragment key={`${item.id}_ibCR-${idx}`} />);
            })
        }
        {
          itemBankManager.loading && <Loader />
        }
      </div>
    </Droppable>
  );
});

export default ResourceBucketItemList;
