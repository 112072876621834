import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactModal from 'react-modal-resizable-draggable';

import parse from 'html-react-parser';

import { PDFObject } from 'react-pdfobject';

import classNames from 'classnames';

import '../../../css/Tools.scss';
import { register } from '../../../i18n';

import authService from '../../services/AuthService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import { useSizeChange } from '../../../hooks/useSizeChange';

import HtmlComponent from '../HtmlComponent';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';

const t2 = register('AriaLabels');

/**
 * Modal (dialog) for previewing a given resource.
 *
 * @param {{
 *   allowResourcePadding: boolean;
 *   autoPlay: boolean;
 *   handleCloseResourcePreviewDialog: () => {};
 *   modalDisableResize: boolean;
 *   modalHeight: string | number;
 *   modalLeft: string | number;
 *   modalTop: string | number;
 *   modalWidth: string | number;
 *   resourceData: {};
 *   resourceDisplayName: string;
 *   resourceOpenId: string;
 *   resourceType: string;
 *   resourceUrl: string;
 * }} props
 *
 * Can pass `props` for a specific resource, else prop values associated with `toolbarManager` will be used.
 */
const ResourcePreviewDialog = observer((props = {}) => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const allowResourcePadding = !!props.allowResourcePadding;
  const modalDisableResize = !!props.modalDisableResize;
  const resourceData = props.resourceData || toolbarManager.resourceData;
  let resourceDisplayName = props.resourceDisplayName || toolbarManager.resourceDisplayName;
  if (parse(resourceDisplayName)?.type === 'img') {
    resourceDisplayName = '';
  }
  const resourceOpenId = props.resourceOpenId || toolbarManager.resourceOpenId;
  const resourceType = props.resourceType || toolbarManager.resourceType;

  const closeRef = useRef();

  const handleCloseResourcePreviewDialog = (_event) => {
    if (props.handleCloseResourcePreviewDialog) {
      props.handleCloseResourcePreviewDialog?.();
    } else {
      toolbarManager.setIsResourcesOpen(false);
      if (resourceOpenId != null) {
        toolbarManager.toggleToolOff(resourceOpenId);
      }
    }
  };

  useAccessibilityClick(closeRef, handleCloseResourcePreviewDialog);

  useEffect(() => {
    if (resourceType === 'article_resource') {
      const modalContainer = document.getElementsByClassName('flexible-modal')[0];
      modalContainer.style.top = '20px';
    }
  }, []);

  let width = 800;
  let height = 400;
  let suggestedWidth = null;
  let suggestedHeight = null;
  let suggestedAspectRatio = null;
  if (typeof (resourceData) === 'object' && 'size' in resourceData) {
    suggestedWidth = resourceData.size.width;
    suggestedHeight = resourceData.size.height;
    suggestedAspectRatio = suggestedWidth / suggestedHeight;
  }

  const widthClamp = window.innerWidth - 100;
  const heightClamp = window.innerHeight - 150;
  if (suggestedAspectRatio != null) {
    if (suggestedWidth > widthClamp || suggestedHeight > heightClamp) {
      if (suggestedWidth > widthClamp) {
        width = widthClamp;
        height = width / suggestedAspectRatio;
      }
      if (suggestedHeight > heightClamp) {
        height = heightClamp;
        width = height * suggestedAspectRatio;
      }
    } else {
      width = suggestedWidth;
      height = suggestedHeight;
    }
  } else {
    width = Math.min(widthClamp, width);
    height = Math.min(heightClamp, height);
  }

  if (resourceType === 'article_resource' && resourceData.className?.indexOf('articlePopup') !== -1) {
    const contentLength = parseInt(resourceData.contentLength);
    width = 600;
    height = '';
    if (contentLength < 30 && !resourceData.hasImage) {
      width = 260;
    } else if (contentLength > 250) {
      width = 800;
    }
  }

  const articleStyle = { maxWidth: `${widthClamp }px`, maxHeight: `${heightClamp }px`, height: 'calc(100% - 10px)' };
  // TODO unused // const [disabled, setDisabled] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [modalRef, _] = useSizeChange(async (_dims, _ref) => {
    if (resourceType === 'article_resource') {
      const articleContainer = document.getElementsByClassName('article-container')[0];
      articleContainer.style.height = 'calc(100% - 10px)';
    }
  });

  let internals = null;
  switch (resourceType) {
  case 'pdf_resource':
    internals = (
      <PDFObject url={resourceData} />
    );
    break;
  case 'article_resource':
    internals = (
      <div className='article-container' data-allow='1' style={articleStyle}>
        <HtmlComponent htmlStr={resourceData.content} />
      </div>
    );
    break;
  case 'image_resource':
    internals = (
      <div className={classNames('centered-content', {
        'centered-content-padding': allowResourcePadding
      })}>
        <img
          alt={resourceDisplayName}
          className='fit-content'
          src={props.resourceUrl || authService.getResourceUrlByFileName(resourceData.fileName)} />
      </div>
    );
    break;
  case 'video_resource':
    internals = (
      <div className={classNames('centered-content', {
        'centered-content-padding': allowResourcePadding
      })}>
        <video
          alt={resourceDisplayName}
          autoPlay={props.autoPlay}
          className='fit-content'
          controls
          src={props.resourceUrl || authService.getResourceUrlByFileName(resourceData.fileName)} />
      </div>
    );
    break;
  case 'audio_resource':
    internals = (
      <div className={classNames('centered-content', {
        'centered-content-padding': allowResourcePadding
      })}>
        <audio
          alt={resourceDisplayName}
          autoPlay={props.autoPlay}
          className='fit-content'
          controls
          src={props.resourceUrl || authService.getResourceUrlByFileName(resourceData.fileName)} />
      </div>
    );
    break;
  default:
    console.error('Unknown resource type', resourceType, '(came with data', resourceData, ')');
  }

  useReactModalResizeableDraggable('resourcesTool');

  return (
    <div className='resourcesTool resourcePreviewDialog'>
      <ReactModal
        disableResize={modalDisableResize}
        initHeight={props.modalHeight || (height + 25)}
        initWidth={props.modalWidth || width}
        isOpen={true}
        onRequestClose={handleCloseResourcePreviewDialog}
        {...(props.modalLeft || props.modalLeft === 0 ? { left: props.modalLeft } : {})}
        {...(props.modalTop || props.modalTop === 0 ? { top: props.modalTop } : {})}>
        <div ref={modalRef} className={classNames('modalBody', resourceType)}>
          <div className='tool-header'>
            <div className='title-label large'>
              <div className='title-label-inner'>
                {parse(resourceDisplayName)}
              </div>
              {/* TODO remove - we are now using CSS to handle title truncation for this modal */}
              {/* {utilsService.truncateText(resourceDisplayName, 26)} */}
            </div>
            <div ref={closeRef} aria-label={t2('closedialog')} className='x-close-button' id='modal-close'
              onClick={handleCloseResourcePreviewDialog} role='button' tabIndex='0' />
          </div>
          {internals}
        </div>
      </ReactModal>
    </div>
  );
});

export default ResourcePreviewDialog;
