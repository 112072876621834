import React from 'react';

const DefaultInstruction = ({ model }) => {
  return (
    <>
      <div className='top-label'>Instruction:</div>
      <div className='element-container' id={`_${model.id}`}>
        <div className='label-value-row'>
          <div>Instruction Type:</div>
          <div className='not-implemented'>{model.type} (*** not implemented yet ***)</div>
        </div>
      </div>
    </>
  );
};
export default DefaultInstruction;
