import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { MobXProviderContext, observer } from 'mobx-react';

import {
  DndContext, DragOverlay, KeyboardSensor, PointerSensor,
  useDraggable, useDroppable, useSensor, useSensors
} from '@dnd-kit/core';

import { snapCenterToCursor } from '@dnd-kit/modifiers';

import classNames from 'classnames';

import '../../../css/ImageTextMatch.scss';
import { LessonMode } from '../../../Constants';

import { register } from '../../../i18n';

import Auth from '../../services/AuthService';

import userManager from '../../managers/UserManager';

import navMenuService from '../../services/NavMenuService';
import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';

import useStyleEvents from '../../../hooks/useStyleEvents';

import FeedbackIcon from '../FeedbackIcon';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import PrintQuestionNumber from './PrintQuestionNumber';

const t = register('ImageTextMatch');

const Draggable = ({
  children,
  className,
  id,
  model,
  prompt,
  readOnly,
  target = null,
  type
}) => {
  const additionalDraggableProps = {};

  if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'image') {
    additionalDraggableProps['aria-label'] = prompt?.sourceLabelAria || '';
  } else if (model.sourceDisplay === 'htmlText' || model.sourceDisplay === 'html') {
    const htmlTextAriaLabel = prompt?.sourceLabelAria || utilsService.stripHtmlTagsAdvanced(prompt?.sourceHtml || prompt?.sourceText || '');
    additionalDraggableProps['aria-label'] = htmlTextAriaLabel;
  }

  const { setNodeRef, attributes, listeners } = useDraggable({
    id,
    data: {
      promptId: (prompt) ? prompt.id : '',
      type,
      activeText: (target) ? target.text : '',
      prompt
    },
    disabled: readOnly
  });

  return (
    <div ref={setNodeRef} className={className} {...listeners} {...attributes}
      {...additionalDraggableProps}>
      {children}
    </div>
  );
};

const OverlaySourceCard = ({ model, prompt }) => {
  const backStyle = {};
  const wrapperStyle = { width: `${model.imageWidth}px`, height: `${model.imageHeight}px` };

  let showHtml = false;
  let className = 'image';

  if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'image') {
    backStyle.backgroundImage = `url(${prompt.imageUrl})`;
  }

  if (model.sourceDisplay === 'html' || model.sourceDisplay === 'htmlText') {
    showHtml = true;
    className = 'text';
  }

  return (
    <div className={classNames('match-container')} style={wrapperStyle} tabIndex={0}>
      <div className={classNames('matcher', 'source', className)}>
        <div className='back match-card' style={backStyle}>
          {showHtml && <HtmlComponent htmlStr={prompt.sourceHtml} />}
        </div>
      </div>
    </div>
  );
};

const DraggableSourceCard = observer(({ model, prompt }) => {
  const {
    lessonManager,
    responseManager,
  } = useContext(MobXProviderContext);

  const backStyle = {};
  const wrapperStyle = { width: `${model.imageWidth }px`, height: `${model.imageHeight}px` };
  if (model.layout === 'manualLayout') {
    wrapperStyle.position = 'absolute';
    wrapperStyle.top = `${prompt.top}px`;
    wrapperStyle.left = `${prompt.left}px`;
  }

  let showLabel = false;
  let showHtml = false;
  let className = 'image';

  const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;
  const readOnly = (lessonElementState && lessonElementState.readOnly) || lessonManager.isReadOnly;

  const matchCardAdditionalProps = {};

  let answer = null;

  if (currentResponseAnswer) {
    answer = currentResponseAnswer.prompts.find(({ id }) => prompt.id === id);
  }

  if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'image') {
    backStyle.backgroundImage = `url(${prompt.imageUrl})`;
    matchCardAdditionalProps['aria-label'] = prompt.sourceLabel;
  }

  if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'htmlText') {
    showLabel = true;
  }

  if (model.sourceDisplay === 'html' || model.sourceDisplay === 'htmlText') {
    showHtml = true;
    className = 'text';
  }

  const sideLabel = (model.labelPosition === 'right' && model.layout === 'columnLayout');

  let isBlank = false;

  if (answer && !utilsService.isNullOrEmpty(answer.text)) {
    isBlank = true;
  }

  return (
    <div className={classNames('match-container', 'prompt', { 'side-label': sideLabel, 'blank': isBlank })} style={wrapperStyle} tabIndex={-1}>
      {(!isBlank) ? (
        <Draggable
          className={classNames('matcher', 'source', className)}
          id={prompt.id}
          model={model}
          prompt={prompt}
          readOnly={readOnly}
          type='prompt'>
          <div className='back match-card' style={backStyle}>
            {showHtml && <HtmlComponent htmlStr={prompt.sourceHtml} />}
          </div>
        </Draggable>
      ) :
        <div className='back match-card' style={{ width: '100%', height: '100%' }} />}
      {showLabel && <div className='promptText prompt' data-text={prompt.sourceLabel}>{prompt.sourceLabel}</div>}
    </div>
  );
});

const ChangeDraggable = observer(({ model, target }) => {
  const {
    lessonManager,
    responseManager,
  } = useContext(MobXProviderContext);

  const backStyle = {};
  const wrapperStyle = { width: `${model.imageWidth}px`, height: `${model.imageHeight}px` };
  if (model.layout === 'manualLayout') {
    wrapperStyle.zIndex = 3;
    wrapperStyle.position = 'absolute';
    wrapperStyle.top = `${target.top}px`;
    wrapperStyle.left = `${target.left}px`;
  }

  let className = 'image';

  const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;
  const readOnly = (lessonElementState && lessonElementState.readOnly) || lessonManager.isReadOnly;

  let answer = null;
  let prompt = null;
  if (currentResponseAnswer) {
    answer = currentResponseAnswer.prompts.find(({ text }) => target.text === text);
  }

  if (answer) {
    prompt = model.prompts.find(({ id }) => answer.id === id);
  }
  let showHtml = false;

  if (answer && prompt) {
    if (model.sourceDisplay === 'image' || model.sourceDisplay === 'imageText') {
      backStyle.backgroundImage = `url(${prompt.imageUrl})`;
    }

    if (model.sourceDisplay === 'html' || model.sourceDisplay === 'htmlText') {
      showHtml = true;
      className = 'text';
    }
  }

  return (
    <Draggable
      className={classNames('matcher', 'change', className, { hide: (!answer) })}
      id={`${target.text}_switcher`}
      model={model}
      prompt={prompt}
      readOnly={readOnly}
      target={target} type='swap'>
      <div className='back match-card' style={backStyle}>
        {showHtml && <HtmlComponent htmlStr={(prompt) ? prompt.sourceHtml : ''} />}
      </div>
    </Draggable>
  );
});

const DroppableTargetCard = observer(({ model, target }) => {
  const {
    responseManager,
  } = useContext(MobXProviderContext);

  const backStyle = {};
  const sideLabel = (model.labelPosition === 'right' && model.layout === 'columnLayout');
  const wrapperStyle = { width: `${model.imageWidth }px`, height: `${model.imageHeight}px` };

  const matcherStyle = {};

  if (sideLabel) {
    matcherStyle.width = `${model.imageWidth }px`;
  }

  let className = 'image';

  if (model.layout === 'manualLayout') {
    wrapperStyle.zIndex = 3;
    wrapperStyle.position = 'absolute';
    wrapperStyle.top = `${target.top}px`;
    wrapperStyle.left = `${target.left}px`;
  }

  const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;

  let answer = null;
  let prompt = null;
  if (currentResponseAnswer) {
    answer = currentResponseAnswer.prompts.find(({ text }) => target.text === text);
  }

  if (answer) {
    prompt = model.prompts.find(({ id }) => answer.id === id);
  }

  if (model.targetDisplay === 'image' || model.targetDisplay === 'imageText') {
    if (target.targetImageUrl && target.targetImageUrl.indexOf('redirectToStream') > -1) {
      const id = Auth.getEntityIdFromStreamUrl(target.targetImageUrl);
      backStyle.backgroundImage = `url(${Auth.getResourceUrlByResourceId(id)}&entityTypeId=image-resource)`;
    } else {
      backStyle.backgroundImage = `url(${target.targetImageUrl})`;
    }
  }

  const { isOver, setNodeRef, active } = useDroppable({
    id: `${target.text}`,
    prompt
  });

  let showHtml = false;
  if (answer && prompt) {
    if (model.sourceDisplay === 'html' || model.sourceDisplay === 'htmlText') {
      showHtml = true;
      className = 'text';
    }
  } else {
    if (model.targetDisplay === 'html' || model.targetDisplay === 'htmlText') {
      showHtml = true;
      className = 'text';
    }
  }

  let isActive = false;
  if (active && answer && active.data.current.type === 'swap' && active.data.current.activeText === answer.text) {
    isActive = true;
  }

  let showLabel = false;
  if (model.targetDisplay === 'text' || model.targetDisplay === 'htmlText' || model.targetDisplay === 'imageText') {
    showLabel = true;
  }

  return (
    <div className={classNames('match-container', 'target', { 'side-label': sideLabel, 'active': isActive, 'hasAnswer': (answer) })} style={wrapperStyle} tabIndex={0}>
      {!!answer && <ChangeDraggable model={model} target={target} />}
      <div ref={setNodeRef} className={classNames('matcher', 'target', className, { isOver, })} style={matcherStyle}>
        <div className='back match-card' style={backStyle}>
          {showHtml && <HtmlComponent htmlStr={target.targetText} />}
        </div>
      </div>
      {showLabel ? <div className='promptText target' data-text={target.text}>{target.text}</div> : null}
      <FeedbackIcon answerId={target.text} lessonElementId={model.lessonElementId} />
    </div>
  );
});

const ImageTextMatch = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  const [promptDraggingId, setPromptDraggingId] = useState(null);
  const [draggingPrompt, setDraggingPrompt] = useState(null);

  // help improve drag/drop sensitivity for mobile devices
  const sensors = useSensors(
    useSensor(KeyboardSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        tolerance: 5
      }
    })
  );

  // If labels scroll width is greater then the offset width that means it has been truncated. We
  // add a class to those that have been truncated so we can show a popup with the full text.
  useEffect(() => {
    const labels = document.getElementsByClassName('promptText');
    for (const label of labels) {
      if (label.offsetWidth < label.scrollWidth) {
        label.classList.add('ellipsed');
      }
    }
  }, [promptDraggingId, draggingPrompt, lessonElementId]);

  const processedAnswers = (!lessonElementState) ? [] : utilsService.shuffleArrayForUser([...model.targets], userManager.userId);

  const SourceColumns = () => {
    return (
      <div className='source-column'>
        {
          model.prompts.map((prompt) => {
            return (<DraggableSourceCard key={utilsService.createGuid()} model={model} prompt={prompt} />);
          })
        }
      </div>
    );
  };
  const TargetColumns = () => {
    return (
      <div className='target-column'>
        {
          processedAnswers.map((target) => {
            return (<DroppableTargetCard key={utilsService.createGuid()} model={model} target={target} />);
          })
        }
      </div>
    );
  };

  const ManualLayout = () => {
    return (
      <>
        {
          model.prompts.map((prompt, index) => {
            return (<DraggableSourceCard key={index} model={model} prompt={prompt} />);
          })
        }
        {
          processedAnswers.map((target, index) => {
            return (<DroppableTargetCard key={index} model={model} target={target} />);
          })
        }
      </>
    );
  };

  const navToCurrentLessonElement = async () => {
    if (lessonManager.currentLessonElementId !== lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
    }
  };

  const handleDragStart = (event) => {
    navToCurrentLessonElement();
    if (event.active && event.active.id) {
      const prompt = model.prompts.find(({ id }) => id === event.active.data.current.promptId);
      if (prompt) {
        setDraggingPrompt(prompt);
      }
      setPromptDraggingId(event.active.id);
    }
  };

  const handleDragEnd = (event) => {
    setDraggingPrompt(null);
    setPromptDraggingId(null);
    if (event.collisions && event.collisions.length > 0) {
      responseService.responseChangeHandler(
        {
          id: event.active.data.current.promptId,
          text: event.collisions[0].id,
          type: event.active.data.current.type,
          activeText: event.active.data.current.activeText
        }, lessonElementId);
    }
  };

  useStyleEvents(lessonElementId);

  const dndContextAccessibility = {
    // used by screen readers
    announcements: {
      onDragStart({ active }) {
        const prompt = active?.data?.current?.prompt;
        const dragAnnouncementLabel = getImageTextMatchDragAnnoucementLabel(prompt);
        return t('dragStartAnnouncement', {
          sourceLabel: dragAnnouncementLabel
        });
      },
      onDragOver({ active, over }) {
        if (over) {
          const prompt = active?.data?.current?.prompt;
          const dragAnnouncementLabel = getImageTextMatchDragAnnoucementLabel(prompt);
          return t('dragOverAnnouncement', {
            sourceLabel: dragAnnouncementLabel,
            targetLabel: over?.id || ''
          });
        }
      },
      onDragEnd({ active, over }) {
        const prompt = active?.data?.current?.prompt;
        const dragAnnouncementLabel = getImageTextMatchDragAnnoucementLabel(prompt);
        if (over) {
          return t('dragOverEndAnnouncement', {
            sourceLabel: dragAnnouncementLabel,
            targetLabel: over?.id || ''
          });
        } else {
          return t('dragEndAnnouncement', {
            sourceLabel: dragAnnouncementLabel,
            targetLabel: over?.id || ''
          });
        }
      },
      onDragCancel({ active }) {
        const prompt = active?.data?.current?.prompt;
        const dragAnnouncementLabel = getImageTextMatchDragAnnoucementLabel(prompt);
        return t('dragCancelAnnouncement', {
          sourceLabel: dragAnnouncementLabel
        });
      }
    },
    screenReaderInstructions: t('screenReaderInstructions')
  };

  const getImageTextMatchDragAnnoucementLabel = (prompt) => {
    let dragAnnouncementLabel;
    if (model.sourceDisplay === 'imageText' || model.sourceDisplay === 'image') {
      dragAnnouncementLabel = prompt?.sourceLabelAria || '';
      return dragAnnouncementLabel;
    } else if (model.sourceDisplay === 'htmlText' || model.sourceDisplay === 'html') {
      const htmlTextAriaLabel = prompt?.sourceLabelAria || utilsService.stripHtmlTagsAdvanced(prompt?.sourceHtml || prompt?.sourceText || '');
      dragAnnouncementLabel = htmlTextAriaLabel;
      return dragAnnouncementLabel;
    }
  };

  return (
    <div className='match-select-question'>

      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <DndContext
        accessibility={dndContextAccessibility}
        modifiers={[snapCenterToCursor]}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        sensors={sensors}>
        <div aria-labelledby={`test-item-question-${lessonElementId}`} className='test-item-answers'>
          <div className='card-container'>
            <div className={`${model.layout}`}>
              {
                (model.layout === 'columnLayout') && (
                  <>
                    <SourceColumns />
                    <TargetColumns />
                  </>
                )
              }
              {(model.layout === 'rowLayout') && (
                <>

                  <SourceColumns />
                  <TargetColumns />
                </>
              )}
              {
                (model.layout === 'manualLayout') && (
                  <>
                    <ManualLayout />
                  </>
                )
              }

            </div>
          </div>
        </div>
        {createPortal(
          <DragOverlay className='dragging-prompt'>
            {promptDraggingId ? (
              <OverlaySourceCard
                model={model}
                prompt={draggingPrompt} />
            ) : null}
          </DragOverlay>, document.getElementById('the-body'))}
      </DndContext>
    </div>
  );
});
export default ImageTextMatch;
