import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { /* closestCenter, */ DndContext, DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

import '../../../css/Categorize.scss';
import { LessonMode } from '../../../Constants';

import playerService from '../../services/PlayerService';
import responseService from '../../services/ResponseService';
import styleService from '../../services/StyleService';

import FeedbackIcon from '../FeedbackIcon';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import useStyleEvents from '../../../hooks/useStyleEvents';
import navMenuService from '../../services/NavMenuService';
import PrintQuestionNumber from './PrintQuestionNumber';

const FIRST_COL_PERCENT = 15;

const Droppable = (props) => {
  const { id, children } = props;
  const { isOver, setNodeRef } = useDroppable({
    id,
  });
  const { getStyleVar } = styleService;
  const bgColor = getStyleVar('--theme-drag-drop-dragover-color')

  const style = {
    backgroundColor: isOver ? bgColor : '',
    height: '100%',
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

const Draggable = (props) => {
  const { id, children, disabled } = props;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    disabled,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    padding: 0,
    margin: '8px',
    fontFamily: 'Facit-Regular',
    lineHeight: 1.4,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

const Categorize = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const [dragging, setDragging] = useState({ isDragging: false, answer: null });

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);

  const readOnly = playerService.isReadOnly(lessonElementId);
  const { showAnswers } = feedbackState; // Do we show what answers are correct
  const { showAnswerFeedback } = feedbackState; // Do we show feedback for the user

  // These are both objects with keys like cell0 or bank0 and value array of prompt objects with at least keys id and text
  const { bankPrompts, cellPrompts } = lessonElementState.currentResponseAnswer;

  // Set up state
  // Categories
  const hasSubcategories = model.rowLabels.length > 0 && model.rowLabels[0].length > 0; // If there are no subcategories we leave out the left side headers
  const subcategories = model.rowLabels;
  const categories = model.columnLabels;
  const numberBlanks = model.numberedBlanks;

  const firstColumnPercent = hasSubcategories ? FIRST_COL_PERCENT : 0;
  const otherColumnPercent = (100 - firstColumnPercent) / categories.length;

  const hasCustomBankBackground = model.customAnswerLayoutBackground;
  const customBankBackgroundHTML = model.customAnswerLayoutBackgroundHtml;

  // Answers
  const answerBoxType = (model.answerLayoutType) ? model.answerLayoutType : 'auto'; // 'manual' or 'auto' - default to auto
  const answerBoxSize = answerBoxType === 'manual' ? { width: parseInt(model.answerLayoutWidth), height: parseInt(model.answerLayoutHeight) } : null;
  const answerBoxPosition = (model.answerBankPosition) ? model.answerBankPosition : 'bottom'; // 'left', 'right', 'top', 'bottom'
  const answerBoxHeader = model.answerBankHeading;
  const internalTextStyle = {
    promptLayout: model.promptLayout, // 'text', 'image', 'imageText'
    verticalAlignment: model.answerVerticalAlignment, // 'left', 'center', 'right'
    horizontalAlignment: model.answerAlignment, // 'left', 'center', 'right'
    padding: model.answerPadding, // int
    imageWidth: model.imageWidth, // int
    imageHeight: model.imageHeight, // int
    showAnswers,
    showAnswerFeedback,
    lessonElementId
  };

  useStyleEvents(lessonElementId);

  const formatZoneAnswerItems = (answerItems) => {
    // Convert { id, imageUrl, text } to { answerId, answer, dimensions }
    if (answerItems == null) {
      return [];
    }
    const formattedAnswerItems = answerItems.map((answerItem) => {
      return {
        answerId: answerItem.id,
        answer: { text: answerItem.text, imageUrl: answerItem.imageUrl },
        dimensions: answerBoxType === 'manual' ? { left: answerItem.left, top: answerItem.top, height: answerItem.height, width: answerItem.width } :
          { left: '', top: '', height: '', width: '' },
        textStyle: internalTextStyle
      };
    });
    return formattedAnswerItems;
  };

  const bankKeys = Object.keys(bankPrompts);

  const getZoneAnswerItems = (zoneKey) => {
    // check if zoneKey is a string
    if (typeof zoneKey === 'string' && zoneKey.includes('bank')) {
      // Then this is a bank key and we can use it directly
      return formatZoneAnswerItems(bankPrompts[zoneKey]);
    } else if (typeof zoneKey === 'number') {
      // Then this is a cell key. Cell keys are just the index because it makes rendering more easy
      const cellKey = `cell${zoneKey}`;
      return formatZoneAnswerItems(cellPrompts[cellKey]);
    }
  };
  const navToCurrentLessonElement = async () => {
    if (lessonManager.currentLessonElementId !== lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
    }
  };
  const onDragStart = ({ active }) => {
    navToCurrentLessonElement();
    const draggableId = active.id;
    const answerId = draggableId.split('_')[1];
    const answer = findAnswerById(answerId);
    const formattedAnswer = {
      answerId: answer.id,
      answer: { text: answer.text, imageUrl: answer.imageUrl },
      dimensions: answerBoxType === 'manual' ? { left: answer.left, top: answer.top, height: answer.height, width: answer.width } :
        { left: '', top: '', height: '', width: '' },
      textStyle: internalTextStyle
    };
    setDragging({ isDragging: true, answer: formattedAnswer });
  };

  const findAnswerById = (id) => {
    const answer = model.prompts.find((prompt) => prompt.id === id);
    return answer;
  };

  const onDragEnd = ({ over, active }) => {
    setDragging({ isDragging: false, answer: null });

    // if not dropped on a drop zone, do nothing
    if (!over?.id) {
      return;
    }

    const destinationZoneKey = over.id;
    const draggableId = active.id;
    const [sourceZoneKey, answerId] = draggableId.split('_');

    // if not moved to a different zone, do nothing
    if (sourceZoneKey === destinationZoneKey) {
      return;
    }

    responseService.responseChangeHandler({ sourceZoneKey, destinationZoneKey, answerId }, lessonElementId);
  };

  return (
    <DndContext
      // TODO commented out because it was causing draggable items to 'jump' to whatever droppable area was closest to it.
      // collisionDetection={closestCenter}
      modifiers={[snapCenterToCursor]}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}>

      <DragOverlay className='drag-overlay'>
        {(dragging && dragging.isDragging) ? (
          <div className='answer-item' style={{ border: '1px solid gray', backgroundColor: '#fff' }}>
            <AnswerBox answer={dragging.answer.answer} answerId={dragging.answer.answerId} dimensions={dragging.answer.dimensions} textStyle={dragging.answer.textStyle} />
          </div>
        ) : null}
      </DragOverlay>

      <div className='categorize-question'>
        {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
        <div className='test-item-question'>
          {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
          <HtmlComponent htmlStr={model.questionText} />
        </div>
        <OptionalImage model={model} runtime={true} />
        <div className={`test-item-answers bank-${answerBoxPosition}`}>
          <div>
            <table className='categorize-table' style={{ width: '100%' }}>
              <thead>
                <tr>
                  {hasSubcategories && <th>&nbsp;</th>}
                  {categories.map((category, index) => {
                    return (
                      <th key={index} style={{ color: 'initial' }}>
                        <HtmlComponent htmlStr={category} />
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {
                  subcategories.map((subcategory, subcategoryIndex) => (
                    <tr key={subcategoryIndex}>
                      {hasSubcategories && (
                        <th style={{
                          color: 'initial',
                          width: `${firstColumnPercent}%`
                        }}>
                          <HtmlComponent htmlStr={subcategory} />
                        </th>
                      )}
                      {categories.map((_, categoryIndex) => {
                        const zoneIndex = subcategoryIndex * categories.length + categoryIndex;
                        return (
                          <td key={categoryIndex} style={{ width: `${otherColumnPercent}%` }}>
                            <AnswerDropZone answerItems={getZoneAnswerItems(zoneIndex)} disabled={readOnly || showAnswerFeedback} dragging={dragging} showZoneIndex={numberBlanks}
                              zoneIndex={zoneIndex} />
                          </td>
                        );
                      })}
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className='answer-bank'>
            {answerBoxHeader && <HtmlComponent className='bank-heading' htmlStr={answerBoxHeader} />}
            {answerBoxType === 'auto' && <AutoBank disabled={readOnly || showAnswerFeedback} dragging={dragging} getZoneAnswerItems={getZoneAnswerItems} />}
            {answerBoxType === 'manual' && (
              <ManualBank
                bankKeys={bankKeys}
                bankSize={answerBoxSize}
                customBankBackgroundHTML={customBankBackgroundHTML}
                disabled={readOnly || showAnswerFeedback}
                dragging={dragging}
                getZoneAnswerItems={getZoneAnswerItems}
                hasCustomBankBackground={hasCustomBankBackground}
              />
            )}
            {/* It's technically possible for it to be passed as neither */}
          </div>
        </div>
      </div>
    </DndContext>
  );
});
export default Categorize;

const AutoBank = ({ dragging, getZoneAnswerItems, disabled = false }) => {
  const answerItems = getZoneAnswerItems('bank0');
  return (
    <div className='bank-content auto'>
      <AnswerDropZone answerItems={answerItems} disabled={disabled} dragging={dragging} zoneIndex='bank0' />
    </div>
  );
};

const ManualBank = ({ dragging, getZoneAnswerItems, bankKeys, bankSize, hasCustomBankBackground, customBankBackgroundHTML, disabled = false }) => {
  const customStyle = bankSize;
  if (hasCustomBankBackground) {
    customStyle['backgroundColor'] = '#FFFFFF';
  }
  return (
    <div className='bank-content manual' style={{ ...customStyle }}>
      {hasCustomBankBackground && <div className='custom-background'><HtmlComponent htmlStr={customBankBackgroundHTML} /></div>}
      {
        bankKeys.map((bankKey) => {
          const answerItems = getZoneAnswerItems(bankKey);
          const prompt = answerItems[0]; // There should only ever be one or 0 per bank in manual banks
          if (!prompt) {
            return (
              <div key={bankKey} style={{ position: 'absolute', ...bankSize }}>
                <AnswerDropZone answerItems={answerItems} disabled={disabled} dragging={dragging} zoneIndex={bankKey} />
              </div>
            );
          }

          const { top, left } = prompt.dimensions;
          const promptContainerStyles = {
            position: 'absolute',
            top: `${top}px`,
            left: `${left}px`,
            zIndex: '2'
          };
          return (
            <div key={bankKey} style={{ ...promptContainerStyles }}>
              <AnswerDropZone answerItems={answerItems} disabled={disabled} dragging={dragging} zoneIndex={bankKey} />
            </div>
          );
        })
      }
    </div>
  );
};

const AnswerBox = ({ answerId, answer, dimensions, textStyle }) => {
  const { promptLayout, verticalAlignment, horizontalAlignment, padding, imageWidth, imageHeight, showAnswers, showAnswerFeedback, lessonElementId } = textStyle;
  const hasText = promptLayout === 'text' || promptLayout === 'textImage';
  const hasImage = promptLayout === 'image' || promptLayout === 'textImage';

  const containerStyles = {
    padding: '6px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };
  if (verticalAlignment && verticalAlignment.length > 0) {
    const positionMap = {
      top: 'flex-start',
      center: 'center',
      bottom: 'flex-end',
    };
    containerStyles.justifyContent = positionMap[verticalAlignment];
  }
  if (horizontalAlignment && horizontalAlignment.length > 0) {
    const positionMap = {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end',
    };
    containerStyles.alignItems = positionMap[horizontalAlignment];
  }
  if (padding && padding.length > 0) {
    containerStyles.padding = `${padding}px`;
  }

  const { text, imageUrl } = answer;
  const imageStyles = {
    width: `${imageWidth}px`,
    height: `${imageHeight}px`,
    backgroundImage: `url(${imageUrl})`,
  };

  const { left, top, height, width } = dimensions; // These dimensions are sometimes given and sometimes not.
  return (
    <div className='answer-box' style={{ left, top, height, width }}>
      <div className='answer-box-filler' style={{ ...containerStyles }}>
        {(showAnswers || showAnswerFeedback) && (
          <div className='feedback-icon-container'>
            <FeedbackIcon answerId={answerId} lessonElementId={lessonElementId} />
          </div>
        )}
        <div className='answer-text'>
          {hasImage && <div className='answer-image' style={{ ...imageStyles }} />}
          {hasText && <HtmlComponent htmlStr={text} />}
        </div>
      </div>
    </div>
  );
};

const AnswerDropZone = ({ dragging, answerItems, zoneIndex, showZoneIndex = false, disabled = false }) => {
  return (
    <Droppable
      direction='horizontal'
      id={zoneIndex.toString()}>
      <div className='answer-drop-zone'>
        {showZoneIndex && <div className='zone-index'>{zoneIndex + 1}</div>}
        {answerItems.map((answer, index) => {
          const opacity = (dragging && dragging.isDragging && dragging.answer.answerId === answer.answerId) ? 0 : 1;
          return (
            <Draggable key={answer.answerId} disabled={disabled} id={`${zoneIndex}_${answer.answerId}`} index={index}>
              <div className='answer-item' style={{ opacity }}>
                <AnswerBox answer={answer.answer} answerId={answer.answerId} dimensions={answer.dimensions} textStyle={answer.textStyle} />
              </div>
            </Draggable>
          );
        })}
      </div>
    </Droppable>
  );
};
