import { action, makeObservable, observable } from 'mobx';

export class NumberLineManager {
  @observable elementEditMode = '';

  @action setElementEditMode(elementEditMode) {
    this.elementEditMode = elementEditMode;
  }

  constructor() {
    makeObservable(this);
  }
}

export default new NumberLineManager();
