import { action, computed, makeObservable, observable } from 'mobx';
import Auth from '../services/AuthService';

export const ERROR_MESSAGES = {
  GENERAL: 'Something has gone wrong with your registration.  Please check your information and try again.  If you think everything is correct, please contact your administrator.',
  ACCESS_CODE_INVALID: 'This access code does not work. Please check your code and try again.',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match!',
  PASSWORD_LENGTH: 'The password must be equal to or greater than five characters long.',
  VALID_EMAIL_FORMAT: 'Please enter a valid email format. (name@example.com)',
  USER_EXISTS: 'There is already a user with that email address assigned to that access code.  Try resetting your password.  If the problem persists, contact your administrator.',
  USERNAME_EXISTS: 'There is already a user with the Username/email you entered.  Try again using a different username.'
};

export class UserManager {
    studentPermissionId = 'student_user';
    teacherPermissionId = 'teacher_user';
    manageLearnosityScoresId = 'manage_learnosity_scores';
    satelliteAdminId = 'satellite_admin_user';
    districtAdminId = 'district_admin_user';
    schoolAdminId = 'school_admin_user';
    publisherAdminId = 'manage_publisher_products';
    schoolProductAdminId = 'school_product_admin';
    districtProductAdminId = 'district_product_admin';
    publisherUserId = 'publisher_user';
    betaPermissionId = 'satellite_beta_access';
    viewEngagementDataId = 'view_engagement_data';
    copyPasteOverride = 'copy_paste_override';

    @observable displayName = '';
    @observable userId = '';
    @observable authKey = '';
    @observable permissionIds = {};
    @observable accommodationIds = {};
    @observable profileImageUrl = '';
    @observable fullProfileImageUrl = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable username = '';
    @observable loaded = false;
    @observable isSsoUser = false;
    @observable institutionImage = null;
    @observable institutionState = '';
    @observable hideDropdown = false;
    @observable institutionId = '';
    @observable restrictAvatar = false;
    @observable accessCodeResult = false;
    @observable emailResult = false;
    @observable institutionName = '';
    @observable institutionTimezoneid = '';
    @observable clientPrefix = 'DEMO';
    @observable isCoTeacher = false;
    @observable enabled = false;
    @observable timezoneId = '';
    @observable creationDate = '';
    @observable lastLogin = '';
    @observable loginAttempts = '';
    @observable textHelpEnabled = false;
    @observable publisherTextHelpActive = false;
    @observable showTextHelpForAll = false;
    @observable publisherId = '';
    @observable isImpersonatorAdmin = false;

    constructor() {
      makeObservable(this);
    }

    @action setIsImpersonatorAdmin(toggle) {
      this.isImpersonatorAdmin = toggle;
    }

    @action getIsImpersonatorAdmin() {
      return this.isImpersonatorAdmin;
    }

    @computed get isPublisherUser() {
      return this.loaded && (this.permissionIds.includes(this.publisherUserId) || this.permissionIds.includes(this.publisherAdminId));
    }

    @computed get isPublisherAdmin() {
      return this.loaded && this.permissionIds.includes(this.publisherAdminId);
    }

    @computed get isDistrictAdmin() {
      return this.loaded && this.permissionIds.includes(this.districtAdminId);
    }

    @computed get isSchoolAdmin() {
      return this.loaded && this.permissionIds.includes(this.schoolAdminId);
    }

    @computed get isDistrictProductAdmin() {
      return this.loaded && this.permissionIds.includes(this.districtProductAdminId);
    }

    @computed get isSchoolProductAdmin() {
      return this.loaded && this.permissionIds.includes(this.schoolProductAdminId);
    }

    @computed get isProductAdmin() {
      return this.isSchoolProductAdmin || this.isDistrictProductAdmin;
    }

    /* teacher cannot do satellite admin stuff */
    @computed get isSatAdmin() {
      return this.loaded && this.permissionIds.includes(this.satelliteAdminId);
    } /* but satellite admin can do some teacher stuff */

    @computed get isBetaUser() {
      return this.loaded && this.permissionIds.includes(this.betaPermissionId);
    }

    @computed get hasViewEngagementDataPermission() {
      return this.loaded && this.permissionIds.includes(this.viewEngagementDataId);
    }

    @computed get isTeacher() {
      return (this.loaded && (this.permissionIds.includes(this.teacherPermissionId) ||
        this.permissionIds.includes(this.satelliteAdminId))
      );
    }

    @computed get isStudent() {
      return (this.loaded && this.permissionIds.includes(this.studentPermissionId));
    }

    @computed get canViewAsTeacher() {
      // in case user is set to student and teacher
      if (this.loaded && this.permissionIds.includes(this.studentPermissionId)) {
        return false;
      }
      return (this.loaded && (this.permissionIds.includes(this.teacherPermissionId) ||
        this.permissionIds.includes(this.satelliteAdminId) ||
        this.permissionIds.includes(this.districtAdminId) ||
        this.permissionIds.includes(this.schoolAdminId) ||
        this.permissionIds.includes(this.publisherAdminId) ||
        this.permissionIds.includes(this.publisherUserId) ||
        this.permissionIds.includes(this.districtProductAdminId) ||
        this.permissionIds.includes(this.schoolProductAdminId))
      );
    }

    @computed get canManageLearnosityScores() { return (this.loaded && this.permissionIds.includes(this.manageLearnosityScoresId)); }

    @computed get hasCopyPasteOverride() {
      return this.loaded && this.permissionIds.includes(this.copyPasteOverride);
    }

    @action
    setLoaded(toggle) {
      this.loaded = toggle;
    }

    @action setIsCoTeacher(toggle) {
      this.isCoTeacher = toggle;
    }

    @action
    setHideDropdown(hide) {
      this.hideDropdown = hide;
    }

    @action
    setRestrictAvatar(restrict) {
      this.restrictAvatar = restrict;
    }

    @action
    setClientPrefix(prefix) {
      this.clientPrefix = prefix;
    }

    @action
    setAccessCodeResult(result) {
      this.accessCodeResult = result;
    }

    @action
    setEmailResult(result) {
      this.emailResult = result;
    }

    @action setPublisherTextHelpActive(active) {
      this.publisherTextHelpActive = active;
    }

    @action setShowTextHelpForAll(show) {
      this.showTextHelpForAll = show;
    }

    @action setTextHelpEnabled(enabled) {
      this.textHelpEnabled = enabled;
    }

    @action setAccommodationIds(ids) {
      this.accommodationIds = ids;
    }

    @action
    clear() {
      this.displayName = '';
      this.userId = '';
      this.authKey = '';
      this.permissionIds = {};
      this.accommodationIds = {};
      this.profileImageUrl = '';
      this.fullProfileImageUrl = '';
      this.firstName = '';
      this.lastName = '';
      this.username = '';
      this.loaded = false;
      this.institutionState = '';
      this.institutionId = '';
      this.institutionImage = null;
      this.isCoTeacher = false;
      this.hideDropdown = false;
      this.accessCodeResult = false;
      this.emailResult = false;
      this.institutionName = '';
      this.institutionTimezoneid = '';
      this.enabled = false;
      this.timezoneId = '';
      this.creationDate = '';
      this.lastLogin = '';
      this.loginAttempts = '';
      this.publisherId = '';
      this.isImpersonatorAdmin = false;
    }

    @action
    setUser=(user) => {
      const now = new Date();
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.username = user.username;
      this.displayName = user.displayName;
      this.permissionIds = user.permissionIds;
      // this.accommodationIds = user.accommodationIds; // there is now a separate call for these. keep in case they want cascade. "in any class"
      this.profileImageUrl = user.profileImageUrl;
      this.fullProfileImageUrl = `${user.fullProfileImageUrl }&authKey=${ user.authKey}&rn=${now.getTime()}`;
      this.userId = user.userId;
      this.authKey = user.authKey;
      Auth.setAuthKey(this.authKey);
      this.loaded = true;
      this.isSsoUser = user.isSsoUser && user.isSsoUser !== 'false';
      this.institutionId = user.institutionId;
      this.institutionState = user.institutionState;
      this.institutionName = user.institutionName;
      this.institutionTimezoneid = user.institutionTimezoneid;
      this.enabled = user.enabled;
      this.timezoneId = user.timezoneId;
      this.creationDate = user.creationDate;
      this.lastLogin = user.lastLogin;
      this.loginAttempts = user.loginAttempts;
      this.publisherId = user.publisherId;
    }

    createFullAuthUrl = (url) => {
      const now = new Date();
      return `${url}&authKey=${ this.authKey}&rn=${now.getTime()}`;
    }

    @action setUserEnabled(enabled) {
      this.enabled = enabled;
    }

    @action setFirstName(name) {
      this.firstName = name;
    }

    @action setLastName(name) {
      this.lastName = name;
    }

    @action async getTempStudentCode() {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/generateRandomCode`, {
          method: 'POST',
        });

        if (response.tempRandomCode) {
          return response.tempRandomCode;
        }
      } catch (e) {
        console.error(e);
      }
    }

    async fetchUser(userId) {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/getUser?userId=${userId}`, {
          method: 'GET',
        });

        if (response.status === 'SUCCESS') {
          if (response.users && response.users.length > 0) {
            return response.users[0];
          }
        }
        return null;
      } catch (e) {
        console.error(e);
      }
    }

    async fetchMyUserOptions() {
      // if we know, don't do it again.
      // if (this.publisherTextHelpActive !== null) {
      //  return;
      // }
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/getMyUserOptions`, {
          method: 'GET',
        });
        if (response.status === 'SUCCESS') {
          this.setPublisherTextHelpActive(response.textHelpActive);
          this.setTextHelpEnabled(response.textHelpEnabled);
        }
        return null;
      } catch (e) {
        console.error(e);
      }
    }

    @action impersonatorLogIn = async (authKey) => {
      const result = await this.authKeyLogIn(authKey);
      if (result) {
        this.setIsImpersonatorAdmin(true);
        return true;
      } else {
        return false;
      }
    }

    @action cleverLogIn = async (authKey) => {
      return this.authKeyLogIn(authKey);
    }

    @action authKeyLogIn = async (authKey) => {
      try {
        const user = await Auth.logInWithAuthKey(authKey);
        if (user && user.status === 'SUCCESS') {
          this.setUser(user);
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    @action publisherLogIn = async (authKey) => {
      try {
        const user = await Auth.logInWithAuthKey(authKey);
        if (user && user.status === 'SUCCESS') {
          this.setUser(user);
          return true;
        }
        return false;
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    @action setInstitutionImage = (image) => {
      this.institutionImage = image;
    }

    fetchInstitutionImage = async () => {
      if (this.userId !== null && this.userId !== undefined && this.userId !== '') {
        try {
          const response = await Auth.fetch(`${Auth.ecms}/api/viewMyInstitution`, {
            method: 'GET'
          });
          if (response && response.status && response.status === 'SUCCESS') {
            const institution = response.data[0];
            if (institution !== null && institution !== undefined) {
              this.setInstitutionImage(institution.imageContentItemId);
            }
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      }
      return null;
    }

    @action uploadImage = async (formData) => {
      const token = Auth.getToken();

      const headers = new Headers({
        Authorization: token,
        Accept: 'application/json; text/plain'
      });

      try {
        const response = await fetch(`${Auth.ecms}/api/saveUserProfileImage?saveUserProfile=false`, {
          method: 'POST',
          body: formData,
          headers

        });
        const data = await response.json();
        return data.newFileImageUrl;
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    verifyUser = async (verificationId) => {
      try {
        const result = await Auth.fetch(`${Auth.ecms}/api/verifyUser?id=${verificationId}`, {
          method: 'GET'
        });

        if (result && result.status && result.status === 'SUCCESS') {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    }

    @action async setProfileImageUrl(newFileName, save, userId = null) {
      const now = new Date();
      this.profileImageUrl = newFileName;
      const tempArray = this.fullProfileImageUrl.split('resourceFileName=');
      if (!tempArray || !tempArray[0]) {
        this.fullProfileImageUrl = newFileName;
      } else {
        this.fullProfileImageUrl = `${tempArray[0] }resourceFileName=${ newFileName }&authKey=${ this.authKey }&rn=${ now.getTime()}`;
      }
      if (save) {
        await this.saveImage(newFileName, userId);
      }
    }

    getFullUrlFromName = (imageName) => {
      const now = new Date();
      if (imageName === null || this.fullProfileImageUrl === null) {
        return null;
      }
      const tempArray = this.fullProfileImageUrl.split('resourceFileName=');
      if (!tempArray || !tempArray[0]) {
        return imageName;
      } else {
        return `${tempArray[0] }resourceFileName=${ imageName }&authKey=${ this.authKey }&rn=${ now.getTime()}`;
      }
    }

    @action saveUserDefaultAvatar(selectedAvatar, userId = null) {
      const body = {
        profileImage: `85px-${selectedAvatar}.png`,
      };
      if (userId) {
        body.userId = userId;
      } else {
        body.userType = (this.isTeacher) ? 'teacher' : 'student';
      }
      Auth.fetch(`${Auth.ecms}/api/saveUserDefaultAvatar`, {
        method: 'POST',
        body
      }).then((response) => {
        if (response && response.status === 'SUCCESS') {
          const save = false;
          this.setProfileImageUrl(response.imageName, save, userId);
          return response.imageName;
        }
        return null;
      }).catch((error) => {
        console.error(error);
      });
    }

     submitCrop = async (newFileName, crop) => {
       const body = {
         x: String(crop.x),
         y: String(crop.y),
         toX: String(crop.x), // not used in backend
         toY: String(crop.y), // not used in backend
         width: String(crop.width),
         height: String(crop.height),
         fileName: String((newFileName) || this.profileImageUrl)
       };
       try {
         const response = await Auth.fetch(`${Auth.ecms}/api/cropImagesForImagePicker?imageFromResources=false&folderName=avatars`, {
           method: 'POST',
           body
         });
         if (response && response.status === 'SUCCESS') {
           return response.newFileName;
         }
         return null;
       } catch (e) {
         console.log(e);
       }
     }

     getFullProfileImageUrl = (imageUrl) => {
       const now = new Date();
       if (imageUrl !== null && imageUrl !== undefined && imageUrl !== '') {
         return `${Auth.ecms}/api/redirectToResource?resourceFolder=avatars&resourceFileName=${imageUrl}&authKey=${Auth.authKey}&rn=${now.getTime()}`;
       } else {
         return null;
       }
     }

    @action updatePassword = async (oldPassword, newConfirmPassword) => {
      const body = {
        oldPassword,
        newPassword: newConfirmPassword
      };

      try {
        const result = await Auth.fetch(`${Auth.ecms}/api/updatePassword`, {
          method: 'POST',
          body
        });
        if (result && result.status === 'SUCCESS') {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    @action saveChanges = async (input) => {
      try {
        const body = {
          userId: input.userId,
          firstName: input.firstName,
          lastName: input.lastName,
        };

        if (input.enabled !== undefined) {
          body.enabled = input.enabled;
        }

        if (input.timezoneId) {
          body.timezoneId = input.timezoneId;
        }

        if (input.username) {
          body.username = input.username;
        }

        if (input.id) {
          body.id = input.id;
        }

        if (input.password) {
          body.password = input.password;
        }

        const data = await Auth.fetch(`${Auth.ecms}/api/updateUserProfile`, {
          method: 'POST',
          body
        });

        if (data && data.status === 'SUCCESS') {
          this.setFirstName(body.firstName);
          this.setLastName(body.lastName);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    @action enableUser = async (userId) => {
      try {
        const body = {
          userId
        };

        const data = await Auth.fetch(`${Auth.ecms}/api/activateUser`, {
          method: 'POST',
          body
        });

        if (data && data.status === 'SUCCESS') {
          this.setUserEnabled(true);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    saveImage = async (imageName, userId = null) => {
      try {
        const body = {
          profileImage: imageName,
        };
        if (userId) {
          body.userId = userId;
        }
        const data = await Auth.fetch(`${Auth.ecms}/api/updateUserProfile`, {
          method: 'POST',
          body
        });
        if (data && data.status === 'SUCCESS') {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    @action resetPassword = async (passwordChangeId, password) => {
      let response = null;
      try {
        response = await Auth.fetch(`${Auth.ecms}/api/changePassword?passwordChangeId=${passwordChangeId}&password=${password}`, {
          method: 'POST'
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      if (response && response.status === 'SUCCESS') {
        return true;
      }
    }

    @action sendResetEmail = async (email) => {
      let response = null;
      try {
        response = await Auth.fetch(`${Auth.ecms}/api/sendResetPasswordEmail?publisherSatelliteCode=${Auth.publisherSatelliteCode}&emailAddress=${email}`, {
          method: 'POST'
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      if (response && response.status === 'SUCCESS') {
        if (response.emailSent === false) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    validateAccessCode = async (accessCode) => {
      try {
        const data = await Auth.fetch(`${Auth.ecms}/api/validateAccessCode`, {
          method: 'POST',
          body: {
            accessCode: accessCode.value,
            publisherSatelliteCode: Auth.publisherSatelliteCode
          }
        });
        this.setAccessCodeResult(data);
        return true;
      } catch (error) {
        console.log(error);
      }
    }

    validateUser = async (accessCode, email) => {
      const { result } = accessCode;

      try {
        const data = await Auth.fetch(`${Auth.ecms}/api/checkEmailAddress`, {
          method: 'POST',
          body: { publisherId: result.publisherId, emailAddress: email.value }
        });

        this.setEmailResult(data);

        if (data.emailAddressExists) {
          email.error = ERROR_MESSAGES.USER_EXISTS;
        } else {
          email.error = null;
        }

        if (email.error !== null) {
          return false;
        }
        return true;
      } catch (error) {
        console.log(error);
      }
    }

    validateUserEmail = async (publisherId, emailAddress) => {
      try {
        const data = await Auth.fetch(`${Auth.ecms}/api/checkEmailAddress`, {
          method: 'POST',
          body: { publisherId, emailAddress }
        });

        if (data.emailAddressExists) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    }

    updateStudentTextHelpOption = async (userId, textHelpEnabled) => {
      const body = {
        userId,
        textHelpEnabled
      };
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/updateStudentTextHelpOption`, {
          method: 'POST',
          body
        });
        if (response && response.status === 'SUCCESS') {
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    updateMyTextHelpOption = async (textHelpEnabled) => {
      const body = {
        textHelpEnabled
      };
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/updateMyTextHelpOption`, {
          method: 'POST',
          body
        });
        if (response && response.status === 'SUCCESS') {
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    registerUser = async (body, accessCode) => {
      let url = Auth.ecms;
      // determine which signup url to call
      if (accessCode) {
        if (accessCode.accessCodeType === 'district' || accessCode.accessCodeType === 'school') {
          switch (accessCode.accessCodeCategory) {
          case 'district_admin':
            url = `${url }/api/signupDistrictAdmin`;
            break;
          case 'school_admin':
            url = `${url }/api/signupSchoolAdmin`;
            break;
          case 'user':
            url = `${url }/api/signupTeacher`;
            break;
          default:
          }
        } else if (accessCode.accessCodeType === 'classroom') {
          switch (accessCode.accessCodeCategory) {
          case 'classroom':
            url = `${url }/api/signupStudent`;
            break;
          case 'coteacher':
            url = `${url }/api/signupTeacher`;
            break;
          default:
          }
        }
      } else if (!accessCode && body.userType === 'teacher') {
        // special case for admin adding a teacher user
        url += '/api/signupTeacher';
      }

      try {
        const data = await Auth.fetch(url, {
          method: 'POST',
          body
        });
        // if the status is SUCCESS and there is an ID then new user registered
        if (data.status === 'SUCCESS' && data.userId) {
          return true;
        }
        return false;
      } catch (error) {
        console.log(error);
      }
    }

    fetchUserAccommodations = async (activityId) => {
      if (this.userId !== null && this.userId !== undefined && this.userId !== '') {
        try {
          const response = await Auth.fetch(`${Auth.ecms}/api/getUserAccommodations?activityId=${activityId}`, {
            method: 'GET'
          });

          if (response && response.status && response.status === 'SUCCESS') {
            if (response.data && response.data.length > 0) {
              const ids = response.data.map((item) => item.id);
              const idsString = ids.join(',');
              this.setAccommodationIds(idsString);
            }
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      }
      return null;
    }
}
export default new UserManager();
