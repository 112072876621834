import questionService from '../services/QuestionService';

import { ClozeBehavior } from './ClozeBehavior';

export class ClozeDragDropBehavior extends ClozeBehavior {
  // TODO phase out responseAnswer in favor of `lessonElementState.currentResponseAnswer`
  setResponseData = (data, _responseAnswer, model, { dataId, lessonElementState, oldDataId } = {}) => {
    if (!lessonElementState.currentResponseAnswer.userInputMap) {
      const userInputMap = questionService.updateUserInputMap({ model, lessonElementState });
      if (dataId) {
        userInputMap.set(dataId, { ...data });
      }
      if (oldDataId) {
        userInputMap.set(oldDataId, {
          dataId: oldDataId,
          text: ''
        });
      }
    } else {
      if (dataId) {
        lessonElementState.currentResponseAnswer.userInputMap.set(dataId, { ...data });
      }
      if (oldDataId) {
        lessonElementState.currentResponseAnswer.userInputMap.set(oldDataId, {
          dataId: oldDataId,
          text: ''
        });
      }
    }
  }
}

export default new ClozeDragDropBehavior();
