import { action, makeObservable, observable } from 'mobx';

export class VideoManager {
  @observable allowShowDividedVideoQuestions = false;

  @observable isVideoPlaying = false;

  @observable isVideoTranscriptDialogOpen = false;

  @observable previousVideoTimeProgress = 0;

  @observable selectedSideNavVideoChildLessonElementId = null;

  @observable selectedVideoTranscriptOption = null;

  @observable shouldCallVideoScrubChangeHandler = false;

  @observable sideNavVideoProgressValue = null;

  constructor() {
    makeObservable(this);
  }

  @action setAllowShowDividedVideoQuestions = (toggle) => {
    this.allowShowDividedVideoQuestions = toggle;
  }

  @action setIsVideoPlaying = (isVideoPlaying) => {
    this.isVideoPlaying = isVideoPlaying;
  }

  @action setIsVideoTranscriptDialogOpen = (isOpen) => {
    this.isVideoTranscriptDialogOpen = isOpen;
  }

  @action setSelectedSideNavVideoChildLessonElementId = (lessonElementId) => {
    this.selectedSideNavVideoChildLessonElementId = lessonElementId;
  }

  @action setSelectedVideoTranscriptOption = (transcriptOption) => {
    this.selectedVideoTranscriptOption = transcriptOption;
  }

  @action setSideNavVideoProgressValue = (value) => {
    this.sideNavVideoProgressValue = value;
    this.setShouldCallVideoScrubChangeHandler(!!this.selectedSideNavVideoChildLessonElementId);
  }

  @action setPreviousVideoTimeProgress = (progress) => {
    this.previousVideoTimeProgress = progress;
  }

  @action setShouldCallVideoScrubChangeHandler = (toggle) => {
    this.shouldCallVideoScrubChangeHandler = toggle;
  }
}

export default new VideoManager();
