/* eslint-disable no-empty-pattern */
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactModalDraggable from 'react-modal-resizable-draggable';

import classNames from 'classnames';

import '../../css/SimpleModal.scss';

const SimpleModal = observer((props = {}) => {
  const {
    additionalClassNames,
    disableKeystroke = false,
    disableMove = false,
    disableResize = false,
    handleRequestClose = () => {},
    initHeight,
    initWidth,
    modalBody = null,
    modalHeader,
    modalLeft,
    modalTop,
    onFocus
  } = props;
  const { /* managers */ } = useContext(MobXProviderContext);

  return modalBody && (!modalTop || modalTop > 0) && (
    <ReactModalDraggable
      className={
        classNames(
          'simple-modal', additionalClassNames, {
            'simple-modal-fixed': disableMove || disableResize
          }
        )
      }
      disableKeystroke={disableKeystroke}
      disableMove={disableMove}
      disableResize={disableResize}
      initHeight={initHeight || 500}
      initWidth={initWidth || 500}
      isOpen={!!modalBody}
      left={modalLeft}
      onFocus={onFocus}
      onRequestClose={handleRequestClose}
      top={modalTop}>
      <h3 className={classNames('simple-modal-header', {
        'simple-modal-header-hidden': !modalHeader
      })}>
        {modalHeader || 'simple-modal-header-placeholder'}
      </h3>
      <div className='simple-modal-body'>
        {modalBody}
      </div>
    </ReactModalDraggable>
  );
});

export default SimpleModal;
