import { action, computed, makeObservable, observable } from 'mobx';
import toolbarService from '../services/ToolbarService';
import { register } from '../../i18n';
import { Accommodations } from '../../Constants';

const t = register('ToolNames');

const OVERFLOW_LIMIT = 4;

const DIRECT_USE_COUNT = ['TextHelp', 'HAT', 'StickyNotes', 'Eliminator'];
export class ToolbarManager {
  @observable windowData = { isOpen: false };

  @observable isTextHelpOpen = false;
  @observable isTranslateOpen = false;
  @observable isDictionarysOpen = false;

  @observable isColorToolOpen = false;
  @observable selectedColorOption = {};

  @observable isStickyNotesOpen = false;
  @observable isStickyNotesActive = false;
  @observable isNotepadOpen = false;
  @observable isEliminatorActive = false;
  @observable isToolbarAnswerFeedbackActive = false;
  @observable isHighlightAnnotationOpen = false;
  @observable isBasicCalculatorOpen = false;
  @observable isScientificCalculatorOpen = false;
  @observable isGraphingCalculatorOpen = false;
  @observable isGraphPaperOpen = false;
  @observable isHelpOpen = false;
  @observable isCustomaryRulerOpen = false;
  @observable isMetricRulerOpen = false;
  @observable isCombinedRulerOpen = false;
  @observable isProtractorOpen = false;
  @observable isRevealOpen = false;
  @observable isGuidelineOpen = false;
  @observable isInstructionOpen = false;
  @observable instructionData = '';
  @observable instructionOpenId = '';
  @observable isResourcesOpen = false;
  @observable resourceUrl = '';
  @observable helpData = '';
  @observable resourceType = '';
  @observable resourceData = '';
  @observable resourceDisplayName = '';
  @observable helpDisplayName = '';
  @observable resourceOpenId = '';
  @observable helpOpenId = '';
  @observable buttonGroupSettings = new Map();
  @observable buttonSettings = new Map();
  @observable textHelpApi = null;
  @observable showWebReader = false;

  activityAccommodationsOff = [];
  userAccommodationsOff = [];
  excludedButtonTypes = [];
  buttonActions = [];
  contentToolUsage = [];
  contentToolAvailability = [];
  // satilitte url needed by some tools
  externalUrl = '';

  constructor() {
    makeObservable(this);
  }

  // TextHelp
  @action setTextHelpApi(val) {
    this.textHelpApi = val;
  }

  @action setWindowData(val) {
    this.windowData = val;
  }

  @action setIsTextHelpOpen(val) {
    this.isTextHelpOpen = val;
  }

  @action setIsTranslateOpen(val) {
    this.isTranslateOpen = val;
  }

  @action setIsDictionaryOpen(val) {
    this.isDictionaryOpen = val;
  }

  @action setIsColorOpen = (val) => {
    this.isColorToolOpen = val;
  }

  @action setSelectedColorOption = (val) => {
    this.selectedColorOption = val;
  }

  @action setIsStickyNotesOpen(val) {
    this.isStickyNotesOpen = val;

    if (val) {
      this.addContentToolUsage('StickyNotes');
    }
  }

  @action setIsStickyNotesActive(val) {
    this.isStickyNotesActive = val;
  }

  @action setIsNotepadOpen(val) {
    this.isNotepadOpen = val;

    if (val) {
      this.addContentToolUsage('Notepad');
    }
  }

  @action setIsEliminatorActive = (val) => {
    this.isEliminatorActive = val;
  }

  @action setIsToolbarAnswerFeedbackActive = (val) => {
    this.isToolbarAnswerFeedbackActive = val;
  }

  @action setIsHighlightAnnotationOpen(val) {
    this.isHighlightAnnotationOpen = val;
  }

  @action setIsBasicCalculatorOpen(val) {
    this.isBasicCalculatorOpen = val;
  }

  @action setIsScientificCalculatorOpen(val) {
    this.isScientificCalculatorOpen = val;
  }

  @action setIsGraphingCalculatorOpen(val) {
    this.isGraphingCalculatorOpen = val;
  }

  @action setIsGraphPaperOpen(val) {
    this.isGraphPaperOpen = val;
  }

  @action setIsHelpOpen(val) {
    this.isHelpOpen = val;
  }

  @action setIsInstructionOpen = (val) => {
    this.isInstructionOpen = val;
  }

  @action setInstructionData = (val) => {
    this.instructionData = val;
  }

  @action setInstructionOpenId = (val) => {
    this.instructionOpenId = val;
  }

  @action setIsCustomaryRulerOpen(val) {
    this.isCustomaryRulerOpen = val;
  }

  @action setIsMetricRulerOpen(val) {
    this.isMetricRulerOpen = val;
  }

  @action setIsCombinedRulerOpen(val) {
    this.isCombinedRulerOpen = val;
  }

  @action setIsProtractorOpen(val) {
    this.isProtractorOpen = val;
  }

  @action setIsRevealOpen(val) {
    this.isRevealOpen = val;
  }

  @action setIsGuidelineOpen(val) {
    this.isGuidelineOpen = val;
  }

  @action setIsResourcesOpen(val) {
    this.isResourcesOpen = val;
  }

  @action setResourceType(val) {
    this.resourceType = val;
  }

  @action setResourceData(val) {
    this.resourceData = val;
  }

  @action setHelpData(val) {
    this.helpData = val;
  }

  @action setResourceDisplayName(val) {
    this.resourceDisplayName = val;
  }

  @action setHelpDisplayName(val) {
    this.helpDisplayName = val;
  }

  @action setResourceOpenId(val) {
    this.resourceOpenId = val;
  }

  @action setHelpOpenId(val) {
    this.helpOpenId = val;
  }

  @action setActivityAccommodationsOff(vals) {
    const arr = vals.split(',');
    this.activityAccommodationsOff = arr;
  }

  @action setUserAccommodationsOff(vals) {
    if (vals && vals.length > 0) {
      const arr = vals.split(',');
      this.userAccommodationsOff = arr;
    }
  }

  @action addExcludedButtonType(val) {
    this.excludedButtonTypes.push(val);
  }

  @action resetCalculatorTool() {
    this.windowData = {};
    this.isBasicCalculatorOpen = false;
    this.isGraphingCalculatorOpen = false;
    this.isScientificCalculatorOpen = false;
  }

  @action setShowWebReader(toggle) {
    this.showWebReader = toggle;
  }

  @observable buttonLayout = [
    {
      id: 'subjectGroup',
      type: 'group',
      children: [{ id: 'Dictionary', type: 'button' },
        { id: 'WritingChecklist', type: 'button' },
        {
          id: 'calculatorsGroup',
          type: 'group',
          children: [{ id: 'BasicCalculator', type: 'button' },
            { id: 'ScientificCalculator', type: 'button' },
            { id: 'GraphingCalculator', type: 'button' }]
        },
        {
          id: 'rulersGroup',
          type: 'group',
          children: [{ id: 'CustomaryRuler', type: 'button' },
            { id: 'MetricRuler', type: 'button' },
            { id: 'CombinedRuler', type: 'button' }]
        },
        { id: 'Protractor', type: 'button' },
        { id: 'Graph', type: 'button' }]
    },
    { type: 'spacer', id: 'spacer' },
    {
      type: 'group',
      id: 'lessonGroup',
      children: [{ id: 'Reveal', type: 'button' },
        { id: 'AnswerFeedback', type: 'button' },
        { id: 'Instruction', type: 'button' }]
    },
    {
      type: 'group',
      id: 'audioGroup',
      children: [{ id: 'Translate', type: 'button' },
        { id: 'TextHelp', type: 'button' }]
    },
    {
      type: 'group',
      id: 'annotationGroup',
      children: [{ id: 'Guideline', type: 'button' },
        { id: 'Eliminator', type: 'button' },
        { id: 'HAT', type: 'button' },
        { id: 'StickyNotes', type: 'button' },
        { id: 'Notepad', type: 'button' },
        { id: 'Bookmark', type: 'button' }]
    },
    {
      type: 'group',
      id: 'persistentGroup',
      children: [{ id: 'Help', type: 'button' },
        {
          type: 'group',
          id: 'settingsGroup',
          children: [{
            id: 'FontSize',
            type: 'group',
            children: [
              { id: 'SmallText', type: 'button' },
              { id: 'MediumText', type: 'button' },
              { id: 'LargeText', type: 'button' },
              { id: 'BoldText', type: 'button' }
            ]
          },
          { id: 'Color', type: 'button' },
          { id: 'TranslateSettings', type: 'button' },
          { id: 'Audio', type: 'button' }]
        },
        { id: 'Logout', type: 'button' }]
    }
  ];

  // toolbar init functions
  @action
  initButtonGroupSettings(lessonOptions, toolbarOptions) {
    const simpleToolbar = (lessonOptions && lessonOptions.simpleToolbar !== undefined) ? lessonOptions.simpleToolbar : false;

    if (simpleToolbar) {
      this.buttonLayout = [
        { type: 'spacer', id: 'spacer' },
        {
          type: 'group',
          id: 'lessonGroup',
          children: [
            {
              id: 'annotationGroup',
              type: 'group',
              children: [{ id: 'Guideline', type: 'button' },
                { id: 'Eliminator', type: 'button' },
                { id: 'HAT', type: 'button' },
                { id: 'StickyNotes', type: 'button' },
                { id: 'Notepad', type: 'button' },
                { id: 'Dictionary', type: 'button' },
                { id: 'WritingChecklist', type: 'button' },
                { id: 'Translate', type: 'button' },
                { id: 'TextHelp', type: 'button' }]
            },
            {
              id: 'calculatorsGroup',
              type: 'group',
              children: [{ id: 'BasicCalculator', type: 'button' },
                { id: 'ScientificCalculator', type: 'button' },
                { id: 'GraphingCalculator', type: 'button' }]
            },
            {
              id: 'rulersGroup',
              type: 'group',
              children: [{ id: 'CustomaryRuler', type: 'button' },
                { id: 'MetricRuler', type: 'button' },
                { id: 'CombinedRuler', type: 'button' },
                { id: 'Protractor', type: 'button' },
                { id: 'Graph', type: 'button' }]
            }]
        },
        {
          type: 'group',
          id: 'subjectGroup',
          children: [{ id: 'Reveal', type: 'button' },
            { id: 'AnswerFeedback', type: 'button' },
            { id: 'Instruction', type: 'button' },
            { id: 'Bookmark', type: 'button' }]
        },
        {
          type: 'group',
          id: 'persistentGroup',
          children: [{ id: 'Help', type: 'button' },
            {
              type: 'group',
              id: 'settingsGroup',
              children: [{
                id: 'FontSize',
                type: 'group',
                children: [
                  { id: 'SmallText', type: 'button' },
                  { id: 'MediumText', type: 'button' },
                  { id: 'LargeText', type: 'button' },
                  { id: 'BoldText', type: 'button' }
                ]
              },
              { id: 'Color', type: 'button' },
              { id: 'TranslateSettings', type: 'button' },
              { id: 'Audio', type: 'button' }]
            },
            { id: 'Logout', type: 'button' }]
        }
      ];

      this.buttonGroupSettings.set('lessonGroup', {
        name: () => t('lessonMenu', 'Lesson Tools'),
        open: false,
        id: 'lessonGroup',
        type: 'group',
        isMenu: false,
        visible: false,
        parentId: 'toolbar',
        icon: false,
        className: ''
      });

      this.buttonGroupSettings.set('annotationGroup', {
        name: () => t('annotationMenu', 'Annotation Tools'),
        open: false,
        id: 'annotationGroup',
        type: 'group',
        isMenu: true,
        visible: false,
        icon: true,
        parentId: 'lessonGroup',
        className: ''
      });

      this.buttonGroupSettings.set('calculatorsGroup', {
        name: () => t('calculatorsMenu', 'Calculators'),
        open: false,
        id: 'calculatorsGroup',
        type: 'group',
        isMenu: true,
        visible: false,
        icon: true,
        parentId: 'lessonGroup',
        className: ''
      });

      this.buttonGroupSettings.set('rulersGroup', {
        name: () => t('rulerMenu', 'Rulers'),
        open: false,
        id: 'rulersGroup',
        type: 'group',
        isMenu: true,
        visible: false,
        parentId: 'lessonGroup',
        icon: true,
        className: ''
      });

      this.buttonGroupSettings.set('subjectGroup', {
        name: () => t('subjectMenu', 'Subject Tools'),
        open: false,
        type: 'group',
        id: 'subjectGroup',
        parentId: 'toolbar',
        isMenu: false,
        visible: false,
        icon: false,
        className: ''
      });
      this.buttonGroupSettings.set('persistentGroup', {
        name: () => t('persistentMenu', 'Persistent Tools'),
        id: 'persistentGroup',
        open: false,
        type: 'group',
        isMenu: false,
        parentId: 'toolbar',
        visible: true,
        icon: false,
        className: 'small-tool-button'
      });

      this.buttonGroupSettings.set('settingsGroup', {
        name: () => t('settingsMenu', 'Settings'),
        id: 'settingsGroup',
        open: false,
        type: 'group',
        isMenu: true,
        parentId: 'persistentGroup',
        visible: false,
        icon: true,
        className: 'small-tool-button'
      });
      this.buttonGroupSettings.set('FontSize', {
        name: () => t('fontMenu', 'Font'),
        id: 'FontSize',
        open: false,
        type: 'group',
        visible: false,
        isMenu: true,
        parentId: 'settingsGroup',
        icon: false,
        className: ''
      });
    } else {
      this.buttonGroupSettings.set('subjectGroup', {
        name: () => t('subjectMenu', 'Subject Tools'),
        open: false,
        id: 'subjectGroup',
        type: 'group',
        isMenu: false,
        visible: false,
        icon: false,
        parentId: 'toolbar',
        className: ''
      });

      this.buttonGroupSettings.set('calculatorsGroup', {
        name: () => t('calculatorsMenu', 'Calculators'),
        open: false,
        id: 'calculatorsGroup',
        type: 'group',
        isMenu: true,
        visible: false,
        icon: true,
        parentId: 'subjectGroup',
        className: ''
      });

      this.buttonGroupSettings.set('rulersGroup', {
        name: () => t('rulerMenu', 'Rulers'),
        open: false,
        id: 'rulersGroup',
        type: 'group',
        isMenu: true,
        visible: false,
        parentId: 'subjectGroup',
        icon: true,
        className: ''
      });

      this.buttonGroupSettings.set('lessonGroup', {
        name: () => t('lessonMenu', 'Lesson Tools'),
        open: false,
        type: 'group',
        id: 'lessonGroup',
        parentId: 'toolbar',
        isMenu: false,
        visible: false,
        icon: false,
        className: ''
      });

      this.buttonGroupSettings.set('audioGroup', {
        name: () => t('audioMenu', 'Audio Tools'),
        open: false,
        parentId: 'toolbar',
        type: 'group',
        id: 'audioGroup',
        isMenu: false,
        visible: false,
        icon: false,
        className: ''
      });

      this.buttonGroupSettings.set('annotationGroup', {
        name: () => t('annotationMenu', 'Annotation Tools'),
        open: false,
        type: 'group',
        parentId: 'toolbar',
        id: 'annotationGroup',
        isMenu: false,
        visible: false,
        icon: false,
        className: ''
      });

      this.buttonGroupSettings.set('persistentGroup', {
        name: () => t('persistentMenu', 'Persistent Tools'),
        open: false,
        type: 'group',
        id: 'persistentGroup',
        parentId: 'toolbar',
        isMenu: false,
        visible: true,
        icon: false,
        className: 'small-tool-button'
      });

      this.buttonGroupSettings.set('settingsGroup', {
        name: () => t('settingsMenu', 'Settings'),
        id: 'settingsGroup',
        open: false,
        type: 'group',
        isMenu: true,
        parentId: 'persistentGroup',
        visible: false,
        icon: true,
        className: 'small-tool-button'
      });
      this.buttonGroupSettings.set('FontSize', {
        name: () => t('fontMenu', 'Font'),
        id: 'FontSize',
        open: false,
        type: 'group',
        visible: false,
        isMenu: true,
        parentId: 'settingsGroup',
        icon: true,
        className: ''
      });
    }
  }

  @action
  simpleToolbarButtonSettings = () => {
    this.buttonSettings.set('Bookmark', {
      icon: true,
      id: 'Bookmark',
      toggle: false,
      visible: false,
      name: () => t('bookmarkTool', 'Mark for Review'),
      parentId: 'subjectGroup',
      type: 'button'
    });
    this.buttonSettings.set('Reveal', {
      icon: true,
      id: 'Reveal',
      toggle: false,
      visible: false,
      parentId: 'subjectGroup',
      name: () => t('revealTool', 'Reveal'),
      type: 'button'
    });
    this.buttonSettings.set('AnswerFeedback', {
      icon: true,
      id: 'AnswerFeedback',
      toggle: false,
      visible: false,
      parentId: 'subjectGroup',
      name: () => t('answerTool', 'Answers'),
      type: 'button'
    });
    this.buttonSettings.set('Instruction', {
      icon: true,
      id: 'Instruction',
      toggle: false,
      visible: false,
      parentId: 'subjectGroup',
      name: () => t('instructionTool', 'Instructions'),
      type: 'button'
    });
    this.buttonSettings.set('Translate', {
      icon: false,
      id: 'Translate',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('translateTool', 'Translate'),
      type: 'button'
    });
    this.buttonSettings.set('TextHelp', {
      icon: false,
      id: 'TextHelp',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('textHelp', 'Speak'),
      type: 'button'
    });
    this.buttonSettings.set('Dictionary', {
      id: 'Dictionary',
      icon: false,
      toggle: false,
      visible: false,
      name: () => t('dictionaryTool', 'Dictionary'),
      type: 'button',
      parentId: 'annotationGroup',
    });

    this.buttonSettings.set('WritingChecklist', {
      id: 'WritingChecklist',
      icon: false,
      toggle: false,
      visible: false,
      name: () => t('writingCheckListTool', 'Writing Checklist'),
      type: 'button',
      parentId: 'annotationGroup',
    });
    this.buttonSettings.set('Protractor', {
      id: 'Protractor',
      icon: false,
      toggle: false,
      visible: false,
      name: () => t('protractorTool', 'Protractor'),
      type: 'button',
      parentId: 'rulersGroup'
    });
    this.buttonSettings.set('Graph', {
      id: 'Graph',
      icon: false,
      toggle: false,
      visible: false,
      name: () => t('graphPaperLabel', 'Graph Paper'),
      parentId: 'rulersGroup',
      type: 'button'
    });

    this.buttonSettings.set('CustomaryRuler', {
      id: 'CustomaryRuler',
      icon: false,
      toggle: false,
      visible: false,
      parentId: 'rulersGroup',
      name: () => t('imperialRuler', 'Imperial Ruler'),
      type: 'button'
    });
    this.buttonSettings.set('MetricRuler', {
      id: 'MetricRuler',
      icon: false,
      toggle: false,
      visible: false,
      parentId: 'rulersGroup',
      name: () => t('metricRuler', 'Metric Ruler'),
      type: 'button'
    });
    this.buttonSettings.set('CombinedRuler', {
      id: 'CombinedRuler',
      icon: false,
      toggle: false,
      visible: false,
      parentId: 'rulersGroup',
      name: () => t('doubleRuler', 'Ruler'),
      type: 'button'
    });

    this.buttonSettings.set('BasicCalculator', {
      id: 'BasicCalculator',
      icon: false,
      toggle: false,
      visible: false,
      parentId: 'calculatorsGroup',
      name: () => t('basicCalculator', 'Basic Calculator'),
      type: 'button'
    });
    this.buttonSettings.set('ScientificCalculator', {
      id: 'ScientificCalculator',
      icon: false,
      toggle: false,
      visible: false,
      name: () => t('scientificCalculator', 'Scientific Calculator'),
      parentId: 'calculatorsGroup',
      type: 'button'
    });
    this.buttonSettings.set('GraphingCalculator', {
      id: 'GraphingCalculator',
      icon: false,
      toggle: false,
      visible: false,
      name: () => t('graphingCalculator', 'Graphing Calculator'),
      parentId: 'calculatorsGroup',
      type: 'button'
    });

    this.buttonSettings.set('Guideline', {
      icon: false,
      id: 'Guideline',
      parentId: 'audioGroup',
      toggle: false,
      visible: false,
      name: () => t('lineReaderTool', 'Line Reader'),
      type: 'button'
    });
    this.buttonSettings.set('Eliminator', {
      icon: false,
      id: 'Eliminator',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('strikethrough', 'Strikethrough'),
      type: 'button'
    });
    this.buttonSettings.set('HAT', {
      icon: false,
      id: 'HAT',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('highlightTool', 'Highlight'),
      type: 'button'
    });

    this.buttonSettings.set('StickyNotes', {
      icon: false,
      id: 'StickyNotes',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('stickyNotes', 'Sticky Notes'),
      type: 'button'
    });

    this.buttonSettings.set('Notepad', {
      icon: false,
      id: 'Notepad',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('notepadLabel', 'Notepad'),
      type: 'button'
    });

    this.buttonSettings.set('Color', {
      icon: false,
      toggle: false,
      id: 'Color',
      visible: false,
      name: () => t('colorPicker', 'Color'),
      parentId: 'settingsGroup',
      type: 'button'
    });
    this.buttonSettings.set('TranslateSettings', {
      icon: false,
      toggle: false,
      id: 'TranslateSettings',
      parentId: 'settingsGroup',
      visible: false,
      name: () => t('translateTool', 'Translate'),
      type: 'button'
    });
    this.buttonSettings.set('Audio', {
      icon: false,
      toggle: false,
      id: 'Audio',
      parentId: 'settingsGroup',
      visible: false,
      name: () => t('audioTool', 'Speech/Translate'),
      type: 'button'
    });

    this.buttonSettings.set('Logout', {
      icon: true,
      toggle: false,
      id: 'Logout',
      parentId: 'persistentGroup',
      visible: true,
      name: () => t('exitButton', 'Exit'),
      type: 'button',
    });

    this.buttonSettings.set('Help', {
      icon: true,
      id: 'Help',
      parentId: 'persistentGroup',
      toggle: false,
      visible: false,
      name: () => t('helpPagesLabel', 'Help'),
      type: 'button'
    });
    this.buttonSettings.set('SmallText', {
      icon: false,
      id: 'SmallText',
      parentId: 'FontSize',
      toggle: false,
      visible: true,
      name: () => t('smallFont', 'Small'),
      type: 'button'
    });
    this.buttonSettings.set('MediumText', {
      icon: false,
      id: 'MediumText',
      parentId: 'FontSize',
      toggle: true,
      visible: true,
      name: () => t('mediumFont', 'Medium'),
      type: 'button'
    });
    this.buttonSettings.set('LargeText', {
      icon: false,
      id: 'LargeText',
      parentId: 'FontSize',
      toggle: false,
      visible: true,
      name: () => t('largeFont', 'Large'),
      type: 'button'
    });
    this.buttonSettings.set('BoldText', {
      icon: false,
      id: 'BoldText',
      parentId: 'FontSize',
      toggle: false,
      visible: true,
      name: () => t('boldFont', 'Bold'),
      type: 'button'
    });
  }

  @action
  expandedToolbarButtonSettings = () => {
    this.buttonSettings.set('Dictionary', {
      id: 'Dictionary',
      icon: true,
      toggle: false,
      visible: false,
      name: () => t('dictionaryTool', 'Dictionary'),
      type: 'button',
      parentId: 'subjectGroup',
    });

    this.buttonSettings.set('WritingChecklist', {
      id: 'WritingChecklist',
      icon: true,
      toggle: false,
      visible: false,
      name: () => t('writingCheckListTool', 'Writing Checklist'),
      type: 'button',
      parentId: 'subjectGroup',
    });

    this.buttonSettings.set('Protractor', {
      id: 'Protractor',
      icon: true,
      toggle: false,
      visible: false,
      name: () => t('protractorTool', 'Protractor'),
      type: 'button',
      parentId: 'subjectGroup'
    });
    this.buttonSettings.set('Graph', {
      id: 'Graph',
      icon: true,
      toggle: false,
      visible: false,
      name: () => t('graphPaperLabel', 'Graph Paper'),
      parentId: 'subjectGroup',
      type: 'button'
    });

    this.buttonSettings.set('CustomaryRuler', {
      id: 'CustomaryRuler',
      icon: true,
      toggle: false,
      visible: false,
      parentId: 'rulersGroup',
      name: () => t('imperialRuler', 'Imperial Ruler'),
      type: 'button'
    });
    this.buttonSettings.set('MetricRuler', {
      id: 'MetricRuler',
      icon: true,
      toggle: false,
      visible: false,
      parentId: 'rulersGroup',
      name: () => t('metricRuler', 'Metric Ruler'),
      type: 'button'
    });
    this.buttonSettings.set('CombinedRuler', {
      id: 'CombinedRuler',
      icon: true,
      toggle: false,
      visible: false,
      parentId: 'rulersGroup',
      name: () => t('doubleRuler', 'Ruler'),
      type: 'button'
    });

    this.buttonSettings.set('BasicCalculator', {
      id: 'BasicCalculator',
      icon: true,
      toggle: false,
      visible: false,
      parentId: 'calculatorsGroup',
      name: () => t('basicCalculator', 'Basic Calculator'),
      type: 'button'
    });
    this.buttonSettings.set('ScientificCalculator', {
      id: 'ScientificCalculator',
      icon: true,
      toggle: false,
      visible: false,
      name: () => t('scientificCalculator', 'Scientific Calculator'),
      parentId: 'calculatorsGroup',
      type: 'button'
    });
    this.buttonSettings.set('GraphingCalculator', {
      id: 'GraphingCalculator',
      icon: true,
      toggle: false,
      visible: false,
      name: () => t('graphingCalculator', 'Graphing Calculator'),
      parentId: 'calculatorsGroup',
      type: 'button'
    });
    this.buttonSettings.set('Reveal', {
      icon: true,
      id: 'Reveal',
      toggle: false,
      visible: false,
      parentId: 'lessonGroup',
      name: () => t('revealTool', 'Reveal'),
      type: 'button'
    });
    this.buttonSettings.set('AnswerFeedback', {
      icon: true,
      id: 'AnswerFeedback',
      toggle: false,
      visible: false,
      parentId: 'lessonGroup',
      name: () => t('answerTool', 'Answers'),
      type: 'button'
    });
    this.buttonSettings.set('Instruction', {
      icon: true,
      id: 'Instruction',
      toggle: false,
      visible: false,
      parentId: 'lessonGroup',
      name: () => t('instructionTool', 'Instructions'),
      type: 'button'
    });
    this.buttonSettings.set('Translate', {
      icon: true,
      id: 'Translate',
      parentId: 'audioGroup',
      toggle: false,
      visible: false,
      name: () => t('translateTool', 'Translate'),
      type: 'button'
    });
    this.buttonSettings.set('TextHelp', {
      icon: true,
      id: 'TextHelp',
      parentId: 'audioGroup',
      toggle: false,
      visible: false,
      name: () => t('textHelp', 'Speak'),
      type: 'button'
    });
    this.buttonSettings.set('Guideline', {
      icon: true,
      id: 'Guideline',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('lineReaderTool', 'Line Reader'),
      type: 'button'
    });
    this.buttonSettings.set('Eliminator', {
      icon: true,
      id: 'Eliminator',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('strikethrough', 'Strikethrough'),
      type: 'button'
    });
    this.buttonSettings.set('HAT', {
      icon: true,
      id: 'HAT',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('highlightTool', 'Highlight'),
      type: 'button'
    });

    this.buttonSettings.set('StickyNotes', {
      icon: true,
      id: 'StickyNotes',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('stickyNotes', 'Sticky Notes'),
      type: 'button'
    });

    this.buttonSettings.set('Notepad', {
      icon: true,
      id: 'Notepad',
      parentId: 'annotationGroup',
      toggle: false,
      visible: false,
      name: () => t('notepadLabel', 'Notepad'),
      type: 'button'
    });

    this.buttonSettings.set('Bookmark', {
      icon: true,
      id: 'Bookmark',
      toggle: false,
      visible: false,
      name: () => t('bookmarkTool', 'Mark for Review'),
      parentId: 'annotationGroup',
      type: 'button'
    });
    this.buttonSettings.set('Color', {
      icon: true,
      toggle: false,
      id: 'Color',
      visible: false,
      name: () => t('colorPicker', 'Color'),
      parentId: 'settingsGroup',
      type: 'button'
    });
    this.buttonSettings.set('TranslateSettings', {
      icon: true,
      toggle: false,
      id: 'TranslateSettings',
      parentId: 'settingsGroup',
      visible: false,
      name: () => t('translateTool', 'Translate'),
      type: 'button'
    });
    this.buttonSettings.set('Audio', {
      icon: true,
      toggle: false,
      id: 'Audio',
      parentId: 'settingsGroup',
      visible: false,
      name: () => t('textHelp', 'Speak'),
      type: 'button'
    });

    this.buttonSettings.set('Logout', {
      icon: true,
      toggle: false,
      id: 'Logout',
      parentId: 'persistentGroup',
      visible: true,
      name: () => t('exitButton', 'Exit'),
      type: 'button',
    });

    this.buttonSettings.set('Help', {
      icon: true,
      id: 'Help',
      parentId: 'persistentGroup',
      toggle: false,
      visible: false,
      name: () => t('helpPagesLabel', 'Help'),
      type: 'button'
    });
    this.buttonSettings.set('SmallText', {
      icon: true,
      id: 'SmallText',
      parentId: 'FontSize',
      toggle: false,
      visible: true,
      name: () => t('smallFont', 'Small'),
      type: 'button'
    });
    this.buttonSettings.set('MediumText', {
      icon: true,
      id: 'MediumText',
      parentId: 'FontSize',
      toggle: true,
      visible: true,
      name: () => t('mediumFont', 'Medium'),
      type: 'button'
    });
    this.buttonSettings.set('LargeText', {
      icon: true,
      id: 'LargeText',
      parentId: 'FontSize',
      toggle: false,
      visible: true,
      name: () => t('largeFont', 'Large'),
      type: 'button'
    });
    this.buttonSettings.set('BoldText', {
      icon: true,
      id: 'BoldText',
      parentId: 'FontSize',
      toggle: false,
      visible: true,
      name: () => t('boldFont', 'Bold'),
      type: 'button'
    });
  }

  @action
  initButtonSettings(lessonOptions, toolbarOptions) {
    const simpleToolbar = (lessonOptions && lessonOptions.simpleToolbar !== undefined) ? lessonOptions.simpleToolbar : false;
    if (simpleToolbar) {
      this.simpleToolbarButtonSettings();
    } else {
      this.expandedToolbarButtonSettings();
    }

    if (toolbarOptions && toolbarOptions.length > 0) {
      for (let i = 0; i < toolbarOptions.length; ++i) {
        if (toolbarOptions[i].type === 'Resources') {
          if (lessonOptions.resourceList && lessonOptions.resourceList.length > 0) {
            if (toolbarOptions[i].list) {
              toolbarOptions[i].list = toolbarOptions[i].list.concat(lessonOptions.resourceList);
            } else {
              toolbarOptions[i].list = lessonOptions.resourceList;
            }
          }
          // If this is something like an article or pdf, it will be displayed as its own button in the subjectGroup
          this.initResourcesTools(toolbarOptions[i], simpleToolbar);
        } else if (toolbarOptions[i].type === 'Help') {
          const settings = this.buttonSettings.get(toolbarOptions[i].type);
          settings.visible = false;
          if (toolbarOptions[i] && toolbarOptions[i].list) {
            if (toolbarOptions[i].list.length > 0) {
              settings.visible = true;
              settings.data = {
                contentItemId: toolbarOptions[i].list[0].id,
                displayName: toolbarOptions[i].list[0].displayName,
                resourceType: toolbarOptions[i].list[0].entityTypeId
              };
            }
          }
        } else {
          // Then this is a default tool type
          if (toolbarOptions[i].type) {
            const settings = this.buttonSettings.get(toolbarOptions[i].type);
            if (settings) {
              // If this tool has settings, we enable it and show it to the user
              settings.visible = true;

              // notpad can have special toolbars check for it
              if (toolbarOptions[i].type === 'Notepad') {
                settings.ckeditor = toolbarOptions[i].ckeditor;
              }

              if (toolbarOptions[i].teacherTool) {
                // We don't know yet if this user is a teacher yet so we disable the tool. Eventually toolbarService.setTeacherToolVisibility will be called to update this visibility
                settings.visible = false;
              }
              if (settings.parentId !== 'toolbar') {
                // If the tool is not top level, we also need to show its parent or else it still won't be visible
                // if it's a menu, but there is only one menu item just show the item and not a menu
                const buttonGroupSettings = this.getButtonGroupSettings(settings.parentId);
                if (buttonGroupSettings) {
                  buttonGroupSettings.visible = true;
                  if (buttonGroupSettings.isMenu && typeof buttonGroupSettings.numVisible === 'undefined' && settings.parentId != 'settingsGroup') {
                    buttonGroupSettings.numVisible = 1;
                    buttonGroupSettings.isMenu = false;
                    buttonGroupSettings.className = 'no-line';
                  } else if (typeof buttonGroupSettings.numVisible !== 'undefined' && buttonGroupSettings.numVisible >= 1 && settings.parentId != 'settingsGroup') {
                    buttonGroupSettings.numVisible += 1;
                    buttonGroupSettings.isMenu = true;
                    buttonGroupSettings.className = '';
                  }

                  this.buttonGroupSettings.set(settings.parentId, buttonGroupSettings);
                }
              }
            } else {
              // should only be the Font tool
              if (toolbarOptions[i].type === 'FontSize') {
                const gSettings = this.buttonGroupSettings.get(toolbarOptions[i].type);
                gSettings.visible = true;
              }
            }
          }
        }
        // now turn on any groups that have only menus in theme
        // eslint-disable-next-line no-unused-vars
        for (const [id, group] of this.buttonGroupSettings.entries()) {
          if (group.visible) {
            if (group.parentId !== 'toolbar') {
              const parent = this.getButtonGroupSettings(group.parentId);
              if (parent) {
                parent.visible = true;
              }
            }
          }
        }

        if (toolbarOptions[i].type === 'Resources' || toolbarOptions[i].type === 'Help') {
          if (toolbarOptions[i].list && toolbarOptions[i].list.length > 0) {
            this.addContentToolAvailability(toolbarOptions[i].type);
          }
        } else {
          this.addContentToolAvailability(toolbarOptions[i].type);
        }
      }
    }
    // console.info('AVAILABLE TOOLS');
    // console.info(this.contentToolAvailability);
  }

  @action
  initResourcesTools = (resourceOptions, simpleToolbar) => {
    const subjectGroupLayout = this.buttonLayout.find((group) => group.id === 'subjectGroup');
    // We also need to show the containing button group. Otherwise this will only show if another tool in the same group is active.
    const buttonGroupSettings = this.getButtonGroupSettings('subjectGroup');
    if (buttonGroupSettings) {
      buttonGroupSettings.visible = true;
    }

    if (resourceOptions.list) {
      const children = resourceOptions.list;
      if (children.length > 0) {
        if (simpleToolbar) {
          for (let x = 0; x < children.length; ++x) {
            const resourceButton = {
              id: `Resources${x}`,
              icon: false,
              toggle: false,
              visible: true,
              name: () => ((children[x].displayName) ? children[x].displayName : children[x].name),
              data: { contentItemId: children[x].id, displayName: children[x].displayName, resourceType: children[x].entityTypeId },
              type: 'button',
              parentId: 'subjectGroupLayout',
            };
            console.log(resourceButton, children[x].displayName);
            this.buttonSettings.set(`Resources${x}`, resourceButton);
            subjectGroupLayout.children.push({ type: 'button', id: `Resources${x}` });

            this.initResourceButtonActions(`Resources${x}`);
          }
        } else {
          for (let i = children.length - 1; i > -1; --i) {
            const resourceButton = {
              id: `Resources${i}`,
              icon: true,
              toggle: false,
              visible: true,
              name: () => ((children[i].displayName) ? children[i].displayName : children[i].name),
              data: { contentItemId: children[i].id, displayName: children[i].displayName, resourceType: children[i].entityTypeId },
              type: 'button',
              parentId: 'subjectGroupLayout',
            };
            this.buttonSettings.set(`Resources${i}`, resourceButton);
            subjectGroupLayout.children.unshift({ type: 'button', id: `Resources${i}` });

            this.initResourceButtonActions(`Resources${i}`);
          }
        }
      }
    }
  }

  @action
  initResourceButtonActions = (buttonName) => {
    this.buttonActions[buttonName] = (buttonSetting) => {
      toolbarService.resourcesToolHandler(buttonSetting);
    };
  }

  @action
  initButtonActions = () => {
    // for the button with id 'exit'
    this.setButtonAction('Logout', toolbarService.exitToolHandler);

    // for the button with id 'TextHelp'
    // const data = this.getButtonData(button.id);
    this.setButtonAction('TextHelp', toolbarService.textHelpToolHandler);

    this.setButtonAction('Translate', toolbarService.translateToolHandler);

    this.setButtonAction('Dictionary', toolbarService.dictionaryToolHandler);

    this.setButtonAction('Color', toolbarService.colorToolHandler);

    this.setButtonAction('TranslateSettings', toolbarService.textHelpSettingsHandler);

    this.setButtonAction('Audio', toolbarService.textHelpSettingsHandler);

    this.setButtonAction('BasicCalculator', toolbarService.basicCalculatorToolHandler);

    this.setButtonAction('ScientificCalculator', toolbarService.scientificCalculatorToolHandler);

    this.setButtonAction('GraphingCalculator', toolbarService.graphingCalculatorToolHandler);

    this.setButtonAction('Help', toolbarService.helpToolHandler);

    this.setButtonAction('Instruction', toolbarService.instructionToolHandler);

    this.setButtonAction('CustomaryRuler', toolbarService.customaryRulerToolHandler);

    this.setButtonAction('MetricRuler', toolbarService.metricRulerToolHandler);

    this.setButtonAction('CombinedRuler', toolbarService.combinedRulerToolHandler);

    this.setButtonAction('Protractor', toolbarService.protractorToolHandler);

    this.setButtonAction('Graph', toolbarService.graphPaperToolHandler);

    this.setButtonAction('Reveal', toolbarService.revealToolHandler);

    this.setButtonAction('Guideline', toolbarService.guidelineToolHandler);

    this.setButtonAction('HAT', toolbarService.highlightAnnotationToolHandler);

    this.setButtonAction('StickyNotes', toolbarService.stickyNotesToolHandler);

    this.setButtonAction('Notepad', toolbarService.notepadToolHandler);

    this.setButtonAction('Eliminator', toolbarService.eliminatorToolHandler);

    this.setButtonAction('AnswerFeedback', toolbarService.answerFeedbackToolHandler);

    this.setButtonAction('Bookmark', toolbarService.bookmarkToolHandler);

    this.setButtonAction('SmallText', toolbarService.smallTextToolHandler);

    this.setButtonAction('LargeText', toolbarService.largeTextToolHandler);

    this.setButtonAction('MediumText', toolbarService.mediumTextToolHandler);

    this.setButtonAction('BoldText', toolbarService.boldTextToolHandler);
  }

  orignalSubjectGroupChildren = null;
  originalSettings = {};

  @action
  handleSubjectGroupOverflow = (currentLimit) => {
    const saveGroupLayout = () => {
      const subjectGroupLayout = this.buttonLayout.find((group) => group.id === 'subjectGroup');
      this.orignalSubjectGroupChildren = subjectGroupLayout.children;
      // We also need to save all the settings
      for (const { id, type } of this.orignalSubjectGroupChildren) {
        let settings;
        if (type === 'button') {
          settings = this.getButtonSettings(id);
        } else if (type === 'group') {
          settings = this.getButtonGroupSettings(id);
        }
        this.originalSettings[id] = { type, settings: { ...settings } }; // Shallow clone the settings because they are mutated lower and this would not work
      }
    };

    const recallGroupLayout = () => {
      const subjectGroupLayout = this.buttonLayout.find((group) => group.id === 'subjectGroup');
      subjectGroupLayout.children = this.orignalSubjectGroupChildren;
      for (const [id, { type, settings }] of Object.entries(this.originalSettings)) {
        if (type === 'button') {
          this.buttonSettings.set(id, settings);
        } else if (type === 'group') {
          this.buttonGroupSettings.set(id, settings);
        }
      }
    };

    if (currentLimit == null) {
      currentLimit = OVERFLOW_LIMIT;
    }
    let totalTools = 0;

    const subjectGroupLayout = this.buttonLayout.find((group) => group.id === 'subjectGroup');
    if (this.orignalSubjectGroupChildren != null) {
      recallGroupLayout(); // Reset the subject group to the original layout
    }
    const groupChildren = new Map();

    const subjectChildren = subjectGroupLayout.children;

    const overflowList = [];

    for (let x = 0; x < subjectChildren.length; ++x) {
      const child = subjectChildren[x];
      if (child.type === 'group') {
        const group = this.buttonGroupSettings.get(child.id);
        if (group && group.visible) {
          overflowList.push(group);
          groupChildren.set(child.id, child);
        }
      } else {
        const button = this.buttonSettings.get(child.id);
        if (button && button.visible) {
          overflowList.push(button);
        }
      }
    }

    totalTools = overflowList.length;

    if (totalTools > currentLimit) {
      const LIMIT = currentLimit - 1; // this allows us to show the overflow if there is at least 2 items in there
      this.makeOverflow();

      saveGroupLayout();
      subjectGroupLayout.children = [];

      for (let i = 0; i < LIMIT; ++i) {
        const overflow = overflowList[i];
        overflow.parentId = 'subjectGroup';

        subjectGroupLayout.children.push((overflow.type === 'group') ?
          { type: overflow.type, id: overflow.id, children: groupChildren.get(overflow.id).children } :
          { type: overflow.type, id: overflow.id, parentId: 'subjectGroup' }
        );
      }

      const overflowLayout = { type: 'group', id: 'overflowGroup', children: [] };

      for (let i = LIMIT; i < overflowList.length; ++i) {
        const overflow = overflowList[i];

        overflow.parentId = 'overflowGroup';

        if (overflow.type === 'group') {
          overflow.isMenu = false;
        }

        overflowLayout.children.push(overflow.type === 'group' ?
          { type: overflow.type, id: overflow.id, children: groupChildren.get(overflow.id).children } :
          { type: overflow.type, id: overflow.id, parentId: 'overflowGroup' }
        );
      }

      subjectGroupLayout.children.push(overflowLayout);
    }
  }

  @action
  makeOverflow = () => {
    const overflowGroup = {
      name: () => t('moreTools', 'More Tools'),
      open: false,
      id: 'overflowGroup',
      type: 'group',
      isMenu: true,
      visible: true,
      icon: true,
      parentId: 'subjectGroup',
      className: ''
    };

    this.buttonGroupSettings.set('overflowGroup', overflowGroup);
    return overflowGroup;
  }

  @action
  setButtonGroupSettings = (id, buttonGroup) => {
    this.buttonGroupSettings.set(id, buttonGroup);
  }

  @action
  setButtonSettings = (id, button) => {
    this.buttonSettings.set(id, button);
  }

  @action
  setButtonLayout = (layout) => {
    this.buttonLayout = layout;
  }

  @action
  toggleButton = (buttonId, forceState = null) => {
    const settings = this.buttonSettings.get(buttonId);
    if (!settings) {
      return;
    }
    if (forceState === null) {
      settings.toggle = !settings.toggle;
    } else {
      settings.toggle = forceState;
    }
    return settings;
  }

  @action
  toggleMenuButton = (buttonId, forceState) => {
    const settings = this.buttonGroupSettings.get(buttonId);
    if (!settings) {
      return;
    }
    if (forceState !== undefined) {
      settings.open = forceState;
    } else {
      settings.open = !settings.open;
    }
  }

  @action
  doButtonAction = (buttonId, lessonElementId, forceState = null) => {
    let buttonSettings;
    if (buttonId !== 'Logout' &&
        buttonId !== 'Translate' &&
        buttonId !== 'Dictionary' &&
        buttonId !== 'Audio' &&
        buttonId !== 'TranslateSettings') {
      buttonSettings = this.toggleButton(buttonId, forceState);
    } else {
      buttonSettings = this.buttonSettings.get(buttonId);
    }

    if (buttonSettings) {
      const action = this.getButtonAction(buttonSettings.id);

      if (typeof action !== 'undefined') {
        if (buttonSettings) {
          buttonSettings.lessonElementId = lessonElementId;
        } else {
          buttonSettings = { id: buttonId, lessonElementId };
        }
        action(buttonSettings);
      }

      // track tool usage toggle
      if (buttonSettings.toggle && !DIRECT_USE_COUNT.includes(buttonId)) {
        if (buttonId.startsWith('Resources')) {
          this.addContentToolUsage('Resources');
        } else {
          this.addContentToolUsage(buttonId);
        }
      }
      // track tool usage action
      if (buttonId === 'Translate' || buttonId === 'Dictionary') {
        this.addContentToolUsage(buttonId);
      }

      if (buttonSettings.parentId) {
        const menu = this.getButtonGroupSettings(buttonSettings.parentId);
        if (menu && menu.open) {
          menu.open = false;
        }
      }
      const overflowGroup = this.getButtonGroupSettings('overflowGroup');
      if (overflowGroup && overflowGroup.open) {
        overflowGroup.open = false;
      }
    }
  }

  @action
  getContentToolUsage = () => {
    return this.contentToolUsage;
  }

  @action
  getContentToolAvailability = () => {
    return this.contentToolAvailability;
  }

  @action
  addContentToolUsage = (buttonId) => {
    this.contentToolUsage.push(buttonId);
  }

  @action
  addContentToolAvailability = (buttonId) => {
    this.contentToolAvailability.push(buttonId);
  }

  @action
  clearContentToolUsage = () => {
    this.contentToolUsage = [];
  }

  @action
  clearContentToolAvailability = () => {
    this.contentToolAvailability = [];
  }

  @action
  toggleToolOff = (toolId) => {
    this.buttonSettings.get(toolId).toggle = false;
  }

  @action
  toggleToolByIdValue = (toolId, value) => {
    this.buttonSettings.get(toolId).toggle = value;
  }

  @action
  toggleToolVisibility = (toolId, value) => {
    this.buttonSettings.get(toolId).visible = value;
  }

  isToolOn = (toolId) => {
    if (this.buttonSettings.get(toolId)) {
      return this.buttonSettings.get(toolId).toggle;
    }
    return false;
  }

  setButtonAction = (buttonId, callback) => {
    this.buttonActions[buttonId] = callback;
  }

  getButtonGroupSettings = (buttonGroupId) => {
    const settings = this.buttonGroupSettings.get(buttonGroupId);
    if (settings !== undefined && settings !== null) {
      return settings;
    }

    // console.debug('WARNING:button group settings missing');
    return null;
  }

  getButtonSettings = (buttonId) => {
    const settings = this.buttonSettings.get(buttonId);
    if (settings !== undefined && settings !== null) {
      return settings;
    }
    // console.debug('WARNING:button settings missing');
    return null;
  }

  getButtonData = (buttonId) => {
    if (this.buttonData[buttonId] !== undefined) {
      return this.buttonData[buttonId];
    }
    // console.debug('WARNING:button data missing');
    return null;
  }

  getButtonAction = (buttonId) => {
    if (this.buttonActions[buttonId] !== undefined) {
      return this.buttonActions[buttonId];
    }
    return (() => {
      // console.debug('WARNING:button action missing');
    });
  }

  setExternalUrl = (url) => {
    this.externalUrl = url;
  }
}

export default new ToolbarManager();
