import React, { useContext } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { PDFObject } from 'react-pdfobject';
import { MobXProviderContext } from 'mobx-react';
import '../../../css/Tools.scss';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';
import { register } from '../../../i18n';

const t = register('ToolNames');
const t2 = register('AriaLabels')

function BasicCalculator() {
  const {
    toolbarManager,
    // lessonManager
  } = useContext(MobXProviderContext);

  const url = 'https://www.desmos.com/testing/default/fourfunction';

  // CF-4158 If browser doesn't support embedded pdfs (Most mobile browsers) instead of using PDFObject
  // we can use an Iframe.
  const doesBrowserSupportPdfs = PDFObject.supportsPDFs();

  // per CF-4136, DESMOS dropped support for basic calculator, so ok to use default here
  // if (lessonManager?.lessonOptions?.hasTexasTools) {
  //   url = 'https://www.desmos.com/testing/texas/fourfunction';
  // }

  function closeCalc() {
    toolbarManager.setIsBasicCalculatorOpen(false);
    toolbarManager.toggleToolOff('BasicCalculator');
  }

  useReactModalResizeableDraggable('calculatorTool');

  return (
    <div className='calculatorTool'>
      <ReactModal
        initHeight={500}
        initWidth={300}
        isOpen={true}>
        <div className='calculatorBody'>
          <div className='tool-header'>
            <div className='title-label basic'>{t('basicCalculatorInfo')}</div>
            <div aria-label={t2('closedialog')} className='x-close-button' id='modal-close' onClick={closeCalc} role='button'
              tabIndex='0' />
          </div>
          {doesBrowserSupportPdfs ?
            <PDFObject url={url} />
            : <iframe height='95%' src={url} title='calculator-iframe' width='100%' />}
        </div>
      </ReactModal>
    </div>
  );
}

export default BasicCalculator;
