import React from 'react';
import { PDFObject } from 'react-pdfobject';
import '../../../../css/PdfResource.scss';

const PdfWidget = (props) => {
  const { model, url } = props;
  const display = (model.display) ? model.display : 'visible';

  return (
    <>
      <div className={`element-container PdfResource ${display}`} id={model.id}>
        <PDFObject height='95%' url={url} width='95%' />
      </div>
    </>
  );
};
export default PdfWidget;
