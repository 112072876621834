import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/Cloze.scss';
import { LessonMode } from '../../../Constants';

import clozeMultichoiceService from '../../services/ClozeMultichoiceService';
import clozeTypingService from '../../services/ClozeTypingService';
import promptService from '../../services/PromptService';
import questionService from '../../services/QuestionService';

import useForceUpdate from '../../../hooks/useForceUpdate';

import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import useStyleEvents from '../../../hooks/useStyleEvents';
import toolbarService from '../../services/ToolbarService';
import PrintQuestionNumber from './PrintQuestionNumber';

const ClozeCombo = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    questionFeedbackManager,
    responseManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const forceUpdate = useForceUpdate();

  const uiState = questionFeedbackManager.getUiState(lessonElementId) || {};
  const { noSubmit } = questionFeedbackManager;

  useStyleEvents(lessonElementId);

  let {
    lessonElementState,
    toolbar,
    model,
    readOnly,
    userInputMap,
  } = questionService.initQuestionComponent({
    lessonElementId,
    questionClassName: 'cloze-question'
  });
  lessonElementState = lessonElementState || responseManager.getLessonElementState(lessonElementId);

  const renderClozeCombo = (_props = null) => {
    const answerBankPositionClassName = `answer-bank-position-${ model?.answerBankPosition || 'bottom'}`;
    const clozeQuestionInnerClassNames = classNames('cloze-question-inner', answerBankPositionClassName);
    const questionTitleTemp = (model && model.questionText) ? model.questionText : '';

    const questionTitle = <HtmlComponent htmlStr={questionTitleTemp} />;

    return (
      <div className='cloze-question'>
        <div className='cloze-question-section-title'>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='test-item-question'>
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
            {questionTitle}
          </div>
          <OptionalImage model={model} runtime={true} />
        </div>
        <div className={clozeQuestionInnerClassNames}>
          <div className='cloze-question-section-body' onClick={toolbarService.handleContentResourceClick}>
            <div className='test-item-answers'>
              {lessonElementState?.questionBehavior?.renderClozeBody({
                lessonElementId, model, renderBlankSection
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /** @param {import('html-react-parser').DOMNode} domNode */
  const renderBlankSection = (domNode, { lessonElementId } = {}) => {
    const rangeType  = domNode.attribs['data-range-type'];


    if (rangeType && rangeType === 'cloze') {
      return clozeTypingService.renderClozeTypingBlankSection(domNode, {
        lessonElementId,
        lessonElementState,
        model,
        readOnly,
        toolbar,
        uiState,
        userInputMap,
        noSubmit
      });
    } else if(rangeType && rangeType === 'clozeMultichoice') {
      return clozeMultichoiceService.renderClozeMultichoiceBlankSection(domNode, {
        forceUpdate,
        lessonElementId,
        lessonElementState,
        model,
        readOnly,
        uiState,
        userInputMap,
        noSubmit
      });
    }
  };
  return /* !loadingClozeCombo && */lessonElementId ? renderClozeCombo() : null;
});

export default ClozeCombo;
