import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { register } from '../../../i18n';

import navMenuService from '../../services/NavMenuService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import ActivityProgressBar from '../ActivityProgressBar';

import '../../../css/Navbar.scss';

import htmlService from '../../services/HtmlService';
import utilsService from '../../services/UtilsService';

const t = register('NavigationBar');
const t2 = register('AriaLabel');

const Navbar = observer(({ buttonGroup }) => {
  const {
    lessonManager,
    navMenuManager
  } = useContext(MobXProviderContext);

  const backButtonRef = useRef();
  const gotoButtonRef = useRef();
  const nextButtonRef = useRef();

  useAccessibilityClick(backButtonRef, (event) => {
    htmlService.closeMathTypeModal();
    htmlService.resetHiddenCkeditorInstance();
    navMenuService.gotoButtonHandler(event, false);
    navMenuService.previousSlideHandler(event);
  });

  useAccessibilityClick(gotoButtonRef, (event) => {
    htmlService.resetHiddenCkeditorInstance();
    navMenuService.gotoButtonHandler(event);
  });

  useAccessibilityClick(nextButtonRef, (event) => {
    htmlService.closeMathTypeModal();
    htmlService.resetHiddenCkeditorInstance();
    navMenuService.gotoButtonHandler(event, false);
    navMenuService.nextSlideHandler(event);
  });

  return (
    <>
      <div className='navbar-wrapper' tabIndex='-1'>
        <div className='progress-bar'>
          {/* This will change. using number of slides minus the start and summary pages for now. */}
          <ActivityProgressBar />
        </div>
        <div className='button-bar' tabIndex='-1'>
          <button ref={backButtonRef} aria-label={t2('gotothepreviousslide')} className='nav-previous navbar-button' style={(lessonManager.showNavBackButton) ? {} : { visibility: 'hidden' }}
            tabIndex='0'> &#60; {t('previous')}
          </button>
          <button ref={gotoButtonRef} aria-label={(navMenuManager.gotoMenuOpen) ? `${t2('closethevavigationmenu')}` : `${t2('showthevavigationmenu')}` } className='nav-goto navbar-button' tabIndex='0'>{t('goto')}</button>
          <button ref={nextButtonRef} aria-label={t2('gotothenextslide')} className='nav-next navbar-button' style={(lessonManager.showNavNextButton) ? {} : { visibility: 'hidden' }}
            tabIndex='0'>{t('next')} &#62;
          </button>
        </div>
        {(utilsService.isNullOrEmpty(lessonManager.lessonOptions.copyright)) ? <div className='copyright' /> : <div className='copyright'>{`© ${lessonManager.lessonOptions.copyright}`}</div>}
      </div>
    </>
  );
});
export default Navbar;
