import { Behavior } from './Behavior';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import UtilsService from '../services/UtilsService';

export class ChoiceMatrixMultiBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    // Check that each prompt has at least one answer selected
    const valid = responseAnswer.answers.every((answerString) => answerString.length > 0);
    return valid;
  }

  setCorrectAnswer = (state, model) => {
    const temp = {
      answers: UtilsService.safeMobxClone(model.validation?.correctAnswers || []),
      lessonElementId: model.lessonElementId
    };
    state.setCurrentResponse(UtilsService.safeMobxClone(temp));
  }

  resetStudentAnswer = (state, validation) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  isAutoScored = (model) => {
    const autoScore = (ContentType.getAutoscore(model.type) && !model.survey && !model.unscored);
    return autoScore;
  }

  _getAnswerArray = (answerString) => answerString.split(',').filter((answer) => answer.length > 0).map((answer) => parseInt(answer));

  getScore = (responseAnswer, model) => {
    const { maxScore } = model;
    const { scoringType } = model;

    // Get the total number of correct answers
    if (model.validation && model.validation.correctAnswers) {
      const potentialPoints = model.validation.correctAnswers.reduce((acc, promptAnswers) => {
        // promptAnswers is like '1,2,3'. If promptAnswers is '' then we have no correct answers for this prompt.
        return acc + this._getAnswerArray(promptAnswers).length;
      }, 0);

      const subscore = model.validation.correctAnswers.reduce((acc, promptAnswers, index) => {
        // Counts the number of answers that are included in both the correct answers and the student's answers
        const correctAnswerIds = this._getAnswerArray(promptAnswers);
        const studentAnswerIds = this._getAnswerArray(responseAnswer.answers[index]);
        for (const answerId of correctAnswerIds) {
          if (studentAnswerIds.includes(answerId)) {
            acc++;
          }
        }
        return acc;
      }, 0);

      let score;
      if (scoringType === 'exact') {
        score = subscore === potentialPoints ? maxScore : 0;
      } else if (scoringType === 'partial') {
        score = subscore / potentialPoints * maxScore;
      } else if (scoringType === 'partialMatchResponse') {
        score = subscore;
      }
      return score;
    }
    return 0;
  }

  setResponseData = (data, responseAnswer, model) => {
    const { promptId, answerIndex } = data;
    const { prompts } = model;
    const promptIndex = prompts.findIndex((prompt) => prompt.id === promptId);
    const currentAnswer = responseAnswer.answers[promptIndex].split(',').map((answer) => parseInt(answer));
    let newAnswer;
    const isMultiChoice = model.answerType === 'mchoice'; // If false then this is multiselect
    if (isMultiChoice) {
      // Then we can just set the answer to str(answerIndex)
      newAnswer = [answerIndex];
    } else {
      // Then we need to toggle the answer
      if (currentAnswer.includes(answerIndex)) {
        newAnswer = currentAnswer.filter((answer) => answer !== answerIndex);
      } else {
        newAnswer = [...currentAnswer, answerIndex];
      }
    }
    // Not sure if this is necessary, but we sort them just in case
    newAnswer.sort();
    responseAnswer.answers[promptIndex] = newAnswer.join(',');
  }

  getInitializedResponse = (lessonElementId, model) => {
    const responseAnswer = {
      answers: model.prompts.map(() => ''),
      // extra field for ui control should probably be added to every
      // possible answer in a response model.
      lessonElementId
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    // Answers shown in question body. Nothing needs to be done here.
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectPrompt()}. ${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new ChoiceMatrixMultiBehavior();
