import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import SplitPane from 'react-split-pane';
import classNames from 'classnames';
import useWirisParser from '../../../hooks/useWirisParser';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import QuestionListViewer from '../QuestionListViewer';
import '../../../css/ActivityViewer.scss';
import { useScrollBottomIndicator } from '../../../hooks/useScrollBottomIndicator';
import AudioPlayer from '../AudioPlayer';
import ActivityChildViewer from './ActivityChildViewer';

const ActivityViewer = observer(({ lessonElementId, showQuestionBanner }) => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const currentModel = lessonManager.getSlideModel(lessonElementId);
  let childModel, activityLessonElement, activityModel, ActivityComponent;
  if (currentModel.isActivityPart) {
    // Then we are showing a split with an activity on the left and a question on the right
    childModel = currentModel;
    if (!childModel) {
      return (<div className='activity-content' />);
    }

    activityLessonElement = lessonManager.getLessonElement(childModel.parentActivityElementId);
    activityModel = lessonManager.getSlideModel(childModel.parentActivityElementId);
    ActivityComponent = lessonManager.getActivityComponent(activityLessonElement.id);
  } else if (currentModel.isActivity) {
    // Then we are just showing an activity that was given no children
    childModel = null;
    activityLessonElement = lessonManager.getLessonElement(lessonElementId);
    activityModel = currentModel;
    ActivityComponent = lessonManager.getActivityComponent(activityLessonElement.id);
  }

  let defaultCssWidth = parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--activity-split-pane-default'));

  const smallestCssWidth = parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--activity-split-pane-small'));

  let largestCssWidth = parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--activity-split-pane-large'));

  if (activityModel && activityModel.dividedLayoutChildWidth) {
    if (activityModel.dividedLayoutChildWidth === 'small') {
      defaultCssWidth = 70;
    }
    if (activityModel.dividedLayoutChildWidth === 'medium') {
      defaultCssWidth = 60;
    }
    if (activityModel.dividedLayoutChildWidth === 'large') {
      defaultCssWidth = 50;
    }
    if (!isNaN(activityModel.dividedLayoutChildWidth)) {
      defaultCssWidth = activityModel.dividedLayoutChildWidth;
      if (Number(defaultCssWidth) >= Number(largestCssWidth)) {
        largestCssWidth = parseInt(getComputedStyle(document.documentElement)
          .getPropertyValue('--activity-split-pane-largest'));
      }
    }
  }

  const [defaultSize, setDefaultSize] = useState();
  const [contentDisplay, setContentDisplay] = useState();

  const slideContentRef = useRef();
  const slideQuestionsRef = useRef();
  const componentRef = useRef();
  const scrollBottomContentRef = useRef();
  const scrollBottomQuestionRef = useRef();
  const canScrollBottomContent = useScrollBottomIndicator(scrollBottomContentRef);
  const canScrollBottomQuestion = useScrollBottomIndicator(scrollBottomQuestionRef);

  useWirisParser(componentRef);

  useEffect(() => {
    // look at lesson options to set default size
    setDefaultSize(defaultCssWidth);
    setContentDisplay('content-middle');
  }, [defaultCssWidth]);

  // TODO: MAKE showAllChildren a things on all activities. The list or single can be flexible.
  const isQuestionListView = activityModel.showAllChildren;
  lessonManager.setIsQuestionListView(isQuestionListView);

  // TODO: Use the lessonOptios
  // create lessonOption for custom resizing (not buttons.)
  useAccessibilityClick(slideContentRef, () => {
    let toSize = 0;
    if (defaultSize === smallestCssWidth) {
      toSize = defaultCssWidth;
      setContentDisplay('content-middle');
    } else {
      toSize = smallestCssWidth;
      setContentDisplay('content-collapse');
    }
    setDefaultSize(toSize);
  });

  useAccessibilityClick(slideQuestionsRef, () => {
    let toSize = 0;
    if (defaultSize === largestCssWidth) {
      toSize = defaultCssWidth;
      setContentDisplay('content-middle');
    } else {
      toSize = largestCssWidth;
      setContentDisplay('question-collapse');
    }
    setDefaultSize(toSize);
  });

  return (
    <>
      <div className={classNames('activity-content', 'standard-view', `${activityModel.type}`, { 'no-children': (childModel === null) })}>
        {
          childModel === null ? (
            <div ref={componentRef} className='activity-content-no-children' style={{ position: 'relative' }}>
              <AudioPlayer lessonElementId={activityModel.lessonElementId} />
              <ActivityComponent lessonElementId={activityModel.lessonElementId} model={activityModel} />
            </div>
          ) : (

            <SplitPane allowResize={false} defaultSize={`${defaultSize}%`} split='vertical'>
              {/*  // implement with resizable <SplitPane split='vertical' minSize={'15%'} defaultSize={`${defaultSize}%`} maxSize={'95%'} allowResize={false}> */}
              <div className='top-noscroll'>
                <div ref={scrollBottomContentRef} className='scrollable-inner-pane'>
                  <div ref={slideContentRef} className={`content-slider-button-wrapper slider-button ${contentDisplay}`} role='button' tabIndex={1}>
                    <div className='icon-wrapper'>
                      <div className='content-slider-button ' />
                    </div>
                  </div>
                  <div ref={componentRef} className={`activity-parent-wrapper ${contentDisplay}`}>
                    <AudioPlayer lessonElementId={childModel.parentActivityElementId} />
                    <ActivityComponent lessonElementId={childModel.parentActivityElementId} model={activityModel} />
                  </div>
                  <div className='scroll-bottom-indicator-multi' style={(canScrollBottomContent) ? {} : { visibility: 'hidden' }} />
                </div>
              </div>
              <div className='top-noscroll'>
                {childModel && (
                  <div ref={scrollBottomQuestionRef} className='scrollable-inner-pane'>
                    {/* eslint-disable-next-line jsx-a11y/aria-role */}
                    <div ref={slideQuestionsRef} className={`question-slider-button-wrapper slider-button ${contentDisplay}`} role='buttom' tabIndex={1}>
                      <div className='icon-wrapper'>
                        <div className='question-slider-button' />
                      </div>
                    </div>
                    <div className={`question-panel ${contentDisplay}`} role='tabpanel'>
                      {(isQuestionListView) ?
                        <QuestionListViewer isMultiPart={false} lessonElementIds={activityLessonElement.lessonElementIds} parentElementId={activityLessonElement.id} showQuestionBanner={showQuestionBanner} />
                        :
                        <ActivityChildViewer lessonElementId={lessonElementId} model={childModel} showQuestionBanner={showQuestionBanner} />}
                    </div>
                    <div className='scroll-bottom-indicator-multi' style={(canScrollBottomQuestion) ? {} : { visibility: 'hidden' }} />
                  </div>
                )}
              </div>
            </SplitPane>
          )
        }
      </div>
    </>
  );
});

export default ActivityViewer;
