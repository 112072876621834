import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { LessonMode } from '../../../Constants';
import OptionalImage from '../OptionalImage';
import HtmlComponent from '../HtmlComponent';
import FeedbackIcon from '../FeedbackIcon';
import responseService from '../../services/ResponseService';
import Guideline from '../tools/Guideline';
import '../../../css/ChoiceMatrix.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const ChoiceMatrix = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);
  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);

  const readOnly = lessonElementState.readOnly || lessonManager.isReadOnly;
  const { showAnswers } = feedbackState; // Do we show what answers are correct

  // Render state
  const { questionText } = model;
  const options = model.options.filter((option) => option.length > 0); // Such as 'True' or 'False'
  const { prompts } = model; // Object with keys id and text. text is an html string

  const { currentResponseAnswer } = lessonElementState;
  const selectedOptions = currentResponseAnswer?.answers?.map((answerIndex) => {
    if (answerIndex === '') {
      return null;
    } else {
      return parseInt(answerIndex);
    }
  });
  const correctOptions = model.validation?.correctAnswers.map((answerIndex) => parseInt(answerIndex)) || [];

  useStyleEvents(lessonElementId);

  const handleSelect = (promptId, option) => {
    // We get the index of the option selected as string
    const optionIndex = options.findIndex((opt) => opt === option);
    if (optionIndex === -1) {
      // This should never happen
      throw new Error(`ChoiceMatrix: option ${option} not found in options ${options}`);
    }
    const promptIndex = prompts.findIndex((prompt) => prompt.id === promptId);
    responseService.responseChangeHandler({ promptIndex, optionIndex }, lessonElementId);
  };

  return (
    <div className='choice-matrix-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question choice-matrix'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='test-item-answers'>
        <table className='choice-matrix-table'>
          <tbody>
            {
              prompts.map(({ id, text }, promptIndex) => (
                <tr key={id}>
                  <td className='prompt-cell'><HtmlComponent htmlStr={text} /></td>
                  {
                    options.map((option, optionIndex) => (
                      <ChoiceCell
                        key={`${id}-${option}`}
                        isCorrectAnswer={correctOptions[promptIndex] === optionIndex}
                        isSelected={selectedOptions?.[promptIndex] === optionIndex}
                        lessonElementId={lessonElementId}
                        lessonElementState={lessonElementState}
                        onSelect={handleSelect}
                        option={option}
                        optionIndex={optionIndex}
                        promptId={id}
                        promptSelectedAnswer={selectedOptions?.[promptIndex]}
                        readOnly={readOnly}
                        showAnswers={showAnswers}
                      />
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default ChoiceMatrix;

const ChoiceCell = ({ isSelected, readOnly, promptId, optionIndex, promptSelectedAnswer, option, onSelect, showAnswers, isCorrectAnswer, lessonElementId, lessonElementState }) => {
  const id = `${promptId}-${option}`;

  const canShowFeedback = isSelected;

  const [focused, setFocused] = useState(false);

  const callOnSelect = () => {
    if (!readOnly) {
      onSelect(promptId, option);
    }
  };

  let visualClass = '';
  if (showAnswers) {
    if (isCorrectAnswer) {
      visualClass = 'correct';
    } else {
      visualClass = 'incorrect';
    }
  }

  return (
    <td className='option-cell'>
      <label className='selector-container' htmlFor={id}>
        {canShowFeedback && (
          <div className='correct-answer-symbol'>
            <FeedbackIcon isCorrectOverride={isSelected && isCorrectAnswer}
              lessonElementId={lessonElementId} />
          </div>
        )}
        <div className='text-container'>
          <label className='option-label' htmlFor={id}><HtmlComponent htmlStr={option} /></label>
          <label className={`outline-separate radio-symbol ${focused ? 'focused' : ''} ${readOnly ? 'disabled' : ''} ${isSelected ? 'selected' : ''} ${visualClass}`} htmlFor={id} />
          <input
            aria-checked={isSelected}
            checked={isSelected}
            disabled={readOnly}
            id={id}
            name={promptId}
            onBlur={() => setFocused(false)}
            onChange={callOnSelect}
            onFocus={() => setFocused(true)}
            tabIndex={0}
            type='radio'
            value={id}
          />
        </div>
      </label>
    </td>
  );
};
