import { action, makeObservable, observable } from 'mobx';

export class AudioManager {
  @observable currentAudioSource;

  @observable allowShowDividedAudioQuestions = false;

  @observable isAudioPlaying = false;

  @observable isAudioTranscriptDialogOpen = false;

  @observable previousAudioTimeProgress = 0;

  @observable selectedSideNavAudioChildLessonElementId = null;

  @observable selectedAudioTranscriptOption = null;

  @observable shouldCallAudioScrubChangeHandler = false;

  @observable sideNavAudioProgressValue = null;

  player = null;
  interval = null;
  audioQue = [];

  constructor() {
    makeObservable(this);
  }

  @action
  clearAudioQue = (audioSource) => {
    const player = document.getElementById('main-c2c-audio-player');
    clearInterval(this.interval);
    if (player) {
      player.pause();
    }
    this.audioQue = [];
    this.currentAudioSource = null;
  }

  @action
  playAudio = (source) => {
    if (!this.player) {
      this.player = document.getElementById('main-c2c-audio-player');
    }
    const that = this;

    if ((this.player.ended || this.player.paused) && (this.audioQue && this.audioQue.length === 0)) {
      this.player.removeAttribute('src');
      this.player.setAttribute('src', source);
      this.player.play();
    } else {
      const currentSrc = this.player.getAttribute('src');
      const index = this.audioQue.indexOf(currentSrc);
      if (index < 0 && currentSrc !== source) {
        this.audioQue.push(source);
      }
    }
    this.player.onended = () => {
      if (that.audioQue && that.audioQue.length > 0) {
        const source = that.audioQue.shift();
        that.player.removeAttribute('src');
        that.player.setAttribute('src', source);
        that.player.play();
      }
    };
  }

  @action setAllowShowDividedAudioQuestions = (toggle) => {
    this.allowShowDividedAudioQuestions = toggle;
  }

  @action setIsAudioPlaying = (isAudioPlaying) => {
    this.isAudioPlaying = isAudioPlaying;
  }

  @action setIsAudioTranscriptDialogOpen = (isOpen) => {
    this.isAudioTranscriptDialogOpen = isOpen;
  }

  @action setSelectedSideNavAudioChildLessonElementId = (lessonElementId) => {
    this.selectedSideNavAudioChildLessonElementId = lessonElementId;
  }

  @action setSelectedAudioTranscriptOption = (transcriptOption) => {
    this.selectedAudioTranscriptOption = transcriptOption;
  }

  @action setSideNavAudioProgressValue = (value) => {
    this.sideNavAudioProgressValue = value;
    this.setShouldCallAudioScrubChangeHandler(!!this.selectedSideNavAudioChildLessonElementId);
  }

  @action setPreviousAudioTimeProgress = (progress) => {
    this.previousAudioTimeProgress = progress;
  }

  @action setShouldCallAudioScrubChangeHandler = (toggle) => {
    this.shouldCallAudioScrubChangeHandler = toggle;
  }
}

export default new AudioManager();
