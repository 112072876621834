import { renderToStaticMarkup } from 'react-dom/server';

import parse from 'html-react-parser';

import { Behavior } from './Behavior';

import { ContentType } from '../../Constants';

import promptService from '../services/PromptService';
import questionService from '../services/QuestionService';
import utilsService from '../services/UtilsService';

export class ImageLabelBehavior extends Behavior {
  getInitializedResponse = (lessonElementId, model) => {
    const userInputMap = new Map();
    if (Array.isArray(model?.prompts)) {
      for (const prompt of model?.prompts) {
        userInputMap.set(prompt.id, {
          dataId: prompt.id,
          text: ''
        });
        prompt.answers = prompt.responseItems;
      }
      return {
        ...questionService.generateInitializedQuestionResponse({
          feedbackIconSvgHeight: '15',
          feedbackIconSvgWidth: '15',
          lessonElementId,
          multi: true,
          userInputMap
        })
      };
    }
  }

  setCorrectAnswer = (state, model) => {
    const userInputMap = new Map();
    for (const prompt of model?.validation?.prompts) {
      userInputMap.set(prompt.id, {
        dataId: prompt.id,
        text: prompt.correctAnswers[0]
      });
    }
    state.setCurrentResponse(utilsService.safeMobxClone({ userInputMap, lessonElementId: model.lessonElementId }));
  }

  resetStudentAnswer = (state, validation) => {
    state.setCurrentResponse(utilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  checkForValidResponse = (responseAnswer, model) => {
    if (!responseAnswer?.userInputMap) {
      const userInputMap = questionService.updateUserInputMap({ model, responseAnswer });
      const userInputs = Array.from(userInputMap?.values?.() || []);
      return userInputs?.every((userInput) => !!userInput.text);
    } else {
      const userInputs = Array.from(responseAnswer?.userInputMap?.values?.() || []);
      return userInputs?.every((userInput) => !!userInput.text);
    }
  }

  isAutoScored = (model) => {
    return questionService.isAutoScored(model);
    // TODO remove
    // const { autoScore, responseFormat, survey, unscored } = model;
    // return responseFormat !== 'html' && autoScore && !survey && unscored;
  }

  getScore = (responseAnswer, model) => {
    const score = promptService.isAllCorrectPromptAnswerStatus({ model, responseAnswer }) ? 1 : 0;
    return score;
  }

  setResponseData = (data, responseAnswer, model, { dataId } = {}) => {
    if (!responseAnswer?.userInputMap) {
      questionService.updateUserInputMap({ model, responseAnswer });
    } else {
      let text;
      if (model.responseFormat === 'numeric') {
        text = data?.target?.value || data.text;
      } else {
        text = data || data.text;
      }
      responseAnswer.userInputMap.set(dataId, {
        dataId,
        text
      });
    }
  }

  getCorrectAnswersText = (model) => {
    if (model?.validation?.prompts?.length) {
      let text = '';
      let count = 1;
      const isImageLabel = model.type === ContentType.IMAGE_LABEL.type;
      for (const modelValidationPrompt of model?.validation?.prompts) {
        let answerTextBody = (
          modelValidationPrompt?.answers?.filter((answer) => {
            const isCorrectAnswer = isImageLabel || typeof answer.isCorrect !== 'boolean' || answer.isCorrect;
            return isCorrectAnswer;
          }).map((answer, index, correctAnswers) => {
            const lastIndex = correctAnswers.length - 1;
            const shouldIncludeComma = index < lastIndex;
            return answer.text ? parse(`${answer.text}${shouldIncludeComma ? ', ' : ''}`) : '';
          }).filter((answerText) => answerText)
        );
        answerTextBody = answerTextBody.length ? answerTextBody : ['?'];
        text += renderToStaticMarkup(
          <div className='answer-list-row'>
            <div className='list-number'>
              {count}
            </div>
            <div className='correct-icon' />
            <div className='answer-text'>
              <span>
                {answerTextBody}
              </span>
            </div>
          </div>
        );
        ++count;
      }
      return text;
    }
    return '<div></div>';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, _questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    return questionService.getBehaviorQuestionFeedbackText({
      correctFeedback,
      correctFeedbackBody,
      incorrectFeedback,
      incorrectFeedbackBody,
      mode,
      model,
      questionIsCorrect: this.isQuestionCorrect(lessonElementState),
      lessonElementState,
      currentAttemptCount
    });
  }

  isAnswerCorrect = (dataId, lessonElementState, model) => {
    const userInputMap = lessonElementState?.currentResponseAnswer.userInputMap || questionService.updateUserInputMap({
      model,
      responseAnswer: lessonElementState.currentResponseAnswer,
      lessonElementState
    });
    const currentUserInput = userInputMap?.get(dataId);
    const currentUserInputValue = currentUserInput?.text;

    const result = promptService.isCorrectPromptAnswerStatus({
      dataId, currentUserInputValue, model
    });
    return result;
  }

  isQuestionCorrect = (lessonElementState, _lessonElementId) => {
    return promptService.getQuestionFeedbackCodeForAllPrompts({ lessonElementState });
  }
}

export default new ImageLabelBehavior();
