import { action, makeObservable, observable } from 'mobx';
import { UploadResponseOption } from '../../Constants';

export class UploadManager {
  @observable selectedUploadResponseOptionMap = new Map();
  @observable waveSurferVideoMediaRecorderMap = new Map();

  constructor() {
    makeObservable(this);
  }

  @action clearSelectedUploadResponseOption = (lessonElementId) => {
    this.selectedUploadResponseOptionMap.delete(lessonElementId);
  }

  @action setSelectedUploadResponseOption = (lessonElementId, option) => {
    this.selectedUploadResponseOptionMap.set(lessonElementId, option);
  }

  isRecordVideoSelectedResponseOption = (lessonElementId) => {
    const option = this.selectedUploadResponseOptionMap.get(lessonElementId);
    return option?.value === UploadResponseOption.OPTION_RECORD_VIDEO.value;
  }

  isRecordAudioSelectedResponseOption = (lessonElementId) => {
    const option = this.selectedUploadResponseOptionMap.get(lessonElementId);
    return option?.value === UploadResponseOption.OPTION_RECORD_AUDIO.value;
  }

  isUploadFileSelectedResponseOption = (lessonElementId) => {
    const option = this.selectedUploadResponseOptionMap.get(lessonElementId);
    return option?.value === UploadResponseOption.OPTION_UPLOAD_FILE.value;
  }

  getSelectedUploadResponseOption = (lessonElementId) => {
    return this.selectedUploadResponseOptionMap.get(lessonElementId);
  }

  @action addToWaveSurferVideoMediaRecorderMap = (lessonElementId, mediaRecorder) => {
    const previousArray = this.waveSurferVideoMediaRecorderMap.get(lessonElementId) || [];
    this.waveSurferVideoMediaRecorderMap.set(lessonElementId, [...previousArray, mediaRecorder]);
  }

  @action removeFromWaveSurferVideoMediaRecorderMap = (lessonElementId) => {
    this.waveSurferVideoMediaRecorderMap.delete(lessonElementId);
  }
}

export default new UploadManager();
