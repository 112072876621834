import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/Flyout.scss';
import '../../../css/FlyoutTeacherNotesPanel.scss';
import '../../../css/FlyoutRubricPanel.scss';

import lessonService from '../../services/LessonService';
import navMenuService from '../../services/NavMenuService';
import studentActivityService from '../../services/StudentActivityService';
import { ContentType, FlyoutOpenState, FlyoutSide, FlyoutTabs, LessonMode, LessonSlideIds } from '../../../Constants';
import AlignmentPanel from './AlignmentPanel';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import videoService from '../../services/VideoService';
import utilsService from '../../services/UtilsService';
import EngagementPanel from './EngagementPanel';
import FlyupNavForScoring from './FlyupNavForScoring';
import RubricPanel from './RubricPanel';
import StudentScorePanel from './StudentScorePanel';
import TeacherNotesPanel from './TeacherNotesPanel';
import TeacherScorePanel from './TeacherScorePanel';
import { register } from '../../../i18n';

const t = register('PanelMenu');
const t2 = register('AriaLabel');
const t3 = register('TeacherScoringPanel');

const Flyout = observer(({
  flyoutSide,
  isActivity,
  isScoreModeVideoActivityParent,
  isScoring,
  lessonElementId,
} = {}) => {
  const {
    lessonManager,
    navMenuManager,
    alignmentManager,
    userManager,
    scoringManager,
    responseManager,
    studentActivityManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  let hasDirectionFlyout = null;
  if (model.isActivity) {
    hasDirectionFlyout = lessonManager.hasDirectionFlyout.has(model.type);
  }
  const mode = lessonManager.playerMode;
  const [internalFlyoutSide, setInternalFlyoutSide] = useState((flyoutSide) || FlyoutSide.RIGHT);
  const lessonElementState = responseManager.lessonElementStates.get(lessonElementId);
  const isSummary = (lessonManager.currentLessonElementId === LessonSlideIds.SUMMARY_PAGE);

  const openButton = useRef();
  const flipButton = useRef();
  const alignButton = useRef();
  const rubricButton = useRef();
  const teacherNotes = useRef();
  const scoreButton = useRef();
  const engageButton = useRef();

  const isTeacher = userManager.canViewAsTeacher;
  const { isStudent } = userManager;
  const { hasViewEngagementDataPermission } = userManager;
  const hasAlignments = alignmentManager.hasAlignments(lessonElementId);
  // eslint-disable-next-line eqeqeq
  const hasRubric = (scoringManager.rubricMap.has(lessonElementId) && model.type !== ContentType.RUBRIC.type);

  const showFlipButton = lessonService.showFlyoutFlip();
  const showAlignTab = lessonService.showFlyoutAlignments(hasAlignments);
  const showRubricTab = lessonService.showFlyoutRubric(hasRubric, lessonElementId);
  const showScoreTab = lessonService.showFlyoutScore();
  const showNotesTab = lessonService.showFlyoutTeacherNotes() && !utilsService.isNullOrEmpty(model.teacherNotes);
  const showEngagementTab = hasViewEngagementDataPermission && isTeacher && (mode === LessonMode.SCORING) && lessonElementState;
  const showSpace = (internalFlyoutSide === FlyoutSide.LEFT && model &&
    model.directions && hasDirectionFlyout);

  const selectATab = (closed, currentTab) => {
    if (closed) {
      return FlyoutTabs.NONE;
    } else {
      if (!currentTab || (currentTab && currentTab === FlyoutTabs.NONE)) {
        // this if stack is priority order
        // if this proves to not work in every case
        // the lesson mode might need to be queried and this should
        // go into a service
        if (showScoreTab) {
          return FlyoutTabs.SCORE;
        } else if (showAlignTab) {
          return FlyoutTabs.ALIGN;
        } else if (showNotesTab) {
          return FlyoutTabs.NOTES;
        } else if (showRubricTab) {
          navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN_WIDE, lessonElementId);
          return FlyoutTabs.RUBRIC;
        } else if (showEngagementTab) {
          return FlyoutTabs.ENGAGEMENT;
        }
      }
    }
    return currentTab;
  };

  const openState = navMenuManager.getFlyoutOpenState(lessonElementId);

  useEffect(() => {
    if (openState) {
      if (openState.open !== FlyoutOpenState.CLOSED) {
        if ((openState.tab === FlyoutTabs.SCORE && !showScoreTab) ||
            (openState.tab === FlyoutTabs.ALIGN && !showAlignTab) ||
            (openState.tab === FlyoutTabs.NOTES && !showNotesTab) ||
            (openState.tab === FlyoutTabs.RUBRIC && !showRubricTab) ||
            (openState.tab === FlyoutTabs.ENGAGEMENT && !showEngagementTab) ||
            (openState.tab === FlyoutTabs.NONE)) {
          const tab = selectATab(false, FlyoutTabs.NONE);
          navMenuService.setFlyoutPanelTab(tab, lessonElementId);
        }
      }
      if (isScoring) {
        setInternalFlyoutSide(navMenuManager.scoringFlyoutSide);
      } else {
        setInternalFlyoutSide(openState.side);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openState]);

  useAccessibilityClick(flipButton, () => {
    const os = navMenuManager.getFlyoutOpenState(lessonElementId);
    if (internalFlyoutSide === FlyoutSide.LEFT) {
      isScoring && navMenuManager.setScoringFlyoutSide(FlyoutSide.RIGHT);
      setInternalFlyoutSide(FlyoutSide.RIGHT);
      navMenuManager.setFlyoutOpenState({ open: os.open, tab: os.tab, side: FlyoutSide.RIGHT }, lessonElementId);
    } else {
      isScoring && navMenuManager.setScoringFlyoutSide(FlyoutSide.LEFT);
      setInternalFlyoutSide(FlyoutSide.LEFT);
      navMenuManager.setFlyoutOpenState({ open: os.open, tab: os.tab, side: FlyoutSide.LEFT }, lessonElementId);
    }
  });

  useAccessibilityClick(openButton, () => {
    let didClose = false;
    if (openState.tab === FlyoutTabs.RUBRIC) {
      didClose = navMenuService.toggleFlyoutPanel(true, FlyoutOpenState.OPEN_WIDE, lessonElementId);
    } else {
      didClose = navMenuService.toggleFlyoutPanel(true, FlyoutOpenState.OPEN, lessonElementId);
    }

    const tab = selectATab(didClose, openState.tab);

    navMenuService.setFlyoutPanelTab(tab, lessonElementId);
  });

  useAccessibilityClick(rubricButton, () => {
    navMenuService.setFlyoutPanelTab(FlyoutTabs.RUBRIC, lessonElementId);
    navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN_WIDE, lessonElementId);
  });

  useAccessibilityClick(scoreButton, () => {
    navMenuService.setFlyoutPanelTab(FlyoutTabs.SCORE, lessonElementId);
    navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN, lessonElementId);
  });

  useAccessibilityClick(teacherNotes, () => {
    navMenuService.setFlyoutPanelTab(FlyoutTabs.NOTES, lessonElementId);
    navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN, lessonElementId);
  });

  useAccessibilityClick(alignButton, () => {
    navMenuService.setFlyoutPanelTab(FlyoutTabs.ALIGN, lessonElementId);
    navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN, lessonElementId);
  });

  useAccessibilityClick(engageButton, () => {
    navMenuService.setFlyoutPanelTab(FlyoutTabs.ENGAGEMENT, lessonElementId);
    navMenuService.toggleFlyoutPanel(false, FlyoutOpenState.OPEN, lessonElementId);
  });

  useEffect(() => {
    let goNextItem = false;
    let goPrevItem = false;
    let goNextStudent = false;
    let goPrevStudent = false;
    const onKeyDown = (e) => {
      if (e.ctrlKey && e.key === '\'') {
        goNextItem = true;
      } else if (e.ctrlKey && e.key === ';') {
        goPrevItem = true;
      } else if (e.ctrlKey && e.key === ']') {
        goNextStudent = true;
      } else if (e.ctrlKey && e.key === '[') {
        goPrevStudent = true;
      }
    };
    const onKeyUp = (e) => {
      if (e.key === '\'' && goNextItem) {
        navMenuService.nextSlideHandler(e);
      }
      if (e.key === ';' && goPrevItem) {
        navMenuService.previousSlideHandler(e);
      }
      if (e.key === ']' && goNextStudent) {
        if (studentActivityManager.students.length > 1) {
          studentActivityService.nextStudentHandler(e);
        }
      }
      if (e.key === '[' && goPrevStudent) {
        if (studentActivityManager.students.length > 1) {
          studentActivityService.previousStudentHandler(e);
        }
      }
      if (e.ctrlKey) {
        goNextItem = false;
        goPrevItem = false;
        goNextStudent = false;
        goPrevStudent = false;
      }
    };
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
      goNextItem = false;
      goPrevItem = false;
      goNextStudent = false;
      goPrevStudent = false;
    };
    // eslint-disable-next-line
  }, [])

  return ((openState) ? (
    <div className={classNames('flyout-wrapper',
      videoService.getVideoActivityParentClassNamesIfApplicable({ isScoreModeVideoActivityParent }), {
        wide: (openState.open === FlyoutOpenState.OPEN_WIDE),
        open: (openState.open === FlyoutOpenState.OPEN),
        left: (internalFlyoutSide === FlyoutSide.LEFT),
        closed: (openState.open === FlyoutOpenState.CLOSED),
        activity: isActivity,
        score: FlyoutTabs.SCORE === openState.tab,
        align: FlyoutTabs.ALIGN === openState.tab,
        notes: FlyoutTabs.NOTES === openState.tab,
        rubric: FlyoutTabs.RUBRIC === openState.tab,
        engagement: FlyoutTabs.ENGAGEMENT === openState.tab
      })}>
      <div className={classNames({ 'flyout-tab-bar': true })}>
        {showSpace && (
          <div className='directions-spacer' />
        )}
        <div ref={openButton} className='flyout-tab open-button-wrapper'>
          <div aria-hidden className='icon-wrapper' tabIndex='-1'>
            <div aria-hidden aria-label={(openState.open === FlyoutOpenState.CLOSED) ? `${t('openMenu', 'Open flyout menu')}` : `${t('closeMenu', 'Close flyout menu')}`}
              className='open-button'
              tabIndex='-1' />
          </div>
        </div>
        {showFlipButton && (
          <div ref={flipButton} className='flyout-tab flip-button-wrapper'>
            <div aria-hidden className='icon-wrapper' tabIndex='-1'>
              <div aria-hidden aria-label={(internalFlyoutSide === FlyoutSide.RIGHT) ? 'Move Flyout Menu to the left side of screen' : 'Move Flyout Menu to the right side of screen'}
                className={classNames({ 'flip-button': true, 'left': (internalFlyoutSide === FlyoutSide.LEFT) })}
                tabIndex='-1' />
            </div>
          </div>
        )}
        {showScoreTab && (
          <div ref={scoreButton} aria-controls={`${lessonElementId}-question-view`} aria-label={(isTeacher) ? t3('headerLabelScore') : t3('headerLabelScore')}
            aria-selected={FlyoutTabs.SCORE === openState.tab} className={classNames({ 'flyout-tab': true, 'score-button': true, 'selected': (FlyoutTabs.SCORE === openState.tab) })}
            role='tab'
            tabIndex='0'>
            <div className={classNames({ 'flyout-tab-label': true, 'selected': (FlyoutTabs.SCORE === openState.tab) })} tabIndex='-1'>{isSummary ? '' : t3('headerLabelScore')}</div>
          </div>
        )}
        {showAlignTab && (
          <div ref={alignButton} aria-controls={`${lessonElementId}-align-panel`} aria-label={t2('alignmentPanelTab')}
            aria-selected={FlyoutTabs.ALIGN === openState.tab} className={classNames({ 'flyout-tab': true, 'alignment-button': true, 'selected': (FlyoutTabs.ALIGN === openState.tab) })}
            role='tab'
            tabIndex='0'>
            <div className={classNames({ 'flyout-tab-label': true, 'selected': (FlyoutTabs.ALIGN === openState.tab) })} tabIndex='-1'>{t('alignmentPanel')}</div>
          </div>
        )}
        {showNotesTab && (
          <div ref={teacherNotes} aria-controls={`${lessonElementId}-teacher-notes-panel`} aria-label={t2('teacherFeedbackPanelTab')}
            aria-selected={FlyoutTabs.NOTES === openState.tab} className={classNames({ 'flyout-tab': true, 'notes-button': true, 'selected': (FlyoutTabs.NOTES === openState.tab) })}
            role='tab'
            tabIndex='0'>
            <div className={classNames({ 'flyout-tab-label': true, 'selected': (FlyoutTabs.NOTES === openState.tab) })} tabIndex='-1'>{t('teacherNotePanel')}</div>
          </div>
        )}
        {showRubricTab && (
          <div ref={rubricButton} aria-controls={`${lessonElementId}-rubric-panel`} aria-label={t2('rubricPanelTab')}
            aria-selected={FlyoutTabs.RUBRIC === openState.tab} className={classNames({ 'flyout-tab': true, 'rubric-button': true })}
            role='tab'
            tabIndex='0'>
            <div className={classNames({ 'flyout-tab-label': true, 'selected': (FlyoutTabs.RUBRIC === openState.tab) })} tabIndex='-1'>{t('rubricPanel')}</div>
          </div>
        )}
        {showEngagementTab && (
          <div ref={engageButton} aria-controls={`${lessonElementId}-engagement-panel`} aria-label={t2('engagementPanelTab')}
            aria-selected={FlyoutTabs.ENGAGEMENT === openState.tab} className={classNames({ 'flyout-tab': true, 'engagement-button': true, 'selected': (FlyoutTabs.ENGAGEMENT === openState.tab) })}
            role='tab'
            tabIndex='0'>
            <div className={classNames({ 'flyout-tab-label': true, 'selected': (FlyoutTabs.ENGAGEMENT === openState.tab) })} tabIndex='-1'>{t('engagementPanel')}</div>
          </div>
        )}
      </div>
      <div className={classNames({
        'flyout-panel-wrapper': true,
        'scoring': isScoring
      })}>
        {(isScoring) && (
          <FlyupNavForScoring
            flyoutSide={internalFlyoutSide}
            isActivity={isActivity}
            lessonElementId={lessonManager.currentLessonElementId} />
        )}
        <div className='flyout-panel'>
          {(FlyoutTabs.ALIGN === openState.tab) &&
            <AlignmentPanel lessonElementId={lessonElementId} />}
          {(FlyoutTabs.RUBRIC === openState.tab && mode !== LessonMode.SCORING) &&
            <RubricPanel lessonElementId={lessonElementId} />}
          {(FlyoutTabs.SCORE === openState.tab && isStudent) &&
            <StudentScorePanel lessonElementId={lessonElementId} />}
          {/* eslint-disable-next-line no-mixed-operators */}
          {((FlyoutTabs.SCORE === openState.tab && isTeacher) ||
              // eslint-disable-next-line no-mixed-operators
              FlyoutTabs.SCORE === openState.tab && isTeacher && model.isActivity) &&
              <TeacherScorePanel lessonElementId={lessonElementId} />}
          {(FlyoutTabs.NOTES === openState.tab && isTeacher) &&
            <TeacherNotesPanel lessonElementId={lessonElementId} />}
          {(FlyoutTabs.ENGAGEMENT === openState.tab && showEngagementTab) &&
            <EngagementPanel lessonElementId={lessonElementId} />}
        </div>
      </div>
    </div>
  ) :
    <></>);
});

export default Flyout;
