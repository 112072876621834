import React from 'react';
import RadioButton from '../RadioButton';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import OptionalImage from '../../lessonPlayer/components/OptionalImage';
import AudioFeedback from '../AudioFeedback';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const ChoiceMatrix = ({ model, isManuscriptShort }) => {
  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>True - False</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Question Instructions:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <OptionalImage model={model} />
      <div className='standalone-label'>Answer Choices:</div>
      <ul>
        {model.options.map((option, idx) => {
          if (!option) {
            return null;
          }

          return (
            <li key={idx} className='ckeditor-text answer'>{option}</li>
          );
        })}
      </ul>
      {model.prompts.map((prompt, promptIdx) => {
        return (
          <div key={prompt.id}>
            <div className='label-value-row'>
              <div className='text-area-label'>Question:</div>
              <div className='ckeditor-text text-area'><HtmlComponent htmlStr={prompt.text} /></div>
            </div>
            <div className='matrix-choices'>
              {model.options.map((option, optionIdx) => {
                if (!option) {
                  return null;
                }

                const selected = Number(model.validation.correctAnswers[promptIdx]) === optionIdx;

                return (
                  <React.Fragment key={optionIdx}>
                    <div className='ckeditor-text'>{option}</div>
                    <div className=''>
                      <RadioButton selected={selected} />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <AudioFeedback model={model} />
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default ChoiceMatrix;
