/*
This tool allows the user to change the background and font color of the lesson.
*/

import React, { useContext, useRef } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext } from 'mobx-react';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import { GlobalColorOptions, GlobalColorVariableMap } from '../../../Constants';
import styleService from '../../services/StyleService';
import '../../../css/Tools.scss';
import { register } from '../../../i18n';

const t = register('ToolNames');
const t2 = register('ColorNames');
const t3 = register('AriaLabels');

function GlobalColorPicker() {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  function closeColorPicker() {
    toolbarManager.setIsColorOpen(false);
    toolbarManager.toggleToolOff('Color');
  }

  return (
    <div className='globalColorPickerTool'>
      <ReactModal
        initHeight={600}
        initWidth={500}
        isOpen={true}>
        <div className='modalBody'>
          <div className='tool-header'>
            <div className='title-label basic'>{t('colorPicker')}</div>
            <div aria-label={t3('closedialog')} className='x-close-button' onClick={closeColorPicker} role='button'
              tabIndex='0' />
          </div>
          <div className='tool-body'>
            <p className='instruction-text'>{t('colorPickerInfo')}</p>
            <ColorButtonGrid buttonsPerRow={2} />
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ColorButtonGrid({ buttonsPerRow = 2 }) {
  const colorButtons = Object.entries(GlobalColorOptions).map(([optionName, colorOption]) => <ColorButton key={optionName} colorId={optionName} colorOption={colorOption} optionName={colorOption['humanName']} />);
  const numRows = Math.ceil(colorButtons.length / buttonsPerRow);
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${buttonsPerRow}, 1fr)`,
    gridTemplateRows: `repeat(${numRows}, 1fr)`,
    gridGap: '10px',
    gridAutoRows: 'minmax(auto, auto)',
    gridAutoFlow: 'row dense',
  };

  return (
    <div className='button-grid' style={gridStyle}>
      {colorButtons}
    </div>
  );
}

function ColorButton({ optionName, colorOption, colorId }) {

  const buttonRef = useRef(null);
  useAccessibilityClick(buttonRef, () => {
    console.log('Setting color to', optionName, colorOption);
    // First, we reset all the color overrides.
    styleService.resetAllOverrideVariables();

    // Then we set the color overrides
    // In order to override correctly, we need to iterate over the GlobalColorVariableMap first and check if the key is in the color option
    for (const [key, styleVariables] of Object.entries(GlobalColorVariableMap)) {
      if (colorOption[key]) {
        styleVariables.forEach((styleVar) => styleService.setStyleVar(styleVar, colorOption[key]));
      }
    }
  });

  const colorStyle = {
    backgroundColor: colorOption.background,
    color: colorOption.text,
  };

  return (
    <div ref={buttonRef} className='color-button' style={colorStyle}>
      <p>{t2(colorId)}</p>
    </div>
  );
}

export default GlobalColorPicker;
