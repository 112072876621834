import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import Loader from './Loader';

const FullpageLoader = observer((props) => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  return (
    <div className='loading-content'>
      <Loader />
    </div>
  );
});

export default FullpageLoader;
