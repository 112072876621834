/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Scrubber } from 'react-scrubber';

import Select from 'react-select';

import classNames from 'classnames';

import '../../../css/AudioQuestion.scss';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import { ContentType } from '../../../Constants';

import { register } from '../../../i18n';

import Auth from '../../services/AuthService';

import audioService from '../../services/AudioService';
import dropdownService from '../../services/DropdownService';
import navMenuService from '../../services/NavMenuService';
import utilsService from '../../services/UtilsService';

import useStyleEvents from '../../../hooks/useStyleEvents';

import DirectionsFlyout from '../tabbedFlyout/DirectionsFlyout';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import TranscriptDialog from '../TranscriptDialog';

// const t = register('AudioQuestion');
const t2 = register('AriaLabels');

const AudioQuestion = observer(({ lessonElementId, model, useDirectionsFlyout = true }) => {
  const {
    audioManager,
    lessonManager,
    navMenuManager,
    toolbarManager,
  } = useContext(MobXProviderContext);

  const [endTime, setEndTime] = useState(0);
  const [endTimeDisplay, setEndTimeDisplay] = useState(utilsService.formatTimeMMSS(0));

  const [scrubberValue, setScrubberValue] = useState();

  const [volumeValue, setVolumeValue] = useState(5);

  const [showTranscriptOptions, setShowTranscriptOptions] = useState(false);
  const [transcriptOptions, setTranscriptOptions] = useState();

  const nextRef = useRef();
  const pauseRef = useRef();
  const playRef = useRef();
  const previousRef = useRef();
  const scrubberRef = useRef();
  const scrubberWrapperRef = useRef();
  const transcriptButtonRef = useRef();
  const transcriptSelectRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    if (!audioManager.shouldCallAudioScrubChangeHandler) {
      audioManager.setSelectedSideNavAudioChildLessonElementId(null);
      audioManager.setSideNavAudioProgressValue(null);
    }
    audioManager.setAllowShowDividedAudioQuestions(!!model.showAllChildren);
    audioManager.setIsAudioPlaying(false);
    audioManager.setPreviousAudioTimeProgress(0);
    audioManager.setSelectedAudioTranscriptOption(null);

    const { interval } = listenForSideNavAudioProgressValueChange();

    addEventListeners();

    loadAudioAndTrackOptions();

    setEndTimeDisplay(utilsService.formatTimeMMSS(endTime));

    return () => {
      removeEventListeners();
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonElementId]);

  useEffect(() => () => {
    // on unmount
    audioManager.setIsAudioTranscriptDialogOpen(false);
  }, [audioManager]);

  const listenForSideNavAudioProgressValueChange = () => {
    const interval = setInterval(() => {
      if (audioManager.shouldCallAudioScrubChangeHandler) {
        handleScrubChange(audioManager.sideNavAudioProgressValue);
        audioManager.setShouldCallAudioScrubChangeHandler(false);
      }
    }, 1000);
    return { interval };
  };

  function loadAudioAndTrackOptions() {
    const audioRefCurrent = audioRef.current;
    audioRefCurrent.src = redirectUrl;
    audioRefCurrent.load();

    setOptionsForSubtitlesAndTranscripts();
  }

  function addEventListeners() {
    const audioRefCurrent = audioRef.current;
    audioRefCurrent.addEventListener('loadedmetadata', initializeAudio);
    audioRefCurrent.addEventListener('timeupdate', updateProgress);
    audioRefCurrent.addEventListener('ended', audioEndedActions);
  }

  function removeEventListeners() {
    const audioRefCurrent = audioRef.current;
    if (audioRefCurrent) {
      audioRefCurrent.removeEventListener('loadedmetadata', initializeAudio);
      audioRefCurrent.removeEventListener('timeupdate', updateProgress);
      audioRefCurrent.addEventListener('ended', audioEndedActions);
    }
  }

  async function setOptionsForSubtitlesAndTranscripts() {
    // TODO AudioQuestion currently does not support subtitles
    // if (model.subtitles?.length) {
    //   // set options for subtitles
    //   const _subtitleOptions = [{ value: -1, label: `${t2('off','Off')}` }].concat((model.subtitles || []).map((subtitleObj, index) => {
    //     const remoteUrl = Auth.getResourceUrlByFileName(subtitleObj.remoteFileName);
    //     const trackRef = audioRef.current.getElementsByTagName('track')[index];
    //     trackRef.setAttribute('src', remoteUrl);
    //     return {
    //       label: subtitleObj.label,
    //       original: subtitleObj,
    //       remoteUrl,
    //       value: index
    //     };
    //   }));
    //   setSubtitleOptions(_subtitleOptions);
    // }

    if (model.transcripts?.length) {
      // set options for transcripts
      const _transcriptOptions = [{ value: -1, label: `${t2('off', 'Off')}` }].concat((model.transcripts || []).map((transcriptsObj, index) => {
        const remoteUrl = Auth.getResourceUrlByFileName(transcriptsObj.remoteFileName);
        return {
          label: transcriptsObj.label,
          original: transcriptsObj,
          remoteUrl,
          value: index
        };
      }));

      const hasOnlyOneTranscript = model.transcripts?.length === 1;
      if (hasOnlyOneTranscript) {
        // set 'selected transcript option' to the only one we have
        audioManager.setSelectedAudioTranscriptOption(_transcriptOptions[1]);
      }
      setTranscriptOptions(_transcriptOptions);
    }
  }

  useAccessibilityClick(transcriptButtonRef, (_event) => {
    const hasOnlyOneTranscript = model.transcripts?.length === 1;
    if (hasOnlyOneTranscript) {
      // no need to show transcript options since there is only one
      // i.e. we can immediately toggle transcript dialog on/off without opening a select dropdown first
      audioManager.setIsAudioTranscriptDialogOpen(!audioManager.isAudioTranscriptDialogOpen);
    } else {
      if (showTranscriptOptions) {
        setShowTranscriptOptions(false);
        return;
      }
      setShowTranscriptOptions(true);
      transcriptSelectRef?.current?.focus?.();
    }
  });

  const handleTranscriptChange = (option) => {
    audioManager.setSelectedAudioTranscriptOption(option);

    setShowTranscriptOptions(false);

    const isTranscriptSelected = option?.value >= 0;

    if (!isTranscriptSelected) {
      // ensure transcript dialog is closed since no transcript is selected
      if (audioManager.isAudioTranscriptDialogOpen) {
        audioManager.setIsAudioTranscriptDialogOpen(false);
      }
    } else if (audioManager.isAudioTranscriptDialogOpen) {
      // close for a fraction of a second, then reopen to refresh the transcript text
      audioManager.setIsAudioTranscriptDialogOpen(false);
      setTimeout(() => {
        audioManager.setIsAudioTranscriptDialogOpen(true);
      }, 400);
    } else if (!audioManager.isAudioTranscriptDialogOpen) {
      audioManager.setIsAudioTranscriptDialogOpen(true);
    }
  };

  const lessonElement = lessonManager.getLessonElement(lessonElementId);
  const childElementIds = lessonElement.lessonElementIds;

  let markerValuesEnt = null;
  if (model && model.childShowTimes && model.childIds) {
    markerValuesEnt = Object.entries(model.childShowTimes)
      .filter(([key, _value]) => model.childIds.includes(key))
      .sort((a, b) => a[1] - b[1]);
  }

  const markerValues = [];
  if (markerValuesEnt) {
    for (const mv of markerValuesEnt) {
      markerValues.push(mv[1]);
    }
  }

  const earliestShownQuestion = Math.min(...markerValues);

  let viewedMarkerIndexList = [];

  // eslint-disable-next-line max-len
  let redirectUrl = `${Auth.ecms}/api/redirectToStreamUrl?authKey=${Auth.authKey}&entityId=${model.entityId}&entityTypeId=${model.entityTypeId}&cacheBuster=${(new Date().getTime())}`;

  // function handleScrubStart () {
  //   // placeholder
  // }

  function handleScrubEnd() {
    // placeholder
  }

  const handleVolumeChange = (e) => {
    const volume = parseInt(e.target.value);
    setVolumeValue(volume);
    audioRef.current.volume = volume * 0.1;
  };

  function handleScrubChange(value, { event, /* forceUpdateProgress = false */ } = {}) {
    const currentTime = audioRef?.current?.currentTime;

    // user triggered play/pause via pressing spacebar or enter on focused scrubber container
    if (event?.key === ' ' || event?.key === 'Enter') {
      if (audioManager.isAudioPlaying) {
        handlePauseAudio();
        return;
      } else {
        handlePlayAudio();
        return;
      }
    }

    if (event?.key === 'ArrowLeft' || event?.key === 'ArrowRight') {
      if (audioManager.isAudioPlaying) {
        handlePauseAudio();
      }
      const SECONDS_VARIATION = 1;
      const SECONDS_VARIATION_SHIFT_MULTIPLIER = 8;

      const multiplier = event.shiftKey ? SECONDS_VARIATION_SHIFT_MULTIPLIER : 1;

      if (event.key === 'ArrowLeft') {
        // DECREASE scrub position by `SECONDS_VARIATION`
        const proposedValue = Math.ceil(currentTime) - (SECONDS_VARIATION * multiplier);
        value = proposedValue >= 0 ? proposedValue : 0;
      } else if (event.key === 'ArrowRight') {
        // INCREASE scrub position by `SECONDS_VARIATION`
        const proposedValue = Math.ceil(currentTime) + (SECONDS_VARIATION * multiplier);
        value = proposedValue < endTime ? proposedValue : endTime;
      }
    } else if (event?.key && !value) {
      return;
    }

    if (audioManager.isAudioTranscriptDialogOpen) {
      handleChangeAudioScrubberSeekPositionManually();
    }

    if (isNaN(value)) {
      value = 0;
    }

    audioManager.setPreviousAudioTimeProgress(Math.floor(value));
    setScrubberValue(value);
    audioRef.current.currentTime = value;
    updateProgress(value);
    audioManager.setPreviousAudioTimeProgress(0);
  }

  /**
   * Typically react-scrubber handles changing of the 'audio scrubber seek position' automatically
   * (i.e. by design, it stops 'seeking' on `mousemove` when `mouseup` is triggered).
   *
   * But there is an issue where this does not work automatically when `TranscriptDialog` is open.
   *
   * So the purpose of this method is to manually handle the audio scrubber seek position on change -
   * usable for cases that need it handled manually, such as when `TranscriptDialog` is open.
   */
  function handleChangeAudioScrubberSeekPositionManually(event = undefined) {
    if (event?.type === 'mouseup') {
      scrubberRef.current.handleSeekEnd();
      // eslint-disable-next-line no-useless-return
    } else {
      const isPointerWithinAudioPlayerToolbar = scrubberRef?.current?.state?.hover;
      if (!isPointerWithinAudioPlayerToolbar) {
        scrubberRef.current.handleSeekEnd();
      }
    }
  }

  const handlePauseAudio = () => {
    audioRef.current.pause();
    audioManager.setIsAudioPlaying(false);
  };

  const handlePlayAudio = () => {
    audioRef.current.play();
    audioManager.setIsAudioPlaying(true);
  };

  useAccessibilityClick(playRef, (_e) => {
    const currentTime = audioRef?.current?.currentTime;
    if (currentTime >= model.endTime || currentTime >= Math.floor(audioRef.current.duration)) {
      handleScrubChange(model?.startTime ? model.startTime : 0);
    }
    audioRef.current.play();
    audioManager.setIsAudioPlaying(true);
    // eslint-disable-next-line max-len
    audioManager.setAllowShowDividedAudioQuestions(!model.hideChildrenWhilePlaying && (!!model.showAllChildren || currentTime >= earliestShownQuestion || audioManager.previousAudioTimeProgress !== 0));
  });

  useAccessibilityClick(pauseRef, (_e) => {
    const currentTime = audioRef?.current?.currentTime;

    audioRef.current.pause();
    audioManager.setIsAudioPlaying(false);
    audioManager.setAllowShowDividedAudioQuestions(model.showAllChildren || currentTime >= earliestShownQuestion);
  });

  useAccessibilityClick(nextRef, (e) => {
    navMenuService.nextSlideHandler(e);
  });

  useAccessibilityClick(previousRef, (e) => {
    navMenuService.previousSlideHandler(e);
  });

  useStyleEvents(lessonElementId, `${lessonElementId}-activity-view`);

  const { customDropdownStyles } = dropdownService.getDropdownConfig({
    model,
    shouldHideControl: true,
    shouldHideValueContainer: true
  });

  const customAudioContentLabel = audioService.getCustomAudioLayoutLabel({
    audioLabelPrefix: 'a-content',
    model
  });

  function initializeAudio() {
    if (audioRef && audioRef.current) {
      let audioDuration = Math.floor(audioRef.current.duration);
      if (model && model.endTime) {
        audioDuration = model.endTime;
      }
      setEndTime(audioDuration);
      setEndTimeDisplay(utilsService.formatTimeMMSS(audioDuration));

      if (model && model.startTime) {
        setScrubberValue(model.startTime);
        audioRef.current.currentTime = model.startTime;
      } else {
        setScrubberValue(0);
        audioRef.current.currentTime = 0;
      }
    }
  }

  const updateProgress = (playedSeconds) => {
    const currentTime = (typeof playedSeconds === 'number' ? playedSeconds : undefined) || audioRef?.current?.currentTime || 0;
    if (currentTime === 0 && scrubberValue !== 0) {
      return;
    }
    const progress = Math.floor(currentTime);

    setScrubberValue(progress);

    if (markerValues.includes(progress)) {
      const index = markerValues.indexOf(progress);

      const isStartMarker = currentTime < 0.30;

      const isEndMarker = currentTime > model.endTime - 1;

      // eslint-disable-next-line max-len
      if (!viewedMarkerIndexList.includes(progress) || isStartMarker || isEndMarker || progress !== Math.floor(audioManager.previousAudioTimeProgress)) {
        if (model.pauseAtChildShowTime) {
          audioRef.current.pause();
          audioManager.setIsAudioPlaying(false);
          audioManager.setAllowShowDividedAudioQuestions(true);
          audioManager.setPreviousAudioTimeProgress(progress);
        } else {
          audioManager.setAllowShowDividedAudioQuestions(!model.hideChildrenWhilePlaying || !audioManager.isAudioPlaying);
        }

        const proposedLessonElementId = childElementIds[index];

        if (proposedLessonElementId) {
          const proposedActivityNavigatorLessonElement = navMenuManager.activityNavigatorLessonElementMap.get(proposedLessonElementId);

          const { currentActivityNavigatorLessonElementId } = navMenuManager;

          lessonManager.setIsInteractiveFlyoutOpen(true);

          let lessonElementId;
          if (!proposedActivityNavigatorLessonElement?.groupList?.includes?.(currentActivityNavigatorLessonElementId)) {
            lessonElementId = proposedLessonElementId;
          } else {
            lessonElementId = currentActivityNavigatorLessonElementId;
          }

          navMenuService.navToSlideClickHandler(lessonElementId, {
            shouldReturnIfSameLessonElementId: false,
            sideNavAudioProgressValue: progress
          });
          viewedMarkerIndexList.push(progress);
        }
      }
    }
  };

  function audioEndedActions() {
    viewedMarkerIndexList = [];
  }

  const renderTranscriptButton = () => {
    return (
      <>
        <div ref={transcriptButtonRef} aria-label={t2('transcript')} className='small-button transcript-button' />
        <div className='transcript-placeholder'>
          <div className={classNames('transcript-select', {
            // eslint-disable-next-line quote-props
            'showTranscriptOptions': showTranscriptOptions
          })}>
            <Select
              ref={transcriptSelectRef}
              aria-label={t2('selectTranscriptLanguage')}
              closeMenuOnSelect={false}
              isSearchable={false}
              menuPlacement='top'
              onChange={handleTranscriptChange}
              openMenuOnFocus={true}
              options={transcriptOptions}
              styles={customDropdownStyles}
              value={audioManager.selectedAudioTranscriptOption} />
          </div>
        </div>
      </>
    );
  };

  const isStandaloneAudioResource = model.type === ContentType.AUDIO.type;

  const AUDIO_NAV_BUTTONS_MIN_WIDTH = 230;
  const TRANSCRIPT_BUTTON_WIDTH_OFFSET = 22;

  let audioNavButtonsMinWidth = AUDIO_NAV_BUTTONS_MIN_WIDTH;
  if (model.resourceItem?.transcripts?.length) {
    audioNavButtonsMinWidth += TRANSCRIPT_BUTTON_WIDTH_OFFSET;
  }

  const backgroundLayout = model.backgroundImageLayout;

  // NOTE: the properties `model.hasTranscript`, `model.transcript` and `model.transcriptLayout` are technically referring to a passage/article.
  // i.e. they are NOT referring to the transcripts we show in TranscriptDialog.
  // ---
  // we refer to them as `hasPassage`, `passage` and `passageLayout` here to help avoid confusion.
  const hasPassage = model.hasTranscript;
  const passage = hasPassage ? model.transcript : undefined;
  const passageLayout = hasPassage && passage ? model.transcriptLayout : undefined;

  return (
    <>
      <div className={classNames('a-content', customAudioContentLabel)} id={`${lessonElementId}-activity-view`}>
        {(model.text && useDirectionsFlyout) ? (
          <DirectionsFlyout directions={model.text} isActivity={true} lessonElementId={model.lessonElementId} />
        ) : ''}
        {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
        <audio ref={audioRef}
          className={classNames({
            'standalone-audio-resource': isStandaloneAudioResource,
            'standalone-audio-resource-no-directions': isStandaloneAudioResource && !model.text
          })}
          crossOrigin='anonymous'
          id='question-audio' onClick={() => {
            if (audioManager.isAudioPlaying) {
              pauseRef.current.click();
            } else {
              playRef.current.click();
            }
          }} />
        {hasPassage ? (
          <div aria-label={t2('passage')} className={classNames(`audio-passage ${passageLayout}`)} tabIndex={0}>
            <HtmlComponent htmlStr={passage} tabIndex={0} />
          </div>
        ) : null}
        {model.imageSource ? (
          <div className={classNames('background-image', backgroundLayout, backgroundLayout === 'auto' && model.horizontalAlign)}
            style={{
              backgroundImage: `url(${model.imageSource})`,
              backgroundSize: backgroundLayout === 'manual' && model.backgroundSize
            }} />
        ) : null}
        {!model.imageSource && !passage ? (
          <div className={classNames('question-audio-play-img', customAudioContentLabel, {
            'standalone-audio-resource': isStandaloneAudioResource
          })}
          // eslint-disable-next-line react/jsx-indent-props
          onClick={() => {
            audioManager.isAudioPlaying ? pauseRef.current.click() : playRef.current.click();
          }} />
        )
          : null}
        <div className={`audio-and-controls-container audio-and-controls-container-${lessonManager.playerMode}`}>
          <div className='audio-control-row'>
            <div className='button-wrapper'>
              <div className='nav-buttons' style={{ minWidth: audioNavButtonsMinWidth }}>
                {(audioManager.isAudioPlaying) ?
                  <button ref={pauseRef} aria-label={t2('pause')} className='pauseButton controlIcon' type='button' />
                  :
                  <button ref={playRef} aria-label={t2('play')} className='playButton controlIcon' type='button' />}

                <div aria-label={t2('volume')} className='volumeIcon' />
                <input aria-label={t2('volumeControl')}
                  className='volumeControl'
                  max={10}
                  min={0}
                  onChange={handleVolumeChange}
                  type='range'
                  value={volumeValue} />
                {!!model.transcripts?.length && renderTranscriptButton()}
              </div>
              <div className='time-display'>{utilsService.formatTimeMMSS(scrubberValue)}{' / '}{endTimeDisplay}</div>
            </div>
            <div ref={scrubberWrapperRef}
              className='scrubber-container'
              onKeyDown={(event) => handleScrubChange(undefined, { event })}
              onMouseUp={(event) => {
                if (audioManager.isAudioTranscriptDialogOpen) {
                  handleChangeAudioScrubberSeekPositionManually(event);
                }
              }}
              style={{ height: '40px', width: '100%' }}
              tabIndex={0}>
              <Scrubber
                ref={scrubberRef}
                markers={markerValues}
                max={(endTime > 0 ? endTime : model.endTime)/* + (isStandaloneAudioResource ? 0 : 1) */}
                min={(model.startTime) ? model.startTime : 0}
                onScrubChange={handleScrubChange}
                onScrubEnd={handleScrubEnd}
                onScrubStart={handleScrubChange}
                value={scrubberValue} />
            </div>
          </div>
        </div>

      </div>
      {audioManager.isAudioTranscriptDialogOpen && (
        <TranscriptDialog
          isOpen={audioManager.isAudioTranscriptDialogOpen}
          model={model}
          setIsOpen={audioManager.setIsAudioTranscriptDialogOpen} />
      )}
    </>
  );
});
export default AudioQuestion;
