/* eslint-disable array-callback-return */
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { LessonMode } from '../../Constants';
import lessonManager from '../managers/LessonManager';
import FreeGraphService from './FreeGraphService';
import Utils from './UtilsService';
import drawManager from '../managers/DrawManager';
import graphPaperManager from '../managers/GraphPaperManager';
import responseService from './ResponseService';

// From fabric.js docs. Fixes issue with strokeUniform not working when resizing. (CF-3180)
fabric.Object.prototype.noScaleCache = false;
export default class DrawService extends FreeGraphService {
  constructor(model, drawBrushColorMenuRef) {
    super();
    this.model = model;
    this.drawBrushColorMenuRef = drawBrushColorMenuRef;
    this.state = [];
    this.stateIndex = -1;
    this.graphVisible = false;
    this.textManager = drawManager;
    if (this.model.id === 'graphTool') {
      this.textManager = graphPaperManager;
    }
  }

  model;
  drawCanvas;

  currentStrokeColor = '#000';
  currentFillColor = 'transparent';

  BOX_WIDTH = 120;
  BOX_HEIGHT = 80;
  BOX_TOP_MARGIN = 40;
  TRIANGLE = 'triangle';
  SQUARE = 'square';
  CIRCLE = 'circle';
  STAR = 'star';

  type = 'Draw';

  getLabel = () => {
    return 'draw';
  }

  isAutoScore = () => {
    return false;
  }

  isOrganizer = () => {
    return false;
  }

  isTestItem = () => {
    return true;
  }

  getCanvasId = () => {
    return `${this.getId() }_canvas`;
  }

  initialize = () => {
    // const settings = {
    //   height: this.getHeight(),
    //   width: this.model.graphWidth,
    // };
    // this.drawCanvas = new fabric.Canvas(this.getCanvasId(), settings);
    // this.setCanvas(this.drawCanvas);

    this.setDefaultEditMode();
    this.initializeGraph();

    if (!this.model.imageSource) {
      this.model.imageSource = null;
      // this.$get().addClass('noImage');
    }

    if (!this.model.hasGraph) {
      this.model.hasGraph = false;
    }

    if (!this.model.imageTitle) {
      this.model.imageTitle = '';
    }

    if (!this.model.altText) {
      if (this.model.imageTitle) {
        const altText = Utils.stripTagsAndEntities(this.model.imageTitle) || '';
        this.model.altText = altText;
      } else {
        this.model.altText = '';
      }
    }

    if (typeof this.model.snapToGrid === 'undefined') {
      this.model.snapToGrid = false;
    }
    if (typeof this.model.tolerance === 'undefined') {
      this.model.tolerance = 100;
    }
    if (typeof this.model.hasAxes === 'undefined') {
      this.model.hasAxes = true;
    }

    this.currentStrokeWidth = 1;
    this.currentStrokeColor = '#000';
    this.currentFillColor = 'transparent';
    this.model.responseFormat = (this.model.responseFormat) ? this.model.responseFormat : 'html';
    this.model.toolbarType = (this.model.toolbarType) ? this.model.toolbarType : 'basic';
  }

  setStrokeColor = (color) => {
    this.currentStrokeColor = color;

    const activeObject = this.drawCanvas.getActiveObject();
    if (activeObject) {
      activeObject.stroke = color;
      activeObject.dirty = true;
      this.drawCanvas.renderAll();
      this.updateState();
      this.updateResponseModel();
    } else {
      const groupElements = this.findCanvasElementGroups();
      groupElements.forEach((groupElement) => {
        if (groupElement.isSelected) {
          this.colorGroupElement(groupElement, color);
          this.updateState();
          this.updateResponseModel();
        }
      });
    }

    // workaround to force the color change of the current tool - recreate the fabric pencil or paint tool
    const tool = this.getSelectedToolbarButton();
    if (tool === 'pencil') {
      this.onClickPencilTool();
    } else if (tool === 'paint') {
      this.onClickPaintTool();
    }
  }

  setFillColor = (color) => {
    this.currentFillColor = color;

    const activeObject = this.drawCanvas.getActiveObject();
    if (activeObject) {
      activeObject.fill = color;
      activeObject.dirty = true;
      this.drawCanvas.renderAll();
      this.updateState();
      this.updateResponseModel();
    }
  }

  setStrokeWidth = (value) => {
    this.setBrushWidth(value);
    this.currentStrokeWidth = value;

    const activeObject = this.drawCanvas.getActiveObject();
    if (activeObject) {
      activeObject.strokeWidth = value;
      activeObject.dirty = true;
      this.drawCanvas.renderAll();
      this.updateState();
      this.updateResponseModel();
    }
  };

  getId = () => {
    return this.model.id;
  }

  getImageTitleId = () => {
    return `${this.getId() }_imageLabelTitle`;
  };

  getCanvasContainer = () => {
    // return this.$get('.canvasContainer');
    return document.getElementById('canvasContainer');
  };

  getDrawCanvasContainer = () => {
    // return this.$get('.drawCanvasContainer');
    return document.getElementById('drawCanvasContainer');
  };

  getDrawCanvasId = () => {
    return `${this.getId() }_drawCanvas`;
  };

  deselectAllDrawObjects = () => {
    const self = this;
    const objects = this.drawCanvas.getObjects();

    if (objects.length > 0) {
      objects.map((object, index) => {
        if (object && object.active) {
          object.set({ active: false });
        }
      });
      self.drawCanvas.renderAll();
    }
  }

  selectFirstDrawObject = () => {
    const self = this;
    const objects = this.drawCanvas.getObjects();

    if (objects.length > 0) {
      objects[0].set({ active: true });
      // objects[0].setControlsVisibility({});

      self.drawCanvas.renderAll();
    }
  }

  deleteDrawObjects = () => {
    // const activeObjects = this.getActiveObjects();
    const activeObjects = this.drawCanvas.getActiveObjects();
    // const objTypes = activeObjects.map((obj) => obj.get('type'));
    // console.log(objTypes.join());
    if (activeObjects.length > 0) {
      activeObjects.forEach((object) => {
        this.drawCanvas.remove(object);
      });
      this.drawCanvas.discardActiveObject().renderAll();
    }
  }

  /*
   * Clone shapes and paths with this method rather than using fabric.util.object.clone or lodash.cloneDeep. When cloned with those
   * other methods, resizing the shapes with the handles causes the clones to resize too when clicking on the canvas afterwards.
   * This is possibly a bug in fabric, but not sure.  This clone method fixes the problem.
   */
  cloneShape = (shape) => {
    let clonedShape = null;

    const type = shape.get('type');
    if (type === 'triangle') {
      clonedShape = new fabric.Triangle({
        width: shape.width,
        height: shape.height,
      });
    } else if (type === 'rect') {
      clonedShape = new fabric.Rect({
        width: shape.width,
        height: shape.height,
      });
    } else if (type === 'circle') {
      clonedShape = new fabric.Circle({
        radius: shape.radius,
        originX: shape.originX,
        originY: shape.originY,
      });
    } else if (type === 'polygon') {
      const starPoints = this.getStarPoints(5, 45, 20);
      clonedShape = new fabric.Polygon(starPoints, {
      }, false);
    } else if (type === 'path') {
      clonedShape = new fabric.Path(
        shape.path,
      );
    }

    if (clonedShape) {
      clonedShape.top = shape.top;
      clonedShape.left = shape.left;
      clonedShape.stroke = shape.stroke;
      clonedShape.fill = shape.fill;
      clonedShape.strokeWidth = shape.strokeWidth;
      clonedShape.strokeUniform = shape.strokeUniform;
      clonedShape.scaleX = shape.scaleX;
      clonedShape.scaleY = shape.scaleY;
      clonedShape.angle = shape.angle;
    }
    return clonedShape;
  }

  copyDrawObjects = () => {
    // const activeObjects = this.getActiveObjects();
    const activeObjects = this.drawCanvas.getActiveObjects();
    if (activeObjects.length > 0) {
      activeObjects.forEach((object) => {
        const type = object.get('type');
        let copy;
        if (type === 'triangle' || type === 'rect' || type === 'circle' || type === 'polygon' || type === 'path') {
          // if a shape or path, clone with cloneShape - see method description for reasoning
          copy = this.cloneShape(object);
        } else {
          // it must be a SprayBrush - use fabric clone which has bugs when copies are resized but don't know how to
          // duplicate it any other way
          const { clone } = fabric.util.object;
          copy = clone(object);
        }
        copy.left += 10;
        copy.top += 10;
        this.drawCanvas.add(copy);
      });
      this.drawCanvas.discardActiveObject().renderAll();
    }
  }

  setDefaultEditMode = () => {
    this.setElementEditMode(this.DRAW);
  }

  setElementEditMode = (elementType) => {
    const isDrawEditMode = elementType === this.DRAW;
    this.elementEditMode = elementType;

    // TODO: fix this
    // this.$get().attr("data-draw-mode", this.elementEditMode);

    if (isDrawEditMode) {
      this.getCanvasContainer().style.zIndex = '0';
      this.getDrawCanvasContainer().style.zIndex = '1';
      this.setDrawingMode(true, 'pencil');
    } else {
      this.getCanvasContainer().style.zIndex = '1';
      this.getDrawCanvasContainer().style.zIndex = '0';
    }
  }

  drawResponse = () => {
    const self = this;
    const responseModel = this.getResponseModel();

    if (responseModel.elements) {
      responseModel.elements.forEach((elementModel) => {
        if (elementModel.elementType === this.POINT) {
          self.drawPoint(elementModel.point, { fill: elementModel.fill, stroke: elementModel.stroke, strokeWidth: elementModel.strokeWidth });
        } else {
          self.addGraphElement(elementModel.points.startPoint, elementModel.points.endPoint, elementModel.color, elementModel.dashed, elementModel.startPointType, elementModel.endPointType,
            elementModel.elementType);
        }
      });
    }
  }

  /**
   * Returns the canvas JSON.
   */
  getDrawCanvasJson = () => {
    let json = {};
    if (this.drawCanvas != null) {
      json = this.drawCanvas.toDatalessJSON(['strokeColor', 'isSpray']);
    }
    return json;
  };

  updateResponseModel = () => {
    const responseModel = this.getResponseModel();
    responseModel.drawing = this.getDrawCanvasJson();
    responseModel.elements = this.getElementModels();
    responseModel.text = this.getTextModels();
    this.setResponseModel(responseModel);
  };

  getTextModels = () => {
    const textModels = [];

    this.textManager.textContainers.map((textContainer) => {
      const { textId } = textContainer;
      const { text } = textContainer;
      const { left } = textContainer;
      const { top } = textContainer;

      textModels.push({ id: textId, text, position: { left, top } });
    });

    return textModels;
  }

  showResponse = (callback, settings) => {
    const responseModel = this.getResponseModel();
    this.clear();
    this.drawResponse(); // show graph elements.
    settings = settings || {};

    if (responseModel.drawing) {
      this.setDrawCanvasFromJson(responseModel.drawing);
    }

    if (!settings.skipInitializeState) {
      this.textManager.initializeTextContainers(responseModel.text);
      this.initializeState();
    }

    if (callback) {
      callback();
    }
  }

  createCanvas = () => {
    const width = this.getWidth();
    const height = this.getHeight();
    const settings = {
      width,
      height,
    };
    this.canvas = new fabric.Canvas(this.getCanvasId(), settings);
  }

  createDrawCanvas = () => {
    const width = this.getWidth();
    const height = this.getHeight();
    const settings = {
      width,
      height,
    };

    this.drawCanvas = new fabric.Canvas(this.getDrawCanvasId(), settings);

    this.drawCanvas.off('mouse:down');
    this.drawCanvas.on('mouse:down', (evt) => {
      // if (utils.isMobile()) {
      //   $("body").addClass("noContentScroll");
      // }

      // If no target of click, then switch to the graph canvas.
      if (!evt.target && !this.drawCanvas.isDrawingMode) {
        this.setElementEditMode('');
      }
    });

    this.drawCanvas.off('mouse:up');
    this.drawCanvas.on('mouse:up', (evt) => {
      // if (utils.isMobile())
      // {
      //   $("body").removeClass("noContentScroll");
      // }
      this.updateState();
      this.updateResponseModel();

      const activeObject = this.drawCanvas.getActiveObject();

      if (activeObject) {
        // Set stroke and fill menu values to match selected object.
        if (activeObject.isSpray) {
          this.setStrokeMenuValue(activeObject.strokeColor);
        } else {
          this.setStrokeMenuValue(activeObject.stroke);
        }
        if (!activeObject.isSpray) {
          this.setFillMenuValue(activeObject.fill);
        }

        // this.setStrokeWidthMenuValue(width);
      }
    });
  }

  setBrushWidth = (width) => {
    this.drawCanvas.freeDrawingBrush.width = width;
  }

  addLine = (value) => {
    const { model } = this;
    const yMidValue = (model.yAxisMaxValue - model.yAxisMinValue) / 2;
    const yStart = parseInt(yMidValue - 2 * model.yAxisStepValue);
    const yEnd = parseInt(yMidValue + 2 * model.yAxisStepValue);

    const xMidValue = (model.xAxisMaxValue - model.xAxisMinValue) / 2;
    const xStart = parseInt(xMidValue - 2 * model.xAxisStepValue);
    const xEnd = parseInt(xMidValue + 2 * model.xAxisStepValue);

    this.updateResponseModel();
    const responseModel = this.getResponseModel();
    let elementType = value;

    const dashedIndex = elementType.indexOf('-dashed');
    let isDashed = false;
    if (dashedIndex > -1) {
      isDashed = true;
      elementType = elementType.substring(0, dashedIndex);
    }

    const bothHiddenIndex = elementType.indexOf('-hidden-hidden');
    let startPointType = this.CLOSED;
    let endPointType = this.CLOSED;
    if (bothHiddenIndex > -1) {
      startPointType = this.HIDDEN;
      endPointType = this.HIDDEN;
      elementType = elementType.substring(0, bothHiddenIndex);
    }

    const startOpenIndex = elementType.indexOf('-open-hidden');
    if (startOpenIndex > -1) {
      startPointType = this.OPEN;
      endPointType = this.HIDDEN;
      elementType = elementType.substring(0, startOpenIndex);
    }

    responseModel.elements.push({
      elementType,
      points: { startPoint: { x: xStart, y: yStart }, endPoint: { x: xEnd, y: yEnd } },
      dashed: isDashed,
      startPointType,
      endPointType,
      color: this.currentStrokeColor
    });

    this.showResponse(() => {}, { skipInitializeState: true });
    this.setElementEditMode('');
    this.selectToolbarButton('lineToolButton');
    // lineMenu.select(-1);
    // self.initializeLineMenu();
    this.updateState();
  }

  /**
    @param pointType: "closed" or "open"
   */
  addPoint = (pointType) => {
    const { model } = this;
    const yMidValue = (model.yAxisMaxValue - model.yAxisMinValue) / 2;
    const y = parseInt(yMidValue);

    const xMidValue = (model.xAxisMaxValue - model.xAxisMinValue) / 2;
    const x = parseInt(xMidValue);

    this.setElementEditMode(this.POINT);
    this.setDrawingMode(true);
    this.selectToolbarButton('pointToolButton');

    this.updateResponseModel();
    const responseModel = this.getResponseModel();

    // let stroke = this.getStrokeMenuValue();
    let stroke = this.currentStrokeColor;
    let fill = this.POINT_COLOR;
    let strokeWidth = 0;

    if (pointType === 'open') {
      fill = '#fff';
      stroke = this.POINT_COLOR;
      strokeWidth = 1;
    }

    responseModel.elements.push({ elementType: this.POINT, point: { x, y }, fill, stroke, strokeWidth });

    this.showResponse(() => {}, { skipInitializeState: true });

    this.setElementEditMode('');
    this.updateState();
  }

  addShape = (type) => {
    let shape = null;

    const top = 200;
    const left = 200;
    const randomX = 50 * Math.random();
    const randomY = 50 * Math.random();

    if (type === this.TRIANGLE) {
      shape = new fabric.Triangle({
        width: 80,
        height: 80,
      });
    } else if (type === this.SQUARE) {
      shape = new fabric.Rect({
        width: 80,
        height: 80,
      });
    } else if (type === this.CIRCLE) {
      shape = new fabric.Circle({
        radius: 40,
        originX: 'center',
        originY: 'center',
      });
    } else if (type === this.STAR) {
      const starPoints = this.getStarPoints(5, 45, 20);
      shape = new fabric.Polygon(starPoints, {
      }, false);
    }

    if (shape) {
      shape.top = top + randomY;
      shape.left = left + randomX;
      shape.stroke = this.currentStrokeColor;
      shape.fill = this.currentFillColor;
      shape.strokeWidth = this.currentStrokeWidth;
      shape.strokeUniform = true;
      this.drawCanvas.add(shape);
      this.drawCanvas.renderAll();

      this.setElementEditMode(this.DRAW);
      this.setDrawingMode(false);
      this.selectToolbarButton('shapeToolButton');

      this.drawCanvas.setActiveObject(shape);
      this.drawCanvas.renderAll();

      this.updateState();
      this.updateResponseModel();
    }
  }

  // get the vertices of a hexagon with a radius of 30
  getStarPoints = (spikeCount, outerRadius, innerRadius) => {
    const rot = Math.PI / 2 * 3;
    const cx = outerRadius;
    const cy = outerRadius;
    const sweep = Math.PI / spikeCount;
    const points = [];
    let angle = rot;

    for (let i = 0; i < spikeCount; i++) {
      let x = cx + Math.cos(angle) * outerRadius;
      let y = cy + Math.sin(angle) * outerRadius;
      points.push({ x, y });
      angle += sweep;

      x = cx + Math.cos(angle) * innerRadius;
      y = cy + Math.sin(angle) * innerRadius;
      points.push({ x, y });
      angle += sweep;
    }
    return (points);
  }

  setDrawCanvasFromJson = (json) => {
    const canvas = this.drawCanvas;
    this.drawCanvas.loadFromJSON(json, () => {
      canvas.renderAll();
    });
  }

  onGraphClickNoTarget = () => {
    this.deselectAllElements();
    this.setElementEditMode(this.DRAW);
    this.setDrawingMode(false);
  }

  deleteFromDraw = () => {
    this.deleteDrawObjects();
    this.deleteSelectedElement();
    this.updateState();
    this.updateResponseModel();
  }

  copy = () => {
    this.copyDrawObjects();
    this.copySelectedElements();
  }

  select = () => {
    this.setElementEditMode(this.DRAW);
    this.setDrawingMode(false);
    this.selectToolbarButton('selectToolButton');
  }

  createRuntimeEvents = () => {
    const self = this;
    // var model = this.getModel();
    // const responseModel = self.getResponseModel();

    self.drawCanvas.on(
      // selection:created not working in our version of fabricjs
      'selection:created', () => {
        // console.log('drawCanvas selection created');
        // self.setAllObjectControlsOff();
      },
      'path:created', () => {
        // console.log('drawCanvas path created');
        self.updateState();
      },
      'object:modified', () => {
        // console.log('drawCanvas object modified');
        self.updateState();
      },
      'object:added', () => {
        // console.log('drawCanvas object added');
        self.updateState();
      });
  }

  onClickPencilTool = (event, ui) => {
    this.setElementEditMode(this.DRAW);
    this.setDrawingMode(true, 'pencil');
    this.selectToolbarButton('pencilToolButton');
    this.drawCanvas.freeDrawingBrush = new fabric.PencilBrush(this.drawCanvas);
    this.drawCanvas.freeDrawingBrush.isSpray = false;

    this.drawCanvas.freeDrawingBrush.width = this.currentStrokeWidth;
    this.drawCanvas.freeDrawingBrush.color = this.currentStrokeColor;
    this.drawCanvas.freeDrawingBrush.fill = this.currentFillColor;
  };

  onClickPaintTool = (event, ui) => {
    this.setElementEditMode(this.DRAW);
    this.setDrawingMode(true, 'paint');
    this.selectToolbarButton('paintToolButton');
    this.drawCanvas.freeDrawingBrush = new fabric.SprayBrush(this.drawCanvas);
    this.drawCanvas.freeDrawingBrush.isSpray = true;

    this.drawCanvas.freeDrawingBrush.width = this.currentStrokeWidth;
    this.drawCanvas.freeDrawingBrush.color = this.currentStrokeColor;
    this.drawCanvas.freeDrawingBrush.fill = this.currentFillColor;
  };

  onClickTextTool = (event, ui) => {
    const textId = uuidv4();

    let top = parseInt(this.model.graphHeight / 3 - 20);
    let left = parseInt(this.model.graphWidth / 2 - 40);
    left += 50 * Math.random();
    top += 50 * Math.random();

    const textContainer = {
      textId,
      top,
      left,
      text: '',
      opacity: 1,
    };

    this.textManager.addTextContainer(textContainer);

    this.updateState();
    this.updateResponseModel();
  };

  setStrokeMenuValue = (val) => {
    // var $brushColorMenu = this.$get().find(".drawBrushColorMenu");
    //
    // if (val === "rgb(0, 0, 0)")
    // {
    //   val = "#000000";
    // }
    // $brushColorMenu.spectrum('set',val);
  }

  setFillMenuValue = (val) => {
    // var $fillColorMenu = this.$get().find(".fillColorMenu");
    //
    // if (val === "rgb(0, 0, 0)")
    // {
    //   val = "#000000";
    // }
    //
    // $fillColorMenu.spectrum('set',val);
  }

  setStrokeWidthMenuValue = (val) => {
    // var $drawBrushSizeMenu = this.$get(".drawBrushSizeMenu").first();
    // var drawBrushMenu = $drawBrushSizeMenu.data("kendoDropDownList");
    //
    // if (drawBrushMenu)
    // {
    //   drawBrushMenu.val(val);
    // }
  }

  setDrawingMode = (drawMode, tool) => {
    if (this.drawCanvas) {
      if (typeof drawMode === 'undefined') {
        drawMode = this.drawCanvas.isDrawingMode;
      }
      this.drawCanvas.isDrawingMode = (lessonManager.isReadOnly) ? false : drawMode;

      if (this.drawCanvas.isDrawingMode) {
        this.deselectAllDrawObjects();
      } else {
        this.selectFirstDrawObject();
      }

      this.drawCanvas.renderAll();
    }
  }

  selectToolbarButton = (button) => {
    if (this.drawCanvas) {
      const toolbarButtons = document.getElementsByClassName('drawToolbarButton');
      Array.from(toolbarButtons).forEach((button) => button.classList.remove('drawToolbarButtonSelected'));

      const selectedButton = document.getElementsByClassName(button)[0];
      selectedButton.classList.add('drawToolbarButtonSelected');
    }
  }

  getSelectedToolbarButton = () => {
    if (this.drawCanvas) {
      const toolbarButtons = document.getElementsByClassName('drawToolbarButtonSelected');
      const selectedButton = toolbarButtons[0];
      return selectedButton?.dataset?.tool;
    }
  }

  runtimeObjectReady = () => {
    // const self = this;
    // var model = this.getModel();

    this.model.questionText = this.model.questionText || this.model.directions;
    this.model.directions = this.model.questionText || this.model.directions;

    this.setDefaultEditMode();
    this.createCanvas();
    this.createDrawCanvas();
    this.createRuntimeEvents();
    this.drawGraph();
    this.createGraphControls();

    this.setGraphVisible(this.model.hasGraph, true);

    this.drawCanvas.isDrawingMode = !(lessonManager.isReadOnly);
    this.setDrawingMode(true, 'pencil');
    this.selectToolbarButton('pencilToolButton');
  }

  getCurrentStateModel = () => {
    const currentStateModel = {};
    currentStateModel.drawing = cloneDeep(this.getDrawCanvasJson());
    currentStateModel.elements = cloneDeep(this.getElementModels());
    currentStateModel.text = cloneDeep(this.getTextModels());
    return currentStateModel;
  };

  initializeState = () => {
    if (!this.state || this.state.length === 0) {
      const currentStateModel = this.getCurrentStateModel();
      this.state = [currentStateModel];
      this.stateIndex = 0;
    }
  }

  updateState = () => {
    const currentStateModel = this.getCurrentStateModel();
    const stateLength = this.state.length;

    if (stateLength - this.stateIndex > 1) {
      this.state.splice(this.stateIndex + 1, stateLength - this.stateIndex - 1);
    }
    this.state.push(currentStateModel);
    this.stateIndex = this.state.length - 1;
  };

  updateResponseModelFromState = (state) => {
    const responseModel = this.getResponseModel();
    responseModel.drawing = cloneDeep(state.drawing);
    responseModel.elements = cloneDeep(state.elements);
    responseModel.text = cloneDeep(state.text);
    this.setResponseModel(responseModel);
    this.textManager.initializeTextContainers(responseModel.text);
  }

  undo = () => {
    if (this.stateIndex > 0) {
      this.stateIndex--;

      if (this.stateIndex >= 0) {
        this.updateResponseModelFromState(this.state[this.stateIndex]);
        // this.setResponseModel(cloneDeep(this.state[this.stateIndex]));
        // this.clearAll(); // clears graph
        this.drawCanvas.clear().renderAll();
        this.showResponse(() => {}, { skipInitializeState: true });
      }
    }
  };

  redo = () => {
    if (this.stateIndex < this.state.length - 1) {
      this.stateIndex++;
      this.updateResponseModelFromState(this.state[this.stateIndex]);
      // this.setResponseModel(cloneDeep(this.state[this.stateIndex]));
      // this.clearAll(); // clears graph
      this.drawCanvas.clear().renderAll();
      this.showResponse(() => {}, { skipInitializeState: true });
    }
  };

  getResponseModel = () => {
    let responseModel;
    if (this.model.id === 'graphTool') {
      responseModel = graphPaperManager.getResponseModel();
    } else {
      responseModel = responseService.getResponseModel(this.model.lessonElementId);
    }
    return (responseModel) || {};
  }

  setResponseModel = (responseModel) => {
    if (this.model.id === 'graphTool') {
      graphPaperManager.setResponseModel(responseModel);
    } else {
      responseService.responseChangeHandler(responseModel, this.model.lessonElementId);
    }
  }
}
