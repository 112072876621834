import React from 'react';
import { observer } from 'mobx-react';

import '../../../css/AudioResource.scss';

import styleService from '../../services/StyleService';

import HtmlComponent from '../HtmlComponent';
import AudioQuestion from './AudioQuestion';

const AudioResource = observer(({ lessonElementId, model }) => {
  const { getStyleVar } = styleService;

  const directionsPosition = getStyleVar('--theme-audio-resource-directions-content-position');

  return (
    <>
      {(!!model.text && directionsPosition === '"top"') && (
        <div className='directions-content directions-content-top'>
          <HtmlComponent htmlStr={model.text} />
        </div>
      )}
      <div className='audio-content'>
        <AudioQuestion lessonElementId={lessonElementId} model={model} openQuestionSlide={() => {}} useDirectionsFlyout={false} />
      </div>
      {(!!model.text && directionsPosition === '"bottom"') && (
        <div className='directions-content directions-content-bottom'>
          <HtmlComponent htmlStr={model.text} />
        </div>
      )}
    </>
  );
});
export default AudioResource;
