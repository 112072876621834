import { renderToStaticMarkup } from 'react-dom/server';

import parse from 'html-react-parser';

import _ from 'lodash';
import UtilsService from '../services/UtilsService';
import { Behavior } from './Behavior';

import { ContentType, PartialScoringType } from '../../Constants';

import clozeMathService from '../services/ClozeMathService';
import clozeService from '../services/ClozeService';
import htmlService from '../services/HtmlService';
import promptService from '../services/PromptService';
import questionService from '../services/QuestionService';
import scoringService from '../services/ScoringService';
import utilsService from '../services/UtilsService';

export class ClozeBehavior extends Behavior {
  getInitializedResponse = (lessonElementId, model) => {
    const userInputMap = new Map();
    if (model.type === ContentType.CLOZE.type ||
      model.type === ContentType.CLOZE_DRAG_DROP.type) {
      this._initTypingAndDragDrop(model, userInputMap);
    }

    if (model.type === ContentType.CLOZE_MULTICHOICE.type) {
      this._initMultiselect(model, userInputMap);
    }

    if (model.type === ContentType.CLOZE_COMBO.type) {
      this._initCombo(model, userInputMap);
    }

    if (model.type === ContentType.CLOZE_MATH.type) {
      this._initMath(model, userInputMap);
    }

    return {
      ...questionService.generateInitializedQuestionResponse({
        feedbackIconSvgHeight: '15',
        feedbackIconSvgWidth: '15',
        lessonElementId,
        multi: true,
        userInputMap
      })
    };

    /*
    if (Array.isArray(model?.validation?.prompts)) {
      for (const prompt of model?.validation?.prompts) {
        userInputMap.set(prompt.id, {
          dataId: prompt.id,
          text: ''
        });
      }
      return {
        ...questionService.generateInitializedQuestionResponse({
          lessonElementId,
          multi: true,
          userInputMap
        })
      };
    } else {
      return {
        ...questionService.generateInitializedQuestionResponse({
          feedbackIconSvgHeight: '15',
          feedbackIconSvgWidth: '15',
          lessonElementId,
          multi: true,
          userInputMap
        })
      };
    }

    */
  }

  _initTypingAndDragDrop = (model, userInputMap) => {
    model.prompts = [];

    const mathText = (model && !UtilsService.isNullOrEmpty(model.mathText)) ? model.mathText : '';

    const polyfilledClozeBody = htmlService.getPolyfilledHtmlString((model?.body || mathText || ''));

    // eslint-disable-next-line no-unused-vars
    const parsedClozeBody = parse((polyfilledClozeBody || ''), {
      replace: (domNode) => {
        if (domNode.attribs?.['data-id']) {
          const dataId = domNode.attribs['data-id'];
          const rangeType = domNode.attribs['data-range-type'];
          userInputMap.set(dataId, {
            dataId,
            text: ''
          });
          if (model.prompts && _.findIndex(model.prompts, { id: dataId }) < 0) {
            model.prompts.push({ id: dataId, text: '', rangeType });
          }
        }
      }
    });
  }

  _initMultiselect = (model, userInputMap) => {
    model.prompts = [];

    if (model.responseItems) {
      model.responseItems.forEach((item, index) => {
        userInputMap.set(item.promptId, {
          dataId: item.promptId,
          text: ''
        });
        if (model.prompts && _.findIndex(model.prompts, { id: item.promptId }) < 0) {
          model.prompts.push({
            id: item.promptId,
            answers: item.responseOptions,
            dataId: item.promptId,
            text: ''
          });
        }
      });
    }
  }

  _initCombo = (model, userInputMap) => {
    model.prompts = [];

    const mathText = (model && !UtilsService.isNullOrEmpty(model.mathText)) ? model.mathText : '';
    const polyfilledClozeBody = htmlService.getPolyfilledHtmlString((model?.body || mathText || ''));

    // eslint-disable-next-line no-unused-vars
    const parsedClozeBody = parse((polyfilledClozeBody || ''), {
      replace: (domNode) => {
        if (domNode.attribs?.['data-id']) {
          const dataId = domNode.attribs['data-id'];
          const rangeType = domNode.attribs['data-range-type'];
          userInputMap.set(dataId, {
            dataId,
            text: ''
          });

          if (model.prompts && _.findIndex(model.prompts, { id: dataId }) < 0) {
            if (rangeType === 'cloze') {
              model.prompts.push({
                id: dataId,
                text: '',
                rangeType
              });
            } else if (rangeType === 'clozeMultichoice') {
              if (model.responseItems) {
                const item = _.find(model.responseItems, { promptId: dataId });
                if (item) {
                  model.prompts.push({
                    id: item.promptId,
                    answers: item.responseOptions,
                    dataId: item.promptId,
                    text: '',
                    rangeType
                  });
                }
              }
            }
          }
        }
      }
    });
  }

  _initMath = (model, userInputMap) => {
    if (model.prompts) {
      model.prompts.forEach((item, index) => {
        userInputMap.set(item.blankId, {
          dataId: item.blankId,
          text: '',
          answerType: item.answerType
        });
        item.id = item.blankId;
      });
    }
  }

  // TODO commented out so default tryAgainButtonHandler will be used.
  // tryAgainButtonHandler = async ({
  //   /* event, lessonElementId, */
  //   lessonElementState, model
  // } = {}) => {
  //   const isClozeTypingMath = model?.type === ContentType.CLOZE.type && model.responseFormat === 'math';
  //   const isClozeMath = model?.type === ContentType.CLOZE_MATH.type;
  //   if (!isClozeTypingMath && !isClozeMath) {
  //     return;
  //   }
  //   const userInputMap = questionService.updateUserInputMap({
  //     lessonElementState,
  //     model,
  //     promptAnswerTypeToValidateForPreservation: isClozeMath ? 'cloze' : undefined,
  //     shouldPreserveCorrectUserInputs: true
  //   });
  //   if (userInputMap) {
  //     lessonElementState.currentResponseAnswer.userInputMap = userInputMap;
  //   }
  //   return { lessonElementState };
  // }

  checkForValidResponse = (responseAnswer, model) => {
    if (!responseAnswer?.userInputMap) {
      const userInputMap = questionService.updateUserInputMap({ model, responseAnswer });
      const userInputs = Array.from(userInputMap?.values?.() || []);
      if (userInputs.length === 0) {
        return false;
      }
      return userInputs?.some((userInput) => !!userInput.text);
    } else {
      const userInputs = Array.from(responseAnswer?.userInputMap?.values?.() || []);
      if (userInputs.length === 0) {
        return false;
      }
      return userInputs?.some((userInput) => !!userInput.text);
    }
  }

  isAutoScored = (model) => {
    return questionService.isAutoScored(model);
  }

  getScore = (responseAnswer, model) => {
    if (responseAnswer?.userInputMap) {
      let score = promptService.getScoreFromPrompts({ model, responseAnswer });
      score = scoringService.getAdjustedScore(model, score, model?.validation?.prompts?.length, {
        scoringTypeOverride: model.type === ContentType.CLOZE_MATH.type ? PartialScoringType.PARTIAL : undefined
      });
      return score;
    }
  }

  // TODO phase out responseAnswer in favor of `lessonElementState.currentResponseAnswer`
  setResponseData = (data = '', _responseAnswer, model, {
    dataId,
    isCustomBlankSection,
    lessonElementState
  } = {}) => {
    if (!lessonElementState.currentResponseAnswer.userInputMap) {
      questionService.updateUserInputMap({ model, lessonElementState });
    } else if (htmlService.isHtmlResponseFormat(model) || isCustomBlankSection) {
      lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
        dataId,
        text: data
      });
    } else {
      // `responseFormat` is 'text' or 'numeric' and using the native input element
      if (model.responseFormat === 'numeric') {
        if (utilsService.ensureNumericInput(data.target.value)) {
          lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
            dataId,
            text: data?.target?.value
          });
        }
      } else {
        lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
          dataId,
          text: data?.target?.value
        });
      }
    }
  }

  getCorrectAnswersText = (model) => {
    if (model?.validation?.prompts?.length) {
      let text = '';
      let count = 1;
      for (const modelValidationPrompt of model?.validation?.prompts) {
        let answerTextBody = (
          modelValidationPrompt?.answers?.filter((answer) => {
            const isCorrectAnswer = promptService.isUserInputAlwaysCorrectIfFoundInModelPromptAnswers(model, modelValidationPrompt) || answer.isCorrect;
            return isCorrectAnswer;
          }).map((answer, index, correctAnswers) => {
            const lastIndex = correctAnswers.length - 1;
            const shouldIncludeComma = index < lastIndex;

            const polyfilledAnswerText = answer.text ? htmlService.getPolyfilledHtmlString(answer.text) : '';

            return polyfilledAnswerText ? (
              <span key={answer.id}>
                {parse(`${polyfilledAnswerText}${shouldIncludeComma ? ', ' : ''}`)}
              </span>
            ) : '';
          }).filter((answerText) => answerText)
        );
        answerTextBody = answerTextBody.length ? answerTextBody : ['?'];
        text += renderToStaticMarkup(
          <div className='answer-list-row'>
            {model.numberedBlanks && (
              <div className='list-number'>
                {count}
              </div>
            )}
            <div className='correct-icon' />
            <div className='answer-text'>
              <span>
                {answerTextBody}
              </span>
            </div>
          </div>
        );
        ++count;
      }
      return text;
    }
    return '<div></div>';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, _questionIsCorrect, currentAttemptCount, { lessonElementState } = {}) => {
    return questionService.getBehaviorQuestionFeedbackText({
      correctFeedback,
      correctFeedbackBody,
      incorrectFeedback,
      incorrectFeedbackBody,
      mode,
      model,
      questionIsCorrect: this.isQuestionCorrect(lessonElementState),
      lessonElementState,
      currentAttemptCount
    });
  }

  isQuestionCorrect = (lessonElementState, _lessonElementId) => {
    const _isQuestionCorrect = promptService.getQuestionFeedbackCodeForAllPrompts({ lessonElementState });
    return _isQuestionCorrect;
  }

  isAnswerCorrect = (dataId, lessonElementState, model) => {
    const userInputMap = lessonElementState?.currentResponseAnswer.userInputMap || questionService.updateUserInputMap({
      model,
      responseAnswer: lessonElementState.currentResponseAnswer,
      lessonElementState
    });
    const currentUserInput = userInputMap?.get(dataId);
    // Stripping paragraph tags here so we can trim the string.
    const currentUserInputValue = currentUserInput?.text;

    const result = currentUserInput?.isCorrect || promptService.isCorrectPromptAnswerStatus({
      dataId, currentUserInputValue, model
    });
    return result;
  }

  setCorrectAnswer = (lessonElementState, model) => {
    const userInputMap = new Map();
    for (const modelValidationPrompt of (model?.validation?.prompts || [])) {
      let answerText;
      if (promptService.isUserInputAlwaysCorrectIfFoundInModelPromptAnswers(model, modelValidationPrompt)) {
        answerText = modelValidationPrompt?.answers?.[0]?.text || '';
      } else {
        answerText = modelValidationPrompt?.answers?.find((answer) => answer.isCorrect)?.text || '';
      }

      if (answerText && ((model && !utilsService.isNullOrEmpty(model.mathText)) || model?.responseFormat === 'math')) {
        answerText = renderToStaticMarkup(parse(answerText, {
          replace: (domNode) => clozeMathService.appendDataIdToMathDomNodeThenConvertToMencloseIfApplicable(domNode, modelValidationPrompt.id, model)
        }));
      }
      const dataId = modelValidationPrompt?.id;
      userInputMap.set(dataId, {
        dataId,
        text: answerText || (model.mathText && renderToStaticMarkup(
          clozeMathService.renderClozeMathBlankPlaceholder(dataId, model, '?')
        )) || '',
        isCorrect: true
      });
    }
    lessonElementState.setCurrentResponse(utilsService.safeMobxClone({
      userInputMap, lessonElementId: model.lessonElementId
    }));
  }

  resetStudentAnswer = (lessonElementState, _validation) => {
    lessonElementState.setCurrentResponse(utilsService.safeMobxClone(lessonElementState.cachedResponseAnswer));
  }

  renderClozeBody = ({
    lessonElementId, lessonElementState, modalBody,
    modalLeft, modalTop, model, renderBlankSection,
    setModalBody, setModalLeft, setModalTop, noSubmit
  } = {}) => {
    return clozeService.renderClozeBody({
      lessonElementId,
      lessonElementState,
      modalBody,
      modalLeft,
      modalTop,
      model,
      renderBlankSection,
      setModalBody,
      setModalLeft,
      setModalTop,
      noSubmit
    });
  }
}

export default new ClozeBehavior();
