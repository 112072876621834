import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { ContentType, LessonMode } from '../../../Constants';

import navMenuService from '../../services/NavMenuService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import responseService from '../../services/ResponseService';
import questionService from '../../services/QuestionService';
import UtilsService from '../../services/UtilsService';
import ResourceIcon from '../ResourceIcon';

const isActivity = (model, lessonElementId, lessonManager) => {
  const lessonElement = lessonManager.getLessonElement(lessonElementId);
  if (model.isActivity && lessonElement.lessonElementIds && lessonElement.lessonElementIds.length > 0) {
    return true;
  }
  return false;
};

const SummaryCard = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    questionFeedbackManager,
    responseManager,
    videoManager,
    audioManager
  } = useContext(MobXProviderContext);

  const mode = lessonManager.playerMode;
  const model = lessonManager.getSlideModel(lessonElementId);
  const state = responseManager.getLessonElementState(lessonElementId);
  const cardRef = useRef();

  // TODO unused // const status = (studentActivityManager && studentActivityManager.activityInstanceStatus) ? studentActivityManager.activityInstanceStatus : ActivityStatus.ACTIVITY_STARTED;

  const notReady = (!model);
  const isAnsweredSurvey = questionService.isAnsweredSurveyQuestion({
    lessonElementId: model.lessonElementId,
    lessonElementState: state,
    model
  });

  const isSubmitted = !!state?.isSubmitted || isAnsweredSurvey;
  const hasFeedback = questionFeedbackManager.hasFeedback || (mode === LessonMode.REVIEW) || (mode === LessonMode.SCORING);
  const correct = responseManager.correctList.includes(lessonElementId) && hasFeedback;
  const notScored = responseManager.notScoredList.includes(lessonElementId) && hasFeedback;
  const unScored = responseManager.unscoredList.includes(lessonElementId) && hasFeedback;
  const incorrect = responseManager.incorrectList.includes(lessonElementId) && hasFeedback;
  const partially = responseManager.partiallyCorrectList.includes(lessonElementId) && hasFeedback;
  const flagged = responseManager.markedForReviewList.includes(lessonElementId);
  const showTeacherFeedbackIcon = (lessonManager.playerMode === LessonMode.REVIEW &&
    state && state.comment &&
    !UtilsService.isNullOrEmpty(state.comment)
  );

  const isTestItem = (model) ? model.isTestItem || isActivity(model, lessonElementId, lessonManager) : false;
  const isResource = (model) ? model.isResource || model.type === ContentType.WEB_PAGE.type : false;

  const type = (model) ? model.type : null;
  // eslint-disable-next-line eqeqeq
  const isInstruction = (model) ? (model.isInstruction && model.type != ContentType.WEB_PAGE.type) : false;

  let isLocked = (state && state.isDependencyLocked);
  let parentLocked = false;
  if (model && model.isActivityPart) {
    const parentState = responseManager.getLessonElementState(model.parentActivityElementId);
    if (parentState.isDependencyLocked) {
      isLocked = true;
      parentLocked = true;
    }
  }

  useAccessibilityClick(cardRef, async (_event) => {
    if (isLocked) {
      if (parentLocked) {
        const parentState = responseManager.getLessonElementState(model.parentActivityElementId);
        await responseService.alertDependencyLock(parentState);
      } else {
        await responseService.alertDependencyLock(state);
      }
      return;
    }
    const parentModel = lessonManager.getSlideModel(model?.parentActivityElementId);

    if (parentModel?.type === ContentType.VIDEO_QUESTION.type) {
      videoManager.setSideNavVideoProgressValue(model.showTime);
      videoManager.setSelectedSideNavVideoChildLessonElementId(lessonElementId);
      const slides = lessonManager.getFilteredSlides();
      const index = slides.indexOf(lessonElementId);
      lessonManager.setCurrentSlideIndex(index);
      videoManager.setShouldCallVideoScrubChangeHandler(true);
      await navMenuService.navToSlideClickHandler(lessonElementId, { fromCardHandler: true });
    } else if (parentModel?.type === ContentType.AUDIO_QUESTION.type) {
      audioManager.setSideNavAudioProgressValue(model.showTime);
      audioManager.setSelectedSideNavAudioChildLessonElementId(lessonElementId);
      const slides = lessonManager.getFilteredSlides();
      const index = slides.indexOf(lessonElementId);
      lessonManager.setCurrentSlideIndex(index);
      audioManager.setShouldCallAudioScrubChangeHandler(true);
      await navMenuService.navToSlideClickHandler(lessonElementId, { fromCardHandler: true });
    } else {
      await navMenuService.navToSlideClickHandler(lessonElementId, { fromCardHandler: true });
    }
  });

  const renderTestItemCard = () => {
    return (
      <div ref={cardRef} className={classNames({
        'summary-card': true,
        'un-scored': (unScored),
        'not-scored': (notScored),
        'answered': (isSubmitted),
        'is-correct': (correct),
        'is-incorrect': (incorrect),
        'is-partial': (partially),
        'is-flagged': (flagged),
        'is-dependent-locked': (isLocked)
      })}>
        <div className='background-color' />
        <div className='card-info'>
          {(showTeacherFeedbackIcon) && <ResourceIcon altText='Teacher Feedback' className='TeacherFeedback' maskOff={true} />}
          <div className='card-top-row'>
            <div className='summary-card-label'>
              {model.questionNumber}
            </div>
            <div className={classNames({ icons: true, visible: ((flagged || !isSubmitted) && !model.isInstruction) })}>
              {(flagged) && <div className='flagged icon' />}
              {(!isSubmitted) && <div className='not-answered icon' />}
            </div>
          </div>
          {((hasFeedback && isSubmitted) || mode === LessonMode.REVIEW) && (
            <div className='card-bottom-row'>
              {(correct) && <div className='correct icon' />}
              {(incorrect) && <div className='incorrect icon' />}
              {(partially) && <div className='partial icon' />}
              {(notScored) && <div className='not-scored icon' />}
              {(unScored) && <div className='un-scored icon' />}
            </div>
          )}
        </div>

      </div>
    );
  };

  const renderInstructionCard = () => {
    return (
      <div ref={cardRef} className={classNames({
        'summary-card': true,
        'is-instruction': true,
        'has-feedback': (hasFeedback),
        'is-dependent-locked': (isLocked)
      })}>
        <div className='background-color' />
        <div className='card-info'>
          {(showTeacherFeedbackIcon) && <ResourceIcon altText='Teacher Feedback' className='TeacherFeedback' maskOff={true} />}
          <div className='card-top-row'>
            <div className='instruction-wrapper'>
              <div className='instruction-icon' />
            </div>
            <div className={classNames({ icons: true, visible: flagged })}>
              {(flagged) && <div className='flagged icon' />}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderResourceCard = () => {
    return (
      <div ref={cardRef} className={classNames({
        'summary-card': true,
        'has-feedback': hasFeedback,
        'is-resource': true,
        'is-dependent-locked': (isLocked)
      })}>
        <div className='background-color' />
        <div className='card-info'>
          {(showTeacherFeedbackIcon) && <ResourceIcon altText='Teacher Feedback' className='TeacherFeedback' maskOff={true} />}
          <div className='card-top-row'>
            <ResourceIcon altText={ContentType.getLabel(type)} className={type} />
            <div className={classNames({ icons: true, visible: flagged })}>
              {(flagged) && <div className='flagged icon' />}
            </div>
          </div>
        </div>

      </div>
    );
  };

  return (
    <>
      {notReady && <></>}
      {isTestItem && renderTestItemCard()}
      {isInstruction && renderInstructionCard()}
      {isResource && renderResourceCard()}
    </>
  );
});

export default SummaryCard;
