import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../css/LessonProfileSelectDialog.scss';
import ReactModal from 'react-modal-resizable-draggable';
// import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';
import { MobXProviderContext, observer } from 'mobx-react';
import { default as ReactSelect } from 'react-select';
import itemBankService from '../services/ItemBankService';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import Loader from '../../lessonPlayer/components/Loader';

const LessonProfileSelectDialog = observer((props) => {
  const {
    itemBankManager,
    lessonManager
  } = useContext(MobXProviderContext);

  const exitSelectRef = useRef();
  const { lessonOptions } = lessonManager;
  const [isShowingChosenProfile, setIsShowingChosenProfile] = useState(true);
  const isProfilesLoaded = itemBankManager.profilesLoaded;
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    /*
      If a session variable 'chosen-profile-id' has been set, use that profile
      rather than showing the select profile dialog.
    */
    const chosenProfileId = localStorage.getItem('chosen-profile-id');

    setIsShowingChosenProfile(true);

    // Note: Profiles are loaded by contentViewer.
    if (isProfilesLoaded) {
      const getProfileData = async () => {
        itemBankManager.setLessonProfileId(null);
        itemBankManager.setSelectedProfileOption(null);
        await itemBankService.getProfiles();

        if (chosenProfileId) {
          setIsShowingChosenProfile(true);
          const { goToProfile } = props;

          itemBankManager.setLessonProfileId(chosenProfileId);
          goToProfile();
        } else {
          setIsShowingChosenProfile(false);
          setShowContent(true);
        }
      };
      getProfileData();
    }
  }, [lessonOptions, itemBankManager, isProfilesLoaded]);

  useAccessibilityClick(exitSelectRef, (e) => {
    if (itemBankManager.selectedProfileOption && itemBankManager.selectedProfileOption.value) {
      const { goToProfile } = props;
      const selectedProfileId = itemBankManager.selectedProfileOption.value;
      if (selectedProfileId) {
        itemBankManager.setLessonProfileId(selectedProfileId);
        localStorage.setItem('chosen-profile-id', selectedProfileId);
        goToProfile();
      }
    }
  });

  const handleCloseViewer = async () => {
    const { previewSource } = props;
    if (previewSource && previewSource === 'ext') {
      window.close();
    } else {
      itemBankService.setViewMode(itemBankManager.originViewMode);
    }
  };

  const handleChangeProfile = async (selected) => {
    if (selected) {
      itemBankManager.setSelectedProfileOption(selected);
    }
  };

  return isProfilesLoaded && !isShowingChosenProfile ? (
    <div className='profile-select-dialog'>
      {itemBankManager.lessonProfileId === null && ( // Check if value is null
        <ReactModal
          disableMove={true}
          disableResize={true}
          initHeight={300}
          initWidth={600}
          isOpen={!itemBankManager.lessonProfileId} top={50}>
          <div className='dialog-body'>
            <div className='solution-header'>
              <div className='title-label basic'>Preview Item</div>
              <div className='x-close-button mask-image' onClick={() => {
                handleCloseViewer();
              }} />
            </div>
            {itemBankManager.profiles.length > 0 && (
              <div className='profile-picker-wrapper'>
                <div className='label-text'>Select a test template to view this item with.</div>
                <ReactSelect
                  className='profile-select'
                  classNamePrefix='profile-select'
                  closeMenuOnSelect={true}
                  onChange={handleChangeProfile}
                  options={itemBankManager.profiles}
                  placeholder='Select option'
                  value={itemBankManager.selectedProfileOption} />
              </div>
            )}

            {(itemBankManager.profiles.length === 0 && showContent) && (
              <div className='profile-picker-wrapper'>
                <div className='label-text'>There are no profiles available.</div>
                <div className='label-text'> Please add a profile and make it visible on satellite.</div>
              </div>
            )}

            {(itemBankManager.profiles.length === 0 && !showContent) &&
              <div className='dialog-loader'><Loader /></div>}

            {itemBankManager.profiles.length !== 0 && (
              <div className='button-wrapper'>
                <button ref={exitSelectRef} className={(!itemBankManager.selectedProfileOption) ? 'preview-button control-button disabled' : 'preview-button control-button'}>Close</button>
              </div>
            )}
          </div>
        </ReactModal>
      )}
    </div>
  ) : null;
});

export default LessonProfileSelectDialog;
