import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { LessonMode } from '../../../Constants';
import { register } from '../../../i18n';
import OptionalImage from '../OptionalImage';
import RichTextEditor, { ADVANCED_CKEDITOR_TOOLBAR, BASIC_CKEDITOR_TOOLBAR, MATH_TOOLBAR, MATH_WITH_TABLE_TOOLBAR } from '../RichTextEditor';
import Guideline from '../tools/Guideline';
import responseService from '../../services/ResponseService';
import styleService from '../../services/StyleService';
import '../../../css/LongText.scss';
import HtmlComponent from '../HtmlComponent';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const t = register('GlobalQuestionLabels');

const LongText = observer(({ lessonElementId }) => {
  const {
    lessonManager, // eslint-disable-line no-unused-vars
    responseManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const textBody = model.questionText || '';

  let toolbar = null;
  if (model.toolbarType === 'basicToolbar') {
    toolbar = BASIC_CKEDITOR_TOOLBAR;
  } else if (model.toolbarType === 'mathToolbar') {
    toolbar = MATH_TOOLBAR;
  } else if (model.toolbarType === 'advancedToolbar') {
    toolbar = ADVANCED_CKEDITOR_TOOLBAR;
  } else if (model.toolbarType === 'mathWithTableToolbar') {
    toolbar = MATH_WITH_TABLE_TOOLBAR;
  }

  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  if (!lessonElementState) {
    return (<div className='free-response-question' />);
  }

  const readOnly = lessonElementState.readOnly || lessonManager.isReadOnly;

  const { currentResponseAnswer } = lessonElementState;

  const maxLength = parseInt(model.maxLength) || null;
  const maxWords = parseInt(model.maxWordCount) || null;

  useStyleEvents(lessonElementId);

  return (
    <div className='free-response-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='  test-item-question'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={textBody} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='test-item-answers'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) ? (
          <div className='print-line-wrapper'>
            {(lessonManager.isForceTeacher) ? (
              <HtmlComponent htmlStr={model.validation.solution} />
            ) : (
              <>
                <div className='print-line'><HtmlComponent htmlStr={currentResponseAnswer.text} /></div>
                <div className='print-line' />
                <div className='print-line' />
              </>
            )}
          </div>
        ) : (
          <RichTextEditor
            data={currentResponseAnswer.text}
            disabled={readOnly}
            lessonElementId={lessonElementId}
            maxCharacters={maxLength}
            maxWords={maxWords}
            onBlur={() => styleService.resetStyleVar('--ck-z-modal')}
            onChange={(data) => responseService.textChangeHandler(data, lessonElementId)}
            onFocus={() => styleService.setStyleVar('--ck-z-modal', '1')}
            placeHolder={t('enterHere')}
            toolbar={toolbar}
          />
        )}
      </div>
    </div>
  );
});

export default LongText;
