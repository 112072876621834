import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactModal from 'react-modal-resizable-draggable';

import '../../css/ResourceRecommendCartModal.scss';

import { register } from '../../i18n';

import itemBankService from '../services/ItemBankService';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';

import ItemBankCard from './ItemBankCard';

const t = register('ResourceRecommendCartModal');

const ResourceRecommendCartModal = observer(() => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  const cancelRef = useRef();
  const closeRef = useRef();
  const saveRef = useRef();
  const [isAnythingDisabled, setIsAnythingDisabled] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useAccessibilityClick(cancelRef, (_e) => {
    setDisableSave(false);
    itemBankManager.clearRecommendedSelected();
    itemBankManager.clearRecommendedItems();
    if (itemBankManager.isNewAdd) {
      itemBankService.removeItemFromLessonService(itemBankManager.parentRecommendedCartId);
    } else {
      itemBankManager.setResourceRecommendAllSelected(false);
    }
    itemBankManager.toggleResourceRecommendCart();
  });

  // might be a little different than cancel (open question)
  useAccessibilityClick(closeRef, (event) => {
    itemBankManager.clearRecommendedSelected();
    itemBankManager.clearRecommendedItems();
    if (itemBankManager.isNewAdd) {
      itemBankService.removeItemFromLessonService(itemBankManager.parentRecommendedCartId);
    } else {
      itemBankManager.setResourceRecommendAllSelected(false);
    }
    itemBankManager.toggleResourceRecommendCart();
  });

  useAccessibilityClick(saveRef, async (_e) => {
    setDisableSave(true);
    await itemBankService.createLessonElementFromItem();
    itemBankManager.clearRecommendedSelected();
    itemBankManager.clearRecommendedItems();
    itemBankManager.setResourceRecommendAllSelected(false);
    itemBankManager.toggleResourceRecommendCart();
  });

  const handleChange = (e) => {
    const id = e.target.value;
    const isChecked = e.target.checked;
    itemBankManager.changeRecommendedSelected(id, isChecked);
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    itemBankManager.setResourceRecommendAllSelected(isChecked);
  };

  // function closeResources() {
  //   itemBankManager.clearCartRemovals();
  //   itemBankManager.toggleResourceRecommendCart();
  // }

  return (
    <div className='resource-cart'>
      <ReactModal
        disableKeystroke={true}
        disableMove={true}
        disableResize={true}
        initHeight={650}
        initWidth={800}
        isOpen={true}
        onRequestClose={() => {}}>
        <div className='resource-cart-header'>
          <div className='title-label large'>
            {t('selectFromRecommendations')}
          </div>
          <div ref={closeRef}
            aria-label='close dialog'
            className='x-close-button'
            role='button'
            tabIndex='0' />
        </div>
        {(itemBankManager.recommendedItems && itemBankManager.recommendedItems.length > 0) ? (
          <div className='sub-header'>
            <div className='label instructions'>
              {t('recommendedItemsDescription', { recommendedItemsCount: itemBankManager.recommendedItems.length })}
            </div>

            {(isAnythingDisabled) ? (
              <>
                <div className='select-all'><input checked={false} disabled={true} id='all' type='checkbox' /></div>
              </>
            ) : (
              <>
                <div className='select-all'>
                  <input checked={itemBankManager.isResourceRecommendAllSelected} id='all'
                    onChange={handleSelectAll} type='checkbox' />
                </div>
              </>
            )}
            <div className='label checkbox'>
              {t('selectAll')}
            </div>
          </div>
        ) : (
          <div className='sub-header'>
            <div className='label instructions message'>
              {t('noRecommendedItemsDescription')}
            </div>
            {(itemBankManager.isNewAdd) && (
              <div className='label instructions message'>
                {t('newRecommendedItemsDescription')}
              </div>
            )}
          </div>
        )}

        <div className='resource-list-items'>
          {
            (itemBankManager.recommendedItems && itemBankManager.recommendedItems.length > 0) &&
            itemBankManager.recommendedItems.map((item) => {
              const isSelected = itemBankManager.recommendedSelected.includes(item.id);
              const isDisabled = (
                isSelected && !itemBankManager.isRecommendedAddMode && itemBankManager.lessonItemIdArray.includes(item.id)
              );
              if (isDisabled && !isAnythingDisabled) {
                setIsAnythingDisabled(true);
              }
              return (
                <ItemBankCard key={`${item.id}_ibcard`}
                  customChangeHandler={handleChange}
                  isDisabled={isDisabled}
                  isSelected={isSelected}
                  isShopping={true}
                  libraryCardParams={item}
                  skipRemove={true} />
              );
            })
          }
        </div>
        <div className='resource-cart-footer'>
          <div className='button-wrapper'>
            {((itemBankManager.isNewAdd) || (!itemBankManager.isNewAdd && itemBankManager.recommendedItems.length > 0)) && (
              <button ref={saveRef} className='add-items-button control-button' type='button' disabled={disableSave}>
                {t('add')}
              </button>
            )}
            <button ref={cancelRef} className='preview-button control-button' type='button'>
              {t('cancel')}
            </button>
          </div>
        </div>

      </ReactModal>
    </div>
  );
});

export default ResourceRecommendCartModal;
