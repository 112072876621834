import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { debounce } from 'lodash';

import parse from 'html-react-parser';

import classNames from 'classnames';

import '../../../css/Cloze.scss';
import { LessonMode } from '../../../Constants';

import questionFeedbackManager from '../../managers/QuestionFeedbackManager';

import clozeMathService from '../../services/ClozeMathService';
import lessonService from '../../services/LessonService';
import playerService from '../../services/PlayerService';
import promptService from '../../services/PromptService';
import questionService from '../../services/QuestionService';

import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';

import OptionalImage from '../OptionalImage';
import SimpleModal from '../SimpleModal';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const ClozeMath = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const [allowRenderClozeMathSimpleModals, setAllowRenderClozeMathSimpleModals] = useState(false);

  const [modalBody, setModalBody] = useState(null);
  const [modalLeft, setModalLeft] = useState(null);
  const [modalTop, setModalTop] = useState(null);

  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
  const { showAnswerFeedback, showAnswers } = feedbackState;
  useEffect(() => {
    clozeMathService.toggleFeedbackIconClasses(lessonElementId, model, lessonElementState, showAnswerFeedback);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonElementId, showAnswerFeedback, showAnswers]);

  useStyleEvents(lessonElementId);

  // on init
  useEffect(() => {
    setTimeout(() => {
      setAllowRenderClozeMathSimpleModals(true);
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClozeMathMouseEvents = (_this, _event) => {
    if (playerService.isReadOnly(lessonElementId)) {
      setAllowRenderClozeMathSimpleModals(false);
      debounce(() => {
        setAllowRenderClozeMathSimpleModals(true);
      }, 1000)();
    }
  };

  // on update
  useEffect(() => {
    if(!playerService.isPrintPreviewMode()) {
      window.addEventListener('mousedown', handleClozeMathMouseEvents, { once: true });
      window.addEventListener('resize', handleClozeMathMouseEvents, { once: true });
      window.addEventListener('wheel', handleClozeMathMouseEvents, { once: true });
    }
  });

  const {
    lessonElementState,
    model,
    userInputMap,
  } = questionService.initQuestionComponent({
    lessonElementId,
    questionClassName: 'cloze-question'
  });

  // TODO remove
  // ensure we are destroying all old MathJax listeners (if any exist)
  // const { eventListenerHelper } = eventListenerManager;
  // const clozeMathElement = document.getElementsByClassName('ClozeMath')?.[0];
  // if (clozeMathElement) {
  //   eventListenerHelper.clearEventListeners(clozeMathElement, 'click', {
  //     listenerNamesToKeep: [lessonElementId]
  //   });
  // }

  const renderClozeMath = (_props = null) => {
    const answerBankPositionClassName = `answer-bank-position-${ model?.answerBankPosition || 'bottom'}`;
    const clozeQuestionInnerClassNames = classNames('cloze-question-inner', answerBankPositionClassName);
    const questionTitle = <HtmlComponent htmlStr={model.questionText || ''} />;

    const activityPartModalLeft = modalLeft * 0.34;

    return (
      <>
        <div className='cloze-question'>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='cloze-question-section-title'>
            <div className='test-item-question'>
              {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
              {questionTitle}
            </div>
            <OptionalImage model={model} runtime={true} />
          </div>
          <div className={clozeQuestionInnerClassNames}>
            <div className='cloze-question-section-body'>
              <div className='test-item-answers'>
                {lessonElementState?.questionBehavior?.renderClozeBody({
                  lessonElementId,
                  lessonElementState,
                  modalBody,
                  modalLeft,
                  modalTop,
                  model,
                  renderBlankSection,
                  setModalBody,
                  setModalLeft,
                  setModalTop
                })}
              </div>
            </div>
          </div>
        </div>
        {/* when a ClozeMath prompt is clicked, the multichoice component for that prompt
          will be opened within this SimpleModal */}
        {allowRenderClozeMathSimpleModals && (
          <SimpleModal
            additionalClassNames='simple-modal-no-border'
            disableMove disableResize
            handleRequestClose={() => setModalBody(undefined)}
            initHeight={1}
            initWidth={1}
            modalBody={modalBody}
            modalLeft={model?.isActivityPart ? activityPartModalLeft : modalLeft}
            modalTop={modalTop} />
        )}
      </>
    );
  };

  /** @param {import('html-react-parser').DOMNode} domNode */
  const renderBlankSection = (domNode) => {
    /** dataId = 'data-id' or menclose → mn → text */
    const dataId = domNode.attribs?.['data-id'] || domNode?.children?.[0]?.children?.[0]?.data;
    const prompt = promptService.getCurrentPrompt({
      dataId,
      lessonElementState,
      model,
      promptType: 'anyModelPrompt'
    });

    const userInputText = lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text?.label
      || lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text
      || prompt?.text?.trim();

    if (userInputText) {
      userInputMap?.set(dataId, {
        dataId,
        text: userInputText
      });
    }

    const currentUserInputValue = questionService.getCurrentUserInputValue({ dataId, lessonElementState, userInputMap });

    if (currentUserInputValue) {
      return (
        parse(currentUserInputValue)
      );
    } else {
      return (
        clozeMathService.renderClozeMathBlankPlaceholder(dataId, model)
      );
    }
  };

  // TODO remove
  // // on unmount
  // useEffect(() => () => {
  //   window.removeEventListener('resize', handleClozeMathMouseEvents);
  //   window.removeEventListener('scroll', handleClozeMathMouseEvents);
  //   window.removeEventListener('wheel', handleClozeMathMouseEvents);
  // });

  return lessonElementState ? (
    <>
      {renderClozeMath()}
    </>
  ) : null;
});

export default ClozeMath;
