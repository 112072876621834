import CryptoMD5 from 'crypto-js/md5';
import { toJS } from 'mobx';
import Auth from '../../lessonPlayer/services/AuthService';
import itemBankManager from '../managers/ItemBankManager';
import curriculumManager from '../managers/CurriculumManager';
// import ItemBankItem from '../managers/observableObjects/ItemBankItem';
// import lessonManager from '../../lessonPlayer/managers/LessonManager';
// import { ContentType } from '../../Constants';

export class CurriculumService {
  getArrayFromMap(map) {
    if (map) {
      return Array.from(toJS(map).values());
    }
    return [];
  }

  handleItemPreview = (data) => {
    this.setPreviewId(data.id);
    this.setViewMode('itemPreview');
  }

  // THIS DOESN'T SEEM TO BE USED, IF IT BECOMES USED, SHOULD IT CHANGE TO USE "api/searchLicencedItembankCmaps"??
  fetchCmapList = async () => {
    try {
      if (itemBankManager.productCmaps.length > 0) {
        return false;
      }

      const response = await Auth.fetch(`${Auth.ecms}/api/searchContentItems`, {
        method: 'POST',
        body: {
          searchScope: 'PUBLISHER',
          searchTypes: 'curriculum_map',
          tagSearchCondition: 'OR',
          standardSearchCondition: 'OR',
          take: 1000,
          skip: 0,
          page: 1,
          pageSize: 1000
        }
      });
      if (response && response.status === 'SUCCESS') {
        console.log(response);
        // Not sure what is appropriate on success yet.
        if (response && response.status === 'SUCCESS') {
          const items = response.contentItems;
          itemBankManager.setProductCmaps(items);
          return !!response.status;
        }
      }
      itemBankManager.setContentItemsLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      itemBankManager.setContentItemsLoading(false);
      return false;
    }
  }

  fetchFullCmap = async (cmapContentItemId) => {
    if (!cmapContentItemId || cmapContentItemId.length < 1) {
      return false;
    }

    let response = null;
    try {
      const apiUrlParams = `?cmapContentItemId=${cmapContentItemId}`;
      const apiUrl = `${Auth.ecms}${'/api/viewFullCurriculumMapJson'}${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }

    if (response && response.jsonString) {
      const jsonObj = JSON.parse(response.jsonString);
      const startObj = jsonObj.CurriculumMapElements[1];
      if (startObj) {
        const formattedJson = this.buildCmapStandardsJson(startObj.CurriculumMapElements, jsonObj.name);
        itemBankManager.setCmapStandardsJson(formattedJson);
        return true;
      }
    }
    return false;
  }

  buildCmapStandardsJson = (elemInput, cmapName) => {
    let index = 1;
    if (!elemInput || elemInput.length < 0) return [];
    return elemInput.map((obj) => {
      const cmapElementId = obj.id; // curriculumMapElement id is unique but the entity id is not so use this for the node value
      const standardId = obj.entityId || CryptoMD5(obj).toString() || null;
      let name = '';
      // get dropdown label `name` for standards AssignmentItemFilter
      if (obj.displayName || obj.name) {
        // if obj.displayName or obj.name, use that
        name = obj.displayName || obj.name;
      } else if (cmapName) {
        // else if cmapName, use that
        name = cmapName;
      } else /* if (obj.type === 'UNIT') */ {
        // else use default 'Unit' label
        // (edge case, will likely never happen)
        name = obj.unitName || 'Unit';
        name = `${name} ${index}`;
        index++;
      }
      name = this.stripHtmlTags(name);
      const showCheckbox = (obj.type === 'STANDARD');

      if (showCheckbox) {
        name = `${name} ${obj.decodedDescription}`;
      }

      const cmapElement = {
        label: name,
        showCheckbox,
        standardId,
        value: cmapElementId
      };
      if (obj.CurriculumMapElements.length > 0) {
        itemBankManager.addExpanded(cmapElementId);
        cmapElement.children = this.buildCmapStandardsJson(obj.CurriculumMapElements, '');
      }
      // add a mapping by curriculumElementId so we can swap out the standard id later
      itemBankManager.addCmapStandardsMapItem(cmapElementId, cmapElement);
      return cmapElement;
    });
  }

  stripHtmlTags = (str, ...htmlTagsToKeep) => {
    if (typeof str === 'string') {
      return str.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
        return htmlTagsToKeep.includes(tag) ? `<${ endMark }${tag }>` : '';
      }).replace(/<!--.*?-->/g, '') // strip all html tags except those specified in `htmlTagsToKeep`
        .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig, '') // strip all html entities
        .trim();
    }
  }

  setViewMode = (mode) => {
    itemBankManager.setViewMode(mode);
  }

  setPreviewId = (id) => {
    itemBankManager.setPreviewId(id);
  }

    // The following calls are new for DEMO-1833 and will be moved to a c2c-lesson service.
    fetchAuthorityStates = async () => {
      if (this.authorityStates && this.authorityStates.length > 0) {
        return false;
      }

      try {
        const url = `${Auth.ecms}/api/viewStatesByCountry`;
        const response = await Auth.fetch(url, {
          method: 'GET'
        });
        if (response.status === 'SUCCESS') {
          curriculumManager.setAuthorityStates(response.states);
          // curriculumManager.setProductStates(response.states);
        }
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }

    fetchStandardFrameworkDomainList = async (stateId) => {
      try {
        const url = `${Auth.ecms}/api/viewStandardFrameworkDomainList?stateId=${stateId}`;
        const response = await Auth.fetch(url, {
          method: 'GET'
        });
        if (response.status === 'SUCCESS') {
          curriculumManager.setStandardDocuments(response.data);
        }
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }

    fetchStandardFrameworkBookmarks = async (entityId) => {
      try {
        const url = `${Auth.ecms}/api/viewStandardFrameworkBookmarks?entityId=${entityId}&pageSize=100`;
        const response = await Auth.fetch(url, {
          method: 'GET'
        });
        if (response.status === 'SUCCESS') {
          curriculumManager.loadStandardBookmarks(response.data);
        }
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }

    addStandardFrameworkBookmark = async (sourceId, entityId) => {
      try {
        const body = {
          entityTypeId: 'USER',
          entityId,
          sourceId
        };

        const response = await Auth.fetch(`${Auth.ecms}/api/addStandardFrameworkBookmark`, {
          method: 'POST',
          body
        });

        if (response.status === 'SUCCESS') {
          return true;
        }
        return false;
      } catch (error) {
        console.warn(error);
        return false;
      }
    }

    deleteStandardFrameworkBookmark = async (id) => {
      try {
        const body = {
          id
        };

        const response = await Auth.fetch(`${Auth.ecms}/api/deleteStandardFrameworkBookmark`, {
          method: 'POST',
          body
        });

        if (response.status === 'SUCCESS') {
          return true;
        }
        return false;
      } catch (error) {
        console.warn(error);
        return false;
      }
    }

    fetchFullStandardDocument = async (standardGuid) => {
      if (!standardGuid || standardGuid.length < 1) {
        return false;
      }

      let response = null;
      try {
        const apiUrlParams = `?standardGuid=${standardGuid}`;

        const apiUrl = `${Auth.ecms}/api/viewFullStandardDocument${apiUrlParams}`;
        response = await Auth.fetch(apiUrl, {
          method: 'GET'
        });
      } catch (error) {
        console.error(error);
      }

      const defaultExpanded = false;
      if (response && response.standardFrameworkElements) {
        await curriculumManager.setStandardsResponseData(response.standardFrameworkElements);

        const formattedJson = await this.buildAdoptedStandardsJson(curriculumManager.standardsResponseData, defaultExpanded, false);
        await curriculumManager.setAdoptedStandardsJson(formattedJson);
        return true;
      }
      return false;
    }

    buildAdoptedStandardsJson = (standardFrameworkElements, defaultExpanded = true, allCheckboxes = false) => {
      // eslint-disable-next-line no-unused-vars
      const index = 1;
      if (!standardFrameworkElements || standardFrameworkElements.length < 0) return [];
      // if there is an outer wrapper with no parent element id, skip it.
      const treeElements = (!standardFrameworkElements[0].parentElementId) ? standardFrameworkElements[0].standardFrameworkElements : standardFrameworkElements;
      return treeElements.map((obj) => {
        const stdFwkElementId = obj.id;
        let showCheckbox = false;

        if (allCheckboxes) {
          showCheckbox = true;
        } else {
          showCheckbox = (obj.type && obj.type.toUpperCase() === 'STANDARD');
        }
        // Node label for assignment filter tree node 'listName' has the display name but we have to
        // build it from parts because we have to bold the prefix stored in humanCodingScheme.
        const label = obj.humanCodingScheme ? `${this.stripHtmlTags(obj.humanCodingScheme)} ${this.stripHtmlTags(obj.fullStatement)}` : `${this.stripHtmlTags(obj.fullStatement)}`;
        const stdFrwkElement = {
          label,
          showCheckbox,
          standardId: stdFwkElementId,
          value: stdFwkElementId
        };
        if (obj.standardFrameworkElements.length > 0) {
          if (defaultExpanded) {
            curriculumManager.addExpanded(stdFwkElementId);
          }
          stdFrwkElement.children = this.buildAdoptedStandardsJson(obj.standardFrameworkElements, defaultExpanded, allCheckboxes);
        }
        // add a mapping by stdFwkElementId so we can swap out the standard id later
        curriculumManager.addStdFrwkStandardsMap(stdFwkElementId, stdFrwkElement);
        return stdFrwkElement;
      });
    }

    stripHtmlTags = (str, ...htmlTagsToKeep) => {
      if (typeof str === 'string') {
        return str.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
          return htmlTagsToKeep.includes(tag) ? `<${ endMark }${tag }>` : '';
        }).replace(/<!--.*?-->/g, '') // strip all html tags except those specified in `htmlTagsToKeep`
          .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig, '') // strip all html entities
          .trim();
      }
    }
}

export default new CurriculumService();
