import { useEffect } from 'react';

const useAccessibilityClick = (ref, callback) => {
  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const element = ref.current;

    // eslint-disable-next-line prefer-destructuring
    const tagName = element.tagName;
    const role = element.getAttribute('role');
    const tindex = element.getAttribute('tabindex');

    const keyHandler = (e) => {
      e.stopPropagation();
      if (e.key === ' ' || e.key === 'Enter') {
        callback(e);
      }
    };

    if (tagName !== 'BUTTON') {
      element.addEventListener('keyup', keyHandler);
      if (!role) {
        element.setAttribute('role', 'button');
      }
    }

    if (!tindex) {
      element.setAttribute('tabindex', '0');
    }
    const callbackWrapper = (e) => {
      e.stopPropagation();
      callback(e);
    };
    element.addEventListener('click', callbackWrapper);

    return (
      () => {
        element.removeEventListener('click', callbackWrapper);
        if (tagName !== 'BUTTON') {
          element.removeEventListener('keyup', keyHandler);
        }
      }
    );
  });
};

export default useAccessibilityClick;
