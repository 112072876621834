import React from 'react';

const ResourceItemCheckbox = (props) => {
  const { itemId, onChangeHandler, isSelected, isDisabled, showMe } = props;

  return (
    <> {showMe && (
      <div className='bank-item-select'>
        <input checked={isSelected} disabled={isDisabled} id={itemId} onChange={onChangeHandler}
          type='checkbox' value={itemId} />
      </div>
    )}
    </>
  );
};

export default ResourceItemCheckbox;
