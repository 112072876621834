import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';

const MoveItemDownButton = (props) => {
  const { itemId, callback } = props;

  const addRef = useRef();

  useAccessibilityClick(addRef, () => {
    callback(itemId);
  });

  return (
    <>
      <ReactTooltip border effect='solid' type='light' />
      <div ref={addRef} aria-label='move item down' className='move-item-down-button' data-tip='Move item down.'
        data-tool='move down' />
    </>
  );
};

export default MoveItemDownButton;
