import { renderToStaticMarkup } from 'react-dom/server';

import { components } from 'react-select';

import parse from 'html-react-parser';

import { shuffle } from 'lodash';
import { register } from '../../i18n';

import clozeMathService from './ClozeMathService';
import promptService from './PromptService';
import styleService from './StyleService';
import utilsService from './UtilsService';

import CustomHtmlOption from '../components/lessonItems/CustomHtmlOption';
const t = register('GlobalQuestionLabels');

export default class DropdownService {
  // eslint-disable-next-line no-empty-pattern
  static getDropdownConfig = ({
    disableControlBorder = false,
    modalLeft,
    modalTop,
    model,
    shouldHideControl,
    shouldHideValueContainer
  } = {}) => {
    const { getStyleVar } = styleService;
    const customDropdownStyles = {
      control: (provided, state) => {
        shouldHideControl = shouldHideControl || (model && !utilsService.isNullOrEmpty(model.mathText));
        return shouldHideControl ? {
          maxHeight: 0,
          maxWidth: 0,
          [shouldHideValueContainer ? 'div' : '']: {
            div: {
              color: 'transparent',
              marginLeft: '0',
              marginRight: '0'
            }
          }
        } : {
          ...provided,
          'backgroundColor': state.isFocused ? getStyleVar('--theme-dropdown-closed-menu-focus') : 'transparent',
          'border': disableControlBorder ? 'none' : undefined,
          'borderColor': getStyleVar('--theme-dropdown-control-border-color'),
          'boxShadow': getStyleVar('--theme-dropdown-control-box-shadow'),
          'fontSize': getStyleVar('--theme-font-size'),
          'maxWidth': getStyleVar('--theme-cloze-blank-dropdown-max-width'),
          'minHeight': getStyleVar('--theme-dropdown-control-height'),
          'minWidth': getStyleVar('--theme-dropdown-control-min-width'),
          'width': getStyleVar('--theme-dropdown-control-width'),
          [!state.isDisabled ? 'div' : '']: {
            div: {
              color: state.isFocused ? getStyleVar('--theme-dropdown-open-menu-text') : 'inherit',
            }
          },
          [state.isDisabled ? 'div' : '']: {
            div: {
              color: 'inherit'
            }
          },
          ':hover': {
            backgroundColor: state.isFocused && state.menuIsOpen ? (
              getStyleVar('--theme-dropdown-closed-menu-focus')
            ) : getStyleVar('--theme-dropdown-option-selected'),
            borderColor: getStyleVar('--theme-dropdown-control-border-color-hover'),
            cursor: 'pointer',
            [!state.isDisabled ? 'div' : '']: {
              div: {
                color: (!state.menuIsOpen ? getStyleVar('--theme-dropdown-closed-menu-text') : getStyleVar('--theme-dropdown-open-menu-text'))
              }
            }
          }
        };
      },
      dropdownIndicator: (provided, _state) => {
        return model.mathText ? {
          display: 'none'
        } : {
          ...provided,
          'img': {
            margin: getStyleVar('--theme-dropdown-indicator-margin')
          },
          ':hover': {
            /* placeholder to prevent default styles here */
          }
        };
      },
      indicatorContainer: (provided, _state) => {
        return {
          ...provided,
          transition: 'none'
        };
      },
      indicatorSeparator: (_provided, _state) => {
        return {
          display: getStyleVar('--theme-dropdown-separator-display')
        };
      },
      input: (provided, _state) => {
        return {
          ...provided,
          padding: getStyleVar('--theme-dropdown-input-padding')
        };
      },
      menu: (provided, state) => {
        return {
          ...provided,
          cursor: 'pointer',
          maxWidth: getStyleVar('--theme-cloze-blank-dropdown-max-width'),
          minWidth: getStyleVar('--theme-dropdown-control-min-width'),
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          width: 'fit-content',
          right: this.isMenuOffScreen(state) && 0
        };
      },
      menuList: (provided, _state) => {
        return {
          ...provided,
          maxWidth: getStyleVar('--theme-cloze-blank-dropdown-max-width'),
          minWidth: getStyleVar('--theme-dropdown-control-min-width'),
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          width: 'fit-content'
        };
      },
      menuPortal: (provided, _state) => {
        // TODO remove // const PORTAL_TOP_OFFSET = -200;
        const PORTAL_LEFT_OFFSET = (model && !utilsService.isNullOrEmpty(model.mathText)) ? -15 : 0;
        const PORTAL_TOP_OFFSET = (model && !utilsService.isNullOrEmpty(model.mathText)) ? 15 : -25;
        return {
          ...provided,
          ...(!model?.body && (model && !utilsService.isNullOrEmpty(model.mathText)) ? {
            left: modalLeft + PORTAL_LEFT_OFFSET,
            top: (modalTop || getStyleVar('--theme-dropdown-math-menu-portal-top')) + PORTAL_TOP_OFFSET,
          } : {}),
          zIndex: 1000 // `toolbar-wrapper` (header) and `goto-menu` (footer) have z-index of 999, and there is a potential of the dropdown overlapping these, so we need menuPortal z-index to be at least +1 of the header & footer
        };
      },
      option: (provided, state) => {
        return {
          ...provided,
          'backgroundColor': state.isSelected ?
            getStyleVar('--theme-dropdown-option-selected')
            : (state.isFocused ? getStyleVar('--theme-dropdown-option-hover') : 'transparent'),
          'color': getStyleVar('--theme-dropdown-option-foreground'),
          'cursor': getStyleVar('--theme-dropdown-option-cursor'),
          ':hover': {
            backgroundColor: getStyleVar('--theme-dropdown-option-hover')
          }
        };
      },
      placeholder: (provided, _state) => {
        const shouldHidePlaceholder = (model && !utilsService.isNullOrEmpty(model.mathText));
        return shouldHidePlaceholder ? {
          display: 'none'
        } : provided;
      },
      singleValue: (provided, _state) => {
        return {
          ...provided,
          div: {
            p: {
              margin: '0 !important'
            }
          }
        };
      },
      valueContainer: (provided, _state) => {
        return {
          ...provided,
          padding: getStyleVar('--theme-dropdown-control-padding')
        };
      }
    };

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          {styleService.getSvgCaretDown()}
        </components.DropdownIndicator>
      );
    };

    return {
      customComponents: {
        DropdownIndicator,
        Option: CustomHtmlOption
      },
      customDropdownStyles
    };
  }

  static getDropdownOptions = ({
    dataId,
    lessonElementState, // optional
    model,
    prependSelectLabel = false
  } = {}) => {
    const prompt = promptService.getCurrentPrompt({
      dataId,
      lessonElementState,
      model
    });
    let dropdownOptions = prompt?.answers?.map((answer) => {
      let answerText = typeof answer === 'string' ? answer.trim() : answer.text;
      answerText = prompt.answerType !== 'multichoice' ? answerText : renderToStaticMarkup(parse(answerText || '', {
        replace: (domNode) => {
          if (domNode.name === 'math') {
            clozeMathService.appendDataIdToMathDomNodeThenConvertToMencloseIfApplicable(domNode, dataId, model);
          }
        }
      }));

      return {
        dataId,
        label: answerText,
        originalData: {
          ...answer,
          dataId
        },
        value: answer.id || utilsService.createGuid()
      };
    }) || [];
    dropdownOptions = model?.isRandomized ? shuffle(dropdownOptions) : dropdownOptions;
    dropdownOptions = prependSelectLabel || (model && !utilsService.isNullOrEmpty(model.mathText)) ? [
      {
        dataId: 'prependedSelectLabel',
        label: `${t('choose')}...`,
        value: 'prependedSelectLabel'
      },
      ...dropdownOptions
    ] : dropdownOptions;
    return dropdownOptions;
  }

  static isMenuOffScreen = (state) => {
    const { innerRef } = state;
    if (innerRef?.current) {
      const { right } = innerRef.current.getBoundingClientRect();
      if (right > window.innerWidth) {
        return true;
      }
    }
    return false;
  };
}
