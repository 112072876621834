import React from 'react';
import QuestionDependency from '../QuestionDependency';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const StudentInstruction = ({ model, isManuscriptShort }) => {
  return (
    <>
      <div className='top-label'>Instruction:</div>
      <div className='element-container' id={`_${model.id}`}>
        <div className='label-value-row'>
          <div>Instruction Type:</div>
          <div>Instructions For Students</div>
        </div>
        <div className='label-value-row'>
          <div>Question Number:</div>
          <div>{model.questionNumber}</div>
        </div>
        <div className='label-value-row'>
          <div className='text-area-label'>Student Instructions:</div>
          <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.text} /></div>
        </div>
        {/* advanced options */}
        {!isManuscriptShort && (
          <>
            <QuestionDependency model={model} />
            <div className='label-value-row'>
              <div>Extra Class:</div>
              <div>{model.extraClass}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default StudentInstruction;
