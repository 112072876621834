import { Behavior } from './Behavior';
import { QuestionFeedbackState } from '../../Constants';

export class ActivityBehavior extends Behavior {
  checkForValidResponse = (response, model) => {
    return true;
  }

  isAutoScored = (model) => {
    return false;
  }

  setCorrectAnswer = (state, model) => {
    // do nothing
  }

  resetStudentAnswer = (state) => {
    // do nothing
  }

  getScore = (responseAnswer, model) => {
    return 1;
  }

  setResponseData = (data, responseAnswer) => {
    // do nothing
  }

  getAnswerFeedback = (id, responseAnswer) => {
    return '';
  }

  getInitializedResponse = (lessonElementId, model, activityId) => {
    const responseAnswer = {
      lessonElementId
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    return '';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    return '';
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    return true;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    return QuestionFeedbackState.CORRECT;
  }
}

export default new ActivityBehavior();
