import { renderToStaticMarkup } from 'react-dom/server';
import { Behavior } from './Behavior';
import questionService from '../services/QuestionService';
import UtilsService from '../services/UtilsService';
import { ContentType, LessonMode, PartialScoringType, QuestionFeedbackState } from '../../Constants';
import HtmlComponent from '../components/HtmlComponent';

export class ImageLabelMultichoiceBehavior extends Behavior {
  checkForValidResponse = (response, model) => {
    // Can also return a object with optional keys `isValid`, `title`, `text`, and `allowSubmit` to control the dialog box
    // Valid if all prompts have text that is not an empty string
    const valid = response.prompts.every(({ text }) => text.length > 0);
    return valid;
  }

  isAutoScored = (model) => {
    let autoScore = ContentType.getAutoscore(model.type) && !model.survey && !model.unscored;
    if (model.responseFormat && model.responseFormat === 'html') {
      autoScore = false;
    }
    return autoScore;
  }

  setCorrectAnswer = (state, model) => {
    const temp = model.validation?.prompts.map(({ id, correctAnswers }) => ({ id, text: correctAnswers[0] }));
    if (temp == null) {
      // console.debug('ImageLabelMultichoiceBehavior.setCorrectAnswer: no correct answers', model);
      return;
    }
    state.setCurrentResponse(UtilsService.safeMobxClone({ ...state.cachedResponseAnswer, prompts: temp }));
  }

  resetStudentAnswer = (state) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  getScore = (responseAnswer, model) => {
    // This question allows for partial scoring so we first find the total possible correct answers and the total correct answers
    const { totalScore, totalCorrect } = responseAnswer.prompts.reduce((acc, { id, text }) => {
      // Find the corresponding validation
      const validationPrompt = model.validation?.prompts.find(({ id: validationId }) => id === validationId);
      if (validationPrompt == null) {
        // console.debug('ImageLabelMultichoiceBehavior.getScore: no validation prompt found for prompt', id);
        return acc;
      }
      const { correctAnswers } = validationPrompt;
      const isCorrect = correctAnswers.includes(text);
      return {
        totalScore: acc.totalScore + 1,
        totalCorrect: isCorrect ? acc.totalCorrect + 1 : acc.totalCorrect,
      };
    }, { totalScore: 0, totalCorrect: 0 });

    const { maxScore } = model;
    let score;
    switch (model.scoringType) {
    case PartialScoringType.PARTIAL: {
      // In this case, you get a point for each true result. So this sums the trueCorrect and trueIncorrect. This is out of the total number of answers.
      score = (totalCorrect / totalScore) * maxScore;
      break;
    }
    case PartialScoringType.PARTIAL_MATCH_RESPONSE: {
      // In this case, you get a point for trueCorrect answers. This is out of the total correct answers.
      score = totalCorrect;
      break;
    }
    case PartialScoringType.EXACT: {
      // In this case, you get full points if you have no false results or no points otherwise
      score = totalCorrect === totalScore ? maxScore : 0;
      break;
    }
    default: {
      // console.debug('ImageLabelMultichoiceBehavior.getScore: unknown scoring type', model.scoringType);
      score = 0;
    }
    }
    return score;
  }

  setResponseData = ({ id, text }, responseAnswer) => {
    const currentAnswer = responseAnswer.prompts.find(({ id: promptId }) => promptId === id);
    if (currentAnswer == null) {
      // console.debug('Tried to set ImageLabelMultichoice response data for prompt that does not exist', id, text);
      return;
    }
    currentAnswer.text = text;
  }

  getAnswerFeedback = (id, responseAnswer) => {
    return true;
  }

  getInitializedResponse = (lessonElementId, model, activityId) => {
    const prompts = model.prompts.map(({ id }) => ({ id, text: '' }));
    const responseAnswer = {
      ...questionService.generateInitializedQuestionResponse({
        lessonElementId,
        prompts
      })
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    const answerTexts = model.validation?.prompts.map(({ id, correctAnswers }, index) => ({ id, index, text: correctAnswers.join(', ') }));

    return renderToStaticMarkup(
      <>
        {
          answerTexts.map(({ id, index, text }) => {
            return (
              <div key={id} style={{ display: 'flex', alignItems: 'center' }}>
                {index + 1}:&nbsp;<HtmlComponent htmlStr={text} /><br />
              </div>
            );
          })
        }
      </>
    );
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody } `;
      }

      textBody = `${UtilsService.partiallyCorrectPrompt()}. <br/>${ custom }${textBody}`;
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    // Checks if the current answer is in the list of correct answers
    const responseAnswer = lessonElementState.currentResponseAnswer;
    const currentAnswerText = responseAnswer.prompts.find(({ id: promptId }) => promptId === answerId)?.text;
    const validationAnswerTexts = model.validation?.prompts.find(({ id: promptId }) => promptId === answerId)?.correctAnswers;
    if (currentAnswerText == null || validationAnswerTexts == null) {
      // console.debug('ImageLabelMultichoiceBehavior.isAnswerCorrect: no answer or validation answer found', answerId, currentAnswerText, validationAnswerTexts);
      return false;
    }
    return validationAnswerTexts.includes(currentAnswerText);
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new ImageLabelMultichoiceBehavior();
