import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import SummaryCard from './SummaryCard';

const SummaryCardList = observer(({ sectionLessonElementId }) => {
  const {
    lessonManager,
    userManager
  } = useContext(MobXProviderContext);

  const cardElementIdList = lessonManager.getSectionLessonElementIdList(sectionLessonElementId, userManager.isStudent);

  return (
    <div className='summary-card-list'>
      {
        (cardElementIdList && cardElementIdList.length > 0) ?
          cardElementIdList.map((lessonElementId) => <SummaryCard key={`${lessonElementId}-summary-card`} lessonElementId={lessonElementId} />

          ) : <></>
      }
    </div>
  );
});

export default SummaryCardList;
