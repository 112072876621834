import React, { useRef } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import { CirclePicker } from 'react-color';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import '../../../css/Tools.scss';
import { register } from '../../../i18n';
import highlightAnnotationService from '../../services/HighlightAnnotationService';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';

const t = register('ToolNames');

function Highlighter() {
  const deleteRef = useRef();
  const underlineRef = useRef();
  const circleRef = useRef();

  useAccessibilityClick(deleteRef, (e) => {
    highlightAnnotationService.deleteSelectedHighlight();
  });

  useAccessibilityClick(underlineRef, (e) => {
    highlightAnnotationService.underlineSelectedHighlight();
  });

  useAccessibilityClick(circleRef, (e) => {
    highlightAnnotationService.circleSelectedHighlight();
  });

  function handleChangeComplete(color, event) {
    highlightAnnotationService.changeColor(color.hex);
  }

  const colors = ['#15FF3C', '#56D4FF', '#FFBBFD', '#FFF200'];
  const circleSize = 25;

  useReactModalResizeableDraggable('colorSelector');

  return (
    <div className='colorSelector'>
      <ReactModal
        className='highlightModal'
        disableResize={true}
        initHeight={75}
        initWidth={280}
        isOpen={true}
        left={5}
        top={60}>
        <div className='selectorBody'>
          <div className='title-label basic'>{t('highlightInfo')}</div>
          <div className='control-container colors'>
            <div className='control-section actions'>
              <CirclePicker circleSize={circleSize} colors={colors} onChangeComplete={handleChangeComplete} />
            </div>
            <div className='control-section'>
              <button ref={underlineRef} className='hatUnderlineButton hatIcon hatControl' />
              <button ref={circleRef} className='hatCircleButton hatIcon hatControl' />
              <button ref={deleteRef} className='hatDeleteButton hatIcon hatControl' />
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default Highlighter;
