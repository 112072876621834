import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { LessonMode } from '../../../Constants';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import responseService from '../../services/ResponseService';
import Guideline from '../tools/Guideline';
import textQuestionUtilsService from '../../services/TextQuestionUtilsService';

import '../../../css/TextSelector.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const TextSelector = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  let selectedIds = [];
  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  const readOnly = (lessonElementState?.readOnly || lessonManager.isReadOnly) && lessonManager.playerMode === LessonMode.PRINT_PREVIEW;

  const resetRanges = () => {
    const thisContainerId = `${lessonElementId}-item-view`;
    const thisContainer = document.getElementById(thisContainerId);
    if (thisContainer) {
      const rangeElements = thisContainer.getElementsByClassName('range');
      for (const elem of rangeElements) {
        elem.classList.remove('selected');
        elem.classList.remove('correct');
        elem.classList.remove('incorrect');
      }
    }
  };

  const showStudentRanges = () => {
    resetRanges();
    for (const sa of lessonElementState.currentResponseAnswer.studentAnswers) {
      // query selector uses CSS selectors for querying the DOM, so ID's that start with
      // a number are not supported. CSS.escape solves this problem.
      const elem = document.querySelector(`[data-id=${ CSS.escape(sa) }]`);
      if (elem) {
        elem.classList.add('selected');
      }
    }
  };

  const handleKeyup = function (e) {
    e.stopPropagation();
    if (e.key === ' ' || e.key === 'Enter') {
      handleSelected(e);
    }
  };

  const handleSelected = function (e) {
    const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
    if (feedbackState.showAnswerFeedback) {
      return;
    }
    e.currentTarget.classList.toggle('selected');

    const thisContainerId = `${lessonElementId}-item-view`;
    const thisContainer = document.getElementById(thisContainerId);
    const selectedRangeElements = thisContainer.getElementsByClassName('range selected');
    selectedIds = [];
    for (const elem of selectedRangeElements) {
      selectedIds.push(elem.getAttribute('data-id'));
    }

    responseService.responseChangeHandler(selectedIds, lessonElementId);
  };

  useStyleEvents(lessonElementId);

  useEffect(() => {
    const thisContainerId = `${lessonElementId}-item-view`;
    const thisContainer = document.getElementById(thisContainerId);
    const rangeElements = thisContainer.getElementsByClassName('range');

    Array.from(rangeElements).forEach((element) => {
      if (!readOnly) {
        element.addEventListener('click', handleSelected);
        element.addEventListener('keyup', handleKeyup);
        element.setAttribute('tabindex', '0');
        element.setAttribute('role', 'checkbox');
        element.setAttribute('aria-label', element.innerHTML);
      }
    });

    if (lessonElementState?.currentResponseAnswer?.studentAnswers) {
      showStudentRanges();
      if (feedbackState && showAnswerFeedback) {
        textQuestionUtilsService.decorateFeedbackText(lessonElementState, lessonElementId);
      }
    }

    return () => {
      const thisContainerId = `${lessonElementId}-item-view`;
      const thisContainer = document.getElementById(thisContainerId);
      if (thisContainer) {
        const rangeElements = thisContainer.getElementsByClassName('range');

        Array.from(rangeElements).forEach((element) => {
          element.removeEventListener('click', handleSelected);
          element.removeEventListener('keyup', handleKeyup);
        });
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonManager.currentLessonElementId, toolbarManager.isToolbarAnswerFeedbackActive]);

  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
  const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;
  const showAnswers = (feedbackState) ? feedbackState.showAnswers : false;

  if (lessonElementState?.currentResponseAnswer?.studentAnswers?.length > 0) {
    showStudentRanges();
    if (feedbackState && showAnswerFeedback) {
      textQuestionUtilsService.decorateFeedbackText(lessonElementState, lessonElementId);
    }
  }

  if (feedbackState && showAnswers) {
    textQuestionUtilsService.setCorrectAnswersText();
  }

  return (
    <>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='textSelectorBody'>
        <HtmlComponent htmlStr={model.body} />
      </div>
    </>
  );
});
export default TextSelector;
