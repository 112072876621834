import React, { useContext } from 'react';

import { MobXProviderContext } from 'mobx-react';

import { StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment } from 'react-complex-tree';

import classNames from 'classnames';

import '../../../css/FlyoutAlignmentPanel.scss';
import { register } from '../../../i18n';

import alignmentService from '../../services/AlignmentService';

import HtmlComponent from '../HtmlComponent';

const t = register('PanelMenu');

const customItemArrow = ({ item, context }) => {
  if (item.hasChildren) {
    return (
      (context.isExpanded) ? <div className='expanded-icon' /> : <div className='closed-icon' />
    );
  } else {
    return null;
  }
};

// we can't use hooks in the tree item handler so we have to make the click
// accessible manually
const infoClick = async (e, cmapContentItemId, elementPath) => {
  alignmentService.openCmapDialog(true);

  // use a copy of the elementPath because getCmapDetail mutates the array
  await alignmentService.getCmapDetail(cmapContentItemId, elementPath.slice());
};

const keyHandler = (e) => {
  if (e.key === ' ' || e.key === 'Enter') {
    infoClick(e);
  }
};

const customItemRender = ({ item, title, arrow, depth, context, children }, alignmentPaths) => {
  context.interactiveElementProps.tabIndex = '0';
  let showCheck = false;

  if (alignmentPaths) {
    if (alignmentPaths.indexOf(item.data.coveragePath) > -1) {
      showCheck = true;
    }
  }

  return (
    <>
      {(showCheck || item.data.type === 'CMAP' || item.data.type === 'COURSE') && (
        <li
          {...context.itemContainerWithChildrenProps}
          className={classNames({ 'not-cmap': showCheck, 'tree-item': true })} data-sub-category-name={item.data.subCategoryName}
        >
          <div className='item-container' {...context.itemContainerWithoutChildrenProps} {...context.interactiveElementProps}>
            {arrow}
            {(item.data.type !== 'CMAP') && (
              <>
                <div
                  onClick={(e) => infoClick(
                    e, item.data.cmapContentItemId, item.data.elementPath
                  )}
                  onKeyUp={keyHandler}
                  role='button' tabIndex='0'> {title}
                </div>
                <div>- </div>
              </>
            )}
            {(item.data.type === 'CMAP') && <div tabIndex='0'>{title}</div>}
            <HtmlComponent className='tree-item-description' htmlStr={item.data.description} tabIndex='0' />
          </div>
          {children}
        </li>
      )}
    </>
  );
};

const AlignmentPanel = ({ lessonElementId }) => {
  const {
    alignmentManager,
    lessonManager
  } = useContext(MobXProviderContext);
  const model = lessonManager.getSlideModel(lessonElementId);
  const isActivity = model?.isActivity;
  const alignmentPaths = alignmentManager.alignmentCoveragePaths.get(lessonElementId);

  return (
    <div className='alignment-panel flyout-panel'>
      <div className='panel-header'>{isActivity ? `${t('activityAlignment')}` : `${t('itemAlignment')}`}</div>
      <div className='tree-wrapper'>
        <UncontrolledTreeEnvironment
          canDragAndDrop={false}
          canReorderItems={false}
          dataProvider={new StaticTreeDataProvider(alignmentManager.alignmentTreeStructure, (item, data) => ({ ...item, data }))}
          getItemTitle={(item) => (item.data.friendlyName || item.data.name)}
          renderItem={(props) => customItemRender(props, alignmentPaths)}
          renderItemArrow={customItemArrow}
          renderItemTitle={({ title }) => {
            return (
              <HtmlComponent className='tree-item-title' htmlStr={title} />
            );
          }}
          renderItemsContainer={({ children, containerProps }) => {
            return (
              <ul className={`alignment-tree-list ${(containerProps.role) ? containerProps.role : ''}`} {...containerProps}>{children}</ul>
            );
          }}
          renderTreeContainer={({ children, containerProps }) => {
            return (
              <div className='alignment-tree-container' {...containerProps}>{children}</div>
            );
          }}
          viewState={{
            'tree-1': {
              expandedItems: alignmentManager.expandedItems,
              selectedItems: null
            },
          }}>
          <Tree rootItem='root' treeId='tree-1' treeLabel='Item Alignments' />
        </UncontrolledTreeEnvironment>
      </div>
    </div>
  );
};

export default AlignmentPanel;
