import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import Select from 'react-select';
import CheckboxTree from 'react-checkbox-tree';
import itemBankService from '../services/ItemBankService';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const CmapFilter = observer((props) => {
  const {
    itemBankManager,
  } = useContext(MobXProviderContext);

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    const fetchInitData = async (itemBankId) => {
      itemBankManager.clearProductCmaps();
      await itemBankService.fetchCmapList(itemBankId);
    };
    fetchInitData(props.itemBankId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeCmap = async (selected) => {
    await itemBankService.setSelectedCmapOption(selected);
    await itemBankService.fetchFullCmap(selected.value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeExpanded = async (selected) => {
    await itemBankManager.setCmapExpanded(selected);
    // itemBankService.searchContentItemsForItemBank();
  };

  const handleChangeStandards = async (searchElementIds, node, isTOCSearch = false) => {
    const searchStandards = [];
    // Convert the search curriculum element ids to a standards list
    if (itemBankManager.cmapStandardsMap) {
      searchElementIds.forEach((elementId) => {
        // Get the standard for the cmapElement
        const cmapElement = itemBankManager.cmapStandardsMap.get(elementId);
        if (cmapElement) {
          searchStandards.push(cmapElement.standardId);
        }
      });
    }

    itemBankManager.resetPaginator();
    itemBankService.setSearchStandardElementIds(searchElementIds);
    await itemBankManager.setCmapStandardSelected(searchStandards);
    itemBankService.searchContentItemsForItemBank();
  };

  return (
    <>
      <div>
        <div className='filter-label' />
        <Select
          allowSelectAll={false}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          isDisabled={!itemBankManager.searchItemBankId}
          onChange={handleChangeCmap}
          options={itemBankManager.sortedProductCmaps}
          value={itemBankManager.selectedCmapOption} />
      </div>
      <div className='filter-label' />
      <div>
        <CheckboxTree
          checked={itemBankManager.searchStandardElementIds}
          expanded={(expanded && expanded.length > 0) ? expanded : itemBankManager.expandedSelected}
          icons={{
            check: <span className='rct-icon rct-icon-check' />,
            uncheck: <span className='rct-icon rct-icon-uncheck' />,
            halfCheck: <span className='rct-icon rct-icon-half-check' />,
            expandClose: <span className='icon-expand-close' />,
            expandOpen: <span className='icon-expand-open' />,
            expandAll: <span className='rct-icon rct-icon-expand-all' />,
            collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
            parentClose: <span className='rct-icon rct-icon-parent-close' />,
            parentOpen: <span className='rct-icon rct-icon-parent-open' />,
            leaf: <span className='rct-icon rct-icon-leaf' />
          }}
          nativeCheckboxes={true}
          noCascade={true}
          nodes={itemBankManager.cmapStandardsJson}
          onCheck={(checked) => handleChangeStandards(checked)}
          onExpand={(expanded) => setExpanded(expanded)} />
      </div>
    </>
  );
});

export default CmapFilter;
