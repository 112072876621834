import { useEffect } from 'react';

import { LessonMode, LessonSlideIds } from '../Constants';

import highlightAnnotationManager from '../lessonPlayer/managers/HighlightAnnotationManager';
import lessonManager from '../lessonPlayer/managers/LessonManager';
import toolbarManager from '../lessonPlayer/managers/ToolbarManager';
import userManager from '../lessonPlayer/managers/UserManager';

import highlightAnnotationService from '../lessonPlayer/services/HighlightAnnotationService';
import htmlService from '../lessonPlayer/services/HtmlService';

export const useTextHighlight = (ref, lessonElementId) => {
  // TODO unused // const disabled = (lessonManager.playerMode === LessonMode.SCORING && highlightAnnotationManager.highlightUserMode === 'student');
  useEffect(() => {
    const textHighlightListener = (event) => {
      toolbarManager.setIsStickyNotesOpen(false);

      const isMobile = htmlService.isMobile();
      if (isMobile && event.type === 'mouseup') {
        return;
      }
      const tagName = event.target?.tagName?.toLowerCase?.();
      if (tagName === 'input' || tagName === 'button') {
        return;
      }

      const selection = document.getSelection();

      const isAnchorOffsetSameAsFocusOffset = selection?.anchorOffset === selection?.focusOffset;

      if (isMobile) {
        if (isAnchorOffsetSameAsFocusOffset) {
          if (selection.focusNode || selection.anchorNode) {
            selection.extend(selection.focusNode || selection.anchorNode);
          } else if (toolbarManager.isToolOn('StickyNotes')) {
            return;
          }
        }
      }

      if ((toolbarManager.isToolOn('HAT') || (toolbarManager.isToolOn('StickyNotes')))) {
        event = event || window.event;

        if (lessonManager.playerMode === LessonMode.SCORING && userManager.isTeacher && highlightAnnotationManager.highlightUserMode === 'student') {
          // There is no range selected and notes are turned on. Ordered this way because the sticky notes take precedence if on.
          if (isAnchorOffsetSameAsFocusOffset && (event.target.classList.contains('hlt-on'))) {
            if (toolbarManager.isToolOn('StickyNotes')) {
              highlightAnnotationManager.setSelectedHighlightElement(event.target);
              highlightAnnotationService.showTextHighlightNotes(ref.current.id, event.target);
              return;
            }
            // just select the highlight (if no sticky note anchors are in the selection)
            if ((toolbarManager.isToolOn('HAT'))) {
              if (highlightAnnotationService.hasNoteAnchorWithinHighlightedSelection({
                selection
              })) {
                highlightAnnotationService.showHighlightErrorDialog();
                selection?.empty?.();
                return;
              }
              highlightAnnotationManager.setSelectedHighlightElement(event.target);
              highlightAnnotationManager.setCurrentDomElementId(ref.current.id);
            }
          }
        } else {
          // There is no range selected and notes are turned on. Ordered this way because the sticky notes take precedence if on.
          if (isAnchorOffsetSameAsFocusOffset && (event.target.classList.contains('hlt-on'))) {
            if (toolbarManager.isToolOn('StickyNotes')) {
              highlightAnnotationManager.setSelectedHighlightElement(event.target);
              highlightAnnotationService.showTextHighlightNotes(ref.current.id, event.target);
              return;
            }
            // just select the highlight
            if ((toolbarManager.isToolOn('HAT'))) {
              highlightAnnotationManager.setSelectedHighlightElement(event.target);
              highlightAnnotationManager.setCurrentDomElementId(ref.current.id);
              return;
            }
          }

          // create the note anchor
          if (isAnchorOffsetSameAsFocusOffset && (toolbarManager.isToolOn('StickyNotes')) && !event.target.classList.contains('hlt-on')) {
            const selectedRange = document.getSelection();
            let range;
            if (selectedRange.rangeCount > 0) {
              range = selectedRange.getRangeAt(0);
            }

            selectedRange.modify('extend', 'right', 'character');

            const hasNoSelectedTextWhenTrimmed = !selectedRange?.toString?.()?.trim?.();

            if (hasNoSelectedTextWhenTrimmed
              || selectedRange.focusOffset === 0
              || selectedRange.extentOffset === 0
              || range.startContainer !== range.endContainer) {
              return false;
            }

            const domElement = document.getElementById(ref.current.id);
            const isStickyNode = true;

            highlightAnnotationService.createUpdateHighlight(
              domElement, ref.current.id, lessonElementId, isStickyNode, { event, selection }
            );
            highlightAnnotationManager.setCurrentDomElementId(ref.current.id);
          } else {
            // if there is a range selected (with no sticky note anchors) and HAT is on, do the highlight
            if (toolbarManager.isToolOn('HAT')) {
              if (highlightAnnotationService.hasNoteAnchorWithinHighlightedSelection({
                selection
              })) {
                highlightAnnotationService.showHighlightErrorDialog();
                selection?.empty?.();
                return;
              }
              const domElement = document.getElementById(ref.current.id);
              const isStickyNote = false;

              highlightAnnotationService.createUpdateHighlight(domElement, ref.current.id, lessonElementId, isStickyNote, {
                event, selection
              });
              highlightAnnotationManager.setCurrentDomElementId(ref.current.id);
            }
          }
        }
      }
    };
    setTimeout(() => {
      const isContentPage = (lessonManager.currentLessonElementId !== LessonSlideIds.SUMMARY_PAGE && lessonManager.currentLessonElementId !== LessonSlideIds.TITLE_PAGE);
      const element = ref.current;
      if (element) {
        const highlightableElements = element.querySelectorAll('.html-input, .paragraph-wrapper');
        for (const highlightableElement of highlightableElements) {
          if (highlightableElement && isContentPage) {
            if (toolbarManager.isStickyNotesActive) {
              highlightableElement.classList.add('sticky-notes-active');
            }
            highlightableElement.addEventListener('mouseup', textHighlightListener);
            highlightableElement.addEventListener('touchend', textHighlightListener);
          }
        }
        return () => {
          for (const highlightableElement of highlightableElements) {
            if (highlightableElement) {
              highlightableElement.removeEventListener('mouseup', textHighlightListener);
              highlightableElement.removeEventListener('touchend', textHighlightListener);
            }
          }
        };
      }
    }, 500);
  }, [lessonElementId, ref, toolbarManager.isStickyNotesActive]);
  //   const isContentPage = (lessonManager.currentLessonElementId !== LessonSlideIds.SUMMARY_PAGE && lessonManager.currentLessonElementId !== LessonSlideIds.TITLE_PAGE);
  //   const element = ref.current;
  //   if (element) {
  //     const highlightableElements = element.querySelectorAll('.html-input, .paragraph-wrapper');
  //     for (const highlightableElement of highlightableElements) {
  //       if (highlightableElement && isContentPage) {
  //         highlightableElement.addEventListener('mouseup', textHighlightListener);
  //         highlightableElement.addEventListener('touchend', textHighlightListener);
  //       }
  //     }
  //     return () => {
  //       for (const highlightableElement of highlightableElements) {
  //         if (highlightableElement) {
  //           highlightableElement.removeEventListener('mouseup', textHighlightListener);
  //           highlightableElement.removeEventListener('touchend', textHighlightListener);
  //         }
  //       }
  //     };
  //   }
};

export default useTextHighlight;
