import { makeObservable, observable } from 'mobx';

import { EventListenerHelper } from './helpers/EventListenerHelper';

export class EventListenerManager {
  @observable eventListenerHelper = new EventListenerHelper();

  constructor() {
    makeObservable(this);
  }
}

export default new EventListenerManager();
