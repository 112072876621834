import React, { useContext } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import HtmlComponent from '../HtmlComponent';
import '../../../css/Tools.scss';
import { register } from '../../../i18n';
import { MobXProviderContext, observer } from 'mobx-react';

const t = register('ToolNames');
const t2 = register('AriaLabel')

const Instruction = observer(() => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  function closeInstruction() {
    toolbarManager.setIsInstructionOpen(false);
    toolbarManager.toggleToolOff(toolbarManager.instructionOpenId);
  }

  return (
    <div className='instructionTool'>
      <ReactModal
        initHeight={300}
        initWidth={800}
        isOpen={true}>
        <div className='modalBody'>
          <div className='tool-header'>
            <div className='title-label basic'>{t('instructionTitle','Student Instructions')}</div>
            <div aria-label={t2('closedialog')} className='x-close-button' onClick={closeInstruction} role='button'
              tabIndex='0' />
          </div>
          <div className='article-container' data-allow='1'><HtmlComponent htmlStr={toolbarManager.instructionData} /></div>
        </div>
      </ReactModal>
    </div>
  );
});

export default Instruction;
