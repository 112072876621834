import React, { useEffect, useRef, useState } from 'react';

import Moveable from 'react-moveable';

import '../../../css/Tools.scss';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

const MetricRuler = () => {
  const [target, setTarget] = useState();
  // eslint-disable-next-line no-unused-vars
  const [frame, _setFrame] = useState({
    translate: [0, 0],
    rotate: 0,
    transformOrigin: '50% 50%',
  });

  const moveableRef = useRef();
  const verticalRef = useRef();
  const horizontalRef = useRef();

  useAccessibilityClick(verticalRef, (_event) => {
    moveableRef.current.moveable.request('rotatable', { rotate: 90 }, true);
  });

  useAccessibilityClick(horizontalRef, (_event) => {
    moveableRef.current.moveable.request('rotatable', { rotate: 0 }, true);
  });

  // CF-4140: Added to fix bug that continued drag even after mouse click.
  const stopDrag = (_event) => {
    moveableRef.current.moveable.stopDrag();
  };

  useEffect(() => {
    setTarget(document.querySelector('.metricRulerTarget'));
    document.addEventListener('mouseup', stopDrag);
    return () => {
      document.removeEventListener('mouseup', stopDrag);
    };
  }, []);

  return (
    <div className='metricRulerContainer'>
      <div className='metricRulerTarget' id='metricRulerTool'>
        <button ref={horizontalRef} id='metricRulerToolSnapHorizontalButton' />
        <button ref={verticalRef} id='metricRulerToolSnapVerticalButton' />
        <div id='metricRulerToolRotator'>
          <div id='metricRulerToolRotateHandle' />
        </div>
      </div>
      <Moveable
        ref={moveableRef}
        draggable={true}
        onDrag={(event) => {
          frame.translate = event.beforeTranslate;
        }}
        onDragOrigin={(event) => {
          frame.translate = event.drag.beforeTranslate;
          frame.transformOrigin = event.transformOrigin;
        }}
        onDragOriginStart={(event) => {
          event.dragStart && event.dragStart.set(frame.translate);
        }}
        onDragStart={(event) => {
          event.set(frame.translate);
        }}
        onRender={(event) => {
          const { translate, rotate, transformOrigin } = frame;
          event.target.style.transformOrigin = transformOrigin;
          event.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
            + ` rotate(${rotate}deg)`;
        }}
        onRotate={(event) => {
          frame.rotate = event.beforeRotate;
        }}
        onRotateStart={(event) => {
          event.set(frame.rotate);
        }}
        origin={false}
        originDraggable={true}
        originRelative={true}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        rotatable={true}
        rotationPosition='left'
        startDragRotate={0}
        target={target}
        throttleDrag={0}
        throttleDragRotate={0}
        throttleRotate={0}
        zoom={1}
      />
    </div>
  );
};

export default MetricRuler;
