import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../css/QuestionButtonBar.scss';

import { LessonMode } from '../../Constants';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import { register } from '../../i18n';

import navMenuService from '../services/NavMenuService';
import responseService from '../services/ResponseService';
import Loader from './Loader';

const t = register('GlobalQuestionLabels');

const QuestionButtonBar = observer((props = {}) => {
  const { isSurvey } = props;

  const { lessonManager, questionFeedbackManager } = useContext(MobXProviderContext);

  const submitRef = useRef();
  const tryAgainRef = useRef();
  const showAnswersRef = useRef();
  const showAnswersStudentRef = useRef();
  const hintRef = useRef();
  const solutionRef = useRef();

  const showStudentAnswerLabel = (lessonManager.playerMode === LessonMode.SCORING) ? `${t('showAnswer')}` : `${t('showMyAnswer')}`;

  useAccessibilityClick(submitRef, async (event) => {
    if (lessonManager.currentLessonElementId !== props.lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(props.lessonElementId, { shouldScrollQuestionIntoView: false });
    }

    await responseService.submitButtonHandler(event, props.lessonElementId);
  });

  useAccessibilityClick(tryAgainRef, async (event) => {
    if (lessonManager.currentLessonElementId !== props.lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(props.lessonElementId, { shouldScrollQuestionIntoView: false });
    }
    await responseService.tryAgainButtonHandler(event, props.lessonElementId);
  });

  useAccessibilityClick(showAnswersRef, async (event) => {
    if (lessonManager.currentLessonElementId !== props.lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(props.lessonElementId, { shouldScrollQuestionIntoView: false });
    }
    responseService.showAnswersButtonHandler(event, props.lessonElementId, true);
  });

  useAccessibilityClick(hintRef, async (event) => {
    if (lessonManager.currentLessonElementId !== props.lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(props.lessonElementId, { shouldScrollQuestionIntoView: false });
    }
    responseService.setHintDialogOpen(true);
    responseService.setSolutionDialogOpen(false);
  });

  useAccessibilityClick(solutionRef, async (event) => {
    if (lessonManager.currentLessonElementId !== props.lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(props.lessonElementId, { shouldScrollQuestionIntoView: false });
    }
    responseService.setSolutionDialogOpen(true);
    responseService.setHintDialogOpen(false);
  });

  useAccessibilityClick(showAnswersStudentRef, async (event) => {
    if (lessonManager.currentLessonElementId !== props.lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(props.lessonElementId, { shouldScrollQuestionIntoView: false });
    }
    responseService.showAnswersButtonHandler(event, props.lessonElementId, false);
  });

  const uiState = questionFeedbackManager.getUiState(props.lessonElementId);
  const model = lessonManager.getSlideModel(props.lessonElementId);
  const submitButtonLabel = questionFeedbackManager.getSubmitButtonLabel(model);

  return (
    <>
      <div className={classNames('question-button-bar', { 'is-survey': isSurvey })}>
        {
          uiState && uiState.loadingButtonVisible &&
          <button  disabled className='loading primary inverse' tabIndex='0'> <Loader></Loader></button>
        }
        {
          uiState && uiState.submitButtonVisible && (
            <button ref={submitRef}
              className={classNames('submit-response primary', {
                disabled: uiState.submitButtonDisabled
              })}
              disabled={uiState.submitButtonDisabled}
              tabIndex='0'>
              {submitButtonLabel}
            </button>
          )
        }
        {
          uiState && uiState.tryAgainButtonVisible &&
          <button ref={tryAgainRef} className='try-again primary' tabIndex='0'> {questionFeedbackManager.tryAgainButtonLabel} </button>
        }
        {
          uiState && uiState.showAnswersButtonVisible && !uiState.showAnswers &&
          <button ref={showAnswersRef} className='show-answer primary' tabIndex='0'> {questionFeedbackManager.showAnswersButtonLabel} </button>
        }
        {
          uiState && uiState.showAnswersButtonVisible && uiState.showAnswers &&
          <button ref={showAnswersStudentRef} className='show-answer primary' tabIndex='0'> {showStudentAnswerLabel} </button>
        }
        {
          uiState && uiState.hintButtonVisible &&
          <button ref={hintRef} className='submit-response primary' tabIndex='0'> {questionFeedbackManager.hintButtonLabel} </button>
        }
        {
          uiState && uiState.solutionButtonVisible &&
          <button ref={solutionRef} className='try-again primary' tabIndex='0'> {questionFeedbackManager.solutionButtonLabel} </button>
        }
      </div>
    </>
  );
});

export default QuestionButtonBar;
