import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';

const MoveItemUpButton = (props) => {
  const { itemId, callback } = props;

  const addRef = useRef();

  useAccessibilityClick(addRef, () => {
    callback(itemId);
  });

  return (
    <>
      <ReactTooltip border effect='solid' type='light' />
      <div ref={addRef} aria-label='move item up' className='move-item-up-button' data-tip='Move item up.'
        data-tool='move up' />
    </>
  );
};

export default MoveItemUpButton;
