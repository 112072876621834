import React from 'react';
import NumberCircle from '../NumberCircle';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import AudioFeedback from '../AudioFeedback';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const ClozeSpelling = ({ model, isManuscriptShort }) => {
  let body;
  if (model.body) {
    body = model.body.replaceAll('contenteditable', 'content');
  }

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Spelling</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Directions:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Text:</div>
        <div className='ckeditor-text text-area has-number-circles'><HtmlComponent htmlStr={body} /></div>
      </div>
      <div>
        <div className='standalone-label'>Answers:</div>
        {model.validation.prompts.map((prompt, idx) => {
          return (
            <div key={prompt.id} className='has-number-circles spelling-answer'><NumberCircle number={idx + 1} />
              {prompt.answers.map((answer) => {
                return (
                  <span key={answer.id}>{answer.text}</span>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
          <div className='label-value-row'>
            <div>Numbered Blanks:</div>
            <div>{(model.numberedBlanks) ? 'ON' : 'OFF'}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <AudioFeedback model={model} />
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default ClozeSpelling;
