import React from 'react';
import '../../css/BasicPaginator.scss';

const BasicPaginator = (props) => {
  const { handleForward, handleBack, label, showMe } = props;

  return (
    <> {showMe && (
      <div className='basic-paginator'>
        <div className='space-label' />
        <div className='num-items-label'>{label}</div>
        <button className='page-button' onClick={handleBack} tabIndex='0'><span>⟨</span></button>
        <button className='page-button' onClick={handleForward} tabIndex='0'><span>⟩</span></button>
      </div>
    )}
    </>
  );
};

export default BasicPaginator;
