import React, { useRef } from 'react';
import useAccessibilityClick from '../../../../hooks/useAccessibilityClick';
import DefaultDialog from '../../dialogs';
import StyleService from '../../../services/StyleService';
import HtmlComponent from '../../HtmlComponent';
import UtilsService from '../../../services/UtilsService';

const ButtonWidget = (props) => {
  const { model } = props;

  const labelContentsId = `buttonContents_${model.id}`;
  const labelBackgroundId = `buttonBackground_${model.id}`;
  const buttonBackgroundColor = model.style.buttonBackgroundColor || '#3A9CFA';
  const buttonBackgroundOpacity = model.style.buttonBackgroundOpacity || '1';
  const buttonColor = model.style.buttonColor || StyleService.getStyleVar('--theme-font-color');
  const width = model.buttonWidth || '';
  const height = model.buttonHeight || '';
  const display = (model.display) ? model.display : 'visible';
  const borderRadius = model.borderRadius || '16px';
  const label = model.label || '';
  const buttonRef = useRef();

  useAccessibilityClick(buttonRef, () => {
    if (model.action === 'toggleContent') {
      const $widget = document.getElementById(model.targetContent);
      if ($widget) {
        const isVisible = $widget.classList.contains('visible');
        if (isVisible) {
          $widget.classList.add('hidden');
          $widget.classList.remove('visible');
        } else {
          $widget.classList.remove('hidden');
          $widget.classList.add('visible');
        }
      }
    } else if (model.action === 'showContentInPopup') {
      const $content = document.getElementById(model.targetContent);

      if ($content) {
        // eslint-disable-next-line no-unused-vars
        const height = window.innerHeight - 300;
        // eslint-disable-next-line no-unused-vars
        const width = window.innerWidth - 600;
        const html = $content.outerHTML;

        DefaultDialog.fire({
          html,
          showCancelButton: false,
          buttonsStyling: false,
          confirmButtonText: `${UtilsService.confirmOk()}` ,
          showClass: {
            backdrop: 'swal2-noanimation',
            popup: '',
            icon: ''
          },
          hideClass: {
            popup: '',
          }
        });
      }
    }
  });

  return (
    <div ref={buttonRef} className={`widgetButton widgetContent ${display}`} id={model.id} style={{ borderRadius, width: `${width}px`, height: `${height}px`, backgroundColor: buttonBackgroundColor }}>
      <div className='widgetButtonBackground' id={labelBackgroundId} style={{ borderRadius, backgroundColor: buttonBackgroundColor, opacity: buttonBackgroundOpacity }}>
        <div className='widgetButtonContents' id={labelContentsId} style={{ borderRadius, placeHolder: 'Your label', color: buttonColor }}><HtmlComponent htmlStr={label} /></div>
      </div>
    </div>
  );
};

export default ButtonWidget;
