import React from 'react';
import NumberCircle from '../NumberCircle';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import AudioFeedback from '../AudioFeedback';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const Categorize = ({ model, isManuscriptShort }) => {
  const rowCount = Number(model.rowCount);
  const columnCount = Number(model.columnCount);

  const tracks = ['1fr'];
  for (let i = 0; i < columnCount; i++) {
    tracks.push('3fr');
  }
  const template = tracks.join(' ');

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Classify</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Question:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <div className='label-value-row'>
        <div>Number of Categories:</div>
        <div>{model.columnCount}</div>
      </div>
      <div className='label-value-row'>
        <div>Number of Subcategories:</div>
        <div>{model.rowCount}</div>
      </div>
      <div className='grid-container' style={{ display: 'grid', gridTemplateColumns: template }}>
        <div />
        {[...Array(columnCount)].map((val, idx) => {
          return (
            <div key={idx} className='grid-col-label'><div className='ckeditor-text'><HtmlComponent htmlStr={model.columnLabels[idx]} /></div></div>
          );
        })}
        {[...Array(rowCount)].map((val, rowIdx) => {
          return (
            <React.Fragment key={rowIdx}>
              <div className='grid-row-label'><div>{model.rowLabels[rowIdx]}</div></div>
              {[...Array(columnCount)].map((val, colIdx) => {
                const number = (rowIdx * columnCount) + colIdx + 1;
                return (
                  <div key={colIdx} className='grid-cell'><NumberCircle number={number} /></div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
      <div>
        {[...Array(rowCount * columnCount)].map((val, idx) => {
          const cellName = `cell${idx}`;
          const cell = model.validation.prompts[cellName];
          if (!cell) {
            return null;
          }

          return (
            <React.Fragment key={idx}>
              <div className='standalone-label has-number-circles'><NumberCircle number={idx + 1} />Accepted Answers:</div>
              <ul>
                {cell.map((answer) => {
                  return (
                    <li key={answer.id} className='answer'><HtmlComponent htmlStr={answer.text} /></li>
                  );
                })}
              </ul>
            </React.Fragment>
          );
        })}
      </div>
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
          <div className='label-value-row'>
            <div>Answer Format:</div>
            <div>{model.responseFormat}</div>
          </div>
        </>
      )}
      <div className='label-value-row'>
        <div>Answer Bank Heading:</div>
        <HtmlComponent htmlStr={model.answerBankHeading} />
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Answer Bank Position:</div>
            <div>{model.answerBankPosition}</div>
          </div>
          <div className='label-value-row'>
            <div>Answer Bank Layout:</div>
            <div>{model.answerLayoutType}</div>
          </div>
          <div className='label-value-row'>
            <div>Answer Bank Options Persist:</div>
            <div>{model.answersPersist}</div>
          </div>
          <div className='label-value-row'>
            <div>Show Duplicate Accepted Answers:</div>
            <div>{model.duplicateAnswers}</div>
          </div>
          <div className='label-value-row'>
            <div>Numbered Blanks:</div>
            <div>{(model.numberedBlanks) ? 'ON' : 'OFF'}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <AudioFeedback model={model} />
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default Categorize;
