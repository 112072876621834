import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/ShortText.scss';
import { LessonMode } from '../../../Constants';

import responseService from '../../services/ResponseService';
import styleService from '../../services/StyleService';

import RichTextEditor, {
  ADVANCED_CKEDITOR_TOOLBAR,
  ADVANCED_MATH_CKEDITOR_TOOLBAR,
  MATH_ONLY_CKEDITOR_TOOLBAR,
  MATH_TOOLBAR,
  MINIMUM_TOOLBAR,
} from '../RichTextEditor';

import FeedbackIcon from '../FeedbackIcon';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

import { register } from '../../../i18n';

const t = register('GlobalQuestionLabels');

const ShortText = observer(({ lessonElementId }) => {
  const {
    lessonManager, // eslint-disable-line no-unused-vars
    responseManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const textBody = model.questionText || '';

  let toolbar = null;

  if (model.responseFormat === 'html') {
    toolbar = MINIMUM_TOOLBAR;

    if (model.toolbarType === 'mathToolbar') {
      toolbar = MATH_TOOLBAR;
    } else if (model.toolbarType === 'advancedToolbar') {
      toolbar = ADVANCED_CKEDITOR_TOOLBAR;
    } else if (model.toolbarType === 'mathAdvancedToolbar') {
      toolbar = ADVANCED_MATH_CKEDITOR_TOOLBAR;
    }
  }

  if (model.responseFormat === 'math') {
    toolbar = MATH_ONLY_CKEDITOR_TOOLBAR;
  }

  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  if (!lessonElementState) {
    return (<div className='short-text-question' />);
  }

  const readOnly = lessonElementState.readOnly || lessonManager.isReadOnly;

  const { currentResponseAnswer } = lessonElementState;

  useStyleEvents(lessonElementId);

  return (
    <div className='short-text-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question short-text'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={textBody} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div className='test-item-answers'>
        {/* answerId is ignored in a single answer question */}
        <FeedbackIcon lessonElementId={lessonElementId} />
        {(model.responseFormat === 'math' || model.responseFormat === 'html') ? (
          <div className={(model.responseFormat === 'html') ? 'cke_topspace' : ''}>
            <RichTextEditor data={currentResponseAnswer.text} lessonElementId={lessonElementId}
              onBlur={() => styleService.resetStyleVar('--ck-z-modal')}
              onChange={(data) => responseService.textChangeHandler(data, lessonElementId)}
              onFocus={() => styleService.setStyleVar('--ck-z-modal', '1')}
              readOnly={readOnly} toolbar={toolbar} />
          </div>
        ) : (
          <input className='short-text-response text-input ' onChange={(event) => responseService.textChangeHandler(event, lessonElementId)}
            placeholder={t('enterHere')}
            readOnly={readOnly} tabIndex='0'
            type='text' value={currentResponseAnswer.text} />
        )}
      </div>
    </div>
  );
});

export default ShortText;
