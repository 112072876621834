import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import itemBankService from '../services/ItemBankService';

const DeleteGroupFromAssessmentButton = (props) => {
  const { itemIds } = props;
  const { refreshCallback, setErrorMessage } = props;
  const addRef = useRef();

  useAccessibilityClick(addRef, () => {
    const doDelete = async () => {
      const status = await itemBankService.removeItemsFromLessonService(itemIds);
      if (status && status !== 'SUCCESS') {
        setErrorMessage(`Error removing item from assessment: ${status}`);
      } else {
        if (refreshCallback) {
          await refreshCallback();
        }
      }
    };
    ReactTooltip.hide();
    doDelete();
  });

  return (
    <>
      <ReactTooltip border effect='solid' type='light' />
      <div className='del-control-container'>
        <div ref={addRef} aria-label='remove item from assessment' className='delete-from-assessment-button' data-tip='Remove group from assessment.'
          data-tool='quick remove' />
        <div className='del-control-label'>Remove Group</div>
      </div>
    </>
  );
};

export default DeleteGroupFromAssessmentButton;
