import React, { useContext, useRef } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import ItemBankCard from './ItemBankCard';
import Loader from '../../lessonPlayer/components/Loader';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';

import '../../css/ResourceCartModal.scss';
import { MobXProviderContext, observer } from 'mobx-react';

const ResourceCartModal = observer(() => {
  const {
    itemBankManager
  } = useContext(MobXProviderContext);

  const cancelRef = useRef();
  const saveRef = useRef();

  useAccessibilityClick(cancelRef, (e) => {
    itemBankManager.clearCartRemovals();
    itemBankManager.toggleResourceCart();
  });

  useAccessibilityClick(saveRef, (e) => {
    for (const id of itemBankManager.cartRemovals) {
      itemBankManager.changeSelectedBankItemArray(id, false);
    }
    itemBankManager.clearCartRemovals();
    itemBankManager.toggleResourceCart();
  });

  const handleChange = (e) => {
    const id = e.target.value;
    const isChecked = e.target.checked;
    itemBankManager.changeCartRemovals(id, isChecked);
  };

  function closeResources() {
    itemBankManager.clearCartRemovals();
    itemBankManager.toggleResourceCart();
  }

  return (
    <div className='resource-cart'>
      <ReactModal
        disableKeystroke={true}
        disableMove={true}
        disableResize={true}
        initHeight={650}
        initWidth={800}
        isOpen={true}>
        <div className='resource-cart-header'>
          <div className='title-label large'>Selected Items</div>
          <div aria-label='close dialog' className='x-close-button' onClick={closeResources} role='button'
            tabIndex='0' />
        </div>
        <div className='resource-content'>
          {
            (itemBankManager.itemBankItemsSelected && itemBankManager.itemBankItemsSelected.length > 0) ?
              itemBankManager.itemBankItemsSelected.map((item) => {
                const isSelected = !itemBankManager.cartRemovals.includes(item.id);
                return (
                  <ItemBankCard key={`${item.id}_ibcard`} customChangeHandler={handleChange} isSelected={isSelected} isShopping={true}
                    libraryCardParams={item} skipRemove={true} />
                );
              }) : <Loader />
          }
        </div>
        <div className='resource-cart-footer'>
          <div className='button-wrapper'>
            <button ref={saveRef} className='add-items-button control-button'>Save</button>
            <button ref={cancelRef} className='preview-button control-button'>Cancel</button>
          </div>
        </div>

      </ReactModal>
    </div>
  );
});

export default ResourceCartModal;
