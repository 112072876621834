import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { LessonMode } from '../Constants';
import BuilderLayout from '../assessmentBuilder/layout/BuilderLayout';

// remove manuscript when all components are built and manuscript items are removed from
// preview, graded and review modes
import '../css/AssesmentBuilder.scss';

const AssessmentBuilder = observer(() => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  useEffect(() => {
    const className = `${LessonMode.ASSESSMENT_BUILDER} builder-theme-override`;
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute('class', className);
  });

  return (
    <>
      <div className='lesson-window Assessment-Builder'>
        <BuilderLayout />
      </div>
    </>
  );
});
export default AssessmentBuilder;
