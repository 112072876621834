import React from 'react';
import HtmlComponent from '../../HtmlComponent';

const ArticleWidget = (props) => {
  const { model } = props;
  model.entityTypeId = model.entityTypeId || 'article_resource';

  if (model.contentItem && model.contentItem.resource && model.contentItem.resource.articleHtml) {
    model.text = model.contentItem.resource.articleHtml;
  }

  const display = (model.display) ? model.display : 'visible';
  const text = model.text || 'Text Block';

  return (
    <div className={`widget article ${display}`} id={model.id} style={{ display }}>
      <div className='widgetText widgetContent ' data-allow='1'><HtmlComponent htmlStr={text} /></div>
    </div>
  );
};

export default ArticleWidget;
