import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
import Auth from '../../services/AuthService';
import '../../../css/Keyboarding.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';
import UtilsService from '../../services/UtilsService';

const Keyboarding = ({ lessonElementId }) => {
  const {
    lessonManager,
    studentActivityManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    let activityUrl = '';

    if (studentActivityManager) {
      const { activityId } = studentActivityManager;
      if (!UtilsService.isNullOrEmpty(activityId)) {
        activityUrl = `&activityId=${activityId}`;
      }
    }
    const tempPreviewUrl = `${Auth.ecms}/api/launchTypingMasterResource?authKey=${Auth.authKey}&contentItemId=${model.contentItemId}${activityUrl}`;

    setPreviewUrl(tempPreviewUrl);
  }, []);

  useStyleEvents(lessonElementId);

  return (
    <div className='webpage-activity-question'>
      <div className='test-item-question lesson-element webpage-question iframe' id={`${lessonElementId}-webpage-view`}>
        <iframe key={lessonElementId} allowFullScreen={true} id={`${lessonElementId}-webpage-iframe`} src={previewUrl}
          title={model.title} />
      </div>

    </div>
  );
};
export default Keyboarding;
