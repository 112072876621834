import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import SummaryCardList from './SummaryCardList';
import HtmlComponent from '../HtmlComponent';

const SummarySection = observer(({ lessonElementId }) => {
  // eslint-disable-next-line no-empty-pattern
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const toogleRef = useRef();

  const section = lessonManager.getSectionModel(lessonElementId);

  // TODO remove per CF-3048
  // let title = section.title;
  // if (!title) {
  //   title = `Section ${section.sectionNumber}`;
  // }

  return (
    <div className='summary-section-wrapper'>
      <div ref={toogleRef} className='summary-toggle-button'>
        <div className='summary-section-name'> <HtmlComponent htmlStr={section?.title || ''} /></div>
      </div>
      <div className={classNames({ 'summary-card-list-wrapper': true, 'open': true })}>
        <SummaryCardList sectionLessonElementId={lessonElementId} />
      </div>
    </div>
  );
});

export default SummarySection;
