import React, { useEffect, useRef, useState } from 'react';
import Moveable from 'react-moveable';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import '../../../css/Tools.scss';

function Protractor() {
  const [target, setTarget] = useState();
  // eslint-disable-next-line no-unused-vars
  const [frame, setFrame] = useState({
    translate: [0, 0],
    rotate: 0,
    transformOrigin: '50% 50%',
  });

  const moveableRef = useRef();
  const pivotRef = useRef();

  useAccessibilityClick(pivotRef, (e) => {
    let snapTo = 0;
    const { rotate } = frame;

    if (rotate === 0) {
      snapTo = 90;
    } else if (rotate === 90) {
      snapTo = 180;
    } else if (rotate === 180) {
      snapTo = 270;
    } else if (rotate === 270) {
      snapTo = 0;
    } else {
      snapTo = Math.abs(rotate);
      snapTo = Math.round(rotate / 90) * 90;
    }

    // console.log(' snapTo ' + snapTo);

    moveableRef.current.moveable.request('rotatable', { rotate: snapTo }, true);

    // console.log(' rotate ' + rotate);
  });

  // CF-4140: Added to fix bug that continued drag even after mouse click.
  const stopDrag = (_event) => {
    moveableRef.current.moveable.stopDrag();
  };

  useEffect(() => {
    setTarget(document.querySelector('.protractorTarget'));
    document.addEventListener('mouseup', stopDrag);
    return () => {
      document.removeEventListener('mouseup', stopDrag);
    };
  }, []);

  return (
    <div className='protractorContainer'>
      <div className='protractorTarget' id='protractorTool'>
        <button ref={pivotRef} id='protractorToolRotate90Button' />
        <div id='protractorToolRotator'>
          <div id='protractorToolRotateHandle' />
        </div>
      </div>
      <Moveable
        ref={moveableRef}
        draggable={true}
        onDrag={(e) => {
          frame.translate = e.beforeTranslate;
        }}
        onDragOrigin={(e) => {
          frame.translate = e.drag.beforeTranslate;
          frame.transformOrigin = e.transformOrigin;
        }}
        onDragOriginStart={(e) => {
          e.dragStart && e.dragStart.set(frame.translate);
        }}
        onDragStart={(e) => {
          e.set(frame.translate);
        }}
        onRender={(e) => {
          const { translate, rotate, transformOrigin } = frame;
          e.target.style.transformOrigin = transformOrigin;
          e.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
                    + ` rotate(${rotate}deg)`;
        }}
        onRotate={(e) => {
          // frame.rotate = e.beforeRotate;
          frame.rotate = (e.beforeRotate > 360 || e.beforeRotate < -360) ? 0 : e.beforeRotate;
        }}
        onRotateStart={(e) => {
          e.set(frame.rotate);
        }}
        origin={false}
        originDraggable={true}
        originRelative={true}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        rotatable={true}
        rotationPosition='left'
        startDragRotate={0}
        target={target}
        throttleDrag={0}
        throttleDragRotate={0}
        throttleRotate={0}
        zoom={1}
      />
    </div>
  );
}

export default Protractor;
