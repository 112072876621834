import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import { LessonMode, QuestionFeedbackState } from '../../Constants';
import { register } from '../../i18n';
import HtmlComponent from './HtmlComponent';
import responseService from '../services/ResponseService';
import HintDialog from './HintDialog';
import SolutionDialog from './SolutionDialog';

import '../../css/QuestionFeedback.scss';
import UtilsService from '../services/UtilsService';

const t = register('GlobalQuestionLabels');

const QuestionFeedback = observer((props) => {
  const { lessonElementId, isSurvey } = props;

  const {
    lessonManager,
    responseManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  const uiState = questionFeedbackManager.getUiState(lessonElementId);

  const showAnswers = (uiState) ? uiState.showAnswers : false;
  let showQuestionFeedback = (uiState) ? uiState.showQuestionFeedback : false;
  const feedBackString = responseService.getQuestionFeedbackText(lessonElementId);
  if (UtilsService.isNullOrEmpty(feedBackString)) {
    showQuestionFeedback = false;
  }

  const isCorrect = responseManager.isQuestionCorrect(lessonElementId);
  // TODO unused // const status = (studentActivityManager && studentActivityManager.activityInstanceStatus)?studentActivityManager.activityInstanceStatus:ActivityStatus.ACTIVITY_STARTED;
  const { hasFeedback } = questionFeedbackManager;
  const mode = lessonManager.playerMode;
  const isAuto = (responseService.isAutoScored(lessonElementId));
  const showAnswerText = responseService.getShowAnswerText(lessonElementId);
  const { noSubmit } = questionFeedbackManager;

  return (
    <>
      {(lessonElementState && lessonElementState.isTestItem) ? (
        <div className={classNames('question-feedback theme-container', { 'is-survey': isSurvey })}>
          {(showAnswers && showAnswerText) && (
            <div className='accepted-answers-feedback'>
              <div className='accepted-answers-feedback-heading'>
                {t('acceptedAnswers')}
              </div>
              <HtmlComponent htmlStr={showAnswerText} />
            </div>
          )}
          {((showQuestionFeedback && hasFeedback) || mode === LessonMode.REVIEW) && (
            <div className={classNames({
              'question-feedback-wrapper': true,
              'correct': (isCorrect === QuestionFeedbackState.CORRECT && (isAuto)),
              'incorrect': ((isCorrect === QuestionFeedbackState.INCORRECT || isCorrect === QuestionFeedbackState.PARTIAL_CORRECT)
             && (isAuto))

            })}>
              <HtmlComponent htmlStr={feedBackString} />
            </div>
          )}
          {(showQuestionFeedback && !hasFeedback && !noSubmit && mode !== LessonMode.REVIEW) && (
            <div className='question-feedback-wrapper'>
              <HtmlComponent htmlStr={feedBackString} />
            </div>
          )}
        </div>
      )
        : <div className='question-feedback theme-container' />}
      {questionFeedbackManager.openHintDialog && <HintDialog lessonElementId={lessonElementId} />}
      {questionFeedbackManager.openSolutionDialog && <SolutionDialog lessonElementId={lessonElementId} />}
    </>
  );
});

export default QuestionFeedback;
