import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import ReactModal from 'react-modal-resizable-draggable';
import { register } from '../../i18n';
import Loader from './Loader';
import responseService from '../services/ResponseService';
import UtilsService from '../services/UtilsService';
import HtmlComponent from './HtmlComponent';
import '../../css/SolutionDialog.scss';
import useReactModalResizeableDraggable from '../../hooks/useReactModalResizeableDraggable';

const t = register('GlobalQuestionLabels')

const SolutionDialog = observer((props) => {
  const {
    lessonManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  const { lessonElementId } = props;

  const model = lessonManager.getSlideModel(lessonElementId);
  const solution = (model.validation && model.validation.solution) ? model.validation.solution : null;
  const title = `${t('sampleAnswer')}`;

  const id = `${lessonElementId}_solutiondialog`;
  const isOpen = questionFeedbackManager.openSolutionDialog && (lessonElementId === lessonManager.currentLessonElementId);
  useEffect(() => {
    const dialog = document.getElementById(id);
    const flexibleModal = dialog.querySelector('.flexible-modal');
    if (dialog && flexibleModal) {
      const windowWidth = window.innerWidth;
      const styleString = `width: 500px; height: 200px; top: 0px; left: ${windowWidth - 515}px;`;
      flexibleModal.setAttribute('style', styleString);
      dialog.setAttribute('style', '');
    }
  });

  useReactModalResizeableDraggable('solution-dialog');

  return (

    <div className='solution-dialog theme-container' id={id} style={{ display: 'none' }}>
      {solution && (
        <ReactModal
          initHeight={130}
          initWidth={500}
          isOpen={isOpen}
          left={0}
          top={0}>
          <div className='solution-dialog-body'>
            {(!UtilsService.isNullOrEmpty(solution)) ? (
              <>
                <div className='solution-header'>
                  <div className='title-label basic'>{title}</div>
                  <div className='x-close-button mask-image' id='modal-close' onClick={() => {
                    responseService.setSolutionDialogOpen(false);
                  }} />
                </div>
                <div className='scroll-area'>
                  <HtmlComponent htmlStr={solution} />
                </div>
              </>
            )
              :
              <div className='dialog-loader'><Loader /></div>}
          </div>
        </ReactModal>
      )}
    </div>
  );
});

export default SolutionDialog;
