import { Behavior } from './Behavior';
import { ContentType, LessonMode, PartialScoringType, QuestionFeedbackState } from '../../Constants';
import UtilsService from '../services/UtilsService';
import { confirmDialog } from '../components/dialogs';

export class ImageHotSpotBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    if (responseAnswer && responseAnswer.prompts) {
      const prompt = responseAnswer.prompts.find((element) => element.text === 'selected');
      if (typeof prompt != 'undefined') {
        return true;
      }
    }
    return false;
  }

  isAutoScored = (model) => {
    const autoScore = (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);

    return autoScore;
  }

  getScore = (responseAnswer, model) => {
    let score = 0;
    let tempScore = 0;

    if (!model ||
      !model.validation ||
      !model.prompts ||
      !model.validation.prompts ||
      !responseAnswer ||
      !responseAnswer.prompts) {
      return 0;
    }

    const promptCount = model.prompts.length;

    responseAnswer.omittedList.clear();
    responseAnswer.selectedIncorrectList.clear();
    responseAnswer.trueCorrectList.clear();

    model.validation.prompts.forEach((validationPrompt, index) => {
      const studentResponse = responseAnswer.prompts.find((element) => element.id === validationPrompt.id);
      if (studentResponse) {
        const correctAnswer = (validationPrompt.correctAnswers) ? validationPrompt.correctAnswers[0] : ''; // with image hot spot this is only one
        const studentResponseText = (studentResponse.text);

        if (correctAnswer && studentResponseText) {
          const isMatch = studentResponseText === correctAnswer;
          if (isMatch) {
            tempScore += 1;
            if (studentResponseText === 'selected') {
              responseAnswer.trueCorrectList.push(studentResponse.id);
            }
          }
          if (studentResponseText === 'unselected' && correctAnswer === 'selected') {
            responseAnswer.omittedList.push(studentResponse.id);
          }
          if (studentResponseText === 'selected' && correctAnswer === 'unselected') {
            responseAnswer.selectedIncorrectList.push(studentResponse.id);
          }
        }
      }
    });

    if (model.scoringType === PartialScoringType.EXACT) {
      score = (tempScore === promptCount) ? model.maxScore : 0;
    } else if (model.scoringType === PartialScoringType.PARTIAL) {
      score = tempScore / promptCount * model.maxScore;
    } else if (model.scoringType === PartialScoringType.PARTIAL_MATCH_RESPONSE) {
      score = tempScore;
    }

    return score;
  }

  setResponseData = async (prompt, responseAnswer, model) => {
    const savedPrompt = responseAnswer.prompts.find((element) => element.id === prompt.id);
    if (savedPrompt.text === 'selected') {
      savedPrompt.text = 'unselected';
    } else {
      if (model.correctAnswerCount <= 1) {
        savedPrompt.text = 'selected';
        responseAnswer.prompts.forEach((element) => {
          if (element.id !== prompt.id) {
            element.text = 'unselected';
          }
        });
      } else if (model.allowSelectAll) {
        savedPrompt.text = 'selected';
      } else {
        const currentSelected = responseAnswer.prompts.filter((element) => element.text === 'selected');
        const selectedCount = (currentSelected) ? currentSelected.length : 0;

        if ((model.correctAnswerCount <= selectedCount && !model.unscored)) {
          await confirmDialog({
            title: `${UtilsService.maxSelected()}`,
            text: `${UtilsService.deselect()}`,
            showCancelButton: false,
            confirmButtonText: `${UtilsService.confirmOk()}`
          });
        } else {
          savedPrompt.text = 'selected';
        }
      }
    }
  }

  getInitializedResponse = (lessonElementId, model) => {
    const prompts = [];
    model.prompts.forEach((prompt, index) => {
      const newPropmt = {
        id: prompt.id,
        text: 'unselected'
      };
      prompts.push(newPropmt);
    });
    const responseAnswer = {
      prompts,
      lessonElementId,
      selectedIncorrectList: [],
      omittedList: [],
      trueCorrectList: []
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    if (!model ||
      !model.prompts ||
      !model.validation ||
      !model.validation.prompts) {
      return '<div></div>';
    }

    let text = '';
    let count = 1;
    model.validation.prompts.forEach((prompt) => {
      const renderPrompt = model.prompts.find((element) => element.id === prompt.id);
      let displayLabel = null;

      if (renderPrompt) {
        displayLabel = renderPrompt.displayLabel;
      }

      const resultLabel = (prompt.correctAnswers) ? prompt.correctAnswers[0] : 'unselected';
      const label = (!UtilsService.isNullOrEmpty(displayLabel)) ? `${displayLabel} ${resultLabel}` : resultLabel;

      text += '<div class=\'answer-list-row\'>';
      text = `${text }<div class='list-number'>${count}</div>`;
      text += '<div class=\'correct-icon\'>';
      text += '</div><div class=\'answer-text\'>';
      text = `${text + label}</div>`;
      text += '</div>';
      ++count;
    });
    return text;
  }

  setCorrectAnswer = (state, model) => {
    const tempPrompts = [];
    state.currentResponseAnswer.prompts.forEach((prompt) => {
      const validPrompt = model.validation.prompts.find((element) => element.id === prompt.id);
      if (validPrompt) {
        if (validPrompt.correctAnswers && validPrompt.correctAnswers.length > 0) {
          tempPrompts.push({
            text: validPrompt.correctAnswers[0],
            id: validPrompt.id
          });
        }
      }
    });
    const temp = {
      prompts: tempPrompts,
      lessonElementId: model.lessonElementId,
      selectedIncorrectList: [],
      omittedList: [],
      trueCorrectList: []
    };

    state.setCurrentResponse(UtilsService.safeMobxClone(temp));
  }

  resetStudentAnswer = (state) => {
    const cached = UtilsService.safeMobxClone(state.cachedResponseAnswer);
    state.setCurrentResponse(cached);
  }

  /// ///
  // model = content model
  //
  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let numberOmitted = 0;
    let numberSelectedIncorrect = 0;
    let correctCount = 0;
    if (lessonElementState && lessonElementState.currentResponseAnswer) {
      numberOmitted = (lessonElementState.currentResponseAnswer.omittedList) ? lessonElementState.currentResponseAnswer.omittedList.length : 0;
      numberSelectedIncorrect = (lessonElementState.currentResponseAnswer.selectedIncorrectList) ? lessonElementState.currentResponseAnswer.selectedIncorrectList.length : 0;
      correctCount = (lessonElementState.currentResponseAnswer.trueCorrectList) ? lessonElementState.currentResponseAnswer.trueCorrectList.length : 0;
    }

    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    const showPartialMessage = (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) || (questionIsCorrect === QuestionFeedbackState.INCORRECT);
    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if ((showPartialMessage) && (model.correctAnswerCount > 1) && (correctCount > 0)) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
      }
      if (numberOmitted > 0 && numberSelectedIncorrect === 0) {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt2()}.<br/>${ custom }${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody}<br/> ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model, isPracticeRunning) => {
    if (!model ||
      !model.validation ||
      !model.prompts ||
      !model.validation.prompts ||
      !lessonElementState ||
      !lessonElementState.currentResponseAnswer ||
      !lessonElementState.currentResponseAnswer.prompts) {
      return null;
    }
    const response = lessonElementState.currentResponseAnswer;
    const answer = lessonElementState.currentResponseAnswer.prompts.find((element) => element.id === answerId);
    // TODO unused // const validationPrompt = model.validation.prompts.find(element => element.id === answerId);
    const selected = answer.text === 'selected';
    const notSelected = answer.text === 'unselected';
    if (isPracticeRunning) {
      if (answer && response.selectedIncorrectList && response.omittedList && response.trueCorrectList) {
        if (selected && response.selectedIncorrectList.indexOf(answer.id) > -1) {
          return false;
        }
        if (notSelected && response.omittedList.indexOf(answer.id) > -1) {
          return null;
        }
        if (selected && response.trueCorrectList.indexOf(answer.id) > -1) {
          return true;
        }
      }
    } else {
      const prompts = model.validation?.prompts;
      const prompt = prompts.find((element) => element.id === answerId);
      const correctAnswer = (prompt.correctAnswers) ? prompt.correctAnswers[0] : '';

      if (correctAnswer && answer && correctAnswer === 'selected' && answer.text === 'selected') {
        return true;
      } else if (correctAnswer && answer && correctAnswer === 'unselected' && answer.text === 'unselected') {
        return null;
      } else if (correctAnswer && answer && correctAnswer === 'unselected' && answer.text === 'selected') {
        return false;
      } else if (correctAnswer && answer && correctAnswer === 'selected' && answer.text === 'unselected') {
        return false;
      }
    }
    return null;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new ImageHotSpotBehavior();
