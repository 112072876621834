import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import responseManager from '../../managers/ResponseManager';
import scoringManager from '../../managers/ScoringManager';
import scoringService from '../../services/ScoringService';
import { LessonMode } from '../../../Constants';
import UtilsService from '../../services/UtilsService';
import { register } from '../../../i18n';

const t = register('PanelMenu');
const t2 = register('AriaLabels');

const RubricScoreTable = observer(({ lessonElementId }) => {
  const {
    scoringManager,
    responseManager,
    lessonManager
  } = useContext(MobXProviderContext);

  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const rubric = scoringManager.rubricMap.get(lessonElementId);
  const mode = lessonManager.playerMode;
  const teacherScoreRef = useRef();

  const handleChange = (event, categoryId) => {
    const str = event.target.value;
    if (UtilsService.ensureNumericInput(str)) {
      scoringService.setCategoryTempScoreFromTable(lessonElementId, categoryId, str);
    }
  };

  const handleBlur = (event, categoryId, maxPoints) => {
    let str = event.target.value;

    if (UtilsService.isNullOrEmpty(str) || str.trim() === '.') {
      scoringService.setCategoryTempScoreFromTable(lessonElementId, categoryId, '0.00');
      return;
    }
    if (UtilsService.ensureNumericInput(str)) {
      const num = parseFloat(str);
      const maxScore = parseFloat(maxPoints);
      if (num > maxScore) {
        str = maxScore.toFixed(2);
      }
      if (num < 0) {
        str = '0.00';
      }
      scoringService.setCategoryTempScoreFromTable(lessonElementId, categoryId, str);
    }
  };
  const fieldValue = (category) => {
    return (typeof category.calculatedTempScore != 'undefined') ? category.calculatedTempScore : '';
  };

  useEffect(() => {
    if (lessonElementState && lessonElementState.subscore) {
      scoringManager.setSubscore(lessonElementId, JSON.parse(lessonElementState.subscore), lessonElementState.isScored);
    }
    if (teacherScoreRef && teacherScoreRef.current) {
      teacherScoreRef.current.focus();
    }
  }, []);

  return (
    <>
      <table className='rubric-score-table'>
        <tbody>
          {rubric.categories.map((category, idx) => {
            const extraProps = idx == 0 ? { ref: teacherScoreRef } : {}; // set ref for first score box in order to preselect
            return (
              <tr key={`${category.categoryId}_scoring-table`} className='rubric-score-row'>
                <td className='category-name'>{category.name}:</td>
                <td className='category-score'>
                  {(mode === LessonMode.SCORING) && (
                    <input
                      aria-label={t2('categoryfinalscore')}
                      onBlur={(e) => {
                        handleBlur(e, category.categoryId, category.maxPoints);
                      }}
                      onChange={(e) => {
                        handleChange(e, category.categoryId);
                      }}
                      type='text'
                      value={fieldValue(category)}
                      {...extraProps} />
                  )}
                  {(mode === LessonMode.REVIEW) && <span>{category.calculatedTempScore}</span>}
                  <span>/</span><span>{category.maxPoints}</span>
                </td>
              </tr>
            );
          })}

        </tbody>
      </table>
      <div className='total-box'>
        <div className='category-name'>{t('total')}:</div>
        {(mode === LessonMode.SCORING) && (
          <div className='category-score'>
            <span>{scoringManager.tempScores.get(lessonElementId)}</span><span> /</span> <span>{lessonElementState.maxScore}</span>
          </div>
        )}
        {(mode === LessonMode.REVIEW) && (
          <div className='category-score review'>
            <span>{lessonElementState.scoreValue}</span><span>/</span><span>{lessonElementState.maxScore}</span>
          </div>
        )}
      </div>
    </>
  );
});
export default RubricScoreTable;
