import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactTooltip from 'react-tooltip';

import classNames from 'classnames';

import styleService from '../../services/StyleService';
import utilsService from '../../services/UtilsService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import ToolbarSideScrollMenu from './ToolbarSideScrollMenu';
import ToolbarButton from './ToolbarButton';

const ToolbarDropdownMenu = observer(({ buttonGroupSettings, items, className, MenuItemComponent }) => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const [toolbarButtonLabelEllipsifyValue, setToolbarButtonLabelEllipsifyValue] = useState('');

  const menuRef = useRef();
  const menuButtonRef = useRef();

  const showMenu = (event) => {
    event.preventDefault();
    toolbarManager.toggleMenuButton(buttonGroupSettings.id);
  };

  useEffect(() => {
    const closeMenu = (event) => {
      if (!menuRef.current) {
        return;
      }
      if (!menuRef.current.contains(event.target)) {
        toolbarManager.toggleMenuButton(buttonGroupSettings.id, false);
        document.removeEventListener('click', closeMenu);
      }
    };
    document.addEventListener('click', closeMenu);

    const _toolbarButtonLabelEllipsifyValue = styleService.getStyleVar('--toolbar-button-label-ellipsify');
    setToolbarButtonLabelEllipsifyValue(_toolbarButtonLabelEllipsifyValue || '');

    return (
      () => { document.removeEventListener('click', closeMenu); }
    );
  });

  useAccessibilityClick(menuButtonRef, showMenu);

  const buttons = [];
  if (items) {
    for (let i = 0; i < items.length; ++i) {
      const value = items[i];

      if (value.type === 'group') {
        const buttonSettings = toolbarManager.buttonGroupSettings.get(value.id);
        buttonSettings.children = value.children;
        if (buttonSettings && buttonSettings.visible) {
          buttons.push(buttonSettings);
        }
      } else {
        const buttonSettings = toolbarManager.buttonSettings.get(value.id);
        if (buttonSettings && buttonSettings.visible) {
          buttons.push(value);
        }
      }
    }
  }

  const renderToolbarDropdownMenuLabel = () => {
    const allowEllipsify = toolbarButtonLabelEllipsifyValue !== 'none';

    const buttonGroupSettingsName = buttonGroupSettings.name();

    const dataTip = allowEllipsify ? (
      utilsService.stripTagsAndEntities(buttonGroupSettingsName)
    ) : undefined;

    return (
      <>
        <div className={classNames('dropdown-label', {
          'toolbar-button-label-ellipsify': allowEllipsify
        })} data-tip={dataTip} tabIndex='-1'>
          {(buttonGroupSettings.id === 'overflowGroup') ? '' : buttonGroupSettingsName}
        </div>

        {allowEllipsify ? (
          <ReactTooltip border className='react-tooltip' effect='solid'
            place='bottom' type='light' />
        ) : null}
      </>
    );
  };

  return (
    <div ref={menuRef} className={`${className} dropdown-menu ${(buttonGroupSettings.open) ? 'menuShowing' : ''}`} tabIndex='-1'>
      <div ref={menuButtonRef} aria-label={`${buttonGroupSettings.name()}`} className={`dropdown-button-wrapper ${buttonGroupSettings.id}`} role={buttons.length > 0 ? 'menu' : 'menuitem'}
        tabIndex='0'>
        {buttonGroupSettings.icon && (
          <div className={`toolbar-icon-wrapper ${buttonGroupSettings.id}`} tabIndex='-1'>
            <div className={`toolbar-icon ${buttonGroupSettings.id}`} tabIndex='-1' />
          </div>
        )}
        {renderToolbarDropdownMenuLabel()}
        <div className='carrot mask-image' tabIndex='-1' />
      </div>

      {
        (buttonGroupSettings.open)
          ? (
            <div className='dropdown-menu-popup open'>
              {buttons.map((button) => {
                return (
                  <div key={`${button.id}-menu-button`} className='toolbar-dropdown'>
                    {
                      (button.type === 'group') ? (
                        <ToolbarSideScrollMenu MenuItemComponent={ToolbarButton}
                          buttonGroupSettings={button}
                          className={`toolbar-dropdown ${button.id} ${(!button.visible) ? 'visible-none' : ''}`}
                          items={button.children}
                        />
                      ) :
                        <MenuItemComponent aRole='menuitem' buttonId={button.id} menuId={buttonGroupSettings.id} />
                    }
                  </div>
                );
              })}
            </div>
          )
          :
          <div key={`${buttonGroupSettings.id}-menu-button`} aria-hidden='true' className='dropdown-menu-popup closed' />

      }
    </div>
  );
});
export default ToolbarDropdownMenu;
