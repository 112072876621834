import { renderToStaticMarkup } from 'react-dom/server';

import { MathJax, MathJaxContext } from 'better-react-mathjax';

import classNames from 'classnames';

import parse, { domToReact } from 'html-react-parser';

import { debounce } from 'lodash';

import { ContentType } from '../../Constants';

// TODO remove // import eventListenerManager from '../managers/EventListenerManager';
import lessonManager from '../managers/LessonManager';
import responseManager from '../managers/ResponseManager';
import toolbarManager from '../managers/ToolbarManager';

import clozeMultichoiceService from './ClozeMultichoiceService';
import dropdownService from './DropdownService';
import htmlService from './HtmlService';
import playerService from './PlayerService';
import promptService from './PromptService';
import questionService from './QuestionService';
import responseService from './ResponseService';
import utilsService from './UtilsService';

export default class ClozeMathService {
  static renderClozeMathBody = ({
    lessonElementId,
    lessonElementState,
    modalBody,
    modalLeft,
    modalTop,
    model,
    parsedClozeBody,
    setModalBody,
    setModalLeft,
    setModalTop
  } = {}) => {
    const handleClozeMathKey = (event) => {
      if (event.key === ' ' || event.key === 'Enter') {
        handleClozeMathClick(event);
      }
    };
    const handleClozeMathClick = (event) => {
      if (questionService.getCurrentQuestionType() === ContentType.CLOZE_MATH.type) {
        htmlService.closeMathTypeModal();

        this.handleUpdateMathJaxClozePrompt({
          event,
          lessonElementId,
          lessonElementState,
          modalBody,
          modalLeft,
          modalTop,
          model,
          parsedClozeBody,
          setModalBody,
          setModalLeft,
          setModalTop
        });
      }
    };

    // TODO remove
    // const { eventListenerHelper } = eventListenerManager;

    return (
      <MathJaxContext config={{
        chtml: { scale: 1.5 },
        options: { enableMenu: false }
      }}>
        <MathJax dynamic hideUntilTypeset='every' inline
          // TODO remove
          // onInitTypeset={() => {
          //   const clozeMathElement = document.getElementsByClassName('ClozeMath')?.[0];
          //   eventListenerHelper.addEventListener(clozeMathElement, 'click', handleClozeMathClick, {
          //     listenerName: lessonElementId
          //   });
          // }}
        />
        {parse(renderToStaticMarkup(parsedClozeBody), {
          replace: (domNode) => {
            if (model.mathText && domNode.name === 'math') {
              const mathStr = renderToStaticMarkup(domToReact([domNode]));
              return (
                <MathJax className='math-jax-body-section' dynamic inline
                  onClick={handleClozeMathClick} onKeyUp={handleClozeMathKey} renderMode='pre'
                  text={mathStr} typesettingOptions={{ fn: 'mathml2chtml' }} />
              );
            }
          }
        })}
      </MathJaxContext>
    );
  }

  static handleChangeMathJaxEditor = ({
    ckeditorInstance,
    modalBody,
    modalLeft,
    modalTop,
    setModalBody,
    setModalLeft,
    setModalTop
  } = {}) => {
    const { lessonElementId } = ckeditorInstance;

    if (!lessonElementId) {
      return null;
    }

    ckeditorInstance = ckeditorInstance || this.getHiddenCkeditorInstanceFromDOM();
    const { dataId } = ckeditorInstance;
    const model = lessonManager.getSlideModel(lessonElementId);
    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
    this.handleUpdateMathJaxClozePrompt({
      dataId,
      fromEditor: true,
      lessonElementId,
      lessonElementState,
      modalBody,
      modalLeft,
      modalTop,
      model,
      setModalBody,
      setModalLeft,
      setModalTop
    });
  }

  static handleUpdateMathJaxClozePrompt = (props = {}) => {
    const {
      lessonElementId,
      event,
      fromEditor = false,
      lessonElementState,
      modalBody,
      modalLeft,
      modalTop,
      model,
      setModalBody,
      setModalLeft,
      setModalTop
    } = props;

    let { dataId } = props;

    const closestDataIdElement = event?.target?.closest?.('[data-id]');
    dataId = closestDataIdElement?.getAttribute?.('data-id') || dataId;

    if (!dataId || playerService.isReadOnly(lessonElementId)) {
      return null;
    }
    const ckeditorInstance = htmlService.getHiddenCkeditorInstanceFromDOM();

    const isStaleData = () => questionService.activityWithQuestions(props.lessonElementId) && ckeditorInstance.lessonElementId && ckeditorInstance.lessonElementId !== lessonElementId;
    if (isStaleData()) {
      // data is not up to date, retry
      htmlService.resetHiddenCkeditorInstance();
      debounce(() => {
        this.handleUpdateMathJaxClozePrompt(props);
      }, 200)();
      return;
    } else {
      ckeditorInstance.lessonElementId = dataId ? lessonElementId : null;
    }

    if (dataId && ckeditorInstance?.execute) {
      const initialClozeMathPromptText = (model && !utilsService.isNullOrEmpty(model.mathText)) ? renderToStaticMarkup(
        this.renderClozeMathBlankPlaceholder(dataId, model)
      ) : '';
      const modelPrompt = promptService.getCurrentPrompt({
        dataId,
        lessonElementState,
        model,
        promptType: 'anyModelPrompt'
      });
      const responseAnswerPrompt = promptService.getCurrentPrompt({
        dataId,
        lessonElementState,
        model,
        promptType: 'responseAnswerPrompt'
      });

      const editorData = ckeditorInstance.dataId === dataId ? ckeditorInstance.getData() : '';

      let currentUserInputValue = utilsService.stripWrappingParagraphTags(
        editorData || lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text?.label
        || lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text
        || responseAnswerPrompt?.text || initialClozeMathPromptText || ''
      );

      currentUserInputValue = typeof currentUserInputValue === 'string' ? currentUserInputValue : '';

      currentUserInputValue = htmlService.getPolyfilledHtmlString(currentUserInputValue);

      currentUserInputValue = renderToStaticMarkup(parse(currentUserInputValue, {
        replace: (domNode) => {
          if (domNode.name === 'math') {
            this.appendDataIdToMathDomNodeThenConvertToMencloseIfApplicable(domNode, dataId, model);
          } else if (domNode.name === 'mo' || domNode.name === 'mn') {
            if (domNode.children?.[0]?.type === 'text' && !domNode.children[0].data?.trim?.()) {
              return <></>;
            }
          }
        }
      }));

      if (modelPrompt?.answerType === 'multichoice' || modalBody) {
        // ClozeMath Multichoice
        if ((model && !utilsService.isNullOrEmpty(model.mathText)) && !modalBody) {
          const domNode = undefined;
          const updatedModalBody = clozeMultichoiceService.renderClozeMultichoiceBlankSection(domNode, {
            // readOnly,
            // uiState,
            dataId,
            lessonElementId,
            lessonElementState,
            modalLeft: modalLeft || event?.clientX,
            modalTop: modalTop || event?.clientY,
            model,
            modalBody,
            setModalBody,
            setModalLeft,
            setModalTop,
            userInputMap: lessonElementState.currentResponseAnswer.userInputMap
          });

          if (closestDataIdElement?.parentElement) {
            if (currentUserInputValue?.includes?.('data-id')) {
              const customDropdownOptions = dropdownService.getDropdownOptions({
                dataId,
                model,
                prependSelectLabel: (model && !utilsService.isNullOrEmpty(model.mathText))
              });
              const selectedDropdownOption = customDropdownOptions.find((option) => {
                return option.label === currentUserInputValue;
              });
              responseService.textChangeHandler(
                selectedDropdownOption || {}, lessonElementId, {
                  dataId,
                  lessonElementState
                }
              );
              const domRect = closestDataIdElement.parentElement.getBoundingClientRect?.();
              // const domRectTopOffset = -120;
              const domRectTopOffset = 0;
              setModalLeft(domRect.left);
              setModalTop(domRect.top + domRectTopOffset);
              setModalBody(updatedModalBody);
            }
          }
        }
      } else {
        // ClozeMath Typing
        ckeditorInstance.dataId = dataId || null;
        // TODO remove // ckeditorInstance.lessonElementId = dataId ? lessonElementId : null;
        ckeditorInstance.modelPrompt = dataId ? modelPrompt : null;
        ckeditorInstance.responseAnswerPrompt = dataId ? responseAnswerPrompt : null;

        if (!fromEditor) {
          // trigger MathType modal via hidden ckeditorInstance
          ckeditorInstance.setData(renderToStaticMarkup(parse(currentUserInputValue || '', {
            replace: (domNode) => {
              if ((model && !utilsService.isNullOrEmpty(model.mathText)) && domNode.name === 'menclose') {
                // temporarily convert 'menclose' tags to 'math' so MathType editor can recognize & render the MathML properly
                // the tags will be converted back to 'menclose' once the user has finished editing the data
                domNode.attribs = {
                  ...domNode.attribs,
                  class: classNames('cloze-math-input', {
                    'empty-cloze-math-input': this.isClozeMathBlankPlaceholder(domNode)
                  }),
                  tabIndex: 0
                };
                domNode.name = 'math';
              }
            }
          })));
          ckeditorInstance.execute('selectAll');
          // console.log(ckeditorInstance);
          ckeditorInstance.execute('MathType', {
            integration: window.currentWirisInstanceForHiddenCkeditor
          });
        } else if (currentUserInputValue?.includes?.('data-id')) {
          // store MathType modal data changes
          responseService.textChangeHandler(
            currentUserInputValue, lessonElementId, {
              dataId,
              lessonElementState
            }
          );
        }
      }
    }
  }

  static appendDataIdToMathDomNodeThenConvertToMencloseIfApplicable = (domNode, dataId, model) => {
    if (domNode.name === 'math') {
      // xmlns is a <math> wrapper namespace - we want to exclude that because we are changing the tag to a <menclose> wrapper
      const { xmlns, ...attribs } = domNode.attribs;
      const forceShowAnswers = toolbarManager.isToolbarAnswerFeedbackActive;

      domNode.attribs = (model && !utilsService.isNullOrEmpty(model.mathText)) ? {
        ...attribs,
        'class': classNames('cloze-math-input', {
          'empty-cloze-math-input': this.isClozeMathBlankPlaceholder(domNode),
          'correct': forceShowAnswers
        }),
        'data-id': dataId,
        'notation': domNode.attribs?.notation || 'none',
        'tabIndex': 0
      } : {
        ...attribs,
        'data-id': dataId
      };

      if ((model && !utilsService.isNullOrEmpty(model.mathText))) {
        domNode.name = 'menclose';
      }
      return domNode;
    }
  }

  static renderClozeMathBlankPlaceholder = (dataId, _model, innerData = '') => {
    return (
      <menclose
        className={classNames({
          'cloze-math-input': innerData,
          'empty-cloze-math-input': !innerData
        })}
        data-id={dataId}
        notation='none' tabIndex={0}>
        {innerData ? <mn>{innerData}</mn> : <mn />}
      </menclose>
    )/*: <math data-id={dataId} /> */;
  }

  static isClozeMathBlankPlaceholder = (domNode) => {
    return !!(
      domNode.children?.length === 1 && domNode.children[0]?.name === 'mn' &&
        Array.isArray(domNode.children[0].children) && (
        !domNode.children[0].children.length || (
          domNode.children[0].children?.length === 1 &&
          domNode.children[0].children[0]?.type === 'text' &&
          domNode.children[0].children[0].data?.trim?.()?.length === 0
        )
      )
    );
  }

  // eslint-disable-next-line no-empty-pattern
  static getClozeMathPromptElementsMap = ({
    lessonElementId
  } = {}) => {
    const lessonElementWrapper = lessonElementId ? document.getElementById(`${lessonElementId}-standalone-wrapper`) : undefined;

    const promptElements = (lessonElementWrapper || document)?.getElementsByTagName?.('mjx-menclose') || [];

    const promptElementsMap = new Map();
    for (const promptElement of promptElements) {
      const dataId = promptElement.getAttribute('data-id');
      if (dataId) {
        promptElementsMap.set(dataId, promptElement);
      }
    }
    return promptElementsMap;
  }

  static toggleFeedbackIconClasses = (lessonElementId, model, lessonElementState, showAnswerFeedback) => {
    const promptElementsMap = this.getClozeMathPromptElementsMap({ lessonElementId });

    if (model?.validation?.prompts) {
      for (const prompt of model.validation.prompts) {
        const currentPromptElement = promptElementsMap.get(prompt.id);
        if (!currentPromptElement) {
          return undefined;
        }
        if (!showAnswerFeedback) {
          currentPromptElement.classList.remove('correct');
          currentPromptElement.classList.remove('incorrect');
        } else {
          const isAnswerCorrect = responseService.isAnswerCorrect(lessonElementId, prompt.id, { lessonElementState, model });
          if (isAnswerCorrect) {
            currentPromptElement.classList.add('correct');
          } else {
            currentPromptElement.classList.add('incorrect');
          }
        }
      }
    }
  }
}
