import { action, makeObservable, observable } from 'mobx';

export default class ItemBankItem {
  @observable
  id = null;

  @observable
  lessonElementId = null;

  @observable
  backgroundImg = null;

  @observable
  libraryResource = {};

  @observable
  visibleInResourceBucket = true;

  @observable
  orderNum = 0;

  @observable
  childElementIds = [];

  placeHolder = false;

  cardClassName = 'item-bank';

  constructor() {
    makeObservable(this);
  }

  @action
  initItem = (data) => {
    this.id = data.id;
    this.backgroundImg = data.fullImageUrl || data.imageUrl;
    this.libraryResource = data;
  }

  @action
  setVisibleInResourceBucket = (visible) => {
    this.visibleInResourceBucket = visible;
  }

  @action
  setLessonElementId = (id) => {
    this.lessonElementId = id;
  }

  @action
  setOrderNum = (num) => {
    this.orderNum = num;
  }

  @action
  setChildElementIds = (ids) => {
    this.childElementIds = ids;
  }
}
