import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import ButtonGroup from './ButtonGroup';

const ButtonGroupList = observer(() => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  return (
    <>
      {toolbarManager.buttonLayout && toolbarManager.buttonLayout.map((buttonGroup) => {
        if (buttonGroup.type === 'spacer') {
          return <div key={`${buttonGroup.id}-spacer`} className='spacer' />;
        }
        return (<ButtonGroup key={`${buttonGroup.id}-button-group`} buttonGroup={buttonGroup} />);
      })}
    </>
  );
});
export default ButtonGroupList;
