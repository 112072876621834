import React, { useContext } from 'react';
import '../../css/PrintPreviewDialog.scss';
import Iframe from 'react-iframe';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';
import Auth from '../../lessonPlayer/services/AuthService';

import itemBankService from '../services/ItemBankService';

// import UtilsService from '../../lessonPlayer/services/UtilsService';

import { register } from '../../i18n';

const t = register('DetailDialog');

const PrintPreviewDialog = observer(() => {
  const {
    itemBankManager,
    lessonManager
  } = useContext(MobXProviderContext);
  const contentId = (itemBankManager.printPreviewMode) ? lessonManager.lessonContentItem.id : itemBankManager.previewId;

  return (
    <>
      {(lessonManager.lessonContentItem) && (
        <div className='print-preview-dialog'>
          <ReactModal
            disableResize={true}
            initHeight={600}
            initWidth={900}
            isOpen={itemBankManager.printPreviewDialogOpen}
            onRequestClose={() => { itemBankService.setPrintPreviewDialogOpen(false); }} top={50}>
            <div className='print-preview-dialog-body'>

              <div className='solution-header'>
                <div className='title-label basic'>{t('headerLabel')}</div>
                <div className='x-close-button mask-image' onClick={() => {
                  itemBankService.setPrintPreviewDialogOpen(false);
                }} />
              </div>

              <Iframe
                // key='preview'
                className='print-preview-frame'
                id={`preview_${lessonManager.lessonContentItem.id}`}
                url={`../printPreview?contentItemId=${contentId}&previewLessonElementId=${itemBankManager.itemPreviewElementId}
                &profileId=${lessonManager.lesson.optionsProfileId}&sat=${lessonManager.isSat}&previewMode=${itemBankManager.printPreviewMode ? '' : 'itemPreview'}#${Auth.authKey}`} />
            </div>
          </ReactModal>
        </div>
      )}
    </>
  );
});

export default PrintPreviewDialog;
