import React from 'react';
import Auth from '../../lessonPlayer/services/AuthService';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const Image = ({ model }) => {
  const imgSrc = `${Auth.ecms}/api/redirectToResource?contentItemId=${model.resourceContentItemId}&authKey=${Auth.authKey}`;
  const imgSize = `${model.scale * 100}%`;

  return (
    <>
      <div className='top-label'>Resource:</div>
      <div className='element-container' id={`_${model.id}`}>
        <div className='label-value-row'>
          <div>Resource Type:</div>
          <div>Image</div>
        </div>
        <div className='label-value-row'>
          <div>Title:</div>
          <div>{model.title}</div>
        </div>
        <div className='label-value-row'>
          <div>Directions:</div>
          <div className='ckeditor-text'><HtmlComponent htmlStr={model.text} /></div>
        </div>
        <img alt='' className='max-img-size' src={imgSrc} style={{ width: imgSize }} />
      </div>
    </>
  );
};
export default Image;
