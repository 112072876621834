import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import flaggedSvg from '../../../images/summaryIcons/flagged.svg';
import { ContentType } from '../../../Constants';
import navMenuService from '../../services/NavMenuService';
import responseService from '../../services/ResponseService';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

const ActivityNavButton = observer(({
  childElementId/* , index, lessonElementId */
}) => {
  const {
    lessonManager,
    navMenuManager,
    responseManager,
    videoManager,
    audioManager
  } = useContext(MobXProviderContext);

  const buttonRef = useRef();

  const childModel = lessonManager.getSlideModel(childElementId);

  const lessonElementState = responseManager.getLessonElementState(childElementId);
  const isLocked = (lessonElementState && lessonElementState.isDependencyLocked);
  const flagged = responseManager.markedForReviewList.includes(childElementId);

  const clickHandler = async (_event) => {
    if (isLocked) {
      await responseService.alertDependencyLock(lessonElementState);
      return;
    }

    if (!lessonManager.isInteractiveFlyoutOpen) {
      navMenuService.setInteractiveFlyoutOpen(true);
    }

    lessonManager.setIgnoreQuestionChildEventScrollListener(true);
    setTimeout(async () => {
      lessonManager.setIgnoreQuestionChildEventScrollListener(false);
    }, 300);

    videoManager.setSelectedSideNavVideoChildLessonElementId(childElementId);
    audioManager.setSelectedSideNavAudioChildLessonElementId(childElementId);

    const activityModel = lessonManager.getSlideModel(childModel.parentActivityElementId);

    const shouldReturnIfSameLessonElementId = !(activityModel.type.includes('Audio') || activityModel.type.includes('Video'));

    const currentActivityNavigatorLessonElementIndex = navMenuManager.activityNavigatorLessonElementMap.get(childElementId)?.index;
    navMenuManager.setSelectedActivityNavigatorLessonElementIndex(currentActivityNavigatorLessonElementIndex);

    await navMenuService.navToSlideClickHandler(childElementId, { shouldReturnIfSameLessonElementId });
  };

  useAccessibilityClick(buttonRef, clickHandler);
  const isCustomNumbering = lessonManager?.lessonOptions?.customNumbering && childModel.questionNumber;
  const isInstruction = childModel.category === 'Instruction';
  const isAudio = childModel.type === ContentType.AUDIO_QUESTION.type || childModel.type === ContentType.AUDIO.type;
  const isVideo = childModel.type === ContentType.VIDEO_QUESTION.type || childModel.type === ContentType.VIDEO.type;
  const isImage = childModel.type === ContentType.IMAGE_QUESTION.type || childModel.type === ContentType.IMAGE.type;
  const isPdf = childModel.type === ContentType.PDF.type;

  // TODO unused // const currentActivityNavigatorLessonElementIndex = navMenuManager.activityNavigatorLessonElementMap.get(childElementId)?.index;
  const selected = lessonManager.currentLessonElementId === childElementId;

  const IconDisplay = () => {
    if (isInstruction && !isCustomNumbering) {
      return (
        <div className='instruction-wrapper'>
          <div className='instruction-icon' />
          {(flagged) && <img alt='' className='marked-for-review-icon' src={flaggedSvg} />}
        </div>
      );
    } else if (isAudio) {
      return (
        <div className='instruction-wrapper'>
          <div className='audio-icon' />
          {(flagged) && <img alt='' className='marked-for-review-icon' src={flaggedSvg} />}
        </div>
      );
    } else if (isVideo) {
      return (
        <div className='instruction-wrapper'>
          <div className='video-icon' />
          {(flagged) && <img alt='' className='marked-for-review-icon' src={flaggedSvg} />}
        </div>
      );
    } else if (isImage) {
      return (
        <div className='instruction-wrapper'>
          <div className='image-icon' />
          {(flagged) && <img alt='' className='marked-for-review-icon' src={flaggedSvg} />}
        </div>
      );
    } else if (isPdf) {
      return (
        <div className='instruction-wrapper'>
          <div className='pdf-icon' />
          {(flagged) && <img alt='' className='marked-for-review-icon' src={flaggedSvg} />}
        </div>
      );
    } else {
      return (
        <span>{childModel.questionNumber}</span>
      );
    }
  };

  return (
    <>
      <button ref={buttonRef} aria-controls={`${childElementId}-question-view`}
        aria-label={`Question ${childModel.questionNumber}`} aria-selected={selected}
        className={classNames({
          'activity-nav-button': true,
          'selected': selected,
          'instruction': isInstruction,
          'is-dependent-locked': isLocked,
          'is-audio': isAudio,
          'is-video': isVideo,
          'is-image': isImage,
          'is-pdf': isPdf
        })} id={`${childElementId}-activity-nav`}
        role='tab'
        tabIndex='0'>
        <IconDisplay />
        {(flagged) && <img alt='' className='marked-for-review-icon' src={flaggedSvg} />}
      </button>
    </>
  );
});

export default ActivityNavButton;
