import React from 'react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import deprecatedDragDropService from './DeprecatedDragDropService';
import playerService from './PlayerService';
import responseService from './ResponseService';
import styleService from './StyleService';
import utilsService from './UtilsService';
import toolbarService from './ToolbarService';

import DndKitDraggable from '../components/DndKitDraggable';
import DndKitDroppable from '../components/DndKitDroppable';

import HtmlComponent from '../components/HtmlComponent';

export default class DragDropService {
  static initResponseItemAnswerBankOptions = ({
    model,
    setResponseItemAnswerBankOptions
  } = {}) => {
    const responseItems = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || [])
      .map((responseItem) => {
        return {
          ...responseItem,
        };
      });

    let responseItemOptions = utilsService.shuffleArrayForUser(responseItems);
    if (!model?.duplicateAnswers) {
      responseItemOptions = utilsService.removeDuplicates(responseItemOptions, 'text');
    }
    setResponseItemAnswerBankOptions(responseItemOptions);
  };

  static handleDragDropInitUseEffect = ({
    initResponseItemAnswerBankOptions,
    setLoadingDragDrop
  } = {}) => {
    // (async () => {
    setLoadingDragDrop(true);

    initResponseItemAnswerBankOptions();

    // TODO remove
    // // disable react-beautiful-dnd dev warnings for now
    // // ---
    // // when react-beautiful-dnd warnings are enabled for this question type,
    // // there is an issue with react-beautiful-dnd where it is showing
    // // console warnings regarding 'nested containers are unsupported',
    // // but functionality seems to work correctly.
    // // (https://github.com/atlassian/react-beautiful-dnd/issues/131)
    // window['__react-beautiful-dnd-disable-dev-warnings'] = true;

    setLoadingDragDrop(false);

    setTimeout(() => {
      toolbarService.readSpeakerIgnoreRead();
    }, 2000);
    // })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  /**
   * @param {{
   *  dndKitEvent: import('@dnd-kit/core').DragEndEvent;
   *  forceUpdate;
   *  lessonElementId;
   *  lessonElementState;
   *  model;
   *  result: Partial<import('react-beautiful-dnd').DropResult>;
   *  setDragEndResult;
   * }}
   */
  static handleDragEnd = ({
    dndKitEvent = null,
    forceUpdate,
    lessonElementId,
    lessonElementState,
    model,
    result,
    setDragEndResult
  } = {}) => {
    if (!lessonElementState?.currentResponseAnswer?.userInputMap) {
      return null;
    }

    if (dndKitEvent) {
      result = this.convertDndKitEventToPartialReactBeautifulDndDragEndResult(dndKitEvent);
      setDragEndResult(result);
    } else {
      setDragEndResult(result);
    }

    const sourceDroppableId = result?.source?.droppableId;
    const destinationDroppableId = result?.destination?.droppableId;

    if (!sourceDroppableId || !destinationDroppableId || sourceDroppableId === destinationDroppableId) {
      return;
    }

    const isSourceAnswerBank = sourceDroppableId === 'drag-drop-options-answer-bank';
    // TODO remove // const isDestinationAnswerBank = destinationDroppableId === 'drag-drop-options-answer-bank';

    const sourceIndex = result?.source?.index;

    const responseItems = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || []);

    const currentResponseItem = responseItems[sourceIndex];

    // prep data then trigger call to `behavior.setResponseData`
    const data = {
      ...currentResponseItem,
      dataId: destinationDroppableId
    };
    responseService.textChangeHandler(
      data, lessonElementId, {
        dataId: destinationDroppableId || '',
        lessonElementState,
        oldDataId: !isSourceAnswerBank ? (sourceDroppableId || '') : ''
      }
    );
    setTimeout(() => {
      // setDragEndResult(null);
      forceUpdate();
    }, 1);
  }

  static handleDragDropUnmountUseEffect = (_CONTENT_TYPE_OBJ) => {
    /* placeholder */
    // TODO remove
    // const navigatedAwayFromQuestion = questionService.navigatedAwayFromQuestion(CONTENT_TYPE_OBJ);
    // if (navigatedAwayFromQuestion) {
    //   window['__react-beautiful-dnd-disable-dev-warnings'] = false;
    // }
  }

  static getAbsoluteDragDropResponseItemStyles = ({
    model,
    responseItem
  } = {}) => {
    let answerVerticalAlignment;
    if (model.answerVerticalAlignment === 'top') {
      answerVerticalAlignment = 'flex-start';
    } else if (model.answerVerticalAlignment === 'bottom') {
      answerVerticalAlignment = 'flex-end';
    } else {
      answerVerticalAlignment = 'center';
    }
    return {
      alignItems: answerVerticalAlignment,
      display: 'flex',
      height: !responseItem?.width ? `${responseItem.height }px` : 'auto',
      justifyContent: model.answerAlignment || 'center',
      left: responseItem?.left ? `${responseItem.left }px` : 'auto',
      maxWidth: responseItem?.width ? `${responseItem.width }px` : 'auto',
      position: 'absolute',
      textAlign: model.answerAlignment || 'left',
      top: (responseItem?.top) ? `${responseItem.top }px` : 'auto',
      width: responseItem?.width ? `${responseItem.width }px` : 'auto'
    };
  }

  static renderDragDropAnswerBankSection_dndKit = ({
    // dragEndResult,
    // lessonElementState,
    CONTENT_TYPE_OBJ,
    answerBankBackgroundUrl,
    dragStartDndKitEvent,
    getAbsoluteDragDropResponseItemStyles,
    isCustomAnswerBankStyling,
    lessonElementId,
    model,
    responseItemAnswerBankOptions
  } = {}) => {
    const contentClassName = kebabCase(CONTENT_TYPE_OBJ.type);

    const { getStyleVar } = styleService;
    const { stripWrappingParagraphTags } = utilsService;

    const responseItems = utilsService.getResponseItemsObjArrayFromStringArray([...model?.responseItems] || []);

    const isDragDisabled = playerService.isReadOnly(lessonElementId);

    const activeDraggableId = dragStartDndKitEvent?.active?.data?.current?.draggableId;

    return (
      <div className={`${contentClassName}-question-section-footer`}>
        {!!model.answerBankHeading && (
          <div className={`${contentClassName}-section-footer-title`}>
            <HtmlComponent htmlStr={model.answerBankHeading} />
          </div>
        )}
        <div className={`${contentClassName}-options-answer-bank-wrapper`}
          style={{
            height: isCustomAnswerBankStyling && model.answerLayoutHeight ?
              `${model.answerLayoutHeight }px` : 'auto'
          }}>
          <DndKitDroppable droppableId='drag-drop-options-answer-bank'>
            <div className={classNames(`${contentClassName}-options-answer-bank`,
              `answer-bank-inner-position-${model.answerBankPosition || 'bottom'}`, {
                [`${contentClassName}-options-answer-bank-default`]: !isCustomAnswerBankStyling,
                [`${contentClassName}-options-answer-bank-custom`]: isCustomAnswerBankStyling,
              })}
            style={{
              background: answerBankBackgroundUrl && `url(${answerBankBackgroundUrl})`,
              height: isCustomAnswerBankStyling && model.answerLayoutHeight ?
                (`${model.answerLayoutHeight }px`) : 'auto',
              width: isCustomAnswerBankStyling ? `${model.answerLayoutWidth }px` : 'auto',
              maxWidth: isCustomAnswerBankStyling ? `${model.answerLayoutWidth }px` : 'auto',
              ...(!isCustomAnswerBankStyling ? {
                padding: getStyleVar('--theme-drag-drop-options-answer-bank-padding')
              } : {})
            }}>
              {responseItemAnswerBankOptions?.map((responseItem, _index) => {
                const modelResponseItemIndex = responseItems?.findIndex((modelResponseItem) => {
                  return modelResponseItem.id && modelResponseItem.id === responseItem.id;
                });
                const draggableResponseItemId = responseItem.id;

                const occupiedByPrompt = this.isOccupiedByPrompt(responseItem/* , destinationDroppableId */);

                const isDragging = activeDraggableId && activeDraggableId === responseItem.id;

                const hiddenFromAnswerBank = (
                  !model.answersPersist && (/* isDragging || */occupiedByPrompt)
                );

                const optionPadding = parseInt(getStyleVar('--theme-drag-drop-option-padding')) || 0;
                // Max width so the answer bank options don't overflow the answer bank. We take the answer bank width
                // subtract the left value of the option and then subtract the option padding times two for left and right.
                const optionMaxWidth = model?.answerLayoutWidth - responseItem?.left - (optionPadding * 2);

                return (!hiddenFromAnswerBank || isDragging) && (
                  <DndKitDraggable key={draggableResponseItemId}
                    draggableId={draggableResponseItemId}
                    draggableInnerId={draggableResponseItemId}
                    draggableValue={responseItem.text}
                    droppableId='drag-drop-options-answer-bank'
                    index={modelResponseItemIndex}
                    isDragDisabled={isDragDisabled}>
                    <div
                      className={classNames(`${contentClassName}-option`, {
                        [`${contentClassName}-option-custom`]: isCustomAnswerBankStyling,
                        [`${contentClassName}-option-default`]: !isCustomAnswerBankStyling,
                      })}
                      style={{
                        ...(!isCustomAnswerBankStyling ? {
                          alignItems: 'center'
                        } : {
                          ...getAbsoluteDragDropResponseItemStyles(responseItem),
                        }),
                        maxWidth: isCustomAnswerBankStyling && optionMaxWidth ? `${optionMaxWidth}px` : 'auto',
                        boxSizing: 'border-box'
                        // visibility: isDragging ? 'hidden' : 'visible'
                      }}>
                      <HtmlComponent
                        htmlStr={stripWrappingParagraphTags(responseItem.text)}
                        useSpan />
                    </div>
                  </DndKitDraggable>
                );
              }).filter((jsx) => jsx)}
            </div>
          </DndKitDroppable>
        </div>
      </div>
    );
  }

  // TODO unused - remove
  /** @deprecated note: we are phasing out `react-beautiful-dnd` in favor of `@dnd-kit` */
  static renderDragDropAnswerBankSection_reactBeautifulDnd = ({
    CONTENT_TYPE_OBJ,
    answerBankBackgroundUrl,
    dragEndResult,
    getAbsoluteDragDropResponseItemStyles,
    isCustomAnswerBankStyling,
    lessonElementId,
    lessonElementState,
    model,
    responseItemAnswerBankOptions
  } = {}) => {
    return deprecatedDragDropService.renderDragDropAnswerBankSection_reactBeautifulDnd({
      CONTENT_TYPE_OBJ,
      answerBankBackgroundUrl,
      dragEndResult,
      getAbsoluteDragDropResponseItemStyles,
      isCustomAnswerBankStyling,
      lessonElementId,
      lessonElementState,
      model,
      responseItemAnswerBankOptions
    });
  }

  static isOccupiedByPrompt = (responseItem, promptDataId) => {
    if (!responseItem) {
      console.error('isOccupiedByPrompt: responseItem required');
      return;
    }
    let droppableElements;

    if (promptDataId) {
      const droppableElement = this.getDroppableElement(promptDataId);
      if (!droppableElement) {
        return false;
      } else {
        droppableElements = [droppableElement];
      }
    } else {
      droppableElements = this.getAllDroppableElements();
    }
    const _isOccupiedByPrompt = !!this.findInnerDroppableElement(responseItem, droppableElements);
    return _isOccupiedByPrompt;
  }

  /**
   * @param {import('@dnd-kit/core').DragEndEvent | import('@dnd-kit/core').DragStartEvent} dndKitEvent
   * @returns {Partial<import('react-beautiful-dnd').DropResult> | Partial<import('react-beautiful-dnd').DragStart>}
   */
  static convertDndKitEventToPartialReactBeautifulDndDragEndResult = (dndKitEvent) => {
    return {
      draggableId: dndKitEvent?.active?.data?.current?.draggableId,
      source: {
        ...(dndKitEvent?.active?.data?.current || {})
      },
      destination: {
        ...(dndKitEvent?.over?.data?.current || {})
      }
    };
  }

  static findInnerDroppableElement = (responseItem, droppableElements) => {
    droppableElements = droppableElements || this.getAllDroppableElements();
    return droppableElements.find((droppableElement) => {
      const dataId = this.getDataIdFromDroppableElement(droppableElement);

      if (dataId === 'drag-drop-options-answer-bank') {
        return undefined;
      }
      const draggableId = droppableElement.querySelector(`[data-answer-id="${responseItem.id}"]`);
      return !!draggableId;
    });
  }

  /** @param {Element} droppableElement */
  static getDataIdFromDroppableElement = (droppableElement) => {
    return droppableElement.getAttribute('data-rbd-droppable-id');
  }

  static getDraggableIdFromDroppableElement = (promptDataId) => {
    if (promptDataId === 'drag-drop-options-answer-bank') {
      return undefined;
    }
    const droppableElement = this.getDroppableElement(promptDataId);

    const draggableId = droppableElement?.children?.[0]?.children?.[0]?.getAttribute('data-answer-id');
    return draggableId;
  }

  static getDroppableElement = (promptDataId) => {
    if (promptDataId === 'drag-drop-options-answer-bank') {
      return undefined;
    }
    const droppableElements = this.getAllDroppableElements();

    const droppableElement = droppableElements.find((droppableElement) => {
      const promptDataIdFromAttribute = droppableElement?.getAttribute('data-rbd-droppable-id') || droppableElement?.getAttribute('data-prompt-id');
      return promptDataIdFromAttribute === promptDataId;
    });
    return droppableElement;
  }

  static getAllDroppableElements = () => {
    return Array.from(document.querySelectorAll('[data-rbd-droppable-id]')?.values() || [])
      .concat(Array.from(document.querySelectorAll('[data-prompt-id]')?.values() || []));
  }
}
