import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import HtmlComponent from '../HtmlComponent';
import UtilsService from '../../services/UtilsService';
import { register } from '../../../i18n';

const t = register('PanelMenu');

const teacherNotePanel = observer(({ lessonElementId }) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);

  return (
    <>
      {(!UtilsService.isNullOrEmpty(model.teacherNotes)) && (
        <div className='teacher-notes-panel flyout-panel'>
          <div className='panel-header'>{t('teacherNotePanel')}</div>
          <div className='teacher-notes-wrapper'>
            <div className='teacher-notes-container'>
              <div className='teacher-notes-textbox-wrapper'>
                <HtmlComponent htmlStr={model.teacherNotes} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default teacherNotePanel;
