import React from 'react';
import { register } from '../../i18n';
import nowifi from '../../images/player-icons/no-wifi_2313867.png';

const t = register('GlobalQuestionLabels')

const InternetMessage = (props) => {
  return (
    <div className='loading-content'>
      <div className='internet-message-wrapper'>
        <img alt='No Internet' aria-label='No Internet' className='internet-image' src={nowifi} />
        <div className='internet-heading'>{t('internetLostHeader')}</div>
        <div className='internet-message'>{t('internetLostLine1')}</div>
        <div className='internet-message'>{t('internetLostLine2')} </div>
        <div className='internet-message'>{t('internetLostLine3')}</div>
      </div>
    </div>
  );
};

export default InternetMessage;
