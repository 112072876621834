import React, { useContext, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';
import RichTextEditor, { BASIC_CKEDITOR_TOOLBAR } from '../RichTextEditor';

import NoteEditor from './NoteEditor';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import highlightAnnotationService from '../../services/HighlightAnnotationService';
import '../../../css/Tools.scss';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';
import { register } from '../../../i18n';

const t = register('ToolNames');
const t2 = register('GlobalQuestionLabels');
const t3 = register('AriaLabels');

// function StickyNotes() {
const StickyNotes = observer(() => {
  const {
    toolbarManager,
    lessonManager,
    highlightAnnotationManager
  } = useContext(MobXProviderContext);

  const { allowDeleteAllHighlightNotes, currentHighlightNotes, selectedHighlightElement } = highlightAnnotationManager;

  const toolbar = BASIC_CKEDITOR_TOOLBAR;
  // TODO unused // const readOnly = false;

  const [enteredText, setEnteredText] = useState('');

  if (lessonManager &&
    lessonManager.lessonOptions) {
    // console.info('options');
  }

  function closeNotes() {
    toolbarManager.setIsStickyNotesOpen(false);
    if (selectedHighlightElement) {
      selectedHighlightElement.classList.remove('hlt-selected');
    }
  }

  const saveNoteRef = useRef();
  useAccessibilityClick(saveNoteRef, (e) => {
    highlightAnnotationService.createHighlightNote(enteredText);
    toolbarManager.setIsStickyNotesOpen(false);
    if (selectedHighlightElement) {
      selectedHighlightElement.classList.remove('hlt-selected');
    }
  });

  const cancelNoteRef = useRef();
  useAccessibilityClick(cancelNoteRef, (e) => {
    highlightAnnotationService.cancelCreateNote();
  });

  const deleteAllNoteRef = useRef();
  useAccessibilityClick(deleteAllNoteRef, (e) => {
    highlightAnnotationService.deleteAllHighlightNotes();
    toolbarManager.setIsStickyNotesOpen(false);
  });

  useReactModalResizeableDraggable('stickyNotesTool');

  return (
    <div className='stickyNotesTool'>
      <ReactModal
        disableKeystroke
        initHeight={300}
        initWidth={280}
        isOpen={true}
        left={5}
        top={150}>
        <div className='notes-body'>
          <div className='tool-header'>
            <div className='title-label basic'>{t('stickyNotes', 'Sticky Notes')}</div>
            <div aria-label={t3('closedialog')} className='x-close-button' id='modal-close'
              onClick={closeNotes} role='button'
              tabIndex='0' />
          </div>
          <div className='existing-notes'>
            {
              (currentHighlightNotes && currentHighlightNotes.length > 0) ?
                currentHighlightNotes.map((textHighlightNote, index) => <NoteEditor key={`${textHighlightNote.id}-note`} noteObject={textHighlightNote} />
                ) : <></>
            }
          </div>
          <div className='new-note'>
            <div className='cke_topspace'>
              <RichTextEditor data={enteredText}
                onChange={(data) => setEnteredText(data)}
                readOnly={false} toolbar={toolbar} />
            </div>
            <div className='footer-buttons'>
              <button ref={saveNoteRef} className='submit-response primary'>{t2('save', 'Save')}</button>
              <button ref={cancelNoteRef} className='submit-response primary'>{t2('cancel', 'Cancel')}</button>
              {
                (allowDeleteAllHighlightNotes && currentHighlightNotes && currentHighlightNotes.length > 1) ?
                  <button ref={deleteAllNoteRef} className='submit-response primary'>{t2('deleteAll', 'Delete All')}</button>
                  : <></>
              }
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
});

export default StickyNotes;
