import { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/Relationships.scss';

import questionService from '../../services/QuestionService';
import responseService from '../../services/ResponseService';

import HtmlComponent from '../HtmlComponent';

import RichTextEditor, { ADVANCED_CKEDITOR_TOOLBAR } from '../RichTextEditor';
import useStyleEvents from '../../../hooks/useStyleEvents';

const Relationships = observer(({ lessonElementId }) => {
  // eslint-disable-next-line no-empty-pattern
  const { /* managers */ } = useContext(MobXProviderContext);

  const {
    lessonElementState,
    model,
    readOnly: isReadOnly,
    uiState
  } = questionService.initQuestionComponent({
    includeUiFeedbackState: true,
    includeUserInputMap: false,
    lessonElementId
  });

  const toolbar = ADVANCED_CKEDITOR_TOOLBAR;

  const showAnswerFeedback = uiState?.showAnswerFeedback || false;

  const renderRelationshipBoxPromptInput = ({ index, pathId, prompt }) => {
    const isDisabled = isReadOnly || showAnswerFeedback || !!prompt.sample || false;

    const promptText = prompt.sample || prompt.content || '';

    return (
      <div className={classNames('relationship-box-prompt-input', {
        'relationship-box-prompt-input-sample': !!prompt.sample
      })}>
        <RichTextEditor
          data={promptText}
          disabled={isDisabled}
          lessonElementId={lessonElementId}
          onChange={(data) => responseService.responseChangeHandler({
            index,
            pathId,
            prompt,
            text: data,
          }, lessonElementId)}
          toolbar={toolbar} />
      </div>
    );
  };

  useStyleEvents(lessonElementId);

  return (
    <>
      {!!model.directions && (
        <div className={classNames('directions relationships')}>
          <HtmlComponent htmlStr={model.directions} />
        </div>
      )}
      <div className='relationships-table-container'>
        <table className='relationships-table custom-table'>
          <thead />
          <tbody>
            {lessonElementState?.currentResponseAnswer?.prompts?.map((prompt, index) => {
              const pathId = `[${index}]`;
              const baseKeyId = `${model.sectionItemId || model.sectionLessonElementId}`;
              const keyId = `${baseKeyId}_${pathId}`;

              return (
                <tr key={keyId} className='relationship-row' id={keyId}>
                  {/* first relationship column aka level1 aka parent */}
                  <td className='relationship-cell level1'>
                    {renderRelationshipBoxPromptInput({ index, pathId, prompt })}
                  </td>
                  {/* second relationship column aka level2 aka children */}
                  {!!prompt.children?.length && (
                    <>
                      <td className='relationship-cell-spacer-left'>–</td>
                      <td className='relationships-cell level2'>
                        <table className='relationships-table level2'>
                          <thead />
                          <tbody>
                            {prompt.children?.map((childPrompt, childIndex) => {
                              const childPathId = `${pathId}.children[${childIndex}]`;
                              const childKeyId = `${baseKeyId}_${childPathId}`;

                              return (
                                <tr key={childKeyId} className='relationship-row level2' id={childKeyId}>

                                  <td className='relationship-cell-spacer-right'>–</td>

                                  <td className={classNames('relationship-cell level2')}>
                                    {renderRelationshipBoxPromptInput({ index: childIndex, pathId: childPathId, prompt: childPrompt })}
                                  </td>

                                  {/* third relationship column aka level3 aka grandchildren */}
                                  {!!childPrompt.children?.length && (
                                    <>
                                      <td className='relationship-cell-spacer-left'>–</td>
                                      <td className='relationships-cell level3'>
                                        <table className='relationships-table level3'>
                                          <thead />
                                          <tbody>
                                            {childPrompt.children?.map((grandchildPrompt, grandchildIndex) => {
                                              const grandchildPathId = `${childPathId}.children[${grandchildIndex}]`;
                                              const grandchildKeyId = `${baseKeyId}_${grandchildPathId}`;

                                              return (
                                                <tr key={grandchildKeyId} className='relationship-row level3' id={grandchildKeyId}>

                                                  <td className='relationship-cell-spacer-right'>–</td>
                                                  <td className={classNames('relationship-cell level3')}>
                                                    {renderRelationshipBoxPromptInput({ index: grandchildIndex, pathId: grandchildPathId, prompt: grandchildPrompt })}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}

          </tbody>
        </table>
      </div>
    </>
  );
});

export default Relationships;
