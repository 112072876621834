export class Behavior {
  checkForValidResponse = (questionResponse, model) => {
    console.warn('Behavior.checkForValidResponse should be implemented by behaviors that use this method');
  }

  setCorrectAnswer = (state, model) => {
    console.warn('Behavior.checkForValidResponse should be implemented by behaviors that use this method');
  }

  resetStudentAnswer = (state) => {
    console.warn('Behavior.checkForValidResponse should be implemented by behaviors that use this method');
  }

  getScore = (questionResponse, model) => {
    console.warn('Behavior.getScore should be implemented by behaviors that use this method');
  }

  setResponseData = (data, questionResponse) => {
    console.warn('Behavior.setResponseData should be implemented by behaviors that use this method');
  }

  getInitializedResponse = (lessonElementId, model, activityId) => {
    console.warn('Behavior.getInitializedResponse should be implemented by behaviors that use this method');
  }

  isAutoScored = (model) => {
    console.warn('Behavior.isAutoScored should be implemented by behaviors that use this method');
  }

  getCorrectAnswersText = (model) => {
    console.warn('Behavior.getCorrectAnswersText should be implemented by behaviors that use this method');
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    console.warn('Behavior.getQuestionFeedbackText should be implemented by behaviors that use this method');
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    console.warn('Behavior.isAnswerCorrect should be implemented by behaviors that use this method');
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    console.warn('Behavior.isQuestionCorrect should be implemented by behaviors that use this method');
  }

  removeResponseData = () => {
    console.warn('Behavior.removeResponseData should be implemented by behaviors that use this method');
  }
}
