import { Behavior } from './Behavior';
import ResponseService from '../services/ResponseService';
import { LessonMode, QuestionFeedbackState } from '../../Constants';
import UtilsService from '../services/UtilsService';

export class UploadBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    // Check if something has been uploaded
    return Boolean(responseAnswer.responseContent && responseAnswer.responseContent.length > 0);
  }

  isAutoScored = (_model) => {
    return false;
  }

  getScore = (_responseAnswer, _model) => {
    return 0;
  }

  setResponseData = async (data, responseAnswer, _model) => {
    // Performs an action on the data and then updates the response content
    const { action, parameters } = data;
    const {
      contentItemId,
      contentObjectId,
      file,
      mimeMainType,
      name
    } = parameters;
    const { activityId, lessonElementId } = responseAnswer;
    switch (action) {
    case 'upload':
      await ResponseService.uploadAndAttachFileResource(file, activityId, contentObjectId, lessonElementId, 'content_item', {
        mimeMainType
      });
      break;
    case 'setName':
      await ResponseService.updateContentItemName(contentItemId, name);
      break;
    case 'remove':
      await ResponseService.removeActivityResponseContent(activityId, lessonElementId, contentItemId);
      break;
    default:
      // Just update the response content
      break;
    }
    const responseContent = await ResponseService.fetchLessonElementResponseContent(activityId, lessonElementId);
    responseAnswer.responseContent = responseContent;
    await ResponseService.saveSingleResponse(lessonElementId);
  }

  getInitializedResponse = async (lessonElementId, _, activityId) => {
    const responseContent = await ResponseService.fetchLessonElementResponseContent(activityId, lessonElementId);
    const responseAnswer = {
      data: {},
      responseContent,
      lessonElementId,
      activityId
    };
    return responseAnswer;
  }

  setCorrectAnswer = (_state, _model) => {
    // placeholder
  }

  resetStudentAnswer = (_state) => {
    // placeholder
  }

  getCorrectAnswersText = (_model) => {
    // placeholder
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }
    const textHeader = '';

    // TODO unused; this content type is not autoscored so we do not have this feedback
    // if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
    //   textHeader = correctFeedback;
    //   if (correctFeedbackBody) {
    //     textBody = correctFeedbackBody + ' ' + textBody;
    //   }
    // } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
    //   textHeader = `${UtilsService.partiallyCorrect()}`;
    //   let custom = '';
    //   if (incorrectFeedbackBody) {
    //     custom = incorrectFeedbackBody + ' ';
    //   }

    //   textBody = UtilsService.partiallyCorrectPrompt() + ' <br/>' + custom + textBody;

    // } else {
    //   textHeader = incorrectFeedback;
    //   if (incorrectFeedbackBody) {
    //     textBody = incorrectFeedbackBody + ' ' + textBody;
    //   }
    // }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (_answerId, lessonElementState) => {
    // eslint-disable-next-line eqeqeq
    return lessonElementState.isScored && (lessonElementState.scoreValue == lessonElementState.maxScore);
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }
    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }
    return QuestionFeedbackState.INCORRECT;
  }
}

export default new UploadBehavior();
