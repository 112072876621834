import { action, computed, makeObservable, observable } from 'mobx';

import userManager from './UserManager';
import utilsService from '../services/UtilsService';

export class HighlightAnnotationManager {
  @observable highlightColor = '#fff200';
  @observable elementTextHighlightMap = new Map();
  @observable textHighlightNoteMap = new Map();
  @observable selectedHighlightElement = null;
  @observable currentDomElementId = null;
  @observable highlightUserMode = localStorage.getItem('chosen-hat-mode');
  // @observable currentHighlightNotes = [];

  @observable elementListenerMap = new Map();

  highlighter = null;
  serializedHighlights = null;

  options = {
    color: this.highlightColor,
    highlightedClass: 'hlt-on',
    onAfterHighlight: this.onAfterHighlight
  };

  constructor() {
    makeObservable(this);
    // console.log(`userManager.isTeacher: ${userManager.isTeacher} userManager.isStudent: ${userManager.isStudent}`);
  }

  onAfterHighlight = (_range, hlt) => {
    if (hlt[0]) {
      hlt[0].setAttribute('id', utilsService.createGuid());
      hlt[0].setAttribute('data-backgroundcolor', this.highlightColor);
      hlt[0].setAttribute('style', `background-color: ${ this.highlightColor}`);
    }
  }

  onAfterHighlightStickyNote = (_range, hlt) => {
    if (hlt[0]) {
      hlt[0].setAttribute('id', utilsService.createGuid());
      hlt[0].setAttribute('data-backgroundcolor', 'transparent');
      hlt[0].setAttribute('style', 'background-color: transparent');
      hlt[0].classList.add('note-anchor');
      this.setSelectedHighlightElement(hlt[0]);
    }
  }

  getRandom = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  // @action
  // setCurrentHighlightNotes = () => {
  //   this.currentHighlightNotes = this.textHighlightNoteMap.get(this.selectedHighlightElement.id);
  // }

  @computed
  get currentHighlightNotes() {
    if (this.selectedHighlightElement) {
      return this.textHighlightNoteMap.get(this.selectedHighlightElement.id);
    } else {
      return undefined;
    }
  }

    @computed
  get allowDeleteAllHighlightNotes() {
    if (this.selectedHighlightElement) {
      const noteArray = this.textHighlightNoteMap.get(this.selectedHighlightElement.id);
      if (noteArray) {
        const newNoteArray = noteArray.filter((note) => note.creatorId !== userManager.userId);
        if (newNoteArray.length > 0) {
          return false
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  @action
  setHighlightColor = (color) => {
    this.highlightColor = color;
  }

  @action
  setSelectedHighlightElement = (elem) => {
    if (elem) {
      if (this.selectedHighlightElement) {
        this.selectedHighlightElement.classList.remove('hlt-selected');
      }
      this.selectedHighlightElement = elem;
      this.selectedHighlightElement.classList.add('hlt-selected');
    } else {
      this.selectedHighlightElement = null;
    }
  }

  @action
  setCurrentDomElementId = (id) => {
    this.currentDomElementId = id;
  }

  @action
  setHighlightUserMode = (mode) => {
    localStorage.setItem('chosen-hat-mode', mode);
    this.highlightUserMode = mode;
  }

  @action
  addHighlightData = (lessonElementId, textHighlight) => {
    this.elementTextHighlightMap.set(lessonElementId, textHighlight);
  }

  @action
  addNoteData = (textHighlightId, textHighlightNote) => {
    this.textHighlightNoteMap.set(textHighlightId, textHighlightNote);
    console.log(this.textHighlightNoteMap.size);
  }

  @action
  deleteHighlightData = (lessonElementId) => {
    this.elementTextHighlightMap.delete(lessonElementId);
  }

  @action
  clearAllHighlightAndNoteData = () => {
    this.elementTextHighlightMap.clear();
    this.elementTextHighlightMap = new Map();
    this.textHighlightNoteMap.clear();
    this.textHighlightNoteMap = new Map();
  }

  @action
  removeNote = (noteId) => {
    const noteArray = this.textHighlightNoteMap.get(this.selectedHighlightElement.id);
    const newNoteArray = noteArray.filter((note) => note.id !== noteId);
    this.textHighlightNoteMap.set(this.selectedHighlightElement.id, newNoteArray);
    console.log(newNoteArray);
    if (newNoteArray.length < 1) {
      this.textHighlightNoteMap.delete(this.selectedHighlightElement.id);
      this.selectedHighlightElement.classList.remove('last');
      this.selectedHighlightElement.removeAttribute('data-note-count');
    } else {
      // remove one from the data-note-count
      this.selectedHighlightElement.setAttribute('data-note-count', newNoteArray.length);
    }
  }

  @action
  addNote = (note) => {
    if (note) {
      const noteArray = this.textHighlightNoteMap.get(this.selectedHighlightElement.id) || [];
      noteArray.push(note);
      this.textHighlightNoteMap.set(this.selectedHighlightElement.id, noteArray);
    }
  }

  @action
  updateNote = (note) => {
    let noteArray = this.textHighlightNoteMap.get(this.selectedHighlightElement.id) || [];
    noteArray = noteArray.filter((oldNote) => oldNote.id !== note.id);
    noteArray.push(note);
    this.textHighlightNoteMap.set(this.selectedHighlightElement.id, noteArray);
  }

  @action
  deleteAllHighlightNotes = (highlightId) => {
    this.textHighlightNoteMap.delete(highlightId);
  }
}
export default new HighlightAnnotationManager();
