import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { default as ReactSelect } from 'react-select';
import curriculumService from '../services/CurriculumService';
import BookmarkSelectionTree from './BookmarkSelectionTree';
import StandardsSelectionTree from './StandardsSelectionTree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import bookmarkImg from '../../images/content-object-icons/bookmark.svg';
import bookmarkSelectedImg from '../../images/content-object-icons/bookmark-selected.svg';
import Loader from '../../lessonPlayer/components/Loader';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';

const CurriculumFrameworkFilter = observer((props) => {
  const {
    curriculumManager,
    userManager
  } = useContext(MobXProviderContext);

  const [selectedState, setSelectedState] = useState();
  const [expanded, setExpanded] = useState([]); // eslint-disable-line no-unused-vars

  const [curriculumFilterType, setCurriculumFilterType] = useState('bookmark');
  const [standardFramework, setStandardFramework] = useState();
  const [bookmarkMode, setBookmarkMode] = useState(false);
  const [loadingMode, setLoadingMode] = useState(false);

  const clearDoc = useRef();

  useEffect(() => {
    const fetchInitData = async () => {
      await curriculumService.fetchAuthorityStates();
      await curriculumService.fetchStandardFrameworkBookmarks(userManager.userId);
    };
    fetchInitData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useAccessibilityClick(clearDoc, () => {
    setStandardFramework(null);
    setBookmarkMode(false);
  });

  const handleChangeState = async (selected) => {
    setSelectedState(selected);
    await curriculumService.fetchStandardFrameworkDomainList(selected.value);
  };

  const handleChangeCurriculumFilterType = async (event) => {
    setStandardFramework(null);
    setCurriculumFilterType(event.target.value);
    setBookmarkMode(false);
  };

  const handleSelectStandardsFramework = async (value, showStandards = false) => {
    await curriculumManager.clearExpanded();
    if (value.length > 0) {
      const id = value;
      setLoadingMode(true);
      await curriculumService.fetchFullStandardDocument(id);
      setLoadingMode(false);
    } else {
      curriculumManager.clearAdoptedStandardsJson();
      curriculumManager.clearStdFrwkStandardsMap();
    }
    setStandardFramework(value);

    if (showStandards) {
      setCurriculumFilterType('standards');
    }

    setTimeout(() => {
      setExpanded(curriculumManager.expanded);
    }, 1000);
  };

  const handleChangeBookmarkMode = async (event) => {
    setBookmarkMode(!bookmarkMode);
    const formattedJson = await curriculumService.buildAdoptedStandardsJson(curriculumManager.standardsResponseData, false, event.target.checked);
    await curriculumManager.setAdoptedStandardsJson(formattedJson);
  };

  return (
    <>
      <div className='filter-label' />
      <div className='radio-label'>
        <input
          checked={curriculumFilterType === 'bookmark'}
          id='bookmark'
          name='frameworkFilter'
          onChange={handleChangeCurriculumFilterType}
          type='radio'
          value='bookmark'
        /> Show Bookmarks
      </div>
      <div className='radio-label'>
        <input
          checked={curriculumFilterType === 'standards'}
          id='standards'
          name='frameworkFilter'
          onChange={handleChangeCurriculumFilterType}
          type='radio'
          value='standards'
        /> Browse Standards
      </div>
      {(curriculumFilterType === 'standards')
        ? (
          <>
            {(curriculumManager.authorityStates && !standardFramework) ? (
              <div>
                <div className='filter-label' />
                <ReactSelect
                  allowSelectAll={false}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={handleChangeState}
                  options={curriculumManager.authorityStates}
                  value={selectedState} />
              </div>
            ) : null}
            <div className='filter-label' />
            {(selectedState && !standardFramework) ? (
              <div className='framework-list-container'>
                {curriculumManager.standardDocuments.map(
                  (doc, index) => (
                    <div key={index} className={`${(index % 2 !== 0) ? 'shade' : ''} standard-row standard-doc-label`} onClick={() => handleSelectStandardsFramework(doc.id)}>
                      <img alt='' src={bookmarkImg} />
                      <div key={index} className='standard-doc-label'>{doc.listName}</div>
                    </div>
                  ))}
              </div>
            ) : null}
            {(loadingMode) ? <Loader /> : null}
            {(curriculumManager.adoptedStandardsJson && standardFramework && !loadingMode) ? (
              <>
                <div className='doc-controls'>
                  <label>
                    <input
                      checked={bookmarkMode}
                      onChange={handleChangeBookmarkMode}
                      type='checkbox'
                      value={bookmarkMode}
                    /> Bookmark Mode
                  </label>
                  <div ref={clearDoc} className='reset-filters clear-doc'>Clear Document</div>
                </div>

                {(bookmarkMode) ?
                  <BookmarkSelectionTree /> :
                  <StandardsSelectionTree />}

              </>
            ) : null}
          </>
        )
        : (
          <><div className='filter-label' /><div className='bookmark-list-container'>
            {curriculumManager.standardBookmarkArray.map(
              (bookmark, index) => (
                <div key={index} className={`${(index % 2 !== 0) ? 'shade' : ''} standard-row standard-doc-label`} onClick={() => handleSelectStandardsFramework(bookmark.sourceId, true)}>
                  <img alt='' src={bookmarkSelectedImg} />
                  <div key={index} className='standard-doc-label'>{(bookmark.name.length > 100 ? `${bookmark.name.substring(0, 100)}...` : bookmark.name)}</div>
                </div>
              )
            )}
          </div>
          </>
        )}
    </>
  );
});

export default CurriculumFrameworkFilter;
