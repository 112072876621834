import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { LessonSlideIds } from '../../Constants';
import lessonService from '../services/LessonService';
import '../../css/ContentLayout.scss';
import UtilsService from '../services/UtilsService';
import { register } from '../../i18n';

const t = register('Metadata');

const BackgroundShade = observer((props) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const titlePageImageUrl = lessonService.getLessonTitleBackgroundUrl();
  const slideImageUrl = lessonService.getLessonSlideBackgroundUrl();

  let finalUrl = '';

  let shadeClass = 'background-shade';
  if (lessonManager.currentLessonElementId === LessonSlideIds.TITLE_PAGE) {
    finalUrl = (titlePageImageUrl) || ((slideImageUrl) || '');
    shadeClass = 'background-shade title-shade';
  } else {
    finalUrl = (slideImageUrl) || '';
  }

  const shadeClassFinal = `${shadeClass} ${t('languageCode', document.documentElement.lang)}`

  return (
    <div className={shadeClassFinal} lang={t('languageCode', document.documentElement.lang)} style={(UtilsService.isNullOrEmpty(finalUrl)) ? {} : { backgroundImage: `url(${finalUrl})` }}>
      {props.children}
    </div>
  );
});

export default BackgroundShade;
