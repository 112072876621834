import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import lessonService from '../services/LessonService';
import UtilsService from '../services/UtilsService';
import { register } from '../../i18n';

const t = register('GlobalQuestionLabels');

const DefaultDialog = withReactContent(Swal);

export async function confirmDialog(props) {
  const ConfirmDialog = withReactContent(Swal);

  const defaults = {
    title: `<p>${t('exitCheck')}</p>`,
    text: '',
    showCancelButton: true,
    buttonsStyling: false,
    confirmButtonText: `${t('yes')}`,
    cancelButtonText: `${t('cancel')}`,
    showClass: {
      backdrop: 'swal2-noanimation',
      popup: '',
      icon: ''
    },
    hideClass: {
      popup: '',
    }
  };

  const result = await ConfirmDialog.fire({ ...defaults, ...props });
  return result;
}

export async function dependentAlert(props) {
  const AlertDialog = withReactContent(Swal);
  const { lessonElementState } = props;
  let html = '<div className={\'dependency-message\'}>';
  html += `<span>${t('dependencyMessage')}</span><span>&nbsp;</span>`;

  lessonElementState.controllerList.forEach((id, index) => {
    const controllerModel = lessonService.getSlideModel(id);

    html += '<span>';
    html += `<span >${ controllerModel.questionNumber }</span>`;
    html += '<span>';
    if (index === lessonElementState.controllerList.length - 1) {
      html += '';
    } else if (index === lessonElementState.controllerList.length - 2) {
      html += `&nbsp; ${t('and')} &nbsp; `;
    } else {
      html += ', ';
    }
    html += '</span>';
    html += '</span>';
  });

  html += `<span>&nbsp;${t('toSee')}</span>`;
  html += '</div>';

  const defaults = {
    title: html,
    text: '',
    showCancelButton: false,
    buttonsStyling: false,
    confirmButtonText: `${t('close')}` ,
    showClass: {
      backdrop: 'swal2-noanimation',
      popup: '',
      icon: ''
    },
    hideClass: {
      popup: '',
    }
  };

  const result = await AlertDialog.fire({ ...defaults, ...props });
  return result;
}

// if you just want a raw dialog with the default behavior;
export default DefaultDialog;
