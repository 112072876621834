import React from 'react';

import HtmlComponent from '../lessonPlayer/components/HtmlComponent';

const Section = ({ model, isManuscriptShort }) => {
  if (!model) {
    model = {};
  }

  return (
    <>
      <div className='top-label'>Section:</div>
      <div className='element-container'>
        <div className='label-value-row'>
          <div>Title:</div>
          <div className='ckeditor-text'><HtmlComponent htmlStr={model.title} /></div>
        </div>
        {!isManuscriptShort && (
          <>
            <div className='label-value-row'>
              <div>Minutes:</div>
              <div>{model.duration}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Section;
