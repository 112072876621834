import { Behavior } from './Behavior';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import scoringService from '../services/ScoringService';
import textQuestionUtilsService from '../services/TextQuestionUtilsService';
import UtilsService from '../services/UtilsService';

export class TextSelectorBehavior extends Behavior {
  checkForValidResponse = (responseAnswer, model) => {
    if (responseAnswer && responseAnswer.studentAnswers && responseAnswer.studentAnswers.length > 0) {
      return true;
    }
    return false;
  }

  setCorrectAnswer = (state, model) => {
    const correctAnswers = UtilsService.safeMobxClone(model.validation?.correctAnswers);

    const responseAnswer = {
      isScored: true,
      isScoredCorrect: true,
      lessonElementId: model.lessonElementId,
      studentAnswers: correctAnswers,
      omittedList: state.currentResponseAnswer.omittedList,
      trueCorrectList: state.currentResponseAnswer.trueCorrectList,
      selectedIncorrectList: state.currentResponseAnswer.selectedIncorrectList
    };

    state.setCurrentResponse(UtilsService.safeMobxClone(responseAnswer));
    textQuestionUtilsService.setCorrectAnswersText(model, responseAnswer);
  }

  resetStudentAnswer = (state) => {
    const responseAnswer = UtilsService.safeMobxClone(state.cachedResponseAnswer);
    textQuestionUtilsService.resetStudentAnswersText(responseAnswer);
    state.setCurrentResponse(UtilsService.safeMobxClone(responseAnswer));
  }

  getScore = (responseAnswer, model) => {
    if (!model.validation) {
      return 0;
    }
    let tempScore = 0;
    const correctRanges = model.validation.correctAnswers;
    const incorrectRanges = model.validation.incorrectAnswers;
    const studentRanges = responseAnswer.studentAnswers;
    const promptCount = correctRanges.length;

    for (const correctRange of correctRanges) {
      if (studentRanges.includes(correctRange)) {
        tempScore++;
      }
    }

    for (const incorrectRange of incorrectRanges) {
      if (studentRanges.includes(incorrectRange)) {
        tempScore--;
      }
    }

    if (tempScore < 0) {
      tempScore = 0;
    }

    const score = scoringService.getAdjustedScore(model, tempScore, promptCount);
    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    responseAnswer.studentAnswers = data;
  }

  getInitializedResponse = (lessonElementId, model) => {
    return {
      studentAnswers: [],
      isScoredCorrect: false,
      isScored: false,
      lessonElementId,
      omittedList: [],
      selectedIncorrectList: [],
      trueCorrectList: [],
    };
  }

  isAutoScored = (model) => {
    return (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);
  }

  getAnswerFeedback = (id, responseAnswer) => {
    throw new Error('Behavior.getAnswerFeedback must be implemented');
  }

  getCorrectAnswersText = (model) => {
    return null;
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let numberOmitted = 0;
    let numberSelectedIncorrect = 0;
    let numberSelectedCorrect = 0;
    let correctAnswers;
    if (lessonElementState && lessonElementState.currentResponseAnswer && model && model.validation) {
      const { incorrectAnswers } = model.validation;
      const { studentAnswers } = lessonElementState.currentResponseAnswer;
      correctAnswers = model.validation.correctAnswers;
      numberOmitted = (correctAnswers.length + incorrectAnswers.length) - (studentAnswers.length);
      correctAnswers.forEach((correctAnswer) => {
        if (studentAnswers.includes(correctAnswer)) {
          numberSelectedCorrect++;
        }
      });
      incorrectAnswers.forEach((incorrectAnswer) => {
        if (studentAnswers.includes(incorrectAnswer)) {
          numberSelectedIncorrect++;
        }
      });
    }

    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    const showPartialMessage = (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) || (questionIsCorrect === QuestionFeedbackState.INCORRECT);

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if ((showPartialMessage) && (correctAnswers?.length > 1) && (numberSelectedCorrect > 0)) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
      }
      if (numberOmitted > 0 && numberSelectedIncorrect === 0) {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt2()}.<br/>${ custom }${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody}<br/> ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  // going away-- no not for every question type :-)
  isAnswerCorrect = (answerId, responseAnswer) => {
    // For multiple choice there is only one question so we don't have an array of answers so just return the
    // flag for the only answer
    return responseAnswer.isScoredCorrect;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new TextSelectorBehavior();
