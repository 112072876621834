import { action, makeObservable, observable } from 'mobx';

export class EngagementData {
  @observable
  averageTimePerCorrect = null;

  @observable
  averageTimePerIncorrect = null;

  @observable
  averageTimePerItem = 0;

  @observable
  correctCount = 0; // class activity wide

  @observable
  engagement = null;

  @observable
  showAnswerAvailable = 0;

  @observable
  showAnswerCount = 0;

  @observable
  showHintAvailable = 0;

  @observable
  showHintCount = 0;

  @observable
  showSolutionAvailable = 0;

  @observable
  showSolutionCount = 0;

  @observable
  timeOnTaskSummary = null;

  @observable
  tryAgainAvailable = 0;

  @observable
  tryAgainCount = 0;

  @observable
  viewBasicCalculatorAvailable = 0;

  @observable
  viewBasicCalculatorCount = 0;

  @observable
  viewBookmarkAvailable = 0;

  @observable
  viewBookmarkCount = 0;

  @observable
  viewCustomaryRulerAvailable = 0;

  @observable
  viewCustomaryRulerCount = 0;

  @observable
  viewDictionaryAvailable = 0;

  @observable
  viewDictionaryCount = 0;

  @observable
  viewEliminatorAvailable = 0;

  @observable
  viewEliminatorCount = 0;

  @observable
  viewGraphAvailable = 0;

  @observable
  viewGraphCount = 0;

  @observable
  viewGraphingCalculatorAvailable = 0;

  @observable
  viewGraphingCalculatorCount = 0;

  @observable
  viewGuidelineAvailable = 0;

  @observable
  viewGuidelineCount = 0;

  @observable
  viewHatAvailable = 0;

  @observable
  viewHatCount = 0;

  @observable
  viewMetricRulerAvailable = 0;

  @observable
  viewMetricRulerCount = 0;

  @observable
  viewProtractorAvailable = 0;

  @observable
  viewProtractorCount = 0;

  @observable
  viewResourcesAvailable = 0;

  @observable
  viewResourcesCount = 0;

  @observable
  viewScientificCalculatorAvailable = 0;

  @observable
  viewScientificCalculatorCount = 0;

  @observable
  viewStickyNotesAvailable = 0;

  @observable
  viewStickyNotesCount = 0;

  @observable
  viewTextToSpeechAvailable = 0;

  @observable
  viewTextToSpeechCount = 0;

  @observable
  viewTranslateAvailable = 0;

  @observable
  viewTranslateCount = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  setEngagmentData = (data) => {
    this.averageTimePerCorrect = (data.averageTimePerCorrect) ? data.averageTimePerCorrect : 0;
    this.averageTimePerIncorrect = (data.averageTimePerIncorrect) ? data.averageTimePerIncorrect : 0;
    this.averageTimePerItem = data.averageTimePerItem;
    this.engagement = data.engagement;
    this.showAnswerAvailable = data.showAnswerAvailable;
    this.showAnswerCount = data.showAnswerCount;
    this.showHintAvailable = data.showHintAvailable;
    this.showHintCount = data.showHintCount;
    this.showSolutionAvailable = data.showSolutionAvailable;
    this.showSolutionCount = data.showSolutionCount;
    this.timeOnTaskSummary = data.timeOnTaskSummary;
    this.tryAgainAvailable = data.tryAgainAvailable;
    this.tryAgainCount = data.tryAgainCount;
    this.viewBasicCalculatorAvailable = data.viewBasicCalculatorAvailable;
    this.viewBasicCalculatorCount = data.viewBasicCalculatorCount;
    this.viewBookmarkAvailable = data.viewBookmarkAvailable;
    this.viewBookmarkCount = data.viewBookmarkCount;
    this.viewCustomaryRulerAvailable = data.viewCustomaryRulerAvailable;
    this.viewCustomaryRulerCount = data.viewCustomaryRulerCount;
    this.viewDictionaryAvailable = data.viewDictionaryAvailable;
    this.viewDictionaryCount = data.viewDictionaryCount;
    this.viewEliminatorAvailable = data.viewEliminatorAvailable;
    this.viewEliminatorCount = data.viewEliminatorCount;
    this.viewGraphAvailable = data.viewGraphAvailable;
    this.viewGraphCount = data.viewGraphCount;
    this.viewGraphingCalculatorAvailable = data.viewGraphingCalculatorAvailable;
    this.viewGraphingCalculatorCount = data.viewGraphingCalculatorCount;
    this.viewGuidelineAvailable = data.viewGuidelineAvailable;
    this.viewGuidelineCount = data.viewGuidelineCount;
    this.viewHatAvailable = data.viewHatAvailable;
    this.viewHatCount = data.viewHatCount;
    this.viewMetricRulerAvailable = data.viewMetricRulerAvailable;
    this.viewMetricRulerCount = data.viewMetricRulerCount;
    this.viewProtractorAvailable = data.viewProtractorAvailable;
    this.viewProtractorCount = data.viewProtractorCount;
    this.viewResourcesAvailable = data.viewResourcesAvailable;
    this.viewResourcesCount = data.viewResourcesCount;
    this.viewScientificCalculatorAvailable = data.viewScientificCalculatorAvailable;
    this.viewScientificCalculatorCount = data.viewScientificCalculatorCount;
    this.viewStickyNotesAvailable = data.viewStickyNotesAvailable;
    this.viewStickyNotesCount = data.viewStickyNotesCount;
    this.viewTextToSpeechAvailable = data.viewTextToSpeechAvailable;
    this.viewTextToSpeechCount = data.viewTextToSpeechCount;
    this.viewTranslateAvailable = data.viewTranslateAvailable;
    this.viewTranslateCount = data.viewTranslateCount;
    this.correctCount = data.correctCount;
  }
}
