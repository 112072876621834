import React from 'react';
import { components } from 'react-select';

const CheckOption = (props) => {
  const { isSelected, label } = props;

  return (
    <div>
      <components.Option {...props}>
        <input
          checked={isSelected}
          onChange={() => null}
          type='checkbox'
        />{' '}
        <label>{label}</label>
      </components.Option>
    </div>
  );
};

export default CheckOption;
