import React from 'react';
import QuestionDependency from '../QuestionDependency';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const MultipartActivity = ({ model, isManuscriptShort }) => {
  return (
    <div className='element-container'>
      <div className='label-value-row'>
        <div>Activity Type:</div>
        <div>Multipart Activity</div>
      </div>
      <div className='label-value-row'>
        <div>Questions:</div>
        <div>{model.questionRange}</div>
      </div>
      <div className='label-value-row'>
        <div>Directions:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.directions} /></div>
      </div>
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Show All Items At Once:</div>
            <div>{(model.showAllChildren) ? 'ON' : 'OFF'}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default MultipartActivity;
