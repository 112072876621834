import React from 'react';
import Utils from '../../lessonPlayer/services/UtilsService';
import QuestionDependency from '../QuestionDependency';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const VideoQuestion = ({ model, isManuscriptShort }) => {
  let embedUrl = model.sourceUrl;
  if (model.sourceUrl && model.sourceUrl.includes('youtu.be')) {
    embedUrl = model.sourceUrl.replace('youtu.be/', 'www.youtube.com/embed/');
  }

  return (
    <div className='element-container'>
      <div className='label-value-row'>
        <div>Activity Type:</div>
        <div>Video Activity</div>
      </div>
      <div className='label-value-row'>
        <div>Questions:</div>
        <div>{model.questionRange}</div>
      </div>
      <div className='label-value-row'>
        <div>Directions:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.text} /></div>
      </div>
      <div className='label-value-row'>
        <div>Start Time:</div>
        <div>{Utils.formatTime(model.startTime)}</div>
      </div>
      <div className='label-value-row'>
        <div>End Time:</div>
        <div>{Utils.formatTime(model.endTime)}</div>
      </div>
      <div className='label-value-row'>
        <div>Video URL:</div>
        <div>{model.sourceUrl}</div>
      </div>
      <iframe src={embedUrl} title={model.title} />
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Show All Items At Once:</div>
            <div>{(model.showAllChildren) ? 'ON' : 'OFF'}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default VideoQuestion;
