import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../css/ScoringToolbarLayout.scss';
import { register } from '../../i18n';

import loadingDots from '../../images/tools/loadingDots.svg';

import { ContentType } from '../../Constants';
import TextToSpeechControls from '../components/tools/TextToSpeechControls';

import highlightAnnotationService from '../services/HighlightAnnotationService';
import lessonService from '../services/LessonService';
import scoringService from '../services/ScoringService';
import studentActivityService from '../services/StudentActivityService';
import toolbarService from '../services/ToolbarService';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';

import BasicCalculator from '../components/tools/BasicCalculator';
import CmapDetailDialog from '../components/tabbedFlyout/CmapDetailDialog';
import CombinedRuler from '../components/tools/CombinedRuler';
import CustomaryRuler from '../components/tools/CustomaryRuler';
import GraphingCalculator from '../components/tools/GraphingCalculator';
import Highlighter from '../components/tools/Highlighter';
import MetricRuler from '../components/tools/MetricRuler';
import Notepad from '../components/tools/Notepad';
import Protractor from '../components/tools/Protractor';
import QuestionLogo from '../components/scoringToolbar/QuestionLogo';
import ResourcePreviewDialog from '../components/tools/ResourcePreviewDialog';
import Reveal from '../components/tools/Reveal';
import ScientificCalculator from '../components/tools/ScientificCalculator';
import StickyNotes from '../components/tools/StickyNotes';
import TextHelpCustom from '../components/tools/TextHelpCustom';

const t = register('FlyupNavForScoring');
const t2 = register('GlobalQuestionLabels');
const t3 = register('AriaLabels');

const ScoringToolbarLayout = observer((props) => {
  const {
    lessonManager, toolbarManager, alignmentManager,
    studentActivityManager, highlightAnnotationManager, publisherManager
  } = useContext(MobXProviderContext);

  const [loading, setLoading] = useState(false);
  const model = lessonManager.getSlideModel(lessonManager.currentLessonElementId);
  const type = (model && model.type) ? model.type : '';
  const typeName = ContentType.getLabel(type);
  const exitRef = useRef();
  const checkBox = useRef();
  const checkBox2 = useRef();
  const saveRef = useRef();
  const notepadToggle = useRef();

  useEffect(() => {
    // run once to initialize the highlights.  Teacher default highlight mode is 'student'
    const hilightMode = highlightAnnotationManager.highlightUserMode || 'student';
    highlightAnnotationManager.setHighlightUserMode(hilightMode);
    highlightAnnotationService.switchHighightAndNotesView(highlightAnnotationManager.highlightUserMode);
  }, []);

  useAccessibilityClick(saveRef, async () => {
    setLoading(true);
    await scoringService.updateTestItemScore(lessonManager.currentLessonElementId);
    await scoringService.submitTeacherComment(lessonManager.currentLessonElementId);
    setLoading(false);
  });

  useAccessibilityClick(exitRef, async () => {
    await toolbarService.exitToolHandler({ lessonElementId: lessonManager.currentLessonElementId });
  });

  const handleCheckChange = () => {
    lessonService.setOnlyShowManual(!lessonManager.onlyShowManual);
  };

  const handleCheckChange2 = () => {
    lessonService.setOnlyShowDoNotScore(!lessonManager.onlyShowDoNotScore);
  };

  const handleCheckChangeNotepad = async () => {
    const activityId = studentActivityManager.studentActivityId;
    if (!toolbarManager.isNotepadOpen) {
      await studentActivityService.fetchStudentNotepadNote(activityId);
    }
    toolbarManager.setIsNotepadOpen(!toolbarManager.isNotepadOpen);
  };

  useAccessibilityClick(checkBox, async () => {
    handleCheckChange();
  });

  useAccessibilityClick(checkBox2, async () => {
    handleCheckChange2();
  });

  useAccessibilityClick(notepadToggle, async () => {
    handleCheckChangeNotepad();
  });

  const handleHighlightChange = async (data) => {
    highlightAnnotationManager.setHighlightUserMode(data.target.value);
    highlightAnnotationService.switchHighightAndNotesView(data.target.value);
  };

  return (
    <div aria-label={t3('mainToolbar')} className='scoring-toolbar-wrapper' data-ignore='1' role='menu'>
      {(publisherManager.hasWebReaderId && publisherManager.rsHref) && <TextToSpeechControls />}
      <div className='left-side-toolbar'>
        <QuestionLogo className={type} />
        <div aria-label={t3('studentName')} className='scoring-student-name'>{studentActivityManager.studentName}</div>
        <div aria-label={t3('questiontype')} className='scoring-question-type'>{typeName}</div>
      </div>

      <div className='right-side-toolbar'>
        <label className='scoring-check-label switch' htmlFor='manualOnly'>
          {t('showManual', 'Show only manually scored items:')}
          <input checked={lessonManager.onlyShowManual || lessonManager.startWithOnlyManual} name='manualOnly' readOnly tabIndex='0'
            type='checkbox' />
          <span ref={checkBox} className='slider' />
        </label>
        <label className='scoring-check-label switch' htmlFor='surveyOnly'>
          {t('showUnscored', 'Show only unscored items:')}
          <input checked={lessonManager.onlyShowDoNotScore} name='surveyOnly' readOnly tabIndex='0'
            type='checkbox' />
          <span ref={checkBox2} className='slider' />
        </label>
        <label className='scoring-check-label switch' htmlFor='showNotes'>
          {t('showNotePad', 'Show student notepad:')}
          <input checked={toolbarManager.isNotepadOpen} name='showNotes' readOnly tabIndex='0'
            type='checkbox' />
          <span ref={notepadToggle} className='slider' />
        </label>
        <select aria-label={t3('highlight')} className='highlight-select' name='highlight-select' onChange={handleHighlightChange}
          tabIndex='0' value={highlightAnnotationManager.highlightUserMode}>
          <option value='off'>{t('selectionOne', 'Highlights and Notes: Off')}</option>
          <option value='teacher'>{t('selectionTwo', 'Highlights and Notes: Teacher')}</option>
          <option value='student'>{t('selectionThree', 'Highlights and Notes: Student')}</option>
        </select>
        <div aria-label={t3('highlight')} className='scoring-highlight-menu' />
        {(loading) && <button ref={saveRef} aria-label={t3('savinginprogress')} className='scoring-save-button primary' disabled><img alt='loading' aria-hidden src={loadingDots} /></button>}
        {(!loading) && <button ref={saveRef} aria-label={t3('savebutton')} className='scoring-save-button primary'>{t2('save', 'Save')}</button>}

        {(!lessonManager.isLti) &&
          <button ref={exitRef} aria-label={t3('exitbutton')} className='scoring-exit-button' />}
      </div>

      {toolbarManager.isTextHelpOpen && <TextHelpCustom />}
      {(toolbarManager.isStickyNotesOpen && highlightAnnotationManager.highlightUserMode !== 'off') && <StickyNotes />}
      {toolbarManager.isNotepadOpen && <Notepad />}
      {(toolbarManager.isHighlightAnnotationOpen && highlightAnnotationManager.highlightUserMode === 'teacher') && <Highlighter />}

      {toolbarManager.isBasicCalculatorOpen && <BasicCalculator />}
      {toolbarManager.isScientificCalculatorOpen && <ScientificCalculator />}
      {toolbarManager.isGraphingCalculatorOpen && <GraphingCalculator />}

      {toolbarManager.isCustomaryRulerOpen && <CustomaryRuler />}
      {toolbarManager.isMetricRulerOpen && <MetricRuler />}
      {toolbarManager.isCombinedRulerOpen && <CombinedRuler />}

      {toolbarManager.isProtractorOpen && <Protractor />}

      {toolbarManager.isRevealOpen && <Reveal />}
      {toolbarManager.isResourcesOpen && <ResourcePreviewDialog />}

      {(alignmentManager.cmapDetailDialogIsOpen) && <CmapDetailDialog />}
    </div>
  );
});

export default ScoringToolbarLayout;
