import htmlService from '../services/HtmlService';
import promptService from '../services/PromptService';
import questionService from '../services/QuestionService';

import { ClozeBehavior } from './ClozeBehavior';

export class ClozeMathBehavior extends ClozeBehavior {
  setResponseData = (data, _responseAnswer, model, { dataId, lessonElementState } = {}) => {
    const modelValidationPrompt = promptService.getCurrentPrompt({
      dataId,
      lessonElementState,
      model,
      promptType: 'anyModelPrompt'
    });

    if (modelValidationPrompt?.answerType === 'multichoice') {
      // ClozeMath Multichoice
      if (!lessonElementState.currentResponseAnswer.userInputMap) {
        const userInputMap = questionService.updateUserInputMap({
          model, lessonElementState
        });
        userInputMap.set(dataId, {
          ...data.originalData,
          text: data.label
        });
      } else {
        lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
          ...data.originalData,
          text: data.label
        });
      }
    } else {
      // ClozeMath Typing
      if (!lessonElementState.currentResponseAnswer.userInputMap) {
        questionService.updateUserInputMap({ model, lessonElementState });
        // TODO remove
        // if (model.type === ContentType.CLOZE_MATH.type) {
        //   htmlService.resetHiddenCkeditorInstance();
        // }
      } else if (htmlService.isHtmlResponseFormat(model)) {
        lessonElementState.currentResponseAnswer.userInputMap.set(dataId, {
          dataId,
          text: data
        });
        // TODO remove
        // if (model.type === ContentType.CLOZE_MATH.type) {
        //   htmlService.resetHiddenCkeditorInstance();
        // }
      }
    }
  };
}

export default new ClozeMathBehavior();
