import React from 'react';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

// Removes html tags from the specified string.
const removeTags = (htmlString) => {
  return (htmlString) ? htmlString.replace(/<[^>]+>/g, '') : '';
};

// Gets the char or entity at the specified index and also returns the index of the next char or entity.
const getCharAtIndex = (str, idx) => {
  let compositeChar = '';
  let nextIdx = idx;
  // if the char is an entity, get the entity
  if (str[idx] === '&') {
    while (true) { // eslint-disable-line no-constant-condition
      const char = str[nextIdx];
      compositeChar += char;
      nextIdx++;
      if (char === ';' || nextIdx >= str.length) {
        break;
      }
    }
  } else {
    compositeChar += str[nextIdx];
    nextIdx++;
  }
  return { char: compositeChar, idx: nextIdx };
};

// Returns start tag for highlight if a highlight starts as the specified position.
const getStartTag = (indices, position) => {
  let tag = '';
  if (indices.includes(position)) {
    tag = '<span style="border: 2px solid black; padding: 2px; margin-right: 2px;">';
  }
  return tag;
};

// Returns end tag for highlight if a highlight ends as the specified position.
const getEndTag = (indices, position) => {
  let tag = '';
  if (indices.includes(position)) {
    tag = '</span>';
  }
  return tag;
};

// Gets an html string stripped of ckeditor html tags, but with tags inserted to show the highlight regions.
const getHighlightHtml = (model) => {
  if (!model.validation || !model.validation.ranges) {
    return null;
  }

  const startIndices = [];
  const endIndices = [];
  for (const range of model.validation.ranges) {
    startIndices.push(range.startIndex);
    endIndices.push(range.endIndex);
  }

  let position = 0;
  let idx = 0;
  const cleanBody = removeTags(model.body);
  let highlightHtml = '';

  while (true) { // eslint-disable-line no-constant-condition
    highlightHtml += getStartTag(startIndices, position);
    const charData = getCharAtIndex(cleanBody, idx);
    idx = charData.idx;
    position++;
    highlightHtml += charData.char;
    highlightHtml += getEndTag(endIndices, position);
    if (idx >= cleanBody.length) {
      break;
    }
  }

  return highlightHtml;
};

const TextHighlight = ({ model, isManuscriptShort }) => {
  const highlightHtml = getHighlightHtml(model);

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Highlight Text</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Question:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Text:</div>
        <div className='ckeditor-text text-area selectable-text'><HtmlComponent htmlStr={model.body} /></div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Text:</div>
        <div className='ckeditor-text text-area selectable-text'><HtmlComponent htmlStr={highlightHtml} /></div>
      </div>
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default TextHighlight;
