import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { register } from '../../i18n';
import CanvasOrganizerService from './CanvasOrganizerService';
import utils from './UtilsService';

class TestItem {
  CORRECT_COLOR = '#80bd44';
  INCORRECT_COLOR = '#bc4646';
}

export default class GraphService extends CanvasOrganizerService {
  constructor(model) {
    super();
    this.model = model;
  }



  GRAPH_WIDTH = 600;
  GRAPH_HEIGHT = 600;
  MIN_GRAPH_WIDTH = 300;
  MIN_GRAPH_HEIGHT = 300;
  MAX_GRAPH_WIDTH = 800;
  MAX_GRAPH_HEIGHT = 800;
  GRAPH_INSET = 60;
  FONT_SIZE = 16;
  TITLE_FONT_SIZE = 20;
  CONNECTOR_COLOR = '#3A9CFA';
  POINT_COLOR = '#3A9CFA';
  START_POINT_COLOR = 'lightblue';
  END_POINT_COLOR = this.POINT_COLOR;
  HOVER_COLOR = 'darkblue'; // '#297ADC';
  SELECTED_COLOR = 'darkblue'; // '#297ADC';
  DASHED_ARRAY = [5, 5];
  SELECT = 'select';
  LINE = 'line';
  RAY = 'ray';
  SEGMENT = 'segment';
  VECTOR = 'vector';
  POINT = 'point';
  DRAW = 'draw';

  // Types for start and end points of line, ray, segment, or vector
  OPEN = 'open';
  CLOSED = 'closed';
  HIDDEN = 'hidden';


  initializeGraph = () => {
    const self = this;

    this.state = [];
    this.mods = 0;

    self.dragging = false;
    this.model.graphWidth = this.model.graphWidth || this.GRAPH_WIDTH;
    this.model.graphHeight = this.model.graphHeight || this.GRAPH_HEIGHT;
    this.model.graphTitle = this.model.graphTitle || '';

    this.model.xAxisTitle = this.model.xAxisTitle || 'X-Axis';
    this.model.xAxisMinValue = this.model.xAxisMinValue || 0;
    this.model.xAxisMaxValue = this.model.xAxisMaxValue || 10;
    this.model.xAxisStepValue = this.model.xAxisStepValue || 1;

    this.model.yAxisTitle = this.model.yAxisTitle || 'Y-Axis';
    this.model.yAxisMinValue = this.model.yAxisMinValue || 0;
    this.model.yAxisMaxValue = this.model.yAxisMaxValue || 10;
    this.model.yAxisStepValue = this.model.yAxisStepValue || 1;

    this.createArrowClass();
    this.createLineDoubleArrowClass();

    this.cachedDragLeft = this.model.graphWidth / 2; // Used to constrain line dragging to graph.
    this.cachedDragTop = this.model.graphHeight / 2;

    if (!this.model.validation) {
      this.model.validation = {};
    }
  }

  isSnapToGrid = () => {
    return this.model.snapToGrid;
  }

  getTolerance = () => {
    if (this.isSnapToGrid()) {
      return 0;
    } else {
      return this.model.tolerance;
    }
  }

  getGraphInset = () => {
    let graphInset = this.GRAPH_INSET;

    if (typeof this.model.graphInset != 'undefined') {
      graphInset = this.model.graphInset;
    }

    return graphInset;
  }



  getSnapToPoint = (point) => {
    const { model } = this;
    if (this.isSnapToGrid()) {
      point.x = Math.round(point.x / model.xAxisStepValue) * model.xAxisStepValue;
      point.y = Math.round(point.y / model.yAxisStepValue) * model.yAxisStepValue;
    }
    return point;
  }



  createGraphControls = () => {

  }



  getHeight = () => {
    return this.model.graphHeight;
  }

  getWidth = () => {
    return this.model.graphWidth;
  }

  isSamePoint = (pointA, pointB, isApproximateMatch) => {
    if (isApproximateMatch) {
      return Math.abs(pointA.x - pointB.x) < 10 && Math.abs(pointA.y - pointB.y) < 10;
    } else {
      return pointA.x === pointB.x && pointA.y === pointB.y;
    }
  }

  isGraphReadOnly = () => {
    return this.readOnly || this.isEditReadOnly();
  }

  // proto.setIsReadOnly = function (readOnly) {
  //   var self = this;
  //   self.readOnly = readOnly;
  //   self.setFieldsReadOnly(readOnly);
  // }

  drawGraph = function () {
    let clickPoint = null;

    this.clearCanvas();

    this.canvas.setWidth(this.model.graphWidth);
    this.canvas.setHeight(this.model.graphHeight);
    this.canvas.calcOffset();

    // this.$get(".canvasOrganizerBody").css("width", model.graphWidth).css("height", model.graphHeight);
    this.showGrid();

    // if (this.isGraphReadOnly()) {
    //   setTimeout(() => {
    //     evotextUtil.showOverlay(self.$get().find(".canvas-container"), "transparent", null, {skipDisable: true});
    //   }, 1000);
    //   return;
    // } else {
    //   evotextUtil.hideOverlay();
    // }

    this.canvas.off('object:moving');
    this.canvas.on('object:moving', (evt) => {
      this.setIsDragging(true);

      if (evt.target.isElementGroup) {
        this.deleteDragPoints(evt.target.id);
        this.dragGroup(evt.target);
      } else if (evt.target.elementType === 'dragStartPoint' || evt.target.elementType === 'dragEndPoint') {
        this.moveDragPoint(evt.target.parentGroupId);
      }
    });

    this.canvas.off('mouse:over');
    this.canvas.on('mouse:over', (evt) => {
      if (evt.target) {
        // If over an element group.
        if (evt.target.isElementGroup) {
          evt.target.isHover = true;
          this.colorGroupElement(evt.target);
        }
        // If over a drag point
        else if (evt.target.parentGroupId) {
          evt.target.isHover = true;
          this.colorPointElement(evt.target);
        }
      }
    });

    this.canvas.off('mouse:out');
    this.canvas.on('mouse:out', (evt) => {
      if (evt.target) {
        // If over an element group.
        if (evt.target.isElementGroup) {
          evt.target.isHover = false;
          this.colorGroupElement(evt.target);
        }
        // If over a drag point
        else if (evt.target.parentGroupId) {
          evt.target.isHover = false;
          this.colorPointElement(evt.target);
        }
      }
    });

    this.canvas.off('mouse:down');
    this.canvas.on('mouse:down', (evt) => {
      // if (utils.isMobile())
      // {
      //   $("body").addClass("noContentScroll");
      // }
      // If no target of click, then switch to the graph canvas.
      if (!evt.target || !evt.target.elementType) {
        this.onGraphClickNoTarget(() => {
          clickPoint = this.convertCanvasPointToPoint({ x: evt.e.offsetX, y: evt.e.offsetY });
        });
      } else {
        clickPoint = this.convertCanvasPointToPoint({ x: evt.e.offsetX, y: evt.e.offsetY });
      }
    });

    this.canvas.off('mouse:up');
    this.canvas.on('mouse:up', (evt) => {
      // if (utils.isMobile()) {
      //   $("body").removeClass("noContentScroll");
      // }
      if (this.isDrawEditMode()) {
        return;
      }

      let isElementGroup = false;
      let isDragPoint = false;
      let isSinglePoint = false;
      let elementType = '';
      const point = this.convertCanvasPointToPoint({ x: evt.e.offsetX, y: evt.e.offsetY });

      if (this.isDragging()) {
        this.setIsDragging(false);
      }

      if (evt.target) {
        isElementGroup = evt.target.isElementGroup;
        elementType = evt.target.elementType;
        isDragPoint = evt.target.elementType === 'dragStartPoint' || evt.target.elementType === 'dragEndPoint';
        isSinglePoint = evt.target.elementType === 'point';
      }

      // Dragged group is dropped.
      if (isElementGroup) {
        const group = evt.target;
        const newGroup = this.dropGroup(group);
        if (this.isSamePoint(clickPoint, point)) {
          this.showGroupElementSelected(newGroup);
        } else {
          this.deselectAllElements();
          this.updateState();
        }
      }
      // Dragged positioning end point is dropped.
      else if (isDragPoint) {
        if (!this.isSamePoint(clickPoint, point)) {
          this.dropDragPoint(evt.target.parentGroupId);
        }
      } else if (isSinglePoint) {
        // self.canvas.remove(evt.target);
        const { isSelected } = evt.target;
        const { fill } = evt.target;
        const { stroke } = evt.target;
        const { strokeWidth } = evt.target;

        this.deselectAllElements();

        if (this.isSamePoint(clickPoint, point)) {
          this.togglePointElementSelected(evt.target, !isSelected);
        } else {
          this.canvas.remove(evt.target);
          this.addPointToGraph(point, { fill, stroke, strokeWidth });
          this.updateState();
        }
      } else if (this.isPointInGraph(point)) {
        // Adding a point
        if (this.isPointEditMode()) {
          if (evt.target && evt.target.elementType === 'point') {
            // Do nothing
          } else {
            this.addPointToGraph(point);
            this.updateState();
          }
        } else if (this.isSelectEditMode() || !this.isStartLineWithClick()) {
          // Do nothing.
        } else {
          // Starting a line, ray, segment, or vector
          if (evt.target && evt.target.elementType === 'tempStartPoint') {
            this.canvas.remove(evt.target);

            if (!this.isSamePoint(clickPoint, point)) {
              this.addTempStartPointToGraph(point);
            }
          } else if (!elementType || this.isSamePoint(clickPoint, point)) {
            const hasStartPoint = this.getPoints('tempStartPoint').length > 0;

            if (hasStartPoint) {
              const startPoint = this.getPoints('tempStartPoint')[0];
              const endPoint = point;

              this.addTempEndPointToGraph(endPoint);

              // Remove temporary start and end points.
              this.removeTempCanvasPoints();

              this.addGraphElement(startPoint, endPoint, this.currentStrokeColor, false, true, false, this.getElementEditMode?.());
              this.updateState();
            } else {
              this.addTempStartPointToGraph(point);
            }
          }
        }
      }

      this.updateResponseModel();
    });
  }

  updateResponseModel = () => {
    super.updateResponseModel();
  }

  /**
   Returns true if user can start a new line by clicking in the graph.
   */
  isStartLineWithClick = () => {
    return true;
  }

  /**
   Override to have other functionality when click on no target.
   */
  onGraphClickNoTarget = (callback) => {
    this.deselectAllElements();
    callback();
  }

  isDrawEditMode = () => {
    return this.elementEditMode === this.DRAW;
  }

  isPointEditMode = () => {
    return this.elementEditMode === this.POINT;
  }

  isLineEditMode = () => {
    return this.elementEditMode === this.LINE;
  }

  isRayEditMode = () => {
    return this.elementEditMode === this.RAY;
  }

  isSegmentEditMode = () => {
    return this.elementEditMode === this.SEGMENT;
  }

  isVectorEditMode = () => {
    return this.elementEditMode === this.VECTOR;
  }

  isSelectEditMode = () => {
    return this.elementEditMode === this.SELECT;
  }

  // proto.drawCorrectAnswers = function () {
  //   var self = this;
  //   var model = this.getModel();
  //
  //   if (model.validation.correctAnswers) {
  //     $.each(model.validation.correctAnswers, function (index, elementModel) {
  //       if (elementModel.elementType === "point") {
  //         self.drawPoint(elementModel.point);
  //       } else {
  //         self.addGraphElement(elementModel.points.startPoint, elementModel.points.endPoint, elementModel.color, false, elementModel.elementType);
  //       }
  //     });
  //   }
  // }
  //
  // proto.drawResponse = function () {
  //   var self = this;
  //   var responseModel = this.getResponseModel();
  //
  //   if (responseModel.answers) {
  //     $.each(responseModel.answers, function (index, elementModel) {
  //       if (elementModel.elementType === "point") {
  //         self.drawPoint(elementModel.point);
  //       } else {
  //         self.addGraphElement(elementModel.points.startPoint, elementModel.points.endPoint, elementModel.color, elementModel.dashed, elementModel.elementType);
  //       }
  //     });
  //   }
  // }

  setIsDragging = (dragging) => {
    this.dragging = dragging;
  }

  isDragging = () => {
    return this.dragging;
  }

  // proto.getCanvasHtml = function () {
  //   var html = '';
  //
  //   html += this.getGraphControlsHtml();
  //
  //   html += '<div class="body canvasOrganizerBody editSection" style="height:' + this.getHeightPx() + ';width:' + this.getWidthPx() + '">';
  //   html += this.getGraphControlsHtml();
  //
  //   html += '<canvas id="' + this.getCanvasId() + '" class="canvas"></canvas>';
  //   html += '</div>';
  //   return html;
  // }

  computeSlope = (startPoint, endPoint) => {
    let slope = null;

    if (endPoint.x - startPoint.x !== 0) {
      slope = (endPoint.y - startPoint.y) / (endPoint.x - startPoint.x);
    }
    return slope;
  }

  computeYIntercept = (startPoint, endPoint) => {
    const slope = this.computeSlope(startPoint, endPoint);
    let yIntercept = null;
    // y = mx + b;

    if (slope != null) {
      yIntercept = startPoint.y - slope * startPoint.x;
    }
    return yIntercept;
  }

  computeXIntercept = (startPoint, endPoint) => {
    const slope = this.computeSlope(startPoint, endPoint);
    const yIntercept = this.computeYIntercept(startPoint, endPoint);
    let xIntercept = null;
    // y = mx + b;

    // vertical line
    if (slope === null) {
      xIntercept = startPoint.x;
    } else if (slope !== 0) {
      xIntercept = -yIntercept / slope;
    }
    return xIntercept;
  }

  computeY = (slope, yIntercept, x) => {
    // y = mx + b;
    let y = null;

    if (slope != null) {
      y = slope * x + yIntercept;
    }

    return y;
  }

  computeX = (slope, yIntercept, y) => {
    // y = mx + b;
    let x = null;

    if (slope != null) {
      x = (y - yIntercept) / slope;
    }
    return x;
  }

  drawConnector = (startPoint, endPoint, color, dashed, elementType) => {
    // const model = this.model;

    let canvasConnectorObject = null;

    const xMin = this.getXMin();
    const xMax = this.getXMax();
    const yMin = this.getYMin();
    const yMax = this.getYMax();

    const slope = this.computeSlope(startPoint, endPoint);
    // console.log("slope " + slope);

    const yIntercept = this.computeYIntercept(startPoint, endPoint);
    // console.log("yIntercept " + yIntercept);

    const yAtMinX = this.computeY(slope, yIntercept, xMin);
    // console.log("yAtMinX " + yAtMinX);

    const yAtMaxX = this.computeY(slope, yIntercept, xMax);
    // console.log("yAtMaxX " + yAtMaxX);

    const xAtMinY = this.computeX(slope, yIntercept, yMin);
    // console.log("xAtMinY " + xAtMinY);

    const xAtMaxY = this.computeX(slope, yIntercept, yMax);
    // console.log("xAtMaxY " + xAtMaxY);

    let strokeDashArray = null;

    if (dashed) {
      strokeDashArray = this.DASHED_ARRAY;
    }

    if (elementType === this.LINE) {
      // vertical line
      if (slope === null) {
        startPoint = { x: startPoint.x, y: yMin };
        endPoint = { x: endPoint.x, y: yMax };
      } else {
        startPoint = { x: xMin, y: yAtMinX };
        endPoint = { x: xMax, y: yAtMaxX };

        // startPoint constraints
        if (startPoint.y < yMin) {
          startPoint = { x: xAtMinY, y: yMin };
        }
        if (startPoint.y > yMax) {
          startPoint = { x: xAtMaxY, y: yMax };
        }
        if (startPoint.x < xMin) {
          startPoint = { x: xAtMinY, y: yMin };
        }
        if (startPoint.x > xMax) {
          startPoint = { x: xAtMaxY, y: yMax };
        }

        // endPoint constraints
        if (endPoint.y < yMin) {
          endPoint = { x: xAtMinY, y: yMin };
        }
        if (endPoint.y > yMax) {
          endPoint = { x: xAtMaxY, y: yMax };
        }
        if (endPoint.x < xMin) {
          endPoint = { x: xAtMinY, y: yMin };
        }
        if (endPoint.x > xMax) {
          endPoint = { x: xAtMaxY, y: yMax };
        }
      }

      const canvasStartPoint = this.convertPointToCanvasPoint(startPoint, true);
      const canvasEndPoint = this.convertPointToCanvasPoint(endPoint, true);

      // canvasConnectorObject = this.drawDoubleArrow(canvasStartPoint.x, canvasStartPoint.y, canvasEndPoint.x, canvasEndPoint.y, {strokeDashArray: [5, 5], stroke:this.getCurrentStrokeColor(), perPixelTargetFind:true});
      canvasConnectorObject = this.drawDoubleArrow(canvasStartPoint.x, canvasStartPoint.y, canvasEndPoint.x, canvasEndPoint.y, {
        stroke: color,
        strokeDashArray,
        perPixelTargetFind: true
      });
    } else if (elementType === this.RAY) {
      // vertical line
      if (slope === null) {
        if (endPoint.y > startPoint.y) {
          endPoint = { x: endPoint.x, y: yMax };
        } else {
          endPoint = { x: endPoint.x, y: yMin };
        }
      } else if (slope === 0) {
        if (endPoint.x > startPoint.x) {
          endPoint = { x: xMax, y: endPoint.y };
        } else {
          endPoint = { x: xMin, y: endPoint.y };
        }
      } else {
        if (endPoint.x > startPoint.x && endPoint.y > startPoint.y) {
          endPoint = { x: xAtMaxY, y: yMax };

          if (xAtMaxY >= xMax) {
            endPoint = { x: xMax, y: yAtMaxX };
          }
        } else if (endPoint.x > startPoint.x && endPoint.y < startPoint.y) {
          endPoint = { x: xAtMinY, y: yMin };

          if (xAtMinY >= xMax) {
            endPoint = { x: xMax, y: yAtMaxX };
          }
        } else if (endPoint.x < startPoint.x && endPoint.y < startPoint.y) {
          endPoint = { x: xAtMinY, y: yMin };

          if (xAtMinY <= xMin) {
            endPoint = { x: xMin, y: yAtMinX };
          }
        } else if (endPoint.x < startPoint.x && endPoint.y > startPoint.y) {
          endPoint = { x: xAtMaxY, y: yMax };

          if (xAtMaxY <= xMin) {
            endPoint = { x: xMin, y: yAtMinX };
          }
        }
      }

      const canvasStartPoint = this.convertPointToCanvasPoint(startPoint, true);
      const canvasEndPoint = this.convertPointToCanvasPoint(endPoint, true);

      canvasConnectorObject = this.drawArrow(canvasStartPoint.x, canvasStartPoint.y, canvasEndPoint.x, canvasEndPoint.y, {
        stroke: color,
        strokeDashArray,
        perPixelTargetFind: true
      });
    } else if (elementType === this.SEGMENT) {
      const canvasStartPoint = this.convertPointToCanvasPoint(startPoint, true);
      const canvasEndPoint = this.convertPointToCanvasPoint(endPoint, true);

      canvasConnectorObject = this.drawLine(canvasStartPoint.x, canvasStartPoint.y, canvasEndPoint.x, canvasEndPoint.y, {
        stroke: color,
        strokeDashArray,
        perPixelTargetFind: true
      });
    } else if (elementType === this.VECTOR) {
      const canvasStartPoint = this.convertPointToCanvasPoint(startPoint, true);
      const canvasEndPoint = this.convertPointToCanvasPoint(endPoint, true);

      canvasConnectorObject = this.drawArrow(canvasStartPoint.x, canvasStartPoint.y, canvasEndPoint.x, canvasEndPoint.y, {
        stroke: color,
        strokeDashArray,
        perPixelTargetFind: true
      });
    }

    return canvasConnectorObject;
  }

  // proto.getCurrentStrokeColor = function () {
  //   return this.currentStrokeColor || Graph.CONNECTOR_COLOR;
  // }
  //
  // proto.setCurrentStrokeColor = function (color) {
  //   this.currentStrokeColor = color;
  //   var selectedElement = this.getSelectedElement();
  //
  //   if (selectedElement) {
  //     if (selectedElement.isElementGroup) {
  //       this.colorGroupElement(selectedElement, color);
  //     } else {
  //       selectedElement.stroke = this.currentStrokeColor;
  //       this.canvas.renderAll();
  //     }
  //
  //     this.updateState();
  //   }
  // }
  //
  // proto.setCurrentFillColor = function (color) {
  //   this.currentFillColor = color;
  //   var selectedElement = this.getSelectedElement();
  //   if (selectedElement) {
  //     if (selectedElement.isElementGroup) {
  //       // Element groups, such as segments don't have a fill.
  //       //selectedElement.color = color;
  //       //this.refreshGroup(selectedElement);
  //     } else {
  //       selectedElement.fill = this.currentFillColor;
  //       this.canvas.renderAll();
  //     }
  //
  //     this.updateState();
  //   }
  // }

  addGraphElement = (startPoint, endPoint, color, dashed, startPointType, endPointType, elementType) => {
    startPoint = this.getSnapToPoint(startPoint);
    endPoint = this.getSnapToPoint(endPoint);
    color = color || this.CONNECTOR_COLOR;

    const canvasConnectorObject = this.drawConnector(startPoint, endPoint, color, dashed, elementType);

    let startPointSettings = null;
    let endPointSettings = null;

    if (startPointType === this.HIDDEN) {
      startPointSettings = { opacity: 0.0 };
    } else if (startPointType === this.OPEN) {
      startPointSettings = { fill: '#fff', stroke: color, strokeWidth: 1 };
    }

    if (endPointType === this.HIDDEN) {
      endPointSettings = { opacity: 0.0 };
    } else if (endPointType === this.OPEN) {
      endPointSettings = { fill: '#fff', stroke: color, strokeWidth: 1 };
    }

    const canvasStartPointObject = this.addPointToGraph(startPoint, startPointSettings);
    const canvasEndPointObject = this.addPointToGraph(endPoint, endPointSettings);

    const groupId = uuidv4();

    canvasConnectorObject.groupId = groupId;
    canvasStartPointObject.groupId = groupId;
    canvasEndPointObject.groupId = groupId;

    canvasConnectorObject.groupType = elementType;
    canvasStartPointObject.groupType = elementType;
    canvasEndPointObject.groupType = elementType;

    canvasConnectorObject.elementType = 'connector';
    canvasStartPointObject.elementType = 'startPoint';
    canvasEndPointObject.elementType = 'endPoint';

    const group = this.groupGraphObjects(groupId);
    group.elementType = elementType;
    group.points = { startPoint, endPoint };
    group.color = color;
    group.dashed = dashed;
    group.startPointType = startPointType;
    group.endPointType = endPointType;

    this.createDragPoints(startPoint, endPoint, group.id, group.color, startPointType, endPointType, elementType);

    return group;
  }

  groupGraphObjects = (groupId) => {
    const groupObjects = [];
    const objects = this.findCanvasObjectsByGroup(groupId);
    const elementType = objects[0].groupType;
    const newGroupId = uuidv4();

    // Need to clone existing objects in order to group them.
    objects.forEach((object) => {
      const clone = fabric.util.object.clone(object);
      clone.groupId = newGroupId;
      clone.groupType = object.groupType;
      clone.elementType = object.elementType;

      if (object.point) {
        clone.point = object.point;
      }
      groupObjects.push(clone);
    });

    const group = new fabric.Group(groupObjects, {
      id: newGroupId,
      isElementGroup: true,
      elementType,
      originX: 'center',
      originY: 'center',
      hasControls: false,
      hasBorders: false,
      lockMovementX: false,
      lockMovementY: false,
      perPixelTargetFind: true
    });
    this.canvas.add(group);

    objects.forEach((object) => {
      this.canvas.remove(object);
    });

    return group;
  }

  createDragPoints = (startPoint, endPoint, groupId, color, startPointType, endPointType, groupElementType) => {
    const startFillColor = (startPointType === this.OPEN) ? '#fff' : color;
    const startStrokeWidth = (startPointType === this.OPEN) ? 1 : 0;
    const startOpacity = (startPointType === this.HIDDEN) ? 0.0 : 1.0;

    const dragStartCanvasPoint = this.drawPoint(startPoint, {
      elementType: 'dragStartPoint',
      stroke: color,
      strokeWidth: startStrokeWidth,
      fill: startFillColor,
      opacity: startOpacity,
    });

    const endFillColor = (endPointType === this.OPEN) ? '#fff' : color;
    const endStrokeWidth = (endPointType === this.OPEN) ? 1 : 0;
    const endOpacity = (endPointType === this.HIDDEN) ? 0.0 : 1.0;

    const dragEndCanvasPoint = this.drawPoint(endPoint, {
      elementType: 'dragEndPoint',
      stroke: color,
      strokeWidth: endStrokeWidth,
      fill: endFillColor,
      opacity: endOpacity,
    });

    dragStartCanvasPoint.parentGroupId = groupId;
    dragStartCanvasPoint.groupElementType = groupElementType;

    dragEndCanvasPoint.parentGroupId = groupId;
    dragEndCanvasPoint.groupElementType = groupElementType;
  }

  deleteDragPoints = (groupId) => {
    const dragPoints = this.canvas.getObjects().filter((obj) => obj.parentGroupId === groupId);

    dragPoints.forEach((dragPoint) => {
      this.canvas.remove(dragPoint);
    });
  }

  dragGroup = (group) => {
    const startPointObject = group.getObjects().find((obj) => obj.elementType === 'startPoint');
    const endPointObject = group.getObjects().find((obj) => obj.elementType === 'endPoint');

    const startPoint = this.convertCanvasPointToPoint({
      x: (group.left + startPointObject.left),
      y: group.top + startPointObject.top
    }, true);
    const isStartPointInGraph = this.isPointInGraph(startPoint);

    const endPoint = this.convertCanvasPointToPoint({
      x: (group.left + endPointObject.left),
      y: group.top + endPointObject.top
    }, true);
    const isEndPointInGraph = this.isPointInGraph(endPoint);

    if (!isStartPointInGraph || !isEndPointInGraph) {
      group.set({ left: this.cachedDragLeft, top: this.cachedDragTop });
    }

    this.cachedDragLeft = group.left;
    this.cachedDragTop = group.top;
  }

  deleteGroupElement = (groupElement) => {
    const objects = groupElement.getObjects();
    const { groupId } = objects[0];
    groupElement.destroy();
    this.canvas.remove(groupElement);
    this.deleteDragPoints(groupId);
  }

  copyGroupElement = (group) => {
    const self = this;

    const objects = group.getObjects();
    const { elementType } = group;
    const { color } = group;
    const { dashed } = group;
    const { startPointType } = group;
    const { endPointType } = group;
    let startPoint = null;
    let endPoint = null;
    let startPointN = null;
    let endPointN = null;

    this.deleteGroupElement(group);

    objects.forEach((object) => {
      if (object.elementType === 'startPoint') {
        startPoint = self.convertCanvasPointToPoint({ x: object.left, y: object.top });
        startPointN = self.convertCanvasPointToPoint({ x: object.left + 10, y: object.top });
      } else if (object.elementType === 'endPoint') {
        endPoint = self.convertCanvasPointToPoint({ x: object.left, y: object.top });
        endPointN = self.convertCanvasPointToPoint({ x: object.left + 10, y: object.top });
      }
    });

    self.updateResponseModel();
    const responseModel = self.getResponseModel();
    this.addGraphElement(startPoint, endPoint, color, dashed, startPointType, endPointType, elementType);
    this.addGraphElement(startPointN, endPointN, color, dashed, startPointType, endPointType, elementType);

    responseModel.elements.push({
      elementType,
      points: { startPoint: { x: startPointN.x, y: startPointN.y }, endPoint: { x: endPointN.x, y: endPointN.y } },
      dashed,
      startPointType,
      endPointType,
      color
    });

    self.updateResponseModel();
    self.updateState();
  }

  dropGroup = (group) => {
    const objects = group.getObjects();
    const { elementType } = group;
    const { color } = group;
    const { dashed } = group;
    const { startPointType } = group;
    const { endPointType } = group;
    const { isSelected } = group;
    let startPoint = null;
    let endPoint = null;

    this.deleteGroupElement(group);

    objects.forEach((object) => {
      if (object.elementType === 'startPoint') {
        startPoint = this.convertCanvasPointToPoint({ x: object.left, y: object.top });
      } else if (object.elementType === 'endPoint') {
        endPoint = this.convertCanvasPointToPoint({ x: object.left, y: object.top });
      }
    });

    const newGroup = this.addGraphElement(startPoint, endPoint, color, dashed, startPointType, endPointType, elementType);
    newGroup.isSelected = isSelected;

    return newGroup;
  }

  moveDragPoint = (groupId) => {
    // var self = this;
    const group = this.findCanvasGroup(groupId);
    const dragStartPointObject = this.canvas.getObjects().find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragStartPoint');
    const dragEndPointObject = this.canvas.getObjects().find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragEndPoint');

    if (group) {
      const objects = group.getObjects();
      group.destroy();
      this.canvas.remove(group);

      objects.forEach((object) => {
        if (object.elementType === 'connector') {
          this.canvas.add(object);
        }
      });
    }

    this.constrainObjectToGraph(dragStartPointObject);
    this.constrainObjectToGraph(dragEndPointObject);

    const connectorObject = this.canvas.getObjects().find((obj) => obj.groupId === groupId && obj.elementType === 'connector');
    connectorObject.set({ x1: dragStartPointObject.left, y1: dragStartPointObject.top, x2: dragEndPointObject.left, y2: dragEndPointObject.top });
  }

  constrainObjectToGraph = (object) => {
    const { model } = this;

    const width = model.graphWidth;
    const height = model.graphHeight;
    const left = this.getGraphInset();
    const top = this.getGraphInset();
    const right = width - this.getGraphInset();
    const bottom = height - this.getGraphInset();

    if (object.left < left) {
      object.left = left;
    }
    if (object.left > right) {
      object.left = right;
    }
    if (object.top < top) {
      object.top = top;
    }
    if (object.top > bottom) {
      object.top = bottom;
    }
  }

  isPointInGraph = (point) => {
    return point.x >= this.getXMin() &&
        point.x <= this.getXMax() &&
        point.y >= this.getYMin() &&
        point.y <= this.getYMax();
  }

  dropDragPoint = (groupId) => {
    let color = '';
    let dashed = '';

    const group = this.findCanvasGroup(groupId);
    const dragStartPointObject = this.canvas.getObjects().find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragStartPoint');
    const dragEndPointObject = this.canvas.getObjects().find((obj) => obj.parentGroupId === groupId && obj.elementType === 'dragEndPoint');
    const { groupElementType } = dragStartPointObject;
    const startPoint = this.convertCanvasPointToPoint({ x: dragStartPointObject.left, y: dragStartPointObject.top });
    const endPoint = this.convertCanvasPointToPoint({ x: dragEndPointObject.left, y: dragEndPointObject.top });
    const connectorObject = this.canvas.getObjects().find((obj) => obj.groupId === groupId && obj.elementType === 'connector');

    if (connectorObject) {
      color = connectorObject.stroke;
      dashed = connectorObject.strokeDashArray != null;
    }

    let startPointType = this.CLOSED;
    if (dragStartPointObject.opacity === 0.0) {
      startPointType = this.HIDDEN;
    } else if (dragStartPointObject.fill === '#fff') {
      startPointType = this.OPEN;
    }

    let endPointType = this.CLOSED;
    if (dragEndPointObject.opacity === 0.0) {
      endPointType = this.HIDDEN;
    } else if (dragEndPointObject.fill === '#fff') {
      endPointType = this.OPEN;
    }

    if (group) {
      group.destroy();
      this.canvas.remove(group);
    }

    this.canvas.remove(dragStartPointObject);
    this.canvas.remove(dragEndPointObject);
    this.canvas.remove(connectorObject);

    this.addGraphElement(startPoint, endPoint, color, dashed, startPointType, endPointType, groupElementType);
  }

  addTempStartPointToGraph = (point) => {
    return this.addPointToGraph(point, { elementType: 'tempStartPoint' });
  }

  addTempEndPointToGraph = (point) => {
    return this.addPointToGraph(point, { elementType: 'tempEndPoint' });
  }

  removeTempCanvasPoints = () => {
    this.findCanvasObjectsByElementType('tempStartPoint').forEach((canvasPoint) => {
      this.canvas.remove(canvasPoint);
    });
    this.findCanvasObjectsByElementType('tempEndPoint').forEach((canvasPoint) => {
      this.canvas.remove(canvasPoint);
    });
  }

  addPointToGraph = (point, settings) => {
    settings = settings || {};

    // const model = this.getModel();
    let existingPoint = false;
    let canvasPoint = null;

    if (settings.elementType) {
      existingPoint = this.findExistingPoint(point, settings.elementType);
    }

    if (existingPoint) {
      canvasPoint = this.findCanvasPoint(point);

      if (canvasPoint) {
        this.canvas.remove(canvasPoint);
      }
    } else {
      this.saveHistory(true);
      canvasPoint = this.drawPoint(point, settings);
      canvasPoint.point = point;
    }

    return canvasPoint;
  }

  findExistingPoint = (point, elementType) => {
    const points = this.getPoints(elementType);
    const existingPoint = points.find((obj) => obj.x === point.x && obj.y === point.y);

    return existingPoint;
  }

  findCanvasPoint = (point) => {
    const objects = this.canvas.getObjects();
    const canvasPoint = objects.find((obj) => obj.point.x === point.x && obj.point.y === point.y);

    return canvasPoint;
  }

  drawPoint = (point, settings) => {
    settings = settings || {};

    const canvasPoint = this.convertPointToCanvasPoint(point);
    const left = canvasPoint.x;
    const top = canvasPoint.y;
    // let pointColor = this.POINT_COLOR;
    let pointColor = '#000';
    const pointStrokeColor = this.STROKE;
    let { opacity } = settings;
    let { fill } = settings;
    let { stroke } = settings;
    let { strokeWidth } = settings;
    settings.elementType = settings.elementType || 'point';

    if (settings.elementType === 'tempStartPoint') {
      // pointColor = this.START_POINT_COLOR;
      pointColor = '#000';
    } else if (settings.elementType === 'tempEndPoint') {
      // pointColor = this.END_POINT_COLOR;
      pointColor = '#000';
    }

    /* if (settings.elementType === "tempStartPoint" || "dragStartPoint" ||
      settings.elementType === "tempEndPoint" || "dragEndPoint")
    {
      fill = "#fff";
      stroke = "#aaa";
      strokeWidth = 2;
    } */

    strokeWidth = strokeWidth || 0;
    settings.radius = settings.radius || 6;
    fill = fill || pointColor;
    stroke = stroke || pointStrokeColor;
    opacity = +(opacity !== 0) && (opacity || this.OPACITY);

    settings.elementType = settings.elementType || 'point';

    const circle = new fabric.Circle({
      type: 'circle',
      elementType: settings.elementType,
      left,
      top,
      strokeWidth,
      radius: settings.radius,
      fill,
      stroke,
      opacity,
      originX: 'center',
      originY: 'center',
      point
    });

    circle.hasInput = false;
    circle.hasControls = false;
    circle.hasBorders = false;

    this.canvas.add(circle);

    return circle;
  }

  getXMin = () => {
    const { model } = this;
    return this.getEvenlyDivided(model.xAxisMinValue, model.xAxisStepValue);
  }

  getXMax = () => {
    const { model } = this;
    return this.getEvenlyDivided(model.xAxisMaxValue, model.xAxisStepValue);
  }

  getYMin = () => {
    const { model } = this;
    return this.getEvenlyDivided(model.yAxisMinValue, model.yAxisStepValue);
  }

  getYMax = () => {
    const { model } = this;
    return this.getEvenlyDivided(model.yAxisMaxValue, model.yAxisStepValue);
  }

  getEvenlyDivided = (value, step) => {
    const sign = value < 0 ? -1 : 1;
    const ratio = Math.ceil(Math.abs(value) / step);

    return ratio * step * sign;
  }

  isGraphVisible = () => {
    return this.graphVisible;
  }

  setGraphVisible = (isVisible, skipShowResponse) => {
    this.graphVisible = isVisible;

    //    this.updateResponseModel();
    this.drawGraph();

    if (!skipShowResponse) {
      this.showResponse();
    }
  }

  /**
    Override this function to keep a state array for undo/redo.
   */
  updateState = () => {

  }

  // proto.setAxesVisible = function(isVisible)
  // {
  //   var model = this.getModel();
  //   model.axesVisible = isVisible;
  //
  //   this.updateResponseModel();
  //   this.drawGraph();
  //   this.showResponse();
  // }

  showGrid = () => {
    // var model = this.getModel();

    const width = this.model.graphWidth;
    const height = this.model.graphHeight;
    const chartWidth = width - 2 * this.getGraphInset();
    const chartHeight = height - 2 * this.getGraphInset();
    const xMin = this.getXMin();
    const xMax = this.getXMax();
    const yMin = this.getYMin();
    const yMax = this.getYMax();

    if (this.isGraphVisible()) {
      this.drawText(this.model.graphTitle, { left: width / 2, top: 15, originX: 'center', fontSize: this.TITLE_FONT_SIZE });

      if (this.model.hasAxes) {
        this.drawText(this.model.yAxisTitle, { left: 5, top: height / 2, angle: -90, originX: 'center', fontSize: this.TITLE_FONT_SIZE });
        this.drawText(this.model.xAxisTitle, { left: width / 2, top: height - 35, originX: 'center', fontSize: this.TITLE_FONT_SIZE });
      }
    }

    // Make sure the entered values are valid before drawing.
    if (xMin <= xMax && this.model.xAxisStepValue > 0) {
      const xGridLineCount = Math.round((xMax - xMin) / this.model.xAxisStepValue);
      const xGridWidth = Math.round(chartWidth / xGridLineCount);
      let xStart = this.getGraphInset();

      // Draw vertical grid lines and x-axis values
      for (let i = 0; i <= xGridLineCount; i++) {
        const xValue = utils.roundToDecimal(xMin + i * this.model.xAxisStepValue);
        const stroke = (xValue === 0 && this.model.hasAxes) ? 'black' : 'gray';
        const strokeWidth = (xValue === 0 && this.model.hasAxes) ? this.LINE_STROKE : this.LINE_STROKE_THIN;

        if (this.isGraphVisible()) {
          this.drawLine(xStart, this.getGraphInset(), xStart, chartHeight + this.getGraphInset(), { stroke, strokeWidth, selectable: false });

          if (this.model.hasAxes) {
            this.drawText(xValue.toString(), { left: xStart, top: chartHeight + this.getGraphInset() + 4, originX: 'center', fontSize: this.FONT_SIZE });
          }
        }

        xStart += xGridWidth;
      }
    }

    if (yMin <= yMax && this.model.yAxisStepValue > 0) {
      const yGridLineCount = Math.round((yMax - yMin) / this.model.yAxisStepValue);
      const yGridWidth = Math.round(chartHeight / yGridLineCount);
      let yStart = this.getGraphInset();

      // Draw horizontal grid lines and y-axis values
      for (let i = 0; i <= yGridLineCount; i++) {
        const yValue = utils.roundToDecimal(yMax - i * this.model.yAxisStepValue);
        const stroke = (yValue === 0 && this.model.hasAxes) ? 'black' : 'gray';
        const strokeWidth = (yValue === 0 && this.model.hasAxes) ? this.LINE_STROKE : this.LINE_STROKE_THIN;

        if (this.isGraphVisible()) {
          this.drawLine(this.getGraphInset(), yStart, chartWidth + this.getGraphInset(), yStart, { stroke, strokeWidth, selectable: false });

          if (this.model.hasAxes) {
            this.drawText(yValue.toString(), { left: this.getGraphInset() - 5, top: yStart, originX: 'right', originY: 'center', fontSize: this.FONT_SIZE });
          }
        }

        yStart += yGridWidth;
      }
    }
  }

  convertCanvasPointToPoint = (canvasPoint) => {
    // var model = this.getModel();
    let point = { x: 0, y: 0 };

    const left = this.getGraphInset();
    const right = this.model.graphWidth - this.getGraphInset();
    const width = right - left;

    point.x = ((canvasPoint.x - left) / width) * (this.getXMax() - this.getXMin()) + this.getXMin();

    const top = this.getGraphInset();
    const bottom = this.model.graphHeight - this.getGraphInset();
    const height = bottom - top;

    point.y = ((bottom - canvasPoint.y) / height) * (this.getYMax() - this.getYMin()) + this.getYMin();
    point = this.getSnapToPoint(point);

    // avoid -0
    if (point.x === 0) {
      point.x = 0;
    }
    if (point.y === 0) {
      point.y = 0;
    }

    return point;
  }

  convertPointToCanvasPoint = (point, skipSnapTo) => {
    const { model } = this;
    const canvasPoint = { x: 0, y: 0 };

    const left = this.getGraphInset();
    const right = model.graphWidth - this.getGraphInset();

    if (!skipSnapTo) {
      point = this.getSnapToPoint(point);
    }

    canvasPoint.x = (point.x - this.getXMin()) / (this.getXMax() - this.getXMin()) * (right - left) + left;

    const top = this.getGraphInset();
    const bottom = model.graphHeight - this.getGraphInset();

    canvasPoint.y = (1 - (point.y - this.getYMin()) / (this.getYMax() - this.getYMin())) * (bottom - top) + top;

    return canvasPoint;
  }

  getPoints = (elementType) => {
    const self = this;
    const objects = this.canvas.getObjects();
    const points = [];

    elementType = elementType || 'point';

    if (objects.length === 0) {
      return null;
    }

    objects.forEach((object) => {
      if (object.elementType === elementType) {
        const point = self.convertCanvasPointToPoint({ x: object.left, y: object.top });
        points.push(point);
      }
    });
    return points;
  }

  /**
   * Returns a model of the elements.
   *
   * Format: [{"elementType": "point", point:{x:2, y:3}},
   *       {"elementType": "line, "points": {startPoint:{x:2, y:3}, endPoint:{x:4, y:5}}}]
   */
  getElementModels = () => {
    // var self = this;
    const elementGroups = this.findCanvasElementGroups();
    const elementPoints = this.findCanvasObjectsByElementType('point');
    const elementModels = [];

    // points
    elementPoints.forEach((canvasElementPoint) => {
      const elementModel = {
        elementType: 'point',
        point: canvasElementPoint.point,
        fill: canvasElementPoint.fill,
        stroke: canvasElementPoint.stroke,
        strokeWidth: canvasElementPoint.strokeWidth
      };
      elementModels.push(elementModel);
    });

    // elementGroups such as "line", "ray", "segment", "vector"
    elementGroups.forEach((canvasElementGroup) => {
      const elementModel = {
        elementType: canvasElementGroup.elementType,
        points: canvasElementGroup.points,
        color: canvasElementGroup.color,
        dashed: canvasElementGroup.dashed,
        startPointType: canvasElementGroup.startPointType,
        endPointType: canvasElementGroup.endPointType,
      };

      elementModels.push(elementModel);
    });
    return elementModels;
  }

  // proto.updateCanvasModel = function()
  // {
  //   var model = this.getModel();
  //
  //   //model.snapToGrid = true; //this.$get(".snapToGrid").prop("checked");
  //   var tolerance = 100; //parseInt(utils.getHtmlOrVal(this.$get(".tolerance")));
  //
  //   var graphWidth = parseInt(utils.getHtmlOrVal(this.$get(".graphWidth")));
  //   var graphHeight = parseInt(utils.getHtmlOrVal(this.$get(".graphHeight")));
  //
  //   if (graphWidth < Graph.MIN_GRAPH_WIDTH)
  //   {
  //     graphWidth = Graph.MIN_GRAPH_WIDTH;
  //     utils.setHtmlOrVal(this.$get(".graphWidth"), Graph.MIN_GRAPH_WIDTH)
  //   }
  //   else if (graphWidth > Graph.MAX_GRAPH_WIDTH)
  //   {
  //     graphWidth = Graph.MAX_GRAPH_WIDTH;
  //     utils.setHtmlOrVal(this.$get(".graphWidth"), Graph.MAX_GRAPH_WIDTH)
  //   }
  //
  //   if (graphHeight < Graph.MIN_GRAPH_HEIGHT)
  //   {
  //     graphHeight = Graph.MIN_GRAPH_HEIGHT;
  //     utils.setHtmlOrVal(this.$get(".graphHeight"), Graph.MIN_GRAPH_HEIGHT)
  //   }
  //   else if (graphHeight > Graph.MAX_GRAPH_HEIGHT)
  //   {
  //     graphHeight = Graph.MAX_GRAPH_HEIGHT;
  //     utils.setHtmlOrVal(this.$get(".graphHeight"), Graph.MAX_GRAPH_HEIGHT)
  //   }
  //
  //   var graphTitle = utils.getHtmlOrVal(this.$get(".graphTitle"));
  //
  //   var xAxisTitle = utils.getHtmlOrVal(this.$get(".xAxisTitle"));
  //   var xAxisMinValue = utils.roundToDecimal(parseFloat(utils.getHtmlOrVal(this.$get(".xAxisMinValue"))));
  //   var xAxisMaxValue = utils.roundToDecimal(parseFloat(utils.getHtmlOrVal(this.$get(".xAxisMaxValue"))));
  //   var xAxisStepValue = utils.roundToDecimal(parseFloat(utils.getHtmlOrVal(this.$get(".xAxisStepValue"))));
  //
  //   var yAxisTitle = utils.getHtmlOrVal(this.$get(".yAxisTitle"));
  //   var yAxisMinValue = utils.roundToDecimal(parseFloat(utils.getHtmlOrVal(this.$get(".yAxisMinValue"))));
  //   var yAxisMaxValue = utils.roundToDecimal(parseFloat(utils.getHtmlOrVal(this.$get(".yAxisMaxValue"))));
  //   var yAxisStepValue = utils.roundToDecimal(parseFloat(utils.getHtmlOrVal(this.$get(".yAxisStepValue"))));
  //
  //
  //   if (tolerance >= 0)
  //   {
  //     model.tolerance = tolerance;
  //   }
  //   else
  //   {
  //     utils.setHtmlOrVal(this.$get(".tolerance"), model.tolerance);
  //   }
  //
  //   model.graphWidth = graphWidth;
  //   model.graphHeight = graphHeight;
  //   model.graphTitle = graphTitle;
  //
  //   model.xAxisTitle = xAxisTitle;
  //   model.xAxisMinValue = xAxisMinValue;
  //   model.xAxisMaxValue = xAxisMaxValue;
  //   model.xAxisStepValue = xAxisStepValue;
  //
  //   model.yAxisTitle = yAxisTitle;
  //   model.yAxisMinValue = yAxisMinValue;
  //   model.yAxisMaxValue = yAxisMaxValue;
  //   model.yAxisStepValue = yAxisStepValue;
  //
  // }
  //
  // proto.updateGraphModel = function()
  // {
  //   console.log("Override to update the graph model.");
  //
  //   var model = this.getModel();
  //
  //   model.validation.correctAnswers = this.getElementModels();
  // }
  //
  // proto.updateTestItemModel = function()
  //   {
  //   this.updateCanvasModel();
  //   this.updateGraphModel();
  //   };
  //
  //   proto.initializeTestItemEditor = function()
  // {
  //   var self = this;
  //
  // };
  //
  //   proto.destroyTestItemEditor = function()
  // {
  //   var self = this;
  // };
  //
  // proto.updateResponseModel = function()
  // {
  //   var responseModel = this.getResponseModel();
  //
  //   responseModel.answers = this.getElementModels();
  //
  //   this.setResponseModel(responseModel);
  // };
  //
  // proto.showResponse = function(callback)
  // {
  //   this.clear();
  //   this.drawResponse();
  //
  //   if (callback)
  //   {
  //     callback();
  //   }
  // }
  //
  // proto.showCorrectAnswer = function(callback)
  // {
  //   var self = this;
  //   var model = this.getModel();
  //
  //   this.clear();
  //
  //   this.drawCorrectAnswers();
  //   if (callback)
  //   {
  //     callback();
  //   }
  // }

  saveHistory = (savehistory) => {
    /* if (savehistory === true)
    {
        var stateJson = JSON.stringify(this.canvas);
        this.state.push(stateJson);
    } */
  };

  // // NOT YET WORKING!!!
  // proto.undo = function()
  // {
  //     if (this.mods < this.state.length)
  //     {
  //         this.canvas.clear().renderAll();
  //         this.canvas.loadFromJSON(this.state[this.state.length - 1 - this.mods - 1]);
  //         this.canvas.renderAll();
  //         this.mods += 1;
  //     }
  // };
  //
  // // NOT YET WORKING!!!
  // proto.redo = function()
  // {
  //     if (this.mods > 0)
  //     {
  //       this. canvas.clear().renderAll();
  //       this.canvas.loadFromJSON(this.state[this.state.length - 1 - this.mods + 1]);
  //       this.canvas.renderAll();
  //       this.mods -= 1;
  //     }
  // };

  clear = () => {
    this.clearPoints();

    this.findCanvasElementGroups().forEach((group) => {
      this.canvas.remove(group);
    });

    this.findCanvasObjectsByElementType('dragStartPoint').forEach((canvasPoint) => {
      this.canvas.remove(canvasPoint);
    });

    this.findCanvasObjectsByElementType('dragEndPoint').forEach((canvasPoint) => {
      this.canvas.remove(canvasPoint);
    });

    this.removeTempCanvasPoints();
  }

  getGraphPoints = () => {
    return this.canvas.getObjects().filter((obj) => obj.elementType === 'point');
  }

  /**
   * Returns canvas objects that have a specified groupId.
   */
  findCanvasObjectsByGroup = (groupId) => {
    return this.canvas.getObjects().filter((obj) => obj.groupId === groupId);
  }

  /**
   * Returns canvas objects that have a specified groupId.
   */
  findCanvasGroup = (groupId) => {
    return this.canvas.getObjects().find((obj) => obj.id === groupId);
  }

  /**
   * Returns canvas objects that have a specified groupId.
   */
  findCanvasElementGroups = () => {
    return this.canvas.getObjects().filter((obj) => obj.isElementGroup);
  }

  findCanvasObjectsByElementType = (elementType) => {
    return this.canvas.getObjects().filter((obj) => obj.elementType === elementType);
  }

  // proto.findCanvasDrawObjects = function()
  // {
  //   var drawObjects = [];
  //
  //   $.each(this.canvas.getObjects(), function(index, drawObject) {
  //     if (drawObject.path)
  //     {
  //       drawObjects.push(drawObject);
  //     }
  //   });
  //
  //   return drawObjects;
  // }

  clearPoints = () => {
    // var self = this;
    const graphPoints = this.getGraphPoints();

    graphPoints.forEach((graphPoint) => {
      this.canvas.remove(graphPoint);
    });
  };

  // proto.getPromptCount = function()
  //   {
  //     var model = this.getModel();
  //     return model.validation.correctAnswers.length;
  //   }
  //
  // proto.getScore = function()
  // {
  //   var score = 0;
  //   var tempScore = 0;
  //   var self = this;
  //   var model = this.getModel();
  //   var responseModel = this.getResponseModel();
  //   var promptCount = this.getPromptCount();
  //
  //   $.each(responseModel.answers, function(index, responseModelElement) {
  //
  //     if (responseModelElement.elementType === "point")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"point"});
  //       var isMatch = self.isPointElementMatch(correctElements, responseModelElement);
  //
  //       if (isMatch)
  //       {
  //         tempScore += 1;
  //       }
  //       else
  //       {
  //         tempScore -= 1;
  //       }
  //     }
  //     else if (responseModelElement.elementType === "line")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"line"});
  //       var isMatch = self.isLineMatch(correctElements, responseModelElement);
  //
  //       if (isMatch)
  //       {
  //         tempScore += 1;
  //       }
  //       else
  //       {
  //         tempScore -= 1;
  //       }
  //     }
  //     else if (responseModelElement.elementType === "ray")
  //     {
  //       var isMatch = false;
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"ray"});
  //       var isMatch = self.isRayMatch(correctElements, responseModelElement);
  //
  //       if (isMatch)
  //       {
  //         tempScore += 1;
  //       }
  //       else
  //       {
  //         tempScore -= 1;
  //       }
  //     }
  //     else if (responseModelElement.elementType === "segment")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"segment"});
  //       var isMatch = self.isSegmentMatch(correctElements, responseModelElement);
  //
  //       if (isMatch)
  //       {
  //         tempScore += 1;
  //       }
  //       else
  //       {
  //         tempScore -= 1;
  //       }
  //     }
  //     else if (responseModelElement.elementType === "vector")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"vector"});
  //       var isMatch = self.isVectorMatch(correctElements, responseModelElement);
  //
  //       if (isMatch)
  //       {
  //         tempScore += 1;
  //       }
  //       else
  //       {
  //         tempScore -= 1;
  //       }
  //     }
  //
  //
  //   });
  //
  //   if (this.getScoringType() === TestItem.EXACT_SCORING)
  //   {
  //     score = (tempScore === promptCount)?this.getMaxScore():0;
  //   }
  //   else if (this.getScoringType() === TestItem.PARTIAL_SCORING)
  //   {
  //     score = tempScore/promptCount * this.getMaxScore();
  //   }
  //   else if (this.getScoringType() === TestItem.PARTIAL_MATCH_RESPONSE_SCORING)
  //   {
  //     score = tempScore;
  //   }
  //
  //   return score;
  // };
  //
  // /**
  //  * Returns true if correctElements contains a response element with the same slope and intercepts
  //  */
  // proto.isLineMatch = function(correctElements, responseModelElement)
  // {
  //   var self = this;
  //   var isMatch = false;
  //
  // //    if (this.isSnapToGrid())
  // //    {
  // //      $.each(correctElements, function(index, correctElement) {
  // //
  // //        var correctXIntercept = self.computeXIntercept(correctElement.points.startPoint, correctElement.points.endPoint);
  // //        var correctYIntercept = self.computeYIntercept(correctElement.points.startPoint, correctElement.points.endPoint);
  // //        var correctSlope = self.computeSlope(correctElement.points.startPoint, correctElement.points.endPoint);
  // //
  // //        var responseXIntercept = self.computeXIntercept(responseModelElement.points.startPoint, responseModelElement.points.endPoint);
  // //        var responseYIntercept = self.computeYIntercept(responseModelElement.points.startPoint, responseModelElement.points.endPoint);
  // //        var responseSlope = self.computeSlope(responseModelElement.points.startPoint, responseModelElement.points.endPoint);
  // //
  // //        if ((responseXIntercept === correctXIntercept && responseSlope === correctSlope) ||
  // //            (responseYIntercept === correctYIntercept && responseSlope === correctSlope))
  // //        {
  // //          isMatch = true;
  // //        }
  // //      });
  // //    }
  // //    else
  // //    {
  // $.each(correctElements, function(index, correctElement) {
  //
  //   var correctXIntercept = self.computeXIntercept(correctElement.points.startPoint, correctElement.points.endPoint);
  //   var correctYIntercept = self.computeYIntercept(correctElement.points.startPoint, correctElement.points.endPoint);
  //   var correctSlope = self.computeSlope(correctElement.points.startPoint, correctElement.points.endPoint);
  //
  //   var responseXIntercept = self.computeXIntercept(responseModelElement.points.startPoint, responseModelElement.points.endPoint);
  //   var responseYIntercept = self.computeYIntercept(responseModelElement.points.startPoint, responseModelElement.points.endPoint);
  //   var responseSlope = self.computeSlope(responseModelElement.points.startPoint, responseModelElement.points.endPoint);
  //
  //   if ((self.isCoordMatch(correctXIntercept, responseXIntercept)  && self.isSlopeMatch(correctSlope, responseSlope)) ||
  //       (self.isCoordMatch(correctYIntercept, responseYIntercept) && self.isSlopeMatch(correctSlope, responseSlope)))
  //   {
  //     isMatch = true;
  //   }
  // });
  // //    }
  //
  //     return isMatch;
  //   }
  //
  // /**
  //  * Returns true if correctElements contains a response element with the same startPoint and slope.
  //  */
  // proto.isRayMatch = function(correctElements, responseModelElement)
  // {
  //   var self = this;
  //   var isMatch = false;
  //
  //   var correctElementsWithStartPoint = [];
  //   $.each(correctElements, function(index, correctElement) {
  //
  //     if (self.isPointMatch(correctElement.points.startPoint, responseModelElement.points.startPoint))
  //     {
  //       correctElementsWithStartPoint.push(correctElement);
  //     }
  //   });
  //
  //   $.each(correctElementsWithStartPoint, function(index, correctElementWithStartPoint)
  //   {
  //     var correctSlope = self.computeSlope(correctElementWithStartPoint.points.startPoint, correctElementWithStartPoint.points.endPoint);
  //     var responseSlope = self.computeSlope(responseModelElement.points.startPoint, responseModelElement.points.endPoint);
  //
  //     if (self.isSlopeMatch(correctSlope, responseSlope))
  //     {
  //       isMatch = true;
  //     }
  //   });
  //
  //   return isMatch;
  // }
  //
  // /**
  //  * Returns true if correctElements contains a response element with the same startPoint and endPoint or with startPoint and endPoint reversed.
  //  */
  // proto.isSegmentMatch = function(correctElements, responseModelElement)
  // {
  //   var self = this;
  //   var isMatch = false;
  //
  //   $.each(correctElements, function(index, correctElement) {
  //
  //     if ((self.isPointMatch(correctElement.points.startPoint, responseModelElement.points.startPoint) &&
  //         self.isPointMatch(correctElement.points.endPoint, responseModelElement.points.endPoint)) ||
  //
  //         (self.isPointMatch(correctElement.points.startPoint, responseModelElement.points.endPoint) &&
  //           self.isPointMatch(correctElement.points.endPoint, responseModelElement.points.startPoint)))
  //     {
  //       isMatch = true;
  //     }
  //   });
  //
  //   return isMatch;
  // }
  //
  // /**
  //  * Returns true if correctElements contains a response element with the same startPoint and endPoint.
  //  */
  // proto.isVectorMatch = function(correctElements, responseModelElement)
  // {
  //   var self = this;
  //   var isMatch = false;
  //
  //   $.each(correctElements, function(index, correctElement) {
  //
  //     if (self.isPointMatch(correctElement.points.startPoint, responseModelElement.points.startPoint) &&
  //         self.isPointMatch(correctElement.points.endPoint, responseModelElement.points.endPoint))
  //     {
  //       isMatch = true;
  //     }
  //   });
  //
  //   return isMatch;
  // }
  //
  //   // NOT USED
  //   proto.makePointArray = function(objectArray, pointName)
  //   {
  //     var pointArray = [];
  //
  //     $.each(objectArray, function(index, obj) {
  //       var pointObj = obj[pointName]
  //       pointArray.push({x:pointObj.x, y:pointObj.y});
  //     });
  //
  //     return pointArray;
  //   }
  //
  // proto.isPointElementMatch = function(correctElements, responseModelElement)
  // {
  //   var self = this;
  //   var isMatch = false;
  //
  //   if (this.isSnapToGrid())
  //   {
  //     isMatch = _.find(correctElements, {point:responseModelElement.point}) != null;
  //   }
  //   else
  //   {
  //     $.each(correctElements, function(index, correctPoint) {
  //       if (self.isPointMatch(correctPoint.point, responseModelElement.point))
  //       {
  //         isMatch = true;
  //       }
  //     });
  //   }
  //   return isMatch;
  // }
  //
  // /**
  //  * Is a point within tolerance of targetPoint?
  //  */
  // proto.isPointMatch = function(targetPoint, point)
  // {
  //   return this.isCoordMatch(targetPoint.x, point.x) && this.isCoordMatch(targetPoint.y, point.y);
  // }
  //
  // proto.isCoordMatch = function(coordTarget, coord)
  // {
  //   var tolerance = this.getTolerance();
  //   var lowerBound = coordTarget * (1 - tolerance/100);
  //   var upperBound = coordTarget * (1 + tolerance/100);
  //
  //   return coord >= lowerBound && coord <= upperBound;
  // }
  //
  // proto.isSlopeMatch = function(targetSlope, slope)
  // {
  //   var tolerance = this.getTolerance();
  //   var lowerBound = targetSlope * (1 - tolerance/100);
  //   var upperBound = targetSlope * (1 + tolerance/100);
  //
  //   return slope >= lowerBound && slope <= upperBound;
  // }

  /**
   * Apply a color to a point element.
   */
  colorPointElement = (pointElement) => {
    let fill = pointElement.fill || this.POINT_COLOR;
    let radius = 6;

    if (pointElement.isHover) {
      // fill = Graph.HOVER_COLOR;
      if (pointElement.elementType === 'point') {
        radius = 10;
      }
    } else if (pointElement.isCorrect) {
      fill = TestItem.CORRECT_COLOR;
    } else if (pointElement.isIncorrect) {
      fill = TestItem.INCORRECT_COLOR;
    } else if (pointElement.isSelected) {
      // fill = Graph.SELECTED_COLOR;

      if (pointElement.elementType === 'point') {
        radius = 10;
      }
    }

    pointElement.set('fill', fill);
    pointElement.set('radius', radius);

    this.canvas.renderAll();
  }

  /**
   * Set a group element's coloration based on its state. (e.g., isHover, isSelected).
   *
   */
  colorGroupElement = (canvasElement, color) => {
    const groupId = canvasElement.id;
    const dragPoints = this.canvas.getObjects().filter((obj) => obj.parentGroupId === groupId);
    let strokeWidth = this.LINE_STROKE;

    color = color || canvasElement.color;

    if (canvasElement.isHover) {
      // color = Graph.HOVER_COLOR;
      strokeWidth = 4;
    } else if (canvasElement.isCorrect) {
      color = TestItem.CORRECT_COLOR;
    } else if (canvasElement.isIncorrect) {
      color = TestItem.INCORRECT_COLOR;
    } else if (canvasElement.isSelected) {
      // color = Graph.SELECTED_COLOR;
      strokeWidth = 4;
    }

    canvasElement.color = color;

    // color drag points
    dragPoints.forEach((dragPoint) => {
      if (dragPoint.elementType === 'dragStartPoint') {
        if (canvasElement.startPointType === this.CLOSED) {
          dragPoint.set('fill', color);
        }
      } else if (dragPoint.elementType === 'dragEndPoint') {
        if (canvasElement.endPointType === this.CLOSED) {
          dragPoint.set('fill', color);
        }
      }
    });

    // color child elements
    canvasElement.getObjects().forEach((childObject) => {
      if (childObject.elementType === 'startPoint') {
        if (canvasElement.startPointType === this.CLOSED) {
          childObject.set('fill', color);
        }
      } else if (childObject.elementType === 'endPoint') {
        if (canvasElement.endPointType === this.CLOSED) {
          childObject.set('fill', color);
        }
      }

      childObject.set('stroke', color);
      childObject.set('strokeWidth', strokeWidth);
    });
    this.canvas.renderAll();
  }

  deselectAllElements = () => {
    this.deselectAllPointElements();
    this.deselectAllGroupElements();
    // this.$get().removeClass('elementSelected');
  }

  deselectAllPointElements = () => {
    const pointElements = this.findCanvasObjectsByElementType('point');

    pointElements.forEach((pointElement) => {
      pointElement.isSelected = false;
      this.colorPointElement(pointElement);
    });
  }

  deselectAllGroupElements = () => {
    const groupElements = this.findCanvasElementGroups();

    groupElements.forEach((groupElement) => {
      groupElement.isSelected = false;
      this.colorGroupElement(groupElement);
    });
  }

  /**
   * Color a group element to show isSelected after removing isSelected coloration from other group elements.
   *
   */
  showGroupElementSelected = (selectedGroupElement) => {
    this.deselectAllElements();

    if (selectedGroupElement) {
      if (typeof selectedGroupElement.isSelected === 'undefined') {
        selectedGroupElement.isSelected = false;
      }
      selectedGroupElement.isSelected = true;
      this.colorGroupElement(selectedGroupElement);
      // this.$get().addClass('elementSelected');
    }
  }

  togglePointElementSelected = (pointElement, isSelected) => {
    pointElement.isSelected = isSelected;
    this.colorPointElement(pointElement);

    // this.$get().addClass('elementSelected');
  }

  // /**
  //  * Returns a groupElement or pointElement flagged as isSelected.
  //  */
  // proto.getSelectedElement = function()
  // {
  //   var self = this;
  //   var selectedElement = null;
  //   var groupElements = self.findCanvasElementGroups();
  //   var pointElements = self.findCanvasObjectsByElementType("point");
  //
  //   $.each(groupElements, function(index, groupElement) {
  //     if (groupElement.isSelected)
  //     {
  //       selectedElement = groupElement;
  //     }
  //   });
  //
  //   $.each(pointElements, function(index, pointElement) {
  //     if (pointElement.isSelected)
  //     {
  //       selectedElement = pointElement;
  //     }
  //   });
  //
  //   return selectedElement;
  // }

  /**
   * Delete a group element that has been flagged as isSelected.
   */
  deleteSelectedElement = () => {
    const groupElements = this.findCanvasElementGroups();
    const pointElements = this.findCanvasObjectsByElementType('point');

    groupElements.forEach((groupElement) => {
      if (groupElement.isSelected) {
        this.deleteGroupElement(groupElement);
      }
    });

    pointElements.forEach((pointElement) => {
      if (pointElement.isSelected) {
        this.canvas.remove(pointElement);
      }
    });

    this.deselectAllElements();
  }

  copySelectedElements = () => {
    const groupElements = this.findCanvasElementGroups();
    const pointElements = this.findCanvasObjectsByElementType('point');

    groupElements.forEach((groupElement) => {
      if (groupElement.isSelected) {
        this.copyGroupElement(groupElement);
      }
    });

    pointElements.forEach((pointElement) => {
      if (pointElement.isSelected) {
        this.copyUtil(pointElement);
      }
    });

    this.deselectAllElements();
  }

  // /**
  //  * Show a point element as correct by coloring it.
  //  */
  // proto.showPointElementCorrect = function(pointElement)
  // {
  //   pointElement.isCorrect = true;
  //   pointElement.isIncorrect = false;
  //   this.colorPointElement(pointElement);
  // }
  //
  // /**
  //  * Show a point element as correct by coloring it.
  //  */
  // proto.showPointElementIncorrect = function(pointElement)
  // {
  //   pointElement.isCorrect = false;
  //   pointElement.isIncorrect = true;
  //   this.colorPointElement(pointElement);
  // }
  //
  // /**
  //  * Show a group element (e.g., a line element) as correct by coloring it.
  //  */
  // proto.showGroupElementCorrect = function(groupElement)
  // {
  //   groupElement.isCorrect = true;
  //   groupElement.isIncorrect = false;
  //   this.colorGroupElement(groupElement);
  // }
  //
  // proto.clearValidationFeedback = function()
  // {
  //
  // }
  //
  // proto.showValidationFeedback = function()
  // {
  //   var self = this;
  //   var model = this.getModel();
  //   var responseModel = this.getResponseModel();
  //
  //   // Initially color all grouped graph elements red.
  //   // NOTE: NEED TO COLOR THE DRAG POINTS TOO -- or delete them.
  //   var groupElements = self.findCanvasElementGroups();
  //   $.each(groupElements, function(index, groupElement) {
  //     groupElement.isCorrect = false;
  //     groupElement.isIncorrect = true;
  //     self.colorGroupElement(groupElement);
  //   });
  //
  //   $.each(responseModel.answers, function(index, responseModelElement) {
  //
  //     if (responseModelElement.elementType === "point")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"point"});
  //       var isMatch = self.isPointElementMatch(correctElements, responseModelElement);
  //       var canvasPoint = self.findCanvasPoint(responseModelElement.point);
  //       canvasPoint.fill = isMatch?TestItem.CORRECT_COLOR:TestItem.INCORRECT_COLOR;
  //
  //       if (isMatch)
  //       {
  //         self.showPointElementCorrect(canvasPoint);
  //       }
  //       else
  //       {
  //         self.showPointElementIncorrect(canvasPoint);
  //       }
  //     }
  //     else if (responseModelElement.elementType === "line")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"line"});
  //       var isMatch = self.isLineMatch(correctElements, responseModelElement);
  //       var canvasElement = _.find(self.canvas.getObjects(), {elementType:"line", points:responseModelElement.points});
  //
  //       if (isMatch)
  //       {
  //         self.showGroupElementCorrect(canvasElement);
  //       }
  //     }
  //     else if (responseModelElement.elementType === "ray")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"ray"});
  //       var isMatch = self.isRayMatch(correctElements, responseModelElement);
  //       var canvasElement = _.find(self.canvas.getObjects(), {elementType:"ray", points:responseModelElement.points});
  //
  //       if (isMatch)
  //       {
  //         self.showGroupElementCorrect(canvasElement);
  //       }
  //     }
  //     else if (responseModelElement.elementType === "segment")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"segment"});
  //       var isMatch = self.isSegmentMatch(correctElements, responseModelElement);
  //       var canvasElement = _.find(self.canvas.getObjects(), {elementType:"segment", points:responseModelElement.points});
  //
  //       if (isMatch)
  //       {
  //         self.showGroupElementCorrect(canvasElement);
  //       }
  //     }
  //     else if (responseModelElement.elementType === "vector")
  //     {
  //       var correctElements = _.filter(model.validation.correctAnswers, {elementType:"vector"});
  //       var isMatch = self.isVectorMatch(correctElements, responseModelElement);
  //       var canvasElement = _.find(self.canvas.getObjects(), {elementType:"vector", points:responseModelElement.points});
  //
  //       if (isMatch)
  //       {
  //         self.showGroupElementCorrect(canvasElement);
  //       }
  //     }
  //
  //
  //   });
  //   this.canvas.renderAll();
  //
  // };

  copyUtil = (object) => {
    const { clone } = fabric.util.object;
    const copy = clone(object);
    copy.left += 10;
    copy.top += 10;
    this.canvas.add(copy);
  }

  //   /**
  //    * Show as String
  //    */
  //   proto.toString = function() {
  //
  //       return "Graph";
  //   }
  //
  //
  //
  //
  //   return Graph;
  //
  // });
}
