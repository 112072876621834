import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import { LessonMode } from '../../../Constants';

import ColorPicker from '../tools/ColorPicker';
import Auth from '../../services/AuthService';
import DrawService from '../../services/DrawService';

import RichTextEditor, { ADVANCED_CKEDITOR_TOOLBAR, ADVANCED_MATH_CKEDITOR_TOOLBAR, MATH_TOOLBAR, MATH_WITH_TABLE_TOOLBAR, MINIMUM_TOOLBAR } from '../RichTextEditor';

import HtmlComponent from '../HtmlComponent';

import '../../../css/Draw.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';
import UtilsService from '../../services/UtilsService';
import lessonService from '../../services/LessonService';
import responseService from '../../services/ResponseService';
import navMenuService from '../../services/NavMenuService';
import { register } from '../../../i18n';
import PrintQuestionNumber from './PrintQuestionNumber';

const t = register('GlobalQuestionLabels');
const t2 = register('AriaLabels');

const { ValueContainer, Placeholder, SingleValue } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        return child && child.type !== Placeholder && child.type !== SingleValue ? child : null;
      })}
    </ValueContainer>
  );
};

const brushSizeOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const brushSizeCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    padding: '5px 7px',
    margin: 0,
  }),
  control: (_provided, _state) => ({
    // ...provided,
    width: 50,
    height: 20,
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    padding: 0,
    marginLeft: '-4px',
    marginTop: '-2px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    marginLeft: '4px',
  }),
  input: () => ({
    color: 'transparent',
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    padding: 0,
    marginLeft: '4px',
    marginTop: '-2px',
  })
};

const shapeCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    padding: '4px 0',
    margin: '0 -2px',
  }),
  control: (_provided, _state) => ({
    // ...provided,
    height: 20,
    padding: 0,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
  input: () => ({
    color: 'transparent',
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    padding: 0,
    marginLeft: '4px',
    marginTop: '-2px',
  }),
};

const lineCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    padding: '0px 0px',
    margin: '0px 8px',
  }),
  control: (_provided, _state) => ({
    // ...provided,
    height: 20,
    width: 70,
    padding: 0,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    marginLeft: '8px',
  }),
  input: () => ({
    color: 'transparent',
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    padding: 0,
    marginLeft: '4px',
    marginTop: '-2px',
  }),
};

const pointCustomStyles = {
  option: (provided, _state) => ({
    ...provided,
    padding: '4px 0',
    margin: 0,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    height: 20,
    width: 60,
    padding: 0,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    marginLeft: '12px',
  }),
  input: () => ({
    color: 'transparent',
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    padding: 0,
    marginLeft: '4px',
    marginTop: '-2px',
  }),
};

const brushSizePlaceholder = <div className='brushSizeToolButton drawToolbarButton' />;
const shapePlaceholder = <div className='shapeToolButton drawToolbarButton' />;
const linePlaceholder = <div className='lineToolButton drawToolbarButton' />;
const pointPlaceholder = <div className='pointToolButton drawToolbarButton' />;

const Draw = observer(({ lessonElementId }) => {
  const {
    drawManager,
    lessonManager,
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const drawService = useMemo(() => new DrawService(model), [lessonElementId]); // eslint-disable-line react-hooks/exhaustive-deps
  drawManager.init(lessonElementId);

  const [rerender, setRerender] = useState(false);

  const imageLoaded = (e) => {
    const { clientHeight } = e.target;
    const bodyHeight = drawService.getHeight();

    const finalHeight = ((bodyHeight - clientHeight) / 2);
    e.target.style.marginTop = (lessonManager.playerMode === LessonMode.PRINT_PREVIEW) ? '0' : `${finalHeight}px`;
  };

  useStyleEvents(lessonElementId);

  useEffect(() => {
    const init = async () => {
      // remove all text containers leftover from other Draw slides
      await drawManager.initializeTextContainers([]);
      await drawService.initialize();
      await drawService.runtimeObjectReady();

      await drawService.showResponse();
      // possible timing issue with text containers from other Draw slides showing - rerender may fix it
      setRerender(!rerender);

      if (!UtilsService.isNullOrEmpty(model.imageSource) && UtilsService.isNullOrEmpty(model.altText) && !model.alTextLoaded && UtilsService.isNullOrEmpty(altText)) {
        const contentItemId = model.imageSource.substring(0, model.imageSource.indexOf('.'));
        const contentItem = await lessonService.fetchContentItem(contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        } else {
          if (!altText) {
            if (imageTitle) {
              setAltText(UtilsService.stripTagsAndEntities(model.imageTitle));
              model.altText = UtilsService.stripTagsAndEntities(model.imageTitle);
              model.alTextLoaded = true;
            }
          }
        }
      }
    };
    if (lessonManager.playerMode !== LessonMode.PRINT_PREVIEW) {
      init();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // let imgSrc = `${Auth.ecms}/api/redirectToResource?authKey=${Auth.authKey}&resourceFolder=userFiles&resourceFileName=${model.imageSource}`;

  const strokeColor = drawService.currentStrokeColor;
  const fillColor = drawService.currentFillColor;

  const width = drawService.getWidth();
  const height = drawService.getHeight();

  let widthPx = `${width }px`;
  let heightPx = `${height }px`;

  const imageTitle = model.imageTitle || '';
  // const imageTitleId = drawService.getImageTitleId();
  const [altText, setAltText] = useState('');

  const canvasId = `${model.id }_canvas`;
  const drawCanvasId = `${model.id }_drawCanvas`;

  let imageSrc = null;
  if (model.croppedImageSource) {
    imageSrc = Auth.getResourceUrlByFileName(model.croppedImageSource);
  } else if (model.imageSource) {
    imageSrc = Auth.getResourceUrlByFileName(model.imageSource);
  }

  if (lessonManager.playerMode === LessonMode.PRINT_PREVIEW) {
    widthPx = (imageSrc) ? '3in' : '1.5in';
    heightPx = (imageSrc) ? '3in' : '1.5in';
  }

  let toolbar = MINIMUM_TOOLBAR;
  if (model.responseFormat === 'html') {
    if (model.toolbarType === 'mathToolbar') {
      toolbar = MATH_TOOLBAR;
    } else if (model.toolbarType === 'advancedToolbar') {
      toolbar = ADVANCED_CKEDITOR_TOOLBAR;
    } else if (model.toolbarType === 'mathAdvancedToolbar') {
      toolbar = ADVANCED_MATH_CKEDITOR_TOOLBAR;
    } else if (model.toolbarType === 'mathWithTableToolbar') {
      toolbar = MATH_WITH_TABLE_TOOLBAR;
    }
  }
  // richText.createEditor(textId, {onClick:function() {
  //     self.disableTextDragging();
  //     richText.getEditor(textId).focus();
  //   },
  //   onBlur:function(e) {
  //     self.updateState();
  //     self.enableTextDragging();
  //   },
  //   toolbar:toolbar
  // });

  const shapeOptions = [
    { value: drawService.TRIANGLE, label: <span className={`shapeIcon shapeIcon-${drawService.TRIANGLE}`}>&nbsp;</span> },
    { value: drawService.SQUARE, label: <span className={`shapeIcon shapeIcon-${drawService.SQUARE}`}>&nbsp;</span> },
    { value: drawService.CIRCLE, label: <span className={`shapeIcon shapeIcon-${drawService.CIRCLE}`}>&nbsp;</span> },
    { value: drawService.STAR, label: <span className={`shapeIcon shapeIcon-${drawService.STAR}`}>&nbsp;</span> },
  ];

  const pointOptions = [
    { value: 'closed', label: <span className='pointIcon pointIcon-closed'>&nbsp;</span> },
    { value: 'open', label: <span className='pointIcon pointIcon-open'>&nbsp;</span> },
  ];

  const lineOptions = [
    { value: drawService.LINE, label: <span className={`lineIcon lineIcon-${drawService.LINE}`}>&nbsp;</span> },
    { value: `${drawService.LINE}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.LINE}-dashed`}>&nbsp;</span> },
    { value: drawService.RAY, label: <span className={`lineIcon lineIcon-${drawService.RAY}`}>&nbsp;</span> },
    { value: `${drawService.RAY}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.RAY}-dashed`}>&nbsp;</span> },
    { value: drawService.SEGMENT, label: <span className={`lineIcon lineIcon-${drawService.SEGMENT}`}>&nbsp;</span> },
    { value: `${drawService.SEGMENT}-hidden-hidden`, label: <span className={`lineIcon lineIcon-${drawService.SEGMENT}-hidden-hidden`}>&nbsp;</span> },
    { value: `${drawService.SEGMENT}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.SEGMENT}-dashed`}>&nbsp;</span> },
    { value: drawService.VECTOR, label: <span className={`lineIcon lineIcon-${drawService.VECTOR}`}>&nbsp;</span> },
    { value: `${drawService.VECTOR}-open-hidden`, label: <span className={`lineIcon lineIcon-${drawService.VECTOR}-open-hidden`}>&nbsp;</span> },
    { value: `${drawService.VECTOR}-dashed`, label: <span className={`lineIcon lineIcon-${drawService.VECTOR}-dashed`}>&nbsp;</span> },
  ];

  const handleChangeStrokeColor = (color) => {
    drawService.setStrokeColor(color.hex);
  };

  const handleChangeFillColor = (color) => {
    drawService.setFillColor(color.hex);
  };

  const handleChangeBrushSize = (data) => {
    ReactTooltip.hide();
    drawService.setStrokeWidth(data.value);
  };

  const handleChangeShape = (data) => {
    ReactTooltip.hide();
    drawService.addShape(data.value);
  };

  const handleChangeLine = (data) => {
    ReactTooltip.hide();
    drawService.addLine(data.value);
  };

  const handleChangePoint = (data) => {
    ReactTooltip.hide();
    drawService.addPoint(data.value);
  };

  const handleSelect = () => {
    drawService.select();
  };

  const handleCopy = () => {
    drawService.copy();
  };

  const handleUndo = () => {
    drawService.undo();
  };

  const handleRedo = () => {
    drawService.redo();
  };

  const handleDeleteText = (textId) => {
    drawManager.deleteTextContainer(textId);
    drawService.updateState();
    drawService.updateResponseModel();
  };

  const navToCurrentLessonElement = async () => {
    if (lessonManager.currentLessonElementId !== lessonElementId) {
      responseService.setSolutionDialogOpen(false);
      responseService.setHintDialogOpen(false);
      await navMenuService.navToSlideClickHandler(lessonElementId, { shouldScrollQuestionIntoView: false });
    }
  };

  const handleDragStart = (e) => {
    navToCurrentLessonElement();
    const rect = document.getElementById(e.target.id).getBoundingClientRect();
    drawManager.dragStartTextContainer(e.target.id, e.clientX, e.clientY, rect.width, rect.height);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    drawManager.dropTextContainer(e.clientX, e.clientY);
    drawService.updateState();
    drawService.updateResponseModel();
  };

  return (
    <div>
      <div className='testItemQuestion'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <HtmlComponent className='image-title' htmlStr={model.imageTitle} />

      <>
        {(lessonManager.playerMode !== LessonMode.PRINT_PREVIEW) && (
          <div className='drawToolbar' role='toolbar' style={{ width: widthPx }}>

            <ReactTooltip border effect='solid' id='draw' padding='10px'
              type='light' />

            <div aria-label={t2('drawwithpencil')} className='pencilToolButton drawToolbarButton' data-for='draw' data-tip={t2('pencil\n')}
              data-tool='pencil' onClick={() => drawService.onClickPencilTool()} role='button'
              tabIndex='0'
            />

            <div aria-label={t2('drawwithpaintbrush')} className='paintToolButton drawToolbarButton' data-for='draw' data-tip={t2('brush')}
              data-tool='paint' onClick={() => drawService.onClickPaintTool()} role='button'
              tabIndex='0'
            />

            <div aria-label={t2('strokecolor')} className='strokeColorButton drawToolbarButton' data-for='draw' data-tip={t2('strokecolor')}
              role='menu' style={{ display: 'inline-block' }} tabIndex='0'>
              <ColorPicker color={strokeColor} onChange={handleChangeStrokeColor} />
            </div>

            <div aria-label={t2('fillercolor')} className='fillColorButton drawToolbarButton' data-for='draw' data-tip={t2('fillercolor')}
              role='menu' style={{ display: 'inline-block' }} tabIndex='0'>
              <ColorPicker color={fillColor} onChange={handleChangeFillColor} />
            </div>

            <div className='drawBrushSizeMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t2('brushsize')} className='drawBrushSizeMenu drawToolMenu' data-for='draw' data-tip={t2('brushsize')}
                role='menu' style={{ width: '40px' }} tabIndex='-0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  onChange={handleChangeBrushSize}
                  options={brushSizeOptions}
                  placeholder={brushSizePlaceholder}
                  styles={brushSizeCustomStyles}
                />
              </div>
            </div>

            <div className='shapeMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t2('shape')} className='shapeMenu drawToolMenu' data-for='draw' data-tip={t2('shape')}
                role='menu' style={{ width: '50px' }} tabIndex='0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ValueContainer: CustomValueContainer }}
                  onChange={handleChangeShape}
                  options={shapeOptions}
                  placeholder={shapePlaceholder}
                  styles={shapeCustomStyles}
                />
              </div>
            </div>

            <div className='lineMenuContainer' style={{ width: '60px', display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t2('graphline')} className='lineMenu drawToolMenu' data-for='draw' data-tip={t2('graphline')}
                role='menu' style={{ width: '60px' }} tabIndex='0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ValueContainer: CustomValueContainer }}
                  onChange={handleChangeLine}
                  options={lineOptions}
                  placeholder={linePlaceholder}
                  styles={lineCustomStyles}
                />
              </div>
            </div>

            <div className='pointMenuContainer' style={{ display: 'inline-block' }} tabIndex='-1'>
              <div aria-label={t2('graphpoint')} className='pointMenu drawToolMenu' data-for='draw' data-tip={t2('graphpoint')}
                role='menu' style={{ width: '50px' }} tabIndex='0'>
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ValueContainer: CustomValueContainer }}
                  onChange={handleChangePoint}
                  options={pointOptions}
                  placeholder={pointPlaceholder}
                  styles={pointCustomStyles}
                />
              </div>
            </div>

            <div aria-label={t2('selectitems')} className='selectToolButton drawToolbarButton' data-for='draw' data-tip={t2('selectitems')}
              onClick={handleSelect} role='button' tabIndex='0' />

            <div aria-label={t2('addtext')} className='textToolButton drawToolbarButton' data-for='draw' data-tip={t2('addtext')}
              onClick={drawService.onClickTextTool} role='button' tabIndex='0' />

            <div className='rightButtonContainer' tabIndex='-1'>
              <div aria-label={t2('copyelement')} className='drawCopyElementButton drawToolbarButton' data-for='draw' data-tip={t2('copyelement')}
                onClick={handleCopy} role='button' tabIndex='0' />
              <div aria-label={t2('undo')} className='drawUndoButton drawToolbarButton' data-for='draw' data-tip={t2('undo')}
                onClick={handleUndo} role='button' tabIndex='0' />
              <div aria-label={t2('redo')} className='drawRedoButton drawToolbarButton' data-for='draw' data-tip={t2('redo')}
                onClick={handleRedo} role='button' tabIndex='0' />
              <div aria-label={t2('deleteelement')} className='drawDeleteElementButton drawToolbarButton' data-for='draw' data-tip={t2('deleteelement')}
                onClick={drawService.deleteFromDraw} role='button' tabIndex='0' />
            </div>

            <div className='deleteButtonContainer'>
              <button className='deleteElementButton'>{t('delete')}</button>
              <button className='clearButton'>{t('clearAll')}</button>
            </div>
          </div>
        )}
        <div className='draw-wrapper' style={{ position: 'relative', width: widthPx, height: heightPx }}>
          <div className='drawBody imageBody ' data-allow='1' style={{ width: widthPx, height: heightPx }}>
            {imageSrc && (
              <img alt={altText} className='imageLabelImage' onLoad={imageLoaded} src={imageSrc}
                style={{ maxHeight: heightPx }} />
            )}
          </div>
          {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && (
            <div className='canvasContainerOuter' id='canvasContainerOuter' onDragEnd={handleDragEnd}
              style={{ height: heightPx, width: widthPx }} />
          )}
          {(lessonManager.playerMode !== LessonMode.PRINT_PREVIEW) && (
            <div className='canvasContainerOuter' id='canvasContainerOuter' onDragEnd={handleDragEnd}
              style={{ height: heightPx, width: widthPx }}>

              {drawManager.textContainers.map((textContainer) => {
                return (
                  <div key={textContainer.textId} className='drawTextContainer deleteButtonParent' draggable id={textContainer.textId}
                    onDragStart={handleDragStart} style={{ top: textContainer.top, left: textContainer.left, opacity: textContainer.opacity }}>
                    <div className='deleteButton deleteTextContainerButton'
                      onClick={() => handleDeleteText(textContainer.textId)} />
                    <div className='drawText oneRowToolbar cke_editable cke_editable_inline cke_contents_ltr cke_show_borders placeholder' id={`${textContainer.textId}_text`}>

                      <RichTextEditor
                        data={textContainer.text}
                        lessonElementId={lessonElementId}
                        onChange={(data) => {
                          drawManager.setTextContainerText(textContainer.textId, data);
                        }}
                        placeHolder='Text'
                        readOnly={false}
                        toolbar={toolbar} />
                    </div>
                  </div>
                );
              })}

              <div className='body canvasOrganizerBody  canvasContainer' id='canvasContainer' style={{ height: heightPx, width: widthPx }}>
                <canvas className='canvas' id={canvasId} />
              </div>

              <div className='body canvasOrganizerBody  drawCanvasContainer' id='drawCanvasContainer' style={{ width: widthPx, height: heightPx }}>
                <canvas className='canvas' id={drawCanvasId} />
              </div>

            </div>
          )}
        </div>
      </>
      {((lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && lessonManager.isForceTeacher) && (
        <div className='print-teacher-notes'>
          <HtmlComponent htmlStr={model.validation.solution} />
        </div>
      )}

    </div>
  );
});

export default Draw;
