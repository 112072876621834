import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import Select from 'react-select';

import '../../../css/ImageLabelCore.scss';
import '../../../css/ImageLabelMultichoice.scss';

import { ImageLabelMargins, LessonMode } from '../../../Constants';

import Auth from '../../services/AuthService';

import dropdownService from '../../services/DropdownService';
import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';

import FeedbackIcon from '../FeedbackIcon';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import useStyleEvents from '../../../hooks/useStyleEvents';
import UtilsService from '../../services/UtilsService';
import lessonService from '../../services/LessonService';
import PrintQuestionNumber from './PrintQuestionNumber';

const ImageLabelMultichoice = observer(({ lessonElementId }) => {
  const {
    lessonManager, // eslint-disable-line no-unused-vars
    responseManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const imgBodyRef = useRef();
  const imgRef = useRef();

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);

  const { readOnly } = lessonElementState;

  const questionText = <HtmlComponent htmlStr={model.questionText ?? ''} />;
  const textBody = <HtmlComponent htmlStr={model.textBody ?? ''} />;
  const imageMaxSize = (model.maxSize) ? `${model.maxSize}px` : '';
  const imgSrc = Auth.getResourceUrlByFileName(model.croppedImageSource || model.imageSource);
  const { contentType, imageTitle, prompts } = model;
  const useMargin = (!model.maximizeImageSize && contentType !== 'text');

  let imageBodyStyle = {};
  const imageStyle = {};
  if (contentType === 'text') {
    imageBodyStyle = {
      widthNum: (+model.textBodyWidth > 0 ? model.textBodyWidth : 0),
      width: (+model.textBodyWidth > 0 ? `${model.textBodyWidth}px` : 'auto'),
      height: (+model.textBodyHeight > 0 ? `${model.textBodyHeight}px` : 'auto'),
      heightNum: (+model.textBodyHeight > 0 ? model.textBodyHeight : 0),
      maxWidth: (+model.textBodyWidth > 0 ? `${model.textBodyWidth}px` : 'auto')
    };
  } else if (model.maximizeImageSize) {
    imageBodyStyle = { width: 'unset', height: 'unset', maxWidth: imageMaxSize };
  } else {
    // console.debug('image load');
  }

  useStyleEvents(lessonElementId);
  const imageBodyContainerClasses = `imageBodyContainer  ${ contentType }-type`;
  const [altText, setAltText] = useState('');

  useEffect(() => {
    const doAction = async () => {
      if (UtilsService.isNullOrEmpty(model.altText) && !model.altTextLoaded) {
        const contentItem = await lessonService.fetchContentItem(model.contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        }
      }
    };
    doAction();
  });
  function onImgLoad() {
    if (!model.maximizeImageSize && contentType !== 'text') {
      const [leftMargin, rightMargin, topMargin, bottomMargin] = [model.leftMargin, model.rightMargin, model.topMargin, model.bottomMargin].map((marginString) => ImageLabelMargins[marginString]);

      if (imgBodyRef.current && imgRef.current) {
        const width = `${imgRef.current.naturalWidth + leftMargin + rightMargin}px`;
        const height = `${imgRef.current.naturalHeight + topMargin + bottomMargin}px`;
        imgBodyRef.current.style.width = width;
        imgBodyRef.current.style.height = height;
      }

      if (imgRef.current) {
        imgRef.current.style.width = `${imgRef.current.naturalWidth}px`;
        imgRef.current.style.height = `${imgRef.current.naturalHeight}px`;
        imgRef.current.style.margin = `${topMargin}px ${rightMargin}px ${bottomMargin}px ${leftMargin}px`;
      }
    }
  }

  return (
    <div className='image-label-multichoice-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question image-label-multichoice'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        {questionText}
      </div>
      <div className={`test-item-answers ${imageBodyContainerClasses}`}>
        {model.imageTitle && <div className='textInput imageLabelTitle'><HtmlComponent htmlStr={imageTitle} /></div>}
        <div ref={imgBodyRef} className='imageBody stop' style={imageBodyStyle}>
          {
            contentType === 'text' ?
              <div className='textBody' style={{ width: '100%', height: '100%' }}>{textBody}</div> : (
                <img ref={imgRef} alt={model.altText || altText || ''} className='test-item-image imageLabelImage' onLoad={onImgLoad}
                  src={imgSrc} style={imageStyle} />
              )
          }
          {
            prompts.map((prompt, idx) => (
              <AnswerBlankSection
                key={prompt.id}
                idx={idx} lessonElementState={lessonElementState}
                model={model}
                thisPrompt={prompt}
                useMargin={useMargin}
              >
                <AnswerDropdown lessonElementState={lessonElementState} model={model} readOnly={readOnly} thisPrompt={prompt} />
              </AnswerBlankSection>
            ))
          }
        </div>
      </div>
    </div>
  );
});
export default ImageLabelMultichoice;

const AnswerDropdown = observer(({ thisPrompt, readOnly, model, lessonElementState }) => {
  const responseAnswer = lessonElementState?.currentResponseAnswer;
  const { lessonElementId } = model;
  const promptId = thisPrompt.id;
  const promptOptions = thisPrompt.responseItems?.filter(({ text }) => text.length > 0).map(({ text, id }) => ({
    label: text,
    dataId: promptId,
    value: id
  }));
  if (model.isRandomized) {
    utilsService.shuffleArrayForUser(promptOptions, model.questionText);
  }
  const currentText = responseAnswer.prompts?.find(({ id }) => id === thisPrompt.id)?.text;
  const currentOption = promptOptions.find(({ label }) => label === currentText);

  const handleChange = (e) => {
    responseService.responseChangeHandler({ id: promptId, text: e.label }, lessonElementId);
  };

  const { customComponents, customDropdownStyles } = dropdownService.getDropdownConfig({
    promptId, model, disableControlBorder: true
  });

  return (
    <Select
      className='options-select'
      components={customComponents}
      formatOptionLabel={(data) => {
        let dataLabel = data.label;
        if (dataLabel?.startsWith?.('<menclose')) {
          dataLabel = `<math>${dataLabel}</math>`;
        }
        return <HtmlComponent htmlStr={dataLabel} />;
      }}
      id='options-select'
      isDisabled={readOnly}
      isSearchable={false}
      maxMenuHeight={220}
      menuPlacement='auto'
      menuPortalTarget={document.querySelector('body')}
      menuPosition='absolute'
      onChange={handleChange}
      options={promptOptions}
      styles={customDropdownStyles}
      value={currentOption}
    />
  );
});

const AnswerBlankSection = ({
  children,
  thisPrompt, idx,
  lessonElementState,
  model,
  useMargin
}) => {
  const { lessonElementId } = model;
  const showFeedback = lessonElementState.showAnswerFeedback;
  const dataId = thisPrompt.id;

  let nodeClassNames = '';
  nodeClassNames += `${model.responseFormat} ${thisPrompt.pointerDirection}`;
  nodeClassNames += showFeedback ? ' feedback' : '';
  let topPct = `${thisPrompt.point.topPercent }%`;
  const leftPct = `${thisPrompt.point.leftPercent }%`;
  const hPct = `${thisPrompt.heightPercent }%` || '';
  const wPct = `${thisPrompt.widthPercent }%` || '';
  const lblBgdColor = thisPrompt.labelBackgroundColor;

  if (topPct && thisPrompt.pointerDirection === 'right') {
    topPct = `calc(${topPct} - 16.5px)`;
  }

  let elemStyle = { left: leftPct, top: topPct, height: hPct, width: wPct };
  let { top } = thisPrompt.point;
  if (useMargin) {
    if (top && thisPrompt.pointerDirection === 'right') {
      top -= 16.5;
      top = `${top}px`;
    }
    elemStyle = { left: thisPrompt.point.left, top, height: hPct, width: wPct };
  }

  const editorClassName = model.responseFormat === 'html' ? 'cke_topspace ' : '';

  const imageLabelResponseNumbered = model?.numberedBlanks ? ' imageLabelResponseNumbered ' : '';
  const targetTypeClassName = (model.targetType === 'box') ? '' : 'arrow_box';

  return (
    <div key={dataId}
      className={`imageLabelDrag noFocusOutline ${editorClassName}${nodeClassNames}`}
      data-id={dataId}
      style={elemStyle}
    >
      <FeedbackIcon answerId={dataId} lessonElementId={lessonElementId} />
      <div className='imageLabelDragInner'>
        <div className={`imageLabelResponse noFocusOutline normal ${ targetTypeClassName } `} data-color={lblBgdColor}>
          <div className={`image-label-response imageLabelResponseText noFocusOutline ${imageLabelResponseNumbered}`}
            data-prompt-id={dataId}
            data-prompt-num={idx + 1}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
