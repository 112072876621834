import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import useWirisParser from '../../../hooks/useWirisParser';
import '../../../css/InteractiveViewer.scss';

const InteractiveViewer = observer(({ lessonElementId, showQuestionBanner }) => {
  const {
    lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const childModel = lessonManager.getSlideModel(lessonElementId);

  if (!childModel) {
    return (<div className='activity-content' />);
  }

  const componentRef = useRef();

  useWirisParser(componentRef);

  const activityLessonElement = lessonManager.getLessonElement(childModel.parentActivityElementId);
  const activityModel = lessonManager.getSlideModel(childModel.parentActivityElementId);
  const ActivityComponent = lessonManager.getActivityComponent(activityLessonElement.id);

  // TODO: MAKE showAllChildren a things on all activities. The list or single can be flexible.
  const isQuestionListView = activityModel.showAllChildren || activityModel.type === 'MultipartActivity';
  lessonManager.setIsQuestionListView(isQuestionListView);

  return (
    <>
      <div className={`activity-content ${activityModel.type} ${childModel.parentActivityElementId}`}>
        <ActivityComponent lessonElementId={childModel.parentActivityElementId} model={activityModel} />
      </div>
    </>
  );
});

export default InteractiveViewer;
