import React from 'react';
import lessonService from '../../services/LessonService';

const PrintQuestionNumber = ({ model }) => {

  return (
    <div className='print-question-num'>{model.questionNumber}</div>
  );
};
export default PrintQuestionNumber;
