import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import { LessonMode } from '../../../Constants';
import Auth from '../../services/AuthService';
import FeedbackIcon from '../FeedbackIcon';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import HtmlComponent from '../HtmlComponent';
import Guideline from '../tools/Guideline';
import '../../../css/ImageHotSpot.scss';
import responseService from '../../services/ResponseService';
import useStyleEvents from '../../../hooks/useStyleEvents';
import UtilsService from '../../services/UtilsService';
import lessonService from '../../services/LessonService';
import PrintQuestionNumber from './PrintQuestionNumber';

const NO_MARGIN = 50;
const SMALL_MARGIN = 125;
const MEDIUM_MARGIN = 250;
const LARGE_MARGIN = 375;
const MARGINS = { none: NO_MARGIN, small: SMALL_MARGIN, medium: MEDIUM_MARGIN, large: LARGE_MARGIN };

/**
 * Returns HTML for a label response.
 */
const Prompt = observer(({ promptId, readOnly, promptIndex, lessonElementId }) => {
  const {
    lessonManager, // eslint-disable-line no-unused-vars
    responseManager,
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);

  const { numberedBlanks, maximizeImageSize, prompts, targetType } = model;

  let prompt = prompts.find((element) => element.id === promptId);

  if (typeof prompt === 'undefined') {
    prompt = {
      id: -1,
      text: 'unselected'
    };
  }

  const state = responseManager.getLessonElementState(lessonElementId);
  const responsePrompts = (state &&
    state.currentResponseAnswer &&
    state.currentResponseAnswer.prompts) ? state.currentResponseAnswer.prompts : [];

  const responsePrompt = responsePrompts.find((element) => promptId === element.id);

  const {
    borderColor,
    displayLabel,
    heightPercent,
    id,
    labelIndex,
    point,
    selectionColor,
    widthPercent
  } = prompt;

  const { left, leftPercent, top, topPercent } = point;

  const styleAtt = {};
  styleAtt.borderColor = (responsePrompt.text === 'selected') ? selectionColor : borderColor;
  styleAtt.height = `${heightPercent}%`;
  styleAtt.left = maximizeImageSize && typeof left === 'number' ? left : `${leftPercent}%`;
  styleAtt.position = 'absolute';
  styleAtt.top = maximizeImageSize && typeof top === 'number' ? top : `${topPercent}%`;
  styleAtt.width = `${widthPercent}%`;

  const finalDisplayLabel = displayLabel || labelIndex;

  // eslint-disable-next-line eqeqeq
  const svgFill = (responsePrompt && responsePrompt.text == 'selected') ? selectionColor : borderColor;
  // eslint-disable-next-line eqeqeq
  const svgStroke = (responsePrompt && responsePrompt.text == 'selected') ? selectionColor : borderColor;
  // eslint-disable-next-line eqeqeq
  const svgSize = (responsePrompt && responsePrompt.text == 'selected') ? 20 : 10;

  const promptRef = useRef();

  useStyleEvents(lessonElementId);

  useAccessibilityClick(promptRef, () => {
    if (responsePrompt && !readOnly) {
      responseService.responseChangeHandler(responsePrompt, lessonElementId);
    }
  });
  const isOk = (model && state && responsePrompt);

  return (
    <>
      {(isOk && (targetType === 'hot-spot-circle' || targetType === 'hot-spot-box')) && (
        <div ref={promptRef} aria-checked={(responsePrompt.text === 'selected') ? 'true' : 'false'} aria-label={finalDisplayLabel}
          className={`imageHotSpotDrag ${responsePrompt.text} ${targetType}`}
          id={id}
          role='checkbox' style={styleAtt}
          tabIndex='0'>
          {numberedBlanks && <div className='imageHotSpotResponseNumbered noFocusOutline' tabIndex='-1'>{promptIndex}</div>}
          <FeedbackIcon answerId={id} lessonElementId={lessonElementId} />
        </div>
      )}
      {(isOk && targetType === 'hot-spot-crosshair') && (
        <div ref={promptRef} aria-checked={(responsePrompt.text === 'selected') ? 'true' : 'false'} aria-label={finalDisplayLabel} className={`imageHotSpotDrag noFocusOutline ${responsePrompt.text} ${targetType}`}
          id={id} role='checkbox' style={styleAtt} tabIndex='0'>
          {numberedBlanks && <div className='imageHotSpotResponseNumbered noFocusOutline' tabIndex='-1'>{promptIndex}</div>}
          <FeedbackIcon answerId={id} lessonElementId={lessonElementId} tabIndex='-1' />
          <svg fill={svgFill} id='Layer_1' stroke={svgStroke} strokeWidth={svgSize}
            tabIndex='-1' version='1.1' viewBox='0 0 500.004 500.004' x='0px'
            xmlns='http://www.w3.org/2000/svg' y='0px'>
            <g>
              <g>
                <path d='M179.746,238.28H11.718c-6.468,0-11.716,5.26-11.716,11.72c0,6.464,5.248,11.72,11.716,11.72h168.028    c6.456,0,11.716-5.252,11.716-11.72C191.458,243.54,186.202,238.28,179.746,238.28z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M488.282,238.28H320.258c-6.464,0-11.716,5.256-11.716,11.72s5.248,11.72,11.716,11.72h168.024c6.464,0,11.72-5.252,11.72-11.72C500.002,243.54,494.75,238.28,488.282,238.28z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M250.002,308.54c-6.46,0-11.72,5.252-11.72,11.72v168.028c0,6.46,5.256,11.716,11.72,11.716s11.72-5.252,11.72-11.716V320.256C261.722,313.792,256.466,308.54,250.002,308.54z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M250.002,0c-6.46,0-11.72,5.252-11.72,11.72v168.024c0,6.46,5.256,11.712,11.72,11.712s11.72-5.248,11.72-11.712V11.72C261.722,5.252,256.466,0,250.002,0z' />
              </g>
            </g>
            <g>
              <g>
                <circle cx='249.922' cy='250' r='11.796' />
              </g>
            </g>
            <filter id='shadow2'>
              <feDropShadow dx='0' dy='0' floodColor='#186e7a' stdDeviation='5' />
            </filter>
          </svg>
        </div>
      )}
    </>
  );
});

const ImageHotSpot = observer(({ lessonElementId }) => {
  const {
    lessonManager, // eslint-disable-line no-unused-vars
    responseManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const [styleAttr, setStyleAttr] = useState({});
  const [altText, setAltText] = useState('');

  useEffect(() => {
    const doAction = async () => {
      if (UtilsService.isNullOrEmpty(model.altText) && !model.altTextLoaded) {
        const contentItem = await lessonService.fetchContentItem(model.contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        }
      }
    };
    doAction();
  });
  const {
    prompts, questionText, maximizeImageSize,
    maxSize, croppedImageSource, imageSource
  } = model;

  const hasImg = croppedImageSource != null || imageSource != null;
  const imgSrc = Auth.getResourceUrlByFileName(croppedImageSource || imageSource);

  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const readOnly = (lessonElementState && lessonElementState.readOnly) || lessonManager.isReadOnly;
  // TODO unused // const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : {};

  const imageLoaded = () => {
    const temp = {};

    if (maximizeImageSize) {
      temp.maxWidth = `${maxSize}px`;
      temp.width = 'unset';
      temp.height = 'unset';
    } else {
      const totalMarginWidth = MARGINS[model.leftMargin] + MARGINS[model.rightMargin];
      const totalMarginHeight = MARGINS[model.topMargin] + MARGINS[model.bottomMargin];
      const img = document.getElementById(`${model.lessonElementId}_${model.contentItemId}_image`);
      const width = img.clientWidth + totalMarginWidth;
      const height = img.clientHeight + totalMarginHeight;
      temp.maxWidth = 'unset';
      temp.width = `${width}px`;
      temp.height = `${height}px`;
    }
    setStyleAttr(temp);
  };

  return (
    <>
      {(lessonElementState) ? (
        <div className='image-hot-spot-question' style={{ visibility: styleAttr?.width ? 'visible' : 'hidden' }}>
          {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
          <div className='test-item-question image-hot-spot'>
            {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
            <HtmlComponent htmlStr={questionText} />
          </div>
          <div className='imageBodyContainer '>
            {model.imageTitle &&
              <div className='textInput imageHotSpotTitle'><HtmlComponent htmlStr={model.imageTitle} /></div>}
            {hasImg && (
              <div className={classNames('imageBody', { 'max-width': maximizeImageSize })} id={`${model.lessonElementId}_${model.contentItemId}_image_body`} style={styleAttr}>
                <img alt={model.altText || altText || ''} className='test-item-image' id={`${model.lessonElementId}_${model.contentItemId}_image`} onLoad={imageLoaded}
                  src={imgSrc} />
                {prompts.map((prompt, index) => (
                  <Prompt key={`${prompt.id}_hot_spot`} lessonElementId={lessonElementId} promptId={prompt.id} promptIndex={index + 1}
                    readOnly={readOnly} />
                ))}
              </div>
            )}
          </div>
        </div>
      ) :
        <div className='image-hot-spot-question' />}
    </>
  );
});

export default ImageHotSpot;
