import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { useDropzone } from 'react-dropzone';

import '../../../css/Upload.scss';

import {
  LessonMode,
  PLAYABLE_UPLOADED_FILE_TYPES,
  PREVIEWABLE_UPLOADED_FILE_TYPES,
  UploadResponseOption
} from '../../../Constants';

import { register } from '../../../i18n';

import Auth from '../../services/AuthService';
import audioService from '../../services/AudioService';
import playerService from '../../services/PlayerService';
import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import useStyleEvents from '../../../hooks/useStyleEvents';

import CaptionedImageButton from '../CaptionedImageButton';
import FeedbackIcon from '../FeedbackIcon';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import MediaRecorderContainer from '../MediaRecorderContainer';
import OptionalImage from '../OptionalImage';
import ResourcePreviewDialog from '../tools/ResourcePreviewDialog';
import PrintQuestionNumber from './PrintQuestionNumber';

const t = register('Upload');
const t2 = register('AriaLabels');

const Upload = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    questionFeedbackManager,
    responseManager,
    toolbarManager,
    uploadManager,
  } = useContext(MobXProviderContext);

  const [allowRenderUploadComponent, setAllowRenderUploadComponent] = useState(false);
  const [loadingMap, setLoadingMap] = useState({});

  const [activeResourcePreviewDialogData, setActiveResourcePreviewDialogData] = useState(undefined);

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setAllowRenderUploadComponent(false);
    uploadManager.clearSelectedUploadResponseOption(lessonElementId);
    questionFeedbackManager.setSubmitDisabledState(lessonElementId, false);
    setAllowRenderUploadComponent(true);
  }, []);

  const { OPTION_RECORD_AUDIO, OPTION_RECORD_VIDEO, OPTION_UPLOAD_FILE } = UploadResponseOption;

  const model = lessonManager.getSlideModel(lessonElementId);

  const textBody = model.questionText || '';

  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  // const activityId = lessonElementState.serverResponse.activityId;
  const { contentObjectId } = lessonElementState.serverResponse;

  if (!lessonElementState) {
    return (<div className='upload-question' />);
  }

  const { playerMode } = lessonManager;
  const isInvalidMode = playerMode === LessonMode.PREVIEW ||
    playerMode === LessonMode.STUDENT_PREVIEW ||
    playerMode === LessonMode.ITEM_PREVIEW ||
    playerMode === LessonMode.PUBLISHER_PREVIEW;
  const readOnly = playerService.isReadOnly(lessonElementId) || isInvalidMode;
  const { currentResponseAnswer } = lessonElementState;

  const isAllowedUploadResponseOptions = !readOnly && (playerMode !== LessonMode.SCORING && playerMode !== LessonMode.REVIEW);

  const files = currentResponseAnswer.responseContent?.map((file = {}) => {
    return {
      contentItemId: file.id,
      ...file
    };
  });

  const clearLoadingMap = () => {
    const loadingMap = {};
    currentResponseAnswer.responseContent.forEach(({ id }) => {
      loadingMap[id] = false;
    });
    setLoadingMap(loadingMap);
  };

  const handleChangeUploadResponseButton = (event) => {
    if (!isAllowedUploadResponseOptions) {
      return;
    }
    if (activeResourcePreviewDialogData) {
      handleCloseResourcePreviewDialog();
    }
    const value = event?.target?.value;

    const selectedUploadResponseOption = uploadManager.getSelectedUploadResponseOption(lessonElementId);

    const isResponseButtonAlreadySelected = !!value && value === selectedUploadResponseOption?.value;

    if (!value || isResponseButtonAlreadySelected) {
      uploadManager.clearSelectedUploadResponseOption(lessonElementId);
      questionFeedbackManager.setSubmitDisabledState(lessonElementId, false);
      return;
    }

    questionFeedbackManager.setSubmitDisabledState(lessonElementId, true);

    switch (value) {
    case OPTION_RECORD_VIDEO.value:
      uploadManager.setSelectedUploadResponseOption(lessonElementId, OPTION_RECORD_VIDEO);
      break;
    case OPTION_RECORD_AUDIO.value:
      uploadManager.setSelectedUploadResponseOption(lessonElementId, OPTION_RECORD_AUDIO);
      break;
    case OPTION_UPLOAD_FILE.value:
      uploadManager.setSelectedUploadResponseOption(lessonElementId, OPTION_UPLOAD_FILE);
      break;
    }
  };

  const handleCloseResourcePreviewDialog = () => {
    setActiveResourcePreviewDialogData(undefined);
  };

  const renderUploadResponseOptionButtons = () => {
    if (!model) {
      return;
    }
    return (
      <div className='upload-response-options'>
        {Object.keys(UploadResponseOption).filter((key) => {
          const option = UploadResponseOption[key];
          const allow = typeof model[option.modelKey] === 'boolean' ? (
            model[option.modelKey]
          ) : true;
          return allow;
        }).map((key) => {
          const option = UploadResponseOption[key];
          return (
            <CaptionedImageButton
              key={key}
              changed={handleChangeUploadResponseButton}
              className={option.className}
              disabled={readOnly}
              id={key}
              isSelected={uploadManager.getSelectedUploadResponseOption(lessonElementId)?.value === option.value}
              label={t(option.label)}
              value={option.value}
            />
          );
        })}
      </div>
    );
  };

  const renderActiveUploadResponseOption = () => {
    return isAllowedUploadResponseOptions ? (
      <>
        {uploadManager.isRecordVideoSelectedResponseOption(lessonElementId) && (
          <MediaRecorderContainer
            isVideo={true}
            lessonElementId={lessonElementId}
            mimeMainType='video'
            model={model}
            uploadFile={uploadFile} />
        )}
        {uploadManager.isRecordAudioSelectedResponseOption(lessonElementId) && (
          <MediaRecorderContainer
            isVideo={false}
            lessonElementId={lessonElementId}
            mimeMainType='audio'
            model={model}
            uploadFile={uploadFile} />
        )}
        {(uploading || uploadManager.isUploadFileSelectedResponseOption(lessonElementId)) && (
          <UploadBox
            lessonElementId={lessonElementId}
            readOnly={readOnly}
            uploadFile={uploadFile}
            uploading={uploading} />
        )}
      </>
    ) : null;
  };

  const uploadFile = async (file, { mimeMainType } = {}) => {
    const isWebm = file?.type?.includes?.('webm');
    const isWebmAudioResource = isWebm && !mimeMainType ? await audioService.isWebmAudioResource(file) : undefined;

    mimeMainType = mimeMainType || (isWebmAudioResource ? 'audio' : undefined);

    // Uploads the file with uploadFileResource and then uses the contentItemId to attach it to the current activity
    setUploading(true);
    await responseService.responseChangeHandler({
      action: 'upload',
      parameters: {
        contentObjectId,
        file,
        mimeMainType,
        uploadFile
      }
    }, lessonElementId);
    if (uploadManager.isUploadFileSelectedResponseOption(lessonElementId)) {
      uploadManager.clearSelectedUploadResponseOption(lessonElementId);
    }
    questionFeedbackManager.setSubmitDisabledState(lessonElementId, false);
    setUploading(false);
  };

  const setFileName = async (contentItemId, name) => {
    // Updates the name of the file with updateContentItemName
    setLoadingMap({ ...loadingMap, [contentItemId]: true });
    await responseService.responseChangeHandler({
      action: 'setName',
      parameters: {
        contentItemId, name
      }
    }, lessonElementId);
    clearLoadingMap();
  };

  const removeFile = async (contentItemId) => {
    // Removes the file with removeActivityResponseContent
    setLoadingMap({ ...loadingMap, [contentItemId]: true });
    await responseService.responseChangeHandler({
      action: 'remove',
      parameters: {
        contentItemId
      }
    }, lessonElementId);
    clearLoadingMap();
  };

  useStyleEvents(lessonElementId);

  return allowRenderUploadComponent ? (
    <>
      <div className='upload-question'>
        {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
        <div className='test-item-question'>
          {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
          <HtmlComponent htmlStr={textBody} />
        </div>
        <OptionalImage model={model} runtime={true} />
        <div className='test-item-answers'>
          {renderUploadResponseOptionButtons()}

          {renderActiveUploadResponseOption()}

          <div className='uploaded-files-container'>
            <FeedbackIcon lessonElementId={lessonElementId} />
            {isInvalidMode ?
              <p>{t('invalidUploadModeMsg')}</p>
              :
              files && files.map((file) => {
                return (
                  <FileBox
                    key={file.contentItemId}
                    file={file}
                    loading={loadingMap[file.contentItemId]}
                    readOnly={readOnly}
                    removeFile={() => removeFile(file.contentItemId)}
                    setActiveResourcePreviewDialogData={setActiveResourcePreviewDialogData}
                    setFileName={(name) => setFileName(file.contentItemId, name)}
                  />
                );
              })}
          </div>
        </div>
      </div>
      {activeResourcePreviewDialogData?.resourceType && (
        <ResourcePreviewDialog
          allowResourcePadding={true}
          autoPlay={true}
          handleCloseResourcePreviewDialog={handleCloseResourcePreviewDialog}
          modalDisableResize={true}
          modalHeight='max-content'
          modalLeft='50%'
          modalTop='50%'
          modalWidth='max-content'
          resourceData={activeResourcePreviewDialogData}
          resourceDisplayName={activeResourcePreviewDialogData.name || 'Resource'}
          resourceOpenId={null}
          resourceType={activeResourcePreviewDialogData.resourceType}
          resourceUrl={activeResourcePreviewDialogData.resourceUrl} />
      )}
    </>
  ) : null;
});

const UploadBox = ({
  lessonElementId,
  readOnly,
  uploadFile,
  uploading
}) => {
  const disabled = readOnly || uploading;
  const onDrop = (files) => {
    for (const file of files) {
      uploadFile(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled });

  return (
    <div {...getRootProps({ className: `upload-dropzone ${isDragActive ? 'drop-ready' : ''} ${disabled ? 'disabled' : ''}` })}>
      <input {...getInputProps({ id: `upload-input-${lessonElementId}`, className: 'upload-input' })} />
      <label className='upload-label' htmlFor={`upload-input-${lessonElementId}`}>
        <button className='primary' tabIndex={-1}>{t('selectFiles')}</button>
      </label>
      <em>{uploading ? t('uploadingActive') : t('uploadingInactive')}</em>
    </div>
  );
};

const ResourceItemIcon = ({ resourceType, imageUrl }) => {
  const colorClass = `resourceCardColor_${ resourceType}`;
  const iconClass = `resourceItemIcon_${ resourceType}`;

  const imgSrc = `${Auth.ecms}/api/redirectToResource?authKey=${Auth.authKey}&resourceFolder=${'userFiles'}&resourceFileName=${imageUrl}&cacheBuster=${new Date().getTime()}`;

  return (
    <div className={`resource-card ${colorClass}`}>
      {
        imageUrl == null ?
          <div className={`resource-card-icon ${iconClass}`} /> :
          <img alt='' className={`resource-card-image ${iconClass}`} src={imgSrc} />
      }
    </div>
  );
};

const FileBox = ({
  file = {}, loading, readOnly, removeFile, setActiveResourcePreviewDialogData, setFileName
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const { entityId, entityTypeId, imageUrl, name } = file;

  const [editedFileName, setEditedFileName] = useState(name);

  const [resourceType, setResourceType] = useState();

  const downloadUrl = `${Auth.ecms}/api/downloadResource?authKey=${Auth.authKey}&contentItemId=${file.contentItemId}`;

  let resourceUrl;
  if (file.imageUrl) {
    resourceUrl = `${Auth.ecms}/api/redirectToResource?authKey=${Auth.authKey}&resourceFolder=${'userFiles'}&resourceFileName=${imageUrl}&cacheBuster=${new Date().getTime()}`;
  } else if (entityTypeId === 'audio_resource' || entityTypeId === 'video_resource') {
    resourceUrl = Auth.getStreamResourceUrl(entityId, entityTypeId);
  } else {
    resourceUrl = downloadUrl;
  }

  useEffect(() => {
    (async () => {
      setResourceType(entityTypeId || 'file_resource');
      // TODO remove
      // if (entityTypeId === 'video_resource') {
      //   // for some cases, a resource marked as 'video_resource'
      //   // can technically be **video, audio or both** (such as webm)
      //   // for that case, we need to parse the resource blob
      //   // to determine what icon and tag to show for that resource
      //   const blob = await fetch(resourceUrl).then(response => response.blob());
      //   const isAudio = await audioService.isWebmAudioResource(blob);
      //   setResourceType(isAudio ? 'audio_resource' : (entityTypeId || 'file_resource'));
      // } else {
      //   setResourceType(entityTypeId || 'file_resource');
      // }
    })();
  }, [resourceUrl, entityTypeId, name]);

  const fileItemCardRef = useRef(null);
  const finishedEditingButtonRef = useRef(null);
  const deleteButtonRef = useRef(null);
  const editButtonRef = useRef(null);
  const showResourceButtonRef = useRef(null);
  const fileNameInputRef = useRef(null);
  const submitFormRef = useRef(null);

  const loadingFileBox = loading || !resourceType;

  // The background color and text of the file resource type depends on the entityTypeId
  const resourceTypeColorClass = `resourceCardTypeColor_${
    resourceType || entityTypeId || 'default'}`;

  const readableType = (!loadingFileBox ? resourceType : '')
    ?.substring(0, entityTypeId.indexOf('_'))?.toUpperCase?.() || '';
  // TODO unused // const readableSubType = (entitySubTypeId) ? entitySubTypeId.substring(0, entitySubTypeId.indexOf('_')) : '';

  const download = () => {
    window.open(downloadUrl, '_blank');
  };

  const onEditFinished = (event) => {
    // When the edit form is submitted
    event.preventDefault();
    if (editedFileName !== name) {
      setFileName(editedFileName);
    }
    setIsEditing(false);
  };

  const onFormKeyPress = (event) => {
    // If escape was pressed, reset the edited file name to the original name
    if (event.key === 'Escape') {
      setEditedFileName(name);
      setIsEditing(false);
    }
  };

  useAccessibilityClick(fileItemCardRef, (event) => {
    // NOTE: per C2C-5625 (and comments in C2C-5879) the default behavior is to trigger downloading a file
    if (!event?.target?.className?.includes('no-download') && !isEditing) {
      download();
    }
  });

  useAccessibilityClick(finishedEditingButtonRef, (_event) => {
    submitFormRef.current.dispatchEvent(new Event('submit'));
  });

  useAccessibilityClick(deleteButtonRef, (_event) => {
    removeFile();
  });

  useAccessibilityClick(editButtonRef, (_event) => {
    setIsEditing(true);
    // We also want to select the filename in the input field
    // Find the position of the start of the extension if it exists
    const extensionIndex = fileNameInputRef.current.value.lastIndexOf('.');
    if (extensionIndex > -1) {
      // Then we only select the filename
      fileNameInputRef.current.select();
      fileNameInputRef.current.setSelectionRange(0, extensionIndex);
    } else {
      // Otherwise we select the entire filename
      fileNameInputRef.current.select();
    }
  });

  useAccessibilityClick(showResourceButtonRef, (_event) => {
    setActiveResourcePreviewDialogData(undefined);
    setTimeout(() => {
      setActiveResourcePreviewDialogData({
        ...file,
        resourceType,
        resourceUrl
      });
    }, 300);
  });

  // filenames can sometimes have a suffix of a number in parentheses, e.g. `example_file.webm(1)`
  // we need to strip parentheses content out so we can properly check if the filename ends in a certain type (e.g. 'webm')
  const fileNameNoParentheses = utilsService.stripParenthesesContent(name);

  const isAudioOrVideoResource = entityTypeId === 'audio_resource' || entityTypeId === 'video_resource';
  const isPlayableAudioOrVideoResource = isAudioOrVideoResource && !!PLAYABLE_UPLOADED_FILE_TYPES.find((soundType) => {
    return fileNameNoParentheses?.toUpperCase?.()?.endsWith?.(soundType);
  });

  const isImageResource = entityTypeId === 'image_resource';
  const isPreviewableImageResource = isImageResource && !!PREVIEWABLE_UPLOADED_FILE_TYPES.find((imageType) => {
    return fileNameNoParentheses?.toUpperCase?.()?.endsWith?.(imageType);
  });

  return (
    <div ref={fileItemCardRef}
      className={classNames({
        'file-item': true,
        'editing': isEditing,
        'loading': loadingFileBox
      })}>
      <ResourceItemIcon
        imageUrl={imageUrl}
        resourceType={resourceType} />
      <div className='file-info'>
        <div className={`file-resource-type ${resourceTypeColorClass}`}>{readableType}</div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form ref={submitFormRef} onKeyUp={onFormKeyPress} onSubmit={onEditFinished}>
          <div className='file-name'>
            {isEditing ?
              <input ref={fileNameInputRef} onChange={(event) => setEditedFileName(event.target.value)} type='text' value={editedFileName} />
              :
              <span>{!loadingFileBox ? name : t('loadingFileBox')}</span>}
          </div>
          {isEditing && <button ref={finishedEditingButtonRef} className='primary edit-done'>{t('done')}</button>}
        </form>
      </div>

      <div className='upload-file-tools'>
        <div className={classNames('upload-file-tools-top', { hidden: isEditing || readOnly })}>
          {/* settings */}
          <div ref={editButtonRef} aria-label={t2('settings')} className={classNames('file-settings-wrapper no-download')}>
            <div className='settings-button mask-image no-download' tabIndex={-1} />
          </div>
        </div>
        <div className='upload-file-tools-bottom'>
          {/* delete */}
          {isEditing && (
            <div ref={deleteButtonRef} aria-label={t2('delete')} className={classNames('file-delete-wrapper no-download')}>
              <div className='delete-button mask-image no-download' tabIndex={-1} />
            </div>
          )}
          {/* download */}
          {!isEditing && (
            <div aria-label={t2('download')} className={classNames('file-download-wrapper')}>
              <div className='download-button mask-image' tabIndex={-1} />
            </div>
          )}
          {/* show resource (in dialog) */}
          {!isEditing && (isPreviewableImageResource || isPlayableAudioOrVideoResource) && (
            <div ref={showResourceButtonRef} aria-label={t2('togglepreview')}
              className={classNames('file-show-resource-wrapper no-download', entityTypeId)}>
              <div className={classNames('show-resource-button mask-image no-download', entityTypeId)} tabIndex={-1} />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default Upload;
