import { Behavior } from './Behavior';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';
import UtilsService from '../services/UtilsService';

export class DrawBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    if (!responseAnswer || Object.keys(responseAnswer).length === 0) {
      return false;
    }
    return true;
  }

  isAutoScored = (model) => {
    const autoScore = ContentType.getAutoscore(model.type) && !model.survey && !model.unscored;
    return autoScore;
  }

  getScore = (responseAnswer, model) => {
    const score = 0;
    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    responseAnswer = data;
  }

  getInitializedResponse = (lessonElementId) => {
    const responseAnswer = {};
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    return null;
  }

  setCorrectAnswer = (state, model) => {
  }

  resetStudentAnswer = (state) => {
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;
        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectSelectPrompt2()}. ${textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    return lessonElementState.isScored && (lessonElementState.scoreValue === lessonElementState.maxScore);
  }

  isQuestionCorrect = (lessonElementState) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }

    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new DrawBehavior();
