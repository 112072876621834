import React, { useContext, useRef } from 'react';
import { MobXProviderContext } from 'mobx-react';

import Guideline from '../tools/Guideline';

import '../../../css/BookmarkActivity.scss';

import AuthService from '../../services/AuthService';

import DirectionsFlyout from '../tabbedFlyout/DirectionsFlyout';
import useStyleEvents from '../../../hooks/useStyleEvents';

const BookmarkActivity = ({ lessonElementId }) => {
  const {
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);

  let url = model.pageUrl;
  const isPDF = (model.entityTypeId === 'pdf_resource');
  if (isPDF) {
    url = AuthService.getFlowpaperUrl(model);
  } else if (url) {
    if (AuthService.isGoogleDoc(url)) {
      url = AuthService.fixGoogleDocUrl(url);
    }
  }

  useStyleEvents(lessonElementId, `${lessonElementId}-activity-view`);

  // C2C-5450: Back and Forward buttons should be hidden
  // const backRef = useRef();
  // const forwardRef = useRef();

  // useAccessibilityClick(backRef, (e) => {
  //   const element = document.getElementById(`${lessonElementId}-webpage-iframe`);

  //   if ( element ) {
  //     window.history.back();
  //   }
  // });

  // useAccessibilityClick(forwardRef, (e) => {
  //   const element = document.getElementById(`${lessonElementId}-webpage-iframe`);
  //   if ( element ) {
  //     window.history.forward();
  //   }
  // });

  return (
    <div className='bookmark-activity-question' id={`${lessonElementId}-activity-view`}>
      {(model.directions) ? <DirectionsFlyout directions={model.directions} isActivity={true} lessonElementId={model.lessonElementId} /> : ''}
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      {/* {(!isPDF)&&<div className={'web-buttons'}>
        <button className={'primary'} ref={backRef} >Back</button><button ref={forwardRef} className={'primary'}>Forward</button>
      </div>} */}
      <div className='test-item-question lesson-element bookmark-question' id={`${lessonElementId}-bookmark-view`}>
        <iframe key={lessonElementId} allowFullScreen={true} id={`${lessonElementId}-webpage-iframe`} src={url}
          title={model.title} />
      </div>
    </div>
  );
};
export default BookmarkActivity;
