import { action, computed, makeObservable, observable } from 'mobx';

export class PositionedTextBlock {
  @observable backgroundColor = null;

  @observable borderRadius = null;

  @observable height = null;

  @observable id = null;

  @observable leftPercent = null;

  @observable paddingBottom = null;

  @observable paddingLeft = null;

  @observable paddingRight = null;

  @observable paddingTop = null;

  @observable text = null;

  @observable topPercent = null;

  @observable width = null;

  @observable opacity = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setBackgroundColor(value) {
    this.backgroundColor = value;
  }

  @action
  setBorderRadius(value) {
    this.borderRadius = value;
  }

  @action
  setHeight(value) {
    this.height = value;
  }

  @action
  setId(value) {
    this.id = value;
  }

  @action
  setLeftPercent(value) {
    this.leftPercent = value;
  }

  @action
  setPaddingBottom(value) {
    this.paddingBottom = value;
  }

  @action
  setPaddingLeft(value) {
    this.paddingLeft = value;
  }

  @action
  setPaddingRight(value) {
    this.paddingRight = value;
  }

  @action
  setPaddingTop(value) {
    this.paddingTop = value;
  }

  @action
  setText(value) {
    this.text = value;
  }

  @action
  setTopPercent(value) {
    this.topPercent = value;
  }

  @action
  setWidth(value) {
    this.width = value;
  }

  @action
  setOpacity(value) {
    this.Opacity = value;
  }

  @action
  setData(data) {
    this.backgroundColor = data.backgroundColor;
    this.borderRadius = data.borderRadius;
    this.height = data.height;
    this.id = data.id;
    this.leftPercent = data.leftPercent;
    this.paddingBottom = data.paddingBottom;
    this.paddingLeft = data.paddingLeft;
    this.paddingRight = data.paddingRight;
    this.paddingTop = data.paddingTop;
    this.text = data.text;
    this.topPercent = data.topPercent;
    this.width = data.width;
  }

  @computed
  get styleAttrBackGround() {
    const style = {
      backgroundColor: this.backgroundColor,
      borderRadius: this.borderRadius,
      opacity: this.opacity,
      paddingBottom: this.paddingBottom,
      paddingLeft: this.paddingLeft,
      paddingRight: this.paddingRight,
      paddingTop: this.paddingTop,
      height: this.height,
      width: this.width,
      position: 'absolute',
      zIndex: -1,
    };
    return style;
  }

  @computed
  get styleAttrWrapper() {
    const style = {
      height: this.height,
      width: this.width,
      left: `${this.leftPercent}%`,
      top: `${this.topPercent}%`,
      position: 'absolute',
    };
    return style;
  }
}
