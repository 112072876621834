import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { LessonMode } from '../../../Constants';

import toolbarService from '../../services/ToolbarService';
import responseService from '../../services/ResponseService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import userWirisParser from '../../../hooks/useWirisParser';

import questionService from '../../services/QuestionService';

import { register } from '../../../i18n';

const t = register('SummaryInfo');

const SummaryInfo = observer(() => {
  const {
    lessonManager,
    responseManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  if (!lessonManager.lesson || !lessonManager.lessonOptions) {
    return (<div className='title-page-wrapper' />);
  }

  const containerRef = useRef();
  const exitButton = useRef();
  const submitLessonButton = useRef();
  userWirisParser(containerRef);
  useAccessibilityClick(exitButton, async () => {
    await toolbarService.exitToolHandler();
  });

  useAccessibilityClick(submitLessonButton, async () => {
    await responseService.submitLessonForGrade();
  });

  const {
    totalTestItemResponses, correctItemCount,
    incorrectItemCount, partiallyCorrectItemCount, markedForReviewItemCount, notScoredItemCount, notToBeScoredItemCount
  } = responseManager;

  let { numberSubmittedResponses } = responseManager;

  // TODO unused // const status = (studentActivityManager && studentActivityManager.activityInstanceStatus)?studentActivityManager.activityInstanceStatus:ActivityStatus.ACTIVITY_STARTED;
  const mode = lessonManager.playerMode;
  const items = (totalTestItemResponses === 1) ? `${t('item', 'item')}` : `${t('items', 'items')}`;
  let notSubmittedCount = (((totalTestItemResponses + notToBeScoredItemCount) - numberSubmittedResponses) > 0) ? ((totalTestItemResponses + notToBeScoredItemCount) - numberSubmittedResponses) : 0;
  const hasFeedback = questionFeedbackManager.hasFeedback || (mode === LessonMode.REVIEW) || (mode === LessonMode.SCORING);

  const countedSurveyModelsMap = questionService.getModelsMap({
    condition: (lessonElementState, model, submittedResponseList) => {
      return (
        questionService.isAnsweredSurveyQuestion({
          lessonElementId: model.lessonElementId, lessonElementState, model
        }) && !submittedResponseList?.includes?.(model.lessonElementId)
      );
    },
    originalArray: responseManager.submittedResponseList
  });

  numberSubmittedResponses += countedSurveyModelsMap.size;
  notSubmittedCount -= countedSurveyModelsMap.size;
  const isDisabled = (lessonManager.isSat || lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW);

  return (
    <div ref={containerRef} className='summary-info'>
      <h1>{t('summaryInfoHeader')}</h1>
      <div className='summary-tally-list'>
        <div className='summary-tally'>{`${t('outOf', 'Out of')} ${totalTestItemResponses} ${items}:`}</div>
        <table>
          <tbody>
            <tr className='tally-row'>
              <td className='completed-number'>{numberSubmittedResponses}</td>
              <td className='no-icon' />
              <td className='label'>{t('completed', 'Completed')}</td>
            </tr>
            {(markedForReviewItemCount > 0) && (
              <tr className='tally-row'>
                <td className='completed-number small'>{markedForReviewItemCount}</td>
                <td className='icon'><div className='flagged' /></td>
                <td className='label'><span>{t('markedForReview', 'Marked For Review')}</span></td>
              </tr>
            )}
            <tr className='tally-row'>
              <td className='completed-number small'>{notSubmittedCount}</td>
              <td className='icon'><div className='not-answered' /></td>
              <td className='label'><span>{t('unanswered', 'Unanswered')}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      {(hasFeedback) && (
        <div className='summary-filter-list'>
          <div className='summary-tally'>{t('results', 'Results')}:</div>
          <table>
            <tbody>
              <tr className='tally-row'>
                <td className='completed-number small'>{correctItemCount}</td>
                <td className='icon'><div className='correct' /></td>
                <td className='label'><span>{t('correct', 'Correct')}</span></td>
              </tr>
              <tr className='tally-row'>
                <td className='completed-number small'>{partiallyCorrectItemCount}</td>
                <td className='icon'><div className='partial' /></td>
                <td className='label'><span>{t('partiallyCorrect', 'Partially correct')}</span></td>
              </tr>
              <tr className='tally-row'>
                <td className='completed-number small'>{incorrectItemCount}</td>
                <td className='icon'><div className='incorrect' /></td>
                <td className='label'><span>{t('incorrect', 'Incorrect')}</span></td>
              </tr>
              {(notScoredItemCount > 0) && (
                <tr className='tally-row'>
                  <td className='completed-number small'>{notScoredItemCount}</td>
                  <td className='icon'><div className='not-scored' /></td>
                  <td className='label'><span>{t('toBeScored', 'To Be Scored')}</span></td>
                </tr>
              )}
              {(notToBeScoredItemCount > 0) && (
                <tr className='tally-row'>
                  <td className='completed-number small'>{notToBeScoredItemCount}</td>
                  <td className='icon'><div className='un-scored' /></td>
                  <td className='label'><span>{t('notToBeScored', 'Not Scored')}</span></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <div className='summary-button-wrapper'>
        {(mode === LessonMode.ACTIVITY_MODE) ? (
          <>
            <button ref={submitLessonButton} className='submit-activity-button primary' tabIndex='0'>{t('submit', 'Submit')}</button>
            <button ref={exitButton} className='submit-activity-button primary inverse bottom' tabIndex='0'>{t('saveForLater', 'Save for Later')}</button>
          </>
        ) :
          (!lessonManager.isLti) &&
            <button ref={exitButton} className={`submit-activity-button primary ${(isDisabled) ? 'disabled' : ''}`} disabled={isDisabled} tabIndex='0'>{t('exit', 'Exit')}</button>}
      </div>
    </div>
  );
});

export default SummaryInfo;
