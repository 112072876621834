import { Behavior } from './Behavior';
import UtilsService from '../services/UtilsService';
import { ContentType, LessonMode, QuestionFeedbackState } from '../../Constants';

export class ShortTextBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    if (responseAnswer.text === null || responseAnswer.text.trim() === '' || responseAnswer.text === undefined) {
      return false;
    }
    return true;
  }

  isAutoScored = (model) => {
    let autoScore = (ContentType.getAutoscore(model.type) &&
      !model.survey && !model.unscored);

    if (model.responseFormat && model.responseFormat === 'html') {
      autoScore = false;
    }
    return autoScore;
  }

  getScore = (responseAnswer, model) => {
    let score = -1;
    const { isCaseSensitive } = model;
    let studentResponseText = responseAnswer.text;

    if (model.responseFormat === 'math') {
      studentResponseText = UtilsService.findMathInput(studentResponseText);
    } else {
      studentResponseText = UtilsService.cleanMathMLForScoring(studentResponseText);
    }
    studentResponseText = UtilsService.cleanStringForScoring(studentResponseText);

    (model.validation?.answers || []).forEach((answer) => {
      let answerText = answer.text.trim();

      if (model.responseFormat === 'math') {
        answerText = UtilsService.cleanMathMLForScoring(answerText);
      }

      answerText = UtilsService.cleanStringForScoring(answerText);

      const isMatch = (isCaseSensitive && studentResponseText === answerText) ||
        (!isCaseSensitive && (studentResponseText.toUpperCase() === answerText.toUpperCase()));

      if (isMatch) {
        score = model.maxScore;
        return false; // break out of each
      }
    });
    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    if (model.responseFormat === 'math' || model.responseFormat === 'html') {
      responseAnswer.text = data;
    } else {
      if (model.responseFormat === 'numeric') {
        if (UtilsService.ensureNumericInput(data.target.value)) {
          responseAnswer.text = data.target.value;
        }
      } else {
        responseAnswer.text = data.target.value;
      }
    }
  }

  getInitializedResponse = (lessonElementId) => {
    const responseAnswer = {
      text: '',
      lessonElementId
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    if (model && model.validation && model.validation.answers && model.validation.answers.length > 0) {
      let text = '';
      let count = 1;
      model.validation.answers.forEach((answer) => {
        text += '<div class=\'answer-list-row\'>';
        text = `${text}<div class='list-number'>${count}</div><div class='correct-icon'></div><div class='answer-text'>${answer.text}</div>`;
        text += '</div>';
        ++count;
      });

      return text;
    }
    return '<div></div>';
  }

  setCorrectAnswer = (state, model) => {
    const temp = {
      text: model.validation.answers[0].text,
      lessonElementId: model.lessonElementId
    };
    state.setCurrentResponse(UtilsService.safeMobxClone(temp));
  }

  resetStudentAnswer = (state) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  /// ///
  // model = content model
  //
  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody } `;
      }

      textBody = `${UtilsService.partiallyCorrectPrompt()}. <br/>${ custom }${textBody}`;
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';

    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState, model) => {
    // For short Answers there is only one question so we don't have an array of answers so just return the
    // flag for the only answer
    // eslint-disable-next-line eqeqeq
    return lessonElementState.isScored && (lessonElementState.scoreValue == lessonElementState.maxScore);
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    if (lessonElementState.scoreValue >= lessonElementState.maxScore) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }

    return QuestionFeedbackState.INCORRECT;
  }
}

export default new ShortTextBehavior();
