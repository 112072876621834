import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import useAccessibilityClick from '../../hooks/useAccessibilityClick';
import '../../css/StampIconPicker.scss';
import { MobXProviderContext, observer } from 'mobx-react';
import { TOOL_TYPE } from '../managers/BookDrawManager';

const StampIconPicker = observer((props) => {
  const {
    bookDrawManager
  } = useContext(MobXProviderContext);

  const happyStampRef = useRef();
  const neutralStampRef = useRef();
  const sadStampRef = useRef();
  const thumbsUpStampRef = useRef();
  const thumbsDownStampRef = useRef();
  const heartStampRef = useRef();
  const starStampRef = useRef();

  const handleClick = (e) => {
    const { iconId } = e.target.dataset;
    bookDrawManager.setSelectedIcon(iconId);
    bookDrawManager.setTool(TOOL_TYPE.STAMPS);
  };

  useAccessibilityClick(happyStampRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(neutralStampRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(sadStampRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(thumbsUpStampRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(thumbsDownStampRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(heartStampRef, (e) => {
    handleClick(e);
  });
  useAccessibilityClick(starStampRef, (e) => {
    handleClick(e);
  });

  return (
    <div className={classNames({ stampIconPicker: true, menuOpen: props.open })}>
      <div className='stampIconPickerInner'>
        <div className='stampIconGroup'>
          <div ref={happyStampRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'happyStamp') }, 'stampIcon', 'happyStamp')}
            data-icon-id='happyStamp' title='happyStamp' />
          <div ref={neutralStampRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'neutralStamp') }, 'stampIcon', 'neutralStamp')}
            data-icon-id='neutralStamp'
            title='neutralStamp' />
          <div ref={sadStampRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'sadStamp') }, 'stampIcon', 'sadStamp')}
            data-icon-id='sadStamp' title='sadStamp' />
          <div ref={thumbsUpStampRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'thumbsUpStamp') }, 'stampIcon', 'thumbsUpStamp')}
            data-icon-id='thumbsUpStamp'
            title='thumbs-upStamp' />
          <div ref={thumbsDownStampRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'thumbsDownStamp') }, 'stampIcon', 'thumbsDownStamp')}
            data-icon-id='thumbsDownStamp'
            title='thumbs-downStamp' />
          <div ref={heartStampRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'heartStamp') }, 'stampIcon', 'heartStamp')}
            data-icon-id='heartStamp' title='heartStamp' />
          <div ref={starStampRef}
            className={classNames({ selected: (bookDrawManager.selectedIcon === 'starStamp') }, 'stampIcon', 'starStamp')}
            data-icon-id='starStamp' title='starStamp' />
        </div>
      </div>
    </div>
  );
});

export default StampIconPicker;
