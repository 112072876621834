import { action, computed, makeObservable, observable } from 'mobx';
import { ContentType, LessonMode, LessonSlideIds } from '../../Constants';

import responseManager from './ResponseManager';
import userManager from './UserManager';

import UtilsService from '../services/UtilsService';

import DefaultQuestion from '../../manuscript/questions/DefaultQuestion';
import Analogies from '../../manuscript/graphicorganizers/Analogies';
import CategoryTree from '../../manuscript/graphicorganizers/CategoryTree';
import DefaultGraphicOrganizer from '../../manuscript/graphicorganizers/DefaultGraphicOrganizer';
import DefaultInstruction from '../../manuscript/instructions/DefaultInstruction';
import DefaultResource from '../../manuscript/resources/DefaultResource';
import DefaultActivity from '../../manuscript/activities/DefaultActivity';
import Google from '../../manuscript/google/Google';

import TitlePage from '../components/TitlePage';
import SummaryPage from '../components/summaryPage/SummaryPage';
import ImageLabelMultichoice from '../components/lessonItems/ImageLabelMultichoice';
import ImageTextMatch from '../components/lessonItems/ImageTextMatch';
import ImageTextMatchFlip from '../components/lessonItems/ImageTextMatchFlip';
import ChoiceMatrixMulti from '../components/lessonItems/ChoiceMatrixMulti';
import Cloze from '../components/lessonItems/Cloze';
import ClozeCombo from '../components/lessonItems/ClozeCombo';
import ClozeDragDrop from '../components/lessonItems/ClozeDragDrop';
import ClozeMath from '../components/lessonItems/ClozeMath';
import ClozeMultichoice from '../components/lessonItems/ClozeMultichoice';
import ClozeSpelling from '../components/lessonItems/ClozeSpelling';

import Draw from '../components/lessonItems/Draw';
import LongText from '../components/lessonItems/LongText';
import Multichoice from '../components/lessonItems/Multichoice';
import Multiselect from '../components/lessonItems/Multiselect';
import NumberLine from '../components/lessonItems/NumberLine';
import OrderList from '../components/lessonItems/OrderList';
import OrderSentences from '../components/lessonItems/OrderSentences';
import ShortText from '../components/lessonItems/ShortText';
import Griddable from '../components/lessonItems/Griddable';
import StudentInstruction from '../components/lessonItems/StudentInstruction';
import TeacherInstruction from '../components/lessonItems/TeacherInstruction';
import Presentation from '../components/lessonItems/Presentation';
import TextSelector from '../components/lessonItems/TextSelector';
import HotText from '../components/lessonItems/HotText';
import TextHighlight from '../components/lessonItems/TextHighlight';
import Keyboarding from '../components/lessonItems/Keyboarding';
import Upload from '../components/lessonItems/Upload';
import ImageLabel from '../components/lessonItems/ImageLabel';
import ImageLabelDragDrop from '../components/lessonItems/ImageLabelDragDrop';
import ImageHotSpot from '../components/lessonItems/ImageHotSpot';
import Categorize from '../components/lessonItems/Categorize';
import DiInfo from '../components/diInfoPage/DiInfoPage';
import WebPage from '../components/lessonItems/WebPage';

import BubbleWebGraphicOrganizer from '../components/lessonItems/BubbleWebGraphicOrganizer';
import CauseAndEffect from '../components/lessonItems/CauseAndEffect';
import KWLChart from '../components/lessonItems/KWLChart';
import Relationships from '../components/lessonItems/Relationships';
import TChart from '../components/lessonItems/TChart';
import Venn from '../components/lessonItems/Venn';
import WordAssociation from '../components/lessonItems/WordAssociation';

// Activities
import BookmarkActivity from '../components/lessonItems/BookmarkActivity';
import BookActivity from '../components/lessonItems/BookActivity';

import ImageQuestion from '../components/lessonItems/ImageQuestion';
import AudioQuestion from '../components/lessonItems/AudioQuestion';
import VideoQuestion from '../components/lessonItems/VideoQuestion';
import ArticleQuestion from '../components/lessonItems/ArticleQuestion';
import MultipartActivity from '../components/lessonItems/MultipartActivity';
import PdfResource from '../components/lessonItems/PdfResource';
import Rubric from '../components/lessonItems/RubricResourceViewer';
import ImageResource from '../components/lessonItems/ImageResource';
import VideoResource from '../components/lessonItems/VideoResource';
import AudioResource from '../components/lessonItems/AudioResource';
import ChoiceMatrix from '../components/lessonItems/ChoiceMatrix';

export class LessonManager {
  @observable currentSlide = -1;
  @observable isDirectionsFlyoutOpen = false;
  @observable isInteractiveFlyoutOpen = false;
  @observable isInteractiveViewer = false;
  @observable isQuestionListView = false;
  @observable lesson;
  @observable lessonContentItem;
  @observable lessonOptions;
  @observable lessonResourceUrl = '';
  @observable loaded = 0;
  @observable manualSlideList = [];
  @observable doNotScoreSlideList = [];
  @observable onlyShowManual = false;
  @observable onlyShowDoNotScore = false;
  @observable playerMode = null;
  @observable publisherTheme = null;
  @observable rootObject;
  @observable sectionList = new Map();
  @observable slideList = [];
  @observable startWithOnlyManual = false;
  @observable themeLoaded = false;
  @observable translationsLoaded = false;
  @observable isLti = false;
  @observable currentAudioId = null;
  @observable isSat = false;
  @observable isOld = false;
  @observable isForceTeacher = false;
  @observable slideLabels = [];
  @observable ignoreQuestionChildEventScrollListener = false;
  @observable isManuscriptShort = false;
  @observable isOnline = true;

  LETTER_TABLE= ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'] ;

  interactiveElementId = null;

  lessonElementIdRequested = null;
  onlyShowManualOff = false;

  structureMap;
  contentMap;
  dependencies;
  firstSection;
  clozeIds = [];
  activityPartIds = new Set();
  courseContentItemId = null;

  resourceTypes = new Set([
    ContentType.IMAGE.type,
    ContentType.ARTICLE.type,
    ContentType.AUDIO.type,
    ContentType.VIDEO.type,
    ContentType.BOOKMARK.type,
    ContentType.PDF.type,
    ContentType.RUBRIC.type,
    ContentType.USER_FILE.type,
    ContentType.KEYBOARDING.type,
  ]);

  activityTypes = new Set([
    ContentType.MULTIPART_ACTIVITY.type,
    ContentType.VIDEO_QUESTION.type,
    ContentType.AUDIO_QUESTION.type,
    ContentType.ARTICLE_QUESTION.type,
    ContentType.IMAGE_QUESTION.type,
    ContentType.BOOKMARK_ACTIVITY.type,
    ContentType.BOOK_ACTIVITY.type,
  ]);

  interactiveActivityTypes = new Set([
    ContentType.AUDIO_QUESTION.type,
    ContentType.VIDEO_QUESTION.type,
    ContentType.BOOK_ACTIVITY.type,
    ContentType.IMAGE_QUESTION.type,

  ]);

  multiAnswerTypes = new Set([
    ContentType.IMAGE_HOT_SPOT.type,
    ContentType.MULTISELECT.type,
    ContentType.HOT_TEXT.type
  ]);

  gameTypes = new Set([
    ContentType.IMAGE_TEXT_MATCH_FLIP.type
  ]);

  hasDirectionFlyout = new Set([
    ContentType.BOOK_ACTIVITY.type,
    ContentType.AUDIO_QUESTION.type,
    ContentType.VIDEO_QUESTION.type,
    ContentType.BOOKMARK_ACTIVITY.type
  ])

  constructor() {
    makeObservable(this);
  }

  @action
  setRootObject = (obj) => {
    this.rootObject = obj;
  }

  @action
  setStartWithOnlyManual = () => {
    if (this.lessonOptions.showOnlyManuallyGradedQuestions) {
      this.setOnlyShowManual(true);
    }

    if (this.lessonOptions.showUngradedSurveyQuestions) {
      this.setOnlyShowDoNotScore(true);
    }
  }

  @action
  createSlideLabels = () => {
    this.slideLabels = [];
    this.slideList.forEach((lessonElementId) => {
      const model = this.getSlideModel(lessonElementId);
      if (model && !model.isActivityPart) {
        this.slideLabels.push(lessonElementId);
      }
    });
  }

  @action
  setOnlyShowManual = (toggle) => {
    if (toggle && this.manualSlideList.length === 0) {
      this.onlyShowManual = false;
      return false;
    }

    this.onlyShowManual = toggle;
    this.setCurrentSlideIndex(0);
    return true;
  }

  setManuscriptShort = (toggle) => {
    this.isManuscriptShort = toggle;
  }

  setOnlyShowDoNotScore = (toggle) => {
    if (toggle && this.doNotScoreSlideList.length === 0) {
      this.onlyShowDoNotScore = false;
      return false;
    }
    this.onlyShowDoNotScore = toggle;

    this.setCurrentSlideIndex(0);
    return true;
  }

  @action
  setContentItem = (ci) => {
    ci.entityType = ci.entityTypeId.toUpperCase();
    this.lessonContentItem = ci;
  }

  @action
  setLoaded = (loaded) => {
    if (loaded === 0) {
      this.loaded = 0;
      return;
    }
    this.loaded += loaded;
  }

  @action
  setModelValidation = (lessonElementId, validation) => {
    const model = this.getSlideModel(lessonElementId);
    if (model && validation) {
      model.validation = UtilsService.safeMobxClone(validation);
    }
  }

  @computed get isLoaded() {
    return (this.loaded <= 0);
  }

  @action
  setLessonOptions = (lessonOptions) => {
    this.lessonOptions = lessonOptions;
  }

  @action
  setLesson = (lesson) => {
    this.lesson = lesson;
  }

  @action
  setPublisherTheme = (publisherTheme) => {
    this.publisherTheme = publisherTheme;
  }

  @action
  setThemeLoaded = (loaded) => {
    this.themeLoaded = loaded;
  }

  @action
  setTranslationsLoaded = (loaded) => {
    this.translationsLoaded = loaded;
  }

  @computed
  get hasNoTheme() {
    return this.themeLoaded && this.publisherTheme === null;
  }

  @action
  setIsLti = (val) => {
    this.isLti = val;
  }

  @action
  setIsSat = (val) => {
    this.isSat = val;
  }

  @action
  setIsOld = (val) => {
    this.isOld = val;
  }

  @action
  setIsForceTeacher = (val) => {
    this.isForceTeacher = val;
  }

  @action
  setIsQuestionListView = (toggle) => {
    this.isQuestionListView = toggle;
  }

  @action setIgnoreQuestionChildEventScrollListener = (toggle) => {
    this.ignoreQuestionChildEventScrollListener = toggle;
  }

  @action
  setPlayerMode = (mode) => {
    this.playerMode = mode;
  }

  @action
  setResourceUrl = (url) => {
    this.lessonResourceUrl = url;
  }

  @action
  setCurrentAudioId = (id) => {
    this.currentAudioId = id;
  }

  @action
  setIsInteractiveViewer = (val) => {
    this.isInteractiveViewer = val;
  }

  @action
  setInteractiveElementId = (id) => {
    this.interactiveElementId = id;
  }

  @action
  setIsInteractiveFlyoutOpen = (isOpen) => {
    this.isInteractiveFlyoutOpen = isOpen;
  }

  @action
  setIsDirectionsFlyoutOpen = (isOpen) => {
    this.isDirectionsFlyoutOpen = isOpen;
  }

  @action
  setOnline = (isOnline) => {
    this.isOnline = isOnline;
  }

  @computed get currentLessonElementId() {
    const slides = this.getFilteredSlides();

    if (slides.length > 0 && this.currentSlide < slides.length) {
      return slides[this.currentSlide];
    }
    if (slides.length > 0) {
      return slides[0];
    }

    return null;
  }

  @computed get lessonElementIds() {
    const slides = this.getFilteredSlides();

    return slides;
  }

  @computed get currentParentLessonElementId() {
    const slides = this.getFilteredSlides();

    if (slides.length > 0 && this.currentSlide < slides.length) {
      const lessonElementId = slides[this.currentSlide];
      const currentModel = this.getSlideModel(lessonElementId);
      if (!UtilsService.isNullOrEmpty(currentModel.parentActivityElementId)) {
        return currentModel.parentActivityElementId;
      }
    }
    if (slides.length > 0) {
      return slides[0];
    }
    return null;
  }

  @computed get currentRespondableLessonElementId() {
    const lessonElementId = this.currentLessonElementId;
    const model = this.getSlideModel(lessonElementId);
    if (this.shouldHaveElementState(model)) {
      return lessonElementId;
    }
    return null;
  }

  shouldHaveElementState = (model) => {
    if (model.isTestItem || model.isStudentInstruction || model.isActivity || model.isResource || model.isInstruction) {
      return true;
    }
    return false;
  }

  @computed get lessonTitle() {
    let finalTitle = (this.lessonContentItem) ? this.lessonContentItem.name : '';
    if (this.lessonOptions && this.lessonOptions.title) {
      finalTitle = this.lessonOptions.title;
    }

    return finalTitle;
  }

  @computed get lessonTitleAndSubtitle() {
    let finalTitle = this.lessonTitle;
    if (this.lessonOptions && this.lessonOptions.showSubtitleHeader && this.lessonOptions.subtitle) {
      finalTitle = `${this.lessonOptions.title.trim()}: ${this.lessonOptions.subtitle}`;
    }
    return finalTitle;
  }

  @computed get lessonGotoTitle() {
    let finalTitle = null;
    if (this.lessonOptions && this.lessonOptions.showTitleGoto && this.lessonOptions.title) {
      finalTitle = `${this.lessonOptions.title.trim()}`;
      if (this.lessonOptions.showSubtitleGoto && this.lessonOptions.subtitle) {
        finalTitle = `${finalTitle}: ${this.lessonOptions.subtitle}`;
      }
    }
    return finalTitle;
  }

  @action
  setCurrentSlideIndex = (index) => {
    const modelElement = this.getModelAndLessonElement(index);
    if (!modelElement.model || !modelElement.lessonElement) {
      this.currentSlide = 0;
      return;
    }
    if (modelElement.model.isActivity && modelElement.lessonElement.lessonElementIds.length > 0) {
      this.currentSlide = index + 1; // first child
    } else {
      this.currentSlide = index;
    }
  }

  getParentLastSlide = (parentLessonElement) => {
    const slides = this.getFilteredSlides();
    const filteredChildren = [];
    for (const childId of parentLessonElement.lessonElementIds) {
      if (slides.indexOf(childId) > -1) {
        filteredChildren.push(childId);
      }
    }
    if (filteredChildren.length <= 0) {
      return null;
    }
    return filteredChildren[filteredChildren.length - 1];
  }

  getParentFirstSlide = (parentLessonElement) => {
    const slides = this.getFilteredSlides();
    const filteredChildren = [];
    for (const childId of parentLessonElement.lessonElementIds) {
      if (slides.indexOf(childId) > -1) {
        filteredChildren.push(childId);
      }
    }
    if (filteredChildren.length <= 0) {
      return null;
    }
    return filteredChildren[0];
  }

  @action
  nextSlide = () => {
    try {
      if (window.ReadSpeaker && window.ReadSpeaker.ui?.getActivePlayer?.()) {
        window.ReadSpeaker.ui.getActivePlayer().close();
        window.ReadSpeaker.ui.destroyActivePlayer();
      }
      const slides = this.getFilteredSlides();
      if (this.currentSlide === (slides.length - 1)) {
        if (this.playerMode === LessonMode.SCORING) {
          this.setCurrentSlideIndex(0);
        }
        return;
      }
      if (this.lessonOptions.navigateWithinActivity) {
        this.setCurrentSlideIndex(this.currentSlide + 1);
      } else {
        const modelElement = this.getModelAndLessonElement(this.currentSlide);
        let newIndex = this.currentSlide + 1;
        if (modelElement.model.isActivityPart) {
          const parentLessonElement = this.getLessonElement(modelElement.model.parentActivityElementId);
          const lastChildId = this.getParentLastSlide(parentLessonElement);
          if (lastChildId) {
            newIndex = this.getIndexOfLessonElementId(lastChildId);
            if (newIndex === (slides.length - 1)) {
              if (this.playerMode === LessonMode.SCORING) {
                this.setCurrentSlideIndex(0);
              }
              return;
            }
            this.setCurrentSlideIndex(newIndex + 1);
            return;
          }
        }
        this.setCurrentSlideIndex(newIndex);
      }
    } catch (error) {
      console.error(error);
    }
  }

  checkNextSlide = () => {
    try {
      const slides = this.slideList;
      if (this.currentSlide === (slides.length - 1)) {
        return null;
      }
      return slides[this.currentSlide + 1];
    } catch (error) {
      console.error(error);
    }
  }

  getFilteredSlides = () => {
    if (this.onlyShowManual && !this.onlyShowDoNotScore) {
      const list = [].concat(this.manualSlideList);
      if (this.playerMode === LessonMode.SCORING && this.lessonOptions.hasSummaryPage) {
        list.push(LessonSlideIds.SUMMARY_PAGE);
      }
      return list;
    }

    if (!this.onlyShowManual && this.onlyShowDoNotScore) {
      const list = [].concat(this.doNotScoreSlideList);
      if (this.playerMode === LessonMode.SCORING && this.lessonOptions.hasSummaryPage) {
        list.push(LessonSlideIds.SUMMARY_PAGE);
      }
      return list;
    }

    if (this.onlyShowManual && this.onlyShowDoNotScore) {
      const list = [];
      this.slideList.forEach((slide, index) => {
        if (this.manualSlideList.includes(slide) || this.doNotScoreSlideList.includes(slide)) {
          list.push(slide);
        }
      });
      if (this.playerMode === LessonMode.SCORING && this.lessonOptions.hasSummaryPage) {
        list.push(LessonSlideIds.SUMMARY_PAGE);
      }
      return list;
    }
    return this.slideList;
  }

  @computed get numberOfQuestions() {
    let count = 0;
    for (let i = 0; i < this.slideList.length; i++) {
      const model = this.getSlideModel(this.slideList[i]);
      if (model && model.isTestItem) {
        ++count;
      }
    }
    return count;
  }

  @action
  previousSlide = () => {
    try {
      if (window.ReadSpeaker && window.ReadSpeaker.ui?.getActivePlayer?.()) {
        window.ReadSpeaker.ui.getActivePlayer().close();
        window.ReadSpeaker.ui.destroyActivePlayer();
      }
      const slides = this.getFilteredSlides();
      if (this.currentSlide === 0) {
        if (this.playerMode === LessonMode.SCORING) {
          this.setCurrentSlideIndex(slides.length - 1);
        }
        return;
      }

      if (this.lessonOptions.navigateWithinActivity) {
        const modelElement = this.getModelAndLessonElement(this.currentSlide);
        if (this.isActivityFirstChild(modelElement)) {
          if (this.currentSlide - 2 < 0) {
            return;
          } else {
            this.setCurrentSlideIndex(this.currentSlide - 2);
            return;
          }
        }
        this.setCurrentSlideIndex(this.currentSlide - 1);
      } else {
        const modelElement = this.getModelAndLessonElement(this.currentSlide);
        if (modelElement.model.isActivityPart) {
          if (this.isActivityFirstChild(modelElement)) {
            if (this.currentSlide - 2 < 0) {
              return;
            } else {
              const modelElement = this.getModelAndLessonElement(this.currentSlide - 2);
              if (modelElement.model.isActivityPart) {
                const parentLessonElement = this.getLessonElement(modelElement.model.parentActivityElementId);
                const firstChildId = this.getParentFirstSlide(parentLessonElement);
                if (firstChildId) {
                  const newIndex = this.getIndexOfLessonElementId(firstChildId);
                  this.setCurrentSlideIndex(newIndex);
                  return;
                }
              } else {
                this.setCurrentSlideIndex(this.currentSlide - 2);
                return;
              }
            }
          } else {
            const parentLessonElement = this.getLessonElement(modelElement.model.parentActivityElementId);
            const firstChildId = parentLessonElement.lessonElementIds[0];
            if (firstChildId) {
              const newIndex = this.getIndexOfLessonElementId(firstChildId);
              if (newIndex - 2 < 0) {
                return;
              } else {
                const checkElement = this.getModelAndLessonElement(newIndex - 2);
                if (checkElement.model.isActivityPart) {
                  const parentLessonElement = this.getLessonElement(checkElement.model.parentActivityElementId);
                  const firstChildId = parentLessonElement.lessonElementIds[0];
                  if (firstChildId) {
                    const newIndex = this.getIndexOfLessonElementId(firstChildId);
                    this.setCurrentSlideIndex(newIndex);
                    return;
                  }
                } else {
                  this.setCurrentSlideIndex(newIndex - 2);
                }
                return;
              }
            }
          }
        }
        const checkElement = this.getModelAndLessonElement(this.currentSlide - 1);
        if (checkElement.model.isActivityPart) {
          const parentLessonElement = this.getLessonElement(checkElement.model.parentActivityElementId);
          const firstChildId = parentLessonElement.lessonElementIds[0];
          if (firstChildId) {
            const newIndex = this.getIndexOfLessonElementId(firstChildId);
            this.setCurrentSlideIndex(newIndex);
          }
        } else {
          this.setCurrentSlideIndex(this.currentSlide - 1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // this should only be called in Activity Mode
  checkPreviousSlide = () => {
    try {
      const modelElement = this.getModelAndLessonElement(this.currentSlide);
      if (this.isActivityFirstChild(modelElement)) {
        if (this.currentSlide - 2 < 0) {
          return null;
        } else {
          return this.slideList[this.currentSlide - 2];
        }
      }
      return this.slideList[this.currentSlide - 1];
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  @action
  addSlide = (lessonElementId) => {
    this.slideList.push(lessonElementId);
  }

  @action
  addManuallyScoredSlide = (lessonElementId) => {
    this.manualSlideList.push(lessonElementId);
  }

  addDoNotScoreSlide = (lessonElementId) => {
    this.doNotScoreSlideList.push(lessonElementId);
  }

  @computed get showNavBackButton() {
    // eslint-disable-next-line no-unused-vars
    const slides = this.getFilteredSlides();
    const mode = this.playerMode;

    if (mode === LessonMode.ACTIVITY_MODE || mode === LessonMode.PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      const previousLessonElementId = this.checkPreviousSlide();
      const lessonElementState = responseManager.getLessonElementState(previousLessonElementId);
      if (lessonElementState && lessonElementState.isDependencyLocked) {
        return true;
      }
    }

    if (this.currentSlide <= 0) {
      return false;
    }

    const modelElement = this.getModelAndLessonElement(this.currentSlide);

    if (this.currentSlide === 1 && this.isActivityFirstChild(modelElement)) {
      return false;
    }
    return true;
  }

  @computed get isAssessment() {
    return this.lesson?.contentItem?.entityTypeId === 'assessment';
  }

  @computed get isLesson() {
    return this.lesson?.contentItem?.entityTypeId === 'lesson';
  }

  @computed get showNavNextButton() {
    const slides = this.getFilteredSlides();
    const mode = this.playerMode;
    if (mode === LessonMode.ACTIVITY_MODE || mode === LessonMode.PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      const nextLessonElementId = this.checkNextSlide();
      const lessonElementState = responseManager.getLessonElementState(nextLessonElementId);
      if (lessonElementState && lessonElementState.isDependencyLocked) {
        return false;
      }
    }

    if (this.currentSlide >= slides.length - 1) {
      return false;
    }
    return true;
  }

  @computed get sectionLessonElementIds() {
    const keyIt = this.sectionList.keys();
    if (keyIt) {
      return Array.from(keyIt);
    }
    return null;
  }

  @computed
  get elementsThatHaveState() {
    const returnList = [];
    // iterating using the slideList to ensure the models
    // are sorted in slide order.
    for (let i = 0; i < this.slideList.length; ++i) {
      const lessonElement = this.structureMap[this.slideList[i]];
      const model = this.contentMap[lessonElement.entityId];
      if (this.shouldHaveElementState(model)) {
        returnList.push(model);
      }
    }
    return returnList;
  }

  @computed
  get isReadOnly() {
    if (this.playerMode === LessonMode.REVIEW ||
      this.playerMode === LessonMode.STUDENT_PREVIEW ||
      this.playerMode === LessonMode.PRINT_PREVIEW ||
      this.playerMode === LessonMode.SCORING ||
      this.playerMode === LessonMode.MANUSCRIPT) {
      return true;
    }
    return false;
  }

  @computed
  get slideNumberLabel() {
    const slides = this.slideLabels;

    const model = this.getSlideModel(this.currentLessonElementId);
    const currentIndex = slides.indexOf(this.currentLessonElementId) + 1;

    if (model.isActivityPart) {
      const parentLessonElement = this.structureMap[model.parentActivityElementId];
      const parentIndex = slides.indexOf(parentLessonElement.id) + 1;
      const childIndex = parentLessonElement.lessonElementIds.indexOf(model.lessonElementId) + 1;
      return `${parentIndex}.${childIndex}/${slides.length}`;
    }
    if (model.isActivity) {
      return `${currentIndex}.0/${slides.length}`;
    }
    return `${currentIndex}/${slides.length}`;
  }

  get currentSlideNumber() {
    const slides = this.getFilteredSlides();

    const model = this.getSlideModel(this.currentLessonElementId);
    const currentIndex = slides.indexOf(this.currentLessonElementId) + 1;

    if (model.isActivityPart) {
      const parentLessonElement = this.structureMap[model.parentActivityElementId];
      const arrayIndex = slides.indexOf(parentLessonElement.id);
      const parentIndex = arrayIndex + 1;
      const childIndex = parentLessonElement.lessonElementIds.indexOf(model.lessonElementId) + 1;
      return `${parentIndex}.${childIndex}`;
    }
    if (model.isActivity) {
      return `${currentIndex}.0`;
    }
    return `${currentIndex}`;
  }

  @computed
  get questionNumberLabel() {
    const model = this.getSlideModel(this.currentLessonElementId);
    if (model.questionNumber === null || model.questionNumber === undefined) {
      return null;
    }
    const number = model.questionNumber;

    if (UtilsService.isNullOrEmpty(number)) {
      return '';
    }

    return `${number}/${this.numberOfQuestions}`;
  }

  get currentItemNumber() {
    const model = this.getSlideModel(this.currentLessonElementId);
    if (model.questionNumber === null || model.questionNumber === undefined) {
      return '-1';
    }
    return model.questionNumber;
  }

  getIndexOfLessonElementId = (lessonElementId) => {
    const slides = this.getFilteredSlides();
    return slides.indexOf(lessonElementId);
  }

  @computed
  get currentSectionTitle() {
    const model = this.getSlideModel(this.currentLessonElementId);
    if (!model || !model.sectionLessonElementId) {
      return null;
    }
    const sectionModel = this.getSectionModel(model.sectionLessonElementId);
    if (!sectionModel) {
      return null;
    }
    const { title } = sectionModel;
    // TODO remove per CF-3048
    // if (Utils.isNullOrEmpty(title)) {
    //   title = `Section ${sectionModel.sectionNumber}`;
    // }
    return title;
  }

  // TODO remove
  // @computed
  // get isCurrentModeUsingTranslations() {
  //   return this.playerMode === LessonMode.STUDENT_PREVIEW ||
  //     this.playerMode === LessonMode.PREVIEW ||
  //     this.playerMode === LessonMode.ITEM_PREVIEW ||
  //     this.playerMode === LessonMode.REVIEW ||
  //     this.playerMode === LessonMode.SCORING ||
  //     this.playerMode === LessonMode.ACTIVITY_MODE;
  // }

  getActivityComponent(lessonElementId) {
    const model = this.getSlideModel(lessonElementId);

    let Activity;
    if (model && model.isActivity) {
      switch (model.type) {
      case ContentType.MULTIPART_ACTIVITY.type:
        Activity = MultipartActivity;
        break;
      case ContentType.VIDEO_QUESTION.type:
        Activity = VideoQuestion;
        break;
      case ContentType.AUDIO_QUESTION.type:
        Activity = AudioQuestion;
        break;
      case ContentType.ARTICLE_QUESTION.type:
        Activity = ArticleQuestion;
        break;
      case ContentType.IMAGE_QUESTION.type:
        Activity = ImageQuestion;
        break;
      case ContentType.BOOKMARK_ACTIVITY.type:
        Activity = BookmarkActivity;
        break;
      case ContentType.BOOK_ACTIVITY.type:
        Activity = BookActivity;
        break;
      default:
        Activity = DefaultActivity;
      }
    }
    if (model) {
      return Activity;
    }
    return null;
  }

  getCurrentQuestionComponent(lessonElementId) {
    const model = this.getSlideModel(lessonElementId);
    let Question;
    if (model && model.isQuestion) {
      switch (model.type) {
      case ContentType.MULTICHOICE.type:
        Question = Multichoice;
        break;
      case ContentType.MULTISELECT.type:
        Question = Multiselect;
        break;
      case ContentType.SHORT_TEXT.type:
        Question = ShortText;
        break;
      case ContentType.LONG_TEXT.type:
        Question = LongText;
        break;
      case ContentType.UPLOAD.type:
        Question = Upload;
        break;
      case ContentType.CLOZE.type:
        Question = Cloze;
        break;
      case ContentType.CLOZE_COMBO.type:
        Question = ClozeCombo;
        break;
      case ContentType.CLOZE_DRAG_DROP.type:
        Question = ClozeDragDrop;
        break;
      case ContentType.CLOZE_MULTICHOICE.type:
        Question = ClozeMultichoice;
        break;
      case ContentType.CLOZE_MATH.type:
        Question = ClozeMath;
        break;
      case ContentType.CATEGORIZE.type:
        Question = Categorize;
        break;
      case ContentType.IMAGE_HOT_SPOT.type:
        Question = ImageHotSpot;
        break;
      case ContentType.IMAGE_LABEL.type:
        Question = ImageLabel;
        break;
      case ContentType.IMAGE_LABEL_MULTICHOICE.type:
        Question = ImageLabelMultichoice;
        break;
      case ContentType.IMAGE_LABEL_DRAG_DROP.type:
        Question = ImageLabelDragDrop;
        break;
      case ContentType.DRAW.type:
        Question = Draw;
        break;
      case ContentType.CHOICE_MATRIX.type:
        Question = ChoiceMatrix;
        break;
      case ContentType.CHOICE_MATRIX_MULTI.type:
        Question = ChoiceMatrixMulti;
        break;
      case ContentType.TEXT_SELECTOR.type:
        Question = TextSelector;
        break;
      case ContentType.TEXT_HIGHLIGHT.type:
        Question = TextHighlight;
        break;
      case ContentType.HOT_TEXT.type:
        Question = HotText;
        break;
      case ContentType.ORDER_SENTENCES.type:
        Question = OrderSentences;
        break;
      case ContentType.ORDER_LIST.type:
        Question = OrderList;
        break;
      case ContentType.CLOZE_SPELLING.type:
        Question = ClozeSpelling;
        break;
      case ContentType.IMAGE_TEXT_MATCH.type:
        Question = ImageTextMatch;
        break;
      case ContentType.IMAGE_TEXT_MATCH_FLIP.type:
        Question = ImageTextMatchFlip;
        break;
      case ContentType.NUMBER_LINE.type:
        Question = NumberLine;
        break;
      case ContentType.GRIDDABLE.type:
        Question = Griddable;
        break;
      default:
        Question = DefaultQuestion;
      }
    }

    let GraphicOrganizer;
    if (model && model.isGraphicOrganizer) {
      switch (model.type) {
      case ContentType.ANALOGIES.type:
        GraphicOrganizer = Analogies;
        break;
      case ContentType.BUBBLE_WEB.type:
        GraphicOrganizer = BubbleWebGraphicOrganizer;
        break;
      case ContentType.CATEGORY_TREE.type:
        GraphicOrganizer = CategoryTree;
        break;
      case ContentType.CAUSE_AND_EFFECT.type:
        GraphicOrganizer = CauseAndEffect;
        break;
      case ContentType.KWL_CHART.type:
        GraphicOrganizer = KWLChart;
        break;
      case ContentType.RELATIONSHIPS.type:
        GraphicOrganizer = Relationships;
        break;
      case ContentType.T_CHART.type:
        GraphicOrganizer = TChart;
        break;
      case ContentType.VENN.type:
        GraphicOrganizer = Venn;
        break;
      case ContentType.WORD_ASSOCIATION.type:
        GraphicOrganizer = WordAssociation;
        break;
      default:
        GraphicOrganizer = DefaultGraphicOrganizer;
      }
    }

    let Instruction;
    if (model && model.isInstruction) {
      switch (model.type) {
      case ContentType.PRESENTATION.type:
        Instruction = Presentation;
        break;
      case ContentType.STUDENT_INSTRUCTION.type:
        Instruction = StudentInstruction;
        break;
      case ContentType.TEACHER_INSTRUCTION.type:
        Instruction = TeacherInstruction;
        break;
      case ContentType.WEB_PAGE.type:
        Instruction = WebPage;
        break;
      default:
        Instruction = DefaultInstruction;
      }
    }

    let Resource;
    if (model && model.isResource) {
      switch (model.type) {
      case ContentType.IMAGE.type:
        Resource = ImageResource;
        break;
      case ContentType.VIDEO.type:
        Resource = VideoResource;
        break;
      case ContentType.AUDIO.type:
        Resource = AudioResource;
        break;
      case ContentType.PDF.type:
        Resource = PdfResource;
        break;
      case ContentType.RUBRIC.type:
        Resource = Rubric;
        break;
      case ContentType.KEYBOARDING.type:
        Resource = Keyboarding;
        break;
      default:
        Resource = DefaultResource;
      }
    }

    if (model) {
      if (model.isGraphicOrganizer) {
        return GraphicOrganizer;
      }
      if (model.isQuestion) {
        return Question;
      }
      if (model.isGoogle) {
        return Google;
      }
      if (model.isResource) {
        return Resource;
      }
      if (model.isInstruction) {
        return Instruction;
      }
      if (model.isDiInfo) {
        return DiInfo;
      }
      if (model.isTitlePage) {
        return TitlePage;
      }
      if (model.isSummaryPage) {
        return SummaryPage;
      }
    }

    return null;
  }

  isSelected = (lessonElementId) => {
    const slides = this.getFilteredSlides();

    if (slides[this.currentSlide] === lessonElementId) {
      return true;
    } else {
      const model = this.getSlideModel(lessonElementId);
      const lessonElement = this.getLessonElement(lessonElementId);
      const selectedId = slides[this.currentSlide];
      if (model.isActivity && lessonElement.lessonElementIds.indexOf(selectedId) > -1) {
        return true;
      }
    }
    return false;
  }

  getSlideModelFromTotalOnly(lessonElementId) {
    const theSlides = this.slideList;

    if (theSlides.length === 0 ||
      typeof this.structureMap === 'undefined' ||
      typeof this.contentMap === 'undefined') {
      return null;
    }

    const lessonElement = this.structureMap[lessonElementId];

    if (!lessonElement) {
      return null;
    }

    const model = this.contentMap[lessonElement.entityId];

    if (!model) {
      return null;
    }
    return model;
  }

  getSlideModel(lessonElementId) {
    const theSlides = this.getFilteredSlides();

    if (theSlides.length === 0 ||
      typeof this.structureMap === 'undefined' ||
      typeof this.contentMap === 'undefined') {
      return null;
    }

    const lessonElement = this.structureMap[lessonElementId];

    if (!lessonElement) {
      return null;
    }

    const model = this.contentMap[lessonElement.entityId];

    if (!model) {
      return null;
    }
    return model;
  }

  getSectionModel = (lessonElementId) => {
    const model = this.sectionList.get(lessonElementId);
    if (model) {
      return model;
    }
    return null;
  }

  studentHidden = (model, isStudent) => {
    if (isStudent && model.type === ContentType.TEACHER_INSTRUCTION.type) {
      return true;
    }
    if (isStudent && model.hideFromStudents) {
      return true;
    }
    return false;
  }

  getSectionLessonElementIdList = (sectionLessonElementId, isStudent) => {
    const lessonElementIdList = [];

    const sectionLessonElement = this.getLessonElement(sectionLessonElementId);

    if (sectionLessonElement.lessonElementIds && sectionLessonElement.lessonElementIds.length > 0) {
      sectionLessonElement.lessonElementIds.forEach((lessonElementId) => {
        const lessonElement = this.structureMap[lessonElementId];
        const visibleSlides = this.getFilteredSlides();
        if (lessonElement) {
          const model = this.contentMap[lessonElement.entityId];
          if (model && (model.isTestItem || model.isInstruction || model.isResource)) {
            const visible = visibleSlides.indexOf(lessonElementId) > -1;
            if (!this.studentHidden(model, isStudent) && visible) {
              lessonElementIdList.push(lessonElementId);
            }
          } else if (model && model.isActivity) {
            if (this.activityPartIds.size > 0) {
              this.activityPartIds.forEach((activityChildLessonElementId) => {
                const childLessonElement = this.structureMap[activityChildLessonElementId];
                const childModel = this.contentMap[childLessonElement.entityId];
                const visible = visibleSlides.indexOf(activityChildLessonElementId) > -1;
                if (visible && (childModel.isTestItem || childModel.isInstruction || childModel.isResource) && childModel.parentActivityElementId === lessonElementId) {
                  lessonElementIdList.push(activityChildLessonElementId);
                }
              });
            } else {
              const visible = visibleSlides.indexOf(lessonElementId) > -1;
              if (visible) {
                lessonElementIdList.push(lessonElementId);
              }
            }
          }
        }
      });
    }
    return lessonElementIdList;
  }

  getLessonElement = (lessonElementId) => {
    const lessonElement = this.structureMap[lessonElementId];
    if (!lessonElement) {
      return null;
    }
    return lessonElement;
  }

  getModelAndLessonElement = (index) => {
    const theSlides = this.getFilteredSlides();
    if (index < 0) {
      return { model: null, lessonElement: null };
    }
    const lessonElementId = theSlides[index];
    if (!lessonElementId) {
      return { model: null, lessonElement: null };
    }
    const lessonElement = this.structureMap[lessonElementId];
    if (!lessonElement) {
      return { model: null, lessonElement: null };
    }
    const model = this.contentMap[lessonElement.entityId];
    if (!model) {
      return { model: null, lessonElement: null };
    }
    return { model, lessonElement };
  }

  isActivityFirstChild = (modelElement) => {
    if (!modelElement) {
      return false;
    }
    const { model } = modelElement;
    const { lessonElement } = modelElement;

    if (!model || !lessonElement) {
      return false;
    }

    if (!model.isActivityPart) {
      return false;
    }

    const parentModelElement = this.getModelAndLessonElement(this.currentSlide - 1);

    if (parentModelElement && parentModelElement.model && parentModelElement.model.isActivity) {
      return true;
    }

    return false;
  }

  addLessonWideListeners = () => {
    if (this.lessonOptions && this.lessonOptions.hasPreventCopyPaste && !userManager.hasCopyPasteOverride) {
      window.addEventListener('copy', this.preventActionListener);
      window.addEventListener('contextmenu', this.preventActionListener);
      // window.addEventListener('paste', this.preventActionListener);
    }
  }

  preventActionListener = (e) => {
    e = e || window.event;
    e.preventDefault();
  };
}

export default new LessonManager();
