import React from 'react';
import { observer } from 'mobx-react';

const FeedbackIcon = observer((props) => {
  const {
    feedbackIconStyleObj = {},
    feedbackIconSvgHeight = '23',
    feedbackIconSvgTransform = 'translate(0, 0)',
    feedbackIconSvgWidth = '23',
    isCorrect
  } = props;

  return (
    <div className='feedback-icon'>
      {isCorrect ? (
        <svg
          className='feedback-svg'
          height={feedbackIconSvgHeight}
          style={feedbackIconStyleObj}
          transform={feedbackIconSvgTransform}
          viewBox='0 0 23 23'
          width={feedbackIconSvgWidth}>
          <path d='M1062.292-6225.672l7.042-23 7.09 22.852-18.54-14.474h23z' fill='#508d14' transform='translate(-1057.885 6248.672)' />
        </svg>
      )
        : (
          <svg
            className='feedback-svg'
            height={feedbackIconSvgHeight}
            style={feedbackIconStyleObj}
            transform={feedbackIconSvgTransform}
            viewBox='0 0 23 23'
            width={feedbackIconSvgWidth}>
            <path d='M3842.348-1199.53l3.011-3.121 8.45 8.434 8.472-8.434 3.067 3.121-8.426 8.363 8.426 8.511-3.067 3-8.472-8.444-8.45 8.444-3.011-3 8.421-8.511z' fill='#cd373d' transform='translate(-3842.348 1202.651)' />
          </svg>
        )}
    </div>
  );
});

export default FeedbackIcon;
