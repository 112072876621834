import { Behavior } from './Behavior';
import UtilsService from '../services/UtilsService';
import responseService from '../services/ResponseService';
import Auth from '../services/AuthService';
import audioManager from '../managers/AudioManager';

export class ImageTextMatchFlipBehavior extends Behavior {
  checkForValidResponse = (responseAnswer) => {
    // The answer is valid if any of the answers are selected
    return responseAnswer.answers.some(({ selectedOnce }) => selectedOnce);
  }

  setCorrectAnswer = (state, model) => {
    const response = { answers: [] };

    for (const prompt of model.prompts) {
      response.answers.push({ type: 'prompt', id: prompt.id, selectedOnce: true, selected: true, locked: true });
    }

    for (const target of model.targets) {
      response.answers.push({ type: 'target', id: target.text, selectedOnce: true, selected: true, locked: true });
    }

    state.setCurrentResponse(response);
  }

  resetStudentAnswer = (state) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  getScore = (responseAnswer, model) => {
    const score = 0;
    return score;
  }

  setResponseData = (data, responseAnswer, model) => {
    if (data.clearAll) {
      responseAnswer.answers.forEach((answer) => {
        if (answer.selected && !answer.locked) {
          answer.selected = false;
        }
      });
      return;
    }

    let correctSound = null;
    let incorrectSound = null;
    let flipSound = '/audio/camera-click.mp3';

    if (model.audioFeedback && model.audioFeedback.length > 0) {
      model.audioFeedback.forEach((audio) => {
        if (audio.type === 'correct') {
          correctSound = Auth.getStreamResourceUrl(audio.resourceId, 'audio_resource');
        }
        if (audio.type === 'incorrect') {
          incorrectSound = Auth.getStreamResourceUrl(audio.resourceId, 'audio_resource');
        }
        if (audio.type === 'contentObject') {
          flipSound = Auth.getStreamResourceUrl(audio.resourceId, 'audio_resource');
        }
      });
    }

    // data is an id and checked of one of the answers. We want to set the isCorrect property to true on that answer
    const answer = responseAnswer.answers.find(({ id }) => id === data.id);

    if (responseAnswer.answers.some(({ type, selected, id, locked }) => {
      return (type === data.type && selected === true && id !== data.id && !locked);
    }
    )) {
      return;
    }

    if (answer) {
      if (answer.locked === true) {
        return;
      }
      answer.selected = !answer.selected;
      if (!answer.selectedOnce) {
        answer.selectedOnce = true;
      }
      if (answer.selected) {
        audioManager.playAudio(flipSound);
      }
    }

    if (answer.type === 'prompt' && answer.selected) {
      const target = responseAnswer.answers.find(({ type, selected, locked }) => {
        return type === 'target' && selected && !locked;
      });
      if (target) {
        const correctAnswer = model.internalValidationPrompts.find(({ id }) => id === answer.id);
        if (correctAnswer && correctAnswer.text === target.id) {
          answer.locked = true;
          target.locked = true;
          if (correctSound) {
            audioManager.playAudio(correctSound);
          }
        }
      }
    }

    if (answer.type === 'target' && answer.selected) {
      const prompt = responseAnswer.answers.find(({ type, selected, locked }) => {
        return type === 'prompt' && selected && !locked;
      });
      if (prompt) {
        const correctAnswer = model.internalValidationPrompts.find(({ text }) => text === answer.id);
        if (correctAnswer && correctAnswer.id === prompt.id) {
          answer.locked = true;
          prompt.locked = true;
          if (correctSound) {
            audioManager.playAudio(correctSound);
          }
        }
      }
    }

    const promptNotLocked = responseAnswer.answers.some(({ type, selected, locked }) => {
      return (type === 'prompt' && selected && !locked);
    });

    const targetNotLocked = responseAnswer.answers.some(({ type, selected, locked }) => {
      return (type === 'target' && selected && !locked);
    });

    if (promptNotLocked && targetNotLocked) {
      if (incorrectSound) {
        audioManager.playAudio(incorrectSound);
      }
      setTimeout(() => {
        responseService.responseChangeHandler({ clearAll: true }, model.lessonElementId);
      }, 2000);
    }
  }

  getInitializedResponse = (lessonElementId, model) => {
    const ans = [];

    model.prompts.forEach((prompt) => {
      const answer = { id: prompt.id, type: 'prompt', selected: false, locked: false, selectedOnce: false };
      ans.push(answer);
    });

    model.targets.forEach((target) => {
      const answer = { id: target.text, type: 'target', selected: false, locked: false, selectedOnce: false };
      ans.push(answer);
    });

    return {
      answers: ans,
      lessonElementId,
    };
  }

  isAutoScored = (model) => {
    return false;
  }

  getCorrectAnswersText = (model) => {
    return '';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    return '';
  }

  isAnswerCorrect = (answerId, lessonElementState, model, isPracticeRunning) => {
    return true;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    return true;
  }
}

export default new ImageTextMatchFlipBehavior();
