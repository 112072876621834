import React, { useEffect, useRef } from 'react';

import utilsService from '../services/UtilsService';
import toolbarService from '../services/ToolbarService';
import lessonService from '../services/LessonService';
import Auth from '../services/AuthService';

const HtmlComponent = (props) => {
  const ref = useRef();
  const {
    ariaHidden, className, htmlStr, tabIndex, useSpan
  } = props;

  useEffect(() => {
    // render math if applicable
    if (!ref || !ref.current) {
      return;
    }
    if (window.com && window.com.wiris.js.JsPluginViewer) {
      window.com.wiris.js.JsPluginViewer.parseElement(ref.current, true);
    }
    const items = document.getElementsByClassName('ck-cartResourceItem');
    for (const item of items) {
      item.setAttribute('tabindex', 0);

      // If there is a data accomodation required check it
      const accommodationAttr = !!(item.getAttribute('data-accommodation'));
      const exclude = lessonService.excludeContentLanguage();

      if (accommodationAttr && exclude) {
        if (item.classList.contains('articlePopup')) {
          item.classList.remove('articlePopup');
          item.classList.remove('ck-cartResourceItem');
        }
      }
    }
  });

  let finalText = htmlStr;

  if (utilsService.isNullOrEmpty(finalText)) {
    finalText = '';
  }

  let classNameStr = 'html-input tRdbl';
  if (!utilsService.isNullOrEmpty(className)) {
    classNameStr += ` ${className}`;
  }

  finalText = finalText.replaceAll('contenteditable', 'content');
  finalText = finalText.replaceAll(/authKey=([^authKey=&]{1,})&/g, `authKey=${Auth.authKey}&`);

  const handleClick = async (e) => {
    if (props.onClick) {
      props.onClick(e);
      return;
    }

    toolbarService.handleContentResourceClick(e);
  };

  return (
    <>
      {(useSpan) ?
        (ariaHidden) ? (
          <span
            dangerouslySetInnerHTML={{ __html: finalText }} ref={ref}
            aria-hidden
            className={classNameStr}
            onClick={handleClick}
            tabIndex={(tabIndex !== undefined) ? tabIndex : '-1'} />
        ) : (
          <span
            dangerouslySetInnerHTML={{ __html: finalText }}
            ref={ref}
            className={classNameStr}
            onClick={handleClick}
            tabIndex={(tabIndex !== undefined) ? tabIndex : '-1'} />
        )
        :
        (ariaHidden) ? (
          <div dangerouslySetInnerHTML={{ __html: finalText }}
            ref={ref}
            aria-hidden
            className={classNameStr}
            onClick={handleClick}
            tabIndex={(tabIndex !== undefined) ? tabIndex : '-1'} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: finalText }}
            ref={ref}
            className={classNameStr}
            onClick={handleClick}
            tabIndex={(tabIndex !== undefined) ? tabIndex : '-1'} />
        )}
    </>
  );
};

export default HtmlComponent;
