import React from 'react';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { has, isNumber, isPlainObject } from 'lodash';

import { default as coreTranslations } from './coreTranslations.js';

// We need some placeholder resource and language to get i18n to initialize correctly
const resources = {
  placeholder: {
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'placeholder',
    returnObjects: true,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

// We need a deep merge function to allow overriding of specific translations while keeping others with their default core values
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

// A satellite can change its content by calling this function with any name.
// translations must be an object where the keys are the keys of SatCoreRegistry and the inner object are translations
// SatCore components can then be translated by using 'const t = this.props.t' and t('translationKey', 'defaultTranslation')
// t('translationKey', {defaultValue: 'defaultTranslation', count: 0})
export const addThemeTranslations = (lang, translations) => {
  translations = mergeDeep(coreTranslations, translations);
  for (const [namespaceKey, namespace] of Object.entries(translations)) {
    for (const key of Object.keys(namespace)) {
      if (typeof (namespace[key]) !== 'string') {
        // Things that are not strings get mutated if they are not wrapped in a function. So we wrap them in a function.
        const oldProp = namespace[key];
        if (!oldProp.wrapped) {
          namespace[key] = () => oldProp;
          namespace[key].wrapped = true;
        }
      }
    }
    i18n.addResourceBundle(lang, namespaceKey, namespace);
  }
  i18n.changeLanguage(lang);
};

// Adds the core translations
addThemeTranslations('core', {});

// Default pluralize functionality doesn't work since we use custom languages names
// Do not override context if it already exists
const addContextPluralize = (options) => {
  isPlainObject(options) && has(options, 'count') && isNumber(options.count) && !has(options, 'context') && options.count !== 1 && (options.context = 'plural');
  return options;
};

// For non-react uses a special component can be registered with a given name so that a t function can be returned
// Usage:
// import  { register } from '../i18n';
// const t = register('SomeName');
// class AnyClass {
//   someFunction = () => {
//     console.log("You can now translate using the key 'SomeName' in the translation file...", t('SomeKey', 'SomeDefaultValue'));
//   }
// }
export const register = (name) => function t(key, options) {
  addContextPluralize(options);
  let translation = i18n.t(`${name}:${key}`, options);
  if (typeof (translation) === 'function' && translation.wrapped) {
    // Then function was also modified to allow for automatic unwrapping of wrapped objects.
    translation = translation();
  }
  return translation;
};

function getDisplayName(Component) {
  return (
    Component.displayName ||
    Component.name ||
    (typeof Component === 'string' && Component.length > 0 ? Component : 'Unknown')
  );
}

// I override this i18n-react function in order to add in the wrapping feature that allows for jsx to be transfered through i18n
export function withTranslation(ns, options = {}) {
  return function Extend(WrappedComponent) {
    function I18nextWithTranslation({ forwardedRef, ...rest }) {
      const [t, i18n, ready] = useTranslation(ns, rest);
      // This is the thing that changes. It allows wrapped objects to be automatically unwrapped.
      const trans = (key, options) => {
        addContextPluralize(options);
        let translation = t(key, options);
        if (typeof (translation) === 'function' && translation.wrapped) {
          translation = translation();
        }
        return translation;
      };
      const passDownProps = {
        ...rest,
        t: trans,
        i18n,
        tReady: ready
      };
      if (options.withRef && forwardedRef) {
        passDownProps.ref = forwardedRef;
      } else if (!options.withRef && forwardedRef) {
        passDownProps.forwardedRef = forwardedRef;
      }
      return React.createElement(WrappedComponent, passDownProps);
    }

    I18nextWithTranslation.displayName = `withI18nextTranslation(${getDisplayName(
      WrappedComponent
    )})`;

    I18nextWithTranslation.WrappedComponent = WrappedComponent;

    const forwardRef = (props, ref) => React.createElement(I18nextWithTranslation, { ...props, forwardedRef: ref });

    return options.withRef ? React.forwardRef(forwardRef) : I18nextWithTranslation;
  };
}

export default i18n;
