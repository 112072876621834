import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import responseService from '../../services/ResponseService';
import speedFlag from '../../../images/summaryIcons/speedFlag.svg';
import usageFlag from '../../../images/summaryIcons/usageFlag.svg';
import '../../../css/SummaryPage.scss';
import { LessonMode } from '../../../Constants';
import { register } from '../../../i18n';

const t = register('Engagement')

const EngagementSummaryPage = observer(() => {
  const {
    lessonManager,
    responseManager,
    studentActivityManager
  } = useContext(MobXProviderContext);

  if (!lessonManager.lesson || !lessonManager.lessonOptions) {
    return (<div className='title-page-wrapper' />);
  }

  useEffect(() => {
    const activityId = (studentActivityManager && studentActivityManager.activityId) ?
      studentActivityManager.activityId : null;
    if (activityId) {
      const doAwaitFunction = async () => {
        let studentId = null;
        if (lessonManager.playerMode === LessonMode.SCORING) {
          studentId = studentActivityManager.studentId;
        }
        await responseService.fetchStudentReviewEngagementSummary(activityId, studentId);
      };
      doAwaitFunction().then();
    }
  }, [lessonManager.playerMode, studentActivityManager]);

  return (
    <div className='engagement-page-wrapper'>
      {(responseManager.studentReviewEngagementReport) && (
        <>
          <div className='title'>{t('useOfTime')}</div>
          {(!responseManager.studentReviewEngagementReport.engagement) ? (
            <div className='message'>
              <strong>{t('goodJob')}</strong><span>&nbsp;{t('learning')}</span>
            </div>
          ) : (
            <div className='flag-wrapper'>
              <div className='icon'><img alt='' src={speedFlag} /></div>
              <div className='message'>
                <strong>{t('slowDown')}</strong><span>&nbsp;{t('appears')}</span>
              </div>
            </div>
          )}
          <div className='title'>{t('useOfFeedback')}</div>
          {(responseManager.studentReviewEngagementReport.supportToolUse === 'disengaged') ? (
            <div className='flag-wrapper'>
              <div className='icon'><img alt='' src={usageFlag} /></div>
              <div className='message'>
                <strong>{t('keepTrying')}</strong><span>&nbsp;{t('appears2')}</span>
              </div>
            </div>
          ) : (
            <div className='message'>
              <strong>{t('wellDone')}</strong><span>&nbsp;{t('learning2')}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default EngagementSummaryPage;
