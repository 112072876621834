/* eslint-disable no-undef */
import { confirmDialog } from '../components/dialogs';
import toolbarManager from '../managers/ToolbarManager';
import publisherManager from '../managers/PublisherManager';
import timeManager from '../managers/TimeManager';
import lessonManager from '../managers/LessonManager';
import responseManager from '../managers/ResponseManager';
import studentActivityManager from '../managers/StudentActivityManager';
import publisherService from './PublisherService';
import responseService from './ResponseService';
import UtilsService from './UtilsService';
import lessonService from './LessonService';
import highlightAnnotationService from './HighlightAnnotationService';
import { Accommodations, GlobalFontSizes, LessonMode } from '../../Constants';
import scoringService from './ScoringService';
import StyleService from './StyleService';

export class ToolbarService {
  async exitToolHandler(data) {
    const result = await confirmDialog();
    if (result.isConfirmed) {
      if (lessonManager.playerMode === LessonMode.ACTIVITY_MODE) {
        await timeManager.stopAllLessonElementTimers();
        await responseService.saveAllResponses();
      }
      if (lessonManager.playerMode === LessonMode.SCORING) {
        await scoringService.updateTestItemScore(lessonManager.currentLessonElementId);
        await scoringService.submitTeacherComment(lessonManager.currentLessonElementId);
      }
      await UtilsService.exitPlayer();
    }
  }

  textHelpToolHandler = (button, data) => {
    if(publisherManager.hasTextHelpId) {
      const isOpen = !!button?.toggle;
      toolbarManager.setIsTextHelpOpen(isOpen);

      if (button.toggle) {
        if (!speechstream.isToolbarActive()) {
          // speechstream.toggleBar();
        }
      } else {
        if (speechstream.isToolbarActive()) {
          // speechstream.toggleBar();
        }
        // stop reading in progress.
        if (toolbarManager.textHelpApi.speechTools) {
          if (toolbarManager.textHelpApi.speechTools.getClickToSpeakState()) {
            toolbarManager.textHelpApi.speechTools.clickToSpeak();
          }
          toolbarManager.textHelpApi.speechTools.stop();
        }
      }
    } else if (publisherManager.hasWebReaderId) {
      toolbarManager.setShowWebReader(!toolbarManager.showWebReader);
    }
  }

  translateToolHandler = (button, data) => {
    toolbarManager.textHelpApi.textTools.translate();
  }

  dictionaryToolHandler = (button, data) => {
    toolbarManager.textHelpApi.textTools.dictionaryLookup();
  }

  textHelpSettingsHandler = (button, data) => {
    speechstream.toggleSettings();
  }

  colorToolHandler = (button, data) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsColorOpen(isOpen);
  }

  stickyNotesToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    if (!isOpen) {
      toolbarManager.setIsStickyNotesOpen(isOpen);
    }
    toolbarManager.setIsStickyNotesActive(isOpen);

    if (!toolbarManager.isToolOn('HAT')) {
      if (isOpen) {
        // highlightAnnotationService.showHighlights(lessonManager.currentLessonElementId);
        highlightAnnotationService.showScopeLessonElementHighlights();
      } else {
        // highlightAnnotationService.removeElementHighlights(lessonManager.currentLessonElementId);
        highlightAnnotationService.removeScopeLessonElementHighlights();
      }
    }
  }

  notepadToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsNotepadOpen(isOpen);
  }

  eliminatorToolHandler = (button, data) => {
    const isActive = !!button?.toggle;
    toolbarManager.setIsEliminatorActive(isActive);
  }

  answerFeedbackToolHandler = (button) => {
    const isActive = !!button?.toggle;
    toolbarManager.setIsToolbarAnswerFeedbackActive(isActive);

    // Now that the tool is active, when we move slides we will see the feedback. In order to see the feedback on the current slide, we need to update doFeedbackLogic with the current lessonElementId.
    const lessonElementId = lessonManager.currentLessonElementId;
    const mode = lessonManager.playerMode;

    if (!isActive) {
      // In the case where we just turned the tool off, we also need to manually reset the responseAnswer
      const state = responseManager.getLessonElementState(lessonElementId);
      const model = lessonManager.getSlideModel(lessonElementId);

      // Checking to see if we are on a multipart activity. If so, we need to loop over each child and reset the responseAnswer
      if (model && model?.isActivityPart) {
        const parentLessonElement = lessonManager.getLessonElement(model.parentActivityElementId);
        parentLessonElement?.lessonElementIds.forEach((id) => {
          const childModel = lessonManager.getSlideModel(id);
          const childState = responseManager.getLessonElementState(id);
          if (childState && childState.correctAnswerShowing) {
            childState.questionBehavior.resetStudentAnswer(childState, false);
            childState.turnOffAnswerShowing();
            if (childState.isSubmitted) {
              responseManager.scoreResponse(childModel, id);
            }
          }
        });
      } else if (state && state.correctAnswerShowing) {
        state.questionBehavior.resetStudentAnswer(state, false);
        state.turnOffAnswerShowing();
        if (state.isSubmitted) {
          responseManager.scoreResponse(model, lessonElementId);
        }
      }
    }

    responseService.loadSlide(lessonElementId, mode);
  }

  highlightAnnotationToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsHighlightAnnotationOpen(isOpen);

    if (!toolbarManager.isToolOn('StickyNotes')) {
      if (isOpen) {
        // highlightAnnotationService.showHighlights(lessonManager.currentLessonElementId);
        highlightAnnotationService.showScopeLessonElementHighlights();
      } else {
        // highlightAnnotationService.removeElementHighlights(lessonManager.currentLessonElementId);
        highlightAnnotationService.removeScopeLessonElementHighlights();
      }
    }
  }

  basicCalculatorToolHandler = (button) => {
    toolbarManager.resetCalculatorTool();
    const isOpen = !!button?.toggle;
    toolbarManager.setIsBasicCalculatorOpen(isOpen);
    toolbarManager.toggleToolOff('GraphingCalculator');
    toolbarManager.toggleToolOff('ScientificCalculator');
  }

  scientificCalculatorToolHandler = (button) => {
    toolbarManager.resetCalculatorTool();

    const isOpen = !!button?.toggle;
    toolbarManager.setIsScientificCalculatorOpen(isOpen);
    toolbarManager.toggleToolOff('BasicCalculator');
    toolbarManager.toggleToolOff('GraphingCalculator');
  }

  graphingCalculatorToolHandler = (button) => {
    toolbarManager.resetCalculatorTool();
    const isOpen = !!button?.toggle;
    toolbarManager.setIsGraphingCalculatorOpen(isOpen);
    toolbarManager.toggleToolOff('BasicCalculator');
    toolbarManager.toggleToolOff('ScientificCalculator');
  }

  customaryRulerToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsCustomaryRulerOpen(isOpen);
  }

  metricRulerToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsMetricRulerOpen(isOpen);
  }

  combinedRulerToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsCombinedRulerOpen(isOpen);
  }

  protractorToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsProtractorOpen(isOpen);
  }

  graphPaperToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsGraphPaperOpen(isOpen);
  }

  revealToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsRevealOpen(isOpen);
  }

  guidelineToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    toolbarManager.setIsGuidelineOpen(isOpen);
  }

  doButtonAction = (buttonId, lElementId = null, forceState = null) => {
    const preventOpen = this.preventToolOpen(buttonId);
    if (preventOpen) {
      return false;
    }
    const lessonElementId = (lElementId) || lessonManager.currentLessonElementId;
    toolbarManager.doButtonAction(buttonId, lessonElementId, forceState);
  }

  preventToolOpen = (buttonId) => {
    if (lessonManager.playerMode === LessonMode.ITEM_PREVIEW) {
      if (buttonId === 'TextHelp' || buttonId === 'Translate' || buttonId === 'Dictionary') {
        if (!publisherManager.textHelpId) {
          const dialogObject = {
            title: 'Item Preview Mode',
            text: 'This feature requires a subscription key.',
            showCancelButton: false,
            confirmButtonText: `${UtilsService.confirmOk()}`
          };
          confirmDialog(dialogObject);
          return true;
        }
      }
    }
    if (lessonManager.playerMode === LessonMode.PUBLISHER_PREVIEW) {
      if (buttonId === 'Logout') {
        return true;
      }
    }
    return false;
  }

  resourcesToolHandler = async (button) => {
    const isOpen = !!button?.toggle;
    if (isOpen) {
      const shouldOpen = await this.getResource(button.data, button.id);
      toolbarManager.setIsResourcesOpen(false);
      toolbarManager.setIsResourcesOpen(shouldOpen);
      if (shouldOpen) {
        // We also want to toggle off all other resource buttons
        const resourceButtonIds = Array.from(toolbarManager.buttonSettings.keys()).filter((id) => id.startsWith('Resources'));
        resourceButtonIds.forEach((id) => {
          if (id !== button.id) {
            toolbarManager.buttonSettings.get(id).toggle = false;
          }
        });
      }
    } else {
      toolbarManager.setIsResourcesOpen(false);
    }
  }

  helpToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    if (isOpen) {
      this.getHelp(button.data, button.id);
    }
    toolbarManager.setIsHelpOpen(isOpen);
  }

  instructionToolHandler = (button) => {
    const isOpen = !!button?.toggle;
    if (isOpen) {
      toolbarManager.setInstructionData(studentActivityManager.studentInstruction);
      toolbarManager.setInstructionOpenId(button.id);
    }
    toolbarManager.setIsInstructionOpen(isOpen);
  }

  toggleTools = () => {
    if (responseService.isMarkedForReview(lessonManager.currentLessonElementId)) {
      toolbarManager.toggleToolByIdValue('Bookmark', true);
    } else {
      toolbarManager.toggleToolByIdValue('Bookmark', false);
    }
  }

  bookmarkToolHandler = (buttonSetting) => {
    const { lessonElementId } = buttonSetting;
    if (lessonElementId) {
      responseService.setMarkedForReview(lessonElementId, buttonSetting.toggle);
    } else {
      buttonSetting.toggle = false;
    }
  }

  smallTextToolHandler = (buttonSetting) => {
    if (!buttonSetting.toggle) {
      toolbarManager.toggleToolByIdValue('MediumText', true);
    } else {
      toolbarManager.toggleToolByIdValue('MediumText', false);
    }
    toolbarManager.toggleToolByIdValue('LargeText', false);

    if (this.textDefaultEmpty()) {
      this.setTextDefault();
    }
    if (!buttonSetting.toggle) {
      this.setToMediumFonts();
    } else {
      this.setToSmallFonts();
    }
  }

  mediumTextToolHandler = (buttonSetting) => {
    toolbarManager.toggleToolByIdValue('SmallText', false);
    toolbarManager.toggleToolByIdValue('MediumText', true);
    toolbarManager.toggleToolByIdValue('LargeText', false);
    if (this.textDefaultEmpty()) {
      this.setTextDefault();
    }
    if (!buttonSetting.toggle) {
      this.setToMediumFonts();
    } else {
      this.setToMediumFonts();
    }
  }

  largeTextToolHandler = (buttonSetting) => {
    if (!buttonSetting.toggle) {
      toolbarManager.toggleToolByIdValue('MediumText', true);
    } else {
      toolbarManager.toggleToolByIdValue('MediumText', false);
    }
    toolbarManager.toggleToolByIdValue('SmallText', false);
    if (this.textDefaultEmpty()) {
      this.setTextDefault();
    }
    if (!buttonSetting.toggle) {
      this.setToMediumFonts();
    } else {
      this.setToLargeFonts();
    }
  }

  boldTextToolHandler = (buttonSetting) => {
    const element = document.body;
    if (buttonSetting.toggle) {
      element.classList.add('allBold');
    } else {
      element.classList.remove('allBold');
    }
  }

  setTextDefault = () => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, _value] of Object.entries(GlobalFontSizes)) {
      const currentSize = StyleService.getStyleVar(key);
      GlobalFontSizes[key] = parseInt(currentSize);
    }
  }

  textDefaultEmpty = () => {
    // if the fist item is 0 then we havent init the defaults yet
    const currentSizeInt = GlobalFontSizes['--theme-font-size'];
    return currentSizeInt === 0;
  }

  setToLargeFonts = () => {
    for (const [key, value] of Object.entries(GlobalFontSizes)) {
      const newSize = `${value + 2}px`;
      StyleService.setStyleVar(key, newSize);
    }

    const elementsWithFontSize = document.querySelectorAll('[style*="font-size"]');

    // Increase font size for each element that has a font-size style attribute.
    elementsWithFontSize.forEach((element) => {
      const currentSizeInt = GlobalFontSizes['--theme-font-size'];
      let currentSize = parseInt(element.style.fontSize, 10) || currentSizeInt;

      if (element.dataset.originalFontSize == null) {
        element.dataset.originalFontSize = currentSize;
      }
      currentSize = parseInt(element.dataset.originalFontSize, 10);

      const newSize = currentSize + 2;
      element.style.fontSize = `${newSize}px`;
    });
  }

  setToSmallFonts = () => {
    for (const [key, value] of Object.entries(GlobalFontSizes)) {
      const newSize = `${value - 2}px`;

      StyleService.setStyleVar(key, newSize);
    }

    const elementsWithFontSize = document.querySelectorAll('[style*="font-size"]');

    // Reduce font size for each element that has a font-size style attribute.
    elementsWithFontSize.forEach((element) => {
      const currentSizeInt = GlobalFontSizes['--theme-font-size'];
      let currentSize = parseInt(element.style.fontSize, 10) || currentSizeInt;

      if (element.dataset.originalFontSize == null) {
        element.dataset.originalFontSize = currentSize;
      }
      currentSize = parseInt(element.dataset.originalFontSize, 10);

      const newSize = currentSize - 2;
      element.style.fontSize = `${newSize}px`;
    });
  }

  setToMediumFonts = () => {
    for (const [key, value] of Object.entries(GlobalFontSizes)) {
      const newSize = `${value}px`;

      StyleService.setStyleVar(key, newSize);
    }

    // Set font size for each element that has a font-size style attribute back to its default font-size.
    const elementsWithFontSize = document.querySelectorAll('[style*="font-size"]');

    elementsWithFontSize.forEach((element) => {
      if (element.dataset.originalFontSize != null) {
        const newSize = parseInt(element.dataset.originalFontSize, 10);
        element.style.fontSize = `${newSize}px`;
      }
    });
  }

  setTeacherToolVisibility = (isTeacher, toolbarOptions) => {
    // This was causing both Reveal and AnswerFeedback to show up even if only one of them was turned on.
    // Change allows for them to work independently from one another.
    // toolbarManager.buttonSettings.get('AnswerFeedback').toggle = false;
    // toolbarManager.buttonSettings.get('AnswerFeedback').visible = isTeacher;
    // toolbarManager.buttonSettings.get('Reveal').toggle = false;
    // toolbarManager.buttonSettings.get('Reveal').visible = isTeacher;

    if (!toolbarOptions) {
      return;
    }
    for (const tools of toolbarOptions) {
      if (tools.type === 'Reveal') {
        toolbarManager.buttonSettings.get('Reveal').toggle = false;
        toolbarManager.buttonSettings.get('Reveal').visible = isTeacher;
      } else if (tools.type === 'AnswerFeedback') {
        toolbarManager.buttonSettings.get('AnswerFeedback').toggle = false;
        toolbarManager.buttonSettings.get('AnswerFeedback').visible = isTeacher;
      }
    }
  }

  setStudentInstructionVisibility = (hasInstruction) => {
    const settings = toolbarManager.buttonSettings.get('Instruction');
    const parentSettings = toolbarManager.buttonGroupSettings.get(settings.parentId);
    settings.toggle = false;
    settings.visible = hasInstruction;
    // Have to make sure the group is visible as well in order to see the button
    parentSettings.visible = hasInstruction;
  }

  async initToolbar(lessonOptions, toolbarOptions) {
    toolbarManager.initButtonGroupSettings(lessonOptions, toolbarOptions);
    toolbarManager.initButtonSettings(lessonOptions, toolbarOptions);
    await this.initIndividualTools();
    toolbarManager.initButtonActions();
  }

  resizeToFitOld = async (toolbarElem, widthIncreasing) => {
    // If width is decreasing: Removes tools from the toolbar until there is no overflow
    // TODO: If width is increasing: Adds tools to the toolbar until there is overflow then backs up one
    const subjectGroupLayout = toolbarManager.buttonLayout.find((group) => group.id === 'subjectGroup');
    // First, we find a starting place for the limit by getting the number of tools currently visible
    // eslint-disable-next-line array-callback-return
    let limit = subjectGroupLayout.children.reduce((numVisible, buttonOrGroup) => {
      let settings = null;
      if (buttonOrGroup.type === 'button') {
        settings = toolbarManager.buttonSettings.get(buttonOrGroup.id);
      } else if (buttonOrGroup.type === 'group') {
        settings = toolbarManager.buttonGroupSettings.get(buttonOrGroup.id);
      } else {
        console.log('Something wasn\'t a button or group', buttonOrGroup);
      }

      if (settings) {
        const isVisible = settings.visible;
        return numVisible + (isVisible ? 1 : 0);
      }
    }, 0);

    const isOverflowing = () => {
      return toolbarElem.scrollWidth > toolbarElem.clientWidth;
    };
    const waitForScrollWidthChange = (originalSize) => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (toolbarElem.scrollWidth !== originalSize) {
            clearInterval(interval);
            resolve();
          }
        }, 10);
      });
    };

    // Then we decrease by 1 until there is no overflow or until the only tool left is the ellipsis
    while (isOverflowing() && limit > 1) {
      limit--;
      const originalSize = toolbarElem.scrollWidth;
      toolbarManager.handleSubjectGroupOverflow(limit);
      await waitForScrollWidthChange(originalSize);
    }
    // At this point either there is no overflow or there is only one tool left and there is nothing more we can do
  }

  maxLimit = null;
  async resizeToFit(toolbarElem) {
    // Starts at a limit of 1 and increases the number of tools in the toolbar by 1 until it overflow. Then back up one.
    const subjectGroupLayout = toolbarManager.buttonLayout.find((group) => group.id === 'subjectGroup');
    if (this.maxLimit === null) {
      // eslint-disable-next-line array-callback-return
      this.maxLimit = subjectGroupLayout.children.reduce((numVisible, buttonOrGroup) => {
        let settings = null;
        if (buttonOrGroup.type === 'button') {
          settings = toolbarManager.buttonSettings.get(buttonOrGroup.id);
        } else if (buttonOrGroup.type === 'group') {
          settings = toolbarManager.buttonGroupSettings.get(buttonOrGroup.id);
        } else {
          console.log('Something wasn\'t a button or group', buttonOrGroup);
        }

        if (settings) {
          const isVisible = settings.visible;
          return numVisible + (isVisible ? 1 : 0);
        }
      }, 0);
    }
    let limit = 2;

    const isOverflowing = () => {
      return toolbarElem.scrollWidth > toolbarElem.clientWidth;
    };
    const waitForParentOrChildWidthChange = (initialWidths) => {
      // Waits until either the parent width or any of the children width changes
      return new Promise((resolve) => {
        let interval, timeout;
        interval = setInterval(() => {
          const currentWidths = {
            parent: toolbarElem.scrollWidth,
            children: Array.from(toolbarElem.children).map((child) => child.clientWidth)
          };
          if (currentWidths.parent !== initialWidths.parent || currentWidths.children.some((child, i) => child !== initialWidths.children[i])) {
            clearInterval(interval);
            clearTimeout(timeout);
            resolve(true);
          }
        }, 10);
        timeout = setTimeout(() => {
          clearInterval(interval);
          resolve(false);
        }, 200);
      });
    };

    toolbarManager.handleSubjectGroupOverflow(limit);
    while (!isOverflowing()) {
      if (limit >= this.maxLimit) {
        return; // We are not overflowing and we have reached the max limit so we can simply stop
      }
      limit++;
      const initialWidths = {
        parent: toolbarElem.scrollWidth,
        children: Array.from(toolbarElem.children).map((child) => child.clientWidth)
      };
      toolbarManager.handleSubjectGroupOverflow(limit);
      const expanded = await waitForParentOrChildWidthChange(initialWidths);
      if (!expanded) {
        // Then adding a new tool didn't do anything so we are actually done
        return;
      }
    }
    // At this point we are overflowing and we need to back up one
    limit--;
    toolbarManager.handleSubjectGroupOverflow(limit);
  }

  async getResource({ contentItemId, displayName, resourceType, className }, buttonId) {
    /*
    Sets the resource tool to have the correct data for the resource.
    If the resource is a bookmark, then a new tab is opened with a bookmark and this method returns false to indicate that the tool should not actually be opened.
    */
    const resource = await lessonService.fetchResourceItem(contentItemId);
    toolbarManager.setResourceType(resourceType);
    if (resourceType === 'bookmark_resource') {
      // toolbarManager.setResourceData({ url: pageUrl });
      window.open(resource.pageUrl, '_blank');
      return false;
    } else if (resourceType === 'article_resource') {
      const contentLength = UtilsService.stripTagsAndEntities(resource.articleHtml).length;
      const hasImage = resource.articleHtml.indexOf('<img') != -1;
      const paragraphCount = resource.articleHtml.split('<p').length - 1;
      toolbarManager.setResourceData({ content: resource.articleHtml, className, contentLength, hasImage, paragraphCount });
    } else if (resourceType === 'pdf_resource') {
      toolbarManager.setResourceData(resource.presignedAwsUrl);
    } else if (resourceType === 'video_resource') {
      toolbarManager.setResourceData({ fileName: resource.remoteFileName, mime: resource.mime, size: { width: parseInt(resource.width), height: parseInt(resource.height) } });
    } else if (resourceType === 'image_resource') {
      toolbarManager.setResourceData({ fileName: resource.remoteFileName, mime: resource.mime, size: { width: parseInt(resource.width), height: parseInt(resource.height) } });
    } else {
      throw new Error(`Unknown resource type ${resourceType}`);
    }
    toolbarManager.setResourceDisplayName(displayName);
    toolbarManager.setResourceOpenId(buttonId);
    return true;
  }

  handleContentResourceClick = async (e) => {
    /*
    This html can contain resources that should open when clicked. The most robust way to handle this is to react to all clicks on the html and afterward
    check if the click was on a resource. If it was, then open the resource using the dedicated toolbar service method.
    It is important to use the toolbar service so that the correct side effects are applied to the toolbar.
    */
    const { target } = e;
    let resourceLink = null;

    if (target) {
      // If the target is the 'ck-cartResourceItem' element, use the target, otherwise use the first parent that is the 'ck-cartResourceItem' element (if any).
      resourceLink = (target.className.indexOf && target.className.indexOf('ck-cartResourceItem') !== -1) ? target : target.closest('.ck-cartResourceItem');
    }

    if (resourceLink) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      return;
    }

    // Now we have the resource link element. It has a data-content-item-id that we can use to get the content item. Then we get the entity type id and use that to determine what gets rendered.
    const contentItemId = resourceLink.getAttribute('data-content-item-id');
    const { className } = resourceLink;

    if (!contentItemId) {
      return;
    }

    const contentItem = await lessonService.fetchContentItem(contentItemId);
    let entityTypeId = contentItem?.entityTypeId;

    if (!entityTypeId) {
      const image = resourceLink.querySelectorAll('img');
      if (image && image.length > 0) {
        entityTypeId = 'image_resource';
      } else {
        return;
      }
    }

    if (entityTypeId === 'audio_resource') {
      lessonService.showInlineAudioPlayer(resourceLink);
    } else {
      const displayName = entityTypeId === 'article_resource' ? resourceLink.innerHTML : 'Resource';
      const contentLength = resourceLink.getAttribute('data-content-length');
      await this.resourcesToolHandler({
        toggle: true,
        data: { contentItemId, resourceType: entityTypeId, displayName, className, contentLength },
        id: null
      });
    }
  }

  async getHelp({ contentItemId, displayName, resourceType }, buttonId) {
    const resource = await lessonService.fetchResourceItem(contentItemId);

    if (resourceType === 'article_resource') {
      toolbarManager.setHelpData(resource.articleHtml);
    } else if (resourceType === 'pdf_resource') {
      toolbarManager.setHelpData(resource.presignedAwsUrl);
    } else {
      throw new Error(`Unknown resource type ${resourceType}`);
    }

    toolbarManager.setResourceType(resourceType);
    toolbarManager.setHelpDisplayName(displayName);
    toolbarManager.setHelpOpenId(buttonId);
  }

  async initIndividualTools() {
    // This is required to get the relevant publisher details for tool licensing.
    await publisherService.fetchMyPublisherOptions();
    await highlightAnnotationService.fetchTextHighlights();
  }

  async initTextHelpApi() {
    // let buttonArray = ['hover', 'play', 'pause', 'stop', 'translate', 'dictionary'];

    // if (toolbarManager.contentToolAvailability.includes('Translate')) {
    //   buttonArray = buttonArray.filter(b => b !== 'translate');
    // }
    // if (toolbarManager.contentToolAvailability.includes('Dictionary')) {
    //   buttonArray = buttonArray.filter(b => b !== 'dictionary');
    // }

    if (speechstream) {
    //  speechstream.Loader.setToolBarButtons(buttonArray);
      try {
        const toolbarApi = await speechstream.Loader.lateLoad();
        toolbarManager.setTextHelpApi(toolbarApi);
      } catch (e) {
        console.error(e);
        lessonManager.setLoaded(-1);
      }
    }
  }

  getPersistence = (lessonElementId) => {
    return toolbarManager.getPersistence(lessonElementId);
  }

  getButtonSettings = (buttonId) => {
    return toolbarManager.getButtonSettings(buttonId);
  }

  buildToolPersistence(lessonElementId) {
    const omissionList = ['HAT', 'StickyNotes'];

    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
    if (!lessonElementState) {
      return;
    }
    const { toolPersistence } = lessonElementState;
    if (!toolPersistence) {
      return;
    }

    toolbarManager.contentToolAvailability.forEach((buttonId) => {
      const button = toolbarManager.getButtonSettings(buttonId);
      const oldTool = toolPersistence[buttonId];

      if (button && !omissionList.includes(button.id)) {
        let tool = {
          id: buttonId,
          isActive: button.toggle,
          ...button
        };
        if (oldTool) {
          tool = { ...oldTool, ...tool };
        }
        toolPersistence[buttonId] = tool;
      }
    });
  }

  loadToolPersistence = (lessonElementId, lessonElementState) => {
    if (!lessonElementState) {
      return;
    }
    const { toolPersistence } = lessonElementState;
    if (!toolPersistence) {
      return;
    }

    const that = this;

    toolbarManager.contentToolAvailability.forEach((buttonId) => {
      if (buttonId !== 'Logout' &&
        buttonId !== 'Translate' &&
        buttonId !== 'Dictionary' &&
        buttonId !== 'Audio' &&
        buttonId !== 'TranslateSettings' &&
        buttonId !== 'TextHelp' &&
        buttonId !== 'WritingChecklist' &&
        buttonId !== 'Notepad') {
        const tool = toolPersistence[buttonId];
        if (tool) {
          that.doButtonAction(buttonId, lessonElementId, tool.isActive);
        }
      }
    });
  }

  async processAccommodations() {
    let excludeBasicCalc = false;
    let excludeScientificCalc = false;
    let excludeGraphingCalc = false;
    let excludeTextToSpeech = false;
    let excludeTranslation = false;
    let excludeDictionary = false;

    // console.debug(toolbarManager.userAccommodationsOff);

    // check user accommodations first
    if (lessonManager.isLesson) {
      excludeBasicCalc = toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_BASIC_CALCULATOR);
      excludeScientificCalc = toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_SCIENTIFIC_CALCULATOR);
      excludeGraphingCalc = toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_GRAPHING_CALCULATOR);
      excludeTextToSpeech = toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_TEXT_SPEECH);
      excludeTranslation = toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_TRANSLATION);
      excludeDictionary = toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_DICTIONARY);
    } else {
      excludeBasicCalc = toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_BASIC_CALCULATOR);
      excludeScientificCalc = toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_SCIENTIFIC_CALCULATOR);
      excludeGraphingCalc = toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_GRAPHING_CALCULATOR);
      excludeTextToSpeech = toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_TEXT_SPEECH);
      excludeTranslation = toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_TRANSLATION);
    }

    // if there is activity accommodations, do those checks.

    if (excludeBasicCalc) {
      toolbarManager.addExcludedButtonType('BasicCalculator');
    }

    if (excludeScientificCalc) {
      toolbarManager.addExcludedButtonType('ScientificCalculator');
    }

    if (excludeGraphingCalc) {
      toolbarManager.addExcludedButtonType('GraphingCalculator');
    }

    if (excludeTextToSpeech) {
      toolbarManager.addExcludedButtonType('TextHelp');
    }

    if (excludeDictionary) {
      toolbarManager.addExcludedButtonType('Dictionary');
    }

    if (excludeTranslation) {
      toolbarManager.addExcludedButtonType('Translate');
    }

    // if (!await this.hasBasicCalculatorAccess()) {
    //   toolbarManager.addExcludedButtonType('BasicCalculator');
    // }

    // if (!await this.hasGraphingCalculatorAccess()) {
    //   toolbarManager.addExcludedButtonType('GraphingCalculator');
    // }

    // if (!await this.hasScientificCalculatorAccess()) {
    //   toolbarManager.addExcludedButtonType('ScientificCalculator');
    // }
  }

  readSpeakerIgnoreRead = () => {
    if (toolbarManager.textHelpApi) {
      const elems = document.querySelectorAll('[id^="DndLiveRegion-"]');
      if (elems && elems.length > 0) {
        for (const el of elems) {
          el.setAttribute('data-ignore', '1');
        }
      }
    }
  }
  // async hasBasicCalculatorAccess() {

  //   if (lessonManager.lesson.entityType === 'lesson') {
  //     if (toolbarManager.activityAccommodationsOff.includes(Accommodations.LESSON_BASIC_CALCULATOR) &&
  //     toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_BASIC_CALCULATOR)) {
  //       return false;
  //     }
  //   } else {
  //     if (toolbarManager.activityAccommodationsOff.includes(Accommodations.ASSESSMENT_BASIC_CALCULATOR) &&
  //     toolbarManager.userAccommodationsOff.includes(Accommodations.ASSESSMENT_BASIC_CALCULATOR)) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  // async hasScientificCalculatorAccess() {
  //   if (toolbarManager.activityAccommodationsOff.includes(Accommodations.LESSON_SCIENTIFIC_CALCULATOR) &&
  //   toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_SCIENTIFIC_CALCULATOR)) {
  //     return false;
  //   }
  //   return true;
  // }

  // async hasGraphingCalculatorAccess() {
  //   if (toolbarManager.activityAccommodationsOff.includes(Accommodations.LESSON_GRAPHING_CALCULATOR) &&
  //   toolbarManager.userAccommodationsOff.includes(Accommodations.LESSON_GRAPHING_CALCULATOR)) {
  //     return false;
  //   }
  //   return true;
  // }
}
export default new ToolbarService();
