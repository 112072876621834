import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/Multiselect.scss';
import { LessonMode } from '../../../Constants';

import questionService from '../../services/QuestionService';
import responseService from '../../services/ResponseService';
import utilsService from '../../services/UtilsService';

import { useViewportSize } from '../../../hooks/useViewportSize';

import FeedbackIcon from '../FeedbackIcon';
import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import OptionalImage from '../OptionalImage';

import { confirmDialog } from '../dialogs';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const Multiselect = observer(({ lessonElementId }) => {
  // Initialize state and get question model and lessonState
  const {
    lessonManager,
    responseManager,
    toolbarManager,
    questionFeedbackManager
  } = useContext(MobXProviderContext);

  const { width: viewportWidth } = useViewportSize();

  const model = lessonManager.getSlideModel(lessonElementId);
  const lessonElementState = responseManager.getLessonElementState(lessonElementId);
  const feedbackState = questionFeedbackManager.getUiState(lessonElementId);

  const { isEliminatorActive } = toolbarManager;

  const readOnly = (lessonElementState && lessonElementState.readOnly)
    || (feedbackState && feedbackState.showAnswers)
    || lessonManager.isReadOnly;

  const { showAnswers, showAnswerFeedback } = feedbackState;
  const alignRadioSymbolTop = model.anserTopJustify;

  const currentResponseAnswer = (lessonElementState) ? lessonElementState.currentResponseAnswer : null;
  const randomize = model.isRandomized;
  const processedAnswers = (!lessonElementState) ? [] : randomize ? utilsService.shuffleArrayForUser([...model.answers], model.questionText) : model.answers;
  // The currently selected answers are the elements of currentResponseAnswer.answers that have isCorrect==true
  const selectedAnswerMap = (!lessonElementState) ? [] : currentResponseAnswer.answers.reduce((acc, answer) => {
    acc[answer.id] = answer.isCorrect;
    return acc;
  }, {});

  const eliminatedAnswerMap = (!lessonElementState) ? [] : currentResponseAnswer.answers.reduce((acc, answer) => {
    acc[answer.id] = answer.isEliminated;
    return acc;
  }, {});

  let correctAnswerMap = [];
  if (lessonElementState && model.validation && model.validation.answers) {
    correctAnswerMap = model.validation.answers.reduce((acc, answer) => {
      acc[answer.id] = answer.isCorrect;
      return acc;
    }, {});
  }

  const optionNames = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const handleAnswerChange = async (id, newState, lessonElementId) => {
    if (readOnly) {
      return;
    }
    const currentlySelected = currentResponseAnswer.answers.filter(({ isCorrect }) => isCorrect).length;
    if (!model.allowSelectAll && !model.unscored && newState && currentlySelected >= model.correctAnswerCount) {
      // Then we are trying to select an answer when we already have the maximum number of answers selected
      await confirmDialog({
        title: `${utilsService.maxSelected()}`,
        text: `${utilsService.deselect()}`,
        showCancelButton: false,
        confirmButtonText: `${utilsService.confirmOk()}`
      });
    } else {
      await responseService.responseChangeHandler({
        action: isEliminatorActive ? 'eliminate' : 'select',
        id,
        checked: newState
      }, lessonElementId);

      if (isEliminatorActive) {
        toolbarManager.addContentToolUsage('Eliminator');
      }
    }
  };

  // Create handlers for state change
  const handleClick = (data) => {
    // Get the value of the clicked checkbox and whether it is checked or not
    const { value, checked } = data.target;
    handleAnswerChange(value, checked, lessonElementId);
  };

  const handleKeyPress = (event) => {
    // If space was pressed, select the value of the element that spawned the event
    // If an arrow key was pressed, select the next or previous element

    // We have actually selected the parent of the parent so we need to find the input inside of it.
    const getTargetValue = (target) => {
      const checkbox = target.getElementsByTagName('input')[0];
      if (checkbox) {
        return [checkbox.value, checkbox.checked];
      }
      return ['', false];
    };

    // One child of event.target is the radio button which has a value of the answer id
    const [checkboxId, checked] = getTargetValue(event.target);
    if (checkboxId == null) {
      // If no radio button was selected, buttons will be handled by the browser without intervention
      return;
    }
    if (event.code === 'Space') {
      // Set the answer id to the selected radio button
      handleAnswerChange(checkboxId, !checked, lessonElementId);
    }
  };

  useStyleEvents(lessonElementId);

  return (
    <div className='multi-select-question'>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='test-item-question' id={`test-item-question-${lessonElementId}`}>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.questionText} />
      </div>
      <OptionalImage model={model} runtime={true} />
      <div aria-labelledby={`test-item-question-${lessonElementId}`}
        className={`test-item-answers${(model.layout === 'grid') ? ' grid' : ''}${(model.layout === 'grid') ? ` col_${model.gridColumnCount}` : ''}`}
        style={questionService.getGenericMultichoiceStyleObjs({ model, viewportWidth })?.answerContainerStyle}
      >
        {
          processedAnswers.map(({ id, text }, index) => {
            const isSelected = selectedAnswerMap[id];
            const isCorrectAnswer = (Object.values(correctAnswerMap).length > 0) ? correctAnswerMap[id] : '';
            const isEliminated = eliminatedAnswerMap[id];
            const symbol = optionNames[index];
            let visualClass = '';
            if (showAnswers || showAnswerFeedback) {
              if (isCorrectAnswer) {
                visualClass = 'correct';
              } else {
                visualClass = 'incorrect';
              }
            }

            const allowIsCorrectOverride = toolbarManager.isToolbarAnswerFeedbackActive;
            const isCorrectOverride = isSelected && isCorrectAnswer;

            return (
              <label
                key={`${id}_${lessonElementId}`}
                aria-checked={isSelected} className={`radio-container ${isEliminated ? 'eliminated' : ''}`}
                htmlFor={`${id}_${lessonElementId}`} onKeyDown={handleKeyPress}
                role='checkbox'
                style={questionService.getGenericMultichoiceStyleObjs({ model, viewportWidth })?.answerDivStyle}
                tabIndex={readOnly ? -1 : 0}>
                <div className='radio-text-container'>
                  <input checked={isSelected} disabled={readOnly} id={`${id}_${lessonElementId}`} onChange={handleClick}
                    tabIndex='-1'
                    type='checkbox' value={id} />
                  <label className={`checkbox-symbol ${isSelected ? 'selected' : ''} ${visualClass} ${readOnly ? 'disabled' : ''} ${alignRadioSymbolTop ? 'align-top' : ''}`}
                    htmlFor={`${id}_${lessonElementId}`}>{symbol}
                  </label>
                  <label htmlFor={`${id}_${lessonElementId}`}><HtmlComponent htmlStr={text} /></label>
                  {((showAnswers || showAnswerFeedback) || (allowIsCorrectOverride && isCorrectOverride)) && (
                    <div className='correct-answer-symbol'>
                      <FeedbackIcon
                        answerId={id}
                        isCorrectOverride={allowIsCorrectOverride ? isCorrectOverride : undefined}
                        lessonElementId={lessonElementId} />
                    </div>
                  )}
                </div>
              </label>
            );
          })
        }
      </div>
    </div>
  );
});
export default Multiselect;
