import lessonManager from '../managers/LessonManager';
import responseManager from '../managers/ResponseManager';
import toolbarManager from '../managers/ToolbarManager';
import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import styleService from './StyleService';

export default class TextQuestionUtilsService {
  static decorateFeedbackText = (lessonElementState, lessonElementId) => {
    const model = lessonManager.getSlideModel(lessonElementId);
    const thisContainerId = `${lessonElementId}-item-view`;
    const thisContainer = document.getElementById(thisContainerId);
    if ((lessonElementState && lessonElementState.isSubmitted) || toolbarManager.isToolbarAnswerFeedbackActive) {
      const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
      const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;

      if (feedbackState && showAnswerFeedback) {
        if (thisContainer) {
          const rangeElements = thisContainer.getElementsByClassName('range');
          for (const elem of rangeElements) {
            elem.classList.remove('correct');
            elem.classList.remove('incorrect');
          }
          for (const elem of rangeElements) {
            const thisId = elem.getAttribute('data-id');
            // check for correct and incorrect and mark accordingly.
            if (elem.classList.contains('selected')) {
              if (model.validation?.correctAnswers.includes(thisId)) {
                elem.classList.add('correct');
              }
              if (model.validation?.incorrectAnswers.includes(thisId)) {
                elem.classList.add('incorrect');
              }
            }
          }
        }
      }
    } else {
      if (thisContainer) {
        const rangeElements = thisContainer.getElementsByClassName('range');
        for (const elem of rangeElements) {
          elem.classList.remove('correct');
          elem.classList.remove('incorrect');
          // elem.classList.remove('selected');
        }
      }
    }
  }

  static setCorrectAnswersText = (model, responseAnswer) => {
    const lessonElementId = lessonManager.currentLessonElementId;
    model = model || lessonManager.getSlideModel(lessonElementId);
    responseAnswer = responseAnswer || responseManager.getCurrentResponseAnswer(lessonElementId);

    const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
    const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;

    if (feedbackState && showAnswerFeedback) {
      const thisContainerId = `${lessonElementId}-item-view`;
      const thisContainer = document.getElementById(thisContainerId);
      if (thisContainer) {
        const rangeElements = thisContainer.getElementsByClassName('range');
        for (const elem of rangeElements) {
          elem.classList.remove('correct');
          elem.classList.remove('incorrect');
          elem.classList.remove('selected');
        }
        for (const elem of rangeElements) {
          const thisId = elem.getAttribute('data-id');
          // check for correct and incorrect and mark accordingly.
          if (thisId && model.validation?.correctAnswers?.includes(thisId)) {
            elem.classList.add('selected');
            elem.classList.add('correct');
          }
        }
      }
    }
  }

  static resetStudentAnswersText = (responseAnswer) => {
    const lessonElementId = lessonManager.currentLessonElementId;
    const model = lessonManager.getSlideModel(lessonElementId);
    responseAnswer = responseAnswer || responseManager.getCurrentResponseAnswer(lessonElementId);

    const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
    const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;

    if (feedbackState && showAnswerFeedback) {
      const thisContainerId = `${lessonElementId}-item-view`;
      const thisContainer = document.getElementById(thisContainerId);
      if (thisContainer) {
        const rangeElements = thisContainer.getElementsByClassName('range');
        for (const elem of rangeElements) {
          elem.classList.remove('correct');
          elem.classList.remove('incorrect');
          if (!responseAnswer.studentAnswers.includes(elem.getAttribute('data-id'))) {
            elem.classList.remove('selected');
          }
        }
        for (const elem of rangeElements) {
          const thisId = elem.getAttribute('data-id');

          // set the student answer
          if (responseAnswer.studentAnswers.includes(thisId)) {
            elem.classList.add('selected');
          }

          // check for correct and incorrect and mark accordingly.
          if (elem.classList.contains('selected')) {
            if (model.validation?.correctAnswers.includes(thisId)) {
              elem.classList.add('correct');
            }
            if (model.validation?.incorrectAnswers.includes(thisId)) {
              elem.classList.add('incorrect');
            }
          }
        }
      }
    }
  }

  static resetStudentAnswersTextHotText = (responseAnswer) => {
    const lessonElementId = lessonManager.currentLessonElementId;
    const model = lessonManager.getSlideModel(lessonElementId);
    responseAnswer = responseAnswer || responseManager.getCurrentResponseAnswer(lessonElementId);

    const feedbackState = questionFeedbackManager.getUiState(lessonElementId);
    const showAnswerFeedback = (feedbackState) ? feedbackState.showAnswerFeedback : false;

    if (feedbackState && showAnswerFeedback) {
      const thisContainerId = `${lessonElementId}-item-view`;
      const thisContainer = document.getElementById(thisContainerId);
      if (thisContainer) {
        const rangeElements = thisContainer.getElementsByClassName('range');
        for (const elem of rangeElements) {
          elem.classList.remove('correct');
          elem.classList.remove('incorrect');
          elem.classList.remove('selected');
          if (responseAnswer.studentAnswers.includes(elem.getAttribute('data-id'))) {
            elem.classList.add('selected');
          }
        }

        for (const elem of rangeElements) {
          const thisId = elem.getAttribute('data-id');
          // check for correct and incorrect and mark accordingly.
          if (elem.classList.contains('selected')) {
            if (model.validation?.correctAnswers.includes(thisId)) {
              elem.classList.add('correct');
            }
            if (model.validation?.incorrectAnswers.includes(thisId)) {
              elem.classList.add('incorrect');
            }
          }
          // TODO removed because it was causing incorrect answer feedback to show when show my answers was clicked.
          // if (!isPracticeRunning && omittedList.indexOf(thisId)>-1) {
          //   elem.classList.add('incorrect');
          // }
        }
      }
    }
  }

  static getFeedbackIconOptionsForDecoratingTextInputs = ({
    feedbackIconSvgTransform
  } = {}) => {
    const { getStyleVar } = styleService;
    return {
      feedbackIconStyleObj: {
        background: getStyleVar('--feedback-icon-text-question-background'),
        border: getStyleVar('--feedback-icon-text-question-border'),
        borderRadius: getStyleVar('--feedback-icon-text-question-border-radius')
      },
      feedbackIconSvgHeight: getStyleVar('--feedback-icon-text-question-svg-height'),
      feedbackIconSvgWidth: getStyleVar('--feedback-icon-text-question-svg-width'),
      feedbackIconSvgTransform: feedbackIconSvgTransform || getStyleVar('--feedback-icon-text-question-svg-transform')
    };
  }
}
