import { renderToStaticMarkup } from 'react-dom/server';

import classNames from 'classnames';

import lessonManager from '../managers/LessonManager';

import clozeMathService from './ClozeMathService';
import htmlService from './HtmlService';
import playerService from './PlayerService';
import promptService from './PromptService';
import responseService from './ResponseService';
import textQuestionUtilsService from './TextQuestionUtilsService';
import utilsService from './UtilsService';
import styleService from './StyleService';

import FeedbackIcon from '../components/FeedbackIcon';
import HtmlComponent from '../components/HtmlComponent';
import RichTextEditor from '../components/RichTextEditor';
import { ContentType } from '../../Constants';

export default class ClozeTypingService {
  /** @param {import('html-react-parser').DOMNode} domNode */
  static renderClozeTypingBlankSection = (domNode, {
    lessonElementId,
    lessonElementState,
    model,
    noSubmit,
    readOnly,
    toolbar,
    uiState,
    userInputMap,
    inputSize
  } = {}) => {
    const isHtml = model.responseFormat === 'html';
    const isMath = model.responseFormat === 'math';
    const isNumeric = model.responseFormat === 'numeric';
    const isText = model.responseFormat === 'text';

    const dataId = domNode.attribs['data-id'];
    const showAnswerFeedback = !noSubmit ? !!uiState?.showAnswerFeedback : false;

    let nodeClassNames = domNode.attribs['class'];
    const noInput = ((nodeClassNames.indexOf('fakeRange') >= 0) && model.type === ContentType.CLOZE_SPELLING.type);

    nodeClassNames = classNames(nodeClassNames, {
      correct: false, // we do not want students seeing this className in the dev tools
      feedback: showAnswerFeedback,
      incorrect: false // we do not want students seeing this className in the dev tools
    }, `${model.responseFormat || ''}`);

    const { stripWrappingParagraphTags } = utilsService;

    const responseAnswerPrompt = promptService.getCurrentPrompt({
      dataId,
      lessonElementState,
      model,
      promptType: 'responseAnswerPrompt'
    });

    const userInputText = responseAnswerPrompt?.text?.trim()/* || clozeText */;

    if (userInputText) {
      userInputMap?.set(dataId, {
        dataId,
        text: userInputText
      });
    }

    let currentUserInputValue = lessonElementState?.currentResponseAnswer?.userInputMap?.get(dataId)?.text ||
      userInputMap?.get(dataId)?.text || userInputText || '';

    if (model?.responseFormat === 'math') {
      currentUserInputValue = stripWrappingParagraphTags(currentUserInputValue);
    }

    const { playerMode } = lessonManager;

    let ckeditorInstance, modelValidationPrompt;
    if (isMath) {
      ckeditorInstance = htmlService.getHiddenCkeditorInstanceFromDOM();
      modelValidationPrompt = promptService.getCurrentPrompt({ dataId, model });
    }

    const styleStr = domNode.attribs['style'] || '';
    const styleObj = styleService.convertStrToStyleObj(styleStr);

    let maxClozeTypingWidth = styleService.getStyleVar('--theme-cloze-blank-ckeditor-max-width');
    maxClozeTypingWidth = +(utilsService.stripNonNumeric(maxClozeTypingWidth) || 'NaN');
    maxClozeTypingWidth = !isNaN(maxClozeTypingWidth) ? maxClozeTypingWidth : undefined;

    // we need to consider a FIB typing prompt as 'custom' if it is allowed to wrap
    // ---
    // the native input element we typically use for 'text' and 'numeric' does not support
    // text wrapping out-of-the-box
    // ---
    // having a custom max-width gives 'FIB typing prompts' the possibility to wrap
    // so for that case, we need to always use CKEditor
    const isCustomBlankSection = !!maxClozeTypingWidth;

    /**
     * intended to be used when `isCustomBlankSection` is `false` and `model.responseFormat` is `'text'` or `'numeric'`
     */
    const renderBlankSectionTypingTextInput = () => {
      const MIN_CLOZE_INPUT_SIZE = 7;
      const finalSize = (inputSize) || MIN_CLOZE_INPUT_SIZE;
      const limit = (finalSize === 1 && model.type === ContentType.CLOZE_SPELLING.type);
      return (
        <input className='cloze-response'
          disabled={showAnswerFeedback || readOnly}
          maxLength={(limit) ? '1' : ''}
          onChange={(event) => responseService.textChangeHandler(
            event, lessonElementId, { dataId, lessonElementState }
          )}
          readOnly={showAnswerFeedback || readOnly}
          size={currentUserInputValue?.length > finalSize ? currentUserInputValue.length : finalSize}
          style={{
            minWidth: styleObj?.width || 'fit-content',
            minHeight: styleObj.height || 'fit-content'
          }}
          type='text'
          value={currentUserInputValue || ''} />
      );
    };

    /**
     * intended to be used when `isCustomBlankSection` is `true` or when `model.responseFormat` is `'html'`
     */
    const renderBlankSectionTypingTextCkeditor = () => {
      return (
        (
          <RichTextEditor readOnly={readOnly}
            // additionalStyles={{
            //   maxWidth: maxClozeTypingWidth || undefined
            // }}
            data={currentUserInputValue}
            dataId={dataId}
            disabled={showAnswerFeedback || readOnly}
            forceNoToolbar={!isHtml}
            lessonElementId = {lessonElementId}
            onChange={(data = '', { editor } = {}) => {
              if (isText) {
                const strippedData = utilsService.stripTagsAndEntities(data) || '';
                if (utilsService.hasHtmlTags(utilsService.stripWrappingParagraphTags(data))) {
                  // update ckeditor data to wipe out html tags & entities
                  editor.setData(strippedData);
                }
              } else if (isNumeric) {
                const strippedData = utilsService.stripNonNumeric(data) || '';
                if (stripWrappingParagraphTags(strippedData) !== stripWrappingParagraphTags(data)) {
                  // update ckeditor data to wipe out non-numeric characters
                  editor.setData(strippedData);
                }
              }
              responseService.textChangeHandler(
                data, lessonElementId, { dataId, isCustomBlankSection, lessonElementState }
              );
            }}
            skipLaunchMathImmediately
            tabIndex={-1}
            toolbar={isHtml ? toolbar : []} />
        )
      );
    };

    /**
     * intended to be used when `model.responseFormat` is `'math'`
     */
    const renderBlankSectionTypingTextMath = () => {
      const handleActivateBlankSectionTypingTextMath = (_event) => {
        if (!playerService.isReadOnly(lessonElementId)) {
          ckeditorInstance.dataId = dataId || null;
          ckeditorInstance.lessonElementId = dataId ? lessonElementId : null;
          ckeditorInstance.modelPrompt = dataId ? modelValidationPrompt : null;
          ckeditorInstance.responseAnswerPrompt = dataId ? responseAnswerPrompt : null;
          ckeditorInstance.setData(currentUserInputValue ||
            renderToStaticMarkup(clozeMathService.renderClozeMathBlankPlaceholder(dataId, model)));
          ckeditorInstance.execute('selectAll');
          ckeditorInstance.execute('MathType', {
            integration: window.currentWirisInstanceForHiddenCkeditor
          });
        }
      };
      return (
        <div className='cloze-response cloze-div-readonly-input'
          onClick={handleActivateBlankSectionTypingTextMath}
          onKeyUp={(event) => {
            if (event?.key === 'Enter' || event?.key === ' ') {
              handleActivateBlankSectionTypingTextMath(event);
            }
          }}
          tabIndex={0}>
          <HtmlComponent htmlStr={currentUserInputValue} />
        </div>
      );
    };

    let blankContent = '';
    if (domNode.children[0]) {
      blankContent = domNode.children[0]['data'];
    }
    // MAIN starting logic for renderClozeTypingBlankSection()
    return (
      <div key={dataId}
        className={classNames('cloze-blank-section cloze-section', {
          // TODO remove // editorClassName
          'cloze-blank-math-section': isMath
        }, nodeClassNames)}
        data-id={dataId}>
        {(!noInput) ? (
          <>
            <div className={classNames('cloze-section-input-wrapper', 'cloze-typing-section-input-wrapper', {
              'cloze-typing-custom-input-wrapper': isCustomBlankSection,
              'empty-input': !currentUserInputValue,
            }, nodeClassNames, playerMode)}>
              {model.numberedBlanks && <div className='list-number' />}
              {(isCustomBlankSection || isHtml) && !isMath && renderBlankSectionTypingTextCkeditor()}
              {!isCustomBlankSection && !isHtml && !isMath && renderBlankSectionTypingTextInput()}
              {isMath && renderBlankSectionTypingTextMath()}
            </div>
            <FeedbackIcon
              {...lessonElementState.currentResponseAnswer}
              {...textQuestionUtilsService.getFeedbackIconOptionsForDecoratingTextInputs()}
              answerId={dataId} />
          </>
        ) : <span>{blankContent}</span>}
      </div>
    );
  };
}
