import React, { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import useWirisParser from '../hooks/useWirisParser';
import { LessonMode } from '../Constants';
import FullpageLoader from '../lessonPlayer/components/FullpageLoader';
import LessonElementList from '../manuscript/LessonElementList';
import useLessonInit from '../hooks/useLessonInit';

import '../css/Manuscript.scss';
import HtmlComponent from '../lessonPlayer/components/HtmlComponent';

const Header = ({ contentItem, lessonOptions }) => {
  if (!contentItem) {
    return null;
  }

  if (!lessonOptions) {
    lessonOptions = {};
  }

  document.title = contentItem.name;

  return (
    <>
      <div className='label-value-row'>
        <div>Content Name:</div>
        <div>
          {contentItem.name}
        </div>
      </div>
      <div className='label-value-row'>
        <div>Resource Type:</div>
        <div>{contentItem.entityType}</div>
      </div>
      <div className='label-value-row'>
        <div>Lesson Name:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={lessonOptions.title} /></div>
      </div>
      <div className='label-value-row'>
        <div>Lesson Subtitle:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={lessonOptions.subtitle} /></div>
      </div>
    </>
  );
};

const LessonOptions = ({ contentItem, lesson, lessonOptions, publisherTheme, isManuscriptShort }) => {
  if (!contentItem) {
    return null;
  }

  if (!lessonOptions) {
    return null;
  }

  return (
    <>
      {!isManuscriptShort &&
        <div className='top-label'>Lesson Options:</div>}
      <div className='element-container'>
        {!isManuscriptShort && (
          <>
            <div className='label-value-row'>
              <div>Theme:</div>
              <div>{publisherTheme && publisherTheme.name}</div>
            </div>
            <div className='label-value-row'>
              <div>Title Page:</div>
              <div>{(lessonOptions.hideTitlePage) ? 'OFF' : 'ON'}</div>
            </div>
            <div className='label-value-row'>
              <div>Summary Page:</div>
              <div>{(lessonOptions.hasSummaryPage) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Item Number Banner:</div>
              <div>{(lessonOptions.hasItemNumberBanner) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Custom Numbering:</div>
              <div>{(lessonOptions.customNumbering) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Section Banner:</div>
              <div>{(lessonOptions.hasSectionBanner) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Navigate to Items Within Activities:</div>
              <div>{(lessonOptions.navigateWithinActivity) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Force Landscape Mode On Mobile:</div>
              <div>{(lessonOptions.forceLandscapeOnMobile) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Question Dependency:</div>
              <div>{(lessonOptions.itemFlow && lessonOptions.itemFlow.sequentialItemDependency) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Highlights and Annotations:</div>
              <div>{(lessonOptions.hasHat) ? 'ON' : 'OFF'}</div>
            </div>
            <div className='label-value-row'>
              <div>Test Item Feedback:</div>
              <div>{lessonOptions.testItemFeedback}</div>
            </div>
            <div className='label-value-row'>
              <div>Test Item Correct Feedback:</div>
              <div>{lessonOptions.correctFeedback}</div>
            </div>
            <div className='label-value-row'>
              <div>Test Item Incorrect Feedback:</div>
              <div>{lessonOptions.incorrectFeedback}</div>
            </div>
            <div className='label-value-row'>
              <div>Toolbar:</div>
              <div>{(lessonOptions.hasToolbar) ? 'ON' : 'OFF'}</div>
            </div>
            {lessonOptions.hasToolbar && (
              <>
                <div className='heavy-label'>Tools:</div>
                <ul>
                  {lessonOptions.tools && lessonOptions.tools.map((tool) => {
                    return (
                      <li key={tool.type}>{tool.label}</li>
                    );
                  })}
                </ul>
              </>
            )}
          </>
        )}
        <div className='label-value-row'>
          <div>Lesson Name:</div>
          <div className='ckeditor-text'><HtmlComponent htmlStr={lessonOptions.title} /></div>
        </div>
        <div className='label-value-row'>
          <div>Lesson Subtitle:</div>
          <div className='ckeditor-text'><HtmlComponent htmlStr={lessonOptions.subtile} /></div>
        </div>
        {!isManuscriptShort && (
          <div className='label-value-row'>
            <div>Duration:</div>
            <div>{lesson.duration}</div>
          </div>
        )}
      </div>
    </>
  );
};

const Manuscript = observer(() => {
  const {
    userManager, lessonManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const [message, setMessage] = useState(null); // eslint-disable-line no-unused-vars

  const manuscriptRef = useRef();
  useWirisParser(manuscriptRef);

  useLessonInit(LessonMode.MANUSCRIPT);

  useEffect(() => {
    // add circled numbers for cloze blanks
    for (const id of lessonManager.clozeIds) {
      const blanks = document.querySelectorAll(`#_${id} span[data-id]:not([data-type='fakeRange']`);
      if (blanks) {
        for (let i = 0; i < blanks.length; i++) {
          const elem = blanks[i];
          elem.innerHTML = `
            <svg height="20" width="20">
              <circle cx="10" cy="10" r="9" stroke="#666" stroke-width="2" fill="none" />
              <text x="10" y="10" fill="#000" style="font-size: 12px; font-weight: bold;" text-anchor="middle" alignment-baseline="central">
                ${i + 1}
              </text>
            </svg>`;
        }
      }
    }
  });

  return (
    <>
      {lessonManager.isLoaded ? (
        <div ref={manuscriptRef} className='manuscript true-manuscript'>
          <Header
            contentItem={lessonManager.lessonContentItem}
            lessonOptions={lessonManager.lessonOptions}
          />
          {(lessonManager.lessonOptions && (
            <>
              <hr />
              <LessonOptions
                contentItem={lessonManager.lessonContentItem}
                isManuscriptShort={lessonManager.isManuscriptShort}
                lesson={lessonManager.lesson}
                lessonOptions={lessonManager.lessonOptions}
                publisherTheme={lessonManager.publisherTheme}
              />
            </>
          )
          )}
          {message &&
          <div><span>Message: </span><span>{message}</span></div>}
          <LessonElementList
            lessonElement={lessonManager.rootObject}
          />
        </div>
      ) : <FullpageLoader />}
    </>
  );
});
export default Manuscript;
