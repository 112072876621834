import * as musicMetadataParser from 'music-metadata-browser';

import { ContentType, WebmTrackType } from '../../Constants';

import audioManager from '../managers/AudioManager';

export default class AudioService {
  /**
   * Parse a given `blob` to determine if it only has audio (and not video) characteristics.
   *
   * This method might technically work for identifying non-webm media as well,
   * but would need to be tested more thoroughly to confirm
   */
  static isWebmAudioResource = async (blob) => {
    try {
      const metadata = await musicMetadataParser.parseBlob(blob);

      const trackInfo = metadata?.format?.trackInfo || [];

      return (trackInfo || []).every((track = {}) => {
        return track.type === WebmTrackType.AUDIO || (
          !!track.audio && !track.video
        );
      });
    } catch (error) {
      return false;
    }
  }

  static getCustomAudioLayoutLabel = ({
    model,
    audioLabelPrefix = ''
  } = {}) => {
    if (this.isDividedLayoutAudioQuestion(model)) {
      audioLabelPrefix = audioLabelPrefix ? `${audioLabelPrefix}-divided-layout-child-` : 'divided-layout-child-';
      return `${audioLabelPrefix}${model.dividedLayoutChildWidth || 'large'}`;
    } else {
      return '';
    }
  }

  static isDividedLayoutAudioQuestion = (model) => {
    return model?.type === ContentType.AUDIO_QUESTION.type && model?.layout === 'divided';
  }

  static fetchAudioTranscriptText = async (transcriptInfo) => {
    transcriptInfo = transcriptInfo || audioManager.selectedAudioTranscriptOption;
    return fetch(transcriptInfo.remoteUrl, {
      headers: {
        'Content-Type': 'text/html'
      },
      method: 'GET',
      redirect: 'follow'
    }).then((response) => {
      return response.text();
    });
  }
}
