import React from 'react';
import Auth from '../../../services/AuthService';

const AudioWidget = (props) => {
  const { model } = props;
  model.entityTypeId = model.entityTypeId || 'audio_resource';

  const display = (model.display) ? model.display : 'visible';
  const audioUrl = Auth.getStreamResourceUrl(model.resourceId, model.entityTypeId);

  return (
    <audio className={`media  audio ${display}`} controls id={model.id} style={{ width: `${model.width}px`, height: `${model.height}px` }}>
      <source src={audioUrl} type={`audio/${model.mediaTyp}`} />
      Your browser does not support the audio element.
    </audio>
  );
};

export default AudioWidget;
