import { renderToStaticMarkup } from 'react-dom/server';
import parse from 'html-react-parser';

import { toJS } from 'mobx';

import {
  ContentType, LessonMode, PartialScoringType, QuestionFeedbackState
} from '../../Constants';

import lessonManager from '../managers/LessonManager';
import userManager from '../managers/UserManager';

import questionFeedbackManager from '../managers/QuestionFeedbackManager';
import questionService from '../services/QuestionService';
import UtilsService from '../services/UtilsService';

import { Behavior } from './Behavior';

export class CategorizeBehavior extends Behavior {
  checkForValidResponse = (response) => {
    // Valid if any of the prompts has an answer
    return Boolean(response.prompts && Object.values(response.prompts).some((answers) => answers.length > 0));
  }

  isAutoScored = (model) => {
    return ContentType.getAutoscore(model.type) && !model.survey && !model.unscored;
  }

  setCorrectAnswer = (state, model) => {
    if (!model?.validation?.prompts) {
      return;
    }

    const modelValidationPrompts = toJS(model.validation.prompts);

    for (const cell of Object.values(modelValidationPrompts)) {
      for (const answer of cell) {
        if (answer.imageUrl && answer.imageUrl.includes('authKey')) {
          answer.imageUrl = UtilsService.swapAuthKey(answer.imageUrl, userManager.authKey);
        }
        if (answer.text) {
          answer.text = renderToStaticMarkup(parse(answer.text, {
            replace: (domNode) => {
              if (domNode.name === 'img' && domNode.attribs?.src && domNode.attribs.src.includes('authKey')) {
                domNode.attribs.src = UtilsService.swapAuthKey(domNode.attribs.src, userManager.authKey);
              }
            }
          }));
        }
      }
    }

    const temp = {
      prompts: UtilsService.safeMobxClone(modelValidationPrompts || []),
      cellPrompts: UtilsService.safeMobxClone(modelValidationPrompts || []),
      bankPrompts: [],
      isScored: false,
      isScoredCorrect: false,
      lessonElementId: model.lessonElementId
    };
    state.setCurrentResponse(UtilsService.safeMobxClone(temp));
  }

  resetStudentAnswer = (state, validation) => {
    state.setCurrentResponse(UtilsService.safeMobxClone(state.cachedResponseAnswer));
  }

  getScore = (responseAnswer, model) => {
    const correctPrompts = model.validation?.prompts;
    if (correctPrompts == null) {
      return 0;
    }

    // First, we need to know all the prompt ids visible to the student. To do this we need to iterate over all cells and banks in the responseAnswer
    const cellPromptIds = Object.values(responseAnswer.cellPrompts).reduce((acc, prompts) => {
      return acc.concat(prompts.map((prompt) => prompt.id));
    }, []);

    const bankPromptIds = Object.values(responseAnswer.bankPrompts).reduce((acc, prompts) => {
      return acc.concat(prompts.map((prompt) => prompt.id));
    }, []);

    const allPromptIds = cellPromptIds.concat(bankPromptIds);

    // If preserve is on this may have duplicates that we need to get rid of
    const uniquePromptIds = [...new Set(allPromptIds)];

    const subscore = uniquePromptIds.reduce((acc, id) => {
      const isCorrect = this._isAnswerCorrect(id, model, responseAnswer);
      return isCorrect ? acc + 1 : acc;
    }, 0);

    switch (model.scoringType) {
    case PartialScoringType.EXACT:
      return subscore === uniquePromptIds.length ? model.maxScore : 0;
    case PartialScoringType.PARTIAL:
      return (subscore / uniquePromptIds.length) * model.maxScore;
    case PartialScoringType.PARTIAL_MATCH_RESPONSE:
      return subscore;
    default:
      break;
    }
  }

  _isAnswerCorrect = (id, model, responseAnswer) => {
    // The answer is correct if a the cell that contains the id is one of the cells that has an answer with the answer text in it
    // First we find the cell and answer text
    const currentPrompts = responseAnswer.cellPrompts;

    if (currentPrompts == null) {
      return false;
    }
    let currentCellId = null;
    let currentText = null;
    for (const [cellId, prompts] of Object.entries(currentPrompts)) {
      // prompts is an array of objects that have the key id
      for (const prompt of prompts) {
        if (prompt.id === id) {
          currentCellId = cellId;
          currentText = prompt.text;
        }
      }
    }
    if (currentCellId == null) {
      return false;
    }

    // Checks if the cell the answer is currently in is the correct cell
    const correctPrompts = (model && model.validation) ? model.validation.prompts : null;

    if (!correctPrompts) {
      return false;
    }
    // Now we check if an answer with the text currentText appears in a cell with the same cellId
    for (const [cellId, prompts] of Object.entries(correctPrompts)) {
      if (cellId === currentCellId) {
        for (const prompt of prompts) {
          const { cleanStringForScoring } = UtilsService;
          if (cleanStringForScoring(prompt.text) === cleanStringForScoring(currentText)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  _setResponsePrompts = (responseAnswer) => {
    const promptsObj = {};
    for (const [cellId, promptsArray] of Object.entries(responseAnswer.cellPrompts)) {
      promptsObj[cellId] = promptsArray.map((prompt) => prompt.text);
    }
    responseAnswer.prompts = promptsObj;
  }

  setResponseData = ({ sourceZoneKey, destinationZoneKey, answerId }, responseAnswer, model) => {
    const { answersPersist } = model;
    const answer = model.prompts.find((prompt) => prompt.id === answerId);

    const getCellArray = (zoneKey) => {
      // If cellId has bank in it, then the cellId is zoneKey. Otherwise it is cell+zoneKey
      if (zoneKey.includes('bank')) {
        return responseAnswer.bankPrompts[zoneKey];
      } else {
        return responseAnswer.cellPrompts[`cell${zoneKey}`];
      }
    };

    const sourceCell = getCellArray(sourceZoneKey);
    const destinationCell = getCellArray(destinationZoneKey);

    // Removing so we can move items back to answer bank
    // if (destinationZoneKey.includes('bank')) {
    //   // You cannot move things back to the bank
    //   return;
    // }

    // Remove answer from source cell if the cell exists
    if (sourceCell) {
      // If answersPersist is true, then we do not remove it if this is a bank
      if (!(answersPersist && sourceZoneKey.includes('bank'))) {
        for (let i = 0; i < sourceCell.length; i++) {
          if (sourceCell[i].id === answerId) {
            sourceCell.splice(i, 1);
            break;
          }
        }
      }
    }

    // Add answer to destination cell if the cell exists
    if (destinationCell) {
      // If answersPersist is true and this answerId is already in the cell, we do not add it again
      if (!(answersPersist && destinationCell.find((prompt) => prompt.id === answerId))) {
        // destinationCell.splice(destinationIndex, 0, answer);
        destinationCell.push(answer);
      }
    }

    this._setResponsePrompts(responseAnswer); // Update the response data
  }

  getAnswerFeedback = (id, responseAnswer) => {
    return true;
  }

  getInitializedResponse = (lessonElementId, model) => {
    /*
    Decisions here need to be justified
    Usually we store data about question state in prompts. However, in this case, we would need to do a lot more work if we only stored the answer state.
    Therefore, along with `prompts`, which stores the answer, we also store cellPrompts and bankPrompts which divide the prompts into their visual locations.
    This also means that we need to convert the data stored in the cellPrompts and bankPrompts objects into the final answer format. This is done by calling _setResponsePrompts with the full answerResponse object.
    */
    const showDuplicateAnswers = model.duplicateAnswers;
    const seenAnswers = new Set();

    const numCells = model.rowLabels.length * model.columnLabels.length;
    const promptsObject = {};
    for (let i = 0; i < numCells; i++) {
      promptsObject[`cell${i}`] = [];
    }
    // All prompts are initially in the bank. If the layoutType is manual, we create bank0, ..., bankN keys for the prompts or if not we use bank0 for all prompts.
    const bankObject = {};
    const { prompts } = model; // Array of prompt objects
    const isManualLayout = model.answerLayoutType === 'manual';
    for (let i = 0; i < prompts.length; i++) {
      const prompt = prompts[i];
      const bankKey = isManualLayout ? `bank${i}` : 'bank0';
      if (!bankObject[bankKey]) {
        bankObject[bankKey] = [];
      }
      const promptAnswer = prompt.text;
      if (!showDuplicateAnswers && seenAnswers.has(promptAnswer)) {
        continue;
      }
      seenAnswers.add(promptAnswer);
      bankObject[bankKey].push(prompt);
    }
    const responseAnswer = {
      ...questionService.generateInitializedQuestionResponse({
        prompts: {}, // Computed from cellPrompts each time they change
        cellPrompts: promptsObject,
        bankPrompts: bankObject,
        isScored: false,
        isScoredCorrect: false,
        lessonElementId
      })
    };
    return responseAnswer;
  }

  getCorrectAnswersText = (model) => {
    return '';
  }

  getQuestionFeedbackText = (model, correctFeedback, incorrectFeedback, correctFeedbackBody, incorrectFeedbackBody, mode, questionIsCorrect, currentAttemptCount, { lessonElementState }) => {
    let textBody = '';
    if (mode === LessonMode.PREVIEW || mode === LessonMode.STUDENT_PREVIEW || mode === LessonMode.PUBLISHER_PREVIEW) {
      textBody += UtilsService.previewWarning();
    } else {
      if (typeof currentAttemptCount !== 'undefined' && currentAttemptCount !== -1 && currentAttemptCount > 1) {
        textBody += UtilsService.saveWarning();
      } else {
        textBody += UtilsService.saveCommit();
      }
    }

    let textHeader = '';

    if (questionIsCorrect === QuestionFeedbackState.CORRECT) {
      textHeader = correctFeedback;
      if (correctFeedbackBody) {
        textBody = `${correctFeedbackBody} ${textBody}`;
      }
    } else if (questionIsCorrect === QuestionFeedbackState.PARTIAL_CORRECT) {
      textHeader = `${UtilsService.partiallyCorrect()}`;
      let custom = '';
      if (incorrectFeedbackBody) {
        custom = `${incorrectFeedbackBody }<br/>`;

        textBody = `${UtilsService.partiallyCorrectSelectPrompt()}.<br/>${ custom }${textBody}`;
      } else {
        textBody = `${UtilsService.partiallyCorrectPrompt()}. ${ textBody}`;
      }
    } else {
      textHeader = incorrectFeedback;
      if (incorrectFeedbackBody) {
        textBody = `${incorrectFeedbackBody} ${textBody}`;
      }
    }

    let finalText = '<div class=\'question-feedback-header\'>';
    finalText += textHeader;
    finalText += '</div>';
    finalText += '<div class=\'question-feedback-body\'>';
    finalText += textBody;
    finalText += '</div>';
    return finalText;
  }

  isAnswerCorrect = (answerId, lessonElementState) => {
    const lessonElementId = lessonElementState?.currentResponseAnswer?.lessonElementId;
    if (!lessonElementId) {
      return false;
    }
    const uiState = questionFeedbackManager.getUiState(lessonElementId);
    if (uiState.showAnswers) {
      return true;
    }
    const model = lessonManager.getSlideModel(lessonElementId);
    const isCorrect = this._isAnswerCorrect(answerId, model, lessonElementState.currentResponseAnswer);
    return isCorrect;
  }

  isQuestionCorrect = (lessonElementState, lessonElementId) => {
    if (!lessonElementState) {
      return QuestionFeedbackState.INCORRECT;
    }

    const uiState = questionFeedbackManager.getUiState(lessonElementId);

    if (lessonElementState.scoreValue >= lessonElementState.maxScore || uiState.showAnswers) {
      return QuestionFeedbackState.CORRECT;
    }
    if ((lessonElementState.scoreValue > 0) && (lessonElementState.scoreValue < lessonElementState.maxScore)) {
      return QuestionFeedbackState.PARTIAL_CORRECT;
    }
    return QuestionFeedbackState.INCORRECT;
  }
}

export default new CategorizeBehavior();
