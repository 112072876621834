import React, { useContext, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import navMenuService from '../../services/NavMenuService';
import { FlyoutOpenState, FlyoutSide } from '../../../Constants';
import studentActivityService from '../../services/StudentActivityService';
import UtilsService from '../../services/UtilsService';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import '../../../css/Flyup.scss';
import { register } from '../../../i18n';

const t = register('FlyupNavForScoring');
const t2 = register('ArialLabels');

const FlyupNavForScoring = observer(({ lessonElementId, flyoutSide }) => {
  const {
    lessonManager,
    navMenuManager,
    studentActivityManager
  } = useContext(MobXProviderContext);

  navMenuService.initFlyupOpenState(FlyoutOpenState.OPEN, lessonElementId);

  const openButton = useRef();

  const openState = navMenuManager.getFlyupOpenState(lessonElementId);

  const previousButtonRef = useRef();
  const nextButtonRef = useRef()
  const previousStudentButtonRef = useRef();
  const nextStudentButtonRef = useRef();
  const disableStudentButtons = (studentActivityManager.students.length < 2);
  const [showKeys, setShowKeys] = useState(true);

  useAccessibilityClick(previousButtonRef, (e) => {
    navMenuService.previousSlideHandler(e);
  });

  useAccessibilityClick(nextButtonRef, (e) => {
    navMenuService.nextSlideHandler(e);
  });

  useAccessibilityClick(previousStudentButtonRef, async (e) => {
    if (disableStudentButtons) {
      return;
    }
    await studentActivityService.previousStudentHandler(e);
  });

  useAccessibilityClick(nextStudentButtonRef, async (e) => {
    if (disableStudentButtons) {
      return;
    }
    await studentActivityService.nextStudentHandler(e);
  });

  useAccessibilityClick(openButton, () => {
    navMenuService.toggleFlyupPanel(lessonElementId);
  });

  return (
    <div className={classNames({ 'flyup-wrapper': true, 'open': (openState === FlyoutOpenState.OPEN), 'left': (flyoutSide === FlyoutSide.LEFT) })}>
      <div className='flyup-panel-wrapper'>

        {/* <div className={classNames({ 'flyup-tab-bar': true })}>
          <div ref={openButton} aria-label={(openState === FlyoutOpenState.CLOSED) ? 'Open Scoring Navigation menu' : 'Close Scoring Navigation Menu'} className='flyup-tab'
            role='button'
            tabIndex='0'>
              <div className='open-button' />
              <div className='open-button' />
          </div>
        </div> */}
        <div className='nav-info-wrapper'>{t('navInfoLabel')}
          <div aria-label={t2('viewkeyshortcutcommands')} className='nav-info-icon' role='button' tabIndex='0' onMouseEnter={() => setShowKeys(false)}
            onMouseLeave={() => setShowKeys(true)}></div>
          </div>
        <div className='button-wrapper '>
          <div className='button-wrapper mid'>
            <button ref={previousStudentButtonRef} aria-label={t2('previousstudentbutton')}
              className={classNames('item-button', 'primary', 'inverse', 'previous-student', { disabled: disableStudentButtons }, { hidden: lessonManager.isLti })}>
              <div aria-hidden className='icon' />
              <div aria-hidden className={`label ${showKeys ? '' : 'keyCombo'}`}>{showKeys ? t('previousStudent') : 'ctrl ['}</div>
            </button>
            <button ref={nextStudentButtonRef} aria-label={t2('nextstudentbutton')}
              className={classNames('item-button', 'primary', 'inverse', 'next-student', { disabled: disableStudentButtons }, { hidden: lessonManager.isLti })}>
              <div aria-hidden className={`label ${showKeys ? '' : 'keyCombo'}`}>{showKeys ? t('nextStudent', 'Next Student') : 'ctrl ]'}</div>
              <div aria-hidden className='icon' />
            </button>
          </div>
          <div className='button-wrapper mid'>
            <button ref={previousButtonRef} aria-label={t2('previousitembutton')}
              className={classNames('item-button', 'primary', 'previous')}>
              <div aria-hidden className='icon' />
              <div aria-hidden className={`label ${showKeys ? '' : 'keyCombo'}`}>{showKeys ? t('previousItem') : 'ctrl ;'}</div>
            </button>
            <button ref={nextButtonRef} aria-label={t2('nextitembutton')}
              className='item-button primary next'>
              <div aria-hidden className={`label ${showKeys ? '' : 'keyCombo'}`}>{showKeys ? t('nextItem') : 'ctrl \''}</div>
              <div aria-hidden className='icon' />
            </button>
          </div>
          <div className='slideNumberWrapper'>
            {(!UtilsService.isNullOrEmpty(lessonManager.questionNumberLabel)) &&
              <>
              <div aria-label={`${t2('currentlyviewing')} ${t('questionLabel','Question')} ${lessonManager.questionNumberLabel}`} className='slideLabel'>{t('questionLabel', 'Question')}:</div> <div className='slideNumber'>{lessonManager.questionNumberLabel ? lessonManager.questionNumberLabel : ''}</div><br/>
              </>
            }
            <div aria-label={`${t2('currentlyviewing')} ${t('slideLabel','Slide')} ${lessonManager.slideNumberLabel}`} className='slideLabel'>{t('slideLabel', 'Slide')}:</div> <div className='slideNumber'>{lessonManager.slideNumberLabel}</div>
          </div>

        </div>
      </div>
    </div>
  );
});

export default FlyupNavForScoring;
