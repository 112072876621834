/**
 * Record audio from the microphone with a real-time waveform preview.
 *
 * Note: This is a drop-in replacement for https://github.com/katspaugh/wavesurfer.js/blob/main/src/plugins/record.ts
 */

import BasePlugin from 'wavesurfer.js/dist/base-plugin';
import Timer from 'wavesurfer.js/dist/timer';

import {
  RecordPluginDeviceOptions, // eslint-disable-line no-unused-vars
  RecordPluginOptions // eslint-disable-line no-unused-vars
} from 'wavesurfer.js/dist/plugins/record';

const DEFAULT_BITS_PER_SECOND = 128000;
const DEFAULT_SCROLLING_WAVEFORM_WINDOW = 5;

const MIME_TYPES = ['audio/webm', 'audio/wav', 'audio/mpeg', 'audio/mp4', 'audio/mp3'];
const findSupportedMimeType = () => MIME_TYPES.find((mimeType) => MediaRecorder.isTypeSupported(mimeType));

/**
 * Drop-in replacement for https://github.com/katspaugh/wavesurfer.js/blob/main/src/plugins/record.ts
 */
class RecordPlugin extends BasePlugin {
  stream; /* MediaStream | null = null */
  mediaRecorder; /* : MediaRecorder | null = null */
  dataWindow; /* : Float32Array | null = null */
  isWaveformPaused = false
  originalOptions; /* : { cursorWidth: number; interact: boolean } | undefined */
  timer; /* : Timer */
  lastStartTime = 0;
  lastDuration = 0;
  duration = 0;

  /**
   * Create an instance of the Record plugin
   * @param {RecordPluginOptions} options
   */
  constructor(options) {
    super({
      ...options,
      audioBitsPerSecond: options.audioBitsPerSecond ?? DEFAULT_BITS_PER_SECOND,
      scrollingWaveform: options.scrollingWaveform ?? false,
      scrollingWaveformWindow: options.scrollingWaveformWindow ?? DEFAULT_SCROLLING_WAVEFORM_WINDOW,
      renderRecordedAudio: options.renderRecordedAudio ?? true,
    });

    this.timer = new Timer();

    this.subscriptions.push(
      this.timer.on('tick', () => {
        const currentTime = performance.now() - this.lastStartTime;
        this.duration = this.isPaused() ? this.duration : this.lastDuration + currentTime;
        this.emit('record-progress', this.duration);
      }),
    );
  }

  /**
   * Create an instance of the Record plugin
   * @param {RecordPluginOptions} options
   */
  static create(options) {
    return new RecordPlugin(options || {});
  }

  /** @param {MediaStream} stream */
  renderMicStream(stream) {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    source.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Float32Array(bufferLength);

    let animationId;

    const windowSize = Math.floor((this.options.scrollingWaveformWindow || 0) * audioContext.sampleRate);

    const drawWaveform = () => {
      if (this.isWaveformPaused) {
        animationId = requestAnimationFrame(drawWaveform);
        return;
      }

      analyser.getFloatTimeDomainData(dataArray);

      if (this.options.scrollingWaveform) {
        const newLength = Math.min(windowSize, this.dataWindow ? this.dataWindow.length + bufferLength : bufferLength);
        const tempArray = new Float32Array(windowSize); // Always make it the size of the window, filling with zeros by default

        if (this.dataWindow) {
          const startIdx = Math.max(0, windowSize - this.dataWindow.length);
          tempArray.set(this.dataWindow.slice(-newLength + bufferLength), startIdx);
        }

        tempArray.set(dataArray, windowSize - bufferLength);
        this.dataWindow = tempArray;
      } else {
        this.dataWindow = dataArray;
      }

      const duration = this.options.scrollingWaveformWindow;

      if (this.wavesurfer) {
        this.originalOptions ??= {
          cursorWidth: this.wavesurfer.options.cursorWidth,
          interact: this.wavesurfer.options.interact,
        };
        this.wavesurfer.options.cursorWidth = 0;
        this.wavesurfer.options.interact = false;
        this.wavesurfer.load('', [this.dataWindow], duration);
      }

      animationId = requestAnimationFrame(drawWaveform);
    };

    drawWaveform();

    return {
      onDestroy: () => {
        cancelAnimationFrame(animationId);
        source?.disconnect();
        audioContext?.close();
      },
      onEnd: () => {
        this.isWaveformPaused = true;
        cancelAnimationFrame(animationId);
        this.stopMic();
      },
    };
  }

  /**
   * Request access to the microphone and start monitoring incoming audio
   * @param {RecordPluginDeviceOptions} options
   */
  async startMic(options) {
    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: options?.deviceId ? { deviceId: options.deviceId } : true,
      });
    } catch (err) {
      // eslint-disable-next-line prefer-template
      throw new Error('Error accessing the microphone: ' + (err).message);
    }

    const { onDestroy, onEnd } = this.renderMicStream(stream);
    this.subscriptions.push(this.once('destroy', onDestroy));
    this.subscriptions.push(this.once('record-end', onEnd));
    this.stream = stream;

    return stream;
  }

  /** Stop monitoring incoming audio */
  stopMic() {
    // this.mediaRecorder?.stream?.getTracks?.().forEach((track) => {
    //   track.stop();
    // });
    if (!this.stream) return;
    this.stream.getTracks().forEach((track) => track.stop());
    this.stream = null;
    this.mediaRecorder = null;
  }

  /**
   * Start recording audio from the microphone
   *
   * @param {RecordPluginDeviceOptions} options
   *
   * */
  async startRecording(options = {}) {
    const stream = this.stream || (await this.startMic(options));
    this.dataWindow = null;
    const mediaRecorder =
      this.mediaRecorder ||
      new MediaRecorder(stream, {
        mimeType: findSupportedMimeType(),
        audioBitsPerSecond: this.options.audioBitsPerSecond,
      });

    // mediaRecorder.onstop = (...rest) => {}

    this.mediaRecorder = mediaRecorder;
    this.stopRecording();

    const recordedChunks = [];

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.push(event.data);
      }
    };

    /**
     * @param {'record-pause' | 'record-end'} ev
     */
    const emitWithBlob = (ev) => {
      const blob = new Blob(recordedChunks, { type: mediaRecorder.mimeType });
      this.emit(ev, blob);
      if (this.options.renderRecordedAudio) {
        this.applyOriginalOptionsIfNeeded();
        this.wavesurfer?.load(URL.createObjectURL(blob));
      }
    };

    mediaRecorder.onpause = () => emitWithBlob('record-pause');

    mediaRecorder.onstop = () => emitWithBlob('record-end');

    mediaRecorder.start();
    this.lastStartTime = performance.now();
    this.lastDuration = 0;
    this.duration = 0;
    this.isWaveformPaused = false;
    this.timer.start();

    this.emit('record-start', { mediaRecorder });
  }

  /** Get the duration of the recording */
  getDuration() {
    return this.duration;
  }

  /** Check if the audio is being recorded */
  isRecording() {
    return this.mediaRecorder?.state === 'recording';
  }

  isPaused() {
    return this.mediaRecorder?.state === 'paused';
  }

  isActive() {
    return this.mediaRecorder?.state !== 'inactive';
  }

  /** Stop the recording */
  stopRecording() {
    if (this.isActive()) {
      this.mediaRecorder?.stop();
      this.timer.stop();
    }
  }

  /** Pause the recording */
  pauseRecording() {
    if (this.isRecording()) {
      this.isWaveformPaused = true;
      this.mediaRecorder?.requestData();
      this.mediaRecorder?.pause();
      this.timer.stop();
      this.lastDuration = this.duration;
    }
  }

  /** Resume the recording */
  resumeRecording() {
    if (this.isPaused()) {
      this.isWaveformPaused = false;
      this.mediaRecorder?.resume();
      this.timer.start();
      this.lastStartTime = performance.now();
      this.emit('record-resume');
    }
  }

  /**
   * Get a list of available audio devices.
   *
   * You can use this to get the device ID of the microphone to use with the startMic and startRecording methods.
   *
   * Will return an empty array if the browser doesn't support the MediaDevices API
   * (or if the user has not granted access to the microphone).
   *
   * You can ask for permission to the microphone by calling startMic.
   */
  static async getAvailableAudioDevices() {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => devices.filter((device) => device.kind === 'audioinput'));
  }

  /** Destroy the plugin */
  destroy() {
    this.applyOriginalOptionsIfNeeded();
    super.destroy();
    this.stopRecording();
    this.stopMic();
  }

  applyOriginalOptionsIfNeeded() {
    if (this.wavesurfer && this.originalOptions) {
      this.wavesurfer.options.cursorWidth = this.originalOptions.cursorWidth;
      this.wavesurfer.options.interact = this.originalOptions.interact;
      delete this.originalOptions;
    }
  }
}

export default RecordPlugin;
