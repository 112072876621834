import _ from 'lodash';
import Select from 'react-select';

import classNames from 'classnames';
import { LessonMode } from '../../Constants';
import { register } from '../../i18n';

import lessonManager from '../managers/LessonManager';
import clozeMultichoiceManager from '../managers/ClozeMultichoiceManager';

import dropdownService from './DropdownService';
import promptService from './PromptService';
import questionService from './QuestionService';
import responseService from './ResponseService';
import textQuestionUtilsService from './TextQuestionUtilsService';
import utilsService from './UtilsService';

import FeedbackIcon from '../components/FeedbackIcon';
import HtmlComponent from '../components/HtmlComponent';
const t = register('GlobalQuestionLabels');

export default class ClozeMultichoiceService {
  /** @param {import('html-react-parser').DOMNode} domNode */
  static renderClozeMultichoiceBlankSection = (domNode, {
    dataId,
    forceUpdate = () => {}, // empty function by default. needs to be passed by ClozeMultichoice
    lessonElementId,
    lessonElementState,
    // modalBody,
    modalLeft,
    modalTop,
    model,
    readOnly,
    setModalBody,
    uiState,
    userInputMap,
    noSubmit
  } = {}) => {
    const _renderClozeMultichoiceBlankSectionFn = (domNode, {
      dataId,
      forceUpdate = () => {}, // empty function by default. needs to be passed by ClozeMultichoice
      lessonElementId,
      lessonElementState,
      model,
      readOnly,
      uiState,
      userInputMap
    } = {}) => {
      dataId = dataId || domNode?.attribs['data-id'];

      if (!dataId) {
        return null;
      }

      // There is an issue with copied cloze multichoice item bank items where the dataId does not get updated.
      // Which means the multichoiceDropdwonItems map didn't know the difference between all of the dropdowns.
      // Concatenating the dataId and the lessonElementId is hopefully a temporary solution to fix this problem.
      const dropdownId = dataId + lessonElementId;

      const customDropdownOptions = clozeMultichoiceManager.multichoiceDropdownItems.has(dropdownId) ? clozeMultichoiceManager.multichoiceDropdownItems.get(dropdownId) : dropdownService.getDropdownOptions({
        dataId,
        model
      });

      clozeMultichoiceManager.setMultichoiceDropdownItems(dropdownId, customDropdownOptions);

      if (!customDropdownOptions?.length) {
        return undefined;
      }

      const showAnswerFeedback = uiState?.showAnswerFeedback || false;

      let nodeClassNames = domNode?.attribs?.['class'];
      nodeClassNames = classNames(nodeClassNames, {
        correct: false, // we do not want students seeing this className in the dev tools
        feedback: showAnswerFeedback,
        incorrect: false // we do not want students seeing this className in the dev tools
      }, `${model.responseFormat || ''}`);

      // TODO remove // let editorClassName = model.responseFormat === 'html' ? 'cke_topspace ' : '';

      const responseAnswerPrompt = promptService.getCurrentPrompt({
        dataId,
        lessonElementState,
        model,
        promptType: 'responseAnswerPrompt',
      }) || {};

      const { id, text, ...responseAnswerPromptRest } = responseAnswerPrompt;

      const isResponseAnswerPromptEmpty = !Object.keys(responseAnswerPrompt)?.length;
      if (!isResponseAnswerPromptEmpty) {
        userInputMap?.set(dataId, {
          dataId,
          text: responseAnswerPrompt?.text?.trim?.()/* || clozeText */,
          ...responseAnswerPromptRest
        });
      }

      let currentUserInputValue = questionService.getCurrentUserInputValue({ dataId, lessonElementState, userInputMap });
      if (currentUserInputValue?.includes?.('data-mathml') && (model.responseFormat !== 'math' && !model.mathText)) {
        currentUserInputValue = utilsService.findMathInput(currentUserInputValue);
      }

      let selectedDropdownOption = customDropdownOptions.find((option) => {
        let optionLabel = option.label;
        if (optionLabel?.includes?.('data-mathml') && (model.responseFormat !== 'math' && !model.mathText)) {
          optionLabel = utilsService.findMathInput(optionLabel);
        }

        const { stripZeroWidthChars } = utilsService;
        const stripPWrap = utilsService.stripWrappingParagraphTags;

        return stripZeroWidthChars(stripPWrap(optionLabel)) === stripZeroWidthChars(stripPWrap(currentUserInputValue));
      });
      if (selectedDropdownOption === undefined) {
        selectedDropdownOption = null;
      }

      // TODO unused
      // const promptAnswerStatus = promptService.getAnswerStatusForCurrentPrompt({
      //   currentUserInputValue,
      //   dataId,
      //   model
      // });

      const { customComponents, customDropdownStyles } = dropdownService.getDropdownConfig({
        dataId, modalLeft, modalTop, model
      });
      const { playerMode } = lessonManager;

      if (lessonManager.playerMode === LessonMode.PRINT_PREVIEW) {
        const prompt = _.find(model.prompts, { id: dataId });
        const { answers } = prompt;
        return (
          <div className='select-options'>
            {
              answers.map((answer, index) => {
                return (
                  <div key={`${dataId}_${index}`}>{answer}</div>
                );
              })
            }
          </div>
        );
      }

      return (
        <div key={/* model.mathText ? `math-${dataId}` : */dataId}
          className={classNames('cloze-blank-section cloze-section input-section multichoice-section', {
            // TODO remove // editorClassName
            // 'cloze-math-blank-section': !!model.mathText
          }, nodeClassNames)}
          data-id={dataId}>
          {/* <FeedbackIcon answerId={dataId} lessonElementId={lessonElementId} /> */}
          {/* <span className={`standalone-input-icon ${nodeClassNames}${numberedCircleClassName} ${playerMode} ${promptAnswerStatus}`} /> */}
          <div className={classNames('cloze-section-input-wrapper', {
            'empty-input': !currentUserInputValue,
          }, nodeClassNames, playerMode)}>
            {model.numberedBlanks && <div className='list-number' />}
            <Select
              {...((model && !utilsService.isNullOrEmpty(model.mathText)) ? {
                autoFocus: true,
                // TODO remove // menuIsOpen: true,
                openMenuOnFocus: true
              } : {})}

              className={'react-player-select-container'}
              classNamePrefix='player-select'
              components={customComponents}
              formatOptionLabel={(data) => {
                let dataLabel = data.label;
                if (dataLabel?.startsWith?.('<menclose')) {
                  dataLabel = `<math>${dataLabel}</math>`;
                }
                return <HtmlComponent  htmlStr={dataLabel} />;
              }}
              isDisabled={showAnswerFeedback || readOnly}
              isSearchable={false}
              maxMenuHeight={220}
              menuPlacement='auto'
              menuPortalTarget={document.querySelector('body')}
              menuPosition='absolute'
              onChange={(data) => {
                if (data.dataId !== 'prependedSelectLabel') {
                  responseService.textChangeHandler(
                    data, lessonElementId, { dataId, lessonElementState }
                  );
                  setModalBody?.(undefined);
                } else {
                  setModalBody?.(undefined);
                }
              }}
              onBlur={() => setModalBody?.(undefined)}
              //TODO commented out becuase it was causing drop down options to randomize everytime the dropdown menu was closed.
              //onMenuClose={forceUpdate}
              options={customDropdownOptions}
              placeholder={`${t('choose')}...`}
              styles={customDropdownStyles}
              value={selectedDropdownOption}
            />
          </div>
          <FeedbackIcon
            {...lessonElementState.currentResponseAnswer}
            {...textQuestionUtilsService.getFeedbackIconOptionsForDecoratingTextInputs()}
            answerId={dataId} />
        </div>
      );
    };
    return _renderClozeMultichoiceBlankSectionFn(domNode, {
      dataId,
      forceUpdate,
      lessonElementId,
      lessonElementState,
      model,
      readOnly,
      uiState,
      userInputMap,
      noSubmit
    });
  };
}
