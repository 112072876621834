import { action, computed, makeObservable, observable } from 'mobx';

export class LessonElementState {
  @observable
  readOnly = false;

  @observable
  currentResponseAnswer = null; // current response of the lesson element;

  @observable
  cachedResponseAnswer = null; // the response after it's submitted

  cachedCorrectState= null; // the response after it's submitted

  @observable
  toolPersistence = {}; // Allows for persisting the state of the tool as well as arbitrary data

  @observable
  serverResponse = null; // response structure needed for saving to the backend

  @observable
  isSaved=false;

  @observable
  isSubmitted=false;

  @observable
  isScored=false;

  @observable
  scoreValue=0;

  @observable
  maxScore=0;

  @observable
  scoreState = null;

  @observable
  responseId=null;

  @observable
  subscore = null;

  @observable
  viewedTime = 0;

  @observable
  controllerList = [];

  @observable
  dependentList = [];

  @observable
  isTestItem = false;

  @observable
  markedForReview = false;

  @observable
  submittedItemScore = 0;

  @observable
  submittedMaxScore = 0;

  @observable
  comment = null;

  // The object that will implement functionality for each question type
  questionBehavior = null;

  correctAnswerShowing = false;

  turnOnAnswerShowing = () => {
    this.correctAnswerShowing = true;
  }

  turnOffAnswerShowing = () => {
    this.correctAnswerShowing = false;
  }

  @action
  setComment = (comment) => {
    this.comment = comment;
  }

  @action
  setCurrentResponse = (response) => {
    this.currentResponseAnswer = response;
  }

  setCachedCorrectState = (state) => {
    this.cachedCorrectState = state;
  }

  @action
  setCachedResponse = (response) => {
    this.cachedResponseAnswer = response;
  }

  @action
  setSubmitted = (s) => {
    this.isSubmitted = s;
  }

  @action
  addController = (id) => {
    if (this.controllerList.indexOf(id) < 0) {
      this.controllerList.push(id);
    }
  }

  @action
  removeController = (id) => {
    if (this.controllerList.indexOf(id) >= 0) {
      this.controllerList.remove(id);
    }
  }

  @action
  addDependent = (id) => {
    if (this.dependentList.indexOf(id) < 0) {
      this.dependentList.push(id);
    }
  }

  @action
  removeAllDependents = () => {
    this.dependentList.clear();
  }

  @action
  setPersistentToolData = (toolId, updatedValueObj) => {
    // Here we create the tool persistence object if it doesn't exist and override old values with the new ones leaving the rest alone
    if (!this.toolPersistence[toolId]) {
      this.toolPersistence[toolId] = {};
    }
    Object.assign(this.toolPersistence[toolId], updatedValueObj);
  };

  @computed
  get isTryAgain() {
    return (!this.isSubmitted && this.readOnly && this.cachedResponseAnswer !== null);
  }

  @computed
  get isDependencyLocked() {
    return this.controllerList.length > 0;
  }

  getPersistentToolData = (toolId) => {
    return this.toolPersistence[toolId];
  };

  getPersistentToolDataValue = (toolId, key) => {
    return this.toolPersistence[toolId]?.[key];
  };

  constructor() {
    makeObservable(this);
  }
}
