// milliseconds

import studentActivityService from '../services/StudentActivityService';

const INSPECT_INTERVAL = 1000;
// const SUBMIT_SECONDS_INTERVAL = 10;

export class TimeManager {
  contentItemTimers = new Map();
  contentItemViewedTime = new Map();
  isShowTimingInfo = true;

  showTimingLog = (log) => {
    if (this.isShowTimingInfo) {
      console.log(log);
    }
  }

  startLessonElementTimer = (lessonElementId) => {
    if (!this.contentItemTimers.has(lessonElementId)) {
      if (!this.contentItemViewedTime.has(lessonElementId)) {
        this.contentItemViewedTime.set(lessonElementId, 0);
      }

      // let submitTime = 0;
      const interval = setInterval(async () => {
        if (document.hasFocus()) {
          let viewedTime = this.contentItemViewedTime.get(lessonElementId);
          this.contentItemViewedTime.set(lessonElementId, ++viewedTime);
          // if we ever need to mark viewed at interval
          // ++submitTime;
          // if (submitTime >= SUBMIT_SECONDS_INTERVAL) {
          //   studentActivityService.markActivityElementAsViewed(lessonElementId);
          //   console.log(lessonElementId, submitTime);
          //   submitTime = 0;
          // }
        }
      }, INSPECT_INTERVAL);

      this.contentItemTimers.set(lessonElementId, interval);
    }
  }

  stopLessonElementTimer= async (lessonElementId, interval) => {
    if (interval) {
      clearInterval(interval);
    } else if (this.contentItemTimers.has(lessonElementId)) {
      const intervalOnMap = this.contentItemTimers.get(lessonElementId);
      clearInterval(intervalOnMap);
      this.contentItemTimers.delete(lessonElementId);
    }
    if (this.contentItemViewedTime.has(lessonElementId)) {
      const roundedTime = Math.round(this.contentItemViewedTime.get(lessonElementId));
      this.contentItemViewedTime.set(lessonElementId, roundedTime);
      await studentActivityService.markActivityElementAsViewed(lessonElementId);
    }
  }

  getViewTime = (lessonElementId) => {
    if (this.contentItemViewedTime.has(lessonElementId)) {
      return this.contentItemViewedTime.get(lessonElementId);
    }
    return 0;
  }

  stopAllLessonElementTimers = async () => {
    const lessonElementIds = this.contentItemTimers.keys();

    for (const lessonElementId of lessonElementIds) {
      const interval = this.contentItemTimers.get(lessonElementId);
      await this.stopLessonElementTimer(lessonElementId, interval);
    }
  }
}

export default new TimeManager();
