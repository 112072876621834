import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import HtmlComponent from '../HtmlComponent';
import bookDrawService from '../../services/BookDrawService';
import { TOOL_TYPE } from '../../managers/BookDrawManager';
import { STAMP_TYPES } from '../../managers/obseverableObjects/AnnotationStamp';

const BookPage = observer(({ page, pageClass, pageWidth, styleAttr, contentStyleAttr, drawContainerStyleAttr, pageIndex }) => {
  const {
    bookDrawManager
  } = useContext(MobXProviderContext);

  const stamps = bookDrawManager.getStamps(page.id);
  const textBoxes = bookDrawManager.getTextBoxes(page.id);
  const pageRef = useRef();

  const handleClick = (e) => {
    if (!pageRef?.current) {
      return;
    }
    bookDrawService.handleAddStamp(e, pageRef, page);
  };

  const handleStampSelect = (stampId) => {
    bookDrawManager.selectStamp(page.id, stampId);
  };

  const handleDragEnd = async (e) => {
    if (bookDrawManager.selectedStamp === null) {
      return;
    }
    const scaleX = pageRef.current.getBoundingClientRect().width / pageRef.current.offsetWidth;
    let x = ((e.clientX - pageRef.current.getBoundingClientRect().x) - 21) / scaleX;
    let y = ((e.clientY - pageRef.current.getBoundingClientRect().y) - 21) / scaleX;

    const containerWidth = pageRef.current.offsetWidth;
    const containerHeight = pageRef.current.offsetHeight;
    if (x > containerWidth) {
      x = (containerWidth - 40);
    }
    if (y > containerHeight) {
      y = (containerHeight - 40);
    }
    if (x < 0) {
      x = 0;
    }
    if (y < 0) {
      y = 0;
    }
    const leftPercent = 100 * x / containerWidth;
    const topPercent = 100 * y / containerHeight;

    const { id } = e.target;
    const stamp = bookDrawManager.updateStamp(page.id, id, topPercent, leftPercent);
    if (stamp) {
      await bookDrawService.saveAnnotation(page.id, stamp);
    }
  };

  const handleDragStart = (e) => {
    if (bookDrawManager.tool !== TOOL_TYPE.SELECTION || bookDrawManager.selectedStamp === null) {
      e.preventDefault();
      return false;
    }
  };
  return (

    <div ref={pageRef} className={`page ${pageClass}`} data-id={page.id} data-page-index={pageIndex}
      id={page.id} onClick={handleClick} style={styleAttr}>
      <div className='drawAnnotationContainer body canvasOrganizerBody' id={`drawAnnotationContainer_${page.id}`} style={drawContainerStyleAttr}>
        <canvas className='canvas' id={`${page.id}_drawCanvas`} />
      </div>
      <div className='pageContentContainer' id={`pageContentContainer_${page.id}`} style={contentStyleAttr}>
        <div className='pageContent' id={`pageContent_${page.id}`} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <>
            {
              textBoxes.map((box) => {
                return (
                  <div key={box.id} className='textBoxWrapper' style={box.styleAttrWrapper}>
                    <div key={box.id} className='textBoxBackground' style={box.styleAttrBackGround} />
                    <HtmlComponent htmlStr={box.text} />
                  </div>
                );
              })
            }
            {
              stamps.map((stamp) => {
                let selected = false;
                if (bookDrawManager.selectedStamp && bookDrawManager.selectedStamp.stampId) {
                  selected = stamp.id === bookDrawManager.selectedStamp.stampId;
                }
                return (
                  <div key={stamp.id} className={classNames({ selected }, 'stampIcon', stamp.iconId)} draggable id={stamp.id}
                    onClick={() => { handleStampSelect(stamp.id); }} onDragStart={handleDragStart} style={stamp.styleAttr}>
                    {(stamp.type === STAMP_TYPES.NOTE && stamp.discussionCount > 0) && <div className='discussion-count'>{stamp.discussionCount} </div>}
                  </div>
                );
              })
            }

          </>
        </div>
      </div>
    </div>
  );
});

export default BookPage;
