import { useEffect } from 'react';

const useWirisParser = (ref) => {
  useEffect(() => {
    // render math if applicable
    if (!ref || !ref.current) {
      return;
    }
    if (window.com && window.com.wiris.js.JsPluginViewer) {
      window.com.wiris.js.JsPluginViewer.parseElement(ref.current, true);
    }
  });
};

export default useWirisParser;
