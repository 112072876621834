import React from 'react';
import Pill from './Pill';
import '../../css/SimplePillList.scss';
import itemBankService from '../services/ItemBankService';

const SimplePillList = (props) => {
  const { pills, item, pillClass } = props;

  const renderTruncated = (pills) => {
    const MAX_PILLS = 4;
    let truncatePills = false;
    if (pills && pills.length > MAX_PILLS) {
      truncatePills = true;
    }

    if (pills && pills.length > 0 && truncatePills) {
      pills = pills.slice(0, 3);
    }

    let categoryTagsList = [];

    if (pills && pills.length > 0) {
      categoryTagsList = pills.map((pill, index) => {
        if (pill && pill.name !== undefined && pill.name !== null && pill.visible) {
          return (
            <Pill key={`${pill.id}_tag`} pill={pill} pillClass={pillClass} />
          );
        } else {
          return (<></>);
        }
      });
    }

    return (
      <div className='simple-pill-list-container'>
        {/* <Image alt='' className='tag-image' src={iconTag} /> */}
        {categoryTagsList}
        {(truncatePills) ? (
          <div className='clickable blue' onClick={() => {
            itemBankService.handleShowItemDetails(item);
          }}>
            {' '}
            +More
          </div>
        ) : ''}
      </div>
    );
  };

  return (
    <>
      {renderTruncated(pills)}
    </>
  );
};

export default SimplePillList;
