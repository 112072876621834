import $ from 'jquery';

import { renderToStaticMarkup } from 'react-dom/server';

import parse from 'html-react-parser';

import { isObservableObject, observable, toJS } from 'mobx';
import { flatMapDeep, shuffle } from 'lodash';

import { GUID_SIZE, ONE_FULL_CIRCLE_ROTATION, ONE_HOUR_IN_SECONDS } from '../../Constants';
import { register } from '../../i18n';

import toolbarManager from '../managers/ToolbarManager';
import userManager from '../managers/UserManager';

// const SESSION_EXPIRED_ERROR = 'Not logged in.';
// const SESSION_EXPIRED_EXCEPTION = 'NotAuthenticatedException';
const INCOMPLETE_REPLY_EXCEPTION = 'dwr.engine.incompleteReply';
const NOT_FOUND_EXCEPTION = 'dwr.engine.http.404';
// const CONNECTION_ERROR = 'connectionError';
// const CONNECTION_SUCCESS = 'connectionSuccess';
const LOCKED_ERROR = 'LockedException';
const REVIEW_ERROR = 'ReviewException';
const INTERNAL_ERROR = 'InternalErrorException';
const SYSTEM_ERROR = 'JpaSystemException';

const t = register('GlobalQuestionLabels');

export default class UtilsService {
  // these translation constants are wrapped to force them
  // to update after a translation is returned from the server.
  //
  static previewWarning() {
    return t('previewWarning');
  }

  static previewWarning2() {
    return t('previewWarning2');
  }

  static saveWarning() {
    return t('saveWarning');
  }

  static saveCommit() {
    return t('saveCommit');
  }

  static partiallyCorrectPrompt() {
    return t('partiallyCorrectPrompt');
  }

  static partiallyCorrectSelectPrompt() {
    return t('partiallyCorrectSelectPrompt');
  }

  static partiallyCorrectSelectPrompt2() {
    return t('partiallyCorrectSelectPrompt2');
  }

  static errorGeneric() {
    return t('errorGeneric');
  }

  static notSubmittedBody() {
    return t('notSubmittedBody');
  }

  static cannotBe() {
    return t('cannotBe');
  }

  static maxSelected() {
    return t('maxSelected');
  }

  static deselect() {
    return t('deselect');
  }

  static maxReached() {
    return t('maxReached');
  }

  static deselect2() {
    return t('deselect2');
  }

  static deleteCheck() {
    return t('deleteCheck');
  }

  static responseWarning() {
    return t('responseWarning');
  }

  static errorAlert() {
    return t('errorAlert');
  }

  static submitCheck() {
    return t('submitCheck');
  }

  static notCompleteWarning() {
    return t('notCompleteWarning');
  }

  static noManuallyScored() {
    return t('noManuallyScored');
  }

  static saveErrorMessage() {
    return t('saveErrorMessage');
  }

  static correct() {
    return t('correct');
  }

  static partiallyCorrect() {
    return t('partiallyCorrect');
  }

  static incorrect() {
    return t('incorrect');
  }

  static confirmOk() {
    return t('ok');
  }
  /// ///

  static safeMobxClone = (sourceObject) => {
    let temp = null;
    if (isObservableObject(sourceObject)) {
      temp = toJS(sourceObject);
    } else {
      temp = sourceObject;
    }
    return observable(temp);
  }

  static formatTimeMMSS(timeInSeconds) {
    try {
      timeInSeconds = timeInSeconds || 0;
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
      // return normal `result` if time exceeds (or is exactly) 1 hour (3600 seconds)
      // otherwise returned string would roll back to '00:00' for 1 hour and beyond
      if (timeInSeconds >= ONE_HOUR_IN_SECONDS) {
        return result;
      }
      const time = {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2),
      };
      return `${time.minutes}:${time.seconds}`;
    } catch (error) {
      // `timeInSeconds` was invalid time value, so return default string
      return '00:00';
    }
  }

  static formatTime = (milliseconds) => {
    let formatted;
    if (typeof milliseconds === 'number') {
      formatted = new Date(milliseconds * 1000).toISOString().substr(11, 8);
    }
    return formatted;
  }

  static secondsToMMSS = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds - (minutes * 60);

    // round seconds
    seconds = Math.round(seconds * 100) / 100;

    let result = (minutes < 10) ? `0${ minutes}` : minutes;
    result += `:${ seconds < 10 ? `0${ seconds}` : seconds}`;

    return result;
  }

  static findMathInput = (str = '') => {
    if (str?.includes?.('data-mathml')) {
      // TODO remove // const imgMath = /(?:data-mathml=")(.*)(?=" src)/gm;
      const dataMathmlRegex = /data-mathml=(["\\'])?((?:.(?!\1|>))*.?)\1?/ig;

      const result = str.match(dataMathmlRegex);

      const DATA_MATHML_START_INDEX = 13;
      const DATA_MATHML_END_INDEX = (result?.[0]?.length || 0) - 1;
      str = result?.[0]?.substring?.(DATA_MATHML_START_INDEX, DATA_MATHML_END_INDEX) || '';
    } else {
      const mathString = /(<math)(.*)(<\/math>)/gm;
      const result = str.match(mathString);
      if (result && result.length > 0) {
        str = result[0];
      } else {
        str = '';
      }
    }

    str = UtilsService.cleanMathMLForScoring(str);
    return str;
  }

  static isNullButNotEmpty = (str) => {
    if (str === null) {
      return true;
    }
    if (typeof str === 'undefined') {
      return true;
    }
    const input = str.trim();
    if (input === 'null') {
      return true;
    }
    return false;
  }

  static isNullOrEmpty = (str) => {
    if (str === null) {
      return true;
    }
    if (str === undefined) {
      return true;
    }
    if (str === 'undefined' || str === 'null') {
      return true;
    }

    if (typeof str !== 'string' && typeof str !== 'String') {
      return false;
    }

    const input = str.trim();
    if (input === '') {
      return true;
    }
    if (input === 'null') {
      return true;
    }
    if (input === 'undefined') {
      return true;
    }
    return false;
  }

  static isNullOrEmptyOrUndefined = (str) => {
    if (str === null) {
      return true;
    }
    if (str === undefined) {
      return true;
    }
    // we are not a string, but we are also not null
    // or undefined
    if (typeof str !== 'String' && typeof str !== 'string') {
      return false;
    }
    const input = str.trim();
    if (input === '') {
      return true;
    }
    if (input === 'null') {
      return true;
    }
    return false;
  }

  static mapById = (objs) => {
    const map = {};
    for (const obj of objs) {
      map[obj.id] = obj;
    }
    return map;
  }

  static roundToDecimal = (num, decimalPlaces) => {
    if (!decimalPlaces) {
      decimalPlaces = 1;
    }

    return Math.round(num * decimalPlaces * 10) / (decimalPlaces * 10);
  }

  static strReplaceAll = (text, targetStr, replaceStr) => {
    const regex = new RegExp(targetStr, 'g');
    return text.replace(regex, replaceStr);
  }

  static cleanStringForScoring = (str) => {
    str = this.stripWrappingParagraphTags(str);
    str = str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    // eslint-disable-next-line no-irregular-whitespace
    str = str.replace(/&nbsp;/g, ' ').replace(/ /g, ' '); // replace non-breaking spaces with regular spaces.
    str = str.replace(/\n/g, ' ');
    str = str.replace(/&amp;/g, '&').replace(/&/g, '&amp;');
    str = str.replaceAll('‘', "'").replaceAll('’', "'");
    str = this.stripAuthKeys(str);
    str = this.stripCacheBuster(str);

    return str;
  }

  static stripAuthKeys = (str) => {
    while (str.indexOf('authKey') !== -1) {
      const startIndex = str.indexOf('authKey');
      if (startIndex !== -1) {
        const endIndex = startIndex + 40;
        const startStr = str.substring(0, startIndex);
        const endStr = str.substring(endIndex, str.length);

        str = startStr + endStr;
      }
    }
    return str;
  }

  static swapAuthKey = (url, authKey) => {
    const authKeyStringLength = 'authKey='.length;
    const totalAuthKeyLength = authKeyStringLength + GUID_SIZE;
    return url.replace(url.slice(url.indexOf('authKey='), url.indexOf('authKey=') + totalAuthKeyLength),
    `authKey=${authKey}`);
  }

  static stripCacheBuster = (str) => {
    while (str.indexOf('cacheBuster') !== -1) {
      const startIndex = str.indexOf('cacheBuster');
      if (startIndex !== -1) {
        let endIndex = str.indexOf('"', startIndex);
        if (endIndex === -1) {
          endIndex = str.length;
        }
        const startStr = str.substring(0, startIndex);
        const endStr = str.substring(endIndex, str.length);
        str = startStr + endStr;
      }
    }
    return str;
  }

  static cleanMathMLForScoring = (mathml, { model, stripAttributes = false } = {}) => {
    if (stripAttributes) {
      mathml = renderToStaticMarkup(parse(mathml || '', {
        replace: (domNode) => {
          if (model.mathText && domNode.name === 'math') {
            domNode.name = 'menclose';
          }
          domNode.attribs = {};
        }
      }));
    }
    mathml = mathml || '';
    mathml = this.cleanMathML(mathml);
    mathml = this.strReplaceAll(mathml, '<mrow>', '');
    mathml = this.strReplaceAll(mathml, '</mrow>', '');
    mathml = this.strReplaceAll(mathml, '<mstyle>', '');
    mathml = this.strReplaceAll(mathml, '</mstyle>', '');
    mathml = this.strReplaceAll(mathml, '<semantics>', '');
    mathml = this.strReplaceAll(mathml, '</semantics>', '');

    return mathml || '';
  }

  static cleanMathML = (mathml) => {
    mathml = mathml || '';
    mathml = this.strReplaceAll(mathml, '§', '&');
    mathml = this.strReplaceAll(mathml, '«', '<');
    mathml = this.strReplaceAll(mathml, '»', '>');
    mathml = this.strReplaceAll(mathml, '¨', '\'');

    mathml = this.strReplaceAll(mathml, '<mo>§#160;</mo>', '');
    mathml = this.strReplaceAll(mathml, '<mo>&nbsp;</mo>', '');
    mathml = this.strReplaceAll(mathml, '<mn>§#160;</mn>', '');
    mathml = this.strReplaceAll(mathml, '<mn>&nbsp;</mn>', '');

    mathml = mathml.replace(/<mo>\s<\/mo>/g, '');
    mathml = mathml.replace(/<mn>\s<\/mn>/g, '');

    const div = document.createElement('div');
    div.innerHTML = mathml.trim();

    const annotations = div.getElementsByTagName('annotation');

    try {
      while (annotations[0]) annotations[0].parentNode.replaceChild(annotations[0]);
    } catch (e) {
      // the format may differ with custom editor.
      while (annotations[0]) annotations[0].parentNode.removeChild(annotations[0]);
    }

    mathml = this.stripZeroWidthChars(div.innerHTML || '');

    return mathml || '';
  }

  static ensureNumericInput = (string) => {
    if (Number.isNaN(Number(string))) {
      return false;
    }
    return true;
  }

  static isScrollableArea = (scrollBottomRef) => {
    if (scrollBottomRef && scrollBottomRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollBottomRef.current;
      // parans around addition is important.
      if ((scrollTop + clientHeight) === scrollHeight) {
        return false;
      } else {
        return true;
      }
    }
  }

  /**
   * Flatten an array of objects with `children` arrays
   * @returns {[]} `any[]`
   */
  static flattenChildren = (data, propName = 'children') => {
    const getChildren = (data) => {
      if (!data || !data[propName] || !data[propName].length) {
        return data;
      } else {
        return [data, flatMapDeep(data[propName], getChildren)];
      }
    };
    return flatMapDeep(data, getChildren);
  };

  static shuffleArrayForUser(array, extraSeed) {
    // Shuffles the array using the current userId as the seed.
    const seedBase = userManager.userId.replace(/\D/g, '');
    let key = 0;

    if (extraSeed) {
      extraSeed = UtilsService.stripTagsAndEntities(extraSeed);
      extraSeed = UtilsService.stripLineBreaks(extraSeed);
      extraSeed = extraSeed.replace(/\s/g, '');

      key = extraSeed.length;
    }

    const seed = parseInt(seedBase + key);
    return UtilsService.shuffleArray(array, seed);
  }

  static shuffleArray(array, seed) {
    if (seed) {
      let temp, j;

      for (let i = 0; i < array.length; i++) {
        // Select a "random" position.
        j = (seed % (i + 1) + i) % array.length;

        // Swap the current element with the "random" one.
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      for (let i = 0; i < array.length - 1; i++) {
        array.push(array.shift());
      }
    } else {
      let count = 0;
      while (count < 100) {
        array.sort(() => { return 0.5 - Math.random(); });
        count++;
      }
    }
    return array;
  }

  static guaranteedShuffle(items) {
    // Don't attempt to shuffle if 0 or 1 items.
    if (items.length < 2) {
      return items;
    }

    const shuffled = shuffle(items);
    let isShuffled = false;

    for (let i = 0; i < items.length; i++) {
      if (items[i] !== shuffled[i]) {
        isShuffled = true;
        break;
      }
    }

    if (isShuffled) {
      return shuffled;
    } else {
      return UtilsService.guaranteedShuffle(items);
    }
  }

  static removeDuplicates = (arr = [], prop = 'id') => {
    return arr.filter((v1, i, a) => !v1[prop] || a.findIndex((v2) => (v2[prop] && v1[prop] && (v2[prop] === v1[prop]))) === i);
  };

  static markDuplicatesAsHidden = (arr = [], prop = 'id', hiddenPropName = 'hidden') => {
    return arr.map((v1, i, a) => {
      if (!v1[prop]) {
        return {
          ...v1,
          [hiddenPropName]: false,
        };
      } else {
        const currentIndex = a.findIndex((v2) => v2[prop] && v1[prop] && v2[prop] === v1[prop]);
        if (currentIndex === i) {
          return {
            ...v1,
            [hiddenPropName]: false,
          };
        } else {
          return {
            ...v1,
            [hiddenPropName]: true,
          };
        }
      }
    });
  };

  /** more robust version of `stripTagsAndEntities` */
  static stripHtmlTagsAdvanced = (str, ...htmlTagsToKeep) => {
    if (typeof str === 'string' && str?.length) {
      return str.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
        // eslint-disable-next-line prefer-template
        return htmlTagsToKeep.includes(tag) ? '<' + endMark + tag + '>' : '';
      }).replace(/<!--.*?-->/g, '') // strip all html tags except those specified in `htmlTagsToKeep`
        .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig, '') // strip all html entities
        .trim();
    } else {
      return str;
    }
  }

  static hasHtmlTags = (str = '', ...htmlTagsToIgnore) => {
    try {
      parse(str, {
        replace: (domNode) => {
          if (domNode.type === 'tag' && !htmlTagsToIgnore?.includes(domNode.name)) {
            throw new TypeError('UNIGNORED_HTML_TAG_FOUND');
          }
        }
      });
    } catch (error) {
      if (error.message === 'UNIGNORED_HTML_TAG_FOUND') {
        return true;
      }
    }
  }

  static stripNonNumeric = (str) => {
    return typeof str === 'string' ? str.replace(/[^\d.-]/g, '') : str;
  }

  static stripTagsAndEntities(str) {
    if (!str || typeof str !== 'string') {
      return str || '';
    }
    str = UtilsService.stripTags(str);
    str = UtilsService.stripEntities(str);
    return str;
  }

  /**
   * Strips tags from a string.
   */
  static stripTags(str) {
    if (!str) {
      return '';
    }
    str = str.replace(/<\/?[^>]+>/gi, '');
    return str;
  }

  /**
   * Converts common entities.
   */
  static stripEntities(str) {
    str = str.replace(/&nbsp;/g, ' ');
    return str;
  }

  static stripLineBreaks(str) {
    // Removes all line breaks from a string.
    if (!str) {
      return '';
    }
    str = str.replace(/[\n\r]/g, '');
    return str;
  }

  static stripZeroWidthChars = (str = '') => {
    if (typeof str !== 'string') {
      return str;
    }
    return str.replace(/[\u200B-\u200D\uFEFF]/g, '').trim();
  }

  /**
   * Remove all parentheses content from a given string.
   *
   * Example usage:
   *
   * ```
   * const str = 'This is (some text) with (parentheses) inside.';
   * const output = stripParenthesesContent(str); // output: This is with inside.
   * ```
   */
  static stripParenthesesContent(str = '') {
    if (typeof str !== 'string' || !str) {
      return str;
    }
    return str.replace(/\([^)]*\)/g, '');
  }

  static async exitPlayer(suffix) {
    if (window && window.location) {
      if (!this.isNullOrEmpty(suffix)) {
        window.parent.postMessage(`hideIframe${suffix}`, toolbarManager.externalUrl);
        window.parent.postMessage(`closePressed${suffix}`, toolbarManager.externalUrl);
      } else {
        window.parent.postMessage('hideIframe', toolbarManager.externalUrl);
        window.parent.postMessage('closePressed', toolbarManager.externalUrl);
      }
    }
    window.close();
  }

  static async satellitePreview() {
    if (window && window.location) {
      window.parent.postMessage('satellitePreview', toolbarManager.externalUrl);
    }

  }

  static truncateText(txt, charCount, settings) {
    settings = settings || {};

    let ellipsis = ' ...';

    if (settings.noEllipsis) {
      ellipsis = '';
    }
    if (txt.length <= charCount) {
      return txt;
    } else {
      return txt.trim().substring(0, charCount).split(' ').slice(0, -1)
        .join(' ') + ellipsis;
    }
  }

  static stripWrappingParagraphTags = (str = '') => {
    if (typeof str === 'string') {
      str = str.trim();
      return str.replace(/^<p[^>]*>|<\/p>$/g, '');
    }
    return str;
  };

  static replaceHtmlEntitiesWithTags = (str = '') => {
    const div = document.createElement('div');
    div.innerHTML = str;
    return div.textContent || div.innerText;
  }

  static stripInvalidFilenameChars = (str, replaceValue = '-') => {
    if (typeof str !== 'string') {
      return '';
    }
    return str.replace(/[/\\?%*:|"<> ]/g, replaceValue);
  }

  static isObjectNullOrEmpty = (object) => {
    if (typeof object === 'undefined') {
      return true;
    }

    if (object === null) {
      return true;
    }

    return (Object.keys(object).length === 0);
  }

  static objectSwapKeyValue(obj) {
    const ret = {};
    Object.keys(obj).forEach((key) => {
      ret[obj[key]] = key;
    });
    return ret;
  }

  static getResponseItemsObjArrayFromStringArray = (responseItems = []) => {
    if (!Array.isArray(responseItems)) {
      console.error('getResponseItemsObjArrayFromStringArray: responseItems must be an array');
    } else {
      return responseItems.map((responseItem, index) => {
        if (typeof responseItem === 'string') {
          const responseItemText = responseItem;
          return {
            id: this.getHashCodeIdFromStrAndIndex(responseItemText, index),
            text: responseItemText
          };
        } else {
          return {
            ...(responseItem || {}),
            id: responseItem?.id || this.getHashCodeIdFromStrAndIndex(responseItem?.text, index),
            text: responseItem?.text || ''
          };
        }
      });
    }
  }

  static getHashCodeIdFromStrAndIndex = (str, index = 0) => {
    const hash = this.getHashCode(`${str}${index}`);
    const hashStr = hash < 0 ? `1${Math.abs(hash)}` : `${hash}`;
    return hashStr;
  }

  static getHashCode = (str) => {
    let hash = 0, i, chr;
    if (!str || str?.length === 0) {
      return hash;
    }
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  static createGuid() {
    const guid = (`${this.getRandom()
      + this.getRandom()
      + this.getRandom()
       }4${
       this.getRandom().substr(0, 3)
       }${this.getRandom()
       }${this.getRandom()
       }${this.getRandom()
       }${this.getRandom()}`).toUpperCase();
    return guid;
  }

  static getRandom() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  static isConnectionError(exception) {
    return exception.className === INCOMPLETE_REPLY_EXCEPTION || exception.className === NOT_FOUND_EXCEPTION;
  }

  static isLockedError(exception) {
    return exception.className === LOCKED_ERROR;
  }

  static isReviewError(exception) {
    return exception.className === REVIEW_ERROR;
  }

  static isInternalError(exception) {
    return exception.className === INTERNAL_ERROR;
  }

  static isSystemError(exception) {
    return exception.className === SYSTEM_ERROR;
  }

  /**
   * @param {number} m `number`
   * @param {number} n `number`
   * @returns `(m / n) * 100`
   */
  static M_isWhatPercentageOf_N = (m, n) => {
    if (n === 0) {
      console.error('M_isWhatPercentageOf_N: cannot divide `m` by zero');
      return;
    }
    const percentage = (m / n) * 100;
    return percentage;
  };

  /** Given a number, subtract an amount equal to `(ONE_FULL_CIRCLE_ROTATION * fullCircleRotationCount)` */
  static subtractFullCircleRotations = (num) => {
    if (typeof num !== 'number') {
      return;
    } else if (Math.abs(num) < ONE_FULL_CIRCLE_ROTATION) {
      return num;
    }
    const fullCircleRotationCount = this.getFullCircleRotationCount(num);

    if (num >= 0) {
      const adjustedPositiveRotationValue = Math.round(
        num - (ONE_FULL_CIRCLE_ROTATION * fullCircleRotationCount)
      );
      return adjustedPositiveRotationValue;
    } else if (num < 0) {
      const adjustedNegativeRotationValue = Math.round(
        num + (ONE_FULL_CIRCLE_ROTATION * fullCircleRotationCount)
      );
      return adjustedNegativeRotationValue;
    }
  };

  /** Given a number, track how many times it was rotated by `ONE_FULL_CIRCLE_ROTATION`  */
  static getFullCircleRotationCount = (num) => {
    if (typeof num !== 'number') {
      return;
    }
    const fullRotations = Math.floor(Math.abs(num) / ONE_FULL_CIRCLE_ROTATION);

    return fullRotations;
  };

  static getKey = () => Math.floor((Math.random() * 10000) + 1);

  static replaceParagraphWithSpan = (str) => {
    // Create a new DOM element
    const tempElement = document.createElement('span');
    tempElement.innerHTML = str;

    // Select all the paragraph elements within the tempElement
    const paragraphs = tempElement.querySelectorAll('p');

    // Iterate over each paragraph and replace it with a span
    paragraphs.forEach((paragraph) => {
      const span = document.createElement('span'); // Create a new span element
      span.textContent = paragraph.textContent; // Copy the text content from the paragraph
      paragraph.parentNode.replaceChild(span, paragraph); // Replace the paragraph with the span
    });

    const parsed = tempElement.innerHTML;

    return parsed;
  }

  // START JQuery functions
  static $_getElementByDataId(id, $container) {
    return $container.find(`*[data-id="${ id }"]`);
  }

  static $_getElement(elementOrId, $container) {
    let $element = null;
    const hasContainer = $container != null;
    $container = $container || $('body');

    if (typeof elementOrId === 'string') {
      $element = $(`#${ elementOrId}`);

      if (hasContainer || $element.length === 0) {
        $element = this.$_getElementByDataId(elementOrId, $container);
      }
    } else {
      $element = elementOrId;
    }
    return $element;
  }
  // END JQuery functions
}
