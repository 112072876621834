import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { useDroppable } from '@dnd-kit/core';

import styleService from '../services/StyleService';

const DndKitDroppable = observer((props = {}) => {
  const { className, droppableId, index, style, title } = props;

  // eslint-disable-next-line no-empty-pattern
  const {
    /* managers */
  } = useContext(MobXProviderContext);

  const { isOver, setNodeRef } = useDroppable({
    data: {
      droppableId,
      index
    },
    id: droppableId
  });

  const { getStyleVar } = styleService;

  return (
    <div
      ref={setNodeRef}
      className={classNames(className, 'dnd-kit-droppable')}
      style={{
        ...style,
        ...(isOver ? {
          backgroundColor: getStyleVar('--theme-drag-drop-dragover-color')
        } : {})
      }}
      title={title}>
      {props.children}
    </div>
  );
});

export default DndKitDroppable;
