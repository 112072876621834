import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { PDFObject } from 'react-pdfobject';
import lessonService from '../../services/LessonService';
import '../../../css/PdfResource.scss';

const PdfResource = observer(({ lessonElementId, model }) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  lessonService.getResource(model.contentItemId);

  return (
    <>
      <div className='element-container PdfResource' id={`_${model.id}`}>
        <PDFObject height='95%' url={lessonManager.lessonResourceUrl} width='95%' />
      </div>
    </>
  );
});
export default PdfResource;
