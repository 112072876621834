import { useContext, useEffect, useRef, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import HoverPlugin from 'wavesurfer.js/dist/plugins/hover';
// import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import RecordPlugin from '../services/WaveSurferRecordPluginService'; // drop-in replacement for original RecordPlugin

import '../../css/WaveSurferPlayer.scss';

import mediaMute from '../../images/player-icons/media-mute.png';
import mediaPause from '../../images/player-icons/media-pause.png';
import mediaPlay from '../../images/player-icons/media-play.png';
import mediaRecordCircle from '../../images/player-icons/media-record-circle.svg';
import mediaVolume from '../../images/player-icons/media-volume.png';

import { register } from '../../i18n';

import { useWaveSurfer } from '../../hooks/useWaveSurfer';

import utilsService from '../services/UtilsService';

const t = register('MediaRecorder');

const WaveSurferPlayer = observer(({ internalOptions, ...props } = {}) => {
  const {
    currentBlobUrl,
    isRecording,
    isVideo,
    lessonElementId,
    videoRecorderPermission,
    videoRecordedPlayerRef
  } = props;

  const isInitializing = isVideo && !videoRecorderPermission;

  // eslint-disable-next-line no-empty-pattern
  const { uploadManager } = useContext(MobXProviderContext);

  const containerRef = useRef();

  const [isPlaying, setIsPlaying] = useState(false);

  const [isMuted, setIsMuted] = useState(false);
  const [volumeSliderValue, setVolumeSliderValue] = useState(50);

  const [currentDurationSeconds, setCurrentDurationSeconds] = useState(0);

  const [totalDurationStr, setTotalDurationStr] = useState('00:00');

  const wavesurfer = useWaveSurfer(containerRef, internalOptions);

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    setCurrentDurationSeconds(0);
    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on('ready', () => {
        const durationSeconds = wavesurfer.getDuration();

        const formattedDurationStr = utilsService.formatTimeMMSS(durationSeconds);
        setTotalDurationStr(formattedDurationStr);

        const adjustedVolume = volumeSliderValue / 100;
        wavesurfer.setVolume(adjustedVolume);

        if (isVideo && videoRecordedPlayerRef?.current) {
          videoRecordedPlayerRef.current.onvolumechange = (event) => {
            setIsMuted(event.target.muted);

            if (typeof videoRecordedPlayerRef?.current?.volume === 'number') {
              const adjustedVolumeSliderValue = videoRecordedPlayerRef.current.volume * 100;
              setVolumeSliderValue(adjustedVolumeSliderValue);
            }
          };
        }
      }),
      wavesurfer.on('play', () => setIsPlaying(true)),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('timeupdate', (currentDurationSeconds) => setCurrentDurationSeconds(currentDurationSeconds))
    ];

    const hover = !isRecording ? wavesurfer.registerPlugin(HoverPlugin.create({
      pluginName: 'hover'
    })) : undefined;

    const record = wavesurfer.registerPlugin(RecordPlugin.create({
      pluginName: 'record',
      mimeType: 'audio/webm'
    }));

    if (isRecording) {
      record.startRecording({ isRecording });
    }

    record.on('record-start', ({ mediaRecorder } = {}) => {
      if (isVideo) {
        uploadManager.addToWaveSurferVideoMediaRecorderMap(lessonElementId, mediaRecorder);
      }
    });

    record.on('record-end', (recordedBlob) => {
      record.stopRecording();
      props.onChangeMedia(recordedBlob);
    });

    // on unmount
    return () => {
      for (const unsub of subscriptions) {
        unsub();
      }
      hover?.destroy();
      record?.destroy();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesurfer]);

  const handleRecord = () => {
    if (!isRecording) {
      props.onRecordStart();
    } else {
      props.onRecordEnd();
    }
  };

  const handlePlayClick = /* useCallback( */() => {
    wavesurfer?.isPlaying() ? wavesurfer?.pause() : wavesurfer?.play();
  };

  const handleVolumeChange = (event) => {
    if (!wavesurfer || isWaveSurferZeroState) {
      return;
    }
    const volume = +event.target.value;
    const adjustedVolume = volume / 100;

    wavesurfer.setVolume(adjustedVolume);
    setVolumeSliderValue(volume);
  };

  const handleToggleMute = (_event) => {
    if (!wavesurfer || isWaveSurferZeroState) {
      return;
    }
    wavesurfer.setMuted(!isMuted);
    setIsMuted(!isMuted);
  };

  const renderRecordControl = () => {
    return !isInitializing ? (
      <div className={classNames('ws-record', {
        recording: isRecording,
      })} onClick={handleRecord}>
        <img alt={isRecording ? t('stopRecording') : t('recording')}
          className={classNames('ws-record-icon', {
            'blink red': isRecording,
            'recording': isRecording,
          })}
          src={mediaRecordCircle}
        />
      </div>
    ) : null;
  };

  const renderPlayPauseControl = () => {
    return (
      <div className='ws-play-pause-button' onClick={handlePlayClick}>
        <img alt={isPlaying ? t('pauseButton') : t('playButton')}
          className={classNames('ws-play-pause-button-icon', {
            playing: isPlaying
          })}
          src={isPlaying ? mediaPause : mediaPlay}
        />
      </div>
    );
  };

  const renderVolumeSliderControl = () => {
    return (
      <div className={classNames('ws-volume', {
        muted: isMuted
      })}>
        <div className={classNames('ws-volume-icon-wrapper', {
          muted: isMuted
        })} onClick={handleToggleMute}>
          <img
            alt={t('Volume')}
            className={classNames('ws-volume-icon', {
              muted: isMuted
            })}
            src={!isMuted ? mediaVolume : mediaMute}
          />
        </div>
        <input
          className='ws-volume-slider'
          disabled={isMuted}
          max={100}
          min={0}
          name='ws-volume-slider'
          onChange={handleVolumeChange}
          type='range'
          value={volumeSliderValue}
        />
      </div>
    );
  };

  const renderDurationControl = () => {
    return (
      <div className='ws-duration'>
        <span>{utilsService.formatTimeMMSS(currentDurationSeconds)}</span>
        <span>/</span>
        <span>{totalDurationStr}</span>
      </div>
    );
  };

  const isWaveSurferZeroState = !isRecording && !currentBlobUrl;

  return (
    <div className={classNames('WaveSurferPlayer', {
      initializing: isInitializing
    })}>
      <div ref={!isWaveSurferZeroState ? containerRef : undefined} className={classNames('WaveSurferPlayerWaveForm', {
        'ws-waveform-zero-state': isWaveSurferZeroState,
        'initializing': isInitializing
      })}>
        {isWaveSurferZeroState ? (
          <div className={classNames('ws-waveform-zero-state-text', {
            initializing: isInitializing
          })} onClick={handleRecord}>
            {/* {t('clickRecordToStart')} */}
            {isInitializing ? t('initializing') : t('clickRecordToStart')}
          </div>
        ) : null}
      </div>

      <div className='WaveSurferPlayerControls'>
        <div className={classNames('ws-record-control-wrapper', {
          initializing: isInitializing
        })}>
          {renderRecordControl()}
        </div>
        {(isRecording || isInitializing) && (
          <div className={classNames('ws-recording-text', {
            initializing: isInitializing,
          })} onClick={handleRecord}>
            <span>
              {!isInitializing && t('recording')}
            </span>
          </div>
        )}
        {!isRecording && !isInitializing && (
          <>
            <div className='ws-play-pause-control-wrapper'>
              {renderPlayPauseControl()}
            </div>
            <div className='ws-volume-slider-control-wrapper'>
              {renderVolumeSliderControl()}
            </div>
            <div className='ws-duration-control-wrapper'>
              {renderDurationControl()}
            </div>
            <div className='ws-duration-control-blank-area'>
              {/* placeholder */}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default WaveSurferPlayer;
