import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { LessonMode } from '../../../Constants';
import NumberLineService from '../../services/NumberLineService';
import HtmlComponent from '../HtmlComponent';
import Guideline from '../tools/Guideline';

import '../../../css/NumberLine.scss';
import useStyleEvents from '../../../hooks/useStyleEvents';
import { register } from '../../../i18n';
import PrintQuestionNumber from './PrintQuestionNumber';

const t = register('GlobalQuestionLabels');

const NumberLine = observer(({ lessonElementId }) => {
  const {
    numberLineManager,
    questionFeedbackManager,
    responseManager,
    lessonManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const model = lessonManager.getSlideModel(lessonElementId);
  const numberLineService = useMemo(() => new NumberLineService(model), [lessonElementId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    const init = async () => {
      await numberLineService.initialize();
      await numberLineService.runtimeObjectReady();
      await numberLineService.showResponse();

      setRerender(!rerender);
    };
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const feedbackState = questionFeedbackManager.getUiState(model.lessonElementId);
  if (feedbackState.showAnswerFeedback) {
    if (numberLineService.canvas) {
      numberLineService.showResponse();
      numberLineService.showValidationFeedback();
    }
  }

  if (feedbackState.showAnswers) {
    if (numberLineService.canvas) {
      numberLineService.drawCorrectAnswers();
    }
  }

  const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
  if (lessonElementState.isTryAgain) {
    numberLineService.showResponse();
  }

  // console.log(
  //   'showAnswers: ' + feedbackState.showAnswers,
  //   'showAnswerFeedback: ' + feedbackState.showAnswerFeedback,
  //   'try again :' + lessonElementState.isTryAgain,
  //   'canvas: ' + numberLineService.canvas !== null);

  const handleClear = () => {
    if (!feedbackState.showAnswerFeedback && !feedbackState.showAnswers) {
      numberLineService.clearAll();
    }
  };

  const handleDelete = () => {
    if (!feedbackState.showAnswerFeedback && !feedbackState.showAnswers) {
      numberLineService.deleteSelectedElement();
    }
  };

  const setActiveElementType = (elementType) => {
    if (!feedbackState.showAnswerFeedback && !feedbackState.showAnswers) {
      numberLineService.setElementEditMode(elementType);
    }
  };

  useStyleEvents(lessonElementId);

  return (
    <div className='element-container' id={`_${numberLineService.getId()}`}>
      {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
      <div className='testItemQuestion'>
        {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
        <HtmlComponent htmlStr={model.directions || model.questionText || ''} />
      </div>
      <div className='editSection'>
        <div className='sideBySide graphControls'>
          <button className='clearButton' onClick={handleClear}>{t('clearAll')}</button>
          <button className='deleteElementButton' onClick={handleDelete}>{t('deleteElement')}</button>
        </div>
        <div className='body canvasOrganizerBody' style={{ height: numberLineService.getHeight(), width: model.graphWidth }}>
          <canvas className='canvas' id={numberLineService.getCanvasId()} />
        </div>
        <div className='bottomControlsContainer'>
          <div className='numberLineBottomControls'>
            {numberLineService.ELEMENT_TYPES.map((elementType, index) => { // eslint-disable-line array-callback-return
              if (model.elementTypes.includes(elementType)) {
                return (
                  <button
                    key={elementType}
                    className={`elementTypeButton noFocusOutline icon ${elementType} ${(numberLineManager.elementEditMode === elementType) ? 'active' : ''}`}
                    data-element-type={elementType}
                    onClick={() => setActiveElementType(elementType)} />
                );
              } else {
                return (<></>);
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default NumberLine;
