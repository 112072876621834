import { action, computed, makeObservable, observable } from 'mobx';
import { PositionedTextBlock } from './obseverableObjects/PositionedTextBlock';
import { STAMP_TYPES } from './obseverableObjects/AnnotationStamp';

export const TOOL_TYPE = {
  PENCIL: 'pencil',
  SELECTION: 'selection',
  STAMPS: 'stamps',
  NOTES: 'notes',
};

export class BookDrawManager {
  constructor() {
    makeObservable(this);
  }

  @observable textBlocks = new Map();
  @observable stamps = new Map();
  @observable drawingMode = false;
  @observable tool = null;
  @observable drawEditMode = false;

  @observable selectedIcon = null;
  @observable selectedStamp = null;
  zoom = -1;

  entityId = null;

  dragProps = null;
  lessonElementId = null;

  // prevent data for a previous slide from being used in the current slide
  @action init(lessonElementId, pages, entityId) {
    if (this.lessonElementId !== lessonElementId) {
      this.lessonElementId = lessonElementId;
      this.textBlocks = new Map();
      this.entityId = entityId;
      this.stamps = new Map();

      for (const page of pages) {
        if (page && page.textBlocks && page.textBlocks.length > 0) {
          for (const textBlock of page.textBlocks) {
            const positionedText = new PositionedTextBlock();
            positionedText.setData(textBlock);

            const pageTextBlocks = this.textBlocks.get(page.id);
            if (pageTextBlocks) {
              pageTextBlocks.push(positionedText);
              this.textBlocks.set(page.id, pageTextBlocks);
            } else {
              const newBlockArray = [positionedText];
              this.textBlocks.set(page.id, newBlockArray);
            }
          }
        }
      }

      this.dragProps = null;
      this.tool = null;
      this.selectedIcon = null;
      this.drawingMode = false;
    }
  }

  @action
  setDrawingMode(mode) {
    this.drawingMode = mode;
  }

  @action
  setTool(tool) {
    this.tool = tool;
  }

  @action
  setSelectedStamp(stamp) {
    this.selectedStamp = stamp;
  }

  @action
  setSelectedIcon(icon) {
    this.selectedIcon = icon;
  }

  @action
  setDrawEditMode(mode) {
    this.drawEditMode = mode;
  }

  @action
  deleteCurrentStamp=() => {
    if (this.selectedStamp) {
      const pageStamps = this.stamps.get(this.selectedStamp.pageId);
      if (pageStamps && pageStamps.length > 0) {
        const index = pageStamps.findIndex((stamp) => stamp.id === this.selectedStamp.stampId);
        if (index >= 0) {
          pageStamps.splice(index, 1);
          this.stamps.set(this.selectedStamp.pageId, pageStamps);
          this.setSelectedStamp(null);
        }
      }
    }
  }

  @action
  setDiscussionCounts = (summarys) => {
    if (summarys && summarys.length > 0) {
      for (const summary of summarys) {
        const it = this.stamps.values();
        if (it) {
          const pageStampArrays = Array.from(it);
          for (const stamps of pageStampArrays) {
            for (const stamp of stamps) {
              if (stamp.id === summary.id) {
                stamp.setSummaryCount(summary.count);
              }
            }
          }
        }
      }
    }
  }

  getStampIds = () => {
    const it = this.stamps.values();
    if (it) {
      const stampIds = [];
      const pageStampArrays = Array.from(it);
      for (const stamps of pageStampArrays) {
        for (const stamp of stamps) {
          if (stamp.type === STAMP_TYPES.NOTE) {
            stampIds.push(stamp.id);
          }
        }
      }
      return stampIds;
    }
    return [];
  }

  @action
  updateStamp = (pageId, stampId, topPercent, leftPercent) => {
    const pageStamps = this.stamps.get(pageId);
    for (const stamp of pageStamps) {
      if (stamp.id === stampId) {
        stamp.setLeftPercent(leftPercent);
        stamp.setTopPercent(topPercent);
        return stamp;
      }
    }
    return null;
  }

  @action
  addDiscussions = (discussions) => {
    if (discussions && discussions.length === 1) {
      if (discussions[0].id === null) {
        return;
      }
    }
    for (const discussion of discussions) {
      if (this.selectedStamp) {
        const stamp = this.getStamp(this.selectedStamp.pageId, this.selectedStamp.stampId);
        if (stamp) {
          stamp.setDiscussion(discussion.id, discussion);
        }
      }
    }
  }

  resolveDiscussion = (discussionId, isResolved) => {
    if (this.selectedStamp) {
      const stamp = this.getStamp(this.selectedStamp.pageId, this.selectedStamp.stampId);
      if (stamp) {
        stamp.resolveDiscussion(discussionId, isResolved);
      }
    }
  }

  @action
  addStamp(pageId, stamp) {
    let array = this.stamps.get(pageId);
    if (array) {
      const index = array.findIndex((currentStamp) => (currentStamp.id === stamp.id));

      if (index >= 0) {
        array[index] = stamp;
      } else {
        array.push(stamp);
      }
    } else {
      array = [stamp];
    }
    this.stamps.set(pageId, array);
  }

  selectStamp(pageId, stampId) {
    const stamp = this.getStamp(pageId, stampId);

    this.setTool(TOOL_TYPE.SELECTION);

    if (this.selectedStamp && this.selectedStamp.stampId) {
      if (stampId === this.selectedStamp.stampId) {
        this.setSelectedStamp(null);
        return;
      }
    }
    this.setSelectedStamp({ stampId, pageId, type: stamp.type });
  }

  clearNotes = () => {
    if (this.selectedStamp !== null) {
      const stamp = this.getStamp(this.selectedStamp.pageId, this.selectedStamp.stampId);
      stamp.discussions.clear();
    }
  }

  @computed
  get isNotesOpen() {
    if (this.selectedStamp !== null) {
      if (this.selectedStamp.type === STAMP_TYPES.NOTE) {
        return true;
      }
    }
    return false;
  }

  getStamps = (pageId) => {
    const array = this.stamps.get(pageId);
    if (array) {
      return array;
    }
    return [];
  }

  getTextBoxes = (pageId) => {
    const array = this.textBlocks.get(pageId);
    if (array) {
      return array;
    }
    return [];
  }

  getStamp = (pageId, stampId) => {
    const array = this.stamps.get(pageId);
    if (array && array.length > 0) {
      const findIndex = array.findIndex((s) => s.id === stampId);
      const stamp = array[findIndex];
      if (typeof stamp != 'undefined') {
        return stamp;
      }
      return null;
    }
    return null;
  }
}

export default new BookDrawManager();
