import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ToolbarButton from './ToolbarButton';
import ToolbarDropdownMenu from './ToolbarDropdownMenu';

import '../../../css/ButtonGroup.scss';

const MenuGroup = observer(({ buttonGroup }) => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const buttonGroupSettings = toolbarManager.getButtonGroupSettings(buttonGroup.id);

  return (
    <ToolbarDropdownMenu MenuItemComponent={ToolbarButton}
      buttonGroupSettings={buttonGroupSettings}
      className={`toolbar-dropdown ${buttonGroupSettings.id} ${(!buttonGroupSettings.visible) ? 'visible-none' : ''}`}
      items={buttonGroup.children}
    />
  );
});

const FlatGroup = observer(({ buttonGroup }) => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const buttonGroupSettings = toolbarManager.getButtonGroupSettings(buttonGroup.id);

  if (!buttonGroupSettings) {
    return (<></>);
  }

  return (

    <div key={`${buttonGroup.id}-toolbar-group`}
      className={`${(!buttonGroupSettings.visible) ? 'visible-none' : ''} flat-menu-group ${buttonGroupSettings.className} ${buttonGroupSettings.id}`}
      role='menu' tabIndex='-1'>
      {

        buttonGroup.children && buttonGroup.children.map((button) => {
          if (button.type === 'button') {
            return (<ToolbarButton key={`${button.id}-toolbar-button`} buttonId={button.id} />);
          } else {
            const buttonGroup = toolbarManager.getButtonGroupSettings(button.id);

            return (!buttonGroup) ?
              <div key={`${button.id}-toolbar-group`} style={{ display: 'none' }} />
              : (buttonGroup.isMenu)
                ? <MenuGroup key={`${button.id}-toolbar-group`} buttonGroup={button} />
                : <FlatGroup key={`${button.id}-toolbar-group`} buttonGroup={button} />;
          }
        })
      }
    </div>
  );
});

const ButtonGroup = observer(({ buttonGroup }) => {
  const {
    toolbarManager
  } = useContext(MobXProviderContext);

  const buttonGroupSettings = toolbarManager.getButtonGroupSettings(buttonGroup.id);
  const anyChildrenEnabled = buttonGroup.children.some(({ id, type }) => {
    if (type === 'button') {
      const settings = toolbarManager.buttonSettings.get(id);
      if (settings == null) {
        return false;
      }
      return settings.visible;
    }
    return true;
  });

  if (!buttonGroupSettings || !anyChildrenEnabled) {
    return (<></>);
  }
  return (
    <>
      {(buttonGroupSettings.isMenu)
        ? <MenuGroup buttonGroup={buttonGroup} />
        : <FlatGroup buttonGroup={buttonGroup} />}
    </>
  );
});

export default ButtonGroup;
