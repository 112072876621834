import React from 'react';
// import Auth from '../../../services/AuthService';

const ImageWidget = (props) => {
  const { model, url } = props;
  const display = (model.display) ? model.display : 'visible';

  return (
    <div className={`centered-content image ${display}`} id={model.id}>
      <img alt={model.contentItem.name} className='fit-content' src={url} />
    </div>
  );
};

export default ImageWidget;
