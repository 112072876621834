import React from 'react';
import RadioButton from '../RadioButton';
import NumberCircle from '../NumberCircle';
import DOK from '../DOK';
import CustomFeedback from '../CustomFeedback';
import QuestionDependency from '../QuestionDependency';
import OptionalImage from '../../lessonPlayer/components/OptionalImage';
import Utils from '../../lessonPlayer/services/UtilsService';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const ClozeCombo = ({ model, isManuscriptShort }) => {
  let body;
  if (model.body) {
    body = model.body.replaceAll('contenteditable', 'content');
  }

  // put all the answers in a single array and map them by id
  let allAnswers = [];
  for (const prompt of model.validation.prompts) {
    allAnswers = [...allAnswers, ...prompt.answers];
  }
  const answerMap = Utils.mapById(allAnswers);

  return (
    <div className='element-container' id={`_${model.id}`}>
      <div className='label-value-row'>
        <div>Question Type:</div>
        <div>Fill In The Blank Multi-Choice</div>
      </div>
      <div className='label-value-row'>
        <div>Question Number:</div>
        <div>{model.questionNumber}</div>
      </div>
      <div className='label-value-row'>
        <div>Point Value:</div>
        <div>{model.maxScore}</div>
      </div>
      <div className='label-value-row'>
        <div className='text-area-label'>Directions:</div>
        <div className='ckeditor-text text-area'><HtmlComponent htmlStr={model.questionText} /></div>
      </div>
      <OptionalImage model={model} />
      <div className='label-value-row'>
        <div className='text-area-label'>Text:</div>
        <div className='ckeditor-text text-area has-number-circles'><HtmlComponent htmlStr={body} /></div>
      </div>
      {model.validation.prompts.map((prompt, promptIdx) => {
        return (
          <div key={prompt.id}>
            <div className='choices'>
              <div className='has-number-circles'><NumberCircle number={promptIdx + 1} />Choices:</div>
              <div>Correct</div>
              {prompt.answers.map((answer, answerIdx) => {
                const validation = answerMap[answer.id];
                const selected = validation.isCorrect;
                const answerLetter = String.fromCharCode(65 + answerIdx);

                return (
                  <React.Fragment key={answer.id}>
                    <div className='choice-letter'>{`${answerLetter}:`}</div>
                    <div className='ckeditor-text text-area'><HtmlComponent htmlStr={answer.text} /></div>
                    <div className='correct-button'>
                      <RadioButton selected={selected} />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
      {/* advanced options */}
      {!isManuscriptShort && (
        <>
          <QuestionDependency model={model} />
          <div className='label-value-row'>
            <div>Extra Class:</div>
            <div>{model.extraClass}</div>
          </div>
          <div className='label-value-row'>
            <div>Student Answer Format:</div>
            <div>{model.responseFormat}</div>
          </div>
          <div className='label-value-row'>
            <div>Auto-scored:</div>
            <div>{model.autoScore.toString()}</div>
          </div>
        </>
      )}
      <CustomFeedback model={model} />
      <div className='label-value-row'>
        <div>Hint:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.hint} /></div>
      </div>
      <div className='label-value-row'>
        <div>Solution:</div>
        <div className='ckeditor-text'><HtmlComponent htmlStr={model.validation.solution} /></div>
      </div>
      {!isManuscriptShort && (
        <>
          <div className='label-value-row'>
            <div>Survey Question:</div>
            <div>{(model.survey) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Do Not Score:</div>
            <div>{(model.unscored) ? 'ON' : 'OFF'}</div>
          </div>
          <div className='label-value-row'>
            <div>Randomize Answers:</div>
            <div>{(model.isRandomized) ? 'ON' : 'OFF'}</div>
          </div>
          <DOK model={model} />
        </>
      )}
    </div>
  );
};

export default ClozeCombo;
