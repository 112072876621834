import React, { useContext, useRef } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../css/CaptionedImageButton.scss';

import useAccessibilityClick from '../../hooks/useAccessibilityClick';

const CaptionedImageButton = observer((props) => {
  const {
    // id,
    changed,
    className,
    disabled,
    isSelected,
    label,
    value
  } = props;

  // eslint-disable-next-line no-empty-pattern
  const {
    /* managers */
  } = useContext(MobXProviderContext);

  const captionedImageButtonRef = useRef(null);

  useAccessibilityClick(captionedImageButtonRef, (_event) => {
    const value = captionedImageButtonRef?.current?.id;
    if (value) {
      changed({ target: { value } });
    }
  });

  return (
    <div ref={captionedImageButtonRef}
      className={classNames('CaptionedImageButton', className, {
        disabled,
        selected: isSelected
      })} id={value}>
      <div className={classNames('captioned-image-button-icon-wrapper', {
        selected: isSelected
      })}>
        <div className={classNames('captioned-image-button-icon', className, {
          'mask-image': !isSelected,
          'mask-image-inverse': isSelected,
          'selected': isSelected
        })} />
      </div>
      <div className={classNames('captioned-image-button-label', {
        selected: isSelected
      })}>
        <strong>{label}</strong>
      </div>
    </div>
  );
});

export default CaptionedImageButton;
