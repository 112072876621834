import React, { useContext, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactModal from 'react-modal-resizable-draggable';
import { MobXProviderContext, observer } from 'mobx-react';
import { register } from '../../../i18n';
import toolbarService from '../../services/ToolbarService';
import RichTextEditor, { BASIC_CKEDITOR_TOOLBAR, MATH_TOOLBAR } from '../RichTextEditor';
import { LessonMode } from '../../../Constants';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import studentActivityService from '../../services/StudentActivityService';
import '../../../css/NotepadTool.scss';
import useReactModalResizeableDraggable from '../../../hooks/useReactModalResizeableDraggable';

const t = register('ToolNames');
const t2 = register('GlobalQuestionLabels');

const Notepad = observer(() => {
  const {
    toolbarManager,
    studentActivityManager,
    lessonManager
  } = useContext(MobXProviderContext);

  const notePadSettings = toolbarService.getButtonSettings('Notepad');

  const isReadOnly = (lessonManager.playerMode !== LessonMode.ACTIVITY_MODE && lessonManager.playerMode !== LessonMode.PREVIEW);

  const toolbar = BASIC_CKEDITOR_TOOLBAR;
  const mathToolbar = MATH_TOOLBAR;
  const [enteredText, setEnteredText] = useState((studentActivityManager) ? studentActivityManager.activityNoteText : '');
  const saveNoteRef = useRef();

  useAccessibilityClick(saveNoteRef, (e) => {
    if (lessonManager.playerMode === LessonMode.ACTIVITY_MODE) {
      // If the text that was entered is the same as the text that is currently saved there is no need to save again.
      if (enteredText !== studentActivityManager.activityNoteText) {
        studentActivityService.saveActivityNote(enteredText);
      }
    }
  });

  const closeNoteRef = useRef();
  useAccessibilityClick(closeNoteRef, (e) => {
    toolbarManager.setIsNotepadOpen(false);
  });

  useReactModalResizeableDraggable('notepadTool');

  return (
    createPortal(
      <div className='notepadTool'>
        <ReactModal
          initHeight={300}
          initWidth={280}
          isOpen={true}
          left={5}
          top={150}>
          <div className='note-body'>
            <div className='tool-header'>
              <div className='title-label basic'>{t('notepadLabel')}</div>
            </div>
            <div className='editor-wrapper'>
              <div className='cke_topspace'>
                <RichTextEditor data={(studentActivityManager) ? studentActivityManager.activityNoteText : ''}
                  onChange={(data) => setEnteredText(data)}
                  readOnly={isReadOnly} toolbar={(notePadSettings.ckeditor === 'math')?mathToolbar:toolbar} />
              </div>
            </div>
            <div className='footer-buttons'>
              {(!isReadOnly) ?
                <button ref={saveNoteRef} className='submit-response primary'>{t2('save')}</button> : ''}
            </div>
          </div>
        </ReactModal>
      </div>, document.getElementById('the-body'))
  );
});

export default Notepad;
