import React, { useEffect, useState } from 'react';
import Auth from '../../services/AuthService';
import '../../../css/ImageQuestion.scss';
import DirectionsFlyout from '../tabbedFlyout/DirectionsFlyout';
import lessonService from '../../services/LessonService';
import useStyleEvents from '../../../hooks/useStyleEvents';
import UtilsService from '../../services/UtilsService';

const ImageQuestion = ({ lessonElementId, model }) => {
  const horizontalAlign = (model.horizontalAlign) ? model.horizontalAlign : 'left';
  const imgSrc = `${Auth.ecms}/api/redirectToResource?contentItemId=${model.resourceContentItemId}&authKey=${Auth.authKey}`;

  let wrapperClasses = `image-box ${horizontalAlign}`;
  let imgClasses = '';
  let imgStyle = {};

  const [altText, setAltText] = useState('');

  if (model.backgroundImageLayout === 'manual') {
    imgStyle = { width: model.backgroundSize ? model.backgroundSize : '100%', height: 'auto', top: `${model.imageTop ? model.imageTop : 0}px`, left: `${model.imageLeft ? model.imageLeft : 0}px`, position: 'absolute' };
    wrapperClasses = 'image-box manual';
  }
  if (model.backgroundImageLayout === 'contain') {
    imgClasses = 'fit-to-screen';
  }
  if (model.backgroundImageLayout === 'cover') {
    imgClasses = 'cover';
  }

  useStyleEvents(lessonElementId, `${lessonElementId}-activity-view`);

  useEffect(() => {
    lessonService.setIsInteractiveFlyoutOpen(true);
    const doAction = async () => {
      if (UtilsService.isNullOrEmpty(model.altText) && !model.altTextLoaded) {
        const contentItem = await lessonService.fetchContentItem(model.contentItemId);
        if (contentItem && contentItem.altText) {
          setAltText(contentItem.altText);
          model.altText = contentItem.altText;
          model.alTextLoaded = true;
        }
      }
    };
    doAction();
  }, []);

  return (
    <div id={`${lessonElementId}-activity-view`}>
      {(model.directions) ? <DirectionsFlyout directions={model.directions} isActivity={true} lessonElementId={model.lessonElementId} /> : ''}
      <div className='ImageQuestion element-container'>
        <div className={wrapperClasses}>
          <img alt={model.altText || altText || ''} className={imgClasses} src={imgSrc} style={imgStyle} />
        </div>
        {/* <QuestionDependency model={model} /> */}
      </div>
    </div>
  );
};
export default ImageQuestion;
