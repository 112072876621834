import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Provider } from 'mobx-react';
import '@szhsin/react-menu/dist/index.css';
import './css/Fonts.scss';
import './css/themevars.scss';

import alignmentManager from './lessonPlayer/managers/AlignmentManager';
import audioManager from './lessonPlayer/managers/AudioManager';
import drawManager from './lessonPlayer/managers/DrawManager';
import bookDrawManager from './lessonPlayer/managers/BookDrawManager';
import clozeMultichoiceManager from './lessonPlayer/managers/ClozeMultichoiceManager';
import eventListenerManager from './lessonPlayer/managers/EventListenerManager';
import graphPaperManager from './lessonPlayer/managers/GraphPaperManager';
import highlightAnnotationManager from './lessonPlayer/managers/HighlightAnnotationManager';
import lessonManager from './lessonPlayer/managers/LessonManager';
import navMenuManager from './lessonPlayer/managers/NavMenuManager';
import numberLineManager from './lessonPlayer/managers/NumberLineManager';
import publisherManager from './lessonPlayer/managers/PublisherManager';
import questionFeedbackManager from './lessonPlayer/managers/QuestionFeedbackManager';
import responseManager from './lessonPlayer/managers/ResponseManager';
import scoringManager from './lessonPlayer/managers/ScoringManager';
import studentActivityManager from './lessonPlayer/managers/StudentActivityManager';
import styleManager from './lessonPlayer/managers/StyleManager';
import toolbarManager from './lessonPlayer/managers/ToolbarManager';
import uploadManager from './lessonPlayer/managers/UploadManager';
import userManager from './lessonPlayer/managers/UserManager';
import videoManager from './lessonPlayer/managers/VideoManager';

import itemBankManager from './assessmentBuilder/managers/ItemBankManager';
import curriculumManager from './assessmentBuilder/managers/CurriculumManager';

import htmlService from './lessonPlayer/services/HtmlService';

import ActivityMode from './views/ActivityMode';
import ContentViewer from './views/ContentViewer';
import ItemPreview from './views/ItemPreview';
import Manuscript from './views/Manuscript';
import Preview from './views/Preview';
import PrintPreview from './views/PrintPreview';
import PublisherViewer from './views/PublisherViewer';

import ReviewMode from './views/ReviewMode';
import ScoreMode from './views/ScoreMode';
import AssessmentBuilder from './views/AssessmentBuilder';

export default function App() {
  useEffect(() => {
    const updateNetState = () => {
      lessonManager.setOnline(window.navigator.onLine);
    };
    window.addEventListener('load', updateNetState);
    window.addEventListener('online', updateNetState);
    window.addEventListener('offline', updateNetState);

    return () => {
      window.removeEventListener('load', updateNetState);
      window.removeEventListener('online', updateNetState);
      window.removeEventListener('offline', updateNetState);
    };
  }, []);

  return (
    <>
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path='/manuscript'>
            <Provider
              lessonManager={lessonManager}
              userManager={userManager}>
              <Manuscript />
            </Provider>
          </Route>
          <Route path='/printPreview'>
            <Provider
              alignmentManager={alignmentManager}
              audioManager={audioManager}
              bookDrawManager={bookDrawManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              drawManager={drawManager}
              eventListenerManager={eventListenerManager}
              graphPaperManager={graphPaperManager}
              highlightAnnotationManager={highlightAnnotationManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              numberLineManager={numberLineManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <PrintPreview />
            </Provider>
          </Route>
          <Route path='/preview'>
            <Provider
              alignmentManager={alignmentManager}
              audioManager={audioManager}
              bookDrawManager={bookDrawManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              drawManager={drawManager}
              eventListenerManager={eventListenerManager}
              graphPaperManager={graphPaperManager}
              highlightAnnotationManager={highlightAnnotationManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              numberLineManager={numberLineManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <Preview />
              {htmlService.renderHiddenRichTextEditorInstance()}
            </Provider>
          </Route>
          <Route path='/itemPreview'>
            <Provider
              alignmentManager={alignmentManager}
              audioManager={audioManager}
              bookDrawManager={bookDrawManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              drawManager={drawManager}
              eventListenerManager={eventListenerManager}
              graphPaperManager={graphPaperManager}
              highlightAnnotationManager={highlightAnnotationManager}
              itemBankManager={itemBankManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              numberLineManager={numberLineManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <ItemPreview />
              {htmlService.renderHiddenRichTextEditorInstance()}
            </Provider>
          </Route>

          <Route path='/contentViewer'>
            <Provider
              alignmentManager={alignmentManager}
              // audioManager={audioManager}
              // drawManager={drawManager}
              // eventListenerManager={eventListenerManager}
              // graphPaperManager={graphPaperManager}
              // highlightAnnotationManager={highlightAnnotationManager}
              // numberLineManager={numberLineManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              itemBankManager={itemBankManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <ContentViewer />
              {htmlService.renderHiddenRichTextEditorInstance()}
            </Provider>
          </Route>

          <Route path='/publisherPreview'>
            <Provider
              alignmentManager={alignmentManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              itemBankManager={itemBankManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <PublisherViewer />
              {htmlService.renderHiddenRichTextEditorInstance()}
            </Provider>
          </Route>

          <Route path='/activityMode'>
            <Provider
              alignmentManager={alignmentManager}
              audioManager={audioManager}
              bookDrawManager={bookDrawManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              drawManager={drawManager}
              eventListenerManager={eventListenerManager}
              graphPaperManager={graphPaperManager}
              highlightAnnotationManager={highlightAnnotationManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              numberLineManager={numberLineManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              studentActivityManager={studentActivityManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <ActivityMode />
              {htmlService.renderHiddenRichTextEditorInstance()}
            </Provider>
          </Route>
          <Route path='/review'>
            <Provider
              alignmentManager={alignmentManager}
              audioManager={audioManager}
              bookDrawManager={bookDrawManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              drawManager={drawManager}
              eventListenerManager={eventListenerManager}
              graphPaperManager={graphPaperManager}
              highlightAnnotationManager={highlightAnnotationManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              numberLineManager={numberLineManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              studentActivityManager={studentActivityManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <ReviewMode />
              {htmlService.renderHiddenRichTextEditorInstance()}
            </Provider>
          </Route>
          <Route path='/scoring'>
            <Provider
              alignmentManager={alignmentManager}
              audioManager={audioManager}
              bookDrawManager={bookDrawManager}
              clozeMultichoiceManager={clozeMultichoiceManager}
              drawManager={drawManager}
              eventListenerManager={eventListenerManager}
              graphPaperManager={graphPaperManager}
              highlightAnnotationManager={highlightAnnotationManager}
              lessonManager={lessonManager}
              navMenuManager={navMenuManager}
              numberLineManager={numberLineManager}
              publisherManager={publisherManager}
              questionFeedbackManager={questionFeedbackManager}
              responseManager={responseManager}
              scoringManager={scoringManager}
              studentActivityManager={studentActivityManager}
              styleManager={styleManager}
              toolbarManager={toolbarManager}
              uploadManager={uploadManager}
              userManager={userManager}
              videoManager={videoManager}>
              <ScoreMode />
              {htmlService.renderHiddenRichTextEditorInstance()}
            </Provider>
          </Route>
          <Route path='/builder'>
            <Provider
              curriculumManager={curriculumManager}
              itemBankManager={itemBankManager}
              lessonManager={lessonManager}
              toolbarManager={toolbarManager}
              userManager={userManager}>
              <AssessmentBuilder />
            </Provider>
          </Route>
          <Route path='/'>
            <Empty />
          </Route>
        </Switch>
      </Router>
      {/* {htmlService.renderHiddenRichTextEditorInstance()} */}
    </>
  );
}

function Empty() {
  return <></>;
}
