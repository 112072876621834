import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import HtmlComponent from './HtmlComponent';

// eslint-disable-next-line no-empty-pattern
const SectionBanner = observer(({ /* lessonElementId */ } = {}) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const title = lessonManager.currentSectionTitle;

  return (
    <>
      {(!!title && lessonManager.lessonOptions.hasSectionBanner) ? (
        <div className='section-header'>
          <HtmlComponent htmlStr={title} />
        </div>
      )
        : <></>}
    </>
  );
});

export default SectionBanner;
