import React from 'react';

const DefaultResource = ({ model }) => {
  return (
    <>
      <div className='top-label'>Resource:</div>
      <div className='element-container' id={`_${model.id}`}>
        <div className='label-value-row'>
          <div>Resource Type:</div>
          <div className='not-implemented'>{model.type} (*** not implemented yet ***)</div>
        </div>
      </div>
    </>
  );
};
export default DefaultResource;
