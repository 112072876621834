import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/Venn.scss';
import { LessonMode } from '../../../Constants';

import VennService from '../../services/VennService';

import questionService from '../../services/QuestionService';
import responseService from '../../services/ResponseService';
import styleService from '../../services/StyleService';

import useForceUpdate from '../../../hooks/useForceUpdate';

import Guideline from '../tools/Guideline';
import HtmlComponent from '../HtmlComponent';
import useStyleEvents from '../../../hooks/useStyleEvents';
import PrintQuestionNumber from './PrintQuestionNumber';

const Venn = observer(({ lessonElementId }) => {
  const {
    lessonManager,
    questionFeedbackManager,
    toolbarManager
  } = useContext(MobXProviderContext);

  const { getStyleVar } = styleService;

  const {
    lessonElementState,
    model,
    readOnly: isQuestionReadOnly,
  } = questionService.initQuestionComponent({
    includeUserInputMap: false,
    lessonElementId,
    questionClassName: 'venn-question'
  });

  const uiState = questionFeedbackManager.getUiState(model.lessonElementId);
  const showAnswerFeedback = uiState?.showAnswerFeedback || false;

  const vennService = useRef(new VennService(model));

  const forceUpdate = useForceUpdate();

  useStyleEvents(lessonElementId);

  useEffect(() => {
    async function drawToCanvas() {
      vennService.current.initCanvas();
      vennService.current.render();
      // after all canvas rendering is done, force a react re-render to overlay textareas on canvas objects
      forceUpdate();
    }

    drawToCanvas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.id]);

  // TODO remove
  // const feedbackState = questionFeedbackManager.getUiState(model.lessonElementId);
  // if (feedbackState.showAnswerFeedback) {
  //   if (vennService.canvas) {
  //     vennService.showResponse();
  //     vennService.showValidationFeedback();
  //   }
  // }

  // if (feedbackState.showAnswers) {
  //   if (vennService.canvas) {
  //     vennService.drawCorrectAnswers();
  //   }
  // }

  // const lessonElementState = responseManager.getLessonElementState(model.lessonElementId);
  // if (lessonElementState.isTryAgain) {
  //   vennService.showResponse();
  // }

  // console.info(
  //   'showAnswers: ' + feedbackState.showAnswers,
  //   'showAnswerFeedback: ' + feedbackState.showAnswerFeedback,
  //   'try again: ' + lessonElementState.isTryAgain,
  //   'canvas: ' + vennService.canvas !== null);

  const renderVennTextContainers = ({ canvas, model }) => {
    if (!canvas) {
      return null;
    }

    let textContainerCount = 0;
    const textArray = [ // order is important here - (leftText, rightText, commonText)
      ...model.leftText,
      ...model.rightText,
      ...model.commonText
    ];

    const userInputArray = lessonElementState?.currentResponseAnswer?.prompts;

    return (
      <>
        {canvas.getObjects().map((obj) => {
          if (obj.type === 'rectangle') {
            const textContainerIndex = textContainerCount;

            const id = `promptId${ textContainerIndex}`;
            const readOnlyText = !!textArray[textContainerIndex];

            const value = textArray[textContainerIndex] || userInputArray[textContainerIndex]?.text;

            textContainerCount++;

            return renderVennTextInput({
              height: obj.height,
              id,
              index: textContainerIndex,
              key: id,
              left: obj.left,
              readOnlyText,
              top: obj.top,
              value,
              width: obj.width
            });
          }
          return null;
        })}
      </>
    );
  };

  const renderVennTextInput = ({
    height, id, index, key, left, readOnlyText, top, value, width
  }) => {
    const fontWeight = readOnlyText ? 'bold' : 'normal';

    const TEXT_AREA_WIDTH_OFFSET = !readOnlyText ?
      +getStyleVar('--venn-text-area-width-offset') : +getStyleVar('--venn-text-area-readonly-width-offset');
    const TEXT_AREA_HEIGHT_OFFSET = !readOnlyText ?
      +getStyleVar('--venn-text-area-height-offset') : +getStyleVar('--venn-text-area-readonly-height-offset');

    return (
      <textarea key={key}
        className={classNames('canvasTextInput', {
          readOnlyText: !!readOnlyText
        })}
        data-id={id}
        onChange={(event) => responseService.responseChangeHandler({ event, id, index }, lessonElementId)}
        readOnly={!!readOnlyText || isQuestionReadOnly || showAnswerFeedback}
        style={{
          left,
          top,
          width: width - TEXT_AREA_WIDTH_OFFSET,
          height: height - TEXT_AREA_HEIGHT_OFFSET,
          fontWeight
        }}
        type='text'
        value={value}
      />
    );
  };

  return (
    <div className='venn-question'>
      <div className='venn-section-title'>
        {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
        <div className='test-item-question'>
          {(lessonManager.playerMode === LessonMode.PRINT_PREVIEW) && <PrintQuestionNumber model={model} />}
          <HtmlComponent htmlStr={model.directions || model.questionText || ''} />
        </div>
      </div>
      <div className={classNames('venn-question-inner')}>
        <div className='venn-question-section-body'>
          <div className='test-item-answers'>
            <div className='venn-body'>
              <div className='body canvasOrganizerBody editSection' style={{ height: vennService.current.canvasHeight, width: vennService.current.canvasWidth }}>
                <canvas className='canvas' id={model.id} />
                <div className='canvasTextInputContainer' style={{ height: vennService.current.canvasHeight }}>
                  {renderVennTextContainers({
                    canvas: vennService.current.canvas,
                    model
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
});

export default Venn;
