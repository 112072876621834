import React from 'react';

import classNames from 'classnames';
import { register } from '../../i18n';

import toolbarManager from '../managers/ToolbarManager';

import questionService from './QuestionService';
import responseService from './ResponseService';

import FeedbackIcon from '../components/FeedbackIcon';
import Guideline from '../components/tools/Guideline';
import HtmlComponent from '../components/HtmlComponent';
import OptionalImage from '../components/OptionalImage';
import RichTextEditor from '../components/RichTextEditor';

const t = register('GlobalQuestionLabels');

export default class ChartGraphicOrganizerService {
  static initChartGraphicOrganizer = ({ lessonElementId } = {}) => {
    const {
      lessonElementState,
      model,
      readOnly,
      toolbar,
      uiState: feedbackState
    } = questionService.initQuestionComponent({
      includeUiFeedbackState: true,
      includeUserInputMap: false,
      lessonElementId
    });

    const { showAnswers } = feedbackState;
    const { currentResponseAnswer } = lessonElementState;

    const { directions } = model;
    const hasDirections = directions && directions.length > 0;
    const { title } = model;
    const hasTitle = title && title.length > 0;
    const isSingleHeader = model.headerLayout === 'single';
    const singleHeaderText = model.singleHeaderTitle;
    const columns = model.columns || [];
    const numColumns = columns.length;
    const hasColumnContent = columns.some((c) => c.text && c.text.length > 0);
    const columnPercentWidth = `${100 / numColumns}%`;

    return {
      columnPercentWidth,
      columns,
      currentResponseAnswer,
      directions,
      feedbackState,
      hasColumnContent,
      hasDirections,
      hasTitle,
      isSingleHeader,
      lessonElementState,
      model,
      numColumns,
      readOnly,
      showAnswers,
      singleHeaderText,
      title,
      toolbar
    };
  }

  static renderChart = ({
    chartPrefixClassName,
    columnPercentWidth,
    columns,
    currentResponseAnswer,
    directions,
    feedbackState,
    hasColumnContent,
    hasDirections,
    hasTitle,
    isSingleHeader,
    lessonElementId,
    lessonElementState,
    model,
    numColumns,
    readOnly,
    showAnswers,
    singleHeaderText,
    title,
    toolbar
  }) => {
    const showAnswerFeedback = feedbackState?.showAnswerFeedback || false;
    const isDisabled = readOnly || showAnswerFeedback;

    return (
      <div className={classNames('chart-graphic-organizer', chartPrefixClassName)}>
        {toolbarManager.isGuidelineOpen && <Guideline lessonElementId={lessonElementId} />}
        {hasDirections && (
          <div className={classNames('directions chart', chartPrefixClassName)}>
            <HtmlComponent htmlStr={directions} />
          </div>
        )}
        <OptionalImage model={model} runtime={true} />
        <div className={classNames('test-item-answers', chartPrefixClassName)}>
          {showAnswers && (
            <div className={classNames('feedback-icon-container', chartPrefixClassName)}>
              <FeedbackIcon isScored={lessonElementState.isScored} lessonElementId={lessonElementId} />
            </div>
          )}
          {hasTitle && (
            <div className={classNames('title', chartPrefixClassName)}>
              <p className={classNames('title-header')}>
                Title:
              </p><HtmlComponent htmlStr={`${title}`} />
            </div>
          )}
          <table className={classNames('chart-table', chartPrefixClassName)}>
            <thead>
              <tr>
                {isSingleHeader ? (
                  <td className={classNames('chart-header', chartPrefixClassName)} colSpan={numColumns}>
                    <HtmlComponent htmlStr={singleHeaderText} />
                  </td>
                ) : (
                  columns.map((column) => {
                    return (
                      <td key={column.id} className={classNames('chart-header')}><HtmlComponent htmlStr={column.title} /></td>
                    );
                  })
                )}
              </tr>
            </thead>
            <tbody>
              {hasColumnContent && (
                <tr>
                  {columns.map((column) => {
                    return (
                      <td key={column.id} className={classNames('chart-column-text', chartPrefixClassName)}>
                        <div className={classNames('text-wrapper', chartPrefixClassName)}>
                          <HtmlComponent htmlStr={column.text} />
                        </div>
                      </td>
                    );
                  })}
                </tr>
              )}
              <tr>
                {columns.map((column) => {
                  return (
                    <td key={column.id}
                      className={classNames('chart-input-container', chartPrefixClassName)} style={{ width: columnPercentWidth }}>
                      <RichTextEditor data={this.getCurrentColumnAnswer({
                        columnId: column.id,
                        currentResponseAnswer
                      })}
                      disabled={isDisabled}
                      lessonElementId={lessonElementId}
                      onChange={(data) => {
                        this.setCurrentColumnAnswer({
                          columnId: column.id,
                          lessonElementId,
                          text: data
                        });
                      }}
                      placeHolder={t('enterText')}
                      readOnly={isDisabled}
                      toolbar={toolbar} />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  static getCurrentColumnAnswer = ({
    columnId, currentResponseAnswer
  } = {}) => {
    if (currentResponseAnswer?.columns) {
      const column = currentResponseAnswer.columns.find((c) => c.id === columnId);
      if (column) {
        return column.text;
      }
    }
  }

  static setCurrentColumnAnswer = ({ columnId, lessonElementId, text } = {}) => {
    responseService.responseChangeHandler({
      id: columnId,
      text
    }, lessonElementId);
  }
}
