import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import CheckboxTree from 'react-checkbox-tree';
import curriculumService from '../services/CurriculumService';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import bookmarkImg from '../../images/content-object-icons/bookmark.svg';
import bookmarkSelectedImg from '../../images/content-object-icons/bookmark-selected.svg';

const BookmarkSelectionTree = observer((props) => {
  const {
    curriculumManager,
    userManager
  } = useContext(MobXProviderContext);

  const handleChangeBookmarks = async (ids, node) => {
    if (!node.value) {
      return false;
    }
    if (curriculumManager.bookmarkedStandardIds.includes(node.value)) {
      const bookmark = curriculumManager.standardToBookmarkMap.get(node.value);
      await curriculumService.deleteStandardFrameworkBookmark(bookmark.id);
    } else {
      await curriculumService.addStandardFrameworkBookmark(node.value, userManager.userId);
    }
    await curriculumService.fetchStandardFrameworkBookmarks(userManager.userId);
  };

  return (
    <>
      <div className='checkbox-tree-container'>
        <div className='filter-label' />
        <CheckboxTree
          checked={curriculumManager.bookmarkedStandardIds}
          expanded={curriculumManager.curriculumTreeExpanded}
          icons={{
            check: <img alt='' className='bookmark-mode' src={bookmarkSelectedImg} />,
            uncheck: <img alt='' className='bookmark-mode' src={bookmarkImg} />,
            halfCheck: <span className='rct-icon rct-icon-half-check' />,
            expandClose: <span className='icon-expand-close' />,
            expandOpen: <span className='icon-expand-open' />,
            expandAll: <span className='rct-icon rct-icon-expand-all' />,
            collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
            parentClose: <span className='rct-icon rct-icon-parent-close' />,
            parentOpen: <span className='rct-icon rct-icon-parent-open' />,
            leaf: <span className='rct-icon rct-icon-leaf' />
          }}
          nativeCheckboxes={false}
          noCascade={true}
          nodes={curriculumManager.adoptedStandardsJson}
          onCheck={(checked, node) => handleChangeBookmarks(checked, node)}
          onExpand={(expanded) => curriculumManager.addCurriculumTreeExpanded(expanded)} />
      </div>
    </>
  );
});

export default BookmarkSelectionTree;
