import React, { useContext, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/QuestionFlyout.scss';

import videoService from '../../services/VideoService';
import audioService from '../../services/AudioService';

import useAccessibilityClick from '../../../hooks/useAccessibilityClick';

import ActivityChildViewer from '../activityViewer/ActivityChildViewer';
import StandAloneViewer from '../StandAloneViewer';
import navMenuService from '../../services/NavMenuService';
import { register } from '../../../i18n';
const t = register('PanelMenu');

const QuestionFlyout = observer(({
  // TODO unused // model,
  isActivity,
  lessonElementId,
  showQuestionBanner
}) => {
  const {
    lessonManager, videoManager, audioManager
  } = useContext(MobXProviderContext);

  const openButton = useRef();
  const childModel = lessonManager.getSlideModel(lessonElementId);

  const parentModel = lessonManager.getSlideModel(childModel?.parentActivityElementId);

  lessonManager.setIsQuestionListView(false);

  useAccessibilityClick(openButton, () => {
    navMenuService.toggleInteractiveFlyout();
  });

  const isVideo = parentModel.type.includes('Video');
  const isAudio = parentModel.type.includes('Audio');

  const isDividedLayoutVideoQuestion = videoService.isDividedLayoutVideoQuestion(parentModel);
  const isDividedLayoutAudioQuestion = audioService.isDividedLayoutAudioQuestion(parentModel);
  let customVideoContentLabel, customAudioContentLabel;

  if (isDividedLayoutVideoQuestion) {
    customVideoContentLabel = videoService.getCustomVideoLayoutLabel({
      model: parentModel,
      videoLabelPrefix: 'q-flyout-wrapper'
    });
    if (!lessonManager.isInteractiveFlyoutOpen) {
      lessonManager.setIsInteractiveFlyoutOpen(true);
    }
  }
  if (isDividedLayoutAudioQuestion) {
    customAudioContentLabel = audioService.getCustomAudioLayoutLabel({
      audioLabelPrefix: 'q-flyout-wrapper',
      model: parentModel
    });
    if (!lessonManager.isInteractiveFlyoutOpen) {
      lessonManager.setIsInteractiveFlyoutOpen(true);
    }
  }

  const activityLessonElement = lessonManager.getLessonElement(childModel.parentActivityElementId);
  const groupListIds = activityLessonElement?.lessonElementGroups?.get?.(childModel.groupId) || [];

  return ((lessonElementId) ? (
    <div className={classNames('q-flyout-wrapper', (isAudio && customAudioContentLabel), (isVideo && customVideoContentLabel), {
      activity: isActivity,
      closed: (!lessonManager.isInteractiveFlyoutOpen),
      wide: (lessonManager.isInteractiveFlyoutOpen)
    }, parentModel.type)}>
      <div className={classNames({
        'q-flyout-tab-bar': (isVideo && !isDividedLayoutVideoQuestion) || (isAudio && !isDividedLayoutAudioQuestion)
      })}>
        <div ref={openButton} className={classNames('q-flyout-tab open-button-wrapper', {
          'q-flyout-tab-closed': !lessonManager.isInteractiveFlyoutOpen
        })}>
          <div aria-hidden className='icon-wrapper' tabIndex='-1'>
            <div aria-hidden
              aria-label={(!lessonManager.isInteractiveFlyoutOpen) ? `${t('openMenu', 'Open flyout menu')}` : `${t('closeMenu', 'Close flyout menu')}`}
              className='open-button'
              tabIndex='-1' />
          </div>
        </div>
      </div>
      <div className='q-flyout-panel-wrapper'>
        <div className={classNames('q-flyout-panel', {
          'audio-q-flyout-panel': isDividedLayoutAudioQuestion,
          'video-q-flyout-panel': isDividedLayoutVideoQuestion
        })}>
          {((isVideo && (!isDividedLayoutVideoQuestion || videoManager.allowShowDividedVideoQuestions)) ||
            (isAudio && (!isDividedLayoutAudioQuestion || audioManager.allowShowDividedAudioQuestions))) && (
            <>
              {(groupListIds?.length || parentModel?.showAllChildren) && (
                <ActivityChildViewer
                  lessonElementId={lessonElementId}
                  model={childModel}
                  showAllChildren={parentModel?.showAllChildren}
                  showQuestionBanner={showQuestionBanner} />
              )}
              {!groupListIds?.length && !parentModel?.showAllChildren && (
                <StandAloneViewer
                  lessonElementId={lessonElementId}
                  model={childModel}
                  showQuestionBanner={showQuestionBanner} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  ) :
    <></>);
});

export default QuestionFlyout;
