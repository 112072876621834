import { action, computed, makeObservable, observable } from 'mobx';

// TODO: this is Gallo only.
export const ITEM_TYPES = {
  MULTIPLE_CHOICE: 'Multiple Choice',
  MULTIPLE_SELECT: 'Multiple Select'
};

// eslint-disable-next-line max-len
export const BANK_ITEM_TYPES = 'article_resource,audio_resource,bookmark_resource,mathia_resource,google_doc_resource,google_sheet_resource,google_slide_resource,google_resource,file_resource,pdf_resource,image_resource,video_resource,assessment,learnosity_item_resource,learnosity_activity_resource,lesson,lesson_plan,course_resource,curriculum_map,rubric,microsite_resource,carnegie_resource,flipgrid,performance,custom_activity,cog_tutor_workspace,ready_check,ipa,ebook_activity,ereader,iculture,item_resource,Presentation,StudentInstruction,TeacherInstruction,WebPage,Multichoice,Multiselect,ShortText,LongText,Upload,Cloze,ClozeDragDrop,ClozeMultichoice,ClozeMath,Categorize,Griddable,ImageLabel,ImageLabelMultichoice,ImageLabelDragDrop,Draw,ChoiceMatrix,ChoiceMatrixMulti,TextSelector,TextHighlight,HotText,OrderList,OrderSentences,ClozeSpelling,ImageTextMatch,ImageTextMatchFlip,Analogies,BubbleWeb,CategoryTree,CauseAndEffect,CompareContrast,CornellNotes,CircleDefine,KWLChart,Relationships,Sequence,TChart,Venn,WordAssociation';

export class ItemBankManager {
  questionTypeOptions =
  [
    { key: 'Categorize', value: 'Categorize', label: 'Classify' },
    { key: 'ClozeDragDrop', value: 'ClozeDragDrop', label: 'Fill In The Blank Drag-Drop' },
    { key: 'ClozeMath', value: 'ClozeMath', label: 'Fill In The Blank Math' },
    { key: 'ClozeMultichoice', value: 'ClozeMultichoice', label: 'Fill In The Blank Multi-Choice' },
    { key: 'ClozeCombo', value: 'ClozeCombo', label: 'Fill In The Blank Combo' },
    { key: 'Cloze', value: 'Cloze', label: 'Fill In The Blank Typing' },
    { key: 'Draw', value: 'Draw', label: 'Free Draw' },
    { key: 'LongText', value: 'LongText', label: 'Free Response' },
    { key: 'Griddable', value: 'Griddable', label: 'Griddable' },
    { key: 'TextHighlight', value: 'TextHighlight', label: 'Highlight Text' },
    { key: 'HotText', value: 'HotText', label: 'Hot Text' },
    { key: 'ImageHotSpot', value: 'ImageHotSpot', label: 'Image Hot Spot' },
    { key: 'ImageLabel', value: 'ImageLabel', label: 'Label Image Typing' },
    { key: 'ImageLabelMultichoice', value: 'ImageLabelMultichoice', label: 'Label Image Multiple Choice' },
    { key: 'ImageLabelDragDrop', value: 'ImageLabelDragDrop', label: 'Label Image Drag Drop' },
    { key: 'Multichoice', value: 'Multichoice', label: 'Multiple Choice' },
    { key: 'ChoiceMatrixMulti', value: 'ChoiceMatrixMulti', label: 'Multiple Choice Table' },
    { key: 'Multiselect', value: 'Multiselect', label: 'Multiple Select' },
    { key: 'NumberLine', value: 'NumberLine', label: 'Number Line' },
    { key: 'OrderList', value: 'OrderList', label: 'Order List' },
    { key: 'OrderSentences', value: 'OrderSentences', label: 'Order Sentences' },
    { key: 'TextSelector', value: 'TextSelector', label: 'Select Text' },
    { key: 'ShortText', value: 'ShortText', label: 'Short Response' },
    { key: 'ChoiceMatrix', value: 'ChoiceMatrix', label: 'True - False' },
    { key: 'Upload', value: 'Upload', label: 'Upload Files' },
    { key: 'MultipartActivity', value: 'MultipartActivity', label: 'Multipart Activity' },
    { key: 'VideoQuestion', value: 'VideoQuestion', label: 'Video Activity' },
    { key: 'AudioQuestion', value: 'AudioQuestion', label: 'Audio Activity' },
    { key: 'BookletActivity', value: 'BookletActivity', label: 'Book Activity' },
    { key: 'BookmarkActivity', value: 'BookmarkActivity', label: 'Bookmark Activity' },
    { key: 'ArticleQuestion', value: 'ArticleQuestion', label: 'Passage Activity' },
    { key: 'ImageQuestion', value: 'ImageQuestion', label: 'Image Activity' },
    { key: 'StudentInstruction', value: 'StudentInstruction', label: 'Student Instructions' },
    { key: 'TeacherInstruction', value: 'TeacherInstruction', label: 'Teacher Instructions' }

    // { key: 'TChart', value: 'TChart', label: 'T Chart'}
    // { key: 'ClozeSpelling', value: 'ClozeSpelling', label: 'Spelling' },
    // { key: 'ImageTextMatch', value: 'ImageTextMatch', label: 'Image Text Match' },
    // { key: 'ImageTextMatchFlip', value: 'ImageTextMatchFlip', label: 'Image Text Flip' }
  ];

  activityTypes = ['MultipartActivity',
    'VideoQuestion',
    'AudioQuestion',
    'BookletActivity',
    'BookmarkActivity',
    'ArticleQuestion',
    'ImageQuestion'];

  @observable contentItemsLoading = false;
  @observable contentItemsLoadingProgress = false;
  @observable recommendedItemsLoading = false;
  @observable activePage = 1;
  @observable totalPages = 0;
  @observable totalResults = 0;
  @observable resultsLabel = 'No Results';
  // @observable itemBankItems = [];
  @observable itemBankItemsMap = new Map();
  @observable itemBankItemsSelectedMap = new Map();

  @observable recommendedItemsMap = new Map();
  @observable recommendedSelectedMap = new Map();

  @observable activityGroupsMap = new Map();

  @observable itemBanks = [];
  @observable productCmaps = [];
  @observable selectedItemBank = [];
  // @observable selectedBankItemArray = [];

  @observable searchItemBankId = '';
  @observable searchQuestionTypes = [];
  @observable bucketQuestionTypes = [];

  // presently not controlled
  @observable tagSearchOperator = 'AND';
  @observable standardSearchOperator = 'OR';

  @observable searchTerms = '';
  @observable searchTags = [];
  @observable searchStandards = [];
  @observable searchStandardElementIds = [];
  @observable searchTextTimeout = null;

  @observable resourceBucketItems = new Map();
  @observable lessonItems = new Map();

  @observable categoriesWithTags = new Map();
  @observable isResourceCartOpen = false;
  @observable isResourceRecommendCartOpen = false;

  @observable cartRemovals = [];
  @observable recommendedSelected = [];
  @observable isRecommendedAddMode = true;
  @observable lessonContentItemId = '';

  @observable expandedSelected = [];
  @observable cmapStandardsSelected = [];
  @observable cmapStandardsJson = [];

  @observable activityCardExpanded = [];

  @observable elementHasGroupArray = [];

  @observable cmapStandardsMap = new Map();

  @observable selectedCmapOption = null;

  @observable viewMode = 'build';
  @observable previewId = 'null';
  // eslint-disable-next-line no-array-constructor
  @observable lessonProfiles = [];

  @observable loading = false;
  @observable detailDialogOpen = false;
  @observable detailInfo = null;
  @observable duplicateDialogOpen = false;
  @observable printPreviewDialogOpen = false;
  @observable printPreviewMode = null;
  @observable selectedCatToTags = new Map();

  @observable usageDialogOpen = false;
  @observable usageItem = null;
  @observable usageResults = null;

  @observable lessonProfileId = null;
  @observable selectedProfileOption = null;
  @observable profiles = [];
  @observable profilesLoaded = false;

  @observable activityParentLessonElementId = null;

  @observable itemPreviewElementId = null;

  @observable satPreviewUrl = '';

  @observable progressItemsCompleted = 0;
  @observable progressItemsTotal = 0;

  parentRecommendedCartId = null;

  originViewMode = '';

  isNewAdd = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setSatPreviewUrl = (url) => {
    this.satPreviewUrl = url;
  }

  setOriginViewMode = (mode) => {
    this.originViewMode = mode;
  }

  setIsNewAdd = (isNew) => {
    this.isNewAdd = isNew;
  }

  setParentRecommendedCartId = (id) => {
    this.parentRecommendedCartId = id;
  }

  @action setItemPreviewElementId = (id) => {
    this.itemPreviewElementId = id;
  }

  @action clearItemPreviewElementId = () => {
    this.itemPreviewElementId = null;
  }

  @action
  setActivityParentLessonElementId = (val) => {
    this.activityParentLessonElementId = val;
  }

  @action
  setSelectedCatToTags = (val) => {
    this.selectedCatToTags = val;
  }

  @action clearSelectedCatToTags = () => {
    this.selectedCatToTags.clear();
    this.selectedCatToTags = new Map();
  }

  @action clearCmapStandardsJson = () => {
    this.cmapStandardsJson = [];
  }

  @action
  setViewMode = (mode) => {
    this.viewMode = mode;
  }

  @action
  setPreviewId = (id) => {
    this.previewId = id;
  }

  @action
  setLoading = (loading) => {
    this.loading = loading;
  }

  @action setCmapStandardsJson = (json) => {
    this.cmapStandardsJson = json;
  }

  @action addCmapStandardsMapItem = (id, item) => {
    if (item && id) {
      if (!this.cmapStandardsMap.has(id)) {
        this.cmapStandardsMap.set(id, item);
      }
    }
  }

  @action addElementHasGroupArray = (id) => {
    this.elementHasGroupArray.push(id);
  }

  @action setSelectedItemBank = async (val) => {
    this.selectedItemBank = val;
  }

  @action addExpanded = (id) => {
    this.expandedSelected.push(id);
  }

  @action clearExpanded = () => {
    this.expandedSelected = [];
  }

  @action addActivityCardExpanded = (id) => {
    if (!this.activityCardExpanded.includes(id)) {
      this.activityCardExpanded.push(id);
    }
  }

  @action clearActivityCardExpanded = () => {
    this.activityCardExpanded = [];
  }

  @action removeActivityCardExpanded = (remItem) => {
    this.activityCardExpanded.filter((item) => item !== remItem);
  }

  @action setCmapStandardSelected = (selected) => {
    this.cmapStandardsSelected = selected;
    this.setSearchStandards(selected);
  }

  @action setCmapExpanded = (selected) => {
    this.expandedSelected = selected;
  }

  @action clearStandardsSelected = () => {
    this.cmapStandardsSelected = [];
  }

  @action setLessonContentItemId = (val) => {
    this.lessonContentItemId = val;
  }

  @action setSearchTextTimeout = (searchTextTimeout) => {
    this.searchTextTimeout = searchTextTimeout;
  }

  @action setSearchItemBankId = async (val) => {
    this.searchItemBankId = val;
  }

  @action setSearchQuestionTypes = async (selectedQuestionTypes) => {
    this.bucketQuestionTypes = selectedQuestionTypes;
    const searchQuestionTypes = selectedQuestionTypes.map((qt) => qt.value);
    this.searchQuestionTypes = searchQuestionTypes;
  }

  @action setBucketQuestionTypes = async (val) => {
    this.bucketQuestionTypes = val;
  }

  // @action setSearchOperator = async (val) => {
  //   this.searchOperator = val;
  // }

  @action setTagSearchOperator = async (val) => {
    this.searchTagOperator = val;
  }

  @action setStandardSearchOperator = async (val) => {
    this.searchStandardOperator = val;
  }

  @action setSearchTerms = async (val) => {
    this.searchTerms = val;
  }

  @action setSearchTags = async (val) => {
    this.searchTags = val;
  }

  @action setSearchStandards = async (val) => {
    this.searchStandards = val;
  }

  @action setSearchStandardElementIds = async (val) => {
    this.searchStandardElementIds = val;
  }

  @action resetPaginator = () => {
    this.activePage = 1;
    this.totalPages = 0;
    this.totalResults = 0;
    this.resultsLabel = 'No Results';
  }

  @action resetSearchParameters = () => {
    this.searchItemBankId = '';
    this.searchQuestionTypes = [];
    this.bucketQuestionTypes = [];
    this.tagSearchOperator = 'AND';
    this.standardSearchOperator = 'OR';
    this.searchTerms = '';
    this.searchTags = [];
    this.searchStandards = [];
    this.searchTextTimeout = null;
    // fields
    this.selectedItemBank = [];
  }

  @action setActivePage = (pageNum) => {
    this.activePage = pageNum;
  }

  @action setTotalPages = (tp) => {
    this.totalPages = tp;
  }

  @action setTotalResults = (tr) => {
    this.totalResults = tr;
  }

  @action setResultsLabel = (labelText) => {
    this.resultsLabel = labelText;
  }

  @action setContentItemsLoading = (toggle) => {
    this.contentItemsLoading = toggle;
  }

  @action setContentItemsLoadingProgress = (toggle) => {
    this.contentItemsLoadingProgress = toggle;
  }

  @action
  setProgressItemsCompleted = (progressItemsCompleted) => {
    this.progressItemsCompleted = progressItemsCompleted;
  }

  @action
  setProgressItemsTotal = (progressItemsTotal) => {
    this.progressItemsTotal = progressItemsTotal;
  }

  @action setItemBanks = (data) => {
    data.forEach((ib) => {
      const bank = { value: ib.id, label: ib.friendlyName || ib.name };
      this.itemBanks.push(bank);
    });
  }

  @action setProductCmaps = (data) => {
    data.forEach((cm) => {
      if (cm.id) {
        const sortBy = cm.friendlyName || cm.displayName || cm.name;
        const cmap = { value: cm.id, label: sortBy };
        this.productCmaps.push(cmap);
      }
    });
  }

  @action setSelectedCmapOption = (selectedCmapOption) => {
    this.selectedCmapOption = selectedCmapOption;
  }

  @action setStandardSearchElementIds = async (standardSearchElementIds) => {
    standardSearchElementIds(standardSearchElementIds);
  };

  @action toggleResourceCart = () => {
    this.isResourceCartOpen = !this.isResourceCartOpen;
  }

  @action setRecommendedItemsLoading = (toggle) => {
    this.recommendedItemsLoading = toggle;
  }

  @action toggleResourceRecommendCart = (isAdd = true) => {
    this.isRecommendedAddMode = isAdd;
    this.isResourceRecommendCartOpen = !this.isResourceRecommendCartOpen;
  }

  // @action setResourceRecommendAllSelected = (val) => {
  //   this.isResourceRecommendAllSelected = val;
  // }

  @action addResourceItemBankItem = (item) => {
    this.resourceBucketItems.set(item.id, item);
  }

  @action addItemBankItem = (item) => {
    this.itemBankItemsMap.set(item.id, item);
  }

  @action addRecommendedItem = (item) => {
    this.recommendedItemsMap.set(item.id, item);
  }

  @action deleteItemBankItem = (itemId) => {
    this.itemBankItemsMap.delete(itemId);
  }

  @action changeSelectedBankItemArray = (id, isChecked) => {
    if (isChecked) {
      const selectedItem = this.itemBankItemsMap.get(id);
      this.itemBankItemsSelectedMap.set(id, selectedItem);
    } else {
      this.itemBankItemsSelectedMap.delete(id);
    }
  }

  @action removeFromResourceStore = (id) => {
    this.resourceBucketItems.delete(id);
  }

  @action changeCartRemovals = (id, isChecked) => {
    if (isChecked) {
      const index = this.cartRemovals.indexOf(id);
      if (index > -1) { // only splice array when item is found
        this.cartRemovals.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      this.cartRemovals.push(id);
    }
  }

  @action changeRecommendedSelected = (id, isChecked) => {
    if (!isChecked) {
      const index = this.recommendedSelected.indexOf(id);
      if (index > -1) { // only splice array when item is found
        this.recommendedSelected.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      this.recommendedSelected.push(id);
    }

    const recItems = Array.from(this.recommendedItemsMap.values());
    if (recItems !== null && this.recommendedSelected !== null) {
      this.isResourceRecommendAllSelected = (this.recommendedSelected.length === recItems.length);
    }
  }

  @action setResourceRecommendAllSelected = (isChecked) => {
    if (isChecked) {
      const allRecommendedItems = Array.from(this.recommendedItemsMap.keys());
      this.clearRecommendedSelected();
      this.recommendedSelected = allRecommendedItems;
    } else {
      this.clearRecommendedSelected();
    }
    this.isResourceRecommendAllSelected = isChecked;
  }

  @action clearCartRemovals = () => {
    this.cartRemovals = [];
  }

  @action clearRecommendedSelected = () => {
    this.recommendedSelected = [];
  }

  @action addRecommendedSelected = (id) => {
    this.recommendedSelected.push(id);
  }

  @action clearSelectedBankItems = () => {
    this.itemBankItemsSelectedMap.clear();
  }

  @action clearRecommendedItems = () => {
    this.recommendedItemsMap.clear();
  }

  @action clearProductCmaps = () => {
    this.productCmaps.clear();
  }

  @computed get selectedBankItemArray() {
    if (this.itemBankItemsSelectedMap) {
      return Array.from(this.itemBankItemsSelectedMap.keys());
    }
    return null;
  }

  @computed get existingBucketItemIdArray() {
    if (this.resourceBucketItems) {
      return Array.from(this.resourceBucketItems.keys());
    }
    return null;
  }

  @action loadCategoriesWithTags = (cswt) => {
    if (cswt) {
      cswt.forEach((cwt) => {
        if (cwt.id !== null && cwt.id !== '' && cwt.id !== undefined && cwt.visible) {
          this.categoriesWithTags.set(cwt.id, cwt);
        }
      });
    }
  }

  @computed get itemBankItems() {
    if (this.itemBankItemsMap) {
      return Array.from(this.itemBankItemsMap.values());
    }
    return null;
  }

  @computed get itemBankItemsSelected() {
    if (this.itemBankItemsSelectedMap) {
      return Array.from(this.itemBankItemsSelectedMap.values());
    }
    return null;
  }

  @computed get recommendedItems() {
    if (this.recommendedItemsMap) {
      return Array.from(this.recommendedItemsMap.values());
    }
    return null;
  }

  @computed get recommendedItemsSelected() {
    if (this.recommendedItemsSelectedMap) {
      return Array.from(this.recommendedSelectedMap.values());
    }
    return null;
  }

  @computed get activityGroupsMapKeys() {
    if (this.activityGroupsMap) {
      return Array.from(this.activityGroupsMap.keys());
    }
    return null;
  }

  @computed get categoriesWithTagsArray() {
    if (this.categoriesWithTags) {
      return Array.from(this.categoriesWithTags.values());
    }
    return null;
  }

  @computed get resourceBucketItemsArray() {
    const array = [];
    this.resourceBucketItems.forEach((resourceItem, resourceItemKey) => {
      let found = false;
      if (this.lessonItems && this.lessonItems.size > 0) {
        const lessonItem = this.lessonItems.get(resourceItemKey);
        if (lessonItem) {
          found = true;
          if (lessonItem.placeHolder) {
            resourceItem.setLessonElementId(lessonItem.lessonElementId);
            resourceItem.setOrderNum(lessonItem.orderNum);
            resourceItem.setChildElementIds(lessonItem.childElementIds);
            this.deleteLessonItem(resourceItemKey);
            this.addLessonItemBankItem(resourceItem);
          }
        }
      }
      resourceItem.setVisibleInResourceBucket(!found);
      if (!found) {
        resourceItem.setVisibleInResourceBucket(this.isFiltered(resourceItem));
      }
      array.push(resourceItem);
    });

    return array;
  }

  @computed get sortedProductCmaps() {
    let sortedCmaps = [];
    if (this.productCmaps && this.productCmaps.length > 0) {
      sortedCmaps = this.productCmaps.slice();
      sortedCmaps.sort((a, b) => ((a.label > b.label) ? 1 : -1));
    }
    return sortedCmaps;
  }

   isItemInLesson = (itemId) => {
     if (this.lessonItems && this.lessonItems.size > 0) {
       const lessonItem = this.lessonItems.get(itemId);
       if (lessonItem) {
         return true;
       }
     }
     return false;
   }

  isFiltered = (resourceItem) => {
    if (this.bucketQuestionTypes.length === 0) {
      return true;
    }
    for (let i = 0; i < this.bucketQuestionTypes.length; ++i) {
      const type = resourceItem.libraryResource.entitySubTypeId;
      if (type === this.bucketQuestionTypes[i].value) {
        return true;
      }
    }

    return false;
  }

  isActivityType = (typeId) => {
    return this.activityTypes.includes(typeId);
  }

  getItemBankItemFromResourceBucket = (itemId) => {
    return this.resourceBucketItems.get(itemId);
  }

  @action
  getItemBankItemFromLessonBucket = (itemId) => {
    return this.lessonItems.get(itemId);
  }

  @action
  addLessonItemBankItemFromBucket = (itemBankItem, fromDrag = false) => {
    if (itemBankItem) {
      this.addLessonItemBankItem(itemBankItem, fromDrag);
      itemBankItem.setVisibleInResourceBucket(false);
    }
  }

  @action
  removeItemFromLesson = (itemId) => {
    const itemBankItem = this.resourceBucketItems.get(itemId);
    const lessonItem = this.lessonItems.get(itemId);

    if (itemBankItem) {
      let skip = false;
      const targetOrderNum = lessonItem.orderNum;
      this.deleteLessonItem(itemId);
      itemBankItem.setVisibleInResourceBucket(true);
      this.lessonItems.forEach((item, key) => {
        if (item.orderNum > targetOrderNum) {
          item.orderNum -= 1;
        }
        // if this is a child item
        if (!lessonItem.libraryResource.showInStore && !skip) {
          if (item.childElementIds.includes(lessonItem.lessonElementId)) {
            const removeId = lessonItem.lessonElementId;
            const filteredArray = item.childElementIds.filter((id) => id !== removeId);
            item.childElementIds = filteredArray;
            skip = true;
          }
        }
      });
    }
  }

  @action
  deleteLessonItem = (itemId) => {
    this.lessonItems.delete(itemId);
  }

  @action addLessonItemBankItem = (item, fromDrag = false) => {
    if (fromDrag) {
      // if this was added by drag, manually add the item and increment order so UI is correct
      // while the BE is refreshed.
      let foundCollision = false;
      this.lessonItems.forEach((lessonItem) => {
        if (lessonItem.orderNum == item.orderNum) {
          foundCollision = true;
        }
        if (foundCollision) {
          lessonItem.orderNum += 1;
        }
      });
      this.lessonItems.set(item.id, item);
    } else {
      this.lessonItems.set(item.id, item);
    }
  }

  @computed get lessonItemsArray() {
    const array = [];
    this.lessonItems.forEach((lessonItem) => {
      array.push(lessonItem);
    });
    array.sort((a, b) => (Number.parseInt(a.orderNum) - Number.parseInt(b.orderNum)));
    return array;
  }

  @computed get lessonItemIdArray() {
    const array = [];
    this.lessonItems.forEach((lessonItem) => {
      array.push(lessonItem.id);
    });
    return array;
  }

  @computed get lessonParentItemsArray() {
    const array = [];
    this.lessonItems.forEach((lessonItem) => {
      if (lessonItem?.libraryResource?.showInStore) {
        array.push(lessonItem);
      }
    });

    array.sort((a, b) => (Number.parseInt(a.orderNum) - Number.parseInt(b.orderNum)));
    return array;
  }

  getLessonItemsArrayForIds(thisArray) {
    const array = [];
    this.lessonItems.forEach((lessonItem) => {
      if (thisArray.includes(lessonItem.lessonElementId)) {
        array.push(lessonItem);
      }
    });
    // array.sort((a,b)=>( Number.parseInt(a.orderNum) - Number.parseInt(b.orderNum)));
    return array;
  }

  @action clearSearchResults = () => {
    this.itemBankItemsMap.clear();
  }

  @action loadProfiles = (data) => {
    if (data && this.lessonProfiles.length === 0) {
      for (let i = 0; i < data.length; ++i) {
        this.lessonProfiles.push({ value: data[i].id, label: data[i].name });
      }
      this.profilesLoaded = true;
    }
  }

  getQuestionTypeText(id) {
    for (const item of this.questionTypeOptions) {
      if (id === item.key) {
        return item.label;
      }
    }
  }

  @action
  setDetailDialogOpen = (open, item, results) => {
    this.detailDialogOpen = open;
    this.detailInfo = null;
  }

  @action
  setDuplicateDialogOpen = (open) => {
    this.duplicateDialogOpen = open;
  }
  @action
  setPrintPreviewDialogOpen = (open, isInLesson) => {
    this.printPreviewDialogOpen = open;
    this.printPreviewMode = isInLesson;
  }

  @action
  setDetailDialogResults = (item, results) => {
    if (this.detailDialogOpen && results) {
      this.detailInfo = results;
      this.detailInfo.type = this.getQuestionTypeText(item.libraryResource.entitySubTypeId);
      this.detailInfo.text = item.libraryResource.questionStem;
      this.detailInfo.id = item.id;
      this.detailInfo.name = item.libraryResource.name;
    }
    if (!this.detailDialogOpen) {
      this.detailInfo = null;
    }
  }

  @action
  setUsageDialogOpen = (open) => {
    this.usageDialogOpen = open;
  }

  setUsageDialogResults = (item, results) => {
    this.usageItem = item;
    this.usageResults = results;
  }

  @action
  setLessonProfileId = (id) => {
    this.lessonProfileId = id;
  }

  @action
  setProfiles = (array) => {
    this.profiles = array;
  }

  @action
  setSelectedProfileOption = (option) => {
    this.selectedProfileOption = option;
  }
}

export default new ItemBankManager();
