import React from 'react';

const VideoWidget = ({ url, model }) => {
  let { width } = model;
  let { height } = model;
  if (!width) {
    width = '100%';
  } else {
    width = `${model.width}px`;
  }

  if (!height) {
    height = '100%';
  } else {
    height = `${model.height}px`;
  }
  const display = (model.display) ? model.display : 'visible';

  return (
    <video className={`media video ${display}`} controls id={model.id} style={{ width, height }}>
      <source src={url} type={`video/${model.mediaType}`} />
      Your browser does not support the video element.
    </video>
  );
};

export default VideoWidget;
