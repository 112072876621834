import { action, makeObservable, observable } from 'mobx';

export class StyleManager {
  @observable variableOverrides = {};

  constructor() {
    makeObservable(this);
  }

  @action setVariableOverride = (variable, value) => {
    this.variableOverrides[variable] = value;
  }

  @action removeVariableOverride = (variable) => {
    delete this.variableOverrides[variable];
  }

  @action clearVariableOverrides = () => {
    this.variableOverrides = {};
  }
}
export default new StyleManager();
