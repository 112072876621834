import React from 'react';
import QuestionDependency from '../QuestionDependency';

const Presentation = ({ model, isManuscriptShort }) => {
  const grid = (model.grid) ? model.grid : {};

  return (
    <>
      <div className='top-label'>Instruction:</div>
      <div className='element-container' id={`_${model.id}`}>
        <div className='label-value-row'>
          <div>Instruction Type:</div>
          <div>Presentation</div>
        </div>
        <div className='label-value-row'>
          <div>Display:</div>
          <div>{grid.display}</div>
        </div>
        <div className='label-value-row'>
          <div>Column Count:</div>
          <div>{grid.columnCount}</div>
        </div>
        <div className='label-value-row'>
          <div>Column Layout:</div>
          <div>{grid.layout}</div>
        </div>
        <div className='label-value-row'>
          <div>Maximum Width:</div>
          <div>{grid.maxWidth}</div>
        </div>
        {/* advanced options */}
        {!isManuscriptShort && (
          <>
            <QuestionDependency model={model} />
            <div className='label-value-row'>
              <div>Extra Class:</div>
              <div>{model.extraClass}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Presentation;
