import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import QuestionListViewer from '../QuestionListViewer';
import StandAloneViewer from '../StandAloneViewer';

const ActivityChildViewer = observer(({ lessonElementId, showAllChildren, showQuestionBanner }) => {
  const {
    lessonManager
  } = useContext(MobXProviderContext);

  const childModel = lessonManager.getSlideModel(lessonElementId);
  const activityElement = lessonManager.getLessonElement(childModel.parentActivityElementId);

  let lessonElementIds = [];
  if (showAllChildren) {
    lessonElementIds = activityElement.lessonElementIds;
  } else if (childModel.inGroup) {
    lessonElementIds = activityElement.lessonElementGroups.get(childModel.groupId);
  }

  return (
    <>
      {(lessonElementIds?.length > 0) ? (
        <QuestionListViewer isGroup={childModel.inGroup} isMultiPart={false} lessonElementIds={lessonElementIds} parentElementId={childModel.parentActivityElementId}
          showQuestionBanner={showQuestionBanner} />
      )
        :
        <StandAloneViewer lessonElementId={lessonElementId} model={childModel} showQuestionBanner={showQuestionBanner} />}
    </>
  );
});
export default ActivityChildViewer;
