import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../css/ContentLayout.scss';

import { ContentType, FlyoutSide, LessonMode } from '../../Constants';
import lessonManager from '../managers/LessonManager';

import lessonService from '../services/LessonService';
import utilsService from '../services/UtilsService';

import ActivityNavigator from '../components/activityViewer/ActivityNavigator';
import ActivityViewer from '../components/activityViewer/ActivityViewer';
import Flyout from '../components/tabbedFlyout/Flyout';
import FullpageLoader from '../components/FullpageLoader';
import InteractiveViewer from '../components/activityViewer/InteractiveViewer';
import MultipartActivity from '../components/lessonItems/MultipartActivity';
import QuestionBanner from '../components/QuestionBanner';
import QuestionFlyout from '../components/tabbedFlyout/QuestionFlyout';
import SectionBanner from '../components/SectionBanner';
import StandAloneViewer from '../components/StandAloneViewer';
import BookActivityQuestionFlyout from '../components/tabbedFlyout/BookActivityQuestionFlyout';

// TODO remove manuscript class name when all runtime items are created
const ContentLayout = observer(({ isScoring, isScoreModeVideoActivityParent } = {}) => {
  const {
    lessonManager,
    alignmentManager,
    navMenuManager,
    scoringManager
  } = useContext(MobXProviderContext);

  const lessonElementId = lessonManager.currentLessonElementId;

  const mode = lessonManager.playerMode;

  if (!lessonManager ||
    lessonManager.currentSlide < 0 ||
    !lessonManager.getSlideModel(lessonElementId) ||
    !lessonManager.isLoaded) {
    return (<><FullpageLoader /></>);
  }

  const gotOpen = navMenuManager.gotoMenuOpen;

  const model = lessonManager.getSlideModel(lessonElementId);
  const { isActivityPart } = model;
  const { isActivity } = model;

  let showActivityFlyout = false;
  const currentLessonItemHasAlignments = alignmentManager.hasAlignments(lessonElementId);
  // eslint-disable-next-line eqeqeq
  const currentLessonItemHasRubric = (scoringManager.rubricMap.has(lessonElementId) && model.type != ContentType.RUBRIC.type);
  const currentLessonItemHasTeacherNotes = (!utilsService.isNullOrEmpty(model.teacherNotes));

  let isMultipartActivity = false;
  let isInteractiveActivity = false;
  let isBookleteActivity = false;
  const parentLessonElementId = (isActivityPart) ? model.parentActivityElementId : null;
  let parentModel = null;
  if (isActivityPart) {
    parentModel = lessonManager.getSlideModel(parentLessonElementId);
    const activityParentItemHasAlignments = alignmentManager.hasAlignments(parentLessonElementId);
    showActivityFlyout = (mode === LessonMode.PREVIEW ||
        mode === LessonMode.PUBLISHER_PREVIEW ||
         mode === LessonMode.ITEM_PREVIEW) && (((lessonService.showActivityFlyout(activityParentItemHasAlignments)) ||
      (lessonService.showFlyoutTeacherNotes() && !utilsService.isNullOrEmpty(parentModel.teacherNotes))));
    const activityModel = lessonManager.getSlideModel(parentLessonElementId);
    isInteractiveActivity = activityModel.isInteractiveActivity;
    isMultipartActivity = (activityModel.type === ContentType.MULTIPART_ACTIVITY.type);
    isBookleteActivity = (activityModel.type === ContentType.BOOK_ACTIVITY.type);
  }
  lessonManager.setIsInteractiveViewer(isInteractiveActivity);
  const isSplitActivity = (!isInteractiveActivity && !isMultipartActivity);

  const showStandaloneFlyout = lessonService.showStandaloneFlyout(currentLessonItemHasAlignments,
    currentLessonItemHasRubric, currentLessonItemHasTeacherNotes);

  const showQuestionBanner = (lessonManager.lessonOptions.hasItemNumberBanner &&
    !model.isTitlePage &&
    !model.isSummaryPage);

  const isTitle = (model.isTitlePage);
  const isSummary = (model.isSummaryPage);
  lessonManager.setIsQuestionListView(false);

  return (
    <>
      {(!isTitle && !isSummary) && <SectionBanner lessonElementId={lessonElementId} />}
      <QuestionBanner lessonElementId={lessonElementId} showQuestionBanner={showQuestionBanner && (!isActivityPart)} />
      <div className={classNames({
        'content-wrapper': true,
        'manuscript': true,
        'go-to-open': gotOpen,
        'activity': isActivityPart,
        'section-banner': (lessonManager.lessonOptions.hasSectionBanner && !!lessonManager.currentSectionTitle && !isTitle && !isSummary),
        'question-banner': (showQuestionBanner && (!isActivityPart))
      })}
      >
        <div className={classNames({ 'content-stack': true, 'activity': isActivityPart, 'BookletActivity':isBookleteActivity })}>
          {(isActivityPart || isScoreModeVideoActivityParent) ? (
            <div className='test-item-wrapper activity'>
              {(((showActivityFlyout)) || isScoreModeVideoActivityParent) && (
                <Flyout
                  flyoutSide={!isScoreModeVideoActivityParent ? FlyoutSide.LEFT : FlyoutSide.RIGHT}
                  isScoreModeVideoActivityParent={isScoreModeVideoActivityParent}
                  isScoring={isScoring}
                  lessonElementId={model.parentActivityElementId || model.lessonElementId} />
              )}
              {(isInteractiveActivity && !isScoreModeVideoActivityParent) && (
                <>
                  {(parentModel.type !== ContentType.BOOK_ACTIVITY.type && parentModel.type !== ContentType.IMAGE_QUESTION.type) && (
                    <QuestionFlyout
                      isActivity={isActivityPart}
                      lessonElementId={lessonElementId}
                      model={model}
                      showQuestionBanner={showQuestionBanner} />
                  )}
                  <InteractiveViewer lessonElementId={lessonElementId} showQuestionBanner={showQuestionBanner} />
                  {(parentModel.type === ContentType.BOOK_ACTIVITY.type || parentModel.type === ContentType.IMAGE_QUESTION.type) && (
                    <BookActivityQuestionFlyout
                      isActivity={isActivityPart}
                      lessonElementId={lessonElementId}
                      model={model}
                      showQuestionBanner={showQuestionBanner} />
                  )}
                </>
              )}
              {(isMultipartActivity && !isScoreModeVideoActivityParent) && (
                <>
                  {/* Moved to QuestionListViewer */}
                  {/* <AudioPlayer lessonElementId={parentLessonElementId} /> */}
                  <MultipartActivity lessonElementId={lessonElementId} showQuestionBanner={showQuestionBanner} />
                </>
              )}
              {isSplitActivity && <ActivityViewer lessonElementId={lessonElementId} showQuestionBanner={showQuestionBanner} />}
              {(showStandaloneFlyout && !isScoreModeVideoActivityParent) && (
                <Flyout
                  isActivity={isActivityPart}
                  isScoring={isScoring}
                  lessonElementId={lessonElementId} />
              )}
              {(isActivityPart) && (
                <ActivityNavigator activityLessonElementId={model.parentActivityElementId}
                  lessonElementId={lessonElementId} />
              )}
            </div>
          )
            : isActivity ?
              <ActivityViewer lessonElementId={lessonElementId} showQuestionBanner={showQuestionBanner} />
              : (
                <>
                  <div className={classNames({
                    'test-item-wrapper': true,
                    'title': isTitle,
                    'summary': isSummary,
                  }, model.type)}>
                    <StandAloneViewer lessonElementId={lessonElementId} showQuestionBanner={isActivityPart} />
                    {(showStandaloneFlyout && !isScoreModeVideoActivityParent) && (
                      <Flyout
                        isActivity={false}
                        isScoring={isScoring}
                        lessonElementId={lessonElementId} />
                    )}
                  </div>
                </>
              )}
        </div>
      </div>
    </>
  );
});

export default ContentLayout;
