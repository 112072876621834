import React from 'react';
import classNames from 'classnames';
import responseService from '../../services/ResponseService';

const GraphicOrganizerTextField = ({ lessonElementId, left, top, width, height, readOnly, text, promptId, isBold }) => {
  const handleOnChange = (e) => {
    const data = { id: promptId, text: e.target.value };
    responseService.textChangeHandler(data, lessonElementId);
  };

  return (
    <textarea className={classNames('canvas-text-input', { 'read-only': readOnly, 'is-bold': isBold })}
      onInput={handleOnChange}
      readOnly={readOnly}
      style={{ left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px` }}
      type='text'
      value={text} />
  );
};

export default GraphicOrganizerTextField;
