import React, { useRef } from 'react';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import HtmlComponent from '../../lessonPlayer/components/HtmlComponent';

const Pill = (props) => {
  const [menuProps, toggleMenu] = useMenuState({ transition: true });

  const { pill, pillClass } = props;
  const defaultPillClass = (pillClass) || 'default-tag';
  const pillClassName = pill.sortClass || defaultPillClass;
  const { name } = pill;
  const fullName = pill.fullStatement || pill.name;
  const classNameValue = `tag-category-pill ${pillClassName}`;
  const pillRef = useRef();

  return (
    <>
      <div ref={pillRef} className={classNameValue}><HtmlComponent htmlStr={name} onClick={() => toggleMenu(true)} /></div>
      <ControlledMenu {...menuProps} anchorRef={pillRef} arrow={true}
        onClose={() => toggleMenu(false)}
        onPointerLeave={() => toggleMenu(false)}>
        <MenuItem><HtmlComponent htmlStr={fullName} /></MenuItem>
      </ControlledMenu>
    </>
  );
};

export default Pill;
