import React, { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import classNames from 'classnames';
import utils from '../../services/UtilsService';
import { register } from '../../../i18n';
import GotoCard from './GotoCard';
import '../../../css/GotoMenu.scss';
import useAccessibilityClick from '../../../hooks/useAccessibilityClick';
import HtmlComponent from '../HtmlComponent';
import navMenuService from '../../services/NavMenuService';
import responseManager from '../../managers/ResponseManager';

const t = register('AriaLabels');

const GotoMenu = observer((props) => {
  const {
    lessonManager, navMenuManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const leftScrollRef = useRef();
  const rightScrollRef = useRef();
  const scrollRef = useRef();
  const isSmallCard = (lessonManager.lessonOptions && lessonManager.lessonOptions.hasSmallGotoCards);
  const hasNumberedCards = (lessonManager.lessonOptions &&
      lessonManager.lessonOptions.hasNumberedGotoCards &&
      !isSmallCard) ||
    (lessonManager.lessonOptions &&
      !lessonManager.lessonOptions.smallCardQuestionNumber &&
      isSmallCard);

  useEffect(() => {
    navMenuService.setCardWidths();
  });

  useAccessibilityClick(leftScrollRef, (e) => {
    navMenuService.scrollLeftHandler(scrollRef);
  });

  useAccessibilityClick(rightScrollRef, (e) => {
    navMenuService.scrollRightHandler(scrollRef);
  });

  const { lessonGotoTitle } = lessonManager;
  const visibleSlides = lessonManager.getFilteredSlides();

  return (
    <>
      {navMenuManager.gotoMenuOpen && (
        <div className={classNames('goto-menu', { 'small-card': isSmallCard, 'numbered-card': hasNumberedCards, 'has-title': !(utils.isNullOrEmpty(lessonGotoTitle)) })}>
          <div ref={leftScrollRef} aria-label={t('scrollleft')} className='goto-nav-scroll left'>
            <div aria-hidden className='icon-wrapper'><div aria-hidden className='icon' /></div>
          </div>
          <div className='goto-menu-wrapper'>
            <div className='goto-menu-label'><HtmlComponent htmlStr={lessonGotoTitle} /></div>
            <div ref={scrollRef} className='goto-slides'>
              {(lessonManager.slideList.length > 0) ?

              // eslint-disable-next-line array-callback-return
                lessonManager.slideList.map((lessonElementId, _index) => {
                  const model = lessonManager.getSlideModel(lessonElementId);

                  // If the visible slides are less than the slide list that means we are filtering slides in the
                  // grader and we need to make sure those filtered slides are shown correctly.
                  if (!model.isActivityPart || visibleSlides.length < lessonManager.slideList.length) {
                    if (visibleSlides.indexOf(lessonElementId) < 0) {
                      return (<div key={`${lessonElementId}-goto-card`} className='empty-card' />);
                    }
                    const lessonElementState = responseManager.getLessonElementState(lessonElementId);
                    const isLocked = (lessonElementState && lessonElementState.isDependencyLocked);
                    return (<GotoCard key={`${lessonElementId}-goto-card`} isLocked={isLocked} lessonElementId={lessonElementId} />);
                  }
                })
                : <></>}
            </div>
          </div>
          <div ref={rightScrollRef} aria-label={t('scrollright')} className='goto-nav-scroll right'>
            <div aria-hidden className='icon-wrapper'><div aria-hidden className='icon' /></div>
          </div>
        </div>
      )}
    </>
  );
});
export default GotoMenu;
