import React from 'react';

const AudioFeedback = ({ model }) => {
  if (!model.audioFeedback || model.audioFeedback.length === 0) {
    return (
      <div className='label-value-row'>
        <div>Audio Feedback:</div>
        <div>none</div>
      </div>
    );
  }

  return (
    <>
      <div className='standalone-label'>Audio Feedback:</div>
      <ul>
        {model.audioFeedback.map((obj) => {
          let type;
          switch (obj.type) {
          case 'correct':
            type = 'Correct Answer';
            break;
          case 'incorrect':
            type = 'Incorrect Answer';
            break;
          case 'contentObject':
            type = 'All Content';
            break;
          default:
            type = obj.type;
            break;
          }
          return (
            <li key={obj.type} className='answer'>{type}</li>
          );
        })}
      </ul>
    </>
  );
};
export default AudioFeedback;
